import { Component, OnInit, ViewChild } from '@angular/core';
import { UsuariosService, MenuService, InformeProyectosService, MaquinasService, InformeCalidadService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MyFunctions } from '@app/_helpers';
import { first } from 'rxjs/operators';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { ActivatedRoute, Router } from "@angular/router";
import * as c3 from 'c3';
import * as d3 from 'd3';
import * as moment from 'moment';
import { GroupResult, groupBy } from '@progress/kendo-data-query';

@Component({
  selector: 'app-informe-calidad',
  templateUrl: './informe-calidad.component.html'
})
export class InformeCalidadComponent implements OnInit {

  user = this.userService.userValue;

  //For filter
  public loadingDatos: boolean = true;
  public hayDatosFiltro: boolean = false;
  private hayDatosGenerales: boolean = false;
  private hayGruposMaquinas: boolean = false;
  private haySecciones: boolean = false;
  private hayMaquinas: boolean = false;
  private hayOperarios: boolean = false;
  private hayTurnos: boolean = false;

  public fechaIni: Date;
  public fechaFin: Date;

  public listaTurnos: any;
  public turnosSeleccionados: any;

  public listaHerramientas: any;
  public herramientasSeleccionadas: any;

  public listaOperarios: any;
  public operariosSeleccionados: any;

  public listaOfs: any;
  public ofsSeleccionados: any;

  public listaClientes: any;
  public clientesSeleccionados: any;

  public listaPiezas: any;
  public piezasSeleccionadas: any;

  private dataFiltro: any;

 //AREA PRODUCTIVA / SECCION
 private secciones: any;
 public groupedSeccion: GroupResult[];
 public seccionesSeleccionadas: any[] = [];

 //GRUPOS DE MAQUINAS
 public JgruposMaquinasCombo: any;
 public JgruposMaquinasSelected: any;
 public JgruposMaquinasMaquinas: any;

 public listaMaquinas: any;
 public listaMaquinasMostradas: any;
 public maquinasSeleccionadas: any;

 //Para gráficos
 public grafico_donuts: any;
 public grafico_barras: any;
 public dataBarras: any;
 public dataDonut: any;

 //For tabs
 public operacionesstr: String = '';
 public maquinasstr: String = '';
 public operariosstr: String = '';
 public piezasstr: String = '';
 public herramientasstr: String = '';

//Para grids
public maquinas: any;
public operaciones: any;
public operarios: any;
public piezas: any;
public herramientas: any;

 @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  constructor(private maquinasService: MaquinasService, 
              private informeProyectosService: InformeProyectosService, 
              private myFunctions: MyFunctions, 
              private translateService: TranslateService, 
              private informeCalidadService: InformeCalidadService,
              private menuService: MenuService, 
              private userService: UsuariosService,
              private router: Router) {

      //GET GRUPOS
      this.maquinasService.getGruposMaquinas().subscribe(
        json => {
          this.JgruposMaquinasCombo = json.data;
          this.JgruposMaquinasSelected = undefined;
          this.hayGruposMaquinas = true;
        }
      );
  
      /*CARGAR AREAS PRODUCTIVAS*/
      var an1: any = this.userService.secciones;
      this.secciones == undefined;
  
      if (an1 != undefined)
        this.secciones = an1.filter(f => f.activo === true);
      if (this.secciones == undefined) {
        this.userService.getSecciones().subscribe(
          json => {
            this.userService.secciones = json;
  
            //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
            var an1: any = this.userService.secciones;
            this.secciones = an1.filter(f => f.activo === true);
  
            var an: any = this.secciones;
            this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
  
            an.forEach(
              row => {
                if (row.activo)
                  this.seccionesSeleccionadas.push(row);
              });
            this.haySecciones = true;
            this.cargarDatosFiltro();
          });
      } else {
        var an: any = this.secciones;
        this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
  
        an.forEach(
          row => {
            if (row.activo)
              this.seccionesSeleccionadas.push(row);
          });
        this.haySecciones = true;
        this.cargarDatosFiltro();
      }
     this.hayDatosFiltro = false;

    this.menuService.titulo = this.translateService.instant('informeCalidad').toUpperCase();

    this.userService.user.subscribe(x => this.user = x);
   }

  ngOnInit(): void {
    //For tabs
    this.operacionesstr = this.translateService.instant('operaciones');
    this.maquinasstr = this.translateService.instant('maquinas');
    this.operariosstr = this.translateService.instant('operarios');
    this.piezasstr = this.translateService.instant('piezas');
    this.herramientasstr = this.translateService.instant('herramientas');

  }

  //Functions for filter
  btnLimpiarFiltro(): void { //si se quiere limpiar el filtro vaciar los seleccionados y volver a cargar los datos

    this.turnosSeleccionados = undefined;
    this.maquinasSeleccionadas = undefined;
    this.herramientasSeleccionadas = undefined;
    this.operariosSeleccionados = undefined;
    this.ofsSeleccionados = undefined;
    this.clientesSeleccionados = undefined;
    this.piezasSeleccionadas = undefined;
    this.JgruposMaquinasSelected = undefined;
    this.seccionesSeleccionadas = [];
    //SE CARGAN LAS SECCIONES COMO AL PRINCIPIO
    var an: any = this.secciones;
    this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

    an.forEach(
    row => {
      if (row.activo)
        this.seccionesSeleccionadas.push(row);
    });

    this.CambioFiltro();
  }

  btnFiltrar(){
    this.cargarDatosGrids();
  }
  cargarDatosGrids() {
    this.loadingDatos = true; //se están cargando los datos, mostrar barra de carga

    //Cogemos los datos para la llamada de los seleccionados en el filtro
    var listaNombresClientes = (this.clientesSeleccionados === undefined) ? [] : this.clientesSeleccionados.map(a => a.nombreCliente);
    var listaNombresPiezas = (this.piezasSeleccionadas === undefined) ? [] : this.piezasSeleccionadas.map(a => a.nombrePieza);
    var listaNombresOfs = (this.ofsSeleccionados === undefined) ? [] : this.ofsSeleccionados.map(a => a.nombreOf);
    var fechaInicio = (this.fechaIni === undefined) ? undefined : this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaIni);
    var fechaFin = (this.fechaFin === undefined) ? undefined : this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin);
    var listaIdsTurnos = (this.turnosSeleccionados === undefined) ? [] : this.turnosSeleccionados.map(a => a.idTurno);
    var listaIdsSecciones = (this.seccionesSeleccionadas === undefined) ? [] : this.seccionesSeleccionadas.map(a => a.id);
    var listaIdsMaquinas = (this.maquinasSeleccionadas === undefined) ? [] : this.maquinasSeleccionadas.map(a => a.idMaquina);
    var listaIdsGruposMaquinas = (this.JgruposMaquinasSelected === undefined) ? [] : this.JgruposMaquinasSelected.map(a => a.id);
    //var listaIdsHerramientas = (this.herramientasSeleccionadas === undefined) ? [] : this.herramientasSeleccionadas.map(a => a.id);
    var listaIdsOperarios = (this.operariosSeleccionados === undefined) ? [] : this.operariosSeleccionados.map(a => a.idOperario);

    //Tomar datos piezas para tabla
    this.informeCalidadService.GetInformeTablas(fechaInicio, fechaFin, listaNombresClientes.join("&"), listaNombresPiezas.join("&"), listaNombresOfs.join("&"), listaIdsTurnos.join("&"), 
    listaIdsOperarios.join("&"), "", listaIdsSecciones.join("&"), listaIdsMaquinas.join("&"), listaIdsGruposMaquinas.join("&")).pipe(first()).subscribe(
      (result: any) => {
        this.maquinas = result.maquinas;
        this.operaciones = result.operaciones;
        this.dataBarras = result.barras;
        this.dataDonut = result.donut;
        this.operarios = result.operarios;
        this.piezas = result.piezas;
        //this.herramientas = result.herramientas;
        if (this.dataDonut[0].ok!== null && this.dataBarras.length!== 0){
          this.dibujarGraficos();
          this.cargarDatosGrafico();
        }
        else{
          this.grafico_donuts?.destroy();
          this.grafico_barras?.destroy();
          this.loadingDatos = false; //No hay gráficos que dibujar si no se han recibido datos, pero estos ya se han cargado
        }
      });
  }

   // Si cambian la fecha de inicio o final en filtro ponerles las horas
   public onChangeFechaIni(value: Date): void {
    if (value == null) this.fechaIni = undefined;
    else this.fechaIni.setHours(0, 0, 0);
  }

  public onChangeFechaFin(value: Date): void {
    if (value == null) this.fechaFin = undefined;
    else this.fechaFin.setHours(0, 0, 0);
  }

  CambioFiltro() {
    var data: any = this.dataFiltro;

    var idsOFs = [];
    if (this.ofsSeleccionados != undefined)
      this.ofsSeleccionados.forEach(of => idsOFs.push(of.idOf));

    var idsClientes = [];
    if (this.clientesSeleccionados != undefined)
      this.clientesSeleccionados.forEach(cliente => idsClientes.push(cliente.idCliente));

    var idPiezas = [];
    if (this.piezasSeleccionadas != undefined)
      this.piezasSeleccionadas.forEach(pieza => idPiezas.push(pieza.nombrePieza));


    var groupByCliente = [];
    var groupByPieza = [];
    var groupByOf = [];

     //FECHA
     var fechaMin: Date;
     var fechaMax: Date;
     data.forEach(
       row => {
         if ((idsOFs.includes(row.idOf))
           || (idsClientes.includes(row.idCliente))
           || (idPiezas.includes(row.nombrePieza))
         ) {
           if (row.fechaIni != null) {
             var fecha: Date = new Date(row.fechaIni.replaceAll('_', '-'));;
             if ((fechaMin == undefined) || fechaMin > fecha)
               fechaMin = new Date(row.fechaIni.replaceAll('_', '-'));
           }
 
 
           if (row.fechafin != null) {
             var fecha: Date = new Date(row.fechaIni.replaceAll('_', '-'));;
             if ((fechaMax == undefined) || fechaMax < fecha)
               fechaMax = new Date(row.fechafin.replaceAll('_', '-'));
           }
         }
       });
     if (fechaMin != undefined)
       this.fechaIni = fechaMin
     if (fechaMax != undefined)
       this.fechaFin = fechaMax

    //GROUP BY OF
    var lag: any = {};
    data.forEach(
      row => {
        if (!lag[row.idOf]
          && (idsClientes.includes(row.idCliente) || idsClientes[0] == undefined)
          && (idPiezas.includes(row.nombrePieza) || idPiezas[0] == undefined)
        ) {
          lag[row.idOf] = { idOf: row.idOf, nombreOf: row.nombreOf };
          groupByOf.push(lag[row.idOf]);
        }
      });

    this.listaOfs = groupByOf.filter(item => (item.idOf != -1));
    this.listaOfs.sort((a, b) => (a.nombreOf > b.nombreOf) ? 1 : ((b.nombreOf > a.nombreOf) ? -1 : 0));

    //GROUP BY CLIENTE
    lag = {};
    data.forEach(
      row => {
        if (!lag[row.idCliente]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idPiezas.includes(row.nombrePieza) || idPiezas[0] == undefined)
        ) {
          lag[row.idCliente] = {
            idCliente: row.idCliente, nombreCliente: row.nombreCliente,
          };
          groupByCliente.push(lag[row.idCliente]);
        }
      });

    this.listaClientes = groupByCliente.filter(item => (item.idCliente != -1));
    this.listaClientes.sort((a, b) => (a.nombreCliente > b.nombreCliente) ? 1 : ((b.nombreCliente > a.nombreCliente) ? -1 : 0));

    //GROUP BY PIEZA
    lag = {};
    data.forEach(
      row => {
        if (!lag[row.nombrePieza]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idsClientes.includes(row.idCliente) || idsClientes[0] == undefined)) {
          lag[row.nombrePieza] = {
            idPieza: row.idPieza, nombrePieza: row.nombrePieza,
          };
          groupByPieza.push(lag[row.nombrePieza]);
        }
      });

    this.listaPiezas = groupByPieza.filter(item => (item.idPieza != -1));
    this.listaPiezas.sort((a, b) => (a.nombrePieza > b.nombrePieza) ? 1 : ((b.nombrePieza > a.nombrePieza) ? -1 : 0));

  }

  seccionChanged() { //Cuando cambian las secciones desde el panel de usuario actualizar la web y actualizar el filtro (solo con las secciones por estar también asociadas al perfil del usuario)
    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
      this.seccionesSeleccionadas.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    } else {
      this.secciones.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    }
    //FLTRO POR GRUPOS
    var idsGruposSelecteds: any = [];
    if (this.JgruposMaquinasSelected) {
      this.JgruposMaquinasSelected.forEach(
        grupo => {
          idsGruposSelecteds.push(grupo.id.toString()); // se pasa a string para hacer la comparacion
        });
    }

    this.listaMaquinasMostradas = this.listaMaquinas.filter(f => {
      var enGrupo = false

      f.idsGrupos.forEach(
        idGrupo => {
          enGrupo = enGrupo || idsGruposSelecteds.includes(idGrupo);
        });

      return ((idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0) && (enGrupo || idsGruposSelecteds.length == 0));
    });
  }

  cargarDatosFiltro(): void { //tomar datos para filtro

    //Primero se asignan las fechas
    this.fechaIni = new Date(this.myFunctions.getDateNow().getTime() - (6 * 24 * 60 * 60 * 1000)); //tomamos la fecha actual y echamos atrás seis días
    this.fechaFin = this.myFunctions.getDateNow()
    this.fechaIni.setHours(0, 0, 0);
    this.fechaFin.setHours(0, 0, 0);

     //TURNOS
     this.listaTurnos = [
      { nombreTurno: this.translateService.instant("manana"), idTurno: 1 },
      { nombreTurno: this.translateService.instant("tarde"), idTurno: 2 },
      { nombreTurno: this.translateService.instant("noche"), idTurno: 3 }
    ];
    this.hayTurnos = true;


    //MAQUINAS
    this.informeProyectosService.Get_Maquinas().subscribe(
      (data: any) => {
        //los grupos se pasan en una lista de strings separados por ",", de esta forma convertimos este string en una lista de JS manejable.
        var mostra: any = [];
        data.forEach(
          row => {
            row["idsGrupos"] = row.idGrupos.split(',');
            var an: any = this.userService.secciones;
            var listaIdsecciones = (an.filter(f => { return f.activo; }) === undefined) ? [] : an.filter(f => { return f.activo; }).map(a => a.id);
            if (listaIdsecciones.includes(row.idSeccion)) {
              mostra.push(row)
            }
          });
        this.listaMaquinas = mostra;
        this.seccionChanged();
        this.hayMaquinas = true;
        if (this.hayDatosGenerales && this.hayGruposMaquinas && this.hayMaquinas && this.haySecciones) // si tenemos todos los datos del filtro
          this.hayDatosFiltro = true; //ya están cargados los datos del filtro, los botones se pueden habilitar
      });

     //OPERARIOS
     this.informeProyectosService.Get_Operarios().pipe(first()).subscribe((data: any) => {
      this.listaOperarios = data;
      this.listaOperarios.sort((a, b) => (a.nombreOperario > b.nombreOperario) ? 1 : ((b.nombreOperario > a.nombreOperario) ? -1 : 0));
      this.hayOperarios = true;
    });

    //se toman los datos de los clientes, piezas, ofs y operaciones
    this.informeProyectosService.Get_ClientesPiezasOfsOperacionesPartes().pipe(first()).subscribe((data: any) => {

      this.dataFiltro = data;

      var groupByCliente = [];
      var groupByPieza = [];
      var groupByOf = [];

      //GROUP BY CLIENTE
      data.forEach(function (a) {
        if (!this[a.idCliente]) {
          this[a.idCliente] = {
            idCliente: a.idCliente, nombreCliente: a.nombreCliente,
          };
          groupByCliente.push(this[a.idCliente]);
        }
      }, Object.create(null));

      this.listaClientes = groupByCliente.filter(item => (item.idCliente != -1));;
      this.listaClientes.sort((a, b) => (a.nombreCliente > b.nombreCliente) ? 1 : ((b.nombreCliente > a.nombreCliente) ? -1 : 0))

      //GROUP POR PIEZA
      data.forEach(function (a) {
        if (!this[a.nombrePieza]) {
          this[a.nombrePieza] = {
            idPieza: a.idPieza, nombrePieza: a.nombrePieza,
          };
          groupByPieza.push(this[a.nombrePieza]);
        }
      }, Object.create(null));
      
      this.listaPiezas = groupByPieza.filter(item => (item.idPieza != -1));;
      this.listaPiezas.sort((a, b) => (a.nombrePieza > b.nombrePieza) ? 1 : ((b.nombrePieza > a.nombrePieza) ? -1 : 0))

      //GROUP BY OF
      data.forEach(function (a) {
        if (!this[a.idOf]) {
          this[a.idOf] = {
            idOf: a.idOf, nombreOf: a.nombreOf,
          };
          groupByOf.push(this[a.idOf]);
        }
      }, Object.create(null));

      this.listaOfs = groupByOf.filter(item => (item.idOf != -1));
      this.listaOfs.sort((a, b) => (a.nombreOf > b.nombreOf) ? 1 : ((b.nombreOf > a.nombreOf) ? -1 : 0));

      this.hayDatosGenerales = true;

      if (this.hayDatosGenerales && this.hayGruposMaquinas && this.hayMaquinas && this.haySecciones && this.hayTurnos && this.hayOperarios){ // si tenemos todos los datos del filtro
        this.hayDatosFiltro = true; //ya están cargados los datos del filtro, los botones se pueden habilitar
        this.cargarDatosGrids();
      }
    });
  }

  //For grid
  public showTooltip(e: MouseEvent): void { //para mostrar un tooltip con el dato al pasar el ratón por encima cuando el título o contenido no cabe
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  dibujarGraficos() {
    var th = this;

    //grafico_donuts
    this.grafico_donuts = c3.generate({
      data: {
        columns: [],
        type: 'donut',
        onmouseover: function (d) {
          d3.select('#grafico_donuts .c3-chart-arcs-title')
            .append("tspan")
            .attr("font-size", "35")
            .text((d.ratio * 100).toFixed(1) + "%");
          d3.select("#grafico_donuts .c3-chart-arcs-title")
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.id);
        },
        onmouseout: function (d) {
          d3.select('#grafico_donuts .c3-chart-arcs-title').node().innerHTML = "";
        },
      },
      transition: {
        duration: 2000
      },
      legend: {
        position: 'right'
      },
      color: {
        pattern: ['#fd8a1c', '#d33737']
      },
      tooltip: {
        show: false
      },
      bindto: '#grafico_donuts'
    });

    d3.selectAll("#grafico_donuts .c3-chart-arcs path").style("stroke-width", "0px");

    //grafico_barras
    this.grafico_barras = c3.generate({
      data: {
        x: 'x',
        xFormat: '%d/%m/%Y/%H',
        columns: [],
        type: 'bar',
        colors: {
          OK: '#fcce03',
          NoOK: '#f0913e'
        },
        groups: [
          ['OK', 'NoOK']
        ],
        order: null
      },
      axis: {
        x: {
          type: 'timeseries',
          localtime: false,
          tick: {
            format: '%d/%m/%Y'
          }
        },
        y: {
          tick: {
            format: function (d) {
              if (Math.floor(d) != d) {
                return;
              }
              return d;
            }
          },
        }
      },
      tooltip: {
        format: {
          name: function (d) { return th.translateService.instant(d); }
        }
      },
      bindto: '#grafico_barras'
    });    
    
    d3.selectAll("#grafico_barras .c3-chart-arcs path").style("stroke-width", "0px");
  }

  cargarDatosGrafico() {
    //GRAFICOS
    //DONUTS
    if (this.dataDonut !== undefined){
      var datosDonuts = [];
      datosDonuts.push(['OK', this.dataDonut[0].ok]);
      datosDonuts.push(['No OK', this.dataDonut[0].noOK]);
      if(datosDonuts.length !== 0){
        this.grafico_donuts.load({ columns: datosDonuts });
      }
    }
   

    //BARRAS
    if (this.dataBarras !== undefined){
      var datosFechas: any = ['x'];
      var datosOk: any = ['OK'];
      var datosNoOK: any = ['No OK'];

      this.dataBarras.forEach(function (elem) {
        
        var auxYear = elem.fechaTurno.split("T")[0].split("-")[0];
        var auxMonth = elem.fechaTurno.split("T")[0].split("-")[1];
        var auxDay = elem.fechaTurno.split("T")[0].split("-")[2];
        var auxDate = auxDay + "/" + auxMonth + "/" + auxYear;
        datosFechas.push(auxDate + "/10");
        datosOk.push(elem.ok);
        datosNoOK.push(elem.noOK);
      }, this);

      var datosBarras = [datosFechas, datosOk, datosNoOK];

      var max = Math.max(datosOk.length-1, datosNoOK.length-1);
      this.grafico_barras.axis.max(max*10);
      this.grafico_barras.load({ columns: datosBarras });
    }

    this.loadingDatos = false;
  }

  redirectHistoricoPiezas(e: any){

    var fini = (this.fechaIni === undefined) ? "0" : this.myFunctions.dateToYYYY_MM_DD(this.fechaIni);//FORMATO: YYYY_MM_DD
    var ffin = (this.fechaFin === undefined) ? this.myFunctions.dateToYYYY_MM_DD(this.myFunctions.getDateNow()) : this.myFunctions.dateToYYYY_MM_DD(this.fechaFin);//FORMATO: YYYY_MM_DD

    var listaIdTurnos = (this.turnosSeleccionados === undefined || this.turnosSeleccionados.length === 0) ? 0 : this.turnosSeleccionados.map(a => a.idTurno).join('_');
    var listaIdMaquinas = (this.maquinasSeleccionadas === undefined || this.maquinasSeleccionadas.length === 0) ? 0 : this.maquinasSeleccionadas.map(a => a.idMaquina).join('_');
    var listaIdsSecciones = (this.seccionesSeleccionadas === undefined) || this.seccionesSeleccionadas.length === 0 ? 0 : this.seccionesSeleccionadas.map(a => a.id).join('_');
    var listaIdsGruposMaq = (this.JgruposMaquinasSelected === undefined) || this.JgruposMaquinasSelected.length === 0 ? 0 : this.JgruposMaquinasSelected.map(a => a.id).join('_');
    
    //var listaIdHerramientas = (this.herramientasSeleccionadas === undefined || this.herramientasSeleccionadas.length === 0) ? 0 : this.herramientasSeleccionadas.map(a => a.idHerramienta).join('_');
    var listaIdOperarios = (this.operariosSeleccionados === undefined || this.operariosSeleccionados.length === 0) ? 0 : this.operariosSeleccionados.map(a => a.idOperario).join('_');
    var listaIdOfs = (this.ofsSeleccionados === undefined || this.ofsSeleccionados.length === 0) ? 0 : this.ofsSeleccionados.map(a => a.idOf).join('_');
    var listaIdClientes = (this.clientesSeleccionados === undefined || this.clientesSeleccionados.length === 0) ? 0 : this.clientesSeleccionados.map(a => a.idCliente).join('_');
    var listaIdPiezas = (this.piezasSeleccionadas === undefined || this.piezasSeleccionadas.length === 0) ? 0 : this.piezasSeleccionadas.map(a => a.idPieza).join('_');
  
    var enlace = '#/historicoPiezas/' + fini + "/" + ffin + "/" + listaIdTurnos + "/" + listaIdMaquinas + "/0/" + listaIdOperarios + "/" + listaIdOfs + "/" + listaIdClientes + "/" + listaIdPiezas + "/0/0/" + listaIdsSecciones + "/" + listaIdsGruposMaq;
    window.open(enlace, '_blank');

  }

}
