import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

const baseUrl = `${environment.apiUrl}/recetasInduccion`;

@Injectable()
export class RecetasInduccionService {

  constructor(private http: HttpClient) { }

  // HISTORICO RECETAS
  public Get_historico_recetas(id: number): Observable<any> {
    return this.http.post<JSON>(`${baseUrl}/Get_historico_recetas`, { id }, { withCredentials: true });
  }
  public Get_historico_recetas_filtrado(id: number, fechaInicio: Date, fechaFin: Date, filtroCompleto: string): Observable<any> {
    return this.http.post<JSON>(`${baseUrl}/Get_historico_recetas_filtrado`, { id, fechaInicio, fechaFin, filtroCompleto }, { withCredentials: true });
  }
  public Get_recetas(id: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_recetas`, { id }, { withCredentials: true });
  }

  // RECETA ORIGINAL
  public Get_receta_original(id: number): Observable<any> {
    return this.http.post<JSON>(`${baseUrl}/Get_receta_original`, { id }, { withCredentials: true });
  }

}
