import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MyFunctions } from '@app/_helpers';
import { BoxplotService, InformeProyectosService, MenuService, UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { first } from 'rxjs/operators';
import * as d3 from 'd3';
import { nest } from 'd3-collection';
import { style } from '@angular/animations';

@Component({
  selector: 'app-piezas-boxplot',
  templateUrl: './piezas-boxplot.component.html'
})
export class PiezasBoxplotComponent implements OnInit {

  user = this.userService.userValue;

  //Para saber cuando dibujar cosas o cuando cargar círculo cargando
  public loadingDatos: boolean = true;
  public tieneDatos: boolean = true;

  //For filter
  public hayDatosFiltro: boolean = false;

  public listaClientes: any;
  public clientesSeleccionados: any;

  public fechaIni: Date;
  public fechaFin: Date;

  public listaPiezas: any;
  public piezasSeleccionadas: any;

  public listaPartes: any; 
  public partesSeleccionadas: any;

  public listaOfs: any;
  public ofsSeleccionados: any;

  //Datos completos para filtro
  private dataFiltro: any;

  //Datos grid
  public dataInforme: any;
  private dataTabla: any;
  private dataBoxplot: any;

  //PARA AÑADIR PAGGING AL GRID
  public skip = 0;

  //Para gráficas
  // set the dimensions and margins of the graph
  private margin;
  private width;
  private height;
  private svg; //gráficas

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  constructor(private informeProyectosService: InformeProyectosService,
    private userService: UsuariosService,
    public router: Router,
    private menuService: MenuService,
    private translateService: TranslateService,
    private myFunctions: MyFunctions,
    private boxplotService: BoxplotService) { 
    
    this.menuService.titulo = this.translateService.instant('informes-boxplot').toUpperCase();
    this.userService.user.subscribe(x => this.user = x);

  }

  ngOnInit(): void {
    this.cargarDatosFiltro(); //al iniciar la vista se cargan los datos del filtro lo primero, este no habilita sus botones hasta que tiene los datos cargados
  }

  public showGridTooltip(e: MouseEvent): void { //para mostrar un tooltip con el dato al pasar el ratón por encima cuando el título o contenido no cabe
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  // Si cambian la fecha de inicio o final ponerles las horas
  public onChangeFechaIni(value: Date): void {
    if (value == null) this.fechaIni = undefined;
    else this.fechaIni.setHours(0, 0, 0);
  }

  public onChangeFechaFin(value: Date): void {
    if (value == null) this.fechaFin = undefined;
    else this.fechaFin.setHours(0, 0, 0);
  }

  CambioFiltro(): void { //si los datos del filtro cambian actualizar cuáles son los datos seleccionados

    var data: any = this.dataFiltro;

    var idsOFs = [];
    if (this.ofsSeleccionados != undefined)
      this.ofsSeleccionados.forEach(of => idsOFs.push(of.idOf));

    var idsClientes = [];
    if (this.clientesSeleccionados != undefined)
      this.clientesSeleccionados.forEach(cliente => idsClientes.push(cliente.idCliente));

    var idPiezas = [];
    if (this.piezasSeleccionadas != undefined)
      this.piezasSeleccionadas.forEach(pieza => idPiezas.push(pieza.nombrePieza));

    var idPartes = [];
    if (this.partesSeleccionadas != undefined)
      this.partesSeleccionadas.forEach(parte => idPartes.push(parte.nombreParte));

    var groupByCliente = [];
    var groupByPieza = [];
    var groupByOf = [];
    var groupByParte = [];

    //GROUP BY OF
    var lag: any = {};
    data.forEach(
      row => {
        if (!lag[row.idOf]
          && (idsClientes.includes(row.idCliente) || idsClientes[0] == undefined)
          && (idPiezas.includes(row.nombrePieza) || idPiezas[0] == undefined)
          && (idPartes.includes(row.nombreParte) || idPartes[0] == undefined)
        ) {
          lag[row.idOf] = { idOf: row.idOf, nombreOf: row.nombreOf };
          groupByOf.push(lag[row.idOf]);
        }
      });

    this.listaOfs = groupByOf.filter(item => (item.idOf != -1));
    this.listaOfs.sort((a, b) => (a.nombreOf > b.nombreOf) ? 1 : ((b.nombreOf > a.nombreOf) ? -1 : 0));

    //GROUP BY CLIENTE
    lag = {};
    data.forEach(
      row => {
        if (!lag[row.idCliente]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idPiezas.includes(row.nombrePieza) || idPiezas[0] == undefined)
          && (idPartes.includes(row.nombreParte) || idPartes[0] == undefined)
        ) {
          lag[row.idCliente] = {
            idCliente: row.idCliente, nombreCliente: row.nombreCliente,
          };
          groupByCliente.push(lag[row.idCliente]);
        }
      });

    this.listaClientes = groupByCliente.filter(item => (item.idCliente != -1));
    this.listaClientes.sort((a, b) => (a.nombreCliente > b.nombreCliente) ? 1 : ((b.nombreCliente > a.nombreCliente) ? -1 : 0));


    //GROUP BY PIEZA
    lag = {};
    data.forEach(
      row => {
        if (!lag[row.nombrePieza]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idsClientes.includes(row.idCliente) || idsClientes[0] == undefined)
          && (idPartes.includes(row.nombreParte) || idPartes[0] == undefined)) {
          lag[row.nombrePieza] = {
            idPieza: row.idPieza, nombrePieza: row.nombrePieza,
          };
          groupByPieza.push(lag[row.nombrePieza]);
        }
      });

    this.listaPiezas = groupByPieza.filter(item => (item.idPieza != -1));
    this.listaPiezas.sort((a, b) => (a.nombrePieza > b.nombrePieza) ? 1 : ((b.nombrePieza > a.nombrePieza) ? -1 : 0));

    //GROUP BY PARTE
    lag = {};
    data.forEach(
      row => {
        if (!lag[row.nombreParte]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idsClientes.includes(row.idCliente) || idsClientes[0] == undefined)
          && (idPiezas.includes(row.nombrePieza) || idPiezas[0] == undefined)) {
          lag[row.nombreParte] = {
            idParte: row.idParte, nombreParte: row.nombreParte,
          };
          groupByParte.push(lag[row.nombreParte]);
        }
      });

    this.listaPartes = groupByParte.filter(item => (item.idParte != -1 && item.nombreParte !== ""));
    this.listaPartes.sort((a, b) => (a.nombreParte > b.nombreParte) ? 1 : ((b.nombreParte > a.nombreParte) ? -1 : 0));
    
  }

  btnLimpiarFiltro(): void { //si se quiere limpiar el filtro vaciar todo

    this.ofsSeleccionados = undefined;
    this.clientesSeleccionados = undefined;
    this.partesSeleccionadas = undefined;
    this.piezasSeleccionadas = undefined;
    this.CambioFiltro();

  }

  cargarDatosInforme(): void {

    this.loadingDatos = true; //se están cargando los datos, mostrar barra de carga
    var r1, r2: boolean = false;  //aún no se tiene ninguna respuesta

    //Cogemos los datos para la llamada de los seleccionados en el filtro
    var listaNombresClientes = (this.clientesSeleccionados === undefined) ? [] : this.clientesSeleccionados.map(a => a.nombreCliente);
    var listaNombresPiezas = (this.piezasSeleccionadas === undefined) ? [] : this.piezasSeleccionadas.map(a => a.nombrePieza);
    var listaNombresOfs = (this.ofsSeleccionados === undefined) ? [] : this.ofsSeleccionados.map(a => a.nombreOf);
    var listaPartes = (this.partesSeleccionadas === undefined) ? [] : this.partesSeleccionadas.map(a => a.nombreParte);
    var fechaInicio = (this.fechaIni === undefined) ? undefined : this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaIni);
    var fechaFin = (this.fechaFin === undefined) ? undefined : this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin);

    //Tomar datos piezas para tabla
    this.boxplotService.GetInformeTablaPiezas(fechaInicio, fechaFin, listaNombresClientes.join("&"), listaNombresPiezas.join("&"), listaNombresOfs.join("&"), listaPartes.join("&"), "", "", "", "", 1).pipe(first()).subscribe(
      (result: any) => {
        this.dataTabla = result.data;
        r1 = true; // respuesta 1 recibida
        if (r1 && r2){ //si se tienen las dos respuestas procesar los datos
          this.procesarDatos();
        }
      });

      //Tomar datos piezas para boxplots
      this.boxplotService.GetInformePiezasBoxplots(fechaInicio, fechaFin, listaNombresClientes.join("&"), listaNombresPiezas.join("&"), listaNombresOfs.join("&"), listaPartes.join("&"), "", "", "", "", 1).pipe(first()).subscribe(
        (result: any) => {
          this.dataBoxplot= result.data;
          r2 = true; // respuesta 2 recibida
          if (r1 && r2){ //si se tienen las dos respuestas procesar los datos
            this.procesarDatos();}
        });
  }

  procesarDatos(): void{
    if (this.dataTabla !== undefined && this.dataTabla !== null){ //si tenemos los datos de la tabla, los ordenamos (si no había datos podía devolvernos un null)
      this.dataInforme = this.dataTabla.sort((a, b) => {
        // Create the dates
        var keyA = a.pieza,
          keyB = b.pieza;
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });;
    } 
    if (this.dataBoxplot !== undefined && this.dataBoxplot !== null){ //si tenemos los datos del boxplot, los ordenamos (si no había datos podía devolvernos un null)
      this.dataBoxplot = this.dataBoxplot.sort((a, b) => {
        // Create the dates
        var keyA = a.pieza,
          keyB = b.pieza;
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      }); 
    }

    this.loadingDatos = false; //ya no se están cargando los datos
    //Ponemos el skip a 0 para mandar el grid a la primera pagina
    this.skip = 0;
    if (this.dataInforme !== null && this.dataInforme.length > 0) { //si los datos de la tabla incluyen al menos una pieza
      this.tieneDatos = true; //hay datos para dibujar los boxplots
      //eliminar boxplots anteriores, de existir
      var nodes = document.getElementById('graficoEstimadoReal_BoxplotPiezas').childNodes; 
      for(var i=0; i<nodes.length; i++) {
          if (nodes[i].nodeName.toLowerCase() === 'svg') {
              nodes[i].remove();
          }
      }
      var nodes = document.getElementById('graficoPredictivoReal_BoxplotPiezas').childNodes;
      for(var i=0; i<nodes.length; i++) {
          if (nodes[i].nodeName.toLowerCase() === 'svg') {
              nodes[i].remove();
          }
      }
      //Cargar nuevos boxplots
      this.cargarGraficaEstimadoPredictivoReal(false);
      this.cargarGraficaEstimadoPredictivoReal(true);
    } else { //si los datos de la tabla no incluyen ninguna pieza, seguir cargando los boxplots
      this.tieneDatos = false;
    }
  }

  cargarDatosFiltro(): void { //tomar datos para filtro

    //Primero se asignan las fechas
    this.fechaIni = new Date(this.myFunctions.getDateNow().getTime() - (6 * 24 * 60 * 60 * 1000)); //tomamos la fecha actual y echamos atrás seis días
    this.fechaFin = this.myFunctions.getDateNow()
    this.fechaIni.setHours(0, 0, 0);
    this.fechaFin.setHours(0, 0, 0);

    //se toman los datos
    this.informeProyectosService.Get_ClientesPiezasOfsOperacionesPartes().pipe(first()).subscribe((data: any) => {

      this.dataFiltro = data;

      var groupByCliente = [];
      var groupByPieza = [];
      var groupByOf = [];
      var groupByParte = [];

      //GROUP BY CLIENTE
      data.forEach(function (a) {
        if (!this[a.idCliente]) {
          this[a.idCliente] = {
            idCliente: a.idCliente, nombreCliente: a.nombreCliente,
          };
          groupByCliente.push(this[a.idCliente]);
        }
      }, Object.create(null));

      this.listaClientes = groupByCliente.filter(item => (item.idCliente != -1));;
      this.listaClientes.sort((a, b) => (a.nombreCliente > b.nombreCliente) ? 1 : ((b.nombreCliente > a.nombreCliente) ? -1 : 0))

      //GROUP POR PIEZA
      data.forEach(function (a) {
        if (!this[a.nombrePieza]) {
          this[a.nombrePieza] = {
            idPieza: a.idPieza, nombrePieza: a.nombrePieza,
          };
          groupByPieza.push(this[a.nombrePieza]);
        }
      }, Object.create(null));

      this.listaPiezas = groupByPieza.filter(item => (item.idPieza != -1));;
      this.listaPiezas.sort((a, b) => (a.nombrePieza > b.nombrePieza) ? 1 : ((b.nombrePieza > a.nombrePieza) ? -1 : 0))

      //GROUP POR PARTE
      data.forEach(function (a) {
        if (!this[a.nombreParte]) {
          this[a.nombreParte] = {
            idParte: a.idParte, nombreParte: a.nombreParte,
          };
          groupByParte.push(this[a.nombreParte]);
        }
      }, Object.create(null));

      this.listaPartes = groupByParte.filter(item => (item.idParte != -1 && item.nombreParte !== ""));
      this.listaPartes.sort((a, b) => (a.nombreParte > b.nombreParte) ? 1 : ((b.nombreParte > a.nombreParte) ? -1 : 0));

      //GROUP BY OF
      data.forEach(function (a) {
        if (!this[a.idOf]) {
          this[a.idOf] = {
            idOf: a.idOf, nombreOf: a.nombreOf,
          };
          groupByOf.push(this[a.idOf]);
        }
      }, Object.create(null));

      this.listaOfs = groupByOf.filter(item => (item.idOf != -1));
      this.listaOfs.sort((a, b) => (a.nombreOf > b.nombreOf) ? 1 : ((b.nombreOf > a.nombreOf) ? -1 : 0));

      this.hayDatosFiltro = true; //ya están cargados los datos del filtro, los botones se pueden habilitar

      this.cargarDatosInforme(); //cargar la tabla y boxplots

    });
  }

  cargarGraficaEstimadoPredictivoReal(predictiva: boolean): void{ //función para dibujar las gráficas
    
    //Define variables
    var q1, median, q3, interQuantileRange, min, max;
    // el margin y el height es igual para ambas, pero el width cambiará dependiendo de las piezas que tengamos y hay que asignárselo por separado a cada una
    this.margin = {top: 50, right: 10, bottom: 50, left: 30};
    if (predictiva){
      var auxWidth;
      if (this.dataInforme.length * 100 > 1500){
        auxWidth = 1500;
      } else {
        auxWidth = this.dataInforme.length * 100;
      }
      this.width = auxWidth - this.margin.left - this.margin.right;
    } else {
      var auxWidth;
      if (this.dataInforme.length * 100 > 1500){
        auxWidth = 1500;
      } else {
        auxWidth = this.dataInforme.length * 100;
      }
      this.width = auxWidth - this.margin.left - this.margin.right;
    }
    this.height = 250 - this.margin.top - this.margin.bottom;

    var sumstat;

    if (predictiva){
      // append the svg object to the body of the page
      this.svg = d3.select("#graficoPredictivoReal_BoxplotPiezas")
                   .append("svg")
                   //.attr("width", this.width + this.margin.left + this.margin.right)
                   .attr("height", this.height + this.margin.top + this.margin.bottom)
                   .append("g")
                   .attr("transform","translate(" + this.margin.left + "," + this.margin.top + ")");
      // Compute quartiles, median, inter quantile range min and max --> these info are then used to draw the box.
      sumstat = nest() // nest function allows to group the calculation per level of a factor
                    .key(function(d) { return d.nombrePieza;})
                    .rollup(function(d) {
                      q1 = d3.quantile(d.map(function(g) { return g.porcenDesvioPredictivo;}).sort(d3.ascending),0.25);                    
                      median = d3.quantile(d.map(function(g) { return g.porcenDesvioPredictivo;}).sort(d3.ascending),0.5);
                      q3 = d3.quantile(d.map(function(g) { return g.porcenDesvioPredictivo;}).sort(d3.ascending),0.75);
                      interQuantileRange = q3 - q1;
                      min = q1 - 1.5 * interQuantileRange;
                      max = q3 + 1.5 * interQuantileRange;
                      return({q1: q1, median: median, q3: q3, interQuantileRange: interQuantileRange, min: min, max: max})
                    })
                    .entries(this.dataBoxplot);
    } else {
      // append the svg object to the body of the page
      this.svg = d3.select("#graficoEstimadoReal_BoxplotPiezas")
                   .append("svg")
                   .attr("width", this.width + this.margin.left + this.margin.right)
                   .attr("height", this.height + this.margin.top + this.margin.bottom)
                   .append("g")
                   .attr("transform","translate(" + this.margin.left + "," + this.margin.top + ")");
      // Compute quartiles, median, inter quantile range min and max --> these info are then used to draw the box.
      sumstat = nest() // nest function allows to group the calculation per level of a factor
                    .key(function(d) { return d.nombrePieza;})
                    .rollup(function(d) {
                      q1 = d3.quantile(d.map(function(g) { return g.porcenDesvio;}).sort(d3.ascending),0.25);                    
                      median = d3.quantile(d.map(function(g) { return g.porcenDesvio;}).sort(d3.ascending),0.5);
                      q3 = d3.quantile(d.map(function(g) { return g.porcenDesvio;}).sort(d3.ascending),0.75);
                      interQuantileRange = q3 - q1;
                      min = q1 - 1.5 * interQuantileRange;
                      max = q3 + 1.5 * interQuantileRange;
                      return({q1: q1, median: median, q3: q3, interQuantileRange: interQuantileRange, min: min, max: max})
                    })
                    .entries(this.dataBoxplot);
    }
    
    var auxList = []; // create aux variable to get list of pieces
    
    this.dataBoxplot.map(function(d) { if (!auxList.includes(d.nombrePieza)){auxList.push(d.nombrePieza);}});
    auxList.sort((a, b) => { //ordenar la lista con mismo orden que en tabla
      // Create the dates
      var keyA = a.nombrePieza,
        keyB = b.nombrePieza;
      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });

    // Show the X scale
    var x = d3.scaleBand()
              .range([ 0, this.width ])
              .domain(auxList)
              .paddingInner(1)
              .paddingOuter(.5);

    this.svg.append("g")
            .attr("transform", "translate(0," + this.height + ")")
            .call(d3.axisBottom(x));

    //calcular dominio escala y
    var minD, maxD;
    var minimos = sumstat.map(function(d) {return d.value.min;});
    var maximos = sumstat.map(function(d) {return d.value.max;});
    minD = Math.min.apply(null, minimos), maxD = Math.max.apply(null, maximos);
        
    // Show the Y scale
    var y = d3.scaleLinear()
              .domain([minD - 10,maxD + 10])
              .range([this.height, 0]);

    this.svg.append("g").call(d3.axisLeft(y));

    // Show the main vertical line
    this.svg.selectAll("vertLines")
            .data(sumstat)
            .enter()
            .append("line")
            .attr("x1", function(d){return(x(d.key))})
            .attr("x2", function(d){return(x(d.key))})
            .attr("y1", function(d){return(y(d.value.min))})
            .attr("y2", function(d){return(y(d.value.max))})
            .attr("stroke", "grey")
            .attr("class", "boxplotLines")
            .style("width", 50);

    // rectangle for the main box
    var boxWidth = 50;
    
    this.svg.selectAll("boxes")
            .data(sumstat)
            .enter()
            .append("rect")
            .attr("x", function(d){return(x(d.key)-boxWidth/2)})
            .attr("y", function(d){return(y(d.value.q3))})
            .attr("height", function(d){return(y(d.value.q1)-y(d.value.q3))})
            .attr("width", boxWidth )
            .attr("stroke", "white")
            .attr("class", "boxplotLines")
            .style("fill", "#22c4c4");

    // Show the median
    this.svg.selectAll("medianLines")
            .data(sumstat)
            .enter()
            .append("line")
            .attr("x1", function(d){return(x(d.key)-boxWidth/2) })
            .attr("x2", function(d){return(x(d.key)+boxWidth/2) })
            .attr("y1", function(d){return(y(d.value.median))})
            .attr("y2", function(d){return(y(d.value.median))})
            .attr("stroke", "white")
            .attr("class", "boxplotLines")
            .style("width", 40);

    // Show the bottom line
    this.svg.selectAll("horizLines")
            .data(sumstat)
            .enter()
            .append("line")
            .attr("x1", function(d){return(x(d.key) - boxWidth/4) })
            .attr("x2", function(d){return(x(d.key) + boxWidth/4) })
            .attr("y1", function(d){return(y(d.value.min))})
            .attr("y2", function(d){return(y(d.value.min))})
            .attr("stroke", "grey")
            .attr("class", "boxplotLines")
            .style("width", 40);

    // Show the top line
    this.svg.selectAll("horizLines")
            .data(sumstat)
            .enter()
            .append("line")
            .attr("x1", function(d){return(x(d.key) - boxWidth/4) })
            .attr("x2", function(d){return(x(d.key) + boxWidth/4) })
            .attr("y1", function(d){return(y(d.value.max))})
            .attr("y2", function(d){return(y(d.value.max))})
            .attr("stroke", "grey")
            .attr("class", "boxplotLines")
            .style("width", 40);

    // Add individual points with jitter
    var jitterWidth = 50;

    if (predictiva){
      // Define the div for the tooltip
      var div = d3.select("#boxplot-tooltip2")		
                  .style("visibility", "hidden")
                  .style("position", "absolute");
      
      var scope = this;  
      //draw points
      this.svg.selectAll("indPoints")
              .data(this.dataBoxplot)
              .enter()
              .append("circle")
              .attr("cx", function(d){return(x(d.nombrePieza) - jitterWidth/2 + Math.random()*jitterWidth )})
              .attr("cy", function(d){return(y(d.porcenDesvioPredictivo))})
              .attr("r", 4)
              .style("fill", "white")
              .attr("stroke", "black")
              .attr("class", "boxplotLines")
              .on("mouseover", function(evt, d) {	 //on mouse over change tooltip content and add new content of circle
                var coordinates= d3.pointer(evt);
                var x = coordinates[0] + 50;
                var y = coordinates[1] + 50;
                var tooltipContent = '';
                tooltipContent +=`<p><span class="tooltip-title">${scope.translateService.instant("nserie").toUpperCase()}: </span><span class="tooltip-valor">${d.nSerie}</span></p>`;
                tooltipContent +=`<p><span class="tooltip-title">${scope.translateService.instant("fecha").toUpperCase()}: </span><span class="tooltip-valor">${d.fechaIni.split("T")[0]}<br></span></p>`;
                tooltipContent +=`<p><span class="tooltip-title">${scope.translateService.instant("horasPredictivas").toUpperCase()}: </span><span class="tooltip-valor">${d.tiempoPredictivoTotal}<br></span></p>`;
                tooltipContent +=`<p><span class="tooltip-title">${scope.translateService.instant("horasReales").toUpperCase()}: </span><span class="tooltip-valor">${d.tiempoTotal}<br></span></p>`;
                tooltipContent +=`<p><span class="tooltip-title">${scope.translateService.instant("%").toUpperCase()}: </span><span class="tooltip-valor">${d.porcenDesvioPredictivo}<br></span></p>`;
                div.transition()		
                   .duration(200)		
                   .style("visibility", "visible");		
                   div.html(tooltipContent)	
                .style('transform', `translate(${x}px, ${y}px)`);
              })					
              .on("mouseout", function(evt, d) {		//on mouse out hide tooltip
                div.transition()		
                   .duration(500)		
                   .style("visibility", "hidden");	
              })
              .on("click", function(evt, d){ //on mouse click go to historico operaciones
                window.open('#/historicoOperaciones/' + d.idHistorico_piezas + "/" + scope.myFunctions.dateToYYYY_MM_DD(scope.fechaIni) + '/' + scope.myFunctions.dateToYYYY_MM_DD(scope.fechaFin) + '/' + '0' + '/' + '0' + '/' + '0' + '/' + '0' + '/' + '0' + '/' + '0' + '/' + '0' + '/' + '0' + '/' + '0', '_blank');
              });;
    } else {
      // Define the div for the tooltip
      var div = d3.select("#boxplot-tooltip1")		
                  .style("visibility", "hidden")
                  .style("position", "absolute");
            
      var scope = this;        
      this.svg.selectAll("indPoints")
              .data(this.dataBoxplot)
              .enter()
              .append("circle")
              .attr("cx", function(d){return(x(d.nombrePieza) - jitterWidth/2 + Math.random()*jitterWidth )})
              .attr("cy", function(d){return(y(d.porcenDesvio))})
              .attr("r", 4)
              .style("fill", "white")
              .attr("stroke", "black")
              .attr("class", "boxplotLines")
              .on("mouseover", function(evt, d) {	
                var coordinates= d3.pointer(evt);
                var x = coordinates[0] + 50;
                var y = coordinates[1] + 50;
                var tooltipContent = '';
                tooltipContent +=`<p><span class="tooltip-title">${scope.translateService.instant("nserie").toUpperCase()}: </span><span class="tooltip-valor">${d.nSerie}<br></span></p>`;
                tooltipContent +=`<p><span class="tooltip-title">${scope.translateService.instant("fecha").toUpperCase()}: </span><span class="tooltip-valor">${d.fechaIni.split("T")[0]}<br></span></p>`;
                tooltipContent +=`<p><span class="tooltip-title">${scope.translateService.instant("horasEstimadas").toUpperCase()}: </span><span class="tooltip-valor">${d.tiempoEstimadoTotal}<br></span></p>`;
                tooltipContent +=`<p><span class="tooltip-title">${scope.translateService.instant("horasReales").toUpperCase()}: </span><span class="tooltip-valor">${d.tiempoTotal}<br></span></p>`;
                tooltipContent +=`<p><span class="tooltip-title">${scope.translateService.instant("%").toUpperCase()}: </span><span class="tooltip-valor">${d.porcenDesvio}<br></span></p>`;
                div.transition()		
                   .duration(200)		
                   .style("visibility", "visible");		
                   div.html(tooltipContent)	
                .style('transform', `translate(${x}px, ${y}px)`);
              })					
              .on("mouseout", function(evt, d) {		//on mouse out hide tooltip
                div.transition()		
                   .duration(500)		
                   .style("visibility", "hidden");	
              })
              .on("click", function(evt, d){ //on mouse click go to historico operaciones
                window.open('#/historicoOperaciones/' + d.idHistorico_piezas + "/" + scope.myFunctions.dateToYYYY_MM_DD(scope.fechaIni) + '/' + scope.myFunctions.dateToYYYY_MM_DD(scope.fechaFin) + '/' + '0' + '/' + '0' + '/' + '0' + '/' + '0' + '/' + '0' + '/' + '0' + '/' + '0' + '/' + '0' + '/' + '0', '_blank');
              });
    }

  }

  cellClick(e: any): void{ //si se clica en la row
    if (e.columnIndex !== 11){ //si no es la columna del botón de histórico, ir al informe de operaciones
      var fini = this.myFunctions.dateToYYYY_MM_DD(this.fechaIni); //FORMATO: YYYY_MM_DD
      var ffin = this.myFunctions.dateToYYYY_MM_DD(this.fechaFin); //FORMATO: YYYY_MM_DD

      //coger datos para redirección
      if (!this.user.ocultarParte){
        var filtrado= this.dataFiltro.filter(function(elem) {return elem.nombreOf === e.dataItem.numeroOF && elem.nombreCliente === e.dataItem.nombreCliente 
          && elem.nombrePieza === e.dataItem.nombrePieza && elem.nombreParte === e.dataItem.nombreParte;});
          //redireccionar a infomes operaciones boxplot con los datos seleccionados
          this.router.navigate(['/informesOperacionesBoxplot/' +  fini + '/' + ffin + '/'  + filtrado[0].idOf + '/' + filtrado[0].idPieza + '/' + filtrado[0].idCliente + '/' + 
        filtrado[0].idParte]);
      } else{
        var filtrado= this.dataFiltro.filter(function(elem) {return elem.nombreOf === e.dataItem.numeroOF && elem.nombreCliente === e.dataItem.nombreCliente 
          && elem.nombrePieza === e.dataItem.nombrePieza;});
          //redireccionar a infomes operaciones boxplot con los datos seleccionados
          this.router.navigate(['/informesOperacionesBoxplot/' +  fini + '/' + ffin + '/'  + filtrado[0].idOf + '/' + filtrado[0].idPieza + '/' + filtrado[0].idCliente + '/' + '0']);
      }
      
    } else { //si es la columna del histórico ir al histórico
      // filtro fechas
      var fini = this.myFunctions.dateToYYYY_MM_DD(this.fechaIni); //FORMATO: YYYY_MM_DD
      var ffin = this.myFunctions.dateToYYYY_MM_DD(this.fechaFin); //FORMATO: YYYY_MM_DD

      //coger datos para redirección
      if (!this.user.ocultarParte){
        var filtrado= this.dataFiltro.filter(function(elem) {return elem.nombreOf === e.dataItem.numeroOF && elem.nombreCliente === e.dataItem.nombreCliente 
          && elem.nombrePieza === e.dataItem.nombrePieza && elem.nombreParte === e.dataItem.nombreParte;});
        //redirigir a historico piezas
        window.open('#/historicoPiezas/' + fini + '/' + ffin + '/' + '0' + '/' + '0' + '/' + '0' + '/' + '0' + '/' + filtrado[0].idOf + '/' + filtrado[0].idCliente + '/' + filtrado[0].idPieza + '/' + filtrado[0].idParte + '/' + '0', '_blank');
      } else{
        var filtrado= this.dataFiltro.filter(function(elem) {return elem.nombreOf === e.dataItem.numeroOF && elem.nombreCliente === e.dataItem.nombreCliente 
          && elem.nombrePieza === e.dataItem.nombrePieza;});
        //redirigir a historico piezas
        window.open('#/historicoPiezas/' + fini + '/' + ffin + '/' + '0' + '/' + '0' + '/' + '0' + '/' + '0' + '/' + filtrado[0].idOf + '/' + filtrado[0].idCliente + '/' + filtrado[0].idPieza + '/' + '0' + '/' + '0', '_blank');
      }
      
    }
  }

}
