<form [formGroup]="form" >
  <div class="row">
    <div class="col-md-6">

      <div class="card">
        <div class="card-header">
          <h3><label>{{ 'datos' | translate }}</label></h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body" style="height: 520px;">
          <!--NOMBRE-->
          <div class="form-group">
            <kendo-label text="{{ 'nombre' | translate }}">
              <kendo-textbox formControlName="nombre" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }">
              </kendo-textbox>
              <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                <div *ngIf="f.nombre.errors.required">{{ 'nombrerequerido' |
                  translate}}</div>
              </div>
            </kendo-label>
          </div>

          <!--DESCRIPCIÓN-->
          <div class="form-group">
            <kendo-label text="{{ 'descripcion' | translate }}">
              <textarea kendoTextArea formControlName="descripcion" [autoSize]="true"> </textarea>
            </kendo-label>
          </div>

          <div class="row">

            <!-- <div class="col-md-2"> -->

              <!--ES OPERACIÓN-->
              <!-- <div class="form-group form-check">
                <kendo-label class="form-check-label" text="{{ 'esOperacion' | translate}}">
                  <div class="caja">
                    <kendo-switch id="esOperacion" formControlName="operacion" [onLabel]="' '" [offLabel]="' '" [readonly]="true">
                    </kendo-switch>
                  </div>
                </kendo-label>
              </div> -->

              <!--SEGUIMIENTO-->
              <!-- <div class="form-group form-check">
                <kendo-label class="form-check-label" text="{{ 'seguimiento' | translate}}">
                  <div class="caja">
                    <kendo-switch formControlName="seguimiento" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                  </div>
                </kendo-label>
              </div> -->

              <!--Tiene subtipos-->
              <!-- <div class="form-group form-check"
                *ngIf="(idPadre && idPadre < 0) && (idPadreAux < 0 || idPadreAux == null) ">
                <kendo-label class="form-check-label" text="{{ 'tieneSubtipos' | translate}}">
                  <div class="caja">
                    <kendo-switch formControlName="tieneSubtipos" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                  </div>
                </kendo-label>
              </div> -->

              <!--ES PREPARACIÓN-->
              <!-- <div class="form-group form-check">
                <kendo-label class="form-check-label" text="{{ 'esPreparacion' | translate}}">
                  <div class="caja">
                    <kendo-switch formControlName="esPreparacion" [onLabel]="' '" [offLabel]="' '" [readonly]="true"></kendo-switch>
                  </div>
                </kendo-label>
              </div> -->

            <!-- </div> -->

            <div class="col-md-6">
              <!--OBSERVACION-->
              <div class="form-group">
                <label ID="lAsignarPerdidaA" class="control-label">{{ 'observacion' | translate }}</label>
                <div class="caja">
                  <kendo-dropdownlist [data]="tiposObservacion_DAT" [textField]="'nombre'" [valueField]="'id'"
                    (selectionChange)="selectionChangeObservacion($event)" [value]="selectedItemObservacion"
                    class="form-control">
                  </kendo-dropdownlist>
                </div>
              </div>

              <!--ASIGNAR PERDIDA A-->
              <div class="form-group">
                <label ID="lAsignarPerdidaA" class="control-label">{{ 'asignarPerdidaA' | translate }}</label>
                <div class="caja">
                  <kendo-dropdownlist [data]="tiposPerdidas_DAT" [textField]="'nombre'" [valueField]="'id'"
                    (selectionChange)="selectionChange($event)" [value]="selectedItem" class="form-control">
                  </kendo-dropdownlist>
                </div>
              </div>

              <!--GRAVEDAD-->
              <div class="form-group">
                <label ID="lAsignarPerdidaA" class="control-label">{{ 'gravedad' | translate }}</label>
                <div class="caja">
                  <kendo-dropdownlist [data]="tiposGravedad_DAT" [textField]="'nombre'" [valueField]="'id'"
                    (selectionChange)="selectionChangeGravedad($event)" [value]="selectedItemGravedad"
                    class="form-control">
                  </kendo-dropdownlist>
                </div>
              </div>


            </div>
            <div class="col-md-6">
              <!--TIPO DE PERDIDA-->
              <!-- <div class="form-group">
                <label ID="lAsignarPerdidaA" class="control-label">{{ 'tiposGrupo' | translate }}</label>
                <div class="caja">
                  <kendo-dropdownlist [data]="tiposPerdida" [textField]="'nombre'" [valueField]="'id'"
                    (selectionChange)="selectionChangeTipoPerdida($event)" [value]="selectedItemTipoPerdida"
                    class="form-control">
                  </kendo-dropdownlist>
                </div>
              </div> -->

              <!--DICCIONARIO PERDIDA TIPO-->
              <div class="form-group">
                <label ID="lAsignarPerdidaA" class="control-label">{{ 'tipoPerdidas' | translate }}</label>
                <div class="caja">
                  <kendo-dropdownlist [data]="diccionarioPerdidasTipos" [textField]="'nombre'" [valueField]="'id'"
                    (selectionChange)="selectionChangeTipoDiccionarioPerdida($event)" [value]="selectedTipoDiccionarioPerdida"
                    class="form-control">
                  </kendo-dropdownlist>
                </div>
              </div>
              <!--GRUPO DE PERDIDA-->
              <div class="form-group">
                <label ID="lAsignarPerdidaA" class="control-label">{{ 'perdidasGrupo' | translate }}</label>
                <div class="caja">
                  <kendo-dropdownlist [data]="gruposPerdida" [textField]="'nombre'" [valueField]="'id'"
                    (selectionChange)="selectionChangeGrupoPerdida($event)" [value]="selectedItemGrupo" [disabled]="tienePadre"
                    class="form-control">
                  </kendo-dropdownlist>
                </div>
              </div>

              <!--ATRIBUTOS-->
              <div class="form-group">
                <label ID="lAsignarPerdidaA" class="control-label">{{ 'atributos' | translate }}</label>
                <div class="caja">
                  <kendo-multiselect class="form-control" [data]="atributosList" textField="nombre" valueField="id" [autoClose]="false" [(value)]="atributosSelected" (valueChange)="valueChangeAtributo($event)">
                  </kendo-multiselect>
                </div>
              </div>
            </div>


          </div>


        </div>
      </div>
    </div>
    <div class="col-md-6">

      <div class="card">
        <div class="card-header">
          <h3><label>{{ 'maquinas' | translate }}</label></h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body" style="height: 520px;">
          <div class="form-row">
            <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
              (mouseover)="showTooltip($event)">
              <kendo-grid [data]="maquinas" [rowClass]="rowCallback" [selectable]="{enabled: true}"
                [selectedKeys]="mySelection" kendoGridSelectBy="id" [navigable]="true" [sortable]="true"
                scrollable="virtual" [rowHeight]="36" [height]="500" [pageSize]="50" filterable="menu"
                [resizable]="true" (cellClick)="cellClickHandler($event)">

                <!--botoiak--><!--, checkboxOnly: false, drag: true-->
                <ng-template kendoGridToolbarTemplate position="top">
                  <div class="float-left">
                    <button (click)="checkAllMaquinas()" class="btn btn-success btn-sm mr-1">{{
                      buttonAllMaquinasSelectName }}</button>
                  </div>
                </ng-template>

                <kendo-grid-checkbox-column [headerStyle]="{'text-align': 'center'}" [style]="{'text-align': 'center'}"
                  width="7%">
                  <ng-template let-dataItem="rowIndex">
                    <input type="checkbox" [kendoGridSelectionCheckbox]="dataItem" enabled />
                  </ng-template>
                </kendo-grid-checkbox-column>
                <kendo-grid-column field="nombreAP" title="{{ 'AreaProd' | translate}}" width="15%">
                </kendo-grid-column>
                <kendo-grid-column field="nombreSeccion" title="{{ 'seccion' | translate}}" width="13%">
                </kendo-grid-column>
                <kendo-grid-column field="tipoActivo" title="{{ 'tipoActivo' | translate}}" width="20%">
                </kendo-grid-column>
                <kendo-grid-column field="nombre" title="{{ 'nombre' | translate}}" width="25%">
                </kendo-grid-column>
                <kendo-grid-column field="activo" [style]="{'text-align': 'left'}" width="13%"
                  title="{{ 'activo' | translate}}" filter="boolean">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <input type="checkbox" [checked]="dataItem.activo" disabled />
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-messages filter="{{'filter' | translate}}"
                  filterAfterOperator="{{'filterAfterOperator' | translate}}"
                  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                  filterAndLogic="{{'filterAndLogic' | translate}}"
                  filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                  filterBooleanAll="{{'filterBooleanAll' | translate}}"
                  filterClearButton="{{'filterClearButton' | translate}}"
                  filterContainsOperator="{{'filterContainsOperator' | translate}}"
                  filterDateToday="{{'filterDateToday' | translate}}"
                  filterDateToggle="{{'filterDateToggle' | translate}}"
                  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                  filterEqOperator="{{'filterEqOperator' | translate}}"
                  filterFilterButton="{{'filterFilterButton' | translate}}"
                  filterGtOperator="{{'filterGtOperator' | translate}}"
                  filterGteOperator="{{'filterGteOperator' | translate}}"
                  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
                  filterIsFalse="{{'filterIsFalse' | translate}}"
                  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                  filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
                  filterIsTrue="{{'filterIsTrue' | translate}}" filterLtOperator="{{'filterLtOperator' | translate}}"
                  filterLteOperator="{{'filterLteOperator' | translate}}"
                  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                  filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                  filterOrLogic="{{'filterOrLogic' | translate}}"
                  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}"
                  loading="{{'loading' | translate}}" groupPanelEmpty="{{'groupPanelEmpty' | translate}}"
                  lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
              </kendo-grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card" *ngIf="!tienePadre">
    <div class="card-header">
      <h3><label>{{ 'subtipos' | translate }}</label></h3>
      <div class="plegarpanel"></div>
    </div>
    <div class="card-body">
      <kendo-grid [kendoGridBinding]="hijos" [sortable]="true" [navigable]="true" filterable="menu"
        kendoGridSelectBy="id" [selectedKeys]="seleccionados">
        <!--BOTONES ARRIBA-->
        <ng-template kendoGridToolbarTemplate position="top">
          <button (click)="onClickNuevo(save, $event)" class="btn btn-primary btn-sm mr-1">{{ 'nuevo' |
            translate}}</button>
          <button (click)="onClickEditar()" class="btn btn-success btn-sm mr-1"
            [disabled]="disableEliminar">{{ 'editar' | translate}}</button>
          <button (click)="onClickEliminar(content, $event)"
            [disabled]="disableEliminar || this.user.diccionarioPerdidas<2" class="btn btn-danger btn-sm mr-1">{{
            'eliminar' | translate}}</button>
        </ng-template>
        <!--checkbox-->
        <kendo-grid-checkbox-column width="4%" showSelectAll="true"></kendo-grid-checkbox-column>
        <!--id-->
        <kendo-grid-column width="100%" field="id" hidden="hidden" title="id"></kendo-grid-column>
        <!--nombre-->
        <kendo-grid-column width="40%" field="nombre" title="{{ 'nombre' | translate}}"></kendo-grid-column>
        <!--descripcion-->
        <kendo-grid-column width="40%" field="descripcion" title="{{ 'descripcion' | translate}}"></kendo-grid-column>
        <!--tipo perdida-->
        <kendo-grid-column field="perdida" title="{{ 'perdidaAsignada' | translate}}" width="11%">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{ dataItem.perdida | translate }}</span>
          </ng-template>
        </kendo-grid-column>
        <!--es operacion-->
        <kendo-grid-column field="operacion" title="{{ 'esOperacion' | translate}}" width="8%"
          [style]="{'text-align': 'center'}" filter="boolean">
          <ng-template kendoGridCellTemplate let-dataItem>
            <input type="checkbox" [checked]="dataItem.operacion" disabled />
          </ng-template>
        </kendo-grid-column>
        <!--TRADUCCIÓN TEXTOS DEL GRID-->
        <kendo-grid-messages filter="{{'filter' | translate}}"
          filterAfterOperator="{{'filterAfterOperator' | translate}}"
          filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
          filterAndLogic="{{'filterAndLogic' | translate}}"
          filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
          filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
          filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
          filterContainsOperator="{{'filterContainsOperator' | translate}}"
          filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
          filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
          filterEqOperator="{{'filterEqOperator' | translate}}"
          filterFilterButton="{{'filterFilterButton' | translate}}"
          filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
          filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
          filterIsFalse="{{'filterIsFalse' | translate}}"
          filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
          filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
          filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
          filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
          filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
          filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
          filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
          filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
          filterOrLogic="{{'filterOrLogic' | translate}}"
          filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
          groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}"
          unlock="{{'unlock' | translate}}" noRecords="{{'norecords' | translate}}" pagerItems=""
          pagerOf="/"></kendo-grid-messages>
      </kendo-grid>
    </div>
  </div>
  <!--BOTONES-->
  <div class="form-group">
    <button class="btn btn-primary btn-sm mr-1" (click)="onSubmit()" [disabled]="this.user.diccionarioPerdidas<2">
      {{ 'guardar' | translate}}
    </button>
    <button type="submit" class="btn btn-danger btn-sm mr-1" (click)="atras()">
      {{ 'cancelar' | translate}}
    </button>
  </div>
</form>

<!--POPUP ELIMINAR-->
<ng-template #delete let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'preguntaeliminarpopup' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' | translate
      }}</button>
    <button type="button" class="btn btn-danger" (click)="eliminarRegistro(contentloading)"
      [disabled]="this.user.diccionarioPerdidas<2">{{ 'si' | translate }}</button>
  </div>
</ng-template>

<!--POPUP Guardar-->
<ng-template #save let-modal>
  <div class="modal-header">
    <!-- <h4 class="modal-title" id="modal-basic-title"></h4> -->
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>





  <div class="modal-body">
    <!-- <p>{{ 'preguntaGuardarRegistro' | translate }}</p> -->

    <div class="card-body">
      <kendo-grid [kendoGridBinding]="subtiposAnnadir" [sortable]="true" [navigable]="true" filterable="menu"
        kendoGridSelectBy="id" [selectedKeys]="listaIdsHijos" (cellClick)="cellClick(save2, $event)">
        
        <!--checkbox-->
        <kendo-grid-checkbox-column width="8%" showSelectAll="true"></kendo-grid-checkbox-column>
        <!--id-->
        <kendo-grid-column width="100%" field="id" hidden="hidden" title="id"></kendo-grid-column>
        <!--nombre-->
        <kendo-grid-column width="25%" field="nombre" title="{{ 'nombre' | translate}}"></kendo-grid-column>
        <!--descripcion-->
        <kendo-grid-column width="30%" field="descripcion" title="{{ 'descripcion' | translate}}"></kendo-grid-column>
        <!--tipo perdida-->
        <kendo-grid-column field="perdida" title="{{ 'perdidaAsignada' | translate}}" width="20%">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{ dataItem.perdida | translate }}</span>
          </ng-template>
        </kendo-grid-column>
        <!--es operacion-->
        <kendo-grid-column field="operacion" title="{{ 'esOperacion' | translate}}" width="15%"
          [style]="{'text-align': 'center'}" filter="boolean">
          <ng-template kendoGridCellTemplate let-dataItem>
            <input type="checkbox" [checked]="dataItem.operacion" disabled />
          </ng-template>
        </kendo-grid-column>
        <!--TRADUCCIÓN TEXTOS DEL GRID-->
        <kendo-grid-messages filter="{{'filter' | translate}}"
          filterAfterOperator="{{'filterAfterOperator' | translate}}"
          filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
          filterAndLogic="{{'filterAndLogic' | translate}}"
          filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
          filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
          filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
          filterContainsOperator="{{'filterContainsOperator' | translate}}"
          filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
          filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
          filterEqOperator="{{'filterEqOperator' | translate}}"
          filterFilterButton="{{'filterFilterButton' | translate}}"
          filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
          filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
          filterIsFalse="{{'filterIsFalse' | translate}}"
          filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
          filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
          filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
          filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
          filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
          filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
          filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
          filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
          filterOrLogic="{{'filterOrLogic' | translate}}"
          filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
          groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}"
          unlock="{{'unlock' | translate}}" noRecords="{{'norecords' | translate}}" pagerItems=""
          pagerOf="/"></kendo-grid-messages>
      </kendo-grid>
    </div>



  </div>


  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' | translate
      }}</button>
    <button type="button" class="btn btn-danger" (click)="updateHijos()"
      [disabled]="this.user.diccionarioPerdidas<2">{{ 'si' | translate }}</button>
  </div>
</ng-template>

<!--POPUP editar-->
<!-- <ng-template #save2 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'preguntaGuardarRegistro' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' | translate
      }}</button>
    <button type="button" class="btn btn-danger" (click)="editarRegistro()"
      [disabled]="this.user.diccionarioPerdidas<2">{{ 'si' | translate }}</button>
  </div>
</ng-template> -->

<ng-template #contentloading let-modal>
  <div class="modal-body">
    <p>{{ 'eliminando' | translate }}</p>
    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'eliminando' | translate }}...</span>
    </div>
  </div>
</ng-template>


<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>