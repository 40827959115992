import { Component, Input, ɵgetInjectableDef } from '@angular/core';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { GridDataResult, DataStateChangeEvent, RowArgs } from '@progress/kendo-angular-grid';
import { MenuService } from '@app/_services';
import { ActivatedRoute, Router } from "@angular/router";
import { MantenimientosPredefinidosService } from '@app/_services/mantenimientosPredefinidos.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UsuariosService } from '@app/_services';
import { TipoPerdida_DAT, TipoMantenimiento_DAT } from '@app/_models';
import { FileRestrictions, SelectEvent } from '@progress/kendo-angular-upload';
import { MyFunctions } from '../../_helpers';

@Component({
  selector: 'app-mantenimientospredefinidos-detalle',
  templateUrl: './mantenimientosPredefinidosDetalle.component.html'
})
export class MantenimientosPredefinidosDetalleComponent {
  public idTipoAcceso: number = 0;
  public mantPDF: Array<any>;
  private translate: TranslateService;
  public isLink: boolean = false;
  public isUploaded: boolean = false;
  public id: number;
  public requerido: boolean;
  public tiposPerdidas_DAT: TipoPerdida_DAT[];
  public selectedItem: TipoPerdida_DAT;
  public idTipoMantenimiento_DAT: number;
  public idTipoFechaTiempoMantenimiento_DAT: number;
  public tiposMantenimientos_DAT: TipoMantenimiento_DAT[];
  public selectedMantenimiento: TipoMantenimiento_DAT;
  public tiposFechaTiempoMantenimientos_DAT: TipoMantenimiento_DAT[];
  public selectedFechaTiempoMantenimiento: TipoMantenimiento_DAT;
  public idTipoPerdida_DAT: number;
  public requeridoChecked: boolean;
  public operacion: boolean;
  public maxDias: number;
  public operacionChecked: boolean;
  public value: number;
  public frecuencia: number;
  public dias: any;
  public decimals: number;
  public userImages: Array<any>;


  public tiempo: number;
  public contarDesdeUltimo: boolean;
  public contarEjecucion: boolean;
  public contarParada: boolean;
  public contarPreparacion: boolean;
  public contarMantenimiento: boolean;
  public contarAlarma: boolean;
  public contarApagada: boolean;
  //public mask = '00:00';

  public restrictions: FileRestrictions = {
    allowedExtensions: ['.pdf'],
    maxFileSize: 1048576
  };

  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  closeResult = '';
  form: FormGroup;
  loading = false;
  submitted = false;
  isAddMode: boolean;
  user = this.userService.userValue;
  alertService: any;
  autoCorrect = true;
  public borrarDoc: boolean;

  rutaInvalida = false;

  //lista dias
  listaDias: string[];
  listaMeses: string[];
  listaTurnos: any = [];
  turnosSeleccionados: any = [];
  mes: any;

  constructor(
    private formBuilder: FormBuilder,
    private mantenimientosPredefinidosService: MantenimientosPredefinidosService,
    private userService: UsuariosService,
    private route: ActivatedRoute,
    public router: Router,
    private menuService: MenuService,
    translate: TranslateService,
    public myFunctions: MyFunctions) {

    this.decimals = 0;
    this.translate = translate;
    this.value = 0;

  }

  ngOnInit() {
    this.listaDias = [this.translate.instant('lunes'), this.translate.instant('martes'), this.translate.instant('miercoles'),
    this.translate.instant('jueves'), this.translate.instant('viernes'), this.translate.instant('sabado'), this.translate.instant('domingo')];

    this.listaTurnos = [{id: 1, nombre: this.translate.instant('manana')}, {id: 2, nombre: this.translate.instant('tarde')}, {id: 3, nombre: this.translate.instant('noche')}]

    this.listaMeses = [this.translate.instant('enero'), this.translate.instant('febrero'), this.translate.instant('marzo'),
    this.translate.instant('abril'), this.translate.instant('mayo'), this.translate.instant('junio'), this.translate.instant('julio')
      , this.translate.instant('agosto'), this.translate.instant('septiembre'), this.translate.instant('octubre'), this.translate.instant('noviembre')
      , this.translate.instant('diciembre')];

    this.menuService.titulo = this.translate.instant('mantenimientopredefinido').toUpperCase();

    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    this.borrarDoc = false;

    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      nombre: ['', Validators.required],
      descripcion: ['',],
      link: ['',],
      frecuencia: [1,],
      requerido: [false,],
      operacion: [false,],
      archivo: new FormControl(''),
      pdf: ['',],
      dias: [0,],
      activarLink: [false,],
      hora: [new Date(1950, 0, 1, 0, 0),],
      tiempoEstimado: ['00:00:00', Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],
      idTipoPerdida_DAT: undefined,
      idTipoMantenimiento_DAT: undefined,
      idTipoFechaTiempoMantenimiento_DAT: undefined,
      tiempo: [0,],
      contarDesdeUltimo: [true,],
      contarEjecucion: [true,],
      contarParada: [true,],
      contarPreparacion: [true,],
      contarMantenimiento: [true,],
      contarAlarma: [true,],
      contarApagada: [true,],
      tareamantenimiento: '',
      nombreRuta: ['',],
      esDirectorio: [false,]
    });
    this.turnosSeleccionados = this.listaTurnos[0];

    var r1, r2: boolean = false;
    this.mantenimientosPredefinidosService.GetAll_TiposPerdidas_DAT().pipe(first()).subscribe(
      (result: any) => {

        var an: any = result.data;
        an.forEach(f => {
          f.nombre = this.translate.instant(f.nombre);

        });

        this.tiposPerdidas_DAT = result.data;

        this.form.controls['idTipoPerdida_DAT'].setValue(result.data[0]);
        this.idTipoPerdida_DAT = result.data[0].id;

        r1 = true;
        if (r1 && r2) {
          this.cargarDatos();
        }
      }
    );
    this.mantenimientosPredefinidosService.GetAll_TiposMantenimientos_DAT().pipe(first()).subscribe(
      (result: any) => {

        var an: any = result.data;
        an.forEach(f => {
          f.nombre = this.translate.instant(f.nombre);

        });

        this.tiposMantenimientos_DAT = result.data;

        this.form.controls['idTipoMantenimiento_DAT'].setValue(result.data[0]);
        this.idTipoMantenimiento_DAT = result.data[0].id;

        r2 = true;
        if (r1 && r2) {
          this.cargarDatos();
        }
      }
    );
    this.mantenimientosPredefinidosService.GetAll_TiposFechaTiempoMantenimientos_DAT().pipe(first()).subscribe(
      (result: any) => {

        var an: any = result.data;
        an.forEach(f => {
          f.nombre = this.translate.instant(f.nombre);

        });

        this.tiposFechaTiempoMantenimientos_DAT = result.data;

        this.form.controls['idTipoFechaTiempoMantenimiento_DAT'].setValue(result.data[0]);
        this.idTipoFechaTiempoMantenimiento_DAT = result.data[0].id;

        r2 = true;
        if (r1 && r2) {
          this.cargarDatos();
        }
      }
    );

  }


  cargarDatos() {
    if (this.id > 0) {
      this.mantenimientosPredefinidosService.GetById(this.id).pipe().subscribe((result) => {
        
        this.frecuencia = result.data[0].frecuencia;
        //si la frecuencia es anual, traducir dias en mes y dia
        if (result.data[0].idTipo == 4) {
          this.calcularMesDia(result.data[0].dia);
          this.mes = this.listaMeses[this.mes - 1];
        } else {
          this.dias = this.listaDias[result.data[0].dia - 1];
        }
        this.selectedItem = new TipoPerdida_DAT(result.data[0].idTipoPerdida, "");
        this.idTipoPerdida_DAT = result.data[0].idTipoPerdida;
        this.selectedMantenimiento = new TipoMantenimiento_DAT(result.data[0].idTipo, "");
        this.idTipoMantenimiento_DAT = result.data[0].idTipo;
        console.log(result.data[0].idTipoFechaTiempo);
        this.selectedFechaTiempoMantenimiento = new TipoMantenimiento_DAT(result.data[0].idTipoFechaTiempo == null ? 1 : result.data[0].idTipoFechaTiempo, "");
        // si no esta asignada ningun tipo inicializar como Por fecha
        if (result.data[0].idTipoFechaTiempo == null) {
          this.idTipoFechaTiempoMantenimiento_DAT = 1;
        } else this.idTipoFechaTiempoMantenimiento_DAT = result.data[0].idTipoFechaTiempo;
        this.isLink = result.data[0].isLink;
        switch (result.data[0].idTipo) {

          case 2: this.maxDias = 7; break;
          case 3: this.maxDias = 31; break;
          case 4: this.maxDias = 365; break;
          default: this.maxDias = 0;

        }

        this.tiempo = result.data[0].tiempo / 3600;
        this.contarDesdeUltimo = result.data[0].contarDesdeUltimo;
        this.contarEjecucion = result.data[0].contarEjecucion;
        this.contarParada = result.data[0].contarParada;
        this.contarPreparacion = result.data[0].contarPreparacion;
        this.contarMantenimiento = result.data[0].contarMantenimiento;
        this.contarAlarma = result.data[0].contarAlarma;
        this.contarApagada = result.data[0].contarApagada;
        console.table(result.data[0]);
        this.form = this.formBuilder.group({
          idDb: this.user.idDb,
          nombre: [result.data[0].nombre, Validators.required],
          descripcion: [result.data[0].descripcion,],
          requerido: [result.data[0].requerido,],
          idTipoPerdida_DAT: [result.data[0].idTipoPerdida,],
          idTipoMantenimiento_DAT: [result.data[0].idTipo,],
          idTipoFechaTiempoMantenimiento_DAT: [result.data[0].idTipoFechaTiempo],
          operacion: [result.data[0].operacion,],
          frecuencia: [result.data[0].frecuenciaMantenimiento,],
          link: [result.data[0].link,],
          tareamantenimiento: [result.data[0].textoMantenimiento,],
          archivo: [,],
          pdf: [result.data[0].pdf,],
          activarLink: [result.data[0].isLink,],
          dias: [Number(result.data[0].dia),],
          hora: [new Date(1950, 0, 1, result.data[0].hora.substring(0, 2), result.data[0].hora.substring(3, 5)),],
          tiempoEstimado: [this.myFunctions.secondsTo_HH_MM_SS(result.data[0].tiempoEstimado), Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],
          tiempo: [result.data[0].tiempo / 3600,],
          contarDesdeUltimo: [result.data[0].contarDesdeUltimo,],
          contarEjecucion: [result.data[0].contarEjecucion,],
          contarParada: [result.data[0].contarParada,],
          contarPreparacion: [result.data[0].contarPreparacion,],
          contarMantenimiento: [result.data[0].contarMantenimiento,],
          contarAlarma: [result.data[0].contarAlarma,],
          contarApagada: [result.data[0].contarApagada,],
          nombreRuta: [result.data[0].nombreRuta,],
          esDirectorio: [result.data[0].esDir,]
        });
        this.idTipoAcceso = 0;
        if (result.data[0].isLink) this.idTipoAcceso = 1;
        else if (result.data[0].nombreRuta != '') this.idTipoAcceso = 2;

        if (this.idTipoMantenimiento_DAT == 5) {
          var ids = result.data[0].turnos;
          this.turnosSeleccionados = this.listaTurnos.filter(f => ids == f.id)[0];
        }
      })

    }
  }

  // getTipoAcceso(): number {
  //   if (this.form.value.nombreRuta !== '') return 2
  //   if (this.form.value.esLink) return 1
  //   return 0
  // }
  onMaskValueChange(e) {
    var result, res1, res2 = '';
    var found1, found2 = 0;
    if (e.length == 4) {

      if (parseInt(e[2].concat(e[3])) > 59) {
        res1 = '59';
        found1 = 1;
      }
      result = e[0].concat(e[1].concat(':'));
      if (found1) { result = result.concat('59'.concat(':')); } else { result = result.concat(e[2].concat(e[3].concat(':'))); }
      this.form.controls['tiempo'].setValue(result);
    }
  }

  public onClick(tipoAcceso: number): void {
    this.idTipoAcceso = tipoAcceso;
  }
  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;
    this.form.value.activarLink = this.idTipoAcceso == 1;
    if (this.form.value.nombreRuta.length <= 0 && this.idTipoAcceso == 2) {
      this.rutaInvalida = true;
      return; 
    }else{
      this.rutaInvalida = false;
    }
     if (this.form.invalid) {
      return;
    }

    this.loading = true;
    if (this.isAddMode) {
      this.insert();
    } else {
      this.update();
    }
  }

  private async insert() {
    var filename = "";
    var fecha = this.dateToYYYYMMDDtHHmmSSz(this.form.value.hora);
    var tiempoEstimadoSplit = this.form.value.tiempoEstimado.split(":");
    var tiempoEstimado = Number(tiempoEstimadoSplit[0]) * 3600 + Number(tiempoEstimadoSplit[1]) * 60 + Number(tiempoEstimadoSplit[2]);
    if ((this.form.value.archivo != null) && ((this.form.value.archivo[0] != null) && (this.form.value.archivo[0] != ""))) {
      var archivoBase64: any = await this.toBase64(this.form.value.archivo[0]);
      filename = this.form.value.archivo[0].name;
    } else {
      var archivoBase64: any = "";
    }

    var sTiempo = this.form.value.tiempo * 3600;

    if (this.mes > 1) {
      this.form.value.dias = this.getDaysInMonth(2022, this.mes) + this.dias;
    } else {
      this.form.value.dias = this.dias;
    }

    if (this.idTipoMantenimiento_DAT == 5) {
      this.form.value.turnos = this.turnosSeleccionados.id;
    } else {
      this.form.value.turnos = -1;
    }

    this.mantenimientosPredefinidosService.insert(this.form.value,
      this.idTipoPerdida_DAT, this.idTipoMantenimiento_DAT, this.idTipoFechaTiempoMantenimiento_DAT,
      archivoBase64, filename, this.form.value.activarLink, fecha, sTiempo, tiempoEstimado)
      .subscribe((result) => {
        {
          if (result.error == false) {
            this.router.navigate(['mantenimientospredefinidos']);
          }
          else {
          }
        }
      }
      );
  }

  private async update() {
    var filename = "";
    var fecha = this.dateToYYYYMMDDtHHmmSSz(this.form.value.hora);
    var tiempoEstimadoSplit = this.form.value.tiempoEstimado.split(":");
    var tiempoEstimado = Number(tiempoEstimadoSplit[0]) * 3600 + Number(tiempoEstimadoSplit[1]) * 60 + Number(tiempoEstimadoSplit[2]);
    if ((this.form.value.archivo != null) && ((this.form.value.archivo[0] != null) && (this.form.value.archivo[0] != ""))) {
      var archivoBase64: any = await this.toBase64(this.form.value.archivo[0]);
      filename = this.form.value.archivo[0].name;
    } else {
      var archivoBase64: any = "";
    }
    this.form.value.id = this.id;

    if (typeof this.mes === 'string') {
      this.selectMesChange(this.mes);
    }
    if (typeof this.dias === 'string') {
      this.selectDiaChange(this.dias);
    }
    if (this.mes > 1) {
      this.form.value.dias = this.getDaysInMonth(2022, this.mes) + this.dias;
    } else {
      this.form.value.dias = this.dias;
    }

    if (this.idTipoMantenimiento_DAT == 5) {
      this.form.value.turnos = this.turnosSeleccionados.id;
    } else {
      this.form.value.turnos = -1;
    }

    if (this.idTipoAcceso != 2)
    this.form.value.nombreRuta = '';

    var sTiempo = this.form.value.tiempo * 3600;
    console.log(this.form.value)
    this.mantenimientosPredefinidosService.update(this.form.value,
      this.idTipoPerdida_DAT, this.idTipoMantenimiento_DAT, this.idTipoFechaTiempoMantenimiento_DAT,
      archivoBase64, filename, this.form.value.activarLink, fecha, sTiempo, tiempoEstimado, this.borrarDoc)
      .subscribe((result) => {
        if (result.error == false) {
          this.router.navigate(['mantenimientospredefinidos']);
        }
        else {
        }
      });
  }

  public selectionPerdidaChange(value: any): void {
    this.idTipoPerdida_DAT = value.id
  }
  public selectionMantenimientoChange(value: any): void {
    console.log(value.id);
    this.idTipoMantenimiento_DAT = value.id
    switch (value.id) {

      case 2: this.maxDias = 7; break;
      case 3: this.maxDias = 28; break;
      case 4: this.maxDias = 365; break;
      default: this.maxDias = 0;

    }
  }

  public selectDiaChange(value: any): void {
    switch (value) {
      case this.translate.instant('lunes'):
        this.dias = 1;
        break;
      case this.translate.instant('martes'):
        this.dias = 2;
        break;
      case this.translate.instant('miercoles'):
        this.dias = 3;
        break;
      case this.translate.instant('jueves'):
        this.dias = 4;
        break;
      case this.translate.instant('viernes'):
        this.dias = 5;
        break;
      case this.translate.instant('sabado'):
        this.dias = 6;
        break;
      case this.translate.instant('domingo'):
        this.dias = 7;
        break;
      default:
        this.dias = 0;
        break;
    }
    //this.dias = value.id;
    console.log(value + ":" + this.dias);
  }

  public selectMesChange(value: any): void {
    switch (value) {
      case this.translate.instant('enero'):
        this.mes = 1;
        break;
      case this.translate.instant('febrero'):
        this.mes = 2;
        break;
      case this.translate.instant('marzo'):
        this.mes = 3;
        break;
      case this.translate.instant('abril'):
        this.mes = 4;
        break;
      case this.translate.instant('mayo'):
        this.mes = 5;
        break;
      case this.translate.instant('junio'):
        this.mes = 6;
        break;
      case this.translate.instant('julio'):
        this.mes = 7;
        break;
      case this.translate.instant('agosto'):
        this.mes = 8;
        break;
      case this.translate.instant('septiembre'):
        this.mes = 9;
        break;
      case this.translate.instant('octubre'):
        this.mes = 10;
        break;
      case this.translate.instant('noviembre'):
        this.mes = 11;
        break;
      case this.translate.instant('diciembre'):
        this.mes = 12;
        break;
    }
    console.log("selectMesChange" + value + "resultado" + this.mes)
  }
  public selectDia(value: any): void {
    this.dias = value;
  }

  public selectionFechaTiempoMantenimientoChange(value: any): void {
    this.idTipoFechaTiempoMantenimiento_DAT = value.id;
    console.log(value.id);
  }

  public dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    //2020-10-25T23:00:00Z
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return año + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T' + this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos) + 'Z';
  }
  public addZero(n: number) {
    if (n < 10)
      return '0' + n.toString();
    else
      return n.toString();
  }

  public atras() {
    this.router.navigate(['mantenimientospredefinidos']);
  }
  public onValueChange(value: any): void {
    this.isLink = value;
  }
  public selectEventHandler(e: SelectEvent): void {
    if (this.borrarDoc)
      this.borrarDoc = true;
    this.isUploaded = true;
  }

  public removeEventHandler(e: SelectEvent): void {
    this.isUploaded = false;
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  btnBorrarDoc() {
    this.borrarDoc = !this.borrarDoc;
    this.form.value.pdf = "";
    this.form.controls['pdf'].setValue("");
  }

  calcularMesDia(dia) {
    if (dia <= 31) {
      this.mes = 1
      this.dias = dia;
    } else if (dia <= 59) {
      this.mes = 2;
      this.dias = dia - 31;
    } else if (dia <= 90) {
      this.mes = 3;
      this.dias = dia - 59;
    } else if (dia <= 120) {
      this.mes = 4;
      this.dias = dia - 90;
    } else if (dia <= 151) {
      this.mes = 5;
      this.dias = dia - 120;
    } else if (dia <= 181) {
      this.mes = 6;
      this.dias = dia - 151;
    } else if (dia <= 212) {
      this.mes = 7;
      this.dias = dia - 181;
    } else if (dia <= 243) {
      this.mes = 8;
      this.dias = dia - 212;
    } else if (dia <= 273) {
      this.mes = 9;
      this.dias = dia - 243;
    } else if (dia <= 304) {
      this.mes = 10;
      this.dias = dia - 273;
    } else if (dia <= 334) {
      this.mes = 11;
      this.dias = dia - 304;
    } else if (dia <= 365) {
      this.mes = 12;
      this.dias = dia - 334;
    }
    console.log(this.mes + ":" + this.dias)
  }

  getDaysInMonth(year, month) {
    var days = 0;
    for (let index = 0; index < month - 1; index++) {
      days = new Date(year, index, 0).getDate() + days;
    }
    console.log(days)
    return days;
  }
}


