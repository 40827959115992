import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CentroMecanizadoService, UsuariosService, AlertService, InformeOeeService, MenuService, HistoricoOperacionesService, InformeProyectosService, RecetasInduccionService } from '@app/_services';
import { PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { MyFunctions } from '@app/_helpers';
import { PopupRef, PopupService } from '@progress/kendo-angular-popup';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-historicoOperacionesInduccionComponent-data',
  templateUrl: 'historicoOperacionesInduccion.html'
})

export class HistoricoOperacionesInduccionComponent implements OnInit {
  user = this.userService.userValue;

  //Contenidos tooltips headers
  public contenidoAnalitica: any;
  public contenidoEstado: any;

  public JOperaciones: any;
  public JOperacionesView: any;
  public operacionesSelecteds: any;
  public pageSize = 2000;
  public skip = 0;

  public rutasOperaciones: JSON;
  public rutasOperacionesSelected: any;

  public visibleFinalizar: boolean;
  public visibleValidar: boolean;
  public visibleApartar: boolean;
  public visibleMandarProduccion: boolean;
  public visibleChatarra: boolean;
  public visibleCambiarRuta: boolean;

  public idOF: number;
  public idPieza: number;
  public idParte: number;
  public idRuta: number;
  public infoOF: string;
  public infoCliente: string;
  public infoProyecto: string;
  public infoPlano: string;
  public infoRefPieza: string;
  public infoPieza: string;
  public infonserie: string;
  public infoTiempoRealEjecucion: string;
  public infoTiempoRealPreparacion: string;
  public infoTiempoEstimado: string;
  public infoDesvio: string;
  public infoPorcenDesvio: string;
  public cantidadLote: string;
  public cantidadLoteHechas: string;
  public usaLotes: number;
  public coladaCAF: string;
  public loteCAF: string;
  public idEstado: number;
  public idHo: number = 0;
  public tipo_maquina: number;
  public idMaquina: number;

  public popupDataItem: any;
  private popupRef: PopupRef;
  private sub: Subscription;

  public Jobservacion: any;

  private tipoPopup: number; //1 Validar::: 2 Apartar::: 3 Chatarra

  //FILTRO
  //private dataFiltro: any;

  public fechaIni: Date;
  public fechaFin: Date;

  public loadingPanel: boolean = false;

  public usuarioIdDb: any;
  public usuarioIdDbGamesa: any;
  public usuarioIdDbCAF: any;

  // Induccion
  public cantidadAcumulada = 0;

  @ViewChild('popup') popup: NgbModalRef;
  @ViewChild('popupRutas') popupRutas: NgbModalRef;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  public tituloModal: any;
  constructor(
    private centroMecanizadoService: CentroMecanizadoService,
    private informeProyectosService: InformeProyectosService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UsuariosService,
    private alertService: AlertService,
    private informeOeeService: InformeOeeService,
    private translateService: TranslateService,
    private menuService: MenuService,
    private historicoOperacionesService: HistoricoOperacionesService,
    private modalService: NgbModal,
    public myFunctions: MyFunctions,
    private popupService: PopupService,
    private recetasInduccionService: RecetasInduccionService
  ) {

    this.contenidoAnalitica = this.translateService.instant('accesoDirectoAnalitica');
    this.contenidoEstado = this.translateService.instant('estadoProcesoPiezas');

    this.menuService.titulo = this.translateService.instant('historicoOperaciones').toUpperCase();

    //SI idDb=33 (GAMESA) ENTONCES CAMBIAR DISEÑO 
    this.usuarioIdDb = userService.userValue.idDb;
    this.usuarioIdDbGamesa = 33;
    this.usuarioIdDbCAF = userService.userValue.idDb == 7 ? true : false;

    // this.cargarDatosFiltro();

    //Valores del filtro reducido.
    var fini = this.route.snapshot.params['fechaIni'];
    var ffin = this.route.snapshot.params['fechaFin'];

    this.fechaIni = new Date(fini.replace(/_/g, "-"));
    this.fechaFin = new Date(ffin.replace(/_/g, "-"));
    this.fechaIni.setHours(0, 0, 0);
    this.fechaFin.setHours(0, 0, 0);

    this.cargarDatos();

  }

  ngOnInit() {
  }

  cargarDatos() {
    this.loadingPanel = true;

    var fechaInicio = (this.fechaIni === undefined) ? undefined : this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaIni);
    var fechaFin = (this.fechaFin === undefined) ? undefined : this.myFunctions.dateToYYYYMMDDtHHmmSSz(new Date(this.fechaFin.getTime() + (1000 * 60 * 60 * 24)));

    var r1, r2, r3: boolean = false;
    this.historicoOperacionesService.get(this.route.snapshot.params.idHistorico_piezas,
      fechaInicio, fechaFin,
      '', '', '', '',
      '', '', '', '', '', false).subscribe(
        (json: any) => {
          this.JOperaciones = json.data;
          var dict: any = {};

          if (json.imagenes.length > 0) {
            //Tenemos las imagenes, creamos el diccionario
            json.imagenes.forEach(element => {
              dict[element.imagen] = element.imagenBASE64;
            });
          }
          if (this.JOperaciones.length > 0)
            this.JOperaciones.forEach((element) => {
              element.usuarioIdDb = this.usuarioIdDb;
              element.usuarioIdDbGamesa = this.usuarioIdDbGamesa;
              //Primero procesamos los operarios
              if (element.operarios == "") {
                element.operarios = this.translateService.instant('desconocido');
              }
              if (this.usuarioIdDb != this.usuarioIdDbGamesa) {
                if (element.operarios != null) {
                  var operarios = element.operarios.split(",");
                  var operariosAuxi = [];
                  var operariosAuxi2 = [];
                  operarios.forEach(operario => {
                    var nombre;
                    var apellido;
                    if (operario == this.translateService.instant("desconocido")) {
                      operariosAuxi.push("undefined");
                      operariosAuxi2.push("undefined");
                    } else {
                      if (operario.split(';').length > 0) {
                        nombre = operario.split(';')[0];
                        apellido = operario.split(';')[1];
                        if (apellido != undefined)
                          operariosAuxi2.push(nombre + " " + apellido);
                        else
                          operariosAuxi2.push(nombre);
                        if (nombre != undefined)
                          nombre = nombre.trim().substring(0, 1).toUpperCase();
                        if (apellido != undefined)
                          apellido = apellido.trim().substring(0, 1).toUpperCase();
                        operariosAuxi.push(nombre + apellido);
                      }
                    }
                  });
                  element.operariosAuxi2 = operariosAuxi2.join(";");
                  element.operariosAuxi = operariosAuxi.join(",");
                } else {
                  element.operariosAuxi2 = "";
                  element.operariosAuxi = "";
                }
              }

              //Ahora seguimos con las maquinas
              if (element.maquinas == "") {
                element.maquinas = this.translateService.instant('desconocido');
              }
              if (element.maquinas != null) {
                var maquinas = element.maquinas.split(",");
                var maquinasAuxi = [];
                var maquinasAuxi2 = [];
                maquinas.forEach(maquina => {
                  if (maquina == this.translateService.instant("desconocido")) {
                    maquinasAuxi2.push("undefined");
                    maquinasAuxi.push("undefined");
                  } else {
                    var nombre = maquina.trim().substring(0, 1).toUpperCase();
                    var apellido = maquina.trim().substring(1, 2).toUpperCase();
                    maquinasAuxi2.push(maquina);
                    maquinasAuxi.push(nombre + apellido);
                  }
                });
                element.maquinasAuxi2 = maquinasAuxi2.join(";");
                element.maquinasAuxi = maquinasAuxi.join(",");
                //Ahora hay que corregir las imagenes de las maquinas
                var imagenes = element.maquinasIm.split(';and;');
                var auxiImagenes = "";
                imagenes.forEach(imagen => {
                  auxiImagenes += dict[imagen] + ";and;";
                });
                element.maquinasIm = auxiImagenes;
              } else {
                element.maquinasAuxi2 = "";
                element.maquinasAuxi = "";
                element.maquinasIm = "";
              }

              //Por ultimo, ponemos bien las operaciones
              //Lo hacemos así para seguir el esquema de historico piezas, pero no haría falta
              var opera = element.operacion?.split(',and,');
              element.opera = opera;
              element.hechasTotal = element.cantidadLoteHechas;
              if (element.hechasTotal == null) {
                element.hechasTotal = 1;
              }
              element.total = element.cantidadLote;
              element.porcen = ((Number(element.tiempoRealEjecucion) / (Number(element.tiempoEstimadoPreparacion) + Number(element.tiempoEstimadoEjecucion))) * 100).toFixed(0);
              if (element.porcen > 100) {
                element.porcen = 100;
              }
              if (!Number(element.porcen)) {
                element.porcen = 0;
              }
              //Finalmente nos ocupamos de la fecha
              if (element.fechaini != undefined && element.fechaini != null) {
                element.fechaini = this.myFunctions.sqlToJsDate(element.fechaini.replace('T', ' '));
                if (element.fechaini.getFullYear() == 1900) {
                  element.fechaini = null;
                  element.fechaIniOrden = null;
                }
                else {
                  var fechaAuxi = new Date(element.fechaini);
                  element.fechaIniOrden = this.myFunctions.dateToYYYYMMDDHHmmSS(fechaAuxi) + " " + this.myFunctions.dateWithoutYearShorted(element.fechaini) +
                    this.myFunctions.dateToHHMM(element.fechaini);
                }
              }
              if (element.fechafin != undefined && element.fechafin != null) {
                element.fechafin = this.myFunctions.sqlToJsDate(element.fechafin.replace('T', ' '));
                if (element.fechafin.getFullYear() == 1900) {
                  element.fechafin = null;
                  element.fechaFinOrden = null;
                }
                else {
                  var fechaAuxi = new Date(element.fechafin);
                  element.fechaFinOrden = this.myFunctions.dateToYYYYMMDDHHmmSS(fechaAuxi) + " " + this.myFunctions.dateWithoutYearShorted(element.fechafin) +
                    this.myFunctions.dateToHHMM(element.fechafin);
                }
              }
              if (this.usuarioIdDbCAF && element.usaLotes == 0)
                element.auxiCantidadLote = Number(element.loteCAF);
              else if ((this.usuarioIdDbCAF && element.usaLotes == 1) || (!this.usuarioIdDbCAF))
                element.auxiCantidadLote = Number(element.cantidadLoteHechas).toString() + "/" + Number(element.cantidadLote).toString();
              element.lote = Number(element.loteCAF); // aunque se llame loteCAF es lote normal en el resto de DB
              element.cantidad = Number(element.cantidadLoteHechas).toString() + "/" + Number(element.cantidadLote).toString();
            }, this);
          this.JOperacionesView = this.JOperaciones.slice(this.skip, this.skip + this.pageSize);
          this.operacionesSelecteds = [];

          r1 = true;
          if (r1 && r2 && r3) {

            // Si es inyectora hay que cargar los parametros de la toleracia
            if (this.tipo_maquina == 4) {
              this.cargarParametrosTolerancia();
            }

            // Si es induccion
            if (this.tipo_maquina == 5) {
              this.cargarInfRecetas();
            }

            this.loadingPanel = false;
          }
        }
      );

    this.historicoOperacionesService.get_pieza(this.route.snapshot.params.idHistorico_piezas,
      fechaInicio, fechaFin,
      '', '', '', '',
      '', '', '', '', '', false, this.translateService.instant("desconocido")).subscribe(
        //listaIdTurnos.join(), listaIdMaquinas.join(), listaIdHerramientas.join(), listaIdOperarios.join(),
        //listaIdOfs.join(), listaIdClientes.join(), listaPiezas.join(), listaPartes.join(), listaOperaciones.join(), this.terminados, this.translateService.instant("desconocido")).subscribe(
        (json: any) => {
          if (json != null && Object.keys(json).length > 0) {
            var auxiObjeto = json.filter(x => Number(x.id) == Number(this.route.snapshot.params.idHistorico_piezas))[0]; //con esto nos aseguramos
            this.idOF = auxiObjeto.idOF;
            this.idPieza = auxiObjeto.idPieza;
            this.idParte = auxiObjeto.idParte;
            this.idRuta = auxiObjeto.idRuta;
            this.infoOF = auxiObjeto.refOF;
            this.infoCliente = auxiObjeto.cliente;
            this.infoProyecto = auxiObjeto.proyecto;
            this.infoPlano = auxiObjeto.numeroPlano;
            this.infoRefPieza = auxiObjeto.referencia;
            this.infoPieza = auxiObjeto.pieza;
            this.infonserie = auxiObjeto.numeroSerie;
            this.infoTiempoRealEjecucion = this.myFunctions.secondsTo_HH_MM(auxiObjeto.tiempoRealEjecucion);
            this.infoTiempoRealPreparacion = this.myFunctions.secondsTo_HH_MM(auxiObjeto.tiempoRealPreparacion);
            this.cantidadLote = auxiObjeto.cantidadLote;
            this.cantidadLoteHechas = auxiObjeto.cantidadLoteHechas;
            this.usaLotes = auxiObjeto.usaLotes;
            this.coladaCAF = auxiObjeto.coladaCAF;
            this.loteCAF = auxiObjeto.loteCAF;
            this.infoTiempoEstimado = this.myFunctions.secondsTo_HH_MM(auxiObjeto.tiempoEstimadoTotal);
            this.infoDesvio = this.myFunctions.secondsTo_HH_MM(auxiObjeto.desvio);
            this.infoPorcenDesvio = auxiObjeto.porcenDesvio + ' %';
            this.idEstado = auxiObjeto.estado;
            this.tipo_maquina = auxiObjeto.tipo_maquina;
            this.idMaquina = auxiObjeto.idMaquina;

            this.visibleCambiarRuta = auxiObjeto.tieneRutas
            if (auxiObjeto.estado == 1) {//en proceso
              this.visibleFinalizar = true
              this.visibleValidar = true
              this.visibleApartar = true
              this.visibleMandarProduccion = false
              this.visibleChatarra = true
            }
            if (auxiObjeto.estado == 2) {//finalizada
              this.visibleFinalizar = false
              this.visibleValidar = true
              this.visibleApartar = true
              this.visibleMandarProduccion = true
              this.visibleChatarra = true
            }
            if (auxiObjeto.estado == 3) {//validada
              this.visibleFinalizar = false
              this.visibleValidar = false
              this.visibleApartar = false
              this.visibleMandarProduccion = false
              this.visibleChatarra = false
            }
            if (auxiObjeto.estado == 4) {//apartada
              this.visibleFinalizar = false
              this.visibleValidar = false
              this.visibleApartar = false
              this.visibleMandarProduccion = true
              this.visibleChatarra = true
            }
            if (auxiObjeto.estado == 5) {//chatarra
              this.visibleFinalizar = false
              this.visibleValidar = false
              this.visibleApartar = false
              this.visibleMandarProduccion = false
              this.visibleChatarra = false
            }
          } else {
            this.infoTiempoRealEjecucion = this.myFunctions.secondsTo_HH_MM(0);
            this.infoTiempoRealPreparacion = this.myFunctions.secondsTo_HH_MM(0);
            this.infoTiempoEstimado = this.myFunctions.secondsTo_HH_MM(0);
            this.infoDesvio = this.myFunctions.secondsTo_HH_MM(0);
            this.infoPorcenDesvio = '0 %';
          }

          r2 = true;
          if (r1 && r2 && r3) {

            // Si es inyectora hay que cargar los parametros de la toleracia
            if (this.tipo_maquina == 4) {
              this.cargarParametrosTolerancia();
            }

            // Si es induccion
            if (this.tipo_maquina == 5) {
              this.cargarInfRecetas();
            }
          
            this.loadingPanel = false;
          }

        });

    this.historicoOperacionesService.Get_rutas_operaciones_HP_Pieza(this.route.snapshot.params.idHistorico_piezas).subscribe(
      (json) => {
        var an: any = json;
        var dtOp: any = [];
        var dtRu: any = [];
        var idRuta: number = 0;
        var ruta: string = '';
        var primero: boolean = true;

        an.forEach(
          row => {
            if (primero) {
              idRuta = row.idRuta;
              ruta = row.ruta;
              dtOp = [];
              primero = false;
            } else {
              if (idRuta != row.idRuta) {
                var ru: any = {};
                ru.idRuta = idRuta;
                ru.ruta = ruta;
                ru.dtOperaciones = dtOp;
                dtRu.push(ru);

                idRuta = row.idRuta;
                ruta = row.ruta;
                dtOp = [];
              }
            }
            //EJEMPLO
            var op: any = {};
            op.id = row.id;
            op.idRuta = idRuta;
            op.orden = row.orden;
            op.lado = row.lado;
            op.suborden = row.suborden;
            op.operacion = row.operacion;
            dtOp.push(op);
          }
        )

        if (idRuta > 0) {
          var ru: any = {};
          ru.idRuta = idRuta;
          ru.ruta = ruta;
          ru.dtOperaciones = dtOp;
          dtRu.push(ru);
        }

        this.rutasOperaciones = dtRu;
        this.rutasOperacionesSelected = [];

        r3 = true;
        if (r1 && r2 && r3) {
          // Si es inyectora hay que cargar los parametros de la toleracia
          if (this.tipo_maquina == 4) {
            this.cargarParametrosTolerancia();
          }

          // Si es induccion
          if (this.tipo_maquina == 5) {
            this.cargarInfRecetas();
          }

          this.loadingPanel = false;
        }
      });

  }

  cellClick(e) {
      if (this.operacionesSelecteds[0] != null) {
        // filtro fechas
        var fini = this.myFunctions.dateToYYYY_MM_DD(this.fechaIni); //FORMATO: YYYY_MM_DD
        var ffin = this.myFunctions.dateToYYYY_MM_DD(this.fechaFin); //FORMATO: YYYY_MM_DD

        this.router.navigate(['historicoProcesosInduccion/' + this.route.snapshot.params.idHistorico_piezas + "/" + this.operacionesSelecteds[0] + "/" + fini + '/' + ffin + '/0/0/0/0/0/0/0/0/0']);     
      }
  }

  btnFinalizar() {
    this.historicoOperacionesService.Set_estado(this.route.snapshot.params.idHistorico_piezas, 2, 'a', -1,-1)
    this.visibleFinalizar = false
    this.visibleValidar = true
    this.visibleApartar = true
    this.visibleMandarProduccion = true
    this.visibleChatarra = true
  }
  btnValidar() {
    this.tipoPopup = 1;
    this.tituloModal = this.translateService.instant("validarPieza");
    this.Jobservacion = '';
    this.modalReference = this.modalService.open(this.popup, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }
  btnApartarPieza() {
    this.tipoPopup = 2;
    this.tituloModal = this.translateService.instant("apartarPieza");
    this.Jobservacion = '';
    this.modalReference = this.modalService.open(this.popup, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }
  btnmandarProduccion() {
    this.historicoOperacionesService.Set_estado(this.route.snapshot.params.idHistorico_piezas, 1, 'a', -1,-1)
    this.visibleFinalizar = true
    this.visibleValidar = true
    this.visibleApartar = true
    this.visibleMandarProduccion = false
    this.visibleChatarra = true
  }
  btnAchatarrar() {
    this.tipoPopup = 3;
    this.tituloModal = this.translateService.instant("achatarrarPieza");
    this.Jobservacion = '';
    this.modalReference = this.modalService.open(this.popup, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }
  btnPopupAceptar() {
    if (this.tipoPopup == 1) {
      this.historicoOperacionesService.Set_estado(this.route.snapshot.params.idHistorico_piezas, 3, this.Jobservacion, -1,-1)
      this.visibleFinalizar = false
      this.visibleValidar = false
      this.visibleApartar = false
      this.visibleMandarProduccion = false
      this.visibleChatarra = false
    } else if (this.tipoPopup == 2) {
      this.historicoOperacionesService.Set_estado(this.route.snapshot.params.idHistorico_piezas, 4, this.Jobservacion, -1,-1)
      this.visibleFinalizar = false
      this.visibleValidar = false
      this.visibleApartar = false
      this.visibleMandarProduccion = true
      this.visibleChatarra = true
    } else if (this.tipoPopup == 3) {
      this.historicoOperacionesService.Set_estado(this.route.snapshot.params.idHistorico_piezas, 5, this.Jobservacion, -1,-1)
      this.visibleFinalizar = false
      this.visibleValidar = false
      this.visibleApartar = false
      this.visibleMandarProduccion = false
      this.visibleChatarra = false
    }
    this.modalReference.close();
  }

  btnAnaliticaAvanzada() {

    // filtro fechas
    var fini = this.myFunctions.dateToYYYY_MM_DD(this.fechaIni); //FORMATO: YYYY_MM_DD
    var ffin = this.myFunctions.dateToYYYY_MM_DD(this.fechaFin); //FORMATO: YYYY_MM_DD

    window.open('#/analiticaavanzadaejecuciones/' + fini + '/' + ffin + '/0/0/0/0/' + this.route.snapshot.params.idHistorico_piezas + '/0', '_blank');
    //window.open('#/analiticaavanzadaejecuciones/' + fini + '/' + ffin + '/' + clientes + '/' + piezas + '/' + ofs + '/' + terminados + "/" + this.route.snapshot.params.idHistorico_piezas + "/0", '_blank');
  }

  btnCambiarRuta() {
    this.modalReference = this.modalService.open(this.popupRutas, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }
  btnPopupAceptarRuta() {
    if (this.rutasOperacionesSelected.length > 0)
      this.historicoOperacionesService.CambiarRuta(this.route.snapshot.params.idHistorico_piezas, this.rutasOperacionesSelected[0]).subscribe(
        i => {

          var fechaInicio = (this.fechaIni === undefined) ? undefined : this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaIni);
          var fechaFin = (this.fechaFin === undefined) ? undefined : this.myFunctions.dateToYYYYMMDDtHHmmSSz(new Date(this.fechaFin.getTime() + (1000 * 60 * 60 * 24)));
          //var listaIdTurnos = (this.turnosSeleccionados === undefined) ? [] : this.turnosSeleccionados.map(a => a.idTurno);
          //var listaIdMaquinas = (this.maquinasSeleccionadas === undefined) ? [] : this.maquinasSeleccionadas.map(a => a.idMaquina);
          //var listaIdHerramientas = (this.herramientasSeleccionadas === undefined) ? [] : this.herramientasSeleccionadas.map(a => a.idHerramienta);
          //var listaIdOperarios = (this.operariosSeleccionados === undefined) ? [] : this.operariosSeleccionados.map(a => a.idOperario);
          //var listaIdOfs = (this.ofsSeleccionados === undefined) ? [] : this.ofsSeleccionados.map(a => a.idOf);
          //var listaIdClientes = (this.clientesSeleccionados === undefined) ? [] : this.clientesSeleccionados.map(a => a.idCliente);
          //var listaPiezas = (this.piezasSeleccionadas === undefined) ? [] : this.piezasSeleccionadas.map(a => "'" + a.nombrePieza + "'");
          //var listaPartes = (this.partesSeleccionadas === undefined) ? [] : this.partesSeleccionadas.map(a => "'" + a.nombreParte + "'");
          //var listaOperaciones = (this.operacionesSeleccionadas === undefined) ? [] : this.operacionesSeleccionadas.map(a => "'" + a.nombreOperacion + "'");

          this.historicoOperacionesService.get(this.route.snapshot.params.idHistorico_piezas,
            fechaInicio, fechaFin,
            '', '', '', '',
            '', '', '', '', '', '').subscribe(
              //listaIdTurnos.join(), listaIdMaquinas.join(), listaIdHerramientas.join(), listaIdOperarios.join(),
              //listaIdOfs.join(), listaIdClientes.join(), listaPiezas.join(), listaPartes.join(), listaOperaciones.join(), this.terminados).subscribe(
              (json) => {
                this.JOperaciones = json;
                this.JOperacionesView = this.JOperaciones.slice(this.skip, this.skip + this.pageSize);
                this.modalReference.close();
              }
            );
        });
  }

  cellPiezasClick() {
  }
  clickAnalitica(idHo) {
    // filtro fechas
    var fini = this.myFunctions.dateToYYYY_MM_DD(this.fechaIni); //FORMATO: YYYY_MM_DD
    var ffin = this.myFunctions.dateToYYYY_MM_DD(this.fechaFin); //FORMATO: YYYY_MM_DD

    //// filtro generales
    //var idsTurnos = [];
    //if (this.turnosSeleccionados != undefined)
    //  this.turnosSeleccionados.forEach(of => idsTurnos.push(of.idTurno));

    //var idMaquinas = [];
    //if (this.maquinasSeleccionadas != undefined)
    //  this.maquinasSeleccionadas.forEach(of => idMaquinas.push(of.idMaquina));

    //var idsHerramientas = [];
    //if (this.herramientasSeleccionadas != undefined)
    //  this.herramientasSeleccionadas.forEach(of => idsHerramientas.push(of.idHerramienta));

    //var idsOperarios = [];
    //if (this.operariosSeleccionados != undefined)
    //  this.operariosSeleccionados.forEach(operario => idsOperarios.push(operario.idOperario));


    //// filtro ofs...
    //var idsOFs = [];
    //if (this.ofsSeleccionados != undefined)
    //  this.ofsSeleccionados.forEach(of => idsOFs.push(of.idOf));

    //var idsClientes = [];
    //if (this.clientesSeleccionados != undefined)
    //  this.clientesSeleccionados.forEach(cliente => idsClientes.push(cliente.idCliente));

    //var idPiezas = [];
    //if (this.piezasSeleccionadas != undefined)
    //  this.piezasSeleccionadas.forEach(pieza => idPiezas.push(pieza.idPieza));

    //var idPartes = [];
    //if (this.partesSeleccionadas != undefined)
    //  this.partesSeleccionadas.forEach(parte => idPartes.push(parte.idParte));

    //var idOperaciones = [];
    //if (this.operacionesSeleccionadas != undefined)
    //  this.operacionesSeleccionadas.forEach(operacion => idOperaciones.push(operacion.idOperacion));

    //var ofs = this.myFunctions.listToStirng(idsOFs, "_", "0");
    //var clientes = this.myFunctions.listToStirng(idsClientes, "_", "0");
    //var piezas = this.myFunctions.listToStirng(idPiezas, "_", "0");

    //var terminados;
    //if (this.terminados) terminados = 1;
    //else terminados = 0;
    if (idHo != -1) this.idHo = idHo;
    if (idHo == null) this.idHo = 0;
    window.open('#/analiticaavanzadaejecuciones/' + fini + '/' + ffin + '/0/0/0/0/' + this.route.snapshot.params.idHistorico_piezas + "/" + this.idHo, '_blank');
  }

   clickTemplado() {
    window.open('#/informeTemplado/' + this.idHo, '_blank');
  } 


  clickOperacion(idOperacion) {
    if (this.idOF != undefined && this.idPieza != undefined && this.idParte != undefined && this.idRuta != undefined && idOperacion != undefined) {
      window.open('#/operaciones/editar/' + this.idOF + '/' + this.idPieza + '/' + this.idParte + '/' + this.idRuta + '/' + idOperacion + '/1', '_blank');
    }
  }

  pageChange(event: PageChangeEvent) {
    this.skip = event.skip;
    this.loadItems();
  }
  loadItems() {
    this.JOperacionesView = this.JOperaciones.slice(this.skip, this.skip + this.pageSize);
  }


  btnAtras() {
    // filtro fechas
    var fini = this.myFunctions.dateToYYYY_MM_DD(this.fechaIni); //FORMATO: YYYY_MM_DD
    var ffin = this.myFunctions.dateToYYYY_MM_DD(this.fechaFin); //FORMATO: YYYY_MM_DD


    this.router.navigate(['historicoPiezas/']);
    //this.router.navigate(['historicoPiezas/' + fini + '/' + ffin + '/' + turnos + '/' + maquinas + '/' + herramientas + '/' + operarios + '/' + ofs + '/' + clientes + '/' + piezas + '/' + partes + '/' + operaciones]);
  }

  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth && !element.classList.contains('celda-tooltip-externo')) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  //////////////////////GAMESA///////////////////////////

  //ENSEÑAR COLUMNA ROJA SI EL EJECUCION PASA EL ESTIMADO
  public rowCallback(context: RowClassArgs) {
    var registro = context.dataItem;
    if (registro.idHo == null) return { 'k-disabled': true };
    if ((registro.tiempoSoloEjecucion > registro.tiempoEstimadoEjecucion) && registro.usuarioIdDb == registro.usuarioIdDbGamesa) return { rojo: true };
    if ((registro.tiempoRealPreparacion > registro.tiempoEstimadoPreparacion) && registro.usuarioIdDb == registro.usuarioIdDbGamesa) return { rojo: true };
  }

  //BOTON MARCAR COMO ESTANDAR
  public clickMarcarComoEstandar(contentMarcarComoEstandar, contentNoHayOperacionesMarcarComoEstandar) {

    var popupLanzado = false;

    if (this.operacionesSelecteds.length > 0) {
      this.operacionesSelecteds.forEach(function (registro) {
        var historicoOperacionSeleccionado = this.JOperacionesView.find(x => x.idHo === registro);
        if (historicoOperacionSeleccionado.idHo != null && historicoOperacionSeleccionado.idHo > 0 && !popupLanzado) {
          popupLanzado = true;
          this.modalReference = this.modalService.open(contentMarcarComoEstandar, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
        }
      }, this);
    }

    if (!popupLanzado) {
      this.modalReference = this.modalService.open(contentNoHayOperacionesMarcarComoEstandar, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }

  }

  public cambiarTiemposEstimados() {

    var listaIdsdOperaciones = "";
    var listaIdsdOperacionesPredefinidas = "";
    var listaTEstimadosEjecucion = "";
    var listaTEstimadosPreparacion = "";

    this.JOperacionesView.forEach(function (registro) {

      if (registro.idHo != null && registro.idHo > 0 && this.operacionesSelecteds.includes(registro.idHo)) {

        var historicoOperacionSeleccionado = this.JOperacionesView.find(x => x.idHo === registro.idHo);

        listaIdsdOperaciones += historicoOperacionSeleccionado.idOperacion + ",";
        listaIdsdOperacionesPredefinidas += historicoOperacionSeleccionado.idOperacion_Predefinida + ",";
        listaTEstimadosEjecucion += historicoOperacionSeleccionado.tiempoSoloEjecucion + ",";
        listaTEstimadosPreparacion += historicoOperacionSeleccionado.tiempoRealPreparacion + ",";

      }

    }, this);

    if (listaIdsdOperaciones.length > 0 && listaIdsdOperacionesPredefinidas.length > 0 && listaTEstimadosEjecucion.length > 0 && listaTEstimadosPreparacion.length > 0) {
      this.historicoOperacionesService.cambiarEstimadosOperacion(listaIdsdOperaciones.slice(0, -1), listaIdsdOperacionesPredefinidas.slice(0, -1), listaTEstimadosEjecucion.slice(0, -1), listaTEstimadosPreparacion.slice(0, -1)).subscribe(result => {
        var r: any = result;
        if (r.error) {

        } else {
          this.cargarDatos();
        }
      });
    }



    this.modalReference.close();

  }
  ///////////////////////////////////////////////////////

  public onToggle(dataItem: any, anchor: ElementRef, template: TemplateRef<any>): void {
    this.idHo = dataItem.idHo;
    if (this.popupRef && dataItem === this.popupDataItem) {
      this.cleanup();
    } else {
      if (this.popupRef) {
        this.cleanup();
      }
      this.popupDataItem = dataItem;
      this.popupRef = this.popupService.open({
        anchor: anchor,
        content: template,
      });
      this.sub = this.popupRef.popup.instance.anchorViewportLeave.subscribe(() => {
        this.cleanup()
      })
    }
  }
  private cleanup() {
    this.sub.unsubscribe();
    this.popupRef.close();
    this.popupRef = null;
    this.popupDataItem = undefined;
  }

  // INDUCCION
  cargarInfRecetas() {

    var fini = this.route.snapshot.params['fechaIni'];
    var ffin = this.route.snapshot.params['fechaFin'];

    fini = new Date(fini.replace(/_/g, "-"));
    ffin = new Date(ffin.replace(/_/g, "-"));

    this.JOperacionesView.forEach(op => {
      this.recetasInduccionService.Get_recetas(op.idHo).subscribe(
        json => {
          var data: any = json;
          this.cantidadAcumulada = 0;
          data.forEach(row => {
            if (!row.cicloCortado)
              this.cantidadAcumulada += row["piezas"];
          });
          op.cantidad = this.cantidadAcumulada + "/" + op.cantidadLote;
        });
    });
  }

  // INYECTORAS
  cargarParametrosTolerancia() {

    var parametros = [
      "tiempociclo",
      "tiempoinyeccion",
      "tiempopresionremanente",
      "tiempoenfriamiento",
      "tiempodosificacionplastificacion",
      "presioninyeccionmax",
      "presionremanente",
      "contrapresion",
      "ptoconmutacion",
      "paradadosificado",
      "retrocesohusillo",
      "paradamolde",
      "cojin",
      "revolucioneshusillo",
      "fuerzadecierre",
      "temperaturacamaracaliente",
      "temppresionfija",
      "temppresionmovil",
      "temperaturamolde"
    ]

    this.JOperacionesView.forEach(op => {
      
      // obtener la informacion de la tolerancia de cada operacion
      this.historicoOperacionesService.Get_procesos_inyeccion(op.idHo).subscribe(
        (result: any) => {
          var tolerancia = true;
          result.forEach(element => {

            this.cantidadAcumulada += element["nPiezas"];
            // comparar todos los parametros posibles
            parametros.forEach(row => {
              var actual = element[row];
              var estimado = element[row+'real'];
              var toler = element[row+'tolerancia'];
      
              var r1 = estimado + toler >= actual && estimado - toler <= actual;
      
              if (!r1) {
                tolerancia = false;
              }
            });
       
          });

          op.cantidad = this.cantidadAcumulada + "/" + op.cantidadLote;;
          op["tolerancia"] = tolerancia;
        });
   
    });

    console.log("JOperacionesView", this.JOperacionesView);
    
  }

}
