<kendo-grid [kendoGridBinding]="mantenimiento" [selectedKeys]="mantenimiento_seleccionados" [sortable]="true" [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: true, mode: 'multiple'}"
            [navigable]="true" filterable="menu" kendoGridSelectBy="id" (cellClick)="mantenimiento_cellClick($event)">
  <ng-template kendoGridToolbarTemplate position="top">
    <button type="button" (click)="mantenimiento_onClickEditar()" class="btn btn-success btn-sm mr-1">{{ 'editar' | translate}}</button>
    <!-- <button type="button" (click)="mantenimiento_onClickNuevo(mantenimiento_content_nuevo)" class="btn btn-primary btn-sm mr-1">{{ 'nuevoZaharra' | translate}}</button> -->
    <button type="button" (click)="mantenimiento_onClickCrearRegistro()" class="btn btn-primary btn-sm mr-1">{{ 'nuevo' | translate}}</button>
    <button type="button" (click)="mantenimiento_onClickCrearRegistroPredefinido(mantenimiento_content_nuevo)" class="btn btn-primary btn-sm mr-1">{{ 'asignarPredefinido' | translate}}</button>
    <button type="button" (click)="mantenimiento_onClickEliminar(mantenimiento_content)" class="btn btn-danger btn-sm mr-1"
            [disabled]="isDeleting ||  this.user.maquinas<2">
      {{ 'eliminar' | translate}}
    </button>
  </ng-template>
  <!--checkbox-->
  <kendo-grid-checkbox-column width="4%" showSelectAll="true"></kendo-grid-checkbox-column>
  <!--id-->
  <kendo-grid-column width="100%" field="id" hidden="hidden" title="id"></kendo-grid-column>
  <!--nombre-->
  <kendo-grid-column width="35%" field="nombre" title="{{ 'nombre' | translate}}"></kendo-grid-column>
  <kendo-grid-column width="10%" field="tipoFechaTiempo" title="{{ 'tipo' | translate}}">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{ dataItem.tipoFechaTiempo | translate }}</span>
    </ng-template>
  </kendo-grid-column>
  <!--descripcion-->
  <kendo-grid-column width="10%" field="tipo" title="{{ 'frecuencia' | translate}}">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span *ngIf="dataItem.tipoFechaTiempo!='porTiempo'">{{ dataItem.tipo | translate }}</span>
    </ng-template>
  </kendo-grid-column>
  <!--dia-->
  <kendo-grid-column width="10%" field="dia" title="{{ 'dia' | translate}}">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span *ngIf="dataItem.tipoFechaTiempo!='porTiempo' && dataItem.idTipo != 1 && dataItem.idTipo != 2">{{ dataItem.dia | translate }}</span>
      <span *ngIf="dataItem.idTipo == 2 && dataItem.dia == 1">{{ 'lunes' | translate }}</span>
      <span *ngIf="dataItem.idTipo == 2 && dataItem.dia == 2">{{ 'martes' | translate }}</span>
      <span *ngIf="dataItem.idTipo == 2 && dataItem.dia == 3">{{ 'miercoles' | translate }}</span>
      <span *ngIf="dataItem.idTipo == 2 && dataItem.dia == 4">{{ 'jueves' | translate }}</span>
      <span *ngIf="dataItem.idTipo == 2 && dataItem.dia == 5">{{ 'viernes' | translate }}</span>
      <span *ngIf="dataItem.idTipo == 2 && dataItem.dia == 6">{{ 'sabado' | translate }}</span>
      <span *ngIf="dataItem.idTipo == 2 && dataItem.dia == 7">{{ 'domingo' | translate }}</span>
    </ng-template>
  </kendo-grid-column>
  <!--hora-->
  <kendo-grid-column width="10%" field="hora" title="{{ 'hora' | translate}}">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span *ngIf="dataItem.tipoFechaTiempo!='porTiempo'">{{ dataItem.hora | translate }}</span>
    </ng-template>
  </kendo-grid-column>
  <!--frecuencia-->
  <kendo-grid-column width="10%" field="frecuencia" title="{{ 'frecuencia' | translate}}">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span *ngIf="dataItem.tipoFechaTiempo!='porTiempo'">{{ dataItem.frecuencia | translate }}</span>
    </ng-template>
  </kendo-grid-column>
  <!--tiempo-->
  <!--<kendo-grid-column width="10%" title="{{ 'tiempo' | translate}}">
    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
      <ng-container *ngIf="dataItem.tiempo > 0">{{myFunctions.secondsTo_HH_MM(dataItem.tiempo)}}</ng-container>
    </ng-template>
  </kendo-grid-column>-->
  <!--pdf-->
  <kendo-grid-column width="15%" field="pdf" title="{{ 'pdf' | translate}}"></kendo-grid-column>
  <!--link-->
  <kendo-grid-column width="10%" field="link" title="{{ 'link' | translate}}"></kendo-grid-column>
  <!--tipo perdida-->
  <kendo-grid-column field="perdida" title="{{ 'perdidaAsignada' | translate}}" width="11%">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{ dataItem.perdida | translate }}</span>
    </ng-template>
  </kendo-grid-column>
  <!--TRADUCCIÓN TEXTOS DEL GRID-->
  <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
  filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
  filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
  filterContainsOperator="{{'filterContainsOperator' | translate}}"
  filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
  filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
  filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
  filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
  filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
  filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
  groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
</kendo-grid>

<!--POPUP NUEVO: MANTENIMIENTO -->
<ng-template #mantenimiento_content_nuevo let-modal>
  <div class="modal-header">
    <!-- <h4 class="modal-title" id="modal-basic-title">{{ 'textoCrearMantenimiento' | translate }}</h4> -->
    <h3 class="modal-title">
      <label>{{ 'textoCrearMantenimiento' | translate }}</label>
    </h3>

    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <kendo-grid [kendoGridBinding]="popupMantenimientoPredifinido"
                [sortable]="true"
                [navigable]="true"
                filterable="menu"
                kendoGridSelectBy="id"
                [selectedKeys]="popupMantenimientoPredifinido_selectedItems">
      <!--botoiak-->
      <ng-template kendoGridToolbarTemplate position="top">

        <button kendoButton class="btn mr-1  btn-success btn-sm mr-1" (click)="mantenimiento_seleccionarRegistro()" [disabled]="this.user.maquinas<2">{{ 'asignar' | translate }}</button>
        <button kendoButton class="btn mr-1  btn-danger btn-sm mr-1" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>

      </ng-template>
      <!--checkbox-->
      <kendo-grid-checkbox-column width="4%" showSelectAll="true"></kendo-grid-checkbox-column>
      <!--id-->
      <kendo-grid-column width="100%" field="id" hidden="hidden" title="id"></kendo-grid-column>
      <!--nombre-->
      <kendo-grid-column width="35%" field="nombre" title="{{ 'nombre' | translate}}"></kendo-grid-column>
      <kendo-grid-column width="10%" field="tipoFechaTiempo" title="{{ 'tipo' | translate}}">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{ dataItem.tipoFechaTiempo | translate }}</span>
        </ng-template>
      </kendo-grid-column>
      <!--descripcion-->
      <kendo-grid-column width="10%" field="tipo" title="{{ 'frecuencia' | translate}}">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="dataItem.tipoFechaTiempo!='porTiempo'">{{ dataItem.tipo | translate }}</span>
        </ng-template>
      </kendo-grid-column>
      <!--dia-->
      <kendo-grid-column width="10%" field="dia" title="{{ 'dia' | translate}}">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="dataItem.tipoFechaTiempo != 'porTiempo'">{{ dataItem.dia | translate }}</span>
        </ng-template>
      </kendo-grid-column>
      <!--hora-->
      <kendo-grid-column width="10%" field="hora" title="{{ 'hora' | translate}}">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="dataItem.tipoFechaTiempo!='porTiempo'">{{ dataItem.hora | translate }}</span>
        </ng-template>
      </kendo-grid-column>
      <!--frecuencia-->
      <kendo-grid-column width="10%" field="frecuencia" title="{{ 'frecuencia' | translate}}">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="dataItem.tipoFechaTiempo!='porTiempo'">{{ dataItem.frecuencia | translate }}</span>
        </ng-template>
      </kendo-grid-column>
      <!--tiempo-->
      <!--<kendo-grid-column width="10%" title="{{ 'tiempo' | translate}}">
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          <ng-container *ngIf="dataItem.tiempo > 0">{{myFunctions.secondsTo_HH_MM(dataItem.tiempo)}}</ng-container>
        </ng-template>
      </kendo-grid-column>-->
      <!--pdf-->
      <kendo-grid-column width="20%" field="pdf" title="{{ 'pdf' | translate}}"></kendo-grid-column>
      <!--link-->
      <kendo-grid-column width="10%" field="link" title="{{ 'link' | translate}}"></kendo-grid-column>
      <!--tipo perdida-->
      <kendo-grid-column field="perdida" title="{{ 'perdidaAsignada' | translate}}" width="11%">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{ dataItem.perdida | translate }}</span>
        </ng-template>
      </kendo-grid-column>

      <!--TRADUCCIÓN TEXTOS DEL GRID-->
      <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
      filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
      filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
      filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
      filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
      filterContainsOperator="{{'filterContainsOperator' | translate}}"
      filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
      filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
      filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
      filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
      filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
      filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
      filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
      filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
      filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
      filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
      filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
      filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
      filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
      filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
      groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
      noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
    </kendo-grid>
  </div>

</ng-template>

<!--POPUP ELIMINAR-->
<ng-template #mantenimiento_content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'preguntaeliminarpopup' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="mantenimiento_eliminarRegistro(mantenimiento_contentloading)" [disabled]="this.user.maquinas<2">{{ 'si' | translate }}</button>
  </div>
</ng-template>

<ng-template #mantenimiento_contentloading let-modal>
  <div class="modal-body">
    <p>{{ 'eliminando' | translate }}</p>
    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'eliminando' | translate }}...</span>
    </div>
  </div>
</ng-template>
