import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CentroMecanizadoService, MenuService, UsuariosService, MaquinasService, InstalacionesService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { MyFunctions } from '@app/_helpers';
import * as d3 from 'd3';
import * as c3 from 'c3';
import * as moment from 'moment';
import { AppComponent } from '../app.component';

@Component({ templateUrl: 'home_v2.component.html' })

export class Home_v2Component implements OnInit {

  ciclo: number = 0;
  datosPendientes: boolean = true;
  public configVariables: any;

  interval: any;
  interval2: any;
  interval3: any;
  intervalPieza: any;
  bits: number = 0;
  img1: string = "";
  img2: string = "";
  dataMaquinas: any;
  dataMaquinas2: any;
  canales: any;
  ejecuciones: any;
  ejes: any;
  tiempoReal: any;
  tiempoRealExtrusoras: any;
  tiempoRealImpresoras: any;
  tiempoRealInyectoras: any;
  tiempoRealHornos: any;
  tiempoRealHornosTemple: any;

  tiempoPorSemana: any;
  oee: any = 0;
  temperaturasHornoTemple: any;
  user = this.usuariosService.userValue;
  eje1: Boolean;
  eje2: Boolean;
  eje3: Boolean;
  eje4: Boolean;

  primeraVez: Boolean = true;

  maquinas: any;
  marcas: any;
  instalaciones: any;

  /* HORNOS */
  tRestCalidad_HHmmSS: any = 0;
  tEstTotal: any;
  piezasHoraEst: any;
  eurosHoraEst: any;
  /* END HORNOS */

  public tiempoMedioPieza: number = 0;
  public desviacionPieza: number = 0;
  public desviacionLote: number = 0;
  public tiempoEstimadoLoteAux: any;
  public tiempoEstimadoLote: number = 0;
  public signoDesviacionPieza: string = '';
  public signoDesviacionLote: string = '';
  public tiempoAcumuladoAux: any;
  public tiempoAcumulado: number = 0;
  public listaTiempo: any = [];
  public listaTiempo2: any = [];

  public tiempoActualPieza = new Map();
  public tiempoActualLote = new Map();
  public tiempoPreparacion = new Map();
  public tiempoPreparacionPieza = new Map();
  public tiempoEjecucion = new Map();

  public piezaTiempoMedioMap = new Map();
  public piezaDesviacionMap = new Map();
  public loteDesviacionMap = new Map();
  public desviacionLotePreparacionMap = new Map();

  public tiempoEstAux: any;
  public tiempoPredAux: any;
  public piezaEstMap = new Map();
  public piezaPredMap = new Map();
  public loteEstMap = new Map();
  public lotePredMap = new Map();
  public preparacionEstMap = new Map();
  public tiempoTotalMap = new Map();
  public horaServidor: any;

  public piezaPorcentajeMap = new Map();
  public lotePorcentajeMap = new Map();
  public piezaimgMap = new Map();
  public preparacionimgMap = new Map();
  public preparacionPorcentajeMap = new Map();
  public isOFMap = new Map();

  // TIEMPOS NUEVOS PIEZA / LOTE
  public tiempoPiezasSegundos = new Map();
  public tiempoLoteSegundos = new Map();
  public tiempoPrepLoteSegundos = new Map();
  public tiempoPrepPiezaSegundos = new Map();
  public loadingDatosTiempo: any;
  public cambioContador = new Map();
  public cambioIdOperacion = new Map();
  public timeOutTiempos: any;
  public esperarTiempos: any;
  public unicaEjecucionTiempos: boolean = true;
  public maxContadorTerminado = new Map();


  public segundosDeUnDia: number = 86400;

  // INSTALACIONES
  public infInstalaciones: any;
  public iconosDatos: any;
  public infInstalacion: any;
  public r1: boolean = false;
  public r2: boolean = false;
  public instalacionesCargadas: boolean = false;
  public r3: boolean = false;

  // graficos
  public graficoDonutArray: any = [];
  public dataDonutArray: any = [];
  public graficoBarrasArray: any = [];
  public dataBarrasArray: any = [];
  public graficoLineasArray: any = [];
  public dataLineasArray: any = [];
  public graficoPresionArray: any = [];
  public dataPresionArray: any = [];
  public graficoTemperaturaArray: any = [];
  public dataTemperaturaArray: any = [];
  public graficoResumenSemanaArray: any = [];
  public dataResumenSemanaArray: any = [];

  public fechasG: any;
  public valoresG: any;
  public valoresGResumenSemana: any = [["ejecucion", 0],
  ["parada", 0],
  ["microparada", 0],
  ["preparacion", 0],
  ["mantenimiento", 0],
  ["alarma", 0],
  ["apagada", 0]];

  public showLabelResumenSemana: boolean = false;

  // END INSTALACIONES


  // SEMAFORO PARA CONSULTAS LENTAS
  public esperadnoOEE: boolean = false;

  public idGrupoActual: number;

  constructor(private usuariosService: UsuariosService,
    private maquinasService: MaquinasService,
    private menuService: MenuService,
    public router: Router,
    public appComponent: AppComponent,
    private centroMecanizadoService: CentroMecanizadoService,
    private translateService: TranslateService,
    private instalacionesService: InstalacionesService,
    private myFunctions: MyFunctions) {

  }

  ngOnInit(): void {

    this.idGrupoActual = this.appComponent.selectedGrupo.id;

    this.usuariosService.getConfiguracionVariables().subscribe(result => {
      this.configVariables = result[0];
      //console.log(this.configVariables);
      if (result[0].ensennarHomeIsometrico && this.router.url != '/homev2') {
        this.router.navigate(["homeplantaisometrico"]);
      } else if (this.router.url == '/homev2' || this.router.url == '/') {

        if (this.r1 && this.r2 && this.idGrupoActual != this.appComponent.selectedGrupo.id && this.instalacionesCargadas) {
          this.crearGraficos();
          this.idGrupoActual = this.appComponent.selectedGrupo.id;
        }
        this.interval3 = setInterval(() => {
          if (this.r1 && this.r2 && this.idGrupoActual != this.appComponent.selectedGrupo.id && this.instalacionesCargadas) {
            this.crearGraficos();
            this.idGrupoActual = this.appComponent.selectedGrupo.id;
          }
          if (!(this.router.url == '/homev2' || this.router.url == '/')) {
            clearInterval(this.interval3);
          }
          //console.log(" Interval 3 HOME");
        }, 1000);

        this.cargarMaquinasMarcasYGrupos();
        this.menuService.titulo = this.translateService.instant('home').toUpperCase();
        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        });

        // Denboren kalkulu berria

        this.calcularTiempos();

      }
    });

  }

  cargarMaquinasMarcasYGrupos() {

    //Cargar grupos en appcomponent
    this.appComponent.cargarGrupos();

    var r1, r2, r3: boolean = false;

    //MAQUINAS
    var maquinas_model = this.maquinasService.get_maquinas_model();
    if (maquinas_model == false) {
      this.maquinasService.get().subscribe(json => {
        this.maquinasService.set_maquinas_model(json);
        this.maquinas = this.maquinasService.get_maquinas_model();
        r1 = true;
        if (r1 && r2 && r3) this.cargarDatos();
      })
    } else {
      this.maquinas = maquinas_model;
      r1 = true;
      if (r1 && r2 && r3) this.cargarDatos();
    }

    //MARCAS
    var marcas_model = this.maquinasService.get_marcas_model();
    if (marcas_model == false) {
      this.maquinasService.getMarcas().subscribe(json => {
        this.maquinasService.set_marcas_model(json);
        this.marcas = this.maquinasService.get_marcas_model();
        r2 = true;
        if (r1 && r2 && r3) this.cargarDatos();
      })
    } else {
      this.marcas = marcas_model;
      r2 = true;
      if (r1 && r2 && r3) this.cargarDatos();
    }

    //INSTALACIONES
    // var instalaciones_model = this.maquinasService.get_instalaciones_model();
    // if (instalaciones_model == false) {
    this.maquinasService.GetInstalaciones().subscribe(json => {
      this.maquinasService.set_instalaciones_model(json);
      this.instalaciones = this.maquinasService.get_instalaciones_model();

      // informacion de los modulos
      this.instalacionesService.get_instalaciones_modulos().subscribe(
        result => {

          var dataModulos: any = result;
          this.instalaciones.forEach(element => {
            var m1 = dataModulos.filter(f => f.id == element.m1);
            var m2 = dataModulos.filter(f => f.id == element.m2);

            if (m1.length > 0) {
              element['m1Inf'] = { nombre: m1[0].nombre, id_tipoGrafico_DAT: m1[0].id_tipoGrafico_DAT, id_tipoDato_DAT: m1[0].id_tipoDato_DAT, idCampo: m1[0].idCampo };
            } else {
              element['m1Inf'] = { nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1, idCampo: -1 };
            }

            if (m2.length > 0) {
              element['m2Inf'] = { nombre: m2[0].nombre, id_tipoGrafico_DAT: m2[0].id_tipoGrafico_DAT, id_tipoDato_DAT: m2[0].id_tipoDato_DAT, idCampo: m2[0].idCampo };
            } else {
              element['m2Inf'] = { nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1, idCampo: -1 };
            }

          });

          r3 = true;
          if (r1 && r2 && r3) this.cargarDatos();
        });
    })
    // } else {
    //   this.instalaciones = instalaciones_model;

    //   // informacion de los modulos
    //   this.instalacionesService.get_instalaciones_modulos().subscribe(
    //     result => {

    //       var dataModulos: any = result;
    //       this.instalaciones.forEach(element => {
    //         var m1 = dataModulos.filter(f => f.id == element.m1);
    //         var m2 = dataModulos.filter(f => f.id == element.m2);

    //         if (m1.length > 0) {
    //           element['m1Inf'] = { nombre: m1[0].nombre, id_tipoGrafico_DAT: m1[0].id_tipoGrafico_DAT, id_tipoDato_DAT: m1[0].id_tipoDato_DAT, idCampo: m1[0].idCampo };
    //         }
    //         if (m2.length > 0) {
    //           element['m2Inf'] = { nombre: m2[0].nombre, id_tipoGrafico_DAT: m2[0].id_tipoGrafico_DAT, id_tipoDato_DAT: m2[0].id_tipoDato_DAT, idCampo: m2[0].idCampo };
    //         }

    //       });
    //       r3 = true;
    //       if (r1 && r2 && r3) this.cargarDatos();
    //     });
    // }

  }

  cargarDatos() {
    if (this.primeraVez) {
      this.primeraVez = false;
      this.cargarDatosMaquinas();
      this.cargarInstalacionesInformacion();
    }


  }

  cargarDatosMaquinas() {
    this.centroMecanizadoService.get(this.ciclo).subscribe(
      (result) => {
        this.r1 = true;
        this.datosPendientes = false;

        this.dataMaquinas = result.maquinas;
        var dataMaquinas2 = [];

        this.dataMaquinas.forEach((maquina) => {
          this.ejecuciones = result.ejecuciones.filter((element) => (element.idMaquina == maquina.id && element.id == maquina.idHistoricoProcTemp));
          this.canales = result.canales.filter((element) => element.idMaquina == maquina.id);
          this.ejes = result.ejes.filter((element) => element.iDmaquina == maquina.id);
          this.tiempoReal = result.tiempoReal.filter((element) => element.idmaquina == maquina.id);
          this.tiempoRealExtrusoras = result.tiempoRealExtrusoras.filter((element) => element.idMaquina == maquina.id);
          this.tiempoRealImpresoras = result.tiempoRealImpresoras.filter((element) => element.idMaquina == maquina.id);
          this.tiempoRealInyectoras = result.tiempoRealInyectoras.filter((element) => element.idMaquina == maquina.id);
          if (result.tiempoRealHornos != null) this.tiempoRealHornos = result.tiempoRealHornos.filter((element) => element.idMaquina == maquina.id);
          else this.tiempoRealHornos = [];
          if (result.tiempoRealHornosTemple != null) this.tiempoRealHornosTemple = result.tiempoRealHornosTemple.filter((element) => element.idMaquina == maquina.id);
          else this.tiempoRealHornosTemple = [];

          if (this.ejecuciones[0]?.contador != undefined && this.ejecuciones[0]?.hechas != undefined) {
            this.maxContadorTerminado.set(maquina.id, Math.max(this.ejecuciones[0].contador, this.ejecuciones[0].hechas));
          } else {
            this.maxContadorTerminado.set(maquina.id, 0);
          }

          this.tiempoPorSemana = result.tiempoPorSemana.filter((element) => element.idMaquina == maquina.id);

          //this.loadingDatosTiempo.set(maquina.id, true);
          maquina.loading = true;

          if (!maquina.loading && (maquina.ejecuciones[0].contador != this.cambioContador.get(maquina.id) || maquina.ejecuciones[0].idHistorico_operaciones != this.cambioIdOperacion.get(maquina.id))) {
            //console.log("Recarga tiempo maquina: ", maquina.id);
            this.cambioContador.set(maquina.id, maquina.ejecuciones[0].contador);
            this.cambioIdOperacion.set(maquina.id, maquina.ejecuciones[0].idHistorico_operaciones);
            this.calcularTiempos();
          }
          this.tratarDatosMaquina(maquina);
        });

        this.cargarDatosMaquinas_OEE(); // Como la carga de OEE ralentizaba la primera carga, se ha sacado a otra consulta.

        setTimeout((d) => this.refreshData(), 5000); // Despues de cargar los datos por primera vez, llamamos a refresh data (que tiene otro setTimeout()) para que se empiezen a actualizar.
      });
  }
  tratarDatosMaquina(maquina) {

    var dataMaquinas2 = [];

    // this.dataMaquinas.forEach((maquina) => {
    if (this.tiempoPorSemana.length == 0) {
      this.tiempoPorSemana.push({ porcentaje: 0 });
      this.tiempoPorSemana.push({ porcentaje: 0 });
      this.tiempoPorSemana.push({ porcentaje: 0 });
      this.tiempoPorSemana.push({ porcentaje: 0 });
      this.tiempoPorSemana.push({ porcentaje: 0 });
      this.tiempoPorSemana.push({ porcentaje: 0 });
      this.tiempoPorSemana.push({ porcentaje: 0 });
    }
    maquina.tiempoPorSemana = this.tiempoPorSemana;
    maquina.ejecuciones = [];
    if (this.ejecuciones.length > 0) {
      if (this.ejecuciones[0].refOF == undefined || this.ejecuciones[0].refOF == "") {
        this.ejecuciones[0].refOF = this.translateService.instant("noproceso");
        this.ejecuciones[0].realizadas = "";
      } else {
        this.ejecuciones[0].realizadas = this.ejecuciones[0].hechas + "/" + this.ejecuciones[0].cantidad;
      }
      maquina.ejecuciones = this.ejecuciones;
    } else {
      maquina.ejecuciones.push({ procesos_Tipo: "apagada", refof: this.translateService.instant("noproceso"), realizadas: '', eje: 0, pre: 0, mant: 0, alarma: 0, apagado: 0, total: 0 });
    }

    this.CargarCssEstadoMaquina(maquina, dataMaquinas2);
    this.CargarIconosEstadoMaquina(maquina, dataMaquinas2);

    if (this.ejecuciones[0] != null) {
      maquina.operario = this.ejecuciones[0].operario;
      if (maquina.operario == " ") maquina.operario = this.translateService.instant('sinOperario');
    }

    if (this.ejes.length > 0) {
      for (let index = 0; index < this.ejes.length; index++) {
        this.ejes[index].divgrafico = "grafico_" + (index + 1) + "_maquina_" + maquina.id;
        try {
          var rpm = this.tiempoReal[index].rpm;
          this.ejes[index].rpm = this.labelFeed(rpm);
          maquina.potenciometrostr = this.tiempoReal[0].potenciometro;
          maquina.potenciometrostr2 = this.tiempoReal[0].potenciometro2;
          if (this.tiempoReal != undefined && this.tiempoReal[index].rpm != undefined) {
            this.ejes[index].valorGrafico = this.calcularPorcentaje(rpm - this.ejes[index].feedMinimo, 48, this.ejes[index].feedMaximo - this.ejes[index].feedMinimo, 0, true);
          }
        }
        catch (exception) {
        }
      }

      var poten1 = this.tiempoReal[0].potenciometro;
      var poten2 = this.tiempoReal[0].potenciometro2;

      if (poten1 != undefined) {
        maquina.potenciometro = this.calcularPorcentaje(poten1 - this.ejes[0].potenMin, 48, this.ejes[0].potenMax - this.ejes[0].potenMin, 0, true);
      }

      if (poten2 != undefined) {
        maquina.potenciometro2 = this.calcularPorcentaje(poten2 - this.ejes[0].potenMin, 48, this.ejes[0].potenMax - this.ejes[0].potenMin, 0, true);
      }

      for (let index = this.ejes.length - 1; index < 4; index++) {
        try {
          this.ejes.push({
            tempvisible: false,
            valorGrafico: 0,
            potenciometro: 0,
            potenciometro2: 0
          });
        }
        catch (exception) { }
      }
    } else {
      this.ejes = [];
    }

    var maquinaConImagen = this.maquinas.find(x => x.id == maquina.id);
    if (maquinaConImagen != undefined)
      maquina.logo = maquinaConImagen.imagenBase64;
    else
      maquina.logo = "";

    var marcaConImagen = this.marcas.find(x => x.id == maquina.idMarca);
    if (marcaConImagen != undefined)
      maquina.marca = marcaConImagen.imagenBase64;
    else
      maquina.marca = "";

    maquina.ejesActivos = (this.ejes == undefined) ? 0 : this.ejes.length;
    maquina.canales = this.canales;
    maquina.ejes = this.ejes;
    maquina.tiempoReal = this.tiempoReal;

    this.calcularCanales(maquina);
    this.CargarIconosEjes(maquina);

    //SI ES EXTRUSORA CALCULAR TEMPERATURAS
    if (maquina.tipo_maquina == 2) {
      if (this.tiempoRealExtrusoras.length > 0) {

        var temperaturasExtrusora = [];

        //infoControlEspesores
        maquina.controlEsp = this.tiempoRealExtrusoras[0].infoControlEspesores;

        //rendimientoSopladores
        maquina.rendSopladores = this.tiempoRealExtrusoras[0].rendimientoSopladores;

        //tempAnilloEntrada
        var min = this.tiempoRealExtrusoras[0].tempAnilloEntrada_min;
        var max = this.tiempoRealExtrusoras[0].tempAnilloEntrada_max;
        var valor = this.tiempoRealExtrusoras[0].tempAnilloEntrada;
        var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
        temperaturasExtrusora.push({ valor: valor, nombre: this.translateService.instant("tAnill"), valorGrafico: valorGrafico });

        //tempIBCDeEntrada
        var min = this.tiempoRealExtrusoras[0].tempIBCDeEntrada_min;
        var max = this.tiempoRealExtrusoras[0].tempIBCDeEntrada_max;
        var valor = this.tiempoRealExtrusoras[0].tempIBCDeEntrada;
        var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
        temperaturasExtrusora.push({ valor: valor, nombre: this.translateService.instant("tIBC"), valorGrafico: valorGrafico });

        //tempRodilloCalandra
        var min = this.tiempoRealExtrusoras[0].tempRodilloCalandra_min;
        var max = this.tiempoRealExtrusoras[0].tempRodilloCalandra_max;
        var valor = this.tiempoRealExtrusoras[0].tempRodilloCalandra;
        var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
        temperaturasExtrusora.push({ valor: valor, nombre: this.translateService.instant("tRodCal"), valorGrafico: valorGrafico });

        //tempRodilloHalador
        var min = this.tiempoRealExtrusoras[0].tempRodilloHalador_min;
        var max = this.tiempoRealExtrusoras[0].tempRodilloHalador_max;
        var valor = this.tiempoRealExtrusoras[0].tempRodilloHalador;
        var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
        temperaturasExtrusora.push({ valor: valor, nombre: this.translateService.instant("tRodHal"), valorGrafico: valorGrafico });

        maquina.temperaturasExtrusora = temperaturasExtrusora;

      } else {

        maquina.rendSopladores = 0;
        maquina.controlEsp = 0;
        maquina.temperaturasExtrusora = [
          { valor: 0, nombre: this.translateService.instant("tAnill"), valorGrafico: 0 },
          { valor: 0, nombre: this.translateService.instant("tIBC"), valorGrafico: 0 },
          { valor: 0, nombre: this.translateService.instant("tRodCal"), valorGrafico: 0 },
          { valor: 0, nombre: this.translateService.instant("tRodHal"), valorGrafico: 0 },
        ];

      }
    }

    //SI ES IMPRESORA DE PLASTICO CALCULAR PRESIONES
    if (maquina.tipo_maquina == 3) {
      if (this.tiempoRealImpresoras.length > 0) {

        var presionesImpresora = [];

        //speed
        maquina.speed = this.tiempoRealImpresoras[0].speed;

        //secadorTempBCD
        maquina.secTempBCD = this.tiempoRealImpresoras[0].secadorTempBCD;

        //secadorTunelTempBCD
        maquina.secTunelTempBCD = this.tiempoRealImpresoras[0].secadorTunelTempBCD;

        //desbobinadora
        var min = this.tiempoRealImpresoras[0].desbobinadora_min;
        var max = this.tiempoRealImpresoras[0].desbobinadora_max;
        var valor = this.tiempoRealImpresoras[0].desbobinadora;
        var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
        presionesImpresora.push({ valor: valor, nombre: this.translateService.instant("desb"), valorGrafico: valorGrafico });

        //tomaDeAlimentacion
        var min = this.tiempoRealImpresoras[0].tomaDeAlimentacion_min;
        var max = this.tiempoRealImpresoras[0].tomaDeAlimentacion_max;
        var valor = this.tiempoRealImpresoras[0].tomaDeAlimentacion;
        var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
        presionesImpresora.push({ valor: valor, nombre: this.translateService.instant("tAlim"), valorGrafico: valorGrafico });

        //accionadorRodilloEnfriamiento
        var min = this.tiempoRealImpresoras[0].accionadorRodilloEnfriamiento_min;
        var max = this.tiempoRealImpresoras[0].accionadorRodilloEnfriamiento_max;
        var valor = this.tiempoRealImpresoras[0].accionadorRodilloEnfriamiento;
        var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
        presionesImpresora.push({ valor: valor, nombre: this.translateService.instant("rodEnf"), valorGrafico: valorGrafico });

        //bobinadora
        var min = this.tiempoRealImpresoras[0].bobinadora_min;
        var max = this.tiempoRealImpresoras[0].bobinadora_max;
        var valor = this.tiempoRealImpresoras[0].bobinadora;
        var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
        presionesImpresora.push({ valor: valor, nombre: this.translateService.instant("bobi"), valorGrafico: valorGrafico });

        maquina.presionesImpresora = presionesImpresora;

      } else {

        maquina.speed = 0;
        maquina.secTempBCD = 0;
        maquina.secTunelTempBCD = 0;
        maquina.presionesImpresora = [
          { valor: 0, nombre: this.translateService.instant("desb"), valorGrafico: 0 },
          { valor: 0, nombre: this.translateService.instant("tAlim"), valorGrafico: 0 },
          { valor: 0, nombre: this.translateService.instant("rodEnf"), valorGrafico: 0 },
          { valor: 0, nombre: this.translateService.instant("bobi"), valorGrafico: 0 },
        ];

      }
    }

    //SI ES INYECTORA CALCULAR OEE Y CICLO
    if (maquina.tipo_maquina == 4) {

      if (this.tiempoRealInyectoras.length > 0) {

        maquina.ciclo = this.tiempoRealInyectoras[0].nCiclo;
        maquina.nCavidades = this.tiempoRealInyectoras[0].nCavidades;
        maquina.material = this.tiempoRealInyectoras[0].material;
        maquina.refMaterial = this.tiempoRealInyectoras[0].refMaterial;
        maquina.refMolde = this.tiempoRealInyectoras[0].refMolde;

      }

      //OEE Aunque no se le añadan valores, se cargan los datos con 0
      maquina.oee = [
        { valor: 0, nombre: this.translateService.instant("oee"), valorGrafico: 0 },
        { valor: 0, nombre: this.translateService.instant("disp"), valorGrafico: 0 },
        { valor: 0, nombre: this.translateService.instant("rend"), valorGrafico: 0 },
        { valor: 0, nombre: this.translateService.instant("cal"), valorGrafico: 0 },
      ];

    }

    //SI ES HORNO CALCULAR OEE Y RECETA
    if (maquina.tipo_maquina == 5) {

      if (this.tiempoRealHornos.length > 0) {

        maquina.receta = this.tiempoRealHornos[0].nReceta;
        maquina.nCavidades = this.tiempoRealHornos[0].nCavidades;
        maquina.material = this.tiempoRealHornos[0].material;
        maquina.refMaterial = this.tiempoRealHornos[0].refMaterial;
        maquina.refMolde = this.tiempoRealHornos[0].refMolde;

        maquina.eurosHora = this.tiempoRealHornos[0].euros_hora;
        // maquina.porcenEurosHora = this.tiempoRealHornos[0].porcentaje_euros_hora;
        maquina.piezasHora = this.tiempoRealHornos[0].piezas_hora;
        // maquina.porcenPiezasHora = this.tiempoRealHornos[0].porcentaje_piezas_hora;
        maquina.tRestCalidad = this.tiempoRealHornos[0].tRestanteCalidad;
        maquina.temperatura = this.tiempoRealHornos[0].temperatura;
        maquina.kilowatio = this.tiempoRealHornos[0].kilowatio;
        this.tRestCalidad_HHmmSS = this.myFunctions.secondsTo_HH_MM_SS(this.tiempoRealHornos[0].tRestanteCalidad);
        var tEstTotalSec = (maquina.ejecuciones[0].tiempoEstimadoSegundos + maquina.ejecuciones[0].tiempoPreparacionSegundos);
        this.tEstTotal = Math.floor(tEstTotalSec / 3600);
        if (this.tEstTotal == 0) this.piezasHoraEst = 0;
        else this.piezasHoraEst = Math.floor(maquina.ejecuciones[0].cantidad / this.tEstTotal);

        this.eurosHoraEst = Math.round(this.piezasHoraEst * this.tiempoRealHornos[0].coste * 100) / 100;
        maquina.eurosHora = Math.round(maquina.piezasHora * this.tiempoRealHornos[0].coste * 100) / 100;
        maquina.piezasHoraEst = this.piezasHoraEst;
        maquina.eurosHoraEst = this.eurosHoraEst;
        var porcenPieza = (maquina.piezasHora / maquina.piezasHoraEst) * 100
        maquina.porcenPiezasHora = porcenPieza.toFixed(2);
        var porcenEuros = (maquina.eurosHora / this.eurosHoraEst) * 100;
        maquina.porcenEurosHora = porcenEuros.toFixed(2);
      }

      // si no tiene operacion
      if (maquina?.ejecuciones[0]?.idOperacion == -1) {
        maquina.eurosHora = '-';
        maquina.porcenEurosHora = '-';
        maquina.piezasHora = '-';
        maquina.porcenPiezasHora = '-';
        maquina.tRestCalidad = '-';
        maquina.temperatura = '-';
        maquina.kilowatio = '-';
        maquina.piezasHoraEst = '-';
        maquina.eurosHoraEst = '-';
      }

      //OEE Aunque no se le añadan valores, se cargan los datos con 0  
      maquina.oee = [
        { valor: 0, nombre: this.translateService.instant("oee"), valorGrafico: 0 },
        { valor: 0, nombre: this.translateService.instant("disp"), valorGrafico: 0 },
        { valor: 0, nombre: this.translateService.instant("rend"), valorGrafico: 0 },
        { valor: 0, nombre: this.translateService.instant("cal"), valorGrafico: 0 },
      ];
    }

    //si es horno temple. calcular temperaturas
    if (maquina.tipo_maquina == 12) {
      var temperaturaRealHornosTemple = [];
      if (this.tiempoRealHornosTemple.length > 0) {

        maquina.temperaturaProgramada = this.tiempoRealHornosTemple[0].temperaturaProgramada;
        maquina.temperaturaActual = this.tiempoRealHornosTemple[0].temperaturaActual;
        maquina.temperaturaInterior = this.tiempoRealHornosTemple[0].temperaturaInterior;
        maquina.consumo = this.tiempoRealHornosTemple[0].consumo;
        maquina.nivelO2 = this.tiempoRealHornosTemple[0].nivelO2;

        //temperaturaProgramada
        var min: any = maquina.temperaturaMin;
        var max: any = maquina.temperaturaMax;
        var valor: any = maquina.temperaturaProgramada;
        var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
        temperaturaRealHornosTemple.push({ valor: valor, nombre: this.translateService.instant("temperaturaProgr"), valorGrafico: valorGrafico });

        //temperaturaActual
        valor = maquina.temperaturaActual;
        valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
        temperaturaRealHornosTemple.push({ valor: valor, nombre: this.translateService.instant("temperaturaAct"), valorGrafico: valorGrafico });

      }

      maquina.temperaturasHornoTemple = temperaturaRealHornosTemple;

    }
    //console.log(this.ejecuciones[0]);
    this.validateOF(maquina);
    // Mirar si la maquina esta apagada o sin proceso para vaciar los campos
    if (maquina.ejecuciones[0].procesos_Tipo == 'apagada' || maquina.ejecuciones[0].refOF == this.translateService.instant("noproceso")) {
      this.datosMaquinaVaciar(maquina);
    } else {
      this.calcularDesviacionPieza(maquina);
      this.calcularTiempoEstPredPieza(maquina);
      this.calcularDesviacionLote(maquina);
    }
    //  });
  }

  cargarDatosMaquinas_OEE() {
    if (!this.esperadnoOEE) {
      var idsHornos = []
      this.dataMaquinas.forEach(element => {
        if (element.tipo_maquina == 4 || element.tipo_maquina == 5) {
          idsHornos.push(element.id);
        }
      });
      if (idsHornos.length > 0) {
        this.esperadnoOEE = true;
        this.centroMecanizadoService.get_solo_OEE_hornos(idsHornos.toString()).subscribe(
          (dtOee) => {
            this.dataMaquinas.forEach((maquina) => {
              this.oee = dtOee.filter((element) => element.idMaquina == maquina.id); //ARATZ: como este calculo realentizaba la primera carga de la pagina, y meterla en el 2º cicli era esperar demasiado, se hace al terminar la consulta inicial, y cuando el ciclo buelve a 0.
              this.tratarDatosMaquina_OEE(maquina);
            });
            this.esperadnoOEE = false;
            setTimeout((d) => this.cargarDatosMaquinas_OEE(), 10 * 60 * 1000);
          });
      } else {
        setTimeout((d) => this.cargarDatosMaquinas_OEE(), 10 * 60 * 1000);
      }
    }
  }
  tratarDatosMaquina_OEE(maquina) {
    // El OEE solo se muestra en algunas de las maquinas

    //INYECTORA 
    if (maquina.tipo_maquina == 4) {
      if (this.oee != undefined && this.oee.length > 0) {
        var resultadoOee = this.myFunctions.calcularOee(this.oee);
        var oee = [];
        //tempAnilloEntrada
        var min: any = 0;
        var max: any = 100;
        var valor: any = (resultadoOee[0]).toFixed(2);
        var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
        oee.push({ valor: valor, nombre: this.translateService.instant("oee"), valorGrafico: valorGrafico });
        //tempIBCDeEntrada
        var min: any = 0;
        var max: any = 100;
        var valor: any = (resultadoOee[1]).toFixed(2);
        var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
        oee.push({ valor: valor, nombre: this.translateService.instant("disp"), valorGrafico: valorGrafico });
        //tempRodilloCalandra
        var min: any = 0;
        var max: any = 100;
        var valor: any = (resultadoOee[2]).toFixed(2);
        var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
        oee.push({ valor: valor, nombre: this.translateService.instant("rend"), valorGrafico: valorGrafico });
        //tempRodilloHalador
        var min: any = 0;
        var max: any = 100;
        var valor: any = (resultadoOee[3]).toFixed(2);
        var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
        oee.push({ valor: valor, nombre: this.translateService.instant("cal"), valorGrafico: valorGrafico });

        maquina.oee = oee;
      } else {
        maquina.oee = [
          { valor: 0, nombre: this.translateService.instant("oee"), valorGrafico: 0 },
          { valor: 0, nombre: this.translateService.instant("disp"), valorGrafico: 0 },
          { valor: 0, nombre: this.translateService.instant("rend"), valorGrafico: 0 },
          { valor: 0, nombre: this.translateService.instant("cal"), valorGrafico: 0 },
        ];
      }
    }
    // HORNO 
    if (maquina.tipo_maquina == 5) {
      //OEE
      if (this.oee != undefined && this.oee.length > 0) {
        var resultadoOee = this.myFunctions.calcularOee(this.oee);
        var oee = [];
        //tempAnilloEntrada
        var min: any = 0;
        var max: any = 100;
        var valor: any = Math.round(resultadoOee[0] * 10) / 10;
        var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
        oee.push({ valor: valor, nombre: this.translateService.instant("oee"), valorGrafico: valorGrafico });
        //tempIBCDeEntrada
        var min: any = 0;
        var max: any = 100;
        var valor: any = Math.round(resultadoOee[1] * 10) / 10;
        var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
        oee.push({ valor: valor, nombre: this.translateService.instant("disp"), valorGrafico: valorGrafico });
        //tempRodilloCalandra
        var min: any = 0;
        var max: any = 100;
        var valor: any = Math.round(resultadoOee[2] * 10) / 10;
        var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
        oee.push({ valor: valor, nombre: this.translateService.instant("rend"), valorGrafico: valorGrafico });
        //tempRodilloHalador
        var min: any = 0;
        var max: any = 100;
        var valor: any = Math.round(resultadoOee[3] * 10) / 10;
        var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
        oee.push({ valor: valor, nombre: this.translateService.instant("cal"), valorGrafico: valorGrafico });

        maquina.oee = oee;
      } else {
        maquina.oee = [
          { valor: 0, nombre: this.translateService.instant("oee"), valorGrafico: 0 },
          { valor: 0, nombre: this.translateService.instant("disp"), valorGrafico: 0 },
          { valor: 0, nombre: this.translateService.instant("rend"), valorGrafico: 0 },
          { valor: 0, nombre: this.translateService.instant("cal"), valorGrafico: 0 },
        ];
      }
    }
  }

  refreshData() {
    if (this.router.url == '/homev2' || this.router.url == '/') {
      if (!this.datosPendientes) {
        this.datosPendientes = true;
        this.refreshDataMaquinas();
        // this.cargarDatos();
      }
    }

    this.ciclo++;
    if (this.ciclo == 60) this.ciclo = 0; //5 minutos

    if (this.router.url == '/homev2' || this.router.url == '/') {
      setTimeout((d) => this.refreshData(), 5000);
    }
  }
  refreshDataMaquinas() {
    this.centroMecanizadoService.get(this.ciclo).subscribe(
      (result) => {
        this.datosPendientes = false;
        this.dataMaquinas2 = result.maquinas;
        var index = 0;
        this.dataMaquinas.forEach((maquina) => {
          maquina.refreshed = false;
        });

        this.dataMaquinas2.forEach((maquina) => {
          if (result.ejecuciones != null) {
            this.ejecuciones = result.ejecuciones.filter((element) => (element.idMaquina == maquina.id && element.id == maquina.idHistoricoProcTemp));
          }
          this.canales = result.canales.filter((element) => element.idMaquina == maquina.id);
          this.ejes = result.ejes.filter((element) => element.iDmaquina == maquina.id);
          this.tiempoReal = result.tiempoReal.filter((element) => element.idmaquina == maquina.id);
          this.tiempoRealExtrusoras = result.tiempoRealExtrusoras.filter((element) => element.idMaquina == maquina.id);
          this.tiempoRealImpresoras = result.tiempoRealImpresoras.filter((element) => element.idMaquina == maquina.id);
          this.tiempoRealInyectoras = result.tiempoRealInyectoras.filter((element) => element.idMaquina == maquina.id);
          if (result.tiempoRealHornos != null) this.tiempoRealHornos = result.tiempoRealHornos.filter((element) => element.idMaquina == maquina.id);
          else this.tiempoRealHornos = [];
          this.tiempoPorSemana = result.tiempoPorSemana.filter((element) => element.idMaquina == maquina.id);
          // this.oee = result.oee.filter((element) => element.idMaquina == maquina.id);

          if (!maquina.puestoOffline) var maquinaParaCambiar = this.dataMaquinas.find(x => x.id == maquina.id);
          else var maquinaParaCambiar = this.dataMaquinas.find(x => (x.id == maquina.id && x.idHistoricoProcTemp == maquina.idHistoricoProcTemp));

          //SI NO TIENE MAQUINA AÑADIR NUEVA MAQUINA
          if (maquinaParaCambiar == undefined) {
            maquinaParaCambiar = maquina;
            maquinaParaCambiar.tiempoPorSemana = [{ porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }];
            this.dataMaquinas.push(maquinaParaCambiar);
            this.calcularCanales(maquinaParaCambiar);
          }

          //LE AÑADIMOS REFRESHED (TODAS LAS MAQUINAS QUE NO HAYAN SIDO REFRESCADAS SE BORRARAN)
          maquinaParaCambiar.refreshed = true;

          if (this.tiempoPorSemana.length != 0) {
            maquina.tiempoPorSemana = this.tiempoPorSemana;
            maquinaParaCambiar.tiempoPorSemana = maquina.tiempoPorSemana;
          }

          maquina.ejecuciones = [];
          if (this.ejecuciones.length > 0) {
            if (this.ejecuciones[0].refOF == undefined || this.ejecuciones[0].refOF == "") {
              this.ejecuciones[0].refOF = this.translateService.instant("noproceso");
              this.ejecuciones[0].realizadas = "";
            } else {
              this.ejecuciones[0].realizadas = this.ejecuciones[0].hechas + "/" + this.ejecuciones[0].cantidad;
            }
            maquina.ejecuciones = this.ejecuciones;
          } else {
            maquina.ejecuciones.push({ procesos_Tipo: "apagada", refof: this.translateService.instant("noproceso"), realizadas: '', eje: 0, pre: 0, mant: 0, alarma: 0, apagado: 0, total: 0 });
          }
          maquinaParaCambiar.ejecuciones = this.ejecuciones;

          maquina.ejecuciones[0].total = maquina.ejecuciones[0].eje + maquina.ejecuciones[0].pre + maquina.ejecuciones[0].eje + maquina.ejecuciones[0].eje +
            this.CargarCssEstadoMaquina(maquina, this.dataMaquinas2[index]);
          this.CargarIconosEstadoMaquina(maquina, this.dataMaquinas2[index]);
          if (this.ejecuciones[0] != null) {
            maquina.operario = this.ejecuciones[0].operario;
            if (maquina.operario == " ") {
              maquina.operario = this.translateService.instant('sinOperario');
            } else {
              maquinaParaCambiar.operario = maquina.operario;
            }
          }

          if (this.ejes.length > 0) {

            for (let i = 0; i < this.ejes.length; i++) {
              this.ejes[i].divgrafico = "grafico_" + (i + 1) + "_maquina_" + maquina.id;
              try {
                var rpm = this.tiempoReal[i].rpm;

                this.ejes[i].rpm = this.labelFeed(rpm);
                if (this.tiempoReal != undefined && this.tiempoReal[i].rpm != undefined) {
                  this.ejes[i].valorGrafico = this.calcularPorcentaje(rpm - this.ejes[i].feedMinimo, 48, this.ejes[i].feedMaximo - this.ejes[i].feedMinimo, 0, true);
                }
              }
              catch (exception) {
              }
            }

            var poten1 = this.tiempoReal[0].potenciometro;
            var poten2 = this.tiempoReal[0].potenciometro2;
            maquina.potenciometrostr = poten1;
            maquina.potenciometrostr2 = poten2;

            if (poten1 != undefined) {
              maquina.potenciometro = this.calcularPorcentaje(poten1 - this.ejes[0].potenMin, 48, this.ejes[0].potenMax - this.ejes[0].potenMin, 0, true);
            }


            if (poten2 != undefined) {
              maquina.potenciometro2 = this.calcularPorcentaje(poten2 - this.ejes[0].potenMin, 48, this.ejes[0].potenMax - this.ejes[0].potenMin, 0, true);
            }

            for (let i = this.ejes.length - 1; i < 4; i++) {
              try {
                this.ejes.push({
                  tempvisible: false,
                  valorGrafico: 0,
                  potenciometro: 0,
                  potenciometro2: 0
                });
              }
              catch (exception) {
              }
            }

          }
          else {
            this.ejes = [];
          }

          var maquinaConImagen = this.maquinas.find(x => x.id == maquina.id);
          if (maquinaConImagen != undefined)
            maquina.logo = maquinaConImagen.imagenBase64;
          else
            maquina.logo = "";

          var marcaConImagen = this.marcas.find(x => x.id == maquina.idMarca);
          if (marcaConImagen != undefined)
            maquina.marca = marcaConImagen.imagenBase64;
          else
            maquina.marca = "";

          maquina.tiempoReal = this.tiempoReal;
          if (maquina.logo != undefined) {
            maquinaParaCambiar.logo = maquina.logo;
          } else {
            this.dataMaquinas
          }

          maquina.ejesActivos = (this.ejes == undefined) ? 0 : this.ejes.length;
          maquinaParaCambiar.ejesActivos = maquina.ejesActivos;
          maquina.canales = this.canales;
          maquinaParaCambiar.canales = maquina.canales;
          maquina.ejes = this.ejes;
          this.CargarIconosEjes(maquina);
          maquinaParaCambiar.ejes = maquina.ejes;
          maquina.tiempoReal = this.tiempoReal;
          maquinaParaCambiar.tiempoReal = maquina.tiempoReal;
          maquinaParaCambiar.css = maquina.css;
          maquinaParaCambiar.ejecss = maquina.ejecss;
          maquinaParaCambiar.porcentaje = maquina.porcentaje;
          maquinaParaCambiar.estadoimg = maquina.estadoimg;

          //SI ES EXTRUSORA CALCULAR TEMPERATURAS
          if (maquina.tipo_maquina == 2) {
            if (this.tiempoRealExtrusoras.length > 0) {

              var temperaturasExtrusora = [];

              //infoControlEspesores
              maquina.controlEsp = this.tiempoRealExtrusoras[0].infoControlEspesores;

              //rendimientoSopladores
              maquina.rendSopladores = this.tiempoRealExtrusoras[0].rendimientoSopladores;

              //tempAnilloEntrada
              var min = this.tiempoRealExtrusoras[0].tempAnilloEntrada_min;
              var max = this.tiempoRealExtrusoras[0].tempAnilloEntrada_max;
              var valor = this.tiempoRealExtrusoras[0].tempAnilloEntrada;
              var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
              temperaturasExtrusora.push({ valor: valor, nombre: this.translateService.instant("tAnill"), valorGrafico: valorGrafico });

              //tempIBCDeEntrada
              var min = this.tiempoRealExtrusoras[0].tempIBCDeEntrada_min;
              var max = this.tiempoRealExtrusoras[0].tempIBCDeEntrada_max;
              var valor = this.tiempoRealExtrusoras[0].tempIBCDeEntrada;
              var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
              temperaturasExtrusora.push({ valor: valor, nombre: this.translateService.instant("tIBC"), valorGrafico: valorGrafico });

              //tempRodilloCalandra
              var min = this.tiempoRealExtrusoras[0].tempRodilloCalandra_min;
              var max = this.tiempoRealExtrusoras[0].tempRodilloCalandra_max;
              var valor = this.tiempoRealExtrusoras[0].tempRodilloCalandra;
              var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
              temperaturasExtrusora.push({ valor: valor, nombre: this.translateService.instant("tRodCal"), valorGrafico: valorGrafico });

              //tempRodilloHalador
              var min = this.tiempoRealExtrusoras[0].tempRodilloHalador_min;
              var max = this.tiempoRealExtrusoras[0].tempRodilloHalador_max;
              var valor = this.tiempoRealExtrusoras[0].tempRodilloHalador;
              var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
              temperaturasExtrusora.push({ valor: valor, nombre: this.translateService.instant("tRodHal"), valorGrafico: valorGrafico });

              maquina.temperaturasExtrusora = temperaturasExtrusora;

            } else {

              maquina.rendSopladores = 0;
              maquina.controlEsp = 0;
              maquina.temperaturasExtrusora = [
                { valor: 0, nombre: this.translateService.instant("tAnill"), valorGrafico: 0 },
                { valor: 0, nombre: this.translateService.instant("tIBC"), valorGrafico: 0 },
                { valor: 0, nombre: this.translateService.instant("tRodCal"), valorGrafico: 0 },
                { valor: 0, nombre: this.translateService.instant("tRodHal"), valorGrafico: 0 },
              ];

            }

            maquinaParaCambiar.rendSopladores = maquina.rendSopladores;
            maquinaParaCambiar.controlEsp = maquina.controlEsp;
            for (var i = 0; i < maquina.temperaturasExtrusora.length; i++) {
              maquinaParaCambiar.temperaturasExtrusora[i].valor = maquina.temperaturasExtrusora[i].valor;
              maquinaParaCambiar.temperaturasExtrusora[i].nombre = maquina.temperaturasExtrusora[i].nombre;
              maquinaParaCambiar.temperaturasExtrusora[i].valorGrafico = maquina.temperaturasExtrusora[i].valorGrafico;
            }

          }

          //SI ES IMPRESORA DE PLASTICO CALCULAR PRESIONES
          if (maquina.tipo_maquina == 3) {
            if (this.tiempoRealImpresoras.length > 0) {

              var presionesImpresora = [];

              //speed
              maquina.speed = this.tiempoRealImpresoras[0].speed;

              //secadorTempBCD
              maquina.secTempBCD = this.tiempoRealImpresoras[0].secadorTempBCD;

              //secadorTunelTempBCD
              maquina.secTunelTempBCD = this.tiempoRealImpresoras[0].secadorTunelTempBCD;

              //desbobinadora
              var min = this.tiempoRealImpresoras[0].desbobinadora_min;
              var max = this.tiempoRealImpresoras[0].desbobinadora_max;
              var valor = this.tiempoRealImpresoras[0].desbobinadora;
              var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
              presionesImpresora.push({ valor: valor, nombre: this.translateService.instant("desb"), valorGrafico: valorGrafico });

              //tomaDeAlimentacion
              var min = this.tiempoRealImpresoras[0].tomaDeAlimentacion_min;
              var max = this.tiempoRealImpresoras[0].tomaDeAlimentacion_max;
              var valor = this.tiempoRealImpresoras[0].tomaDeAlimentacion;
              var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
              presionesImpresora.push({ valor: valor, nombre: this.translateService.instant("tAlim"), valorGrafico: valorGrafico });

              //accionadorRodilloEnfriamiento
              var min = this.tiempoRealImpresoras[0].accionadorRodilloEnfriamiento_min;
              var max = this.tiempoRealImpresoras[0].accionadorRodilloEnfriamiento_max;
              var valor = this.tiempoRealImpresoras[0].accionadorRodilloEnfriamiento;
              var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
              presionesImpresora.push({ valor: valor, nombre: this.translateService.instant("rodEnf"), valorGrafico: valorGrafico });

              //bobinadora
              var min = this.tiempoRealImpresoras[0].bobinadora_min;
              var max = this.tiempoRealImpresoras[0].bobinadora_max;
              var valor = this.tiempoRealImpresoras[0].bobinadora;
              var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
              presionesImpresora.push({ valor: valor, nombre: this.translateService.instant("bobi"), valorGrafico: valorGrafico });

              maquina.presionesImpresora = presionesImpresora;

            } else {

              maquina.speed = 0;
              maquina.secTempBCD = 0;
              maquina.secTunelTempBCD = 0;
              maquina.presionesImpresora = [
                { valor: 0, nombre: this.translateService.instant("desb"), valorGrafico: 0 },
                { valor: 0, nombre: this.translateService.instant("tAlim"), valorGrafico: 0 },
                { valor: 0, nombre: this.translateService.instant("rodEnf"), valorGrafico: 0 },
                { valor: 0, nombre: this.translateService.instant("bobi"), valorGrafico: 0 },
              ];

            }
            maquinaParaCambiar.speed = maquina.speed;
            maquinaParaCambiar.secTempBCD = maquina.secTempBCD;
            maquinaParaCambiar.secTunelTempBCD = maquina.secTunelTempBCD;
            for (var i = 0; i < maquina.presionesImpresora.length; i++) {
              maquinaParaCambiar.presionesImpresora[i].valor = maquina.presionesImpresora[i].valor;
              maquinaParaCambiar.presionesImpresora[i].nombre = maquina.presionesImpresora[i].nombre;
              maquinaParaCambiar.presionesImpresora[i].valorGrafico = maquina.presionesImpresora[i].valorGrafico;
            }

          }

          //SI ES INYECTORA CALCULAR OEE Y CICLO
          if (maquina.tipo_maquina == 4) {

            if (this.tiempoRealInyectoras.length > 0) {
              maquinaParaCambiar.ciclo = this.tiempoRealInyectoras[0].nCiclo;
              maquinaParaCambiar.nCavidades = this.tiempoRealInyectoras[0].nCavidades;
              maquinaParaCambiar.material = this.tiempoRealInyectoras[0].material;
              maquinaParaCambiar.refMaterial = this.tiempoRealInyectoras[0].refMaterial;
              maquinaParaCambiar.refMolde = this.tiempoRealInyectoras[0].refMolde;
            }
          }

          //SI ES HORNO CALCULAR OEE Y RECETA
          if (maquina.tipo_maquina == 5) {

            if (this.tiempoRealHornos.length > 0) {
              maquinaParaCambiar.receta = this.tiempoRealHornos[0].nReceta;
              maquinaParaCambiar.nCavidades = this.tiempoRealHornos[0].nCavidades;
              maquinaParaCambiar.material = this.tiempoRealHornos[0].material;
              maquinaParaCambiar.refMaterial = this.tiempoRealHornos[0].refMaterial;
              maquinaParaCambiar.refMolde = this.tiempoRealHornos[0].refMolde;

              if (maquinaParaCambiar?.ejecuciones[0]?.idOperacion != -1) {
                maquinaParaCambiar.eurosHora = this.tiempoRealHornos[0].euros_hora;
                // maquinaParaCambiar.porcenEurosHora = this.tiempoRealHornos[0].porcentaje_euros_hora;
                maquinaParaCambiar.piezasHora = this.tiempoRealHornos[0].piezas_hora;
                // maquinaParaCambiar.porcenPiezasHora = this.tiempoRealHornos[0].porcentaje_piezas_hora;
                maquinaParaCambiar.tRestCalidad = this.tiempoRealHornos[0].tRestanteCalidad;
                maquinaParaCambiar.temperatura = this.tiempoRealHornos[0].temperatura;
                maquinaParaCambiar.kilowatio = this.tiempoRealHornos[0].kilowatio;

                var tEstTotalSec = (maquinaParaCambiar.ejecuciones[0].tiempoEstimadoSegundos + maquinaParaCambiar.ejecuciones[0].tiempoPreparacionSegundos);
                this.tEstTotal = Math.floor(tEstTotalSec / 3600);
                if (this.tEstTotal == 0) this.piezasHoraEst = 0;
                else this.piezasHoraEst = Math.floor(maquinaParaCambiar.ejecuciones[0].cantidad / this.tEstTotal);

                this.eurosHoraEst = Math.round(this.piezasHoraEst * this.tiempoRealHornos[0].coste * 100) / 100;
                maquinaParaCambiar.eurosHora = Math.round(maquinaParaCambiar.piezasHora * this.tiempoRealHornos[0].coste * 100) / 100;
                maquinaParaCambiar.piezasHoraEst = this.piezasHoraEst;
                maquinaParaCambiar.eurosHoraEst = this.eurosHoraEst;
                var porcenPieza = (maquinaParaCambiar.piezasHora / maquinaParaCambiar.piezasHoraEst) * 100
                maquinaParaCambiar.porcenPiezasHora = porcenPieza.toFixed(2);
                var porcenEuros = (maquinaParaCambiar.eurosHora / this.eurosHoraEst) * 100;
                maquinaParaCambiar.porcenEurosHora = porcenEuros.toFixed(2);

              } else {
                maquinaParaCambiar.eurosHora = '-';
                maquinaParaCambiar.porcenEurosHora = '-';
                maquinaParaCambiar.piezasHora = '-';
                maquinaParaCambiar.porcenPiezasHora = '-';
                maquinaParaCambiar.tRestCalidad = '-';
                maquinaParaCambiar.temperatura = '-';
                maquinaParaCambiar.kilowatio = '-';
                maquinaParaCambiar.piezasHoraEst = '-';
                maquinaParaCambiar.eurosHoraEst = '-';
              }

            }
          }

          //SI ES aditivo CALCULAR capa
          if (maquina.tipo_maquina == 9) {
            let proba = 1
          }


          // if (!this.loadingDatosTiempo.get(maquina.id) && (maquina.ejecuciones[0].contador != this.cambioContador.get(maquina.id) || maquina.ejecuciones[0].idHistorico_operaciones != this.cambioIdOperacion.get(maquina.id)) ) {
          //   console.log("Recarga tiempo maquina: ", maquina.id);
          //   //this.cambioContador.set(maquina.id, maquina.ejecuciones[0].contador);
          //   //this.cambioIdOperacion.set(maquina.id, maquina.ejecuciones[0].idHistorico_operaciones);
          //   this.calcularTiempos();
          // }
          if (this.ejecuciones[0]?.contador != undefined && this.ejecuciones[0]?.hechas != undefined) {
            this.maxContadorTerminado.set(maquina.id, Math.max(this.ejecuciones[0].contador, this.ejecuciones[0].hechas));
          } else {
            this.maxContadorTerminado.set(maquina.id, 0);
          }

          index++;

        });

        //BORRAR LAS MAQUINAS NO REFRESCADAS
        for (var i = this.dataMaquinas.length - 1; i >= 0; i -= 1) {
          if (!this.dataMaquinas[i].refreshed) {
            this.dataMaquinas[i].borrado = true;
            this.dataMaquinas.splice(i, 1);
          }
        }

        //ORDENAR
        this.dataMaquinas.sort((a, b) => {
          return a.ordenModelo - b.ordenModelo;
        });
      });


  }

  // INSTALACIONES
  cargarInstalacionesInformacion() {
    this.r2 = true;
    this.instalaciones = this.instalaciones.sort((a, b) => { if (a.estructura > b.estructura) return -1 })

    var r1 = false, r2 = false;
    this.instalacionesService.get_iconos_graficoDatos().subscribe(
      result => {
        this.iconosDatos = result;
        r1 = true;
        // if (r1 && r2) this.crearGraficos();
        this.instalacionesService.get_instalaciones_datos(-1).subscribe(
          result => {
            this.instalacionesCargadas = true;
            this.r3 = true;
            this.infInstalaciones = result;
            r2 = true;
            this.crearGraficos();
          });

        setTimeout((d) => this.refreshDataIns(), 5000); // Despues de cargar los datos por primera vez, llamamos a refresh data (que tiene otro setTimeout()) para que se empiezen a actualizar.
      });
  }
  refreshDataIns() {
    if (this.router.url == '/homev2' || this.router.url == '/') {
      if (this.instalacionesCargadas /* && this.r3 */) {
        /*this.r3 = false;*/
        this.refreshDataInstalaciones();
      }
    }
    // else {
    //   clearInterval(this.interval2);
    // }
    if (this.router.url == '/homev2' || this.router.url == '/') {
      setTimeout((d) => this.refreshDataIns(), 60000);
    }
  }
  refreshDataInstalaciones() {
    this.instalacionesService.get_instalaciones_datos(-1).subscribe(
      result => {
        this.infInstalaciones = result;
        this.actualizarGraficos();
      });
  }

  crearGraficos() {

    // if (this.router.url == '/homev2' || this.router.url == '/') {
    //   if (this.r1 && this.r2 && this.idGrupoActual != this.appComponent.selectedGrupo && this.instalacionesCargadas) {
    //     setTimeout((d) => this.crearGraficos(), 1000);
    //     this.idGrupoActual = this.appComponent.selectedGrupo;
    //   }
    // }

    var j = 0;
    this.instalaciones.forEach(element => {
      this.infInstalacion = this.infInstalaciones.filter(f => f.id == element.id);

      if (element.m1Inf?.nombre != '' && element.m1Inf?.id_tipoGrafico_DAT != -1 && element.m1Inf?.id_tipoDato_DAT != -1) {
        switch (element.m1Inf.id_tipoGrafico_DAT) {
          case 1: // Barras
            this.dibujarBarras(j, 1);
            break;
          case 2: // Circular
            break;
          case 3: // Nivel
            this.infTemperaturaPosicionNivel(j, 1);
            break;
          case 4: // Lineas
            this.dibujarLineas(j, 1);
            break;
          case 5: // Presion
            this.dibujarPresion(j, 1);
            break;
          case 6: // Temperatura
            this.infTemperaturaPosicionNivel(j, 1);
            break;
          case 7: // Posicion
            this.infTemperaturaPosicionNivel(j, 1);
            break;
          case 8: // Dato
            this.graficoDato(j, 1);
            break;
          case 9: // Resumen semana
            this.dibujarResumenSemana(j, 1);
            break;
          case 10: // Resumen KWh por dia
            this.dibujarBarras(j, 1, 1);
            break;
          case 11: // Resumen KWh por semana
            this.dibujarBarras(j, 1, 2);
            break;
          case 12: // Resumen KWh por mes
            this.dibujarBarras(j, 1, 3);
            break;
          case 13: // Dato KWh por dia
            this.graficoDatoPorResumen(j, 1, 1);
            break;
          case 14: // Dato KWh por semana
            this.graficoDatoPorResumen(j, 1, 2);
            break;
          case 15: // Dato KWh por mes
            this.graficoDatoPorResumen(j, 1, 3);
            break;
          default:
            break;
        }
      }

      if (element.m2Inf?.nombre != '' && element.m2Inf?.id_tipoGrafico_DAT != -1 && element.m2Inf?.id_tipoDato_DAT != -1) {
        switch (element.m2Inf.id_tipoGrafico_DAT) {
          case 1: // Barras
            this.dibujarBarras(j, 2);
            break;
          case 2: // Circular
            break;
          case 3: // Nivel
            this.infTemperaturaPosicionNivel(j, 2);
            break;
          case 4: // Lineas
            this.dibujarLineas(j, 2);
            break;
          case 5: // Presion
            this.dibujarPresion(j, 2);
            break;
          case 6: // Temperatura
            this.infTemperaturaPosicionNivel(j, 2);
            break;
          case 7: // Posicion
            this.infTemperaturaPosicionNivel(j, 2);
            break;
          case 8: // Dato
            this.graficoDato(j, 2);
            break;
          case 9: // Resumen semana
            this.dibujarResumenSemana(j, 2);
            break;
          case 10: // Resumen KWh por dia
            this.dibujarBarras(j, 2, 1);
            break;
          case 11: // Resumen KWh por semana
            this.dibujarBarras(j, 2, 2);
            break;
          case 12: // Resumen KWh por mes
            this.dibujarBarras(j, 2, 3);
            break;
          case 13: // Dato KWh por dia
            this.graficoDatoPorResumen(j, 2, 1);
            break;
          case 14: // Dato KWh por semana
            this.graficoDatoPorResumen(j, 2, 2);
            break;
          case 15: // Dato KWh por mes
            this.graficoDatoPorResumen(j, 2, 3);
            break;
          default:
            break;
        }
      }

      j++;
    });

  }

  actualizarGraficos() {
    // columnas
    var j = 0;

    var barrasCantidad = 0;
    var lineasCantidad = 0;
    var presionCantidad = 0;
    var resumenSemanaCantidad = 0;

    this.instalaciones.forEach(element => {
      this.infInstalacion = this.infInstalaciones.filter(f => f.id == element.id);
      if (element.m1Inf.nombre != '' && element.m1Inf.id_tipoGrafico_DAT != -1 && element.m1Inf.id_tipoDato_DAT != -1) {
        switch (element.m1Inf.id_tipoGrafico_DAT) {
          case 1: // Barras
            this.cargarDatosParaGraficosBarras(j, 'm1Inf', barrasCantidad);
            // this.cargarDatosBarras(barrasCantidad);
            barrasCantidad++;
            break;
          case 2: // Circular
            break;
          case 3: // Nivel
            this.infTemperaturaPosicionNivel(j, 1);
            break;
          case 4: // Lineas
            var xValue = this.cargarDatosParaGraficosLineas(j, 'm1Inf');
            if (xValue != -1) this.cargarDatosLineas(xValue, lineasCantidad);
            lineasCantidad++;
            break;
          case 5: // Presion
            this.cargarDatosParaGraficoPresion(j, 'm1Inf');
            this.cargarDatosPresion(presionCantidad);
            presionCantidad++;
            break;
          case 6: // Temperatura
            this.infTemperaturaPosicionNivel(j, 1);
            break;
          case 7: // Posicion
            this.infTemperaturaPosicionNivel(j, 1);
            break;
          case 8: // Dato
            this.graficoDato(j, 1);
            break;
          case 9: // Resumen semana
            this.cargarDatosParaGraficoResumenSemana(j, 'm1Inf');
            this.cargarDatosResumenSemana(resumenSemanaCantidad);
            resumenSemanaCantidad++;
            break;
          case 10: // Resumen KWh por dia
            this.cargarDatosParaGraficosBarrasPorDia(j, 'm1Inf', barrasCantidad);
            barrasCantidad++;
            break;
          case 11: // Resumen KWh por semana
            this.cargarDatosParaGraficosBarrasPorSemana(j, 'm1Inf', barrasCantidad);
            barrasCantidad++;
            break;
          case 12: // Resumen KWh por mes
            this.cargarDatosParaGraficosBarrasPorMes(j, 'm1Inf', barrasCantidad);
            barrasCantidad++;
            break;
          case 13: // Dato KWh por dia
            this.graficoDatoPorResumen(j, 1, 1);
            break;
          case 14: // Dato KWh por semana
            this.graficoDatoPorResumen(j, 1, 2);
            break;
          case 15: // Dato KWh por mes
            this.graficoDatoPorResumen(j, 1, 3);
            break;

          default:
            break;
        }
      }

      if (element.m2Inf.nombre != '' && element.m2Inf.id_tipoGrafico_DAT != -1 && element.m2Inf.id_tipoDato_DAT != -1) {
        switch (element.m2Inf.id_tipoGrafico_DAT) {
          case 1: // Barras
            this.cargarDatosParaGraficosBarras(j, 'm2Inf', barrasCantidad);
            // this.cargarDatosBarras(barrasCantidad);
            barrasCantidad++;
            break;
          case 2: // Circular
            break;
          case 3: // Nivel
            this.infTemperaturaPosicionNivel(j, 2);
            break;
          case 4: // Lineas
            var xValue = this.cargarDatosParaGraficosLineas(j, 'm2Inf');
            if (xValue != -1) this.cargarDatosLineas(xValue, lineasCantidad);
            lineasCantidad++;
            break;
          case 5: // Presion
            this.cargarDatosParaGraficoPresion(j, 'm2Inf');
            this.cargarDatosPresion(presionCantidad);
            presionCantidad++;
            break;
          case 6: // Temperatura
            this.infTemperaturaPosicionNivel(j, 2);
            break;
          case 7: // Posicion
            this.infTemperaturaPosicionNivel(j, 2);
            break;
          case 8: // Dato
            this.graficoDato(j, 2);
            break;
          case 9: // Resumen semana
            this.cargarDatosParaGraficoResumenSemana(j, 'm2Inf');
            this.cargarDatosResumenSemana(resumenSemanaCantidad);
            resumenSemanaCantidad++;
            break;
          case 10: // Resumen KWh por dia
            this.cargarDatosParaGraficosBarrasPorDia(j, 'm2Inf', barrasCantidad);
            barrasCantidad++;
            break;
          case 11: // Resumen KWh por semana
            this.cargarDatosParaGraficosBarrasPorSemana(j, 'm2Inf', barrasCantidad);
            barrasCantidad++;
            break;
          case 12: // Resumen KWh por mes
            this.cargarDatosParaGraficosBarrasPorMes(j, 'm2Inf', barrasCantidad);
            barrasCantidad++;
            break;
          case 13: // Dato KWh por dia
            this.graficoDatoPorResumen(j, 2, 1);
            break;
          case 14: // Dato KWh por semana
            this.graficoDatoPorResumen(j, 2, 2);
            break;
          case 15: // Dato KWh por mes
            this.graficoDatoPorResumen(j, 2, 3);
            break;

          default:
            break;
        }
      }

      j++;
    });
  }

  // BARRAS
  /* 
  * tipoInformacion:
  *   -1 => normal
  *   1 => resumen KWh por dia
  *   2 => resumen KWh por semana
  *   3 => resumen KWh por mes
  */
  dibujarBarras(index, m, tipoInformacion = -1) {
    var that = this;
    if (tipoInformacion != -1)
      this.graficoBarrasArray.push(c3.generate({
        bindto: '#graficoBarras_' + m + "_" + index,
        data: {
          x: 'x',
          columns: [['x']],
          type: 'bar'
        },
        axis: {
          x: {
            type: 'timeseries',
            tick: {
              centered: true,
              // count: 10,
              format: function (d) {
                if (tipoInformacion == -1)
                  return that.myFunctions.addZero(d.getHours()) + ':' + that.myFunctions.addZero(d.getMinutes());
                else if (tipoInformacion == 2) {
                  var weekNumber = that.translateService.instant('semana') + ' ' + that.myFunctions.getNumberOfWeek(d) + ' (' + that.myFunctions.dateToYYYY_MM_DD_guion(new Date(d)) + ')';
                  return weekNumber
                } else if (tipoInformacion == 3) {
                  return that.myFunctions.dateTo_Mes_YYYY(d);
                } else if (tipoInformacion == 1) {
                  var mes = d.getMonth() + 1;
                  return d.getFullYear() + "/" + that.myFunctions.addZero(mes) + "/" + d.getDate()
                }
              }
            }
          },
          y: {
            tick: {
              count: 10,
              format: function (d) {
                return that.nFormatter(d, 1)
              }
            }
          }
        },
        legend: {
          show: false
        },
        tooltip: {
          contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
            var auxiString1 = "<div class=\"tooltip-graf-home\"><span>" + d[0].value.toFixed(2) + "</span></div>";
            return auxiString1;
          }
        }
      }));
    else
      this.graficoBarrasArray.push(c3.generate({
        bindto: '#graficoBarras_' + m + "_" + index,
        data: {
          x: 'x',
          columns: [['x']],
          type: 'bar'
        },
        axis: {
          x: {
            type: 'timeseries',
            tick: {
              centered: true,
              count: 10,
              format: function (d) {
                return that.myFunctions.addZero(d.getHours()) + ':' + that.myFunctions.addZero(d.getMinutes());
              }
            }
          },
          y: {
            tick: {
              count: 10,
              format: function (d) {
                return that.nFormatter(d, 1)
              }
            }
          }
        },
        legend: {
          show: false
        },
        tooltip: {
          contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
            var auxiString1 = "<div class=\"tooltip-graf-home\"><span>" + d[0].value.toFixed(2) + "</span></div>";
            return auxiString1;
          }
        }
      }));

    var lag = 'm' + m + 'Inf';
    if (tipoInformacion == -1) {
      this.cargarDatosParaGraficosBarras(index, lag, this.graficoBarrasArray.length - 1);
      // this.cargarDatosBarras(this.graficoBarrasArray.length - 1);
    }
    else if (tipoInformacion == 1)
      this.cargarDatosParaGraficosBarrasPorDia(index, lag, this.graficoBarrasArray.length - 1);
    else if (tipoInformacion == 2)
      this.cargarDatosParaGraficosBarrasPorSemana(index, lag, this.graficoBarrasArray.length - 1);
    else if (tipoInformacion == 3)
      this.cargarDatosParaGraficosBarrasPorMes(index, lag, this.graficoBarrasArray.length - 1);
  }

  cargarDatosParaGraficosBarras(col, m, index) {
    var inf = this.infInstalacion.filter(f => (f.idCampo == this.instalaciones[col][m].idCampo))[0];

    if (inf != undefined) {
      // procesar los datos (si el valor es -1 entonces hay que eliminarlo)
      var valoresHist = inf?.valoresHistoricos.split(",").reverse();
      var fechasHist = inf?.fechas.split(",").reverse();
      var i = 0;
      var fechasToRemove = [];
      var datos = [];
      valoresHist.forEach(element => {
        if (element == "-1.000000")
          fechasToRemove.push(fechasHist[i]);
        else
          datos.push({ valor: element, fecha: fechasHist[i] });
        i++
      });
      // valoresHist = valoresHist.filter(f => f != "-1.000000");
      // fechasHist = fechasHist.filter(f => !fechasToRemove.includes(f));

      datos = datos.sort((a, b) => (b.fecha > a.fecha) ? 1 : ((a.fecha > b.fecha) ? -1 : 0))
      valoresHist = [];
      fechasHist = [];
      datos.forEach(element => {
        valoresHist.push(element.valor);
        fechasHist.push(element.fecha);
      })

      // fechas
      var fechas = fechasHist;
      var fechasGrafico: any = ['x'];
      fechas.forEach(element => {
        if (element != '')
          fechasGrafico.push(new Date(element));
        else
          fechasGrafico.push(new Date())
      });

      // valores
      var valoresGrafico = [this.instalaciones[col][m].nombre]
      var valores = valoresHist;
      valoresGrafico.push(...valores);
      this.fechasG = fechasGrafico.slice(0, 10);
      this.valoresG = valoresGrafico.slice(0, 10);

      this.cargarDatosBarras(index);
    }

  }

  cargarDatosParaGraficosBarrasPorDia(col, m, index) {
    var inf = this.infInstalacion[0];
    this.instalacionesService.get_consumo_agrupado(inf.id, 1).subscribe(
      (result: any) => {
        var valoresHist = [];
        var fechasHist: any = [];
        result.forEach(element => {
          valoresHist.push(element.valor);
          fechasHist.push(new Date(element.dia));
        });

        // valores
        var valoresGrafico = ['KWh']
        var valores = valoresHist.reverse();
        valoresGrafico.push(...valores);
        var fechasGrafico = ['x']
        fechasHist = fechasHist.reverse();
        fechasGrafico.push(...fechasHist);
        this.fechasG = fechasGrafico.slice(0, 5);
        this.valoresG = valoresGrafico.slice(0, 5);
        this.cargarDatosBarras(index);
      });
  }

  cargarDatosParaGraficosBarrasPorSemana(col, m, index) {
    var inf = this.infInstalacion[0];
    this.instalacionesService.get_consumo_agrupado(inf.id, 2).subscribe(
      (result: any) => {
        var valoresHist = [];
        var fechasHist: any = [];
        result.forEach(element => {
          var anno = element.anno.split(';')[0];
          valoresHist.push(element.valor);
          fechasHist.push(this.myFunctions.getDatefromWeek(element.semana, anno));
        });

        // valores
        var valoresGrafico = ['KWh']
        var valores = valoresHist.reverse();
        valoresGrafico.push(...valores);
        var fechasGrafico = ['x']
        fechasHist = fechasHist.reverse();
        fechasGrafico.push(...fechasHist);
        this.fechasG = fechasGrafico.slice(0, 5);
        this.valoresG = valoresGrafico.slice(0, 5);
        this.cargarDatosBarras(index);
      });
  }

  cargarDatosParaGraficosBarrasPorMes(col, m, index) {
    var inf = this.infInstalacion[0];
    this.instalacionesService.get_consumo_agrupado(inf.id, 3).subscribe(
      (result: any) => {
        var valoresHist = [];
        var fechasHist: any = [];
        result.forEach(element => {
          var anno = element.anno.split(';')[0];
          valoresHist.push(element.valor);
          fechasHist.push(new Date(anno, element.mes - 1, 1));
        });

        // valores
        var valoresGrafico = ['KWh']
        var valores = valoresHist.reverse();
        valoresGrafico.push(...valores);
        var fechasGrafico = ['x']
        fechasHist = fechasHist.reverse();
        fechasGrafico.push(...fechasHist);
        this.fechasG = fechasGrafico.slice(0, 5);
        this.valoresG = valoresGrafico.slice(0, 5);
        this.cargarDatosBarras(index);
      });
  }

  cargarDatosBarras(i) {
    this.graficoBarrasArray[i].load({
      unload: true,
      x: 'x',
      columns: [this.fechasG, this.valoresG]
    });
  }

  // LINEAS
  dibujarLineas(index, m) {
    var that = this;
    this.graficoLineasArray.push(c3.generate({
      bindto: '#graficoLineas_' + m + "_" + index,
      data: {
        x: 'x',
        columns: [['x']],
        // type: 'bar'
      },
      color: {
        pattern: ['#1FB5B5', '#00F6FF']
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            centered: true,
            count: 10,
            format: function (d) {
              if (d == undefined)
                return;
              return that.myFunctions.addZero(d?.getHours()) + ':' + that.myFunctions.addZero(d?.getMinutes());
            }
          }
        },
        y: {
          tick: {
            count: 10,
            format: function (d) {
              return that.nFormatter(d, 1)
            }
          }
        }
      },
      legend: {
        show: false
      },
      tooltip: {
        contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
          var auxiString1 = "<div class=\"tooltip-graf-home\"><span>" + d[0].value.toFixed(2) + "</span></div>";
          return auxiString1;
        }
      }
    }
    ));

    var lag = 'm' + m + 'Inf';
    var xValue = this.cargarDatosParaGraficosLineas(index, lag);
    if (xValue != -1) this.cargarDatosLineas(xValue, this.graficoLineasArray.length - 1);
  }

  cargarDatosParaGraficosLineas(col, m) {
    var inf = this.infInstalacion.filter(f => (f.idCampo == this.instalaciones[col][m].idCampo))[0];

    if (inf != undefined) {
      // procesar los datos (si el valor es -1 entonces hay que eliminarlo)
      var valoresHist = inf?.valoresHistoricos.split(",").reverse();
      var fechasHist = inf?.fechas.split(",").reverse();
      var i = 0;
      var fechasToRemove = [];
      var datos = [];
      valoresHist.forEach(element => {
        if (element == "-1.000000")
          fechasToRemove.push(fechasHist[i]);
        else
          datos.push({ valor: element, fecha: fechasHist[i] });
        i++
      });
      // valoresHist = valoresHist.filter(f => f != "-1.000000");
      // fechasHist = fechasHist.filter(f => !fechasToRemove.includes(f));

      datos = datos.sort((a, b) => (b.fecha > a.fecha) ? 1 : ((a.fecha > b.fecha) ? -1 : 0))
      valoresHist = [];
      fechasHist = [];
      datos.forEach(element => {
        valoresHist.push(element.valor);
        fechasHist.push(element.fecha);
      })

      // fechas
      var fechas = fechasHist;
      var fechasGrafico: any = ['x'];
      fechas.forEach(element => {
        if (element != '')
          fechasGrafico.push(new Date(element));
        else
          fechasGrafico.push(new Date())
      });

      // valores
      var valoresGrafico = [this.instalaciones[col][m].nombre]

      var xValue = {};
      xValue[this.instalaciones[col][m].nombre] = 'x';
      xValue[this.translateService.instant('media')] = 'x'

      var valores = valoresHist;
      valoresGrafico.push(...valores);

      var mCalc = 0;
      valores.forEach(element => {
        mCalc += parseInt(element);
      });
      mCalc = mCalc / valores.length;
      var media: any = [this.translateService.instant('media')];
      for (var j = 1; j < valoresGrafico.length; j++) {
        media.push(mCalc);
      }

      // this.fechasG = fechasGrafico;
      this.valoresG = [fechasGrafico.slice(0, 10), valoresGrafico.slice(0, 10), media];

      return xValue;
    }
    return -1
  }

  cargarDatosLineas(xValue, i) {
    this.graficoLineasArray[i].load({
      unload: true,
      xs: xValue,
      columns: [this.valoresG[0], this.valoresG[1]]
    });

    this.graficoLineasArray[i].ygrids.remove();
    this.graficoLineasArray[i].ygrids.add([
      { value: this.valoresG[2][1], class: "graficoLineas-media" }
    ])
  }

  // PRESION
  dibujarPresion(index, m) {

    this.graficoPresionArray.push(c3.generate({
      bindto: '#graficoPresion_' + m + "_" + index,
      data: {
        columns: [['x']],
        type: "donut",
        order: null,
        hide: [],
        colors: {
        },
      },
      legend: {
        show: false
      },
      tooltip: {
        show: false
      },
      donut: {
        label: {
          show: false
        },
        width: 30
      }

    }));

    var lag = 'm' + m + 'Inf';
    this.cargarDatosParaGraficoPresion(index, lag);
    this.cargarDatosPresion(this.graficoPresionArray.length - 1);
  }


  cargarDatosPresion(i) {
    this.graficoPresionArray[i].load({
      unload: true,
      columns: this.valoresG,
      order: null,
      hide: ['hide'],
      colors: {
        hide: '#transparent',
        data: '#22C4C4',
        rest: '#E1E1E1'
      },
    });
  }

  cargarDatosParaGraficoPresion(col, m) {
    var inf = this.infInstalacion.filter(f => (f.idCampo == this.instalaciones[col][m].idCampo))[0];

    if (inf != undefined) {
      // valores
      var porcen = (inf?.valor - inf?.minimo) * 100 / (inf?.maximo - inf?.minimo);

      // %80 es el 100% (para que quede en forma de presion)
      var porcenLag = porcen * 80 / 100
      var valoresGrafico = [['hide', 20], ['data', porcenLag], ['rest', 80 - porcenLag]];
      this.valoresG = valoresGrafico;

      d3.select('#graficoPresion_' + m[1] + '_' + col + ' .c3-chart-arcs-title').attr("transform", "rotate(216)")
      d3.select('#graficoPresion_' + m[1] + '_' + col + ' .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "20px").style("opacity", "1")
        .text(inf?.valor?.toFixed(2));
    }
  }

  // TEMPERATURA Y POSICION
  infTemperaturaPosicionNivel(col, m) {
    var lag = 'm' + m + 'Inf';
    var inf = this.infInstalacion.filter(f => (f.idCampo == this.instalaciones[col][lag].idCampo))[0];

    if (inf != undefined) {
      this.instalaciones[col][lag].min = inf?.minimo;
      this.instalaciones[col][lag].max = inf?.maximo;
      this.instalaciones[col][lag].value = inf?.valor?.toFixed(2);
      this.instalaciones[col][lag].unidad = inf?.unidad;
    }
  }

  // DATOS
  graficoDato(col, m) {
    var lag = 'm' + m + 'Inf';
    var inf_icono = this.iconosDatos.filter(f => (f.id == this.instalaciones[col][lag].id_tipoDato_DAT))[0];
    var inf = this.infInstalacion.filter(f => (f.idCampo == this.instalaciones[col][lag].idCampo))[0];

    if (inf != undefined) {
      this.instalaciones[col][lag].icono = inf_icono?.icono;
      var intValueMore5 = parseInt(inf?.valor).toString().length >= 5

      // para dividirlo en puntos
      var number = []
      if (intValueMore5) {
        this.instalaciones[col][lag].value = Math.abs(inf?.valor)?.toFixed(0).toString().split("").reverse().join("").match(/.{1,3}/g).reverse()
        this.instalaciones[col][lag].value.forEach(element => {
          number.push(element.split("").reverse().join(""));
        });
        this.instalaciones[col][lag].value = number.join('.');
      } else {
        var numberLag: any = Math.abs(inf?.valor)?.toFixed(0).toString().split("").reverse().join("").match(/.{1,3}/g).reverse();
        numberLag.forEach(element => {
          number.push(element.split("").reverse().join(""));
        });
        numberLag = number.join('.');
        var comma = "," + inf?.valor?.toFixed(2).toString().split('.')[1];
        this.instalaciones[col][lag].value = numberLag + comma;
      }

      if (inf?.valor?.toString().includes('-'))
        this.instalaciones[col][lag].value = "-" + this.instalaciones[col][lag].value;

      this.instalaciones[col][lag].unidad = inf?.unidad;
    }
  }

  graficoDatoPorResumen(col, m, tipoAgrupado) {
    var lag = 'm' + m + 'Inf';
    var inf_icono = this.iconosDatos.filter(f => (f.id == this.instalaciones[col][lag].id_tipoDato_DAT))[0];
    var inf = this.infInstalacion.filter(f => (f.idCampo == this.instalaciones[col][lag].idCampo))[0];

    if (inf != undefined)
      this.instalacionesService.get_consumo_agrupado(inf.id, tipoAgrupado, true).subscribe(
        (result: any) => {
          this.instalaciones[col][lag].icono = inf_icono?.icono;

          if (result.length == 0) {
            this.instalaciones[col][lag].value = 0;
            this.instalaciones[col][lag].unidad = "";
            return;
          }

          var intValueMore5 = parseInt(result[0]?.valor).toString().length >= 5

          // para dividirlo en puntos
          var number = [];
          if (intValueMore5) {
            this.instalaciones[col][lag].value = Math.abs(result[0]?.valor)?.toFixed(0).toString().split("").reverse().join("").match(/.{1,3}/g).reverse()
            this.instalaciones[col][lag].value.forEach(element => {
              number.push(element.split("").reverse().join(""));
            });
            this.instalaciones[col][lag].value = number.join('.');
          } else {
            var numberLag: any = Math.abs(result[0]?.valor)?.toFixed(0).toString().split("").reverse().join("").match(/.{1,3}/g).reverse();
            numberLag.forEach(element => {
              number.push(element.split("").reverse().join(""));
            });
            numberLag = number.join('.');
            var comma = "," + result[0]?.valor?.toFixed(2).toString().split('.')[1];
            this.instalaciones[col][lag].value = numberLag + comma;
          }

          if (result[0]?.valor?.toString().includes('-'))
            this.instalaciones[col][lag].value = "-" + this.instalaciones[col][lag].value;

          this.instalaciones[col][lag].unidad = inf?.unidad;
        });
  }

  // RESUMEN SEMANA
  dibujarResumenSemana(index, m) {
    var that = this;
    this.graficoResumenSemanaArray.push(c3.generate({
      bindto: '#graficoResumenSemana_' + m + "_" + index,
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      data: {
        columns: [
          [that.translateService.instant("ejecucion"), 0],
          [that.translateService.instant("parada"), 0],
          [that.translateService.instant("microparada"), 0],
          [that.translateService.instant("preparacion"), 0],
          [that.translateService.instant("mantenimiento"), 0],
          [that.translateService.instant("alarma"), 0],
          [that.translateService.instant("apagada"), 0]
        ],
        type: 'donut',
        order: null,
      },
      transition: {
        duration: 500
      },
      color: {
        pattern: ['#c0eada', '#e7cb68', '#cf8729', '#096844', '#99afc6', '#d33737', '#424242']
      },
      axis: {
        y: {
          show: false
        },
        x: {
          show: false
        }
      },
      bar: {
        width: {
          ratio: 1
        },
        space: 0.1
      },
      tooltip: {
        format: {
          title: function (d) { return 'Resumen semana' },
          value: function (value, ratio, id) {
            return value + '%';
          }
        }
      },
      legend: {
        show: false
      }
    }));

    var lag = 'm' + m + 'Inf';
    this.cargarDatosParaGraficoResumenSemana(index, lag);
    this.cargarDatosResumenSemana(this.graficoResumenSemanaArray.length - 1);
  }

  cargarDatosParaGraficoResumenSemana(col, m) {


    this.centroMecanizadoService.getInstalacionById(this.instalaciones[col].id).subscribe((result) => {
      if (result.tiempoPorSemana.length > 0) {
        this.valoresGResumenSemana = [[this.translateService.instant("ejecucion"), result.tiempoPorSemana[0].porcentaje],
        [this.translateService.instant("parada"), result.tiempoPorSemana[1].porcentaje],
        [this.translateService.instant("microparada"), result.tiempoPorSemana[2]?.porcentaje],
        [this.translateService.instant("preparacion"), result.tiempoPorSemana[3]?.porcentaje],
        [this.translateService.instant("mantenimiento"), result.tiempoPorSemana[4]?.porcentaje],
        [this.translateService.instant("alarma"), result.tiempoPorSemana[5]?.porcentaje],
        [this.translateService.instant("apagada"), result.tiempoPorSemana[6]?.porcentaje]];
        this.showLabelResumenSemana = false;
      } else {
        this.valoresGResumenSemana = [[this.translateService.instant("ejecucion"), 0],
        [this.translateService.instant("parada"), 0],
        [this.translateService.instant("microparada"), 0],
        [this.translateService.instant("preparacion"), 0],
        [this.translateService.instant("mantenimiento"), 0],
        [this.translateService.instant("alarma"), 0],
        [this.translateService.instant("apagada"), 0]];
        this.showLabelResumenSemana = true;
      }
    });
  }

  cargarDatosResumenSemana(i) {
    this.graficoResumenSemanaArray[i].load({
      unload: true,
      columns: this.valoresGResumenSemana,
    });
  }

  nFormatter(num, digits) {
    if (num.toString().length > 3) {
      const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "K" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
      ];
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var item = lookup.slice().reverse().find(function (item) {
        return Math.abs(num) >= Math.abs(item.value);
      });
      if (num.toString()[0] == 0 || (num.toString()[0] == '-' && num.toString()[1] == 0))
        return num.toFixed(2);

      return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
    } else {
      return num;
    }

  }

  // END INSTALACIONES

  cargarDatosInstalaciones() {

    var th = this;

    //CON LOS GRAFICOS YA CREADOS CARGAMOS EL TEMPORAL
    this.instalacionesService.Get_instalaciones_temporal().subscribe((result) => {

      var instalaciones_campos: any = result;
      var instalaciones = [];

      //AGRUPAMOS POR INSTALACION
      instalaciones_campos.forEach(function (a) {
        if (!this[a.id]) {
          this[a.id] = {
            id: a.id, nombre: a.nombre, imagen: a.imagen, campos: []
          };
          instalaciones.push(this[a.id]);
        }
      }, Object.create(null));

      //LE METEMOS A CADA INSTALACION SUS CAMPOS
      instalaciones_campos.forEach(function (row) {
        let instalacion = instalaciones.find(x => x.id === row.id);
        instalacion.campos.push({
          idReferencia: row.idReferencia,
          idProcesos_Tipo: row.idProcesos_Tipo,
          nombre: row.nombreCampo,
          minimo: row.minimo,
          maximo: row.maximo,
          unidad: row.unidad,
          valor: row.valor,
          valoresHistoricos: row.valoresHistoricos,
          fechas: row.fechas
        });

        //LE PONEMOS EL ESTADO (SI TUBIERA historico_Procesos)
        if (row.tieneHistorico_Procesos && row.idProcesos_Tipo == 1) instalacion.pestannaColor = "pestañaprocesos colorejecucion";
        else if (row.tieneHistorico_Procesos && row.idProcesos_Tipo == 2) instalacion.pestannaColor = "pestañaprocesos colorparada";
        else if (row.tieneHistorico_Procesos && row.idProcesos_Tipo == 3) instalacion.pestannaColor = "pestañaprocesos colorpreparacion";
        else if (row.tieneHistorico_Procesos && row.idProcesos_Tipo == 4) instalacion.pestannaColor = "pestañaprocesos colormantenimiento";
        else if (row.tieneHistorico_Procesos && row.idProcesos_Tipo == 6) instalacion.pestannaColor = "pestañaprocesos coloralarma";
        else if (row.tieneHistorico_Procesos && row.idProcesos_Tipo == 8) instalacion.pestannaColor = "pestañaprocesos colorapagada";
        else instalacion.pestannaColor = "pestañaprocesos colorejecucion";

      });

      //A CADA INSTALACION LE ACTUALIZAMOS SUS DATOS TEMPORALES
      instalaciones.forEach(function (row) {

        //ENCONTRAMOS LA INSTALACION EN LA LISTA Y LE METEMOS LOS DATOS
        let instalacion = this.instalaciones.find(x => x.id === row.id);

        instalacion.nombre = row.nombre;
        instalacion.pestannaColor = row.pestannaColor;

        //PONEMOS TODOS LOS CAMPOS INVISIBLES
        instalacion.campos.forEach(function (campo) {
          campo.visible = false;
        });

        row.campos.forEach(function (campo, i) {

          if (i == 0) {
            //PONEMOS EL CAMPO VISIBLE Y LE PONEMOS NOMBRE
            instalacion.campos[i].visible = true;
            instalacion.campos[i].nombre = campo.nombre;
            instalacion.campos[i].valor = campo.valor.toFixed(1) + " " + campo.unidad;

            //ACTUALIZAR GRAFICO VALOR
            instalacion.graficosValor[i].internal.config.gauge_min = campo.minimo;
            instalacion.graficosValor[i].internal.config.gauge_max = campo.maximo;
            instalacion.graficosValor[i].load({ columns: [['data', campo.valor]] });

            //ACTUALIZAR GRAFICO HISTORICO (LE DAMOS LA VUELTA)
            let fechasHistoricos = campo.fechas.split(",").reverse().map(function (e) { return th.myFunctions.sqlToJsDate(e); });
            let arrayValoresHistoricos = campo.valoresHistoricos.split(",").reverse().map(Number);
            fechasHistoricos.unshift("x");
            arrayValoresHistoricos.unshift("data");
            instalacion.graficosHistorico[i].load(
              {
                columns: [fechasHistoricos, arrayValoresHistoricos]
              });

            setTimeout(function () {
              instalacion.graficosValor[i].flush();
              instalacion.graficosHistorico[i].flush();
            }, 500);
          }

        });

      }, this);
    });
    // console.log(this.instalaciones);
  }

  labelFeed(d: number) {

    var s = "";
    var spl = d.toString().split(",");
    if (d.toString().indexOf(".") > 0)
      spl = d.toString().split(".");

    var decimales = 0;
    if (d >= 1000)
      decimales = 0;
    else if (d >= 100)
      decimales = 1;
    else
      decimales = 2;

    if (spl.length > 1) {
      s = spl[0]
      if (decimales > 0) {
        if (spl[1].length >= 2)
          s = s + "," + spl[1].substring(0, decimales);
        else if (spl[1].length == 1 && decimales == 2)
          s = s + "," + spl[1].substring(0, 1) + "0";
        else if (spl[1].length == 1 && decimales == 1)
          s = s + "," + spl[1].substring(0, 1)
      }
    } else {
      s = spl[0];
      switch (decimales) {
        case 1: s = s + ",0"; break;
        case 2: s = s + ",00"; break;
      }
    }
    return s;
  }

  CargarCssEstadoMaquina(maquina, maquina2) {

    if (maquina.ejecuciones[0].procesos_Tipo == null) maquina.ejecuciones[0].procesos_Tipo = "ejecucion";

    maquina.css = "pestañaprocesos color" + maquina.ejecuciones[0].procesos_Tipo.toString().toLowerCase();
    maquina.ejecss = "estado-" + maquina.ejecuciones[0].procesos_Tipo.toString().toLowerCase();

    maquina2.css = "pestañaprocesos color" + maquina.ejecuciones[0].procesos_Tipo.toString().toLowerCase();
    maquina2.ejecss = "estado-" + maquina.ejecuciones[0].procesos_Tipo.toString().toLowerCase();

  }

  calcularPorcentaje(valor: number, sobre: number, de: number, decimales: number, limitado: boolean) {
    var porcentaje = 0.0
    if (de != 0) {
      var d = 10 ^ decimales;
      var porcen = 0;
      porcen = valor * sobre * d / de;
      porcen = Math.round(porcen);
      if (porcen > sobre * d && limitado)
        porcen = sobre * d;
      porcentaje = Math.round(porcen / d);
    } else {
      if (valor > 0 && limitado)
        porcentaje = sobre;
      else if (!limitado) {
        porcentaje = -1;
      }

    }
    if (porcentaje < 0)
      porcentaje = 0;

    return porcentaje;
  }

  calcularCanales(maquina: any) {

    if (maquina != undefined && maquina.ejecuciones != undefined) {
      var horas: any = [];
      if (this.router.url == '/homev2' || this.router.url == '/')
        setTimeout((d) => this.calcularCanales(maquina), 1000);
      var ahora = new Date();
      if (maquina.ejecuciones != undefined) {
        ahora = new Date();
        if (maquina.ejecuciones[0]?.fechaServidor != undefined) {
          ahora = new Date(maquina.ejecuciones[0]?.fechaServidor);//this.myFunctions.getDateNow();
        } else {
          ahora = new Date();
        }
        this.horaServidor = ahora;
        this.horaServidor.setSeconds(this.horaServidor.getSeconds() + 1);
        if (this.horaServidor == undefined) {
          maquina.ejecuciones[0].fechaServidor = ahora;
        } else {
          maquina.ejecuciones[0].fechaServidor = this.horaServidor;
        }
      } else {
        ahora = this.horaServidor;
        ahora.setSeconds(ahora.getSeconds() + 1);
        //maquina.ejecuciones[0].fechaServidor = ahora
      }
      //Le sumamos un segundo a la hora
      //var dateAux = new Date(Date.parse(maquina.ejecuciones[0].fechaServidor));
      // dateAux.setSeconds(dateAux.getSeconds() + 1);
      // maquina.ejecuciones[0].fechaServidor = dateAux

      maquina.eje1 = true;
      maquina.eje2 = false;
      maquina.eje3 = false;
      maquina.eje4 = false;

      if (maquina.ejecuciones != undefined && maquina.ejecuciones.length > 0) {
        maquina.enEjecucion = false;
      }

      maquina.procesos_Tipostr = this.translateService.instant(maquina.ejecuciones[0].procesos_Tipo);
      maquina.programa = maquina.ejecuciones[0].nombreprograma;

      var fechaOrig = new Date(Date.parse(maquina.ejecuciones[0].fechainicio));
      var hanpasado = this.HanPasadoDias(fechaOrig, ahora, 0);
      var hapasadoTiempo = this.HanPasadoTiempo(fechaOrig, ahora, 0);
      var hanpasadoConAcumulado = this.HanPasadoDias(fechaOrig, ahora, maquina.ejecuciones[0].tiempoAcumuladoOperacion);
      var hapasadoTiempoConAcumulado = this.HanPasadoTiempo(fechaOrig, ahora, maquina.ejecuciones[0].tiempoAcumuladoOperacion);
      if (maquina.ejecuciones[0].procesos_Tipo != undefined && maquina.ejecuciones[0].procesos_Tipo.toLowerCase().trim() == "ejecucion") {
        maquina.enEjecucion = true;
      }

      if (maquina.ejecuciones[0].procesos_Tipo != 'apagada' && this.tiempoRealHornos[0]?.idOperacion != -1) {
        //if (maquina.ejecuciones[0].procesos_Tipo != 'apagada') {
        maquina.tiempoPred = this.myFunctions.secondsTo_HH_MM_SS(maquina.ejecuciones[0].tiempoPredSegundos);
        maquina.tiempoEst = this.myFunctions.secondsTo_HH_MM_SS(maquina.ejecuciones[0].tiempoEstimadoSegundos);
      } else {
        maquina.tiempoPred = "00:00:00";
        maquina.tiempoEst = "00:00:00";
      }

      // if (hanpasado > 0) {
      //   horas = hapasadoTiempo.toString().split(":");
      //   //this.tiempoActualPieza.set(maquina.id, hanpasado.toString() + "d " + horas[0] + "h");
      //   maquina.tiempopasado = hanpasado.toString() + "d " + horas[0] + "h";
      //   //maquina.tiempopasado = hanpasado.toString() + " " + this.translateService.instant("numdiashome") + " " + hapasadoTiempo.toString();
      // } else {
      maquina.tiempopasado = hapasadoTiempo.toString();
      //this.tiempoActualPieza.set(maquina.id, hapasadoTiempo.toString());
      // }
      this.tiempoActualPieza.set(maquina.id, '--:--:--')
      if (maquina.ejecuciones[0].procesos_Tipo == 'apagada') {
        this.tiempoActualPieza.set(maquina.id, '--:--:--')
      } else if (maquina.ejecuciones[0].hechas == 0) {
        this.tiempoActualPieza.set(maquina.id, this.tiempoActualLote.get(maquina.id));
      } else if (maquina.ejecuciones[0].hechas > 1) {
        // var tiempoPiezaSegundos = Math.floor(maquina.ejecuciones[0].tiempoAcumuladoOperacion / (maquina.ejecuciones[0].hechas - 1))
        // var tiempoPiezaFormatoHora = this.segundosAFormatoHora(tiempoPiezaSegundos);
        // if (maquina.ejecuciones[0].horaFinUltimaPieza != -1) {
        //   var ultimaPiezaHora = new Date(maquina.ejecuciones[0].horaFinUltimaPieza).getTime();
        //   var tiempoPieza = new Date().getTime() - ultimaPiezaHora;
        //   this.tiempoActualPieza.set(maquina.id, this.segundosAFormatoHora(Math.floor(tiempoPieza / 1000)));
        // } else {
        //   this.tiempoActualPieza.set(maquina.id, '00:00:00');
        // }
        if (maquina.ejecuciones[0].horaFinUltimaPieza == null || maquina.ejecuciones[0].horaFinUltimaPieza == -1 || maquina.ejecuciones[0].horaFinUltimaPieza == undefined) {
          this.tiempoActualPieza.set(maquina.id, "--:--:--");
        } else {
          var ultimaPiezaHora = new Date(maquina.ejecuciones[0].horaFinUltimaPieza).getTime();
          var tiempoPieza = new Date().getTime() - ultimaPiezaHora;
          this.tiempoActualPieza.set(maquina.id, this.segundosAFormatoHora(Math.floor(tiempoPieza / 1000)));
        }

      }

      this.tiempoEjecucion.set(maquina.id, maquina.tiempopasado);

      // NUEVO CALCULO TIEMPOS
      if (this.tiempoLoteSegundos.get(maquina.id) == undefined) {
        // SI TODAVIA NO TIENE NINGUN DATO DE TIEMPO
        this.tiempoActualLote.set(maquina.id, '--:--:--');
        this.tiempoActualPieza.set(maquina.id, '--:--:--');
      } else if (maquina.ejecuciones[0].procesos_Tipo == 'apagada') {
        this.tiempoActualLote.set(maquina.id, '--:--:--');
        this.tiempoActualPieza.set(maquina.id, '--:--:--');
      } else if (this.tiempoLoteSegundos.get(maquina.id) != -1) {
        // LOTE
        var tiempoLoteAux = this.tiempoLoteSegundos.get(maquina.id);
        tiempoLoteAux = tiempoLoteAux + 1;
        this.tiempoLoteSegundos.set(maquina.id, tiempoLoteAux);
        this.tiempoActualLote.set(maquina.id, this.segundosAFormatoHora(this.tiempoLoteSegundos.get(maquina.id)));
        // PIEZA
        var tiempoPiezasAux = this.tiempoPiezasSegundos.get(maquina.id);
        this.tiempoPiezasSegundos.set(maquina.id, tiempoPiezasAux + 1);
        this.tiempoActualPieza.set(maquina.id, this.segundosAFormatoHora(this.tiempoPiezasSegundos.get(maquina.id)));
        // PREPARACION
        var mant = this.tiempoPrepLoteSegundos.get(maquina.id);
        this.tiempoPrepLoteSegundos.set(maquina.id, mant + 1);
        this.tiempoPreparacion.set(maquina.id, this.segundosAFormatoHora(this.tiempoPrepLoteSegundos.get(maquina.id)));
        var mant1 = this.tiempoPrepPiezaSegundos.get(maquina.id);
        this.tiempoPrepPiezaSegundos.set(maquina.id, mant1 + 1);
        this.tiempoPreparacionPieza.set(maquina.id, this.segundosAFormatoHora(this.tiempoPrepPiezaSegundos.get(maquina.id)));
      } else {

        // SI TODAVIA NO TIENE NINGUN DATO DE TIEMPO
        this.tiempoActualLote.set(maquina.id, '--:--:--');
        this.tiempoActualPieza.set(maquina.id, '--:--:--');

        // if (this.mouseOverPorcentajeId != maquina.id) {
        //   if (hanpasadoConAcumulado > 0) {
        //     horas = hapasadoTiempoConAcumulado.toString().split(":");
        //     this.tiempoActualLote.set(maquina.id, hanpasadoConAcumulado.toString() + "d " + horas[0] + "h");
        //     this.tiempoLoteSegundos.set(maquina.id, this.tiempoFormatoDiasAMinutos(this.tiempoActualLote.get(maquina.id)));
        //     this.tiempoActualPieza.set(maquina.id, hanpasadoConAcumulado.toString() + "d " + horas[0] + "h");
        //     this.tiempoPiezasSegundos.set(maquina.id, this.tiempoFormatoDiasAMinutos(this.tiempoActualPieza.get(maquina.id)));
        //     maquina.tiempopasadoConAcumulado = hanpasadoConAcumulado.toString() + " " + this.translateService.instant("numdiashome") + " " + hapasadoTiempoConAcumulado.toString();
        //   }
        //   else {
        //     maquina.tiempopasadoConAcumulado = hapasadoTiempoConAcumulado.toString();
        //     this.tiempoActualLote.set(maquina.id, hapasadoTiempoConAcumulado.toString());
        //     this.tiempoLoteSegundos.set(maquina.id, this.tiempoFormatoDiasAMinutos(this.tiempoActualLote.get(maquina.id)));
        //     this.tiempoActualPieza.set(maquina.id, hapasadoTiempoConAcumulado.toString());
        //     this.tiempoPiezasSegundos.set(maquina.id, this.tiempoFormatoDiasAMinutos(this.tiempoActualPieza.get(maquina.id)));
        //   }
        // }
      }



      var tiempoLoteMinutos = 0;

      // this.dataMaquinas.forEach(maquina => {
      //   if(maquina){}
      // });



      // Mirar si la maquina esta apagada o sin proceso para vaciar los campos
      if (maquina.ejecuciones[0].procesos_Tipo == 'apagada' || maquina.ejecuciones[0].refOF == this.translateService.instant("noproceso")) {
        this.datosMaquinaVaciar(maquina);
      } else {
        this.calcularDesviacionPieza(maquina);
        this.calcularTiempoEstPredPieza(maquina);
        this.calcularDesviacionLote(maquina);
      }
      this.CargarIconosEstadoMaquina(maquina, maquina);
    }
  }

  calcularTiempos() {
    if (this.router.url == '/homev2' || this.router.url == '/') {
      if (this.r1) {
        clearTimeout(this.esperarTiempos);
        if (this.unicaEjecucionTiempos) {
          this.unicaEjecucionTiempos = false;
          this.centroMecanizadoService.get_Tiempos_Lote_Pieza().subscribe(result => {
            var datosTiempo: any = result;
            var tieneTiempo: boolean = false;
            this.dataMaquinas.forEach(maquina => {
              tieneTiempo = false;
              datosTiempo.forEach(tiempo => {
                if (maquina.ejecuciones[0].idHistorico_operaciones == tiempo.idHistoricoOperaciones && maquina.ejecuciones[0].idMaquina == tiempo.idMaquina) {
                  //console.log(maquina.ejecuciones[0].idMaquina);
                  if (tiempo.tiempoUltimaPieza < 0) {
                    this.tiempoPiezasSegundos.set(maquina.ejecuciones[0].idMaquina, 0);
                  } else {
                    this.tiempoPiezasSegundos.set(maquina.ejecuciones[0].idMaquina, tiempo.tiempoUltimaPieza);
                  }
                  if (tiempo.tiempoLote < 0) {
                    this.tiempoLoteSegundos.set(maquina.ejecuciones[0].idMaquina, 0);
                  } else {
                    this.tiempoLoteSegundos.set(maquina.ejecuciones[0].idMaquina, tiempo.tiempoLote);
                  }
                  if (tiempo.tiempoLotePrep < 0) {
                    this.tiempoPrepLoteSegundos.set(maquina.ejecuciones[0].idMaquina, 0);
                  } else {
                    this.tiempoPrepLoteSegundos.set(maquina.ejecuciones[0].idMaquina, tiempo.tiempoLotePrep);
                  }
                  if (tiempo.tiempoUltimaPiezaPrep < 0) {
                    this.tiempoPrepPiezaSegundos.set(maquina.ejecuciones[0].idMaquina, 0);
                  } else {
                    this.tiempoPrepPiezaSegundos.set(maquina.ejecuciones[0].idMaquina, tiempo.tiempoUltimaPiezaPrep);
                  }

                  tieneTiempo = true;
                }
              });
              if (!tieneTiempo) {
                this.tiempoPiezasSegundos.set(maquina.ejecuciones[0].idMaquina, -1);
                this.tiempoLoteSegundos.set(maquina.ejecuciones[0].idMaquina, -1);
                this.tiempoPrepLoteSegundos.set(maquina.ejecuciones[0].idMaquina, -1);
              }
              this.cambioContador.set(maquina.ejecuciones[0].idMaquina, maquina.ejecuciones[0].contador);
              this.cambioIdOperacion.set(maquina.ejecuciones[0].idMaquina, maquina.ejecuciones[0].idHistorico_operaciones);

              this.dataMaquinas.forEach(maquina => {
                //maquina.loading = false;
                setTimeout((l) => maquina.loading = false, 1000);
                // setTimeout((l) => this.loadingDatosTiempo.set(ejecucion.idMaquina, false), 1000);
                // this.loadingDatosTiempo.set(ejecucion.idMaquina, false);
              });

            });
            //this.unicaEjecucionTiempos = true;
            setTimeout((u) => this.unicaEjecucionTiempos = true, 2000);
            //console.log("...Actualizacion de tiempos...")
            if (this.router.url == '/homev2' || this.router.url == '/') {
              this.timeOutTiempos = setTimeout((c) => this.calcularTiempos(), 120000);
            }
          });
        }

      } else {
        if (this.router.url == '/homev2' || this.router.url == '/') {
          this.esperarTiempos = setTimeout((e) => this.calcularTiempos(), 1000);
        }
      }
    }


  }

  cargaLoading(id: number) {
    this.loadingDatosTiempo.set(id, false);
  }

  CargarIconosEjes(maquina: any) {
    maquina.alarmas1 = {
      temperatura: {
        mostrar: false
      },
      warning:
      {
        mostrar: false
      }
    };

    maquina.alarmas2 = {
      temperatura: {
        mostrar: false
      },
      warning:
      {
        mostrar: false
      }
    }

    maquina.alarmas3 = {
      temperatura: {
        mostrar: false
      },
      warning:
      {
        mostrar: false
      }
    }

    maquina.alarmas4 = {
      temperatura: {
        mostrar: false
      },
      warning:
      {
        mostrar: false
      }
    }

    if (maquina.ejes.length > 0) {
      try {
        var tempalarm1 = maquina.ejes[0].tempMax;
        var tempalerta1 = maquina.ejes[0].tempAlarm;
        var consumAlerta1 = maquina.ejes[0].consumAlarm;
        var consumAlarma1 = maquina.ejes[0].consumMax;
      } catch (ex) {

      }

      try {
        var tempalarm2 = maquina.ejes[1].tempMax;
        var tempalerta2 = maquina.ejes[1].tempAlarm;
        var consumAlerta2 = maquina.ejes[1].consumAlarm;
        var consumAlarma2 = maquina.ejes[1].consumMax;
      } catch (ex) {

      }
      try {
        var tempalarm3 = maquina.ejes[2].tempMax;
        var tempalerta3 = maquina.ejes[2].tempAlarm;
        var consumAlerta3 = maquina.ejes[2].consumAlarm;
        var consumAlarma3 = maquina.ejes[2].consumMax;
      } catch (ex) {

      }
      try {
        var tempalarm4 = maquina.ejes[3].tempMax;
        var tempalerta4 = maquina.ejes[3].tempAlarm;
        var consumAlerta4 = maquina.ejes[3].consumAlarm;
        var consumAlarma4 = maquina.ejes[3].consumMax;
      } catch (ex) {

      }

      try {
        var temp1, temp2, temp3, temp4, consum1, consum2, consum3, consum4;
        if (maquina.tiempoReal[0] != undefined)
          temp1 = maquina.tiempoReal[0].temp;
        else
          temp1 = 0;

        if (maquina.tiempoReal[1] != undefined)
          temp2 = maquina.tiempoReal[1].temp;
        else
          temp2 = 0;

        if (maquina.tiempoReal[2] != undefined)
          temp3 = maquina.tiempoReal[2].temp;
        else
          temp3 = 0;

        if (maquina.tiempoReal[3] != undefined)
          temp4 = maquina.tiempoReal[3].temp;
        else
          temp4 = 0;
      } catch (ex) {

      }

      try {

        if (maquina.tiempoReal[0] != undefined)
          consum1 = maquina.tiempoReal[0].consumo;
        else
          consum1 = 0;

        if (maquina.tiempoReal[1] != undefined)
          consum2 = maquina.tiempoReal[1].consumo;
        else
          consum2 = 0;

        if (maquina.tiempoReal[2] != undefined)
          consum3 = maquina.tiempoReal[2].consumo;
        else
          consum3 = 0;

        if (maquina.tiempoReal[3] != undefined)
          consum4 = maquina.tiempoReal[3].consumo;
        else
          consum4 = 0;
      }
      catch (ex) { }

      try {

        if (temp1 > tempalerta1 && tempalarm1 != -1) {
          maquina.ejes[0].tempvisible = true;
          maquina.ejes[0].logoalarmatemp = "assets/tempAmarillo.png";
          if (temp1 > tempalarm1) {
            maquina.ejes[0].logoalarmatemp = "assets/tempRojo.png";
          }
        }

        if (consum1 > consumAlerta1 && consumAlarma1 != -1) {
          maquina.ejes[0].consumvisible = true;
          maquina.ejes[0].logoalarmaconsum = "assets/warningAmarillo.png";
          if (consum1 > consumAlarma1) {
            maquina.ejes[0].logoalarmaconsum = "assets/warningRojo.png";
          }
        }
      }
      catch (ex) {


      }


      try {

        if (temp2 > tempalerta2 && tempalarm2 != -2) {
          maquina.ejes[1].tempvisible = true;
          maquina.ejes[1].logoalarmatemp = "assets/tempAmarillo.png";
          if (temp2 > tempalarm2) {
            maquina.ejes[1].logoalarmatemp = "assets/tempRojo.png";
          }
        }

        if (consum2 > consumAlerta2 && consumAlarma2 != -2) {
          maquina.ejes[1].consumvisible = true;
          maquina.ejes[1].logoalarmaconsum = "assets/warningAmarillo.png";
          if (consum2 > consumAlarma2) {
            maquina.ejes[1].logoalarmaconsum = "assets/warningRojo.png";
          }
        }
      }
      catch (ex) {

      }

      try {

        if (temp3 > tempalerta3 && tempalarm3 != -3) {
          maquina.ejes[2].tempvisible = true;
          maquina.ejes[2].logoalarmatemp = "assets/tempAmarillo.png";
          if (temp3 > tempalarm3) {
            maquina.ejes[2].logoalarmatemp = "assets/tempRojo.png";
          }
        }

        if (consum3 > consumAlerta3 && consumAlarma3 != -3) {
          maquina.ejes[2].consumvisible = true;
          maquina.ejes[2].logoalarmaconsum = "assets/warningAmarillo.png";
          if (consum3 > consumAlarma3) {
            maquina.ejes[2].logoalarmaconsum = "assets/warningRojo.png";
          }
        }
      }
      catch (ex) {
      }

      try {
        if (temp4 > tempalerta4 && tempalarm4 != -4) {
          maquina.ejes[3].tempvisible = true;
          maquina.ejes[3].logoalarmatemp = "assets/tempAmarillo.png";
          if (temp4 > tempalarm4) {
            maquina.ejes[3].logoalarmatemp = "assets/tempRojo.png";
          }
        }

        if (consum4 > consumAlerta4 && consumAlarma4 != -4) {
          maquina.ejes[3].consumvisible = true;
          maquina.ejes[3].logoalarmaconsum = "assets/warningAmarillo.png";
          if (consum4 > consumAlarma4) {
            maquina.ejes[3].logoalarmaconsum = "assets/warningRojo.png";
          }

        }
      }
      catch (ex) {
      }

    }
  }

  RedirigirRendimiento(idMaquina) {
    this.router.navigate(["informerendimiento/" + idMaquina]);
  }

  HanPasadoDias(fechaOrig, ahora, tiempoAcumuladoOperacion) {
    var dt1 = new Date(fechaOrig);
    var dt2 = new Date(ahora);
    var difSegundos = (dt2.getTime() - dt1.getTime()) / 1000;
    difSegundos = difSegundos + tiempoAcumuladoOperacion;
    return Math.floor(difSegundos / (60 * 60 * 24));
  }

  RedirigirAProceso(idmaquina) {

    var maquina = this.maquinas.find(x => x.id === idmaquina);

    if (maquina.tipo_maquina == 1) {
      if (!maquina.puestoOffline) this.router.navigate(["procesos/" + idmaquina]);
      if (maquina.puestoOffline) this.router.navigate(["procesosoffline/" + idmaquina]);
    }

    if (maquina.tipo_maquina == 2) {
      this.router.navigate(["procesosextrusora/" + idmaquina]);
    }

    if (maquina.tipo_maquina == 3) {
      this.router.navigate(["procesosimpresora/" + idmaquina]);
    }

    if (maquina.tipo_maquina == 4) {
      this.router.navigate(["procesosinyectora/" + idmaquina]);
    }

    if (maquina.tipo_maquina == 5) {
      this.router.navigate(["procesoshorno/" + idmaquina]);
    }

    if (maquina.tipo_maquina == 9) {
      this.router.navigate(["procesosaditivo/" + idmaquina]);
    }

    if (maquina.tipo_maquina == 12) {
      this.router.navigate(["procesoshornotemple/" + idmaquina]);
    }

  }

  RedirigirAProcesoInstalacion(idinstalacion) {
    this.router.navigate(["procesosinstalacion/" + idinstalacion]);
  }

  HanPasadoTiempo(fechaOrig, ahora, tiempoAcumuladoOperacion) {
    let diffInMilliSeconds = Math.abs(fechaOrig - ahora) / 1000;
    diffInMilliSeconds = diffInMilliSeconds + tiempoAcumuladoOperacion;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600);
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    // calculate minutes
    const seconds = Math.floor(diffInMilliSeconds % 60);
    diffInMilliSeconds -= minutes * 60;

    let difference = '';

    if (hours < 10) difference = "0" + hours.toString();
    else difference = hours.toString();

    difference = difference + ":";
    if (minutes < 10) difference = difference + "0" + minutes.toString();
    else difference = difference + minutes.toString();

    difference = difference + ":";
    if (seconds < 10) difference = difference + "0" + seconds.toString();
    else difference = difference + seconds.toString();

    return difference;
  }

  CargarIconosEstadoMaquina(maquina, maquina2) {
    var tipoProceso = maquina.ejecuciones[0].procesos_Tipo; //"apagada", "parada", "ejecucion", "preparacion" o "mantenimiento" o "alarma"
    // VALORES POR DEFECTO
    maquina.estadoimg = "assets/dcg/dcg12-apagada.png";
    this.piezaimgMap.set(maquina.id, "assets/dcg/dcg12-apagada.png");
    this.preparacionimgMap.set(maquina.id, "assets/dcg/dcg12-preparacion.png");
    maquina.porcentaje = "";
    if (this.tiempoPiezasSegundos.get(maquina.id) < 1 || this.tiempoPiezasSegundos.size == 0) {
      maquina.estadoimg = "assets/dcg/dcg12-" + tipoProceso + ".png";
      this.piezaimgMap.set(maquina.id, "assets/dcg/dcg12-" + tipoProceso + ".png");
      this.preparacionimgMap.set(maquina.id, "assets/dcg/dcg12-preparacion.png");
      maquina.porcentaje = "";
    } else {
      var ahora;
      if (maquina.ejecuciones[0].fechaServidor != undefined) {
        ahora = new Date(Date.parse(maquina.ejecuciones[0].fechaServidor));//this.myFunctions.getDateNow();
        this.horaServidor = ahora;
      } else {
        ahora = this.horaServidor;
      }
      var fechaOrig = new Date(Date.parse(maquina.ejecuciones[0].fechainicio));
      var tiempoAcumuladoOperacion = maquina.ejecuciones[0].tiempoAcumuladoOperacion;

      var dif = ahora.getTime() - fechaOrig.getTime();

      var Seconds_from_T1_to_T2 = dif / 1000;
      Seconds_from_T1_to_T2 = Seconds_from_T1_to_T2 + tiempoAcumuladoOperacion;
      //var segundosfiff = Math.floor(Seconds_from_T1_to_T2);
      var segundosfiff = Math.floor(this.tiempoLoteSegundos.get(maquina.id));

      if (maquina.ejecuciones[0].procesos_Tipo != 'apagada') {
        var docentaje = this.calcularPorcentaje(segundosfiff, 12, maquina.ejecuciones[0].tiempoEstimadoSegundos, 1, true);
        var porcentaje = this.calcularPorcentaje(segundosfiff, 100, maquina.ejecuciones[0].tiempoEstimadoSegundos, 1, true);

        if (this.tiempoLoteSegundos.get(maquina.id) < maquina.ejecuciones[0].tiempoEstimadoSegundos) {
          maquina.porcentaje = porcentaje + "%";
          maquina.estadoimg = "assets/dcg/dcg" + docentaje + "-" + tipoProceso + ".png";
        } else {
          maquina.porcentaje = "100%";
          maquina.estadoimg = "assets/dcg/dcg12-" + tipoProceso + ".png";
        }

        var preparacionDocentaje = this.calcularPorcentaje(this.tiempoPrepLoteSegundos.get(maquina.id), 12, maquina.ejecuciones[0].tiempoPreparacionSegundos, 1, true);
        var preparacionPorcentaje = this.calcularPorcentaje(this.tiempoPrepLoteSegundos.get(maquina.id), 100, maquina.ejecuciones[0].tiempoPreparacionSegundos, 1, true);
        // if(maquina.id = 96){
        //   console.log("Denbora seg: " + this.tiempoPrepLoteSegundos.get(maquina.id));
        //   console.log("Est seg: " + this.preparacionEstMap.get(maquina.id));
        //   console.log("preparacionDocentaje: " + preparacionDocentaje);
        //   console.log("preparacionPorcentaje: " + preparacionPorcentaje);

        // }

        if (this.tiempoActualPieza.get(maquina.id) != undefined) {
          if (!this.tiempoActualPieza.get(maquina.id).includes('-')) {
            if (this.tiempoActualPieza.get(maquina.id).includes('d')) {
              var tiempoPasadoPieza = this.tiempoFormatoDiasAMinutos(this.tiempoActualPieza.get(maquina.id)) * 60;
              var piezaDocentaje = this.calcularPorcentaje(tiempoPasadoPieza, 12, Math.floor(parseInt(maquina.ejecuciones[0].tiempoEstimadoSegundos.toString()) / parseInt(maquina.ejecuciones[0].cantidad.toString())), 1, true);
              var piezaPorcentaje = this.calcularPorcentaje(tiempoPasadoPieza, 100, Math.floor(parseInt(maquina.ejecuciones[0].tiempoEstimadoSegundos.toString()) / parseInt(maquina.ejecuciones[0].cantidad.toString())), 1, true);
              var preparacionDocentaje = this.calcularPorcentaje(tiempoPasadoPieza, 12, this.preparacionEstMap.get(maquina.id), 1, true);
              var preparacionPorcentaje = this.calcularPorcentaje(tiempoPasadoPieza, 100, this.preparacionEstMap.get(maquina.id), 1, true);
            } else if (this.tiempoActualPieza.get(maquina.id).includes(':')) {
              var tiempoPasadoPieza = this.tiempoFormatoHorasAMinutos(this.tiempoActualPieza.get(maquina.id)) * 60;
              var piezaDocentaje = this.calcularPorcentaje(tiempoPasadoPieza, 12, Math.floor(parseInt(maquina.ejecuciones[0].tiempoEstimadoSegundos.toString()) / parseInt(maquina.ejecuciones[0].cantidad.toString())), 1, true);
              var piezaPorcentaje = this.calcularPorcentaje(tiempoPasadoPieza, 100, Math.floor(parseInt(maquina.ejecuciones[0].tiempoEstimadoSegundos.toString()) / parseInt(maquina.ejecuciones[0].cantidad.toString())), 1, true);
            }
            this.piezaimgMap.set(maquina.id, "assets/dcg/dcg" + piezaDocentaje + "-" + tipoProceso + ".png")
            this.piezaPorcentajeMap.set(maquina.id, piezaPorcentaje + "%");
            this.lotePorcentajeMap.set(maquina.id, maquina.porcentaje);
            this.preparacionimgMap.set(maquina.id, "assets/dcg/dcg" + preparacionDocentaje + "-preparacion.png")
            this.preparacionPorcentajeMap.set(maquina.id, preparacionPorcentaje + "%");
          }
        }
      }
    }
  }

  //Si estamos encima de algun porcentaje entonces guardar el id de la maquina para no actualizar ese tooltip
  mouseOverPorcentajeId = undefined;
  mouseOverPorcentaje(id) {
    this.mouseOverPorcentajeId = id;
  }
  mouseOutPorcentaje(id) {
    this.mouseOverPorcentajeId = undefined;
  }
  calcularDesviacionPieza(maquina: any) {

    try {
      if (this.maxContadorTerminado.get(maquina.id) != 0 && !(this.tiempoActualLote.get(maquina.id).includes('--:--:--'))) {
        this.tiempoAcumuladoAux = this.tiempoActualPieza.get(maquina.id).toString();
        this.listaTiempo = this.tiempoAcumuladoAux.split(' ');

        if (this.listaTiempo.length > 2) {
          this.tiempoAcumuladoAux = "";
          this.tiempoAcumuladoAux = this.listaTiempo[2].toString();
          this.listaTiempo2 = this.tiempoAcumuladoAux.split(':');
          this.tiempoAcumulado = parseInt(this.listaTiempo[0].toString()) * 1440 + parseInt(this.listaTiempo2[0].toString()) * 60 + parseInt(this.listaTiempo2[1].toString());
        } else {
          if (this.tiempoAcumuladoAux.includes("h")) {
            this.tiempoAcumuladoAux = this.tiempoAcumuladoAux.trim()
            this.listaTiempo = this.tiempoAcumuladoAux.split('d');
            this.tiempoAcumulado = parseInt(this.listaTiempo[0].toString()) * 3600 + parseInt(this.listaTiempo[1].replace("h", "".toString())) * 60;
          } else {
            this.listaTiempo = this.tiempoAcumuladoAux.split(':');
            this.tiempoAcumulado = parseInt(this.listaTiempo[0].toString()) * 3600 + parseInt(this.listaTiempo[1].toString()) * 60 + parseInt(this.listaTiempo[2].toString());
          }
        }

        // Cycle time (Tiempo lote - tiempo pieza actual) / piezas hechas
        var lagAcumulado = this.tiempoActualLote.get(maquina.id);
        var lagPieza = this.tiempoActualPieza.get(maquina.id).toString();
        //console.log("=>" + lagAcumulado + " " +this.translateService.instant('numdiashome').toLowerCase());
        if (lagAcumulado.includes("d")) {
          var tiempoAcumulado = this.tiempoFormatoDiasAMinutos(lagAcumulado) * 60;
        } else if (lagAcumulado.includes(':')) {
          var tiempoAcumulado = this.tiempoFormatoHorasASegundos(lagAcumulado);
        }
        if (this.tiempoActualPieza.get(maquina.id) != undefined) {
          if (lagPieza.includes('d')) {
            var tiempoPiezaActual = this.tiempoFormatoDiasAMinutos(lagPieza) * 60;
          } else if (lagPieza.includes(':')) {
            var tiempoPiezaActual = this.tiempoFormatoHorasASegundos(lagPieza);
          }
        }
        if (this.maxContadorTerminado.get(maquina.id) > 0) {
          this.tiempoMedioPieza = Math.floor(Math.abs(this.tiempoLoteSegundos.get(maquina.id) - this.tiempoPiezasSegundos.get(maquina.id)) / this.maxContadorTerminado.get(maquina.id));
        } else {
          this.tiempoMedioPieza = this.tiempoLoteSegundos.get(maquina.id) - this.tiempoPiezasSegundos.get(maquina.id);
        }
        // Dar formato a cycle time
        var cycleTimeFormatoHora = this.segundosAFormatoHora(this.tiempoMedioPieza);
        if (this.tiempoActualLote.get(maquina.id) == '--:--:--' || this.tiempoActualPieza.get(maquina.id) == '--:--:--') {
          this.piezaTiempoMedioMap.set(maquina.id, '--:--:--');
        } else {
          this.piezaTiempoMedioMap.set(maquina.id, cycleTimeFormatoHora);
        }

        // Desviacion pieza
        if (this.piezaEstMap.get(maquina.id) != undefined) {
          if (this.piezaEstMap.get(maquina.id).includes('d')) {
            var tiempoEstimadoPieza = this.tiempoFormatoDiasAMinutos(this.piezaEstMap.get(maquina.id)) * 60;
          } else if (this.piezaEstMap.get(maquina.id).includes(':')) {
            var tiempoEstimadoPieza = this.tiempoFormatoHorasASegundos(this.piezaEstMap.get(maquina.id));
          }
        } else {
          var tiempoEstimadoPieza = 0;
        }

        this.desviacionPieza = this.tiempoMedioPieza - tiempoEstimadoPieza;
        // Dar formato a desviacion pieza
        if (this.desviacionPieza < 0) {
          if (Math.abs(this.desviacionPieza) > 3600) {
            this.piezaDesviacionMap.set(maquina.id, '- ' + this.segundosAFormatoHora(Math.abs(this.desviacionPieza)));
          } else {
            this.piezaDesviacionMap.set(maquina.id, '- ' + "00:" + this.pad(Math.floor(Math.abs(this.desviacionPieza) / 60)) + ":" + this.pad(Math.floor(Math.abs(this.desviacionPieza)) % 60));
          }
        } else {
          if (Math.abs(this.desviacionPieza) > 3600) {
            this.piezaDesviacionMap.set(maquina.id, "+ " + this.segundosAFormatoHora(Math.abs(this.desviacionPieza)));
          } else {
            this.piezaDesviacionMap.set(maquina.id, '+ ' + "00:" + this.pad(Math.floor(Math.abs(this.desviacionPieza) / 60)) + ":" + this.pad(Math.floor(Math.abs(this.desviacionPieza)) % 60));
          }
        }
      } else {
        this.tiempoMedioPieza = 0;
        this.piezaTiempoMedioMap.set(maquina.id, "--:--:--");
        this.desviacionPieza = 0;
        this.piezaDesviacionMap.set(maquina.id, "+ --:--:--");
      }
    } catch (error) {
      this.tiempoMedioPieza = 0;
      this.piezaTiempoMedioMap.set(maquina.id, "--:--:--");
      this.desviacionPieza = 0;
      this.piezaDesviacionMap.set(maquina.id, "+ --:--:--");
    }


  }

  calcularDesviacionLote(maquina: any) { //total time
    var dias = 0;
    var horas = 0;
    if (this.tiempoActualLote.get(maquina.id) != undefined && !(this.tiempoActualLote.get(maquina.id).includes('--:--:--'))) {
      this.tiempoAcumuladoAux = this.tiempoActualLote.get(maquina.id);
    } else {
      this.tiempoAcumuladoAux = "00:00:00";
    }
    // Mirar si tiempo contiene dias o no
    this.listaTiempo = this.tiempoAcumuladoAux.split(' ');
    //Contiene dias
    if (this.listaTiempo.length > 1) {
      // this.tiempoAcumuladoAux = '';
      // this.tiempoAcumuladoAux = this.listaTiempo[2].toString();
      // this.listaTiempo2 = this.tiempoAcumuladoAux.split(':');
      //this.tiempoAcumulado = parseInt(this.listaTiempo[0].replace('d', '')) * 86400 + parseInt(this.listaTiempo2[1].replace('h', '')) * 3600 + parseInt(this.listaTiempo2[1].toString()) * 60 + parseInt(this.listaTiempo2[2].toString());
      this.tiempoAcumulado = parseInt(this.tiempoLoteSegundos.get(maquina.id));
    } else { // No llega al dia
      this.listaTiempo = this.tiempoAcumuladoAux.split(':');
      //this.tiempoAcumulado = parseInt(this.listaTiempo[0].toString()) * 3600 + parseInt(this.listaTiempo[1].toString()) * 60 + parseInt(this.listaTiempo[2].toString());
      this.tiempoAcumulado = parseInt(this.tiempoLoteSegundos.get(maquina.id));
    }
    if (maquina.tiempoEst != undefined) {
      this.tiempoEstimadoLoteAux = maquina.tiempoEst.toString();
    } else {
      this.tiempoEstimadoLoteAux = "00:00:00";
    }
    if (maquina.tiempoEst == "--:--:--") {
      this.tiempoEstimadoLoteAux = "00:00:00";
    }

    this.listaTiempo = this.tiempoEstimadoLoteAux.split(' ');

    if (this.listaTiempo.length > 2) {
      this.tiempoEstimadoLoteAux = '';
      this.tiempoEstimadoLoteAux = this.listaTiempo[2].toString();
      this.listaTiempo2 = this.tiempoEstimadoLoteAux.split(':');
      this.tiempoEstimadoLote = parseInt(this.listaTiempo[0]) * 86400 + parseInt(this.listaTiempo2[0]) * 3600 + parseInt(this.listaTiempo2[1].toString()) * 60 + parseInt(this.listaTiempo2[2].toString());
    } else {
      this.listaTiempo = this.tiempoEstimadoLoteAux.split(':');
      this.tiempoEstimadoLote = parseInt(this.listaTiempo[0].toString()) * 3600 + parseInt(this.listaTiempo[1]) * 60 + parseInt(this.listaTiempo[2]);
    }

    this.desviacionLote = this.tiempoAcumulado - this.tiempoEstimadoLote;
    if (isNaN(this.desviacionLote)) {
      this.loteDesviacionMap.set(maquina.id, '--:--:--');
    } else if (this.desviacionLote < 0) {
      if (Math.abs(this.desviacionLote) > 3600) {
        this.loteDesviacionMap.set(maquina.id, '- ' + this.segundosAFormatoHora(Math.abs(this.desviacionLote)));
      } else {
        this.loteDesviacionMap.set(maquina.id, '- ' + "00:" + this.pad(Math.floor(Math.abs(this.desviacionLote) / 60)) + ":" + this.pad(Math.floor(Math.abs(this.desviacionLote) % 60)));
      }
    } else {
      if (Math.abs(this.desviacionLote) > 3600) {
        this.loteDesviacionMap.set(maquina.id, '+ ' + this.segundosAFormatoHora(Math.abs(this.desviacionLote)));
      } else {
        this.loteDesviacionMap.set(maquina.id, '+ ' + "00:" + this.pad(Math.floor(Math.abs(this.desviacionLote) / 60)) + ":" + this.pad(Math.floor(Math.abs(this.desviacionLote) % 60)));
      }
    }

    // CALCULAR DESVIACION DE PREPARACION
    if (this.tiempoPrepLoteSegundos.get(maquina.id) > maquina.ejecuciones[0].tiempoPreparacionSegundos) {
      this.desviacionLotePreparacionMap.set(maquina.id, "+ " + this.segundosAFormatoHora(this.tiempoPrepLoteSegundos.get(maquina.id) - maquina.ejecuciones[0].tiempoPreparacionSegundos))
    } else {
      this.desviacionLotePreparacionMap.set(maquina.id, "- " + this.segundosAFormatoHora(maquina.ejecuciones[0].tiempoPreparacionSegundos - this.tiempoPrepLoteSegundos.get(maquina.id)))
    }
  }

  calcularTiempoEstPredPieza(maquina: any) {

    if (maquina.ejecuciones[0].tiempoEstimadoSegundos == 0) {
      this.piezaEstMap.set(maquina.id, "00:00:00");
      this.loteEstMap.set(maquina.id, "00:00:00");
    } else {
      this.loteEstMap.set(maquina.id, this.segundosAFormatoHora(maquina.ejecuciones[0].tiempoEstimadoSegundos))
      this.tiempoEstAux = parseInt(maquina.ejecuciones[0].tiempoEstimadoSegundos.toString()) / parseInt(maquina.ejecuciones[0].cantidad.toString());

      var tiempoConFormato = this.segundosAFormatoHora(this.tiempoEstAux);
      this.piezaEstMap.set(maquina.id, tiempoConFormato);


    } if (maquina.ejecuciones[0].tiempoPredSegundos == 0) {
      this.piezaPredMap.set(maquina.id, "00:00:00");
      this.lotePredMap.set(maquina.id, "00:00:00");
      this.preparacionEstMap.set(maquina.id, "00:00:00");
    } else {
      this.lotePredMap.set(maquina.id, this.segundosAFormatoHora(maquina.ejecuciones[0].tiempoPredSegundos))
      this.preparacionEstMap.set(maquina.id, this.segundosAFormatoHora(maquina.ejecuciones[0].tiempoPreparacionSegundos))
      this.tiempoPredAux = maquina.ejecuciones[0].tiempoPredSegundos / maquina.ejecuciones[0].cantidad;

      var tiempoConFormato = this.segundosAFormatoHora(this.tiempoPredAux);
      this.piezaPredMap.set(maquina.id, tiempoConFormato);
    }


  }

  pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }

  validateOF(maquina: any) {
    if (maquina.ejecuciones[0].refOF != this.translateService.instant("noproceso"))
      this.isOFMap.set(maquina.id, true);
    else
      this.isOFMap.set(maquina.id, false);
  }

  datosMaquinaVaciar(maquina: any) {
    this.piezaPorcentajeMap.set(maquina.id, "-");
    if (maquina.ejecuciones[0].refOF == this.translateService.instant("noproceso")) {
      this.piezaimgMap.set(maquina.id, "assets/dcg/dcg0-" + maquina.ejecuciones[0].procesos_Tipo + ".png");
    } else {
      this.piezaimgMap.set(maquina.id, "assets/dcg/dcg0-apagada.png");
      this.lotePorcentajeMap.set(maquina.id, "-");
    }
    // this.tiempoActualPieza.set(maquina.id, "--:--:--");
    this.piezaTiempoMedioMap.set(maquina.id, "--:--:--");
    this.piezaDesviacionMap.set(maquina.id, "--:--:--");
    this.piezaEstMap.set(maquina.id, "--:--:--");
    this.piezaPredMap.set(maquina.id, "--:--:--");
    this.loteEstMap.set(maquina.id, "--:--:--");
    this.lotePredMap.set(maquina.id, "--:--:--");
    this.preparacionEstMap.set(maquina.id, "--:--:--");
    this.tiempoActualLote.set(maquina.id, "--:--:--");
    this.loteDesviacionMap.set(maquina.id, "--:--:--");
  }

  tiempoFormatoDiasAMinutos(tiempo: string) {
    var tiempoAux = tiempo.split(' ');
    // tratar dias
    var dias = tiempoAux[0];
    dias = dias.replace('d', '');
    var diasMinutos = parseInt(dias) * 24 * 60;
    // tratar horas
    if (tiempo.includes(':')) {
      var horasAux = tiempo.split(':');
      var horas = horasAux[0];
      var horasMinutos = parseInt(horas) * 60;
    } else {
      var horas = tiempoAux[1].replace('h', '');
      var horasMinutos = parseInt(horas) * 60;
    }

    return diasMinutos + horasMinutos;
  }

  tiempoFormatoHorasAMinutos(tiempo: string) {
    if (tiempo == undefined) {
      return 0;
    }
    var tiempoAux = tiempo.split(':');
    // Tratar horas
    var horasMinutos = parseInt(tiempoAux[0]) * 60;
    // Tratar minutos
    var minutos = parseInt(tiempoAux[1]);
    return horasMinutos + minutos;
  }

  tiempoFormatoHorasASegundos(tiempo: string) {
    if (tiempo == undefined) {
      return 0;
    }
    var tiempoAux = tiempo.split(':');
    // Tratar horas
    var horasMinutos = parseInt(tiempoAux[0]) * 3600;
    // Tratar minutos
    var minutos = parseInt(tiempoAux[1]) * 60;
    // Tratar segundos
    var segundos = parseInt(tiempoAux[2]);
    return horasMinutos + minutos + segundos;
  }

  segundosAFormatoHora(tiempo: number) {
    // if (tiempo < this.segundosDeUnDia) {
    var horas = this.pad(Math.floor(tiempo / 3600));
    var minutos = this.pad(Math.floor((tiempo % 3600) / 60));
    var segundos = this.pad(Math.floor(((tiempo % 3600) % 60) / 1));
    var formatoHora = horas + ":" + minutos + ":" + segundos;
    // } else {
    //   var dias = Math.floor((tiempo / 3600) / 24);
    //   var horas = this.pad(Math.floor((tiempo / 3600) % 24));
    //   var formatoHora = dias + "d " + horas + "h"
    // }
    return formatoHora;
  }
}
