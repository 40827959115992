<!-- TODO -->
<form [formGroup]="form" (submit)="onSubmit(contentloading,$event)">

  <!-- INFO -->
  <div class="card">
    <div class="card-header">
      <h3>{{ 'datos' | translate }}</h3>
      <div class="plegarpanel"></div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-7">
          <div class="row">
            <!-- NOMBRE -->
            <div class="col-md-6">
              <div class="form-group">
                <label class="control-label">{{ 'nombre' | translate }}</label>
                <div class="caja">
                  <input formControlName="nombre" kendoTextBox [(value)]="nombre" />
                </div>
              </div>
            </div>
            <!-- FECHA INICIO -->
            <div class="col-md-3">
              <div class="form-group">
                <label class="control-label">{{ 'fechainicio' | translate }}</label>
                <div class="caja">
                  <kendo-datepicker formControlName="fechaini" ID="rntFinicio" class="form-control" [(value)]="fechaini"></kendo-datepicker> <!--[(placeholder)]="fechaHoyStrIni"-->
                </div>
              </div>
            </div>
            <!-- FECHA FIN -->
            <div class="col-md-3">
              <div class="form-group">
                <label class="control-label">{{ 'fechafin' | translate }}</label>
                <div class="caja">
                  <kendo-datepicker formControlName="fechafin" ID="rntFinicio" class="form-control" [(value)]="fechafin"></kendo-datepicker> <!--[(placeholder)]="fechaHoyStrFin"-->
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- RESPONSABLE -->
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label">{{ 'responsable' | translate }}</label>
                <div class="caja">
                  <kendo-dropdownlist style="width:100%" formControlName="idresponsable" [data]="responsables" valuePrimitive="true"
                                      [textField]="'nombrecompleto'" [valueField]="'id'" [(ngModel)]="idResponsable">
                  </kendo-dropdownlist>
                </div>
              </div>
            </div>
            <!-- SECCION -->
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label">{{ 'seccion' | translate }}</label>
                <div class="caja">
                  <kendo-multiselect kendoMultiSelectSummaryTag [(data)]="groupedSeccion"
                                     [textField]="'nombre'"
                                     [valueField]="'id'"
                                     [autoClose]="false"
                                     formControlName="secciones"
                                     [(ngModel)]="seccionesSeleccionadas"
                                     placeholder="{{ 'secciones' | translate }}"
                                     (valueChange)="filtrarMaquinasPorSeccionGrupo($event)"
                                     style="width: 100%;"
                                     [kendoDropDownFilter]="{operator: 'contains'}">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                      <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seccionesSeleccionadas' | translate }}</ng-container>
                    </ng-template>
                    <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                  </kendo-multiselect>
                </div>
              </div>
            </div>
            <!-- GRUPO MAQUINAS -->
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label">{{ 'grupo' | translate }}</label>
                <div class="caja">
                  <kendo-multiselect kendoMultiSelectSummaryTag [data]="grupos"
                                     [textField]="'nombre'"
                                     [valueField]="'id'"
                                     [autoClose]="false"
                                     formControlName="grupos"
                                     [(ngModel)]="gruposSeleccionados"
                                     placeholder="{{ 'grupoMaquinas' | translate }}"
                                     (valueChange)="filtrarMaquinasPorSeccionGrupo($event)"
                                     style="width: 100%;"
                                     [kendoDropDownFilter]="{operator: 'contains'}">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                      <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'gruposSeleccionados' | translate }}</ng-container>
                    </ng-template>
                  </kendo-multiselect>
                </div>
              </div>
            </div>
            <!-- PERDIDAS DE GRUPO -->
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label">{{ 'perdidasGrupo' | translate }}</label>
                <div class="caja">
                  <kendo-multiselect kendoMultiSelectSummaryTag [data]="gruposPerdidas"
                                     [textField]="'nombre'"
                                     [valueField]="'id'"
                                     [autoClose]="false"
                                     formControlName="grupos"
                                     [(ngModel)]="gruposperdidasseleccionadas"
                                     placeholder="{{ 'perdidasGrupo' | translate }}"
                                     (valueChange)="filtrarPerdidasPorGrupo($event)"
                                     style="width: 100%;"
                                     [kendoDropDownFilter]="{operator: 'contains'}">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                      <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'gruposSeleccionados' | translate }}</ng-container>
                    </ng-template>
                  </kendo-multiselect>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- DESCRIPCION -->
        <div class="col-md-5">
          <div class="form-group">
            <label class="control-label">{{ 'descripcion' | translate }}</label>
            <div class="caja">
              <textarea formControlName="descripcion" kendoTextArea [autoSize]="true" [(value)]="descripcion" style="height: 106px;"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- GRIDS -->
  <div class="row">
    <!-- PERDIDAS -->
    <div class="col">
      <div class="nuevo-panel nuevo-panel-visorof" visible="false">
        <div class="form-horizontal ">
          <div class="row">
            <label class="title-conf"></label>
          </div>
          <kendo-grid [data]="gridViewPerdidas"
                      kendoGridSelectBy="id"
                      [selectedKeys]="perdidasseleccionadas"
                      [pageSize]="10"
                      [skip]="0"
                      [pageable]="pageablePerdidas"
                      (pageChange)="pageChangePerdidas($event)"
                      [selectable]="{ checkboxOnly: true, mode: 'multiple' }"
                      [height]="270">
            <kendo-grid-checkbox-column [width]="20" showSelectAll="true"></kendo-grid-checkbox-column>
            <kendo-grid-column field="id" hidden="hidden" title="ID" width="40"></kendo-grid-column>
            <kendo-grid-column field="titulo" title="{{ 'perdidas' | translate }}" width="250"></kendo-grid-column>
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
        </div>
      </div>
    </div>
    <!-- MAQUINAS -->
    <div class="col">
      <div class="nuevo-panel nuevo-panel-visorof" visible="false">
        <div class="form-horizontal ">
          <div class="row">
            <label class="title-conf"></label>
          </div>
          <kendo-grid [data]="gridViewMaquinas"
                      kendoGridSelectBy="id"
                      [selectedKeys]="maquinasseleccionadas"
                      [pageSize]="10"
                      [height]="270"
                      [skip]="0"
                      [pageable]="pageableMaquinas"
                      (pageChange)="pageChangeMaquinas($event)"
                      [selectable]="{ checkboxOnly: true, mode: 'multiple' }">
            <kendo-grid-checkbox-column [width]="20" showSelectAll="true"></kendo-grid-checkbox-column>
            <kendo-grid-column field="id" hidden="hidden" title="ID" width="40"></kendo-grid-column>
            <kendo-grid-column field="nombre" title="{{ 'maquinas' | translate }}" width="250"></kendo-grid-column>
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
        </div>
      </div>
    </div>
  </div>

  <!-- BOTONES -->
  <div class="form-group">
    <button type="submit" class="btn btn-primary btn-sm mr-1"  [disabled]="this.user.planesDeAccion<2">{{ 'guardar' | translate}}</button>
    <button type="submit" class="btn btn-danger btn-sm mr-1" (click)="Atras()">{{ 'cancelar' | translate}}</button>
  </div>
</form>

<!-- POPUP: Eliminar seguro? -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'preguntaeliminarpopup' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="Eliminar(contentloading)"  [disabled]="this.user.planesDeAccion<2">{{ 'si' | translate }}</button>
  </div>
</ng-template>

<!-- POPUP: Eliminado -->
<ng-template #contentloading let-modal>
  <div class="modal-body">
    <p id="text_loading_popup"></p>
    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'eliminando' | translate }}...</span>
    </div>
  </div>
</ng-template>
