<!-- PANEL DE CARGA -->
<div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;"></div>
<!--DATOS GRUPO-->
<div class="card w-100">
      <div class="card-header">
            <h3><label>{{ 'datos' | translate }}</label></h3>
      </div>
      <div class="card-body">
            <div class="col ml-2">
                  <div class="float-left mr-2">
                        <kendo-label class="form-check-label" text="{{ 'nombre' | translate}}"> </kendo-label>
                        <div class="caja">
                              <kendo-textbox [(ngModel)]="grupo.nombre"
                                    [ngClass]="{ 'is-invalid': errorNombreVacio }"></kendo-textbox>
                              <div *ngIf="errorNombreVacio" class="invalid-feedback">
                                    <div>{{ 'nombrerequerido' | translate}}</div>
                              </div>
                        </div>
                  </div>
                  <div class="float-left mr-2">
                        <kendo-label class="form-check-label" text="{{ 'periodo' | translate}}"> </kendo-label>
                        <div style="width: 180px;" class="caja" [ngClass]="{ 'is-invalid': errorFechasVacias || errorFechasFuera }">
                              <filtro-fechas [fechaIni]="grupo.inicio" [fechaFin]="grupo.fin"
                                    (newFechaInicio)="grupo.inicio = $event" (newFechaFin)="grupo.fin = $event"
                                    [ngClass]="{ 'is-invalid': errorFechasVacias || errorFechasFuera}"></filtro-fechas>
                              <div *ngIf="errorFechasVacias" class="invalid-feedback">
                                    <div>{{ 'periodoRequerido' | translate}}</div>
                              </div>
                              <div *ngIf="errorFechasFuera" class="invalid-feedback">
                                    <div>{{ 'periodoFueraDeCurso' | translate}}</div>
                              </div>
                        </div>
                  </div>
                  <div class="float-left mr-2">
                        <kendo-label class="form-check-label" text="{{ 'curso' | translate}}"> </kendo-label>
                        <div class="caja">
                              <kendo-dropdownlist [data]="cursos" [valueField]="'id'" [textField]="'nombre'"
                                    [ngModel]="grupo.id_cursoLectivo" [(value)]="JcursoSelected"
                                    [ngClass]="{ 'is-invalid': errorCursoVacio}" (valueChange)="cursoSelectedChange()">
                              </kendo-dropdownlist>
                              <div *ngIf="errorCursoVacio" class="invalid-feedback">
                                    <div>{{ 'errorCursoVacio' | translate}}</div>
                              </div>

                        </div>
                  </div>
                  <div class="float-left mr-2">
                        <kendo-label class="form-check-label" text="{{ 'calendario' | translate}}"> </kendo-label>
                        <div class="caja">
                              <kendo-dropdownlist [data]="calendarios" [valueField]="'id'" [textField]="'nombre'"
                                    [ngModel]="grupo.calendario" [(value)]="JcalendarioSelected"
                                    [ngClass]="{ 'is-invalid': errorCalendarioVacio}"
                                    (valueChange)="calendarioSelectedChange()">
                              </kendo-dropdownlist>
                              <div *ngIf="errorCalendarioVacio" class="invalid-feedback">
                                    <div>{{ 'errorCalendarioVacio' | translate}}</div>
                              </div>
                        </div>
                  </div>
                  <div class="float-left mr-2">
                        <kendo-label class="form-check-label" text="{{ 'tipo' | translate}}"> </kendo-label>
                        <div class="caja">
                              <kendo-dropdownlist [data]="tipos" [valueField]="'id'" [textField]="'nombre'"
                                    [(value)]="JtipoSelected" [ngModel]="grupo.id_tipoGrupo"
                                    [ngClass]="{ 'is-invalid': errorTipoVacio}" (valueChange)="tipoSelectedChange()">
                              </kendo-dropdownlist>
                              <div *ngIf="errorTipoVacio" class="invalid-feedback">
                                    <div>{{ 'errorTipoVacio' | translate}}</div>
                              </div>
                        </div>
                  </div>
                  <div class="float-left mr-2">
                        <kendo-label class="form-check-label" text="{{ 'color' | translate}}"> </kendo-label>
                        <div class="caja">
                              <kendo-colorpicker format="hex" [(ngModel)]="grupo.color"
                                    [ngClass]="{ 'is-invalid': errorColorVacio}"> </kendo-colorpicker>
                              <div *ngIf="errorColorVacio" class="invalid-feedback">
                                    <div>{{ 'errorColorVacio' | translate}}</div>
                              </div>
                        </div>
                  </div>

            </div>
            <div class="col ml-2">
                  <div class="float-right mr-2">
                        <button kendoButton class="btn mr-1  btn-danger btn-sm mr-1" (click)="onClickCancelar()">{{
                              'cancelar' |
                              translate}}</button>
                  </div>
                  <!-- <div class="float-right mr-2">
                        <button kendoButton class="btn mr-1  btn-morado btn-sm mr-1" (click)="duplicarClick()">{{ 'duplicar' |
                              translate}}</button>
                  </div> -->
                  <div class="float-right mr-2">
                        <button kendoButton class="btn btn-success  btn-sm mr-1" (click)="guardarClick()">{{'guardar' |
                              translate}}</button>
                  </div>
            </div>

      </div>

</div>

<div class="row">
      <div class="col">
            <!--TUTORES-->
            <div class="card">
                  <div class="card-header">
                        <h3><label>{{ 'tutor' | translate }}</label></h3>
                  </div>
                  <div class="card-body">
                        <div class="tipo-grid grid-lanzadorof-grupotrabajo">
                              <div class="tipo-grid-linea" *ngFor="let tutor of selectedTutores">
                                    <div class="tipo-grid-col col-grupotrabajo">
                                          <span>{{tutor.nombrecompleto}} </span>
                                    </div>

                                    <div class="tipo-grid-col col-eliminar">
                                          <button Class="flt-eliminar" (click)="annadirParaEliminar('tutor', tutor)"> <i
                                                      class="fas fa-times"></i>
                                          </button>
                                    </div>
                              </div>
                        </div>
                        <div class="seleccion-usuario">
                              <kendo-multiselect [data]="tutores" [textField]="'nombrecompleto'"
                                    [(value)]="multiselectTutores" [filterable]="true"
                                    (filterChange)="filterChangeTutor($event)" (valueChange)="valueChangeTutor($event)"
                                    [ngClass]="{ 'is-invalid': errorTutoresVacios}">

                              </kendo-multiselect>
                              <div *ngIf="errorTutoresVacios" class="invalid-feedback">
                                    <div>{{ 'errorTutoresVacios' | translate}}</div>
                              </div>
                        </div>
                  </div>
            </div>
            <!--ASIGNATURAS/RETOS-->
            <div class="card">
                  <div class="card-header">
                        <h3><label>{{ 'asignaturasRetos' | translate }}</label></h3>
                  </div>
                  <div class="card-body">
                        <div class="tipo-grid grid-lanzadorof-grupotrabajo">
                              <div class="tipo-grid-linea" *ngFor="let asignatura of selectedAsignaturas">
                                    <div class="tipo-grid-col col-asignatura">
                                          <span>{{asignatura.nombre}} </span>
                                    </div>

                                    <!--DOCENTES DE LA ASIGNATURA-->
                                    <div #click class="tipo-grid-col col-asignatura" (click)="asignatura.showMultiselectDocentes = true">
                                          <span *ngIf="!asignatura.showMultiselectDocentes" >
                                            <ng-container  *ngFor="let item of asignatura.selectedDocentes; index as i">
                                              <span *ngIf="i<asignatura.selectedDocentes.length-1">{{item.nombre}} {{item.apellido1}} {{item.apellido2}}, </span>
                                              <span *ngIf="i==asignatura.selectedDocentes.length-1">{{item.nombre}} {{item.apellido1}} {{item.apellido2}} </span>
                                            </ng-container>
                                          </span>
                                      
                                    </div>
                                    <div class="tipo-grid-col col-asignatura" *ngIf="asignatura.showMultiselectDocentes || asignatura.selectedDocentes.length == 0">
                                          <kendo-multiselect #multiselectDocentes [data]="asignatura.docentes" [filterable]="true" [textField]="'nombrecompleto'"
                                            (filterChange)="filterChange($event)" [ngClass]="user-selection" [(value)]="asignatura.selectedDocentes"
                                            [popupSettings]="{ popupClass: 'seleccion-usuario'}" [valueField]="'id'" [valuePrimitive]="false"
                                            (valueChange)="valueChangeDocentes($event, asignatura)"  (close)="asignatura.showMultiselectDocentes = false">
                          
                                            <ng-template kendoMultiSelectTagTemplate let-dataItem>
                                              <span >{{dataItem.nombre}} {{dataItem.apellido1}} {{dataItem.apellido2}}</span>
                                            </ng-template>
                                            <ng-template kendoMultiSelectItemTemplate let-dataItem>
                                              <span><label> {{dataItem.nombre}} {{dataItem.apellido1}} {{dataItem.apellido2}} </label></span>
                                            </ng-template>
                          
                                          </kendo-multiselect>
                                        </div>
                                    <div class="tipo-grid-col col-eliminar">
                                          <button Class="flt-eliminar"
                                                (click)="annadirParaEliminar('asignatura', asignatura)"> <i
                                                      class="fas fa-times"></i>
                                          </button>
                                    </div>
                              </div>
                        </div>
                        <div class="seleccion-usuario">
                              <kendo-multiselect [data]="asignaturasRetos" [textField]="'nombre'"
                                    [(value)]="multiselectAsignaturasRetos"
                                    (valueChange)="valueChangeAsignaturas($event)" [filterable]="true"
                                    [ngClass]="{ 'is-invalid': errorAsignaturasRetosVacios || errorDocentesVacios }"
                                    (filterChange)="asignaturasFilterChange($event)">

                              </kendo-multiselect>
                              <div *ngIf="errorAsignaturasRetosVacios" class="invalid-feedback">
                                    <div>{{ 'errorAsignaturasRetosVacios' | translate}}</div>
                              </div>
                              <div *ngIf="errorDocentesVacios" class="invalid-feedback">
                                    <div>{{ 'errorDocentesVacios' | translate}}</div>
                              </div>
                        </div>

                  </div>
            </div>
      </div>
      <div class="col">
            <!--ALUMNOS-->
            <div class="card">
                  <div class="card-header">
                        <h3><label>{{ 'alumnos' | translate }}</label></h3>
                  </div>
                  <div class="card-body">
                        <div class="tipo-grid grid-lanzadorof-grupotrabajo">
                              <div class="tipo-grid-linea" *ngFor="let alumno of selectedAlumnos">
                                    <div class="tipo-grid-col col-asignatura">
                                          <span>{{alumno.nombrecompleto}} </span>                                       
                                    </div>
                                    <div class="tipo-grid-col col-asignatura">
                                          <span> {{alumno.telefono}}</span>         
                                    </div>
                                    
                                    <div class="tipo-grid-col col-eliminar">
                                          <button Class="flt-eliminar" (click)="annadirParaEliminar('alumno', alumno)">
                                                <i class="fas fa-times"></i>
                                          </button>
                                    </div>
                              </div>
                        </div>
                        <div class="seleccion-usuario">
                              <kendo-multiselect [data]="alumnos" [textField]="'nombrecompleto'"
                                    [(value)]="multiselectAlumnos" [filterable]="true"
                                    (filterChange)="filterChange($event)" (valueChange)="valueChange($event)"
                                    [ngClass]="{ 'is-invalid': errorAlumnosVacios}">

                              </kendo-multiselect>
                              <div *ngIf="errorAlumnosVacios" class="invalid-feedback">
                                    <div>{{ 'errorAlumnosVacios' | translate}}</div>
                              </div>
                        </div>
                  </div>
            </div>
      </div>
</div>
<!---->
<router-outlet></router-outlet>