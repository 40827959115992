import { Component, OnInit, ViewChild } from '@angular/core';

import { TooltipDirective } from '@progress/kendo-angular-tooltip';

import { FormBuilder } from '@angular/forms';

import { Router, ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CentroMecanizadoService, UsuariosService, AlertService, InformeOeeService, MenuService, 
      HistoricoOperacionesService, InformeProyectosService, ConfiguracionService,
      ValidadorService, OperacionesService, OFService, RecetasInduccionService } from '@app/_services';

import { DomSanitizer } from '@angular/platform-browser';

import { MyFunctions } from '@app/_helpers';

import { PageChangeEvent, RowClassArgs, ExcelExportEvent } from '@progress/kendo-angular-grid';
import { Workbook } from '@progress/kendo-angular-excel-export';
import { saveAs } from '@progress/kendo-file-saver';



import { HttpClient } from "@angular/common/http";
import { environment } from '@environments/environment';
import { FileInfo, FileRestrictions, UploadEvent } from '@progress/kendo-angular-upload';


@Component({
  selector: 'app-historicoProcesosInduccionComponent-data',
  templateUrl: 'historicoProcesosInduccion.html'
})

export class HistoricoProcesosInduccionComponent implements OnInit {

  modalReferenceUpload: NgbModalRef;
  @ViewChild('popupSubiImagen') popupSubiImagen: NgbModalRef; //referencia al popup de borrado

  @ViewChild('popupImagenCalidad') popupImagenCalidad: NgbModalRef; //referencia al popup de borrado

  public operacionesSelecteds: any = [];

  Jdata: any;

  //calidad
  mostrarImagenCalidad: Boolean;
  imageToShowCalidad: any;
  archivoAgregar: any;
  archivoAgregarNombre: any;
  dataItemSeleccionado: any;
  //fin calidad
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  public pageSize = 5000;
  public skip = 0;
  public skip2 = 0;
  public skip3 = 0;

  private _kendoFiles: FileInfo[];
  public myFiles: Array<FileInfo> = [];
  modalReferenceloadingupload: NgbModalRef;
  private translate: TranslateService;

  exportarExcel: Boolean = false;

  public todayDate : Date = new Date();

  public primerasPiezasNum;

  public imagePreviews: any[] = [];
  imageToShow: any;
  isImageLoading: boolean;
  irudiZaharraErakutsi: boolean = false;
  public events: string[] = [];
  id: number;
  public uploadSaveUrl;
  public uploadRemoveUrl;
  public hayImagen: boolean;
  public nombreImagen;
  public urlImagen;

  user = this.userService.userValue;

  public infoOF: string;
  public infoCliente: string;
  public infoProyecto: string;
  public infoPlano: string;
  public infoRefPieza: string;
  public infoPieza: string;
  public infonserie: string;
  public infoTiempoRealEjecucion: string;
  public infoTiempoRealPreparacion: string;
  public infoTiempoEstimado: string;
  public infoDesvio: string;
  public infoPorcenDesvio: string;
  public idEstado: number;
  public tipo_maquina: number;
  public idMaquina: number;
  public operacion: string;
  public cantidadLote: string;
  public cantidadLoteHechas: string;
  public infoTiempoRealEjecucionOperacion: string;
  public infoTiempoRealPreparacionOperacion: string;
  public infoTiempoEstimadoOperacion: string;
  public infoDesvioOperacion: string;
  public infoPorcenDesvioOperacion: string;
  public terminado: number;
  public cantidadLoteOperacion: string;
  public cantidadLoteHechasOperacion: string;
  public loteCAFOperacion: string;
  //    grids de operarios
  public Joperarios: JSON;
  public JoperariosView: JSON;
  public Jincidencias: JSON;
  public JincidenciasView: JSON;
  public Jprocesos: JSON;
  public JprocesosView: any;
  //    tipo de operacion: VALOR
  public valorVisible: boolean;
  //    datos de la operacion
  public numeroOperacion: string;
  public nombrePlano: string;
  public idTipoOperacion: number;
  public tipoOperacion: string;
  public nombreOperacion: string;
  public infoTiempoRealOperacion: string;
  public infoTiempoEstimadoOperacionPreparacion: string;
  public infoTiempoEstimadoOperacionEjecucion: string;
  //    tipo de operacion: CONFORMACION
  public confirmacionVisible: boolean;
  public respuestaConfirmacion: string;
  //    valores solicitados en la operacion
  public solicitarVisible: boolean;
  public solicitarEdicionVisible: boolean;
  public solicitarEdicionValor: string;
  public solicitarLoteVisible: boolean;
  public solicitarLoteValor: string;
  public solicitarNserieVisible: boolean;
  public solicitarNserieValor: string;
  //    imagen de operacion
  public imagenOperacionVisible: boolean;
  public imagenOperacion: string;
  //    imagen de operario
  public imagenOperarioVisible: boolean;
  //    visibilidad botones
  public visibleCancelar: boolean;
  public visibleLiberar: boolean;
  public visibleRespuesta: boolean;

  public tiempoMicroParada: number = 120;
  public tiempoMicroEjecucion: number = 120;

  public JvaloresView: any;
  public calidadSeleccionadas: number[] = [];


  //FILTRO
  private dataFiltro: any;

  public fechaIni: Date;
  public fechaFin: Date;

  public listaTurnos: any;
  public turnosSeleccionados: any;

  public listaMaquinas: any;
  public maquinasSeleccionadas: any;

  public listaHerramientas: any;
  public herramientasSeleccionadas: any;

  public listaOperarios: any;
  public operariosSeleccionados: any;

  public listaOfs: any;
  public ofsSeleccionados: any;

  public listaClientes: any;
  public clientesSeleccionados: any;

  public listaPiezas: any; //POR TEXTO
  public piezasSeleccionadas: any;

  public listaPartes: any; //POR TEXTO
  public partesSeleccionadas: any;

  public listaOperaciones: any; //POR TEXTO
  public operacionesSeleccionadas: any;

  public terminados: boolean = false;

  public loadingPanel: boolean = false;
  public loadingInyeccion: boolean = false;
  public loadingInduccion: boolean = false;

  public usaLotes: number;
  public coladaCAF: string;
  public loteCAF: string;

  public usuarioIdDb: any;
  public usuarioIdDbGamesa: any;
  public usuarioIdDbCAF: any;

  // DATA INDUCCION
  dataProcesos: any;
  recetasInf: any = [
    { campo: "op_activar_soplador_1" },
    { campo: "sp_presion_soplador_1" },
    { campo: "op_activar_soplador_2" },
    { campo: "sp_presion_soplador_2" },
    { campo: "op_activar_soplador_3" },
    { campo: "sp_presion_soplador_3" },
    { campo: "op_activar_soplador_4" },
    { campo: "sp_presion_soplador_4" },
    { campo: "op_activar_soplador_5" },
    { campo: "sp_presion_soplador_5" },
    { campo: "op_activar_soplador_6" },
    { campo: "sp_presion_soplador_6" },
    { campo: "op_activar_soplador_7" },
    { campo: "sp_presion_soplador_7" },
    { campo: "op_activar_soplador_8" },
    { campo: "sp_presion_soplador_8" },
    { campo: "op_activar_soplador_9" },
    { campo: "sp_presion_soplador_9" },
    { campo: "op_activar_soplador_10" },
    { campo: "sp_presion_soplador_10" },
    { campo: "op_activar_soplador_11" },
    { campo: "sp_presion_soplador_11" },
    { campo: "op_activar_soplador_12" },
    { campo: "sp_presion_soplador_12" },
    { campo: "op_activar_soplador_13" },
    { campo: "sp_presion_soplador_13" },
    { campo: "op_activar_salida_cuba" },
    { campo: "sp_expulsion_salida_cuba_rapida" },
    { campo: "sp_expulsion_salida_cuba_lenta" },
    { campo: "op_tmp_salida_vibrador_cuba_saturada" },
    { campo: "op_control_paso_pieza" },
    { campo: "sp_salida_paso_pieza" },
    { campo: "op_rodillo_introductor" },
    { campo: "op_giro_plato" },
    { campo: "tmp_giro_plato_inicial_giro" },
    { campo: "tmp_giro_plato_control" },
    { campo: "cv_proceso_giro" },
    { campo: "cv_rapida_giro" },
    { campo: "op_control_energia_activado" },
    { campo: "consigna_energia" },
    { campo: "margen_energia" },
    { campo: "calculo_energia_maximo" },
    { campo: "calculo_energia_minimo" },
    { campo: "op_control_caudal_activado" },
    { campo: "consigna_caudal_ducha" },
    { campo: "margen_caudal_ducha" },
    { campo: "calculo_caudal_ducha_maximo" },
    { campo: "calculo_caudal_ducha_minimo" },
    { campo: "op_control_temperatura_activado" },
    { campo: "consigna_temperatura_ducha" },
    { campo: "margen_temperatura_ducha" },
    { campo: "calculo_temperatura_ducha_maximo" },
    { campo: "calculo_temperatura_ducha_minimo" },
    { campo: "op_control_presion_activado" },
    { campo: "consigna_presion_ducha" },
    { campo: "margen_presion_ducha" },
    { campo: "calculo_presion_ducha_maximo" },
    { campo: "calculo_presion_ducha_minimo" },
    { campo: "gen_configuracion_seleccionada" },
    { campo: "sp_vibrador_1" },
    { campo: "sp_mantenimiento_vibrador_2" },
    { campo: "sp_lenta_vibrador_2" },
    { campo: "sp_recuperacion_vibrador_2" },
    { campo: "sp_vibrador_3" },
    { campo: "cv_cinta_salida" },
    { campo: "consigna_calor" },
    { campo: "velocidad_rapida_subida_volteador" },
    { campo: "velocidad_lenta_subida_volteador" },
    { campo: "velocidad_bajada_volteador" },
    { campo: "sp_consigna_velocidad" },
    { campo: "op_piezas_por_giro" },
    { campo: "op_sector_anchura_expulsor" },
    { campo: "op_sector_continuar_antes_parada_auto" },
    { campo: "op_sector_continuar_calentado_en_parada" },
    { campo: "op_sector_expulsion_defectuosos" },
    { campo: "op_activar_expulsor" },
    { campo: "op_tmp_parada_atasco" },
    { campo: "tmp_vaciado_cinta_salida" },
    { campo: "tmp_falta_material_vibrador_lineal" },
    { campo: "tmp_parada_control_falta_material" },
    { campo: "tmp_recuperacion_falta_material_lineal" },
    { campo: "tmp_filtro_defecto_material_vibrador_2" },
    { campo: "tmp_presencia_material_vibrador_2" },
    { campo: "tmp_peticion_pieza_vibrador_1" },
    { campo: "tmp_presencia_pieza_vibrador_1" },
    { campo: "tmp_vibrador_lineal_completo" },
    { campo: "tmp_def_material_m_alt_vibrador_lineal" },
    { campo: "tmp_pres_material_m_alt_vibrador_lineal" },
    { campo: "cantidadPiezasesteciclo" },
  ];
  lastEditado = 0;
  cantidadAcumulada: number = 0;

  // DATA INYECCION
  dataProcesosInyeccion: any;

  //    HELPERS
  private now: Date = new Date(this.myFunctions.getDateNow().getFullYear(), this.myFunctions.getDateNow().getMonth(), this.myFunctions.getDateNow().getDate(), this.myFunctions.getDateNow().getHours(), this.myFunctions.getDateNow().getMinutes(), this.myFunctions.getDateNow().getSeconds());

  public restrictions: FileRestrictions = {
    allowedExtensions: ['.jpg', '.jpeg', '.png', '.gif'],
    maxFileSize: 10000000
  };
    nombreImagenAnterior: any;
    imageToShowOperacion: any;

  constructor(
    private centroMecanizadoService: CentroMecanizadoService,
    private informeProyectosService: InformeProyectosService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UsuariosService,
    private alertService: AlertService,
    private informeOeeService: InformeOeeService,
    private translateService: TranslateService,
    private menuService: MenuService,
    private historicoOperacionesService: HistoricoOperacionesService,
    private operacionesService: OperacionesService,
    private configuracionService: ConfiguracionService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    public myFunctions: MyFunctions,
    private httpClient: HttpClient,
    private validadorService: ValidadorService,
    private ofService: OFService,
    private recetasInduccionService: RecetasInduccionService
  ) {

    this.menuService.titulo = this.translateService.instant('historicoOperaciones').toUpperCase();

    //SI idDb=33 (GAMESA) ENTONCES CAMBIAR DISEÑO 
    this.usuarioIdDb = userService.userValue.idDb;
    this.usuarioIdDbGamesa = 33;
    this.usuarioIdDbCAF = userService.userValue.idDb == 7 ? true : false;

    this.cargarDatosFiltro();
  }

  private log(event: string): void {
    this.events.unshift(`${event}`);
  }

  public async selectEventHandler(e: any) {
    const that = this;
    var file = e.files[0];
    var re = /(?:\.([^.]+))?$/;
    e.files.forEach((file) => {
      if (!file.validationErrors) {
        const reader = new FileReader();

        reader.onload = function (ev) {
          const image = {
            src: ev.target['result'],
            uid: file.uid
          };
          that.imagePreviews.unshift(image);
          that.urlImagen = reader.result as string;
          that.nombreImagen = that.id + "_imagen." + re.exec(file.name)[1];
        };

        reader.readAsDataURL(file.rawFile);
        this.hayImagen = true;
      }
    });

    this.imagePreviews.forEach(element => { that.imageToShow = element.src; });

    this.imagePreviews = [];
  }

  public onRemove(dataItem: any): void {
    this.irudiZaharraErakutsi = false;
    this.imageToShow = "";
    this.imagePreviews = [];
  }

  ngOnInit() {
    if(this.user.idDb == 25 || this.user.idDb == 1){
      this.exportarExcel=true;
    }

    this.loadingInduccion = true;
    this.loadingInyeccion = true;

    this.cargarDatosInduccion();
    this.cargarDatosInyeccion();
  }

  cargarDatosInduccion() {
    // idHistorico_operaciones
    var idHistorico_operaciones = this.route.snapshot.params.idHistorico_operaciones;

    //FECHAS : con filtro en URL
    var fini = this.route.snapshot.params['fechaIni'];
    var ffin = this.route.snapshot.params['fechaFin'];
    this.fechaIni = new Date(fini.replace(/_/g, "-"));
    this.fechaFin = new Date(ffin.replace(/_/g, "-"));

    this.recetasInduccionService.Get_recetas(idHistorico_operaciones).subscribe(
      (json: any) => {
        this.dataProcesos = json.sort(function(a,b) {return a.id - b.id});;
        var j = 1;
        var cantidadAcumulada = 0;
        this.dataProcesos.forEach(row => {
          // CICLO
          row["number"] = j;

          // FECHAS
          let date = new Date(row.fechaIni);
          let dateFin = new Date(row.fechaFin);
          
          if(dateFin.getFullYear() == 1950)
            dateFin = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours(), new Date().getMinutes(), new Date().getSeconds()); //NOW*

          row["fechaIni"] = this.myFunctions.dateWithoutYearShorted(this.myFunctions.sqlToJsDate(row.fechaIni.replace('T', ' '))) + " " + date.getFullYear();
          row["fechaIniH"] = this.formatoHHMMss(date.getHours(), date.getMinutes(), date.getSeconds()); // date.getUTCHours() + ":" + date.getUTCMinutes() + ":" + date.getUTCSeconds();
          row["fechaFinH"] = this.formatoHHMMss(dateFin.getHours(), dateFin.getMinutes(), dateFin.getSeconds()); // dateFin.getUTCHours() + ":" + dateFin.getUTCMinutes() + ":" + dateFin.getUTCSeconds();

          if (!row.cicloCortado)
            cantidadAcumulada += row["piezas"];
          row["numPiezas"] = cantidadAcumulada + "/" + row["nPiezas"];
          // OPERARIO
          row["operario"] = row["operario"].replace(';', ' ');
          
          // RECETAS
          row["dif"] = false;
          row["edited"] = false;

          j++;
        });

        this.cantidadAcumulada = cantidadAcumulada;

        this.loadingInduccion = false;

        this.cargarSimboloReceta();
      });
  }

  cargarDatosInyeccion() {
    // idHistorico_operaciones
    var idHistorico_operaciones = this.route.snapshot.params.idHistorico_operaciones;

    this.historicoOperacionesService.Get_procesos_inyeccion(idHistorico_operaciones).subscribe(
      json => {
        this.dataProcesosInyeccion = json;

        var cantidadAcumulada = 0;
        this.dataProcesosInyeccion.forEach(row => {
          // FECHAS
          let date = new Date(row.fechaini);
          let dateFin = new Date(row.fechafin);
          row["fechaIni"] = this.myFunctions.dateWithoutYearShorted(date) + " " + date.getFullYear();
          row["fechaIniH"] = this.formatoHHMMss(date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()); // date.getUTCHours() + ":" + date.getUTCMinutes() + ":" + date.getUTCSeconds();
          row["fechaFinH"] = this.formatoHHMMss(dateFin.getUTCHours(), dateFin.getUTCMinutes(), dateFin.getUTCSeconds()); // dateFin.getUTCHours() + ":" + dateFin.getUTCMinutes() + ":" + dateFin.getUTCSeconds();

          cantidadAcumulada += row["nPiezas"];
          row["numPiezas"] = cantidadAcumulada + "/" + row["cantidadTotal"];
          // OPERARIO
          row["operario"] = row["operario"].replace(';', ' ');

        });

        this.cantidadAcumulada = cantidadAcumulada;
        this.cargarParametrosTolerancia();
        this.loadingInyeccion = false;
      });
  }

  formatoHHMMss(horas, minutos, segundos) {
    var str = "";
    if (horas.toString().length == 1) str = str + "0" + horas + ":";
    else str = str + horas + ":";

    if (minutos.toString().length == 1) str = str + "0" + minutos + ":";
    else str = str + minutos + ":";

    if (segundos.toString().length == 1) str = str + "0" + segundos;
    else str = str + segundos;

    return str;
  }

  cargarSimboloReceta() {

    var data = [];
    this.dataProcesos.forEach(element => {
      data.push(this.toLowerKeys(element));
    });

    // insertar en un solo json solo la informacion necesaria para comparar las recetas
    var j = 0;
    data.forEach(element => {
      this.recetasInf.forEach(row => {
        row[j] = element[row["campo"]];
      });
      j++
    });

    // Se compara si la receta es igual que la original
    this.recetasInf.forEach(element => {
      let original = element['0'];
      if (element.campo != 'consigna_energia' && element.campo != 'consigna_temperatura_ducha') {
        for (var key in element) {
          let value = element[key];
          if (value != original) {
            if (this.dataProcesos[key] != undefined) this.dataProcesos[key]['dif'] = true; 
          }   
        }
      }
    });

    // Se mira si la receta esta editada o no
    // for (var i = 1; i<this.dataProcesos.length; i++) {
    this.dataProcesos.forEach((row, index) => {
      
      this.recetasInf.forEach(element => {
        if (element.campo != 'consigna_energia' && element.campo != 'consigna_temperatura_ducha') {
          let original = element[this.lastEditado];
          if (element[index] != original) {
            this.lastEditado = index;
            row["edited"] = true;
          }
        }
      });
      
    });
    
      // }

  }

  toLowerKeys(obj) {
    return Object.keys(obj).reduce((accumulator, key) => {
      accumulator[key.toLowerCase()] = obj[key];
      return accumulator;
    }, {});
  }

  cargarDatos() {

    var r1, r2, r3, r4, r5: boolean = false;

    this.loadingPanel = true;


    var fechaInicio = (this.fechaIni === undefined) ? undefined : this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaIni);
    var fechaFin = (this.fechaFin === undefined) ? undefined : this.myFunctions.dateToYYYYMMDDtHHmmSSz(new Date(this.fechaFin.getTime() + (1000 * 60 * 60 * 24)));
    var listaIdTurnos = (this.turnosSeleccionados === undefined) ? [] : this.turnosSeleccionados.map(a => a.idTurno);
    var listaIdMaquinas = (this.maquinasSeleccionadas === undefined) ? [] : this.maquinasSeleccionadas.map(a => a.idMaquina);
    var listaIdHerramientas = (this.herramientasSeleccionadas === undefined) ? [] : this.herramientasSeleccionadas.map(a => a.idHerramienta);
    var listaIdOperarios = (this.operariosSeleccionados === undefined) ? [] : this.operariosSeleccionados.map(a => a.idOperario);
    var listaIdOfs = (this.ofsSeleccionados === undefined) ? [] : this.ofsSeleccionados.map(a => a.idOf);
    var listaIdClientes = (this.clientesSeleccionados === undefined) ? [] : this.clientesSeleccionados.map(a => a.idCliente);
    var listaPiezas = (this.piezasSeleccionadas === undefined) ? [] : this.piezasSeleccionadas.map(a => "'" + a.nombrePieza + "'");
    var listaPartes = (this.partesSeleccionadas === undefined) ? [] : this.partesSeleccionadas.map(a => "'" + a.nombreParte + "'");
    var listaOperaciones = (this.operacionesSeleccionadas === undefined) ? [] : this.operacionesSeleccionadas.map(a => "'" + a.nombreOperacion + "'");

    // configuracion CALIDAD
    //this.configuracionService.get_configuracion_calidad().subscribe(result => {
      //this.dtConfiguracionCalidad = result[0];
    //});

    // INFO PIEZA
    this.historicoOperacionesService.get_pieza(this.route.snapshot.params.idHistorico_piezas,
      fechaInicio, fechaFin,
      listaIdTurnos.join(), listaIdMaquinas.join(), listaIdHerramientas.join(), listaIdOperarios.join(),
      listaIdOfs.join(), listaIdClientes.join(), listaPiezas.join(), listaPartes.join(), listaOperaciones.join(), this.terminados, this.translateService.instant("desconocido")).subscribe(
        (json) => {
          if (Object.keys(json).length > 0) {
            this.infoOF = json[0].refOF
            this.infoCliente = json[0].cliente
            this.infoProyecto = json[0].proyecto
            this.infoPlano = json[0].numeroPlano
            this.infoRefPieza = json[0].referencia
            this.infoPieza = json[0].pieza
            this.infonserie = json[0].numeroSerie
            this.infoTiempoRealEjecucion = this.myFunctions.secondsTo_HH_MM(json[0].tiempoRealEjecucion);
            this.cantidadLote = json[0].cantidadLote
            this.cantidadLoteHechas = json[0].cantidadLoteHechas
            this.usaLotes = json[0].usaLotes;
            this.coladaCAF = json[0].coladaCAF;
            this.loteCAF = json[0].loteCAF;
            this.infoTiempoRealPreparacion = this.myFunctions.secondsTo_HH_MM(json[0].tiempoRealPreparacion);
            this.infoTiempoEstimado = this.myFunctions.secondsTo_HH_MM(json[0].tiempoEstimadoTotal);
            this.infoDesvio = this.myFunctions.secondsTo_HH_MM(json[0].desvio);
            this.infoPorcenDesvio = json[0].porcenDesvio + ' %';
            this.idEstado = json[0].estado;
            this.operacion = json[0].operaciones?.split(';and;')[0];
            this.tipo_maquina = json[0].tipo_maquina;
            this.idMaquina = json[0].idMaquina;
          }

          r1 = true;
          if (r1 && r2 && r3 && r4 && r5) {
            this.loadingPanel = false;
          }

        }
      );

    // INCIDENCIAS
    this.historicoOperacionesService.Get_incidencias_HP_Operacion(this.route.snapshot.params.idHistorico_operaciones).subscribe(
      json => {
        this.Jincidencias = json;

        r2 = true;
        if (r1 && r2 && r3 && r4 && r5) {
          this.loadingPanel = false;
        }


      }
    );

    // PROCESOS
    //console.time('doSomething')
    var desconocido: string = this.translateService.instant('desconocido');
    this.historicoOperacionesService.Get_procesos_HP_Operacion(this.route.snapshot.params.idHistorico_operaciones,
      fechaInicio, fechaFin,
      listaIdTurnos.join(), listaIdMaquinas.join(), listaIdHerramientas.join(), listaIdOperarios.join(),
      listaIdOfs.join(), listaIdClientes.join(), listaPiezas.join(), listaPartes.join(), listaOperaciones.join(), this.terminados, desconocido).subscribe(
        (json: any) => {

          var dict: any = {};
          if(json?.imagenes!=undefined)
            if (json.imagenes.length > 0) {
              //Tenemos las imagenes, creamos el diccionario
              json.imagenes.forEach(element => {
                dict[element.imagen] = element.imagenBASE64;
              });
            }

          //console.timeEnd('doSomething')
          // GRID OPERARIOS
          var JoperariosTemp: any = [];
          // order by 
          var array = [];
          var an: any = json.data;
          an.forEach(a => array[a['idOperario'] + this.dateToYYYYMMDDHHmmSS(new Date(a['fechaini']))] = a);
          array.sort(function (a, b) { return b[1] - a[1] });
          var keys = [];
          Object.keys(array).sort().forEach(key => keys.push(key));
          var operario: string = '';
          var operariosC: string = '';
          var fecha: Date = this.now;
          var fechaIni: Date = this.now;
          var duracion: number = 0;
          var primero = true;
          keys.forEach(key => {
            var row = array[key];
            if (!primero
              && (operario != row.operarioNombre)) {
              var jOperario: any = {};
              jOperario['operario'] = operario;
              jOperario['fechaini'] = fechaIni;
              jOperario['fechafin'] = fecha;
              jOperario['duracion'] = this.secondsToHms(duracion / 1000);
              jOperario['duracionS'] = fecha.getTime() - fechaIni.getTime();
              JoperariosTemp.push(jOperario);

              duracion = 0;
              fechaIni = this.dateCopy(new Date(row.fechaini));
            } else if (fecha == this.now) { //es la primera row
              fechaIni = this.dateCopy(new Date(row.fechaini));
            }
            operario = row.operarioNombre + '';
            operariosC = row.operarioC + '';
            fecha = this.dateCopy(new Date(row.fechafin));
            var fechaIni2 = this.dateCopy(new Date(row.fechaini));
            duracion += fecha.getTime() - fechaIni2.getTime();

            primero = false;
          });

          var jOperario: any = {};
          jOperario['operario'] = operario;
          jOperario['operariosC'] = operariosC;
          jOperario['fechaini'] = fechaIni;
          jOperario['fechafin'] = fecha;
          jOperario['duracion'] = this.secondsToHms(duracion / 1000);
          jOperario['duracionS'] = fecha.getTime() - fechaIni.getTime();

          JoperariosTemp.push(jOperario);

          this.Joperarios = JoperariosTemp;
          this.loadItems()

          // GRID HISTORICO PROCESOS
          // GRID OPERARIOS
          var JprocesosTemp: any = [];
          // order by 
          var array = []
          var an: any = json.data;
          an.forEach(a => array[a['maquina'] + this.dateToYYYYMMDDHHmmSS(new Date(a['fechaini']))] = a);
          array.sort(function (a, b) { return b[1] - a[1] });
          var keys = [];
          Object.keys(array).sort().forEach(key => keys.push(key));

          var maquina: string = '';
          var maquinasIm: string = '';
          var maquinasC: string = '';
          var procesos_Tipo: string = '';
          var nombrePrograma: string = '';
          var operario: string = '';
          var operariosC: string = '';
          var fechaIni: Date = this.now;
          var fechafin: Date = this.now;
          var tiempoRealHH: string = '';
          var tiempoEstHH: string = '';
          var perdida: string = '';
          var duracion: number = 0;

          var primero = true;

          keys.forEach(key => {
            var row = array[key];
            //Aratz Como se usa tantas veces he pensado en sacarlo aparte.
            var esMicro = ((procesos_Tipo == "ejecucion" || procesos_Tipo == "parada") && row.procesos_Tipo == "parada" && row.tiempoRealHH < this.tiempoMicroParada)
              || ((procesos_Tipo == "ejecucion" || procesos_Tipo == "parada") && row.procesos_Tipo == "ejecucion" && row.tiempoRealHH < this.tiempoMicroEjecucion)

            //Aratz este IF se ha copiado de la subidad e datos. en la subida de datos se entra cuando es igual, por eso esta metido en un !(*******) la mayor parte del if
            if (!primero //para no entrar en la primera vuelta
              && !(
                String(new Date(fechafin)) == String(new Date(row.fechaini))
                && (operario == row.operario || esMicro)
                && (maquina == row.maquina || esMicro)
                && (nombrePrograma == row.nombrePrograma || esMicro)
                && (esMicro
                  || (procesos_Tipo == "preparacion" && row.procesos_Tipo == "parada")
                  || (procesos_Tipo == "preparacion" && row.procesos_Tipo == "ejecucion" && row.tiempoRealHH < this.tiempoMicroEjecucion)
                  || procesos_Tipo == row.procesos_Tipo
                ))) {

              var jOperario: any = {};
              jOperario['maquina'] = maquina;
              jOperario['maquinasC'] = maquinasC;
              jOperario['procesos_Tipo'] = procesos_Tipo;
              jOperario['nombrePrograma'] = nombrePrograma;
              jOperario['operario'] = operario;
              jOperario['operariosC'] = operariosC;
              jOperario['fechaini'] = fechaIni;
              jOperario['fechafin'] = fechafin;

              jOperario['tiempoRealHH'] = this.secondsToHms(duracion); //this.secondsToHms((fechafin.getTime() - new Date(fechaIni).getTime()) / 1000);
              jOperario['tiempoEstHH'] = tiempoEstHH;

              jOperario['perdida'] = perdida;
              jOperario['duracion'] = this.secondsToHms(duracion); //
              jOperario['duracionS'] = fecha.getTime() - fechaIni.getTime();

              //Tratamos operarios
              var operariosAuxi = [];
              var operariosAuxi2 = [];
              var nombre;
              var apellido;
              if (operario == this.translateService.instant("desconocido")) {
                operariosAuxi.push("undefined");
                operariosAuxi2.push("undefined");
              }
              else {
                if (operario.split(';').length > 0) {
                  nombre = operario.split(';')[0];
                  apellido = operario.split(';')[1];
                  if (apellido != undefined)
                    operariosAuxi2.push(nombre + " " + apellido);
                  else
                    operariosAuxi2.push(nombre);
                  if (nombre != undefined)
                    nombre = nombre.trim().substring(0, 1).toUpperCase();
                  if (apellido != undefined)
                    apellido = apellido.trim().substring(0, 1).toUpperCase();
                  operariosAuxi.push(nombre + apellido);
                }
              }
              jOperario['operariosAuxi'] = operariosAuxi.join(",");
              jOperario['operariosAuxi2'] = operariosAuxi2.join(";");

              //Tratamos maquinas
              var maquinasAuxi = [];
              var maquinasAuxi2 = [];
              if (maquina == this.translateService.instant("desconocido")) {
                maquinasAuxi2.push("undefined");
                maquinasAuxi.push("undefined");
              } else {
                nombre = maquina.trim().substring(0, 1).toUpperCase();
                apellido = maquina.trim().substring(1, 2).toUpperCase();
                maquinasAuxi2.push(maquina);
                maquinasAuxi.push(nombre + apellido);
              }
              jOperario['maquinasAuxi'] = maquinasAuxi.join(",");
              jOperario['maquinasAuxi2'] = maquinasAuxi2.join(";");

              //Ahora hay que corregir las imagenes de las maquinas
              var imagenes = row.maquinasIm.split(';and;');
              var auxiImagenes = "";
              imagenes.forEach(imagen => {
                auxiImagenes += dict[imagen] + ";and;";
              });
              jOperario['maquinasIm'] = auxiImagenes;

              JprocesosTemp.push(jOperario);

              maquina = row.maquina + '';
              maquinasIm = row.maquinasIm + '';
              procesos_Tipo = row.procesos_Tipo + '';
              nombrePrograma = row.nombrePrograma + '';
              operario = row.operario + '';
              operariosC = row.operarioC + '';
              maquinasC = row.maquinaC + '';
              fechaIni = this.dateCopy(new Date(row.fechaini));
              tiempoEstHH = row.tiempoEstHH;
              perdida = row.perdida + '';
              duracion = 0;
            } else if (primero) { //es la primera row
              maquina = row.maquina + '';
              maquinasIm = row.maquinasIm + '';
              procesos_Tipo = row.procesos_Tipo + '';
              nombrePrograma = row.nombrePrograma + '';
              operario = row.operario + '';
              operariosC = row.operarioC + '';
              maquinasC = row.maquinaC + '';
              fechaIni = this.dateCopy(new Date(row.fechaini));
              tiempoEstHH = row.tiempoEstHH;
              perdida = row.perdida + '';
              duracion = 0;
              primero = false;
            }
            duracion += row.tiempoRealHH;
            fechafin = this.dateCopy(new Date(row.fechafin));
          });
          if (!primero) {
            var jOperario: any = {};
            jOperario['maquina'] = maquina;
            jOperario['procesos_Tipo'] = procesos_Tipo;
            jOperario['nombrePrograma'] = nombrePrograma;
            jOperario['operario'] = operario;
            jOperario['operariosC'] = operariosC;
            jOperario['maquinasC'] = maquinasC;
            jOperario['fechaini'] = fechaIni;
            jOperario['fechafin'] = fechafin;
            jOperario['tiempoRealHH'] = this.secondsToHms(duracion); //this.secondsToHms((fechafin.getTime() - new Date(fechaIni).getTime()) / 1000);
            jOperario['tiempoEstHH'] = tiempoEstHH;
            jOperario['perdida'] = perdida;
            jOperario['duracion'] = this.secondsToHms(duracion); //
            jOperario['duracionS'] = fecha.getTime() - fechaIni.getTime();

            //Tratamos operarios
            var operariosAuxi = [];
            var operariosAuxi2 = [];
            var nombre;
            var apellido;
            if (operario == this.translateService.instant("desconocido")) {
              operariosAuxi.push("undefined");
              operariosAuxi2.push("undefined");
            }
            else {
              if (operario.split(';').length > 0) {
                nombre = operario.split(';')[0];
                apellido = operario.split(';')[1];
                if (apellido != undefined)
                  operariosAuxi2.push(nombre + " " + apellido);
                else
                  operariosAuxi2.push(nombre);
                if (nombre != undefined)
                  nombre = nombre.trim().substring(0, 1).toUpperCase();
                if (apellido != undefined)
                  apellido = apellido.trim().substring(0, 1).toUpperCase();
                operariosAuxi.push(nombre + apellido);
              }
            }

            jOperario['operariosAuxi2'] = operariosAuxi2.join(";");
            jOperario['operariosAuxi'] = operariosAuxi.join(",");

            //Tratamos maquinas
            var maquinasAuxi = [];
            var maquinasAuxi2 = [];
            if (maquina == this.translateService.instant("desconocido")) {
              maquinasAuxi2.push("undefined");
              maquinasAuxi.push("undefined");
            } else {
              nombre = maquina.trim().substring(0, 1).toUpperCase();
              apellido = maquina.trim().substring(1, 2).toUpperCase();
              maquinasAuxi2.push(maquina);
              maquinasAuxi.push(nombre + apellido);
            }
            jOperario['maquinasAuxi'] = maquinasAuxi.join(",");
            jOperario['maquinasAuxi2'] = maquinasAuxi2.join(";");

            //Ahora hay que corregir las imagenes de las maquinas
            var imagenes = maquinasIm.split(';and;');
            var auxiImagenes = "";
            imagenes.forEach(imagen => {
              auxiImagenes += dict[imagen] + ";and;";
            });

            jOperario['maquinasIm'] = auxiImagenes;

            JprocesosTemp.push(jOperario);
          }

          this.Jprocesos = JprocesosTemp;
          this.loadItems2();

          r3 = true;
          if (r1 && r2 && r3 && r4 && r5) {
            this.loadingPanel = false;
          }

        }
      )

    // INFO OPERACION
    var desconocido: string = this.translateService.instant('desconocido');
    this.historicoOperacionesService.get_info_HP_Operacion(this.route.snapshot.params.idHistorico_operaciones,
      fechaInicio, fechaFin,
      listaIdTurnos.join(), listaIdMaquinas.join(), listaIdHerramientas.join(), listaIdOperarios.join(),
      listaIdOfs.join(), listaIdClientes.join(), listaPiezas.join(), listaPartes.join(), listaOperaciones.join(), this.terminados, desconocido).subscribe(
        json => {
          if (Object.keys(json).length > 0) {
            var row = json[0];
            //    datos de la operacion
            this.numeroOperacion = row.numeroOperacion;
            this.nombrePlano = row.nombrePlano;
            this.idTipoOperacion = row.tipoOperacion;
            this.infoTiempoRealOperacion = row.tiempoReal;
            this.infoTiempoEstimadoOperacionEjecucion = row.tiempoEstimado;
            this.infoTiempoEstimadoOperacionPreparacion = row.tiempoEstimadoPreparacion;

            switch (this.idTipoOperacion) {
              case 1: {
                this.tipoOperacion = this.translateService.instant("informativo");
                this.valorVisible = false
                break;
              }
              case 2: {
                this.historicoOperacionesService.get_tablaValores_piezas(this.route.snapshot.params.idHistorico_operaciones, this.translateService.instant("desconocido")).subscribe(
                  json => {
                    this.JvaloresView = json;
                    // configuracion CALIDAD
                    this.configuracionService.get_configuracion_calidad().subscribe(result => {
                        this.primerasPiezasNum = result[0].primerasPiezasNum;
      
    
                        this.JvaloresView.forEach(element => {
                          //Primero procesamos los operarios
                          var operarios = element.operarios.split(",");
                          var operariosAuxi = [];
                          var operariosAuxi2 = [];
                          operarios.forEach(operario => {
                            var nombre;
                            var apellido;
                            if (operario.split(';').length > 0) {
                              if (operario == this.translateService.instant("desconocido")) {
                                operariosAuxi.push("undefined");
                                operariosAuxi2.push("undefined");
                              } else {
                                nombre = operario.split(';')[0];
                                apellido = operario.split(';')[1];
                                if(nombre!=undefined && nombre!=""){
                                  if (apellido != undefined)
                                    operariosAuxi2.push(nombre + " " + apellido);
                                  else
                                    operariosAuxi2.push(nombre);   
                                  if (nombre != undefined && nombre!="")
                                    nombre = nombre.trim().substring(0, 1).toUpperCase();
                                  if (apellido != undefined && apellido!="")
                                    apellido = apellido.trim().substring(0, 1).toUpperCase();
                                  operariosAuxi.push(nombre + apellido);
                                }
                              }
                            }
                          });
                          element.operariosAuxi2 = operariosAuxi2.join(";");
                          element.operariosAuxi = operariosAuxi.join(",");
                          if (element.tipoFrecuencia == 2)
                            element.cantidad = this.myFunctions.secondsTo_HH_MM(element.frecuencia);
                          else
                            element.cantidad = this.translateService.instant("pieza") + " " + element.cantidad;
                          //Por último nos ocupamos de las fechas
                          if(element.fechaValidacion!=null){
                            var fechaAuxi = new Date(element.fechaValidacion.replace("T", " "));
                            element.fechaValidacionOrden = this.myFunctions.dateToYYYYMMDDHHmmSS(fechaAuxi) + " " + this.myFunctions.dateWithoutYearShorted(this.myFunctions.sqlToJsDate(element.fechaValidacion.replace('T', ' '))) +
                              this.myFunctions.dateToHHMM(this.myFunctions.sqlToJsDate(element.fechaValidacion.replace('T', ' ')));
                          }
                          this.historicoOperacionesService.get_tablaValores(this.route.snapshot.params.idHistorico_operaciones, element.valores, Number(element.frecuencia), element.fecha).subscribe((json2:any)=>{
                            element.interna = json2;
                            
                            json2.forEach(element2 => {
                              // if (element.fechaValidacion==null) element2.operacionValidada=true;
                              // else element2.operacionValidada=false;

                              if(element2.utillaje!=-1){
                                this.historicoOperacionesService.getdatosSerieUtillaje(element2.utillaje).subscribe(
                                (json3:any) => {
                                  element2.numSerieUtillaje = json3.data;
                                  var auxiUtillaje = element2.numSerieUtillaje.filter(x=>x.id==element2.idUtillajeNSerie);
                                  if(auxiUtillaje.length>0)
                                    element2.selectedItem = auxiUtillaje[0];
                                  else
                                  element2.selectedItem = null;
                                });
                              }
                              if (element2.medicion==null) element2.medicion=-1;
                              else
                              {
                              if(!isNaN(element2.medicion) && element2.medicion.toString().indexOf('.') != -1){
                                element2.medicion = parseFloat(element2.medicion);
                              }else{
                                //Puede no ser un float porque es un float entero (3.0) y desde la base de datos ha llegado sin punto
                                if(!isNaN(element2.medicion)){
                                  element2.medicion = parseInt(element2.medicion)
                                }else{
                                  element2.medicion = null;
                                }
                              }
                            }
                              //Miramos si es medicion o boton
                              element2.tipoValorNumerico = (element2.tipoValor == 2);
                              element2.tipoValorBooleano = (element2.tipoValor == 1);
                              if (element2.tipoValor == -1) {
                                  element2.tipoValorNumerico = true;
                              }
                              
                              // element2.operacionValidada = element.validadas==element.numMedidas;
                              // if (element2.tipoValorNumerico)
                              // {
                              //   element2.operacionValidada = (element2.valorNominal<=element2.toleranciaMax && element2.valorNominal>=element2.toleranciaMin);
                              // }
                              // else
                              // {
                              //   if (element2.valor==undefined) element2.operacionValidada = false;
                              //   else
                              //     if (element2.valor==null) element2.operacionValidada = false;
                              //     else element2.operacionValidada = element2.valor;
                              // }
                            });
                          });
                        
                        // if (Number(element.frecuencia)<= this.primerasPiezasNum) element.esPrimerasPieza = true;
                        // else element.esPrimerasPieza = false;
                    });
                    console.table(this.JvaloresView);

                  });
                  }); 
                this.tipoOperacion = this.translateService.instant("valor");
                this.valorVisible = true
                break;
              }
              case 3: {
                this.tipoOperacion = this.translateService.instant("confirmacion");
                this.valorVisible = false
                break;
              }
              case 4: {
                this.tipoOperacion = this.translateService.instant("valorMaquina");
                this.valorVisible = false
                break;
              }
            }
            this.nombreOperacion = row.nombreOperacion
            //    tipo de operacion: CONFORMACION
            this.confirmacionVisible = row.confirmacionVisible
            this.respuestaConfirmacion = row.respuestaConfirmacion
            //    valores solicitados en la operacion
            this.solicitarVisible = row.solicitarVisible
            this.solicitarEdicionVisible = row.solicitarEdicionVisible
            this.solicitarEdicionValor = row.solicitarEdicionValor
            this.solicitarLoteVisible = row.solicitarLoteVisible
            this.solicitarLoteValor = row.solicitarLoteValor
            this.solicitarNserieVisible = row.solicitarNserieVisible
            this.solicitarNserieValor = row.solicitarNserieValor
            this.cantidadLoteOperacion = row.cantidadLote
            this.cantidadLoteHechasOperacion = row.cantidadLoteHechas
            this.loteCAFOperacion = row.solicitarLoteValor
            //    imagen de operacion
            this.imagenOperacionVisible = row.imagenOperacionVisible
            this.createImageFromBase64(row.imagenBase64);
            this.imagenOperacion = row.imagenBase64

            //  visibilidad bototnes
            this.visibleCancelar = !row.cancelada
            this.visibleLiberar = row.enUso
            this.visibleRespuesta = !row.respuesta

            // tiempos
            this.infoTiempoRealEjecucionOperacion = this.myFunctions.secondsTo_HH_MM(row.tiempoRealEjecucion);
            this.infoTiempoRealPreparacionOperacion = this.myFunctions.secondsTo_HH_MM(row.tiempoRealPreparacion);
            this.infoTiempoEstimadoOperacion = this.myFunctions.secondsTo_HH_MM(row.tiempoEstimadoEjecucion + row.tiempoEstimadoPreparacion);
            this.infoDesvioOperacion = this.myFunctions.secondsTo_HH_MM(row.desvio);
            this.infoPorcenDesvioOperacion = row.porcenDesvio + ' %';
            this.terminado = row.terminado;
          }

          r4 = true;
          if (r1 && r2 && r3 && r4 && r5) {
            this.loadingPanel = false;
          }

        }
      )

    // IMAGENES
    this.historicoOperacionesService.Get_imagenes_HP_Operacion(this.route.snapshot.params.idHistorico_operaciones).subscribe(
      (json:any) => {
        var an: any = json;
        if(json!=null && json.length>0){
          this.nombreImagenAnterior = json[0].imagen;
          //Imagen de operario
          if (Object.keys(json).length > 0) {
            this.imagenOperarioVisible = true
          } else {
            this.imagenOperarioVisible = false
          }
          an.forEach(img => {
            document.getElementById('imagen').setAttribute("src", img.imagenBase64);
          });
          r5 = true;
          if (r1 && r2 && r3 && r4 && r5) {
            this.loadingPanel = false;
          }
        }else{
          r5 = true;
          if (r1 && r2 && r3 && r4 && r5) {
            this.loadingPanel = false;
          }
        }
      }, error => {
      });
  }

  showImg(dataItem: any) {
    var imagen = document.createElement("img");
    imagen.setAttribute("src", dataItem.imagenBase64);
    imagen.setAttribute("class", 'img-fluid lightbox_trigger');
    document.getElementById('auxDiv').appendChild(imagen);
    imagen.click();
  }

  openForm(dataItem: any) {
    this.id = dataItem.idHistoricoOperacionValor;
    this.hayImagen = false;
    this.uploadSaveUrl = `${environment.apiUrl}/historicoOperaciones/imagen/upload/` + this.id; // should represent an actual API endpoint
    this.uploadRemoveUrl = `${environment.apiUrl}/historicoOperaciones/imagen/remove/` + this.id; // should represent an actual API endpoint
    this.modalReferenceUpload = this.modalService.open(this.popupSubiImagen, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }

  btnAceptar(evt: any) {
    var index; 
    for (var i = 0; i < this.JvaloresView.length; i++) {
      if (this.JvaloresView[i].idHistoricoOperacionValor === this.id) {
        index = i;
        break;
      }
    }
    this.JvaloresView[index].imagen = true;
    this.JvaloresView[index].fotoPath = this.nombreImagen;
    this.JvaloresView[index].imagenBase64 = this.urlImagen;
    this.modalReferenceUpload.close();
  }

  cargarDatosFiltro() {
    this.loadingPanel = true;
    //idHistorico_piezas /: fechaini /: fechaFin /: idturno /: idMaquina /: idHerramienta /: idOperario /: idOf /: idCliente /: idPieza /: idOperacion

    //Valores del filtro.
    var fini = this.route.snapshot.params['fechaIni'];
    var ffin = this.route.snapshot.params['fechaFin'];

    var turnos = this.route.snapshot.params.idturno.split("_");
    var maquinas = this.route.snapshot.params.idMaquina.split("_");
    var herramientas = this.route.snapshot.params.idHerramienta.split("_");
    var operarios = this.route.snapshot.params.idOperario.split("_");

    var ofs = this.route.snapshot.params.idOf.split("_");
    var clientes = this.route.snapshot.params.idCliente.split("_");
    var piezas = this.route.snapshot.params.idPieza.split("_");
    var partes = this.route.snapshot.params.idParte.split("_");
    var operaciones = this.route.snapshot.params.idOperacion.split("_");

    //FECHAS : con filtro en URL
    this.fechaIni = new Date(fini.replace(/_/g, "-"));
    this.fechaFin = new Date(ffin.replace(/_/g, "-"));
    this.fechaIni.setHours(0, 0, 0);
    this.fechaFin.setHours(0, 0, 0);

    //TURNOS
    this.listaTurnos = [
      { nombreTurno: this.translateService.instant("manana"), idTurno: 1 },
      { nombreTurno: this.translateService.instant("tarde"), idTurno: 2 },
      { nombreTurno: this.translateService.instant("noche"), idTurno: 3 }
    ];

    // selecteds
    this.turnosSeleccionados = [];
    this.listaTurnos.forEach(
      row => {
        if (turnos.includes(String(row.idTurno))) {
          this.turnosSeleccionados.push(row);
        }
      });

    var r1, r2, r3, r6: boolean = false;

    //MAQUINAS
    this.informeProyectosService.Get_Maquinas().subscribe(
      data => {
        this.listaMaquinas = data;

        // selecteds
        this.maquinasSeleccionadas = [];
        this.listaMaquinas.forEach(
          row => {
            if (maquinas.includes(String(row.idMaquina)))
              this.maquinasSeleccionadas.push(row);
          });

        r1 = true;
        if (r1 && r2 && r3 && r6) {
          this.cargarDatos();
        }
      });

    //HERRAMIENTAS
    this.informeProyectosService.Get_Herramientas().subscribe(
      data => {
        this.listaHerramientas = data;

        // selecteds
        this.herramientasSeleccionadas = [];
        this.listaHerramientas.forEach(
          row => {
            if (herramientas.includes(String(row.idHerramienta)))
              this.herramientasSeleccionadas.push(row);
          });

        this.listaHerramientas.sort((a, b) => (a.nombreHerramienta > b.nombreHerramienta) ? 1 : ((b.nombreHerramienta > a.nombreHerramienta) ? -1 : 0));
        r2 = true;
        if (r1 && r2 && r3 && r6) {
          this.cargarDatos();
        }
      });

    //OPERARIOS
    this.informeProyectosService.Get_Operarios().subscribe((data: any) => {
      this.listaOperarios = data;

      // selecteds
      this.operariosSeleccionados = [];
      this.listaOperarios.forEach(
        row => {
          if (operarios.includes(String(row.idOperario)))
            this.operariosSeleccionados.push(row);
        });

      this.listaOperarios.sort((a, b) => (a.nombreOperario > b.nombreOperario) ? 1 : ((b.nombreOperario > a.nombreOperario) ? -1 : 0));
      r3 = true;
      if (r1 && r2 && r3 && r6) {
        this.cargarDatos();
      }
    });

    //configuracion
    this.validadorService.getTiemposMicroParadas().subscribe(
      row => {
        var an: any = row[0];
        this.tiempoMicroParada = an.tMicroParada;
        this.tiempoMicroEjecucion = an.tMicroEjecucion;

        r6 = true
        if (r1 && r2 && r3 && r6) {
          this.cargarDatos();
        }
      });
  }
  btnLimpiarFiltro() {

    this.turnosSeleccionados = undefined;
    this.maquinasSeleccionadas = undefined;
    this.herramientasSeleccionadas = undefined;
    this.operariosSeleccionados = undefined;
    this.ofsSeleccionados = undefined;
    this.clientesSeleccionados = undefined;
    this.piezasSeleccionadas = undefined;
    this.partesSeleccionadas = undefined;
    this.operacionesSeleccionadas = undefined;
    this.terminados = false;

  }
  btnFiltrar() {
    this.cargarDatos();
  }

  cancelarSubida() {
    this.modalReferenceUpload.close();
    this.irudiZaharraErakutsi = false;
    this.imageToShow = "";
    this.imagePreviews = [];
  }

  createImageFromBase64(image) {
    let objectURL = 'data:image/jpeg;base64,' + image;
  }

  btnCancelar() {
    this.historicoOperacionesService.set_HO_cancelar(this.route.snapshot.params.idHistorico_operaciones).subscribe(
      n => {
        this.btnVolver();
      }
    )
  }
  btnLiberar() {
    this.historicoOperacionesService.set_HO_liberar(this.route.snapshot.params.idHistorico_operaciones).subscribe(
      n => {
        this.btnVolver();
      }
    )
  }
  btnRespuesta() {
    this.historicoOperacionesService.set_HO_respuesta(this.route.snapshot.params.idHistorico_operaciones).subscribe(
      n => {
        this.btnVolver();
      }
    )
  }

  btnGuardar() {

    var auxi = [];
    this.JvaloresView.forEach(element => {
      element.interna.forEach(element2 => {
        if(element2.selectedItem!=undefined)
          if(element2.selectedItem.id!=undefined)
            element2.idUtillajeNSerie = element2.selectedItem.id;
          else
          element2.idUtillajeNSerie = element2.selectedItem;
        else
          element2.idUtillajeNSerie = -1;
        delete element2.numSerieUtillaje;
        delete element2.selectedItem;

        if (element2.medicion == undefined || Number.isNaN(element2.medicion) || element2.medicion==null) 
          element2.medicion=-1;//element2.medicion=0;
//        if (element2.medicion==-1) element2.medicion=null;
        element2.esPrimera=element.esPrimera;
        element2.observacionResponsable=element.observacionResponsable;
        if (element.fechaValidacion==null) element2.fechaValidacion='1111-01-01T00:00:00';
        else  element2.fechaValidacion=element.fechaValidacion;
        auxi.push(element2);
      });
    });
    this.historicoOperacionesService.set_HO(this.route.snapshot.params.idHistorico_operaciones, this.solicitarEdicionValor, this.solicitarLoteValor, this.solicitarNserieValor).subscribe(
      n => {
        this.historicoOperacionesService.set_HOV(auxi).subscribe(
          (result:any) => {
            this.btnVolver();
          }
        );
      }
    );
  }
  btnVolver() {
    // filtro fechas
    var fini = this.myFunctions.dateToYYYY_MM_DD(this.fechaIni); //FORMATO: YYYY_MM_DD
    var ffin = this.myFunctions.dateToYYYY_MM_DD(this.fechaFin); //FORMATO: YYYY_MM_DD

    // filtro generales
    var idsTurnos = [];
    if (this.turnosSeleccionados != undefined)
      this.turnosSeleccionados.forEach(of => idsTurnos.push(of.idTurno));

    var idMaquinas = [];
    if (this.maquinasSeleccionadas != undefined)
      this.maquinasSeleccionadas.forEach(of => idMaquinas.push(of.idMaquina));

    var idsHerramientas = [];
    if (this.herramientasSeleccionadas != undefined)
      this.herramientasSeleccionadas.forEach(of => idsHerramientas.push(of.idHerramienta));

    var idsOperarios = [];
    if (this.operariosSeleccionados != undefined)
      this.operariosSeleccionados.forEach(operario => idsOperarios.push(operario.idOperario));


    // filtro ofs...
    var idsOFs = [];
    if (this.ofsSeleccionados != undefined)
      this.ofsSeleccionados.forEach(of => idsOFs.push(of.idOf));

    var idsClientes = [];
    if (this.clientesSeleccionados != undefined)
      this.clientesSeleccionados.forEach(cliente => idsClientes.push(cliente.idCliente));

    var idPiezas = [];
    if (this.piezasSeleccionadas != undefined)
      this.piezasSeleccionadas.forEach(pieza => idPiezas.push(pieza.idPieza));

    var idPartes = [];
    if (this.partesSeleccionadas != undefined)
      this.partesSeleccionadas.forEach(parte => idPartes.push(parte.idParte));

    var idOperaciones = [];
    if (this.operacionesSeleccionadas != undefined)
      this.operacionesSeleccionadas.forEach(operacion => idOperaciones.push(operacion.idOperacion));

    var turnos = this.myFunctions.listToStirng(idsTurnos, "_", "0");
    var maquinas = this.myFunctions.listToStirng(idMaquinas, "_", "0");
    var herramientas = this.myFunctions.listToStirng(idsHerramientas, "_", "0");
    var operarios = this.myFunctions.listToStirng(idsOperarios, "_", "0");

    var ofs = this.myFunctions.listToStirng(idsOFs, "_", "0");
    var clientes = this.myFunctions.listToStirng(idsClientes, "_", "0");
    var piezas = this.myFunctions.listToStirng(idPiezas, "_", "0");
    var partes = this.myFunctions.listToStirng(idPartes, "_", "0");
    var operaciones = this.myFunctions.listToStirng(idOperaciones, "_", "0");

    this.router.navigate(['historicoOperaciones/' + this.route.snapshot.params.idHistorico_piezas + "/" + fini + '/' + ffin + '/' + turnos + '/' + maquinas + '/' + herramientas + '/' + operarios + '/' + ofs + '/' + clientes + '/' + piezas + '/' + partes + '/' + operaciones]);
  }
  btnAnaliticaAvanzada() {

    // filtro fechas
    var fini = this.myFunctions.dateToYYYY_MM_DD(this.fechaIni); //FORMATO: YYYY_MM_DD
    var ffin = this.myFunctions.dateToYYYY_MM_DD(this.fechaFin); //FORMATO: YYYY_MM_DD

    // filtro generales
    var idsTurnos = [];
    if (this.turnosSeleccionados != undefined)
      this.turnosSeleccionados.forEach(of => idsTurnos.push(of.idTurno));

    var idMaquinas = [];
    if (this.maquinasSeleccionadas != undefined)
      this.maquinasSeleccionadas.forEach(of => idMaquinas.push(of.idMaquina));

    var idsHerramientas = [];
    if (this.herramientasSeleccionadas != undefined)
      this.herramientasSeleccionadas.forEach(of => idsHerramientas.push(of.idHerramienta));

    var idsOperarios = [];
    if (this.operariosSeleccionados != undefined)
      this.operariosSeleccionados.forEach(operario => idsOperarios.push(operario.idOperario));


    // filtro ofs...
    var idsOFs = [];
    if (this.ofsSeleccionados != undefined)
      this.ofsSeleccionados.forEach(of => idsOFs.push(of.idOf));

    var idsClientes = [];
    if (this.clientesSeleccionados != undefined)
      this.clientesSeleccionados.forEach(cliente => idsClientes.push(cliente.idCliente));

    var idPiezas = [];
    if (this.piezasSeleccionadas != undefined)
      this.piezasSeleccionadas.forEach(pieza => idPiezas.push(pieza.idPieza));

    var idPartes = [];
    if (this.partesSeleccionadas != undefined)
      this.partesSeleccionadas.forEach(parte => idPartes.push(parte.idParte));

    var idOperaciones = [];
    if (this.operacionesSeleccionadas != undefined)
      this.operacionesSeleccionadas.forEach(operacion => idOperaciones.push(operacion.idOperacion));

    var ofs = this.myFunctions.listToStirng(idsOFs, "_", "0");
    var clientes = this.myFunctions.listToStirng(idsClientes, "_", "0");
    var piezas = this.myFunctions.listToStirng(idPiezas, "_", "0");

    var terminados;
    if (this.terminados) terminados = 1;
    else terminados = 0;
    window.open('#/analiticaavanzadaejecuciones/' + fini + '/' + ffin + '/' + clientes + '/' + piezas + '/' + ofs + '/' + terminados + "/" + this.route.snapshot.params.idHistorico_piezas + "/" + this.route.snapshot.params.idHistorico_operaciones, '_blank');
  }

  onUploadButtonClick(e: UploadEvent, content) {

    let idOperacion: number = this.route.snapshot.params.idHistorico_operaciones;

    let idOperacionString: string  = idOperacion.toString();
    this._kendoFiles = e.files;
    this.myFiles = [];
    var f: File = this._kendoFiles[0].rawFile;

    const formData: FormData = new FormData();
    formData.append('file', f, f.name);
    formData.append('idOperacion',new Blob(["idOPeracion"]), this.route.snapshot.params.idHistorico_operaciones);

    this.modalReferenceloadingupload = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });

    this.historicoOperacionesService.importarExcel(formData).subscribe((result)=>{
      this.modalReferenceloadingupload.close();
      /*if (result.error == false) {
        this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
      } else {
        this.alertService.error(this.translate.instant('error'));
      }*/
      //this.myFiles = [];
      //this.cargarDatos();
    });
    console.log("Despues");
  }


  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth && !element.classList.contains('celda-tooltip-externo')) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  pageChange(event: PageChangeEvent) {
    this.skip = event.skip;
    this.loadItems();
  }
  loadItems() {
    var an: any = this.Joperarios;
    this.JoperariosView = an.slice(this.skip, this.skip + this.pageSize);
  }
  pageChange2(event: PageChangeEvent) {
    this.skip2 = event.skip;
    this.loadItems();
  }
  loadItems2() {
    var an: any = this.Jprocesos;

    this.JprocesosView = an.slice(this.skip, this.skip + this.pageSize);
  }
  pageChange3(event: PageChangeEvent) {
    this.skip3 = event.skip;
    this.loadItems();
  }
  loadItems3() {
    var an: any = this.Jincidencias;
    this.JincidenciasView = an.slice(this.skip, this.skip + this.pageSize);
  }

  dateToYYYYMMDDHHmmSS(fecha: Date) { // es para ordenar en ascendente el string
    //20201025230000
    var ano = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate();
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return ano + '-' + this.addZero(mes) + this.addZero(dia) + this.addZero(hora) + this.addZero(minutos) + this.addZero(segundos);
  }
  addZero(n: number) {
    if (n < 10)
      return '0' + n.toString();
    else
      return n.toString();
  }
  dateCopy(miFecha: Date) {
    return new Date(miFecha.getFullYear(), miFecha.getMonth(), miFecha.getDate(), miFecha.getHours(), miFecha.getMinutes(), miFecha.getSeconds(), miFecha.getMilliseconds());
  }
  secondsToHms(seconds: number) {
    const days = Math.floor(seconds / 86400);
    const remainderSeconds = seconds % 86400;
    const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
    return hms.replace(/^(\d+)/, h => `${Number(h) + days * 24}`.padStart(2, '0'));
  }

  clickFoto(dataItem){
    this.dataItemSeleccionado = dataItem;
    if(dataItem.imagenBase64==""){
      this.imageToShowCalidad = "";
      this.archivoAgregar = "";
      this.archivoAgregarNombre = "";
      this.mostrarImagenCalidad = false;
    }else{
      this.imageToShowCalidad = dataItem.imagenBase64;
      this.archivoAgregar = dataItem.imagenBase64;
      this.archivoAgregarNombre = dataItem.fotoPath;
      this.mostrarImagenCalidad = true;
    }
    this.modalReferenceUpload = this.modalService.open(this.popupImagenCalidad, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }

  cancelarModalCalidad(){
    this.modalReferenceUpload.close();
  }

  aceptarModalCalidad(){
    this.dataItemSeleccionado.imagenBase64 = this.archivoAgregar;
    this.dataItemSeleccionado.fotoPath = this.archivoAgregarNombre;
    this.modalReferenceUpload.close();
  }

  aceptarModalOperacion() {
    var archivo = this.archivoAgregar.split(',')[1]; //Remove cabezera
    this.historicoOperacionesService.set_Imagen_HO(this.route.snapshot.params.idHistorico_operaciones, this.user.id, archivo, this.archivoAgregarNombre, this.nombreImagenAnterior).subscribe(
      (result) => {
        document.getElementById('imagen').setAttribute("src", this.archivoAgregar);
        this.nombreImagenAnterior = this.archivoAgregarNombre;
        this.modalReferenceUpload.close();
      });
  }
  imagenSeleccionada(e, reemplazarimagen) {
    var th = this;
    this.archivoAgregarNombre = e.files[0].name;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivoAgregar != undefined && th.archivoAgregar !== "" && th.archivoAgregar !== null && th.archivoAgregar.length > 0)
        archivoBase64 = await th.toBase64(th.archivoAgregar[0]);
      else
        archivoBase64 = "";
      th.archivoAgregar = archivoBase64;
      th.imageToShowOperacion = archivoBase64;
    }, 500);
    if (!e.files[0].validationErrors) this.modalReferenceUpload = this.modalService.open(reemplazarimagen, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }
  archivoSeleccionado(e) {
    var th = this;
    this.archivoAgregarNombre = e.files[0].name;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivoAgregar !== "" && th.archivoAgregar !== null)
        archivoBase64 = await th.toBase64(th.archivoAgregar[0]);
      else
        archivoBase64 = "";
      th.mostrarImagenCalidad = true;
      th.archivoAgregar = archivoBase64;
      th.imageToShowCalidad = archivoBase64;
    }, 500);
  }

  archivoEliminado(e){
    this.archivoAgregar = undefined;
    this.imageToShowCalidad = undefined;
    this.mostrarImagenCalidad = false;
    this.archivoAgregarNombre = undefined;
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  public rowCallback(context: RowClassArgs) {
    var cota = context.dataItem;
    if(cota.tipoValorNumerico){
      if (cota.critico){
        if((cota.medicion!=undefined && cota.medicion!=null && !Number.isNaN(cota.medicion)) && !(cota.medicion <= cota.toleranciaMax && cota.medicion >= cota.toleranciaMin)){
          return {rojo: true}
        }else{
          return {rojo:false}
        }
      }else{
        if((cota.medicion!=undefined && cota.medicion!=null && !Number.isNaN(cota.medicion)) && !(cota.medicion <= cota.toleranciaMax && cota.medicion >= cota.toleranciaMin)){
          return {naranja: true}
        }else{
          return {naranja:false}
        }
      }
    }else{
      if (cota.critico){
        if((cota.medicion!=undefined && cota.medicion!=null && !Number.isNaN(cota.medicion)) && (cota.medicion==0)){
          return {rojo: true}
        }else{
          return {rojo:false}
        }
      }else{
        if((cota.medicion!=undefined && cota.medicion!=null && !Number.isNaN(cota.medicion)) && (cota.medicion==0)){
          return {naranja: true}
        }else{
          return {naranja:false}
        }
      }
    }
  }

  public rowBackgroundColor(context: RowClassArgs) 
  {
    var cotaPieza = context.dataItem;

    //define: Background color
    let color='white';
    let valoresCriticosOk=cotaPieza.criticosOk.split('/');
    let valoresOtrosOk=cotaPieza.otrosOk.split('/');
    
    if (Number(valoresCriticosOk[0])<Number(valoresCriticosOk[1]))
    return {rojo: true}
    if (Number(valoresOtrosOk[0])<Number(valoresOtrosOk[1]))
    return {naranja: true}

    return{}


  }

  btnSi(dataItem){
    dataItem.medicion=1;
  }

  btnNo(dataItem){
    dataItem.medicion=0;
  }

  public validarOperacion(e)
  {
    e.preventDefault();

    //this.route.snapshot.params.idHistorico_operaciones
    //this.calidadSeleccionadas
    //this.JvaloresView.filter(x => x.frecuencia==this.calidadSeleccionadas[0])[0].fechaValidacion=new Date();
    //new Date().getFullYear()
    //this.JvaloresView.filter(x => x.frecuencia==this.calidadSeleccionadas[0])[0].fechaValidacion=new Date().getFullYear()+'-'+new Date().getMonth()+'T'+new Date().toLocaleTimeString();
    let eguna=new Date();
    //let egunaTxt=eguna.getFullYear()+'-'+eguna.getMonth()+1+'-'+eguna.getDate()+'T'+eguna.toLocaleTimeString();
    let egunaTxt = this.myFunctions.dateToYYYYMMDDHHmmSSconSeparacion(eguna);
    //let latest_date =this.datepipe.transform(egunaTxt, 'yyyy-MM-dd');

    let pedirObservacion = false;
    for (let elem of this.calidadSeleccionadas)
    {
      pedirObservacion = this.pedirObservacion(elem);
      if (pedirObservacion) 
        break;
    }

    if (!pedirObservacion)
      {
        this.JvaloresView.filter(x => x.frecuencia==this.calidadSeleccionadas[0])[0].fechaValidacion=egunaTxt;  //HEMEN NAGOOOOO
        // this.JvaloresView.filter(x => x.frecuencia==this.calidadSeleccionadas[0])[0].fechaValidacionOrden = 
        //               this.myFunctions.dateToYYYYMMDDHHmmSS(eguna) + " " + 
        //               this.myFunctions.dateWithoutYearShorted(this.myFunctions.sqlToJsDate(egunaTxt))+
        //               this.myFunctions.dateToHHMM(this.myFunctions.sqlToJsDate(egunaTxt));   
        this.JvaloresView.filter(x => x.frecuencia==this.calidadSeleccionadas[0])[0].validadas=this.JvaloresView.filter(x => x.frecuencia==this.calidadSeleccionadas[0])[0].numMedidas;
        this.btnGuardar();
      }
    else 
        this.alertService.error(this.translateService.instant('errorObservacion'));

  }

  public pedirObservacion(elem):boolean
  {
    let pedirObserv = this.JvaloresView[0].idObservacionPiezas;
    let observacionResp = this.JvaloresView.filter(x => x.frecuencia==elem)[0].observacionResponsable;
    //insert into calidad_observacionPiezas_DAT (id, nombre) VALUES (1,'Siempre')
    //insert into calidad_observacionPiezas_DAT (id, nombre) VALUES (2,'Nunca')
    //insert into calidad_observacionPiezas_DAT (id, nombre) VALUES (3,'Piezas No OK')
    if (pedirObserv==2) return false;
    if (pedirObserv==1 && (observacionResp=='' || observacionResp==null || observacionResp==undefined)) 
        return true;
    if (pedirObserv==0 ) 
    {
      let valoresCriticosOk=elem.criticosOk.split('/');
      let valoresOtrosOk=elem.otrosOk.split('/');
      if (Number(valoresCriticosOk[0])<Number(valoresCriticosOk[1])) return true;
      if (Number(valoresOtrosOk[0])<Number(valoresOtrosOk[1])) return true;
    }

    return false;
  }


  public onExcelExportOneLine(args: ExcelExportEvent): void 
  {
    const observables = [];
    const workbook = args.workbook;
    let rows =  workbook.sheets[0].rows;
    //let rowsAux = Object.assign([], rows);
    //let rowsAux = rows;
    //let rowsAux = rows.slice(); //array-aren kopia bat egin, erreferentziarik gabe.
    let rowsAux = JSON.parse(JSON.stringify(rows));
    const headerOptions = rows[0].cells[0];

    const dataPiezas = this.JvaloresView;
    let utillaje = '';

    let rows2=[]
    let kont=0
    rowsAux.forEach((row) => {
        //let rowAux = row;
        if (kont==0)
        {
          //columns!!!
          const rowAux = JSON.parse(JSON.stringify(row));
          const cellsAux= [
            Object.assign({}, headerOptions, { value: this.translateService.instant('cota') }),
            Object.assign({}, headerOptions, { value: this.translateService.instant('critico') }),
            Object.assign({}, headerOptions, { value: this.translateService.instant('valornominal') }),
            Object.assign({}, headerOptions, { value: this.translateService.instant('minTolerancia') }),
            Object.assign({}, headerOptions, { value: this.translateService.instant('maxTolerancia') }),
            Object.assign({}, headerOptions, { value: this.translateService.instant('utillaje') }),
           // Object.assign({}, headerOptions, { value: this.translateService.instant('idNumSerie') }),

           // Object.assign({}, headerOptions, { value: this.translateService.instant('utillajeValor') }),
            Object.assign({}, headerOptions, { value: this.translateService.instant('nSerieUtillaje') }),
            Object.assign({}, headerOptions, { value: this.translateService.instant('medicion') }),
            Object.assign({}, headerOptions, { value: this.translateService.instant('observacion') }),
          ]
          cellsAux.forEach(r =>{rowAux.cells.push(r);     });
          rows2.push(rowAux);
        }
        else
        {
            const dataPiezaCotas = dataPiezas[kont-1].interna;
            if (dataPiezaCotas.length>0)
            {
              //for ( let cotaIdx = dataPiezaCotas.length - 1;cotaIdx >= 0; cotaIdx--) 
              for ( let cotaIdx = 0; cotaIdx < dataPiezaCotas.length; cotaIdx++) 
              {
                //const rowAux = Object.assign({},row);
                //const rowAux = row;
                //const rowAux = {...row}; //kopia egin, erreferentziarik gabe
                const rowAux = JSON.parse(JSON.stringify(row));
                //validationDate = Date(rowAux.cells[6].value.split(' ')[0])
                //validationDate = new Date(Date(rowAux.cells[6].value.split(' ')[0]))
                let fechaValidacion = rowAux.cells[6].value;
                if (fechaValidacion!=undefined)
                  if (fechaValidacion.split(' ').length>3)
                    rowAux.cells[6].value = fechaValidacion.split(' ')[1]+' '+fechaValidacion.split(' ')[2]+' '+fechaValidacion.split(' ')[3];
                const cota = dataPiezaCotas[cotaIdx];
                let nSerieNombre='';
                //cota.idUtillajeNSerie
                if (cota.idUtillajeNSerie>0 && cota.numSerieUtillaje.length>0) 
                  nSerieNombre=cota.numSerieUtillaje.filter(x=>x.id==cota.idUtillajeNSerie)[0].nSerie;
                
                let critico='';
                if  (cota.critico) critico=this.translateService.instant('critico');
                
                const cellsAux= [
                  { value: cota.valor },
                  { value: critico },
                  { value: cota.valorNominal },
                  { value: cota.toleranciaMin }, 
                  { value: cota.toleranciaMax }, 
                  { value: cota.utillajeNombre }, 
                  //{ value: cota.idUtillajeNSerie }, 
                  { value: nSerieNombre}, 
                  { value: cota.medicion }, 
                  { value: cota.observacion }, 
                ]
                cellsAux.forEach(r =>{rowAux.cells.push(r);     });
                //rows2.push(Object.assign({},rowAux));
                rows2.push(rowAux);
              }
            }
         }
        kont=kont+1;

    });
    workbook.sheets[0].rows=[];
    workbook.sheets[0].rows=rows2;
    
    new Workbook(workbook).toDataURL().then((dataUrl: string) => {
      // https://www.telerik.com/kendo-angular-ui/components/filesaver/
      //saveAs(dataUrl, "CALIDAD11111111111111111111111.xlsx");
    });
  }

  public onExcelExport(args: ExcelExportEvent): void {
    // Prevent automatically saving the file. We will save it manually after we fetch and add the details
    args.preventDefault();


    const observables = [];
    const workbook = args.workbook;
    const rows = workbook.sheets[0].rows;

    // Get the default header styles.
    // Aternatively set custom styles for the details
    // https://www.telerik.com/kendo-angular-ui/components/excelexport/api/WorkbookSheetRowCell/
    const headerOptions = rows[0].cells[0];

    const dataPiezas = this.JvaloresView;

    let utillaje = '';
    let medicion=0;

    for (let idx = dataPiezas.length - 1; idx >= 0; idx--) {
      //const products = (<GridDataResult>data[idx]).data;
      const dataPiezaCotas = dataPiezas[idx].interna;

      // add the detail data
      for ( let cotaIdx = dataPiezaCotas.length - 1;cotaIdx >= 0; cotaIdx--) 
      {
        

        const cota = dataPiezaCotas[cotaIdx];
        utillaje=''
        if (cota.utillaje>0 && cota.numSerieUtillaje.length>0) utillaje=cota.numSerieUtillaje.filter(x=>x.id==cota.utillaje)[0].nSerie;

        rows.splice(idx + 2, 0, {
          cells: [
            {},
            { value: cota.valor },
            { value: cota.valorNominal },
            { value: cota.toleranciaMin }, 
            { value: cota.toleranciaMax }, 
            { value: cota.utillajeNombre }, 
            { value: cota.utillaje }, 
            { value: utillaje}, 
            { value: cota.medicion }, 
            
          ],
        });
      }
      rows.splice();

      // add the detail header
      rows.splice(idx + 2, 0, {
        cells: [
          {},
          Object.assign({}, headerOptions, { value: this.translateService.instant('valor') }),
          Object.assign({}, headerOptions, { value: this.translateService.instant('valornominal') }),
          Object.assign({}, headerOptions, { value: this.translateService.instant('minTolerancia') }),
          Object.assign({}, headerOptions, { value: this.translateService.instant('maxTolerancia') }),
          Object.assign({}, headerOptions, { value: this.translateService.instant('utillaje') }),
          Object.assign({}, headerOptions, { value: this.translateService.instant('utillajeValor') }),
          Object.assign({}, headerOptions, { value: this.translateService.instant('nSerieUtillaje') }),
          Object.assign({}, headerOptions, { value: this.translateService.instant('medicion') }),
        ],
      });
    }
    


    //   // create a Workbook and save the generated data URL
    //   // https://www.telerik.com/kendo-angular-ui/components/excelexport/api/Workbook/
      new Workbook(workbook).toDataURL().then((dataUrl: string) => {
         // https://www.telerik.com/kendo-angular-ui/components/filesaver/
         saveAs(dataUrl, "CALIDAD.xlsx");
       });
    // });
  }

  recalcularCotasOk(dataItem)
  {
    let kkkk=1;
    this.JvaloresView.forEach(element => {
      // let critOK = element.criticosOk.split('/')[0];
      // let totalOK = element.totalOk.split('/')[0];
      // let otrOK = element.otrosOk.split('/')[0];

      let critOK =0;
      let totalOK=0;
      let otrOK=0;



      element.interna.forEach(element2 => {
        let toleranciaMin=element2.toleranciaMin;
      let toleranciaMax=element2.toleranciaMax;
      if (element2.tipoValorBooleano)
      {
        if (element2.medicion==1 || element2.medicion==-1)
        {
        if (element2.critico) critOK=critOK+1;
        else otrOK=otrOK+1;
        totalOK=totalOK+1;
        }
      }
      else
      {
        if (element2.medicion>0)
         {
          if (element2.medicion>=toleranciaMin && element2.medicion<=toleranciaMax)
            {
              if (element2.critico) critOK=critOK+1;
              else otrOK=otrOK+1;
              totalOK=totalOK+1;
            }
         }
        else if (element2.medicion==-1)
        {
          if (element2.critico) critOK=critOK+1;
              else otrOK=otrOK+1;
              totalOK=totalOK+1;
        }
      }
      });

      element.criticosOk=critOK+'/'+element.criticosOk.split('/')[1];
      element.otrosOk=otrOK+'/'+element.otrosOk.split('/')[1];
      element.totalOk=totalOK+'/'+element.totalOk.split('/')[1];

    });
  }

  cellClick(e) {
    if (this.operacionesSelecteds[0] != null) {

      // filtro fechas
      var fini = this.myFunctions.dateToYYYY_MM_DD(this.fechaIni); //FORMATO: YYYY_MM_DD
      var ffin = this.myFunctions.dateToYYYY_MM_DD(this.fechaFin); //FORMATO: YYYY_MM_DD

      if (this.tipo_maquina == 5) {
        // maquina
        var idMaquina = this.dataProcesos.filter(f => f.id == this.operacionesSelecteds[0])[0].idMaquina;
        this.router.navigate(['recetasInduccion/' + this.route.snapshot.params.idHistorico_piezas + "/" + this.route.snapshot.params.idHistorico_operaciones + "/" + this.infoOF + "/" + this.operacionesSelecteds[0] + "/" + fini + '/' + ffin + '/0/' + idMaquina + '/0/0/0/0/0/0/0']);
      } else if (this.tipo_maquina == 4) {
        this.router.navigate(['recetasInyeccion/' + this.route.snapshot.params.idHistorico_piezas + "/" + this.route.snapshot.params.idHistorico_operaciones + "/" + this.infoOF + "/" + this.operacionesSelecteds[0] + "/" + fini + '/' + ffin + '/0/0/0/0/0/0/0/0/0']);
      }
      
    }
  }

  // INYECTORAS
  cargarParametrosTolerancia() {
    var parametros = [
      "tiempociclo",
      "tiempoinyeccion",
      "tiempopresionremanente",
      "tiempoenfriamiento",
      "tiempodosificacionplastificacion",
      "presioninyeccionmax",
      "presionremanente",
      "contrapresion",
      "ptoconmutacion",
      "paradadosificado",
      "retrocesohusillo",
      "paradamolde",
      "cojin",
      "revolucioneshusillo",
      "fuerzadecierre",
      "temperaturacamaracaliente",
      "temppresionfija",
      "temppresionmovil",
      "temperaturamolde"
    ]
    this.dataProcesosInyeccion.forEach(element => {
      var tolerancia = true;

      parametros.forEach(row => {
        var actual = element[row];
        var estimado = element[row+'real'];
        var toler = element[row+'tolerancia'];

        var r1 = estimado + toler >= actual && estimado - toler <= actual;

        if (!r1) {
          tolerancia = false;
        }
      });

      element["tolerancia"] = tolerancia;
      
    });
    
  }

}
