import { Component, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as c3 from 'c3';

/* SERVICES*/
import { MaquinasService, UsuariosService, PlanificadorService, MenuService, } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';

import { Router } from '@angular/router';

@Component({
  selector: 'app-PlanificadorLargoComparativaVersiones',
  templateUrl: './PlanificadorLargoComparativaVersiones.component.html'
})


export class PlanificadorLargoComparativaVersionesComponent {
  public loadingPanel: boolean = false;

  /*COMPARATIVA*/
  public aplicarTiempoComparativa: boolean = true;
  public aplicarTiempoEstimado: boolean = true;
  public aplicarCalculoPiezas: boolean = false;

  // 1: Tasa de servicio
  public TS_OF_V1: number = 0.0;
  public TS_OF_V2: number = 0.0;
  public TS_OF_V3: number = 0.0;
  public TS_OF_V4: number = 0.0;

  public TS_OF_tiempo_V1: number = 0.0;
  public TS_OF_tiempo_V2: number = 0.0;
  public TS_OF_tiempo_V3: number = 0.0;
  public TS_OF_tiempo_V4: number = 0.0;


  // 2: Tasa de servicio clientes (por grupos)
  public TS_Cli_grupo_A_V1: number = 0.0;
  public TS_Cli_grupo_B_V1: number = 0.0;
  public TS_Cli_grupo_C_V1: number = 0.0;
  public TS_Cli_grupo_A_V2: number = 0.0;
  public TS_Cli_grupo_B_V2: number = 0.0;
  public TS_Cli_grupo_C_V2: number = 0.0;
  public TS_Cli_grupo_A_V3: number = 0.0;
  public TS_Cli_grupo_B_V3: number = 0.0;
  public TS_Cli_grupo_C_V3: number = 0.0;
  public TS_Cli_grupo_A_V4: number = 0.0;
  public TS_Cli_grupo_B_V4: number = 0.0;
  public TS_Cli_grupo_C_V4: number = 0.0;

  public TS_Cli_tiempo_grupo_A_V1: number = 0.0;
  public TS_Cli_tiempo_grupo_B_V1: number = 0.0;
  public TS_Cli_tiempo_grupo_C_V1: number = 0.0;
  public TS_Cli_tiempo_grupo_A_V2: number = 0.0;
  public TS_Cli_tiempo_grupo_B_V2: number = 0.0;
  public TS_Cli_tiempo_grupo_C_V2: number = 0.0;
  public TS_Cli_tiempo_grupo_A_V3: number = 0.0;
  public TS_Cli_tiempo_grupo_B_V3: number = 0.0;
  public TS_Cli_tiempo_grupo_C_V3: number = 0.0;
  public TS_Cli_tiempo_grupo_A_V4: number = 0.0;
  public TS_Cli_tiempo_grupo_B_V4: number = 0.0;
  public TS_Cli_tiempo_grupo_C_V4: number = 0.0;


  // 3: Tasa de servicio facturacion Cliente
  public TS_Cli_facturacion_Alta_V1: number = 0.0;
  public TS_Cli_facturacion_Alta_V2: number = 0.0;
  public TS_Cli_facturacion_Alta_V3: number = 0.0;
  public TS_Cli_facturacion_Alta_V4: number = 0.0;

  public TS_Cli_facturacion_Media_V1: number = 0.0;
  public TS_Cli_facturacion_Media_V2: number = 0.0;
  public TS_Cli_facturacion_Media_V3: number = 0.0;
  public TS_Cli_facturacion_Media_V4: number = 0.0;

  public TS_Cli_facturacion_Baja_V1: number = 0.0;
  public TS_Cli_facturacion_Baja_V2: number = 0.0;
  public TS_Cli_facturacion_Baja_V3: number = 0.0;
  public TS_Cli_facturacion_Baja_V4: number = 0.0;

  public TS_Cli_tiempo_facturacion_Alta_V1: number = 0.0;
  public TS_Cli_tiempo_facturacion_Alta_V2: number = 0.0;
  public TS_Cli_tiempo_facturacion_Alta_V3: number = 0.0;
  public TS_Cli_tiempo_facturacion_Alta_V4: number = 0.0;

  public TS_Cli_tiempo_facturacion_Media_V1: number = 0.0;
  public TS_Cli_tiempo_facturacion_Media_V2: number = 0.0;
  public TS_Cli_tiempo_facturacion_Media_V3: number = 0.0;
  public TS_Cli_tiempo_facturacion_Media_V4: number = 0.0;

  public TS_Cli_tiempo_facturacion_Baja_V1: number = 0.0;
  public TS_Cli_tiempo_facturacion_Baja_V2: number = 0.0;
  public TS_Cli_tiempo_facturacion_Baja_V3: number = 0.0;
  public TS_Cli_tiempo_facturacion_Baja_V4: number = 0.0;


  // 4: Tasa de disminucion de Paradas: en libre y extra
  public tiempoLibreHHmmSS_V1: string = '00:00:00';
  public tiempoLibreHHmmSS_V2: string = '00:00:00';
  public tiempoLibreHHmmSS_V3: string = '00:00:00';
  public tiempoLibreHHmmSS_V4: string = '00:00:00';
  public tiempoLibreHHmmSS_V1_segundos: number = 0;
  public tiempoLibreHHmmSS_V2_segundos: number = 0;
  public tiempoLibreHHmmSS_V3_segundos: number = 0;
  public tiempoLibreHHmmSS_V4_segundos: number = 0;

  public tiempoExtraHHmmSS_V1: string = '00:00:00';
  public tiempoExtraHHmmSS_V2: string = '00:00:00';
  public tiempoExtraHHmmSS_V3: string = '00:00:00';
  public tiempoExtraHHmmSS_V4: string = '00:00:00';
  public tiempoExtraHHmmSS_V1_segundos: number = 0;
  public tiempoExtraHHmmSS_V2_segundos: number = 0;
  public tiempoExtraHHmmSS_V3_segundos: number = 0;
  public tiempoExtraHHmmSS_V4_segundos: number = 0;

  public tiempoLibrePorcen_V1: number = 0.0;
  public tiempoLibrePorcen_V2: number = 0.0;
  public tiempoLibrePorcen_V3: number = 0.0;
  public tiempoLibrePorcen_V4: number = 0.0;

  public tiempoExtraPorcen_V1: number = 0.0;
  public tiempoExtraPorcen_V2: number = 0.0;
  public tiempoExtraPorcen_V3: number = 0.0;
  public tiempoExtraPorcen_V4: number = 0.0;


  // 5: Tasa de servicio prioridad OF
  public TS_OF_prioridad_Alta_V1: number = 0.0;
  public TS_OF_prioridad_Alta_V2: number = 0.0;
  public TS_OF_prioridad_Alta_V3: number = 0.0;
  public TS_OF_prioridad_Alta_V4: number = 0.0;

  public TS_OF_prioridad_Media_V1: number = 0.0;
  public TS_OF_prioridad_Media_V2: number = 0.0;
  public TS_OF_prioridad_Media_V3: number = 0.0;
  public TS_OF_prioridad_Media_V4: number = 0.0;

  public TS_OF_prioridad_Baja_V1: number = 0.0;
  public TS_OF_prioridad_Baja_V2: number = 0.0;
  public TS_OF_prioridad_Baja_V3: number = 0.0;
  public TS_OF_prioridad_Baja_V4: number = 0.0;

  public TS_OF_tiempo_prioridad_Alta_V1: number = 0.0;
  public TS_OF_tiempo_prioridad_Alta_V2: number = 0.0;
  public TS_OF_tiempo_prioridad_Alta_V3: number = 0.0;
  public TS_OF_tiempo_prioridad_Alta_V4: number = 0.0;

  public TS_OF_tiempo_prioridad_Media_V1: number = 0.0;
  public TS_OF_tiempo_prioridad_Media_V2: number = 0.0;
  public TS_OF_tiempo_prioridad_Media_V3: number = 0.0;
  public TS_OF_tiempo_prioridad_Media_V4: number = 0.0;

  public TS_OF_tiempo_prioridad_Baja_V1: number = 0.0;
  public TS_OF_tiempo_prioridad_Baja_V2: number = 0.0;
  public TS_OF_tiempo_prioridad_Baja_V3: number = 0.0;
  public TS_OF_tiempo_prioridad_Baja_V4: number = 0.0;


  // 6: Margen de entrega Semanas antes (por OF y por Piezas)
  public margenNegativo_V1: number = 0.0;
  public margenNegativo_V2: number = 0.0;
  public margenNegativo_V3: number = 0.0;
  public margenNegativo_V4: number = 0.0;

  public margenCorto_V1: number = 0.0;
  public margenCorto_V2: number = 0.0;
  public margenCorto_V3: number = 0.0;
  public margenCorto_V4: number = 0.0;

  public margenMedio_V1: number = 0.0;
  public margenMedio_V2: number = 0.0;
  public margenMedio_V3: number = 0.0;
  public margenMedio_V4: number = 0.0;

  public margenLargo_V1: number = 0.0;
  public margenLargo_V2: number = 0.0;
  public margenLargo_V3: number = 0.0;
  public margenLargo_V4: number = 0.0;

  public margenResto_V1: number = 0.0;
  public margenResto_V2: number = 0.0;
  public margenResto_V3: number = 0.0;
  public margenResto_V4: number = 0.0;

  public margenNegativoPiezas_V1: number = 0.0;
  public margenNegativoPiezas_V2: number = 0.0;
  public margenNegativoPiezas_V3: number = 0.0;
  public margenNegativoPiezas_V4: number = 0.0;

  public margenCortoPiezas_V1: number = 0.0;
  public margenCortoPiezas_V2: number = 0.0;
  public margenCortoPiezas_V3: number = 0.0;
  public margenCortoPiezas_V4: number = 0.0;

  public margenMedioPiezas_V1: number = 0.0;
  public margenMedioPiezas_V2: number = 0.0;
  public margenMedioPiezas_V3: number = 0.0;
  public margenMedioPiezas_V4: number = 0.0;

  public margenLargoPiezas_V1: number = 0.0;
  public margenLargoPiezas_V2: number = 0.0;
  public margenLargoPiezas_V3: number = 0.0;
  public margenLargoPiezas_V4: number = 0.0;

  public margenRestoPiezas_V1: number = 0.0;
  public margenRestoPiezas_V2: number = 0.0;
  public margenRestoPiezas_V3: number = 0.0;
  public margenRestoPiezas_V4: number = 0.0;


  // 7: Retraso medio
  public retrasoMedio_V1: number = 0.0;
  public retrasoMedio_V2: number = 0.0;
  public retrasoMedio_V3: number = 0.0;
  public retrasoMedio_V4: number = 0.0;

  public retrasoMedioPiezas_V1: number = 0.0;
  public retrasoMedioPiezas_V2: number = 0.0;
  public retrasoMedioPiezas_V3: number = 0.0;
  public retrasoMedioPiezas_V4: number = 0.0;

  // 8: Valoracion media
  public valoracionMedia_V1: number = 0.0;
  public valoracionMedia_V2: number = 0.0;
  public valoracionMedia_V3: number = 0.0;
  public valoracionMedia_V4: number = 0.0;


  /*COMPARATIVA*/

  user = this.userService.userValue;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;

  //"LOAD"
  constructor(private maquinasService: MaquinasService, private userService: UsuariosService, private translateService: TranslateService,
    private modalService: NgbModal, private planificadorService: PlanificadorService,
    public router: Router, private menuService: MenuService) {

    this.menuService.titulo = this.translateService.instant('planificadorLargoComparativa').toUpperCase();

    this.cargarDatos();
  }

  ngOnInit() {
  }

  /*CARGA DE DATOS*/
  cargarDatos() {
    // PONER TODOS LOS VALORES A 0 por si es una recarga
    this.vaciarValores()

    this.loadingPanel = true;

    var r1, r2, r3, r4: boolean = false;

    this.planificadorService.CalificarVersion(1, this.aplicarTiempoEstimado).subscribe(
      json => {
        r1 = true;

        // CARGAR LAVELS V1
        this.cargarV1(json);

        if (r1 && r2 && r3 && r4)
          this.calcularTotales();
      });

    this.planificadorService.CalificarVersion(2, this.aplicarTiempoEstimado).subscribe(
      json => {
        r2 = true;

        // CARGAR LAVELS V2
        this.cargarV2(json);

        if (r1 && r2 && r3 && r4)
          this.calcularTotales();
      });
    this.planificadorService.CalificarVersion(3, this.aplicarTiempoEstimado).subscribe(
      json => {
        r3 = true;

        // CARGAR LAVELS V3
        this.cargarV3(json);

        if (r1 && r2 && r3 && r4)
          this.calcularTotales();
      });
    this.planificadorService.CalificarVersion(4, this.aplicarTiempoEstimado).subscribe(
      json => {
        r4 = true;

        // CARGAR LAVELS V4
        this.cargarV4(json);

        if (r1 && r2 && r3 && r4)
          this.calcularTotales();
      });

  }

  vaciarValores() {

    // 1: Tasa de servicio
    this.TS_OF_V1 = 0.0;
    this.TS_OF_V2 = 0.0;
    this.TS_OF_V3 = 0.0;
    this.TS_OF_V4 = 0.0;

    this.TS_OF_tiempo_V1 = 0.0;
    this.TS_OF_tiempo_V2 = 0.0;
    this.TS_OF_tiempo_V3 = 0.0;
    this.TS_OF_tiempo_V4 = 0.0;


    // 2: Tasa de servicio clientes (por grupos)
    this.TS_Cli_grupo_A_V1 = 0.0;
    this.TS_Cli_grupo_B_V1 = 0.0;
    this.TS_Cli_grupo_C_V1 = 0.0;
    this.TS_Cli_grupo_A_V2 = 0.0;
    this.TS_Cli_grupo_B_V2 = 0.0;
    this.TS_Cli_grupo_C_V2 = 0.0;
    this.TS_Cli_grupo_A_V3 = 0.0;
    this.TS_Cli_grupo_B_V3 = 0.0;
    this.TS_Cli_grupo_C_V3 = 0.0;
    this.TS_Cli_grupo_A_V4 = 0.0;
    this.TS_Cli_grupo_B_V4 = 0.0;
    this.TS_Cli_grupo_C_V4 = 0.0;

    this.TS_Cli_tiempo_grupo_A_V1 = 0.0;
    this.TS_Cli_tiempo_grupo_B_V1 = 0.0;
    this.TS_Cli_tiempo_grupo_C_V1 = 0.0;
    this.TS_Cli_tiempo_grupo_A_V2 = 0.0;
    this.TS_Cli_tiempo_grupo_B_V2 = 0.0;
    this.TS_Cli_tiempo_grupo_C_V2 = 0.0;
    this.TS_Cli_tiempo_grupo_A_V3 = 0.0;
    this.TS_Cli_tiempo_grupo_B_V3 = 0.0;
    this.TS_Cli_tiempo_grupo_C_V3 = 0.0;
    this.TS_Cli_tiempo_grupo_A_V4 = 0.0;
    this.TS_Cli_tiempo_grupo_B_V4 = 0.0;
    this.TS_Cli_tiempo_grupo_C_V4 = 0.0;


    // 3: Tasa de servicio facturacion Cliente
    this.TS_Cli_facturacion_Alta_V1 = 0.0;
    this.TS_Cli_facturacion_Alta_V2 = 0.0;
    this.TS_Cli_facturacion_Alta_V3 = 0.0;
    this.TS_Cli_facturacion_Alta_V4 = 0.0;

    this.TS_Cli_facturacion_Media_V1 = 0.0;
    this.TS_Cli_facturacion_Media_V2 = 0.0;
    this.TS_Cli_facturacion_Media_V3 = 0.0;
    this.TS_Cli_facturacion_Media_V4 = 0.0;

    this.TS_Cli_facturacion_Baja_V1 = 0.0;
    this.TS_Cli_facturacion_Baja_V2 = 0.0;
    this.TS_Cli_facturacion_Baja_V3 = 0.0;
    this.TS_Cli_facturacion_Baja_V4 = 0.0;

    this.TS_Cli_tiempo_facturacion_Alta_V1 = 0.0;
    this.TS_Cli_tiempo_facturacion_Alta_V2 = 0.0;
    this.TS_Cli_tiempo_facturacion_Alta_V3 = 0.0;
    this.TS_Cli_tiempo_facturacion_Alta_V4 = 0.0;

    this.TS_Cli_tiempo_facturacion_Media_V1 = 0.0;
    this.TS_Cli_tiempo_facturacion_Media_V2 = 0.0;
    this.TS_Cli_tiempo_facturacion_Media_V3 = 0.0;
    this.TS_Cli_tiempo_facturacion_Media_V4 = 0.0;

    this.TS_Cli_tiempo_facturacion_Baja_V1 = 0.0;
    this.TS_Cli_tiempo_facturacion_Baja_V2 = 0.0;
    this.TS_Cli_tiempo_facturacion_Baja_V3 = 0.0;
    this.TS_Cli_tiempo_facturacion_Baja_V4 = 0.0;


    // 4: Tasa de disminucion de Paradas: en libre y extra
    this.tiempoLibreHHmmSS_V1 = '00:00:00';
    this.tiempoLibreHHmmSS_V2 = '00:00:00';
    this.tiempoLibreHHmmSS_V3 = '00:00:00';
    this.tiempoLibreHHmmSS_V4 = '00:00:00';
    this.tiempoLibreHHmmSS_V1_segundos = 0;
    this.tiempoLibreHHmmSS_V2_segundos = 0;
    this.tiempoLibreHHmmSS_V3_segundos = 0;
    this.tiempoLibreHHmmSS_V4_segundos = 0;

    this.tiempoExtraHHmmSS_V1 = '00:00:00';
    this.tiempoExtraHHmmSS_V2 = '00:00:00';
    this.tiempoExtraHHmmSS_V3 = '00:00:00';
    this.tiempoExtraHHmmSS_V4 = '00:00:00';
    this.tiempoExtraHHmmSS_V1_segundos = 0;
    this.tiempoExtraHHmmSS_V2_segundos = 0;
    this.tiempoExtraHHmmSS_V3_segundos = 0;
    this.tiempoExtraHHmmSS_V4_segundos = 0;

    this.tiempoLibrePorcen_V1 = 0.0;
    this.tiempoLibrePorcen_V2 = 0.0;
    this.tiempoLibrePorcen_V3 = 0.0;
    this.tiempoLibrePorcen_V4 = 0.0;

    this.tiempoExtraPorcen_V1 = 0.0;
    this.tiempoExtraPorcen_V2 = 0.0;
    this.tiempoExtraPorcen_V3 = 0.0;
    this.tiempoExtraPorcen_V4 = 0.0;


    // 5: Tasa de servicio prioridad OF
    this.TS_OF_prioridad_Alta_V1 = 0.0;
    this.TS_OF_prioridad_Alta_V2 = 0.0;
    this.TS_OF_prioridad_Alta_V3 = 0.0;
    this.TS_OF_prioridad_Alta_V4 = 0.0;

    this.TS_OF_prioridad_Media_V1 = 0.0;
    this.TS_OF_prioridad_Media_V2 = 0.0;
    this.TS_OF_prioridad_Media_V3 = 0.0;
    this.TS_OF_prioridad_Media_V4 = 0.0;

    this.TS_OF_prioridad_Baja_V1 = 0.0;
    this.TS_OF_prioridad_Baja_V2 = 0.0;
    this.TS_OF_prioridad_Baja_V3 = 0.0;
    this.TS_OF_prioridad_Baja_V4 = 0.0;

    this.TS_OF_tiempo_prioridad_Alta_V1 = 0.0;
    this.TS_OF_tiempo_prioridad_Alta_V2 = 0.0;
    this.TS_OF_tiempo_prioridad_Alta_V3 = 0.0;
    this.TS_OF_tiempo_prioridad_Alta_V4 = 0.0;

    this.TS_OF_tiempo_prioridad_Media_V1 = 0.0;
    this.TS_OF_tiempo_prioridad_Media_V2 = 0.0;
    this.TS_OF_tiempo_prioridad_Media_V3 = 0.0;
    this.TS_OF_tiempo_prioridad_Media_V4 = 0.0;

    this.TS_OF_tiempo_prioridad_Baja_V1 = 0.0;
    this.TS_OF_tiempo_prioridad_Baja_V2 = 0.0;
    this.TS_OF_tiempo_prioridad_Baja_V3 = 0.0;
    this.TS_OF_tiempo_prioridad_Baja_V4 = 0.0;


    // 6: Margen de entrega Semanas antes (por OF y por Piezas)
    this.margenNegativo_V1 = 0.0;
    this.margenNegativo_V2 = 0.0;
    this.margenNegativo_V3 = 0.0;
    this.margenNegativo_V4 = 0.0;

    this.margenCorto_V1 = 0.0;
    this.margenCorto_V2 = 0.0;
    this.margenCorto_V3 = 0.0;
    this.margenCorto_V4 = 0.0;

    this.margenMedio_V1 = 0.0;
    this.margenMedio_V2 = 0.0;
    this.margenMedio_V3 = 0.0;
    this.margenMedio_V4 = 0.0;

    this.margenLargo_V1 = 0.0;
    this.margenLargo_V2 = 0.0;
    this.margenLargo_V3 = 0.0;
    this.margenLargo_V4 = 0.0;

    this.margenResto_V1 = 0.0;
    this.margenResto_V2 = 0.0;
    this.margenResto_V3 = 0.0;
    this.margenResto_V4 = 0.0;

    this.margenNegativoPiezas_V1 = 0.0;
    this.margenNegativoPiezas_V2 = 0.0;
    this.margenNegativoPiezas_V3 = 0.0;
    this.margenNegativoPiezas_V4 = 0.0;

    this.margenCortoPiezas_V1 = 0.0;
    this.margenCortoPiezas_V2 = 0.0;
    this.margenCortoPiezas_V3 = 0.0;
    this.margenCortoPiezas_V4 = 0.0;

    this.margenMedioPiezas_V1 = 0.0;
    this.margenMedioPiezas_V2 = 0.0;
    this.margenMedioPiezas_V3 = 0.0;
    this.margenMedioPiezas_V4 = 0.0;

    this.margenLargoPiezas_V1 = 0.0;
    this.margenLargoPiezas_V2 = 0.0;
    this.margenLargoPiezas_V3 = 0.0;
    this.margenLargoPiezas_V4 = 0.0;

    this.margenRestoPiezas_V1 = 0.0;
    this.margenRestoPiezas_V2 = 0.0;
    this.margenRestoPiezas_V3 = 0.0;
    this.margenRestoPiezas_V4 = 0.0;


    // 7: Retraso medio
    this.retrasoMedio_V1 = 0.0;
    this.retrasoMedio_V2 = 0.0;
    this.retrasoMedio_V3 = 0.0;
    this.retrasoMedio_V4 = 0.0;

    this.retrasoMedioPiezas_V1 = 0.0;
    this.retrasoMedioPiezas_V2 = 0.0;
    this.retrasoMedioPiezas_V3 = 0.0;
    this.retrasoMedioPiezas_V4 = 0.0;

    // 8: Valoracion media
    this.valoracionMedia_V1 = 0.0;
    this.valoracionMedia_V2 = 0.0;
    this.valoracionMedia_V3 = 0.0;
    this.valoracionMedia_V4 = 0.0;
  }

  cargarV1(json) {
    /*0*/var TS_OF_Table = json['table'][0];
    var TS_OF_tiempo_Table = json['table1'][0];
    var TS_Cli_Table = json['table2'][0];
    var TS_Cli_tiempo_Table = json['table3'][0];
    var TS_Cli_grupo_Table = json['table4'];
    /*5*/var TS_Cli_tiempo_grupo_Table = json['table5'];
    var tiempo_Table = json['table6'][0];
    var margen_Table = json['table7'][0];
    var margenPiezas_Table = json['table8'][0];
    var retraso_Table = json['table9'][0];
    /*10*/var retrasoPiezas_Table = json['table10'][0];


    // 1: Tasa de servicio
    // 5: Tasa de servicio prioridad OF
    if (TS_OF_Table != undefined) {
      // 1: Tasa de servicio
      if (TS_OF_Table['tS_OF'] != undefined) this.TS_OF_V1 = TS_OF_Table['tS_OF'];

      // 5: Tasa de servicio prioridad OF
      if (TS_OF_Table['tS_OF_prioridad_Alta'] != undefined) this.TS_OF_prioridad_Alta_V1 = TS_OF_Table['tS_OF_prioridad_Alta'];
      if (TS_OF_Table['tS_OF_prioridad_Media'] != undefined) this.TS_OF_prioridad_Media_V1 = TS_OF_Table['tS_OF_prioridad_Media'];
      if (TS_OF_Table['tS_OF_prioridad_Baja'] != undefined) this.TS_OF_prioridad_Baja_V1 = TS_OF_Table['tS_OF_prioridad_Baja'];
    }
    if (TS_OF_tiempo_Table != undefined) {
      // 1: Tasa de servicio
      if (TS_OF_tiempo_Table['tS_OF_tiempo'] != undefined) this.TS_OF_tiempo_V1 = TS_OF_tiempo_Table['tS_OF_tiempo'];

      // 5: Tasa de servicio prioridad OF
      if (TS_OF_tiempo_Table['tS_OF_tiempo_prioridad_Alta'] != undefined) this.TS_OF_tiempo_prioridad_Alta_V1 = TS_OF_tiempo_Table['tS_OF_tiempo_prioridad_Alta'];
      if (TS_OF_tiempo_Table['tS_OF_tiempo_prioridad_Media'] != undefined) this.TS_OF_tiempo_prioridad_Media_V1 = TS_OF_tiempo_Table['tS_OF_tiempo_prioridad_Media'];
      if (TS_OF_tiempo_Table['tS_OF_tiempo_prioridad_Baja'] != undefined) this.TS_OF_tiempo_prioridad_Baja_V1 = TS_OF_tiempo_Table['tS_OF_tiempo_prioridad_Baja'];
    }

    // 2: Tasa de servicio clientes (por grupos)
    if (TS_Cli_grupo_Table != undefined) {
      if (TS_Cli_grupo_Table[0] != undefined) if (TS_Cli_grupo_Table[0]['tS_Cli_grupo'] != undefined) this.TS_Cli_grupo_A_V1 = TS_Cli_grupo_Table[0]['tS_Cli_grupo'];
      if (TS_Cli_grupo_Table[1] != undefined) if (TS_Cli_grupo_Table[0]['tS_Cli_grupo'] != undefined) this.TS_Cli_grupo_B_V1 = TS_Cli_grupo_Table[1]['tS_Cli_grupo'];
      if (TS_Cli_grupo_Table[2] != undefined) if (TS_Cli_grupo_Table[0]['tS_Cli_grupo'] != undefined) this.TS_Cli_grupo_C_V1 = TS_Cli_grupo_Table[2]['tS_Cli_grupo'];
    }
    if (TS_Cli_tiempo_grupo_Table != undefined) {
      if (TS_Cli_tiempo_grupo_Table[0] != undefined) if (TS_Cli_tiempo_grupo_Table[0]['tS_Cli_tiempo_grupo'] != undefined) this.TS_Cli_tiempo_grupo_A_V1 = TS_Cli_tiempo_grupo_Table[0]['tS_Cli_tiempo_grupo'];
      if (TS_Cli_tiempo_grupo_Table[1] != undefined) if (TS_Cli_tiempo_grupo_Table[0]['tS_Cli_tiempo_grupo'] != undefined) this.TS_Cli_tiempo_grupo_B_V1 = TS_Cli_tiempo_grupo_Table[1]['tS_Cli_tiempo_grupo'];
      if (TS_Cli_tiempo_grupo_Table[2] != undefined) if (TS_Cli_tiempo_grupo_Table[0]['tS_Cli_tiempo_grupo'] != undefined) this.TS_Cli_tiempo_grupo_C_V1 = TS_Cli_tiempo_grupo_Table[2]['tS_Cli_tiempo_grupo'];
    }

    // 3: Tasa de servicio facturacion Cliente
    if (TS_Cli_Table != undefined) {
      if (TS_Cli_Table['tS_Cli_facturacion_Alta'] != undefined) this.TS_Cli_facturacion_Alta_V1 = TS_Cli_Table['tS_Cli_facturacion_Alta'];
      if (TS_Cli_Table['tS_Cli_facturacion_Media'] != undefined) this.TS_Cli_facturacion_Media_V1 = TS_Cli_Table['tS_Cli_facturacion_Media'];
      if (TS_Cli_Table['tS_Cli_facturacion_Baja'] != undefined) this.TS_Cli_facturacion_Baja_V1 = TS_Cli_Table['tS_Cli_facturacion_Baja'];
    }
    if (TS_Cli_tiempo_Table != undefined) {
      if (TS_Cli_tiempo_Table['tS_Cli_tiempo_facturacion_Alta'] != undefined) this.TS_Cli_tiempo_facturacion_Alta_V1 = TS_Cli_tiempo_Table['tS_Cli_tiempo_facturacion_Alta'];
      if (TS_Cli_tiempo_Table['tS_Cli_tiempo_facturacion_Media'] != undefined) this.TS_Cli_tiempo_facturacion_Media_V1 = TS_Cli_tiempo_Table['tS_Cli_tiempo_facturacion_Media'];
      if (TS_Cli_tiempo_Table['tS_Cli_tiempo_facturacion_Baja'] != undefined) this.TS_Cli_tiempo_facturacion_Baja_V1 = TS_Cli_tiempo_Table['tS_Cli_tiempo_facturacion_Baja'];
    }


    // 4: Tasa de disminucion de Paradas: en libre y extra
    if (tiempo_Table != undefined) {
      if (tiempo_Table['tiempoLibreHHmmSS'] != undefined) this.tiempoLibreHHmmSS_V1 = tiempo_Table['tiempoLibreHHmmSS'];
      if (tiempo_Table['tiempoExtraHHmmSS'] != undefined) this.tiempoExtraHHmmSS_V1 = tiempo_Table['tiempoExtraHHmmSS'];
      if (tiempo_Table['tiempoLibreSegundos'] != undefined) this.tiempoLibreHHmmSS_V1_segundos = tiempo_Table['tiempoLibreSegundos'];
      if (tiempo_Table['tiempoExtraSegundos'] != undefined) this.tiempoExtraHHmmSS_V1_segundos = tiempo_Table['tiempoExtraSegundos'];

      if (tiempo_Table['tiempoLibrePorcen'] != undefined) this.tiempoLibrePorcen_V1 = tiempo_Table['tiempoLibrePorcen'];
      if (tiempo_Table['tiempoExtraPorcen'] != undefined) this.tiempoExtraPorcen_V1 = tiempo_Table['tiempoExtraPorcen'];
    }


    // 6: Margen de entrega Semanas antes (por OF y por Piezas)
    if (margen_Table != undefined) {
      if (margen_Table['margenNegativo'] != undefined) this.margenNegativo_V1 = margen_Table['margenNegativo'];
      if (margen_Table['margenCorto'] != undefined) this.margenCorto_V1 = margen_Table['margenCorto'];
      if (margen_Table['margenMedio'] != undefined) this.margenMedio_V1 = margen_Table['margenMedio'];
      if (margen_Table['margenLargo'] != undefined) this.margenLargo_V1 = margen_Table['margenLargo'];
      if (margen_Table['margenResto'] != undefined) this.margenResto_V1 = margen_Table['margenResto'];
    }
    if (margenPiezas_Table != undefined) {
      if (margenPiezas_Table['margenNegativoPiezas'] != undefined) this.margenNegativoPiezas_V1 = margenPiezas_Table['margenNegativoPiezas'];
      if (margenPiezas_Table['margenCortoPiezas'] != undefined) this.margenCortoPiezas_V1 = margenPiezas_Table['margenCortoPiezas'];
      if (margenPiezas_Table['margenMedioPiezas'] != undefined) this.margenMedioPiezas_V1 = margenPiezas_Table['margenMedioPiezas'];
      if (margenPiezas_Table['margenLargoPiezas'] != undefined) this.margenLargoPiezas_V1 = margenPiezas_Table['margenLargoPiezas'];
      if (margenPiezas_Table['margenRestoPiezas'] != undefined) this.margenRestoPiezas_V1 = margenPiezas_Table['margenRestoPiezas'];
    }


    // 7: Retraso medio
    if (retraso_Table != undefined) if (retraso_Table['retrasoMedio'] != undefined) this.retrasoMedio_V1 = retraso_Table['retrasoMedio'];
    if (retrasoPiezas_Table != undefined) if (retrasoPiezas_Table['retrasoMedioPiezas'] != undefined) this.retrasoMedioPiezas_V1 = retrasoPiezas_Table['retrasoMedioPiezas'];

    // 8: Valoracion media
    //valoracionMedia_V1
  }
  cargarV2(json) {
    /*0*/var TS_OF_Table = json['table'][0];
    var TS_OF_tiempo_Table = json['table1'][0];
    var TS_Cli_Table = json['table2'][0];
    var TS_Cli_tiempo_Table = json['table3'][0];
    var TS_Cli_grupo_Table = json['table4'];
    /*5*/var TS_Cli_tiempo_grupo_Table = json['table5'];
    var tiempo_Table = json['table6'][0];
    var margen_Table = json['table7'][0];
    var margenPiezas_Table = json['table8'][0];
    var retraso_Table = json['table9'][0];
    /*10*/var retrasoPiezas_Table = json['table10'][0];


    // 1: Tasa de servicio
    // 5: Tasa de servicio prioridad OF
    if (TS_OF_Table != undefined) {
      // 1: Tasa de servicio
      if (TS_OF_Table['tS_OF'] != undefined) this.TS_OF_V2 = TS_OF_Table['tS_OF'];

      // 5: Tasa de servicio prioridad OF
      if (TS_OF_Table['tS_OF_prioridad_Alta'] != undefined) this.TS_OF_prioridad_Alta_V2 = TS_OF_Table['tS_OF_prioridad_Alta'];
      if (TS_OF_Table['tS_OF_prioridad_Media'] != undefined) this.TS_OF_prioridad_Media_V2 = TS_OF_Table['tS_OF_prioridad_Media'];
      if (TS_OF_Table['tS_OF_prioridad_Baja'] != undefined) this.TS_OF_prioridad_Baja_V2 = TS_OF_Table['tS_OF_prioridad_Baja'];
    }
    if (TS_OF_tiempo_Table != undefined) {
      // 1: Tasa de servicio
      if (TS_OF_tiempo_Table['tS_OF_tiempo'] != undefined) this.TS_OF_tiempo_V2 = TS_OF_tiempo_Table['tS_OF_tiempo'];

      // 5: Tasa de servicio prioridad OF
      if (TS_OF_tiempo_Table['tS_OF_tiempo_prioridad_Alta'] != undefined) this.TS_OF_tiempo_prioridad_Alta_V2 = TS_OF_tiempo_Table['tS_OF_tiempo_prioridad_Alta'];
      if (TS_OF_tiempo_Table['tS_OF_tiempo_prioridad_Media'] != undefined) this.TS_OF_tiempo_prioridad_Media_V2 = TS_OF_tiempo_Table['tS_OF_tiempo_prioridad_Media'];
      if (TS_OF_tiempo_Table['tS_OF_tiempo_prioridad_Baja'] != undefined) this.TS_OF_tiempo_prioridad_Baja_V2 = TS_OF_tiempo_Table['tS_OF_tiempo_prioridad_Baja'];
    }

    // 2: Tasa de servicio clientes (por grupos)
    if (TS_Cli_grupo_Table != undefined) {
      if (TS_Cli_grupo_Table[0] != undefined) if (TS_Cli_grupo_Table[0]['tS_Cli_grupo'] != undefined) this.TS_Cli_grupo_A_V2 = TS_Cli_grupo_Table[0]['tS_Cli_grupo'];
      if (TS_Cli_grupo_Table[1] != undefined) if (TS_Cli_grupo_Table[0]['tS_Cli_grupo'] != undefined) this.TS_Cli_grupo_B_V2 = TS_Cli_grupo_Table[1]['tS_Cli_grupo'];
      if (TS_Cli_grupo_Table[2] != undefined) if (TS_Cli_grupo_Table[0]['tS_Cli_grupo'] != undefined) this.TS_Cli_grupo_C_V2 = TS_Cli_grupo_Table[2]['tS_Cli_grupo'];
    }
    if (TS_Cli_tiempo_grupo_Table != undefined) {
      if (TS_Cli_tiempo_grupo_Table[0] != undefined) if (TS_Cli_tiempo_grupo_Table[0]['tS_Cli_tiempo_grupo'] != undefined) this.TS_Cli_tiempo_grupo_A_V2 = TS_Cli_tiempo_grupo_Table[0]['tS_Cli_tiempo_grupo'];
      if (TS_Cli_tiempo_grupo_Table[1] != undefined) if (TS_Cli_tiempo_grupo_Table[0]['tS_Cli_tiempo_grupo'] != undefined) this.TS_Cli_tiempo_grupo_B_V2 = TS_Cli_tiempo_grupo_Table[1]['tS_Cli_tiempo_grupo'];
      if (TS_Cli_tiempo_grupo_Table[2] != undefined) if (TS_Cli_tiempo_grupo_Table[0]['tS_Cli_tiempo_grupo'] != undefined) this.TS_Cli_tiempo_grupo_C_V2 = TS_Cli_tiempo_grupo_Table[2]['tS_Cli_tiempo_grupo'];
    }

    // 3: Tasa de servicio facturacion Cliente
    if (TS_Cli_Table != undefined) {
      if (TS_Cli_Table['tS_Cli_facturacion_Alta'] != undefined) this.TS_Cli_facturacion_Alta_V2 = TS_Cli_Table['tS_Cli_facturacion_Alta'];
      if (TS_Cli_Table['tS_Cli_facturacion_Media'] != undefined) this.TS_Cli_facturacion_Media_V2 = TS_Cli_Table['tS_Cli_facturacion_Media'];
      if (TS_Cli_Table['tS_Cli_facturacion_Baja'] != undefined) this.TS_Cli_facturacion_Baja_V2 = TS_Cli_Table['tS_Cli_facturacion_Baja'];
    }
    if (TS_Cli_tiempo_Table != undefined) {
      if (TS_Cli_tiempo_Table['tS_Cli_tiempo_facturacion_Alta'] != undefined) this.TS_Cli_tiempo_facturacion_Alta_V2 = TS_Cli_tiempo_Table['tS_Cli_tiempo_facturacion_Alta'];
      if (TS_Cli_tiempo_Table['tS_Cli_tiempo_facturacion_Media'] != undefined) this.TS_Cli_tiempo_facturacion_Media_V2 = TS_Cli_tiempo_Table['tS_Cli_tiempo_facturacion_Media'];
      if (TS_Cli_tiempo_Table['tS_Cli_tiempo_facturacion_Baja'] != undefined) this.TS_Cli_tiempo_facturacion_Baja_V2 = TS_Cli_tiempo_Table['tS_Cli_tiempo_facturacion_Baja'];
    }


    // 4: Tasa de disminucion de Paradas: en libre y extra
    if (tiempo_Table != undefined) {
      if (tiempo_Table['tiempoLibreHHmmSS'] != undefined) this.tiempoLibreHHmmSS_V2 = tiempo_Table['tiempoLibreHHmmSS'];
      if (tiempo_Table['tiempoExtraHHmmSS'] != undefined) this.tiempoExtraHHmmSS_V2 = tiempo_Table['tiempoExtraHHmmSS'];
      if (tiempo_Table['tiempoLibreSegundos'] != undefined) this.tiempoLibreHHmmSS_V2_segundos = tiempo_Table['tiempoLibreSegundos'];
      if (tiempo_Table['tiempoExtraSegundos'] != undefined) this.tiempoExtraHHmmSS_V2_segundos = tiempo_Table['tiempoExtraSegundos'];

      if (tiempo_Table['tiempoLibrePorcen'] != undefined) this.tiempoLibrePorcen_V2 = tiempo_Table['tiempoLibrePorcen'];
      if (tiempo_Table['tiempoExtraPorcen'] != undefined) this.tiempoExtraPorcen_V2 = tiempo_Table['tiempoExtraPorcen'];
    }


    // 6: Margen de entrega Semanas antes (por OF y por Piezas)
    if (margen_Table != undefined) {
      if (margen_Table['margenNegativo'] != undefined) this.margenNegativo_V2 = margen_Table['margenNegativo'];
      if (margen_Table['margenCorto'] != undefined) this.margenCorto_V2 = margen_Table['margenCorto'];
      if (margen_Table['margenMedio'] != undefined) this.margenMedio_V2 = margen_Table['margenMedio'];
      if (margen_Table['margenLargo'] != undefined) this.margenLargo_V2 = margen_Table['margenLargo'];
      if (margen_Table['margenResto'] != undefined) this.margenResto_V2 = margen_Table['margenResto'];
    }
    if (margenPiezas_Table != undefined) {
      if (margenPiezas_Table['margenNegativoPiezas'] != undefined) this.margenNegativoPiezas_V2 = margenPiezas_Table['margenNegativoPiezas'];
      if (margenPiezas_Table['margenCortoPiezas'] != undefined) this.margenCortoPiezas_V2 = margenPiezas_Table['margenCortoPiezas'];
      if (margenPiezas_Table['margenMedioPiezas'] != undefined) this.margenMedioPiezas_V2 = margenPiezas_Table['margenMedioPiezas'];
      if (margenPiezas_Table['margenLargoPiezas'] != undefined) this.margenLargoPiezas_V2 = margenPiezas_Table['margenLargoPiezas'];
      if (margenPiezas_Table['margenRestoPiezas'] != undefined) this.margenRestoPiezas_V2 = margenPiezas_Table['margenRestoPiezas'];
    }


    // 7: Retraso medio
    if (retraso_Table != undefined) if (retraso_Table['retrasoMedio'] != undefined) this.retrasoMedio_V2 = retraso_Table['retrasoMedio'];
    if (retrasoPiezas_Table != undefined) if (retrasoPiezas_Table['retrasoMedioPiezas'] != undefined) this.retrasoMedioPiezas_V2 = retrasoPiezas_Table['retrasoMedioPiezas'];

  }
  cargarV3(json) {
    /*0*/var TS_OF_Table = json['table'][0];
    var TS_OF_tiempo_Table = json['table1'][0];
    var TS_Cli_Table = json['table2'][0];
    var TS_Cli_tiempo_Table = json['table3'][0];
    var TS_Cli_grupo_Table = json['table4'];
    /*5*/var TS_Cli_tiempo_grupo_Table = json['table5'];
    var tiempo_Table = json['table6'][0];
    var margen_Table = json['table7'][0];
    var margenPiezas_Table = json['table8'][0];
    var retraso_Table = json['table9'][0];
    /*10*/var retrasoPiezas_Table = json['table10'][0];


    // 1: Tasa de servicio
    // 5: Tasa de servicio prioridad OF
    if (TS_OF_Table != undefined) {
      // 1: Tasa de servicio
      if (TS_OF_Table['tS_OF'] != undefined) this.TS_OF_V3 = TS_OF_Table['tS_OF'];

      // 5: Tasa de servicio prioridad OF
      if (TS_OF_Table['tS_OF_prioridad_Alta'] != undefined) this.TS_OF_prioridad_Alta_V3 = TS_OF_Table['tS_OF_prioridad_Alta'];
      if (TS_OF_Table['tS_OF_prioridad_Media'] != undefined) this.TS_OF_prioridad_Media_V3 = TS_OF_Table['tS_OF_prioridad_Media'];
      if (TS_OF_Table['tS_OF_prioridad_Baja'] != undefined) this.TS_OF_prioridad_Baja_V3 = TS_OF_Table['tS_OF_prioridad_Baja'];
    }
    if (TS_OF_tiempo_Table != undefined) {
      // 1: Tasa de servicio
      if (TS_OF_tiempo_Table['tS_OF_tiempo'] != undefined) this.TS_OF_tiempo_V3 = TS_OF_tiempo_Table['tS_OF_tiempo'];

      // 5: Tasa de servicio prioridad OF
      if (TS_OF_tiempo_Table['tS_OF_tiempo_prioridad_Alta'] != undefined) this.TS_OF_tiempo_prioridad_Alta_V3 = TS_OF_tiempo_Table['tS_OF_tiempo_prioridad_Alta'];
      if (TS_OF_tiempo_Table['tS_OF_tiempo_prioridad_Media'] != undefined) this.TS_OF_tiempo_prioridad_Media_V3 = TS_OF_tiempo_Table['tS_OF_tiempo_prioridad_Media'];
      if (TS_OF_tiempo_Table['tS_OF_tiempo_prioridad_Baja'] != undefined) this.TS_OF_tiempo_prioridad_Baja_V3 = TS_OF_tiempo_Table['tS_OF_tiempo_prioridad_Baja'];
    }

    // 2: Tasa de servicio clientes (por grupos)
    if (TS_Cli_grupo_Table != undefined) {
      if (TS_Cli_grupo_Table[0] != undefined) if (TS_Cli_grupo_Table[0]['tS_Cli_grupo'] != undefined) this.TS_Cli_grupo_A_V3 = TS_Cli_grupo_Table[0]['tS_Cli_grupo'];
      if (TS_Cli_grupo_Table[1] != undefined) if (TS_Cli_grupo_Table[0]['tS_Cli_grupo'] != undefined) this.TS_Cli_grupo_B_V3 = TS_Cli_grupo_Table[1]['tS_Cli_grupo'];
      if (TS_Cli_grupo_Table[2] != undefined) if (TS_Cli_grupo_Table[0]['tS_Cli_grupo'] != undefined) this.TS_Cli_grupo_C_V3 = TS_Cli_grupo_Table[2]['tS_Cli_grupo'];
    }
    if (TS_Cli_tiempo_grupo_Table != undefined) {
      if (TS_Cli_tiempo_grupo_Table[0] != undefined) if (TS_Cli_tiempo_grupo_Table[0]['tS_Cli_tiempo_grupo'] != undefined) this.TS_Cli_tiempo_grupo_A_V3 = TS_Cli_tiempo_grupo_Table[0]['tS_Cli_tiempo_grupo'];
      if (TS_Cli_tiempo_grupo_Table[1] != undefined) if (TS_Cli_tiempo_grupo_Table[0]['tS_Cli_tiempo_grupo'] != undefined) this.TS_Cli_tiempo_grupo_B_V3 = TS_Cli_tiempo_grupo_Table[1]['tS_Cli_tiempo_grupo'];
      if (TS_Cli_tiempo_grupo_Table[2] != undefined) if (TS_Cli_tiempo_grupo_Table[0]['tS_Cli_tiempo_grupo'] != undefined) this.TS_Cli_tiempo_grupo_C_V3 = TS_Cli_tiempo_grupo_Table[2]['tS_Cli_tiempo_grupo'];
    }

    // 3: Tasa de servicio facturacion Cliente
    if (TS_Cli_Table != undefined) {
      if (TS_Cli_Table['tS_Cli_facturacion_Alta'] != undefined) this.TS_Cli_facturacion_Alta_V3 = TS_Cli_Table['tS_Cli_facturacion_Alta'];
      if (TS_Cli_Table['tS_Cli_facturacion_Media'] != undefined) this.TS_Cli_facturacion_Media_V3 = TS_Cli_Table['tS_Cli_facturacion_Media'];
      if (TS_Cli_Table['tS_Cli_facturacion_Baja'] != undefined) this.TS_Cli_facturacion_Baja_V3 = TS_Cli_Table['tS_Cli_facturacion_Baja'];
    }
    if (TS_Cli_tiempo_Table != undefined) {
      if (TS_Cli_tiempo_Table['tS_Cli_tiempo_facturacion_Alta'] != undefined) this.TS_Cli_tiempo_facturacion_Alta_V3 = TS_Cli_tiempo_Table['tS_Cli_tiempo_facturacion_Alta'];
      if (TS_Cli_tiempo_Table['tS_Cli_tiempo_facturacion_Media'] != undefined) this.TS_Cli_tiempo_facturacion_Media_V3 = TS_Cli_tiempo_Table['tS_Cli_tiempo_facturacion_Media'];
      if (TS_Cli_tiempo_Table['tS_Cli_tiempo_facturacion_Baja'] != undefined) this.TS_Cli_tiempo_facturacion_Baja_V3 = TS_Cli_tiempo_Table['tS_Cli_tiempo_facturacion_Baja'];
    }


    // 4: Tasa de disminucion de Paradas: en libre y extra
    if (tiempo_Table != undefined) {
      if (tiempo_Table['tiempoLibreHHmmSS'] != undefined) this.tiempoLibreHHmmSS_V3 = tiempo_Table['tiempoLibreHHmmSS'];
      if (tiempo_Table['tiempoExtraHHmmSS'] != undefined) this.tiempoExtraHHmmSS_V3 = tiempo_Table['tiempoExtraHHmmSS'];
      if (tiempo_Table['tiempoLibreSegundos'] != undefined) this.tiempoLibreHHmmSS_V3_segundos = tiempo_Table['tiempoLibreSegundos'];
      if (tiempo_Table['tiempoExtraSegundos'] != undefined) this.tiempoExtraHHmmSS_V3_segundos = tiempo_Table['tiempoExtraSegundos'];

      if (tiempo_Table['tiempoLibrePorcen'] != undefined) this.tiempoLibrePorcen_V3 = tiempo_Table['tiempoLibrePorcen'];
      if (tiempo_Table['tiempoExtraPorcen'] != undefined) this.tiempoExtraPorcen_V3 = tiempo_Table['tiempoExtraPorcen'];
    }


    // 6: Margen de entrega Semanas antes (por OF y por Piezas)
    if (margen_Table != undefined) {
      if (margen_Table['margenNegativo'] != undefined) this.margenNegativo_V3 = margen_Table['margenNegativo'];
      if (margen_Table['margenCorto'] != undefined) this.margenCorto_V3 = margen_Table['margenCorto'];
      if (margen_Table['margenMedio'] != undefined) this.margenMedio_V3 = margen_Table['margenMedio'];
      if (margen_Table['margenLargo'] != undefined) this.margenLargo_V3 = margen_Table['margenLargo'];
      if (margen_Table['margenResto'] != undefined) this.margenResto_V3 = margen_Table['margenResto'];
    }
    if (margenPiezas_Table != undefined) {
      if (margenPiezas_Table['margenNegativoPiezas'] != undefined) this.margenNegativoPiezas_V3 = margenPiezas_Table['margenNegativoPiezas'];
      if (margenPiezas_Table['margenCortoPiezas'] != undefined) this.margenCortoPiezas_V3 = margenPiezas_Table['margenCortoPiezas'];
      if (margenPiezas_Table['margenMedioPiezas'] != undefined) this.margenMedioPiezas_V3 = margenPiezas_Table['margenMedioPiezas'];
      if (margenPiezas_Table['margenLargoPiezas'] != undefined) this.margenLargoPiezas_V3 = margenPiezas_Table['margenLargoPiezas'];
      if (margenPiezas_Table['margenRestoPiezas'] != undefined) this.margenRestoPiezas_V3 = margenPiezas_Table['margenRestoPiezas'];
    }


    // 7: Retraso medio
    if (retraso_Table != undefined) if (retraso_Table['retrasoMedio'] != undefined) this.retrasoMedio_V3 = retraso_Table['retrasoMedio'];
    if (retrasoPiezas_Table != undefined) if (retrasoPiezas_Table['retrasoMedioPiezas'] != undefined) this.retrasoMedioPiezas_V3 = retrasoPiezas_Table['retrasoMedioPiezas'];
  }
  cargarV4(json) {
    /*0*/var TS_OF_Table = json['table'][0];
    var TS_OF_tiempo_Table = json['table1'][0];
    var TS_Cli_Table = json['table2'][0];
    var TS_Cli_tiempo_Table = json['table3'][0];
    var TS_Cli_grupo_Table = json['table4'];
    /*5*/var TS_Cli_tiempo_grupo_Table = json['table5'];
    var tiempo_Table = json['table6'][0];
    var margen_Table = json['table7'][0];
    var margenPiezas_Table = json['table8'][0];
    var retraso_Table = json['table9'][0];
    /*10*/var retrasoPiezas_Table = json['table10'][0];


    // 1: Tasa de servicio
    // 5: Tasa de servicio prioridad OF
    if (TS_OF_Table != undefined) {
      // 1: Tasa de servicio
      if (TS_OF_Table['tS_OF'] != undefined) this.TS_OF_V4 = TS_OF_Table['tS_OF'];

      // 5: Tasa de servicio prioridad OF
      if (TS_OF_Table['tS_OF_prioridad_Alta'] != undefined) this.TS_OF_prioridad_Alta_V4 = TS_OF_Table['tS_OF_prioridad_Alta'];
      if (TS_OF_Table['tS_OF_prioridad_Media'] != undefined) this.TS_OF_prioridad_Media_V4 = TS_OF_Table['tS_OF_prioridad_Media'];
      if (TS_OF_Table['tS_OF_prioridad_Baja'] != undefined) this.TS_OF_prioridad_Baja_V4 = TS_OF_Table['tS_OF_prioridad_Baja'];
    }
    if (TS_OF_tiempo_Table != undefined) {
      // 1: Tasa de servicio
      if (TS_OF_tiempo_Table['tS_OF_tiempo'] != undefined) this.TS_OF_tiempo_V4 = TS_OF_tiempo_Table['tS_OF_tiempo'];

      // 5: Tasa de servicio prioridad OF
      if (TS_OF_tiempo_Table['tS_OF_tiempo_prioridad_Alta'] != undefined) this.TS_OF_tiempo_prioridad_Alta_V4 = TS_OF_tiempo_Table['tS_OF_tiempo_prioridad_Alta'];
      if (TS_OF_tiempo_Table['tS_OF_tiempo_prioridad_Media'] != undefined) this.TS_OF_tiempo_prioridad_Media_V4 = TS_OF_tiempo_Table['tS_OF_tiempo_prioridad_Media'];
      if (TS_OF_tiempo_Table['tS_OF_tiempo_prioridad_Baja'] != undefined) this.TS_OF_tiempo_prioridad_Baja_V4 = TS_OF_tiempo_Table['tS_OF_tiempo_prioridad_Baja'];
    }

    // 2: Tasa de servicio clientes (por grupos)
    if (TS_Cli_grupo_Table != undefined) {
      if (TS_Cli_grupo_Table[0] != undefined) if (TS_Cli_grupo_Table[0]['tS_Cli_grupo'] != undefined) this.TS_Cli_grupo_A_V4 = TS_Cli_grupo_Table[0]['tS_Cli_grupo'];
      if (TS_Cli_grupo_Table[1] != undefined) if (TS_Cli_grupo_Table[0]['tS_Cli_grupo'] != undefined) this.TS_Cli_grupo_B_V4 = TS_Cli_grupo_Table[1]['tS_Cli_grupo'];
      if (TS_Cli_grupo_Table[2] != undefined) if (TS_Cli_grupo_Table[0]['tS_Cli_grupo'] != undefined) this.TS_Cli_grupo_C_V4 = TS_Cli_grupo_Table[2]['tS_Cli_grupo'];
    }
    if (TS_Cli_tiempo_grupo_Table != undefined) {
      if (TS_Cli_tiempo_grupo_Table[0] != undefined) if (TS_Cli_tiempo_grupo_Table[0]['tS_Cli_tiempo_grupo'] != undefined) this.TS_Cli_tiempo_grupo_A_V4 = TS_Cli_tiempo_grupo_Table[0]['tS_Cli_tiempo_grupo'];
      if (TS_Cli_tiempo_grupo_Table[1] != undefined) if (TS_Cli_tiempo_grupo_Table[0]['tS_Cli_tiempo_grupo'] != undefined) this.TS_Cli_tiempo_grupo_B_V4 = TS_Cli_tiempo_grupo_Table[1]['tS_Cli_tiempo_grupo'];
      if (TS_Cli_tiempo_grupo_Table[2] != undefined) if (TS_Cli_tiempo_grupo_Table[0]['tS_Cli_tiempo_grupo'] != undefined) this.TS_Cli_tiempo_grupo_C_V4 = TS_Cli_tiempo_grupo_Table[2]['tS_Cli_tiempo_grupo'];
    }

    // 3: Tasa de servicio facturacion Cliente
    if (TS_Cli_Table != undefined) {
      if (TS_Cli_Table['tS_Cli_facturacion_Alta'] != undefined) this.TS_Cli_facturacion_Alta_V4 = TS_Cli_Table['tS_Cli_facturacion_Alta'];
      if (TS_Cli_Table['tS_Cli_facturacion_Media'] != undefined) this.TS_Cli_facturacion_Media_V4 = TS_Cli_Table['tS_Cli_facturacion_Media'];
      if (TS_Cli_Table['tS_Cli_facturacion_Baja'] != undefined) this.TS_Cli_facturacion_Baja_V4 = TS_Cli_Table['tS_Cli_facturacion_Baja'];
    }
    if (TS_Cli_tiempo_Table != undefined) {
      if (TS_Cli_tiempo_Table['tS_Cli_tiempo_facturacion_Alta'] != undefined) this.TS_Cli_tiempo_facturacion_Alta_V4 = TS_Cli_tiempo_Table['tS_Cli_tiempo_facturacion_Alta'];
      if (TS_Cli_tiempo_Table['tS_Cli_tiempo_facturacion_Media'] != undefined) this.TS_Cli_tiempo_facturacion_Media_V4 = TS_Cli_tiempo_Table['tS_Cli_tiempo_facturacion_Media'];
      if (TS_Cli_tiempo_Table['tS_Cli_tiempo_facturacion_Baja'] != undefined) this.TS_Cli_tiempo_facturacion_Baja_V4 = TS_Cli_tiempo_Table['tS_Cli_tiempo_facturacion_Baja'];
    }


    // 4: Tasa de disminucion de Paradas: en libre y extra
    if (tiempo_Table != undefined) {
      if (tiempo_Table['tiempoLibreHHmmSS'] != undefined) this.tiempoLibreHHmmSS_V4 = tiempo_Table['tiempoLibreHHmmSS'];
      if (tiempo_Table['tiempoExtraHHmmSS'] != undefined) this.tiempoExtraHHmmSS_V4 = tiempo_Table['tiempoExtraHHmmSS'];
      if (tiempo_Table['tiempoLibreSegundos'] != undefined) this.tiempoLibreHHmmSS_V4_segundos = tiempo_Table['tiempoLibreSegundos'];
      if (tiempo_Table['tiempoExtraSegundos'] != undefined) this.tiempoExtraHHmmSS_V4_segundos = tiempo_Table['tiempoExtraSegundos'];

      if (tiempo_Table['tiempoLibrePorcen'] != undefined) this.tiempoLibrePorcen_V4 = tiempo_Table['tiempoLibrePorcen'];
      if (tiempo_Table['tiempoExtraPorcen'] != undefined) this.tiempoExtraPorcen_V4 = tiempo_Table['tiempoExtraPorcen'];
    }


    // 6: Margen de entrega Semanas antes (por OF y por Piezas)
    if (margen_Table != undefined) {
      if (margen_Table['margenNegativo'] != undefined) this.margenNegativo_V4 = margen_Table['margenNegativo'];
      if (margen_Table['margenCorto'] != undefined) this.margenCorto_V4 = margen_Table['margenCorto'];
      if (margen_Table['margenMedio'] != undefined) this.margenMedio_V4 = margen_Table['margenMedio'];
      if (margen_Table['margenLargo'] != undefined) this.margenLargo_V4 = margen_Table['margenLargo'];
      if (margen_Table['margenResto'] != undefined) this.margenResto_V4 = margen_Table['margenResto'];
    }
    if (margenPiezas_Table != undefined) {
      if (margenPiezas_Table['margenNegativoPiezas'] != undefined) this.margenNegativoPiezas_V4 = margenPiezas_Table['margenNegativoPiezas'];
      if (margenPiezas_Table['margenCortoPiezas'] != undefined) this.margenCortoPiezas_V4 = margenPiezas_Table['margenCortoPiezas'];
      if (margenPiezas_Table['margenMedioPiezas'] != undefined) this.margenMedioPiezas_V4 = margenPiezas_Table['margenMedioPiezas'];
      if (margenPiezas_Table['margenLargoPiezas'] != undefined) this.margenLargoPiezas_V4 = margenPiezas_Table['margenLargoPiezas'];
      if (margenPiezas_Table['margenRestoPiezas'] != undefined) this.margenRestoPiezas_V4 = margenPiezas_Table['margenRestoPiezas'];
    }


    // 7: Retraso medio
    if (retraso_Table != undefined) if (retraso_Table['retrasoMedio'] != undefined) this.retrasoMedio_V4 = retraso_Table['retrasoMedio'];
    if (retrasoPiezas_Table != undefined) if (retrasoPiezas_Table['retrasoMedioPiezas'] != undefined) this.retrasoMedioPiezas_V4 = retrasoPiezas_Table['retrasoMedioPiezas'];
  }

  calcularTotales() {
    this.loadingPanel = true;

    this.planificadorService.CalificarVersionConfiguracion().subscribe(
      json => {
        // 1
        var calificarPlanificador_tasaServicioTotal: number = json[0]['calificarPlanificador_tasaServicioTotal'] / 100.0; // vienen sobre 100, lo pasamos a 1 para sumar bien al final.      
        // 2
        var calificarPlanificador_tasaServicioGrupoClientes_A: number = json[0]['calificarPlanificador_tasaServicioGrupoClientes_A'] / 100.0;
        var calificarPlanificador_tasaServicioGrupoClientes_B: number = json[0]['calificarPlanificador_tasaServicioGrupoClientes_B'] / 100.0;
        var calificarPlanificador_tasaServicioGrupoClientes_C: number = json[0]['calificarPlanificador_tasaServicioGrupoClientes_C'] / 100.0;
        // 3
        var calificarPlanificador_tasaServicioFacturacionClientes_alta: number = json[0]['calificarPlanificador_tasaServicioFacturacionClientes_alta'] / 100.0;
        var calificarPlanificador_tasaServicioFacturacionClientes_media: number = json[0]['calificarPlanificador_tasaServicioFacturacionClientes_media'] / 100.0;
        var calificarPlanificador_tasaServicioFacturacionClientes_baja: number = json[0]['calificarPlanificador_tasaServicioFacturacionClientes_baja'] / 100.0;
        // 4
        var calificarPlanificador_tasaServicioPrioridadClientes_alta: number = json[0]['calificarPlanificador_tasaServicioPrioridadClientes_alta'] / 100.0;
        var calificarPlanificador_tasaServicioPrioridadClientes_media: number = json[0]['calificarPlanificador_tasaServicioPrioridadClientes_media'] / 100.0;
        var calificarPlanificador_tasaServicioPrioridadClientes_baja: number = json[0]['calificarPlanificador_tasaServicioPrioridadClientes_baja'] / 100.0;
        // 5
        var calificarPlanificador_disminucionDeParadas: number = json[0]['calificarPlanificador_disminucionDeParadas'] / 100.0;
        // 6
        var calificarPlanificador_horasExtra: number = json[0]['calificarPlanificador_horasExtra'] / 100.0;
        // 7
        var calificarPlanificador_margenEntrega_negativo: number = json[0]['calificarPlanificador_margenEntrega_negativo'] / 100.0;
        var calificarPlanificador_margenEntrega_corto: number = json[0]['calificarPlanificador_margenEntrega_corto'] / 100.0;
        var calificarPlanificador_margenEntrega_medio: number = json[0]['calificarPlanificador_margenEntrega_medio'] / 100.0;
        var calificarPlanificador_margenEntrega_largo: number = json[0]['calificarPlanificador_margenEntrega_largo'] / 100.0;
        var calificarPlanificador_margenEntrega_resto: number = json[0]['calificarPlanificador_margenEntrega_resto'] / 100.0;
        // 8
        var calificarPlanificador_retrasoMedio: number = json[0]['calificarPlanificador_retrasoMedio'] / 100.0;  //% en valor de media
        var calificarPlanificador_retrasoMedio_DiasMedia: number = json[0]['calificarPlanificador_retrasoMedio_DiasMedia']; //dias estandar de retraso

        //V1
        this.valoracionMedia_V1 = 0.0;
        if (this.aplicarTiempoComparativa) {
          this.valoracionMedia_V1 +=
            // 1: Tasa de servicio
            this.TS_OF_tiempo_V1 * calificarPlanificador_tasaServicioTotal +
            // 5: Tasa de servicio prioridad OF
            this.TS_OF_tiempo_prioridad_Alta_V1 * calificarPlanificador_tasaServicioGrupoClientes_A +
            this.TS_OF_tiempo_prioridad_Media_V1 * calificarPlanificador_tasaServicioGrupoClientes_B +
            this.TS_OF_tiempo_prioridad_Baja_V1 * calificarPlanificador_tasaServicioGrupoClientes_C +
            // 2: Tasa de servicio clientes (por grupos)
            this.TS_Cli_tiempo_grupo_A_V1 * calificarPlanificador_tasaServicioFacturacionClientes_alta +
            this.TS_Cli_tiempo_grupo_B_V1 * calificarPlanificador_tasaServicioFacturacionClientes_media +
            this.TS_Cli_tiempo_grupo_C_V1 * calificarPlanificador_tasaServicioFacturacionClientes_baja +
            // 3: Tasa de servicio facturacion Cliente
            this.TS_Cli_tiempo_facturacion_Alta_V1 * calificarPlanificador_tasaServicioPrioridadClientes_alta +
            this.TS_Cli_tiempo_facturacion_Media_V1 * calificarPlanificador_tasaServicioPrioridadClientes_media +
            this.TS_Cli_tiempo_facturacion_Baja_V1 * calificarPlanificador_tasaServicioPrioridadClientes_baja;

        } else {
          this.valoracionMedia_V1 +=
            // 1: Tasa de servicio
            this.TS_OF_V1 * calificarPlanificador_tasaServicioTotal +
            // 5: Tasa de servicio prioridad OF
            this.TS_OF_prioridad_Alta_V1 * calificarPlanificador_tasaServicioGrupoClientes_A +
            this.TS_OF_prioridad_Media_V1 * calificarPlanificador_tasaServicioGrupoClientes_B +
            this.TS_OF_prioridad_Baja_V1 * calificarPlanificador_tasaServicioGrupoClientes_C +
            // 2: Tasa de servicio clientes (por grupos)
            this.TS_Cli_grupo_A_V1 * calificarPlanificador_tasaServicioFacturacionClientes_alta +
            this.TS_Cli_grupo_B_V1 * calificarPlanificador_tasaServicioFacturacionClientes_media +
            this.TS_Cli_grupo_C_V1 * calificarPlanificador_tasaServicioFacturacionClientes_baja +
            // 3: Tasa de servicio facturacion Cliente
            this.TS_Cli_facturacion_Alta_V1 * calificarPlanificador_tasaServicioPrioridadClientes_alta +
            this.TS_Cli_facturacion_Media_V1 * calificarPlanificador_tasaServicioPrioridadClientes_media +
            this.TS_Cli_facturacion_Baja_V1 * calificarPlanificador_tasaServicioPrioridadClientes_baja;
        }
        this.valoracionMedia_V1 +=
          // 4: Tasa de disminucion de Paradas: en libre y extra
          this.tiempoLibrePorcen_V1 * calificarPlanificador_disminucionDeParadas +
          this.tiempoExtraPorcen_V1 * calificarPlanificador_horasExtra;
        if (this.aplicarCalculoPiezas) {
          var tot = 100 / (this.margenNegativoPiezas_V1 + this.margenCortoPiezas_V1 + this.margenMedioPiezas_V1 + this.margenLargoPiezas_V1 + this.margenRestoPiezas_V1)

          this.valoracionMedia_V1 +=
            // 6: Margen de entrega Semanas antes (por OF y por Piezas)
            this.margenNegativoPiezas_V1 * tot * calificarPlanificador_margenEntrega_negativo +
            this.margenCortoPiezas_V1 * tot * calificarPlanificador_margenEntrega_corto +
            this.margenMedioPiezas_V1 * tot * calificarPlanificador_margenEntrega_medio +
            this.margenLargoPiezas_V1 * tot * calificarPlanificador_margenEntrega_largo +
            this.margenRestoPiezas_V1 * tot * calificarPlanificador_margenEntrega_resto;
          // 7: Retraso medio
          if (calificarPlanificador_retrasoMedio_DiasMedia > 0) {
            var porcen = this.retrasoMedioPiezas_V1 * 100.0 / calificarPlanificador_retrasoMedio_DiasMedia;
            if (porcen > 100)
              this.valoracionMedia_V1 += calificarPlanificador_retrasoMedio;
            else
              this.valoracionMedia_V1 += porcen * calificarPlanificador_retrasoMedio;
          }
        } else {
          var tot = 100 / (this.margenNegativo_V1 + this.margenCorto_V1 + this.margenMedio_V1 + this.margenLargo_V1 + this.margenResto_V1)

          this.valoracionMedia_V1 +=
            // 6: Margen de entrega Semanas antes (por OF y por Piezas)
            this.margenNegativo_V1 * tot * calificarPlanificador_margenEntrega_negativo +
            this.margenCorto_V1 * tot * calificarPlanificador_margenEntrega_corto +
            this.margenMedio_V1 * tot * calificarPlanificador_margenEntrega_medio +
            this.margenLargo_V1 * tot * calificarPlanificador_margenEntrega_largo +
            this.margenResto_V1 * tot * calificarPlanificador_margenEntrega_resto;
          // 7: Retraso medio
          if (calificarPlanificador_retrasoMedio_DiasMedia > 0) {
            var porcen = this.retrasoMedio_V1 * 100.0 / calificarPlanificador_retrasoMedio_DiasMedia;
            if (porcen > 100)
              this.valoracionMedia_V1 += calificarPlanificador_retrasoMedio;
            else
              this.valoracionMedia_V1 += porcen * calificarPlanificador_retrasoMedio;
          }
        }

        //V2
        this.valoracionMedia_V2 = 0.0;
        if (this.aplicarTiempoComparativa) {
          this.valoracionMedia_V2 +=
            // 1: Tasa de servicio
            this.TS_OF_tiempo_V2 * calificarPlanificador_tasaServicioTotal +
            // 5: Tasa de servicio prioridad OF
            this.TS_OF_tiempo_prioridad_Alta_V2 * calificarPlanificador_tasaServicioGrupoClientes_A +
            this.TS_OF_tiempo_prioridad_Media_V2 * calificarPlanificador_tasaServicioGrupoClientes_B +
            this.TS_OF_tiempo_prioridad_Baja_V2 * calificarPlanificador_tasaServicioGrupoClientes_C +
            // 2: Tasa de servicio clientes (por grupos)
            this.TS_Cli_tiempo_grupo_A_V2 * calificarPlanificador_tasaServicioFacturacionClientes_alta +
            this.TS_Cli_tiempo_grupo_B_V2 * calificarPlanificador_tasaServicioFacturacionClientes_media +
            this.TS_Cli_tiempo_grupo_C_V2 * calificarPlanificador_tasaServicioFacturacionClientes_baja +
            // 3: Tasa de servicio facturacion Cliente
            this.TS_Cli_tiempo_facturacion_Alta_V2 * calificarPlanificador_tasaServicioPrioridadClientes_alta +
            this.TS_Cli_tiempo_facturacion_Media_V2 * calificarPlanificador_tasaServicioPrioridadClientes_media +
            this.TS_Cli_tiempo_facturacion_Baja_V2 * calificarPlanificador_tasaServicioPrioridadClientes_baja;
        } else {
          this.valoracionMedia_V2 +=
            // 1: Tasa de servicio
            this.TS_OF_V2 * calificarPlanificador_tasaServicioTotal +
            // 5: Tasa de servicio prioridad OF
            this.TS_OF_prioridad_Alta_V2 * calificarPlanificador_tasaServicioGrupoClientes_A +
            this.TS_OF_prioridad_Media_V2 * calificarPlanificador_tasaServicioGrupoClientes_B +
            this.TS_OF_prioridad_Baja_V2 * calificarPlanificador_tasaServicioGrupoClientes_C +
            // 2: Tasa de servicio clientes (por grupos)
            this.TS_Cli_grupo_A_V2 * calificarPlanificador_tasaServicioFacturacionClientes_alta +
            this.TS_Cli_grupo_B_V2 * calificarPlanificador_tasaServicioFacturacionClientes_media +
            this.TS_Cli_grupo_C_V2 * calificarPlanificador_tasaServicioFacturacionClientes_baja +
            // 3: Tasa de servicio facturacion Cliente
            this.TS_Cli_facturacion_Alta_V2 * calificarPlanificador_tasaServicioPrioridadClientes_alta +
            this.TS_Cli_facturacion_Media_V2 * calificarPlanificador_tasaServicioPrioridadClientes_media +
            this.TS_Cli_facturacion_Baja_V2 * calificarPlanificador_tasaServicioPrioridadClientes_baja;
        }
        this.valoracionMedia_V2 +=
          // 4: Tasa de disminucion de Paradas: en libre y extra
          this.tiempoLibrePorcen_V2 * calificarPlanificador_disminucionDeParadas +
          this.tiempoExtraPorcen_V2 * calificarPlanificador_horasExtra;
        if (this.aplicarCalculoPiezas) {
          var tot = 100 / (this.margenNegativoPiezas_V2 + this.margenCortoPiezas_V2 + this.margenMedioPiezas_V2 + this.margenLargoPiezas_V2 + this.margenRestoPiezas_V2)

          this.valoracionMedia_V2 +=
            // 6: Margen de entrega Semanas antes (por OF y por Piezas)
            this.margenNegativoPiezas_V2 * tot * calificarPlanificador_margenEntrega_negativo +
            this.margenCortoPiezas_V2 * tot * calificarPlanificador_margenEntrega_corto +
            this.margenMedioPiezas_V2 * tot * calificarPlanificador_margenEntrega_medio +
            this.margenLargoPiezas_V2 * tot * calificarPlanificador_margenEntrega_largo +
            this.margenRestoPiezas_V2 * tot * calificarPlanificador_margenEntrega_resto;
          // 7: Retraso medio
          if (calificarPlanificador_retrasoMedio_DiasMedia > 0) {
            var porcen = this.retrasoMedioPiezas_V2 * 100.0 / calificarPlanificador_retrasoMedio_DiasMedia;
            if (porcen > 100)
              this.valoracionMedia_V2 += calificarPlanificador_retrasoMedio;
            else
              this.valoracionMedia_V2 += porcen * calificarPlanificador_retrasoMedio;
          }
        } else {
          var tot = 100 / (this.margenNegativo_V2 + this.margenCorto_V2 + this.margenMedio_V2 + this.margenLargo_V2 + this.margenResto_V2)

          this.valoracionMedia_V2 +=
            // 6: Margen de entrega Semanas antes (por OF y por Piezas)
            this.margenNegativo_V2 * tot * calificarPlanificador_margenEntrega_negativo +
            this.margenCorto_V2 * tot * calificarPlanificador_margenEntrega_corto +
            this.margenMedio_V2 * tot * calificarPlanificador_margenEntrega_medio +
            this.margenLargo_V2 * tot * calificarPlanificador_margenEntrega_largo +
            this.margenResto_V2 * tot * calificarPlanificador_margenEntrega_resto;
          // 7: Retraso medio
          if (calificarPlanificador_retrasoMedio_DiasMedia > 0) {
            var porcen = this.retrasoMedio_V2 * 100.0 / calificarPlanificador_retrasoMedio_DiasMedia;
            if (porcen > 100)
              this.valoracionMedia_V2 += calificarPlanificador_retrasoMedio;
            else
              this.valoracionMedia_V2 += porcen * calificarPlanificador_retrasoMedio;
          }
        }

        //V3
        this.valoracionMedia_V3 = 0.0;
        if (this.aplicarTiempoComparativa) {
          this.valoracionMedia_V3 +=
            // 1: Tasa de servicio
            this.TS_OF_tiempo_V3 * calificarPlanificador_tasaServicioTotal +
            // 5: Tasa de servicio prioridad OF
            this.TS_OF_tiempo_prioridad_Alta_V3 * calificarPlanificador_tasaServicioGrupoClientes_A +
            this.TS_OF_tiempo_prioridad_Media_V3 * calificarPlanificador_tasaServicioGrupoClientes_B +
            this.TS_OF_tiempo_prioridad_Baja_V3 * calificarPlanificador_tasaServicioGrupoClientes_C +
            // 2: Tasa de servicio clientes (por grupos)
            this.TS_Cli_tiempo_grupo_A_V3 * calificarPlanificador_tasaServicioFacturacionClientes_alta +
            this.TS_Cli_tiempo_grupo_B_V3 * calificarPlanificador_tasaServicioFacturacionClientes_media +
            this.TS_Cli_tiempo_grupo_C_V3 * calificarPlanificador_tasaServicioFacturacionClientes_baja +
            // 3: Tasa de servicio facturacion Cliente
            this.TS_Cli_tiempo_facturacion_Alta_V3 * calificarPlanificador_tasaServicioPrioridadClientes_alta +
            this.TS_Cli_tiempo_facturacion_Media_V3 * calificarPlanificador_tasaServicioPrioridadClientes_media +
            this.TS_Cli_tiempo_facturacion_Baja_V3 * calificarPlanificador_tasaServicioPrioridadClientes_baja;

        } else {
          this.valoracionMedia_V3 +=
            // 1: Tasa de servicio
            this.TS_OF_V3 * calificarPlanificador_tasaServicioTotal +
            // 5: Tasa de servicio prioridad OF
            this.TS_OF_prioridad_Alta_V3 * calificarPlanificador_tasaServicioGrupoClientes_A +
            this.TS_OF_prioridad_Media_V3 * calificarPlanificador_tasaServicioGrupoClientes_B +
            this.TS_OF_prioridad_Baja_V3 * calificarPlanificador_tasaServicioGrupoClientes_C +
            // 2: Tasa de servicio clientes (por grupos)
            this.TS_Cli_grupo_A_V3 * calificarPlanificador_tasaServicioFacturacionClientes_alta +
            this.TS_Cli_grupo_B_V3 * calificarPlanificador_tasaServicioFacturacionClientes_media +
            this.TS_Cli_grupo_C_V3 * calificarPlanificador_tasaServicioFacturacionClientes_baja +
            // 3: Tasa de servicio facturacion Cliente
            this.TS_Cli_facturacion_Alta_V3 * calificarPlanificador_tasaServicioPrioridadClientes_alta +
            this.TS_Cli_facturacion_Media_V3 * calificarPlanificador_tasaServicioPrioridadClientes_media +
            this.TS_Cli_facturacion_Baja_V3 * calificarPlanificador_tasaServicioPrioridadClientes_baja;
        }
        this.valoracionMedia_V3 +=
          // 4: Tasa de disminucion de Paradas: en libre y extra
          this.tiempoLibrePorcen_V3 * calificarPlanificador_disminucionDeParadas +
          this.tiempoExtraPorcen_V3 * calificarPlanificador_horasExtra;
        if (this.aplicarCalculoPiezas) {
          var tot = 100 / (this.margenNegativoPiezas_V3 + this.margenCortoPiezas_V3 + this.margenMedioPiezas_V3 + this.margenLargoPiezas_V3 + this.margenRestoPiezas_V3)

          this.valoracionMedia_V3 +=
            // 6: Margen de entrega Semanas antes (por OF y por Piezas)
            this.margenNegativoPiezas_V3 * tot * calificarPlanificador_margenEntrega_negativo +
            this.margenCortoPiezas_V3 * tot * calificarPlanificador_margenEntrega_corto +
            this.margenMedioPiezas_V3 * tot * calificarPlanificador_margenEntrega_medio +
            this.margenLargoPiezas_V3 * tot * calificarPlanificador_margenEntrega_largo +
            this.margenRestoPiezas_V3 * tot * calificarPlanificador_margenEntrega_resto;
          // 7: Retraso medio
          if (calificarPlanificador_retrasoMedio_DiasMedia > 0) {
            var porcen = this.retrasoMedioPiezas_V3 * 100.0 / calificarPlanificador_retrasoMedio_DiasMedia;
            if (porcen > 100)
              this.valoracionMedia_V3 += calificarPlanificador_retrasoMedio;
            else
              this.valoracionMedia_V3 += porcen * calificarPlanificador_retrasoMedio;
          }
        } else {
          var tot = 100 / (this.margenNegativo_V3 + this.margenCorto_V3 + this.margenMedio_V3 + this.margenLargo_V3 + this.margenResto_V3)

          this.valoracionMedia_V3 +=
            // 6: Margen de entrega Semanas antes (por OF y por Piezas)
            this.margenNegativo_V3 * tot * calificarPlanificador_margenEntrega_negativo +
            this.margenCorto_V3 * tot * calificarPlanificador_margenEntrega_corto +
            this.margenMedio_V3 * tot * calificarPlanificador_margenEntrega_medio +
            this.margenLargo_V3 * tot * calificarPlanificador_margenEntrega_largo +
            this.margenResto_V3 * tot * calificarPlanificador_margenEntrega_resto;
          // 7: Retraso medio
          if (calificarPlanificador_retrasoMedio_DiasMedia > 0) {
            var porcen = this.retrasoMedio_V3 * 100.0 / calificarPlanificador_retrasoMedio_DiasMedia;
            if (porcen > 100)
              this.valoracionMedia_V3 += calificarPlanificador_retrasoMedio;
            else
              this.valoracionMedia_V3 += porcen * calificarPlanificador_retrasoMedio;
          }
        }

        //V4
        this.valoracionMedia_V4 = 0.0;
        if (this.aplicarTiempoComparativa) {
          this.valoracionMedia_V4 +=
            // 1: Tasa de servicio
            this.TS_OF_tiempo_V4 * calificarPlanificador_tasaServicioTotal +
            // 5: Tasa de servicio prioridad OF
            this.TS_OF_tiempo_prioridad_Alta_V4 * calificarPlanificador_tasaServicioGrupoClientes_A +
            this.TS_OF_tiempo_prioridad_Media_V4 * calificarPlanificador_tasaServicioGrupoClientes_B +
            this.TS_OF_tiempo_prioridad_Baja_V4 * calificarPlanificador_tasaServicioGrupoClientes_C +
            // 2: Tasa de servicio clientes (por grupos)
            this.TS_Cli_tiempo_grupo_A_V4 * calificarPlanificador_tasaServicioFacturacionClientes_alta +
            this.TS_Cli_tiempo_grupo_B_V4 * calificarPlanificador_tasaServicioFacturacionClientes_media +
            this.TS_Cli_tiempo_grupo_C_V4 * calificarPlanificador_tasaServicioFacturacionClientes_baja +
            // 3: Tasa de servicio facturacion Cliente
            this.TS_Cli_tiempo_facturacion_Alta_V4 * calificarPlanificador_tasaServicioPrioridadClientes_alta +
            this.TS_Cli_tiempo_facturacion_Media_V4 * calificarPlanificador_tasaServicioPrioridadClientes_media +
            this.TS_Cli_tiempo_facturacion_Baja_V4 * calificarPlanificador_tasaServicioPrioridadClientes_baja;

        } else {
          this.valoracionMedia_V4 +=
            // 1: Tasa de servicio
            this.TS_OF_V4 * calificarPlanificador_tasaServicioTotal +
            // 5: Tasa de servicio prioridad OF
            this.TS_OF_prioridad_Alta_V4 * calificarPlanificador_tasaServicioGrupoClientes_A +
            this.TS_OF_prioridad_Media_V4 * calificarPlanificador_tasaServicioGrupoClientes_B +
            this.TS_OF_prioridad_Baja_V4 * calificarPlanificador_tasaServicioGrupoClientes_C +
            // 2: Tasa de servicio clientes (por grupos)
            this.TS_Cli_grupo_A_V4 * calificarPlanificador_tasaServicioFacturacionClientes_alta +
            this.TS_Cli_grupo_B_V4 * calificarPlanificador_tasaServicioFacturacionClientes_media +
            this.TS_Cli_grupo_C_V4 * calificarPlanificador_tasaServicioFacturacionClientes_baja +
            // 3: Tasa de servicio facturacion Cliente
            this.TS_Cli_facturacion_Alta_V4 * calificarPlanificador_tasaServicioPrioridadClientes_alta +
            this.TS_Cli_facturacion_Media_V4 * calificarPlanificador_tasaServicioPrioridadClientes_media +
            this.TS_Cli_facturacion_Baja_V4 * calificarPlanificador_tasaServicioPrioridadClientes_baja;
        }
        this.valoracionMedia_V4 +=
          // 4: Tasa de disminucion de Paradas: en libre y extra
          this.tiempoLibrePorcen_V4 * calificarPlanificador_disminucionDeParadas +
          this.tiempoExtraPorcen_V4 * calificarPlanificador_horasExtra;
        if (this.aplicarCalculoPiezas) {
          var tot = 100 / (this.margenNegativoPiezas_V4 + this.margenCortoPiezas_V4 + this.margenMedioPiezas_V4 + this.margenLargoPiezas_V4 + this.margenRestoPiezas_V4)

          this.valoracionMedia_V4 +=
            // 6: Margen de entrega Semanas antes (por OF y por Piezas)
            this.margenNegativoPiezas_V4 * tot * calificarPlanificador_margenEntrega_negativo +
            this.margenCortoPiezas_V4 * tot * calificarPlanificador_margenEntrega_corto +
            this.margenMedioPiezas_V4 * tot * calificarPlanificador_margenEntrega_medio +
            this.margenLargoPiezas_V4 * tot * calificarPlanificador_margenEntrega_largo +
            this.margenRestoPiezas_V4 * tot * calificarPlanificador_margenEntrega_resto;
          // 7: Retraso medio
          if (calificarPlanificador_retrasoMedio_DiasMedia > 0) {
            var porcen = this.retrasoMedioPiezas_V4 * 100.0 / calificarPlanificador_retrasoMedio_DiasMedia;
            if (porcen > 100)
              this.valoracionMedia_V4 += calificarPlanificador_retrasoMedio;
            else
              this.valoracionMedia_V4 += porcen * calificarPlanificador_retrasoMedio;
          }
        } else {
          var tot = 100 / (this.margenNegativo_V4 + this.margenCorto_V4 + this.margenMedio_V4 + this.margenLargo_V4 + this.margenResto_V4)

          this.valoracionMedia_V4 +=
            // 6: Margen de entrega Semanas antes (por OF y por Piezas)
            this.margenNegativo_V4 * tot * calificarPlanificador_margenEntrega_negativo +
            this.margenCorto_V4 * tot * calificarPlanificador_margenEntrega_corto +
            this.margenMedio_V4 * tot * calificarPlanificador_margenEntrega_medio +
            this.margenLargo_V4 * tot * calificarPlanificador_margenEntrega_largo +
            this.margenResto_V4 * tot * calificarPlanificador_margenEntrega_resto;
          // 7: Retraso medio
          if (calificarPlanificador_retrasoMedio_DiasMedia > 0) {
            var porcen = this.retrasoMedio_V4 * 100.0 / calificarPlanificador_retrasoMedio_DiasMedia;
            if (porcen > 100)
              this.valoracionMedia_V4 += calificarPlanificador_retrasoMedio;
            else
              this.valoracionMedia_V4 += porcen * calificarPlanificador_retrasoMedio;
          }
        }


        this.loadingPanel = false;
      });

  }

  /* BOTONES */
  btnComparativaTiempo() {
    this.aplicarTiempoComparativa = true;
    this.calcularTotales();
  }
  btnComparativaOF() {
    this.aplicarTiempoComparativa = false;
    this.calcularTotales();
  }

  btnTiempoEstimado() {
    this.aplicarTiempoEstimado = true;

    this.cargarDatos();
  }
  btnTiempoPredictivo() {
    this.aplicarTiempoEstimado = false;

    this.cargarDatos();
  }

  btnCalculoPiezas() {
    this.aplicarCalculoPiezas = true;
    this.calcularTotales();
  }
  btnCalculoOF() {
    this.aplicarCalculoPiezas = false;
    this.calcularTotales();
  }

  /* BACK */
  btnAceptar() {
    this.router.navigateByUrl('planificadorLargoMaquinas/0');
  }

}



