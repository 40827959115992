<div class="calendarFiltro" >
  <div class="calendarFiltro-input" (click)="showCalendar()" >

      <label *ngIf="fechaIni != undefined && fechaFin == undefined">{{fechaIni | kendoDate}}</label>
      <label *ngIf="fechaFin != undefined && fechaFin != undefined">{{fechaIni | kendoDate}} - {{fechaFin | kendoDate}}</label>
      <i class="k-icon k-i-calendar ml-2"></i>
  
  </div>
  <div  class="calendarFiltro-calendario" style="position: absolute !important; width: 367px;" [hidden]="!mostrarCalendario">
    <div class="row" #calendario>
      <div id="col-mes">
        <kendo-calendar id="calendario-0" [(value)]="DaysInMonths[0]" type="classic"
                        (click)="valueClickCalendar(0, $event)">
          <ng-template kendoCalendarMonthCellTemplate let-date>
            <span class="calendarFiltro-calendario-contenido-dia"
                  [class.calendarFiltro-calendarioSeleccionado]="isDateSelected(date)"> {{ date.getDate() }} </span>
          </ng-template>
          <ng-template kendoCalendarHeaderTitleTemplate let-title>
            <div> {{ title }} </div>
          </ng-template>
        </kendo-calendar>
      </div>
      <div id="col-mes-botones">
        <div class="calendarFiltro-botones">
          <div class="clearfix text-center">
            <button type="button" (click)="cambiarMeses(-1, calendario)"><i class="fa fa-chevron-left"></i></button>
            <button type="button" (click)="cambiarMeses(1, calendario)"><i class="fa fa-chevron-right"></i></button>
            <button type="button" (click)="cargarMeses()">{{ 'hoy' | translate}}</button>
          </div>

          <div>
            <div class="clearfix"><button type="button" (click)="ultimas24HButton()">{{'ultimas24H' | translate}}</button></div>
            <div class="clearfix"><button type="button" (click)="ultimos7DiasButton()">{{'ultimos7Dias' |translate}}</button></div>
            <div class="clearfix"><button type="button" (click)="ultimos30DiasButton()">{{'ultimos30Dias' |translate}}</button></div>
            <div class="clearfix"><button type="button" (click)="ultimos60DiasButton()">{{'ultimos60Dias' |translate}}</button></div>
            <div class="clearfix"><button type="button" (click)="ultimos90DiasButton()">{{'ultimos90Dias' | translate}}</button></div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
