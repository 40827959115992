import { Component } from '@angular/core';
import { AlertService, UsuariosService, MaquinasService } from '@app/_services';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-maquina-secciones-subestados',
    templateUrl: './maquinaDetalleSubEstados.component.html'
})

export class MaquinaDetalleSeccionesSubEstadosComponent {
    //VARIABLES BASICAS
    private translate: TranslateService;
    public loadingPanel: any = false;
    public user = this.userService.userValue;

    //GRID: estado
    public JSubestados: any;
    public seleccionados: number[] = [];
    public idMaquina: any;
    // TIPOS DE PROCESOS (ES IGUAL QUE EL COMBO QUE SE USA DENTRO)
    public JProcesos_Tipo: any =
        [
            { "id": "1", "nombre": "" + this.translateService.instant('ejecucion') + "" },
            { "id": "2", "nombre": "" + this.translateService.instant('parada') + "" },
            { "id": "3", "nombre": "" + this.translateService.instant('preparacion') + "" },
            { "id": "4", "nombre": "" + this.translateService.instant('mantenimiento') + "" },
            { "id": "6", "nombre": "" + this.translateService.instant('alarma') + "" },
            { "id": "8", "nombre": "" + this.translateService.instant('apagada') + "" }
        ];

    //popupEliminar
    public popup_Eliminar: boolean = false;

    constructor(
        private maquinasService: MaquinasService,
        public router: Router,
        private route: ActivatedRoute,
        private translateService: TranslateService,
        private userService: UsuariosService,
        private alertService: AlertService) {

        this.translate = this.translate;
    }


    ngOnInit() {
        this.cargarDatos();
    }
    cargarDatos() {
        this.idMaquina = this.route.snapshot.params['id'];
        this.loadingPanel = true;
        this.maquinasService.Get_subestados(this.idMaquina).subscribe(
            (json) => {
                this.JSubestados = json;
                this.loadingPanel = false;
            });
    }

    get_nombre_Proceso(id): string {
        return this.JProcesos_Tipo.filter(f => f.id == id)[0].nombre;
    }

    clickNuevo() {
        if (this.idMaquina != undefined) {
            this.router.navigate(['maquinas/subestado/crear/' + this.idMaquina]);
        } else {
            this.alertService.warn(this.translateService.instant('creaPrimeroLaMaquina'), { keepAfterRouteChange: true });
        }
    }
    clickEditar() {
        if (this.idMaquina != undefined) {
            if (this.seleccionados.length > 0) {
                var subestadosSeleccionados = this.JSubestados.filter((f) => f.id == this.seleccionados[0])
                if (subestadosSeleccionados.length > 0) {
                    this.router.navigate(['maquinas/subestado/editar/' + this.idMaquina + "/" + subestadosSeleccionados[0].id]);
                }
            }
        } else {
            this.alertService.warn(this.translateService.instant('creaPrimeroLaMaquina'), { keepAfterRouteChange: true });
        }
    }
    cellClick(e) {
        if (this.idMaquina != undefined) {
            this.router.navigate(['maquinas/subestado/editar/' + this.idMaquina + "/" + e.dataItem.id]);
        }
    }
    //BOTON PARA ELIMINAR ESTADOS   
    clickEliminar() {
        if (this.idMaquina != undefined) {
            if (this.seleccionados.length == 0) {
                this.alertService.warn(this.translateService.instant('estadonoseleccionado'), { keepAfterRouteChange: true });
            } else {
                this.popup_Eliminar = true;
            }
        }
    }
    confirmarEliminar() {
        if (this.idMaquina != undefined) {
            if (this.seleccionados.length == 0) {
                this.alertService.warn(this.translateService.instant('seleccioneSubestado'), { keepAfterRouteChange: true });
            } else {
                this.maquinasService.Delete_subestados(this.seleccionados, this.idMaquina).subscribe(
                    (r) => {
                        this.popup_Eliminar = false;
                        this.maquinasService.Get_subestados(this.idMaquina).subscribe(
                            (json) => {
                                this.JSubestados = json;
                                this.loadingPanel = false;
                            });
                    });

            }
        }
    }

}