import { Component } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router"
import {  ViewEncapsulation, ViewChild } from '@angular/core';
import { HerramientasService } from '@app/_services/herramientas.service';

import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService, UsuariosService } from '../_services';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';

@Component({
  selector: 'app-herramientasunidades',
  templateUrl: 'herramientasunidades.component.html'
})

export class HerramientasUnidadesComponent {
  public loadingGraficoTresEjes: any = false;
  public seleccionados: number[] = [];
  public seleccionadosPlacas: number[] = [];
  private translate: TranslateService;
  public herramientas: [];
  public placas: [];
  public r1: boolean;
  public r2: boolean;
  public placastr: string;
  public herramientastr: string
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  closeResult = '';
  navigationSubscription;
  public user = this.userService.userValue;

  constructor(private herramientasService: HerramientasService,
    translate: TranslateService,
    private menuService: MenuService, public router: Router,
    private translateService: TranslateService,
    private userService: UsuariosService,
    private modalService: NgbModal) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('herramientasplacas').toUpperCase();
    this.placastr = this.translate.instant('placas').toUpperCase();
    this.herramientastr = this.translate.instant('herramientas').toUpperCase();

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/herramientasunidades') {
          this.menuService.titulo = this.translate.instant('herramientasplacas').toUpperCase();
          this.herramientasService.GetAllHerramientasUnidades().subscribe((result) => {
            this.herramientas = result.data;
          }
          );
        } else {
         
        }
      }
    });
  }
  finishLoading() {
    if (this.r1 && this.r2) {
      this.loadingGraficoTresEjes = false;
    }

  }
  ngOnInit() {
    this.r1 = false;
    this.r2 = false;
    this.loadingGraficoTresEjes = true;
    this.herramientasService.GetAllHerramientasUnidades().subscribe((result) => {
      this.herramientas = result.data;
     
      this.r1 = true;
      this.finishLoading();
      }
    );
    this.herramientasService.GetAllPlacasUnidades().subscribe((result) => {
      this.placas = result.data;
      this.r2 = true;
      this.finishLoading();
      }
    );
    
  }

  onClickNuevo() {
    this.router.navigate(['herramientasunidades/crear/']);
  }
  onClickNuevoPlacas() {
    this.router.navigate(['herramientasunidades/crearplacas/']);
  }

  onClickEditar() {
    if (this.seleccionados[0] > 0) {
      this.router.navigate(['herramientasunidades/editar/', this.seleccionados[0]]);
    }
  }
  onClickEditarPlacas() {
    if (this.seleccionados[0] > 0) {
      this.router.navigate(['herramientasunidades/editarplacas/', this.seleccionadosPlacas[0]]);
    }
  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['herramientasunidades/editar/', this.seleccionados[0]]);
    }
  }
  cellClickPlacas(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['herramientasunidades/editarplacas/', this.seleccionadosPlacas[0]]);
    }
  }

  onClickEliminar(content) {
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  onClickEliminarPlacas(content) {
    if (this.seleccionadosPlacas.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  private eliminarRegistro(contentloading, contentNotErased) {
   
    this.herramientasService.deleteHerramientasUnidad({ ids: this.seleccionados }).subscribe(
      (data) => {
        if (data.error == false) {
          this.herramientasService.GetAllHerramientasUnidades().subscribe((result) => {
            this.herramientas = result.data;
          }
          );
        }
        if (data.undeletedElements != "") {
          
          this.modalService.open(contentNotErased, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
        }
        this.modalReferenceloading.close();
      }
    );
    this.herramientasService.deletePlacaUnidad({ ids: this.seleccionadosPlacas }).subscribe(
      (data) => {
        if (data.error == false) {
          this.herramientasService.GetAllPlacasUnidades().subscribe((result) => {
            this.placas = result.data;
          }
          );
        }
        if (data.undeletedElements != "") {

          this.modalService.open(contentNotErased, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
        }
        this.modalReferenceloading.close();
      }
    );
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.seleccionados = [];
    this.seleccionadosPlacas = [];
  }

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }
}
