import { Component } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router"

import { SubcontratadoService } from '@app/_services/subcontratado.service';

import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService, UsuariosService } from '../_services';

@Component({
  selector: 'app-subcontratados',
  templateUrl: 'subcontratados.component.html'
})

export class SubcontratadosComponent {

  public seleccionados: number[] = [];
  private translate: TranslateService;
  public subcontratados: any;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  closeResult = '';
  navigationSubscription;
  user = this.userService.userValue;

  constructor(private subcontratadoService: SubcontratadoService,
    translate: TranslateService,
    private menuService: MenuService, public router: Router,
    private userService: UsuariosService,
    private translateService: TranslateService,
    private modalService: NgbModal) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('subcontratados').toUpperCase();

    this.subcontratadoService.get(0).subscribe(
      json => {
        this.subcontratados = json;
      });
  }

  ngOnInit() {
  }

  onClickNuevo() {
    this.router.navigate(['subcontratado/', 0]);
  }
  onClickEditar() {
    if (this.seleccionados[0] > 0) {
      this.router.navigate(['subcontratado/', this.seleccionados[0]]);
    }
  }
  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['subcontratado/', this.seleccionados[0]]);
    }
  }
  onClickEliminar(content) {
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  eliminarRegistro(contentloading, contentNotErased) {
    var ids: string = ''
    var an: any = this.seleccionados;

    an.forEach(f => {
      if (ids == '')
        ids = f
      else
        ids += f + ', '
    });

    this.subcontratadoService.delete(ids).subscribe(
      (data) => {
        this.subcontratadoService.get(0).subscribe(
          json => {
            this.subcontratados = json;
            this.modalReferenceloading.close();
          });
      }
    );

    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.seleccionados = [];
  }
}
