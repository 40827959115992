import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { UsuariosService, MenuService, InformeProyectosService, InformeOeeService, MaquinasService, HistoricoOperacionesService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MyFunctions } from '@app/_helpers';
import { first } from 'rxjs/operators';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { ActivatedRoute, Router } from "@angular/router";
import * as c3 from 'c3';
import * as d3 from 'd3';
import * as moment from 'moment';
import * as $ from 'jquery';

import { IntlService } from '@progress/kendo-angular-intl';

import { GroupResult, groupBy } from '@progress/kendo-data-query';


@Component({
  selector: 'app-analitica-avanzada-ejecuciones',
  templateUrl: './analiticaAvanzadaEjecuciones.component.html'
})

export class AnaliticaAvanzadaEjecucionesComponent {

  user = this.userService.userValue;
  public form: FormGroup;

  public fini: any;
  public ffin: any;
  public idcliente: any;
  public idpieza: any;
  public idof: any;
  public nSerie: any;
  public idOperacion: any;

  public loadingDatos: boolean = false;
  public loadingPerdidas: boolean = false;
  public tieneDatos: boolean = true;

  public dataInforme: any;
  public dataInformeGroupByHistoricoOperacion: any;

  public horasRealesPanelIzq: any = 0;
  public horasEstimadasPanelIzq: any = 0;
  public desvioHorasEstimadoPanelIzq: any = 0;
  public horasPrevistasPanelIzq: any = 0;
  public desvioHorasPrevistasPanelIzq: any = 0;
  public cantidadPanelIzq: any = 0;
  public cantidadRealPanelIzq: any = 0;
  public avanceAcumuladoPanelIzq: any = 0;
  public kwAcumuladosPanelIzq: any = 0;
  public cambiosDePlacaPanelIzq: any = 0;
  public roturaDeHerramientaPanelIzq: any = 0;

  public datosGridOperaciones: any;
  public datosGridMaquinas: any;
  public datosGridOperarios: any;
  public datosGridPiezas: any;
  public datosGridClientes: any;

  public graficoDonutRendimiento: any;
  public graficoRendimientoCalidad: any;
  public graficoDonutCalidad: any;

  public graficoOperaciones: any;
  public graficoMaquinas: any;
  public graficoOperarios: any;
  public graficoPiezas: any;
  public graficoClientes: any;

  public graficoDonutParadas: any;
  public graficoDonutMantenimientos: any;
  public graficoDonutAlarmas: any;
  public dataGraficoDonutParadas = [];
  public dataGraficoDonutParadasParaTooltip: any = {};
  public dataGraficoDonutMantenimientos = [];
  public dataGraficoDonutMantenimientosParaTooltip: any = {};
  public dataGraficoDonutAlarmas = [];
  public dataGraficoDonutAlarmasParaTooltip: any = {};

  //PARA AÑADIR PAGGING AL GRID
  public skipOperaciones = 0;
  public skipMaquinas = 0;
  public skipOperarios = 0;
  public skipPiezas = 0;
  public skipClientes = 0;

  public pageSize = 10;

  private dataFiltro: any;

  //PANEL MAQUINAS
  public maquinas: any = [];
  public instalaciones: any = [];
  public maquinasMostradas: any = [];

  //AREA PRODUCTIVA / SECCION
  public secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //GRUPOS DE MAQUINAS
  public grupos: any;
  public gruposSeleccionados: any;

  //CARGA DE SECCIONES Y GRUPOS
  public gruposCargados: boolean = false;
  public seccionesCargadas: boolean = false;

  //GRAFICOS Y GRIDS VISIBLES
  public datosVisibles: boolean = false;

  public agrupado: number = 0; // 0 perdidas, 1 padres, 2 grupos

  //FILTRO
  status: boolean = false; //para abrir o cerrar el filtro * se carga abierto siempre! si se autofiltra, se cierra solo.
  // NO SE PUEDEN CAMBIAR LOS PROXIMOS IDs START
  //  en las funciones de nuestro a kendo se usan estos IDs, tanto los de AND/OR como los de las opciones de cada tipo de dato.
  public andOr = [
    { id: 0, nombre: this.translateService.instant('y') },
    { id: 1, nombre: this.translateService.instant('o') }
  ];
  public opcionDate = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Menor que', dobleValor: false }, //solo comentado en esta ventana
    // { id: 2, nombre: 'Menor o igual', dobleValor: false }, //solo comentado en esta ventana
    // { id: 3, nombre: 'Mayor que', dobleValor: false }, //solo comentado en esta ventana
    // { id: 4, nombre: 'Mayor o igual', dobleValor: false }, //solo comentado en esta ventana
    // { id: 5, nombre: 'Es', dobleValor: false }, //solo comentado en esta ventana
    // { id: 6, nombre: 'No es', dobleValor: false }, //solo comentado en esta ventana
    { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
    { id: 8, nombre: this.translateService.instant('noEstaEntre'), dobleValor: true }
  ];
  public opcionDateTime = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translateService.instant('menorQue'), dobleValor: false },
    { id: 2, nombre: this.translateService.instant('menorOigual'), dobleValor: false },
    { id: 3, nombre: this.translateService.instant('mayorQue'), dobleValor: false },
    { id: 4, nombre: this.translateService.instant('mayorOigual'), dobleValor: false },
    { id: 5, nombre: this.translateService.instant('es'), dobleValor: false },
    { id: 6, nombre: this.translateService.instant('noEs'), dobleValor: false }
  ];
  public opcionNumericDecimal = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translateService.instant('menorQue'), dobleValor: false },
    { id: 2, nombre: this.translateService.instant('menorOigual'), dobleValor: false },
    { id: 3, nombre: this.translateService.instant('mayorQue'), dobleValor: false },
    { id: 4, nombre: this.translateService.instant('mayorOigual'), dobleValor: false },
    { id: 5, nombre: this.translateService.instant('es'), dobleValor: false },
    { id: 6, nombre: this.translateService.instant('noEs'), dobleValor: false },
    { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
    { id: 8, nombre: this.translateService.instant('noEstaEntre'), dobleValor: true }
  ];
  public opcionComboEstricto = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Uniselect', dobleValor: false},
    { id: 2, nombre: this.translateService.instant('es'), dobleValor: true }, // solo hase van a mostrar combos multiseleccionables IN / OUT
    // { id: 3, nombre: 'No select', dobleValor: false},
    { id: 4, nombre: this.translateService.instant('noEs'), dobleValor: true }// solo hase van a mostrar combos multiseleccionables IN / OUT
  ];
  public opcionComboFlexible = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Uniselect', dobleValor: false},
    { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
    // { id: 3, nombre: 'No select', dobleValor: false},
    { id: 4, nombre: this.translateService.instant('noEs'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
    { id: 5, nombre: this.translateService.instant('empiezaPor'), dobleValor: false },
    // { id: 6, nombre: 'No empieza por', dobleValor: false},
    { id: 7, nombre: this.translateService.instant('acabaPor'), dobleValor: false },
    // { id: 8, nombre: 'No acaba por', dobleValor: false},
    { id: 9, nombre: this.translateService.instant('contiene'), dobleValor: false },
    { id: 10, nombre: this.translateService.instant('noContiene'), dobleValor: false }//,
    // { id: 11, nombre: 'Igual que', dobleValor: false},
    // { id: 12, nombre: 'Diferente a', dobleValor: false}
  ];
  public opcionString = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translateService.instant('empiezaPor'), dobleValor: false },
    // { id: 2, nombre: 'No empieza por', dobleValor: false},
    { id: 3, nombre: this.translateService.instant('acabaPor'), dobleValor: false },
    // { id: 4, nombre: 'No acaba por', dobleValor: false},
    { id: 5, nombre: this.translateService.instant('contiene'), dobleValor: false },
    { id: 6, nombre: this.translateService.instant('noContiene'), dobleValor: false },
    { id: 7, nombre: this.translateService.instant('igualQue'), dobleValor: false },
    { id: 8, nombre: this.translateService.instant('diferenteA'), dobleValor: false }
  ];
  // NO SE PUEDEN CAMBIAR LOS PROXIMOS IDs END
  //FECHA DOBLE
  public DaysInMonths;

  // VARIABLES input del filtro
  public permitirFiltroVacio = false; // permite vaciar el filtro por completo
  public vaciarUltimaLinea = true; // cuando no se deja vaciar por completo un filtro, este resetea la ultima linea al intentar eliminarla
  public permitirMultiplesLineasVacias = false; // perminte añadir mas lineas que las que se han rellenado
  public annadirAutomatico = true; // añade lineas automaticamente si se selecciona la columna o la operacion Esta opcion contradice la de perminir multiples lineas vacias
  public dobleClickMismaFecha = false; // permite seleccionar la misma fecha en inicio y fin en el control doble de fechas
  // DATOS INICIALES DEL FILTRO
  // DATOS INICIALES DEL FILTRO
  public filtroPorDefecto = {
    logic: { id: 1, nombre: this.translateService.instant('o') },
    group: [
      {
        logic: { id: 0, nombre: this.translateService.instant('y') },
        group: [
          {
            columna: { id: 1, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },
            operator: { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          },
          {
            columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        ]
      }
    ]
  };
  public datosFiltro = {
    logic: { id: 1, nombre: this.translateService.instant('o') },
    group: []
  };
  // ESTOS SON LOS TIPOS QUE SE ACEPTAN EN EL FILTRO: date, dateTime, comboEstrincto, comboFlexible, check, numeric, decimal, string
  public columnasFiltro = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'date', field:"", tipo: 'date' },
    // { id: 2, nombre: 'dateTime', field:"", tipo: 'dateTime' },
    // { id: 4, nombre: 'comboEstrincto', field:"", tipo: 'comboEstrincto' },
    // { id: 10, nombre: 'comboFlexible', field:"", tipo: 'comboFlexible' },
    // { id: 16, nombre: 'check', field:"", tipo: 'check' },
    // { id: 17, nombre: 'numeric', field:"", tipo: 'numeric' },
    // { id: 18, nombre: 'decimal', field:"", tipo: 'decimal' },
    // { id: 19, nombre: 'string', field:"", tipo: 'string' }    
    // { id: 1, nombre: 'Fecha inicio', field: "fechaIni", sqlfield: "sql.fechaIni", tipo: 'date' },
    // { id: 2, nombre: 'Fecha fin', field: "fechaFin", sqlfield: "sql.fechaFin", tipo: 'date' },
    { id: 2, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },
    { id: 3, nombre: this.translateService.instant('turno'), field: "idTurno", sqlfield: "hb.tipoTurno", tipo: 'comboEstrincto' },
    { id: 4, nombre: this.translateService.instant('seccion'), field: "idSeccion", sqlfield: "maq.idSeccion", tipo: 'comboEstrincto' },
    { id: 5, nombre: this.translateService.instant('grupoMaquinas'), field: "idGrupo", sqlfield: "mgm.idMaquinasGrupo", tipo: 'comboEstrincto' },
    { id: 6, nombre: this.translateService.instant('maquina'), field: "idMaquina", sqlfield: "hb.idMaquina", tipo: 'comboEstrincto' },
    { id: 7, nombre: this.translateService.instant('operario'), field: "idOperario", sqlfield: "hb.idOperario", tipo: 'comboEstrincto' },
    { id: 8, nombre: this.translateService.instant('of'), field: "nombreOf", sqlfield: "po.numeroOF", tipo: 'comboFlexible' },
    { id: 9, nombre: this.translateService.instant('cliente'), field: "nombreCliente", sqlfield: "po.nombreCliente", tipo: 'comboFlexible' },
    { id: 10, nombre: this.translateService.instant('pieza'), field: "nombrePieza", sqlfield: "po.nombrePieza", tipo: 'comboFlexible' },
    { id: 11, nombre: this.translateService.instant('nserie'), field: "nSerie", sqlfield: "ho.nSerie", tipo: 'string' },
    { id: 12, nombre: this.translateService.instant('parte'), field: "nombreParte", sqlfield: "po.nombreParte", tipo: 'comboFlexible' },
    { id: 13, nombre: this.translateService.instant('operacion'), field: "nombreOperacion", sqlfield: "po.nombreOperacion", tipo: 'comboFlexible' },
    { id: 14, nombre: this.translateService.instant('terminado'), field: "terminado", sqlfield: "po.operacionTerminada", tipo: 'check' }
  ];

  // Combo cargable del filtro
  public filtro_listaMaquinas: any;
  public filtro_listaOperarios: any;
  public filtro_listaTurnos: any;
  public filtro_listaHerramientas: any;
  public filtro_ocultarPartes: boolean;
  // variables para cuando la precarga requiere de alguna consulta
  public idpieza_prefiltro: number = 0;
  public idof_prefiltro: number = 0;
  public nSerie_prefiltro: string = "";
  public idOperacion_prefiltro: number = 0;
  // LOADING filtro
  public loadingFiltro: boolean = false;
  // public mostrarCalendario: boolean = false;
  public actualizarVisible: boolean = false;
  //END FILTRO

  // TRADUCCIONES GRID INFO
  public infoTTotalGridHeader = this.translateService.instant('infoTTotalGridHeader');
  public infoTRealGridHeader = this.translateService.instant('infoTRealGridHeader');

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  constructor(private informeProyectosService: InformeProyectosService,
    private informeOeeService: InformeOeeService,
    private userService: UsuariosService,
    private route: ActivatedRoute,
    private router: Router,
    private menuService: MenuService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    public myFunctions: MyFunctions,
    private maquinasService: MaquinasService,
    private historicoOperacionesService: HistoricoOperacionesService,
    private intl: IntlService,//este último es para traducir las fechas para los tags
    private cdRef: ChangeDetectorRef
  ) {

    this.menuService.titulo = this.translateService.instant('analiticaAvanzadaEjecuciones').toUpperCase();

    this.userService.user.subscribe(x => this.user = x);

  }
  async ngOnInit() {

    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      idTurno: ['',]
    });

    this.loadingFiltro = true;

    //this.cargarDatosFiltro();
    this.cargarGrupos();//DESDE AQUI SE LLAMA A CARGARDATSOFILTRO
    this.cargarAreasProductivas();//DESDE AQUI SE LLAMA A CARGARDATSOFILTRO
    this.dibujarDonutRendimiento();
    this.dibujarDonutCalidad();
    this.dibujarGraficoRendimientoCalidad();

    var controlCiclo = 0;
    while (document.getElementById("graficoClientes_AnaliticaAvanzadaEjecuciones") == null && controlCiclo < 10) {
      await new Promise(r => {
        controlCiclo++;
        setTimeout(r, 200)
      });
    }

    this.dibujarGraficoBarras('#graficoOperaciones_AnaliticaAvanzadaEjecuciones', 0); //GRAFICO OPERACIONES
    this.dibujarGraficoBarras('#graficoMaquinas_AnaliticaAvanzadaEjecuciones', 1); //GRAFICO MAQUINAS
    this.dibujarGraficoBarras('#graficoOperarios_AnaliticaAvanzadaEjecuciones', 2); //GRAFICO OPERARIOS
    this.dibujarGraficoBarras('#graficoPiezas_AnaliticaAvanzadaEjecuciones', 3); //GRAFICO PIEZAS
    this.dibujarGraficoBarras('#graficoClientes_AnaliticaAvanzadaEjecuciones', 4); //GRAFICO CLIENTES

    this.dibujarGraficoDonutPerdidas('#chartDonutParadas_aae', 0); //GRAFICO PARADAS
    this.dibujarGraficoDonutPerdidas('#chartDonutMantenimientos_aae', 1); //GRAFICO MANTENIMIENTOS
    this.dibujarGraficoDonutPerdidas('#chartDonutAlarmas_aae', 2); //GRAFICO ALARMAS
  }

  //FILTRO----------------------------------------------------------------------------------------------------------------------------------------
  annadirGrupoFiltro() {
    this.datosFiltro.group.push(
      {
        logic: { id: 0, nombre: this.translateService.instant('y') },
        group: [
          {
            columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        ]
      }
    )
  }
  annadirLineaFiltro(filtro) {
    // Ahora se selecciona la primera opcion, no hara falta mirar si hay algo seleccionado
    if (this.permitirMultiplesLineasVacias || filtro.group.filter(f => f.columna.id == 0 /*|| f.operator.id == 0*/).length == 0) {
      filtro.group.push({
        columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
        operator: { id: 0, nombre: '' },
        fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
        fechaFin: this.myFunctions.getDateNow(),
        mostrarCalendario: false,
        text: '',
        numberMin: 0,
        numberMax: 0,
        decimalformat: '0.000',
        decimalMin: 0.0,
        decimalMax: 0.0,
        check: false,
        combo: [{ id: 1, nombre: "" }],
        comboSelected: {},
        comboSelecteds: []
      })
    }
  }
  borrarLineaFiltro(row, filtro) {
    if (filtro.group.length > 1) {
      //ELIMINAR LINEA DE FILTRO
      let index = filtro.group.findIndex(d => d === row);
      filtro.group.splice(index, 1);
      this.actualizarVisible = true;
    } else if (filtro.group.length == 1 && ((filtro != this.datosFiltro.group[0] || this.datosFiltro.group.length > 1) || this.permitirFiltroVacio)) {
      //SIEMPRE Y CUANDO NO SEA EL PRIMER GRUPO, SE PUEDE ELIMINAR
      let index = this.datosFiltro.group.findIndex(d => d === filtro);
      this.datosFiltro.group.splice(index, 1);
      this.actualizarVisible = true;
    } else if (filtro.group.length == 1 && (((filtro != this.datosFiltro.group[0] || this.datosFiltro.group.length == 1) || !this.permitirFiltroVacio)) && this.vaciarUltimaLinea) {
      //ELIMINAR LINEA DE FILTRO
      let index = filtro.group.findIndex(d => d === row);
      filtro.group.splice(index, 1);
      // Se borra y se añade una linea nueva vacia
      filtro.group.push({
        columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
        operator: { id: 0, nombre: '' },
        fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
        fechaFin: this.myFunctions.getDateNow(),
        mostrarCalendario: false,
        text: '',
        numberMin: 0,
        numberMax: 0,
        decimalformat: '0.000',
        decimalMin: 0.0,
        decimalMax: 0.0,
        check: false,
        combo: [{ id: 1, nombre: "" }],
        comboSelected: {},
        comboSelecteds: []
      })
      this.actualizarVisible = true;
    }
  }
  filtroTipoChanged(filtro, row, newSelection) {
    row.columna = newSelection;
    if (this.annadirAutomatico)
      this.annadirLineaFiltro(filtro);


    // DATE -
    if (row.columna.tipo == 'date') { row.operator = this.opcionDate[0]; }
    // DATETIME -
    else if (row.columna.tipo == 'dateTime') { row.operator = this.opcionDateTime[0] }
    // COMBO ESTRICTO -
    else if (row.columna.tipo == 'comboEstrincto') { row.operator = this.opcionComboEstricto[0] }
    // COMBO FLEXIBLE -
    else if (row.columna.tipo == 'comboFlexible') { row.operator = this.opcionComboFlexible[0] }
    // CHECK - NO HAY
    // NUMERIC -
    else if (row.columna.tipo == 'numeric') { row.operator = this.opcionNumericDecimal[0] }
    // DECIMAL -
    else if (row.columna.tipo == 'decimal') { row.operator = this.opcionNumericDecimal[0] }
    // STRING -
    else if (row.columna.tipo == 'string') { row.operator = this.opcionString[0] }

    this.preFiltrado(filtro, row);
  }
  borrarFiltro() {
    this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);
  }
  cargarConTodasLasRespuestas() {
    // SI ES NECESARIO, SE CARGAN LAS VARIABLES DESDE LA URL.
    this.cargarFiltroURL();
    this.loadingFiltro = false;
  }

  // DE NUESTRO FILTRO A KENDO FILTER
  filtroToKendo() {
    // NUESTRO public datosFiltro = {                                                          => KENDO		public value: CompositeFilterDescriptor = {
    // NUESTRO   logic: { id: 0, nombre: 'and' },                                              => KENDO		    logic: "or",
    // NUESTRO   group: [                                                                      => KENDO		    filters: [
    // NUESTRO     {                                                                           => KENDO		      { field: "budget", operator: "gt", value: 60 },
    // NUESTRO       logic: { id: 0, nombre: 'and' },                                          => KENDO		      { field: "country", operator: "contains" },
    // NUESTRO       group: [                                                                  => KENDO		      { field: "discontinued", operator: "eq", value: true },
    // NUESTRO         {                                                                       => KENDO		      {
    // NUESTRO           columna: { id: 0, nombre: ' ', tipo: '' },                            => KENDO		        logic: "and",
    // NUESTRO           operator: { id: 0, nombre: '' },                                      => KENDO		        filters: [
    // NUESTRO           fechaIni: this.myFunctions.getDateNow(),                              => KENDO		          { field: "ordered on", operator: "lt", value: new Date(Date.now()) },
    // NUESTRO           fechaFin: this.myFunctions.getDateNow(),                              => KENDO		        ],
    // NUESTRO           mostrarCalendario: false,                                             => KENDO		      },
    // NUESTRO           text: '',                                                             => KENDO		    ],
    // NUESTRO           numberMin: 0,                                                         => KENDO		  };
    // NUESTRO           numberMax: 0,                                                         => KENDO
    // NUESTRO           decimalformat: '0.000',                                               => KENDO
    // NUESTRO           decimalMin: 0.0,                                                      => KENDO
    // NUESTRO           decimalMax: 0.0,                                                      => KENDO
    // NUESTRO           check: false,                                                         => KENDO
    // NUESTRO           combo: [                                                              => KENDO
    // NUESTRO             {id: 1, nombre: "opcion 1 "},                                       => KENDO
    // NUESTRO             {id: 2, nombre: "opcion 2"},                                        => KENDO
    // NUESTRO             {id: 3, nombre: "opcion 3"},                                        => KENDO
    // NUESTRO             {id: 4, nombre: "opcion 4"},                                        => KENDO
    // NUESTRO             {id: 5, nombre: "opcion 5"}                                         => KENDO
    // NUESTRO           ],                                                                    => KENDO
    // NUESTRO           comboSelected: {},                                                    => KENDO
    // NUESTRO           comboSelecteds: []                                                    => KENDO
    // NUESTRO         }                                                                       => KENDO
    // NUESTRO       ]                                                                         => KENDO
    // NUESTRO     }                                                                           => KENDO
    // NUESTRO   ]                                                                             => KENDO
    // NUESTRO };                                                                              => KENDO

    var em = this.filtroToKendo_recursivo(this.datosFiltro);
    return em;
  }
  filtroToKendo_recursivo(jFiltro) {
    // es una linea o es un grupo?
    if (jFiltro.group != undefined) {
      // GRUPO
      //variables para crear la estructura final
      var filtro = [];
      var logica: string = "and";
      if (jFiltro.logic.id == 1)
        logica = "or"
      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroToKendo_recursivo(linea);
          if (newRow != undefined && newRow != "ERROR")
            filtro.push(newRow);
        });
      if(filtro.length > 0)    
        return { logic: logica, filters: filtro };
    }
    else if (jFiltro.columna != undefined) {
      // LINEA
      var jRow = {};
      // DATE -
      if (jFiltro.columna.tipo == 'date') {
        // public opcionDate = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false },
        //   { id: 7, nombre: 'Entre', dobleValor: true },
        //   { id: 8, nombre: 'No entre', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          jSubRow1["field"] = jFiltro.columna.field;
          jSubRow2["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lt"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gte"
          }
          jSubRow1["value"] = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni));
          jSubRow2["value"] = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1)));

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = this.myFunctions.datetimeToDate(this.myFunctions.dateTimeToDate(jFiltro.fechaIni));
        }
      }
      // DATETIME -
      else if (jFiltro.columna.tipo == 'dateTime') {
        // public opcionDateTime = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false }
        // ];
        if (jFiltro.operator.dobleValor) {
          // no existe este caso por ahora
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.fechaIni;
        }
      }
      // COMBO ESTRICTO -
      else if (jFiltro.columna.tipo == 'comboEstrincto') {
        // public opcionComboEstricto = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: 'Uniselect', dobleValor: false },
        //   { id: 2, nombre: 'Multiselect', dobleValor: true },
        //   { id: 3, nombre: 'No select', dobleValor: false },
        //   { id: 4, nombre: 'No Multiselect', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          if (jFiltro.operator.id == 2) {
            jRow["logic"] = 'or';
          }
          else if (jFiltro.operator.id == 4) {
            jRow["logic"] = 'and';
          }
          var subFiltro = [];
          jFiltro.comboSelecteds.forEach(
            seleccionado => {
              var jSubRow1 = {};
              jSubRow1["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 2) {
                jSubRow1["operator"] = "eq";
              }
              else if (jFiltro.operator.id == 4) {
                jSubRow1["operator"] = "neq";
              }
              jSubRow1["value"] = seleccionado.id;
              subFiltro.push(jSubRow1);
            });
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "neq";
          }
          if (jFiltro.comboSelected != undefined)
            jRow["value"] = jFiltro.comboSelected.id;
        }
      }
      // COMBO FLEXIBLE -
      else if (jFiltro.columna.tipo == 'comboFlexible') {
        // public opcionComboFlexible = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: 'Uniselect', dobleValor: false },
        //   { id: 2, nombre: 'Multiselect', dobleValor: true },
        //   { id: 3, nombre: 'No select', dobleValor: false },
        //   { id: 4, nombre: 'No Multiselect', dobleValor: true },
        //   { id: 5, nombre: 'Empieza por', dobleValor: false },
        //   { id: 6, nombre: 'No empieza por', dobleValor: false },
        //   { id: 7, nombre: 'Acaba por', dobleValor: false },
        //   { id: 8, nombre: 'No acaba por', dobleValor: false },
        //   { id: 9, nombre: 'Contiene', dobleValor: false },
        //   { id: 10, nombre: 'No contiene', dobleValor: false },
        //   { id: 11, nombre: 'Igual que', dobleValor: false },
        //   { id: 12, nombre: 'Diferente a', dobleValor: false }
        // ];
        if (jFiltro.operator.dobleValor) {
          if (jFiltro.operator.id == 2) {
            jRow["logic"] = 'or';
          }
          else if (jFiltro.operator.id == 4) {
            jRow["logic"] = 'and';
          }
          var subFiltro = [];
          jFiltro.comboSelecteds.forEach(
            seleccionado => {
              var jSubRow1 = {};
              jSubRow1["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 2) {
                jSubRow1["operator"] = "eq";
              }
              else if (jFiltro.operator.id == 4) {
                jSubRow1["operator"] = "neq";
              }
              jSubRow1["value"] = seleccionado.id;
              subFiltro.push(jSubRow1);
            });
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "eq";
            if (jFiltro.comboSelected != undefined)
              jRow["value"] = jFiltro.comboSelected.id;
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "neq";
            if (jFiltro.comboSelected != undefined)
              jRow["value"] = jFiltro.comboSelected.id;
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "startswith";
            jRow["value"] = jFiltro.text;
          }
          // else if (jFiltro.operator.id == 6){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "startswith";
          //   jRow["value"] = jFiltro.text; 
          // }
          else if (jFiltro.operator.id == 7) {
            jRow["operator"] = "endswith";
            jRow["value"] = jFiltro.text;
          }
          // else if (jFiltro.operator.id == 8){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "endswith"; 
          //   jRow["value"] = jFiltro.text;
          // }
          else if (jFiltro.operator.id == 9) {
            jRow["operator"] = "contains";
            jRow["value"] = jFiltro.text;
          }
          else if (jFiltro.operator.id == 10) {
            jRow["operator"] = "doesnotcontain";
            jRow["value"] = jFiltro.text;
          }
          else if (jFiltro.operator.id == 11) {
            jRow["operator"] = "eq";
            jRow["value"] = jFiltro.text;
          }
          else if (jFiltro.operator.id == 12) {
            jRow["operator"] = "neq";
            jRow["value"] = jFiltro.text;
          }
        }
      }
      // CHECK -
      else if (jFiltro.columna.tipo == 'check') {
        // no es necesaria una opcion
        // if (jFiltro.operator.dobleValor) {
        //   // no existe este caso por ahora
        // }
        // else{
        jRow["field"] = jFiltro.columna.field;
        jRow["operator"] = "eq";
        jRow["value"] = jFiltro.check;
        // }        
      }
      // NUMERIC -
      else if (jFiltro.columna.tipo == 'numeric') {
        // public opcionNumericDecimal = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false },
        //   { id: 7, nombre: 'Entre', dobleValor: true },
        //   { id: 8, nombre: 'No entre', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          jSubRow1["field"] = jFiltro.columna.field;
          jSubRow2["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lte"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gt"
          }
          jSubRow1["value"] = jFiltro.numberMin;
          jSubRow2["value"] = jFiltro.numberMax;

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.numberMin;
        }
      }
      // DECIMAL -
      else if (jFiltro.columna.tipo == 'decimal') {
        // public opcionNumericDecimal = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false },
        //   { id: 7, nombre: 'Entre', dobleValor: true },
        //   { id: 8, nombre: 'No entre', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          jSubRow1["field"] = jFiltro.columna.field;
          jSubRow2["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lte"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gt"
          }
          jSubRow1["value"] = jFiltro.decimalMin;
          jSubRow2["value"] = jFiltro.decimalMax;

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.decimalMin;
        }
      }
      // STRING -
      else if (jFiltro.columna.tipo == 'string') {
        // public opcionString = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: 'Empieza por', dobleValor: false },
        //   { id: 2, nombre: 'No empieza por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
        //   { id: 3, nombre: 'Acaba por', dobleValor: false },
        //   { id: 4, nombre: 'No acaba por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
        //   { id: 5, nombre: 'Contiene', dobleValor: false },
        //   { id: 6, nombre: 'No contiene', dobleValor: false },
        //   { id: 7, nombre: 'Igual que', dobleValor: false },
        //   { id: 8, nombre: 'Diferente a', dobleValor: false }
        // ];
        jRow["field"] = jFiltro.columna.field;
        if (jFiltro.operator.dobleValor) {
          // no existe este caso por ahora
        }
        else {
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "startswith";
          }
          // else if (jFiltro.operator.id == 2){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "startswith"; 
          // }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "endswith";
          }
          // else if (jFiltro.operator.id == 4){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "endswith"; 
          // }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "contains";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "doesnotcontain";
          }
          else if (jFiltro.operator.id == 7) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 8) {
            jRow["operator"] = "neq";
          }
        }
        jRow["value"] = jFiltro.text;
      }

      // LINEA
      if (jRow["value"] != undefined && jRow["operator"] != undefined && jRow["field"] != undefined) {
        return jRow;
      }
      // GRUPO
      if (jRow["filters"] != undefined && jRow["logic"] != undefined)
        if (jRow["filters"].length > 0) {
          return jRow;
        }
      // else // filtro sin terminar! no es un filtro a tener en cuenta
      //   return {}
    }
    else {
      //NO EXISTE UNA ESTRUCTURA DEFINIDA PARA ESE FILTRO
      return "ERROR";
    }
  }

  // DE NUESTRO FILTRO A SQL FILTER
  filtroToSQL(dataSQLfields = []) {
    var em = this.filtroToSQL_recursivo(this.datosFiltro, dataSQLfields);
    if (em.length > 0)
      em = " WHERE " + em;
    return em;
  }
  filtroToSQL_recursivo(jFiltro, dataSQLfields) {
    // INPUT
    //   ['hb.fechaTurno', 'columnaSQL']
    // es una linea o es un grupo?
    var sqlFilter = "";
    if (jFiltro.group != undefined) {
      var logica: string = " AND ";
      if (jFiltro.logic.id == 1)
        logica = " OR ";
      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroToSQL_recursivo(linea, dataSQLfields);
          if (newRow != undefined && newRow != "ERROR" && newRow != "")
            if (sqlFilter != "") {
              sqlFilter = sqlFilter + " " + logica + " (" + newRow + ")";
            }
            else {
              sqlFilter = "(" + newRow + ")";
            }
        });
    }
    else if (jFiltro.columna != undefined) {
      if (dataSQLfields.includes(jFiltro.columna.sqlfield) || dataSQLfields.length == 0) {
        // DATE -
        if (jFiltro.columna.tipo == 'date') {
          // public opcionDate = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: '<', dobleValor: false },
          //   { id: 2, nombre: '<=', dobleValor: false },
          //   { id: 3, nombre: '>', dobleValor: false },
          //   { id: 4, nombre: '>=', dobleValor: false },
          //   { id: 5, nombre: '=', dobleValor: false },
          //   { id: 6, nombre: '!=', dobleValor: false },
          //   { id: 7, nombre: 'Entre', dobleValor: true },
          //   { id: 8, nombre: 'No entre', dobleValor: true }
          // ];
          if (jFiltro.operator.dobleValor) {
            if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "' AND " + jFiltro.columna.sqlfield + " < '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1))) + "'"
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "' OR " + jFiltro.columna.sqlfield + " >= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1))) + "'"
            }
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
          }
        }
        // DATETIME -
        else if (jFiltro.columna.tipo == 'dateTime') {
          // public opcionDateTime = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: '<', dobleValor: false },
          //   { id: 2, nombre: '<=', dobleValor: false },
          //   { id: 3, nombre: '>', dobleValor: false },
          //   { id: 4, nombre: '>=', dobleValor: false },
          //   { id: 5, nombre: '=', dobleValor: false },
          //   { id: 6, nombre: '!=', dobleValor: false }
          // ];
          if (jFiltro.operator.dobleValor) {
            // no existe este caso por ahora
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
          }
        }
        // COMBO ESTRICTO -
        else if (jFiltro.columna.tipo == 'comboEstrincto') {
          // public opcionComboEstricto = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: 'Uniselect', dobleValor: false },
          //   { id: 2, nombre: 'Multiselect', dobleValor: true },
          //   { id: 3, nombre: 'No select', dobleValor: false },
          //   { id: 4, nombre: 'No Multiselect', dobleValor: true }
          // ];
          if (jFiltro.operator.dobleValor) {
            var valores = "";
            jFiltro.comboSelecteds.forEach(
              seleccionado => {
                if (valores == "")
                  valores = "'" + seleccionado.id + "'";
                else
                  valores += ", '" + seleccionado.id + "'";
              });
            if (valores != "") {
              if (jFiltro.operator.id == 2) {
                sqlFilter = jFiltro.columna.sqlfield + ' IN (' + valores + ")";
              }
              else if (jFiltro.operator.id == 4) {
                sqlFilter = jFiltro.columna.sqlfield + ' NOT IN (' + valores + ")";
              }
            }
          }
          // else {
          //   // NO EXISTE PORQUE NO TIENE SENTIDO 
          //   // if (jFiltro.operator.id == 1) {
          //   // }
          //   // else if (jFiltro.operator.id == 3) {
          //   // }
          // }
        }
        // COMBO FLEXIBLE -
        else if (jFiltro.columna.tipo == 'comboFlexible') {
          // public opcionComboFlexible = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: 'Uniselect', dobleValor: false },
          //   { id: 2, nombre: 'Multiselect', dobleValor: true },
          //   { id: 3, nombre: 'No select', dobleValor: false },
          //   { id: 4, nombre: 'No Multiselect', dobleValor: true },
          //   { id: 5, nombre: 'Empieza por', dobleValor: false },
          //   { id: 6, nombre: 'No empieza por', dobleValor: false },
          //   { id: 7, nombre: 'Acaba por', dobleValor: false },
          //   { id: 8, nombre: 'No acaba por', dobleValor: false },
          //   { id: 9, nombre: 'Contiene', dobleValor: false },
          //   { id: 10, nombre: 'No contiene', dobleValor: false },
          //   { id: 11, nombre: 'Igual que', dobleValor: false },
          //   { id: 12, nombre: 'Diferente a', dobleValor: false }
          // ];
          if (jFiltro.operator.dobleValor) {
            var valores = "";
            jFiltro.comboSelecteds.forEach(
              seleccionado => {
                if (valores == "")
                  valores = "'" + seleccionado.id + "'";
                else
                  valores += ", '" + seleccionado.id + "'";
              });
            if (valores != "") {
              if (jFiltro.operator.id == 2) {
                sqlFilter = jFiltro.columna.sqlfield + " IN (" + valores + ")";
              }
              else if (jFiltro.operator.id == 4) {
                sqlFilter = jFiltro.columna.sqlfield + " NOT IN (" + valores + ")";
              }
            }
          }
          else {
            // 1 y 3 NO TIENEN SENTIDO
            // if (jFiltro.operator.id == 1) {
            // }
            // else if (jFiltro.operator.id == 3) {
            // }
            if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "%')";
            }
            // else if (jFiltro.operator.id == 6){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "')";
            }
            // else if (jFiltro.operator.id == 8){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 9) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 10) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 11) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "')";
            }
            else if (jFiltro.operator.id == 12) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('" + jFiltro.text + "')";
            }
          }
        }
        // CHECK -
        else if (jFiltro.columna.tipo == 'check') {
          // no es necesaria una opcion
          // if (jFiltro.operator.dobleValor) {
          //   // no existe este caso por ahora
          // }
          // else{
          if (jFiltro.check) {
            sqlFilter = jFiltro.columna.sqlfield + " = 'true'";
          }
          else {
            sqlFilter = jFiltro.columna.sqlfield + " = 'false'";
          }
          // }        
        }
        // NUMERIC -
        else if (jFiltro.columna.tipo == 'numeric') {
          // public opcionNumericDecimal = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: '<', dobleValor: false },
          //   { id: 2, nombre: '<=', dobleValor: false },
          //   { id: 3, nombre: '>', dobleValor: false },
          //   { id: 4, nombre: '>=', dobleValor: false },
          //   { id: 5, nombre: '=', dobleValor: false },
          //   { id: 6, nombre: '!=', dobleValor: false },
          //   { id: 7, nombre: 'Entre', dobleValor: true },
          //   { id: 8, nombre: 'No entre', dobleValor: true }
          // ];
          if (jFiltro.operator.dobleValor) {
            if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.numberMin + "' AND " + jFiltro.columna.sqlfield + " <= '" + jFiltro.numberMax + "'";
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.numberMin + "' OR '" + jFiltro.columna.sqlfield + " > '" + jFiltro.numberMax + "'";
            }
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> '" + jFiltro.numberMin + "'";
            }
          }
        }
        // DECIMAL -
        else if (jFiltro.columna.tipo == 'decimal') {
          // public opcionNumericDecimal = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: '<', dobleValor: false },
          //   { id: 2, nombre: '<=', dobleValor: false },
          //   { id: 3, nombre: '>', dobleValor: false },
          //   { id: 4, nombre: '>=', dobleValor: false },
          //   { id: 5, nombre: '=', dobleValor: false },
          //   { id: 6, nombre: '!=', dobleValor: false },
          //   { id: 7, nombre: 'Entre', dobleValor: true },
          //   { id: 8, nombre: 'No entre', dobleValor: true }
          // ];
          if (jFiltro.operator.dobleValor) {
            if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.decimalMin + "' AND " + jFiltro.columna.sqlfield + " <= '" + jFiltro.decimalMax + "'";
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.decimalMin + "' OR '" + jFiltro.columna.sqlfield + " > '" + jFiltro.decimalMax + "'";
            }
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> '" + jFiltro.decimalMin + "'";
            }
          }
        }
        // STRING -
        else if (jFiltro.columna.tipo == 'string') {
          // public opcionString = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: 'Empieza por', dobleValor: false },
          //   { id: 2, nombre: 'No empieza por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   { id: 3, nombre: 'Acaba por', dobleValor: false },
          //   { id: 4, nombre: 'No acaba por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   { id: 5, nombre: 'Contiene', dobleValor: false },
          //   { id: 6, nombre: 'No contiene', dobleValor: false },
          //   { id: 7, nombre: 'Igual que', dobleValor: false },
          //   { id: 8, nombre: 'Diferente a', dobleValor: false }
          // ];
          if (jFiltro.operator.dobleValor) {
            // no existe este caso por ahora
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "%')";
            }
            // else if (jFiltro.operator.id == 2){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "')";
            }
            // else if (jFiltro.operator.id == 4){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "')";
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('" + jFiltro.text + "')";
            }
          }
        }
      }
    }
    return sqlFilter;
  }

  // DE NUESTRO FILTRO A CANTIDAD FILTRADOS
  filtroToCount(jFiltro = this.datosFiltro) {
    var em = this.filtroToCount_recursivo(jFiltro);
    return em;
  }
  filtroToCount_recursivo(jFiltro) {
    // es una linea o es un grupo?
    if (jFiltro.group != undefined) {
      // GRUPO
      var count = 0;
      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroToCount_recursivo(linea);
          if (newRow != undefined && newRow > 0)
            count += newRow;
        });
      return count;
    }
    else if (jFiltro.columna != undefined) {
      // LINEA
      var count = 0;
      // DATE -
      if (jFiltro.columna.tipo == 'date') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.fechaIni != undefined && jFiltro.fechaFin != undefined) {
          count = 1;
        }
        else {
          if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.fechaIni != undefined) {
            count = 1;
          }
        }
      }
      // DATETIME -
      else if (jFiltro.columna.tipo == 'dateTime') {
        if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.fechaIni != undefined) {
          count = 1;
        }
      }
      // COMBO ESTRICTO -
      else if (jFiltro.columna.tipo == 'comboEstrincto') {
        if ((jFiltro.operator.id == 2 || jFiltro.operator.id == 4) && jFiltro.comboSelecteds.length > 0) {
          count = 1;
        }
        else if ((jFiltro.operator.id == 1 || jFiltro.operator.id == 3) && jFiltro.comboSelected.id > 0) {
          count = 1;
        }
      }
      // COMBO FLEXIBLE -
      else if (jFiltro.columna.tipo == 'comboFlexible') {
        if ((jFiltro.operator.id == 2 || jFiltro.operator.id == 4) && jFiltro.comboSelecteds.length > 0) {
          count = 1;
        }
        else if ((jFiltro.operator.id == 1 || jFiltro.operator.id == 3) && jFiltro.comboSelected.id > 0) {
          count = 1;
        }
        else if ((jFiltro.operator.id >= 5 && jFiltro.operator.id < 13) && jFiltro.text > "") {
          count = 1;
        }
      }
      // CHECK -
      else if (jFiltro.columna.tipo == 'check') {
        count = 1;
      }
      // NUMERIC -
      else if (jFiltro.columna.tipo == 'numeric') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.numberMin != undefined && jFiltro.numberMax != undefined) {
          count = 1;
        }
        else {
          if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.numberMin == false) {
            count = 1;
          }
        }
      }
      // DECIMAL -
      else if (jFiltro.columna.tipo == 'decimal') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.decimalMin != undefined && jFiltro.decimalMax != undefined) {
          count = 1;
        }
        else if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.decimalMin == false) {
          count = 1;
        }
      }
      // STRING -
      else if (jFiltro.columna.tipo == 'string' && jFiltro.text != "") {
        count = 1;
      }
      return count;
    }
    else {
      return 0;
    }
  }

  // FECHA DOBLE
  //    Función para agregar los días seleccionados al periodo correspondiente (el periodo seleccionado)
  valueClickCalendar(row, month, event) {
    if (event.target.classList.contains("calendarFiltro-calendario-contenido-dia")) { //nos aseguramos de que se está clickando en un día y no en otra parte
      if ((event.target.classList.contains("calendarFiltro-calendarioSeleccionado") && row.fechaIni != undefined && row.fechaFin == undefined)) {//se ha vuelto a seleccionar en el periodo, deseleccionar
        row.fechaFin = this.DaysInMonths[month];
        row.mostrarCalendario = false; //Si ya tenemos las dos fechas, que lo cierre
      } else {
        if (row.fechaIni == undefined && row.fechaFin == undefined) {
          row.fechaIni = this.DaysInMonths[month];
        }
        else if (row.fechaIni != undefined && row.fechaFin == undefined) {
          row.fechaFin = this.DaysInMonths[month];
          if (row.fechaIni > row.fechaFin) { //mirar qué fecha debe ir primero, just in case
            //están al revés, corregirlas
            var aux = new Date(row.fechaIni.getTime());
            row.fechaIni = new Date(row.fechaFin.getTime());
            row.fechaFin = aux;
          }
          row.mostrarCalendario = false; //Si ya tenemos las dos fechas, que lo cierre
          //Y que lo añada com que ya se ha cambiado la fecha, como es obligatoria, nunca saldrá de ese div, solo se vuelve a incluir para destacar el cambio con la animación
          // this.CambioFiltro();
        }
        else {
          //en este caso había dos y se brran para empezar a seleccionar otra vez
          //por tanto, quitamos el tag hasta que se hayan seleccionado las dos
          row.fechaIni = this.DaysInMonths[month];
          row.fechaFin = undefined;
        }
      }
    }
  }
  //    Función para avanzar o ir atrás en los meses del calendario
  cambiarMeses(value) {
    if (value == -1) {
      (document.getElementById("calendario-0").getElementsByTagName("kendo-calendar-header")[0].children[2].children[0] as any).click();
    } else if (value == 1) {
      (document.getElementById("calendario-0").getElementsByTagName("kendo-calendar-header")[0].children[2].children[2] as any).click();
    }
  }
  //    Función para que el calendario se muestre o no
  showCalendar(row) {
    if (!row.mostrarCalendario) {
      row.mostrarCalendario = true;
    }
    else {
      row.mostrarCalendario = false;
      if (row.fechaIni != undefined && row.fechaFin == undefined) {
        row.fechaFin = row.fechaIni;
      }
      else if (row.fechaIni == undefined && row.fechaFin == undefined) {
        row.fechaIni = this.myFunctions.getDateNow();
        row.fechaFin = row.fechaIni;
      }

    }
    // this.mostrarCalendario = true; // activa el click de fondo!
  }
  //    Función para inicializar los meses del calendario
  cargarMeses() {
    this.DaysInMonths = [];
    //Necesitamos inicializar los meses para que se pongan en su día correcto, los pondremos en el día actual y el primer día del mes siguiente
    this.DaysInMonths.push(this.myFunctions.getDateNow());
  }
  //    Función para pintar del color adecuado el periodo seleccionado
  isDateSelected(row, date) {
    if (row.fechaIni == undefined && row.fechaFin == undefined) {
      return false;
    } else if (row.fechaIni != undefined && row.fechaFin == undefined) {
      return date.getFullYear() == row.fechaIni.getFullYear() && date.getMonth() == row.fechaIni.getMonth() && date.getDate() == row.fechaIni.getDate();
    } else if (row.fechaIni != undefined && row.fechaFin != undefined) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate()) >= new Date(row.fechaIni.getFullYear(), row.fechaIni.getMonth(), row.fechaIni.getDate()) &&
        new Date(date.getFullYear(), date.getMonth(), date.getDate()) <= new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth(), row.fechaFin.getDate());
    }
  };
  //    Botones filtro fechas, (los botones que hay a la derecha del calendario)
  ultimas24HButton(row) {
    var today = this.myFunctions.getDateNow();
    row.fechaIni = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    row.fechaFin = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false; // quita el click de fondo!
  }
  ultimos7DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth(), row.fechaFin.getDate() - 6);
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false;// quita el click de fondo!
  }
  ultimos30DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth() - 1, row.fechaFin.getDate());
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false;// quita el click de fondo!
  }
  ultimos60DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth() - 2, row.fechaFin.getDate());
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false;// quita el click de fondo!
  }

  // ESTA FUNCION CAMBIA SEGUN EL FILTRO!
  cargar_Filtro() {
    // Dentro de esta funcion se meteran todas las cargas de combos, fechas... que necesite el filtro para funcionar.

    // Como cal definir el filtro no se pueden poner las fechas de lunes a domingo, se hace al cargar el filtro, se actualiza el filtro por defecto
    var fechaIni;
    var fechaInicio;
    var fechaFin;

    //calcular fecha inicio
    if (fechaInicio == undefined) {
      fechaInicio = this.myFunctions.getDateNow()
    }
    fechaIni = fechaInicio
    if (fechaIni.getDay() == 3) {
      fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 2));
    } else if (fechaIni.getDay() == 4) {
      fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 3));
    } else if (fechaIni.getDay() == 5) {
      fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 4));
    } else if (fechaIni.getDay() == 6) {
      fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 5));
    } else if (fechaIni.getDay() == 0) {
      fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 6));
    } else if (fechaIni.getDay() == 2) {
      fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 1));
    }

    //calcular fecha fin
    fechaIni = new Date(fechaInicio);
    fechaFin = new Date(fechaIni.setDate(fechaIni.getDate() + 6));

    //calcular horas
    fechaInicio.setHours(0, 0, 0);
    fechaFin.setHours(0, 0, 0);
    this.filtroPorDefecto = {
      logic: { id: 1, nombre: this.translateService.instant('o') },
      group: [
        {
          logic: { id: 0, nombre: this.translateService.instant('y') },
          group: [
            {
              columna: { id: 1, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },
              operator: { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
              fechaIni: fechaInicio,
              fechaFin: fechaFin,
              mostrarCalendario: false,
              text: '',
              numberMin: 0,
              numberMax: 0,
              decimalformat: '0.000',
              decimalMin: 0.0,
              decimalMax: 0.0,
              check: false,
              combo: [{ id: 1, nombre: "" }],
              comboSelected: {},
              comboSelecteds: []
            },
            {
              columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
              operator: { id: 0, nombre: '' },
              fechaIni: fechaInicio,
              fechaFin: fechaFin,
              mostrarCalendario: false,
              text: '',
              numberMin: 0,
              numberMax: 0,
              decimalformat: '0.000',
              decimalMin: 0.0,
              decimalMax: 0.0,
              check: false,
              combo: [{ id: 1, nombre: "" }],
              comboSelected: {},
              comboSelecteds: []
            }
          ]
        }
      ]
    };


    this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);

    var r1, r2, r3, r4: boolean = false;
    //FECHAS
    this.cargarMeses();

    //TURNOS
    this.filtro_listaTurnos = [
      { nombreTurno: this.translateService.instant("manana"), idTurno: 1 },
      { nombreTurno: this.translateService.instant("tarde"), idTurno: 2 },
      { nombreTurno: this.translateService.instant("noche"), idTurno: 3 }
    ];

    //OPERARIOS
    this.informeProyectosService.Get_Operarios_simple().pipe(first()).subscribe((data: any) => {
      this.filtro_listaOperarios = data;
      this.filtro_listaOperarios.sort((a, b) => (a.nombreOperario > b.nombreOperario) ? 1 : ((b.nombreOperario > a.nombreOperario) ? -1 : 0));

      r3 = true;
      if (r1 && r2 && r3 && r4) this.cargarConTodasLasRespuestas();
    });

    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
      this.seccionesSeleccionadas.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    }
    else {
      this.secciones.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    }
    //FLTRO POR GRUPOS
    var idsGruposSelecteds: any = [];
    if (this.gruposSeleccionados) {
      this.gruposSeleccionados.forEach(
        grupo => {
          idsGruposSelecteds.push(grupo.id.toString()); // se pasa a string para hacer la comparacion
        });
    }
    //MAQUINAS    
    this.filtro_listaMaquinas = this.maquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) && idsGruposSelecteds.some(r => f.idsGrupos.split(",").map(Number).includes(r))));
    this.filtro_listaMaquinas = this.maquinas.filter(f => {
      var enGrupo = false
      f.idsGrupos.split(',').forEach(
        idGrupo => {
          enGrupo = enGrupo || idsGruposSelecteds.includes(idGrupo);
        });
      return ((idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0) && (enGrupo || idsGruposSelecteds.length == 0));
    });

    //HERRAMIENTAS
    this.informeProyectosService.Get_Herramientas().pipe(first()).subscribe(
      (data: any) => {
        this.filtro_listaHerramientas = data;
        this.filtro_listaHerramientas.sort((a, b) => (a.nombreHerramienta > b.nombreHerramienta) ? 1 : ((b.nombreHerramienta > a.nombreHerramienta) ? -1 : 0));
        r2 = true;
        if (r1 && r2 && r3 && r4) this.cargarConTodasLasRespuestas();
      });

    //CLIENTES, PIEZAS, OFS, OPERACIONES Y PARTES
    this.informeProyectosService.Get_ClientesPiezasOfsOperacionesPartes_simple().pipe(first()).subscribe(
      (data: any) => {
        this.dataFiltro = data;

        r4 = true;
        if (r1 && r2 && r3 && r4) this.cargarConTodasLasRespuestas();

      });

    //SI ocultarParte=1 NO ENSEÑAR EL MULTISELECT DE PARTES

    if (!this.user.ocultarParte) this.filtro_ocultarPartes = false;
    if (this.user.ocultarParte) this.filtro_ocultarPartes = true;

    // se quita parte de las opciones seleccionables directamente
    if (this.filtro_ocultarPartes)
      this.columnasFiltro = [
        { id: 2, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },
        { id: 3, nombre: this.translateService.instant('turno'), field: "idTurno", sqlfield: "hb.tipoTurno", tipo: 'comboEstrincto' },
        { id: 4, nombre: this.translateService.instant('seccion'), field: "idSeccion", sqlfield: "maq.idSeccion", tipo: 'comboEstrincto' },
        { id: 5, nombre: this.translateService.instant('grupoMaquinas'), field: "idGrupo", sqlfield: "mgm.idMaquinasGrupo", tipo: 'comboEstrincto' },
        { id: 6, nombre: this.translateService.instant('maquina'), field: "idMaquina", sqlfield: "hb.idMaquina", tipo: 'comboEstrincto' },
        { id: 7, nombre: this.translateService.instant('operario'), field: "idOperario", sqlfield: "hb.idOperario", tipo: 'comboEstrincto' },
        { id: 8, nombre: this.translateService.instant('of'), field: "nombreOf", sqlfield: "po.numeroOF", tipo: 'comboFlexible' },
        { id: 9, nombre: this.translateService.instant('cliente'), field: "nombreCliente", sqlfield: "po.nombreCliente", tipo: 'comboFlexible' },
        { id: 10, nombre: this.translateService.instant('pieza'), field: "nombrePieza", sqlfield: "po.nombrePieza", tipo: 'comboFlexible' },
        { id: 11, nombre: this.translateService.instant('nserie'), field: "nSerie", sqlfield: "ho.nSerie", tipo: 'string' },
        { id: 13, nombre: this.translateService.instant('operacion'), field: "nombreOperacion", sqlfield: "po.nombreOperacion", tipo: 'comboFlexible' },
        { id: 14, nombre: this.translateService.instant('terminado'), field: "terminado", sqlfield: "po.operacionTerminada", tipo: 'check' }
      ];

    r1 = true;
    if (r1 && r2 && r3 && r4) this.cargarConTodasLasRespuestas();

  }
  preFiltrado(filtro, row) {
    // si es un COMBO lo cargamos
    if (row.columna.tipo == "comboEstrincto" || row.columna.tipo == "comboFlexible") {
      // cargamos los datos filtrados que NO sean de esta linea.   
      var dataFiltroLag;
      dataFiltroLag = this.dataFiltro;

      // borrar la seleccion actual
      row.comboSelected = {};
      row.comboSelecteds = [];

      // cargar los combos
      if (row.columna.id == 3) { // turnos
        row.combo = this.filtro_listaTurnos;
      }
      else if (row.columna.id == 4) { // seccion
        row.combo = this.groupedSeccion; // OK
      }
      else if (row.columna.id == 5) { // grupo de maquinas
        row.combo = this.grupos.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0)); // OK
      }
      else if (row.columna.id == 6) { // maquinas FILTRO!
        row.combo = this.filtro_listaMaquinas.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0)); // OK
      }
      else if (row.columna.id == 7) { // operarios 
        row.combo = this.filtro_listaOperarios.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
      }

      // cargar desde dataFiltroLag = filtrado(this.dataFiltro)
      else if (row.columna.id == 8) { // OF FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          of => {
            if (!lag.includes(of.nombreOf)) {
              lag.push(of.nombreOf);
              var js = { id: of.nombreOf, nombre: of.nombreOf };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
      }
      else if (row.columna.id == 9) { // clientes FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          cliente => {
            if (!lag.includes(cliente.nombreCliente)) {
              lag.push(cliente.nombreCliente);
              var js = { id: cliente.nombreCliente, nombre: cliente.nombreCliente };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 10) { // piezas FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          pieza => {
            if (!lag.includes(pieza.nombrePieza)) {
              lag.push(pieza.nombrePieza);
              var js = { id: pieza.nombrePieza, nombre: pieza.nombrePieza };
              combo.push(js);
            }

          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 11) { // N series FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          nSerie => {
            if (!lag.includes(nSerie.nSerie)) {
              lag.push(nSerie.nSerie);
              var js = { id: nSerie.nSerie, nombre: nSerie.nSerie };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 12) { // partes FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          parte => {
            if (!lag.includes(parte.nombreParte)) {
              lag.push(parte.nombreParte);
              var js = { id: parte.nombreParte, nombre: parte.nombreParte };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 13) { // operaciones FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          operacion => {
            if (!lag.includes(operacion.nombreOperacion)) {
              lag.push(operacion.nombreOperacion);
              var js = { id: operacion.nombreOperacion, nombre: operacion.nombreOperacion };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
      }
    }
  }
  refiltrarFiltro(filtro) {
    // ESTA FUNCION SE EJECUTA CUANDO SE CAMBIA LA COLUMNA DE TIPO DE FILTRADO!
    // DE:
    //  - AND --> OR
    //  - OR  --> AND
  }
  onFilter() {
    // si se miran las proximas funciones se vera como se aplica el filtro. 
    // aqui hay un ejemplo de todos modos:    
    //         var filtroFechas: any = this.filtroToSQL(['hb.fechaTurno']); // filtro solo Fechas
    //         filtroFechas = filtroFechas.replace(/hb.fechaTurno(.*?)hb.fechaTurno/g, 'fechaMod$1fechaCreado');
    //         var filtroCompleto: any = this.filtroToSQL(); // filtro completo

    this.cargarDatosInforme();
    this.cargarGraficosPerdidas();
    this.status = true;
    this.actualizarVisible = false;
  }
  cargarFiltroURL() {
    
    // cuando existe fechaIni/Fin, idPieza e idOF
    var fini = this.route.snapshot.params['fini'];
    var ffin = this.route.snapshot.params['ffin'];
    var idpieza = this.route.snapshot.params['idpieza'];
    var idof = this.route.snapshot.params['idof'];

    // Las lineas con '/**/' son lineas de antes!, para ver como se cargarian ahora en el nuevo filtro
    // SE PUEDE FILTRAR DESDE HISTORICO PIEZAS o INFORME PROYECTOS
    if (Number.parseInt(this.route.snapshot.params['idHistoricoPieza']) > 0) {
      // Historico Piezas / Informe Proyectos:
      //   - idHistoricoPiezas *fijo*
      //   - idHistoricoOperaciones (posible extra)

      // EN ESTE CASO, EL FILTRO REQUIERE UNA CONSULTA. Por eso se cargan las fechas y el filtro en otra funcion aparte despues de recibir las 2 respuestas
      var r1, r2 = false;
      // HISTORICO PIEZAS??
      /**/var IdHistorico = Number.parseInt(this.route.snapshot.params['idHistoricoPieza']);
      this.historicoOperacionesService.Get_nSerie(IdHistorico).subscribe(
        (json) => {
          var an: any = json
          if (an.length > 0) {
            this.idpieza_prefiltro = an[0].idPieza;
            this.idof_prefiltro = an[0].idOF;
            this.nSerie_prefiltro = an[0].nSerie;
          }
          r1 = true;
          if (r1 && r2)
            this.cargarFiltroURL_postConsulta();
        });
      // HISTORICO OPERACIONES??
      /**/var IdHistoricoOp = Number.parseInt(this.route.snapshot.params['idHistoricoOperacion']);
      this.historicoOperacionesService.Get_operacin_HO(IdHistoricoOp).subscribe(
        (json) => {
          var an: any = json
          if (an.length > 0) {
            this.idOperacion_prefiltro = an[0].idOFs_Operacion;
          }
          r2 = true;
          if (r1 && r2)
            this.cargarFiltroURL_postConsulta();
        });
    }
    else if (fini != undefined && ffin != undefined && idpieza != undefined && idof != undefined) {
      this.idpieza_prefiltro = idpieza;
      this.idof_prefiltro = idof;
      this.cargarFiltroURL_postConsulta();
    }
    else {
      // Acutalizamos el filtro
      this.datosFiltro = this.filtroPorDefecto;
      // Autofiltramos el informe
      this.onFilter();
    }

  }
  cargarFiltroURL_postConsulta() {
    // Esta funcion existe para cargar los filtros previos que requieren una consulta a la DB antes de poder ser aplicados
    // cargaremos el filtro en una variable para despues actualizar el filtro    
    var datosFiltro = {
      logic: { id: 1, nombre: this.translateService.instant('o') },
      group: [
        {
          logic: { id: 0, nombre: this.translateService.instant('y') },
          group: []
        }
      ]
    };
    // FECHAS (INICIO, FIN)
    /**/this.fini = this.route.snapshot.params['fini'];
    /**/this.ffin = this.route.snapshot.params['ffin'];
    if (this.route.snapshot.params['fini'] != '0' && this.route.snapshot.params['ffin'] != '0'
      && this.route.snapshot.params['fini'] != undefined && this.route.snapshot.params['ffin'] != undefined) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 2, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },
          operator: { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
          fechaIni: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['fini']),
          fechaFin: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['ffin']),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
    }
    // OF
    var rowOFs = this.dataFiltro.filter(x => x.idOf == this.idof_prefiltro);
    if (rowOFs.length > 0) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 8, nombre: this.translateService.instant('of'), field: "nombreOf", sqlfield: "po.numeroOF", tipo: 'comboFlexible' },
          operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
      // Se carga el combo de clientes (esto limpia la seleccion)
      this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
      // Se selecciona el que queremos
      var of = { id: rowOFs[0].nombreOf, nombre: rowOFs[0].nombreOf }
      datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [of];
    }
    this.idof_prefiltro = 0;
    // PIEZA
    var rowPiezas = this.dataFiltro.filter(x => x.idPieza == this.idpieza_prefiltro);
    if (rowPiezas.length > 0) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 10, nombre: this.translateService.instant('pieza'), field: "nombrePieza", sqlfield: "po.nombrePieza", tipo: 'comboFlexible' },
          operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
      // Se carga el combo de clientes (esto limpia la seleccion)
      this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
      // Se selecciona el que queremos
      var pieza = { id: rowPiezas[0].nombrePieza, nombre: rowPiezas[0].nombrePieza }
      datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [pieza];
    }
    this.idpieza_prefiltro = 0;
    // N SERIE
    if (this.nSerie_prefiltro != "") {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 11, nombre: this.translateService.instant('nserie'), field: "nSerie", sqlfield: "ho.nSerie", tipo: 'string' },
          operator: { id: 7, nombre: this.translateService.instant('igualQue'), dobleValor: false },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: this.nSerie_prefiltro,
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
    }
    this.nSerie_prefiltro = "";
    // OPERACION
    var rowOperaciones = this.dataFiltro.filter(x => x.idOperacion == this.idOperacion_prefiltro);
    if (rowOperaciones.length > 0) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 13, nombre: this.translateService.instant('operacion'), field: "nombreOperacion", sqlfield: "po.nombreOperacion", tipo: 'comboFlexible' },
          operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
      // Se carga el combo de clientes (esto limpia la seleccion)
      this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
      // Se selecciona el que queremos
      var operacion = { id: rowOperaciones[0].nombreOperacion, nombre: rowOperaciones[0].nombreOperacion }
      datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [operacion];
    }
    this.idOperacion_prefiltro = 0;
    // Si hay filtrado
    if (datosFiltro.group[0].group.length > 0) {
      // Annadimos la ultima linea al filtro
      datosFiltro.group[0].group.push(
        {
          columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
          operator: { id: 0, nombre: '' },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
      // Acutalizamos el filtro
      this.datosFiltro = datosFiltro;
      // Autofiltramos el informe
      this.onFilter();
    }
  }
  // END FILTRO----------------------------------------------------------------------------------------------------------------------------------------

  // CONSULTAS INFORME
  cargarDatosInforme() {
    var filtroFechas: any = this.filtroToSQL(['hb.fechaTurno']); // filtro solo Fechas
    filtroFechas = filtroFechas.replace(/hb.fechaTurno(.*?)hb.fechaTurno/g, 'fechaMod$1fechaCreado');

    var filtroCompleto: any = this.filtroToSQL(); // filtro completo

    this.datosVisibles = true;

    this.loadingDatos = true;
    this.loadingPerdidas = true;
    (<HTMLInputElement>document.getElementById('checkboxGrupos')).disabled = true;

    this.informeProyectosService.Get_Informe_Of_Cliente_Pieza_Avanzado_filtrado(filtroFechas, filtroCompleto).pipe(first()).subscribe(
      (data: any) => {

        this.loadingDatos = false;

        this.dataInforme = data;


        console.log(this.dataInforme);

        var dataInformeGroupByHistoricoOperacion = [];
        //GROUP BY POR HISTORICO OPERACION
        this.dataInforme.forEach(function (a) {
          if (!this[a.idHistoricoOperaciones]) {
            this[a.idHistoricoOperaciones] = {
              idHistoricoOperaciones: a.idHistoricoOperaciones, operacion: a.operacion, tEjecucion: 0, tParadaDisponibilidad: 0, tMicroParadaDisponibilidad: 0, tMantenimientoDisponibilidad: 0, tAlarmaDisponibilidad: 0,
              tApagadaDisponibilidad: 0, tParadaRendimiento: 0, tMicroParadaRendimiento: 0, tMantenimientoRendimiento: 0, tAlarmaRendimiento: 0, tApagadaRendimiento: 0, tParadaCalidad: 0, tMicroParadaCalidad: 0,
              tMantenimientoCalidad: 0, tAlarmaCalidad: 0, tApagadaCalidad: 0, tNegativoCalidad: 0, tEstimado: 0, tEstimadoGlobal: 0, tiempoPredictivoGlobal: 0,
              avanceAcumulado: 0, consumoAcumulado: 0, cambioPlacas: 0, roturaHerramientas: 0, tTotal: 0, tTotalHistoricoOperacion: 0, tTotalHistoricoOperacion_sinDispo: 0, terminado: a.terminado,
              cantidadTotal: a.cantidadTotal, piezaTerminadas: a.piezaTerminadas            
            };

            dataInformeGroupByHistoricoOperacion.push(this[a.idHistoricoOperaciones]);
          }
          this[a.idHistoricoOperaciones].tEjecucion += a.tEjecucion;
          this[a.idHistoricoOperaciones].tParadaDisponibilidad += a.tParadaDisponibilidad;
          this[a.idHistoricoOperaciones].tMicroParadaDisponibilidad += a.tMicroParadaDisponibilidad;
          this[a.idHistoricoOperaciones].tMantenimientoDisponibilidad += a.tMantenimientoDisponibilidad;
          this[a.idHistoricoOperaciones].tAlarmaDisponibilidad += a.tAlarmaDisponibilidad;
          this[a.idHistoricoOperaciones].tApagadaDisponibilidad += a.tApagadaDisponibilidad;
          this[a.idHistoricoOperaciones].tParadaRendimiento += a.tParadaRendimiento;
          this[a.idHistoricoOperaciones].tMicroParadaRendimiento += a.tMicroParadaRendimiento;
          this[a.idHistoricoOperaciones].tMantenimientoRendimiento += a.tMantenimientoRendimiento;
          this[a.idHistoricoOperaciones].tAlarmaRendimiento += a.tAlarmaRendimiento;
          this[a.idHistoricoOperaciones].tApagadaRendimiento += a.tApagadaRendimiento;
          this[a.idHistoricoOperaciones].tParadaCalidad += a.tParadaCalidad;
          this[a.idHistoricoOperaciones].tMicroParadaCalidad += a.tMicroParadaCalidad;
          this[a.idHistoricoOperaciones].tMantenimientoCalidad += a.tMantenimientoCalidad;
          this[a.idHistoricoOperaciones].tAlarmaCalidad += a.tAlarmaCalidad;
          this[a.idHistoricoOperaciones].tApagadaCalidad += a.tApagadaCalidad;
          this[a.idHistoricoOperaciones].tNegativoCalidad += a.tNegativoCalidad;
          this[a.idHistoricoOperaciones].tEstimado += a.tEstimado;
          this[a.idHistoricoOperaciones].tEstimadoGlobal = Math.max(this[a.idHistoricoOperaciones].tEstimadoGlobal, a.tEstimadoGlobal);
          this[a.idHistoricoOperaciones].tiempoPredictivoGlobal = Math.max(this[a.idHistoricoOperaciones].tiempoPredictivoGlobal, a.tiempoPredictivoGlobal);
          this[a.idHistoricoOperaciones].avanceAcumulado += a.avanceAcumulado;
          this[a.idHistoricoOperaciones].consumoAcumulado += a.consumoAcumulado;
          this[a.idHistoricoOperaciones].cambioPlacas += a.cambioPlacas;
          this[a.idHistoricoOperaciones].roturaHerramientas += a.roturaHerramientas;
          this[a.idHistoricoOperaciones].tTotal += a.tTotal;
          this[a.idHistoricoOperaciones].tTotalHistoricoOperacion += a.tTotalHistoricoOperacion;
          this[a.idHistoricoOperaciones].tTotalHistoricoOperacion_sinDispo += a.tTotalHistoricoOperacion_sinDispo;
          this[a.idHistoricoOperaciones].terminado = this[a.idHistoricoOperaciones].terminado && a.terminado;
        }, Object.create(null));

        //FOREACH CON EL IF DE TIEMPO ESTIMADO
        //Si tReal de la operacion recibida < tReal operacion total (operacion cortada por fecha): usar tEstimado ficticio
        //Si tReal de la operacion recibida = tReal operacion total (operacion no cortada por fecha):
        //  Si terminada=1: usar tEstimado global
        //  Si terminada=0:
        //    Si tReal de la operacion recibida <= tEstimado: tEst=tReal
        //    Si tReal de la operacion recibida > tEstimado: tEst=tEst
        dataInformeGroupByHistoricoOperacion.forEach(function (a) {
          if (a.tEjecucion < a.tTotalHistoricoOperacion_sinDispo) {//(operacion cortada por fecha)
            a.tEstimadoFinal = a.tEstimado;
          } else {//(operacion no cortada por fecha)
            if (a.terminado) {
              a.tEstimadoFinal = a.tEstimadoGlobal;
            } else {
              if (a.tEjecucion <= a.tEstimadoGlobal) {
                a.tEstimadoFinal = a.tEjecucion;
              } else {
                a.tEstimadoFinal = a.tEstimadoGlobal;
              }
            }
          }
          if (a.tEstimadoFinal > a.tEjecucion)
            a.tEstimadoFinal = a.tEjecucion
        });

        this.dataInformeGroupByHistoricoOperacion = dataInformeGroupByHistoricoOperacion;

        //Ponemos estos valores a 0 para mandar todos los grids a la primera pagina
        this.skipOperaciones = 0;
        this.skipMaquinas = 0;
        this.skipOperarios = 0;
        this.skipPiezas = 0;
        this.skipClientes = 0;

        this.cargarDatosGridOperaciones();
        this.cargarDatosGridMaquinas();
        this.cargarDatosGridOperarios();
        this.cargarDatosGridPiezas();
        this.cargarDatosGridClientes();

        this.cargarDatosPanelIzquierdo();

        if (this.dataInforme.length > 0) {

          this.tieneDatos = true;

          this.cargarGraficosDonuts();
          this.cargarGraficosRendimientoCalidad();

          this.cargarGraficoOperaciones();
          this.cargarGraficoMaquinas();
          this.cargarGraficoOperarios();
          this.cargarGraficoPiezas();
          this.cargarGraficoClientes();

        } else {
          this.tieneDatos = false;
        }

      });
  }

  cargarGraficosPerdidas() {
    var filtroCompleto: any = this.filtroToSQL(); // filtro completo

    if (this.agrupado == 2) { //si las pérdidas están agrupadas tomar los datos de las pérdidas agrupadas   
      this.informeOeeService.Get_Perdidas_PorGrupo_ConFiltros_filtrado(filtroCompleto, this.translateService.instant("sinAgrupar"), this.translateService.instant("sinAsignar")).subscribe(data => {
        document.getElementById('lblNoParadas_aae').style.display = 'block';
        document.getElementById('checkboxGrupos').style.display = 'none';
        document.getElementById('chartDonutParadas_aae').style.display = 'none';

        var dataPerdidas: any = data;

        var donutDataParadas: any = [];

        dataPerdidas.forEach(function (obj) {
          //PARADAS (idProcesos_Tipo = 1, 2)
          if (obj.tiempoPerdida > 0) {
            this.dataGraficoDonutParadasParaTooltip[this.cortarLeyenda(obj.nombrePerdida)] = obj.nombrePerdida;
            donutDataParadas.push([this.cortarLeyenda(obj.nombrePerdida), obj.tiempoPerdida]);
            document.getElementById('lblNoParadas_aae').style.display = 'none';
            document.getElementById('checkboxGrupos').style.display = 'block';
            document.getElementById('chartDonutParadas_aae').style.display = 'block';
          }

        }, this);

        var oldDataGraficoDonutParadas = this.dataGraficoDonutParadas;
        this.dataGraficoDonutParadas = donutDataParadas.sort(function (a, b) { return b[1] - a[1]; });
        this.dataGraficoDonutParadas = this.acortarDatosGraficoDonuts(this.dataGraficoDonutParadas);

        this.updateDonutLeyenda(this.graficoDonutParadas, oldDataGraficoDonutParadas, this.dataGraficoDonutParadas.sort(function (a, b) { return b[1] - a[1]; }));

        d3.selectAll("#chartDonutParadas_aae .c3-chart-arcs path").style("stroke-width", "0px");
        this.loadingPerdidas = false;
        (<HTMLInputElement>document.getElementById('checkboxGrupos')).disabled = false;

      });
    }
    else if (this.agrupado == 1) { //si las pérdidas están agrupadas por perdida padre   
      this.informeOeeService.Get_Perdidas_PorPadre_ConFiltros_filtrado(filtroCompleto, this.translateService.instant("sinAsignar")).subscribe(data => {
        document.getElementById('lblNoParadas_aae').style.display = 'block';
        document.getElementById('checkboxGrupos').style.display = 'none';
        document.getElementById('chartDonutParadas_aae').style.display = 'none';

        var dataPerdidas: any = data;

        var donutDataParadas: any = [];

        dataPerdidas.forEach(function (obj) {
          //PARADAS (idProcesos_Tipo = 1, 2)
          if (obj.tiempoPerdida > 0) {
            this.dataGraficoDonutParadasParaTooltip[this.cortarLeyenda(obj.nombrePerdida)] = obj.nombrePerdida;
            donutDataParadas.push([this.cortarLeyenda(obj.nombrePerdida), obj.tiempoPerdida]);
            document.getElementById('lblNoParadas_aae').style.display = 'none';
            document.getElementById('checkboxGrupos').style.display = 'block';
            document.getElementById('chartDonutParadas_aae').style.display = 'block';
          }

        }, this);

        var oldDataGraficoDonutParadas = this.dataGraficoDonutParadas;
        this.dataGraficoDonutParadas = donutDataParadas.sort(function (a, b) { return b[1] - a[1]; });
        this.dataGraficoDonutParadas = this.acortarDatosGraficoDonuts(this.dataGraficoDonutParadas);

        this.updateDonutLeyenda(this.graficoDonutParadas, oldDataGraficoDonutParadas, this.dataGraficoDonutParadas.sort(function (a, b) { return b[1] - a[1]; }));

        d3.selectAll("#chartDonutParadas_aae .c3-chart-arcs path").style("stroke-width", "0px");
        this.loadingPerdidas = false;
        (<HTMLInputElement>document.getElementById('checkboxGrupos')).disabled = false;

      });
    }

    this.informeOeeService.Get_Perdidas_Todas_ConFiltros_filtrado(filtroCompleto, this.translateService.instant("sinAsignar")).subscribe(data => {
      if (this.agrupado == 0) { //si no están agrupadas las pérdidas tomar los datos de las pérdidas sin agrupar
        document.getElementById('lblNoParadas_aae').style.display = 'block';
        document.getElementById('checkboxGrupos').style.display = 'none';
        document.getElementById('chartDonutParadas_aae').style.display = 'none';
        var donutDataParadas: any = [];
      }
      document.getElementById('lblNoMantenimientos_aae').style.display = 'block';
      document.getElementById('lblNoAlarmas_aae').style.display = 'block';


      document.getElementById('chartDonutMantenimientos_aae').style.display = 'none';
      document.getElementById('chartDonutAlarmas_aae').style.display = 'none';

      var dataPerdidas: any = data;

      var donutDataMantenimientos: any = [];
      var donutDataAlarmas: any = [];

      dataPerdidas.forEach(function (obj) {
        //PARADAS (idProcesos_Tipo = 1, 2)
        if ((obj.idProcesos_Tipo == 1 || obj.idProcesos_Tipo == 2) && obj.tiempoPerdida > 0 && this.agrupado == 0) {//si no están agrupadas las pérdidas tomar los datos de las pérdidas sin agrupar
          this.dataGraficoDonutParadasParaTooltip[this.cortarLeyenda(obj.nombrePerdida)] = obj.nombrePerdida;
          donutDataParadas.push([this.cortarLeyenda(obj.nombrePerdida), obj.tiempoPerdida]);
          document.getElementById('lblNoParadas_aae').style.display = 'none';
          document.getElementById('checkboxGrupos').style.display = 'block';
          document.getElementById('chartDonutParadas_aae').style.display = 'block';
        }

        //MANTENIMIENTOS (idProcesos_Tipo = 4, 10)
        if ((obj.idProcesos_Tipo == 4 || obj.idProcesos_Tipo == 10) && obj.tiempoPerdida > 0) {
          this.dataGraficoDonutAlarmasParaTooltip[this.cortarLeyenda(obj.nombrePerdida)] = obj.nombrePerdida;
          donutDataMantenimientos.push([this.cortarLeyenda(obj.nombrePerdida), obj.tiempoPerdida]);
          document.getElementById('lblNoMantenimientos_aae').style.display = 'none';
          document.getElementById('chartDonutMantenimientos_aae').style.display = 'block';
        }

        //ALARMAS (idProcesos_Tipo = 6, 7)
        if ((obj.idProcesos_Tipo == 6 || obj.idProcesos_Tipo == 7) && obj.tiempoPerdida > 0) {
          this.dataGraficoDonutAlarmasParaTooltip[this.cortarLeyenda(obj.nombrePerdida)] = obj.nombrePerdida;
          donutDataAlarmas.push([this.cortarLeyenda(obj.nombrePerdida), obj.tiempoPerdida]);
          document.getElementById('lblNoAlarmas_aae').style.display = 'none';
          document.getElementById('chartDonutAlarmas_aae').style.display = 'block';
        }

      }, this);

      if (this.agrupado == 0) { //si no están agrupadas las pérdidas tomar los datos de las pérdidas sin agrupar
        var oldDataGraficoDonutParadas = this.dataGraficoDonutParadas;
        this.dataGraficoDonutParadas = donutDataParadas.sort(function (a, b) { return b[1] - a[1]; });
        this.dataGraficoDonutParadas = this.acortarDatosGraficoDonuts(this.dataGraficoDonutParadas);
        this.updateDonutLeyenda(this.graficoDonutParadas, oldDataGraficoDonutParadas, this.dataGraficoDonutParadas.sort(function (a, b) { return b[1] - a[1]; }));
        d3.selectAll("#chartDonutParadas_aae .c3-chart-arcs path").style("stroke-width", "0px");
      }

      var oldDataGraficoDonutMantenimientos = this.dataGraficoDonutMantenimientos;
      this.dataGraficoDonutMantenimientos = donutDataMantenimientos.sort(function (a, b) { return b[1] - a[1]; });
      this.dataGraficoDonutMantenimientos = this.acortarDatosGraficoDonuts(this.dataGraficoDonutMantenimientos);

      var oldDataGraficoDonutAlarmas = this.dataGraficoDonutAlarmas;
      this.dataGraficoDonutAlarmas = donutDataAlarmas.sort(function (a, b) { return b[1] - a[1]; });
      this.dataGraficoDonutAlarmas = this.acortarDatosGraficoDonuts(this.dataGraficoDonutAlarmas);


      this.updateDonutLeyenda(this.graficoDonutMantenimientos, oldDataGraficoDonutMantenimientos, this.dataGraficoDonutMantenimientos.sort(function (a, b) { return b[1] - a[1]; }))
      this.updateDonutLeyenda(this.graficoDonutAlarmas, oldDataGraficoDonutAlarmas, this.dataGraficoDonutAlarmas.sort(function (a, b) { return b[1] - a[1]; }))


      d3.selectAll("#chartDonutMantenimientos_aae .c3-chart-arcs path").style("stroke-width", "0px");
      d3.selectAll("#chartDonutAlarmas_aae .c3-chart-arcs path").style("stroke-width", "0px");

      this.loadingPerdidas = false;
      (<HTMLInputElement>document.getElementById('checkboxGrupos')).disabled = false;

    });

    this.loadingPerdidas = false;
  }

  public checkboxChange(tipo: number): void { //Función para saber si están o no agrupadas las pérdidas

    this.agrupado = tipo;

    this.cargarGraficosPerdidas();
  }

  cargarGrupos() {
    this.maquinasService.getGruposMaquinas().subscribe(json => {
      this.grupos = json.data;
      this.gruposCargados = true;
      if (this.gruposCargados && this.seccionesCargadas)
        this.cargar_Filtro();
    });

  }

  cargarAreasProductivas() {

    var an1: any = this.userService.secciones;
    this.secciones = undefined;

    if (an1 != undefined) this.secciones = an1.filter(f => f.activo === true);

    if (this.secciones == undefined) {

      this.userService.getSecciones().subscribe(json => {
        this.userService.secciones = json;
        //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
        var an1: any = this.userService.secciones;
        this.secciones = an1.filter(f => f.activo === true);

        var an: any = this.secciones;
        this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
        an.forEach(row => {
          if (row.activo) this.seccionesSeleccionadas.push(row);
        });

        this.cargarMaquinas();

      });

    } else {

      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

      an.forEach(row => {
        if (row.activo) this.seccionesSeleccionadas.push(row);
      });

      this.cargarMaquinas();

    }

  }

  cargarMaquinas() {

    var r1: boolean = false;

    //MAQUINAS
    var maquinas_model = this.maquinasService.get_maquinas_model();
    if (maquinas_model == false) {
      this.maquinasService.get().subscribe(json => {
        this.maquinasService.set_maquinas_model(json);
        this.maquinas = this.maquinasService.get_maquinas_model();
        this.seccionesCargadas = true;
        r1 = true;
        if (r1) this.maquinas = this.maquinas.concat(this.instalaciones);
        if (this.gruposCargados && this.seccionesCargadas && r1) {
          this.maquinas.forEach(
            maquina => {
              maquina.idMaquina = maquina.id;
              maquina.nombreMaquina = maquina.nombre;
            });
          this.cargar_Filtro();
        }
      })
    } else {
      this.maquinas = maquinas_model;
      this.seccionesCargadas = true;
      r1 = true;
      if (r1) this.maquinas = this.maquinas.concat(this.instalaciones);
      if (this.gruposCargados && this.seccionesCargadas && r1) {
        this.maquinas.forEach(
          maquina => {
            maquina.idMaquina = maquina.id;
            maquina.nombreMaquina = maquina.nombre;
          });
        this.cargar_Filtro();
      }
    }

    //INSTALACIONES
    //var instalaciones_model = this.maquinasService.get_instalaciones_model();
    //if (instalaciones_model == false) {
    //  this.maquinasService.GetInstalaciones().subscribe(json => {
    //    this.maquinasService.set_instalaciones_model(json);
    //    this.instalaciones = this.maquinasService.get_instalaciones_model();
    //    r2 = true;
    //    if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
    //    if (this.gruposCargados && this.seccionesCargadas && r1 && r2) {
    //      this.maquinas.forEach(
    //        maquina => {
    //          maquina.idMaquina = maquina.id;
    //          maquina.nombreMaquina = maquina.nombre;
    //        });
    //      this.filtrarMaquinasPorAreaProductivaYGrupo();
    //    }
    //  })
    //} else {
    //  this.instalaciones = instalaciones_model;
    //  r2 = true;
    //  if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
    //  if (this.gruposCargados && this.seccionesCargadas && r1 && r2) {
    //    this.maquinas.forEach(
    //      maquina => {
    //        maquina.idMaquina = maquina.id;
    //        maquina.nombreMaquina = maquina.nombre;
    //      });
    //    this.filtrarMaquinasPorAreaProductivaYGrupo();
    //  }
    //}

  }

  filtrarMaquinasPorAreaProductivaYGrupo(cargarDatos = true) {


  }

  dibujarDonutRendimiento() {

    this.graficoDonutRendimiento = c3.generate({
      bindto: '#graficoDonutRendimiento_AnaliticaAvanzadaEjecuciones',
      data: {
        columns: [['completo', 0], ['nocompleto', 100]],
        type: 'donut',
        colors: {
          completo: '#18d6b0',
          nocompleto: '#3d6063'
        },
        order: 'null'
      },
      transition: {
        duration: 1000
      },
      donut: {
        title: "0",
        width: 18,
        label: { show: false }
      },
      legend: {
        show: false
      },
      tooltip: {
        show: false
      }
    });

    d3.select('#graficoDonutRendimiento_AnaliticaAvanzadaEjecuciones .c3-chart-arcs-title')
      .text("")
      .style("font-size", "30px")
      .attr("fill", "#18d6b0");

    d3.selectAll("#graficoDonutRendimiento_AnaliticaAvanzadaEjecuciones .c3-chart-arcs path").style("stroke-width", "0px");

  }
  dibujarDonutCalidad() {

    this.graficoDonutCalidad = c3.generate({
      bindto: '#graficoDonutCalidad_AnaliticaAvanzadaEjecuciones',
      data: {
        columns: [['completo', 0], ['nocompleto', 100]],
        type: 'donut',
        colors: {
          completo: '#18d6b0',
          nocompleto: '#3d6063'
        },
        order: 'null'
      },
      transition: {
        duration: 1000
      },
      donut: {
        title: "0",
        width: 20,
        label: { show: false }
      },
      legend: {
        show: false
      },
      tooltip: {
        show: false
      }
    });

    d3.select('#graficoDonutCalidad_AnaliticaAvanzadaEjecuciones .c3-chart-arcs-title')
      .text("")
      .style("font-size", "30px")
      .attr("fill", "#18d6b0");

    d3.selectAll("#graficoDonutCalidad_AnaliticaAvanzadaEjecuciones .c3-chart-arcs path").style("stroke-width", "0px");

  }
  dibujarGraficoRendimientoCalidad() {

    this.graficoRendimientoCalidad = c3.generate({
      data: {
        columns: [[this.translateService.instant('total'), 0, 0, 0],
        [this.translateService.instant('perdidas'),0 , 0, 0]],
        order: null,
        type: 'bar',
        groups: [[this.translateService.instant('total'), this.translateService.instant('perdidas')]]
      },
      color: {
        pattern: [['#44e3c4'], ['#ffa8a8']]
      },
      axis: {
        x: {
          type: 'category',
          tick: {
            multiline: false
          },
          categories: [this.translateService.instant('disponibilidad'),this.translateService.instant('rendimiento'), this.translateService.instant('calidad')]
        },
        y: {
          show: false,
        },
        rotated: true
      },
      transition: {
        duration: 1000
      },
      bar: {
        width: {
          ratio: 0.75
        }
      },
      tooltip: {
        format: {
          value: function (value) {
            var hours = Math.floor(value / (60 * 60));
            var divisor_for_minutes = value % (60 * 60);
            var minutes = Math.floor(divisor_for_minutes / 60);
            if (0 <= minutes && minutes < 10)
              return hours + ":0" + minutes + "h";
            else
              return hours + ":" + minutes + "h";
          }
        }
      },
      bindto: '#graficoRendimientoCalidad_AnaliticaAvanzadaEjecuciones'
    });

  }
  dibujarGraficoBarras(idDivContenedor: string, index: number) {

    var colors = ['#ffb63d', '#ff7666', '#FF89BC', '#bb8eff', '#68e0ff', '#92dcdc', '#50df88', '#87ed77', '#eaea62', '#ffb976'];

    var grafico = c3.generate({
      padding: {
        top: 0,
        right: 0,
        bottom: -13,
        left: 50,
      },
      data: {
        x: 'x',
        columns: this.sortData([[' '], [this.translateService.instant('desvio'), null]]),
        type: 'bar',
        color: function (color, d) {
          return colors[d.index % 10];
        },
        selection: {
          grouped: true
        },
      },
      axis: {
        x: {
          type: 'category'
        },
        y: {
          tick: {
            values: [0],
            format: function (value) {
              return Math.floor(value / 3600) + "h"
            }
          }
        }
      },
      tooltip: {
        format: {
          value: function (value) {
            if (value >= 0) {
              return Math.floor(Math.abs(value) / 3600) + ":" + ("0" + (Math.floor((Math.abs(value) % 3600) / 60))).slice(-2) + "h"
            } else {
              return "-" + Math.floor(Math.abs(value) / 3600) + ":" + ("0" + (Math.floor((Math.abs(value) % 3600) / 60))).slice(-2) + "h"
            }
          }
        }
      },
      transition: {
        duration: 0
      },
      bar: {
        width: {
          ratio: 0.75
        }
      },
      legend: {
        show: false
      },
      bindto: idDivContenedor
    });

    if (index == 0) this.graficoOperaciones = grafico;
    if (index == 1) this.graficoMaquinas = grafico;
    if (index == 2) this.graficoOperarios = grafico;
    if (index == 3) this.graficoPiezas = grafico;
    if (index == 4) this.graficoClientes = grafico;

  }
  dibujarGraficoDonutPerdidas(idDivContenedor: string, index: number) {

    var that = this;
    var grafico = c3.generate({
      data: {
        columns: [],
        type: 'donut',
        onmouseover: function (d) {
          d3.select(idDivContenedor + ' .c3-chart-arcs-title')
            .append("tspan")
            .attr("font-size", "35")
            .text((d.ratio * 100).toFixed(1) + "%");
          d3.select(idDivContenedor + ' .c3-chart-arcs-title')
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.id);
        },
        onmouseout: function (d) {
          d3.select(idDivContenedor + ' .c3-chart-arcs-title').node().innerHTML = "";
        },
      },
      transition: {
        duration: 2000
      },
      legend: {
        //bottom, right, inset
        position: 'right'
      },
      color: {
        pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
      },
      tooltip: {
        show: true,
        contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
          if (index == 0) {
            if (that.dataGraficoDonutParadasParaTooltip[d[0].id] != undefined)
              d[0].name = that.dataGraficoDonutParadasParaTooltip[d[0].id];
          } else if (index == 1) {
            if (that.dataGraficoDonutMantenimientosParaTooltip[d[0].id] != undefined)
              d[0].name = that.dataGraficoDonutMantenimientosParaTooltip[d[0].id];
          } else if (index == 2) {
            if (that.dataGraficoDonutAlarmasParaTooltip[d[0].id] != undefined)
              d[0].name = that.dataGraficoDonutAlarmasParaTooltip[d[0].id];
          }
          return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
        }
      },
      bindto: idDivContenedor
    });

    d3.selectAll(idDivContenedor + " .c3-chart-arcs path").style("stroke-width", "0px");

    if (index == 0) this.graficoDonutParadas = grafico;
    if (index == 1) this.graficoDonutMantenimientos = grafico;
    if (index == 2) this.graficoDonutAlarmas = grafico;
  }

  // CARGAR DATOS / GRAFICOS GENERALES ---------------------------------------------------------------------------------------------------------------------
  cargarGraficosDonuts() {

    var tTotal = 0; //t Total entre fechas
    var tEjecucion = 0;
    var tParadaDisponibilidad = 0;
    var tMicroParadaDisponibilidad = 0;
    var tMantenimientoDisponibilidad = 0;
    var tAlarmaDisponibilidad = 0;
    var tApagadaDisponibilidad = 0;
    var tParadaRendimiento = 0;
    var tMicroParadaRendimiento = 0;
    var tMantenimientoRendimiento = 0;
    var tAlarmaRendimiento = 0;
    var tApagadaRendimiento = 0;
    var tParadaCalidad = 0;
    var tMicroParadaCalidad = 0;
    var tMantenimientoCalidad = 0;
    var tAlarmaCalidad = 0;
    var tApagadaCalidad = 0;
    var tNegativoCalidad = 0;
    var tEstimadoGlobal = 0;

    this.dataInformeGroupByHistoricoOperacion.forEach(function (a) {

      tEjecucion += a.tEjecucion;
      tParadaDisponibilidad += a.tParadaDisponibilidad;
      tMicroParadaDisponibilidad += a.tMicroParadaDisponibilidad;
      tMantenimientoDisponibilidad += a.tMantenimientoDisponibilidad;
      tAlarmaDisponibilidad += a.tAlarmaDisponibilidad;
      tApagadaDisponibilidad += a.tApagadaDisponibilidad;
      tParadaRendimiento += a.tParadaRendimiento;
      tMicroParadaRendimiento += a.tMicroParadaRendimiento;
      tMantenimientoRendimiento += a.tMantenimientoRendimiento;
      tAlarmaRendimiento += a.tAlarmaRendimiento;
      tApagadaRendimiento += a.tApagadaRendimiento;
      tParadaCalidad += a.tParadaCalidad;
      tMicroParadaCalidad += a.tMicroParadaCalidad;
      tMantenimientoCalidad += a.tMantenimientoCalidad;
      tAlarmaCalidad += a.tAlarmaCalidad;
      tApagadaCalidad += a.tApagadaCalidad;
      tNegativoCalidad += a.tNegativoCalidad;
      tEstimadoGlobal += a.tEstimadoFinal; //a.tEstimado; //a.tEstimadoGlobal; // //a.tEstimadoFinal;
      tTotal += a.tTotal;

    }, this);

    var oee: number = 0;
    var cal: number = 0;
    var ren: number = 0;
    var dis: number = 0;

    var perdidasDisponibilidad = tParadaDisponibilidad + tMicroParadaDisponibilidad + tMantenimientoDisponibilidad + tAlarmaDisponibilidad + tApagadaDisponibilidad;
    var perdidasRendimiento = tParadaRendimiento + tMicroParadaRendimiento + tMantenimientoRendimiento + tAlarmaRendimiento + tApagadaRendimiento;
    var perdidasCalidad = tParadaCalidad + tMicroParadaCalidad + tMantenimientoCalidad + tAlarmaCalidad + tApagadaCalidad;

    var disDividendo: number = tEjecucion + perdidasRendimiento;
    var disDivisor: number = tEjecucion + perdidasRendimiento + perdidasDisponibilidad;
    if (disDivisor == 0) dis = 0;
    else dis = (disDividendo / disDivisor) * 100;

    var renDividendo: number = tEstimadoGlobal;
    var renDivisor: number = tEjecucion + perdidasRendimiento;
    if (renDivisor == 0) ren = 0;
    else ren = (renDividendo / renDivisor) * 100;

    var calDividendo: number = tEstimadoGlobal - tNegativoCalidad - perdidasCalidad;
    var calDivisor: number = tEstimadoGlobal;
    if (calDivisor == 0) cal = 0;
    else cal = (calDividendo / calDivisor) * 100;

    oee = cal * ren * dis / (10000);

    if (oee > 100) oee = 100;
    if (dis > 100) dis = 100;
    if (ren > 100) ren = 100;
    if (cal > 100) cal = 100;

    d3.select('#graficoDonutRendimiento_AnaliticaAvanzadaEjecuciones .c3-chart-arcs-title').transition().duration(500).style("font-size", "0px").style("opacity", "0").transition().duration(500).style("font-size", "30px").style("opacity", "1")
      .text(Math.round(ren) + "%");

    d3.select('#graficoDonutCalidad_AnaliticaAvanzadaEjecuciones .c3-chart-arcs-title').transition().duration(500).style("font-size", "0px").style("opacity", "0").transition().duration(500).style("font-size", "30px").style("opacity", "1")
      .text(Math.round(cal) + "%");


    var dataRendimiento = [['completo', ren], ['nocompleto', 100 - ren]];
    this.graficoDonutRendimiento.load({ columns: dataRendimiento });

    var dataCalidad = [['completo', cal], ['nocompleto', 100 - cal]];
    this.graficoDonutCalidad.load({ columns: dataCalidad });

  }
  cargarGraficosRendimientoCalidad() {

    var tTotal = 0; //t Total entre fechas
    var tEjecucion = 0;
    var tParadaDisponibilidad = 0;
    var tMicroParadaDisponibilidad = 0;
    var tMantenimientoDisponibilidad = 0;
    var tAlarmaDisponibilidad = 0;
    var tApagadaDisponibilidad = 0;
    var tParadaRendimiento = 0;
    var tMicroParadaRendimiento = 0;
    var tMantenimientoRendimiento = 0;
    var tAlarmaRendimiento = 0;
    var tApagadaRendimiento = 0;
    var tParadaCalidad = 0;
    var tMicroParadaCalidad = 0;
    var tMantenimientoCalidad = 0;
    var tAlarmaCalidad = 0;
    var tApagadaCalidad = 0;
    var tNegativoCalidad = 0;
    var tEstimado = 0;

    this.dataInformeGroupByHistoricoOperacion.forEach(function (a) {

      tEjecucion += a.tEjecucion;

      tParadaDisponibilidad += a.tParadaDisponibilidad;
      tMicroParadaDisponibilidad += a.tMicroParadaDisponibilidad;
      tMantenimientoDisponibilidad += a.tMantenimientoDisponibilidad;
      tAlarmaDisponibilidad += a.tAlarmaDisponibilidad;
      tApagadaDisponibilidad += a.tApagadaDisponibilidad;

      tParadaRendimiento += a.tParadaRendimiento;
      tMicroParadaRendimiento += a.tMicroParadaRendimiento;
      tMantenimientoRendimiento += a.tMantenimientoRendimiento;
      tAlarmaRendimiento += a.tAlarmaRendimiento;
      tApagadaRendimiento += a.tApagadaRendimiento;

      tParadaCalidad += a.tParadaCalidad;
      tMicroParadaCalidad += a.tMicroParadaCalidad;
      tMantenimientoCalidad += a.tMantenimientoCalidad;
      tAlarmaCalidad += a.tAlarmaCalidad;
      tApagadaCalidad += a.tApagadaCalidad;

      tNegativoCalidad += a.tNegativoCalidad;

      tEstimado += a.tEstimadoFinal; //a.tEstimado;//a.tEstimadoGlobal; // // a.tEstimadoFinal;

      tTotal += a.tTotal;
    }, this);

    var perdidasDisponibilidad = tParadaDisponibilidad + tMicroParadaDisponibilidad + tMantenimientoDisponibilidad + tAlarmaDisponibilidad + tApagadaDisponibilidad;
    var perdidasRendimiento = tParadaRendimiento + tMicroParadaRendimiento + tMantenimientoRendimiento + tAlarmaRendimiento + tApagadaRendimiento;
    var perdidasCalidad = tParadaCalidad + tMantenimientoCalidad + tAlarmaCalidad + tApagadaCalidad;

    var tRendimiento: number = 0;
    if (tEstimado > tEjecucion) {
      tRendimiento = 0;
      tEstimado = tEjecucion;
    } else {
      tRendimiento = tEjecucion - tEstimado;
    }

    var data = [[this.translateService.instant('total'), (tEstimado + tRendimiento + perdidasRendimiento), tEstimado, tEstimado - tNegativoCalidad - perdidasCalidad],
    [this.translateService.instant('perdidas'), perdidasDisponibilidad, tRendimiento + perdidasRendimiento, tParadaCalidad + tMicroParadaCalidad + tMantenimientoCalidad + tAlarmaCalidad + tApagadaCalidad + tNegativoCalidad]];

    this.graficoRendimientoCalidad.load({ columns: data });

  }
  cargarDatosPanelIzquierdo() {

    this.horasRealesPanelIzq = 0;
    this.horasEstimadasPanelIzq = 0;
    this.desvioHorasEstimadoPanelIzq = 0;
    this.horasPrevistasPanelIzq = 0;
    this.desvioHorasPrevistasPanelIzq = 0;
    this.cantidadPanelIzq = 0;
    this.cantidadRealPanelIzq = 0;
    this.avanceAcumuladoPanelIzq = 0;
    this.kwAcumuladosPanelIzq = 0;
    this.cambiosDePlacaPanelIzq = 0;
    this.roturaDeHerramientaPanelIzq = 0;

    var idsHistoricoOperaciones = "";

    this.dataInformeGroupByHistoricoOperacion.forEach(function (registro) {

      this.horasRealesPanelIzq += registro.tTotal; //registro.tEjecucion + registro.tNegativoCalidad;//registro.tEjecucion;
      this.horasEstimadasPanelIzq += registro.tEstimadoGlobal; //registro.tEstimadoFinal;//registro.tEstimadoGlobal;
      this.horasPrevistasPanelIzq += registro.tiempoPredictivoGlobal;

      idsHistoricoOperaciones += registro.idsHistoricoOperaciones;

      this.avanceAcumuladoPanelIzq += registro.avanceAcumulado;
      this.kwAcumuladosPanelIzq += registro.consumoAcumulado;
      this.cambiosDePlacaPanelIzq += registro.cambioPlacas;
      this.roturaDeHerramientaPanelIzq += registro.roturaHerramientas;

      this.cantidadPanelIzq += registro.cantidadTotal;
      this.cantidadRealPanelIzq += registro.piezaTerminadas;
    }, this);

    if (this.horasRealesPanelIzq > 0){
      this.desvioHorasEstimadoPanelIzq = Math.round((this.horasEstimadasPanelIzq - this.horasRealesPanelIzq) * 10000 / this.horasEstimadasPanelIzq)/100;
      this.desvioHorasPrevistasPanelIzq = Math.round((this.horasPrevistasPanelIzq - this.horasRealesPanelIzq) * 10000 / this.horasPrevistasPanelIzq)/100;
    }
    else {
      this.desvioHorasEstimadoPanelIzq = 0;
      this.desvioHorasPrevistasPanelIzq = 0;      
    }

    //this.cantidadPanelIzq += idsHistoricoOperaciones.slice(0, -1).split(',').filter((v, i, a) => a.indexOf(v) === i).length;
    //this.cantidadRealPanelIzq += idsHistoricoOperaciones.slice(0, -1).split(',').filter((v, i, a) => a.indexOf(v) === i).length;

  }
  // CARGAR DATOS / GRAFICOS GENERALES END -----------------------------------------------------------------------------------------------------------------

  // CARGAR GRIDS ------------------------------------------------------------------------------------------------------------------------------------------
  cargarDatosGridOperaciones() {
    /* AQUI CANTIDAD (hecha / total) SE CUENTA POR OPERACIONES */
    this.datosGridOperaciones = [];

    //GROUP BY POR OPERACION Y HISTORICO OPERACIONES (PARA CONSEGUIR EL ESTIMADO DEL HISTORICO)
    var groupByOperacionYHistoricoOperaciones = [];

    this.dataInforme.forEach(function (a) {
      if (!this[a.operacion + "_" + a.idHistoricoOperaciones]) {
        this[a.operacion + "_" + a.idHistoricoOperaciones] = {
          numeroOF: a.numeroOF,
          numeroPlano: a.numeroPlano,
          idOperacion: a.idOperacion,
          operacion: a.operacion,
          idHistoricoOperaciones: a.idHistoricoOperaciones,
          tEjecucion: 0,
          tParadaDisponibilidad: 0,
          tMicroParadaDisponibilidad: 0,
          tMantenimientoDisponibilidad: 0,
          tAlarmaDisponibilidad: 0,
          tApagadaDisponibilidad: 0,
          tParadaRendimiento: 0,
          tMicroParadaRendimiento: 0,
          tMantenimientoRendimiento: 0,
          tAlarmaRendimiento: 0,
          tApagadaRendimiento: 0,
          tParadaCalidad: 0,
          tMicroParadaCalidad: 0,
          tMantenimientoCalidad: 0,
          tAlarmaCalidad: 0,
          tApagadaCalidad: 0,
          tNegativoCalidad: 0,
          tEstimado: 0,
          tEstimadoGlobal: 0,
          tTotal: 0,
          tTotalHistoricoOperacion: 0,
          terminado: a.terminado,
          cantidadTotal: 0,
          terminadas: 0
        };
        groupByOperacionYHistoricoOperaciones.push(this[a.operacion + "_" + a.idHistoricoOperaciones]);
      }
      this[a.operacion + "_" + a.idHistoricoOperaciones].tEjecucion += a.tEjecucion;
      this[a.operacion + "_" + a.idHistoricoOperaciones].tParadaDisponibilidad += a.tParadaDisponibilidad;
      this[a.operacion + "_" + a.idHistoricoOperaciones].tMicroParadaDisponibilidad += a.tMicroParadaDisponibilidad;
      this[a.operacion + "_" + a.idHistoricoOperaciones].tMantenimientoDisponibilidad += a.tMantenimientoDisponibilidad;
      this[a.operacion + "_" + a.idHistoricoOperaciones].tAlarmaDisponibilidad += a.tAlarmaDisponibilidad;
      this[a.operacion + "_" + a.idHistoricoOperaciones].tApagadaDisponibilidad += a.tApagadaDisponibilidad;
      this[a.operacion + "_" + a.idHistoricoOperaciones].tParadaRendimiento += a.tParadaRendimiento;
      this[a.operacion + "_" + a.idHistoricoOperaciones].tMicroParadaRendimiento += a.tMicroParadaRendimiento;
      this[a.operacion + "_" + a.idHistoricoOperaciones].tMantenimientoRendimiento += a.tMantenimientoRendimiento;
      this[a.operacion + "_" + a.idHistoricoOperaciones].tAlarmaRendimiento += a.tAlarmaRendimiento;
      this[a.operacion + "_" + a.idHistoricoOperaciones].tApagadaRendimiento += a.tApagadaRendimiento;
      this[a.operacion + "_" + a.idHistoricoOperaciones].tParadaCalidad += a.tParadaCalidad;
      this[a.operacion + "_" + a.idHistoricoOperaciones].tMicroParadaCalidad += a.tMicroParadaCalidad;
      this[a.operacion + "_" + a.idHistoricoOperaciones].tMantenimientoCalidad += a.tMantenimientoCalidad;
      this[a.operacion + "_" + a.idHistoricoOperaciones].tAlarmaCalidad += a.tAlarmaCalidad;
      this[a.operacion + "_" + a.idHistoricoOperaciones].tApagadaCalidad += a.tApagadaCalidad;
      this[a.operacion + "_" + a.idHistoricoOperaciones].tNegativoCalidad += a.tNegativoCalidad;
      this[a.operacion + "_" + a.idHistoricoOperaciones].tEstimado += a.tEstimado;
      this[a.operacion + "_" + a.idHistoricoOperaciones].tEstimadoGlobal = Math.max(this[a.operacion + "_" + a.idHistoricoOperaciones].tEstimadoGlobal, a.tEstimadoGlobal);
      this[a.operacion + "_" + a.idHistoricoOperaciones].tTotal += a.tTotal;
      this[a.operacion + "_" + a.idHistoricoOperaciones].tTotalHistoricoOperacion = Math.max(this[a.operacion + "_" + a.idHistoricoOperaciones].tTotalHistoricoOperacion, a.tTotalHistoricoOperacion);
      this[a.operacion + "_" + a.idHistoricoOperaciones].terminado = this[a.operacion + "_" + a.idHistoricoOperaciones].terminado && a.terminado;
      this[a.operacion + "_" + a.idHistoricoOperaciones].cantidadTotal = a.cantidadTotal;
      this[a.operacion + "_" + a.idHistoricoOperaciones].terminadas = a.terminadas;
    }, Object.create(null));

    //FOREACH CON EL IF DE TIEMPO ESTIMADO
    //Si tReal de la operacion recibida < tReal operacion total (operacion cortada por fecha): usar tEstimado ficticio
    //Si tReal de la operacion recibida = tReal operacion total (operacion no cortada por fecha):
    //  Si terminada=1: usar tEstimado global
    //  Si terminada=0:
    //    Si tReal de la operacion recibida <= tEstimado: tEst=tReal
    //    Si tReal de la operacion recibida > tEstimado: tEst=tEst
    groupByOperacionYHistoricoOperaciones.forEach(function (a) {
      if (a.tTotal < a.tTotalHistoricoOperacion) {//(operacion cortada por fecha)
        a.tEstimadoFinal = a.tEstimado;
      } else {//(operacion no cortada por fecha)
        if (a.terminado) {
          a.tEstimadoFinal = a.tEstimadoGlobal;
        } else {
          if (a.tTotal <= a.tEstimadoGlobal) {
            a.tEstimadoFinal = a.tTotal;
          } else {
            a.tEstimadoFinal = a.tEstimadoGlobal;
          }
        }
      }
    });

    //GROUP BY POR OPERACION
    var groupByOperacion = [];

    groupByOperacionYHistoricoOperaciones.forEach(function (a) {

      //si se quiere agrupar por otra cosa.. cambiar esta linea, esto es un group by idOperacion, operacion y numeroPlano
      var criterioDeAgrupacion = a.idOperacion + '_' + a.operacion + '_' + a.numeroPlano;
      if (!this[criterioDeAgrupacion]) {
        this[criterioDeAgrupacion] = {
          numeroOF: a.numeroOF, idOperacion: a.idOperacion, operacion: a.operacion, numeroPlano: a.numeroPlano, idsHistoricoOperaciones: [a.idHistoricoOperaciones], tEjecucion: 0, tParadaDisponibilidad: 0, tMicroParadaDisponibilidad: 0,
          tMantenimientoDisponibilidad: 0, tAlarmaDisponibilidad: 0, tApagadaDisponibilidad: 0, tParadaRendimiento: 0, tMicroParadaRendimiento: 0, tMantenimientoRendimiento: 0,
          tAlarmaRendimiento: 0, tApagadaRendimiento: 0, tParadaCalidad: 0, tMicroParadaCalidad: 0, tMantenimientoCalidad: 0, tAlarmaCalidad: 0, tApagadaCalidad: 0, tNegativoCalidad: 0,
          tEstimadoFinal: 0, tEstimadoGlobal: 0, tTotal: 0, tTotalHistoricoOperacion: 0, cantidadTotal: 0, terminadas: 0, cantidad: 0
        };
        groupByOperacion.push(this[criterioDeAgrupacion]);
      }
      this[criterioDeAgrupacion].idsHistoricoOperaciones.push(a.idHistoricoOperaciones);
      this[criterioDeAgrupacion].tEjecucion += a.tEjecucion;
      this[criterioDeAgrupacion].tParadaDisponibilidad += a.tParadaDisponibilidad;
      this[criterioDeAgrupacion].tMicroParadaDisponibilidad += a.tMicroParadaDisponibilidad;
      this[criterioDeAgrupacion].tMantenimientoDisponibilidad += a.tMantenimientoDisponibilidad;
      this[criterioDeAgrupacion].tAlarmaDisponibilidad += a.tAlarmaDisponibilidad;
      this[criterioDeAgrupacion].tApagadaDisponibilidad += a.tApagadaDisponibilidad;
      this[criterioDeAgrupacion].tParadaRendimiento += a.tParadaRendimiento;
      this[criterioDeAgrupacion].tMicroParadaRendimiento += a.tMicroParadaRendimiento;
      this[criterioDeAgrupacion].tMantenimientoRendimiento += a.tMantenimientoRendimiento;
      this[criterioDeAgrupacion].tAlarmaRendimiento += a.tAlarmaRendimiento;
      this[criterioDeAgrupacion].tApagadaRendimiento += a.tApagadaRendimiento;
      this[criterioDeAgrupacion].tParadaCalidad += a.tParadaCalidad;
      this[criterioDeAgrupacion].tMicroParadaCalidad += a.tMicroParadaCalidad;
      this[criterioDeAgrupacion].tMantenimientoCalidad += a.tMantenimientoCalidad;
      this[criterioDeAgrupacion].tAlarmaCalidad += a.tAlarmaCalidad;
      this[criterioDeAgrupacion].tApagadaCalidad += a.tApagadaCalidad;
      this[criterioDeAgrupacion].tNegativoCalidad += a.tNegativoCalidad;
      this[criterioDeAgrupacion].tEstimadoFinal += a.tEstimadoFinal;
      this[criterioDeAgrupacion].tEstimadoGlobal += a.tEstimadoGlobal;
      this[criterioDeAgrupacion].tTotal += a.tTotal;
      this[criterioDeAgrupacion].tTotalHistoricoOperacion += a.tTotalHistoricoOperacion;
      this[criterioDeAgrupacion].cantidadTotal += a.cantidadTotal;
      this[criterioDeAgrupacion].terminadas += a.terminadas;
      this[criterioDeAgrupacion].cantidad += a.terminadas; //terminadas tiene la cantidad hechas en el historicoOperacion
    }, Object.create(null));

    groupByOperacion.forEach(function (registro) {

      var tReal = registro.tTotal;// registro.tEjecucion + registro.tNegativoCalidad;

      var tEjecuciones = registro.tEjecucion;
      var tEstimado = registro.tEstimadoGlobal;

      var tRendimientoNegativos = (tEjecuciones - tEstimado) + registro.tParadaRendimiento + registro.tMicroParadaRendimiento + registro.tMantenimientoRendimiento + registro.tAlarmaRendimiento + registro.tApagadaRendimiento;
      var tCalidadNegativos = registro.tParadaCalidad + registro.tMicroParadaCalidad + registro.tMantenimientoCalidad + registro.tAlarmaCalidad + registro.tApagadaCalidad + registro.tNegativoCalidad;

      var desvioPorcentaje = 0
      if (tEstimado == 0) {
        desvioPorcentaje = 100;
      } else {
        desvioPorcentaje = (100 - (((registro.tTotal) / tEstimado) * 100));//(((tEstimado - tRendimientoNegativos - tCalidadNegativos) / tEstimado) * 100));
      }

      this.datosGridOperaciones.push(
        {
          idoperacion: registro.idOperacion,
          numeroOF: registro.numeroOF,
          horasHistoricoOperacion: registro.tTotalHistoricoOperacion,
          operacion: registro.operacion,
          numeroPlano: registro.numeroPlano,
          numeroOperaciones: registro.cantidad, //idsHistoricoOperaciones.filter((v, i, a) => a.indexOf(v) === i).length,
          horasEstimadas: registro.tEstimadoGlobal,
          horasEstimadasReales: tEstimado,
          horasReales: tReal,//tEjecuciones,
          desvioRendimiento: tRendimientoNegativos,
          desvioCalidad: tCalidadNegativos,
          desvioPorcentaje: desvioPorcentaje,
          nTerminado: registro.terminadas + '/' + registro.cantidadTotal
        }
      );

    }, this);

  }
  cargarDatosGridMaquinas() {

    this.datosGridMaquinas = [];

    //GROUP BY POR MAQUINA Y HISTORICO OPERACIONES (PARA CONSEGUIR EL ESTIMADO DEL HISTORICO)
    var groupByMaquinaYHistoricoOperaciones = [];

    this.dataInforme.forEach(function (a) {
      if (!this[a.idMaquina + "_" + a.idHistoricoOperaciones]) {
        this[a.idMaquina + "_" + a.idHistoricoOperaciones] = {
          idMaquina: a.idMaquina, maquina: a.maquina, idHistoricoOperaciones: a.idHistoricoOperaciones, tEjecucion: 0, tParadaDisponibilidad: 0, tMicroParadaDisponibilidad: 0,
          tMantenimientoDisponibilidad: 0, tAlarmaDisponibilidad: 0, tApagadaDisponibilidad: 0, tParadaRendimiento: 0, tMicroParadaRendimiento: 0, tMantenimientoRendimiento: 0,
          tAlarmaRendimiento: 0, tApagadaRendimiento: 0, tParadaCalidad: 0, tMicroParadaCalidad: 0, tMantenimientoCalidad: 0, tAlarmaCalidad: 0, tApagadaCalidad: 0, tNegativoCalidad: 0,
          tEstimado: 0, tEstimadoGlobal: 0, tTotal: 0, tTotalHistoricoOperacion: 0, terminado: a.terminado
        };
        groupByMaquinaYHistoricoOperaciones.push(this[a.idMaquina + "_" + a.idHistoricoOperaciones]);
      }
      this[a.idMaquina + "_" + a.idHistoricoOperaciones].tEjecucion += a.tEjecucion;
      this[a.idMaquina + "_" + a.idHistoricoOperaciones].tParadaDisponibilidad += a.tParadaDisponibilidad;
      this[a.idMaquina + "_" + a.idHistoricoOperaciones].tMicroParadaDisponibilidad += a.tMicroParadaDisponibilidad;
      this[a.idMaquina + "_" + a.idHistoricoOperaciones].tMantenimientoDisponibilidad += a.tMantenimientoDisponibilidad;
      this[a.idMaquina + "_" + a.idHistoricoOperaciones].tAlarmaDisponibilidad += a.tAlarmaDisponibilidad;
      this[a.idMaquina + "_" + a.idHistoricoOperaciones].tApagadaDisponibilidad += a.tApagadaDisponibilidad;
      this[a.idMaquina + "_" + a.idHistoricoOperaciones].tParadaRendimiento += a.tParadaRendimiento;
      this[a.idMaquina + "_" + a.idHistoricoOperaciones].tMicroParadaRendimiento += a.tMicroParadaRendimiento;
      this[a.idMaquina + "_" + a.idHistoricoOperaciones].tMantenimientoRendimiento += a.tMantenimientoRendimiento;
      this[a.idMaquina + "_" + a.idHistoricoOperaciones].tAlarmaRendimiento += a.tAlarmaRendimiento;
      this[a.idMaquina + "_" + a.idHistoricoOperaciones].tApagadaRendimiento += a.tApagadaRendimiento;
      this[a.idMaquina + "_" + a.idHistoricoOperaciones].tParadaCalidad += a.tParadaCalidad;
      this[a.idMaquina + "_" + a.idHistoricoOperaciones].tMicroParadaCalidad += a.tMicroParadaCalidad;
      this[a.idMaquina + "_" + a.idHistoricoOperaciones].tMantenimientoCalidad += a.tMantenimientoCalidad;
      this[a.idMaquina + "_" + a.idHistoricoOperaciones].tAlarmaCalidad += a.tAlarmaCalidad;
      this[a.idMaquina + "_" + a.idHistoricoOperaciones].tApagadaCalidad += a.tApagadaCalidad;
      this[a.idMaquina + "_" + a.idHistoricoOperaciones].tNegativoCalidad += a.tNegativoCalidad;
      this[a.idMaquina + "_" + a.idHistoricoOperaciones].tEstimado += a.tEstimado;
      this[a.idMaquina + "_" + a.idHistoricoOperaciones].tEstimadoGlobal = Math.max(this[a.idMaquina + "_" + a.idHistoricoOperaciones].tEstimadoGlobal, a.tEstimadoGlobal);
      this[a.idMaquina + "_" + a.idHistoricoOperaciones].tTotal += a.tTotal;
      this[a.idMaquina + "_" + a.idHistoricoOperaciones].tTotalHistoricoOperacion = Math.max(this[a.idMaquina + "_" + a.idHistoricoOperaciones].tTotalHistoricoOperacion, a.tTotalHistoricoOperacion);
      this[a.idMaquina + "_" + a.idHistoricoOperaciones].terminado = this[a.idMaquina + "_" + a.idHistoricoOperaciones].terminado && a.terminado;
    }, Object.create(null));

    //FOREACH CON EL IF DE TIEMPO ESTIMADO
    //Si tReal de la operacion recibida < tReal operacion total (operacion cortada por fecha): usar tEstimado ficticio
    //Si tReal de la operacion recibida = tReal operacion total (operacion no cortada por fecha):
    //  Si terminada=1: usar tEstimado global
    //  Si terminada=0:
    //    Si tReal de la operacion recibida <= tEstimado: tEst=tReal
    //    Si tReal de la operacion recibida > tEstimado: tEst=tEst
    groupByMaquinaYHistoricoOperaciones.forEach(function (a) {
      if (a.tTotal < a.tTotalHistoricoOperacion) {//(operacion cortada por fecha)
        a.tEstimadoFinal = a.tEstimado;
      } else {//(operacion no cortada por fecha)
        if (a.terminado) {
          a.tEstimadoFinal = a.tEstimadoGlobal;
        } else {
          if (a.tTotal <= a.tEstimadoGlobal) {
            a.tEstimadoFinal = a.tTotal;
          } else {
            a.tEstimadoFinal = a.tEstimadoGlobal;
          }
        }
      }
    });

    var groupByMaquina = [];

    //GROUP BY POR MAQUINA
    groupByMaquinaYHistoricoOperaciones.forEach(function (a) {
      if (!this[a.idMaquina]) {
        this[a.idMaquina] = {
          idMaquina: a.idMaquina, maquina: a.maquina, idsHistoricoOperaciones: [a.idHistoricoOperaciones], tEjecucion: 0, tParadaDisponibilidad: 0, tMicroParadaDisponibilidad: 0,
          tMantenimientoDisponibilidad: 0, tAlarmaDisponibilidad: 0, tApagadaDisponibilidad: 0, tParadaRendimiento: 0, tMicroParadaRendimiento: 0, tMantenimientoRendimiento: 0,
          tAlarmaRendimiento: 0, tApagadaRendimiento: 0, tParadaCalidad: 0, tMicroParadaCalidad: 0, tMantenimientoCalidad: 0, tAlarmaCalidad: 0, tApagadaCalidad: 0, tNegativoCalidad: 0,
          tEstimadoFinal: 0, tEstimadoGlobal: 0, tTotal: 0, tTotalHistoricoOperacion: 0
        };
        groupByMaquina.push(this[a.idMaquina]);
      }
      this[a.idMaquina].idsHistoricoOperaciones.push(a.idHistoricoOperaciones);
      this[a.idMaquina].tEjecucion += a.tEjecucion;
      this[a.idMaquina].tParadaDisponibilidad += a.tParadaDisponibilidad;
      this[a.idMaquina].tMicroParadaDisponibilidad += a.tMicroParadaDisponibilidad;
      this[a.idMaquina].tMantenimientoDisponibilidad += a.tMantenimientoDisponibilidad;
      this[a.idMaquina].tAlarmaDisponibilidad += a.tAlarmaDisponibilidad;
      this[a.idMaquina].tApagadaDisponibilidad += a.tApagadaDisponibilidad;
      this[a.idMaquina].tParadaRendimiento += a.tParadaRendimiento;
      this[a.idMaquina].tMicroParadaRendimiento += a.tMicroParadaRendimiento;
      this[a.idMaquina].tMantenimientoRendimiento += a.tMantenimientoRendimiento;
      this[a.idMaquina].tAlarmaRendimiento += a.tAlarmaRendimiento;
      this[a.idMaquina].tApagadaRendimiento += a.tApagadaRendimiento;
      this[a.idMaquina].tParadaCalidad += a.tParadaCalidad;
      this[a.idMaquina].tMicroParadaCalidad += a.tMicroParadaCalidad;
      this[a.idMaquina].tMantenimientoCalidad += a.tMantenimientoCalidad;
      this[a.idMaquina].tAlarmaCalidad += a.tAlarmaCalidad;
      this[a.idMaquina].tApagadaCalidad += a.tApagadaCalidad;
      this[a.idMaquina].tNegativoCalidad += a.tNegativoCalidad;
      this[a.idMaquina].tEstimadoFinal += a.tEstimadoFinal;
      this[a.idMaquina].tEstimadoGlobal += a.tEstimadoGlobal;
      this[a.idMaquina].tTotal += a.tTotal;
      this[a.idMaquina].tTotalHistoricoOperacion += a.tTotalHistoricoOperacion;
    }, Object.create(null));

    groupByMaquina.forEach(function (registro) {

      var tReal = registro.tTotal;//registro.tEjecucion + registro.tNegativoCalidad;

      var tEjecuciones = registro.tEjecucion;
      var tEstimado = registro.tEstimadoGlobal;

      var tRendimientoNegativos = (tEjecuciones - tEstimado) + registro.tParadaRendimiento + registro.tMicroParadaRendimiento + registro.tMantenimientoRendimiento + registro.tAlarmaRendimiento + registro.tApagadaRendimiento;
      var tCalidadNegativos = registro.tParadaCalidad + registro.tMicroParadaCalidad + registro.tMantenimientoCalidad + registro.tAlarmaCalidad + registro.tApagadaCalidad + registro.tNegativoCalidad;

      var desvioPorcentaje = 0
      if (tEstimado == 0) {
        desvioPorcentaje = 100;
      } else {
        desvioPorcentaje = (100 - (((registro.tTotal) / tEstimado) * 100));//(((tEstimado - tRendimientoNegativos - tCalidadNegativos) / tEstimado) * 100));
      }

      this.datosGridMaquinas.push(
        {
          idmaquina: registro.idMaquina,
          maquina: registro.maquina,
          numeroOperaciones: registro.idsHistoricoOperaciones.filter((v, i, a) => a.indexOf(v) === i).length,
          horasEstimadas: registro.tEstimadoGlobal,
          horasEstimadasReales: tEstimado,
          horasReales: tReal,//tEjecuciones,
          desvioRendimiento: tRendimientoNegativos,
          desvioCalidad: tCalidadNegativos,
          desvioPorcentaje: desvioPorcentaje
        }
      );

    }, this);

  }
  cargarDatosGridOperarios() {

    this.datosGridOperarios = [];

    //GROUP BY POR OPERARIO Y HISTORICO OPERACIONES (PARA CONSEGUIR EL ESTIMADO DEL HISTORICO)
    var groupByOperarioYHistoricoOperaciones = [];

    this.dataInforme.forEach(function (a) {
      if (!this[a.idOperario + "_" + a.idHistoricoOperaciones]) {
        this[a.idOperario + "_" + a.idHistoricoOperaciones] = {
          idOperario: a.idOperario, operario: a.operario, idHistoricoOperaciones: a.idHistoricoOperaciones, tEjecucion: 0, tParadaDisponibilidad: 0, tMicroParadaDisponibilidad: 0,
          tMantenimientoDisponibilidad: 0, tAlarmaDisponibilidad: 0, tApagadaDisponibilidad: 0, tParadaRendimiento: 0, tMicroParadaRendimiento: 0, tMantenimientoRendimiento: 0,
          tAlarmaRendimiento: 0, tApagadaRendimiento: 0, tParadaCalidad: 0, tMicroParadaCalidad: 0, tMantenimientoCalidad: 0, tAlarmaCalidad: 0, tApagadaCalidad: 0, tNegativoCalidad: 0,
          tEstimado: 0, tEstimadoGlobal: 0, tTotal: 0, tTotalHistoricoOperacion: 0, terminado: a.terminado
        };
        groupByOperarioYHistoricoOperaciones.push(this[a.idOperario + "_" + a.idHistoricoOperaciones]);
      }
      this[a.idOperario + "_" + a.idHistoricoOperaciones].tEjecucion += a.tEjecucion;
      this[a.idOperario + "_" + a.idHistoricoOperaciones].tParadaDisponibilidad += a.tParadaDisponibilidad;
      this[a.idOperario + "_" + a.idHistoricoOperaciones].tMicroParadaDisponibilidad += a.tMicroParadaDisponibilidad;
      this[a.idOperario + "_" + a.idHistoricoOperaciones].tMantenimientoDisponibilidad += a.tMantenimientoDisponibilidad;
      this[a.idOperario + "_" + a.idHistoricoOperaciones].tAlarmaDisponibilidad += a.tAlarmaDisponibilidad;
      this[a.idOperario + "_" + a.idHistoricoOperaciones].tApagadaDisponibilidad += a.tApagadaDisponibilidad;
      this[a.idOperario + "_" + a.idHistoricoOperaciones].tParadaRendimiento += a.tParadaRendimiento;
      this[a.idOperario + "_" + a.idHistoricoOperaciones].tMicroParadaRendimiento += a.tMicroParadaRendimiento;
      this[a.idOperario + "_" + a.idHistoricoOperaciones].tMantenimientoRendimiento += a.tMantenimientoRendimiento;
      this[a.idOperario + "_" + a.idHistoricoOperaciones].tAlarmaRendimiento += a.tAlarmaRendimiento;
      this[a.idOperario + "_" + a.idHistoricoOperaciones].tApagadaRendimiento += a.tApagadaRendimiento;
      this[a.idOperario + "_" + a.idHistoricoOperaciones].tParadaCalidad += a.tParadaCalidad;
      this[a.idOperario + "_" + a.idHistoricoOperaciones].tMicroParadaCalidad += a.tMicroParadaCalidad;
      this[a.idOperario + "_" + a.idHistoricoOperaciones].tMantenimientoCalidad += a.tMantenimientoCalidad;
      this[a.idOperario + "_" + a.idHistoricoOperaciones].tAlarmaCalidad += a.tAlarmaCalidad;
      this[a.idOperario + "_" + a.idHistoricoOperaciones].tApagadaCalidad += a.tApagadaCalidad;
      this[a.idOperario + "_" + a.idHistoricoOperaciones].tNegativoCalidad += a.tNegativoCalidad;
      this[a.idOperario + "_" + a.idHistoricoOperaciones].tEstimado += a.tEstimado;
      this[a.idOperario + "_" + a.idHistoricoOperaciones].tEstimadoGlobal = Math.max(this[a.idOperario + "_" + a.idHistoricoOperaciones].tEstimadoGlobal, a.tEstimadoGlobal);
      this[a.idOperario + "_" + a.idHistoricoOperaciones].tTotal += a.tTotal;
      this[a.idOperario + "_" + a.idHistoricoOperaciones].tTotalHistoricoOperacion = Math.max(this[a.idOperario + "_" + a.idHistoricoOperaciones].tTotalHistoricoOperacion, a.tTotalHistoricoOperacion);
      this[a.idOperario + "_" + a.idHistoricoOperaciones].terminado = this[a.idOperario + "_" + a.idHistoricoOperaciones].terminado && a.terminado;
    }, Object.create(null));

    //FOREACH CON EL IF DE TIEMPO ESTIMADO
    //Si tReal de la operacion recibida < tReal operacion total (operacion cortada por fecha): usar tEstimado ficticio
    //Si tReal de la operacion recibida = tReal operacion total (operacion no cortada por fecha):
    //  Si terminada=1: usar tEstimado global
    //  Si terminada=0:
    //    Si tReal de la operacion recibida <= tEstimado: tEst=tReal
    //    Si tReal de la operacion recibida > tEstimado: tEst=tEst
    groupByOperarioYHistoricoOperaciones.forEach(function (a) {
      if (a.tTotal < a.tTotalHistoricoOperacion) {//(operacion cortada por fecha)
        a.tEstimadoFinal = a.tEstimado;
      } else {//(operacion no cortada por fecha)
        if (a.terminado) {
          a.tEstimadoFinal = a.tEstimadoGlobal;
        } else {
          if (a.tTotal <= a.tEstimadoGlobal) {
            a.tEstimadoFinal = a.tTotal;
          } else {
            a.tEstimadoFinal = a.tEstimadoGlobal;
          }
        }
      }
    });

    var groupByOperario = [];

    //GROUP BY POR OPERARIO
    groupByOperarioYHistoricoOperaciones.forEach(function (a) {
      if (!this[a.idOperario]) {
        this[a.idOperario] = {
          idOperario: a.idOperario, operario: a.operario, idsHistoricoOperaciones: [a.idHistoricoOperaciones], tEjecucion: 0, tParadaDisponibilidad: 0, tMicroParadaDisponibilidad: 0,
          tMantenimientoDisponibilidad: 0, tAlarmaDisponibilidad: 0, tApagadaDisponibilidad: 0, tParadaRendimiento: 0, tMicroParadaRendimiento: 0, tMantenimientoRendimiento: 0,
          tAlarmaRendimiento: 0, tApagadaRendimiento: 0, tParadaCalidad: 0, tMicroParadaCalidad: 0, tMantenimientoCalidad: 0, tAlarmaCalidad: 0, tApagadaCalidad: 0, tNegativoCalidad: 0,
          tEstimadoFinal: 0, tEstimadoGlobal: 0, tTotal: 0, tTotalHistoricoOperacion: 0
        };
        groupByOperario.push(this[a.idOperario]);
      }
      this[a.idOperario].idsHistoricoOperaciones.push(a.idHistoricoOperaciones);
      this[a.idOperario].tEjecucion += a.tEjecucion;
      this[a.idOperario].tParadaDisponibilidad += a.tParadaDisponibilidad;
      this[a.idOperario].tMicroParadaDisponibilidad += a.tMicroParadaDisponibilidad;
      this[a.idOperario].tMantenimientoDisponibilidad += a.tMantenimientoDisponibilidad;
      this[a.idOperario].tAlarmaDisponibilidad += a.tAlarmaDisponibilidad;
      this[a.idOperario].tApagadaDisponibilidad += a.tApagadaDisponibilidad;
      this[a.idOperario].tParadaRendimiento += a.tParadaRendimiento;
      this[a.idOperario].tMicroParadaRendimiento += a.tMicroParadaRendimiento;
      this[a.idOperario].tMantenimientoRendimiento += a.tMantenimientoRendimiento;
      this[a.idOperario].tAlarmaRendimiento += a.tAlarmaRendimiento;
      this[a.idOperario].tApagadaRendimiento += a.tApagadaRendimiento;
      this[a.idOperario].tParadaCalidad += a.tParadaCalidad;
      this[a.idOperario].tMicroParadaCalidad += a.tMicroParadaCalidad;
      this[a.idOperario].tMantenimientoCalidad += a.tMantenimientoCalidad;
      this[a.idOperario].tAlarmaCalidad += a.tAlarmaCalidad;
      this[a.idOperario].tApagadaCalidad += a.tApagadaCalidad;
      this[a.idOperario].tNegativoCalidad += a.tNegativoCalidad;
      this[a.idOperario].tEstimadoFinal += a.tEstimadoFinal;
      this[a.idOperario].tEstimadoGlobal += a.tEstimadoGlobal;
      this[a.idOperario].tTotal += a.tTotal;
      this[a.idOperario].tTotalHistoricoOperacion += a.tTotalHistoricoOperacion;
    }, Object.create(null));

    groupByOperario.forEach(function (registro) {

      var tReal = registro.tTotal;//registro.tEjecucion + registro.tNegativoCalidad;

      var tEjecuciones = registro.tEjecucion;
      var tEstimado = registro.tEstimadoGlobal;

      var tRendimientoNegativos = (tEjecuciones - tEstimado) + registro.tParadaRendimiento + registro.tMicroParadaRendimiento + registro.tMantenimientoRendimiento + registro.tAlarmaRendimiento + registro.tApagadaRendimiento;
      var tCalidadNegativos = registro.tParadaCalidad + registro.tMicroParadaCalidad + registro.tMantenimientoCalidad + registro.tAlarmaCalidad + registro.tApagadaCalidad + registro.tNegativoCalidad;

      var desvioPorcentaje = 0
      if (tEstimado == 0) {
        desvioPorcentaje = 100;
      } else {
        desvioPorcentaje = (100 - (((registro.tTotal) / tEstimado) * 100));//(((tEstimado - tRendimientoNegativos - tCalidadNegativos) / tEstimado) * 100));
      }

      this.datosGridOperarios.push(
        {
          idoperario: registro.idOperario,
          operario: registro.operario,
          numeroOperaciones: registro.idsHistoricoOperaciones.filter((v, i, a) => a.indexOf(v) === i).length,
          horasEstimadas: registro.tEstimadoGlobal,
          horasEstimadasReales: tEstimado,
          horasReales: tReal,//tEjecuciones,
          desvioRendimiento: tRendimientoNegativos,
          desvioCalidad: tCalidadNegativos,
          desvioPorcentaje: desvioPorcentaje
        }
      );

    }, this);

  }
  cargarDatosGridPiezas() {
    /* AQUI CANTIDAD (hecha / total) SE CUENTA POR PIEZAS */

    this.datosGridPiezas = [];

    //GROUP BY POR OPERACION Y HISTORICO OPERACIONES (PARA CONSEGUIR EL ESTIMADO DEL HISTORICO)
    var groupByPiezaYHistoricoOperaciones = [];

    this.dataInforme.forEach(function (a) {
      if (!this[a.piezaId + "_" + a.idHistoricoOperaciones]) {
        this[a.piezaId + "_" + a.idHistoricoOperaciones] = {
          numeroOF: a.numeroOF,
          piezaId: a.piezaId, 
          pieza: a.pieza, 
          idHistoricoOperaciones: a.idHistoricoOperaciones, 
          tEjecucion: 0,
          tParadaDisponibilidad: 0, 
          tMicroParadaDisponibilidad: 0,
          tMantenimientoDisponibilidad: 0,
          tAlarmaDisponibilidad: 0,
          tApagadaDisponibilidad: 0, 
          tParadaRendimiento: 0, 
          tMicroParadaRendimiento: 0, 
          tMantenimientoRendimiento: 0,
          tAlarmaRendimiento: 0, 
          tApagadaRendimiento: 0, 
          tParadaCalidad: 0, 
          tMicroParadaCalidad: 0,
          tMantenimientoCalidad: 0, 
          tAlarmaCalidad: 0, 
          tApagadaCalidad: 0, 
          tNegativoCalidad: 0,
          tEstimado: 0, 
          tEstimadoGlobal: 0,
          tTotal: 0, 
          tTotalHistoricoOperacion: 0, 
          terminado: a.terminado, 
          cantidadTotal: 0, 
          terminadas: 0
        };
        groupByPiezaYHistoricoOperaciones.push(this[a.piezaId + "_" + a.idHistoricoOperaciones]);
      }
      this[a.piezaId + "_" + a.idHistoricoOperaciones].tEjecucion += a.tEjecucion;
      this[a.piezaId + "_" + a.idHistoricoOperaciones].tParadaDisponibilidad += a.tParadaDisponibilidad;
      this[a.piezaId + "_" + a.idHistoricoOperaciones].tMicroParadaDisponibilidad += a.tMicroParadaDisponibilidad;
      this[a.piezaId + "_" + a.idHistoricoOperaciones].tMantenimientoDisponibilidad += a.tMantenimientoDisponibilidad;
      this[a.piezaId + "_" + a.idHistoricoOperaciones].tAlarmaDisponibilidad += a.tAlarmaDisponibilidad;
      this[a.piezaId + "_" + a.idHistoricoOperaciones].tApagadaDisponibilidad += a.tApagadaDisponibilidad;
      this[a.piezaId + "_" + a.idHistoricoOperaciones].tParadaRendimiento += a.tParadaRendimiento;
      this[a.piezaId + "_" + a.idHistoricoOperaciones].tMicroParadaRendimiento += a.tMicroParadaRendimiento;
      this[a.piezaId + "_" + a.idHistoricoOperaciones].tMantenimientoRendimiento += a.tMantenimientoRendimiento;
      this[a.piezaId + "_" + a.idHistoricoOperaciones].tAlarmaRendimiento += a.tAlarmaRendimiento;
      this[a.piezaId + "_" + a.idHistoricoOperaciones].tApagadaRendimiento += a.tApagadaRendimiento;
      this[a.piezaId + "_" + a.idHistoricoOperaciones].tParadaCalidad += a.tParadaCalidad;
      this[a.piezaId + "_" + a.idHistoricoOperaciones].tMicroParadaCalidad += a.tMicroParadaCalidad;
      this[a.piezaId + "_" + a.idHistoricoOperaciones].tMantenimientoCalidad += a.tMantenimientoCalidad;
      this[a.piezaId + "_" + a.idHistoricoOperaciones].tAlarmaCalidad += a.tAlarmaCalidad;
      this[a.piezaId + "_" + a.idHistoricoOperaciones].tApagadaCalidad += a.tApagadaCalidad;
      this[a.piezaId + "_" + a.idHistoricoOperaciones].tNegativoCalidad += a.tNegativoCalidad;
      this[a.piezaId + "_" + a.idHistoricoOperaciones].tEstimado += a.tEstimado;
      this[a.piezaId + "_" + a.idHistoricoOperaciones].tEstimadoGlobal = Math.max(this[a.piezaId + "_" + a.idHistoricoOperaciones].tEstimadoGlobal, a.tEstimadoGlobal);
      this[a.piezaId + "_" + a.idHistoricoOperaciones].tTotal += a.tTotal;
      this[a.piezaId + "_" + a.idHistoricoOperaciones].tTotalHistoricoOperacion = Math.max(this[a.piezaId + "_" + a.idHistoricoOperaciones].tTotalHistoricoOperacion, a.tTotalHistoricoOperacion);
      this[a.piezaId + "_" + a.idHistoricoOperaciones].terminado = this[a.piezaId + "_" + a.idHistoricoOperaciones].terminado && a.terminado;
      this[a.piezaId + "_" + a.idHistoricoOperaciones].cantidadTotal = a.piezaCantidad;
      this[a.piezaId + "_" + a.idHistoricoOperaciones].terminadas = a.piezaTerminadas;
    }, Object.create(null));

    //FOREACH CON EL IF DE TIEMPO ESTIMADO
    //Si tReal de la operacion recibida < tReal operacion total (operacion cortada por fecha): usar tEstimado ficticio
    //Si tReal de la operacion recibida = tReal operacion total (operacion no cortada por fecha):
    //  Si terminada=1: usar tEstimado global
    //  Si terminada=0:
    //    Si tReal de la operacion recibida <= tEstimado: tEst=tReal
    //    Si tReal de la operacion recibida > tEstimado: tEst=tEst
    groupByPiezaYHistoricoOperaciones.forEach(function (a) {
      if (a.tTotal < a.tTotalHistoricoOperacion) {//(operacion cortada por fecha)
        a.tEstimadoFinal = a.tEstimado;
      } else {//(operacion no cortada por fecha)
        if (a.terminado) {
          a.tEstimadoFinal = a.tEstimadoGlobal;
        } else {
          if (a.tTotal <= a.tEstimadoGlobal) {
            a.tEstimadoFinal = a.tTotal;
          } else {
            a.tEstimadoFinal = a.tEstimadoGlobal;
          }
        }
      }
    });

    //GROUP BY POR PIEZA
    var groupByPieza = [];

    groupByPiezaYHistoricoOperaciones.forEach(function (a) {
      if (!this[a.piezaId]) {
        this[a.piezaId] = {
          numeroOF: a.numeroOF,
          piezaId: a.piezaId,
          pieza: a.pieza,
          idsHistoricoOperaciones: [a.idHistoricoOperaciones], 
          tEjecucion: 0, 
          tParadaDisponibilidad: 0, 
          tMicroParadaDisponibilidad: 0,
          tMantenimientoDisponibilidad: 0, 
          tAlarmaDisponibilidad: 0,
          tApagadaDisponibilidad: 0,
          tParadaRendimiento: 0,
          tMicroParadaRendimiento: 0,
          tMantenimientoRendimiento: 0,
          tAlarmaRendimiento: 0,
          tApagadaRendimiento: 0,
          tParadaCalidad: 0,
          tMicroParadaCalidad: 0,
          tMantenimientoCalidad: 0,
          tAlarmaCalidad: 0,
          tApagadaCalidad: 0,
          tNegativoCalidad: 0,
          tEstimadoFinal: 0,
          tEstimadoGlobal: 0,
          tTotal: 0,
          tTotalHistoricoOperacion: 0,
          cantidadTotal: 0,
          terminadas: 0
        };
        groupByPieza.push(this[a.piezaId]);
      }
      this[a.piezaId].idsHistoricoOperaciones.push(a.idHistoricoOperaciones);
      this[a.piezaId].tEjecucion += a.tEjecucion;
      this[a.piezaId].tParadaDisponibilidad += a.tParadaDisponibilidad;
      this[a.piezaId].tMicroParadaDisponibilidad += a.tMicroParadaDisponibilidad;
      this[a.piezaId].tMantenimientoDisponibilidad += a.tMantenimientoDisponibilidad;
      this[a.piezaId].tAlarmaDisponibilidad += a.tAlarmaDisponibilidad;
      this[a.piezaId].tApagadaDisponibilidad += a.tApagadaDisponibilidad;
      this[a.piezaId].tParadaRendimiento += a.tParadaRendimiento;
      this[a.piezaId].tMicroParadaRendimiento += a.tMicroParadaRendimiento;
      this[a.piezaId].tMantenimientoRendimiento += a.tMantenimientoRendimiento;
      this[a.piezaId].tAlarmaRendimiento += a.tAlarmaRendimiento;
      this[a.piezaId].tApagadaRendimiento += a.tApagadaRendimiento;
      this[a.piezaId].tParadaCalidad += a.tParadaCalidad;
      this[a.piezaId].tMicroParadaCalidad += a.tMicroParadaCalidad;
      this[a.piezaId].tMantenimientoCalidad += a.tMantenimientoCalidad;
      this[a.piezaId].tAlarmaCalidad += a.tAlarmaCalidad;
      this[a.piezaId].tApagadaCalidad += a.tApagadaCalidad;
      this[a.piezaId].tNegativoCalidad += a.tNegativoCalidad;
      this[a.piezaId].tEstimadoFinal += a.tEstimadoFinal;
      this[a.piezaId].tEstimadoGlobal += a.tEstimadoGlobal;
      this[a.piezaId].tTotal += a.tTotal;
      this[a.piezaId].tTotalHistoricoOperacion += a.tTotalHistoricoOperacion;
      this[a.piezaId].cantidadTotal = a.cantidadTotal;
      this[a.piezaId].terminadas = a.terminadas;
    }, Object.create(null));

    groupByPieza.forEach(function (registro) {

      var tReal = registro.tTotal;//registro.tEjecucion + registro.tNegativoCalidad;

      var tEjecuciones = registro.tEjecucion;
      var tEstimado = registro.tEstimadoGlobal;

      var tRendimientoNegativos = (tEjecuciones - tEstimado) + registro.tParadaRendimiento + registro.tMicroParadaRendimiento + registro.tMantenimientoRendimiento + registro.tAlarmaRendimiento + registro.tApagadaRendimiento;
      var tCalidadNegativos = registro.tParadaCalidad + registro.tMicroParadaCalidad + registro.tMantenimientoCalidad + registro.tAlarmaCalidad + registro.tApagadaCalidad + registro.tNegativoCalidad;

      var desvioPorcentaje = 0
      if (tEstimado == 0) {
        desvioPorcentaje = 100;
      } else {
        desvioPorcentaje = (100 - (((registro.tTotal) / tEstimado) * 100));//(((tEstimado - tRendimientoNegativos - tCalidadNegativos) / tEstimado) * 100));
      }

      this.datosGridPiezas.push(
        {
          idpieza: registro.piezaId,
          numeroOF: registro.numeroOF,
          horasHistoricoOperacion: registro.tTotalHistoricoOperacion,
          pieza: registro.pieza,
          numeroOperaciones: registro.idsHistoricoOperaciones.filter((v, i, a) => a.indexOf(v) === i).length,
          horasEstimadas: registro.tEstimadoGlobal,
          horasEstimadasReales: tEstimado,
          horasReales: tReal,//tEjecuciones,
          desvioRendimiento: tRendimientoNegativos,
          desvioCalidad: tCalidadNegativos,
          desvioPorcentaje: desvioPorcentaje,
          nTerminado: registro.terminadas + '/' + registro.cantidadTotal
        }
      );

    }, this);

  }
  cargarDatosGridClientes() {
    /* AQUI CANTIDAD (hecha / total) SE CUENTA POR PIEZAS */

    this.datosGridClientes = [];

    //GROUP BY POR CLIENTE Y HISTORICO OPERACIONES (PARA CONSEGUIR EL ESTIMADO DEL HISTORICO)
    var groupByClienteYHistoricoOperaciones = [];

    this.dataInforme.forEach(function (a) {
      if (!this[a.idCliente + "_" + a.idHistoricoOperaciones]) {
        this[a.idCliente + "_" + a.idHistoricoOperaciones] = {
          numeroOF: a.numeroOF,
          idCliente: a.idCliente, cliente: a.cliente, idHistoricoOperaciones: a.idHistoricoOperaciones, tEjecucion: 0, tParadaDisponibilidad: 0, tMicroParadaDisponibilidad: 0,
          tMantenimientoDisponibilidad: 0, tAlarmaDisponibilidad: 0, tApagadaDisponibilidad: 0, tParadaRendimiento: 0, tMicroParadaRendimiento: 0, tMantenimientoRendimiento: 0,
          tAlarmaRendimiento: 0, tApagadaRendimiento: 0, tParadaCalidad: 0, tMicroParadaCalidad: 0, tMantenimientoCalidad: 0, tAlarmaCalidad: 0, tApagadaCalidad: 0, tNegativoCalidad: 0,
          tEstimado: 0, tEstimadoGlobal: 0, tTotal: 0, tTotalHistoricoOperacion: 0, terminado: a.terminado, piezaCantidad: 0, piezaTerminadas: 0, idPieza: 0
        };
        groupByClienteYHistoricoOperaciones.push(this[a.idCliente + "_" + a.idHistoricoOperaciones]);
      }
      this[a.idCliente + "_" + a.idHistoricoOperaciones].tEjecucion += a.tEjecucion;
      this[a.idCliente + "_" + a.idHistoricoOperaciones].tParadaDisponibilidad += a.tParadaDisponibilidad;
      this[a.idCliente + "_" + a.idHistoricoOperaciones].tMicroParadaDisponibilidad += a.tMicroParadaDisponibilidad;
      this[a.idCliente + "_" + a.idHistoricoOperaciones].tMantenimientoDisponibilidad += a.tMantenimientoDisponibilidad;
      this[a.idCliente + "_" + a.idHistoricoOperaciones].tAlarmaDisponibilidad += a.tAlarmaDisponibilidad;
      this[a.idCliente + "_" + a.idHistoricoOperaciones].tApagadaDisponibilidad += a.tApagadaDisponibilidad;
      this[a.idCliente + "_" + a.idHistoricoOperaciones].tParadaRendimiento += a.tParadaRendimiento;
      this[a.idCliente + "_" + a.idHistoricoOperaciones].tMicroParadaRendimiento += a.tMicroParadaRendimiento;
      this[a.idCliente + "_" + a.idHistoricoOperaciones].tMantenimientoRendimiento += a.tMantenimientoRendimiento;
      this[a.idCliente + "_" + a.idHistoricoOperaciones].tAlarmaRendimiento += a.tAlarmaRendimiento;
      this[a.idCliente + "_" + a.idHistoricoOperaciones].tApagadaRendimiento += a.tApagadaRendimiento;
      this[a.idCliente + "_" + a.idHistoricoOperaciones].tParadaCalidad += a.tParadaCalidad;
      this[a.idCliente + "_" + a.idHistoricoOperaciones].tMicroParadaCalidad += a.tMicroParadaCalidad;
      this[a.idCliente + "_" + a.idHistoricoOperaciones].tMantenimientoCalidad += a.tMantenimientoCalidad;
      this[a.idCliente + "_" + a.idHistoricoOperaciones].tAlarmaCalidad += a.tAlarmaCalidad;
      this[a.idCliente + "_" + a.idHistoricoOperaciones].tApagadaCalidad += a.tApagadaCalidad;
      this[a.idCliente + "_" + a.idHistoricoOperaciones].tNegativoCalidad += a.tNegativoCalidad;
      this[a.idCliente + "_" + a.idHistoricoOperaciones].tEstimado += a.tEstimado;
      this[a.idCliente + "_" + a.idHistoricoOperaciones].tEstimadoGlobal = Math.max(this[a.idCliente + "_" + a.idHistoricoOperaciones].tEstimadoGlobal, a.tEstimadoGlobal);
      this[a.idCliente + "_" + a.idHistoricoOperaciones].tTotal += a.tTotal;
      this[a.idCliente + "_" + a.idHistoricoOperaciones].tTotalHistoricoOperacion = Math.max(this[a.idCliente + "_" + a.idHistoricoOperaciones].tTotalHistoricoOperacion, a.tTotalHistoricoOperacion);
      this[a.idCliente + "_" + a.idHistoricoOperaciones].terminado = this[a.idCliente + "_" + a.idHistoricoOperaciones].terminado && a.terminado;
      this[a.idCliente + "_" + a.idHistoricoOperaciones].piezaCantidad = a.piezaCantidad;
      this[a.idCliente + "_" + a.idHistoricoOperaciones].piezaTerminadas = a.piezaTerminadas;
      this[a.idCliente + "_" + a.idHistoricoOperaciones].idPieza = a.piezaId;
    }, Object.create(null));

    //FOREACH CON EL IF DE TIEMPO ESTIMADO
    //Si tReal de la operacion recibida < tReal operacion total (operacion cortada por fecha): usar tEstimado ficticio
    //Si tReal de la operacion recibida = tReal operacion total (operacion no cortada por fecha):
    //  Si terminada=1: usar tEstimado global
    //  Si terminada=0:
    //    Si tReal de la operacion recibida <= tEstimado: tEst=tReal
    //    Si tReal de la operacion recibida > tEstimado: tEst=tEst
    groupByClienteYHistoricoOperaciones.forEach(function (a) {
      if (a.tTotal < a.tTotalHistoricoOperacion) {//(operacion cortada por fecha)
        a.tEstimadoFinal = a.tEstimado;
      } else {//(operacion no cortada por fecha)
        if (a.terminado) {
          a.tEstimadoFinal = a.tEstimadoGlobal;
        } else {
          if (a.tTotal <= a.tEstimadoGlobal) {
            a.tEstimadoFinal = a.tTotal;
          } else {
            a.tEstimadoFinal = a.tEstimadoGlobal;
          }
        }
      }
    });

    var groupByCliente = [];

    //GROUP BY POR CLIENTE
    groupByClienteYHistoricoOperaciones.forEach(function (a) {
      if (!this[a.cliente]) {
        this[a.cliente] = {
          idCliente: a.idCliente, cliente: a.cliente, idsHistoricoOperaciones: [a.idHistoricoOperaciones], tEjecucion: 0, tParadaDisponibilidad: 0, tMicroParadaDisponibilidad: 0,
          tMantenimientoDisponibilidad: 0, tAlarmaDisponibilidad: 0, tApagadaDisponibilidad: 0, tParadaRendimiento: 0, tMicroParadaRendimiento: 0, tMantenimientoRendimiento: 0,
          tAlarmaRendimiento: 0, tApagadaRendimiento: 0, tParadaCalidad: 0, tMicroParadaCalidad: 0, tMantenimientoCalidad: 0, tAlarmaCalidad: 0, tApagadaCalidad: 0, tNegativoCalidad: 0,
          tEstimadoFinal: 0, tEstimadoGlobal: 0, tTotal: 0, tTotalHistoricoOperacion: 0, cantidadTotal: 0, terminadas: 0, idsPiezas: []
        };
        groupByCliente.push(this[a.cliente]);
      }
      this[a.cliente].idsHistoricoOperaciones.push(a.idHistoricoOperaciones);
      this[a.cliente].tEjecucion += a.tEjecucion;
      this[a.cliente].tParadaDisponibilidad += a.tParadaDisponibilidad;
      this[a.cliente].tMicroParadaDisponibilidad += a.tMicroParadaDisponibilidad;
      this[a.cliente].tMantenimientoDisponibilidad += a.tMantenimientoDisponibilidad;
      this[a.cliente].tAlarmaDisponibilidad += a.tAlarmaDisponibilidad;
      this[a.cliente].tApagadaDisponibilidad += a.tApagadaDisponibilidad;
      this[a.cliente].tParadaRendimiento += a.tParadaRendimiento;
      this[a.cliente].tMicroParadaRendimiento += a.tMicroParadaRendimiento;
      this[a.cliente].tMantenimientoRendimiento += a.tMantenimientoRendimiento;
      this[a.cliente].tAlarmaRendimiento += a.tAlarmaRendimiento;
      this[a.cliente].tApagadaRendimiento += a.tApagadaRendimiento;
      this[a.cliente].tParadaCalidad += a.tParadaCalidad;
      this[a.cliente].tMicroParadaCalidad += a.tMicroParadaCalidad;
      this[a.cliente].tMantenimientoCalidad += a.tMantenimientoCalidad;
      this[a.cliente].tAlarmaCalidad += a.tAlarmaCalidad;
      this[a.cliente].tApagadaCalidad += a.tApagadaCalidad;
      this[a.cliente].tNegativoCalidad += a.tNegativoCalidad;
      this[a.cliente].tEstimadoFinal += a.tEstimadoFinal;
      this[a.cliente].tEstimadoGlobal += a.tEstimadoGlobal;
      this[a.cliente].tTotal += a.tTotal;
      this[a.cliente].tTotalHistoricoOperacion += a.tTotalHistoricoOperacion;

      if (!this[a.cliente].idsPiezas.includes(a.idPieza)) {
        this[a.cliente].cantidadTotal += a.piezaCantidad;
        this[a.cliente].terminadas += a.piezaTerminadas;
        this[a.cliente].idsPiezas.push(a.idPieza);
      }
    }, Object.create(null));

    groupByCliente.forEach(function (registro) {

      var tReal = registro.tTotal;//registro.tEjecucion + registro.tNegativoCalidad;

      var tEjecuciones = registro.tEjecucion;
      var tEstimado = registro.tEstimadoGlobal;

      var tRendimientoNegativos = (tEjecuciones - tEstimado) + registro.tParadaRendimiento + registro.tMicroParadaRendimiento + registro.tMantenimientoRendimiento + registro.tAlarmaRendimiento + registro.tApagadaRendimiento;
      var tCalidadNegativos = registro.tParadaCalidad + registro.tMicroParadaCalidad + registro.tMantenimientoCalidad + registro.tAlarmaCalidad + registro.tApagadaCalidad + registro.tNegativoCalidad;

      var desvioPorcentaje = 0
      if (tEstimado == 0) {
        desvioPorcentaje = 100;
      } else {
        desvioPorcentaje = (100 - (((registro.tTotal) / tEstimado) * 100));//(((tEstimado - tRendimientoNegativos - tCalidadNegativos) / tEstimado) * 100));
      }

      this.datosGridClientes.push(
        {
          idcliente: registro.idCliente,
          cliente: registro.cliente,
          horasHistoricoOperacion: registro.tTotalHistoricoOperacion,
          numeroOperaciones: registro.idsHistoricoOperaciones.filter((v, i, a) => a.indexOf(v) === i).length,
          horasEstimadas: registro.tEstimadoGlobal,
          horasEstimadasReales: tEstimado,
          horasReales: tReal,//tEjecuciones,
          desvioRendimiento: tRendimientoNegativos,
          desvioCalidad: tCalidadNegativos,
          desvioPorcentaje: desvioPorcentaje,
          nTerminado: registro.terminadas + '/' + registro.cantidadTotal
        }
      );

    }, this);

  }
  // CARGAR GRIDS END --------------------------------------------------------------------------------------------------------------------------------------

  // CARGAR GRAFICOS ---------------------------------------------------------------------------------------------------------------------------------------
  cargarGraficoOperaciones() {

    var arrayOperaciones = [];
    var arrayValores = [this.translateService.instant('desvio')];
    var arrayOperacionesT = [];
    var arrayValoresT = [this.translateService.instant('desvio')];

    this.dataInformeGroupByHistoricoOperacion.forEach(
      function (registro) {

        var tEjecuciones = registro.tEjecucion;
        var tEstimado = registro.tEstimado;

        var tRendimientoNegativos = (tEjecuciones - tEstimado) + registro.tParadaRendimiento + registro.tMicroParadaRendimiento + registro.tMantenimientoRendimiento + registro.tAlarmaRendimiento + registro.tApagadaRendimiento;
        var tCalidadNegativos = registro.tParadaCalidad + registro.tMicroParadaCalidad + registro.tMantenimientoCalidad + registro.tAlarmaCalidad + registro.tApagadaCalidad + registro.tNegativoCalidad;

        var desvioTotal = tRendimientoNegativos + tCalidadNegativos;

        arrayOperaciones.push(registro.operacion);
        arrayValores.push(desvioTotal);
        arrayOperacionesT.push(registro.operacion);
        arrayValoresT.push(desvioTotal);
      }, this);

    //SI SON MENOS DE 5 RELLENAR CON VACIOS PARA QUE LAS BARRAS DEL GRAFICO NO QUEDEN MUY ANCHAS
    for (var i = this.myFunctions.quitarRepeticiones(arrayOperaciones).length; i <= 5; i++) {
      arrayOperaciones.push(' '.repeat(i));
      arrayValores.push(null);
      arrayOperacionesT.push(' '.repeat(i));
      arrayValoresT.push(null);
    }

    //GRAFICO OPERACIONES BARRAS

    var data = [arrayOperaciones, arrayValores];
    var ticks = this.getChartAxisRange([arrayOperacionesT, arrayValoresT]);

    this.graficoOperaciones.internal.config.axis_y_tick_values = ticks;
    this.graficoOperaciones.load({ columns: this.sortData(data) });

  }
  cargarGraficoMaquinas() {

    var arrayMaquinas = [];
    var arrayValores = [this.translateService.instant('desvio')];
    var arrayMaquinasT = [];
    var arrayValoresT = [this.translateService.instant('desvio')];

    this.datosGridMaquinas.forEach(function (registro) {

      var desvioTotal = registro.desvioRendimiento + registro.desvioCalidad;

      arrayMaquinas.push(registro.maquina);
      arrayValores.push(desvioTotal);
      arrayMaquinasT.push(registro.maquina);
      arrayValoresT.push(desvioTotal);

    }, this);

    //SI SON MENOS DE 5 RELLENAR CON VACIOS PARA QUE LAS BARRAS DEL GRAFICO NO QUEDEN MUY ANCHAS
    for (var i = arrayMaquinas.length; i <= 5; i++) {
      arrayMaquinas.push(' '.repeat(i));
      arrayValores.push(null);
      arrayMaquinasT.push(' '.repeat(i));
      arrayValoresT.push(null);
    }

    //GRAFICO MAQUINAS BARRAS
    var data = [arrayMaquinas, arrayValores];
    var ticks = this.getChartAxisRange([arrayMaquinasT, arrayValoresT]);

    this.graficoMaquinas.internal.config.axis_y_tick_values = ticks;
    this.graficoMaquinas.load({ columns: this.sortData(data) });

  }
  cargarGraficoOperarios() {

    var arrayOperarios = [];
    var arrayValores = [this.translateService.instant('desvio')];
    var arrayOperariosT = [];
    var arrayValoresT = [this.translateService.instant('desvio')];

    this.datosGridOperarios.forEach(function (registro) {

      var desvioTotal = registro.desvioRendimiento + registro.desvioCalidad;

      arrayOperarios.push(registro.operario);
      arrayValores.push(desvioTotal);
      arrayOperariosT.push(registro.operario);
      arrayValoresT.push(desvioTotal);

    }, this);

    //SI SON MENOS DE 5 RELLENAR CON VACIOS PARA QUE LAS BARRAS DEL GRAFICO NO QUEDEN MUY ANCHAS
    for (var i = arrayOperarios.length; i <= 5; i++) {
      arrayOperarios.push(' '.repeat(i));
      arrayValores.push(null);
      arrayOperariosT.push(' '.repeat(i));
      arrayValoresT.push(null);
    }

    //GRAFICO CLIENTES BARRAS
    var data = [arrayOperarios, arrayValores];
    var ticks = this.getChartAxisRange([arrayOperariosT, arrayValoresT]);

    this.graficoOperarios.internal.config.axis_y_tick_values = ticks;
    this.graficoOperarios.load({ columns: this.sortData(data) });

  }
  cargarGraficoPiezas() {

    var arrayPiezas = [];
    var arrayValores = [this.translateService.instant('desvio')];
    var arrayPiezasT = [];
    var arrayValoresT = [this.translateService.instant('desvio')];

    this.datosGridPiezas.forEach(function (registro) {

      var desvioTotal = registro.desvioRendimiento + registro.desvioCalidad;

      arrayPiezas.push(registro.pieza);
      arrayValores.push(desvioTotal);
      arrayPiezasT.push(registro.pieza);
      arrayValoresT.push(desvioTotal);

    }, this);

    //SI SON MENOS DE 5 RELLENAR CON VACIOS PARA QUE LAS BARRAS DEL GRAFICO NO QUEDEN MUY ANCHAS
    for (var i = arrayPiezas.length; i <= 5; i++) {
      arrayPiezas.push(' '.repeat(i));
      arrayValores.push(null);
      arrayPiezasT.push(' '.repeat(i));
      arrayValoresT.push(null);
    }

    //GRAFICO PIEZAS BARRAS
    var data = [arrayPiezas, arrayValores];
    var ticks = this.getChartAxisRange([arrayPiezasT, arrayValoresT]);

    this.graficoPiezas.internal.config.axis_y_tick_values = ticks;
    this.graficoPiezas.load({ columns: this.sortData(data) });

  }
  cargarGraficoClientes() {

    var arrayClientes = [];
    var arrayValores = [this.translateService.instant('desvio')];
    var arrayClientesT = [];
    var arrayValoresT = [this.translateService.instant('desvio')];

    this.datosGridClientes.forEach(function (registro) {

      var desvioTotal = registro.desvioRendimiento + registro.desvioCalidad;

      arrayClientes.push(registro.cliente);
      arrayValores.push(desvioTotal);
      arrayClientesT.push(registro.cliente);
      arrayValoresT.push(desvioTotal);

    }, this);

    //SI SON MENOS DE 5 RELLENAR CON VACIOS PARA QUE LAS BARRAS DEL GRAFICO NO QUEDEN MUY ANCHAS
    for (var i = arrayClientes.length; i <= 5; i++) {
      arrayClientes.push(' '.repeat(i));
      arrayValores.push(null);
      arrayClientesT.push(' '.repeat(i));
      arrayValoresT.push(null);
    }

    //GRAFICO CLIENTES BARRAS
    var data = [arrayClientes, arrayValores];
    var ticks = this.getChartAxisRange([arrayClientesT, arrayValoresT]);

    this.graficoClientes.internal.config.axis_y_tick_values = ticks;
    this.graficoClientes.load({ columns: this.sortData(data) });

  }
  // CARGAR GRAFICOS END -----------------------------------------------------------------------------------------------------------------------------------

  public onTabSelect(e) {

    var th = this;

    setTimeout(function () {
      th.graficoOperaciones.flush();
      th.graficoMaquinas.flush();
      th.graficoOperarios.flush();
      th.graficoPiezas.flush();
      th.graficoClientes.flush();
    }, 1);

  }

  //CLICK LINKS ---------------------------------------------------------------------------------------------------------------------------------------------
  abrirHistoricoPiezas_operaciones(dataItem) {
    var row = dataItem;
    var fini = "0";
    var ffin = "0";

    var listaIdTurnos = "0";
    var listaIdMaquinas = "0";
    var listaIdHerramientas = "0";
    var listaIdOperarios = "0";
    var listaIdOfs = "0";
    var listaIdClientes = "0";
    var listaIdPiezas = "0";
    var listaIdPartes = "0";
    var listaIdOperaciones = (row.idoperacion === -1) ? 0 : row.idoperacion;

    var enlace = '#/historicoPiezas/' + fini + "/" + ffin + "/" + listaIdTurnos + "/" + listaIdMaquinas + "/" + listaIdHerramientas + "/" + listaIdOperarios + "/" + listaIdOfs + "/" + listaIdClientes + "/" + listaIdPiezas + "/" + listaIdPartes + "/" + listaIdOperaciones;
    window.open(enlace, '_blank');
  }
  abrirHistoricoPiezas_maquinas(dataItem) {
    var row = dataItem;
    var fini = "0";
    var ffin = "0";

    var listaIdTurnos = "0";
    var listaIdMaquinas = (row.idmaquina === -1) ? 0 : row.idmaquina;
    var listaIdHerramientas = "0";
    var listaIdOperarios = "0";
    var listaIdOfs = "0";
    var listaIdClientes = "0";
    var listaIdPiezas = "0";
    var listaIdPartes = "0";
    var listaIdOperaciones = "0";

    var enlace = '#/historicoPiezas/' + fini + "/" + ffin + "/" + listaIdTurnos + "/" + listaIdMaquinas + "/" + listaIdHerramientas + "/" + listaIdOperarios + "/" + listaIdOfs + "/" + listaIdClientes + "/" + listaIdPiezas + "/" + listaIdPartes + "/" + listaIdOperaciones;
    window.open(enlace, '_blank');
  }
  abrirHistoricoPiezas_operarios(dataItem) {
    var row = dataItem;
    var fini = "0";
    var ffin = "0";

    var listaIdTurnos = "0";
    var listaIdMaquinas = "0";
    var listaIdHerramientas = "0";
    var listaIdOperarios = (row.idoperario === -1) ? 0 : row.idoperario;
    var listaIdOfs = "0";
    var listaIdClientes = "0";
    var listaIdPiezas = "0";
    var listaIdPartes = "0";
    var listaIdOperaciones = "0";

    var enlace = '#/historicoPiezas/' + fini + "/" + ffin + "/" + listaIdTurnos + "/" + listaIdMaquinas + "/" + listaIdHerramientas + "/" + listaIdOperarios + "/" + listaIdOfs + "/" + listaIdClientes + "/" + listaIdPiezas + "/" + listaIdPartes + "/" + listaIdOperaciones;
    window.open(enlace, '_blank');
  }
  abrirHistoricoPiezas_piezas(dataItem) {
    var row = dataItem;
    var fini = "0";
    var ffin = "0";

    var listaIdTurnos = "0";
    var listaIdMaquinas = "0";
    var listaIdHerramientas = "0";
    var listaIdOperarios = "0";
    var listaIdOfs = "0";
    var listaIdClientes = "0";
    var listaIdPiezas = (row.idpieza === -1) ? 0 : row.idpieza;
    var listaIdPartes = "0";
    var listaIdOperaciones = "0";

    var enlace = '#/historicoPiezas/' + fini + "/" + ffin + "/" + listaIdTurnos + "/" + listaIdMaquinas + "/" + listaIdHerramientas + "/" + listaIdOperarios + "/" + listaIdOfs + "/" + listaIdClientes + "/" + listaIdPiezas + "/" + listaIdPartes + "/" + listaIdOperaciones;
    window.open(enlace, '_blank');
  }
  abrirHistoricoPiezas_clientes(dataItem) {
    var row = dataItem;
    var fini = "0";
    var ffin = "0";

    var listaIdTurnos = "0";
    var listaIdMaquinas = "0";
    var listaIdHerramientas = "0";
    var listaIdOperarios = "0";
    var listaIdOfs = "0";
    var listaIdClientes = (row.idcliente === -1) ? 0 : row.idcliente;
    var listaIdPiezas = "0";
    var listaIdPartes = "0";
    var listaIdOperaciones = "0";

    var enlace = '#/historicoPiezas/' + fini + "/" + ffin + "/" + listaIdTurnos + "/" + listaIdMaquinas + "/" + listaIdHerramientas + "/" + listaIdOperarios + "/" + listaIdOfs + "/" + listaIdClientes + "/" + listaIdPiezas + "/" + listaIdPartes + "/" + listaIdOperaciones;
    window.open(enlace, '_blank');
  }
  //CLICK LINKS END -----------------------------------------------------------------------------------------------------------------------------------------

  // HELPERS ------------------------------------------------------------------------------------------------------------------------------------------------
  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }
  public sortData(unsortedData) {
    var sorted = unsortedData.map(function (row) {
      return row.slice();
    })
    var name = sorted[1].splice(0, 1);
    var datapoints = sorted[1].map(function (d, i) {
      return [sorted[0][i], d];
    });
    var sortedData = datapoints.sort(function (a, b) {
      return b[1] - a[1];
    });
    sorted[1] = sortedData.map(function (point, i) {
      sorted[0][i] = point[0];
      return point[1];
    });
    sorted[1] = name.concat(sorted[1]);
    sorted[0].splice(0, 0, 'x');
    return sorted;
  }
  public getChartAxisRange(data) {
    data[1].shift();

    var nmax = Math.max.apply(null, data[1]) / 3600
    var smax = Math.abs(nmax).toString().split('.')
    var dmax = Math.pow(10, smax[0].length - 1)
    var nmin = Math.min.apply(null, data[1]) / 3600
    var smin = Math.abs(nmin).toString().split('.')
    var dmin = Math.pow(10, smin[0].length - 1)

    if (nmin > 0) {
      nmin = 0
    }

    if (dmax > dmin) {
      var result = this.range(Math.round((nmin - dmax) / dmax) * dmax, nmax + dmax, dmax);
      return (result.map(x => x * 3600));
    }
    if (dmax < dmin) {
      var result = this.range(Math.round((nmin - dmin) / dmin) * dmin, nmax + dmin, dmin);
      return (result.map(x => x * 3600));
    }
    if (dmax == dmin) {
      var result = this.range(Math.round((nmin - dmin) / dmin) * dmin, Math.round((nmax + dmin) / dmax) * dmax, dmin);
      return (result.map(x => x * 3600));
    }
  }
  public range(start, end, step = 1) {
    const len = Math.floor((end - start) / step) + 1
    return Array(len).fill(0).map((_, idx) => start + (idx * step))
  }
  public acortarDatosGraficoDonuts(arrayDatos) {
    //Si hay valores repetidos se agrupan
    var nuevoArray = [];
    arrayDatos.forEach(function (elementArray, indexArray) {
      var contains = false;
      var i = -1;
      nuevoArray.forEach(function (elementNuevoArray, indexNuevoArray) {
        if (elementArray[0] == elementNuevoArray[0]) {
          contains = true;
          i = indexNuevoArray;
        }
      });
      if (contains) {
        nuevoArray[i][1] = nuevoArray[i][1] + elementArray[1];
      } else {
        nuevoArray.push([elementArray[0], elementArray[1]]);
      }
    });

    //Acortar los datos
    if (nuevoArray.length < 10) {
      return nuevoArray;
    } else {
      var arrayParaEnsenar = nuevoArray.slice(0, 9);
      var arrayParaAgrupar = nuevoArray.slice(9, nuevoArray.length);
      var totalOtros = 0;
      arrayParaAgrupar.forEach(function (obj) {
        totalOtros = totalOtros + obj[1];
      });
      arrayParaEnsenar.push(['Otros', totalOtros]);
      return arrayParaEnsenar;
    }
  }
  public updateDonutLeyenda(grafico, oldData, newData) {

    var nombresOldData = oldData.map(function (x) { return x[0]; });
    var nombresNewData = newData.map(function (x) { return x[0]; });

    var nombresAEliminar = [];
    for (let value of nombresOldData) {
      if (nombresNewData.indexOf(value) === -1) {
        nombresAEliminar.push(value);
      }
    }

    var names = {};
    newData.forEach(function (a) {
      names[a[0]] = a[0] + " (" + this.myFunctions.secondsTo_HH_MM_SS(a[1]) + ")";
    }, this);

    grafico.load({
      columns: newData,
      names: names
    });
    grafico.unload({
      ids: nombresAEliminar
    });
  }
  public cortarLeyenda(inputText, trimmed = false) {

    var font = "12px sans-serif"; //DIMESIONES LEYENDA C3

    var canvas = document.createElement("canvas");
    var context = canvas.getContext("2d");
    context.font = font;
    var width = context.measureText(inputText).width;

    if (width > 140) {
      return this.cortarLeyenda(inputText.substring(0, inputText.length - 1), true);
    } else if (trimmed) {
      return inputText + "...";
    } else {
      return inputText;
    }

  }
  // HELPERS END --------------------------------------------------------------------------------------------------------------------------------------------
}
