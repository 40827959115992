<form [formGroup]="form" (ngSubmit)="onSubmit()">

  <div class="row">
    <!--CARACTERISTICAS-->
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h3>
                <label ID="lCaracteristicas" CssClass="">{{ 'caracteristicas' | translate }}</label>
              </h3>
              <div class="plegarpanel"></div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <!--Nombre-->
                  <div class="form-group">
                    <kendo-label text="{{ 'nombre' | translate }}">
                      <kendo-textbox formControlName="nombre"></kendo-textbox>
                    </kendo-label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="row" style="margin: -5px 0 15px -17px;">

    <!--Presion-->
    <div class="col-md-3" style="padding-right: 0px;">
      <div class="row extrusoras-panel-rangos">
        <div class="col-md-12 extrusoras-panel-rangos-header">
          <label class="extrusoras-panel-rangos-header-titulo">{{ 'presion' | translate}}</label>
          <kendo-switch formControlName="presion_activo" (valueChange)="onChangePresion($event)" [onLabel]="' '" [offLabel]="' '" style="float: right; margin-top: 2px;"></kendo-switch>
        </div>
        <div class="col-md-12 extrusoras-panel-rangos-body">
          <div class="row" style="margin-top: 7px;">
            <div class="col-md-4">
              <kendo-label text="{{ 'min' | translate}}">
                <kendo-numerictextbox formControlName="presion_min" class="form-control" [disabled]="!f.presion_activo.value" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
              </kendo-label>
            </div>
            <div class="col-md-4">
              <kendo-label text="{{ 'max' | translate}}">
                <kendo-numerictextbox formControlName="presion_max" class="form-control" [disabled]="!f.presion_activo.value" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
              </kendo-label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Rendimiento/Tasa de transporte-->
    <div class="col-md-3" style="padding-right: 0px;">
      <div class="row extrusoras-panel-rangos">
        <div class="col-md-12 extrusoras-panel-rangos-header">
          <label class="extrusoras-panel-rangos-header-titulo">{{ 'rendimientoTasaDeTransporte' | translate}}</label>
          <kendo-switch formControlName="rendimientoTasaDeTransporte_activo" (valueChange)="onChangeRendimientoTasaDeTransporte($event)" [onLabel]="' '" [offLabel]="' '" style="float: right; margin-top: 2px;"></kendo-switch>
        </div>
        <div class="col-md-12 extrusoras-panel-rangos-body">
          <div class="row" style="margin-top: 7px;">
            <div class="col-md-4">
              <kendo-label text="{{ 'min' | translate}}">
                <kendo-numerictextbox formControlName="rendimientoTasaDeTransporte_min" class="form-control" [disabled]="!f.rendimientoTasaDeTransporte_activo.value" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
              </kendo-label>
            </div>
            <div class="col-md-4">
              <kendo-label text="{{ 'max' | translate}}">
                <kendo-numerictextbox formControlName="rendimientoTasaDeTransporte_max" class="form-control" [disabled]="!f.rendimientoTasaDeTransporte_activo.value" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
              </kendo-label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Revoluciones motor-->
    <div class="col-md-3" style="padding-right: 0px;">
      <div class="row extrusoras-panel-rangos">
        <div class="col-md-12 extrusoras-panel-rangos-header">
          <label class="extrusoras-panel-rangos-header-titulo">{{ 'revolucionesMotor' | translate}}</label>
          <kendo-switch formControlName="revolucionesMotor_activo" (valueChange)="onChangeRevolucionesMotor($event)" [onLabel]="' '" [offLabel]="' '" style="float: right; margin-top: 2px;"></kendo-switch>
        </div>
        <div class="col-md-12 extrusoras-panel-rangos-body">
          <div class="row" style="margin-top: 7px;">
            <div class="col-md-4">
              <kendo-label text="{{ 'min' | translate}}">
                <kendo-numerictextbox formControlName="revolucionesMotor_min" class="form-control" [disabled]="!f.revolucionesMotor_activo.value" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
              </kendo-label>
            </div>
            <div class="col-md-4">
              <kendo-label text="{{ 'max' | translate}}">
                <kendo-numerictextbox formControlName="revolucionesMotor_max" class="form-control" [disabled]="!f.revolucionesMotor_activo.value" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
              </kendo-label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Carga motor-->
    <div class="col-md-3" style="padding-right: 0px;">
      <div class="row extrusoras-panel-rangos">
        <div class="col-md-12 extrusoras-panel-rangos-header">
          <label class="extrusoras-panel-rangos-header-titulo">{{ 'cargaMotor' | translate}}</label>
          <kendo-switch formControlName="cargaMotor_activo" (valueChange)="onChangeCargaMotor($event)" [onLabel]="' '" [offLabel]="' '" style="float: right; margin-top: 2px;"></kendo-switch>
        </div>
        <div class="col-md-12 extrusoras-panel-rangos-body">
          <div class="row" style="margin-top: 7px;">
            <div class="col-md-4">
              <kendo-label text="{{ 'min' | translate}}">
                <kendo-numerictextbox formControlName="cargaMotor_min" class="form-control" [disabled]="!f.cargaMotor_activo.value" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
              </kendo-label>
            </div>
            <div class="col-md-4">
              <kendo-label text="{{ 'max' | translate}}">
                <kendo-numerictextbox formControlName="cargaMotor_max" class="form-control" [disabled]="!f.cargaMotor_activo.value" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
              </kendo-label>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
    

  <div class="form-group" style=" margin-top: 20px;">

    <button type="submit" class="btn btn-primary btn-sm mr-1" [disabled]="this.user.maquinas<2">
      {{ 'guardar' | translate}}
    </button>
    <a class="btn btn-danger btn-sm mr-1" (click)="atras()">
      {{ 'cancelar' | translate}}
    </a>

  </div>
</form>
