import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/operaciones`;
@Injectable()
export class OperacionesService extends BehaviorSubject<any> {

  public loading = false;

  constructor(private http: HttpClient) {
    super(null);
  }

  GetCombo() {
    return this.http.post<JSON>(`${baseUrl}/GetCombo`, {}, { withCredentials: true });
  }

  public GetAll(action: string = '', param?: string, data?: ''): Observable<any> {
    this.loading = true;
    return this.http.get(baseUrl);
  }

  public GetByID(idOf: number, idPieza: number, idParte: number, idRuta: number, id: number): Observable<any> {
    this.loading = true;
    // return this.http.get(baseUrl + "/" + idOF + "/" + idpieza + "/" + idparte +"/" + idruta + "/" + idoperacion);
    return this.http.post<any>(`${baseUrl}/GetById`, { idOf, idPieza, idParte, idRuta, id }, { withCredentials: true });
  }

  public Get_CantidadPartePadre(idParte): Observable<any> {
    this.loading = true;
    return this.http.get(baseUrl + "/Get_CantidadPartePadre/" + idParte);
  }

  public getRecetasOperacion(idOperacion): Observable<any> {
    this.loading = true;
    return this.http.get(baseUrl + "/getRecetasOperacion/" + idOperacion);
  }

  public updateOrdenes(idRuta: number): Observable<any> {
    this.loading = true;
    return this.http.get(baseUrl + "/updateOrdenes/" + idRuta);
  }

  public delete(data?: any): Observable<any> {
    var formData: any = new FormData();
    formData.append("idoperacion", data.ids);
    return this.http.post<JSON>(baseUrl + "/eliminar", formData, { withCredentials: true });
  }

  public EliminarOperacion(id): Observable<any> {
    this.loading = true;
    return this.http.get(baseUrl + "/eliminarOperacion/" + id);
  }

  create(data, filename, archivo, fecha, idsmaquinas, estadosmaquinas) {
    data['filename'] = filename;
    data['archivo'] = archivo;
    data['fecha'] = fecha;
    data['idsmaquinas'] = idsmaquinas;
    data['estadosmaquinas'] = estadosmaquinas;
    if (data.hechos == null) data.hechos = 0;
    if (data.escandallo == null) data.escandallo = 0;
    var fIni: Date = data.fechainicio;
    var fFin: Date = data.fechafin;
    data.atributos = data.atributos.map(a => (a.id));
    if (fIni !== null && fIni !== undefined && data.fechainicio != "")
      data['fechainicio'] = this.dateToYYYYMMDDtHHmmSSz(fIni);
    else
      data['fechainicio'] = null;
    if (fFin !== null && fFin !== undefined && data.fechafin != "")
      data['fechafin'] = this.dateToYYYYMMDDtHHmmSSz(fFin);
    else
      data['fechafin'] = null;
    return this.http.post<any>(baseUrl + "/crear", data, { withCredentials: true });
  }

  public GetReferencias(): Observable<any> {
    this.loading = true;
    return this.http.get(baseUrl + "/referencias");
  }

  update(data, filename, archivo, idsmaquinas, estadosmaquinas, histCambiosAntes, histCambiosDespues, histCambiosCampos, fecha) {
    data['idsmaquinas'] = idsmaquinas;
    data['fecha'] = fecha;
    data['estadosmaquinas'] = estadosmaquinas;
    data['filename'] = filename;
    data['archivo'] = archivo;
    data['histCambiosAntes'] = histCambiosAntes;
    data['histCambiosDespues'] = histCambiosDespues;
    data['histCambiosCampos'] = histCambiosCampos;
    if (data.escandallo == null) data.escandallo = 0;
    if (data.hechos == null) data.hechos = 0;
    if (data.lado == null) data.lado = 0;
    if (data.hechos == null) data.hechos = 0;
    if (data.escandallo == null) data.escandallo = 0;
    var fIni: Date = data.fechainicio;
    var fFin: Date = data.fechafin;
    data.atributos = data.atributos.map(a => (a.id));
    if (fIni !== null && fIni !== undefined)
      data['fechainicio'] = this.dateToYYYYMMDDtHHmmSSz(fIni);
    else
      data['fechainicio'] = null;
    if (fFin !== null && fFin !== undefined)
      data['fechafin'] = this.dateToYYYYMMDDtHHmmSSz(fFin);
    else
      data['fechafin'] = null;
    return this.http.post<any>(baseUrl + "/editar", data, { withCredentials: true });
  }

  updateTerminado(IdRuta, Id, imputarPiezasTeoricas, Terminado) {
    return this.http.post<any>(baseUrl + "/editarTerminado", {IdRuta, Id, imputarPiezasTeoricas, Terminado}, { withCredentials: true });
  }

  /*update_herramientas(herramientas: JSON, idOperacion: number) {
    return this.http.post<JSON>(`${baseUrl}/Update`, { herramientas, idOperacion}, { withCredentials: true }).subscribe();
  }*/
  addValor(data) {
    return this.http.post<any>(baseUrl + "/addvalor", data, { withCredentials: true });
  }

  Get_TipoOperacion_Combo() {
    return this.http.post<JSON>(`${baseUrl}/Get_TipoOperacion_Combo`, {}, { withCredentials: true });
  }

  public GetAll_Herramientas_DAT(action: string = '', param?: string, data?: ''): Observable<any> {
    return this.http.get(baseUrl + "/todaslasherramientas");
  }

  editValor(data) {
    return this.http.post<any>(baseUrl + "/editvalor", data, { withCredentials: true });
  }

  removeValor(id) {
    return this.http.post<any>(baseUrl + "/removevalor", id, { withCredentials: true });
  }

  duplicar(data) {
    return this.http.post<any>(baseUrl + "/duplicar", data, { withCredentials: true });
  }

  public GetByIDSelectedCombo(data: any): Observable<any> {
    this.loading = true;
    return this.http.get(baseUrl + "/combo/" + data.idoperacion);
  }

  update_valores(req: any) {//vdersion
    req.forEach(element => {
      if (element.tipoControl != undefined && element.tipoControl != null) {
        if (element.tipoControl.id != undefined && element.tipoControl.id != null) {
          element.tipoControl = element.tipoControl.id;
        }
      }
      if (element.tipoTolerancia != undefined && element.tipoTolerancia != null) {
        if (element.tipoTolerancia.id != undefined && element.tipoTolerancia.id != null) {
          element.tipoTolerancia = element.tipoTolerancia.id;
        }
      }
      if (element.idUtillaje != undefined && element.idUtillaje != null) {
        if (element.idUtillaje.id != undefined && element.idUtillaje.id != null) {
          element.idUtillaje = element.idUtillaje.id;
        }
      }
    });
    var json: JSON = JSON.parse(JSON.stringify(req));
    return this.http.post<JSON>(`${baseUrl}/update_valores`, { json }, { withCredentials: true });
  }

  update_herramientas(req: any) {
    var json: JSON = JSON.parse(JSON.stringify(req));
    return this.http.post<JSON>(`${baseUrl}/update_herramientas`, { json }, { withCredentials: true });
  }

  update_programas(req: any) {
    var json: JSON = JSON.parse(JSON.stringify(req));
    return this.http.post<JSON>(`${baseUrl}/update_programas`, { json }, { withCredentials: true });
  }

  creardepredefinido(id, idRuta) {
    return this.http.post<any>(baseUrl + "/creardepredefinido", { idOperacionPred: id, IdRuta: idRuta }, { withCredentials: true });
  }

  dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    var ano = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return ano + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T' + this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos) + 'Z';
  }

  addZero(n: number) {
    if (n < 10)
      return '0' + n.toString();
    else
      return n.toString();
  }

  public getdatosControl(): Observable<any> {
    this.loading = true;
    return this.http.get(baseUrl + "/getdatosControl");
  }

  public getdatosTolerancia(): Observable<any> {
    this.loading = true;
    return this.http.get(baseUrl + "/getdatosTolerancia");
  }

  public getdatosUtillaje(): Observable<any> {
    this.loading = true;
    return this.http.get(baseUrl + "/getdatosUtillaje");
  }

  importarCotas(data) {
    return this.http.post<any>(baseUrl + "/importarCotas", data, { withCredentials: true });
  }

  public getOperacionesAgrupar(){
    return this.http.post<JSON>(`${baseUrl}/getOperacionesAgrupar`, { withCredentials: true });
  }

  public getOperacionesGrupos(version){
    return this.http.post<JSON>(`${baseUrl}/getOperacionesGrupos`, { version }, { withCredentials: true });
  }

  public getOperacionesGrupos_Operaciones(version){
    return this.http.post<JSON>(`${baseUrl}/getOperacionesGrupos_Operaciones`, { version }, { withCredentials: true });
  }

  public insertOperacionesGrupos(idMaquina, orden, tiempoEstimado, temperatura, idOperaciones, cantidades, cantidadGrupo, grupoFijado, opFijadosList, planificador,fechaFin, version){
    return this.http.post<JSON>(`${baseUrl}/insertOperacionesGrupos`, { idMaquina: idMaquina, orden: orden, tiempoEstimado: tiempoEstimado, temperatura: temperatura
    , idOperaciones: idOperaciones, cantidades: cantidades, cantidadGrupo: cantidadGrupo, grupoFijado: grupoFijado, opFijadosList: opFijadosList, planificador: planificador, fechaFin: fechaFin, version: version }, { withCredentials: true });
  }

  public updateOperacionesGrupos(idOperacionGrupo, idMaquina, orden, tiempoEstimado, temperatura, idOperaciones, cantidades, cantidadGrupo, grupoFijado, opFijadosList, planificador, fechaFin, version){
    return this.http.post<JSON>(`${baseUrl}/updateOperacionesGrupos`, { idOperacionGrupo: idOperacionGrupo,idMaquina: idMaquina, orden: orden, tiempoEstimado: tiempoEstimado, temperatura: temperatura
    , idOperaciones: idOperaciones, cantidades: cantidades, cantidadGrupo: cantidadGrupo, grupoFijado: grupoFijado, opFijadosList: opFijadosList, planificador: planificador, fechaFin: fechaFin, version: version }, { withCredentials: true });
  }

  public updateOperacionesGrupos_Orden(listaIdOrdenes){
    return this.http.post<JSON>(`${baseUrl}/updateOperacionesGrupos_Orden`, { listaIdOrdenes: listaIdOrdenes }, { withCredentials: true });
  }

  public updateOperacionesGrupos_TempDenb(idGrupo, tiempoEstimado, temperatura){
    return this.http.post<JSON>(`${baseUrl}/updateOperacionesGrupos_TempDenb`, { idGrupo: idGrupo, tiempoEstimado: tiempoEstimado, temperatura: temperatura }, { withCredentials: true });
  }
  public deleteOperacionesGrupos(idGrupo, idMaquina, fechaFin, planificador, version){
    return this.http.post<JSON>(`${baseUrl}/deleteOperacionesGrupos`, { idGrupo: idGrupo, idMaquina: idMaquina, fechaFin: fechaFin, planificador: planificador, version: version }, { withCredentials: true });
  }
  public deleteOperacionsGrupos_operaciones(idGrupo, idOperacion){
    return this.http.post<JSON>(`${baseUrl}/deleteOperacionsGrupos_operaciones`, { idGrupo: idGrupo, idOperacion: idOperacion }, { withCredentials: true });
  }
  public copiarOperacionesGruposVersiones(deVersion, aVersion){
    return this.http.post<JSON>(`${baseUrl}/copiarOperacionesGruposVersiones`, { deVersion, aVersion }, { withCredentials: true });
  }
}
