<style>
  :host /deep/ .k-grid tbody td {
    white-space: nowrap;
    line-height: 24px;
    padding: 10px 12px;
  }

  :host /deep/ .k-grid .k-grid-content td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
<ng-template #template1 let-anchor>
    <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>
<div kendoTooltip showOn="none" [tooltipTemplate]="template1" filter=".k-grid td"
    (mouseover)="showGridTooltip1($event)">
    <!--GRID Programas-->
    <kendo-grid [kendoGridBinding]="listaFinal" [navigable]="true" [pageable]="true" [sortable]="true"
        [reorderable]="true" [resizable]="true" [pageSize]="25" [rowHeight]="38" scrollable="virtual">
        <kendo-grid-column field="nombrePrograma" title="{{ 'programa' | translate}}"></kendo-grid-column>
        <kendo-grid-column field="nVersiones" title="{{ 'num_versiones' | translate}}"
            [style]="{'text-align': 'right'}"></kendo-grid-column>
        <kendo-grid-column field="fechaVersion" title="{{ 'creacion' | translate}}" [style]="{'text-align': 'center'}">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{myFunctions.dateToDayString(parseDate(dataItem.fechaVersion))}}</ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="tipo" title="{{ 'tipo' | translate}}" >
            <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.tipoToText(dataItem.tipo)}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="cliente" title="{{ 'cliente' | translate}}" ></kendo-grid-column>
        <kendo-grid-column field="numeroOF" title="{{ 'of' | translate}}"></kendo-grid-column>
        <kendo-grid-column field="pieza" title="{{ 'pieza' | translate}}"></kendo-grid-column>
        <kendo-grid-column field="nombreOperacion" title="{{ 'operacion' | translate}}" ></kendo-grid-column>
        <kendo-grid-column *ngIf="!user.ocultarParte" field="parte" title="{{ 'parte' | translate}}"></kendo-grid-column>
        <kendo-grid-column field="operario" title="{{ 'operario' | translate}}" class="celda-tooltip-externo">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span class="tooltip-consumibles-contenido">
                  <ng-container >
                    <span class="grid-circulo"
                          [style.background-color]="dataItem.operarioColor+ '!important'">{{dataItem.operarioSiglas}}</span>
                    </ng-container>
                  <span class="tooltiptext">
                    <ng-container >
                      <label> {{dataItem.operario}} </label>
                      <!-- <label *ngIf="operario =='Dundefined' || operario =='undefined'"> {{'desconocido' | translate}} </label> -->
                    </ng-container>
                  </span>
                </span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="nombreMaquina" title="{{ 'maquina' | translate}}"></kendo-grid-column>
        <kendo-grid-column field="media" title="{{ 'tiempo_medio' | translate}}" [style]="{'text-align': 'right'}">
            <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM_SS(dataItem.media)}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="idRepositorio" title="{{ 'repositorio' | translate}}"
            [style]="{'text-align': 'right'}"></kendo-grid-column>
        <!--Set list of programs && programs data, field should be the same as attribute we want-->
        <ng-container *ngFor="let programa of listaFinal"></ng-container>
        <!--GRID Versiones programas-->
        <div *kendoGridDetailTemplate="let version">
            <kendo-grid [kendoGridBinding]="version.versiones" [navigable]="true" [pageable]="true" [sortable]="true"
                [reorderable]="true" [resizable]="true" [pageSize]="25" (cellClick)="redirectToVersionVisor($event)"
                kendoGridFocusable [rowHeight]="38"  scrollable="virtual"  class="celda-tooltip-externo">
                <kendo-grid-column field="version" title="{{ 'version' | translate}}"  width="30%" [style]="{'text-align': 'right'}">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{myFunctions.sumarleLaVALaVersion(dataItem.version)}}</ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="fechaVersion" title="{{ 'fecha' | translate}}"  width="30%"
                    [style]="{'text-align': 'center'}">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{myFunctions.dateToDayString(parseDate(dataItem.fechaVersion))}}</ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="tipo" title="{{ 'tipo' | translate}}"  width="30%" [style]="{'text-align': 'left'}"
                    [headerStyle]="{'text-align': 'right'}">
                    <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.tipoToText(dataItem.tipo)}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="pieza" title="{{ 'pieza' | translate}}"  width="30%" [style]="{'text-align': 'left'}"
                    [headerStyle]="{'text-align': 'right'}"></kendo-grid-column>
                <kendo-grid-column field="nombreOperacion" title="{{ 'operacion' | translate}}" width="30%" [style]="{'text-align': 'left'}"
                [headerStyle]="{'text-align': 'right'}"></kendo-grid-column>
                <kendo-grid-column field="cliente" title="{{ 'cliente' | translate}}" width="30%" [style]="{'text-align': 'left'}"
                    [headerStyle]="{'text-align': 'right'}"></kendo-grid-column>
                <kendo-grid-column field="numeroOF" title="{{ 'of' | translate}}"  width="30%" [style]="{'text-align': 'left'}"
                    [headerStyle]="{'text-align': 'right'}"></kendo-grid-column>
                <kendo-grid-column *ngIf="!user.ocultarParte" field="parte" title="{{ 'parte' | translate}}"  width="30%"
                    [style]="{'text-align': 'left'}" [headerStyle]="{'text-align': 'right'}"></kendo-grid-column>
                <kendo-grid-column field="operario" title="{{ 'operario' | translate}}"  width="30%" [style]="{'text-align': 'left'}"
                    [headerStyle]="{'text-align': 'right'}" class="celda-tooltip-externo">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <!-- <span class="tooltip-consumibles-contenido"> -->
                          <ng-container >
                            <span class="grid-circulo"
                                  [style.background-color]="dataItem.operarioColor+ '!important'">{{dataItem.operarioSiglas}}</span>
                            </ng-container>
                          <!-- <span class="tooltiptext">
                            <ng-container >
                              <label> {{dataItem.operario}} </label>
                            </ng-container>
                          </span> -->
                        <!-- </span> -->
                      </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="nombreMaquina" title="{{ 'maquina' | translate}}" width="30%"
                    [style]="{'text-align': 'left'}" [headerStyle]="{'text-align': 'right'}"></kendo-grid-column>
                <kendo-grid-column field="tMedio" title="{{ 'tiempo_medio' | translate}}"  width="30%"
                    [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
                    <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM_SS(dataItem.tMedio)}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="tMejor" title="{{ 'mejor_tiempo' | translate}}"  width="30%"
                    [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
                    <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM_SS(dataItem.tMejor)}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="cantidad" title="{{ 'cantidad2' | translate}}"  width="30%"
                    [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
                </kendo-grid-column>
                <kendo-grid-column field="porcenCalidad" title="{{ '%OK' | translate}}"  width="30%"
                    [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
                </kendo-grid-column>
                    <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                    filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                    filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                    filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                    filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                    filterContainsOperator="{{'filterContainsOperator' | translate}}"
                    filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                    filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                    filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                    filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                    filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                    filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                    filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                    filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                    filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                    filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                    filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                    filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                    filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                    filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                    groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                    noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
        </div>
        <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
        filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
        filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
        filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
        filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
        filterContainsOperator="{{'filterContainsOperator' | translate}}"
        filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
        filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
        filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
        filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
        filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
        filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
        filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
        filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
        filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
        filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
        filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
        filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
        filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
        filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
        groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
        noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
    </kendo-grid>
</div>