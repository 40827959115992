import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';


const baseUrl = `${environment.apiUrl}/subidaFicheros`;

@Injectable({
  providedIn: 'root'
})
export class SubidaFicherosService {

  constructor(private http: HttpClient) { }

  
  //#region AZURE
  // SUBIR ARCHIVOS AL AZURE DE ERIS
  uploadFile(nombre:string,file:File, nombreCarpeta:string){
    debugger
    let formData = new FormData();
    formData.append('archivo', file);
    return this.http.post<JSON>(`${baseUrl}/subirArchivosAzureEris`, formData,
    {
      params: {
        nombre: nombre,
        nombreCarpeta: nombreCarpeta
      }
    }
    );
  }
   // SUBIR ARCHIVOS A AZURE EXTERNO
   uploadFileAzureExterno(file:File,nombreCarpeta:string){//,cadenaDeConexion:string,nombreContenedor:string
    debugger
    let formData = new FormData();
    formData.append('archivo', file);
    return this.http.post<JSON>(`${baseUrl}/subirArchivosAzureExterno`, formData,
    {
      params: {
        nombreCarpeta: nombreCarpeta
      }
    }
    );
  }
  borrarArchivosDeAzure(urls: string[]){
    debugger
    return this.http.post<JSON>(`${baseUrl}/borrarArchivoDeAzure`, { urls:urls } );
  }
  borrarArchivosDeAzureExterno(urls: string[]){
    debugger
    return this.http.post<JSON>(`${baseUrl}/borrarArchivoDeAzureExterno`, { urls:urls } );
  }
  borrarArchivosDeFTP(urls: string[]){
    debugger
    return this.http.post<JSON>(`${baseUrl}/borrarArchivoFTP`, { urls:urls } );
  }
  borrarArchivosDeGoogleCloud(urls: string[]){
    debugger
    return this.http.post<JSON>(`${baseUrl}/borrarArchivoGoogleCloud`, { urls:urls } );
  }
  //#endregion
  // SUBIR ARCHIVOS A FTP
  uploadFileFTP(file:File, nombreCarpeta: string)//servidorFTP: string, puertoFTP: string, nombreUsuario: string, password: string,
  {
    debugger
    let formData = new FormData();
    formData.append('archivo', file);
    return this.http.post<JSON>(`${baseUrl}/subirArchivosFTP`, formData,
    {
      params: {
        //nombre: nombre,
        // servidorFTP: servidorFTP,
        // puertoFTP: puertoFTP,
        // nombreUsuario: nombreUsuario,
        // password: password,
        nombreCarpeta: nombreCarpeta
      }
    }
    );
  }

  //Hasierako bertsioa
  // uploadFileFTP(nombre:string, file:File, servidorFTP: string, puertoFTP: string, nombreUsuario: string, password: string, nombreCarpeta: string)
  // {
  //   debugger
  //   let formData = new FormData();
  //   formData.append('archivo', file);
  //   return this.http.post<JSON>(`${baseUrl}/subirArchivosFTP`, formData,
  //   {
  //     params: {
  //       nombre: nombre,
  //       servidorFTP: servidorFTP,
  //       puertoFTP: puertoFTP,
  //       nombreUsuario: nombreUsuario,
  //       password: password,
  //       nombreCarpeta: nombreCarpeta
  //     }
  //   }
  //   );
  // }

  // SUBIR ARCHIVOS A GOOGLE CLOUD
  uploadFileAGoogleCloud(nombreCarpeta: string, file: File){
    debugger
    let formData = new FormData();
    formData.append('archivo', file);
    
    return this.http.post<JSON>(`${baseUrl}/subirArchivosAGoogleCloud`, formData,
    {
      params: {
        nombreCarpeta: nombreCarpeta
      }
    });
    
  }

  // SUBIR ARCHIVOS A GOOGLE DRIVE
  uploadFileAGoogleDrive(nombre:string, file:File){
    let formData = new FormData();
    formData.append('archivo', file);
  }

 

  //GUARDAR ARCHIVOS EN GOOGLE CLOUD 
  uploadFileGoogleCloud(nombre:string, file:File, credentialsFile: File, nombreBucket: string){
    let formData = new FormData();
    formData.append('archivo',file);
    let formData2 = new FormData();
    formData2.append('archivoCredenciales',credentialsFile);
    return this.http.post<JSON>(`${baseUrl}/subirArchivosAzureExterno`, formData,
    {
      params: {
        nombre: nombre,
        nombreBucket: nombreBucket,
        //ficheroCredenciales: formData2
      }
    }
    );
  }
}
