<!-- FILTRO OF / TIEMPO -->
<div class="card filtro-planificador-comparativa">
  <div class="card-body">

    <!-- TIPO DE CALCULO TIEMPO / OF -->
    <div class="mb-2 fpc-1">
      <div class="btn mr-1" [class.btn-success]="aplicarTiempoComparativa" [class.btn-outline-success]="!aplicarTiempoComparativa">
        <input type="radio" name="comparativaGroup" id="btnComparativaTiempo" class="k-radio" (click)="btnComparativaTiempo()" [checked]="aplicarTiempoComparativa" kendoRadioButton>
        <label class="k-radio-label" for="btnComparativaTiempo">{{ 'tiempo' | translate }}</label>
      </div>
      <div class="btn mr-1" [class.btn-success]="!aplicarTiempoComparativa" [class.btn-outline-success]="aplicarTiempoComparativa">
        <input type="radio" name="comparativaGroup" id="btnComparativaOF" class="k-radio" (click)="btnComparativaOF()" [checked]="!aplicarTiempoComparativa" kendoRadioButton>
        <label class="k-radio-label" for="btnComparativaOF">{{ 'OF' | translate }}</label>
      </div>
    </div>
    <!-- TIEMPO ESTIMADO / TIEMPO PREDICTIVO -->
    <div class="mb-2 fpc-2">
      <div class="btn mr-1" [class.btn-success]="aplicarTiempoEstimado" [class.btn-outline-success]="!aplicarTiempoEstimado">
        <input type="radio" name="estimadoGroup" id="btnTiempoEstimado" class="k-radio" (click)="btnTiempoEstimado()" [checked]="aplicarTiempoEstimado" kendoRadioButton>
        <label class="k-radio-label" for="btnTiempoEstimado">{{ 'tiempoEstimado' | translate }}</label>
      </div>
      <div class="btn " [class.btn-success]="!aplicarTiempoEstimado" [class.btn-outline-success]="aplicarTiempoEstimado">
        <input type="radio" name="estimadoGroup" id="btnTiempoPredictivo" class="k-radio" (click)="btnTiempoPredictivo()" [checked]="!aplicarTiempoEstimado" kendoRadioButton>
        <label class="k-radio-label" for="btnTiempoPredictivo">{{ 'predictivo' | translate }}</label>
      </div>
    </div>
    <!-- CALCULOS CON OF / PIEZAS -->
    <div class="mb-2 fpc-3">
      <div class="btn mr-1" [class.btn-success]="aplicarCalculoPiezas" [class.btn-outline-success]="!aplicarCalculoPiezas">
        <input type="radio" name="piezasGroup" id="btnCalculoPiezas" class="k-radio" (click)="btnCalculoPiezas()" [checked]="aplicarCalculoPiezas" kendoRadioButton>
        <label class="k-radio-label" for="btnCalculoPiezas">{{ 'piezas' | translate }}</label>
      </div>
      <div class="btn mr-1" [class.btn-success]="!aplicarCalculoPiezas" [class.btn-outline-success]="aplicarCalculoPiezas">
        <input type="radio" name="piezasGroup" id="btnCalculoOF" class="k-radio" (click)="btnCalculoOF()" [checked]="!aplicarCalculoPiezas" kendoRadioButton>
        <label class="k-radio-label" for="btnCalculoOF">{{ 'OF' | translate }}</label>
      </div>
    </div>

  </div>
</div>

<!-- 0: TÍTULOS -->
<table cellspacing="0" cellpadding="0" class="titulo-tablas-comparativas  tablas-comparativas">
  <tr>
    <td class="principal"></td>

    <th class="datos"><span class="titulillo">{{ 'real' | translate }}</span><span class="triangulo-titulo"></span></th>
    <th class="datos colalt"><span class="titulillo">V1</span><span class="triangulo-titulo"></span></th>
    <th class="datos"><span class="titulillo">V2</span><span class="triangulo-titulo"></span></th>
    <th class="datos colalt"><span class="titulillo">V3</span><span class="triangulo-titulo"></span></th>
  </tr>
</table>

<!-- 1: TASA DE SERVICIO A TIEMPO -->
<table cellspacing="0" cellpadding="0" class="TasaServicioTiempo tablas-comparativas" [hidden]="aplicarTiempoComparativa">
  <tr>
    <td rowspan="4" class="principal">{{ 'TasaServicioTiempo' | translate }}</td>
    <!--<th class="titulo linea">{{ 'OF' | translate }}</th>-->

    <td class="datos" [class.verde]="TS_OF_V1 >= TS_OF_V2 && TS_OF_V1 >= TS_OF_V3 && TS_OF_V1 >= TS_OF_V4">{{TS_OF_V1.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_OF_V2 >= TS_OF_V1 && TS_OF_V2 >= TS_OF_V3 && TS_OF_V2 >= TS_OF_V4">{{TS_OF_V2.toFixed(2)}} %</td>
    <td class="datos" [class.verde]="TS_OF_V3 >= TS_OF_V2 && TS_OF_V3 >= TS_OF_V1 && TS_OF_V3 >= TS_OF_V4">{{TS_OF_V3.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_OF_V4 >= TS_OF_V2 && TS_OF_V4 >= TS_OF_V3 && TS_OF_V4 >= TS_OF_V1">{{TS_OF_V4.toFixed(2)}} %</td>
  </tr>
</table>
<table cellspacing="0" cellpadding="0" class="TasaServicioTiempo tablas-comparativas" [hidden]="!aplicarTiempoComparativa">
  <tr>
    <td rowspan="4" class="principal">{{ 'TasaServicioTiempo' | translate }}</td>
    <!--<th class="titulo linealt">{{ 'tiempo' | translate }}</th>-->

    <td class="datos" [class.verde]="TS_OF_tiempo_V1 >= TS_OF_tiempo_V2 && TS_OF_tiempo_V1 >= TS_OF_tiempo_V3 && TS_OF_tiempo_V1 >= TS_OF_tiempo_V4">{{TS_OF_tiempo_V1.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_OF_tiempo_V2 >= TS_OF_tiempo_V1 && TS_OF_tiempo_V2 >= TS_OF_tiempo_V3 && TS_OF_tiempo_V2 >= TS_OF_tiempo_V4">{{TS_OF_tiempo_V2.toFixed(2)}} %</td>
    <td class="datos" [class.verde]="TS_OF_tiempo_V3 >= TS_OF_tiempo_V2 && TS_OF_tiempo_V3 >= TS_OF_tiempo_V1 && TS_OF_tiempo_V3 >= TS_OF_tiempo_V4">{{TS_OF_tiempo_V3.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_OF_tiempo_V4 >= TS_OF_tiempo_V2 && TS_OF_tiempo_V4 >= TS_OF_tiempo_V3 && TS_OF_tiempo_V4 >= TS_OF_tiempo_V1">{{TS_OF_tiempo_V4.toFixed(2)}} %</td>
  </tr>
</table>

<!-- 2: TASA DE SERVICIO CLIENTES -->
<table cellspacing="0" cellpadding="0" class="TasaServicioClientes tablas-comparativas" [hidden]="aplicarTiempoComparativa">
  <tr>
    <td rowspan="3" class="principal">{{ 'TasaServicioClientes' | translate }}</td>
    <!--<th rowspan="3" class="titulo linea">{{ 'OF' | translate }}</th>-->
    <td class="linealt">{{ 'A' | translate }}</td> <!-- SE QUE NO ES NECESARIO, PERO ES PARA MANTENER LA MISMA ESTRUCTURA SIEMPRE -->

    <td class="datos" [class.verde]="TS_Cli_grupo_A_V1 >= TS_Cli_grupo_A_V2 && TS_Cli_grupo_A_V1 >= TS_Cli_grupo_A_V3 && TS_Cli_grupo_A_V1 >= TS_Cli_grupo_A_V4">{{TS_Cli_grupo_A_V1.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_Cli_grupo_A_V2 >= TS_Cli_grupo_A_V1 && TS_Cli_grupo_A_V2 >= TS_Cli_grupo_A_V3 && TS_Cli_grupo_A_V2 >= TS_Cli_grupo_A_V4">{{TS_Cli_grupo_A_V2.toFixed(2)}} %</td>
    <td class="datos" [class.verde]="TS_Cli_grupo_A_V3 >= TS_Cli_grupo_A_V2 && TS_Cli_grupo_A_V3 >= TS_Cli_grupo_A_V1 && TS_Cli_grupo_A_V3 >= TS_Cli_grupo_A_V4">{{TS_Cli_grupo_A_V3.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_Cli_grupo_A_V4 >= TS_Cli_grupo_A_V2 && TS_Cli_grupo_A_V4 >= TS_Cli_grupo_A_V3 && TS_Cli_grupo_A_V4 >= TS_Cli_grupo_A_V1">{{TS_Cli_grupo_A_V4.toFixed(2)}} %</td>
  </tr>
  <tr>
    <td class="linea">{{ 'B' | translate }}</td>

    <td class="datos" [class.verde]="TS_Cli_grupo_B_V1 >= TS_Cli_grupo_B_V2 && TS_Cli_grupo_B_V1 >= TS_Cli_grupo_B_V3 && TS_Cli_grupo_B_V1 >= TS_Cli_grupo_B_V4">{{TS_Cli_grupo_B_V1.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_Cli_grupo_B_V2 >= TS_Cli_grupo_B_V1 && TS_Cli_grupo_B_V2 >= TS_Cli_grupo_B_V3 && TS_Cli_grupo_B_V2 >= TS_Cli_grupo_B_V4">{{TS_Cli_grupo_B_V2.toFixed(2)}} %</td>
    <td class="datos" [class.verde]="TS_Cli_grupo_B_V3 >= TS_Cli_grupo_B_V2 && TS_Cli_grupo_B_V3 >= TS_Cli_grupo_B_V1 && TS_Cli_grupo_B_V3 >= TS_Cli_grupo_B_V4">{{TS_Cli_grupo_B_V3.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_Cli_grupo_B_V4 >= TS_Cli_grupo_B_V2 && TS_Cli_grupo_B_V4 >= TS_Cli_grupo_B_V3 && TS_Cli_grupo_B_V4 >= TS_Cli_grupo_B_V1">{{TS_Cli_grupo_B_V4.toFixed(2)}} %</td>
  </tr>
  <tr>
    <td class="linealt">{{ 'C' | translate }}</td>

    <td class="datos" [class.verde]="TS_Cli_grupo_C_V1 >= TS_Cli_grupo_C_V2 && TS_Cli_grupo_C_V1 >= TS_Cli_grupo_C_V3 && TS_Cli_grupo_C_V1 >= TS_Cli_grupo_C_V4">{{TS_Cli_grupo_C_V1.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_Cli_grupo_C_V2 >= TS_Cli_grupo_C_V1 && TS_Cli_grupo_C_V2 >= TS_Cli_grupo_C_V3 && TS_Cli_grupo_C_V2 >= TS_Cli_grupo_C_V4">{{TS_Cli_grupo_C_V2.toFixed(2)}} %</td>
    <td class="datos" [class.verde]="TS_Cli_grupo_C_V3 >= TS_Cli_grupo_C_V2 && TS_Cli_grupo_C_V3 >= TS_Cli_grupo_C_V1 && TS_Cli_grupo_C_V3 >= TS_Cli_grupo_C_V4">{{TS_Cli_grupo_C_V3.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_Cli_grupo_C_V4 >= TS_Cli_grupo_C_V2 && TS_Cli_grupo_C_V4 >= TS_Cli_grupo_C_V3 && TS_Cli_grupo_C_V4 >= TS_Cli_grupo_C_V1">{{TS_Cli_grupo_C_V4.toFixed(2)}} %</td>
  </tr>
</table>
<table cellspacing="0" cellpadding="0" class="TasaServicioClientes tablas-comparativas" [hidden]="!aplicarTiempoComparativa">
  <tr>
    <td rowspan="3" class="principal">{{ 'TasaServicioClientes' | translate }}</td>
    <!--<th rowspan="3" class="titulo linealt">{{ 'tiempo' | translate }}</th>-->
    <td class="linealt">{{ 'A' | translate }}</td>

    <td class="datos" [class.verde]="TS_Cli_tiempo_grupo_A_V1 >= TS_Cli_tiempo_grupo_A_V2 && TS_Cli_tiempo_grupo_A_V1 >= TS_Cli_tiempo_grupo_A_V3 && TS_Cli_tiempo_grupo_A_V1 >= TS_Cli_tiempo_grupo_A_V4">{{TS_Cli_tiempo_grupo_A_V1.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_Cli_tiempo_grupo_A_V2 >= TS_Cli_tiempo_grupo_A_V1 && TS_Cli_tiempo_grupo_A_V2 >= TS_Cli_tiempo_grupo_A_V3 && TS_Cli_tiempo_grupo_A_V2 >= TS_Cli_tiempo_grupo_A_V4">{{TS_Cli_tiempo_grupo_A_V2.toFixed(2)}} %</td>
    <td class="datos" [class.verde]="TS_Cli_tiempo_grupo_A_V3 >= TS_Cli_tiempo_grupo_A_V2 && TS_Cli_tiempo_grupo_A_V3 >= TS_Cli_tiempo_grupo_A_V1 && TS_Cli_tiempo_grupo_A_V3 >= TS_Cli_tiempo_grupo_A_V4">{{TS_Cli_tiempo_grupo_A_V3.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_Cli_tiempo_grupo_A_V4 >= TS_Cli_tiempo_grupo_A_V2 && TS_Cli_tiempo_grupo_A_V4 >= TS_Cli_tiempo_grupo_A_V3 && TS_Cli_tiempo_grupo_A_V4 >= TS_Cli_tiempo_grupo_A_V1">{{TS_Cli_tiempo_grupo_A_V4.toFixed(2)}} %</td>
  </tr>
  <tr>
    <td class="linea">{{ 'B' | translate }}</td>

    <td class="datos" [class.verde]="TS_Cli_tiempo_grupo_B_V1 >= TS_Cli_tiempo_grupo_B_V2 && TS_Cli_tiempo_grupo_B_V1 >= TS_Cli_tiempo_grupo_B_V3 && TS_Cli_tiempo_grupo_B_V1 >= TS_Cli_tiempo_grupo_B_V4">{{TS_Cli_tiempo_grupo_B_V1.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_Cli_tiempo_grupo_B_V2 >= TS_Cli_tiempo_grupo_B_V1 && TS_Cli_tiempo_grupo_B_V2 >= TS_Cli_tiempo_grupo_B_V3 && TS_Cli_tiempo_grupo_B_V2 >= TS_Cli_tiempo_grupo_B_V4">{{TS_Cli_tiempo_grupo_B_V2.toFixed(2)}} %</td>
    <td class="datos" [class.verde]="TS_Cli_tiempo_grupo_B_V3 >= TS_Cli_tiempo_grupo_B_V2 && TS_Cli_tiempo_grupo_B_V3 >= TS_Cli_tiempo_grupo_B_V1 && TS_Cli_tiempo_grupo_B_V3 >= TS_Cli_tiempo_grupo_B_V4">{{TS_Cli_tiempo_grupo_B_V3.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_Cli_tiempo_grupo_B_V4 >= TS_Cli_tiempo_grupo_B_V2 && TS_Cli_tiempo_grupo_B_V4 >= TS_Cli_tiempo_grupo_B_V3 && TS_Cli_tiempo_grupo_B_V4 >= TS_Cli_tiempo_grupo_B_V1">{{TS_Cli_tiempo_grupo_B_V4.toFixed(2)}} %</td>
  </tr>
  <tr>
    <td class="linealt">{{ 'C' | translate }}</td>

    <td class="datos" [class.verde]="TS_Cli_tiempo_grupo_C_V1 >= TS_Cli_tiempo_grupo_C_V2 && TS_Cli_tiempo_grupo_C_V1 >= TS_Cli_tiempo_grupo_C_V3 && TS_Cli_tiempo_grupo_C_V1 >= TS_Cli_tiempo_grupo_C_V4">{{TS_Cli_tiempo_grupo_C_V1.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_Cli_tiempo_grupo_C_V2 >= TS_Cli_tiempo_grupo_C_V1 && TS_Cli_tiempo_grupo_C_V2 >= TS_Cli_tiempo_grupo_C_V3 && TS_Cli_tiempo_grupo_C_V2 >= TS_Cli_tiempo_grupo_C_V4">{{TS_Cli_tiempo_grupo_C_V2.toFixed(2)}} %</td>
    <td class="datos" [class.verde]="TS_Cli_tiempo_grupo_C_V3 >= TS_Cli_tiempo_grupo_C_V2 && TS_Cli_tiempo_grupo_C_V3 >= TS_Cli_tiempo_grupo_C_V1 && TS_Cli_tiempo_grupo_C_V3 >= TS_Cli_tiempo_grupo_C_V4">{{TS_Cli_tiempo_grupo_C_V3.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_Cli_tiempo_grupo_C_V4 >= TS_Cli_tiempo_grupo_C_V2 && TS_Cli_tiempo_grupo_C_V4 >= TS_Cli_tiempo_grupo_C_V3 && TS_Cli_tiempo_grupo_C_V4 >= TS_Cli_tiempo_grupo_C_V1">{{TS_Cli_tiempo_grupo_C_V4.toFixed(2)}} %</td>
  </tr>
</table>

<!-- 3: TASA DE SERVICIO FACTURACION -->
<table cellspacing="0" cellpadding="0" class="TasaServicioFacturacionClientes tablas-comparativas" [hidden]="aplicarTiempoComparativa">
  <tr>
    <td rowspan="3" class="principal">{{ 'TasaServicioFacturacionClientes' | translate }}</td>
    <!--<th rowspan="3" class="titulo linea">{{ 'OF' | translate }}</th>-->
    <td class="linealt">{{ 'alta' | translate }}</td>

    <td class="datos" [class.verde]="TS_Cli_facturacion_Alta_V1 >= TS_Cli_facturacion_Alta_V2 && TS_Cli_facturacion_Alta_V1 >= TS_Cli_facturacion_Alta_V3 && TS_Cli_facturacion_Alta_V1 >= TS_Cli_facturacion_Alta_V4">{{TS_Cli_facturacion_Alta_V1.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_Cli_facturacion_Alta_V2 >= TS_Cli_facturacion_Alta_V1 && TS_Cli_facturacion_Alta_V2 >= TS_Cli_facturacion_Alta_V3 && TS_Cli_facturacion_Alta_V2 >= TS_Cli_facturacion_Alta_V4">{{TS_Cli_facturacion_Alta_V2.toFixed(2)}} %</td>
    <td class="datos" [class.verde]="TS_Cli_facturacion_Alta_V3 >= TS_Cli_facturacion_Alta_V2 && TS_Cli_facturacion_Alta_V3 >= TS_Cli_facturacion_Alta_V1 && TS_Cli_facturacion_Alta_V3 >= TS_Cli_facturacion_Alta_V4">{{TS_Cli_facturacion_Alta_V3.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_Cli_facturacion_Alta_V4 >= TS_Cli_facturacion_Alta_V2 && TS_Cli_facturacion_Alta_V4 >= TS_Cli_facturacion_Alta_V3 && TS_Cli_facturacion_Alta_V4 >= TS_Cli_facturacion_Alta_V1">{{TS_Cli_facturacion_Alta_V4.toFixed(2)}} %</td>
  </tr>
  <tr>
    <td class="linea">{{ 'media' | translate }}</td>

    <td class="datos" [class.verde]="TS_Cli_facturacion_Media_V1 >= TS_Cli_facturacion_Media_V2 && TS_Cli_facturacion_Media_V1 >= TS_Cli_facturacion_Media_V3 && TS_Cli_facturacion_Media_V1 >= TS_Cli_facturacion_Media_V4">{{TS_Cli_facturacion_Media_V1.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_Cli_facturacion_Media_V2 >= TS_Cli_facturacion_Media_V1 && TS_Cli_facturacion_Media_V2 >= TS_Cli_facturacion_Media_V3 && TS_Cli_facturacion_Media_V2 >= TS_Cli_facturacion_Media_V4">{{TS_Cli_facturacion_Media_V2.toFixed(2)}} %</td>
    <td class="datos" [class.verde]="TS_Cli_facturacion_Media_V3 >= TS_Cli_facturacion_Media_V2 && TS_Cli_facturacion_Media_V3 >= TS_Cli_facturacion_Media_V1 && TS_Cli_facturacion_Media_V3 >= TS_Cli_facturacion_Media_V4">{{TS_Cli_facturacion_Media_V3.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_Cli_facturacion_Media_V4 >= TS_Cli_facturacion_Media_V2 && TS_Cli_facturacion_Media_V4 >= TS_Cli_facturacion_Media_V3 && TS_Cli_facturacion_Media_V4 >= TS_Cli_facturacion_Media_V1">{{TS_Cli_facturacion_Media_V4.toFixed(2)}} %</td>
  </tr>
  <tr>
    <td class="linealt">{{ 'baja' | translate }}</td>

    <td class="datos" [class.verde]="TS_Cli_facturacion_Baja_V1 >= TS_Cli_facturacion_Baja_V2 && TS_Cli_facturacion_Baja_V1 >= TS_Cli_facturacion_Baja_V3 && TS_Cli_facturacion_Baja_V1 >= TS_Cli_facturacion_Baja_V4">{{TS_Cli_facturacion_Baja_V1.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_Cli_facturacion_Baja_V2 >= TS_Cli_facturacion_Baja_V1 && TS_Cli_facturacion_Baja_V2 >= TS_Cli_facturacion_Baja_V3 && TS_Cli_facturacion_Baja_V2 >= TS_Cli_facturacion_Baja_V4">{{TS_Cli_facturacion_Baja_V2.toFixed(2)}} %</td>
    <td class="datos" [class.verde]="TS_Cli_facturacion_Baja_V3 >= TS_Cli_facturacion_Baja_V2 && TS_Cli_facturacion_Baja_V3 >= TS_Cli_facturacion_Baja_V1 && TS_Cli_facturacion_Baja_V3 >= TS_Cli_facturacion_Baja_V4">{{TS_Cli_facturacion_Baja_V3.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_Cli_facturacion_Baja_V4 >= TS_Cli_facturacion_Baja_V2 && TS_Cli_facturacion_Baja_V4 >= TS_Cli_facturacion_Baja_V3 && TS_Cli_facturacion_Baja_V4 >= TS_Cli_facturacion_Baja_V1">{{TS_Cli_facturacion_Baja_V4.toFixed(2)}} %</td>
  </tr>
</table>
<table cellspacing="0" cellpadding="0" class="TasaServicioFacturacionClientes tablas-comparativas" [hidden]="!aplicarTiempoComparativa">
  <tr>
    <td rowspan="3" class="principal">{{ 'TasaServicioFacturacionClientes' | translate }}</td>
    <!--<th rowspan="3" class="titulo linealt">{{ 'tiempo' | translate }}</th>-->
    <td class="linealt">{{ 'alta' | translate }}</td>

    <td class="datos" [class.verde]="TS_Cli_tiempo_facturacion_Alta_V1 >= TS_Cli_tiempo_facturacion_Alta_V2 && TS_Cli_tiempo_facturacion_Alta_V1 >= TS_Cli_tiempo_facturacion_Alta_V3 && TS_Cli_tiempo_facturacion_Alta_V1 >= TS_Cli_tiempo_facturacion_Alta_V4">{{TS_Cli_tiempo_facturacion_Alta_V1.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_Cli_tiempo_facturacion_Alta_V2 >= TS_Cli_tiempo_facturacion_Alta_V1 && TS_Cli_tiempo_facturacion_Alta_V2 >= TS_Cli_tiempo_facturacion_Alta_V3 && TS_Cli_tiempo_facturacion_Alta_V2 >= TS_Cli_tiempo_facturacion_Alta_V4">{{TS_Cli_tiempo_facturacion_Alta_V2.toFixed(2)}} %</td>
    <td class="datos" [class.verde]="TS_Cli_tiempo_facturacion_Alta_V3 >= TS_Cli_tiempo_facturacion_Alta_V2 && TS_Cli_tiempo_facturacion_Alta_V3 >= TS_Cli_tiempo_facturacion_Alta_V1 && TS_Cli_tiempo_facturacion_Alta_V3 >= TS_Cli_tiempo_facturacion_Alta_V4">{{TS_Cli_tiempo_facturacion_Alta_V3.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_Cli_tiempo_facturacion_Alta_V4 >= TS_Cli_tiempo_facturacion_Alta_V2 && TS_Cli_tiempo_facturacion_Alta_V4 >= TS_Cli_tiempo_facturacion_Alta_V3 && TS_Cli_tiempo_facturacion_Alta_V4 >= TS_Cli_tiempo_facturacion_Alta_V1">{{TS_Cli_tiempo_facturacion_Alta_V4.toFixed(2)}} %</td>
  </tr>
  <tr>
    <td class="linea">{{ 'media' | translate }}</td>

    <td class="datos" [class.verde]="TS_Cli_tiempo_facturacion_Media_V1 >= TS_Cli_tiempo_facturacion_Media_V2 && TS_Cli_tiempo_facturacion_Media_V1 >= TS_Cli_tiempo_facturacion_Media_V3 && TS_Cli_tiempo_facturacion_Media_V1 >= TS_Cli_tiempo_facturacion_Media_V4">{{TS_Cli_tiempo_facturacion_Media_V1.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_Cli_tiempo_facturacion_Media_V2 >= TS_Cli_tiempo_facturacion_Media_V1 && TS_Cli_tiempo_facturacion_Media_V2 >= TS_Cli_tiempo_facturacion_Media_V3 && TS_Cli_tiempo_facturacion_Media_V2 >= TS_Cli_tiempo_facturacion_Media_V4">{{TS_Cli_tiempo_facturacion_Media_V2.toFixed(2)}} %</td>
    <td class="datos" [class.verde]="TS_Cli_tiempo_facturacion_Media_V3 >= TS_Cli_tiempo_facturacion_Media_V2 && TS_Cli_tiempo_facturacion_Media_V3 >= TS_Cli_tiempo_facturacion_Media_V1 && TS_Cli_tiempo_facturacion_Media_V3 >= TS_Cli_tiempo_facturacion_Media_V4">{{TS_Cli_tiempo_facturacion_Media_V3.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_Cli_tiempo_facturacion_Media_V4 >= TS_Cli_tiempo_facturacion_Media_V2 && TS_Cli_tiempo_facturacion_Media_V4 >= TS_Cli_tiempo_facturacion_Media_V3 && TS_Cli_tiempo_facturacion_Media_V4 >= TS_Cli_tiempo_facturacion_Media_V1">{{TS_Cli_tiempo_facturacion_Media_V4.toFixed(2)}} %</td>
  </tr>
  <tr>
    <td class="linealt">{{ 'baja' | translate }}</td>

    <td class="datos" [class.verde]="TS_Cli_tiempo_facturacion_Baja_V1 >= TS_Cli_tiempo_facturacion_Baja_V2 && TS_Cli_tiempo_facturacion_Baja_V1 >= TS_Cli_tiempo_facturacion_Baja_V3 && TS_Cli_tiempo_facturacion_Baja_V1 >= TS_Cli_tiempo_facturacion_Baja_V4">{{TS_Cli_tiempo_facturacion_Baja_V1.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_Cli_tiempo_facturacion_Baja_V2 >= TS_Cli_tiempo_facturacion_Baja_V1 && TS_Cli_tiempo_facturacion_Baja_V2 >= TS_Cli_tiempo_facturacion_Baja_V3 && TS_Cli_tiempo_facturacion_Baja_V2 >= TS_Cli_tiempo_facturacion_Baja_V4">{{TS_Cli_tiempo_facturacion_Baja_V2.toFixed(2)}} %</td>
    <td class="datos" [class.verde]="TS_Cli_tiempo_facturacion_Baja_V3 >= TS_Cli_tiempo_facturacion_Baja_V2 && TS_Cli_tiempo_facturacion_Baja_V3 >= TS_Cli_tiempo_facturacion_Baja_V1 && TS_Cli_tiempo_facturacion_Baja_V3 >= TS_Cli_tiempo_facturacion_Baja_V4">{{TS_Cli_tiempo_facturacion_Baja_V3.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_Cli_tiempo_facturacion_Baja_V4 >= TS_Cli_tiempo_facturacion_Baja_V2 && TS_Cli_tiempo_facturacion_Baja_V4 >= TS_Cli_tiempo_facturacion_Baja_V3 && TS_Cli_tiempo_facturacion_Baja_V4 >= TS_Cli_tiempo_facturacion_Baja_V1">{{TS_Cli_tiempo_facturacion_Baja_V4.toFixed(2)}} %</td>
  </tr>
</table>

<!-- 5: TASA DE SERVICIO PRIORIDAD OF -->
<table cellspacing="0" cellpadding="0" class="tasadeservicio tablas-comparativas" [hidden]="aplicarTiempoComparativa">
  <tr>
    <td rowspan="3" class="principal">{{ 'tasaServicioPrioridadOF' | translate }}</td>
    <!--<th rowspan="3" class="titulo linea">{{ 'OF' | translate }}</th>-->
    <td class="linealt">{{ 'alta' | translate }}</td>

    <td class="datos" [class.verde]="TS_OF_prioridad_Alta_V1 >= TS_OF_prioridad_Alta_V2 && TS_OF_prioridad_Alta_V1 >= TS_OF_prioridad_Alta_V3 && TS_OF_prioridad_Alta_V1 >= TS_OF_prioridad_Alta_V4">{{TS_OF_prioridad_Alta_V1.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_OF_prioridad_Alta_V2 >= TS_OF_prioridad_Alta_V1 && TS_OF_prioridad_Alta_V2 >= TS_OF_prioridad_Alta_V3 && TS_OF_prioridad_Alta_V2 >= TS_OF_prioridad_Alta_V4">{{TS_OF_prioridad_Alta_V2.toFixed(2)}} %</td>
    <td class="datos" [class.verde]="TS_OF_prioridad_Alta_V3 >= TS_OF_prioridad_Alta_V2 && TS_OF_prioridad_Alta_V3 >= TS_OF_prioridad_Alta_V1 && TS_OF_prioridad_Alta_V3 >= TS_OF_prioridad_Alta_V4">{{TS_OF_prioridad_Alta_V3.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_OF_prioridad_Alta_V4 >= TS_OF_prioridad_Alta_V2 && TS_OF_prioridad_Alta_V4 >= TS_OF_prioridad_Alta_V3 && TS_OF_prioridad_Alta_V4 >= TS_OF_prioridad_Alta_V1">{{TS_OF_prioridad_Alta_V4.toFixed(2)}} %</td>
  </tr>
  <tr>
    <td class="linea">{{ 'media' | translate }}</td>

    <td class="datos" [class.verde]="TS_OF_prioridad_Media_V1 >= TS_OF_prioridad_Media_V2 && TS_OF_prioridad_Media_V1 >= TS_OF_prioridad_Media_V3 && TS_OF_prioridad_Media_V1 >= TS_OF_prioridad_Media_V4">{{TS_OF_prioridad_Media_V1.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_OF_prioridad_Media_V2 >= TS_OF_prioridad_Media_V1 && TS_OF_prioridad_Media_V2 >= TS_OF_prioridad_Media_V3 && TS_OF_prioridad_Media_V2 >= TS_OF_prioridad_Media_V4">{{TS_OF_prioridad_Media_V2.toFixed(2)}} %</td>
    <td class="datos" [class.verde]="TS_OF_prioridad_Media_V3 >= TS_OF_prioridad_Media_V2 && TS_OF_prioridad_Media_V3 >= TS_OF_prioridad_Media_V1 && TS_OF_prioridad_Media_V3 >= TS_OF_prioridad_Media_V4">{{TS_OF_prioridad_Media_V3.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_OF_prioridad_Media_V4 >= TS_OF_prioridad_Media_V2 && TS_OF_prioridad_Media_V4 >= TS_OF_prioridad_Media_V3 && TS_OF_prioridad_Media_V4 >= TS_OF_prioridad_Media_V1">{{TS_OF_prioridad_Media_V4.toFixed(2)}} %</td>
  </tr>
  <tr>
    <td class="linealt">{{ 'baja' | translate }}</td>

    <td class="datos" [class.verde]="TS_OF_prioridad_Baja_V1 >= TS_OF_prioridad_Baja_V2 && TS_OF_prioridad_Baja_V1 >= TS_OF_prioridad_Baja_V3 && TS_OF_prioridad_Baja_V1 >= TS_OF_prioridad_Baja_V4">{{TS_OF_prioridad_Baja_V1.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_OF_prioridad_Baja_V2 >= TS_OF_prioridad_Baja_V1 && TS_OF_prioridad_Baja_V2 >= TS_OF_prioridad_Baja_V3 && TS_OF_prioridad_Baja_V2 >= TS_OF_prioridad_Baja_V4">{{TS_OF_prioridad_Baja_V2.toFixed(2)}} %</td>
    <td class="datos" [class.verde]="TS_OF_prioridad_Baja_V3 >= TS_OF_prioridad_Baja_V2 && TS_OF_prioridad_Baja_V3 >= TS_OF_prioridad_Baja_V1 && TS_OF_prioridad_Baja_V3 >= TS_OF_prioridad_Baja_V4">{{TS_OF_prioridad_Baja_V3.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_OF_prioridad_Baja_V4 >= TS_OF_prioridad_Baja_V2 && TS_OF_prioridad_Baja_V4 >= TS_OF_prioridad_Baja_V3 && TS_OF_prioridad_Baja_V4 >= TS_OF_prioridad_Baja_V1">{{TS_OF_prioridad_Baja_V4.toFixed(2)}} %</td>
  </tr>
</table>
<table cellspacing="0" cellpadding="0" class="tasadeservicio tablas-comparativas" [hidden]="!aplicarTiempoComparativa">
  <tr>
    <td rowspan="3" class="principal">{{ 'tasaServicioPrioridadOF' | translate }}</td>
    <!--<th rowspan="3" class="titulo linea">{{ 'tiempo' | translate }}</th>-->
    <td class="linealt">{{ 'alta' | translate }}</td>

    <td class="datos" [class.verde]="TS_OF_tiempo_prioridad_Alta_V1 >= TS_OF_tiempo_prioridad_Alta_V2 && TS_OF_tiempo_prioridad_Alta_V1 >= TS_OF_tiempo_prioridad_Alta_V3 && TS_OF_tiempo_prioridad_Alta_V1 >= TS_OF_tiempo_prioridad_Alta_V4">{{TS_OF_tiempo_prioridad_Alta_V1.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_OF_tiempo_prioridad_Alta_V2 >= TS_OF_tiempo_prioridad_Alta_V1 && TS_OF_tiempo_prioridad_Alta_V2 >= TS_OF_tiempo_prioridad_Alta_V3 && TS_OF_tiempo_prioridad_Alta_V2 >= TS_OF_tiempo_prioridad_Alta_V4">{{TS_OF_tiempo_prioridad_Alta_V2.toFixed(2)}} %</td>
    <td class="datos" [class.verde]="TS_OF_tiempo_prioridad_Alta_V3 >= TS_OF_tiempo_prioridad_Alta_V2 && TS_OF_tiempo_prioridad_Alta_V3 >= TS_OF_tiempo_prioridad_Alta_V1 && TS_OF_tiempo_prioridad_Alta_V3 >= TS_OF_tiempo_prioridad_Alta_V4">{{TS_OF_tiempo_prioridad_Alta_V3.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_OF_tiempo_prioridad_Alta_V4 >= TS_OF_tiempo_prioridad_Alta_V2 && TS_OF_tiempo_prioridad_Alta_V4 >= TS_OF_tiempo_prioridad_Alta_V3 && TS_OF_tiempo_prioridad_Alta_V4 >= TS_OF_tiempo_prioridad_Alta_V1">{{TS_OF_tiempo_prioridad_Alta_V4.toFixed(2)}} %</td>
  </tr>
  <tr>
    <td class="linea">{{ 'media' | translate }}</td>

    <td class="datos" [class.verde]="TS_OF_tiempo_prioridad_Media_V1 >= TS_OF_tiempo_prioridad_Media_V2 && TS_OF_tiempo_prioridad_Media_V1 >= TS_OF_tiempo_prioridad_Media_V3 && TS_OF_tiempo_prioridad_Media_V1 >= TS_OF_tiempo_prioridad_Media_V4">{{TS_OF_tiempo_prioridad_Media_V1.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_OF_tiempo_prioridad_Media_V2 >= TS_OF_tiempo_prioridad_Media_V1 && TS_OF_tiempo_prioridad_Media_V2 >= TS_OF_tiempo_prioridad_Media_V3 && TS_OF_tiempo_prioridad_Media_V2 >= TS_OF_tiempo_prioridad_Media_V4">{{TS_OF_tiempo_prioridad_Media_V2.toFixed(2)}} %</td>
    <td class="datos" [class.verde]="TS_OF_tiempo_prioridad_Media_V3 >= TS_OF_tiempo_prioridad_Media_V2 && TS_OF_tiempo_prioridad_Media_V3 >= TS_OF_tiempo_prioridad_Media_V1 && TS_OF_tiempo_prioridad_Media_V3 >= TS_OF_tiempo_prioridad_Media_V4">{{TS_OF_tiempo_prioridad_Media_V3.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_OF_tiempo_prioridad_Media_V4 >= TS_OF_tiempo_prioridad_Media_V2 && TS_OF_tiempo_prioridad_Media_V4 >= TS_OF_tiempo_prioridad_Media_V3 && TS_OF_tiempo_prioridad_Media_V4 >= TS_OF_tiempo_prioridad_Media_V1">{{TS_OF_tiempo_prioridad_Media_V4.toFixed(2)}} %</td>
  </tr>
  <tr>
    <td class="linealt">{{ 'baja' | translate }}</td>

    <td class="datos" [class.verde]="TS_OF_tiempo_prioridad_Baja_V1 >= TS_OF_tiempo_prioridad_Baja_V2 && TS_OF_tiempo_prioridad_Baja_V1 >= TS_OF_tiempo_prioridad_Baja_V3 && TS_OF_tiempo_prioridad_Baja_V1 >= TS_OF_tiempo_prioridad_Baja_V4">{{TS_OF_tiempo_prioridad_Baja_V1.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_OF_tiempo_prioridad_Baja_V2 >= TS_OF_tiempo_prioridad_Baja_V1 && TS_OF_tiempo_prioridad_Baja_V2 >= TS_OF_tiempo_prioridad_Baja_V3 && TS_OF_tiempo_prioridad_Baja_V2 >= TS_OF_tiempo_prioridad_Baja_V4">{{TS_OF_tiempo_prioridad_Baja_V2.toFixed(2)}} %</td>
    <td class="datos" [class.verde]="TS_OF_tiempo_prioridad_Baja_V3 >= TS_OF_tiempo_prioridad_Baja_V2 && TS_OF_tiempo_prioridad_Baja_V3 >= TS_OF_tiempo_prioridad_Baja_V1 && TS_OF_tiempo_prioridad_Baja_V3 >= TS_OF_tiempo_prioridad_Baja_V4">{{TS_OF_tiempo_prioridad_Baja_V3.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="TS_OF_tiempo_prioridad_Baja_V4 >= TS_OF_tiempo_prioridad_Baja_V2 && TS_OF_tiempo_prioridad_Baja_V4 >= TS_OF_tiempo_prioridad_Baja_V3 && TS_OF_tiempo_prioridad_Baja_V4 >= TS_OF_tiempo_prioridad_Baja_V1">{{TS_OF_tiempo_prioridad_Baja_V4.toFixed(2)}} %</td>
  </tr>
</table>

<!-- 4: DISMINUCION DE PARADAS -->
<table cellspacing="0" cellpadding="0" class="disminucionParadas tablas-comparativas">
  <tr>
    <td rowspan="2" class="principal">{{ 'disminucionParadas' | translate }}</td>
    <!--<th rowspan="2" class="titulo linea">{{ 'libres' | translate }}</th>-->
    <td class="linea">{{ 'horas' | translate }}</td>

    <td class="datos" [class.verde]="tiempoLibreHHmmSS_V1_segundos >= tiempoLibreHHmmSS_V2_segundos && tiempoLibreHHmmSS_V1_segundos >= tiempoLibreHHmmSS_V3_segundos && tiempoLibreHHmmSS_V1_segundos >= tiempoLibreHHmmSS_V4_segundos">{{tiempoLibreHHmmSS_V1}}</td>
    <td class="datos colalt" [class.verde]="tiempoLibreHHmmSS_V2_segundos >= tiempoLibreHHmmSS_V1_segundos && tiempoLibreHHmmSS_V2_segundos >= tiempoLibreHHmmSS_V3_segundos && tiempoLibreHHmmSS_V2_segundos >= tiempoLibreHHmmSS_V4_segundos">{{tiempoLibreHHmmSS_V2}}</td>
    <td class="datos" [class.verde]="tiempoLibreHHmmSS_V3_segundos >= tiempoLibreHHmmSS_V2_segundos && tiempoLibreHHmmSS_V3_segundos >= tiempoLibreHHmmSS_V1_segundos && tiempoLibreHHmmSS_V3_segundos >= tiempoLibreHHmmSS_V4_segundos">{{tiempoLibreHHmmSS_V3}}</td>
    <td class="datos colalt" [class.verde]="tiempoLibreHHmmSS_V4_segundos >= tiempoLibreHHmmSS_V2_segundos && tiempoLibreHHmmSS_V4_segundos >= tiempoLibreHHmmSS_V3_segundos && tiempoLibreHHmmSS_V4_segundos >= tiempoLibreHHmmSS_V1_segundos">{{tiempoLibreHHmmSS_V4}}</td>
  </tr>
  <tr>
    <td class="linealt">{{ 'porcentaje' | translate }}</td>

    <td class="datos" [class.verde]="tiempoLibrePorcen_V1 >= tiempoLibrePorcen_V2 && tiempoLibrePorcen_V1 >= tiempoLibrePorcen_V3 && tiempoLibrePorcen_V1 >= tiempoLibrePorcen_V4">{{tiempoLibrePorcen_V1.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="tiempoLibrePorcen_V2 >= tiempoLibrePorcen_V1 && tiempoLibrePorcen_V2 >= tiempoLibrePorcen_V3 && tiempoLibrePorcen_V2 >= tiempoLibrePorcen_V4">{{tiempoLibrePorcen_V2.toFixed(2)}} %</td>
    <td class="datos" [class.verde]="tiempoLibrePorcen_V3 >= tiempoLibrePorcen_V2 && tiempoLibrePorcen_V3 >= tiempoLibrePorcen_V1 && tiempoLibrePorcen_V3 >= tiempoLibrePorcen_V4">{{tiempoLibrePorcen_V3.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="tiempoLibrePorcen_V4 >= tiempoLibrePorcen_V2 && tiempoLibrePorcen_V4 >= tiempoLibrePorcen_V3 && tiempoLibrePorcen_V4 >= tiempoLibrePorcen_V1">{{tiempoLibrePorcen_V4.toFixed(2)}} %</td>
  </tr>
</table>
<table cellspacing="0" cellpadding="0" class="horasExtra tablas-comparativas">
  <tr>
    <td rowspan="2" class="principal">{{ 'horasExtra' | translate }}</td>
    <!--<th rowspan="2" class="titulo linealt">{{ 'extras' | translate }}</th>-->
    <td class="linea">{{ 'horas' | translate }}</td>


    <td class="datos" [class.verde]="tiempoExtraHHmmSS_V1_segundos >= tiempoExtraHHmmSS_V2_segundos && tiempoExtraHHmmSS_V1_segundos >= tiempoExtraHHmmSS_V3_segundos && tiempoExtraHHmmSS_V1_segundos >= tiempoExtraHHmmSS_V4_segundos">{{tiempoExtraHHmmSS_V1}}</td>
    <td class="datos colalt" [class.verde]="tiempoExtraHHmmSS_V2_segundos >= tiempoExtraHHmmSS_V1_segundos && tiempoExtraHHmmSS_V2_segundos >= tiempoExtraHHmmSS_V3_segundos && tiempoExtraHHmmSS_V2_segundos >= tiempoExtraHHmmSS_V4_segundos">{{tiempoExtraHHmmSS_V2}}</td>
    <td class="datos" [class.verde]="tiempoExtraHHmmSS_V3_segundos >= tiempoExtraHHmmSS_V2_segundos && tiempoExtraHHmmSS_V3_segundos >= tiempoExtraHHmmSS_V1_segundos && tiempoExtraHHmmSS_V3_segundos >= tiempoExtraHHmmSS_V4_segundos">{{tiempoExtraHHmmSS_V3}}</td>
    <td class="datos colalt" [class.verde]="tiempoExtraHHmmSS_V4_segundos >= tiempoExtraHHmmSS_V2_segundos && tiempoExtraHHmmSS_V4_segundos >= tiempoExtraHHmmSS_V3_segundos && tiempoExtraHHmmSS_V4_segundos >= tiempoExtraHHmmSS_V1_segundos">{{tiempoExtraHHmmSS_V4}}</td>
  </tr>
  <tr>
    <td class="linealt">{{ 'porcentaje' | translate }}</td>

    <td class="datos" [class.verde]="tiempoExtraPorcen_V1 >= tiempoExtraPorcen_V2 && tiempoExtraPorcen_V1 >= tiempoExtraPorcen_V3 && tiempoExtraPorcen_V1 >= tiempoExtraPorcen_V4">{{tiempoExtraPorcen_V1.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="tiempoExtraPorcen_V2 >= tiempoExtraPorcen_V1 && tiempoExtraPorcen_V2 >= tiempoExtraPorcen_V3 && tiempoExtraPorcen_V2 >= tiempoExtraPorcen_V4">{{tiempoExtraPorcen_V2.toFixed(2)}} %</td>
    <td class="datos" [class.verde]="tiempoExtraPorcen_V3 >= tiempoExtraPorcen_V2 && tiempoExtraPorcen_V3 >= tiempoExtraPorcen_V1 && tiempoExtraPorcen_V3 >= tiempoExtraPorcen_V4">{{tiempoExtraPorcen_V3.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="tiempoExtraPorcen_V4 >= tiempoExtraPorcen_V2 && tiempoExtraPorcen_V4 >= tiempoExtraPorcen_V3 && tiempoExtraPorcen_V4 >= tiempoExtraPorcen_V1">{{tiempoExtraPorcen_V4.toFixed(2)}} %</td>
  </tr>
</table>


<!-- MARGEN DE ENTREGAS -->
<table cellspacing="0" cellpadding="0" class="margenEntrega tablas-comparativas" [hidden]="aplicarCalculoPiezas">
  <tr>
    <td rowspan="5" class="principal">{{ 'margenEntrega' | translate }}</td>
    <!--<th rowspan="5" class="titulo linea">{{ 'OF' | translate }}</th>-->
    <td class="linealt">{{ 'fueraDePlazo' | translate }}</td>

    <td class="datos" [class.verde]="margenNegativo_V1 >= margenNegativo_V2 && margenNegativo_V1 >= margenNegativo_V3 && margenNegativo_V1 >= margenNegativo_V4">{{margenNegativo_V1}}</td>
    <td class="datos colalt" [class.verde]="margenNegativo_V2 >= margenNegativo_V1 && margenNegativo_V2 >= margenNegativo_V3 && margenNegativo_V2 >= margenNegativo_V4">{{margenNegativo_V2}}</td>
    <td class="datos" [class.verde]="margenNegativo_V3 >= margenNegativo_V2 && margenNegativo_V3 >= margenNegativo_V1 && margenNegativo_V3 >= margenNegativo_V4">{{margenNegativo_V3}}</td>
    <td class="datos colalt" [class.verde]="margenNegativo_V4 >= margenNegativo_V2 && margenNegativo_V4 >= margenNegativo_V3 && margenNegativo_V4 >= margenNegativo_V1">{{margenNegativo_V4}}</td>
  </tr>
  <tr>
    <td class="linea">{{ 'margencorto' | translate }}</td>

    <td class="datos" [class.verde]="margenCorto_V1 >= margenCorto_V2 && margenCorto_V1 >= margenCorto_V3 && margenCorto_V1 >= margenCorto_V4">{{margenCorto_V1}}</td>
    <td class="datos colalt" [class.verde]="margenCorto_V2 >= margenCorto_V1 && margenCorto_V2 >= margenCorto_V3 && margenCorto_V2 >= margenCorto_V4">{{margenCorto_V2}}</td>
    <td class="datos" [class.verde]="margenCorto_V3 >= margenCorto_V2 && margenCorto_V3 >= margenCorto_V1 && margenCorto_V3 >= margenCorto_V4">{{margenCorto_V3}}</td>
    <td class="datos colalt" [class.verde]="margenCorto_V4 >= margenCorto_V2 && margenCorto_V4 >= margenCorto_V3 && margenCorto_V4 >= margenCorto_V1">{{margenCorto_V4}}</td>
  </tr>
  <tr>
    <td class="linealt">{{ 'medio' | translate }}</td>

    <td class="datos" [class.verde]="margenMedio_V1 >= margenMedio_V2 && margenMedio_V1 >= margenMedio_V3 && margenMedio_V1 >= margenMedio_V4">{{margenMedio_V1}}</td>
    <td class="datos colalt" [class.verde]="margenMedio_V2 >= margenMedio_V1 && margenMedio_V2 >= margenMedio_V3 && margenMedio_V2 >= margenMedio_V4">{{margenMedio_V2}}</td>
    <td class="datos" [class.verde]="margenMedio_V3 >= margenMedio_V2 && margenMedio_V3 >= margenMedio_V1 && margenMedio_V3 >= margenMedio_V4">{{margenMedio_V3}}</td>
    <td class="datos colalt" [class.verde]="margenMedio_V4 >= margenMedio_V2 && margenMedio_V4 >= margenMedio_V3 && margenMedio_V4 >= margenMedio_V1">{{margenMedio_V4}}</td>
  </tr>
  <tr>
    <td class="linea">{{ 'largo' | translate }}</td>

    <td class="datos" [class.verde]="margenLargo_V1 >= margenLargo_V2 && margenLargo_V1 >= margenLargo_V3 && margenLargo_V1 >= margenLargo_V4">{{margenLargo_V1}}</td>
    <td class="datos colalt" [class.verde]="margenLargo_V2 >= margenLargo_V1 && margenLargo_V2 >= margenLargo_V3 && margenLargo_V2 >= margenLargo_V4">{{margenLargo_V2}}</td>
    <td class="datos" [class.verde]="margenLargo_V3 >= margenLargo_V2 && margenLargo_V3 >= margenLargo_V1 && margenLargo_V3 >= margenLargo_V4">{{margenLargo_V3}}</td>
    <td class="datos colalt" [class.verde]="margenLargo_V4 >= margenLargo_V2 && margenLargo_V4 >= margenLargo_V3 && margenLargo_V4 >= margenLargo_V1">{{margenLargo_V4}}</td>
  </tr>
  <tr>
    <td class="linealt">{{ 'resto' | translate }}</td>

    <td class="datos" [class.verde]="margenResto_V1 >= margenResto_V2 && margenResto_V1 >= margenResto_V3 && margenResto_V1 >= margenResto_V4">{{margenResto_V1}}</td>
    <td class="datos colalt" [class.verde]="margenResto_V2 >= margenResto_V1 && margenResto_V2 >= margenResto_V3 && margenResto_V2 >= margenResto_V4">{{margenResto_V2}}</td>
    <td class="datos" [class.verde]="margenResto_V3 >= margenResto_V2 && margenResto_V3 >= margenResto_V1 && margenResto_V3 >= margenResto_V4">{{margenResto_V3}}</td>
    <td class="datos colalt" [class.verde]="margenResto_V4 >= margenResto_V2 && margenResto_V4 >= margenResto_V3 && margenResto_V4 >= margenResto_V1">{{margenResto_V4}}</td>
  </tr>
</table>
<table cellspacing="0" cellpadding="0" class="margenEntrega tablas-comparativas" [hidden]="!aplicarCalculoPiezas">
  <tr>
    <td rowspan="5" class="principal">{{ 'margenEntrega' | translate }}</td>
    <!--<th rowspan="5" class="titulo linealt">{{ 'piezas' | translate }}</th>-->
    <td class="linealt">{{ 'fueraDePlazo' | translate }}</td>

    <td class="datos" [class.verde]="margenNegativoPiezas_V1 >= margenNegativoPiezas_V2 && margenNegativoPiezas_V1 >= margenNegativoPiezas_V3 && margenNegativoPiezas_V1 >= margenNegativoPiezas_V4">{{margenNegativoPiezas_V1}}</td>
    <td class="datos colalt" [class.verde]="margenNegativoPiezas_V2 >= margenNegativoPiezas_V1 && margenNegativoPiezas_V2 >= margenNegativoPiezas_V3 && margenNegativoPiezas_V2 >= margenNegativoPiezas_V4">{{margenNegativoPiezas_V2}}</td>
    <td class="datos" [class.verde]="margenNegativoPiezas_V3 >= margenNegativoPiezas_V2 && margenNegativoPiezas_V3 >= margenNegativoPiezas_V1 && margenNegativoPiezas_V3 >= margenNegativoPiezas_V4">{{margenNegativoPiezas_V3}}</td>
    <td class="datos colalt" [class.verde]="margenNegativoPiezas_V4 >= margenNegativoPiezas_V2 && margenNegativoPiezas_V4 >= margenNegativoPiezas_V3 && margenNegativoPiezas_V4 >= margenNegativoPiezas_V1">{{margenNegativoPiezas_V4}}</td>
  </tr>
  <tr>
    <td class="linea">{{ 'margencorto' | translate }}</td>

    <td class="datos" [class.verde]="margenCortoPiezas_V1 >= margenCortoPiezas_V2 && margenCortoPiezas_V1 >= margenCortoPiezas_V3 && margenCortoPiezas_V1 >= margenCortoPiezas_V4">{{margenCortoPiezas_V1}}</td>
    <td class="datos colalt" [class.verde]="margenCortoPiezas_V2 >= margenCortoPiezas_V1 && margenCortoPiezas_V2 >= margenCortoPiezas_V3 && margenCortoPiezas_V2 >= margenCortoPiezas_V4">{{margenCortoPiezas_V2}}</td>
    <td class="datos" [class.verde]="margenCortoPiezas_V3 >= margenCortoPiezas_V2 && margenCortoPiezas_V3 >= margenCortoPiezas_V1 && margenCortoPiezas_V3 >= margenCortoPiezas_V4">{{margenCortoPiezas_V3}}</td>
    <td class="datos colalt" [class.verde]="margenCortoPiezas_V4 >= margenCortoPiezas_V2 && margenCortoPiezas_V4 >= margenCortoPiezas_V3 && margenCortoPiezas_V4 >= margenCortoPiezas_V1">{{margenCortoPiezas_V4}}</td>
  </tr>
  <tr>
    <td class="linealt">{{ 'medio' | translate }}</td>

    <td class="datos" [class.verde]="margenMedioPiezas_V1 >= margenMedioPiezas_V2 && margenMedioPiezas_V1 >= margenMedioPiezas_V3 && margenMedioPiezas_V1 >= margenMedioPiezas_V4">{{margenMedioPiezas_V1}}</td>
    <td class="datos colalt" [class.verde]="margenMedioPiezas_V2 >= margenMedioPiezas_V1 && margenMedioPiezas_V2 >= margenMedioPiezas_V3 && margenMedioPiezas_V2 >= margenMedioPiezas_V4">{{margenMedioPiezas_V2}}</td>
    <td class="datos" [class.verde]="margenMedioPiezas_V3 >= margenMedioPiezas_V2 && margenMedioPiezas_V3 >= margenMedioPiezas_V1 && margenMedioPiezas_V3 >= margenMedioPiezas_V4">{{margenMedioPiezas_V3}}</td>
    <td class="datos colalt" [class.verde]="margenMedioPiezas_V4 >= margenMedioPiezas_V2 && margenMedioPiezas_V4 >= margenMedioPiezas_V3 && margenMedioPiezas_V4 >= margenMedioPiezas_V1">{{margenMedioPiezas_V4}}</td>
  </tr>
  <tr>
    <td class="linea">{{ 'largo' | translate }}</td>

    <td class="datos" [class.verde]="margenLargoPiezas_V1 >= margenLargoPiezas_V2 && margenLargoPiezas_V1 >= margenLargoPiezas_V3 && margenLargoPiezas_V1 >= margenLargoPiezas_V4">{{margenLargoPiezas_V1}}</td>
    <td class="datos colalt" [class.verde]="margenLargoPiezas_V2 >= margenLargoPiezas_V1 && margenLargoPiezas_V2 >= margenLargoPiezas_V3 && margenLargoPiezas_V2 >= margenLargoPiezas_V4">{{margenLargoPiezas_V2}}</td>
    <td class="datos" [class.verde]="margenLargoPiezas_V3 >= margenLargoPiezas_V2 && margenLargoPiezas_V3 >= margenLargoPiezas_V1 && margenLargoPiezas_V3 >= margenLargoPiezas_V4">{{margenLargoPiezas_V3}}</td>
    <td class="datos colalt" [class.verde]="margenLargoPiezas_V4 >= margenLargoPiezas_V2 && margenLargoPiezas_V4 >= margenLargoPiezas_V3 && margenLargoPiezas_V4 >= margenLargoPiezas_V1">{{margenLargoPiezas_V4}}</td>
  </tr>
  <tr>
    <td class="linealt">{{ 'resto' | translate }}</td>

    <td class="datos" [class.verde]="margenRestoPiezas_V1 >= margenRestoPiezas_V2 && margenRestoPiezas_V1 >= margenRestoPiezas_V3 && margenRestoPiezas_V1 >= margenRestoPiezas_V4">{{margenRestoPiezas_V1}}</td>
    <td class="datos colalt" [class.verde]="margenRestoPiezas_V2 >= margenRestoPiezas_V1 && margenRestoPiezas_V2 >= margenRestoPiezas_V3 && margenRestoPiezas_V2 >= margenRestoPiezas_V4">{{margenRestoPiezas_V2}}</td>
    <td class="datos" [class.verde]="margenRestoPiezas_V3 >= margenRestoPiezas_V2 && margenRestoPiezas_V3 >= margenRestoPiezas_V1 && margenRestoPiezas_V3 >= margenRestoPiezas_V4">{{margenRestoPiezas_V3}}</td>
    <td class="datos colalt" [class.verde]="margenRestoPiezas_V4 >= margenRestoPiezas_V2 && margenRestoPiezas_V4 >= margenRestoPiezas_V3 && margenRestoPiezas_V4 >= margenRestoPiezas_V1">{{margenRestoPiezas_V4}}</td>
  </tr>
</table>


<!-- RETRASO MEDIO -->
<table cellspacing="0" cellpadding="0" class="retrasoMedio tablas-comparativas" [hidden]="aplicarCalculoPiezas">
  <tr>
    <td rowspan="1" class="principal">{{ 'retrasoMedioEnDias' | translate }}</td>
    <!--<th class="linea">{{ 'OF' | translate }}</th>-->

    <td class="datos" [class.verde]="retrasoMedio_V1 >= retrasoMedio_V2 && retrasoMedio_V1 >= retrasoMedio_V3 && retrasoMedio_V1 >= retrasoMedio_V4">{{retrasoMedio_V1.toFixed(2)}} {{ 'dias' | translate }}</td>
    <td class="datos colalt" [class.verde]="retrasoMedio_V2 >= retrasoMedio_V1 && retrasoMedio_V2 >= retrasoMedio_V3 && retrasoMedio_V2 >= retrasoMedio_V4">{{retrasoMedio_V2.toFixed(2)}} {{ 'dias' | translate }}</td>
    <td class="datos" [class.verde]="retrasoMedio_V3 >= retrasoMedio_V2 && retrasoMedio_V3 >= retrasoMedio_V1 && retrasoMedio_V1 >= retrasoMedio_V4">{{retrasoMedio_V3.toFixed(2)}} {{ 'dias' | translate }}</td>
    <td class="datos colalt" [class.verde]="retrasoMedio_V4 >= retrasoMedio_V2 && retrasoMedio_V4 >= retrasoMedio_V3 && retrasoMedio_V4 >= retrasoMedio_V1">{{retrasoMedio_V4.toFixed(2)}} {{ 'dias' | translate }}</td>
  </tr>
</table>
<table cellspacing="0" cellpadding="0" class="retrasoMedio tablas-comparativas" [hidden]="!aplicarCalculoPiezas">
  <tr>
    <td rowspan="1" class="principal">{{ 'retrasoMedioEnDias' | translate }}</td>
    <!--<th class="linea">{{ 'piezas' | translate }}</th>-->

    <td class="datos" [class.verde]="retrasoMedioPiezas_V1 >= retrasoMedioPiezas_V2 && retrasoMedioPiezas_V1 >= retrasoMedioPiezas_V3 && retrasoMedioPiezas_V1 >= retrasoMedioPiezas_V4">{{retrasoMedioPiezas_V1.toFixed(2)}} {{ 'dias' | translate }}</td>
    <td class="datos colalt" [class.verde]="retrasoMedioPiezas_V2 >= retrasoMedioPiezas_V1 && retrasoMedioPiezas_V2 >= retrasoMedioPiezas_V3 && retrasoMedioPiezas_V2 >= retrasoMedioPiezas_V4">{{retrasoMedioPiezas_V2.toFixed(2)}} {{ 'dias' | translate }}</td>
    <td class="datos" [class.verde]="retrasoMedioPiezas_V3 >= retrasoMedioPiezas_V2 && retrasoMedioPiezas_V3 >= retrasoMedioPiezas_V1 && retrasoMedioPiezas_V3 >= retrasoMedioPiezas_V4">{{retrasoMedioPiezas_V3.toFixed(2)}} {{ 'dias' | translate }}</td>
    <td class="datos colalt" [class.verde]="retrasoMedioPiezas_V4 >= retrasoMedioPiezas_V2 && retrasoMedioPiezas_V4 >= retrasoMedioPiezas_V3 && retrasoMedioPiezas_V4 >= retrasoMedioPiezas_V1">{{retrasoMedioPiezas_V1.toFixed(2)}} {{ 'dias' | translate }}</td>
  </tr>
</table>

<!-- VALORACION MEDIA -->
<table cellspacing="0" cellpadding="0" class="valoracionMedia tablas-comparativas">
  <tr>
    <td rowspan="1" class="principal">{{ 'valoracionMedia' | translate }}</td>

    <td class="datos" [class.verde]="valoracionMedia_V1 >= valoracionMedia_V2 && valoracionMedia_V1 >= valoracionMedia_V3 && valoracionMedia_V1 >= valoracionMedia_V4">{{valoracionMedia_V1.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="valoracionMedia_V2 >= valoracionMedia_V1 && valoracionMedia_V1 >= valoracionMedia_V2 && valoracionMedia_V2 >= valoracionMedia_V4">{{valoracionMedia_V2.toFixed(2)}} %</td>
    <td class="datos" [class.verde]="valoracionMedia_V2 >= valoracionMedia_V2 && valoracionMedia_V2 >= valoracionMedia_V1 && valoracionMedia_V2 >= valoracionMedia_V4">{{valoracionMedia_V3.toFixed(2)}} %</td>
    <td class="datos colalt" [class.verde]="valoracionMedia_V4 >= valoracionMedia_V2 && valoracionMedia_V4 >= valoracionMedia_V3 && valoracionMedia_V4 >= valoracionMedia_V1">{{valoracionMedia_V4.toFixed(2)}} %</td>
  </tr>
</table>

<button type="button" class="btn btn-primary" (click)="btnAceptar()" [disabled]="this.user.planificadorLargo<2">{{ 'aceptar' | translate }}</button>

<div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;"></div>
