import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/programas`;

@Injectable()
export class ProgramasService {

  constructor(private http: HttpClient) {}

  //SELECT
  GetProgramas() {
    return this.http.post<JSON>(`${baseUrl}/Get_Programas`, {}, { withCredentials: true });
  }

  GetVersiones() {
    return this.http.post<JSON>(`${baseUrl}/Get_Versiones`, {}, { withCredentials: true });
  }

  GetSubVersiones(programa, version) {
    return this.http.post<JSON>(`${baseUrl}/Get_Subversiones`, {programa, version}, { withCredentials: true });
  }

  public GetProgramasTotales() {
    return this.http.post<JSON>(`${baseUrl}/Get_ProgramasTotales`, {}, { withCredentials: true });
  }

}
