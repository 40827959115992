import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService, UsuariosService, AlmacenesService, HerramientasService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-vista-almacenes',
  templateUrl: './vistaAlmacenes.html'
})

export class VistaAlmacenesComponent {

  user = this.usuariosService.userValue;

  public listaAlmacenes: any = [];
  public almacenSeleccionado: any;

  public datosTiposHerramienta: any;
  public datosHerramienta: any;
  public datosTiposPlaca: any;
  public datosPlaca: any;

  public listaHerramientas: any = [];
  public listaPlacas: any = [];

  constructor(private usuariosService: UsuariosService,
    private menuService: MenuService,
    public router: Router,
    private almacenesService: AlmacenesService,
    private herramientasService: HerramientasService,
    private translateService: TranslateService) {

    this.menuService.titulo = this.translateService.instant('vistaAlmacenes').toUpperCase();

  }

  ngOnInit(): void {

    this.cargarDatos();

  }

  cargarDatos() {

    var r1, r2, r3, r4, r5: boolean = false;

    this.almacenesService.GetAlmacenesConImagen().subscribe((result) => {
      var listaAlmacenes: any = result;
      listaAlmacenes.push({ id: 0, nombre: this.translateService.instant('todas'), imagenBase64: "../../assets/almacen.png" });
      this.listaAlmacenes = listaAlmacenes;
      if (this.listaAlmacenes.length > 0) this.almacenSeleccionado = this.listaAlmacenes[0];
      r1 = true;
      if (r1 && r2 && r3 && r4 && r5) this.filtrarDatos();
    });

    //TIPOS HERRAMIENTA
    this.herramientasService.GetAll().subscribe((result) => {
      this.datosTiposHerramienta = result.data;
      r2 = true;
      if (r1 && r2 && r3 && r4 && r5) this.filtrarDatos();
    });

    //HERRAMIENTAS
    this.herramientasService.GetAllHerramientasUnidades().subscribe((result) => {
      this.datosHerramienta = result.data;
      r3 = true;
      if (r1 && r2 && r3 && r4 && r5) this.filtrarDatos();
    });

    //TIPOS PLACA
    this.herramientasService.GetAllPlacas().subscribe((result) => {
      this.datosTiposPlaca = result.data;
      r4 = true;
      if (r1 && r2 && r3 && r4 && r5) this.filtrarDatos();
    });

    //PLACAS
    this.herramientasService.GetAllPlacasUnidades().subscribe((result) => {
      this.datosPlaca = result.data;
      r5 = true;
      if (r1 && r2 && r3 && r4 && r5) this.filtrarDatos();
    });

  }

  clickAlmacen(almacen) {
    this.almacenSeleccionado = almacen;
    this.filtrarDatos();
  }

  filtrarDatos() {
    this.cargarHerramientas();
    this.cargarPlacas();
  }

  cargarHerramientas() {

    var tiposHerramienta: any = this.datosTiposHerramienta;
    tiposHerramienta.forEach(function (th) { th.details = []; });
    var herramientas: any = this.datosHerramienta;

    herramientas.forEach(function (h) {
      if (h.idAlmacen == this.almacenSeleccionado.id || this.almacenSeleccionado.id == 0) {
        var tipoHerramienta = tiposHerramienta.find(x => x.id === h.idTipoHerramienta);
        tipoHerramienta.details.push(h);
      }
    }, this);

    this.listaHerramientas = tiposHerramienta.filter(th => th.details.length > 0);

  }

  cargarPlacas() {

    var tiposPlaca: any = this.datosTiposPlaca;
    tiposPlaca.forEach(function (tp) { tp.details = []; });
    var placas: any = this.datosPlaca;

    placas.forEach(function (p) {
      if (p.idAlmacen == this.almacenSeleccionado.id || this.almacenSeleccionado.id == 0) {
        var tipoPlaca = tiposPlaca.find(x => x.id === p.idTipoPlaca);
        tipoPlaca.details.push(p);
      }
    }, this);

    this.listaPlacas = tiposPlaca.filter(tp => tp.details.length > 0);

  }

}
