import { Component, ViewChild } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router"
import { UsuariosService, MaquinasService, HistoricoAlarmasService, MenuService, InformeProyectosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { MyFunctions } from '@app/_helpers';
import { CompositeFilterDescriptor, GroupResult, groupBy, filterBy, State } from '@progress/kendo-data-query';
import { first } from 'rxjs/operators';
import { DataStateChangeEvent, SelectAllCheckboxState } from '@progress/kendo-angular-grid';

interface ItemTurno {
  nombre: string,
  id: number
}

@Component({
  selector: 'app-historicoalarmas',
  templateUrl: 'historicoalarmas.component.html'
})

export class HistoricoAlarmasComponent {

  user = this.userService.userValue;

  // GRID
  public selectAllState: SelectAllCheckboxState = 'unchecked';
  public state: State;
  public grid: any;

  //PANEL MAQUINAS
  public selectedMaquinas: any = [];
  public maquinas: any = [];
  public instalaciones: any = [];
  public maquinasMostradas: any = [];

  //AREA PRODUCTIVA / SECCION
  public secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //GRUPOS DE MAQUINAS
  public grupos: any;
  public gruposSeleccionados: any;

  //CARGA DE SECCIONES Y GRUPOS
  public gruposCargados: boolean = false;
  public seccionesCargadas: boolean = false;

  //FECHAS
  public fechaInicio: Date;
  public fechaFin: Date;

  private translate: TranslateService;
  public historicoalarmas: any[];
  public imagenes: any;

  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  closeResult = '';
  navigationSubscription;

  public aparecer = false;

  //FILTRO *START*
  status: boolean = true; //para abrir o cerrar el filtro *se carga abierto siempre!* si se autofiltra, se cierra solo.

  public fechaInicioFiltro: any;
  public fechaFinFiltro: any;

  public original: any;

  // NO SE PUEDEN CAMBIAR LOS PROXIMOS IDs *START*

  //en las funciones de nuestro a kendo se usan estos IDs, tanto los de AND/OR como los de las opciones de cada tipo de dato.
  public andOr = [
    { id: 0, nombre: this.translateService.instant('y') },
    { id: 1, nombre: this.translateService.instant('o') }
  ];

  public opcionDate = [
    { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
    { id: 8, nombre: this.translateService.instant('noEstaEntre'), dobleValor: true }
  ];

  public opcionDateTime = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translateService.instant('menorQue'), dobleValor: false },
    { id: 2, nombre: this.translateService.instant('menorOigual'), dobleValor: false },
    { id: 3, nombre: this.translateService.instant('mayorQue'), dobleValor: false },
    { id: 4, nombre: this.translateService.instant('mayorOigual'), dobleValor: false },
    { id: 5, nombre: this.translateService.instant('es'), dobleValor: false },
    { id: 6, nombre: this.translateService.instant('noEs'), dobleValor: false }
  ];

  public opcionNumericDecimal = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translateService.instant('menorQue'), dobleValor: false },
    { id: 2, nombre: this.translateService.instant('menorOigual'), dobleValor: false },
    { id: 3, nombre: this.translateService.instant('mayorQue'), dobleValor: false },
    { id: 4, nombre: this.translateService.instant('mayorOigual'), dobleValor: false },
    { id: 5, nombre: this.translateService.instant('es'), dobleValor: false },
    { id: 6, nombre: this.translateService.instant('noEs'), dobleValor: false },
    { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
    { id: 8, nombre: this.translateService.instant('noEstaEntre'), dobleValor: true }
  ];

  public opcionComboEstricto = [
    { id: 2, nombre: this.translateService.instant('es'), dobleValor: true }, // solo hase van a mostrar combos multiseleccionables IN / OUT
    { id: 4, nombre: this.translateService.instant('noEs'), dobleValor: true }// solo hase van a mostrar combos multiseleccionables IN / OUT
  ];

  public opcionComboFlexible = [
    { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
    // { id: 3, nombre: 'No select', dobleValor: false},
    { id: 4, nombre: this.translateService.instant('noEs'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
    { id: 5, nombre: this.translateService.instant('empiezaPor'), dobleValor: false },
    // { id: 6, nombre: 'No empieza por', dobleValor: false},
    { id: 7, nombre: this.translateService.instant('acabaPor'), dobleValor: false },
    // { id: 8, nombre: 'No acaba por', dobleValor: false},
    { id: 9, nombre: this.translateService.instant('contiene'), dobleValor: false },
    { id: 10, nombre: this.translateService.instant('noContiene'), dobleValor: false }//,
    // { id: 11, nombre: 'Igual que', dobleValor: false},
    // { id: 12, nombre: 'Diferente a', dobleValor: false}
  ];

  public opcionComboFlexibleOperarioMaquinas = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Uniselect', dobleValor: false},
    { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
    // { id: 3, nombre: 'No select', dobleValor: false},
    { id: 4, nombre: this.translateService.instant('noEs'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
    //{ id: 5, nombre: this.translateService.instant('empiezaPor'), dobleValor: false },
    // { id: 6, nombre: 'No empieza por', dobleValor: false},
    //{ id: 7, nombre: this.translateService.instant('acabaPor'), dobleValor: false },
    // { id: 8, nombre: 'No acaba por', dobleValor: false},
    { id: 9, nombre: this.translateService.instant('contiene'), dobleValor: false },
    { id: 10, nombre: this.translateService.instant('noContiene'), dobleValor: false }//,
    // { id: 11, nombre: 'Igual que', dobleValor: false},
    // { id: 12, nombre: 'Diferente a', dobleValor: false}
  ];

  public opcionString = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translateService.instant('empiezaPor'), dobleValor: false },
    // { id: 2, nombre: 'No empieza por', dobleValor: false},
    { id: 3, nombre: this.translateService.instant('acabaPor'), dobleValor: false },
    // { id: 4, nombre: 'No acaba por', dobleValor: false},
    { id: 5, nombre: this.translateService.instant('contiene'), dobleValor: false },
    { id: 6, nombre: this.translateService.instant('noContiene'), dobleValor: false },
    { id: 7, nombre: this.translateService.instant('igualQue'), dobleValor: false },
    { id: 8, nombre: this.translateService.instant('diferenteA'), dobleValor: false }
  ];

  // NO SE PUEDEN CAMBIAR LOS PROXIMOS IDs *END*

  //FECHA DOBLE
  public DaysInMonths;

  // VARIABLES input del filtro
  public permitirFiltroVacio = false; // permite vaciar el filtro por completo
  public vaciarUltimaLinea = true; // cuando no se deja vaciar por completo un filtro, este resetea la ultima linea al intentar eliminarla
  public permitirMultiplesLineasVacias = false; // perminte añadir mas lineas que las que se han rellenado
  public annadirAutomatico = true; // añade lineas automaticamente si se selecciona la columna o la operacion Esta opcion contradice la de perminir multiples lineas vacias
  public dobleClickMismaFecha = false; // permite seleccionar la misma fecha en inicio y fin en el control doble de fechas

  // DATOS INICIALES DEL FILTRO
  public filtroPorDefecto = {
    logic: { id: 1, nombre: this.translateService.instant('o') },
    group: [
      {
        logic: { id: 0, nombre: this.translateService.instant('y') },
        group: [
          {
            columna: { id: 1, nombre: this.translateService.instant('fecha'), field: "fechaIniFiltro", sqlfield: "hb.fechaTurno", tipo: 'date' },
            operator: { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          },
          {
            columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        ]
      }
    ]
  };

  public datosFiltro = {
    logic: { id: 1, nombre: this.translateService.instant('o') },
    group: []
  };

  //ESTOS SON LOS TIPOS QUE SE ACEPTAN EN EL FILTRO: date, dateTime, comboEstrincto, comboFlexible, comboFlexibleOperarioMaquina check, numeric, decimal, string
  public columnasFiltro = [
    { id: 1, nombre: this.translateService.instant('fecha'), field: "fechaIniFiltro", sqlfield: "hb.fechaTurno", tipo: 'date' },
    { id: 2, nombre: this.translateService.instant('of'), field: "refOF", sqlfield: "do.numeroOF", tipo: 'comboFlexible' },
    { id: 3, nombre: this.translateService.instant('cliente'), field: "cliente", sqlfield: "do.nombreCliente", tipo: 'comboFlexible' },
    { id: 4, nombre: this.translateService.instant('pieza'), field: "pieza", sqlfield: "do.nombrePieza", tipo: 'comboFlexible' },
    { id: 5, nombre: this.translateService.instant('terminado'), field: "terminado", sqlfield: "do.operacionTerminada", tipo: 'check' },
    { id: 6, nombre: this.translateService.instant('valores'), field: "valorFueraLimites", sqlfield: "hb.valorFueraLimites", tipo: 'comboEstrincto' },
    { id: 7, nombre: this.translateService.instant('estado'), field: "estado", sqlfield: "m.estado", tipo: 'comboEstrincto' },
    { id: 8, nombre: this.translateService.instant('nserie'), field: "numeroSerie", sqlfield: "do.numeroSerie", tipo: 'comboFlexible' },
    { id: 9, nombre: this.translateService.instant('operario'), field: "operarios", sqlfield: "do.operarios", tipo: 'comboFlexibleOperarioMaquina' },
    { id: 10, nombre: this.translateService.instant('maquina'), field: "maquinas", sqlfield: "do.maquinas", tipo: 'comboFlexibleOperarioMaquina' },
    { id: 11, nombre: this.translateService.instant('plano'), field: "numeroPlano", sqlfield: "do.numeroPlano", tipo: 'comboFlexible' },
  ];

  //Combo cargable del filtro
  public filtro_listaMaquinas: any;
  public filtro_listaOperarios: any;
  public filtro_listaValores: any;
  public filtro_listaEstado: any;
  public filtro_listaTurnos: any;
  public filtro_listaHerramientas: any;
  public filtro_ocultarPartes: boolean;
  //variables para cuando la precarga requiere de alguna consulta
  public idpieza_prefiltro: number = 0;
  public idof_prefiltro: number = 0;
  public nSerie_prefiltro: string = "";
  public idOperacion_prefiltro: number = 0;
  //LOADING filtro
  public loadingFiltro: boolean = false;
  public actualizarVisible: boolean = false;

  public fini_filtro: any;
  public ffin_filtro: any;

  public listaTurnos: any;
  public turnosSeleccionados: any;
  public datosValores: any;
  public datosValoresSelected: any;
  public datosEstado: any;
  public datosEstadoSelected: any;
  private dataFiltro: any;
  private dataFiltroAux: any;
  public loadingPanel: boolean = false;
  public opcionesSeleccionadas: boolean = false;

  public listaOperarios: any;
  public operariosSeleccionados: any;
  public listaMaquinas: any;
  public listaMaquinasMostradas: any;
  public maquinasSeleccionadas: any;

  // Fechas para controlar el rango de si hacer o no consulta nueva
  public fechaIniUltimaConsulta: any;
  public fechaFinUltimaConsulta: any;

  public turnosCombo: Array<ItemTurno> = [];

  //FILTRO *END*

  constructor(private historicoAlarmasService: HistoricoAlarmasService,
    private maquinasService: MaquinasService,
    private informeProyectosService: InformeProyectosService,
    private userService: UsuariosService,
    translate: TranslateService,
    private menuService: MenuService,
    private translateService: TranslateService,
    private myFunctions: MyFunctions,
    public router: Router,
    private route: ActivatedRoute,) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('historicoalarmas').toUpperCase();

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/historicoalarmas') {
          
        }
      }
    });

    this.turnosCombo = [
      { nombre: this.translate.instant("manana"), id: 1 },
      { nombre: this.translate.instant("tarde"), id: 2 },
      { nombre: this.translate.instant("noche"), id: 3 },
      { nombre: this.translate.instant("fueraTurno"), id: 5 }
    ];

    // filtro
    this.cargarGrupos();
    this.cargarAreasProductivas();
  }

  ngOnInit() {

    this.loadingPanel = true;

    this.fechaInicio = new Date(this.myFunctions.getDateNow().getTime() - (7 * 24 * 60 * 60 * 1000));//7 egun atzera
    this.fechaFin = this.myFunctions.getDateNow();
    this.fechaInicio.setHours(0, 0, 0);
    this.fechaFin.setHours(0, 0, 0);

    // actualizar la fecha de ultima consulta
    var regex = /01:00:00/i;
    this.fechaIniUltimaConsulta = this.myFunctions.datetimeToSQL(new Date (this.fechaInicio));
    this.fechaIniUltimaConsulta = this.fechaIniUltimaConsulta.replace(regex, '00:00:00');
    this.fechaFinUltimaConsulta = this.myFunctions.datetimeToSQL(new Date (this.fechaFin));
    this.fechaFinUltimaConsulta = this.fechaFinUltimaConsulta.replace(regex, '00:00:00');
    
    // this.cargarGrupos();
    // this.cargarAreasProductivas();

  }

  cargarGrupos() {

    this.maquinasService.getGruposMaquinas().subscribe(json => {
      this.grupos = json.data;
      this.gruposCargados = true;
      if (this.gruposCargados && this.seccionesCargadas) this.filtrarMaquinasPorAreaProductivaYGrupo();
      this.cargar_Filtro();
    });

  }

  cargarAreasProductivas() {

    var an1: any = this.userService.secciones;
    this.secciones == undefined

    if (an1 != undefined) this.secciones = an1.filter(f => f.activo === true);

    if (this.secciones == undefined) {

      this.userService.getSecciones().subscribe(json => {
        this.userService.secciones = json;
        //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
        var an1: any = this.userService.secciones;
        this.secciones = an1.filter(f => f.activo === true);

        var an: any = this.secciones;
        this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
        an.forEach(row => {
          if (row.activo) this.seccionesSeleccionadas.push(row);
        });

        this.cargarMaquinas();

      });

    } else {

      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

      an.forEach(row => {
        if (row.activo) this.seccionesSeleccionadas.push(row);
      });

      this.cargarMaquinas();

    }

  }

  cargarMaquinas() {

    //MAQUINAS
    var maquinas_model = this.maquinasService.get_maquinas_model();
    if (maquinas_model == false) {
      this.maquinasService.get().subscribe(json => {
        this.maquinasService.set_maquinas_model(json);
        this.maquinas = this.maquinasService.get_maquinas_model();
        this.seccionesCargadas = true;
        if (this.gruposCargados && this.seccionesCargadas) this.filtrarMaquinasPorAreaProductivaYGrupo();
        this.cargar_Filtro();
      })
    } else {
      this.maquinas = maquinas_model;
      this.seccionesCargadas = true;
      if (this.gruposCargados && this.seccionesCargadas) this.filtrarMaquinasPorAreaProductivaYGrupo();
      this.cargar_Filtro();
    }

  }

  filtrarMaquinasPorAreaProductivaYGrupo(primeraCarga = true) {

    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
      this.seccionesSeleccionadas.forEach(seccion => {
        idsSeccionesSelecteds.push(seccion.id);
      });
    } else {
      this.secciones.forEach(seccion => {
        idsSeccionesSelecteds.push(seccion.id);
      });
    }

    //FILTRO POR GRUPOS
    var idsGruposSelecteds: any = [];
    if (this.gruposSeleccionados && this.gruposSeleccionados.length > 0) {
      this.gruposSeleccionados.forEach(grupo => {
        idsGruposSelecteds.push(grupo.id);
      });
    } else {
      this.grupos.forEach(grupo => {
        idsGruposSelecteds.push(grupo.id);
      });
    }

    this.maquinasMostradas = this.maquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) && idsGruposSelecteds.some(r => f.idsGrupos.split(",").map(Number).includes(r))));

    if (primeraCarga) {

      if (this.maquinasMostradas.length > 0) this.selectedMaquinas.push(this.maquinasMostradas[0]);
      else this.selectedMaquinas = [];
      this.identificarMaquinaYCargarDatos();

    }

  }

  identificarMaquinaYCargarDatos(ini: Date = null, fin: Date = null ) {

    // var idsMaquinasSeleccionadas = this.selectedMaquinas.map(a => a.id);
    // var idsMaquinasEnPanel = this.maquinasMostradas.map(a => a.id);

    // //UNION DE LOS IDS SELECCIONADOS Y LOS QUE ESTEN EN EL MENU
    // var idsParaEnsenar = idsMaquinasSeleccionadas.filter(value => idsMaquinasEnPanel.includes(value));

    if (ini == null && fin == null ) {
      var fini = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio);
      var ffin = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin);
    } else {
      var fini = this.myFunctions.dateToYYYYMMDDtHHmmSSz(ini);
      var ffin = this.myFunctions.dateToYYYYMMDDtHHmmSSz(fin);
    }
    
    
    this.menuService.titulo = this.translateService.instant('historicoalarmas').toUpperCase();
    this.historicoAlarmasService.filtrarAlarmas(-1, fini, ffin, "-1").subscribe((result) => {
      this.historicoalarmas = result.data;
      this.dataFiltro = result.data;
      this.original = result.data // para luego filtrar la informacion en el filtro

      // preparar para luego poder filtrar por kendo
      this.historicoalarmas.forEach(element => {
        // preparar las fechas
        element.fechaIniFiltro = this.myFunctions.sqlToJsDateT(element.fechaini);
        element.fechaIniFiltro = this.myFunctions.datetimeToSQL(element.fechaIniFiltro);
        element.fechaFinFiltro = this.myFunctions.sqlToJsDateT(element.fechafin);
        element.fechaFinFiltro = this.myFunctions.datetimeToSQL(element.fechaFinFiltro);
        element.fechaTurnoFiltro = this.myFunctions.sqlToJsDateT(element.fechaTurno);
        element.fechaTurnoFiltro = this.myFunctions.datetimeToSQL(element.fechaTurnoFiltro);
      })
      this.historicoalarmas = filterBy(this.original, this.filtroToKendo());

      var dict: any = {};
      this.imagenes = result.imagenes;
      if (result.imagenes.length > 0) {
        //Tenemos las imagenes, creamos el diccionario
        result.imagenes.forEach(element => {
          dict[element.imagen] = element.imagenBASE64;
        });
      }
      if (this.historicoalarmas != null && this.historicoalarmas.length > 0)
            this.historicoalarmas.forEach(element => {
              //Primero procesamos los operarios
              if(element.operarios!=null){
                var operarios = element.operarios.split(",");
                var operariosAuxi = [];
                var operariosAuxi2 = [];
                operarios.forEach(operario => {
                  var nombre;
                  var apellido;
                  if (operario.split(';').length > 0) {
                    if (operario == this.translateService.instant("desconocido")) {
                      operariosAuxi.push("undefined");
                      operariosAuxi2.push("undefined");
                    } else {
                      nombre = operario.split(';')[0];
                      apellido = operario.split(';')[1];
                      if (apellido != undefined)
                        operariosAuxi2.push(nombre + " " + apellido);
                      else
                        operariosAuxi2.push(nombre);
                      if (nombre != undefined)
                        nombre = nombre.trim().substring(0, 1).toUpperCase();
                      if (apellido != undefined)
                        apellido = apellido.trim().substring(0, 1).toUpperCase();
                      operariosAuxi.push(nombre + apellido);
                    }
                  }
                });
                element.operariosAuxi2 = operariosAuxi2.join(";");
                element.operariosAuxi = operariosAuxi.join(",");
              }        
              //Ahora seguimos con las maquinas
              if(element.maquinas!=null){
                var maquinas = element.maquinas.split(",");
                var maquinasAuxi = [];
                var maquinasAuxi2 = [];
                maquinas.forEach(maquina => {
                  if (maquina == this.translateService.instant("desconocido")) {
                    maquinasAuxi2.push("undefined");
                    maquinasAuxi.push("undefined");
                  } else {
                    var nombre = maquina.trim().substring(0, 1).toUpperCase();
                    var apellido = maquina.trim().substring(1, 2).toUpperCase();
                    maquinasAuxi2.push(maquina);
                    maquinasAuxi.push(nombre + apellido);
                  }
                });
                element.maquinasAuxi2 = maquinasAuxi2.join(";");
                element.maquinasAuxi = maquinasAuxi.join(",");
                //Ahora hay que corregir las imagenes de las maquinas
                var imagenes = element.maquinasIm.split(';and;');
                var auxiImagenes = "";
                imagenes.forEach(imagen => {
                  auxiImagenes += dict[imagen] + ";and;";
                });
                element.maquinasIm = auxiImagenes;
              }
              
              //Por último nos ocupamos de las fechas
              var fechaAuxi = new Date(element.fechaini.replace("T", " "));
              element.fechaIniOrden = this.myFunctions.dateToYYYYMMDDHHmmSS(fechaAuxi) + " " + this.myFunctions.dateWithoutYearShorted(this.myFunctions.sqlToJsDate(element.fechaini.replace('T', ' '))) +
                this.myFunctions.dateToHHMM(this.myFunctions.sqlToJsDate(element.fechaini.replace('T', ' ')));
              fechaAuxi = new Date(element.fechafin.replace("T", " "));
              element.fechaFinOrden = this.myFunctions.dateToYYYYMMDDHHmmSS(fechaAuxi) + " " + this.myFunctions.dateWithoutYearShorted(this.myFunctions.sqlToJsDate(element.fechafin.replace('T', ' '))) +
                this.myFunctions.dateToHHMM(this.myFunctions.sqlToJsDate(element.fechafin.replace('T', ' ')));

              // preparar las fechas
              element.fechaIniFiltro = this.myFunctions.sqlToJsDateT(element.fechaini);
              element.fechaIniFiltro = this.myFunctions.datetimeToSQL(element.fechaIniFiltro);
              element.fechaFinFiltro = this.myFunctions.sqlToJsDateT(element.fechafin);
              element.fechaFinFiltro = this.myFunctions.datetimeToSQL(element.fechaFinFiltro);
              element.fechaTurnoFiltro = this.myFunctions.sqlToJsDateT(element.fechaTurno);
              element.fechaTurnoFiltro = this.myFunctions.datetimeToSQL(element.fechaTurnoFiltro);
            });

      this.loadingPanel = false;
      
    });

  }

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN') &&
    (element.offsetWidth < element.scrollWidth)
    && !element.classList.contains('celda-tooltip-externo') && !element.classList.contains('tooltiptext')) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  desplegarMaquinasClick() {
    this.myFunctions.desplegarMaquinasClick(this);
  }

  public dataStateChange(state: DataStateChangeEvent, grid = null): void {
    this.state = state;
    this.selectAllState = 'unchecked';

    if (grid != null)
      this.grid = grid;

    if (this.loadingPanel) {
      this.cargarInformacionAlarmas();
    }
  }

//FILTRO
annadirGrupoFiltro() {
  this.datosFiltro.group.push(
    {
      logic: { id: 0, nombre: this.translate.instant('y') },
      group: [
        {
          columna: { id: 0, nombre: this.translate.instant('seleccioneCampo'), tipo: '' },
          operator: { id: 0, nombre: '' },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: [],
          tipoNoEditable:false,
          tipoNoVisible: false
        }
      ]
    }
  )
}
annadirLineaFiltro(filtro) {
  // Ahora se selecciona la primera opcion, no hara falta mirar si hay algo seleccionado
  if (this.permitirMultiplesLineasVacias || filtro.group.filter(f => f.columna.id == 0 /*|| f.operator.id == 0*/).length == 0) {
    filtro.group.push({
      columna: { id: 0, nombre: this.translate.instant('seleccioneCampo'), tipo: '' },
      operator: { id: 0, nombre: '' },
      fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
      fechaFin: this.myFunctions.getDateNow(),
      mostrarCalendario: false,
      text: '',
      numberMin: 0,
      numberMax: 0,
      decimalformat: '0.000',
      decimalMin: 0.0,
      decimalMax: 0.0,
      check: false,
      combo: [{ id: 1, nombre: "" }],
      comboSelected: {},
      comboSelecteds: [],
      tipoNoEditable: false,
      tipoNoVisible: false
    })
  }
}
borrarLineaFiltro(row, filtro) {
  if (filtro.group.length > 1) {
    //ELIMINAR LINEA DE FILTRO
    let index = filtro.group.findIndex(d => d === row);
    filtro.group.splice(index, 1);
    this.actualizarVisible = true;
  } else if (filtro.group.length == 1 && ((filtro != this.datosFiltro.group[0] || this.datosFiltro.group.length > 1) || this.permitirFiltroVacio)) {
    //SIEMPRE Y CUANDO NO SEA EL PRIMER GRUPO, SE PUEDE ELIMINAR
    let index = this.datosFiltro.group.findIndex(d => d === filtro);
    this.datosFiltro.group.splice(index, 1);
    this.actualizarVisible = true;
  } else if (filtro.group.length == 1 && (((filtro != this.datosFiltro.group[0] || this.datosFiltro.group.length == 1) || !this.permitirFiltroVacio)) && this.vaciarUltimaLinea) {
    //ELIMINAR LINEA DE FILTRO
    let index = filtro.group.findIndex(d => d === row);
    filtro.group.splice(index, 1);
    // Se borra y se añade una linea nueva vacia
    filtro.group.push({
      columna: { id: 0, nombre: this.translate.instant('seleccioneCampo'), tipo: '' },
      operator: { id: 0, nombre: '' },
      fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
      fechaFin: this.myFunctions.getDateNow(),
      mostrarCalendario: false,
      text: '',
      numberMin: 0,
      numberMax: 0,
      decimalformat: '0.000',
      decimalMin: 0.0,
      decimalMax: 0.0,
      check: false,
      combo: [{ id: 1, nombre: "" }],
      comboSelected: {},
      comboSelecteds: [],
      tipoNoEditable: false,
      tipoNoVisible: false
    })
    this.actualizarVisible = true;
  }
}
filtroTipoChanged(filtro, row, newSelection) {
  row.columna = newSelection;
  if (this.annadirAutomatico)
    this.annadirLineaFiltro(filtro);


  // DATE -
  if (row.columna.tipo == 'date') { row.operator = this.opcionDate[0]; }
  // DATETIME -
  else if (row.columna.tipo == 'dateTime') { row.operator = this.opcionDateTime[0] }
  // COMBO ESTRICTO -
  else if (row.columna.tipo == 'comboEstrincto') { row.operator = this.opcionComboEstricto[0] }
  // COMBO FLEXIBLE -
  else if (row.columna.tipo == 'comboFlexible') { row.operator = this.opcionComboFlexible[0] }
  // CHECK - NO HAY
  // NUMERIC -
  else if (row.columna.tipo == 'numeric') { row.operator = this.opcionNumericDecimal[0] }
  // DECIMAL -
  else if (row.columna.tipo == 'decimal') { row.operator = this.opcionNumericDecimal[0] }
  // STRING -
  else if (row.columna.tipo == 'string') { row.operator = this.opcionString[0] }

  this.preFiltrado(filtro, row);
}
borrarFiltro() {
  this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);
  this.loadingPanel = true;

  this.fechaIniUltimaConsulta = this.fechaInicioFiltro;
  this.fechaFinUltimaConsulta = this.fechaFinFiltro;

  this.identificarMaquinaYCargarDatos(new Date(this.fechaIniUltimaConsulta), new Date(this.fechaFinUltimaConsulta));

  this.status = true;
}

  
cargarConTodasLasRespuestas() {
  // SI ES NECESARIO, SE CARGAN LAS VARIABLES DESDE LA URL.
  //this.cargarFiltroURL();
  this.loadingFiltro = false;
}

// DE NUESTRO FILTRO A KENDO FILTER
filtroToKendo(): CompositeFilterDescriptor {
  var em: CompositeFilterDescriptor = this.filtroToKendo_recursivo(this.datosFiltro);
  return em;
}

// DE NUESTRO FILTRO A SQL FILTER

filtroToKendo_recursivo(jFiltro): CompositeFilterDescriptor {
  // es una linea o es un grupo?
  if (jFiltro.group != undefined) {
    // GRUPO
    //variables para crear la estructura final
    var filtro = [];
    var logica: "or" | "and" = "and";
    if (jFiltro.logic.id == 1)
      logica = "or"
    //por cada grupo
    jFiltro.group.forEach(
      linea => {
        var newRow = this.filtroToKendo_recursivo(linea);
        if (newRow != undefined && newRow != "ERROR" as unknown as CompositeFilterDescriptor)
          filtro.push(newRow);
      });
    if(filtro.length > 0)    
      return { logic: logica, filters: filtro };
  }
  else if (jFiltro.columna != undefined) {
    // LINEA
    //var jRow : CompositeFilterDescriptor;

    var jRow = {} as CompositeFilterDescriptor;

    // DATE -
    if (jFiltro.columna.tipo == 'date') {
      if (jFiltro.operator.dobleValor) {
        var jSubRow1 = {};
        var jSubRow2 = {};
        //jSubRow1["field"] = jFiltro.columna.field;
        //jSubRow2["field"] = jFiltro.columna.field;
        // jSubRow1["field"] = "fechaIniFiltro";
        // jSubRow2["field"] = "fechaFinFiltro";
        jSubRow1["field"] = "fechaTurnoFiltro";
        jSubRow2["field"] = "fechaTurnoFiltro";
        if (jFiltro.operator.id == 7) {
          jRow["logic"] = 'and';
          jSubRow1["operator"] = "gte"
          jSubRow2["operator"] = "lt" // lte
        }
        else if (jFiltro.operator.id == 8) {
          jRow["logic"] = 'or';
          jSubRow1["operator"] = "lt"
          jSubRow2["operator"] = "gt"
        }
        jSubRow1["value"] = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni));
        jSubRow2["value"] = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1)));

        var subFiltro = [];
        subFiltro.push(jSubRow1);
        subFiltro.push(jSubRow2);

        jRow["filters"] = subFiltro;
      }
      else {
        jRow["field"] = jFiltro.columna.field;
        if (jFiltro.operator.id == 1) {
          jRow["operator"] = "lt";
        }
        else if (jFiltro.operator.id == 2) {
          jRow["operator"] = "lte";
        }
        else if (jFiltro.operator.id == 3) {
          jRow["operator"] = "gt";
        }
        else if (jFiltro.operator.id == 4) {
          jRow["operator"] = "gte";
        }
        else if (jFiltro.operator.id == 5) {
          jRow["operator"] = "eq";
        }
        else if (jFiltro.operator.id == 6) {
          jRow["operator"] = "neq";
        }
        jRow["value"] = this.myFunctions.datetimeToDate(this.myFunctions.dateTimeToDate(jFiltro.fechaIni));
      }
    }
    // DATETIME -
    else if (jFiltro.columna.tipo == 'dateTime') {
      if (jFiltro.operator.dobleValor) {
        // no existe este caso por ahora
      }
      else {
        jRow["field"] = jFiltro.columna.field;
        if (jFiltro.operator.id == 1) {
          jRow["operator"] = "lt";
        }
        else if (jFiltro.operator.id == 2) {
          jRow["operator"] = "lte";
        }
        else if (jFiltro.operator.id == 3) {
          jRow["operator"] = "gt";
        }
        else if (jFiltro.operator.id == 4) {
          jRow["operator"] = "gte";
        }
        else if (jFiltro.operator.id == 5) {
          jRow["operator"] = "eq";
        }
        else if (jFiltro.operator.id == 6) {
          jRow["operator"] = "neq";
        }
        jRow["value"] = jFiltro.fechaIni;
      }
    }
    // COMBO ESTRICTO -
    else if (jFiltro.columna.tipo == 'comboEstrincto') {
      if (jFiltro.operator.dobleValor) {
        if (jFiltro.operator.id == 2) {
          jRow["logic"] = 'or';
        }
        else if (jFiltro.operator.id == 4) {
          jRow["logic"] = 'and';
        }
        var subFiltro = [];
        jFiltro.comboSelecteds.forEach(
          seleccionado => {
            var jSubRow1 = {};
            jSubRow1["field"] = jFiltro.columna.field;
            if (jFiltro.operator.id == 2) {
              jSubRow1["operator"] = "eq";
            }
            else if (jFiltro.operator.id == 4) {
              jSubRow1["operator"] = "neq";
            }
            jSubRow1["value"] = seleccionado.id;
            subFiltro.push(jSubRow1);
          });
        jRow["filters"] = subFiltro;
      }
      else {
        jRow["field"] = jFiltro.columna.field;
        if (jFiltro.operator.id == 1) {
          jRow["operator"] = "eq";
        }
        else if (jFiltro.operator.id == 3) {
          jRow["operator"] = "neq";
        }
        if (jFiltro.comboSelected != undefined)
          jRow["value"] = jFiltro.comboSelected.id;
      }
    }
    // COMBO FLEXIBLE -
    else if (jFiltro.columna.tipo == 'comboFlexible' || jFiltro.columna.tipo == 'comboFlexibleOperarioMaquina') {
      if (jFiltro.operator.dobleValor) {
        if (jFiltro.operator.id == 2) {
          jRow["logic"] = 'or';
        }
        else if (jFiltro.operator.id == 4) {
          jRow["logic"] = 'and';
        }
        var subFiltro = [];
        jFiltro.comboSelecteds.forEach(
          seleccionado => {
            var jSubRow1 = {};
            jSubRow1["field"] = jFiltro.columna.field;
            if (jFiltro.operator.id == 2) {
              jSubRow1["operator"] = "eq";
              if (jFiltro.columna.id == 9 || jFiltro.columna.id == 10) jSubRow1["operator"] = "contains"; // Operario / Maquina
            }
            else if (jFiltro.operator.id == 4) {
              jSubRow1["operator"] = "neq";
              if (jFiltro.columna.id == 9 || jFiltro.columna.id == 10) jSubRow1["operator"] = "doesnotcontain"; // Operario / Maquina
            }
            jSubRow1["value"] = seleccionado.id;
            if (jFiltro.columna.id == 9) jSubRow1["value"] = seleccionado.nombre.split(" ").join(";"); // Operario
            if (jFiltro.columna.id == 10) jSubRow1["value"] = seleccionado.nombre; //Maquina
            subFiltro.push(jSubRow1);
          });
        jRow["filters"] = subFiltro;
      }
      else {
        jRow["field"] = jFiltro.columna.field;
        if (jFiltro.operator.id == 1) {
          jRow["operator"] = "eq";
          if (jFiltro.columna.id == 9) jRow["operator"] = "contains"; // Operario
          if (jFiltro.comboSelected != undefined)
            jRow["value"] = jFiltro.comboSelected.id;
          if (jFiltro.columna.id == 9) jRow["value"] = jFiltro.comboSelected.nombreOperario.split(" ").join(";"); // Operario
          if (jFiltro.columna.id == 10) jRow["value"] = jFiltro.comboSelected.nombreMaquina; // Maquina
        }
        else if (jFiltro.operator.id == 3) {
          jRow["operator"] = "neq";
          if (jFiltro.columna.id == 9) jRow["operator"] = "doesnotcontain"; // Operario
          if (jFiltro.comboSelected != undefined)
            jRow["value"] = jFiltro.comboSelected.id;
          if (jFiltro.columna.id == 9) jRow["value"] = jFiltro.comboSelected.nombreOperario.split(" ").join(";"); // Operario
          if (jFiltro.columna.id == 10) jRow["value"] = jFiltro.comboSelected.nombreMaquina; // Maquina
        }
        else if (jFiltro.operator.id == 5) {
          jRow["operator"] = "startswith";
          jRow["value"] = jFiltro.text;
        }
        // else if (jFiltro.operator.id == 6){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
        //   jRow["operator"] = NO "startswith";
        //   jRow["value"] = jFiltro.text; 
        // }
        else if (jFiltro.operator.id == 7) {
          jRow["operator"] = "endswith";
          jRow["value"] = jFiltro.text;
        }
        // else if (jFiltro.operator.id == 8){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
        //   jRow["operator"] = NO "endswith"; 
        //   jRow["value"] = jFiltro.text;
        // }
        else if (jFiltro.operator.id == 9) {
          jRow["operator"] = "contains";
          jRow["value"] = jFiltro.text;
          if (jFiltro.columna.id == 9) jRow["value"] = jFiltro.text.split(" ").join(";"); // Operario
        }
        else if (jFiltro.operator.id == 10) {
          jRow["operator"] = "doesnotcontain";
          jRow["value"] = jFiltro.text;
          if (jFiltro.columna.id == 9) jRow["value"] = jFiltro.text.split(" ").join(";"); // Operario
        }
        else if (jFiltro.operator.id == 11) {
          jRow["operator"] = "eq";
          if (jFiltro.columna.id == 9) jRow["operator"] = "contains"; // Operario
          jRow["value"] = jFiltro.text;
          if (jFiltro.columna.id == 9) jRow["value"] = jFiltro.text.split(" ").join(";"); // Operario
        }
        else if (jFiltro.operator.id == 12) {
          jRow["operator"] = "neq";
          if (jFiltro.columna.id == 9) jRow["operator"] = "doesnotcontain"; // Operario
          jRow["value"] = jFiltro.text;
          if (jFiltro.columna.id == 9) jRow["value"] = jFiltro.text.split(" ").join(";"); // Operario
        }
      }
    }
    // CHECK -
    else if (jFiltro.columna.tipo == 'check') {
      // no es necesaria una opcion
      // if (jFiltro.operator.dobleValor) {
      //   // no existe este caso por ahora
      // }
      // else{
      jRow["field"] = jFiltro.columna.field;
      jRow["operator"] = "eq";
      jRow["value"] = jFiltro.check;
      // }        
    }
    // NUMERIC -
    else if (jFiltro.columna.tipo == 'numeric') {
      if (jFiltro.operator.dobleValor) {
        var jSubRow1 = {};
        var jSubRow2 = {};
        jSubRow1["field"] = jFiltro.columna.field;
        jSubRow2["field"] = jFiltro.columna.field;
        if (jFiltro.operator.id == 7) {
          jRow["logic"] = 'and';
          jSubRow1["operator"] = "gte"
          jSubRow2["operator"] = "lte"
        }
        else if (jFiltro.operator.id == 8) {
          jRow["logic"] = 'or';
          jSubRow1["operator"] = "lt"
          jSubRow2["operator"] = "gt"
        }
        jSubRow1["value"] = jFiltro.numberMin;
        jSubRow2["value"] = jFiltro.numberMax;

        var subFiltro = [];
        subFiltro.push(jSubRow1);
        subFiltro.push(jSubRow2);
        jRow["filters"] = subFiltro;
      }
      else {
        jRow["field"] = jFiltro.columna.field;
        if (jFiltro.operator.id == 1) {
          jRow["operator"] = "lt";
        }
        else if (jFiltro.operator.id == 2) {
          jRow["operator"] = "lte";
        }
        else if (jFiltro.operator.id == 3) {
          jRow["operator"] = "gt";
        }
        else if (jFiltro.operator.id == 4) {
          jRow["operator"] = "gte";
        }
        else if (jFiltro.operator.id == 5) {
          jRow["operator"] = "eq";
        }
        else if (jFiltro.operator.id == 6) {
          jRow["operator"] = "neq";
        }
        jRow["value"] = jFiltro.numberMin;
      }
    }
    // DECIMAL -
    else if (jFiltro.columna.tipo == 'decimal') {
      if (jFiltro.operator.dobleValor) {
        var jSubRow1 = {};
        var jSubRow2 = {};
        jSubRow1["field"] = jFiltro.columna.field;
        jSubRow2["field"] = jFiltro.columna.field;
        if (jFiltro.operator.id == 7) {
          jRow["logic"] = 'and';
          jSubRow1["operator"] = "gte"
          jSubRow2["operator"] = "lte"
        }
        else if (jFiltro.operator.id == 8) {
          jRow["logic"] = 'or';
          jSubRow1["operator"] = "lt"
          jSubRow2["operator"] = "gt"
        }
        jSubRow1["value"] = jFiltro.decimalMin;
        jSubRow2["value"] = jFiltro.decimalMax;

        var subFiltro = [];
        subFiltro.push(jSubRow1);
        subFiltro.push(jSubRow2);
        jRow["filters"] = subFiltro;
      }
      else {
        jRow["field"] = jFiltro.columna.field;
        if (jFiltro.operator.id == 1) {
          jRow["operator"] = "lt";
        }
        else if (jFiltro.operator.id == 2) {
          jRow["operator"] = "lte";
        }
        else if (jFiltro.operator.id == 3) {
          jRow["operator"] = "gt";
        }
        else if (jFiltro.operator.id == 4) {
          jRow["operator"] = "gte";
        }
        else if (jFiltro.operator.id == 5) {
          jRow["operator"] = "eq";
        }
        else if (jFiltro.operator.id == 6) {
          jRow["operator"] = "neq";
        }
        jRow["value"] = jFiltro.decimalMin;
      }
    }
    // STRING -
    else if (jFiltro.columna.tipo == 'string') {
      jRow["field"] = jFiltro.columna.field;
      if (jFiltro.operator.dobleValor) {
        // no existe este caso por ahora
      }
      else {
        if (jFiltro.operator.id == 1) {
          jRow["operator"] = "startswith";
        }
        // else if (jFiltro.operator.id == 2){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
        //   jRow["operator"] = NO "startswith"; 
        // }
        else if (jFiltro.operator.id == 3) {
          jRow["operator"] = "endswith";
        }
        // else if (jFiltro.operator.id == 4){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
        //   jRow["operator"] = NO "endswith"; 
        // }
        else if (jFiltro.operator.id == 5) {
          jRow["operator"] = "contains";
        }
        else if (jFiltro.operator.id == 6) {
          jRow["operator"] = "doesnotcontain";
        }
        else if (jFiltro.operator.id == 7) {
          jRow["operator"] = "eq";
        }
        else if (jFiltro.operator.id == 8) {
          jRow["operator"] = "neq";
        }
      }
      jRow["value"] = jFiltro.text;
    }

    // LINEA
    if (jRow["value"] != undefined && jRow["operator"] != undefined && jRow["field"] != undefined) {
      return jRow;
    }
    // GRUPO
    if (jRow["filters"] != undefined && jRow["logic"] != undefined)
      if (jRow["filters"].length > 0) {
        return jRow;
      }
    // else // filtro sin terminar! no es un filtro a tener en cuenta
    //   return {}
  }
  else {
    //NO EXISTE UNA ESTRUCTURA DEFINIDA PARA ESE FILTRO
    return "ERROR" as unknown as CompositeFilterDescriptor;
  }
}

filtroToSQL(dataSQLfields = []) {
  var em = this.filtroToSQL_recursivo(this.datosFiltro, dataSQLfields);
  if (em.length > 0)
    em = " WHERE " + em;
  return em;
}
filtroToSQL_recursivo(jFiltro, dataSQLfields) {
  // INPUT
  //   ['hb.fechaTurno', 'columnaSQL']
  // es una linea o es un grupo?
  var sqlFilter = "";
  if (jFiltro.group != undefined) {
    var logica: string = " AND ";
    if (jFiltro.logic.id == 1)
      logica = " OR ";
    //por cada grupo
    jFiltro.group.forEach(
      linea => {
        var newRow = this.filtroToSQL_recursivo(linea, dataSQLfields);
        if (newRow != undefined && newRow != "ERROR" && newRow != "")
          if (sqlFilter != "") {
            sqlFilter = sqlFilter + " " + logica + " (" + newRow + ")";
          }
          else {
            sqlFilter = "(" + newRow + ")";
          }
      });
  }
  else if (jFiltro.columna != undefined) {
    if (dataSQLfields.includes(jFiltro.columna.sqlfield) || dataSQLfields.length == 0) {
      // DATE -
      if (jFiltro.columna.tipo == 'date') {
        if (jFiltro.operator.dobleValor) {
          if (jFiltro.operator.id == 7) {
            sqlFilter = jFiltro.columna.sqlfield + " >= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "' AND " + jFiltro.columna.sqlfield + " < '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1))) + "'"
          }
          else if (jFiltro.operator.id == 8) {
            sqlFilter = jFiltro.columna.sqlfield + " < '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "' OR " + jFiltro.columna.sqlfield + " > '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1))) + "'"
          }
          this.fechaInicioFiltro = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni));
          this.fechaFinFiltro = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1)));
        }
        else {
          if (jFiltro.operator.id == 1) {
            sqlFilter = jFiltro.columna.sqlfield + " < '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
          }
          else if (jFiltro.operator.id == 2) {
            sqlFilter = jFiltro.columna.sqlfield + " <= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
          }
          else if (jFiltro.operator.id == 3) {
            sqlFilter = jFiltro.columna.sqlfield + " > '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
          }
          else if (jFiltro.operator.id == 4) {
            sqlFilter = jFiltro.columna.sqlfield + " >= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
          }
          else if (jFiltro.operator.id == 5) {
            sqlFilter = jFiltro.columna.sqlfield + " = '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
          }
          else if (jFiltro.operator.id == 6) {
            sqlFilter = jFiltro.columna.sqlfield + " <> '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
          }
        }
      }
      // DATETIME -
      else if (jFiltro.columna.tipo == 'dateTime') {
        // public opcionDateTime = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false }
        // ];
        if (jFiltro.operator.dobleValor) {
          // no existe este caso por ahora
        }
        else {
          if (jFiltro.operator.id == 1) {
            sqlFilter = jFiltro.columna.sqlfield + " < " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
          }
          else if (jFiltro.operator.id == 2) {
            sqlFilter = jFiltro.columna.sqlfield + " <= " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
          }
          else if (jFiltro.operator.id == 3) {
            sqlFilter = jFiltro.columna.sqlfield + " > " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
          }
          else if (jFiltro.operator.id == 4) {
            sqlFilter = jFiltro.columna.sqlfield + " >= " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
          }
          else if (jFiltro.operator.id == 5) {
            sqlFilter = jFiltro.columna.sqlfield + " = " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
          }
          else if (jFiltro.operator.id == 6) {
            sqlFilter = jFiltro.columna.sqlfield + " <> " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
          }
        }
      }
      // COMBO ESTRICTO -
      else if (jFiltro.columna.tipo == 'comboEstrincto') {
        // public opcionComboEstricto = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: 'Uniselect', dobleValor: false },
        //   { id: 2, nombre: 'Multiselect', dobleValor: true },
        //   { id: 3, nombre: 'No select', dobleValor: false },
        //   { id: 4, nombre: 'No Multiselect', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          var valores = "";
          jFiltro.comboSelecteds.forEach(
            seleccionado => {
              if (valores == "")
                valores = "'" + seleccionado.id + "'";
              else
                valores += ", '" + seleccionado.id + "'";
            });
          if (valores != "") {
            if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + ' IN (' + valores + ")";
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + ' NOT IN (' + valores + ")";
            }
          }
        }
        // else {
        //   // NO EXISTE PORQUE NO TIENE SENTIDO 
        //   // if (jFiltro.operator.id == 1) {
        //   // }
        //   // else if (jFiltro.operator.id == 3) {
        //   // }
        // }
      }
      // COMBO FLEXIBLE -
      else if (jFiltro.columna.tipo == 'comboFlexible') {
        // public opcionComboFlexible = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: 'Uniselect', dobleValor: false },
        //   { id: 2, nombre: 'Multiselect', dobleValor: true },
        //   { id: 3, nombre: 'No select', dobleValor: false },
        //   { id: 4, nombre: 'No Multiselect', dobleValor: true },
        //   { id: 5, nombre: 'Empieza por', dobleValor: false },
        //   { id: 6, nombre: 'No empieza por', dobleValor: false },
        //   { id: 7, nombre: 'Acaba por', dobleValor: false },
        //   { id: 8, nombre: 'No acaba por', dobleValor: false },
        //   { id: 9, nombre: 'Contiene', dobleValor: false },
        //   { id: 10, nombre: 'No contiene', dobleValor: false },
        //   { id: 11, nombre: 'Igual que', dobleValor: false },
        //   { id: 12, nombre: 'Diferente a', dobleValor: false }
        // ];
        if (jFiltro.operator.dobleValor) {
          var valores = "";
          jFiltro.comboSelecteds.forEach(
            seleccionado => {
              if (valores == "")
                valores = "'" + seleccionado.id + "'";
              else
                valores += ", '" + seleccionado.id + "'";
            });
          if (valores != "") {
            if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " IN (" + valores + ")";
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT IN (" + valores + ")";
            }
          }
        }
        else {
          // 1 y 3 NO TIENEN SENTIDO
          // if (jFiltro.operator.id == 1) {
          // }
          // else if (jFiltro.operator.id == 3) {
          // }
          if (jFiltro.operator.id == 5) {
            sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "%')";
          }
          // else if (jFiltro.operator.id == 6){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          // }
          else if (jFiltro.operator.id == 7) {
            sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "')";
          }
          // else if (jFiltro.operator.id == 8){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          // }
          else if (jFiltro.operator.id == 9) {
            sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "%')";
          }
          else if (jFiltro.operator.id == 10) {
            sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('%" + jFiltro.text + "%')";
          }
          else if (jFiltro.operator.id == 11) {
            sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "')";
          }
          else if (jFiltro.operator.id == 12) {
            sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('" + jFiltro.text + "')";
          }
        }
      }
      // CHECK -
      else if (jFiltro.columna.tipo == 'check') {
        // no es necesaria una opcion
        // if (jFiltro.operator.dobleValor) {
        //   // no existe este caso por ahora
        // }
        // else{
        if (jFiltro.check) {
          sqlFilter = jFiltro.columna.sqlfield + " = 'true'";
        }
        else {
          sqlFilter = jFiltro.columna.sqlfield + " = 'false'";
        }
        // }        
      }
      // NUMERIC -
      else if (jFiltro.columna.tipo == 'numeric') {
        // public opcionNumericDecimal = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false },
        //   { id: 7, nombre: 'Entre', dobleValor: true },
        //   { id: 8, nombre: 'No entre', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          if (jFiltro.operator.id == 7) {
            sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.numberMin + "' AND " + jFiltro.columna.sqlfield + " <= '" + jFiltro.numberMax + "'";
          }
          else if (jFiltro.operator.id == 8) {
            sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.numberMin + "' OR '" + jFiltro.columna.sqlfield + " > '" + jFiltro.numberMax + "'";
          }
        }
        else {
          if (jFiltro.operator.id == 1) {
            sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.numberMin + "'";
          }
          else if (jFiltro.operator.id == 2) {
            sqlFilter = jFiltro.columna.sqlfield + " <= '" + jFiltro.numberMin + "'";
          }
          else if (jFiltro.operator.id == 3) {
            sqlFilter = jFiltro.columna.sqlfield + " > '" + jFiltro.numberMin + "'";
          }
          else if (jFiltro.operator.id == 4) {
            sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.numberMin + "'";
          }
          else if (jFiltro.operator.id == 5) {
            sqlFilter = jFiltro.columna.sqlfield + " = '" + jFiltro.numberMin + "'";
          }
          else if (jFiltro.operator.id == 6) {
            sqlFilter = jFiltro.columna.sqlfield + " <> '" + jFiltro.numberMin + "'";
          }
        }
      }
      // DECIMAL -
      else if (jFiltro.columna.tipo == 'decimal') {
        // public opcionNumericDecimal = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false },
        //   { id: 7, nombre: 'Entre', dobleValor: true },
        //   { id: 8, nombre: 'No entre', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          if (jFiltro.operator.id == 7) {
            sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.decimalMin + "' AND " + jFiltro.columna.sqlfield + " <= '" + jFiltro.decimalMax + "'";
          }
          else if (jFiltro.operator.id == 8) {
            sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.decimalMin + "' OR '" + jFiltro.columna.sqlfield + " > '" + jFiltro.decimalMax + "'";
          }
        }
        else {
          if (jFiltro.operator.id == 1) {
            sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.decimalMin + "'";
          }
          else if (jFiltro.operator.id == 2) {
            sqlFilter = jFiltro.columna.sqlfield + " <= '" + jFiltro.decimalMin + "'";
          }
          else if (jFiltro.operator.id == 3) {
            sqlFilter = jFiltro.columna.sqlfield + " > '" + jFiltro.decimalMin + "'";
          }
          else if (jFiltro.operator.id == 4) {
            sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.decimalMin + "'";
          }
          else if (jFiltro.operator.id == 5) {
            sqlFilter = jFiltro.columna.sqlfield + " = '" + jFiltro.decimalMin + "'";
          }
          else if (jFiltro.operator.id == 6) {
            sqlFilter = jFiltro.columna.sqlfield + " <> '" + jFiltro.decimalMin + "'";
          }
        }
      }
      // STRING -
      else if (jFiltro.columna.tipo == 'string') {
        // public opcionString = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: 'Empieza por', dobleValor: false },
        //   { id: 2, nombre: 'No empieza por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
        //   { id: 3, nombre: 'Acaba por', dobleValor: false },
        //   { id: 4, nombre: 'No acaba por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
        //   { id: 5, nombre: 'Contiene', dobleValor: false },
        //   { id: 6, nombre: 'No contiene', dobleValor: false },
        //   { id: 7, nombre: 'Igual que', dobleValor: false },
        //   { id: 8, nombre: 'Diferente a', dobleValor: false }
        // ];
        if (jFiltro.operator.dobleValor) {
          // no existe este caso por ahora
        }
        else {
          if (jFiltro.operator.id == 1) {
            sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "%')";
          }
          // else if (jFiltro.operator.id == 2){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          // }
          else if (jFiltro.operator.id == 3) {
            sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "')";
          }
          // else if (jFiltro.operator.id == 4){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          // }
          else if (jFiltro.operator.id == 5) {
            sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "%')";
          }
          else if (jFiltro.operator.id == 6) {
            sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('%" + jFiltro.text + "%')";
          }
          else if (jFiltro.operator.id == 7) {
            sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "')";
          }
          else if (jFiltro.operator.id == 8) {
            sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('" + jFiltro.text + "')";
          }
        }
      }
    }
  }
  return sqlFilter;
}

// DE NUESTRO FILTRO A CANTIDAD FILTRADOS
filtroToCount(jFiltro = this.datosFiltro) {
  var em = this.filtroToCount_recursivo(jFiltro);
  return em;
}
filtroToCount_recursivo(jFiltro) {
  // es una linea o es un grupo?
  if (jFiltro.group != undefined) {
    // GRUPO
    var count = 0;
    //por cada grupo
    jFiltro.group.forEach(
      linea => {
        var newRow = this.filtroToCount_recursivo(linea);
        if (newRow != undefined && newRow > 0)
          count += newRow;
      });
    return count;
  }
  else if (jFiltro.columna != undefined) {
    // LINEA
    var count = 0;
    // DATE -
    if (jFiltro.columna.tipo == 'date') {
      if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.fechaIni != undefined && jFiltro.fechaFin != undefined) {
        count = 1;
      }
      else {
        if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.fechaIni != undefined) {
          count = 1;
        }
      }
    }
    // DATETIME -
    else if (jFiltro.columna.tipo == 'dateTime') {
      if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.fechaIni != undefined) {
        count = 1;
      }
    }
    // COMBO ESTRICTO -
    else if (jFiltro.columna.tipo == 'comboEstrincto') {
      if ((jFiltro.operator.id == 2 || jFiltro.operator.id == 4) && jFiltro.comboSelecteds.length > 0) {
        count = 1;
      }
      else if ((jFiltro.operator.id == 1 || jFiltro.operator.id == 3) && jFiltro.comboSelected.id > 0) {
        count = 1;
      }
    }
    // COMBO FLEXIBLE -
    else if (jFiltro.columna.tipo == 'comboFlexible') {
      if ((jFiltro.operator.id == 2 || jFiltro.operator.id == 4) && jFiltro.comboSelecteds.length > 0) {
        count = 1;
      }
      else if ((jFiltro.operator.id == 1 || jFiltro.operator.id == 3) && jFiltro.comboSelected.id > 0) {
        count = 1;
      }
      else if ((jFiltro.operator.id >= 5 && jFiltro.operator.id < 13) && jFiltro.text > "") {
        count = 1;
      }
    }
    // CHECK -
    else if (jFiltro.columna.tipo == 'check') {
      count = 1;
    }
    // NUMERIC -
    else if (jFiltro.columna.tipo == 'numeric') {
      if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.numberMin != undefined && jFiltro.numberMax != undefined) {
        count = 1;
      }
      else {
        if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.numberMin == false) {
          count = 1;
        }
      }
    }
    // DECIMAL -
    else if (jFiltro.columna.tipo == 'decimal') {
      if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.decimalMin != undefined && jFiltro.decimalMax != undefined) {
        count = 1;
      }
      else if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.decimalMin == false) {
        count = 1;
      }
    }
    // STRING -
    else if (jFiltro.columna.tipo == 'string' && jFiltro.text != "") {
      count = 1;
    }
    return count;
  }
  else {
    return 0;
  }
}

// FECHA DOBLE
//    Función para agregar los días seleccionados al periodo correspondiente (el periodo seleccionado)
valueClickCalendar(row, month, event) {
  if (event.target.classList.contains("calendarFiltro-calendario-contenido-dia")) { //nos aseguramos de que se está clickando en un día y no en otra parte
    if ((event.target.classList.contains("calendarFiltro-calendarioSeleccionado") && row.fechaIni != undefined && row.fechaFin == undefined)) {//se ha vuelto a seleccionar en el periodo, deseleccionar
      row.fechaFin = this.DaysInMonths[month];
      row.mostrarCalendario = false; //Si ya tenemos las dos fechas, que lo cierre
    } else {
      if (row.fechaIni == undefined && row.fechaFin == undefined) {
        row.fechaIni = this.DaysInMonths[month];
      }
      else if (row.fechaIni != undefined && row.fechaFin == undefined) {
        row.fechaFin = this.DaysInMonths[month];
        if (row.fechaIni > row.fechaFin) { //mirar qué fecha debe ir primero, just in case
          //están al revés, corregirlas
          var aux = new Date(row.fechaIni.getTime());
          row.fechaIni = new Date(row.fechaFin.getTime());
          row.fechaFin = aux;
        }
        row.mostrarCalendario = false; //Si ya tenemos las dos fechas, que lo cierre
        //Y que lo añada com que ya se ha cambiado la fecha, como es obligatoria, nunca saldrá de ese div, solo se vuelve a incluir para destacar el cambio con la animación
        // this.CambioFiltro();
      }
      else {
        //en este caso había dos y se brran para empezar a seleccionar otra vez
        //por tanto, quitamos el tag hasta que se hayan seleccionado las dos
        row.fechaIni = this.DaysInMonths[month];
        row.fechaFin = undefined;
      }
    }
  }
}
//    Función para avanzar o ir atrás en los meses del calendario
cambiarMeses(value) {
  if (value == -1) {
    (document.getElementById("calendario-0").getElementsByTagName("kendo-calendar-header")[0].children[2].children[0] as any).click();
  } else if (value == 1) {
    (document.getElementById("calendario-0").getElementsByTagName("kendo-calendar-header")[0].children[2].children[2] as any).click();
  }
}
//    Función para que el calendario se muestre o no
showCalendar(row) {
  if (!row.mostrarCalendario) {
    row.mostrarCalendario = true;
  }
  else {
    row.mostrarCalendario = false;
    if (row.fechaIni != undefined && row.fechaFin == undefined) {
      row.fechaFin = row.fechaIni;
    }
    else if (row.fechaIni == undefined && row.fechaFin == undefined) {
      row.fechaIni = this.myFunctions.getDateNow();
      row.fechaFin = row.fechaIni;
    }

  }
  // this.mostrarCalendario = true; // activa el click de fondo!
}
//    Función para inicializar los meses del calendario
cargarMeses() {
  this.DaysInMonths = [];
  //Necesitamos inicializar los meses para que se pongan en su día correcto, los pondremos en el día actual y el primer día del mes siguiente
  this.DaysInMonths.push(this.myFunctions.getDateNow());
}
//    Función para pintar del color adecuado el periodo seleccionado
isDateSelected(row, date) {
  if (row.fechaIni == undefined && row.fechaFin == undefined) {
    return false;
  } else if (row.fechaIni != undefined && row.fechaFin == undefined) {
    return date.getFullYear() == row.fechaIni.getFullYear() && date.getMonth() == row.fechaIni.getMonth() && date.getDate() == row.fechaIni.getDate();
  } else if (row.fechaIni != undefined && row.fechaFin != undefined) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate()) >= new Date(row.fechaIni.getFullYear(), row.fechaIni.getMonth(), row.fechaIni.getDate()) &&
      new Date(date.getFullYear(), date.getMonth(), date.getDate()) <= new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth(), row.fechaFin.getDate());
  }
};
//    Botones filtro fechas, (los botones que hay a la derecha del calendario)
ultimas24HButton(row) {
  var today = this.myFunctions.getDateNow();
  row.fechaIni = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
  row.fechaFin = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
  row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
  // this.mostrarCalendario = false; // quita el click de fondo!
}
ultimos7DiasButton(row) {
  row.fechaFin = this.myFunctions.getDateNow();
  row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth(), row.fechaFin.getDate() - 7);
  row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
  // this.mostrarCalendario = false;// quita el click de fondo!
}
ultimos30DiasButton(row) {
  row.fechaFin = this.myFunctions.getDateNow();
  row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth() - 1, row.fechaFin.getDate());
  row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
  // this.mostrarCalendario = false;// quita el click de fondo!
}
ultimos60DiasButton(row) {
  row.fechaFin = this.myFunctions.getDateNow();
  row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth() - 2, row.fechaFin.getDate());
  row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
  // this.mostrarCalendario = false;// quita el click de fondo!
}
ultimos90DiasButton(row) {
  row.fechaFin = this.myFunctions.getDateNow();
  row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth() - 3, row.fechaFin.getDate());
  row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
  // this.mostrarCalendario = false;// quita el click de fondo!
}

// ESTA FUNCION CAMBIA SEGUN EL FILTRO!
cargar_Filtro() {
  // Dentro de esta funcion se meteran todas las cargas de combos, fechas... que necesite el filtro para funcionar.
  this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);

  var r1, r2, r3, r4: boolean = false;
  //FECHAS
  this.cargarMeses();

  //TURNOS
  this.filtro_listaTurnos = [
    { nombreTurno: this.translate.instant("manana"), idTurno: 1 },
    { nombreTurno: this.translate.instant("tarde"), idTurno: 2 },
    { nombreTurno: this.translate.instant("noche"), idTurno: 3 }
  ];

  //OPERARIOS
  this.informeProyectosService.Get_Operarios_simple().pipe(first()).subscribe((data: any) => {
    this.filtro_listaOperarios = data;
    // this.filtro_listaOperarios.forEach(element => {
    //   element.id = element.id.toString();
    // });
    this.filtro_listaOperarios.sort((a, b) => (a.nombreOperario > b.nombreOperario) ? 1 : ((b.nombreOperario > a.nombreOperario) ? -1 : 0));

    r3 = true;
    if (r1 && r2 && r3) this.cargarConTodasLasRespuestas();
  });

  //FLTRO POR SECCIONES
  var idsSeccionesSelecteds: any = [];
  if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
    this.seccionesSeleccionadas.forEach(
      seccion => {
        idsSeccionesSelecteds.push(seccion.id);
      });
  }
  else {
    this.secciones?.forEach(
      seccion => {
        idsSeccionesSelecteds.push(seccion.id);
      });
  }
  //FLTRO POR GRUPOS
  var idsGruposSelecteds: any = [];
  if (this.gruposSeleccionados) {
    this.gruposSeleccionados.forEach(
      grupo => {
        idsGruposSelecteds.push(grupo.id.toString()); // se pasa a string para hacer la comparacion
      });
  }
  //MAQUINAS    
  this.filtro_listaMaquinas = this.maquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) && idsGruposSelecteds.some(r => f.idsGrupos.split(",").map(Number).includes(r))));
  this.filtro_listaMaquinas = this.maquinas.filter(f => {
    var enGrupo = false
    f.idsGrupos.split(',').forEach(
      idGrupo => {
        enGrupo = enGrupo || idsGruposSelecteds.includes(idGrupo);
      });
    return ((idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones?.length == 0) && (enGrupo || idsGruposSelecteds?.length == 0));
  });

  //HERRAMIENTAS
  this.informeProyectosService.Get_Herramientas().pipe(first()).subscribe(
    (data: any) => {
      this.filtro_listaHerramientas = data;
      this.filtro_listaHerramientas.sort((a, b) => (a.nombreHerramienta > b.nombreHerramienta) ? 1 : ((b.nombreHerramienta > a.nombreHerramienta) ? -1 : 0));
      r2 = true;
      if (r1 && r2 && r3) this.cargarConTodasLasRespuestas();
    });

  //SI ocultarParte=1 NO ENSEÑAR EL MULTISELECT DE PARTES
  
  if (!this.user.ocultarParte) this.filtro_ocultarPartes = false;
  if (this.user.ocultarParte) this.filtro_ocultarPartes = true;

  // se quita parte de las opciones seleccionables directamente
  if (this.filtro_ocultarPartes)
    this.columnasFiltro = [
      { id: 2, nombre: this.translate.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },                            
      { id: 3, nombre: this.translate.instant('turno'), field: "tipoTurno", sqlfield: "hb.tipoTurno", tipo: 'comboEstrincto' },                 
      { id: 5, nombre: this.translate.instant('grupoMaquinas'), field: "idMaquinasGrupo", sqlfield: "mgm.idMaquinasGrupo", tipo: 'comboEstrincto' },  
      { id: 6, nombre: this.translate.instant('maquina'), field: "idMaquina", sqlfield: "hb.idMaquina", tipo: 'comboEstrincto' },             
      { id: 7, nombre: this.translate.instant('operario'), field: "idOperario", sqlfield: "hb.idOperario", tipo: 'comboEstrincto' },          
      { id: 8, nombre: this.translate.instant('of'), field: "proyecto", sqlfield: "do.numeroOF", tipo: 'comboFlexible' },                  
      { id: 10, nombre: this.translate.instant('pieza'), field: "pieza", sqlfield: "do.nombrePieza", tipo: 'comboFlexible' },                    
      { id: 13, nombre: this.translate.instant('operacion'), field: "operacion", sqlfield: "do.nombreOperacion", tipo: 'comboFlexible' }, 
      { id: 14, nombre: this.translate.instant('numeroAlarma'), field: "numeroAlarma", sqlfield: "ha.numeroAlarma", tipo: 'comboFlexible' }, 
    ];

  r1 = true;
  if (r1 && r2 && r3) this.cargarConTodasLasRespuestas();
    
}
preFiltrado(filtro, row) {
  // si es un COMBO lo cargamos
  if (row.columna.tipo == "comboEstrincto" || row.columna.tipo == "comboFlexible") {
    // cargamos los datos filtrados que NO sean de esta linea.   
    var dataFiltroLag;
    dataFiltroLag = this.dataFiltro;

    // borrar la seleccion actual
    row.comboSelected = {};
    row.comboSelecteds = [];
    // cargar los combos
    if (row.columna.id == 3) { // turnos
      var lag = [];
      dataFiltroLag.forEach(
        turno => {
          if (!lag.includes(turno.tipoTurno)) {
            lag.push(turno.tipoTurno);
          }
        });
      this.turnosCombo = this.turnosCombo.filter(f=> (lag.includes(f.id)));
      row.combo = this.turnosCombo;
    }
    // else if (row.columna.id == 4) { // seccion
    //   var lag = [];
    //   dataFiltroLag.forEach(
    //     seccion => {
    //       if (!lag.includes(seccion.seccion)) {
    //         lag.push(seccion.seccion);
    //       }
    //     });
    //   this.groupedSeccion.forEach(f => {
    //     f.items.forEach(element => {
    //       var aux = lag.includes(element['nombre']);
    //       f.items.filter(f=> (lag.includes(element['nombre'])))
    //     });
    //   });
    //   row.combo = this.groupedSeccion; // OK
    // }
    else if (row.columna.id == 5) { // grupo de maquinas
      var lag = [];
      dataFiltroLag.forEach(
        grupo => {
          if (!lag.includes(grupo.idMaquinasGrupo)) {
            lag.push(grupo.idMaquinasGrupo);
          }
        });
      this.grupos = this.grupos.filter(f=> (lag.includes(f.id)));
      row.combo = this.grupos.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0)); // OK
    }
    else if (row.columna.id == 6) { // maquinas FILTRO!
      var lag = [];
      dataFiltroLag.forEach(
        maquina => {
          if (!lag.includes(maquina.maquinasAuxi2)) {
            lag.push(maquina.maquinasAuxi2);
          }
        });
      this.filtro_listaMaquinas = this.filtro_listaMaquinas.filter(f=> (lag.includes(f.nombre)));
      row.combo = this.filtro_listaMaquinas.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0)); // OK
    }
    else if (row.columna.id == 7) { // operarios 
      var lag = [];
      dataFiltroLag.forEach(
        operario => {
          if (!lag.includes(operario.idOperario)) {
            lag.push(operario.idOperario);
          }
        });
      this.filtro_listaOperarios = this.filtro_listaOperarios.filter(f=> (lag.includes(f.id)));
      row.combo = this.filtro_listaOperarios.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
    }

    // cargar desde dataFiltroLag = filtrado(this.dataFiltro)
    else if (row.columna.id == 8) { // OF FILTRO!
      var combo = [];
      var lag = [];
      dataFiltroLag.forEach(
        of => {
          if (!lag.includes(of.proyecto)) {
            lag.push(of.proyecto);
            var js = { id: of.proyecto, nombre: of.proyecto };
            combo.push(js);
          }
        });
      row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
    }
    else if (row.columna.id == 10) { // piezas FILTRO!
      var combo = [];
      var lag = [];
      dataFiltroLag.forEach(
        pieza => {
          if (!lag.includes(pieza.pieza)) {
            lag.push(pieza.pieza);
            var js = { id: pieza.pieza, nombre: pieza.pieza };
            combo.push(js);
          }

        });
      row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
    }
    else if (row.columna.id == 13) { // operaciones FILTRO!
      var combo = [];
      var lag = [];
      dataFiltroLag.forEach(
        operacion => {
          if (!lag.includes(operacion.operacion)) {
            lag.push(operacion.operacion);
            var js = { id: operacion.operacion, nombre: operacion.operacion };
            combo.push(js);
          }
        });
      row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
    }
    else if (row.columna.id == 14) { // operaciones FILTRO!
      var combo = [];
      var lag = [];
      dataFiltroLag.forEach(
        numeroalarma => {
          if (!lag.includes(numeroalarma.numeroAlarma)) {
            lag.push(numeroalarma.numeroAlarma);
            var js = { id: numeroalarma.numeroAlarma, nombre: numeroalarma.numeroAlarma };
            combo.push(js);
          }
        });
      row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
    }

  }
}
refiltrarFiltro(filtro) {
  // ESTA FUNCION SE EJECUTA CUANDO SE CAMBIA LA COLUMNA DE TIPO DE FILTRADO!
  // DE:
  //  - AND --> OR
  //  - OR  --> AND
}
onFilter() {
  this.loadingPanel = true;
  var filtroFechas: any = this.filtroToSQL(['hb.fechaTurno']);

  // para que al actualizar la informacion aparezca en el grid aunque se haya hecho scroll
  if (this.grid != undefined) {
    this.grid.scrollTo({ row: 0, column: 0 });
  }
  
  this.selectAllState = 'unchecked';
  if (this.state?.skip == 0 || this.state == undefined) {
    this.cargarInformacionAlarmas();
  }


}
cargarFiltroURL() {
 
}
cargarFiltroURL_postConsulta() {
  // Esta funcion existe para cargar los filtros previos que requieren una consulta a la DB antes de poder ser aplicados
  // cargaremos el filtro en una variable para despues actualizar el filtro    
  var datosFiltro = {
    logic: { id: 1, nombre: this.translate.instant('o') },
    group: [
      {
        logic: { id: 0, nombre: this.translate.instant('y') },
        group: []
      }
    ]
  };
  // FECHAS (INICIO, FIN)
  // /**/this.fini = this.route.snapshot.params['fini'];
  // /**/this.ffin = this.route.snapshot.params['ffin'];
  if (this.route.snapshot.params['fini'] != '0' && this.route.snapshot.params['ffin'] != '0'
    && this.route.snapshot.params['fini'] != undefined && this.route.snapshot.params['ffin'] != undefined) {
    datosFiltro.group[0].group.push(
      {
        columna: { id: 2, nombre: this.translate.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },
        operator: { id: 7, nombre: this.translate.instant('estaEntre'), dobleValor: true },
        fechaIni: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['fini']),
        fechaFin: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['ffin']),
        mostrarCalendario: false,
        text: '',
        numberMin: 0,
        numberMax: 0,
        decimalformat: '0.000',
        decimalMin: 0.0,
        decimalMax: 0.0,
        check: false,
        combo: [{ id: 1, nombre: "" }],
        comboSelected: {},
        comboSelecteds: []
      }
    );
  }
  // OF
  var rowOFs = this.dataFiltro.filter(x => x.idOf == this.idof_prefiltro);
  if (rowOFs.length > 0) {
    datosFiltro.group[0].group.push(
      {
        columna: { id: 8, nombre: this.translate.instant('of'), field: "nombreOf", sqlfield: "po.numeroOF", tipo: 'comboFlexible' },
        operator: { id: 2, nombre: this.translate.instant('es'), dobleValor: true },
        fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
        fechaFin: this.myFunctions.getDateNow(),
        mostrarCalendario: false,
        text: '',
        numberMin: 0,
        numberMax: 0,
        decimalformat: '0.000',
        decimalMin: 0.0,
        decimalMax: 0.0,
        check: false,
        combo: [{ id: 1, nombre: "" }],
        comboSelected: {},
        comboSelecteds: []
      }
    );
    // Se carga el combo de clientes (esto limpia la seleccion)
    this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
    // Se selecciona el que queremos
    var of = { id: rowOFs[0].nombreOf, nombre: rowOFs[0].nombreOf }
    datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [of];
  }
  this.idof_prefiltro = 0;
  // PIEZA
  var rowPiezas = this.dataFiltro.filter(x => x.idPieza == this.idpieza_prefiltro);
  if (rowPiezas.length > 0) {
    datosFiltro.group[0].group.push(
      {
        columna: { id: 10, nombre: this.translate.instant('pieza'), field: "nombrePieza", sqlfield: "po.nombrePieza", tipo: 'comboFlexible' },
        operator: { id: 2, nombre: this.translate.instant('es'), dobleValor: true },
        fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
        fechaFin: this.myFunctions.getDateNow(),
        mostrarCalendario: false,
        text: '',
        numberMin: 0,
        numberMax: 0,
        decimalformat: '0.000',
        decimalMin: 0.0,
        decimalMax: 0.0,
        check: false,
        combo: [{ id: 1, nombre: "" }],
        comboSelected: {},
        comboSelecteds: []
      }
    );
    // Se carga el combo de clientes (esto limpia la seleccion)
    this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
    // Se selecciona el que queremos
    var pieza = { id: rowPiezas[0].nombrePieza, nombre: rowPiezas[0].nombrePieza }
    datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [pieza];
  }
  this.idpieza_prefiltro = 0;
  // N SERIE
  if (this.nSerie_prefiltro != "") {
    datosFiltro.group[0].group.push(
      {
        columna: { id: 11, nombre: this.translate.instant('nserie'), field: "nSerie", sqlfield: "ho.nSerie", tipo: 'string' },
        operator: { id: 7, nombre: this.translate.instant('igualQue'), dobleValor: false },
        fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
        fechaFin: this.myFunctions.getDateNow(),
        mostrarCalendario: false,
        text: this.nSerie_prefiltro,
        numberMin: 0,
        numberMax: 0,
        decimalformat: '0.000',
        decimalMin: 0.0,
        decimalMax: 0.0,
        check: false,
        combo: [{ id: 1, nombre: "" }],
        comboSelected: {},
        comboSelecteds: []
      }
    );
  }
  this.nSerie_prefiltro = "";
  // OPERACION
  var rowOperaciones = this.dataFiltro.filter(x => x.idOperacion == this.idOperacion_prefiltro);
  if (rowOperaciones.length > 0) {
    datosFiltro.group[0].group.push(
      {
        columna: { id: 13, nombre: this.translate.instant('operacion'), field: "nombreOperacion", sqlfield: "po.nombreOperacion", tipo: 'comboFlexible' },
        operator: { id: 2, nombre: this.translate.instant('es'), dobleValor: true },
        fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
        fechaFin: this.myFunctions.getDateNow(),
        mostrarCalendario: false,
        text: '',
        numberMin: 0,
        numberMax: 0,
        decimalformat: '0.000',
        decimalMin: 0.0,
        decimalMax: 0.0,
        check: false,
        combo: [{ id: 1, nombre: "" }],
        comboSelected: {},
        comboSelecteds: []
      }
    );
    // Se carga el combo de clientes (esto limpia la seleccion)
    this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
    // Se selecciona el que queremos
    var operacion = { id: rowOperaciones[0].nombreOperacion, nombre: rowOperaciones[0].nombreOperacion }
    datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [operacion];
  }
  this.idOperacion_prefiltro = 0;
  // Si hay filtrado
  if (datosFiltro.group[0].group.length > 0) {
    // Annadimos la ultima linea al filtro
    datosFiltro.group[0].group.push(
      {
        columna: { id: 0, nombre: this.translate.instant('seleccioneCampo'), tipo: '' },
        operator: { id: 0, nombre: '' },
        fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
        fechaFin: this.myFunctions.getDateNow(),
        mostrarCalendario: false,
        text: '',
        numberMin: 0,
        numberMax: 0,
        decimalformat: '0.000',
        decimalMin: 0.0,
        decimalMax: 0.0,
        check: false,
        combo: [{ id: 1, nombre: "" }],
        comboSelected: {},
        comboSelecteds: [],
        tipoNoEditable: false,
        tipoNoVisible: false
      }
    );
    // Acutalizamos el filtro
    this.datosFiltro = datosFiltro;
    // Autofiltramos el informe
    this.onFilter();
  }
}
//Para poner la fecha disabled en el dropdown
public itemDisabled(itemArgs: { dataItem: string; index: number }) {
  return itemArgs.index === 0; // disable the 3rd item
}
// END FILTRO

  // Esta funcion se utiliza para cargar la informacion de las alarmas despues de filtrar
  cargarInformacionAlarmas() {
    if (this.fechaInicioFiltro < this.fechaIniUltimaConsulta || this.fechaFinFiltro > this.fechaFinUltimaConsulta) { // hay que hacer la consulta    
      this.fechaIniUltimaConsulta = this.fechaInicioFiltro;
      this.fechaFinUltimaConsulta = this.fechaFinFiltro;

      this.identificarMaquinaYCargarDatos(new Date(this.fechaIniUltimaConsulta), new Date(this.fechaFinUltimaConsulta));

      this.status = true;

    } else { // no es necesario hacer la consulta
      
      this.historicoalarmas = filterBy(this.original, this.filtroToKendo());

      if (this.historicoalarmas != null && this.historicoalarmas.length > 0)
            this.historicoalarmas.forEach(element => {

              // preparar las fechas
              element.fechaIniFiltro = this.myFunctions.sqlToJsDateT(element.fechaini);
              element.fechaIniFiltro = this.myFunctions.datetimeToSQL(element.fechaIniFiltro);
              element.fechaFinFiltro = this.myFunctions.sqlToJsDateT(element.fechafin);
              element.fechaFinFiltro = this.myFunctions.datetimeToSQL(element.fechaFinFiltro);
              element.fechaTurnoFiltro = this.myFunctions.sqlToJsDateT(element.fechaTurno);
              element.fechaTurnoFiltro = this.myFunctions.datetimeToSQL(element.fechaTurnoFiltro);
            });

      this.status = true;
      this.actualizarVisible = false;
      this.loadingPanel = false;
    }
  }
}
