import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuService, UsuariosService, MaquinasService, ComboService } from '@app/_services';
import { Combo } from '@app/_models';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { FileRestrictions, FileInfo, SelectEvent, RemoveEvent } from '@progress/kendo-angular-upload';

@Component({
  selector: 'app-maquina-detalle-manual-carpeta',
  templateUrl: './maquinaDetalleManualCarpeta.component.html'
})

export class MaquinaDetalleManualCarpetaComponent {
  //#region "CARGA"
  public id: number = -1;
  public idMaquina: number = -1;
  public tipoMaquina: any;
  
  public tipos: Combo[];
  public tipos_value: Combo;
  public idTipo: number;

  public carpetas: Combo[];
  public carpetas_value: Combo;
  public idCarpeta: number;

  public isLink: boolean = false;
  public uploadSaveUrl;
  public uploadRemoveUrl;
  public fichero: String = "";
  public restrictions: FileRestrictions = {
    allowedExtensions: ['.pdf'],
    maxFileSize: 20048576
  };

  public closeResult = '';
  public form: FormGroup;
  public loading: boolean = false;
  public submitted: boolean = false;
  public isAddMode: boolean;
  public user = this.userService.userValue;
  public alertService: any;

  public myFiles: FileInfo[];

  public errorFichero = false;
  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private menuService: MenuService,
    private userService: UsuariosService,
    private maquinasService: MaquinasService,
    private comboService: ComboService) {

    this.menuService.titulo = this.translateService.instant('manual').toUpperCase();
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.params['id'];
    this.idMaquina = this.activatedRoute.snapshot.params['idMaquina'];

    this.isAddMode = !this.id;

    this.maquinasService.GetTipoMaquinaById(this.idMaquina).pipe().subscribe((result) => {
      this.tipoMaquina = result[0].tipo_maquina;
    });


    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      id: [undefined,],
      nombre: ['',]
    });

    if (this.id > 0) {
      this.maquinasService.Get_ManualCarpeta(this.id).pipe().subscribe((result) => {
          this.form = this.formBuilder.group({
          idDb: this.user.idDb,
          nombre: [result.data[0].nombre,],
        })

      });
    }
  }
  //#endregion

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    if (this.isAddMode) {
      this.insert();
    } else {
      this.update();
    }
  }

  private insert() {
    this.maquinasService.Insert_ManualCarpeta(this.form.value).subscribe((result) => {
      if (result.error == false) {
        this.atras();
      }
    });
  }

  private update() {
    this.form.value.id = this.id;
    this.maquinasService.Update_ManualCarpeta(this.form.value, this.id, this.idMaquina).subscribe((result) => {
      if (result.error == false) {
        this.atras();
      }
    });
  }

  public atras() {
    this.maquinasService.SaberSiEsMaquinaOInstalacion(this.idMaquina.toString()).subscribe((result) => {
      var data: any = result.data;
      if (this.idMaquina > 0) {
        if (data[0].esMaquina == 1) {
          if (this.tipoMaquina == -1) this.router.navigate(['maquinas/editar/', this.idMaquina]); //NO TIENE TIPO DE MAQUINA, IR A MECANIZADO POR DEFECTO
          if (this.tipoMaquina == 1) this.router.navigate(['maquinas/editar/', this.idMaquina]); //MECANIZADO
          if (this.tipoMaquina == 2) this.router.navigate(['extrusoras/editar/', this.idMaquina]); //EXTRUSORA
          if (this.tipoMaquina == 3) this.router.navigate(['impresorasPlastico/editar/', this.idMaquina]); //IMPRESORA PLASTICO
          if (this.tipoMaquina == 4) this.router.navigate(['inyectoras/editar/', this.idMaquina]); //INYECTORAS
          if (this.tipoMaquina == 5) this.router.navigate(['hornos/editar/', this.idMaquina]); //HORNOS
          if (this.tipoMaquina == 9) this.router.navigate(['aditivo/editar/', this.idMaquina]); //ADITIVO
        } else if (data[0].esInstalacion == 1) {
          this.router.navigate(['instalaciones/editar/' + this.idMaquina]); //INSTALACION
        }
      }
    });
  }
  errorEventHandler(e: ErrorEvent) {
    this.alertService.error(this.translateService.instant('error'));
  }


  //#region COMBO
  public tipos_selectionChange(value: any): void {
    this.idTipo = value.id
  }
  //#endregion

    //#region COMBO
    public carpetas_selectionChange(value: any): void {
      this.idCarpeta = value.id
    }
    //#endregion

  //#Kregion CHECKBOX
  public onValueChange(value: any): void {
    this.isLink = value;
  }
  //#endregion

  //AZABAL: PDF NUEVO
  archivoSeleccionado(e: SelectEvent) {
    var th = this;
    this.form.controls['nombreArchivo'].setValue(e.files[0].name);

    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.form.value.archivo !== "" && th.form.value.archivo !== null) archivoBase64 = await th.toBase64(th.form.value.archivo[0]);
      else archivoBase64 = "";
      th.form.controls['archivoBase64'].setValue(archivoBase64);
    }, 500);

  }

  archivoEliminado(e: RemoveEvent) {
    this.form.controls['nombreArchivo'].setValue("");
    this.form.controls['archivoBase64'].setValue("");
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

}
