import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
  translateAggregateResults,
  DataResult,
  DataSourceRequestState
} from '@progress/kendo-data-query';

import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';

import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { query } from '@angular/animations';

import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/bom`;
@Injectable()
export class BomService extends BehaviorSubject<any> {
  private BASE_URL: string = '/api/acciones';
  public loading = false;

  constructor(private http: HttpClient) {
    super(null);
  }


  importar(data) {


    
    return this.http.post<any>(baseUrl + "/importar", data, { withCredentials: true });
  }


}
