import { Component, ViewChild } from '@angular/core';
import { UsuariosService, MenuService, InformeProyectosService,MaquinasService, HistoricoOperacionesService, InformePersonalizadoService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { Router } from "@angular/router";
import { MyFunctions } from '@app/_helpers';
import { ActivatedRoute } from '@angular/router';
import { GroupResult, filterBy, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { first } from 'rxjs/operators';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { CellClickEvent } from '@progress/kendo-angular-grid';
import * as c3 from 'c3';
import * as d3 from 'd3';

interface ItemTurno {
    nombre: string,
    id: number
  }

@Component({
    selector: 'app-informe-proyectos_v2',
    templateUrl: './informeProyectos_v2.component.html'
  })

  export class InformeProyectosComponent_v2 {

    user = this.userService.userValue;

    public loading: boolean = false;
    public loadingDatos: boolean = false;
    public tieneDatos: boolean = true;

    //PARA AÑADIR PAGGING AL GRID
    public skip = 0;

    public data: any = [];
    public original;

    public dataGrid = [];
    public datosGridOF: any;

    public graficoDonutRendimiento: any;
    public graficoRendimientoCalidad: any;
    public graficoDonutCalidad: any;
    public graficoClientes: any;

    // informacion del oee
    public tEjecucionOEE = 0;
    public tParadaDisponibilidad = 0;
    public tMantenimientoDisponibilidad = 0;
    public tAlarmaDisponibilidad = 0;
    public tApagadaDisponibilidad = 0;
    public tParadaRendimiento = 0;        
    public tMantenimientoRendimiento = 0;
    public tAlarmaRendimiento = 0;
    public tApagadaRendimiento = 0;
    public tParadaCalidad = 0;
    public tMantenimientoCalidad = 0;
    public tAlarmaCalidad = 0;
    public tApagadaCalidad = 0;
    public tNegativoCalidad = 0;   
    public tEstimado = 0;

    public fechaIni;
    public fechaFin;
    // Fechas para controlar el rango de si hacer o no consulta nueva
    public fechaIniUltimaConsulta: any;
    public fechaFinUltimaConsulta: any;

    // TRADUCCIONES GRID INFO
    public infoTTotalGridHeader = this.translateService.instant('infoTTotalGridHeader');
    public infoTRealGridHeader = this.translateService.instant('infoTRealGridHeader');

    //#region FILTRO
    private dataFiltro: any;

    public turnos_placeHolder: string = "";
    public turnosCombo: Array<ItemTurno> = [];

    public agrupado: number = 0;

    //PANEL MAQUINAS
    private selectedMaquina: any;
    public maquinas: any = [];
    public instalaciones: any = [];
    public maquinasMostradas: any = [];

    //AREA PRODUCTIVA / SECCION
    public secciones: any;
    public groupedSeccion: GroupResult[];
    public seccionesSeleccionadas: any[] = [];

    //GRUPOS DE MAQUINAS
    public grupos: any;
    public gruposSeleccionados: any;

    maquinasDesplegadas = false;
    maquinasDesplegadasCount = 0; //para que solo se realice una vez

    public fechaMin: Date;
    public fechaMax: Date;

    public fini: any;
    public ffin: any;
    public idcliente: any;
    public idpieza: any;
    public idof: any;
    public nSerie: any;
    public idOperacion: any;
    public fechaInicioFiltro: any;
    public fechaFinFiltro: any;
  

    status: boolean = true;
    // NO SE PUEDEN CAMBIAR LOS PROXIMOS IDs START
    //  en las funciones de nuestro a kendo se usan estos IDs, tanto los de AND/OR como los de las opciones de cada tipo de dato.
    public andOr = [
    { id: 0, nombre: this.translateService.instant('y') },
    { id: 1, nombre: this.translateService.instant('o') }
    ];
    public opcionDate = [
    { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
    { id: 8, nombre: this.translateService.instant('noEstaEntre'), dobleValor: true }
    ];
    public opcionDateTime = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translateService.instant('menorQue'), dobleValor: false },
    { id: 2, nombre: this.translateService.instant('menorOigual'), dobleValor: false },
    { id: 3, nombre: this.translateService.instant('mayorQue'), dobleValor: false },
    { id: 4, nombre: this.translateService.instant('mayorOigual'), dobleValor: false },
    { id: 5, nombre: this.translateService.instant('es'), dobleValor: false },
    { id: 6, nombre: this.translateService.instant('noEs'), dobleValor: false }
    ];
    public opcionNumericDecimal = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translateService.instant('menorQue'), dobleValor: false },
    { id: 2, nombre: this.translateService.instant('menorOigual'), dobleValor: false },
    { id: 3, nombre: this.translateService.instant('mayorQue'), dobleValor: false },
    { id: 4, nombre: this.translateService.instant('mayorOigual'), dobleValor: false },
    { id: 5, nombre: this.translateService.instant('es'), dobleValor: false },
    { id: 6, nombre: this.translateService.instant('noEs'), dobleValor: false },
    { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
    { id: 8, nombre: this.translateService.instant('noEstaEntre'), dobleValor: true }
    ];
    public opcionComboEstricto = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Uniselect', dobleValor: false},
    { id: 2, nombre: this.translateService.instant('es'), dobleValor: true }, // solo hase van a mostrar combos multiseleccionables IN / OUT
    // { id: 3, nombre: 'No select', dobleValor: false},
    { id: 4, nombre: this.translateService.instant('noEs'), dobleValor: true }// solo hase van a mostrar combos multiseleccionables IN / OUT
    ];
    public opcionComboFlexible = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Uniselect', dobleValor: false},
    { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
    // { id: 3, nombre: 'No select', dobleValor: false},
    { id: 4, nombre: this.translateService.instant('noEs'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
    { id: 5, nombre: this.translateService.instant('empiezaPor'), dobleValor: false },
    // { id: 6, nombre: 'No empieza por', dobleValor: false},
    { id: 7, nombre: this.translateService.instant('acabaPor'), dobleValor: false },
    // { id: 8, nombre: 'No acaba por', dobleValor: false},
    { id: 9, nombre: this.translateService.instant('contiene'), dobleValor: false },
    { id: 10, nombre: this.translateService.instant('noContiene'), dobleValor: false }//,
    // { id: 11, nombre: 'Igual que', dobleValor: false},
    // { id: 12, nombre: 'Diferente a', dobleValor: false}
    ];
    public opcionString = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translateService.instant('empiezaPor'), dobleValor: false },
    // { id: 2, nombre: 'No empieza por', dobleValor: false},
    { id: 3, nombre: this.translateService.instant('acabaPor'), dobleValor: false },
    // { id: 4, nombre: 'No acaba por', dobleValor: false},
    { id: 5, nombre: this.translateService.instant('contiene'), dobleValor: false },
    { id: 6, nombre: this.translateService.instant('noContiene'), dobleValor: false },
    { id: 7, nombre: this.translateService.instant('igualQue'), dobleValor: false },
    { id: 8, nombre: this.translateService.instant('diferenteA'), dobleValor: false }
    ];
    // NO SE PUEDEN CAMBIAR LOS PROXIMOS IDs END
    //FECHA DOBLE
    public DaysInMonths;

    // VARIABLES input del filtro
    public permitirFiltroVacio = false; // permite vaciar el filtro por completo
    public vaciarUltimaLinea = true; // cuando no se deja vaciar por completo un filtro, este resetea la ultima linea al intentar eliminarla
    public permitirMultiplesLineasVacias = false; // perminte añadir mas lineas que las que se han rellenado
    public annadirAutomatico = true; // añade lineas automaticamente si se selecciona la columna o la operacion Esta opcion contradice la de perminir multiples lineas vacias
    public dobleClickMismaFecha = false; // permite seleccionar la misma fecha en inicio y fin en el control doble de fechas
    // DATOS INICIALES DEL FILTRO
    // DATOS INICIALES DEL FILTRO
    public filtroPorDefecto = {
    logic: { id: 1, nombre: this.translateService.instant('o') },
    group: [
        {
        logic: { id: 0, nombre: this.translateService.instant('y') },
        group: [
            {
            columna: { id: 1, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },
            operator: { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            tipoNoEditable: true,
            tipoNoVisible: true
            },
            {
            columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            tipoNoEditable: false,
            tipoNoVisible: false
            }
        ]
        }
    ]
    }; 
    public datosFiltro = {
    logic: { id: 1, nombre: this.translateService.instant('o') },
    group: []
    };  
    // ESTOS SON LOS TIPOS QUE SE ACEPTAN EN EL FILTRO: date, dateTime, comboEstrincto, comboFlexible, check, numeric, decimal, string
    public columnasFiltro = [
    { id: 2, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },                            
    { id: 8, nombre: this.translateService.instant('of'), field: "nombreOf", sqlfield: "ofo.numeroOF", tipo: 'comboFlexible' },                    // 
    { id: 9, nombre: this.translateService.instant('cliente'), field: "nombreCliente", sqlfield: "ofo.nombreCliente", tipo: 'comboFlexible' },     //
    { id: 10, nombre: this.translateService.instant('pieza'), field: "nombrePieza", sqlfield: "ofo.nombrePieza", tipo: 'comboFlexible' },          // 
    { id: 14, nombre: this.translateService.instant('terminado'), field: "terminado", sqlfield: "ofo.piezaTerminada", tipo: 'check' },          // ofo.operacionTerminada  
    { id: 15, nombre: this.translateService.instant('refpieza'), field: "refPieza", sqlfield: "ofo.refPieza", tipo: 'comboFlexible' },
    { id: 16, nombre: this.translateService.instant('planopieza'), field: "planoPieza", sqlfield: "ofo.planoPieza", tipo: 'comboFlexible' },
    { id: 17, nombre: this.translateService.instant('tipopieza'), field: "tipoPieza", sqlfield: "ofo.tipoPieza", tipo: 'comboFlexible' }
    ];

    // Combo cargable del filtro
    public filtro_listaMaquinas: any;
    public filtro_listaOperarios: any;
    public filtro_listaTurnos: any;
    public filtro_listaHerramientas: any;
    public filtro_ocultarPartes: boolean;
    // variables para cuando la precarga requiere de alguna consulta
    public idpieza_prefiltro: number = 0;
    public idof_prefiltro: number = 0;
    public nSerie_prefiltro: string = "";
    public idOperacion_prefiltro: number = 0;
    // LOADING filtro
    public loadingFiltro: boolean = false;
    // public mostrarCalendario: boolean = false;
    public actualizarVisible: boolean = false;
    //#endregion

    @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

    constructor(private informeProyectosService: InformeProyectosService,
        private userService: UsuariosService,
        public router: Router,
        private menuService: MenuService,
        private translateService: TranslateService,
        private myFunctions: MyFunctions,
        private route: ActivatedRoute,
        private InformePersonalizadoService: InformePersonalizadoService,
        private maquinasService: MaquinasService,
        private historicoOperacionesService: HistoricoOperacionesService  ) { 

            this.menuService.titulo = this.translateService.instant('informeProyectos').toUpperCase();
            this.userService.user.subscribe(x => this.user = x);
            // this.cargarMaquinas();
            // this.cargarGrupos();
            // this.cargarAreasProductivas();
            // this.cargarTurnosCombo();
            this.cargar_Filtro();
    }

    ngOnInit() { 
        // get information
        var fechaInicio = this.myFunctions.dateToYYYY_MM_DD_guion(this.fechaIni) + "T00:00:00Z";
        var fechaFin = this.myFunctions.dateToYYYY_MM_DD_guion(this.fechaFin) + "T00:00:00Z";

        // actualizar la fecha de ultima consulta
        var regex = /01:00:00/i;
        this.fechaIniUltimaConsulta = this.myFunctions.datetimeToSQL(new Date (fechaInicio));
        this.fechaIniUltimaConsulta = this.fechaIniUltimaConsulta.replace(regex, '00:00:00');
        this.fechaFinUltimaConsulta = this.myFunctions.datetimeToSQL(new Date (fechaFin));
        this.fechaFinUltimaConsulta = this.fechaFinUltimaConsulta.replace(regex, '00:00:00');

        this.loading = true;
    
        this.InformePersonalizadoService.getAll(fechaInicio, fechaFin).subscribe(
            json => {
              this.data = this.juntarTablas(json);
              this.data = this.data.filter(f => f.operacion == 1);
      
              if (this.data != null) { // hau da datu basean errorerik baldin badago panelan loading-a ez agertzeko gehiago
                this.data.forEach(element => {
                  element['oculto'] = 0;
      
                  // preparar las fechas
                  element.fechaIniFiltro = this.myFunctions.sqlToJsDateT(element.fechaini);
                  element.fechaIniFiltro = this.myFunctions.datetimeToSQL(element.fechaIniFiltro);
                  element.fechaFinFiltro = this.myFunctions.sqlToJsDateT(element.fechafin);
                  element.fechaFinFiltro = this.myFunctions.datetimeToSQL(element.fechaFinFiltro);
                  element.fechaTurnoFiltro = this.myFunctions.sqlToJsDateT(element.fechaTurno);
                  element.fechaTurnoFiltro = this.myFunctions.datetimeToSQL(element.fechaTurnoFiltro);
                });
                this.original = this.myFunctions.copy(this.data);
              } else this.data = [];
      
              this.loading = false;
      
              // ACTUALIZAR INFORMACION PARA COMBOS DE FILTRO
              this.dataFiltro = this.data;

              this.data = filterBy(this.data, this.filtroToKendo());
      
              this.cargarDatos();
            });
    }

    juntarTablas(json) {
        // table => consulta principal
        var base = json.table;
        // table1 => alarmas
        var infAlarmas = json.table1;
        // table2 => perdidas
        var infPerdidas = json.table2;
        // table3 => mantenimientos
        var infMantenimientos = json.table3;
        // table4 => maquinas
        var infMaquinas = json.table4;
        // table5 => operaciones
        var infOperaciones = json.table5;
        // table6 => ofs
        var infOfs = json.table6;
        // table7 => tiempos, lote...
        var infTiempos = json.table7;
        // table8 => subestados
        var infSubestados = json.table8;
        // table9 && table10 => configuracion
        var infConfiguracion = json.table9;
        var infConfiguracion2 = json.table10;
        // tablas juntadas
        var result = [];
    
        base.forEach(element => {
          var alarma = infAlarmas.filter(f => f.idHistorico_Procesos == element.idHistoricoProcesos)[0];
          var perdidas = infPerdidas.filter(f => f.idPerdidaHistoricoBase == element.idPerdidaHistoricoBase)[0];
          var mant = infMantenimientos.filter(f => f.idMaquinaMantenimiento == element.idMaquinaMantenimiento)[0];
          var maq = infMaquinas.filter(f => f.idmaquina == element.idmaquina)[0];
          var op = infOperaciones.filter(f => f.idOperacion == element.idOperacion)[0];
          var tiempos = infTiempos.filter(f => f.idHistoricoOperaciones == element.idHistoricoOperaciones)[0];
          var subestados = infSubestados.filter(f => f.idProcesos_Tipo_subEstados == element.idProcesos_Tipo_subEstados)[0];
    
          if (op == undefined)
            op = {nombreOperacion: '', tiempoEstimadoEjecucion: -1, tiempoEstimadoPreparacion: 0, tiempoPredictivoEjecucion: 0, tiempoPredictivoPreparacion: 0, operacionTerminada: -1, idRuta: -1}
          
          var ofs = infOfs.filter(f => f.idRuta == op.idRuta)[0];
    
          if (alarma == undefined) 
            alarma = {alarma: '', gravedadAlarma: -1};
          if (perdidas == undefined)
            perdidas = {idPerdida: -1, perdida: '', idSubPerdida: -1, subPerdida: '', idGrupoPerdida: -1, grupoPerdida: ''};
          if (mant == undefined)
            mant = {maquinaMantenimiento: '', idmaquinaMantenimiento: -1}
          if (ofs == undefined)
            ofs = {idOF: -1, numeroOF: '', idCliente: -1, cliente: '', nombreSubCliente: '', referencia: ''
                    , idPieza: -1, nombrePieza: '', referenciaPieza: '', numeroPlano: '', idParte: -1, nombreParte: ''
                    , referenciaParte: '', idRuta: -1, nombreRuta: '', usaLotes: 0, cantidad: 0
                    , piezaTerminada: true}
          if (tiempos == undefined)
            tiempos = {lote: -1, colada: -1, nSerie: -1, idEstado: -1, tiempoTotalRendimientoOperacion: -1, 
                      tiempoTotalRendimientoPreparacion: 0, tiempoTotalDesvioOperacion: 0, tiempoTotalDesvioPreparacion: 0}
          if (subestados == undefined)
            subestados = {nombreSubestado: '', colorSubestado: 'rgba(85, 222, 165, 1)'};
    
          var lag = Object.assign({}, element, alarma);
          lag = Object.assign({}, lag, perdidas);
          lag = Object.assign({}, lag, mant);
          lag = Object.assign({}, lag, maq);
          lag = Object.assign({}, lag, op);
          lag = Object.assign({}, lag, ofs);
          lag = Object.assign({}, lag, tiempos);
          lag = Object.assign({}, lag, subestados);
          lag = Object.assign({}, lag, infConfiguracion[0]);
          lag = Object.assign({}, lag, infConfiguracion2[0]);
    
          // calcular el tNegativoCalidad
          if ((lag["idProcesos_Tipo"] == 3 && lag["operacion"] == 1 && lag["idPerdidaHistoricoBase"] == 3) || lag["idEstado"] == 6)
            lag["tNegativoCalidad"] = lag["tiemporeal"];
          else if (lag["usaLotes"] == 1 && lag["cantidad"] != 0 && lag["cantidadAchatarrada"] > 0)
            lag["tNegativoCalidad"] = lag["tiemporeal"];
          else 
            lag["tNegativoCalidad"] = 0;
    
          result.push(lag);
        });
        return result;
    }

    cargarDatos() {
        if (this.data.length > 0) {
            this.tieneDatos = true;
            this.dibujarDonutRendimiento();
            this.dibujarDonutCalidad();
            this.dibujarGraficoRendimientoCalidad();
            this.dibujarGraficoClientes();
            this.prepararDatos();
        }
    }
    prepararDatos() {
        // OEE circular
        this.tEjecucionOEE = 0;
        this.tParadaDisponibilidad = 0;
        this.tMantenimientoDisponibilidad = 0;
        this.tAlarmaDisponibilidad = 0;
        this.tApagadaDisponibilidad = 0;
        this.tParadaRendimiento = 0;        
        this.tMantenimientoRendimiento = 0;
        this.tAlarmaRendimiento = 0;
        this.tApagadaRendimiento = 0;
        this.tParadaCalidad = 0;
        this.tMantenimientoCalidad = 0;
        this.tAlarmaCalidad = 0;
        this.tApagadaCalidad = 0;
        this.tNegativoCalidad = 0;   
        this.tEstimado = 0;
    
        var arrayGridOF = {};
        this.dataGrid = [];

        var arrayClientes = [];
        var clientes = {};

        this.data.forEach(d => {
            //#region OEE CIRCULAR
            var tEjecucion = 0;
            var tParadaRend = 0;        
            var tMantenimientoRend = 0;
            var tAlarmaRend = 0;
            var tApagadaRend = 0;
            var tParadaCal = 0;
            var tMantenimientoCal = 0;
            var tAlarmaCal = 0;
            var tApagadaCal = 0; 

            if((d.idProcesos_Tipo == 2 || d.idProcesos_Tipo == 1 || d.idProcesos_Tipo == 3) && d['idTipoPerdidaOEE'] == 1) {
                this.tParadaDisponibilidad += d.tiemporeal;
            } else if(d.idProcesos_Tipo == 4 && d['idTipoPerdidaOEE'] == 1) {
                this.tMantenimientoDisponibilidad += d.tiemporeal;
            } else if(d.idProcesos_Tipo == 6 && d['idTipoPerdidaOEE'] == 1) {
                this.tAlarmaDisponibilidad += d.tiemporeal;
            } else if(d.idProcesos_Tipo == 8 && d['idTipoPerdidaOEE'] == 1) {
                this.tApagadaDisponibilidad += d.tiemporeal;
            } 
            
            // si es rendimiento y tiene operacion entonces tiene que ir a ejecucion
            else if(d.idProcesos_Tipo == 2 && d['idTipoPerdidaOEE'] == 2 && d.operacion == 0) {
                this.tParadaRendimiento += d.tiemporeal;
                tParadaRend = d.tiemporeal;
            } else if(d.idProcesos_Tipo == 4 && d['idTipoPerdidaOEE'] == 2 && d.operacion == 0) {
                this.tMantenimientoRendimiento += d.tiemporeal;
                tMantenimientoRend = d.tiemporeal;
            } else if(d.idProcesos_Tipo == 6 && d['idTipoPerdidaOEE'] == 2 && d.operacion == 0) {
                this.tAlarmaRendimiento += d.tiemporeal;
                tAlarmaRend = d.tiemporeal;
            } else if(d.idProcesos_Tipo == 8 && d['idTipoPerdidaOEE'] == 2 && d.operacion == 0) {
                this.tApagadaRendimiento += d.tiemporeal;
                tApagadaRend = d.tiemporeal;
            } 
            
            else if((d.idProcesos_Tipo == 2 || d.idProcesos_Tipo == 1 || d.idProcesos_Tipo == 3) && d['idTipoPerdidaOEE'] == 3 ) {
                this.tParadaCalidad += d.tiemporeal;
                tParadaCal = d.tiemporeal; 
            } else if(d.idProcesos_Tipo == 4 && d['idTipoPerdidaOEE'] == 3) {
                this.tMantenimientoCalidad += d.tiemporeal;
                tMantenimientoCal = d.tiemporeal;
            } else if(d.idProcesos_Tipo == 6 && d['idTipoPerdidaOEE'] == 3) {
                this.tAlarmaCalidad += d.tiemporeal;
                tAlarmaCal = d.tiemporeal;
            } else if(d.idProcesos_Tipo == 8 && d['idTipoPerdidaOEE'] == 3) {
                this.tApagadaCalidad += d.tiemporeal;
                tApagadaCal = d.tiemporeal;
            } else {
                this.tEjecucionOEE += d.tiemporeal;
                tEjecucion = d.tiemporeal;
            }

            if (d.idTipoPerdidaOEE == 2) {
                // si la pieza tiene algo de preparacion (que no sea ni dispo ni calidad):
                //  - t estimado total = t esti ejecucion + t esti preparacion
                //  - t total: t total rendiminieto op + t total rendimiento prep 
                // si no:
                //  - t est: t esti ejecucion
                //  - t total: t total rendminieto op
                var tEstimadoTotal = 0;
                var tTotal = 0;
                var tEstEjec = 0;
                if (d.usaLotes)
                    tEstEjec = d.tiempoEstimadoEjecucion * d.cantidad;
                else 
                    tEstEjec = d.tiempoEstimadoEjecucion;

                if (d.tiempoTotalRendimientoOperacion == -1 || d.tiempoEstimadoEjecucion == -1) { // no tiene operacion
                    // tEstimadoTotal = tEjecucion +  d.tiempoTotalRendimientoPreparacion;
                    tEstimadoTotal = 0;
                    tTotal = -1;
                } else if (d.tiempoTotalRendimientoPreparacion != 0) { // tiene preparacion
                    tEstimadoTotal = tEstEjec + d.tiempoEstimadoPreparacion;
                    // tTotal = d.tiempoTotalRendimientoOperacion + d.tiempoTotalRendimientoPreparacion;
                    tTotal = d.tiempoTotal;
                } else {
                    tEstimadoTotal = tEstEjec;
                    // tTotal = d.tiempoTotalRendimientoOperacion;
                    tTotal = d.tiempoTotal;
                }

                if (d.idEstado == 5 || d.idEstado == 4)
                    var tEstLag = 0;
                else if (d.ejecucionSinOperacionRepercuteA == 0 && tTotal == -1)
                    var tEstLag = 0;
                else if (d.ejecucionSinOperacionRepercuteA != 0 && tTotal == -1)
                    var tEstLag = (tEjecucion +  d.tiempoTotalRendimientoPreparacion) * (d.porcentajeEjecucionSinOperacionRepercuteOEE / 100);
                else
                    var tEstLag = tEstimadoTotal * d.tiemporeal / tTotal;

                // tiempo estimado parcial
                // despues de tener el tiempo estimado total, y el tiempo total operacion,
                // limitamos el tiempo estimado parcial segun el tiempo real de la linea (proceso)
                if (d.tiemporeal < tEstLag)
                    this.tEstimado += d.tiemporeal;
                else
                    this.tEstimado += tEstLag;
            }

            this.tNegativoCalidad += d['tNegativoCalidad'];
            //#endregion

            //#region GRID OF
            if (!arrayGridOF[d.numeroOF + "_" + d.cliente + "_" + d.nombrePieza]) {
              arrayGridOF[d.numeroOF + "_" + d.cliente + "_" + d.nombrePieza] = {
                numeroOf: d.numeroOF,
                nombreCliente: d.cliente,
                nombrePieza: d.nombrePieza,
                tiempoEstimado: 0,
                tiempoTotal: d.tiempoTotal, // d.tiempoTotalDesvioOperacion + d.tiempoTotalDesvioPreparacion, /* SE CALCULABA ASI Y NO SE PORQUE.. PONIA NAN Y POR ESO SE HA CAMBIADO*/
                tiempoReal: 0,
                piezaTerminada: true,
                cantidad: d.cantidad,
                nTerminado: 0,
                desvioPorcentaje: d.piezasTerminadas,
                // necesarios para abrir historico piezas
                idOF: d.idOF,
                idCliente: d.idCliente,
                idPieza: d.idPieza
              }
              
              this.dataGrid.push(arrayGridOF[d.numeroOF + "_" + d.cliente + "_" + d.nombrePieza]);
            }

            var tEstimadoGlobal = 0;
            var tEstEjec = 0;
            if (d.usaLotes)
              tEstEjec = d.tiempoEstimadoEjecucion * d.cantidad;
            else 
              tEstEjec = d.tiempoEstimadoEjecucion;

            if (d.tiempoTotalRendimientoPreparacion == 0)
              tEstimadoGlobal = tEstEjec;
            else 
              tEstimadoGlobal = tEstEjec + d.tiempoEstimadoPreparacion;

            arrayGridOF[d.numeroOF + "_" + d.cliente + "_" + d.nombrePieza].tiempoEstimado = Math.max(tEstimadoGlobal, arrayGridOF[d.numeroOF + "_" + d.cliente + "_" + d.nombrePieza].tiempoEstimado);
            arrayGridOF[d.numeroOF + "_" + d.cliente + "_" + d.nombrePieza].tiempoReal += d.tiemporeal;
            arrayGridOF[d.numeroOF + "_" + d.cliente + "_" + d.nombrePieza].piezaTerminada = d.piezaTerminada && arrayGridOF[d.numeroOF + "_" + d.cliente + "_" + d.nombrePieza].piezaTerminada;
            //#endregion

            //#region CLIENTE BARRAS
            if (!clientes[d.cliente]) {
              clientes[d.cliente] = {
                cliente: d.cliente,
                desvio: 0,
                tEstimado: 0,
                tEjecucion: 0,
                tParadaRendimiento: 0,
                tMantenimientoRendimiento: 0,
                tAlarmaRendimiento: 0,
                tApagadaRendimiento: 0,
                tParadaCalidad: 0,
                tMantenimientoCalidad: 0,
                tAlarmaCalidad: 0,
                tApagadaCalidad: 0,
                tNegativoCalidad: 0,
              }
              arrayClientes.push(clientes[d.cliente]);
            }

            clientes[d.cliente].tEjecucion += tEjecucion;
            clientes[d.cliente].tParadaRendimiento += tParadaRend;
            clientes[d.cliente].tMantenimientoRendimiento += tMantenimientoRend;
            clientes[d.cliente].tAlarmaRendimiento += tAlarmaRend;
            clientes[d.cliente].tApagadaRendimiento += tApagadaRend;
            clientes[d.cliente].tParadaCalidad += tParadaCal;
            clientes[d.cliente].tMantenimientoCalidad += tMantenimientoCal;
            clientes[d.cliente].tAlarmaCalidad += tAlarmaCal;
            clientes[d.cliente].tApagadaCalidad += tApagadaCal;
            clientes[d.cliente].tNegativoCalidad += d.tNegativoCalidad;
            //#endregion
        });

        this.cargarDatosOEE();
        this.cargarGraficoClientes(arrayClientes);
        this.cargarGridOF();
    }

    cargarDatosOEE() {
        //CALCULAR DONUTS
        var oee: number = 0;
        var cal: number = 0;
        var ren: number = 0;
        var dis: number = 0;
  
        var perdidasDisponibilidad = this.tParadaDisponibilidad + this.tMantenimientoDisponibilidad + this.tAlarmaDisponibilidad + this.tApagadaDisponibilidad;
        var perdidasRendimiento = this.tParadaRendimiento + this.tMantenimientoRendimiento + this.tAlarmaRendimiento + this.tApagadaRendimiento;
  
        var perdidasCalidad = this.tParadaCalidad + this.tMantenimientoCalidad + this.tAlarmaCalidad + this.tApagadaCalidad;
  
        var disDividendo: number = this.tEjecucionOEE + perdidasRendimiento;
        var disDivisor: number = this.tEjecucionOEE + perdidasRendimiento + perdidasDisponibilidad;
        if (disDivisor == 0) dis = 0;
        else dis = (disDividendo / disDivisor) * 100;
  
        var renDividendo: number = this.tEstimado;
        var renDivisor: number = this.tEjecucionOEE + perdidasRendimiento;
        if (renDivisor == 0) ren = 0;
        else ren = (renDividendo / renDivisor) * 100;
  
        var calDividendo: number = this.tEstimado - this.tNegativoCalidad - perdidasCalidad;
        var calDivisor: number = this.tEstimado;
        if (calDivisor == 0) cal = 0;
        else cal = (calDividendo / calDivisor) * 100;
  
        if (oee > 100) oee = 100;
        if (dis > 100) dis = 100;
        if (ren > 100) ren = 100;
        if (cal > 100) cal = 100;

        oee = cal * ren * dis / (10000);
  
        d3.select('#graficoDonutRendimiento_InformeProyectos_v2 .c3-chart-arcs-title').transition().duration(500).style("font-size", "0px").style("opacity", "0").transition().duration(500).style("font-size", "30px").style("opacity", "1")
        .text(Math.round(ren) + "%");
  
        d3.select('#graficoDonutCalidad_InformeProyectos_v2 .c3-chart-arcs-title').transition().duration(500).style("font-size", "0px").style("opacity", "0").transition().duration(500).style("font-size", "30px").style("opacity", "1")
            .text(Math.round(cal) + "%");
        
        var dataRendimiento = [['completo', ren], ['nocompleto', 100 - ren]];
        this.graficoDonutRendimiento.load({ columns: dataRendimiento });
    
        var dataCalidad = [['completo', cal], ['nocompleto', 100 - cal]];
        this.graficoDonutCalidad.load({ columns: dataCalidad });

        // Barras OEE
        var tRendimiento: number = 0;
        if (this.tEstimado > this.tEjecucionOEE) {
            tRendimiento = 0;
            this.tEstimado = this.tEjecucionOEE;
        } else {
            tRendimiento =this. tEjecucionOEE - this.tEstimado;
        }

        var data = [[this.translateService.instant('total'), (this.tEstimado + tRendimiento + perdidasRendimiento), this.tEstimado, this.tEstimado - this.tNegativoCalidad - perdidasCalidad],
        [this.translateService.instant('perdidas'), perdidasDisponibilidad, tRendimiento + perdidasRendimiento, this.tParadaCalidad /*+ this.tMicroParadaCalidad*/ + this.tMantenimientoCalidad + this.tAlarmaCalidad + this.tApagadaCalidad + this.tNegativoCalidad]];

        this.graficoRendimientoCalidad.load({ columns: data });
  
    }

    cargarGraficoClientes(groupByClientes) {

      var arrayClientes = [];
      var arrayValores = [this.translateService.instant('desvio')];

      // sumar los tiempos estimados
      var auxClientes = {}
      this.dataGrid.forEach(element => {
        if (!auxClientes[element.nombreCliente]) {
          auxClientes[element.nombreCliente] = {tEstimado: 0};
        }

        auxClientes[element.nombreCliente].tEstimado += element.tiempoEstimado;

      })

      groupByClientes.forEach(element => {
        var tEjecuciones = element.tEjecucion;
        var tEstimado = auxClientes[element.cliente].tEstimado;

        var tRendimientoNegativos = (tEjecuciones - tEstimado) + element.tParadaRendimiento + element.tMantenimientoRendimiento + element.tAlarmaRendimiento + element.tApagadaRendimiento;
        var tCalidadNegativos = element.tParadaCalidad + element.tMantenimientoCalidad + element.tAlarmaCalidad + element.tApagadaCalidad + element.tNegativoCalidad;
      
        var desvioTotal = tRendimientoNegativos + tCalidadNegativos;

        arrayClientes.push(element.cliente);
        arrayValores.push(desvioTotal);
      
      });

      //SI SON MENOS DE 5 RELLENAR CON VACIOS PARA QUE LAS BARRAS DEL GRAFICO NO QUEDEN MUY ANCHAS
      for (var i = arrayClientes.length; i <= 5; i++) {
        arrayClientes.push(' '.repeat(i));
        arrayValores.push(null);
      }

      var data = [arrayClientes, arrayValores];
      this.graficoClientes.load({ columns: this.sortData(data) });
    }

    cargarGridOF() {
      this.dataGrid.forEach(element => {
        if (element.tiempoEstimado != 0)
          element.desvioPorcentaje = (100 - (element.tiempoReal / element.tiempoEstimado * 100));
        else
          element.desvioPorcentaje = 100;
      })
    }

    //#region GRAFICOS
    dibujarDonutRendimiento() {

        this.graficoDonutRendimiento = c3.generate({
          bindto: '#graficoDonutRendimiento_InformeProyectos_v2',
          data: {
            columns: [['completo', 0], ['nocompleto', 100]],
            type: 'donut',
            colors: {
              completo: '#18d6b0',
              nocompleto: '#3d6063'
            },
            order: 'null'
          },
          transition: {
            duration: 1000
          },
          donut: {
            title: "0",
            width: 18,
            label: { show: false }
          },
          legend: {
            show: false
          },
          tooltip: {
            show: false
          }
        });
    
        d3.select('#graficoDonutRendimiento_InformeProyectos_v2 .c3-chart-arcs-title')
          .text("")
          .style("font-size", "30px")
          .attr("fill", "#18d6b0");
    
        d3.selectAll("#graficoDonutRendimiento_InformeProyectos_v2 .c3-chart-arcs path").style("stroke-width", "0px");
    
    }
    
    dibujarDonutCalidad() {

        this.graficoDonutCalidad = c3.generate({
            bindto: '#graficoDonutCalidad_InformeProyectos_v2',
            data: {
            columns: [['completo', 0], ['nocompleto', 100]],
            type: 'donut',
            colors: {
                completo: '#18d6b0',
                nocompleto: '#3d6063'
            },
            order: 'null'
            },
            transition: {
            duration: 1000
            },
            donut: {
            title: "0",
            width: 20,
            label: { show: false }
            },
            legend: {
            show: false
            },
            tooltip: {
            show: false
            }
        });

        d3.select('#graficoDonutCalidad_InformeProyectos_v2 .c3-chart-arcs-title')
            .text("")
            .style("font-size", "30px")
            .attr("fill", "#18d6b0");

        d3.selectAll("#graficoDonutCalidad_InformeProyectos_v2 .c3-chart-arcs path").style("stroke-width", "0px");

    }

    dibujarGraficoRendimientoCalidad() {

        this.graficoRendimientoCalidad = c3.generate({
            data: {
            columns: [[this.translateService.instant('total'), 0, 0, 0],
            [this.translateService.instant('perdidas'), 0, 0, 0]],
            order: null,
            type: 'bar',
            groups: [[this.translateService.instant('total'), this.translateService.instant('perdidas')]]
            },
            color: {
            pattern: [['#44e3c4'], ['#ffa8a8']]
            },
            axis: {
            x: {
                type: 'category',
                tick: {
                multiline: false
                },
                categories: [this.translateService.instant('disponibilidad'), this.translateService.instant('rendimiento'), this.translateService.instant('calidad')]
            },
            y: {
                show: false,
            },
            rotated: true
            },
            transition: {
            duration: 1000
            },
            bar: {
            width: {
                ratio: 0.75
            }
            },
            tooltip: {
            format: {
                value: function (value) {
                var negativo = "";
                if (value < 0) negativo = "-";
                var absValue = Math.abs(value);
                var hours = Math.floor(absValue / (60 * 60));
                var divisor_for_minutes = absValue % (60 * 60);
                var minutes = Math.floor(divisor_for_minutes / 60);
                if (0 <= minutes && minutes < 10)
                    return negativo + hours + ":0" + minutes + "h";
                else
                    return negativo + hours + ":" + minutes + "h";
                }
            }
            },
            bindto: '#graficoRendimientoCalidad_InformeProyectos_v2'
        });

    }

    dibujarGraficoClientes() {

        //GRAFICO CLIENTES BARRAS
        var colors = ['#ffb63d', '#ff7666', '#FF89BC', '#bb8eff', '#68e0ff', '#92dcdc', '#50df88', '#87ed77', '#eaea62', '#ffb976'];

        this.graficoClientes = c3.generate({
            padding: {
            top: 0,
            right: 0,
            bottom: -13,
            left: 50,
            },
            data: {
            x: 'x',
            //columns: this.sortData([['Cliente1', 'Cliente2', 'Cliente3'], [this.translateService.instant('desvio'), 36000, 72000, 54000]]),
            columns: this.sortData([[' '], [this.translateService.instant('desvio'), null]]),
            type: 'bar',
            color: function (color, d) {
                return colors[d.index % 10];
            },
            selection: {
                grouped: true
            },
            },
            axis: {
            x: {
                type: 'category'
            },
            y: {
                tick: {
                values: [0],
                format: function (value) {
                    return Math.floor(value / 3600) + "h"
                }
                }
            }
            },
            tooltip: {
            format: {
                value: function (value) {
                if (value >= 0) {
                    return Math.floor(Math.abs(value) / 3600) + ":" + ("0" + (Math.floor((Math.abs(value) % 3600) / 60))).slice(-2) + "h"
                } else {
                    return "-" + Math.floor(Math.abs(value) / 3600) + ":" + ("0" + (Math.floor((Math.abs(value) % 3600) / 60))).slice(-2) + "h"
                }
                }
            }
            },
            transition: {
            duration: 1000
            },
            bar: {
            width: {
                ratio: 0.75
            }
            },
            legend: {
            show: false
            },
            bindto: '#graficoClientes_InformeProyectos_v2'
        });

    }

    public sortData(unsortedData) {
        var sorted = unsortedData.map(function (inaki) {
            return inaki.slice();
        })
        var name = sorted[1].splice(0, 1);
        var datapoints = sorted[1].map(function (c, a) {
            return [sorted[0][a], c];
        });
        var sortedData = datapoints.sort(function (l, v) {
            return v[1] - l[1];
        });
        sorted[1] = sortedData.map(function (point, o) {
            sorted[0][o] = point[0];
            return point[1];
        });
        sorted[1] = name.concat(sorted[1]);
        sorted[0].splice(0, 0, 'x');
        return sorted;
    }
    //#endregion

    cellClick(event: CellClickEvent) {
      var row = event.dataItem;
      var fini = (this.fechaInicioFiltro === undefined) ? "0" : this.myFunctions.dateToYYYY_MM_DD(new Date(this.fechaInicioFiltro));//FORMATO: YYYY_MM_DD
      var ffin = (this.fechaFinFiltro === undefined) ? "0" : this.myFunctions.dateToYYYY_MM_DD(this.myFunctions.dateAddDays(new Date(this.fechaFinFiltro), -1));//FORMATO: YYYY_MM_DD
      window.open('#/analiticaavanzadaejecuciones/' + fini + '/' + ffin + '/0/' + row.idPieza + '/' + row.idOF + '/0/0/0', '_blank');
    }

    abrirHistoricoPiezas(dataItem) {
      var row = dataItem;
      var fini = (this.fechaInicioFiltro === undefined) ? "0" : this.myFunctions.dateToYYYY_MM_DD(new Date(this.fechaInicioFiltro));//FORMATO: YYYY_MM_DD
      var ffin = (this.fechaFinFiltro === undefined) ? this.myFunctions.dateToYYYY_MM_DD(this.myFunctions.getDateNow()) : this.myFunctions.dateToYYYY_MM_DD(new Date(this.fechaFinFiltro));//FORMATO: YYYY_MM_DD
      var enlace = '#/historicoPiezas/' + fini + "/" + ffin + "/0/0/0/0/" + row.idOF + "/" + row.idCliente + "/" + row.idPieza + "/0/0";
      window.open(enlace, '_blank');
    }
    
    public showGridTooltip(e: MouseEvent): void {
        const element = e.target as HTMLElement;
        if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
          && element.offsetWidth < element.scrollWidth) {
          this.tooltipDir.toggle(element);
        } else {
          this.tooltipDir.hide();
        }
    }

    //#region FILTRO
    annadirGrupoFiltro() {
    this.datosFiltro.group.push(
        {
        logic: { id: 0, nombre: this.translateService.instant('y') },
        group: [
            {
            columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            tipoNoEditable:false,
            tipoNoVisible: false
            }
        ]
        }
    )
    }
    annadirLineaFiltro(filtro) {
    // Ahora se selecciona la primera opcion, no hara falta mirar si hay algo seleccionado
    if (this.permitirMultiplesLineasVacias || filtro.group.filter(f => f.columna.id == 0 /*|| f.operator.id == 0*/).length == 0) {
        filtro.group.push({
        columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
        operator: { id: 0, nombre: '' },
        fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
        fechaFin: this.myFunctions.getDateNow(),
        mostrarCalendario: false,
        text: '',
        numberMin: 0,
        numberMax: 0,
        decimalformat: '0.000',
        decimalMin: 0.0,
        decimalMax: 0.0,
        check: false,
        combo: [{ id: 1, nombre: "" }],
        comboSelected: {},
        comboSelecteds: [],
        tipoNoEditable: false,
        tipoNoVisible: false
        })
    }
    }
    borrarLineaFiltro(row, filtro) {
    if (filtro.group.length > 1) {
        //ELIMINAR LINEA DE FILTRO
        let index = filtro.group.findIndex(d => d === row);
        filtro.group.splice(index, 1);
        this.actualizarVisible = true;
    } else if (filtro.group.length == 1 && ((filtro != this.datosFiltro.group[0] || this.datosFiltro.group.length > 1) || this.permitirFiltroVacio)) {
        //SIEMPRE Y CUANDO NO SEA EL PRIMER GRUPO, SE PUEDE ELIMINAR
        let index = this.datosFiltro.group.findIndex(d => d === filtro);
        this.datosFiltro.group.splice(index, 1);
        this.actualizarVisible = true;
    } else if (filtro.group.length == 1 && (((filtro != this.datosFiltro.group[0] || this.datosFiltro.group.length == 1) || !this.permitirFiltroVacio)) && this.vaciarUltimaLinea) {
        //ELIMINAR LINEA DE FILTRO
        let index = filtro.group.findIndex(d => d === row);
        filtro.group.splice(index, 1);
        // Se borra y se añade una linea nueva vacia
        filtro.group.push({
        columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
        operator: { id: 0, nombre: '' },
        fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
        fechaFin: this.myFunctions.getDateNow(),
        mostrarCalendario: false,
        text: '',
        numberMin: 0,
        numberMax: 0,
        decimalformat: '0.000',
        decimalMin: 0.0,
        decimalMax: 0.0,
        check: false,
        combo: [{ id: 1, nombre: "" }],
        comboSelected: {},
        comboSelecteds: [],
        tipoNoEditable: false,
        tipoNoVisible: false
        })
        this.actualizarVisible = true;
    }
    }
    filtroTipoChanged(filtro, row, newSelection) {
    row.columna = newSelection;
    if (this.annadirAutomatico)
        this.annadirLineaFiltro(filtro);


    // DATE -
    if (row.columna.tipo == 'date') { row.operator = this.opcionDate[0]; }
    // DATETIME -
    else if (row.columna.tipo == 'dateTime') { row.operator = this.opcionDateTime[0] }
    // COMBO ESTRICTO -
    else if (row.columna.tipo == 'comboEstrincto') { row.operator = this.opcionComboEstricto[0] }
    // COMBO FLEXIBLE -
    else if (row.columna.tipo == 'comboFlexible') { row.operator = this.opcionComboFlexible[0] }
    // CHECK - NO HAY
    // NUMERIC -
    else if (row.columna.tipo == 'numeric') { row.operator = this.opcionNumericDecimal[0] }
    // DECIMAL -
    else if (row.columna.tipo == 'decimal') { row.operator = this.opcionNumericDecimal[0] }
    // STRING -
    else if (row.columna.tipo == 'string') { row.operator = this.opcionString[0] }

    this.preFiltrado(filtro, row);
    }
    borrarFiltro() {
    this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);
    var fechaInicio = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -6));
    var fechaFin = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.myFunctions.getDateNow());

    // get information
    var fechaInicio = this.myFunctions.dateToYYYY_MM_DD_guion(this.fechaIni) + "T00:00:00Z";
    var fechaFin = this.myFunctions.dateToYYYY_MM_DD_guion(this.fechaFin) + "T00:00:00Z";

    // actualizar la fecha de ultima consulta
    var regex = /01:00:00/i;
    this.fechaIniUltimaConsulta = this.myFunctions.datetimeToSQL(new Date (fechaInicio));
    this.fechaIniUltimaConsulta = this.fechaIniUltimaConsulta.replace(regex, '00:00:00');
    this.fechaFinUltimaConsulta = this.myFunctions.datetimeToSQL(new Date (fechaFin));
    this.fechaFinUltimaConsulta = this.fechaFinUltimaConsulta.replace(regex, '00:00:00');

    this.loading = true;

    this.InformePersonalizadoService.getAll(fechaInicio, fechaFin).subscribe(
        json => {
          this.data = this.juntarTablas(json);
          this.data = this.data.filter(f => f.operacion == 1);
  
          if (this.data != null) { // hau da datu basean errorerik baldin badago panelan loading-a ez agertzeko gehiago
            this.data.forEach(element => {
              element['oculto'] = 0;
  
              // preparar las fechas
              element.fechaIniFiltro = this.myFunctions.sqlToJsDateT(element.fechaini);
              element.fechaIniFiltro = this.myFunctions.datetimeToSQL(element.fechaIniFiltro);
              element.fechaFinFiltro = this.myFunctions.sqlToJsDateT(element.fechafin);
              element.fechaFinFiltro = this.myFunctions.datetimeToSQL(element.fechaFinFiltro);
              element.fechaTurnoFiltro = this.myFunctions.sqlToJsDateT(element.fechaTurno);
              element.fechaTurnoFiltro = this.myFunctions.datetimeToSQL(element.fechaTurnoFiltro);
            });
            this.original = this.myFunctions.copy(this.data);
          } else this.data = [];
  
          this.loading = false;
  
          // ACTUALIZAR INFORMACION PARA COMBOS DE FILTRO
          this.dataFiltro = this.data;

          this.data = filterBy(this.data, this.filtroToKendo());
  
          this.cargarDatos();
        });

    }
    cargarConTodasLasRespuestas() {
    // SI ES NECESARIO, SE CARGAN LAS VARIABLES DESDE LA URL.
    this.cargarFiltroURL();
    this.loadingFiltro = false;
    }

    // DE NUESTRO FILTRO A KENDO FILTER
    filtroToKendo(): CompositeFilterDescriptor {
    var em: CompositeFilterDescriptor = this.filtroToKendo_recursivo(this.datosFiltro);
    return em;
    }
    filtroToKendo_recursivo(jFiltro): CompositeFilterDescriptor {
        // es una linea o es un grupo?
        if (jFiltro.group != undefined) {
          // GRUPO
          //variables para crear la estructura final
          var filtro = [];
          var logica: "or" | "and" = "and";
          if (jFiltro.logic.id == 1)
            logica = "or"
          //por cada grupo
          jFiltro.group.forEach(
            linea => {
              var newRow = this.filtroToKendo_recursivo(linea);
              if (newRow != undefined && newRow != "ERROR" as unknown as CompositeFilterDescriptor)
                filtro.push(newRow);
            });
          if(filtro.length > 0)    
            return { logic: logica, filters: filtro };
        }
        else if (jFiltro.columna != undefined) {
          // LINEA
          //var jRow : CompositeFilterDescriptor;
    
          var jRow = {} as CompositeFilterDescriptor;
    
          // DATE -
          if (jFiltro.columna.tipo == 'date') {
            if (jFiltro.operator.dobleValor) {
              var jSubRow1 = {};
              var jSubRow2 = {};
              //jSubRow1["field"] = jFiltro.columna.field;
              //jSubRow2["field"] = jFiltro.columna.field;
              // jSubRow1["field"] = "fechaIniFiltro";
              // jSubRow2["field"] = "fechaFinFiltro";
              jSubRow1["field"] = "fechaTurnoFiltro";
              jSubRow2["field"] = "fechaTurnoFiltro";
              if (jFiltro.operator.id == 7) {
                jRow["logic"] = 'and';
                jSubRow1["operator"] = "gte"
                jSubRow2["operator"] = "lt" // lte
              }
              else if (jFiltro.operator.id == 8) {
                jRow["logic"] = 'or';
                jSubRow1["operator"] = "lt"
                jSubRow2["operator"] = "gt"
              }
              jSubRow1["value"] = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni));
              jSubRow2["value"] = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1)));
    
              var subFiltro = [];
              subFiltro.push(jSubRow1);
              subFiltro.push(jSubRow2);
    
              jRow["filters"] = subFiltro;
            }
            else {
              jRow["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 1) {
                jRow["operator"] = "lt";
              }
              else if (jFiltro.operator.id == 2) {
                jRow["operator"] = "lte";
              }
              else if (jFiltro.operator.id == 3) {
                jRow["operator"] = "gt";
              }
              else if (jFiltro.operator.id == 4) {
                jRow["operator"] = "gte";
              }
              else if (jFiltro.operator.id == 5) {
                jRow["operator"] = "eq";
              }
              else if (jFiltro.operator.id == 6) {
                jRow["operator"] = "neq";
              }
              jRow["value"] = this.myFunctions.datetimeToDate(this.myFunctions.dateTimeToDate(jFiltro.fechaIni));
            }
          }
          // DATETIME -
          else if (jFiltro.columna.tipo == 'dateTime') {
            if (jFiltro.operator.dobleValor) {
              // no existe este caso por ahora
            }
            else {
              jRow["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 1) {
                jRow["operator"] = "lt";
              }
              else if (jFiltro.operator.id == 2) {
                jRow["operator"] = "lte";
              }
              else if (jFiltro.operator.id == 3) {
                jRow["operator"] = "gt";
              }
              else if (jFiltro.operator.id == 4) {
                jRow["operator"] = "gte";
              }
              else if (jFiltro.operator.id == 5) {
                jRow["operator"] = "eq";
              }
              else if (jFiltro.operator.id == 6) {
                jRow["operator"] = "neq";
              }
              jRow["value"] = jFiltro.fechaIni;
            }
          }
          // COMBO ESTRICTO -
          else if (jFiltro.columna.tipo == 'comboEstrincto') {
            if (jFiltro.operator.dobleValor) {
              if (jFiltro.operator.id == 2) {
                jRow["logic"] = 'or';
              }
              else if (jFiltro.operator.id == 4) {
                jRow["logic"] = 'and';
              }
              var subFiltro = [];
              jFiltro.comboSelecteds.forEach(
                seleccionado => {
                  var jSubRow1 = {};
                  jSubRow1["field"] = jFiltro.columna.field;
                  if (jFiltro.operator.id == 2) {
                    jSubRow1["operator"] = "eq";
                  }
                  else if (jFiltro.operator.id == 4) {
                    jSubRow1["operator"] = "neq";
                  }
                  jSubRow1["value"] = seleccionado.id;
                  subFiltro.push(jSubRow1);
                });
              jRow["filters"] = subFiltro;
            }
            else {
              jRow["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 1) {
                jRow["operator"] = "eq";
              }
              else if (jFiltro.operator.id == 3) {
                jRow["operator"] = "neq";
              }
              if (jFiltro.comboSelected != undefined)
                jRow["value"] = jFiltro.comboSelected.id;
            }
          }
          // COMBO FLEXIBLE -
          else if (jFiltro.columna.tipo == 'comboFlexible' || jFiltro.columna.tipo == 'comboFlexibleOperarioMaquina') {
            if (jFiltro.operator.dobleValor) {
              if (jFiltro.operator.id == 2) {
                jRow["logic"] = 'or';
              }
              else if (jFiltro.operator.id == 4) {
                jRow["logic"] = 'and';
              }
              var subFiltro = [];
              jFiltro.comboSelecteds.forEach(
                seleccionado => {
                  var jSubRow1 = {};
                  jSubRow1["field"] = jFiltro.columna.field;
                  if (jFiltro.operator.id == 2) {
                    jSubRow1["operator"] = "eq";
                    if (jFiltro.columna.id == 9 || jFiltro.columna.id == 10) jSubRow1["operator"] = "contains"; // Operario / Maquina
                  }
                  else if (jFiltro.operator.id == 4) {
                    jSubRow1["operator"] = "neq";
                    if (jFiltro.columna.id == 9 || jFiltro.columna.id == 10) jSubRow1["operator"] = "doesnotcontain"; // Operario / Maquina
                  }
                  jSubRow1["value"] = seleccionado.id;
                  if (jFiltro.columna.id == 9) jSubRow1["value"] = seleccionado.nombre.split(" ").join(";"); // Operario
                  if (jFiltro.columna.id == 10) jSubRow1["value"] = seleccionado.nombre; //Maquina
                  subFiltro.push(jSubRow1);
                });
              jRow["filters"] = subFiltro;
            }
            else {
              jRow["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 1) {
                jRow["operator"] = "eq";
                if (jFiltro.columna.id == 9) jRow["operator"] = "contains"; // Operario
                if (jFiltro.comboSelected != undefined)
                  jRow["value"] = jFiltro.comboSelected.id;
                if (jFiltro.columna.id == 9) jRow["value"] = jFiltro.comboSelected.nombreOperario.split(" ").join(";"); // Operario
                if (jFiltro.columna.id == 10) jRow["value"] = jFiltro.comboSelected.nombreMaquina; // Maquina
              }
              else if (jFiltro.operator.id == 3) {
                jRow["operator"] = "neq";
                if (jFiltro.columna.id == 9) jRow["operator"] = "doesnotcontain"; // Operario
                if (jFiltro.comboSelected != undefined)
                  jRow["value"] = jFiltro.comboSelected.id;
                if (jFiltro.columna.id == 9) jRow["value"] = jFiltro.comboSelected.nombreOperario.split(" ").join(";"); // Operario
                if (jFiltro.columna.id == 10) jRow["value"] = jFiltro.comboSelected.nombreMaquina; // Maquina
              }
              else if (jFiltro.operator.id == 5) {
                jRow["operator"] = "startswith";
                jRow["value"] = jFiltro.text;
              }
              // else if (jFiltro.operator.id == 6){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
              //   jRow["operator"] = NO "startswith";
              //   jRow["value"] = jFiltro.text; 
              // }
              else if (jFiltro.operator.id == 7) {
                jRow["operator"] = "endswith";
                jRow["value"] = jFiltro.text;
              }
              // else if (jFiltro.operator.id == 8){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
              //   jRow["operator"] = NO "endswith"; 
              //   jRow["value"] = jFiltro.text;
              // }
              else if (jFiltro.operator.id == 9) {
                jRow["operator"] = "contains";
                jRow["value"] = jFiltro.text;
                if (jFiltro.columna.id == 9) jRow["value"] = jFiltro.text.split(" ").join(";"); // Operario
              }
              else if (jFiltro.operator.id == 10) {
                jRow["operator"] = "doesnotcontain";
                jRow["value"] = jFiltro.text;
                if (jFiltro.columna.id == 9) jRow["value"] = jFiltro.text.split(" ").join(";"); // Operario
              }
              else if (jFiltro.operator.id == 11) {
                jRow["operator"] = "eq";
                if (jFiltro.columna.id == 9) jRow["operator"] = "contains"; // Operario
                jRow["value"] = jFiltro.text;
                if (jFiltro.columna.id == 9) jRow["value"] = jFiltro.text.split(" ").join(";"); // Operario
              }
              else if (jFiltro.operator.id == 12) {
                jRow["operator"] = "neq";
                if (jFiltro.columna.id == 9) jRow["operator"] = "doesnotcontain"; // Operario
                jRow["value"] = jFiltro.text;
                if (jFiltro.columna.id == 9) jRow["value"] = jFiltro.text.split(" ").join(";"); // Operario
              }
            }
          }
          // CHECK -
          else if (jFiltro.columna.tipo == 'check') {
            // no es necesaria una opcion
            // if (jFiltro.operator.dobleValor) {
            //   // no existe este caso por ahora
            // }
            // else{
            jRow["field"] = jFiltro.columna.field;
            jRow["operator"] = "eq";
            jRow["value"] = jFiltro.check;
            // }        
          }
          // NUMERIC -
          else if (jFiltro.columna.tipo == 'numeric') {
            if (jFiltro.operator.dobleValor) {
              var jSubRow1 = {};
              var jSubRow2 = {};
              jSubRow1["field"] = jFiltro.columna.field;
              jSubRow2["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 7) {
                jRow["logic"] = 'and';
                jSubRow1["operator"] = "gte"
                jSubRow2["operator"] = "lte"
              }
              else if (jFiltro.operator.id == 8) {
                jRow["logic"] = 'or';
                jSubRow1["operator"] = "lt"
                jSubRow2["operator"] = "gt"
              }
              jSubRow1["value"] = jFiltro.numberMin;
              jSubRow2["value"] = jFiltro.numberMax;
    
              var subFiltro = [];
              subFiltro.push(jSubRow1);
              subFiltro.push(jSubRow2);
              jRow["filters"] = subFiltro;
            }
            else {
              jRow["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 1) {
                jRow["operator"] = "lt";
              }
              else if (jFiltro.operator.id == 2) {
                jRow["operator"] = "lte";
              }
              else if (jFiltro.operator.id == 3) {
                jRow["operator"] = "gt";
              }
              else if (jFiltro.operator.id == 4) {
                jRow["operator"] = "gte";
              }
              else if (jFiltro.operator.id == 5) {
                jRow["operator"] = "eq";
              }
              else if (jFiltro.operator.id == 6) {
                jRow["operator"] = "neq";
              }
              jRow["value"] = jFiltro.numberMin;
            }
          }
          // DECIMAL -
          else if (jFiltro.columna.tipo == 'decimal') {
            if (jFiltro.operator.dobleValor) {
              var jSubRow1 = {};
              var jSubRow2 = {};
              jSubRow1["field"] = jFiltro.columna.field;
              jSubRow2["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 7) {
                jRow["logic"] = 'and';
                jSubRow1["operator"] = "gte"
                jSubRow2["operator"] = "lte"
              }
              else if (jFiltro.operator.id == 8) {
                jRow["logic"] = 'or';
                jSubRow1["operator"] = "lt"
                jSubRow2["operator"] = "gt"
              }
              jSubRow1["value"] = jFiltro.decimalMin;
              jSubRow2["value"] = jFiltro.decimalMax;
    
              var subFiltro = [];
              subFiltro.push(jSubRow1);
              subFiltro.push(jSubRow2);
              jRow["filters"] = subFiltro;
            }
            else {
              jRow["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 1) {
                jRow["operator"] = "lt";
              }
              else if (jFiltro.operator.id == 2) {
                jRow["operator"] = "lte";
              }
              else if (jFiltro.operator.id == 3) {
                jRow["operator"] = "gt";
              }
              else if (jFiltro.operator.id == 4) {
                jRow["operator"] = "gte";
              }
              else if (jFiltro.operator.id == 5) {
                jRow["operator"] = "eq";
              }
              else if (jFiltro.operator.id == 6) {
                jRow["operator"] = "neq";
              }
              jRow["value"] = jFiltro.decimalMin;
            }
          }
          // STRING -
          else if (jFiltro.columna.tipo == 'string') {
            jRow["field"] = jFiltro.columna.field;
            if (jFiltro.operator.dobleValor) {
              // no existe este caso por ahora
            }
            else {
              if (jFiltro.operator.id == 1) {
                jRow["operator"] = "startswith";
              }
              // else if (jFiltro.operator.id == 2){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
              //   jRow["operator"] = NO "startswith"; 
              // }
              else if (jFiltro.operator.id == 3) {
                jRow["operator"] = "endswith";
              }
              // else if (jFiltro.operator.id == 4){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
              //   jRow["operator"] = NO "endswith"; 
              // }
              else if (jFiltro.operator.id == 5) {
                jRow["operator"] = "contains";
              }
              else if (jFiltro.operator.id == 6) {
                jRow["operator"] = "doesnotcontain";
              }
              else if (jFiltro.operator.id == 7) {
                jRow["operator"] = "eq";
              }
              else if (jFiltro.operator.id == 8) {
                jRow["operator"] = "neq";
              }
            }
            jRow["value"] = jFiltro.text;
          }
    
          // LINEA
          if (jRow["value"] != undefined && jRow["operator"] != undefined && jRow["field"] != undefined) {
            return jRow;
          }
          // GRUPO
          if (jRow["filters"] != undefined && jRow["logic"] != undefined)
            if (jRow["filters"].length > 0) {
              return jRow;
            }
          // else // filtro sin terminar! no es un filtro a tener en cuenta
          //   return {}
        }
        else {
          //NO EXISTE UNA ESTRUCTURA DEFINIDA PARA ESE FILTRO
          return "ERROR" as unknown as CompositeFilterDescriptor;
        }
    }

    // DE NUESTRO FILTRO A SQL FILTER
    filtroToSQL(dataSQLfields = []) {
    var em = this.filtroToSQL_recursivo(this.datosFiltro, dataSQLfields);
    if (em.length > 0)
        em = " WHERE " + em;
    return em;
    }
    filtroToSQL_recursivo(jFiltro, dataSQLfields) {
    // INPUT
    //   ['hb.fechaTurno', 'columnaSQL']
    // es una linea o es un grupo?
    var sqlFilter = "";
    if (jFiltro.group != undefined) {
        var logica: string = " AND ";
        if (jFiltro.logic.id == 1)
        logica = " OR ";
        //por cada grupo
        jFiltro.group.forEach(
        linea => {
            var newRow = this.filtroToSQL_recursivo(linea, dataSQLfields);
            if (newRow != undefined && newRow != "ERROR" && newRow != "")
            if (sqlFilter != "") {
                sqlFilter = sqlFilter + " " + logica + " (" + newRow + ")";
            }
            else {
                sqlFilter = "(" + newRow + ")";
            }
        });
    }
    else if (jFiltro.columna != undefined) {
        if (dataSQLfields.includes(jFiltro.columna.sqlfield) || dataSQLfields.length == 0) {
        // DATE -
        if (jFiltro.columna.tipo == 'date') {
            if (jFiltro.operator.dobleValor) {
            this.fechaInicioFiltro = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni));
            this.fechaFinFiltro = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1)));
            if (jFiltro.operator.id == 7) {
                sqlFilter = jFiltro.columna.sqlfield + " >= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "' AND " + jFiltro.columna.sqlfield + " <= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1))) + "'"
            }
            else if (jFiltro.operator.id == 8) {
                sqlFilter = jFiltro.columna.sqlfield + " < '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "' OR " + jFiltro.columna.sqlfield + " > '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1))) + "'"
            }
            }
            else {
            if (jFiltro.operator.id == 1) {
                sqlFilter = jFiltro.columna.sqlfield + " < '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 2) {
                sqlFilter = jFiltro.columna.sqlfield + " <= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 3) {
                sqlFilter = jFiltro.columna.sqlfield + " > '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 4) {
                sqlFilter = jFiltro.columna.sqlfield + " >= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 5) {
                sqlFilter = jFiltro.columna.sqlfield + " = '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 6) {
                sqlFilter = jFiltro.columna.sqlfield + " <> '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            }
        }
        // DATETIME -
        else if (jFiltro.columna.tipo == 'dateTime') {
            if (jFiltro.operator.dobleValor) {
            // no existe este caso por ahora
            }
            else {
            if (jFiltro.operator.id == 1) {
                sqlFilter = jFiltro.columna.sqlfield + " < " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 2) {
                sqlFilter = jFiltro.columna.sqlfield + " <= " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 3) {
                sqlFilter = jFiltro.columna.sqlfield + " > " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 4) {
                sqlFilter = jFiltro.columna.sqlfield + " >= " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 5) {
                sqlFilter = jFiltro.columna.sqlfield + " = " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 6) {
                sqlFilter = jFiltro.columna.sqlfield + " <> " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            }
        }
        // COMBO ESTRICTO -
        else if (jFiltro.columna.tipo == 'comboEstrincto') {
            if (jFiltro.operator.dobleValor) {
            var valores = "";
            jFiltro.comboSelecteds.forEach(
                seleccionado => {
                if (valores == "")
                    valores = "'" + seleccionado.id + "'";
                else
                    valores += ", '" + seleccionado.id + "'";
                });
            if (valores != "") {
                if (jFiltro.operator.id == 2) {
                sqlFilter = jFiltro.columna.sqlfield + ' IN (' + valores + ")";
                }
                else if (jFiltro.operator.id == 4) {
                sqlFilter = jFiltro.columna.sqlfield + ' NOT IN (' + valores + ")";
                }
            }
            }
        }
        // COMBO FLEXIBLE -
        else if (jFiltro.columna.tipo == 'comboFlexible') {
            if (jFiltro.operator.dobleValor) {
            var valores = "";
            jFiltro.comboSelecteds.forEach(
                seleccionado => {
                if (valores == "")
                    valores = "'" + seleccionado.id + "'";
                else
                    valores += ", '" + seleccionado.id + "'";
                });
            if (valores != "") {
                if (jFiltro.operator.id == 2) {
                sqlFilter = jFiltro.columna.sqlfield + " IN (" + valores + ")";
                }
                else if (jFiltro.operator.id == 4) {
                sqlFilter = jFiltro.columna.sqlfield + " NOT IN (" + valores + ")";
                }
            }
            }
            else {
            if (jFiltro.operator.id == 5) {
                sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 7) {
                sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "')";
            }
            else if (jFiltro.operator.id == 9) {
                sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 10) {
                sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 11) {
                sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "')";
            }
            else if (jFiltro.operator.id == 12) {
                sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('" + jFiltro.text + "')";
            }
            }
        }
        // CHECK -
        else if (jFiltro.columna.tipo == 'check') {
            if (jFiltro.check) {
            sqlFilter = jFiltro.columna.sqlfield + " = 1";
            }
            else {
            sqlFilter = jFiltro.columna.sqlfield + " = 0";
            }
            // }        
        }
        // NUMERIC -
        else if (jFiltro.columna.tipo == 'numeric') {
            if (jFiltro.operator.dobleValor) {
            if (jFiltro.operator.id == 7) {
                sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.numberMin + "' AND " + jFiltro.columna.sqlfield + " <= '" + jFiltro.numberMax + "'";
            }
            else if (jFiltro.operator.id == 8) {
                sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.numberMin + "' OR '" + jFiltro.columna.sqlfield + " > '" + jFiltro.numberMax + "'";
            }
            }
            else {
            if (jFiltro.operator.id == 1) {
                sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 2) {
                sqlFilter = jFiltro.columna.sqlfield + " <= '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 3) {
                sqlFilter = jFiltro.columna.sqlfield + " > '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 4) {
                sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 5) {
                sqlFilter = jFiltro.columna.sqlfield + " = '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 6) {
                sqlFilter = jFiltro.columna.sqlfield + " <> '" + jFiltro.numberMin + "'";
            }
            }
        }
        // DECIMAL -
        else if (jFiltro.columna.tipo == 'decimal') {
            if (jFiltro.operator.dobleValor) {
            if (jFiltro.operator.id == 7) {
                sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.decimalMin + "' AND " + jFiltro.columna.sqlfield + " <= '" + jFiltro.decimalMax + "'";
            }
            else if (jFiltro.operator.id == 8) {
                sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.decimalMin + "' OR '" + jFiltro.columna.sqlfield + " > '" + jFiltro.decimalMax + "'";
            }
            }
            else {
            if (jFiltro.operator.id == 1) {
                sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 2) {
                sqlFilter = jFiltro.columna.sqlfield + " <= '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 3) {
                sqlFilter = jFiltro.columna.sqlfield + " > '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 4) {
                sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 5) {
                sqlFilter = jFiltro.columna.sqlfield + " = '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 6) {
                sqlFilter = jFiltro.columna.sqlfield + " <> '" + jFiltro.decimalMin + "'";
            }
            }
        }
        // STRING -
        else if (jFiltro.columna.tipo == 'string') {
            if (jFiltro.operator.dobleValor) {
            // no existe este caso por ahora
            }
            else {
            if (jFiltro.operator.id == 1) {
                sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 3) {
                sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "')";
            }
            else if (jFiltro.operator.id == 5) {
                sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 6) {
                sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 7) {
                sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "')";
            }
            else if (jFiltro.operator.id == 8) {
                sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('" + jFiltro.text + "')";
            }
            }
        }
        }
    }
    return sqlFilter;
    }

    // DE NUESTRO FILTRO A CANTIDAD FILTRADOS
    filtroToCount(jFiltro = this.datosFiltro) {
    var em = this.filtroToCount_recursivo(jFiltro);
    return em;
    }
    filtroToCount_recursivo(jFiltro) {
    // es una linea o es un grupo?
    if (jFiltro.group != undefined) {
        // GRUPO
        var count = 0;
        //por cada grupo
        jFiltro.group.forEach(
        linea => {
            var newRow = this.filtroToCount_recursivo(linea);
            if (newRow != undefined && newRow > 0)
            count += newRow;
        });
        return count;
    }
    else if (jFiltro.columna != undefined) {
        // LINEA
        var count = 0;
        // DATE -
        if (jFiltro.columna.tipo == 'date') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.fechaIni != undefined && jFiltro.fechaFin != undefined) {
            count = 1;
        }
        else {
            if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.fechaIni != undefined) {
            count = 1;
            }
        }
        }
        // DATETIME -
        else if (jFiltro.columna.tipo == 'dateTime') {
        if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.fechaIni != undefined) {
            count = 1;
        }
        }
        // COMBO ESTRICTO -
        else if (jFiltro.columna.tipo == 'comboEstrincto') {
        if ((jFiltro.operator.id == 2 || jFiltro.operator.id == 4) && jFiltro.comboSelecteds.length > 0) {
            count = 1;
        }
        else if ((jFiltro.operator.id == 1 || jFiltro.operator.id == 3) && jFiltro.comboSelected.id > 0) {
            count = 1;
        }
        }
        // COMBO FLEXIBLE -
        else if (jFiltro.columna.tipo == 'comboFlexible') {
        if ((jFiltro.operator.id == 2 || jFiltro.operator.id == 4) && jFiltro.comboSelecteds.length > 0) {
            count = 1;
        }
        else if ((jFiltro.operator.id == 1 || jFiltro.operator.id == 3) && jFiltro.comboSelected.id > 0) {
            count = 1;
        }
        else if ((jFiltro.operator.id >= 5 && jFiltro.operator.id < 13) && jFiltro.text > "") {
            count = 1;
        }
        }
        // CHECK -
        else if (jFiltro.columna.tipo == 'check') {
        count = 1;
        }
        // NUMERIC -
        else if (jFiltro.columna.tipo == 'numeric') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.numberMin != undefined && jFiltro.numberMax != undefined) {
            count = 1;
        }
        else {
            if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.numberMin == false) {
            count = 1;
            }
        }
        }
        // DECIMAL -
        else if (jFiltro.columna.tipo == 'decimal') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.decimalMin != undefined && jFiltro.decimalMax != undefined) {
            count = 1;
        }
        else if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.decimalMin == false) {
            count = 1;
        }
        }
        // STRING -
        else if (jFiltro.columna.tipo == 'string' && jFiltro.text != "") {
        count = 1;
        }
        return count;
    }
    else {
        return 0;
    }
    }

    // FECHA DOBLE
    //    Función para agregar los días seleccionados al periodo correspondiente (el periodo seleccionado)
    valueClickCalendar(row, month, event) {
    if (event.target.classList.contains("calendarFiltro-calendario-contenido-dia")) { //nos aseguramos de que se está clickando en un día y no en otra parte
        if ((event.target.classList.contains("calendarFiltro-calendarioSeleccionado") && row.fechaIni != undefined && row.fechaFin == undefined)) {//se ha vuelto a seleccionar en el periodo, deseleccionar
        row.fechaFin = this.DaysInMonths[month];
        row.mostrarCalendario = false; //Si ya tenemos las dos fechas, que lo cierre
        } else {
        if (row.fechaIni == undefined && row.fechaFin == undefined) {
            row.fechaIni = this.DaysInMonths[month];
        }
        else if (row.fechaIni != undefined && row.fechaFin == undefined) {
            row.fechaFin = this.DaysInMonths[month];
            if (row.fechaIni > row.fechaFin) { //mirar qué fecha debe ir primero, just in case
            //están al revés, corregirlas
            var aux = new Date(row.fechaIni.getTime());
            row.fechaIni = new Date(row.fechaFin.getTime());
            row.fechaFin = aux;
            }
            row.mostrarCalendario = false; //Si ya tenemos las dos fechas, que lo cierre
            //Y que lo añada com que ya se ha cambiado la fecha, como es obligatoria, nunca saldrá de ese div, solo se vuelve a incluir para destacar el cambio con la animación
            // this.CambioFiltro();
        }
        else {
            //en este caso había dos y se brran para empezar a seleccionar otra vez
            //por tanto, quitamos el tag hasta que se hayan seleccionado las dos
            row.fechaIni = this.DaysInMonths[month];
            row.fechaFin = undefined;
        }
        }
    }
    }
    //    Función para avanzar o ir atrás en los meses del calendario
    cambiarMeses(value) {
    if (value == -1) {
        (document.getElementById("calendario-0").getElementsByTagName("kendo-calendar-header")[0].children[2].children[0] as any).click();
    } else if (value == 1) {
        (document.getElementById("calendario-0").getElementsByTagName("kendo-calendar-header")[0].children[2].children[2] as any).click();
    }
    }
    //    Función para que el calendario se muestre o no
    showCalendar(row) {
    if (!row.mostrarCalendario) {
        row.mostrarCalendario = true;
    }
    else {
        row.mostrarCalendario = false;
        if (row.fechaIni != undefined && row.fechaFin == undefined) {
        row.fechaFin = row.fechaIni;
        }
        else if (row.fechaIni == undefined && row.fechaFin == undefined) {
        row.fechaIni = this.myFunctions.getDateNow();
        row.fechaFin = row.fechaIni;
        }

    }
    // this.mostrarCalendario = true; // activa el click de fondo!
    }
    //    Función para inicializar los meses del calendario
    cargarMeses() {
    this.DaysInMonths = [];
    //Necesitamos inicializar los meses para que se pongan en su día correcto, los pondremos en el día actual y el primer día del mes siguiente
    this.DaysInMonths.push(this.myFunctions.getDateNow());
    }
    //    Función para pintar del color adecuado el periodo seleccionado
    isDateSelected(row, date) {
    if (row.fechaIni == undefined && row.fechaFin == undefined) {
        return false;
    } else if (row.fechaIni != undefined && row.fechaFin == undefined) {
        return date.getFullYear() == row.fechaIni.getFullYear() && date.getMonth() == row.fechaIni.getMonth() && date.getDate() == row.fechaIni.getDate();
    } else if (row.fechaIni != undefined && row.fechaFin != undefined) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate()) >= new Date(row.fechaIni.getFullYear(), row.fechaIni.getMonth(), row.fechaIni.getDate()) &&
        new Date(date.getFullYear(), date.getMonth(), date.getDate()) <= new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth(), row.fechaFin.getDate());
    }
    };
    //    Botones filtro fechas, (los botones que hay a la derecha del calendario)
    ultimas24HButton(row) {
    var today = this.myFunctions.getDateNow();
    row.fechaIni = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    row.fechaFin = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false; // quita el click de fondo!
    }
    ultimos7DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth(), row.fechaFin.getDate() - 7);
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false;// quita el click de fondo!
    }
    ultimos30DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth() - 1, row.fechaFin.getDate());
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false;// quita el click de fondo!
    }
    ultimos60DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth() - 2, row.fechaFin.getDate());
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false;// quita el click de fondo!
    }

    // ESTA FUNCION CAMBIA SEGUN EL FILTRO!
    cargar_Filtro() {
    // Dentro de esta funcion se meteran todas las cargas de combos, fechas... que necesite el filtro para funcionar.

    // Como cal definir el filtro no se pueden poner las fechas de lunes a domingo, se hace al cargar el filtro, se actualiza el filtro por defecto
    var fechaIni;
    var fechaInicio;
    var fechaFin;

    //calcular fecha inicio
    if (fechaInicio == undefined) {
        fechaInicio = this.myFunctions.getDateNow()
    }
    fechaIni = fechaInicio
    if (fechaIni.getDay() == 3) {
        fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 2));
    } else if (fechaIni.getDay() == 4) {
        fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 3));
    } else if (fechaIni.getDay() == 5) {
        fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 4));
    } else if (fechaIni.getDay() == 6) {
        fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 5));
    } else if (fechaIni.getDay() == 0) {
        fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 6));
    } else if (fechaIni.getDay() == 2) {
        fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 1));
    }

    //calcular fecha fin
    this.fechaIni = new Date(fechaInicio);
    this.fechaFin = new Date(fechaIni.setDate(fechaIni.getDate() + 6));

    //calcular horas
    this.fechaIni.setHours(0, 0, 0);
    this.fechaFin.setHours(0, 0, 0);

    this.filtroPorDefecto = {
        logic: { id: 1, nombre: this.translateService.instant('o') },
        group: [
        {
            logic: { id: 0, nombre: this.translateService.instant('y') },
            group: [
            {
                columna: { id: 1, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },
                operator: { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
                fechaIni: this.fechaIni,
                fechaFin: this.fechaFin,
                mostrarCalendario: false,
                text: '',
                numberMin: 0,
                numberMax: 0,
                decimalformat: '0.000',
                decimalMin: 0.0,
                decimalMax: 0.0,
                check: false,
                combo: [{ id: 1, nombre: "" }],
                comboSelected: {},
                comboSelecteds: [],
                tipoNoEditable: true,
                tipoNoVisible: true
            },
            {
                columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
                operator: { id: 0, nombre: '' },
                fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
                fechaFin: this.myFunctions.getDateNow(),
                mostrarCalendario: false,
                text: '',
                numberMin: 0,
                numberMax: 0,
                decimalformat: '0.000',
                decimalMin: 0.0,
                decimalMax: 0.0,
                check: false,
                combo: [{ id: 1, nombre: "" }],
                comboSelected: {},
                comboSelecteds: [],
                tipoNoEditable: false,
                tipoNoVisible: false
            }
            ]
        }
        ]
    }; 


    this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);

    var r1, r2, r3, r4: boolean = false;
    //FECHAS
    this.cargarMeses();

    //TURNOS
    this.filtro_listaTurnos = [
        { nombreTurno: this.translateService.instant("manana"), idTurno: 1 },
        { nombreTurno: this.translateService.instant("tarde"), idTurno: 2 },
        { nombreTurno: this.translateService.instant("noche"), idTurno: 3 }
    ];

    //OPERARIOS
    this.informeProyectosService.Get_Operarios_simple().pipe(first()).subscribe((data: any) => {
        this.filtro_listaOperarios = data;
        this.filtro_listaOperarios.sort((a, b) => (a.nombreOperario > b.nombreOperario) ? 1 : ((b.nombreOperario > a.nombreOperario) ? -1 : 0));

        r3 = true;
        if (r1 && r2 && r3 && r4) this.cargarConTodasLasRespuestas();
    });

    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
        this.seccionesSeleccionadas.forEach(
        seccion => {
            idsSeccionesSelecteds.push(seccion.id);
        });
    }
    else {
        this.secciones?.forEach(
        seccion => {
            idsSeccionesSelecteds.push(seccion.id);
        });
    }
    //FLTRO POR GRUPOS
    var idsGruposSelecteds: any = [];
    if (this.gruposSeleccionados) {
        this.gruposSeleccionados.forEach(
        grupo => {
            idsGruposSelecteds.push(grupo.id.toString()); // se pasa a string para hacer la comparacion
        });
    }
    //MAQUINAS    
    this.filtro_listaMaquinas = this.maquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) && idsGruposSelecteds.some(r => f.idsGrupos.split(",").map(Number).includes(r))));
    this.filtro_listaMaquinas = this.maquinas.filter(f => {
        var enGrupo = false
        f.idsGrupos.split(',').forEach(
        idGrupo => {
            enGrupo = enGrupo || idsGruposSelecteds.includes(idGrupo);
        });
        return ((idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0) && (enGrupo || idsGruposSelecteds.length == 0));
    });

    //HERRAMIENTAS
    this.informeProyectosService.Get_Herramientas().pipe(first()).subscribe(
        (data: any) => {
        this.filtro_listaHerramientas = data;
        this.filtro_listaHerramientas.sort((a, b) => (a.nombreHerramienta > b.nombreHerramienta) ? 1 : ((b.nombreHerramienta > a.nombreHerramienta) ? -1 : 0));
        r2 = true;
        if (r1 && r2 && r3 && r4) this.cargarConTodasLasRespuestas();
        });

    //CLIENTES, PIEZAS, OFS, OPERACIONES Y PARTES
    this.informeProyectosService.Get_ClientesPiezasOfsOperacionesPartes_simple().pipe(first()).subscribe(
        (data: any) => {
        this.dataFiltro = data;

        r4 = true;
        if (r1 && r2 && r3 && r4) this.cargarConTodasLasRespuestas();

        });

    //SI ocultarParte=1 NO ENSEÑAR EL MULTISELECT DE PARTES

    if (!this.user.ocultarParte) this.filtro_ocultarPartes = false;
    if (this.user.ocultarParte) this.filtro_ocultarPartes = true;

    // se quita parte de las opciones seleccionables directamente
    if (this.filtro_ocultarPartes)
        this.columnasFiltro = [
        { id: 2, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },                            
        { id: 8, nombre: this.translateService.instant('of'), field: "nombreOf", sqlfield: "ofo.numeroOF", tipo: 'comboFlexible' },                    // 
        { id: 9, nombre: this.translateService.instant('cliente'), field: "nombreCliente", sqlfield: "ofo.nombreCliente", tipo: 'comboFlexible' },     //
        { id: 10, nombre: this.translateService.instant('pieza'), field: "nombrePieza", sqlfield: "ofo.nombrePieza", tipo: 'comboFlexible' },          // 
        { id: 14, nombre: this.translateService.instant('terminado'), field: "terminado", sqlfield: "ofo.piezaTerminada", tipo: 'check' },          // ofo.operacionTerminada 
        { id: 15, nombre: this.translateService.instant('refpieza'), field: "refPieza", sqlfield: "ofo.refPieza", tipo: 'comboFlexible' },
        { id: 16, nombre: this.translateService.instant('planopieza'), field: "planoPieza", sqlfield: "ofo.planoPieza", tipo: 'comboFlexible' },
        { id: 17, nombre: this.translateService.instant('tipopieza'), field: "tipoPieza", sqlfield: "ofo.tipoPieza", tipo: 'comboFlexible' }
        ];

    r1 = true;
    if (r1 && r2 && r3 && r4) this.cargarConTodasLasRespuestas();
        
    }
    preFiltrado(filtro, row) {
    // si es un COMBO lo cargamos
    if (row.columna.tipo == "comboEstrincto" || row.columna.tipo == "comboFlexible") {
        // cargamos los datos filtrados que NO sean de esta linea.   
        var dataFiltroLag;
        dataFiltroLag = this.dataFiltro;

        // borrar la seleccion actual
        row.comboSelected = {};
        row.comboSelecteds = [];

        // cargar los combos
        if (row.columna.id == 3) { // turnos
        row.combo = this.filtro_listaTurnos;
        }
        else if (row.columna.id == 4) { // seccion
        row.combo = this.groupedSeccion; // OK
        }
        else if (row.columna.id == 5) { // grupo de maquinas
        row.combo = this.grupos.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0)); // OK
        }
        else if (row.columna.id == 6) { // maquinas FILTRO!
        row.combo = this.filtro_listaMaquinas.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0)); // OK
        }
        else if (row.columna.id == 7) { // operarios 
        row.combo = this.filtro_listaOperarios.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
        }

        // cargar desde dataFiltroLag = filtrado(this.dataFiltro)
        else if (row.columna.id == 8) { // OF FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
            of => {
            if (!lag.includes(of.nombreOf)) {
                lag.push(of.nombreOf);
                var js = { id: of.nombreOf, nombre: of.nombreOf };
                combo.push(js);
            }
            });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
        }
        else if (row.columna.id == 9) { // clientes FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
            cliente => {
            if (!lag.includes(cliente.nombreCliente)) {
                lag.push(cliente.nombreCliente);
                var js = { id: cliente.nombreCliente, nombre: cliente.nombreCliente };
                combo.push(js);
            }
            });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
        }
        else if (row.columna.id == 10) { // piezas FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
            pieza => {
            if (!lag.includes(pieza.nombrePieza)) {
                lag.push(pieza.nombrePieza);
                var js = { id: pieza.nombrePieza, nombre: pieza.nombrePieza };
                combo.push(js);
            }

            });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
        }
        else if (row.columna.id == 11) { // N series FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
            nSerie => {
            if (!lag.includes(nSerie.nSerie)) {
                lag.push(nSerie.nSerie);
                var js = { id: nSerie.nSerie, nombre: nSerie.nSerie };
                combo.push(js);
            }
            });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
        }
        else if (row.columna.id == 12) { // partes FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
            parte => {
            if (!lag.includes(parte.nombreParte)) {
                lag.push(parte.nombreParte);
                var js = { id: parte.nombreParte, nombre: parte.nombreParte };
                combo.push(js);
            }
            });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
        }
        else if (row.columna.id == 13) { // operaciones FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
            operacion => {
            if (!lag.includes(operacion.nombreOperacion)) {
                lag.push(operacion.nombreOperacion);
                var js = { id: operacion.nombreOperacion, nombre: operacion.nombreOperacion };
                combo.push(js);
            }
            });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
        }

        // ref pieza, plano pieza y tipo pieza
        else if (row.columna.id == 15) { // ref pieza
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
            refPieza => {
            if (!lag.includes(refPieza.referenciaPieza)) {
                lag.push(refPieza.referenciaPieza);
                var js = { id: refPieza.referenciaPieza, nombre: refPieza.referenciaPieza };
                combo.push(js);
            }
            });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
        }
        else if (row.columna.id == 16) { // plano pieza
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
            planopieza => {
            if (!lag.includes(planopieza.numeroPlano)) {
                lag.push(planopieza.numeroPlano);
                var js = { id: planopieza.numeroPlano, nombre: planopieza.numeroPlano };
                combo.push(js);
            }
            });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
        }
        else if (row.columna.id == 17) { // tipo pieza
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
            tipopieza => {
            if (!lag.includes(tipopieza.id_Piezas_Tipos)) {
                lag.push(tipopieza.id_Piezas_Tipos);
                var js = { id: tipopieza.id_Piezas_Tipos, nombre: tipopieza.id_Piezas_Tipos };
                combo.push(js);
            }
            });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
        }
    }
    }
    refiltrarFiltro(filtro) {
    // ESTA FUNCION SE EJECUTA CUANDO SE CAMBIA LA COLUMNA DE TIPO DE FILTRADO!
    // DE:
    //  - AND --> OR
    //  - OR  --> AND
    }
    onFilter() {
        this.loading = true;
        var filtroCompleto: any = this.filtroToSQL(['hb.fechaTurno']); // este filtro sirve para hacer el segundo filtro

        if (this.fechaInicioFiltro < this.fechaIniUltimaConsulta || this.fechaFinFiltro > this.fechaFinUltimaConsulta) { // hay que hacer la consulta    
            this.fechaIniUltimaConsulta = this.fechaInicioFiltro;
            this.fechaFinUltimaConsulta = this.fechaFinFiltro;
            
            this.InformePersonalizadoService.getAll(this.fechaInicioFiltro, this.fechaFinFiltro).subscribe(
              json => {
                this.data = this.juntarTablas(json);
                this.data = this.data.filter(f => f.operacion == 1);
      
                // ACTUALIZAR INFORMACION PARA COMBOS DE FILTRO
                this.dataFiltro = this.data;
                
                if (this.data != null) { // hau da datu basean errorerik baldin badago panelan loading-a ez agertzeko gehiago
                  this.data.forEach(element => {
                    element['oculto'] = 0;
      
                    // preparar las fechas
                    element.fechaIniFiltro = this.myFunctions.sqlToJsDateT(element.fechaini);
                    element.fechaIniFiltro = this.myFunctions.datetimeToSQL(element.fechaIniFiltro);
                    element.fechaFinFiltro = this.myFunctions.sqlToJsDateT(element.fechafin);
                    element.fechaFinFiltro = this.myFunctions.datetimeToSQL(element.fechaFinFiltro);
                    element.fechaTurnoFiltro = this.myFunctions.sqlToJsDateT(element.fechaTurno);
                    element.fechaTurnoFiltro = this.myFunctions.datetimeToSQL(element.fechaTurnoFiltro);
                  });
                  this.original = this.myFunctions.copy(this.data);
                } else this.data = [];
      
                this.data = filterBy(this.data, this.filtroToKendo());
                this.loading = false;
                this.cargarDatos();
              }); 
            this.status = true;
            this.actualizarVisible = false;
          } else { // no es necesario hacer la consulta
            
            this.data = filterBy(this.original, this.filtroToKendo());
            this.data.forEach(element => {
              element['oculto'] = 0;
      
              // preparar las fechas
              element.fechaIniFiltro = this.myFunctions.sqlToJsDateT(element.fechaini);
              element.fechaIniFiltro = this.myFunctions.datetimeToSQL(element.fechaIniFiltro);
              element.fechaFinFiltro = this.myFunctions.sqlToJsDateT(element.fechafin);
              element.fechaFinFiltro = this.myFunctions.datetimeToSQL(element.fechaFinFiltro);
              element.fechaTurnoFiltro = this.myFunctions.sqlToJsDateT(element.fechaTurno);
              element.fechaTurnoFiltro = this.myFunctions.datetimeToSQL(element.fechaTurnoFiltro);
            });

            this.status = true;
            this.cargarDatos();
            this.loading = false;
          }

    }
    cargarFiltroURL() {
    // cargaremos el filtro en una variable para despues actualizar el filtro
    var datosFiltro = {
        logic: { id: 1, nombre: this.translateService.instant('o') },
        group: [
        {
            logic: { id: 0, nombre: this.translateService.instant('y') },
            group: []
        }
        ]
    };
    // Las lineas con '/**/' son lineas de antes!, para ver como se cargarian ahora en el nuevo filtro
    // SE PUEDE FILTRAR DESDE HISTORICO PIEZAS o INFORME PROYECTOS
    if (Number.parseInt(this.route.snapshot.params['idHistoricoPieza']) > 0) {
        // Historico Piezas:
        //   - fechaIni , fechaFin *fijo*
        //   - idHistoricoPiezas *fijo*
        //   - idHistoricoOperaciones (posible extra)

        // EN ESTE CASO, EL FILTRO REQUIERE UNA CONSULTA. Por eso se cargan las fechas y el filtro en otra funcion aparte despues de recibir las 2 respuestas
        var r1, r2 = false;
        // HISTORICO PIEZAS??
        /**/var IdHistorico = Number.parseInt(this.route.snapshot.params['idHistoricoPieza']);
        this.historicoOperacionesService.Get_nSerie(IdHistorico).subscribe(
        (json) => {
            var an: any = json
            if (an.length > 0) {
            this.idpieza_prefiltro = an[0].idPieza;
            this.idof_prefiltro = an[0].idOF;
            this.nSerie_prefiltro = an[0].nSerie;
            }
            r1 = true;
            if (r1 && r2)
            this.cargarFiltroURL_postConsulta();
        });
        // HISTORICO OPERACIONES??
        /**/var IdHistoricoOp = Number.parseInt(this.route.snapshot.params['idHistoricoOperacion']);
        this.historicoOperacionesService.Get_operacin_HO(IdHistoricoOp).subscribe(
        (json) => {
            var an: any = json
            if (an.length > 0) {
            this.idOperacion_prefiltro = an[0].idOFs_Operacion;
            }
            r2 = true;
            if (r1 && r2)
            this.cargarFiltroURL_postConsulta();
        });
    }
    else {
        // Informe Proyectos:
        //   - fechaIni , fechaFin *fijo*
        //   - idcliente (posible extra)
        //   - idpieza (posible extra)
        //   - idof (posible extra)
        //   - terminados (posible extra)
        // FECHAS (INICIO, FIN)
        /**/this.fini = this.route.snapshot.params['fini'];
        /**/this.ffin = this.route.snapshot.params['ffin'];
        if (this.route.snapshot.params['fini'] != '0' && this.route.snapshot.params['ffin'] != '0'
        && this.route.snapshot.params['fini'] != undefined && this.route.snapshot.params['ffin'] != undefined) {
        datosFiltro.group[0].group.push(
            {
            columna: { id: 2, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },
            operator: { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
            fechaIni: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['fini']),
            fechaFin: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['ffin']),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
            }
        );
        }
        // OF
        /**/this.idof = Number.parseInt(this.route.snapshot.params['idof']);
        var rowOFs = this.dataFiltro.filter(x => x.idOf == this.route.snapshot.params['idof']);
        if (rowOFs.length > 0) {
        datosFiltro.group[0].group.push(
            {
            columna: { id: 8, nombre: this.translateService.instant('of'), field: "nombreOf", sqlfield: "po.numeroOF", tipo: 'comboFlexible' },
            operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
            }
        );
        // Se carga el combo de clientes (esto limpia la seleccion)
        this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
        // Se selecciona el que queremos
        var of = { id: rowOFs[0].nombreOf, nombre: rowOFs[0].nombreOf }
        datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [of];
        }
        // CLIENTE
        /**/this.idcliente = Number.parseInt(this.route.snapshot.params['idcliente']);
        // Aprobechamos que los combos ya estan cargados para conseguir el nombre del cliente.
        var rowClientes = this.dataFiltro.filter(x => x.idCliente == this.route.snapshot.params['idcliente']);
        if (rowClientes.length > 0) {
        datosFiltro.group[0].group.push(
            {
            columna: { id: 9, nombre: this.translateService.instant('cliente'), field: "nombreCliente", sqlfield: "po.nombreCliente", tipo: 'comboFlexible' },
            operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
            }
        );
        // Se carga el combo de clientes (esto limpia la seleccion)
        this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
        // Se selecciona el que queremos
        var cliente = { id: rowClientes[0].nombreCliente, nombre: rowClientes[0].nombreCliente }
        datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [cliente];
        }
        // PIEZA
        /**/this.idpieza = Number.parseInt(this.route.snapshot.params['idpieza']);
        var rowPiezas = this.dataFiltro.filter(x => x.idPieza == this.route.snapshot.params['idpieza']);
        if (rowPiezas.length > 0) {
        datosFiltro.group[0].group.push(
            {
            columna: { id: 10, nombre: this.translateService.instant('pieza'), field: "nombrePieza", sqlfield: "po.nombrePieza", tipo: 'comboFlexible' },
            operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
            }
        );
        // Se carga el combo de clientes (esto limpia la seleccion)
        this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
        // Se selecciona el que queremos
        var pieza = { id: rowPiezas[0].nombrePieza, nombre: rowPiezas[0].nombrePieza }
        datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [pieza];
        }
        // TERMINADO 
        // * Siempre va a tener valor 0 o 1 y se filtraria aunque no se quiera filtrar por eso...
        // * solo lo voy a filtrar si es 1
        /**/var terminados = Number.parseInt(this.route.snapshot.params['terminados']);
        if (this.route.snapshot.params['terminados'] == '1') {
        datosFiltro.group[0].group.push(
            {
            columna: { id: 14, nombre: this.translateService.instant('terminado'), field: "terminado", sqlfield: "po.operacionTerminada", tipo: 'check' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: true,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
            }
        );
        }
    }
    // Si hay filtrado
    if (datosFiltro.group[0].group.length > 0) {
        // Annadimos la ultima linea al filtro
        datosFiltro.group[0].group.push(
        {
            columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            tipoNoEditable: false,
            tipoNoVisible: false
        }
        );
        // Acutalizamos el filtro
        this.datosFiltro = datosFiltro;
        // Autofiltramos el informe
        this.onFilter();
    }
    }
    cargarFiltroURL_postConsulta() {
        // Esta funcion existe para cargar los filtros previos que requieren una consulta a la DB antes de poder ser aplicados
        // cargaremos el filtro en una variable para despues actualizar el filtro    
        var datosFiltro = {
            logic: { id: 1, nombre: this.translateService.instant('o') },
            group: [
            {
                logic: { id: 0, nombre: this.translateService.instant('y') },
                group: []
            }
            ]
        };
        // FECHAS (INICIO, FIN)
        /**/this.fini = this.route.snapshot.params['fini'];
        /**/this.ffin = this.route.snapshot.params['ffin'];
        if (this.route.snapshot.params['fini'] != '0' && this.route.snapshot.params['ffin'] != '0'
            && this.route.snapshot.params['fini'] != undefined && this.route.snapshot.params['ffin'] != undefined) {
            datosFiltro.group[0].group.push(
            {
                columna: { id: 2, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },
                operator: { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
                fechaIni: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['fini']),
                fechaFin: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['ffin']),
                mostrarCalendario: false,
                text: '',
                numberMin: 0,
                numberMax: 0,
                decimalformat: '0.000',
                decimalMin: 0.0,
                decimalMax: 0.0,
                check: false,
                combo: [{ id: 1, nombre: "" }],
                comboSelected: {},
                comboSelecteds: []
            }
            );
        }
        // OF
        var rowOFs = this.dataFiltro.filter(x => x.idOf == this.idof_prefiltro);
        if (rowOFs.length > 0) {
            datosFiltro.group[0].group.push(
            {
                columna: { id: 8, nombre: this.translateService.instant('of'), field: "nombreOf", sqlfield: "po.numeroOF", tipo: 'comboFlexible' },
                operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
                fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
                fechaFin: this.myFunctions.getDateNow(),
                mostrarCalendario: false,
                text: '',
                numberMin: 0,
                numberMax: 0,
                decimalformat: '0.000',
                decimalMin: 0.0,
                decimalMax: 0.0,
                check: false,
                combo: [{ id: 1, nombre: "" }],
                comboSelected: {},
                comboSelecteds: []
            }
            );
            // Se carga el combo de clientes (esto limpia la seleccion)
            this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
            // Se selecciona el que queremos
            var of = { id: rowOFs[0].nombreOf, nombre: rowOFs[0].nombreOf }
            datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [of];
        }
        this.idof_prefiltro = 0;
        // PIEZA
        var rowPiezas = this.dataFiltro.filter(x => x.idPieza == this.idpieza_prefiltro);
        if (rowPiezas.length > 0) {
            datosFiltro.group[0].group.push(
            {
                columna: { id: 10, nombre: this.translateService.instant('pieza'), field: "nombrePieza", sqlfield: "po.nombrePieza", tipo: 'comboFlexible' },
                operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
                fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
                fechaFin: this.myFunctions.getDateNow(),
                mostrarCalendario: false,
                text: '',
                numberMin: 0,
                numberMax: 0,
                decimalformat: '0.000',
                decimalMin: 0.0,
                decimalMax: 0.0,
                check: false,
                combo: [{ id: 1, nombre: "" }],
                comboSelected: {},
                comboSelecteds: []
            }
            );
            // Se carga el combo de clientes (esto limpia la seleccion)
            this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
            // Se selecciona el que queremos
            var pieza = { id: rowPiezas[0].nombrePieza, nombre: rowPiezas[0].nombrePieza }
            datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [pieza];
        }
        this.idpieza_prefiltro = 0;
        // N SERIE
        if (this.nSerie_prefiltro != "") {
            datosFiltro.group[0].group.push(
            {
                columna: { id: 11, nombre: this.translateService.instant('nserie'), field: "nSerie", sqlfield: "ho.nSerie", tipo: 'string' },
                operator: { id: 7, nombre: this.translateService.instant('igualQue'), dobleValor: false },
                fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
                fechaFin: this.myFunctions.getDateNow(),
                mostrarCalendario: false,
                text: this.nSerie_prefiltro,
                numberMin: 0,
                numberMax: 0,
                decimalformat: '0.000',
                decimalMin: 0.0,
                decimalMax: 0.0,
                check: false,
                combo: [{ id: 1, nombre: "" }],
                comboSelected: {},
                comboSelecteds: []
            }
            );
        }
        this.nSerie_prefiltro = "";
        // OPERACION
        var rowOperaciones = this.dataFiltro.filter(x => x.idOperacion == this.idOperacion_prefiltro);
        if (rowOperaciones.length > 0) {
            datosFiltro.group[0].group.push(
            {
                columna: { id: 13, nombre: this.translateService.instant('operacion'), field: "nombreOperacion", sqlfield: "po.nombreOperacion", tipo: 'comboFlexible' },
                operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
                fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
                fechaFin: this.myFunctions.getDateNow(),
                mostrarCalendario: false,
                text: '',
                numberMin: 0,
                numberMax: 0,
                decimalformat: '0.000',
                decimalMin: 0.0,
                decimalMax: 0.0,
                check: false,
                combo: [{ id: 1, nombre: "" }],
                comboSelected: {},
                comboSelecteds: []
            }
            );
            // Se carga el combo de clientes (esto limpia la seleccion)
            this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
            // Se selecciona el que queremos
            var operacion = { id: rowOperaciones[0].nombreOperacion, nombre: rowOperaciones[0].nombreOperacion }
            datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [operacion];
        }
        this.idOperacion_prefiltro = 0;
        // Si hay filtrado
        if (datosFiltro.group[0].group.length > 0) {
            // Annadimos la ultima linea al filtro
            datosFiltro.group[0].group.push(
            {
                columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
                operator: { id: 0, nombre: '' },
                fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
                fechaFin: this.myFunctions.getDateNow(),
                mostrarCalendario: false,
                text: '',
                numberMin: 0,
                numberMax: 0,
                decimalformat: '0.000',
                decimalMin: 0.0,
                decimalMax: 0.0,
                check: false,
                combo: [{ id: 1, nombre: "" }],
                comboSelected: {},
                comboSelecteds: [],
                tipoNoEditable: false,
                tipoNoVisible: false
            }
            );
            // Acutalizamos el filtro
            this.datosFiltro = datosFiltro;
            // Autofiltramos el informe
            this.onFilter();
        }
    }
    //#endregion
 
}