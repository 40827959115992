import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
  translateAggregateResults,
  DataResult,
  DataSourceRequestState
} from '@progress/kendo-data-query';

import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';

import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { query } from '@angular/animations';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/controlasignaciones`;
@Injectable()
export class ControlasignacionesService extends BehaviorSubject<any> {

  public loading = false;

  constructor(private http: HttpClient) {
    super(null);
  }

  public getData(data:string): Observable<any> {
    this.loading = true;
    var d = this.http
      .get(baseUrl+"/"+data);


    return d;
  }

  //public post(action: string = '', param?: string, data?: any): Observable<any> {
    //this.loading = true;
    //var headers, params;

    //var options = {
    //  method: 'POST',
    //  responseType: 'json',
    //  //body: JSON.stringify(''),
    //  headers: {
    //    'Content-Type': 'application/json; charset=UTF-8'
    //  }
    //};

    //if (action == undefined) {
    //  action = ''

    //}


    //return this.http
    //  .post(this.BASE_URL + "/" + action, data, options)
    //  .pipe(
    //    map(
    //      response => response['data']
    //    ),
    //    tap(() => this.loading = false)
    //  );
  //}

  //public query(): void {
  //  this.fetchData("","")
  //    .subscribe(x => super.next(x));
  //}
}
