import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Usuario } from '@app/_models';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NumericFilterCellComponent } from '@progress/kendo-angular-grid';
import { MyFunctions } from '../_helpers';

const baseUrl = `${environment.apiUrl}/mantenimientospredefinidos`;

@Injectable()
export class MantenimientosPredefinidosService {

  constructor(private http: HttpClient) { }

  public GetById(id: number): Observable<any> {

    return this.http.get(baseUrl + "/" + id);
  }

  public GetAll(action: string = '', param?: string, data?: ''): Observable<any> {

    var headers, params;

    if (param == undefined) {
      param = ''
    }

    return this.http.get(baseUrl);
  }

  public GetAll_TiposPerdidas_DAT(action: string = '', param?: string, data?: ''): Observable<any> {

    var headers, params;

    if (param == undefined) {
      param = ''
    }

    return this.http.get(baseUrl + "/tiposperdidas");
  }

  public GetAll_TiposMantenimientos_DAT(action: string = '', param?: string, data?: ''): Observable<any> {

    var headers, params;

    if (param == undefined) {
      param = ''
    }

    return this.http.get(baseUrl + "/tiposmantenimientos");
  }

  public GetAll_TiposFechaTiempoMantenimientos_DAT(action: string = '', param?: string, data?: ''): Observable<any> {

    var headers, params;

    if (param == undefined) {
      param = ''
    }

    return this.http.get(baseUrl + "/tiposfechatiempomantenimientos");
  }

  public GetAll_EstadosMantenimientos_DAT(action: string = '', param?: string, data?: ''): Observable<any> {

    var headers, params;

    if (param == undefined) {
      param = ''
    }

    return this.http.get(baseUrl + "/estadosmantenimientos");
  }

  insert(data, idTipoPerdida_DAT, idTipoMantenimiento, idTipoFechaTiempoMantenimiento, archivo, filename, isLink, fecha, sTiempo, tiempoEstimado) {
    return this.http.post<any>(baseUrl + "/crear", {
      Id: -1, 
      text: data.tareamantenimiento, 
      pdf: filename, 
      tipoperdida: idTipoPerdida_DAT, 
      tipo: idTipoMantenimiento, 
      tipoFechaTiempo: idTipoFechaTiempoMantenimiento,
      frecuenciaManteni: data.frecuencia, 
      dia: data.dias, 
      hora: fecha, 
      link: data.link, 
      nombre: data.nombre, 
      archivo: archivo, 
      islink: + isLink,
      tiempo: sTiempo,
      contarDesdeUltimo: data.contarDesdeUltimo,
      contarEjecucion: data.contarEjecucion,
      contarParada: data.contarParada,
      contarPreparacion: data.contarPreparacion,
      contarMantenimiento: data.contarMantenimiento,
      contarAlarma: data.contarAlarma,
      contarApagada: data.contarApagada,
      tiempoEstimado: tiempoEstimado,
      nombreRuta: data.nombreRuta,
      esDirectorio: data.esDirectorio,
      turnos: data.turnos

    }, { withCredentials: true });
  }

  update(data, idTipoPerdida_DAT, idTipoMantenimiento, idTipoFechaTiempoMantenimiento, archivo, filename, isLink, fecha, sTiempo, 
    tiempoEstimado, borrarDoc) {
    var id = Number(data.id);
    if(data.esDirectorio == null){
      data.esDirectorio = false;
    }

    return this.http.post<any>(baseUrl + "/editar", {
      Id: id, text: data.tareamantenimiento, pdf: filename, tipoperdida: idTipoPerdida_DAT,
      tipo: idTipoMantenimiento, tipoFechaTiempo: idTipoFechaTiempoMantenimiento, frecuenciaManteni: data.frecuencia, dia: data.dias, hora: fecha, link: data.link, nombre: data.nombre, archivo: archivo, islink: + isLink,
      tiempo: sTiempo,
      contarDesdeUltimo: data.contarDesdeUltimo,
      contarEjecucion: data.contarEjecucion,
      contarParada: data.contarParada,
      contarPreparacion: data.contarPreparacion,
      contarMantenimiento: data.contarMantenimiento,
      contarAlarma: data.contarAlarma,
      contarApagada: data.contarApagada,
      tiempoEstimado: tiempoEstimado,
      borrarDoc: borrarDoc,
      nombreRuta: data.nombreRuta,
      esDirectorio: data.esDirectorio,
      turnos: data.turnos
    }, { withCredentials: true });
  }

  delete(data?: any): Observable<any> {

    var formData: any = new FormData();
    formData.append("ids", data.ids);

    return this.http.post<JSON>(baseUrl + "/eliminar", formData, { withCredentials: true });
  }

  getTiempoRestanteSiguienteMantenimiento() {
    return this.http.post<JSON>(`${baseUrl}/getTiempoRestanteSiguienteMantenimiento`, { withCredentials: true });
  }

}
