import { Component, NgModule, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MenuService, UsuariosService, ConfiguracionService, AlertService, DiccionarioPerdidasService, SubidaFicherosService } from '@app/_services';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { MustMatch } from '@app/_helpers';
import { TipoPerdida_DAT } from '@app/_models';
import { first } from 'rxjs/operators';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { MyFunctions } from '@app/_helpers';
import { LogicalCellDirective } from '@progress/kendo-angular-grid';
//import { Item } from '@progress/kendo-angular-charts/dist/es2015/common/collection.service';

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html'
})

export class ConfiguracionComponent {

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  user = this.userService.userValue;
  form: FormGroup;
  submitted = false;

  emailsFabricantes: any = [];
  emailsOperarios: any = [];

  //Turnos
  horario: any;
  tieneTurnos: boolean = false;
  public meses: any;

  public alarmasRepercutenA: TipoPerdida_DAT[];
  public alarmasRepercutenASelected: TipoPerdida_DAT;
  public microparadaSinJustificarRepercutenA: TipoPerdida_DAT[];
  public microparadaSinJustificarRepercutenASelected: TipoPerdida_DAT;
  public paradaSinJustificarRepercutenA: TipoPerdida_DAT[];
  public paradaSinJustificarRepercutenASelected: TipoPerdida_DAT;
  alarmasDentroDeOperacion = false;
  public perdidaAlarmaPorDefectoSinOperacion: TipoPerdida_DAT[];
  public perdidaAlarmaPorDefectoSinOperacionSelected: TipoPerdida_DAT;
  public perdidaParadaPorDefectoSinOperacion: TipoPerdida_DAT[];
  public perdidaParadaPorDefectoSinOperacionSelected: TipoPerdida_DAT;
  public perdidaMicroparadaPorDefectoSinOperacion: TipoPerdida_DAT[];
  public perdidaMicroparadaPorDefectoSinOperacionSelected: TipoPerdida_DAT;
  public perdidaApagadaPorDefectoSinOperacion: TipoPerdida_DAT[];
  public perdidaApagadaPorDefectoSinOperacionSelected: TipoPerdida_DAT;
  public perdidaAlarmaPorDefectoConOperacion: TipoPerdida_DAT[];
  public perdidaAlarmaPorDefectoConOperacionSelected: TipoPerdida_DAT;
  public perdidaParadaPorDefectoConOperacion: TipoPerdida_DAT[];
  public perdidaParadaPorDefectoConOperacionSelected: TipoPerdida_DAT;
  public perdidaMicroparadaPorDefectoConOperacion: TipoPerdida_DAT[];
  public perdidaMicroparadaPorDefectoConOperacionSelected: TipoPerdida_DAT;
  public perdidaApagadaPorDefectoConOperacion: TipoPerdida_DAT[];
  public perdidaApagadaPorDefectoConOperacionSelected: TipoPerdida_DAT;
  public procesoIdentificadoSinTiempoEstimadoRepercuteOEE: TipoPerdida_DAT[];
  public procesoIdentificadoSinTiempoEstimadoRepercuteOEESelected: TipoPerdida_DAT;
  public ejecucionSinOperacionRepercuteOEE: TipoPerdida_DAT[];
  public ejecucionSinOperacionRepercuteOEESelected: TipoPerdida_DAT;
  continuarOperacionConParada = false;
  continuarOperacionConApagada = false;
  continuarOperacionConAlarma = false;
  verLote = false;
  verColada = false;
  verNSerie = false;
  public segundosParaAsignarParadasAOperacion: any;
  public segundosParaAsignarApagadasAOperacion: any;
  public segundosParaAsignarAlarmasAOperacion: any;
  public segundosCicloMaquinasTV: any;
  public tamanoTVSelected: any = 2;
  public disenoSelected: any = 1;
  public verHomeTV: any = [{ text: '1x1', id: 1 }, { text: '2x2', id: 2 }];
  public disenoTv: any = [];
  public tipoCalculoLote: any = [];
  public tipoCalculoLoteSelected: any = [];
  public seguimientoOFsUltimosMeses: any;
  public ejecucionSinOperarioFueraTurnoRepercuteOEE: boolean = false;
  public preparacionSinOperarioFueraTurnoRepercuteOEE: boolean = false;
  public paradaSinOperarioFueraTurnoRepercuteOEE: boolean = false;
  public microparadaSinOperarioFueraTurnoRepercuteOEE: boolean = false;
  public alarmaSinOperarioFueraTurnoRepercuteOEE: boolean = false;
  public mantenimientoSinOperarioFueraTurnoRepercuteOEE: boolean = false;
  public apagadaSinOperarioFueraTurnoRepercuteOEE: boolean = false;


  // ETIQUETAS
  public isQR: boolean = true;
  public isCB: boolean = false;
  public parameters: any;
  public param1Seleccionado: any = { valor: -1, nombre: this.translate.instant('tipoparametro') };
  public param2Seleccionado: any = { valor: -1, nombre: this.translate.instant('tipoparametro') };
  public param3Seleccionado: any = { valor: -1, nombre: this.translate.instant('tipoparametro') };
  public param4Seleccionado: any = { valor: -1, nombre: this.translate.instant('tipoparametro') };
  public param5Seleccionado: any = { valor: -1, nombre: this.translate.instant('tipoparametro') };
  public param6Seleccionado: any = { valor: -1, nombre: this.translate.instant('tipoparametro') };
  public parametrosArray = [
    this.param1Seleccionado,
    this.param2Seleccionado,
    this.param3Seleccionado,
    this.param4Seleccionado,
    this.param5Seleccionado,
    this.param6Seleccionado,
  ];
  public parametrosExtras = [];
  public parametrosLengthArray = [1, 1, 1, 1, 1, 1];
  public tieneSeparador: boolean = true;
  public esIndefinido = [false, false, false, false, false, false];

  //OEE
  public repercuteDAT: TipoPerdida_DAT[];
  public fueraTurnoEsOperacion = "";
  public quitarDisponibilidadDeOperacionLabel = "";
  public ejecucionFueraturnoEsOperacion = false;
  public ejecucionConOperacionRepercuteA = 2;
  public ejecucionSinOperacionRepercuteA = 2;
  public preparacionFueraturnoEsOperacion = false;
  public preparacionConOperacionRepercuteA = 2;
  public preparacionSinOperacionRepercuteA = 2;
  public paradaFueraturnoEsOperacion = false;
  public paradaConOperacionRepercuteA = 2;
  public paradaSinOperacionRepercuteA = 1;
  public microparadaFueraturnoEsOperacion = false;
  public microParadaConOperacionRepercuteA = 2;
  public microParadaSinOperacionRepercuteA = 1;
  public alarmaFueraturnoEsOperacion = false;
  public alarmaConOperacionRepercuteA = 2;
  public alarmaSinOperacionRepercuteA = 1;
  public mantenimientoFueraturnoEsOperacion = false;
  public mantenimientoConOperacionRepercuteA = 2;
  public mantenimientoSinOperacionRepercuteA = 1;
  public apagadaFueraturnoEsOperacion = false;
  public apagadaConOperacionRepercuteA = 2;
  public apagadaSinOperacionRepercuteA = 1;
  public quitarDisponibilidadDeOperacion = true;

  //GESTION DOCUMENTAL
  // public listItems: { [key: string]: Object }[] = 
  // [ { id: 1, text: 'Local' },
  //   { id: 2, text: 'FTP' }, 
  //   { id: 3, text: 'Azure' },
  //   { id: 4, text: 'One Drive' },
  //   { id: 5, text: 'Google Cloud' },
  //   { id: 6, text: 'Eris' }];
  
  public listItems: any[] = 
  [ { id: 1, text: 'Local' },
    { id: 2, text: 'FTP' }, 
    { id: 3, text: 'Azure' },
    { id: 4, text: 'One Drive' },
    { id: 5, text: 'Google Cloud' },
    { id: 6, text: 'Eris' }
  ];
  
  public valorSelected: number = 1;
  public selectedValue: any = this.listItems[0];
  

  
  public jsonDataAsString: string = "";

  constructor(
    private formBuilder: FormBuilder, private diccionarioPerdidasService: DiccionarioPerdidasService,
    private configuracionService: ConfiguracionService,
    private userService: UsuariosService,
    private route: ActivatedRoute,
    public router: Router,
    private menuService: MenuService,
    public translate: TranslateService,
    public alertService: AlertService,
    private myFunctions: MyFunctions,
    private subidaFicherosService: SubidaFicherosService) {

  }

  ngOnInit() {
    console.log("Selected value");
    console.log(this.selectedValue);
    this.menuService.titulo = this.translate.instant('configuracion').toUpperCase();

    this.meses = [
      { nombre: this.translate.instant("enero"), valor: 1 },
      { nombre: this.translate.instant("febrero"), valor: 2 },
      { nombre: this.translate.instant("marzo"), valor: 3 },
      { nombre: this.translate.instant("abril"), valor: 4 },
      { nombre: this.translate.instant("mayo"), valor: 5 },
      { nombre: this.translate.instant("junio"), valor: 6 },
      { nombre: this.translate.instant("julio"), valor: 7 },
      { nombre: this.translate.instant("agosto"), valor: 8 },
      { nombre: this.translate.instant("septiembre"), valor: 9 },
      { nombre: this.translate.instant("octubre"), valor: 10 },
      { nombre: this.translate.instant("noviembre"), valor: 11 },
      { nombre: this.translate.instant("diciembre"), valor: 12 },
    ];

    this.parameters = [
      { valor: 1, nombre: this.translate.instant('OF') },
      { valor: 2, nombre: this.translate.instant('cliente') },
      { valor: 3, nombre: this.translate.instant('plano') },
      { valor: 4, nombre: this.translate.instant('pieza') },
      { valor: 5, nombre: this.translate.instant('parte') },
      { valor: 6, nombre: this.translate.instant('refpieza') },
      { valor: 7, nombre: this.translate.instant('orden') },
      { valor: 8, nombre: this.translate.instant('secuencia') },
      { valor: 9, nombre: this.translate.instant('fase') },
      { valor: 10, nombre: this.translate.instant('vacio') },
      { valor: 11, nombre: this.translate.instant('pedircolada') },
      { valor: 12, nombre: this.translate.instant('numSerie') },
      { valor: 13, nombre: this.translate.instant('lote') },
      { valor: 14, nombre: this.translate.instant('ignorar') },
    ]

    this.horario = [
      {
        lunesMannanaInicio: new Date(2000, 1, 1, 0, 0),
        lunesMannanaFin: new Date(2000, 1, 1, 8, 0),
        lunesTardeInicio: new Date(2000, 1, 1, 8, 0),
        lunesTardeFin: new Date(2000, 1, 1, 16, 0),
        lunesNocheInicio: new Date(2000, 1, 1, 16, 0),
        lunesNocheFin: new Date(2000, 1, 1, 0, 0),

        martesMannanaInicio: new Date(2000, 1, 1, 0, 0),
        martesMannanaFin: new Date(2000, 1, 1, 8, 0),
        martesTardeInicio: new Date(2000, 1, 1, 8, 0),
        martesTardeFin: new Date(2000, 1, 1, 16, 0),
        martesNocheInicio: new Date(2000, 1, 1, 16, 0),
        martesNocheFin: new Date(2000, 1, 1, 0, 0),

        miercolesMannanaInicio: new Date(2000, 1, 1, 0, 0),
        miercolesMannanaFin: new Date(2000, 1, 1, 8, 0),
        miercolesTardeInicio: new Date(2000, 1, 1, 8, 0),
        miercolesTardeFin: new Date(2000, 1, 1, 16, 0),
        miercolesNocheInicio: new Date(2000, 1, 1, 16, 0),
        miercolesNocheFin: new Date(2000, 1, 1, 0, 0),

        juevesMannanaInicio: new Date(2000, 1, 1, 0, 0),
        juevesMannanaFin: new Date(2000, 1, 1, 8, 0),
        juevesTardeInicio: new Date(2000, 1, 1, 8, 0),
        juevesTardeFin: new Date(2000, 1, 1, 16, 0),
        juevesNocheInicio: new Date(2000, 1, 1, 16, 0),
        juevesNocheFin: new Date(2000, 1, 1, 0, 0),

        viernesMannanaInicio: new Date(2000, 1, 1, 0, 0),
        viernesMannanaFin: new Date(2000, 1, 1, 8, 0),
        viernesTardeInicio: new Date(2000, 1, 1, 8, 0),
        viernesTardeFin: new Date(2000, 1, 1, 16, 0),
        viernesNocheInicio: new Date(2000, 1, 1, 16, 0),
        viernesNocheFin: new Date(2000, 1, 1, 0, 0),

        sabadoMannanaInicio: new Date(2000, 1, 1, 0, 0),
        sabadoMannanaFin: new Date(2000, 1, 1, 8, 0),
        sabadoTardeInicio: new Date(2000, 1, 1, 8, 0),
        sabadoTardeFin: new Date(2000, 1, 1, 16, 0),
        sabadoNocheInicio: new Date(2000, 1, 1, 16, 0),
        sabadoNocheFin: new Date(2000, 1, 1, 0, 0),

        domingoMannanaInicio: new Date(2000, 1, 1, 0, 0),
        domingoMannanaFin: new Date(2000, 1, 1, 8, 0),
        domingoTardeInicio: new Date(2000, 1, 1, 8, 0),
        domingoTardeFin: new Date(2000, 1, 1, 16, 0),
        domingoNocheInicio: new Date(2000, 1, 1, 16, 0),
        domingoNocheFin: new Date(2000, 1, 1, 0, 0),
      }
    ];

    //Se reutiliza de diccionario p�rdidas, ya que devuelve el mismo dato
    this.diccionarioPerdidasService.GetAll_TiposPerdidas_DAT().pipe(first()).subscribe(
      (result: any) => {

        var an: any = result.data;
        an.forEach(f => {
          f.nombre = this.translate.instant(f.nombre)
        });

        this.alarmasRepercutenA = result.data;
        this.microparadaSinJustificarRepercutenA = result.data;
        this.paradaSinJustificarRepercutenA = result.data;
        this.perdidaAlarmaPorDefectoSinOperacion = result.data;
        this.perdidaParadaPorDefectoSinOperacion = result.data;
        this.perdidaMicroparadaPorDefectoSinOperacion = result.data;
        this.perdidaApagadaPorDefectoSinOperacion = result.data;
        this.perdidaAlarmaPorDefectoConOperacion = result.data;
        this.perdidaParadaPorDefectoConOperacion = result.data;
        this.perdidaMicroparadaPorDefectoConOperacion = result.data;
        this.perdidaApagadaPorDefectoConOperacion = result.data;
        this.procesoIdentificadoSinTiempoEstimadoRepercuteOEE = result.data;
        this.ejecucionSinOperacionRepercuteOEE = result.data;
        this.repercuteDAT = result.data;
      }
    );
    
    this.form = this.formBuilder.group({

      idDb: this.user.idDb,

      codigo: [0,],

      colorFeed1: ["#59B4D9",],
      colorFeed2: ["#3CB371",],
      colorFeed3: ["#F4E44D",],
      colorFeed4: ["#FC8C12",],
      colorFeed5: ["#D33737",],
      colorConsumo1: ["#59B4D9",],
      colorConsumo2: ["#3CB371",],
      colorConsumo3: ["#F4E44D",],
      colorConsumo4: ["#FC8C12",],

      porcentajeDisponibilidadContratoCompra: [0,],
      mesesPorInforme: [0,],
      disponibilidadMesInicio: [this.meses[0],],

      email: ["",],
      usuario: ["",],
      smtp: ["",],
      puerto: [0,],
      contrasena: [""],
      confContrasena: [""],

      emailBuhler: ["",],
      usuarioBuhler: ["",],
      smtpBuhler: ["",],
      puertoBuhler: [0,],
      contrasenaBuhler: [""],
      confContrasenaBuhler: [""],
      perdidaAlarmaPorDefecto: -1,
      perdidaParadaPorDefecto: -1,
      perdidaMicroparadaPorDefecto: -1,
      alarmasDentroDeOperacion: false,
      perdidaAlarmaPorDefectoSinOperacion: -1,
      perdidaParadaPorDefectoSinOperacion: -1,
      perdidaMicroparadaPorDefectoSinOperacion: -1,
      perdidaApagadaPorDefectoSinOperacion: -1,
      perdidaAlarmaPorDefectoConOperacion: -1,
      perdidaParadaPorDefectoConOperacion: -1,
      perdidaMicroparadaPorDefectoConOperacion: -1,
      perdidaApagadaPorDefectoConOperacion: -1,
      continuarOperacionConParada: false,
      continuarOperacionConApagada: false,
      continuarOperacionConAlarma: false,
      procesoIdentificadoSinTiempoEstimadoRepercuteOEE: -1,
      ejecucionSinOperacionRepercuteOEE: -1,
      segundosParaAsignarParadasAOperacion: 1,
      segundosParaAsignarApagadasAOperacion: 1,
      segundosParaAsignarAlarmasAOperacion: 1,
      segundosCicloMaquinasTV: 1,
      verLote: false,
      verColada: false,
      verNSerie: false,
      tamanoTVSelected: 2,
      disenoSelected: 1,
      seguimientoOFsUltimosMeses: 1,
      // etiquetas
      isQR: this.isQR,
      isCB: this.isCB,
      sep: '',
      param1: { valor: -1, nombre: this.translate.instant('tipoparametro') },
      param2: { valor: -1, nombre: this.translate.instant('tipoparametro') },
      param3: { valor: -1, nombre: this.translate.instant('tipoparametro') },
      param4: { valor: -1, nombre: this.translate.instant('tipoparametro') },
      param5: { valor: -1, nombre: this.translate.instant('tipoparametro') },
      param6: { valor: -1, nombre: this.translate.instant('tipoparametro') },
      param7: { valor: -1, nombre: this.translate.instant('tipoparametro') },
      tieneSeparador: this.tieneSeparador,
      param1Length: 1,
      param2Length: 1,
      param3Length: 1,
      param4Length: 1,
      param5Length: 1,
      param6Length: 1,
      param7Length: 1,
      param1Indefinido: this.esIndefinido[0],
      param2Indefinido: this.esIndefinido[1],
      param3Indefinido: this.esIndefinido[2],
      param4Indefinido: this.esIndefinido[3],
      param5Indefinido: this.esIndefinido[4],
      param6Indefinido: this.esIndefinido[5],
      param7Indefinido: this.esIndefinido[5],
      //OEE
      ejecucionEsOperacionSegundos: [this.myFunctions.secondsTo_HH_MM_SS(86400), Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],
      preparacionEsOperacionSegundos: [this.myFunctions.secondsTo_HH_MM_SS(86400), Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],
      paradaEsOperacionSegundos: [this.myFunctions.secondsTo_HH_MM_SS(7200), Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],
      microparadaEsOperacionSegundos: [this.myFunctions.secondsTo_HH_MM_SS(86400), Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],
      alarmaEsOperacionSegundos: [this.myFunctions.secondsTo_HH_MM_SS(600), Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],
      mantenimientoEsOperacionSegundos: [this.myFunctions.secondsTo_HH_MM_SS(0), Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],
      apagadaEsOperacionSegundos: [this.myFunctions.secondsTo_HH_MM_SS(300), Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],

      //Gestion documental
      comboGestionDocumental:'',
      servidorFTP:'',
      puertoFTP:'',
      usuarioFTP:'',
      passwordFTP:'',
      azureConnectionString:'',
      azureContainerName:'',
      cloudNombreBucket:'',
      ficheroCredenciales:'',

      tipoCalculoLote: this.tipoCalculoLoteSelected.id
    }, { validator: [MustMatch('contrasena', 'confContrasena'), MustMatch('contrasenaBuhler', 'confContrasenaBuhler')], });

    this.cargarDatos();

    this.fueraTurnoEsOperacion = this.translate.instant("Fuera de turno es operación");
    this.quitarDisponibilidadDeOperacionLabel = this.translate.instant("Quitar tiempos de disponibilidad a la operación");
  }

  cargarDatos() {

    this.configuracionService.get_emailFabricante().subscribe(result => {
      this.emailsFabricantes = result;
    });

    this.configuracionService.get_emailOperario().subscribe(result => {
      this.emailsOperarios = result;
    });

    this.configuracionService.get_configuracion_OEE().subscribe(result => {
      this.ejecucionFueraturnoEsOperacion = result[0].ejecucionFueraturnoEsOperacion;

      this.form.value.ejecucionEsOperacionSegundos = result[0].ejecucionEsOperacionSegundos;

      this.ejecucionConOperacionRepercuteA = result[0].ejecucionConOperacionRepercuteA;
      this.ejecucionSinOperacionRepercuteA = result[0].ejecucionSinOperacionRepercuteA;
      this.preparacionFueraturnoEsOperacion = result[0].preparacionFueraturnoEsOperacion;

      this.form.value.preparacionEsOperacionSegundos = result[0].preparacionEsOperacionSegundos;

      this.preparacionConOperacionRepercuteA = result[0].preparacionConOperacionRepercuteA;
      this.preparacionSinOperacionRepercuteA = result[0].preparacionSinOperacionRepercuteA;
      this.paradaFueraturnoEsOperacion = result[0].paradaFueraturnoEsOperacion;

      this.form.value.paradaEsOperacionSegundos = result[0].paradaEsOperacionSegundos;

      this.paradaConOperacionRepercuteA = result[0].paradaConOperacionRepercuteA;
      this.paradaSinOperacionRepercuteA = result[0].paradaSinOperacionRepercuteA;
      this.microparadaFueraturnoEsOperacion = result[0].microparadaFueraturnoEsOperacion;

      this.form.value.microparadaEsOperacionSegundos = result[0].microparadaEsOperacionSegundos;

      this.microParadaConOperacionRepercuteA = result[0].microParadaConOperacionRepercuteA;
      this.microParadaSinOperacionRepercuteA = result[0].microParadaSinOperacionRepercuteA;
      this.alarmaFueraturnoEsOperacion = result[0].alarmaFueraturnoEsOperacion;

      this.form.value.alarmaEsOperacionSegundos = result[0].alarmaEsOperacionSegundos;

      this.alarmaConOperacionRepercuteA = result[0].alarmaConOperacionRepercuteA;
      this.alarmaSinOperacionRepercuteA = result[0].alarmaSinOperacionRepercuteA;
      this.mantenimientoFueraturnoEsOperacion = result[0].mantenimientoFueraturnoEsOperacion;

      this.form.value.mantenimientoEsOperacionSegundos = result[0].mantenimientoEsOperacionSegundos;

      this.mantenimientoConOperacionRepercuteA = result[0].mantenimientoConOperacionRepercuteA;
      this.mantenimientoSinOperacionRepercuteA = result[0].mantenimientoSinOperacionRepercuteA;
      this.apagadaFueraturnoEsOperacion = result[0].apagadaFueraturnoEsOperacion;

      this.form.value.apagadaEsOperacionSegundos = result[0].apagadaEsOperacionSegundos;

      this.apagadaConOperacionRepercuteA = result[0].apagadaConOperacionRepercuteA;
      this.apagadaSinOperacionRepercuteA = result[0].apagadaSinOperacionRepercuteA;
      this.quitarDisponibilidadDeOperacion = result[0].quitarDisponibilidadDeOperacion;

      this.ejecucionSinOperarioFueraTurnoRepercuteOEE = result[0].ejecucionSinOperarioFueraTurnoRepercuteOEE;
      this.preparacionSinOperarioFueraTurnoRepercuteOEE = result[0].preparacionSinOperarioFueraTurnoRepercuteOEE;
      this.paradaSinOperarioFueraTurnoRepercuteOEE = result[0].paradaSinOperarioFueraTurnoRepercuteOEE;
      this.microparadaSinOperarioFueraTurnoRepercuteOEE = result[0].microparadaSinOperarioFueraTurnoRepercuteOEE;
      this.alarmaSinOperarioFueraTurnoRepercuteOEE = result[0].alarmaSinOperarioFueraTurnoRepercuteOEE;
      this.mantenimientoSinOperarioFueraTurnoRepercuteOEE = result[0].mantenimientoSinOperarioFueraTurnoRepercuteOEE;
      this.apagadaSinOperarioFueraTurnoRepercuteOEE = result[0].apagadaSinOperarioFueraTurnoRepercuteOEE;
          
      this.configuracionService.get_configuracion().subscribe(result => {

        var configuracion: any = result[0];

        var mes = this.meses.find(x => x.valor === configuracion.disponibilidadMesInicio);

        // preparar los parametros de las etiquetas
        var arrayParams = configuracion.params?.split(";");
        var arrayParamsLength = configuracion.paramsLength?.split(";");
        var indexOfIndefinido = arrayParamsLength.indexOf('0');
        if (indexOfIndefinido != -1)
          this.esIndefinido[indexOfIndefinido] = true;
        this.tieneSeparador = configuracion.tieneSep;
        this.isQR = configuracion.isQR;
        this.isCB = !configuracion.isQR;
        this.tipoCalculoLoteSelected = this.tipoCalculoLote.filter(f => f.id == configuracion.home_tipoCalculoLote)[0]

        this.form = this.formBuilder.group({

          idDb: this.user.idDb,

          codigo: [configuracion.codigo,],

          colorFeed1: [configuracion.colorFeed1,],
          colorFeed2: [configuracion.colorFeed2,],
          colorFeed3: [configuracion.colorFeed3,],
          colorFeed4: [configuracion.colorFeed4,],
          colorFeed5: [configuracion.colorFeed5,],
          colorConsumo1: [configuracion.colorConsumo1,],
          colorConsumo2: [configuracion.colorConsumo2,],
          colorConsumo3: [configuracion.colorConsumo3,],
          colorConsumo4: [configuracion.colorConsumo4,],

          porcentajeDisponibilidadContratoCompra: [configuracion.porcentajeDisponibilidadContratoCompra,],
          mesesPorInforme: [configuracion.mesesPorInforme,],
          disponibilidadMesInicio: [mes,],

          email: [configuracion.email,],
          usuario: [configuracion.usuario,],
          smtp: [configuracion.smtp,],
          puerto: [configuracion.puerto == null ? 0 : configuracion.puerto,],
          contrasena: [configuracion.contrasena],
          confContrasena: [configuracion.contrasena],

          emailBuhler: [configuracion.emailBuhler,],
          usuarioBuhler: [configuracion.usuarioBuhler,],
          smtpBuhler: [configuracion.smtpBuhler,],
          puertoBuhler: [configuracion.puertoBuhler == null ? 0 : configuracion.puertoBuhler,],
          contrasenaBuhler: [configuracion.contrasenaBuhler],
          confContrasenaBuhler: [configuracion.contrasenaBuhler],
          perdidaAlarmaPorDefecto: configuracion.perdidaAlarmaPorDefecto,
          perdidaParadaPorDefecto: configuracion.perdidaParadaPorDefecto,
          perdidaMicroparadaPorDefecto: configuracion.perdidaMicroparadaPorDefecto,
          alarmasDentroDeOperacion: configuracion.alarmasDentroDeOperacion == null ? false : configuracion.alarmasDentroDeOperacion,
          perdidaAlarmaPorDefectoSinOperacion: configuracion.perdidaAlarmaPorDefectoSinOperacion,
          perdidaParadaPorDefectoSinOperacion: configuracion.perdidaParadaPorDefectoSinOperacion,
          perdidaMicroparadaPorDefectoSinOperacion: configuracion.perdidaMicroparadaPorDefectoSinOperacion,
          perdidaApagadaPorDefectoSinOperacion: configuracion.perdidaApagadaPorDefectoSinOperacion,
          perdidaAlarmaPorDefectoConOperacion: configuracion.perdidaAlarmaPorDefectoConOperacion,
          perdidaParadaPorDefectoConOperacion: configuracion.perdidaParadaPorDefectoConOperacion,
          perdidaMicroparadaPorDefectoConOperacion: configuracion.perdidaMicroparadaPorDefectoConOperacion,
          perdidaApagadaPorDefectoConOperacion: configuracion.perdidaApagadaPorDefectoConOperacion,
          procesoIdentificadoSinTiempoEstimadoRepercuteOEE: configuracion.procesoIdentificadoSinTiempoEstimadoRepercuteOEE,
          ejecucionSinOperacionRepercuteOEE: configuracion.ejecucionSinOperacionRepercuteOEE,
          continuarOperacionConParada: configuracion.continuarOperacionConParada == null ? false : configuracion.continuarOperacionConParada,
          continuarOperacionConApagada: configuracion.continuarOperacionConApagada == null ? false : configuracion.continuarOperacionConApagada,
          continuarOperacionConAlarma: configuracion.continuarOperacionConAlarma == null ? false : configuracion.continuarOperacionConAlarma,
          verLote: configuracion.verLote == null ? false : configuracion.verLote,
          tamanoTVSelected: configuracion.visorTV,
          verColada: configuracion.verColada == null ? false : configuracion.verColada,
          verNSerie: configuracion.verNSerie == null ? false : configuracion.verNSerie,
          segundosParaAsignarParadasAOperacion: configuracion.segundosParaAsignarParadasAOperacion == null ? 1 : configuracion.segundosParaAsignarParadasAOperacion,
          segundosParaAsignarApagadasAOperacion: configuracion.segundosParaAsignarApagadasAOperacion == null ? 1 : configuracion.segundosParaAsignarApagadasAOperacion,
          segundosParaAsignarAlarmasAOperacion: configuracion.segundosParaAsignarAlarmasAOperacion == null ? 1 : configuracion.segundosParaAsignarAlarmasAOperacion,
          segundosCicloMaquinasTV: configuracion.segundosCicloMaquinasTV == null ? 1 : configuracion.segundosCicloMaquinasTV,
          disenoSelected: configuracion.idDisenoTv,
          seguimientoOFsUltimosMeses: configuracion.seguimientoOFsUltimosMeses,
          // etiquetas
          isQR: configuracion.isQR,
          isCB: !configuracion.isQR,
          sep: configuracion.sep == null ? '' : configuracion.sep,
          param1: { valor: -1, nombre: this.translate.instant('tipoparametro') },// configuracion.param1 == -1? {valor: -1, nombre: this.translate.instant('tipoparametro')}: this.parameters[configuracion.param1-1],
          param2: { valor: -1, nombre: this.translate.instant('tipoparametro') },// configuracion.param2 == -1? {valor: -1, nombre: this.translate.instant('tipoparametro')}: this.parameters[configuracion.param2-1],
          param3: { valor: -1, nombre: this.translate.instant('tipoparametro') },// configuracion.param3 == -1? {valor: -1, nombre: this.translate.instant('tipoparametro')}: this.parameters[configuracion.param3-1],
          param4: { valor: -1, nombre: this.translate.instant('tipoparametro') },// configuracion.param4 == -1? {valor: -1, nombre: this.translate.instant('tipoparametro')}: this.parameters[configuracion.param4-1],
          param5: { valor: -1, nombre: this.translate.instant('tipoparametro') },// configuracion.param5 == -1? {valor: -1, nombre: this.translate.instant('tipoparametro')}: this.parameters[configuracion.param5-1],
          param6: { valor: -1, nombre: this.translate.instant('tipoparametro') },// configuracion.param6 == -1? {valor: -1, nombre: this.translate.instant('tipoparametro')}: this.parameters[configuracion.param6-1]
          param7: { valor: -1, nombre: this.translate.instant('tipoparametro') },
          tieneSeparador: configuracion.tieneSep,
          param1Length: parseInt(arrayParamsLength[0]),
          param2Length: parseInt(arrayParamsLength[1]),
          param3Length: parseInt(arrayParamsLength[2]),
          param4Length: parseInt(arrayParamsLength[3]),
          param5Length: parseInt(arrayParamsLength[4]),
          param6Length: parseInt(arrayParamsLength[5]),
          param7Length: arrayParamsLength.length > 6 ? arrayParamsLength[6] : 1,
          param1Indefinido: this.esIndefinido[0],
          param2Indefinido: this.esIndefinido[1],
          param3Indefinido: this.esIndefinido[2],
          param4Indefinido: this.esIndefinido[3],
          param5Indefinido: this.esIndefinido[4],
          param6Indefinido: this.esIndefinido[5],
          param7Indefinido: this.esIndefinido.length > 6 ? this.esIndefinido[6] : 1,
          //OEE
          ejecucionEsOperacionSegundos: [this.myFunctions.secondsTo_HH_MM_SS(this.form.value.ejecucionEsOperacionSegundos), Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],
          preparacionEsOperacionSegundos: [this.myFunctions.secondsTo_HH_MM_SS(this.form.value.preparacionEsOperacionSegundos), Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],
          paradaEsOperacionSegundos: [this.myFunctions.secondsTo_HH_MM_SS(this.form.value.paradaEsOperacionSegundos), Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],
          microparadaEsOperacionSegundos: [this.myFunctions.secondsTo_HH_MM_SS(this.form.value.microparadaEsOperacionSegundos), Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],
          alarmaEsOperacionSegundos: [this.myFunctions.secondsTo_HH_MM_SS(this.form.value.alarmaEsOperacionSegundos), Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],
          mantenimientoEsOperacionSegundos: [this.myFunctions.secondsTo_HH_MM_SS(this.form.value.mantenimientoEsOperacionSegundos), Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],
          apagadaEsOperacionSegundos: [this.myFunctions.secondsTo_HH_MM_SS(this.form.value.apagadaEsOperacionSegundos), Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],

          tipoCalculoLote: this.tipoCalculoLoteSelected.id
        }, { validator: [MustMatch('contrasena', 'confContrasena'), MustMatch('contrasenaBuhler', 'confContrasenaBuhler')], });

        var index = 1;
        arrayParams.forEach(element => {
          if (element == -1)
            this.form.value["param" + index] = { valor: -1, nombre: this.translate.instant('tipoparametro') };
          else if (!isNaN(parseInt(element))) {
            this.form.value["param" + index] = this.parameters[element - 1];
            this.parametrosArray[index - 1] = this.parameters[element - 1];
          }
          index++;
        });

        index = 0;
        arrayParamsLength.forEach(element => {
          if (!isNaN(parseInt(element))) {
            this.parametrosLengthArray[index] = element;
            index++;
          }
        });

        this.alarmasRepercutenASelected = new TipoPerdida_DAT(configuracion.perdidaAlarmaPorDefecto == null ? 1 : configuracion.perdidaAlarmaPorDefecto, "");
        this.paradaSinJustificarRepercutenASelected = new TipoPerdida_DAT(configuracion.perdidaParadaPorDefecto == null ? 2 : configuracion.perdidaParadaPorDefecto, "");
        this.microparadaSinJustificarRepercutenASelected = new TipoPerdida_DAT(configuracion.perdidaMicroparadaPorDefecto == null ? 2 : configuracion.perdidaMicroparadaPorDefecto, "");
        this.perdidaAlarmaPorDefectoSinOperacionSelected = new TipoPerdida_DAT(configuracion.perdidaAlarmaPorDefectoSinOperacion == null ? 2 : configuracion.perdidaAlarmaPorDefectoSinOperacion, "");
        this.perdidaParadaPorDefectoSinOperacionSelected = new TipoPerdida_DAT(configuracion.perdidaParadaPorDefectoSinOperacion == null ? 2 : configuracion.perdidaParadaPorDefectoSinOperacion, "");
        this.perdidaMicroparadaPorDefectoSinOperacionSelected = new TipoPerdida_DAT(configuracion.perdidaMicroparadaPorDefectoSinOperacion == null ? 2 : configuracion.perdidaMicroparadaPorDefectoSinOperacion, "");
        this.perdidaApagadaPorDefectoSinOperacionSelected = new TipoPerdida_DAT(configuracion.perdidaApagadaPorDefectoSinOperacion == null ? 2 : configuracion.perdidaApagadaPorDefectoSinOperacion, "");
        this.perdidaAlarmaPorDefectoConOperacionSelected = new TipoPerdida_DAT(configuracion.perdidaAlarmaPorDefectoConOperacion == null ? 2 : configuracion.perdidaAlarmaPorDefectoConOperacion, "");
        this.perdidaParadaPorDefectoConOperacionSelected = new TipoPerdida_DAT(configuracion.perdidaParadaPorDefectoConOperacion == null ? 2 : configuracion.perdidaParadaPorDefectoConOperacion, "");
        this.perdidaMicroparadaPorDefectoConOperacionSelected = new TipoPerdida_DAT(configuracion.perdidaMicroparadaPorDefectoConOperacion == null ? 2 : configuracion.perdidaMicroparadaPorDefectoConOperacion, "");
        this.perdidaApagadaPorDefectoConOperacionSelected = new TipoPerdida_DAT(configuracion.perdidaApagadaPorDefectoConOperacion == null ? 2 : configuracion.perdidaApagadaPorDefectoConOperacion, "");
        this.procesoIdentificadoSinTiempoEstimadoRepercuteOEESelected = new TipoPerdida_DAT(configuracion.procesoIdentificadoSinTiempoEstimadoRepercuteOEE == null ? 2 : configuracion.procesoIdentificadoSinTiempoEstimadoRepercuteOEE, "");
        this.ejecucionSinOperacionRepercuteOEESelected = new TipoPerdida_DAT(configuracion.ejecucionSinOperacionRepercuteOEE == null ? 2 : configuracion.ejecucionSinOperacionRepercuteOEE, "");
        
        this.tamanoTVSelected = configuracion.visorTV;
        this.disenoSelected = configuracion.idDisenoTv;
        // etiquetas
        this.param1Seleccionado = arrayParams[0] == -1 ? { valor: -1, nombre: this.translate.instant('tipoparametro') } : this.parameters[arrayParams[0] - 1];
        this.param2Seleccionado = arrayParams[1] == -1 ? { valor: -1, nombre: this.translate.instant('tipoparametro') } : this.parameters[arrayParams[1] - 1];
        this.param3Seleccionado = arrayParams[2] == -1 ? { valor: -1, nombre: this.translate.instant('tipoparametro') } : this.parameters[arrayParams[2] - 1];
        this.param4Seleccionado = arrayParams[3] == -1 ? { valor: -1, nombre: this.translate.instant('tipoparametro') } : this.parameters[arrayParams[3] - 1];
        this.param5Seleccionado = arrayParams[4] == -1 ? { valor: -1, nombre: this.translate.instant('tipoparametro') } : this.parameters[arrayParams[4] - 1];
        this.param6Seleccionado = arrayParams[5] == -1 ? { valor: -1, nombre: this.translate.instant('tipoparametro') } : this.parameters[arrayParams[5] - 1];

      });
    
    });

    this.configuracionService.get_disenoTv_DAT().subscribe(diseno => {
      this.disenoTv = diseno;
      // var a;
      // this.disenoTv.forEach(disenos => {
      //   if (disenos.id == this.disenoSelected){
      //     a = disenos;
      //   }
      // });
      // this.disenoSelected = a;
    });

    this.configuracionService.getConfiguracionTurnos().subscribe((result: any)=> {
      if(result != null && result.length > 0){
        this.tieneTurnos = true;
        var turnos = result[0];
        this.horario[0].lunesMannanaInicio = new Date(2000, 1, 1, turnos.lunesMannanaInicio.split(":")[0], turnos.lunesMannanaInicio.split(":")[1], 0);
        this.horario[0].lunesMannanaFin = new Date(2000, 1, 1, turnos.lunesMannanaFin.split(":")[0], turnos.lunesMannanaFin.split(":")[1], 0);
        this.horario[0].lunesTardeInicio = new Date(2000, 1, 1, turnos.lunesTardeInicio.split(":")[0], turnos.lunesTardeInicio.split(":")[1], 0);
        this.horario[0].lunesTardeFin = new Date(2000, 1, 1, turnos.lunesTardeFin.split(":")[0], turnos.lunesTardeFin.split(":")[1], 0);
        this.horario[0].lunesNocheInicio = new Date(2000, 1, 1, turnos.lunesNocheInicio.split(":")[0], turnos.lunesNocheInicio.split(":")[1], 0);
        this.horario[0].lunesNocheFin = new Date(2000, 1, 1, turnos.lunesNocheFin.split(":")[0], turnos.lunesNocheFin.split(":")[1], 0);

        this.horario[0].martesMannanaInicio = new Date(2000, 1, 1, turnos.martesMannanaInicio.split(":")[0], turnos.martesMannanaInicio.split(":")[1], 0);
        this.horario[0].martesMannanaFin = new Date(2000, 1, 1, turnos.martesMannanaFin.split(":")[0], turnos.martesMannanaFin.split(":")[1], 0);
        this.horario[0].martesTardeInicio = new Date(2000, 1, 1, turnos.martesTardeInicio.split(":")[0], turnos.martesTardeInicio.split(":")[1], 0);
        this.horario[0].martesTardeFin = new Date(2000, 1, 1, turnos.martesTardeFin.split(":")[0], turnos.martesTardeFin.split(":")[1], 0);
        this.horario[0].martesNocheInicio = new Date(2000, 1, 1, turnos.martesNocheInicio.split(":")[0], turnos.martesNocheInicio.split(":")[1], 0);
        this.horario[0].martesNocheFin = new Date(2000, 1, 1, turnos.martesNocheFin.split(":")[0], turnos.martesNocheFin.split(":")[1], 0);

        this.horario[0].miercolesMannanaInicio = new Date(2000, 1, 1, turnos.miercolesMannanaInicio.split(":")[0], turnos.miercolesMannanaInicio.split(":")[1], 0);
        this.horario[0].miercolesMannanaFin = new Date(2000, 1, 1, turnos.miercolesMannanaFin.split(":")[0], turnos.miercolesMannanaFin.split(":")[1], 0);
        this.horario[0].miercolesTardeInicio = new Date(2000, 1, 1, turnos.miercolesTardeInicio.split(":")[0], turnos.miercolesTardeInicio.split(":")[1], 0);
        this.horario[0].miercolesTardeFin = new Date(2000, 1, 1, turnos.miercolesTardeFin.split(":")[0], turnos.miercolesTardeFin.split(":")[1], 0);
        this.horario[0].miercolesNocheInicio = new Date(2000, 1, 1, turnos.miercolesNocheInicio.split(":")[0], turnos.miercolesNocheInicio.split(":")[1], 0);
        this.horario[0].miercolesNocheFin = new Date(2000, 1, 1, turnos.miercolesNocheFin.split(":")[0], turnos.miercolesNocheFin.split(":")[1], 0);

        this.horario[0].juevesMannanaInicio = new Date(2000, 1, 1, turnos.juevesMannanaInicio.split(":")[0], turnos.juevesMannanaInicio.split(":")[1], 0);
        this.horario[0].juevesMannanaFin = new Date(2000, 1, 1, turnos.juevesMannanaFin.split(":")[0], turnos.juevesMannanaFin.split(":")[1], 0);
        this.horario[0].juevesTardeInicio = new Date(2000, 1, 1, turnos.juevesTardeInicio.split(":")[0], turnos.juevesTardeInicio.split(":")[1], 0);
        this.horario[0].juevesTardeFin = new Date(2000, 1, 1, turnos.juevesTardeFin.split(":")[0], turnos.juevesTardeFin.split(":")[1], 0);
        this.horario[0].juevesNocheInicio = new Date(2000, 1, 1, turnos.juevesNocheInicio.split(":")[0], turnos.juevesNocheInicio.split(":")[1], 0);
        this.horario[0].juevesNocheFin = new Date(2000, 1, 1, turnos.juevesNocheFin.split(":")[0], turnos.juevesNocheFin.split(":")[1], 0);

        this.horario[0].viernesMannanaInicio = new Date(2000, 1, 1, turnos.viernesMannanaInicio.split(":")[0], turnos.viernesMannanaInicio.split(":")[1], 0);
        this.horario[0].viernesMannanaFin = new Date(2000, 1, 1, turnos.viernesMannanaFin.split(":")[0], turnos.viernesMannanaFin.split(":")[1], 0);
        this.horario[0].viernesTardeInicio = new Date(2000, 1, 1, turnos.viernesTardeInicio.split(":")[0], turnos.viernesTardeInicio.split(":")[1], 0);
        this.horario[0].viernesTardeFin = new Date(2000, 1, 1, turnos.viernesTardeFin.split(":")[0], turnos.viernesTardeFin.split(":")[1], 0);
        this.horario[0].viernesNocheInicio = new Date(2000, 1, 1, turnos.viernesNocheInicio.split(":")[0], turnos.viernesNocheInicio.split(":")[1], 0);
        this.horario[0].viernesNocheFin = new Date(2000, 1, 1, turnos.viernesNocheFin.split(":")[0], turnos.viernesNocheFin.split(":")[1], 0);

        this.horario[0].sabadoMannanaInicio = new Date(2000, 1, 1, turnos.sabadoMannanaInicio.split(":")[0], turnos.sabadoMannanaInicio.split(":")[1], 0);
        this.horario[0].sabadoMannanaFin = new Date(2000, 1, 1, turnos.sabadoMannanaFin.split(":")[0], turnos.sabadoMannanaFin.split(":")[1], 0);
        this.horario[0].sabadoTardeInicio = new Date(2000, 1, 1, turnos.sabadoTardeInicio.split(":")[0], turnos.sabadoTardeInicio.split(":")[1], 0);
        this.horario[0].sabadoTardeFin = new Date(2000, 1, 1, turnos.sabadoTardeFin.split(":")[0], turnos.sabadoTardeFin.split(":")[1], 0);
        this.horario[0].sabadoNocheInicio = new Date(2000, 1, 1, turnos.sabadoNocheInicio.split(":")[0], turnos.sabadoNocheInicio.split(":")[1], 0);
        this.horario[0].sabadoNocheFin = new Date(2000, 1, 1, turnos.sabadoNocheFin.split(":")[0], turnos.sabadoNocheFin.split(":")[1], 0);

        this.horario[0].domingoMannanaInicio = new Date(2000, 1, 1, turnos.domingoMannanaInicio.split(":")[0], turnos.domingoMannanaInicio.split(":")[1], 0);
        this.horario[0].domingoMannanaFin = new Date(2000, 1, 1, turnos.domingoMannanaFin.split(":")[0], turnos.domingoMannanaFin.split(":")[1], 0);
        this.horario[0].domingoTardeInicio = new Date(2000, 1, 1, turnos.domingoTardeInicio.split(":")[0], turnos.domingoTardeInicio.split(":")[1], 0);
        this.horario[0].domingoTardeFin = new Date(2000, 1, 1, turnos.domingoTardeFin.split(":")[0], turnos.domingoTardeFin.split(":")[1], 0);
        this.horario[0].domingoNocheInicio = new Date(2000, 1, 1, turnos.domingoNocheInicio.split(":")[0], turnos.domingoNocheInicio.split(":")[1], 0);
        this.horario[0].domingoNocheFin = new Date(2000, 1, 1, turnos.domingoNocheFin.split(":")[0], turnos.domingoNocheFin.split(":")[1], 0);
      }
    })
    
    this.tipoCalculoLote = [
      {id: 1, nombre: 'restante'},
      {id: 2, nombre: 'acumulado'},
      {id: 3, nombre: 'acumuladoYPreparacion'},
    ];
    this.tipoCalculoLoteSelected = this.tipoCalculoLoteSelected[0];

    this.configuracionService.get_configuracion_gestion_documental().subscribe(result => {
      if(result != null){
        var config: any = result[0];
        console.log("Config: ");
        console.log(config);
        
        this.valorSelected = config.idTipo;
        this.selectedValue = this.listItems.filter(f => f.id == config.idTipo)[0];
        //Inicializar valores 
        this.form.controls['cloudNombreBucket'].setValue(config.gCnombreBucket);
        //this.form.value.cloudNombreBucket = config.GCnombreBucket;
        this.form.controls['servidorFTP'].setValue(config.ftPservidor);
        this.form.controls['usuarioFTP'].setValue(config.ftPnombreUsuario);
        this.form.controls['passwordFTP'].setValue(config.ftPpassword);
        this.form.controls['azureConnectionString'].setValue(config.aZcadConexion);
        this.form.controls['azureContainerName'].setValue(config.aZcontenedor);
      }
      

      
      switch(this.valorSelected){
        case 5: {
          this.form.value["cloudNombreBucket"] = config.GCnombreBucket;
          break;
        }
        case 2: {
          this.form.value["servidorFTP"] = config.FTPservidor;
          this.form.value["usuarioFTP"] = config.FTPusuario;
          this.form.value["passwordFTP"] = config.passwordFTP;
          break;
        }
        case 3: {
          this.form.value["azureConnectionString"] = config.AZcadConexion;
          this.form.value["azureContainerName"] = config.AZcontenedor;
          break;
        }
      }
      console.log("Valor seleccionado desde DB");
      console.log(this.selectedValue);
      
    })

  }

  onClickAnnadirEmailFabricante() {
    this.emailsFabricantes.push({ email: "" });
  }

  onClickEliminarEmailFabricante(email) {
    this.emailsFabricantes.splice(this.emailsFabricantes.indexOf(email), 1);
  }

  onClickAnnadirEmailOperario() {
    this.emailsOperarios.push({ email: "" });
  }

  onClickEliminarEmailOperario(email) {
    this.emailsOperarios.splice(this.emailsOperarios.indexOf(email), 1);
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {

    this.submitted = true;
    if (this.form.invalid) return;

    var r1, r2, r3, r4, r5: any = false;

    this.form.value["perdidaAlarmaPorDefecto"] = this.alarmasRepercutenASelected.id;
    this.form.value["perdidaParadaPorDefecto"] = this.paradaSinJustificarRepercutenASelected.id;
    this.form.value["perdidaMicroparadaPorDefecto"] = this.microparadaSinJustificarRepercutenASelected.id;
    this.form.value["alarmasDentroDeOperacion"] = this.alarmasDentroDeOperacion;
    this.form.value["perdidaAlarmaPorDefectoSinOperacion"] = this.perdidaAlarmaPorDefectoSinOperacionSelected.id;
    this.form.value["perdidaParadaPorDefectoSinOperacion"] = this.perdidaParadaPorDefectoSinOperacionSelected.id;
    this.form.value["perdidaMicroparadaPorDefectoSinOperacion"] = this.perdidaMicroparadaPorDefectoSinOperacionSelected.id;
    this.form.value["perdidaApagadaPorDefectoSinOperacion"] = this.perdidaApagadaPorDefectoSinOperacionSelected.id;
    this.form.value["perdidaAlarmaPorDefectoConOperacion"] = this.perdidaAlarmaPorDefectoConOperacionSelected.id;
    this.form.value["perdidaParadaPorDefectoConOperacion"] = this.perdidaParadaPorDefectoConOperacionSelected.id;
    this.form.value["perdidaMicroparadaPorDefectoConOperacion"] = this.perdidaMicroparadaPorDefectoConOperacionSelected.id;
    this.form.value["perdidaApagadaPorDefectoConOperacion"] = this.perdidaApagadaPorDefectoConOperacionSelected.id;
    this.form.value["procesoIdentificadoSinTiempoEstimadoRepercuteOEE"] = this.procesoIdentificadoSinTiempoEstimadoRepercuteOEESelected.id;
    this.form.value["ejecucionSinOperacionRepercuteOEE"] = this.ejecucionSinOperacionRepercuteOEESelected.id;
    this.form.value["continuarOperacionConParada"] = this.continuarOperacionConParada;
    this.form.value["continuarOperacionConApagada"] = this.continuarOperacionConApagada;
    this.form.value["continuarOperacionConAlarma"] = this.continuarOperacionConAlarma;
    if (this.tamanoTVSelected.id != undefined) {
      this.form.value["tamanoTVSelected"] = this.tamanoTVSelected.id;
    } else {
      this.form.value["tamanoTVSelected"] = this.tamanoTVSelected;
    }
    if (this.disenoSelected.id != undefined) {
      this.form.value["disenoSelected"] = this.disenoSelected.id;
    } else {
      this.form.value["disenoSelected"] = this.disenoSelected;
    }
    if (this.tipoCalculoLoteSelected.id != undefined) {
      this.form.value["tipoCalculoLote"] = this.tipoCalculoLoteSelected.id;
    } else {
      this.form.value["tipoCalculoLote"] = 1;
    }


    // etiquetas
    var paramsString = '';
    this.parametrosArray.forEach(element => {
      if (element != undefined)
        paramsString += element.valor + ";";
    });

    var paramsLengthString = '';
    // comprobar que no hay ningun 0 y si hay convertirlo en 1
    var index0 = this.parametrosLengthArray.indexOf(0);
    if (index0 != -1) this.parametrosLengthArray[index0] = 1

    // convertir el parametro indicado con largura 0
    var indexOfIndefinido = this.esIndefinido.indexOf(true);
    this.parametrosLengthArray[indexOfIndefinido] = 0;
    this.parametrosLengthArray.forEach(element => {
      if (element != undefined)
        paramsLengthString += element + ";";
    });

    this.form.value["isQR"] = this.isQR;
    this.form.value["isCB"] = this.isCB;
    this.form.value["parametros"] = paramsString;
    this.form.value["paramsLength"] = paramsLengthString;
    this.form.value["tieneSep"] = this.tieneSeparador;

    //OEE
    var variablesOEE = {
      ejecucionFueraturnoEsOperacion: this.ejecucionFueraturnoEsOperacion,

      ejecucionEsOperacionSegundos: this.HMSToSeconds(this.form.value.ejecucionEsOperacionSegundos),

      ejecucionConOperacionRepercuteA: this.ejecucionConOperacionRepercuteA,
      ejecucionSinOperacionRepercuteA: this.ejecucionSinOperacionRepercuteA,
      preparacionFueraturnoEsOperacion: this.preparacionFueraturnoEsOperacion,

      preparacionEsOperacionSegundos: this.HMSToSeconds(this.form.value.preparacionEsOperacionSegundos),

      preparacionConOperacionRepercuteA: this.preparacionConOperacionRepercuteA,
      preparacionSinOperacionRepercuteA: this.preparacionSinOperacionRepercuteA,
      paradaFueraturnoEsOperacion: this.paradaFueraturnoEsOperacion,

      paradaEsOperacionSegundos: this.HMSToSeconds(this.form.value.paradaEsOperacionSegundos),

      paradaConOperacionRepercuteA: this.paradaConOperacionRepercuteA,
      paradaSinOperacionRepercuteA: this.paradaSinOperacionRepercuteA,
      microparadaFueraturnoEsOperacion: this.microparadaFueraturnoEsOperacion,

      microparadaEsOperacionSegundos: this.HMSToSeconds(this.form.value.microparadaEsOperacionSegundos),

      microParadaConOperacionRepercuteA: this.microParadaConOperacionRepercuteA,
      microParadaSinOperacionRepercuteA: this.microParadaSinOperacionRepercuteA,
      alarmaFueraturnoEsOperacion: this.alarmaFueraturnoEsOperacion,

      alarmaEsOperacionSegundos: this.HMSToSeconds(this.form.value.alarmaEsOperacionSegundos),

      alarmaConOperacionRepercuteA: this.alarmaConOperacionRepercuteA,
      alarmaSinOperacionRepercuteA: this.alarmaSinOperacionRepercuteA,
      mantenimientoFueraturnoEsOperacion: this.mantenimientoFueraturnoEsOperacion,

      mantenimientoEsOperacionSegundos: this.HMSToSeconds(this.form.value.mantenimientoEsOperacionSegundos),

      mantenimientoConOperacionRepercuteA: this.mantenimientoConOperacionRepercuteA,
      mantenimientoSinOperacionRepercuteA: this.mantenimientoSinOperacionRepercuteA,
      apagadaFueraturnoEsOperacion: this.apagadaFueraturnoEsOperacion,

      apagadaEsOperacionSegundos: this.HMSToSeconds(this.form.value.apagadaEsOperacionSegundos),

      apagadaConOperacionRepercuteA: this.apagadaConOperacionRepercuteA,
      apagadaSinOperacionRepercuteA: this.apagadaSinOperacionRepercuteA,
      quitarDisponibilidadDeOperacion: this.quitarDisponibilidadDeOperacion,

      ejecucionSinOperarioFueraTurnoRepercuteOEE: this.ejecucionSinOperarioFueraTurnoRepercuteOEE,
      preparacionSinOperarioFueraTurnoRepercuteOEE: this.preparacionSinOperarioFueraTurnoRepercuteOEE,
      paradaSinOperarioFueraTurnoRepercuteOEE: this.paradaSinOperarioFueraTurnoRepercuteOEE,
      microparadaSinOperarioFueraTurnoRepercuteOEE: this.microparadaSinOperarioFueraTurnoRepercuteOEE,
      alarmaSinOperarioFueraTurnoRepercuteOEE: this.alarmaSinOperarioFueraTurnoRepercuteOEE,
      mantenimientoSinOperarioFueraTurnoRepercuteOEE: this.mantenimientoSinOperarioFueraTurnoRepercuteOEE,
      apagadaSinOperarioFueraTurnoRepercuteOEE: this.apagadaSinOperarioFueraTurnoRepercuteOEE
    }

    console.log("_______________________________")
    console.log(variablesOEE)
    console.log(this.ejecucionSinOperarioFueraTurnoRepercuteOEE)
    //GUARDAR CONFIGURACION
    this.configuracionService.update_configuracion(this.form).subscribe(result => {
      var r: any = result;
      if (r >= 1) r1 = true;
      if (r1 && r2 && r3 && r4 && r5) {
        this.alertService.success(this.translate.instant('editadocorrectamente'), { keepAfterRouteChange: true });
        //this.cargarDatos();
      }
    });

    //GUARDAR EMAILS FABRICANTES
    var dtF: any = [];
    this.emailsFabricantes.forEach(function (e) {
      if (e.email != "") dtF.push({ email: e.email });
    });
    this.configuracionService.Update_EmailsFabricantes(dtF).subscribe(result => {
      r2 = result;
      if (r1 && r2 && r3 && r4 && r5) {
        this.alertService.success(this.translate.instant('editadocorrectamente'), { keepAfterRouteChange: true });
        //this.cargarDatos();
      }
    });

    //GUARDAR EMAILS OPERARIOS
    var dtO: any = [];
    this.emailsOperarios.forEach(function (e) {
      if (e.email != "") dtO.push({ email: e.email });
    });
    this.configuracionService.Update_EmailsOperarios(dtO).subscribe(result => {
      r3 = result;
      if (r1 && r2 && r3 && r4 && r5) {
        this.alertService.success(this.translate.instant('editadocorrectamente'), { keepAfterRouteChange: true });
        //this.cargarDatos();
      }
    });

    //GUARDAR OEE
    this.configuracionService.update_configuracion_OEE(variablesOEE).subscribe(result => {
      r4 = result;
      if (r1 && r2 && r3 && r4 && r5) {
        this.alertService.success(this.translate.instant('editadocorrectamente'), { keepAfterRouteChange: true });
        //this.cargarDatos();
      }
    });

    //GUARDAR TURNOS
    var lunesMannanaInicio = this.myFunctions.dateToHHmm(this.horario[0].lunesMannanaInicio);
    var lunesMannanaFin = this.myFunctions.dateToHHmm(this.horario[0].lunesMannanaFin);
    var lunesTardeInicio = this.myFunctions.dateToHHmm(this.horario[0].lunesTardeInicio);
    var lunesTardeFin = this.myFunctions.dateToHHmm(this.horario[0].lunesTardeFin);
    var lunesNocheInicio = this.myFunctions.dateToHHmm(this.horario[0].lunesNocheInicio);
    var lunesNocheFin = this.myFunctions.dateToHHmm(this.horario[0].lunesNocheFin);
    var martesMannanaInicio = this.myFunctions.dateToHHmm(this.horario[0].martesMannanaInicio);
    var martesMannanaFin = this.myFunctions.dateToHHmm(this.horario[0].martesMannanaFin);
    var martesTardeInicio = this.myFunctions.dateToHHmm(this.horario[0].martesTardeInicio);
    var martesTardeFin = this.myFunctions.dateToHHmm(this.horario[0].martesTardeFin);
    var martesNocheInicio = this.myFunctions.dateToHHmm(this.horario[0].martesNocheInicio);
    var martesNocheFin = this.myFunctions.dateToHHmm(this.horario[0].martesNocheFin);
    var miercolesMannanaInicio = this.myFunctions.dateToHHmm(this.horario[0].miercolesMannanaInicio);
    var miercolesMannanaFin = this.myFunctions.dateToHHmm(this.horario[0].miercolesMannanaFin);
    var miercolesTardeInicio = this.myFunctions.dateToHHmm(this.horario[0].miercolesTardeInicio);
    var miercolesTardeFin = this.myFunctions.dateToHHmm(this.horario[0].miercolesTardeFin);
    var miercolesNocheInicio = this.myFunctions.dateToHHmm(this.horario[0].miercolesNocheInicio);
    var miercolesNocheFin = this.myFunctions.dateToHHmm(this.horario[0].miercolesNocheFin);
    var juevesMannanaInicio = this.myFunctions.dateToHHmm(this.horario[0].juevesMannanaInicio);
    var juevesMannanaFin = this.myFunctions.dateToHHmm(this.horario[0].juevesMannanaFin);
    var juevesTardeInicio = this.myFunctions.dateToHHmm(this.horario[0].juevesTardeInicio);
    var juevesTardeFin = this.myFunctions.dateToHHmm(this.horario[0].juevesTardeFin);
    var juevesNocheInicio = this.myFunctions.dateToHHmm(this.horario[0].juevesNocheInicio);
    var juevesNocheFin = this.myFunctions.dateToHHmm(this.horario[0].juevesNocheFin);
    var viernesMannanaInicio = this.myFunctions.dateToHHmm(this.horario[0].viernesMannanaInicio);
    var viernesMannanaFin = this.myFunctions.dateToHHmm(this.horario[0].viernesMannanaFin);
    var viernesTardeInicio = this.myFunctions.dateToHHmm(this.horario[0].viernesTardeInicio);
    var viernesTardeFin = this.myFunctions.dateToHHmm(this.horario[0].viernesTardeFin);
    var viernesNocheInicio = this.myFunctions.dateToHHmm(this.horario[0].viernesNocheInicio);
    var viernesNocheFin = this.myFunctions.dateToHHmm(this.horario[0].viernesNocheFin);
    var sabadoMannanaInicio = this.myFunctions.dateToHHmm(this.horario[0].sabadoMannanaInicio);
    var sabadoMannanaFin = this.myFunctions.dateToHHmm(this.horario[0].sabadoMannanaFin);
    var sabadoTardeInicio = this.myFunctions.dateToHHmm(this.horario[0].sabadoTardeInicio);
    var sabadoTardeFin = this.myFunctions.dateToHHmm(this.horario[0].sabadoTardeFin);
    var sabadoNocheInicio = this.myFunctions.dateToHHmm(this.horario[0].sabadoNocheInicio);
    var sabadoNocheFin = this.myFunctions.dateToHHmm(this.horario[0].sabadoNocheFin);
    var domingoMannanaInicio = this.myFunctions.dateToHHmm(this.horario[0].domingoMannanaInicio);
    var domingoMannanaFin = this.myFunctions.dateToHHmm(this.horario[0].domingoMannanaFin);
    var domingoTardeInicio = this.myFunctions.dateToHHmm(this.horario[0].domingoTardeInicio);
    var domingoTardeFin = this.myFunctions.dateToHHmm(this.horario[0].domingoTardeFin);
    var domingoNocheInicio = this.myFunctions.dateToHHmm(this.horario[0].domingoNocheInicio);
    var domingoNocheFin = this.myFunctions.dateToHHmm(this.horario[0].domingoNocheFin)
    if(this.tieneTurnos){
      this.configuracionService.updateConfiguracionTurnos(
        lunesMannanaInicio, 
        lunesMannanaFin, 
        lunesTardeInicio, 
        lunesTardeFin, 
        lunesNocheInicio, 
        lunesNocheFin, 
        martesMannanaInicio,
        martesMannanaFin,
        martesTardeInicio, 
        martesTardeFin,
        martesNocheInicio, 
        martesNocheFin,
        miercolesMannanaInicio,
        miercolesMannanaFin,
        miercolesTardeInicio,
        miercolesTardeFin,
        miercolesNocheInicio,
        miercolesNocheFin, 
        juevesMannanaInicio, 
        juevesMannanaFin, 
        juevesTardeInicio,
        juevesTardeFin,
        juevesNocheInicio, 
        juevesNocheFin, 
        viernesMannanaInicio,
        viernesMannanaFin, 
        viernesTardeInicio, 
        viernesTardeFin, 
        viernesNocheInicio, 
        viernesNocheFin, 
        sabadoMannanaInicio,
        sabadoMannanaFin, 
        sabadoTardeInicio, 
        sabadoTardeFin, 
        sabadoNocheInicio, 
        sabadoNocheFin, 
        domingoMannanaInicio,
        domingoMannanaFin, 
        domingoTardeInicio, 
        domingoTardeFin, 
        domingoNocheInicio, 
        domingoNocheFin
      ).subscribe((result: any) => {
        if(result >= 1) r5 = true;
        if (r1 && r2 && r3 && r4 && r5) {
          this.alertService.success(this.translate.instant('editadocorrectamente'), { keepAfterRouteChange: true });
          //this.cargarDatos();
        }
      })
    }else{
      this.configuracionService.addConfiguracionTurnos(
        lunesMannanaInicio, 
        lunesMannanaFin, 
        lunesTardeInicio, 
        lunesTardeFin, 
        lunesNocheInicio, 
        lunesNocheFin, 
        martesMannanaInicio,
        martesMannanaFin,
        martesTardeInicio, 
        martesTardeFin,
        martesNocheInicio, 
        martesNocheFin,
        miercolesMannanaInicio,
        miercolesMannanaFin,
        miercolesTardeInicio,
        miercolesTardeFin,
        miercolesNocheInicio,
        miercolesNocheFin, 
        juevesMannanaInicio, 
        juevesMannanaFin, 
        juevesTardeInicio,
        juevesTardeFin,
        juevesNocheInicio, 
        juevesNocheFin, 
        viernesMannanaInicio,
        viernesMannanaFin, 
        viernesTardeInicio, 
        viernesTardeFin, 
        viernesNocheInicio, 
        viernesNocheFin, 
        sabadoMannanaInicio,
        sabadoMannanaFin, 
        sabadoTardeInicio, 
        sabadoTardeFin, 
        sabadoNocheInicio, 
        sabadoNocheFin, 
        domingoMannanaInicio,
        domingoMannanaFin, 
        domingoTardeInicio, 
        domingoTardeFin, 
        domingoNocheInicio, 
        domingoNocheFin
      ).subscribe((result: any) => {
        if(result >= 1) r5 = true;
        if (r1 && r2 && r3 && r4 && r5) {
          this.alertService.success(this.translate.instant('editadocorrectamente'), { keepAfterRouteChange: true });
          //this.cargarDatos();
        }
      })
    }

    //this.guardarConfiguracionGestionDocumental();

  }

  // TABS
  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth && !element.classList.contains('celda-tooltip-externo')) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  // ETIQUETAS

  // esta funcion controla que no esten activados a la vez el qr y el codigo de barras
  QRorCD() {
    this.isCB = !this.isCB;
    this.isQR = !this.isQR;

    this.form.value.isQR = this.isQR;
    this.form.value.isCB = this.isCB;
  }

  annadirEliminado(i, isExtra = false) {
    if (!isExtra) {
      switch (i) {
        case 1:
          this.param1Seleccionado = { valor: -1, nombre: this.translate.instant('tipoparametro') };
          this.form.value.param1 = { valor: -1, nombre: this.translate.instant('tipoparametro') };
          this.form.value.param1Length = 1;
          break;

        case 2:
          this.param2Seleccionado = { valor: -1, nombre: this.translate.instant('tipoparametro') };
          this.form.value.param2 = { valor: -1, nombre: this.translate.instant('tipoparametro') };
          this.form.value.param2Length = 1;
          break;

        case 3:
          this.param3Seleccionado = { valor: -1, nombre: this.translate.instant('tipoparametro') };
          this.form.value.param3 = { valor: -1, nombre: this.translate.instant('tipoparametro') };
          this.form.value.param3Length = 1;
          break;

        case 4:
          this.param4Seleccionado = { valor: -1, nombre: this.translate.instant('tipoparametro') };
          this.form.value.param4 = { valor: -1, nombre: this.translate.instant('tipoparametro') };
          this.form.value.param4Length = 1;
          break;

        case 5:
          this.param5Seleccionado = { valor: -1, nombre: this.translate.instant('tipoparametro') };
          this.form.value.param5 = { valor: -1, nombre: this.translate.instant('tipoparametro') };
          this.form.value.param5Length = 1;
          break;

        case 6:
          this.param6Seleccionado = { valor: -1, nombre: this.translate.instant('tipoparametro') };
          this.form.value.param6 = { valor: -1, nombre: this.translate.instant('tipoparametro') };
          this.form.value.param6Length = 1;
          break;

        default:
          break;
      }

      this.parametrosArray[i - 1] = { valor: -1, nombre: this.translate.instant('tipoparametro') };
      this.parametrosLengthArray[i - 1] = 1;
      this.esIndefinido[i - 1] = false;
    } else {
      this.parametrosArray.pop();
      this.parametrosExtras.pop();
      this.parametrosLengthArray.pop();
      this.esIndefinido.pop();

      var jArray = this.parametrosArray.length - 1;
      var jExtra = this.parametrosExtras.length - 1;
      this.parametrosArray[jArray] = { valor: -1, nombre: this.translate.instant('tipoparametro') };
      this.parametrosExtras[jExtra] = { valor: -1, nombre: this.translate.instant('tipoparametro') };
      this.parametrosLengthArray[jArray] = 1;
      this.esIndefinido[jArray] = false;
    }

  }

  annadirLinea(i) {
    if (this.parametrosArray[i - 1].valor == -1) {
      this.parametrosExtras.push({ valor: -1, nombre: this.translate.instant('tipoparametro') });
      this.parametrosArray.push({ valor: -1, nombre: this.translate.instant('tipoparametro') });
      this.parametrosLengthArray.push(1);
      this.esIndefinido.push(false);
    }

  }

  annadirValor(e, param) {
    this.parametrosArray[param - 1] = { valor: e.valor, nombre: e.nombre };
  }

  annadirValorLength(e, param) {
    this.parametrosLengthArray[param - 1] = e;
  }

  esIndefinidoChange(i) {
    var count = this.esIndefinido.filter(f => f == true).length;
    if (count > 1) {
      this.esIndefinido.fill(false);
    }

    this.esIndefinido[i - 1] = true;
  }

  HMSToSeconds(tiempoEstimado) {
    var horas, minutos, segundos;
    horas = Number(tiempoEstimado.split(":")[0]);
    minutos = Number(tiempoEstimado.split(":")[1]);
    segundos = Number(tiempoEstimado.split(":")[2]);
    return horas * 3600 + minutos * 60 + segundos;
  }
  
  //#region GESTION DOCUMENTAL
  //Seleccionar tipo
  valorTipoSubidaChange(valor)
  {
    this.valorSelected = valor.id;
    //this.form.value.valorComboSubida = valor.id; 
  }
  //FTP
  guardarServidorFTP(valor) { this.form.value.servidorFTP = valor; }
  guardarPuertoFTP(valor) { this.form.value.puertoFTP = valor; }
  guardarUsuarioFTP(valor) { this.form.value.usuarioFTP = valor; }
  guardarPasswordFTP(valor) { this.form.value.passwordFTP = valor; }

  //Datos azure externo
  guardarAzureConString(valor) { this.form.value.azureConnectionString = valor; }
  guardarAzureContainerName(valor) { this.form.value.azureContainerName = valor; }

  //Google Cloud
  guardarCloudClaveAcceso(valor) { this.form.value.cloudNombreCarpeta = valor; }
  guardarCloudNombreBucket(valor) { this.form.value.cloudNombreBucket = valor; }

  //Google Drive
  guardarDriveIdCarpeta(valor) { this.form.value.driveIdCarpeta = valor; }

  guardarConfiguracionGestionDocumental(){
    var config: any = {id:0,idTipo:this.valorSelected,GCjson:null,GCnombreBucket:null,FTPservidor:null,FTPnombreUsuario:null,FTPpassword:null,AZcadConexion:null,AZcontenedor:null}
    //FTP
    if(this.valorSelected == 2){
      //Actualizar la configuracion FTP
      config.FTPservidor = this.form.value.servidorFTP;
      config.FTPnombreUsuario = this.form.value.usuarioFTP;
      config.FTPpassword = this.form.value.passwordFTP;
      //this.subidaFicherosService.uploadFileFTP(fichero.files[0].name,fichero.files[0],"aioro2.zitu.net","","aioro2","9bJsq#16gmzqYdSx","Manuales").subscribe((res)=>{})
      //this.subidaFicheros.uploadFileFTP(fichero.files[0].name,fichero.files[0],this.form.value.servidorFTP,"",this.form.value.usuarioFTP,this.form.value.passwordFTP).subscribe((res)=>{})
    }
    //Azure
    if(this.valorSelected == 3){
      //Actualizar la configuracion Azure
      config.AZcadConexion = this.form.value.azureConnectionString;
      config.AZcontenedor = this.form.value.azureContainerName;
    }
    //Google Cloud
    if(this.valorSelected == 5){
      var ficheroJSON:any = document.getElementById("ficheroJsonCredenciales");
      let fileCredenciales = ficheroJSON.files[0];
      if(fileCredenciales){
        const reader = new FileReader();
        var fileContent: string = '';
        reader.onload = (e: any) => {
          fileContent = e.target.result;
          //Actualizar la configuracion GC
          config.GCjson = fileContent;
          config.GCnombreBucket = this.form.value.cloudNombreBucket;
        };
        reader.readAsText(fileCredenciales);
      }
    }
    //Eris Azure
    if(this.valorSelected == 6){
      //Eris-eko datu eta kredentzialak direnez, ez da datu basean daturik gordetzen
    }
    this.configuracionService.get_configuracion_gestion_documental().subscribe(res => {
      if(res != null){
        var configExistente:any = res[0];
        configExistente.idTipo = config.idTipo;
        configExistente.FTPservidor = config.FTPservidor
        configExistente.FTPnombreUsuario = config.FTPnombreUsuario;
        configExistente.FTPpassword = config.FTPpassword;
        configExistente.GCjson = config.GCjson
        configExistente.GCnombreBucket = config.GCnombreBucket
        configExistente.AZcadConexion = config.AZcadConexion;
        configExistente.AZcontenedor = config.AZcontenedor;
        this.configuracionService.update_configuracion_gestion_documental(configExistente).subscribe(er => {console.log(er);})
      }
      else{
        this.configuracionService.add_configuracion_gestion_documental(config).subscribe(er => {console.log(er);})
      }
    })
    //valorComboSubida
    console.log(this.form.value.valorComboSubida);
    console.log("Valores de los textboxes: ");
    console.log(this.form.value.azureConnectionString);
    console.log(this.form.value.azureContainerName);
  }
  //#endregion

}
