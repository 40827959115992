import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MyFunctions } from '@app/_helpers';
import { ActivosService, MenuService, UsuariosService } from '@app/_services';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-activos',
  templateUrl: './activos.component.html',
  styleUrls: ['./activos.component.less']
}) 
export class ActivosComponent implements OnInit {

  @ViewChild('popupBorrar') popupBorrar: NgbModalRef;
  dataActivos: any[];
  mySelection: number[] = [];
  mySelection2: number[] = [];

  navigationSubscription;
  isDeleting = false;
  user = this.userService.userValue;
  
  modalReference: NgbModalRef;
  constructor(private activosService: ActivosService,
    private menuService: MenuService, public router: Router,
    private translateService: TranslateService,
    private userService: UsuariosService,
    private modalService: NgbModal, public myFunctions: MyFunctions) {
    this.menuService.titulo = this.translateService.instant('activos');

    this.cargarDatos();

  }

  cargarDatos() {
    this.activosService.getAllActivos().subscribe(
      result => {
        var an: any = result;
        this.dataActivos = an;
      });
  }

  ngOnInit(): void {
  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['activo/', this.mySelection[0]]);
    }
  }
  onClickEditar() {
    if (this.mySelection[0] > 0) {
      this.router.navigate(['activo/', this.mySelection[0]]);
    }
  }
  onClickNuevo() {
    this.router.navigate(['activo/0']);
  }
  onClickEliminar() {
    if (this.mySelection.length > 0)
      this.modalReference = this.modalService.open(this.popupBorrar, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }
  btnBorrarAceptar() {
    this.mySelection.forEach(element => {
      if (element > 0) {
        this.isDeleting = true;
        var ids: string = "";
        var idsActivos: string = "";
        var images: string = "";
        this.mySelection.forEach(
          ap => {
            if (ids == "")
              ids += ap;
            else
              ids += ',' + ap;
            var auxi = this.dataActivos.filter(x=> x.id == ap)[0];
            var auxFile = auxi.fichero;
            if (images == "")
              images += auxFile;
            else
              images += '&1sep1&' + auxFile;
            if (idsActivos == "")
            idsActivos += auxi.idRuta;
            else
            idsActivos += ',' + auxi.idRuta;
          }
        );
        this.activosService.deleteActivos(ids, images, idsActivos).subscribe(() => {
          this.isDeleting = false;
          this.cargarDatos();
        });
      }
      this.modalReference.close();
    });
  }

}
