import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { DecimalPipe } from '@angular/common';
import { NumericFilterCellComponent } from '@progress/kendo-angular-treelist';
import { Observable } from 'rxjs';

const baseUrl = `${environment.apiUrl}/subcontratado`;

@Injectable()
export class SubcontratadoService {
  constructor(
    private http: HttpClient
  ) {
  }

  //SELECT 
  get(id: number) {
    return this.http.post<JSON>(`${baseUrl}/Get`, { id }, { withCredentials: true });
  }

  getGrupoSubcontrtatadosPlanificadorById(idGrupo: number) {
    return this.http.post<JSON>(`${baseUrl}/getGrupoSubcontrtatadosPlanificadorById`, { idGrupo }, { withCredentials: true });
  }

  insert(idSeccion: number, nombre: string, capacidadSemanal_horas: number, envioPorDefecto: number, imagen: string, imagenBase64: string, costeHora: number,pedidoMin: number,
    pedidoMax: number, pedidoOptimo: number, plazoEntrega: number, idTipoUnidad: number, activo: boolean) {
    return this.http.post<JSON>(`${baseUrl}/Insert`, { idSeccion, nombre, capacidadSemanal_horas, envioPorDefecto, imagen, imagenBase64, costeHora, pedidoMin, pedidoMax, pedidoOptimo, plazoEntrega, idTipoUnidad, activo }, { withCredentials: true });
  }

  update(id: number, idSeccion: number, nombre: string, capacidadSemanal_horas: number, envioPorDefecto: number, imagen: string, imagenBase64: string, costeHora: number, pedidoMin: number,
    pedidoMax: number, pedidoOptimo: number, plazoEntrega: number, idTipoUnidad: number, activo: boolean) {
    return this.http.post<JSON>(`${baseUrl}/Update`, { id, idSeccion, nombre, capacidadSemanal_horas, envioPorDefecto, imagen, imagenBase64, costeHora, pedidoMin, pedidoMax, pedidoOptimo, plazoEntrega, idTipoUnidad, activo}, { withCredentials: true });
  }
  // insert(idSeccion: number, nombre: string, capacidadSemanal_horas: number, envioPorDefecto: number, imagen: string, imagenBase64: string, costeHora: number) {
  //   return this.http.post<JSON>(`${baseUrl}/Insert`, { idSeccion, nombre, capacidadSemanal_horas, envioPorDefecto, imagen, imagenBase64, costeHora }, { withCredentials: true });
  // }

  // update(id: number, idSeccion: number, nombre: string, capacidadSemanal_horas: number, envioPorDefecto: number, imagen: string, imagenBase64: string, costeHora: number) {
  //   return this.http.post<JSON>(`${baseUrl}/Update`, { id, idSeccion, nombre, capacidadSemanal_horas, envioPorDefecto, imagen, imagenBase64, costeHora }, { withCredentials: true });
  // }

  delete(ids: string) {
    return this.http.post<JSON>(`${baseUrl}/Delete`, {  ids }, { withCredentials: true });
  }
}
