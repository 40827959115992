<style>
  .reldiv1 {
    height: 0px;
    width: 0px;
    position: relative;
  }

  :host /deep/ .c3-drag-zoom {
    transform: translate(156.5px, 4.5px);
  }

  :host /deep/ .ocultarGrafico .c3-chart-lines {
    visibility: hidden;
  }

  /*GRAFICOS NUEVOS*/
  :host /deep/ #grafico2_inftec .c3-axis-x {
    display: none;
  }

  :host /deep/ #grafico3_inftec .c3-axis-x {
    display: none;
  }

  :host /deep/ #grafico4_inftec .c3-axis-x {
    display: none;
  }

  :host /deep/ #grafico5_inftec .c3-axis-x {
    display: none;
  }

  /* :host /deep/ #grafico1_inftec .c3-axis-y {
    transform: translate(0px, 0px);
  }

  :host /deep/ #grafico2_inftec .c3-axis-y {
    transform: translate(-32px, 0px);
  } */

  /* :host /deep/ #grafico3_inftec .c3-axis-y {
    transform: translate(-64px, 0px);
  }

  :host /deep/ #grafico4_inftec .c3-axis-y {
    transform: translate(-96px, 0px);
  } */

  /* :host /deep/ #grafico5_inftec .c3-axis-y {
    transform: translate(-128px, 0px);
  } */

  /*FIN GRAFICOS NUEVOS*/
  .k-i-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 64px;
    background-color: rgba(255, 255, 255, .3);
    color: #22C4C4;
    z-index: 3;
  }

  .label-una-linea {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .cont-filtro-maquinas-areas .cont-select-maquinas-out.desplegar {
    width: calc(100% - 540px) !important;
  }
</style>


<!-- FILTRO BERRIA HASIERA -->
<!-- <div *ngIf="mostrarCalendario" class="box" click="console.log('Click fondo')"></div> CLICK de fondo de calendario doble! -->
<div class="cont-filtro-desplegable card" [ngClass]="status ? 'filtro-oculto' : 'filtro-desplegado'">
  <div class="card-header">
    <h3>{{ 'filtro' | translate}}</h3>
    <button type="button" class="flt-cerrar " (click)="this.status = !this.status;"><i
        class="fas fa-times"></i></button>
  </div>
  <div class="filtro-desplegable card-body flt">
    <!-- Filtro: CON GRUPOS -->
    <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ filtros: [datosFiltro] }"></ng-container>
    <ng-template #recursiveListTmpl let-filtross="filtros">
      <div *ngFor="let filtro of filtross" class="mt-2">
        <!-- GRUPO -->
        <div *ngFor="let row of filtro.group" class="flt-grupo">
          <!-- LINEA FILTRO -->
          <div *ngIf="row.group == undefined" class="clearfix flt-linea">
            <!-- AND / OR -->
            <div class="flt-andor">
              <!-- = 1ª ROW NO TIENE NI OR, NI AND-->
              <label class="float-left" *ngIf="row == filtro.group[0]"> {{ 'where' | translate}} </label>
              <!-- = 2ª ROW -->
              <kendo-dropdownlist (selectionChange)="refiltrarFiltro(filtro)"
                *ngIf="row != filtro.group[0] && !row.bloqueado" [data]="andOr" [textField]="'nombre'"
                [valueField]="'id'" [(value)]="filtro.logic">
              </kendo-dropdownlist>
              <label class="d-flex justify-content-center" *ngIf="row == filtro.group[1] && row.bloqueado"> {{ 'y' |
                translate}}
              </label>
              <!-- > 2ª ROW -->
              <label class="d-flex justify-content-center"
                *ngIf="row != filtro.group[0] && row != filtro.group[1] && filtro.logic.id == 0 && row.bloqueado"> {{
                'y' | translate}}
              </label>
              <label class="d-flex justify-content-center"
                *ngIf="row != filtro.group[0] && row != filtro.group[1] && filtro.logic.id == 1 && row.bloqueado"> {{
                'o' | translate}}
              </label>
            </div>
            <!-- TIPO -->
            <div class="flt-tipo">
              <kendo-dropdownlist *ngIf="row.bloqueado" (selectionChange)="filtroTipoChanged(filtro, row, $event)"
                [data]="columnasFiltro" [textField]="'nombre'" [valueField]="'id'"
                [class.flt-sin-seleccionar]="row.columna.id == 0" [(value)]="row.columna"
                [disabled]="true"></kendo-dropdownlist>
            </div>
            <div class="flt-tipo">
              <kendo-dropdownlist *ngIf="!row.bloqueado" (selectionChange)="filtroTipoChanged(filtro, row, $event)"
                [data]="columnasFiltro" [textField]="'nombre'" [valueField]="'id'"
                [class.flt-sin-seleccionar]="row.columna.id == 0" [(value)]="row.columna"></kendo-dropdownlist>
            </div>
            <!-- CONTENEDOR FILTRO DE LINEA -->
            <div class="flt-filtro-linea">
              <!-- Filtro: FECHA -->
              <div *ngIf="row.columna.tipo == 'date'" class="flt-cont-tipo">
                <!-- OPCION -->
                <div class="flt-opcion">
                  <!-- <kendo-dropdownlist *ngIf="!row.bloqueado" [data]="opcionDate" [textField]="'nombre'"
                    [valueField]="'id'" [(value)]="row.operator"></kendo-dropdownlist> -->
                  <kendo-dropdownlist [data]="opcionDate" [textField]="'nombre'" [valueField]="'id'"
                    [(value)]="row.operator" [disabled]="true"></kendo-dropdownlist>
                </div>
                <!-- DATO(s) -->
                <div *ngIf="row.operator.id > 0" class="flt-datos">
                  <!-- DATO 1 (tenemos un control especial para las fechas dobles) -->
                  <kendo-datepicker *ngIf="!row.operator.dobleValor" class="flt-dato1"
                    [(value)]="row.fechaIni"></kendo-datepicker>
                  <!-- DATO 1 / 2 -->
                  <div *ngIf="row.operator.dobleValor" class="flt-dato2">
                    <div class="calendarFiltro">
                      <!-- CONTROL -->
                      <div class="calendarFiltro-input" (click)="showCalendar(row)">
                        <label *ngIf="row.fechaIni != undefined && row.fechaFin == undefined">{{row.fechaIni |
                          kendoDate}}</label>
                        <label *ngIf="row.fechaFin != undefined && row.fechaFin != undefined">{{row.fechaIni |
                          kendoDate}} - {{row.fechaFin | kendoDate}}</label>
                        <i class="k-icon k-i-calendar"></i>
                      </div>
                      <!-- CONTROL EXPANDIDO -->
                      <div class="calendarFiltro-calendario" style="position: absolute !important; width: 367px;"
                        [ngClass]="row.mostrarCalendario ? 'mostrarCalendario' : 'ocultarCalendario'">
                        <div class="row">
                          <div class="col-mes">
                            <kendo-calendar id="calendario-0" [(value)]="DaysInMonths[0]" type="classic"
                              (click)="valueClickCalendar(row, 0, $event)">
                              <ng-template kendoCalendarMonthCellTemplate let-date>
                                <span class="calendarFiltro-calendario-contenido-dia"
                                  [class.calendarFiltro-calendarioSeleccionado]="isDateSelected(row, date)"> {{
                                  date.getDate() }} </span>
                              </ng-template>
                              <ng-template kendoCalendarHeaderTitleTemplate let-title>
                                <div> {{ title }} </div>
                              </ng-template>
                            </kendo-calendar>
                          </div>
                          <div class="col-mes-botones">
                            <div class="calendarFiltro-botones">
                              <div class="clearfix text-center">
                                <!-- style="margin-left: -100px; margin-top: 5px; position: absolute;" -->
                                <button (click)="cambiarMeses(-1)"><i class="fa fa-chevron-left"></i></button>
                                <button (click)="cambiarMeses(1)"><i class="fa fa-chevron-right"></i></button>
                                <button (click)="cargarMeses()">{{'hoy' | translate}}</button>
                              </div>
                              <div>
                                <div class="clearfix"><button (click)="ultimas24HButton(row)">{{'ultimas24H' |
                                    translate}}</button></div>
                                <div class="clearfix"><button (click)="ultimos7DiasButton(row)">{{'ultimos7Dias' |
                                    translate}}</button></div>
                                <div class="clearfix"><button (click)="ultimos30DiasButton(row)">{{'ultimos30Dias' |
                                    translate}}</button></div>
                                <div class="clearfix"><button (click)="ultimos60DiasButton(row)">{{'ultimos60Dias' |
                                    translate}}</button></div>
                                <div class="clearfix"><button (click)="ultimos90DiasButton(row)">{{'ultimos90Dias' |
                                    translate}}</button></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Filtro: FECHA y HORA (el caso ENTRE no esta diseñado aun) -->
              <div *ngIf="row.columna.tipo == 'dateTime'" class="flt-cont-tipo">
                <!-- OPCION -->
                <div class="flt-opcion">
                  <kendo-dropdownlist [data]="opcionDateTime" [textField]="'nombre'" [valueField]="'id'"
                    [(value)]="row.operator"></kendo-dropdownlist>
                </div>
                <!-- DATO(s) -->
                <div *ngIf="row.operator.id > 0" class="flt-datos">
                  <!-- DATO -->
                  <div class="flt-dato1">
                    <kendo-datetimepicker [(value)]="row.fechaIni"> </kendo-datetimepicker>
                  </div>
                </div>
              </div>
              <!-- Filtro: NUMEROS ENTEROS -->
              <div *ngIf="row.columna.tipo == 'numeric'">
                <!-- OPCION -->
                <div class="flt-opcion">
                  <kendo-dropdownlist [data]="opcionNumericDecimal" [textField]="'nombre'" [valueField]="'id'"
                    [(value)]="row.operator"></kendo-dropdownlist>
                </div>
                <!-- DATO(s) -->
                <div *ngIf="row.operator.id > 0" class="flt-datos">
                  <div class="flt-dato1">
                    <!-- DATO 1 -->
                    <kendo-numerictextbox format="0" [min]="-9999999" [max]="9999999"
                      [(value)]="row.numberMin"></kendo-numerictextbox>
                  </div>
                  <div *ngIf="row.operator.dobleValor" class="flt-dato2">
                    <!-- DATO 2 -->
                    <kendo-numerictextbox format="0" [min]="-9999999" [max]="9999999"
                      [(value)]="row.numberMax"></kendo-numerictextbox>
                  </div>
                </div>
              </div>
              <!-- Filtro: DECIMALES -->
              <div *ngIf="row.columna.tipo == 'decimal'">
                <div class="flt-opcion">
                  <!-- OPCION -->
                  <kendo-dropdownlist [data]="opcionNumericDecimal" [textField]="'nombre'" [valueField]="'id'"
                    [(value)]="row.operator"></kendo-dropdownlist>
                </div>
                <!-- DATO(s) -->
                <div *ngIf="row.operator.id > 0" class="flt-datos">
                  <div class="flt-dato1">
                    <!-- DATO 1 -->
                    <kendo-numerictextbox [format]="row.decimalformat" [min]="-9999999" [max]="9999999"
                      [(value)]="row.decimalMin"></kendo-numerictextbox>
                  </div>
                  <div class="flt-dato2">
                    <!-- DATO 2 -->
                    <kendo-numerictextbox *ngIf="row.operator.dobleValor" [format]="row.decimalformat" [min]="-9999999"
                      [max]="9999999" [(value)]="row.decimalMax"></kendo-numerictextbox>
                  </div>
                </div>
              </div>
              <!-- Filtro: TEXTO -->
              <div *ngIf="row.columna.tipo == 'string'">
                <div class="flt-opcion">
                  <!-- OPCION -->
                  <kendo-dropdownlist [data]="opcionString" [textField]="'nombre'" [valueField]="'id'"
                    [(value)]="row.operator"></kendo-dropdownlist>
                </div>
                <div class="flt-datos">
                  <div class="flt-dato1 float-left mr-2">
                    <!-- DATO -->
                    <kendo-textbox [(value)]="row.text" [maxlength]="50"></kendo-textbox>
                  </div>
                </div>
              </div>
              <!-- Filtro: COMBO ESTATICO -->
              <div *ngIf="row.columna.tipo == 'comboEstrincto'">
                <div class="flt-opcion">
                  <!-- OPCION -->
                  <kendo-dropdownlist *ngIf="!row.bloqueado" [data]="opcionComboEstricto" [textField]="'nombre'"
                    [valueField]="'id'" [(value)]="row.operator"></kendo-dropdownlist>
                  <kendo-dropdownlist *ngIf="row.bloqueado" [data]="opcionComboEstricto" [textField]="'nombre'"
                    [valueField]="'id'" [(value)]="row.operator" [disabled]="true"></kendo-dropdownlist>
                </div>
                <!-- DATO(s) -->
                <div *ngIf="row.operator.id > 0" class="flt-datos">
                  <div class="flt-dato1">
                    <!-- DATO 1 -->
                    <kendo-dropdownlist *ngIf="!row.operator.dobleValor" [data]="row.combo" [textField]="'nombre'"
                      [valueField]="'id'" [(value)]="row.comboSelected" style="width: 220px;">
                    </kendo-dropdownlist>
                  </div>
                  <div class="flt-dato2">
                    <!-- DATO 2 -->
                    <kendo-multiselect *ngIf="row.operator.dobleValor" kendoMultiSelectSummaryTag [data]="row.combo"
                      [(ngModel)]="row.comboSelecteds" [textField]="'nombre'" [valueField]="'id'" [autoClose]="false"
                      style="width: 220px;" [kendoDropDownFilter]="{operator: 'contains'}" class="flt-dato2"
                      (valueChange)="valueChangeComboEstatico($event)">
                      <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                        <span class="k-icon k-i-arrow-s"></span>
                        <ng-container *ngIf="dataItems.length == 1">{{
                          this.myFunctions.stringMaxLength(dataItems[0].nombre, 16)}}</ng-container>
                        <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seleccionados' | translate
                          }}</ng-container>
                      </ng-template>
                      <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                    </kendo-multiselect>
                  </div>

                </div>
              </div>
              <!-- Filtro: COMBO FLEXIBLE -->
              <div *ngIf="row.columna.tipo == 'comboFlexible'">
                <!-- OPCION -->
                <div class="flt-opcion">
                  <kendo-dropdownlist [data]="opcionComboFlexible" [textField]="'nombre'" [valueField]="'id'"
                    [(value)]="row.operator"></kendo-dropdownlist>
                </div>
                <!-- DATO(s) -->
                <div *ngIf="row.operator.id > 0" class="flt-datos">
                  <!-- DATO 1 -->
                  <kendo-dropdownlist *ngIf="!row.operator.dobleValor && row.operator.id <= 4" class="mr-2 float-left"
                    [data]="row.combo" [textField]="'nombre'" [valueField]="'id'" [(value)]="row.comboSelected"
                    style="width: 220px;"></kendo-dropdownlist>
                  <!-- DATO 1 -->
                  <kendo-multiselect [maxSelectedItems]="1" *ngIf="row.operator.dobleValor" kendoMultiSelectSummaryTag
                    [data]="row.combo" [(ngModel)]="row.comboSelecteds" [textField]="'nombre'" [valueField]="'id'"
                    [autoClose]="false" style="width: 220px;" [kendoDropDownFilter]="{operator: 'contains'}"
                    class="flt-dato2">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{
                        this.myFunctions.stringMaxLength(dataItems[0].nombre, 16)}}</ng-container>
                      <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seleccionados' | translate
                        }}</ng-container>
                    </ng-template>
                    <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                  </kendo-multiselect>
                  <!-- DATO 1 -->
                  <div *ngIf="!row.operator.dobleValor && row.operator.id > 4" class="flt-dato1 float-left mr-2">
                    <kendo-textbox [(value)]="row.text" [maxlength]="50"></kendo-textbox>
                  </div>
                </div>
              </div>
              <!-- Filtro: CHECK -->
              <div *ngIf="row.columna.tipo == 'check'" class="flt-dato1">
                <!-- SERIA MEJOR CON 2 BOTONES SI / NO ? TEXTO PARA VERDADERO / FALSO? -->
                <kendo-switch [(ngModel)]="row.check" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
              </div>
              <!-- Filtro: DROP -->
              <div *ngIf="row.columna.tipo == 'drop'">
                <!-- OPCION -->
                <div class="flt-opcion">
                  <kendo-dropdownlist [data]="opcionComboFlexible" [textField]="'nombre'" [valueField]="'id'"
                    [(value)]="row.operator" [disabled]="true"></kendo-dropdownlist>
                </div>
                <!-- DATO 1 -->
                <kendo-dropdownlist [data]="row.combo" [textField]="'nombre'" [valueField]="'id'"
                  [(value)]="row.comboSelected" style="width: 220px;" class="flt-datos"></kendo-dropdownlist>
              </div>
            </div>
            <!-- ELIMINAR -->
            <button
              *ngIf=" (!((row.columna.id == 0 && filtro == datosFiltro.group[0] && datosFiltro.group.length == 1 && !permitirFiltroVacio ) || (row.columna.id == 0 && row != filtro.group[0])) || !annadirAutomatico) && !row.bloqueado "
              Class="flt-eliminar" (click)="borrarLineaFiltro(row, filtro, datPlanos)"> <i class="fas fa-times"></i>
            </button>
          </div>
          <!-- SUB GRUPO -->
          <div *ngIf="row.group != undefined">
            <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ filtros:  [row] }"></ng-container>
          </div>
        </div>
        <!-- BOTON: AÑADIR LINEA (SOLO EN LA ULTIMA LINEA, o primer subgrupo) -->
        <div class=" mt-1" *ngIf="filtro.group != undefined && filtro != datosFiltro && !annadirAutomatico">
          <button Class="btnEditatu btn btn-success btn-sm mr-1" (click)="annadirLineaFiltro(filtro)"> <i
              class="fas fa-plus"></i> {{ 'agregarCondicion' | translate }} </button>
        </div>
        <div class=" mt-1" *ngIf="filtro.group != undefined && filtro != datosFiltro">
          <hr>
        </div>
      </div>
    </ng-template>
    <!-- <div class="clearfix">
      <button class="flt-btnanadirgrupo" (click)="annadirGrupoFiltro()"> <i class="fas fa-plus"></i> {{ 'agregarGrupo' |
        translate }} </button>
    </div>
    <hr> -->
    <div class="clearfix">
      <button Class="btn btn-primary btn-sm mt-1 float-right" (click)="onFilter(); this.status = !this.status;"> {{
        'filtrar' | translate }}</button>
      <button Class="btn-danger btn btn-sm mt-1 float-right mr-1" (click)="borrarFiltro()"> {{ 'filterClearButton' |
        translate }}</button>
    </div>
  </div>
</div>
<!--   CARD CON OPCIONES SELECCIONADAS FILTRO -->
<div class="card">
  <div class="card-body">
    <div class="cont-tags-filtro" id="tagsFiltro">
      <ng-container *ngTemplateOutlet="recursiveListTmpl2; context:{ filtros: [datosFiltro] }"></ng-container>
      <ng-template #recursiveListTmpl2 let-filtross="filtros">
        <div *ngFor="let filtro of filtross" class="uno">
          <div class="flt-separador"
            *ngIf="filtro.group != undefined && filtro != datosFiltro && filtro!=datosFiltro.group[0] && filtroToCount(filtro) > 0">
          </div>
          <!-- GRUPO -->
          <div *ngFor="let row of filtro.group" class="dos">
            <!-- LINEA FILTRO -->
            <div *ngIf="row.group == undefined &&
            ((row.columna.tipo == 'date') || 
             (row.columna.tipo == 'dateTime') || 
            (row.columna.tipo == 'comboEstrincto' && row.comboSelecteds.length > 0 ) || 
            (row.columna.tipo == 'comboFlexible'  && ((row.operator.id <= 4 && row.comboSelecteds.length > 0 ) ||( row.operator.id > 4 && row.text != ''))) || 
            (row.columna.tipo == 'check') || 
            (row.columna.tipo == 'numeric') || 
            (row.columna.tipo == 'decimal') || 
            (row.columna.tipo == 'drop') || 
            (row.columna.tipo == 'string' && row.text != '' ))" class="tags-filtro">
              <label *ngIf="false"> {{ row.columna.nombre }} </label>
              <label *ngIf="false"> {{ row.operator.nombre }} </label>
              <!-- -->
              <!-- FILTRO -->
              <div class="tags-filtro-label">
                <!-- Filtro: FECHA -->
                <div *ngIf="row.columna.tipo == 'date'">
                  <div *ngIf="row.operator.id > 0">
                    <label class="tags-filtro-label" *ngIf="!row.operator.dobleValor">{{row.fechaIni |
                      kendoDate}}</label>
                    <label class="tags-filtro-label"
                      *ngIf="row.fechaFin != undefined && row.fechaFin == undefined && row.operator.dobleValor">{{row.fechaIni
                      | kendoDate}}</label>
                    <label class="tags-filtro-label"
                      *ngIf="row.fechaFin != undefined && row.fechaFin != undefined && row.operator.dobleValor">{{row.fechaIni
                      | kendoDate}} - {{row.fechaFin | kendoDate}}</label>
                  </div>
                </div>
                <!-- Filtro: FECHA y HORA (el caso ENTRE no esta diseñado aun) -->
                <div *ngIf="row.columna.tipo == 'dateTime'">
                  <div *ngIf="row.operator.id > 0">
                    <label class="tags-filtro-label" *ngIf="!row.operator.dobleValor"> {{row.fechaIni | kendoDate}}
                    </label>
                  </div>
                </div>
                <!-- Filtro: NUMEROS ENTEROS -->
                <div *ngIf="row.columna.tipo == 'numeric'">
                  <div *ngIf="row.operator.id > 0">
                    <label class="tags-filtro-label"> {{row.numberMin}} </label>
                    <label class="tags-filtro-label" *ngIf="row.operator.dobleValor"> {{row.numberMax}} </label>
                  </div>
                </div>
                <!-- Filtro: DECIMALES -->
                <div *ngIf="row.columna.tipo == 'decimal'">
                  <div *ngIf="row.operator.id > 0">
                    <label class="tags-filtro-label"> {{row.decimalMin}} </label>
                    <label class="tags-filtro-label" *ngIf="row.operator.dobleValor"> {{row.decimalMax}} </label>
                  </div>
                </div>
                <!-- Filtro: TEXTO -->
                <div *ngIf="row.columna.tipo == 'string'">
                  <label class="tags-filtro-label"> {{row.text}} </label>
                </div>
                <!-- Filtro: COMBO ESTATICO -->
                <div *ngIf="row.columna.tipo == 'comboEstrincto'">
                  <div *ngIf="row.operator.id > 0">
                    <label class="tags-filtro-label" *ngIf="row.comboSelecteds.length == 1">{{
                      row.comboSelecteds[0].nombre}}</label>
                    <label class="tags-filtro-label" *ngIf="row.comboSelecteds.length > 1">{{ row.columna.nombre }} ({{
                      row.comboSelecteds.length }})</label>
                  </div>
                </div>
                <!-- Filtro: COMBO FLEXIBLE -->
                <div *ngIf="row.columna.tipo == 'comboFlexible'">
                  <div *ngIf="row.operator.id > 0">
                    <div *ngIf="row.operator.id <= 4">
                      <label class="tags-filtro-label" *ngIf="row.comboSelecteds.length == 1">{{
                        row.comboSelecteds[0].nombre}}</label>
                      <label class="tags-filtro-label" *ngIf="row.comboSelecteds.length > 1">{{ row.columna.nombre }}
                        ({{ row.comboSelecteds.length }})</label>
                    </div>
                    <label class="tags-filtro-label" *ngIf="row.operator.id > 4"> {{row.text}} </label>
                  </div>
                </div>
                <!-- Filtro: CHECK -->
                <div *ngIf="row.columna.tipo == 'check'">
                  <label class="tags-filtro-label" *ngIf="row.check"> {{ row.columna.nombre }} : {{ 'si' | translate }}
                  </label>
                  <label class="tags-filtro-label" *ngIf="!row.check"> {{ row.columna.nombre }} : {{ 'no' | translate }}
                  </label>
                </div>
                <!-- Filtro: DROP (Especifico para esta caso de maquinas) -->
                <div *ngIf="row.columna.tipo == 'drop'">
                  <label class="tags-filtro-label"> {{ row.comboSelected.nombre }}
                  </label>
                </div>
              </div>
              <!-- ELIMINAR -->
              <button *ngIf="!row.bloqueado" (click)="borrarLineaFiltro(row, filtro, datPlanos)" class="fas fa-times"
                style="cursor:pointer"></button>
            </div>
            <!-- SUB GRUPO -->
            <div *ngIf="row.group != undefined">
              <ng-container *ngTemplateOutlet="recursiveListTmpl2; context:{ filtros:  [row] }"></ng-container>
            </div>
          </div>
        </div>
      </ng-template>
      <button type="button" class="btn btn-primary float-right mr-1" (click)="this.status = !this.status;"><i
          class="fas fa-filter mr-1"></i>{{ 'filtro' | translate }} ( {{ filtroToCount() }}
        )</button>
      <button Class="btn btn-danger float-right mr-1" (click)="borrarFiltro()"><i class="fas fa-backspace mr-1"></i>{{
        'filterClearButton' | translate }}</button>
      <button *ngIf="actualizarVisible" Class="btn btn-success float-right mr-1" (click)="onFilter()"><i
          class="fas fa-sync-alt mr-1"></i>{{ 'actualizar' | translate }}</button>
    </div>

  </div>

  <div *ngIf="loadingFiltro" class="k-i-loading"></div>
</div>

<!-- FILTRO BERRIA BUKAERA -->

<div class="row" *ngIf="!ezkutatu">
  <div [ngClass]="'card col-md-9' " class="grafico-multiardatza" >


    <!--GRAFICO GANT-->
    <div *ngIf="gantVisible" id="chartGanttTimeline" class="gantt-informe-tecnico-ejecucion" style="width: calc(100% - 110px); margin-left: 110px;"></div>
    <!--GRAFICOS NUEVO-->
    <div style="height:550px;" (mouseenter)="mouseEnterGraficoCincoEjes()" (mouseleave)="mouseLeaveGraficoCincoEjes()"
      class="grupo-graficos-informetecnicoejecucion" id="grupo-graficos-informetecnicoejecucion" >
      <button kendoButton look="flat" [icon]="'zoom-out'" style="float: right; z-index: 2; margin-right: 40px;"
        (click)="onClickMostrarTodos()">{{
        'mostrarTodos' | translate }}</button>
      <div class="reldiv1" id="grafico5_inftec" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[4]"
        [ngClass]="{'d-none': (numeroCamposSeleccionados >= 5 && numeroCamposSeleccionados <= 5 ) ? false : true}"
        style="z-index: 1;"></div>
      <div class="reldiv1" id="grafico4_inftec" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[3]"
        [ngClass]="{'d-none': (numeroCamposSeleccionados >= 4 && numeroCamposSeleccionados <= 5 ) ? false : true}"
        style="z-index: 1;"></div>
      <div class="reldiv1" id="grafico3_inftec" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[2]"
        [ngClass]="{'d-none': (numeroCamposSeleccionados >= 3 && numeroCamposSeleccionados <= 5 ) ? false : true}"
        style="z-index: 1;"></div>
      <div class="reldiv1" id="grafico2_inftec" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[1]"
        [ngClass]="{'d-none': (numeroCamposSeleccionados >= 2 && numeroCamposSeleccionados <= 5 ) ? false : true}"
        style="z-index: 1;"></div>
      <div class="reldiv1" id="grafico1_inftec" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[0]"
        [ngClass]="{'d-none': (numeroCamposSeleccionados >= 1 && numeroCamposSeleccionados <= 5 ) ? false : true}"
        style="z-index: 1;"></div>
    </div>
    <div class="row"  *ngIf="!menuLateralDeplegado">

      <!--BOTONES LEYENDA-->
      <div class="col-md-11">
        <div class="leyendas-chart row justify-content-md-center">
          <!--GENERALES-->
          <ng-container *ngFor="let campo of generales">
            <div class="leyenda-chart col-auto" *ngIf="campo.seleccionado" (click)="clicklLeyenda(campo)"
              style="cursor: pointer; z-index: 1;">
              <div class="leyenda-chart-color" *ngIf="campo.seleccionadoLeyenda"
                [style]="'background-color:' + campo.color"></div>
              <div class="leyenda-chart-color" *ngIf="!campo.seleccionadoLeyenda"></div>
              <button class="leyenda-chart-label">{{ campo.nombre }}</button>
            </div>
          </ng-container>

          <!--EJES-->
          <ng-container *ngFor="let eje of ejes">
            <ng-container *ngFor="let campo of eje.campos">
              <div class="leyenda-chart col-auto" *ngIf="campo.seleccionado" (click)="clicklLeyenda(campo)"
                style="cursor: pointer; z-index: 1;">
                <div class="leyenda-chart-color" *ngIf="campo.seleccionadoLeyenda"
                  [style]="'background-color:' + campo.color"></div>
                <div class="leyenda-chart-color" *ngIf="!campo.seleccionadoLeyenda"></div>
                <button class="leyenda-chart-label">{{ campo.nombre }} ({{ eje.nombre }})</button>
              </div>
            </ng-container>
          </ng-container>

        </div>
      </div>

      <!--BOTON ABRIR MENU LATERAL-->
      <div class="col-md-1">
        <div class="desplegar-menu-lateral" (click)="clickDesplegarMenuLateral()">
          <i class="fas fa-ellipsis-v"></i>
        </div>
      </div>

    </div>
    <!-- EDITAR LEYENDA -->
    <div class="row editar-leyenda-cont" *ngIf="menuLateralDeplegado">
      <div class="editar-leyenda-cont-izq">
        <div class="row">
          <!-- GENERALES -->
          <div class="col-4" *ngIf="generales.length>0 && ejes.length > 0">
            <div class="card">
              <div class="clearfix">
                <h3>
                  <label>{{ 'generales' | translate }}</label>
                </h3>
              </div>
              <div class="row">
                <div class="col" *ngFor="let campo of generales">
                  <div class="form-group switch-row">
                    <kendo-switch [title]="campo.nombre" class="kendo-switch-tipo2" [onLabel]="campo.nombre" [offLabel]="campo.nombre"
                      [(ngModel)]="campo.seleccionado" [(ngClass)]="campo.cssSwitch"
                      [disabled]="numeroCamposSeleccionados==4 && !campo.seleccionado"
                      (valueChange)="clickSwitch($event, campo)"></kendo-switch>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col" *ngIf="generales.length>0 && ejes.length < 1">
            <div class="card">
              <div class="clearfix">
                <h3>
                  <label>{{ 'generales' | translate }}</label>
                </h3>
              </div>
              <div class="row">
                <div class="col" *ngFor="let campo of generales">
                  <div class="form-group switch-row">
                    <kendo-switch [title]="campo.nombre" class="kendo-switch-tipo2" [onLabel]="campo.nombre" [offLabel]="campo.nombre"
                      [(ngModel)]="campo.seleccionado" [(ngClass)]="campo.cssSwitch"
                      [disabled]="numeroCamposSeleccionados==4 && !campo.seleccionado"
                      (valueChange)="clickSwitch($event, campo)"></kendo-switch>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- EJES -->
          <!-- <div *ngIf="ejes.length > 0"> -->
          <ng-container *ngFor="let eje of ejes; let i = index">
            <div class="col-4">
              <div class="card">
                <div class="clearfix">
                  <h3>
                    <label>{{ eje.nombre }}</label>
                  </h3>
                </div>
                <div class="row">
                  <div class="col" *ngFor="let campo of eje.campos">
                    <div class="form-group switch-row">
                      <kendo-switch [title]="campo.nombre" class="kendo-switch-tipo2" [onLabel]="campo.nombre" [offLabel]="campo.nombre"
                        [(ngModel)]="campo.seleccionado" [(ngClass)]="campo.cssSwitch"
                        [disabled]="numeroCamposSeleccionados==4 && !campo.seleccionado"
                        (valueChange)="clickSwitch($event, campo)"></kendo-switch>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- </div> -->
        </div>




      </div>
      <!--BOTON ABRIR MENU LATERAL-->
      <div class="editar-leyenda-cont-der">
        <div class="desplegar-menu-lateral" (click)="clickDesplegarMenuLateral()">
          <div *ngIf="!menuLateralDeplegado">
            <i class="fas fa-ellipsis-v"></i>
          </div>
          <div *ngIf="menuLateralDeplegado">
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
    </div>
    <!-- MODIFICAR PARAMETRO -->
    <div class="row" *ngIf="!menuLateralDeplegado && ensenarModificar">
      <div class="col-11"></div>
      <div class="ml-3">
        <button kendoButton class="btn btn-primary" (click)="modificarParametro()">{{ 'modificarParametro' | translate
          }}
        </button>
      </div>
    </div>

    <div *ngIf="loadingGrafico5Ejes" class="k-i-loading" style="z-index: 2;"></div>

  </div>

  <!-- DATOS LATERAL -->
  <div class="col-md-3" style="height: 800px">

    <!--PANEL DERECHO GANTT-->
    <div class="card">

      <div class="card-body">
        <div class="datos-of-content informe-tec-de-eje-datos">
          <ul>
            <li class="d-flex justify-content-between">
              <label>{{ 'fecha' | translate }}</label>
              <label class="bloques-info-valor ">{{ myFunctions.dateToString(arrayFechas[indicePanelIzquierdo])
                }}</label>
            </li>
            <li class="d-flex justify-content-between" *ngIf="selectedMaquina?.tipo_maquina != 8">
              <label>{{ 'operario' | translate }}</label>
              <label class="bloques-info-valor ">{{operarioPanelDerecho}}</label>
            </li>
            <li class="d-flex justify-content-between" *ngIf="selectedMaquina?.tipo_maquina != 8">
              <label>{{ 'of' | translate }}</label>
              <label class="bloques-info-valor ">{{ofPanelDerecho}}</label>
            </li>
            <li class="d-flex justify-content-between" *ngIf="selectedMaquina?.tipo_maquina != 8">
              <label>{{ 'cliente' | translate }}</label>
              <label class="bloques-info-valor ">{{clientePanelDerecho}}</label>
            </li>
            <li class="d-flex justify-content-between" *ngIf="selectedMaquina?.tipo_maquina != 8">
              <label>{{ 'refPieza' | translate }}</label>
              <label class="bloques-info-valor ">{{refPiezaPanelDerecho}}</label>
            </li>
            <li class="d-flex justify-content-between" *ngIf="selectedMaquina?.tipo_maquina != 8">
              <label>{{ 'pieza' | translate }}</label>
              <label class="bloques-info-valor ">{{piezaPanelDerecho}}</label>
            </li>
            <li class="d-flex justify-content-between" *ngIf="selectedMaquina?.tipo_maquina != 8">
              <label>{{ 'parte' | translate }}</label>
              <label class="bloques-info-valor ">{{partePanelDerecho}}</label>
            </li>
            <li class="d-flex justify-content-between" *ngIf="selectedMaquina?.tipo_maquina != 8">
              <label>{{ 'operacion' | translate }}</label>
              <label class="bloques-info-valor ">{{operacionPanelDerecho}}</label>
            </li>
            <ng-container *ngFor="let eje of ejes">
              <li>
                <!--TITULO EJE-->
                <label><strong>{{eje.nombre}}</strong></label>
              </li>

              <!--CAMPOS-->
              <ng-container *ngFor="let campo of eje.campos">
                <li class="d-flex justify-content-between">
                  <label>{{ campo.nombre }}:</label>
                  <label class="bloques-info-valor ">{{ campo.valoresMomentaneo[indicePanelIzquierdo] }} {{ campo.unidad
                    }}</label>
                </li>
              </ng-container>
            </ng-container>
            <div *ngIf="generales.length > 0">
              <ng-container *ngFor="let campo of generales">
                <!-- <li>
                  <label><strong>{{campo.nombre}}</strong></label>
                </li> -->
                <li class="d-flex justify-content-between">
                  <!--CAMPOS-->
                  <label>{{ campo.nombre }}:</label>
                  <label class="bloques-info-valor ">{{ campo.valoresMomentaneo[indicePanelIzquierdo] }} {{
                    campo.unidad }}</label>
                </li>
              </ng-container>
            </div>
          </ul>
        </div>
      </div>
    </div>


    <!-- <div class="row">

          <div class="col">
            <div class="bloques-info doslineas">
              <label class="bloques-info-label">{{ 'fecha' | translate }}</label>
              <label class="bloques-info-valor">{{fechaPanelDerecho}}</label>
            </div>
          </div>

          <div class="col">
            <div class="bloques-info doslineas">
              <label class="bloques-info-label">{{ 'operario' | translate }}</label>
              <label class="bloques-info-valor">{{operarioPanelDerecho}}</label>
            </div>
          </div>

        </div>

         <div class="row">

          <div class="col">
            <div class="bloques-info doslineas">
              <label class="bloques-info-label">{{ 'of' | translate }}</label>
              <label class="bloques-info-valor">{{ofPanelDerecho}}</label>
            </div>
          </div>

          <div class="col">
            <div class="bloques-info doslineas">
              <label class="bloques-info-label">{{ 'cliente' | translate }}</label>
              <label class="bloques-info-valor">{{clientePanelDerecho}}</label>
            </div>
          </div>

        </div>

        <div class="row">

          <div class="col">
            <div class="bloques-info doslineas">
              <label class="bloques-info-label">{{ 'refPieza' | translate }}</label>
              <label class="bloques-info-valor">{{refPiezaPanelDerecho}}</label>
            </div>
          </div>

          <div class="col">
            <div class="bloques-info doslineas">
              <label class="bloques-info-label">{{ 'pieza' | translate }}</label>
              <label class="bloques-info-valor">{{piezaPanelDerecho}}</label>
            </div>
          </div>

        </div>

        <div class="row">

          <div class="col">
            <div class="bloques-info doslineas">
              <label class="bloques-info-label">{{ 'parte' | translate }}</label>
              <label class="bloques-info-valor">{{partePanelDerecho}}</label>
            </div>
          </div>

          <div class="col">
            <div class="bloques-info doslineas">
              <label class="bloques-info-label">{{ 'operacion' | translate }}</label>
              <label class="bloques-info-valor">{{operacionPanelDerecho}}</label>
            </div>
          </div>

        </div> -->

  </div>

</div>

<!--MENU LATERAL MECANIZADO-->
<!-- <div class="panel-menu" [ngClass]="{ 'desplegado': menuLateralDeplegado }"
  style="overflow-x: hidden; overflow-y: auto; height: calc(100% - 56px);">
  <div class="panel-menu-inner">
    <div class="bloque-innermenu-white">

      <div class="row" *ngIf="generales.length>0">
        <div class="col" style="margin-bottom: -15px; margin-top: 5px;">
          <div class="card-menu-desplegado">

            <div class="card-header">
              <h3>
                <label>{{ 'generales' | translate }}</label>
              </h3>
            </div>

            <div class="row" *ngFor="let campo of generales">
              <div class="col">
                <div class="form-group switch-row">
                  <kendo-switch [onLabel]="' '" [offLabel]="' '" [(ngModel)]="campo.seleccionado"
                    [(ngClass)]="campo.cssSwitch" [disabled]="numeroCamposSeleccionados==5 && !campo.seleccionado"
                    (valueChange)="clickSwitch($event, campo)"></kendo-switch>
                  <label>{{ campo.nombre }}</label>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="row" *ngIf="ejes.length>0">
        <div class="col" style="margin-bottom: -15px; margin-top: 15px;">
          <div class="card-menu-desplegado">

            <div class="card-header" *ngIf="selectedMaquina.tipo_maquina == 1 || selectedMaquina.tipo_maquina == 2">
              <h3>
                <label *ngIf="selectedMaquina.tipo_maquina == 1">{{ 'ejes' | translate }}</label>
                <label *ngIf="selectedMaquina.tipo_maquina == 2">{{ 'capas' | translate }}</label>
              </h3>
            </div>

            <ng-container *ngFor="let eje of ejes">

              <div class="card-header">
                <h4>
                  <label>{{ eje.nombre }}</label>
                </h4>
              </div>

              <div class="row" *ngFor="let campo of eje.campos">
                <div class="col">
                  <div class="form-group switch-row">
                    <kendo-switch [onLabel]="' '" [offLabel]="' '" [(ngModel)]="campo.seleccionado"
                      [(ngClass)]="campo.cssSwitch" [disabled]="numeroCamposSeleccionados==5 && !campo.seleccionado"
                      (valueChange)="clickSwitch($event, campo)"></kendo-switch>
                    <label>{{ campo.nombre }}</label>
                  </div>
                </div>
              </div>

            </ng-container>

          </div>
        </div>
      </div>

    </div>
  </div>
</div> -->



<!-- <div class="card">
  <div class="card-body">

    <div class="row lineas-ejes-infteceje">
      <div class="col">
        <div class="bloques-info">
          <label class="bloques-info-label" style="margin-left: 40px;">{{ 'fecha' | translate }}:</label>
          <label>{{ myFunctions.dateToString(arrayFechas[indicePanelIzquierdo]) }}</label>
        </div>
      </div>
    </div>

    <div class="row" *ngFor="let eje of ejes">
      <div class="col-md-12">

        <div class="row lineas-ejes-infteceje">
          <div class="col">
            <div class="bloques-info doslineas">

              <label class="titulo-eje">{{eje.nombre}}</label>
            </div>
          </div>


          <div class="col" *ngFor="let campo of eje.campos">
            <div class="bloques-info doslineas">
              <label class="bloques-info-label">{{ campo.nombre }}:</label>
              <label class="bloques-info-valor">{{ campo.valoresMomentaneo[indicePanelIzquierdo] }} {{ campo.unidad
                }}</label>
            </div>
          </div>

        </div>

      </div>

    </div>
  </div>
</div>

<div class="card" *ngIf="generales.length > 0">
  <div class="card-body">
    <div class="row">
      <ng-container *ngFor="let campo of generales">
        <div class="col-6">

          <div class="row lineas-poten-infteceje">

            <div class="col">
              <div class="bloques-info">

                <label class="titulo-eje">{{campo.nombre}}</label>
              </div>

            </div>


            <div class="col">
              <div class="bloques-info doslineas">
                <label class="bloques-info-label">{{ campo.nombre }}:</label>
                <label class="bloques-info-valor">{{ campo.valoresMomentaneo[indicePanelIzquierdo] }} {{
                  campo.unidad }}</label>
              </div>
            </div>

          </div>
        </div>

      </ng-container>
    </div>
  </div>
</div> -->

<!-- POPUP MODIFICAR PARAMETRO -->
<ng-template #popupModificarParametro let-modal>
  <div class="modal-header">
    <h3 class="modal-title" id="modal-basic-title">{{ 'modificarParametro' | translate }}</h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>{{ 'parametro' | translate }}</label>
    <div class="caja">
      <kendo-dropdownlist [data]="listaParametros" [textField]="'parametro'" [valueField]="'idParametro'"
        [(value)]="parametro">
      </kendo-dropdownlist>
    </div>

    <label>{{ 'modificarValor' | translate }}</label>
    <div class="caja">
      <kendo-numerictextbox [style.width.px]="170" [(value)]="valorModificar" [min]="0" [autoCorrect]="true">
      </kendo-numerictextbox>
    </div>

    <div class="row">
      <div class="col">
        <label>{{ 'inicioPeriodo' | translate }}</label>
        <div class="example-config">
          <kendo-datetimepicker [min]="fechaInicio" [max]="fechaFin" [size]="size" [rounded]="rounded"
            [fillMode]="fillMode" [(value)]="periodoInicioParametro">
          </kendo-datetimepicker>
        </div>
      </div>
      <div class="col">
        <label>{{ 'finPeriodo' | translate }}</label>
        <div class="example-config">
          <kendo-datetimepicker [min]="fechaInicio" [max]="fechaFin" [size]="size" [rounded]="rounded"
            [fillMode]="fillMode" [(value)]="periodoFinParametro">
          </kendo-datetimepicker>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-md-center">
    <div class="col-4">
      <button type="button" class="float btn btn-primary" (click)="verificarGuardar()"
        [disabled]="this.user.turnos <2">{{
        'guardar' | translate }}</button>
    </div>
    <div class="col-4">
      <button type="button" class="float btn btn-danger" (click)="modal.dismiss('Cross click')"
        [disabled]="this.user.turnos <2">{{
        'cancelar' | translate }}</button>
    </div>
  </div>
</ng-template>

<!-- POPUP MODIFICAR PARAMETRO -->
<ng-template #popupVerificarGuardar let-modal>
  <div class="modal-header">
    <h3 class="modal-title" id="modal-basic-title">{{ 'modificarParametro' | translate }}</h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="row justify-content-md-center">
    <div class="col-md-auto">
      <label>{{ 'preguntaguardarvalores' | translate}}</label>
    </div>
  </div>

  <div class="row justify-content-md-center">
    <div class="float col-4">
      <button type="button" class="btn btn-primary" (click)="guardarParametro()" [disabled]="this.user.turnos <2">{{
        'si' | translate }}</button>
    </div>
    <div class="float col-4">
      <button type="button" class="btn btn-danger" (click)="cerrarTodo()" [disabled]="this.user.turnos <2">{{
        'no' | translate }}</button>
    </div>
  </div>

</ng-template>