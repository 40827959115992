import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CentroMecanizadoService, MenuService, UsuariosService, HmiService, MaquinasService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from "@angular/router";
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { MyFunctions } from '@app/_helpers';
import { GaugesModule } from '@progress/kendo-angular-gauges';

import * as moment from 'moment';

import * as d3 from 'd3';
import * as c3 from 'c3';

@Component({ templateUrl: 'hmi.html' })

export class HMIComponent implements OnInit {
  interval: any;
  interval2: any;
  interval3: any;
  interval4: any;
  bits: number = 0;
  img1: string = "";
  img2: string = "";
  maquina: any;
  maquina2: any;
  idmaquina: any;
  canales: any = [{}];
  ejecuciones: any;
  ejes: any;
  tiempoReal: any;
  tiempoPorSemana: any;
  user = this.usuariosService.userValue;
  eje1: Boolean;
  eje2: Boolean;
  eje3: Boolean;
  eje4: Boolean;

  maquinasModel: any;
  marcasModel: any;

  maxPotenciometro1: any = 100;
  maxPotenciometro2: any = 100;
  valorPotenciometro1: any = 0;
  valorPotenciometro2: any = 0;
  dosPotenciometros: any = true;

  resumenSemanaGrafico: any;

  datasetPotenciometro1: any;
  endAngleGlobal1: any;
  path1: any;
  patharc1: any;
  datasetPotenciometro2: any;
  endAngleGlobal2: any;
  path2: any;
  patharc2: any;

  historicoProcesos: any;
  alarmas: any;
  planificados: any;

  mantenimientoPorFecha: any;
  mantenimientoPorTiempo: any;
  loadingPorFecha: any;
  loadingPorTiempo: any;

  notas: any;

  herramientasCabezal: any;
  herramientas: any;
  roturasCambiosHerramientas: any;

  datosGraficoArray: any = [{}];
  datosGraficoArray2: any;

  tiempoMedioPieza: number = 0;
  desviacionPieza: number = 0;
  desviacionLote: number = 0;
  tiempoEstimadoLoteAux: any;
  tiempoEstimadoLote: number = 0;
  signoDesviacionPieza: string = '';
  signoDesviacionLote: string = '';
  tiempoAcumuladoAux: any;
  tiempoAcumulado: number = 0;
  listaTiempo: any = [];
  listaTiempo2: any = [];
  noProceso: string = "";

  segundosDeUnDia: number = 86400;

  tiempoActualPieza: string = "";
  tiempoActualLote: string = "";
  tiempoLotePreparacion: string = "";
  tiempoPiezaPreparacion: string = "";
  tiempoDesvioPreparacion: string = "";
  preparacionimgMap: string = "";
  preparacionPorcentajeMap: string = "";



  piezaTiempoMedio: string = "";
  piezaDesviacion: string = "";
  loteDesviacion: string = "";
  tiempoTotal: string = "";

  tiempoEstAux: any;
  tiempoPredAux: any;
  piezaEst: string = "";
  piezaPred: string = "";
  loteEst: string = "";
  lotePred: string = "";

  piezaPorcentaje: string = "";
  piezaimg: string = "";
  lotePorcentaje: string = "";
  isOF: boolean;

  // TIEMPOS NUEVOS
  public r1: boolean = false;
  public unicaEjecucionTiempos: boolean = true;
  public tiempoPiezaSegundos: number = -1;
  public tiempoLoteSegundos: number = -1;
  public tiempoLotePreparacionSegundos: number = -1;
  public tiempoPiezaPreparacionSegundos: number = -1;
  public loadingDatosTiempo: boolean = true;
  public cambioContador: number;
  public cambioIdOperacion: number;
  public timeOutTiempos: any;
  public maxContadorTerminado: number = 0;

  constructor(private usuariosService: UsuariosService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private centroMecanizadoService: CentroMecanizadoService,
    private hmiService: HmiService,
    public translateService: TranslateService,
    private maquinasService: MaquinasService,
    private myFunctions: MyFunctions) {

    this.interval = setInterval(() => {
      this.refreshData();
    }, 5000);

    this.interval2 = setInterval(() => {
      this.refreshDataPestanas();
    }, 300000);

  }

  ngOnInit(): void {

    this.menuService.titulo = this.translateService.instant('hmi');
    this.idmaquina = parseInt(this.route.snapshot.paramMap.get("id"));
    this.maquina = {
      css: "AAAAAAAAAAA",
      ejecuciones: [{ refOf: "", cliente: "", pieza: "", parte: "", operacion: "", realizadas: "", repetidas: "", procesos_Tipo: "", descripcion: "" }],
      tiempoReal: [],

    };
    this.calcularTiempos()
    this.cargarMaquinasModel();
  }

  cargarMaquinasModel() {
    var r1, r2: boolean = false;

    //MAQUINAS
    var maquinas_model = this.maquinasService.get_maquinas_model();
    if (maquinas_model == false) {
      this.maquinasService.get().subscribe(json => {
        this.maquinasService.set_maquinas_model(json);
        this.maquinasModel = this.maquinasService.get_maquinas_model();
        r1 = true;
        if (r1 && r2) {
          this.cargarTodosLosDatos();
        }
      })
    } else {
      this.maquinasModel = maquinas_model;
      r1 = true;
      if (r1 && r2) {
        this.cargarTodosLosDatos();
      }
    }

    //MARCAS
    var marcas_model = this.maquinasService.get_marcas_model();
    if (marcas_model == false) {
      this.maquinasService.getMarcas().subscribe(json => {
        this.maquinasService.set_marcas_model(json);
        this.marcasModel = this.maquinasService.get_marcas_model();
        r2 = true;
        if (r1 && r2) {
          this.cargarTodosLosDatos();
        }
      })
    } else {
      this.marcasModel = marcas_model;
      r2 = true;
      if (r1 && r2) {
        this.cargarTodosLosDatos();
      }
    }
  }

  cargarTodosLosDatos() {
    this.cargarDatos();
    this.cargarDatosProcesos();
    this.cargarDatosMantenimientos();
    this.cargarDatosNotas();
    this.cargarHerramientas();
    this.cargarGraficos();

  }

  cargarDatos() {

    this.centroMecanizadoService.getById(this.idmaquina).subscribe((result) => {

      var dataMaquinas = result.maquinas;
      this.maquina = dataMaquinas[0];

      this.ejecuciones = result.ejecuciones;
      this.r1 = true;
      this.canales = result.canales;
      this.ejes = result.ejes;
      this.tiempoReal = result.tiempoReal;
      this.tiempoPorSemana = result.tiempoPorSemana;

      this.maquina.ejecuciones = [];
      if (this.ejecuciones.length > 0) {
        if (this.ejecuciones[0].refOF == undefined || this.ejecuciones[0].refOF == "") {
          this.ejecuciones[0].refOF = this.translateService.instant("noproceso");
          this.ejecuciones[0].realizadas = "";
          this.noProceso = this.translateService.instant("noproceso");
        } else {
          this.ejecuciones[0].realizadas = this.ejecuciones[0].hechas + "/" + this.ejecuciones[0].cantidad;
        }
        this.maquina.ejecuciones = this.ejecuciones;
      } else {
        this.maquina.ejecuciones.push({ procesos_Tipo: "apagada", refof: this.translateService.instant("noproceso"), realizadas: '', eje: 0, pre: 0, mant: 0, alarma: 0, apagado: 0, total: 0 });
      }

      this.CargarCssEstadoMaquina(this.maquina);
      this.CargarIconosEstadoMaquina(this.maquina);

      if (this.ejecuciones[0] != null) {
        this.maquina.operario = this.ejecuciones[0].operario;
        if (this.maquina.operario == " ") this.maquina.operario = this.translateService.instant('sinOperario');
      }

      if (this.ejes.length > 0) {
        for (let index = 0; index < this.ejes.length; index++) {

          this.ejes[index].divgrafico = "grafico_" + (index + 1) + "_maquina_" + this.maquina.id;
          try {
            var rpm = this.tiempoReal[index].rpm;
            this.ejes[index].rpm = this.labelFeed(rpm);
            this.maquina.potenciometrostr = this.tiempoReal[0].potenciometro;
            this.maquina.potenciometrostr2 = this.tiempoReal[0].potenciometro2;
            if (this.tiempoReal != undefined && this.tiempoReal[index].rpm != undefined) {
              this.ejes[index].valorGrafico = this.calcularPorcentaje(rpm - this.ejes[index].feedMinimo, 48, this.ejes[index].feedMaximo - this.ejes[index].feedMinimo, 0, true);
            }
          }
          catch (exception) {

          }
        }

        if (this.tiempoReal.len > 0) {
          var poten1 = this.tiempoReal[0].potenciometro;
          var poten2 = this.tiempoReal[0].potenciometro2;

          if (poten1 != undefined) {
            this.maquina.potenciometro = this.calcularPorcentaje(poten1 - this.ejes[0].potenMin, 48, this.ejes[0].potenMax - this.ejes[0].potenMin, 0, true);
          }


          if (poten2 != undefined) {
            this.maquina.potenciometro2 = this.calcularPorcentaje(poten2 - this.ejes[0].potenMin, 48, this.ejes[0].potenMax - this.ejes[0].potenMin, 0, true);
          }
        }

        for (let index = this.ejes.length - 1; index < 4; index++) {
          try {
            this.ejes.push({
              tempvisible: false,
              valorGrafico: 0,
              potenciometro: 0,
              potenciometro2: 0
            });
          }
          catch (exception) {
          }
        }
      } else {
        this.ejes.push({
          tempvisible: false,
          valorGrafico: 0,
          potenciometro: 0,
          potenciometro2: 0,
        });
        this.ejes.push({
          tempvisible: false,
          valorGrafico: 0,
          potenciometro: 0,
          potenciometro2: 0,
        });
        this.ejes.push({
          tempvisible: false,
          valorGrafico: 0,
          potenciometro: 0,
          potenciometro2: 0,
        });
        this.ejes.push({
          tempvisible: false,
          valorGrafico: 0,
          potenciometro: 0,
          potenciometro2: 0,
        });
      }

      var maquinaActaulModel = this.maquinasModel.find(x => x.id === this.maquina.id);
      var marcaActaulModel = this.marcasModel.find(x => x.id === this.maquina.idMarca);
      this.maquina.logo = maquinaActaulModel.imagenBase64;
      if (marcaActaulModel != undefined){
        this.maquina.marca = marcaActaulModel.imagenBase64;
      }else{
        this.maquina.marca = '';
      }
      

      this.maquina.ejesActivos = (this.ejes == undefined) ? 0 : this.ejes.length;
      this.maquina.canales = this.canales;
      this.maquina.ejes = this.ejes;
      this.maquina.tiempoReal = this.tiempoReal;

      if (this.ejecuciones[0].contador != undefined && this.ejecuciones[0].hechas != undefined) {
        this.maxContadorTerminado = Math.max(this.ejecuciones[0].contador, this.ejecuciones[0].hechas);
      } else {
        this.maxContadorTerminado = 0;
      }


      this.calcularCanales(this.maquina, 0);
      this.CargarIconosEjes(this.maquina);

      //RESUMEN SEMANA
      if (this.tiempoPorSemana.length == 0) {
        this.tiempoPorSemana.push({ porcentaje: 0 });
        this.tiempoPorSemana.push({ porcentaje: 0 });
        this.tiempoPorSemana.push({ porcentaje: 0 });
        this.tiempoPorSemana.push({ porcentaje: 0 });
        this.tiempoPorSemana.push({ porcentaje: 0 });
        this.tiempoPorSemana.push({ porcentaje: 0 });
        this.tiempoPorSemana.push({ porcentaje: 0 });
      }
      this.maquina.tiempoPorSemana = this.tiempoPorSemana;

      this.resumenSemanaGrafico = c3.generate({
        bindto: '#resumenSemanaGrafico',
        size: {
          height: 122
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
        data: {
          columns: [
            [this.translateService.instant("ejecucion"), this.maquina.tiempoPorSemana[0].porcentaje],
            [this.translateService.instant("parada"), this.maquina.tiempoPorSemana[1].porcentaje],
            [this.translateService.instant("microparada"), this.maquina.tiempoPorSemana[2].porcentaje],
            [this.translateService.instant("preparacion"), this.maquina.tiempoPorSemana[3].porcentaje],
            [this.translateService.instant("mantenimiento"), this.maquina.tiempoPorSemana[4].porcentaje],
            [this.translateService.instant("alarma"), this.maquina.tiempoPorSemana[5].porcentaje],
            [this.translateService.instant("apagada"), this.maquina.tiempoPorSemana[6].porcentaje]
          ],
          type: 'bar',
          order: null
        },
        transition: {
          duration: 500
        },
        color: {
          pattern: ['#c0eada', '#e7cb68', '#cf8729', '#096844', '#99afc6', '#d33737', '#424242']
        },
        axis: {
          y: {
            show: false
          },
          x: {
            show: false
          }
        },
        bar: {
          width: {
            ratio: 1
          },
          space: 0.1
        },
        tooltip: {
          format: {
            title: function (d) { return 'Resumen semana' },
            value: function (value, ratio, id) {
              return value + '%';
            }
          }
        }
      });
      this.cargarProgramas();
      if (this.maquina.tiempoReal.length > 0) this.cargarPotenciometros();

      if (this.maquina.ejecuciones[0].procesos_Tipo != 'apagada' && this.maquina.ejecuciones[0].refOF != this.translateService.instant("noproceso")) {
        this.calcularTiempoEstPredPieza(this.maquina);
        this.calcularDesviacionPieza(this.maquina);
        this.calcularDesviacionLote(this.maquina);
      } else {
        this.datosMaquinaVaciar(this.maquina);
      }
    });
  }

  cargarProgramas() {
    if (this.ejecuciones[0].textoPrograma != null && this.ejecuciones[0].textoPrograma != "") { //hay programa
      this.maquina.programaTitulo = this.ejecuciones[0].nombreprograma + " - " + this.translateService.instant("linea") + ": " + this.tiempoReal[0].lineaPrograma;
      this.maquina.programaTexto = "";
      var programaEnsenar = this.ejecuciones[0].textoPrograma.split('rn');
      var i;
      for (i = 0; i < programaEnsenar.length; i++) {
        this.maquina.programaTexto = this.maquina.programaTexto + '<span>' + (this.tiempoReal[0].lineaPrograma + i) + ". &nbsp;&nbsp; </span>" + programaEnsenar[i] + "<br/>";
      }
    } else { //no hay programa
      this.maquina.programaTitulo = this.translateService.instant("nohaydatosprograma");
      this.maquina.programaTexto = "";
    }
  }

  cargarPotenciometros() {

    console.log("________________________________________")
    console.log(this.tiempoReal)
    
    this.maxPotenciometro1 = this.ejecuciones[0].potenMax;
    this.valorPotenciometro1 = this.tiempoReal[0].potenciometro;

    this.maxPotenciometro2 = this.ejecuciones[0].potenmax2;
    this.valorPotenciometro2 = this.tiempoReal[0].potenciometro2;

    var numPotenciometros = 0;

    if (this.valorPotenciometro1 != undefined) {
      numPotenciometros = numPotenciometros + 1;
    }
    if (this.valorPotenciometro2 != undefined) {
      numPotenciometros = numPotenciometros + 1;
    }

    if (numPotenciometros == 0 || numPotenciometros == 1) {
      this.dosPotenciometros = false;
    } else if (numPotenciometros == 2) {
      this.dosPotenciometros = true;
    }

  }

  labelFeed(d: number) {

    var s = "";
    var spl = d.toString().split(",");
    if (d.toString().indexOf(".") > 0)
      spl = d.toString().split(".");

    var decimales = 0;
    if (d >= 1000)
      decimales = 0;
    else if (d >= 100)
      decimales = 1;
    else
      decimales = 2;

    if (spl.length > 1) {
      s = spl[0]
      if (decimales > 0) {
        if (spl[1].length >= 2)
          s = s + "," + spl[1].substring(0, decimales);
        else if (spl[1].length == 1 && decimales == 2)
          s = s + "," + spl[1].substring(0, 1) + "0";
        else if (spl[1].length == 1 && decimales == 1)
          s = s + "," + spl[1].substring(0, 1)
      }
    } else {
      s = spl[0];
      switch (decimales) {
        case 1: s = s + ",0"; break;
        case 2: s = s + ",00"; break;
      }
    }
    return s;
  }

  cargarTiempos(valor: number, sobre: number, de: number, decimales: number, limitado: boolean) {
    var porcentaje = 0.0;
    if (de != 0) {
      var d = 10 ^ decimales;
      var porcen = 0;
      porcen = valor * sobre * d / de;
      porcen = Math.round(porcen);
      if (porcen > sobre * d && limitado)
        porcen = sobre * d;
      porcentaje = Math.round(porcen / d);
    } else {
      if (valor > 0 && limitado)
        porcentaje = sobre
      else if (!limitado)
        porcentaje = -1;
    }
    if (porcentaje < 0)
      porcentaje = 0;

    return porcentaje;
  }

  CargarCssEstadoMaquina(maquina) {
    maquina.css = "pestañaprocesos color" + maquina.ejecuciones[0].procesos_Tipo.toString().toLowerCase();
    maquina.ejecss = "estado-" + maquina.ejecuciones[0].procesos_Tipo.toString().toLowerCase();
  }

  calcularPorcentaje(valor: number, sobre: number, de: number, decimales: number, limitado: boolean) {
    var porcentaje = 0.0
    if (de != 0) {
      var d = 10 ^ decimales;
      var porcen = 0;
      porcen = valor * sobre * d / de;
      porcen = Math.round(porcen);
      if (porcen > sobre * d && limitado)
        porcen = sobre * d;
      porcentaje = Math.round(porcen / d);
    }
    else {
      if (valor > 0 && limitado)
        porcentaje = sobre;
      else if (!limitado) {
        porcentaje = -1;
      }
    }
    if (porcentaje < 0)
      porcentaje = 0;
    return porcentaje;
  }

  calcularCanales(maquina: any, i) {
    var horas: any = [];
    if (this.router.url == '/procesos/' + this.idmaquina && i < 5)
      setTimeout((d) => this.calcularCanales(maquina, i + 1), 1000);

    maquina.eje1 = true;
    maquina.eje2 = false;
    maquina.eje3 = false;
    maquina.eje4 = false;

    if (maquina.ejecuciones != undefined && maquina.ejecuciones.length > 0) {
      maquina.enEjecucion = false;
    }

    maquina.procesos_Tipostr = this.translateService.instant(maquina.ejecuciones[0].procesos_Tipo);
    maquina.programa = maquina.ejecuciones[0].nombreprograma;

    var ahora = new Date(Date.parse(maquina.ejecuciones[0].fechaServidor));//this.myFunctions.getDateNow();
    //Le sumamos un segundo a la hora
    var dateAux = new Date(Date.parse(maquina.ejecuciones[0].fechaServidor));
    dateAux.setSeconds(dateAux.getSeconds() + 1);
    maquina.ejecuciones[0].fechaServidor = dateAux

    var fechaOrig = new Date(Date.parse(maquina.ejecuciones[0].fechainicio));
    var hanpasado = this.HanPasadoDias(fechaOrig, ahora, 0);
    var hapasadoTiempo = this.HanPasadoTiempo(fechaOrig, ahora, 0);
    var hanpasadoConAcumulado = this.HanPasadoDias(fechaOrig, ahora, maquina.ejecuciones[0].tiempoAcumuladoOperacion);
    var hapasadoTiempoConAcumulado = this.HanPasadoTiempo(fechaOrig, ahora, maquina.ejecuciones[0].tiempoAcumuladoOperacion);
    if (maquina.ejecuciones[0].procesos_Tipo != undefined && maquina.ejecuciones[0].procesos_Tipo.toLowerCase().trim() == "ejecucion") {
      maquina.enEjecucion = true;
    }

    maquina.tiempoPred = this.myFunctions.secondsTo_HH_MM_SS(maquina.ejecuciones[0].tiempoPredSegundos);
    maquina.tiempoEst = this.myFunctions.secondsTo_HH_MM_SS(maquina.ejecuciones[0].tiempoEstimadoSegundos);

    // if (hanpasado > 0) {
    //   horas = hapasadoTiempo.toString().split(":");
    //   //this.tiempoActualPieza.set(maquina.id, hanpasado.toString() + "d " + horas[0] + "h");
    //   maquina.tiempopasado = hanpasado.toString() + " " + this.translateService.instant("numdiashome") + " " + hapasadoTiempo.toString();
    // } else {
    maquina.tiempopasado = hapasadoTiempo.toString();
    //this.tiempoActualPieza.set(maquina.id, hapasadoTiempo.toString());
    // }

    this.tiempoActualPieza = '00:00:00';
    if (maquina.ejecuciones[0].contador == 0) {
      this.tiempoActualLote = this.segundosAFormatoHora(this.tiempoLoteSegundos);
      this.tiempoActualPieza = this.tiempoActualLote;
      this.tiempoLotePreparacion = this.segundosAFormatoHora(this.tiempoLotePreparacionSegundos);
      this.tiempoPiezaPreparacion = this.segundosAFormatoHora(this.tiempoPiezaPreparacionSegundos);
    } else {

      // if (maquina.ejecuciones[0].horaFinUltimaPieza != -1) {
      //   var ultimaPiezaHora = new Date(maquina.ejecuciones[0].horaFinUltimaPieza).getTime();
      //   var tiempoPieza = new Date().getTime() - ultimaPiezaHora;
      //   this.tiempoActualPieza.set(maquina.id, this.segundosAFormatoHora(Math.floor(tiempoPieza / 1000)));
      // } else {
      //   this.tiempoActualPieza.set(maquina.id, '00:00:00')
      // }
      if (this.ejecuciones[0].idHistorico_operaciones != -1) {
        if (this.tiempoLoteSegundos == -1 || !this.r1) {
          this.tiempoActualLote = '--:--:--';
          this.tiempoLotePreparacion = '--:--:--';
          this.tiempoPiezaPreparacion = '--:--:--';
        } else {
          this.tiempoPiezaSegundos = this.tiempoPiezaSegundos + 1;
          this.tiempoLoteSegundos = this.tiempoLoteSegundos + 1;
          this.tiempoLotePreparacionSegundos = this.tiempoLotePreparacionSegundos + 1;
          this.tiempoPiezaPreparacionSegundos = this.tiempoPiezaPreparacionSegundos + 1;
          this.tiempoActualLote = this.segundosAFormatoHora(this.tiempoLoteSegundos);
          this.tiempoLotePreparacion = this.segundosAFormatoHora(this.tiempoLotePreparacionSegundos);
          this.tiempoPiezaPreparacion = this.segundosAFormatoHora(this.tiempoPiezaPreparacionSegundos);
        }
        if (this.tiempoPiezaSegundos == -1 || !this.r1) {
          this.tiempoActualPieza = '--:--:--';
        } else {
          var ultimaPiezaHora = new Date(maquina.ejecuciones[0].horaFinUltimaPieza).getTime();
          var tiempoPieza = new Date().getTime() - ultimaPiezaHora;
          this.tiempoActualPieza = this.segundosAFormatoHora(this.tiempoPiezaSegundos);
        }
      }

    }

    if (this.ejecuciones[0].idHistorico_operaciones == -1) {
      if (this.mouseOverPorcentajeId != maquina.id) {
        if (hanpasadoConAcumulado > 0) {
          horas = hapasadoTiempoConAcumulado.toString().split(":");
          this.tiempoActualLote = maquina.id, hanpasadoConAcumulado.toString() + "d " + horas[0] + "h";
          maquina.tiempopasadoConAcumulado = hanpasadoConAcumulado.toString() + " " + this.translateService.instant("numdiashome") + " " + hapasadoTiempoConAcumulado.toString();
        }

        else {
          maquina.tiempopasadoConAcumulado = hapasadoTiempoConAcumulado.toString();
          this.tiempoActualLote = maquina.id, hapasadoTiempoConAcumulado.toString();
        }
      }
    }

    if (this.maquina.ejecuciones[0].procesos_Tipo != 'apagada' && this.maquina.ejecuciones[0].refOF != this.translateService.instant('noproceso')) {
      this.calcularTiempoEstPredPieza(maquina);
      this.calcularDesviacionPieza(maquina);
      this.calcularDesviacionLote(maquina);
    } else {
      this.datosMaquinaVaciar(this.maquina);
    }

    this.CargarIconosEstadoMaquina(maquina);
  }

  calcularTiempos() {

    if (this.r1) {
      if (this.unicaEjecucionTiempos) {
        this.unicaEjecucionTiempos = false;
        this.centroMecanizadoService.get_Tiempos_Lote_Pieza_byId(this.idmaquina).subscribe(result => {
          var datosTiempo: any = result;
          var tieneTiempo: boolean = false;

          if (this.ejecuciones[0]?.idHistorico_operaciones == datosTiempo[0]?.idHistoricoOperaciones) {
            this.tiempoPiezaSegundos = datosTiempo[0].tiempoUltimaPieza;
            this.tiempoLoteSegundos = datosTiempo[0].tiempoLote;
            this.tiempoLotePreparacionSegundos = datosTiempo[0].tiempoLotePrep;
            this.tiempoPiezaPreparacionSegundos = datosTiempo[0].tiempoUltimaPiezaPrep;
            this.cambioContador = this.ejecuciones[0].contador;
            this.cambioIdOperacion = datosTiempo[0].idHistoricoOperaciones
          } else {
            this.tiempoPiezaSegundos = -1;
            this.tiempoLoteSegundos = -1;
            this.tiempoLotePreparacionSegundos = -1;
            this.tiempoPiezaPreparacionSegundos = -1;
          }
          //this.loadingDatosTiempo = false;
          setTimeout((d) => this.loadingDatosTiempo = false, 2000);
          this.unicaEjecucionTiempos = true;
        });
        if (this.router.url == '/procesos/' + this.idmaquina) {
          this.timeOutTiempos = setTimeout((d) => this.calcularTiempos(), 60000);
        }
      }

    } else {
      setTimeout((d) => this.calcularTiempos(), 1000);
    }

  }

  CargarIconosEjes(maquina: any) {
    maquina.alarmas1 = {
      temperatura: {
        mostrar: false
      },
      warning:
      {
        mostrar: false
      }
    };

    maquina.alarmas2 = {
      temperatura: {
        mostrar: false
      },
      warning:
      {
        mostrar: false
      }
    }

    maquina.alarmas3 = {
      temperatura: {
        mostrar: false
      },
      warning:
      {
        mostrar: false
      }
    }

    maquina.alarmas4 = {
      temperatura: {
        mostrar: false
      },
      warning:
      {
        mostrar: false
      }
    }

    if (maquina.ejes.length > 0) {
      try {
        var tempalarm1 = maquina.ejes[0].tempMax;
        var tempalerta1 = maquina.ejes[0].tempAlarm;
        var consumAlerta1 = maquina.ejes[0].consumAlarm;
        var consumAlarma1 = maquina.ejes[0].consumMax;
      }
      catch (ex) {

      }

      try {
        var tempalarm2 = maquina.ejes[1].tempMax;
        var tempalerta2 = maquina.ejes[1].tempAlarm;
        var consumAlerta2 = maquina.ejes[1].consumAlarm;
        var consumAlarma2 = maquina.ejes[1].consumMax;
      }
      catch (ex) {

      }
      try {
        var tempalarm3 = maquina.ejes[2].tempMax;
        var tempalerta3 = maquina.ejes[2].tempAlarm;
        var consumAlerta3 = maquina.ejes[2].consumAlarm;
        var consumAlarma3 = maquina.ejes[2].consumMax;
      }
      catch (ex) {

      }
      try {
        var tempalarm4 = maquina.ejes[3].tempMax;
        var tempalerta4 = maquina.ejes[3].tempAlarm;
        var consumAlerta4 = maquina.ejes[3].consumAlarm;
        var consumAlarma4 = maquina.ejes[3].consumMax;
      }
      catch (ex) {

      }
      try {

        var temp1, temp2, temp3, temp4, consum1, consum2, consum3, consum4;
        if (maquina.tiempoReal[0] != undefined)
          temp1 = maquina.tiempoReal[0].temp;
        else
          temp1 = 0;

        if (maquina.tiempoReal[1] != undefined)
          temp2 = maquina.tiempoReal[1].temp;
        else
          temp2 = 0;

        if (maquina.tiempoReal[2] != undefined)
          temp3 = maquina.tiempoReal[2].temp;
        else
          temp3 = 0;

        if (maquina.tiempoReal[3] != undefined)
          temp4 = maquina.tiempoReal[3].temp;
        else
          temp4 = 0;

      }
      catch (ex) {

      }

      try {
        if (maquina.tiempoReal[0] != undefined)
          consum1 = maquina.tiempoReal[0].consumo;
        else
          consum1 = 0;

        if (maquina.tiempoReal[1] != undefined)
          consum2 = maquina.tiempoReal[1].consumo;
        else
          consum2 = 0;

        if (maquina.tiempoReal[2] != undefined)
          consum3 = maquina.tiempoReal[2].consumo;
        else
          consum3 = 0;

        if (maquina.tiempoReal[3] != undefined)
          consum4 = maquina.tiempoReal[3].consumo;
        else
          consum4 = 0;
      }
      catch (ex) {

      }

      try {
        if (temp1 > tempalerta1 && tempalarm1 != -1) {
          maquina.ejes[0].tempvisible = true;
          maquina.ejes[0].logoalarmatemp = "assets/tempAmarillo.png";
          if (temp1 > tempalarm1) {
            maquina.ejes[0].logoalarmatemp = "assets/tempRojo.png";
          }
        }

        if (consum1 > consumAlerta1 && consumAlarma1 != -1) {
          maquina.ejes[0].consumvisible = true;
          maquina.ejes[0].logoalarmaconsum = "assets/warningAmarillo.png";
          if (consum1 > consumAlarma1) {
            maquina.ejes[0].logoalarmaconsum = "assets/warningRojo.png";
          }
        }
      }
      catch (ex) {

      }

      try {
        if (temp2 > tempalerta2 && tempalarm2 != -2) {
          maquina.ejes[1].tempvisible = true;
          maquina.ejes[1].logoalarmatemp = "assets/tempAmarillo.png";
          if (temp2 > tempalarm2) {
            maquina.ejes[1].logoalarmatemp = "assets/tempRojo.png";
          }
        }

        if (consum2 > consumAlerta2 && consumAlarma2 != -2) {
          maquina.ejes[1].consumvisible = true;
          maquina.ejes[1].logoalarmaconsum = "assets/warningAmarillo.png";
          if (consum2 > consumAlarma2) {
            maquina.ejes[1].logoalarmaconsum = "assets/warningRojo.png";
          }
        }
      }
      catch (ex) {

      }

      try {
        if (temp3 > tempalerta3 && tempalarm3 != -3) {
          maquina.ejes[2].tempvisible = true;
          maquina.ejes[2].logoalarmatemp = "assets/tempAmarillo.png";
          if (temp3 > tempalarm3) {
            maquina.ejes[2].logoalarmatemp = "assets/tempRojo.png";
          }
        }

        if (consum3 > consumAlerta3 && consumAlarma3 != -3) {
          maquina.ejes[2].consumvisible = true;
          maquina.ejes[2].logoalarmaconsum = "assets/warningAmarillo.png";
          if (consum3 > consumAlarma3) {
            maquina.ejes[2].logoalarmaconsum = "assets/warningRojo.png";
          }
        }
      }
      catch (ex) {

      }

      try {
        if (temp4 > tempalerta4 && tempalarm4 != -4) {
          maquina.ejes[3].tempvisible = true;
          maquina.ejes[3].logoalarmatemp = "assets/tempAmarillo.png";
          if (temp4 > tempalarm4) {
            maquina.ejes[3].logoalarmatemp = "assets/tempRojo.png";
          }
        }

        if (consum4 > consumAlerta4 && consumAlarma4 != -4) {
          maquina.ejes[3].consumvisible = true;
          maquina.ejes[3].logoalarmaconsum = "assets/warningAmarillo.png";
          if (consum4 > consumAlarma4) {
            maquina.ejes[3].logoalarmaconsum = "assets/warningRojo.png";
          }
        }
      }
      catch (ex) {

      }

    }
  }

  HanPasadoDias(fechaOrig, ahora, tiempoAcumuladoOperacion) {
    var dt1 = new Date(fechaOrig);
    var dt2 = new Date(ahora);
    var difSegundos = (dt2.getTime() - dt1.getTime()) / 1000;
    difSegundos = difSegundos + tiempoAcumuladoOperacion;
    return Math.floor(difSegundos / (60 * 60 * 24));
  }

  HanPasadoTiempo(fechaOrig, ahora, tiempoAcumuladoOperacion) {
    let diffInMilliSeconds = Math.abs(fechaOrig - ahora) / 1000;
    diffInMilliSeconds = diffInMilliSeconds + tiempoAcumuladoOperacion;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600);
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    // calculate minutes
    const seconds = Math.floor(diffInMilliSeconds % 60);
    diffInMilliSeconds -= minutes * 60;

    let difference = '';

    if (hours < 10) difference = "0" + hours.toString();
    else difference = hours.toString();

    difference = difference + ":";
    if (minutes < 10) difference = difference + "0" + minutes.toString();
    else difference = difference + minutes.toString();

    difference = difference + ":";
    if (seconds < 10) difference = difference + "0" + seconds.toString();
    else difference = difference + seconds.toString();

    return difference;
  }

  CargarIconosEstadoMaquina(maquina) {

    var tipoProceso = maquina.ejecuciones[0].procesos_Tipo; //"apagada", "parada", "ejecucion", "preparacion" o "mantenimiento" o "alarma"

    // if (maquina.ejecuciones[0].tiempoEstimadoSegundos == 0) {
    if (this.tiempoLoteSegundos < 1) {
      maquina.estadoimg = "assets/dcg/dcg12-" + tipoProceso + ".png";
      this.piezaimg = "assets/dcg/dcg12-" + tipoProceso + ".png";
      maquina.porcentaje = "";
    } else {
      var ahora = new Date(Date.parse(maquina.ejecuciones[0].fechaServidor));//this.myFunctions.getDateNow();
      var fechaOrig = new Date(Date.parse(maquina.ejecuciones[0].fechainicio));
      var tiempoAcumuladoOperacion = maquina.ejecuciones[0].tiempoAcumuladoOperacion;

      var dif = ahora.getTime() - fechaOrig.getTime();

      var Seconds_from_T1_to_T2 = dif / 1000;
      Seconds_from_T1_to_T2 = Seconds_from_T1_to_T2 + tiempoAcumuladoOperacion;
      var segundosfiff = Math.floor(Seconds_from_T1_to_T2);

      if (maquina.ejecuciones[0].procesos_Tipo != 'apagada') {
        var docentaje = this.calcularPorcentaje(segundosfiff, 12, maquina.ejecuciones[0].tiempoEstimadoSegundos, 1, true);
        var porcentaje = this.calcularPorcentaje(segundosfiff, 100, maquina.ejecuciones[0].tiempoEstimadoSegundos, 1, true);

        var preparacionDocentaje = this.calcularPorcentaje(this.tiempoLotePreparacionSegundos, 12, maquina.ejecuciones[0].tiempoPreparacionSegundos, 1, true);
        var preparacionPorcentaje = this.calcularPorcentaje(this.tiempoLotePreparacionSegundos, 100, maquina.ejecuciones[0].tiempoPreparacionSegundos, 1, true);

        if (this.tiempoLoteSegundos < maquina.ejecuciones[0].tiempoEstimadoSegundos) {
          maquina.porcentaje = porcentaje + "%";
          maquina.estadoimg = "assets/dcg/dcg" + docentaje + "-" + tipoProceso + ".png";
        } else {
          maquina.porcentaje = "100%";
          maquina.estadoimg = "assets/dcg/dcg12-" + tipoProceso + ".png";
        }

        if (this.tiempoActualPieza != undefined) {
          if (!this.tiempoActualPieza.includes('-')) {
            if (this.tiempoActualPieza.includes('d')) {
              var tiempoPasadoPieza = this.tiempoFormatoDiasAMinutos(this.tiempoActualPieza) * 60;
              var piezaDocentaje = this.calcularPorcentaje(tiempoPasadoPieza, 12, this.tiempoPiezaSegundos, 1, true);
              var piezaPorcentaje = this.calcularPorcentaje(tiempoPasadoPieza, 100, this.tiempoPiezaSegundos, 1, true);
            } else if (this.tiempoActualPieza.includes(':')) {
              var tiempoPasadoPieza = this.tiempoFormatoHorasAMinutos(this.tiempoActualPieza) * 60;
              var piezaDocentaje = this.calcularPorcentaje(tiempoPasadoPieza, 12, this.tiempoPiezaSegundos, 1, true);
              var piezaPorcentaje = this.calcularPorcentaje(tiempoPasadoPieza, 100, this.tiempoPiezaSegundos, 1, true);
            }
            if (piezaDocentaje == undefined) {
              this.piezaimg = "assets/dcg/dcg12-" + tipoProceso + ".png";
              this.piezaPorcentaje = "%";
            } else {
              this.piezaimg = "assets/dcg/dcg" + piezaDocentaje + "-" + tipoProceso + ".png";
              this.piezaPorcentaje = piezaPorcentaje + "%";
            }

            this.lotePorcentaje = maquina.porcentaje;
          }
          this.preparacionimgMap = "assets/dcg/dcg" + preparacionDocentaje + "-preparacion.png";
          this.preparacionPorcentajeMap = preparacionPorcentaje + "%";
        }

      }


      var docentaje = this.calcularPorcentaje(segundosfiff, 12, maquina.ejecuciones[0].tiempoEstimadoSegundos, 1, true);
      var porcentaje = this.calcularPorcentaje(segundosfiff, 100, maquina.ejecuciones[0].tiempoEstimadoSegundos, 1, true);

      if (this.tiempoLoteSegundos < maquina.ejecuciones[0].tiempoEstimadoSegundos) {
        maquina.porcentaje = porcentaje + "%";
        maquina.estadoimg = "assets/dcg/dcg" + docentaje + "-" + tipoProceso + ".png";
      } else {
        maquina.porcentaje = "100%";
        maquina.estadoimg = "assets/dcg/dcg12-" + tipoProceso + ".png";
      }

    }

  }

  refreshData() {

    if (this.router.url == '/procesos/' + this.idmaquina) {

      this.centroMecanizadoService.getById(this.idmaquina).subscribe((result) => {

        var dataMaquinas = result.maquinas;
        this.maquina = dataMaquinas[0];

        this.ejecuciones = result.ejecuciones;
        this.canales = result.canales;
        this.ejes = result.ejes;
        this.tiempoReal = result.tiempoReal;
        this.tiempoPorSemana = result.tiempoPorSemana;


        if (this.ejecuciones.contador != this.cambioContador || this.ejecuciones.idHistorico_operaciones != this.cambioIdOperacion) {
          clearTimeout(this.timeOutTiempos);
          this.calcularTiempos();
        }

        this.maquina.ejecuciones = [];
        if (this.ejecuciones.length > 0) {
          if (this.ejecuciones[0].refOF == undefined || this.ejecuciones[0].refOF == "") {
            this.ejecuciones[0].refOF = this.translateService.instant("noproceso");
            this.ejecuciones[0].realizadas = "";
            this.noProceso = this.translateService.instant("noproceso");
          } else {
            this.ejecuciones[0].realizadas = this.ejecuciones[0].hechas + "/" + this.ejecuciones[0].cantidad;
          }
          this.maquina.ejecuciones = this.ejecuciones;
        } else {
          this.maquina.ejecuciones.push({ procesos_Tipo: "apagada", refof: this.translateService.instant("noproceso"), realizadas: '', eje: 0, pre: 0, mant: 0, alarma: 0, apagado: 0, total: 0 });
        }

        this.CargarCssEstadoMaquina(this.maquina);
        this.CargarIconosEstadoMaquina(this.maquina);

        if (this.ejecuciones[0] != null) {
          this.maquina.operario = this.ejecuciones[0].operario;
          if (this.maquina.operario == " ") this.maquina.operario = this.translateService.instant('sinOperario');
        }

        if (this.ejes.length > 0) {
          for (let index = 0; index < this.ejes.length; index++) {

            this.ejes[index].divgrafico = "grafico_" + (index + 1) + "_maquina_" + this.maquina.id;
            try {
              var rpm = this.tiempoReal[index].rpm;
              this.ejes[index].rpm = this.labelFeed(rpm);
              this.maquina.potenciometrostr = this.tiempoReal[0].potenciometro;
              this.maquina.potenciometrostr2 = this.tiempoReal[0].potenciometro2;
              if (this.tiempoReal != undefined && this.tiempoReal[index].rpm != undefined) {
                this.ejes[index].valorGrafico = this.calcularPorcentaje(rpm - this.ejes[index].feedMinimo, 48, this.ejes[index].feedMaximo - this.ejes[index].feedMinimo, 0, true);
              }
            }
            catch (exception) {

            }
          }

          if (this.tiempoReal.len > 0) {
            var poten1 = this.tiempoReal[0].potenciometro;
            var poten2 = this.tiempoReal[0].potenciometro2;

            if (poten1 != undefined) {
              this.maquina.potenciometro = this.calcularPorcentaje(poten1 - this.ejes[0].potenMin, 48, this.ejes[0].potenMax - this.ejes[0].potenMin, 0, true);
            }

            if (poten2 != undefined) {
              this.maquina.potenciometro2 = this.calcularPorcentaje(poten2 - this.ejes[0].potenMin, 48, this.ejes[0].potenMax - this.ejes[0].potenMin, 0, true);
            }
          }

          for (let index = this.ejes.length - 1; index < 4; index++) {

            try {
              this.ejes.push({
                tempvisible: false,
                valorGrafico: 0,
                potenciometro: 0,
                potenciometro2: 0
              });
            }
            catch (exception) {
            }
          }
        }
        else {
          this.ejes.push({
            tempvisible: false,
            valorGrafico: 0,
            potenciometro: 0,
            potenciometro2: 0,
          });
          this.ejes.push({
            tempvisible: false,
            valorGrafico: 0,
            potenciometro: 0,
            potenciometro2: 0,
          });
          this.ejes.push({
            tempvisible: false,
            valorGrafico: 0,
            potenciometro: 0,
            potenciometro2: 0,
          });
          this.ejes.push({
            tempvisible: false,
            valorGrafico: 0,
            potenciometro: 0,
            potenciometro2: 0,
          });

        }

        var maquinaActaulModel = this.maquinasModel.find(x => x.id === this.maquina.id);
        var marcaActaulModel = this.marcasModel.find(x => x.id === this.maquina.idMarca);
        this.maquina.logo = maquinaActaulModel.imagenBase64;
        if (marcaActaulModel != undefined){
          this.maquina.marca = marcaActaulModel.imagenBase64;
        }else{
          this.maquina.marca = '';
        }

        this.maquina.ejesActivos = (this.ejes == undefined) ? 0 : this.ejes.length;
        this.maquina.canales = this.canales;
        this.maquina.ejes = this.ejes;
        this.maquina.tiempoReal = this.tiempoReal;

        this.calcularCanales(this.maquina, 0);
        this.CargarIconosEjes(this.maquina);
        if (this.tiempoPorSemana.length == 0) {
          this.tiempoPorSemana.push({ porcentaje: 0 });
          this.tiempoPorSemana.push({ porcentaje: 0 });
          this.tiempoPorSemana.push({ porcentaje: 0 });
          this.tiempoPorSemana.push({ porcentaje: 0 });
          this.tiempoPorSemana.push({ porcentaje: 0 });
          this.tiempoPorSemana.push({ porcentaje: 0 });
          this.tiempoPorSemana.push({ porcentaje: 0 });
        }
        this.maquina.tiempoPorSemana = this.tiempoPorSemana;

        var tiempoPorSemanaData = [[this.translateService.instant("ejecucion"), this.maquina.tiempoPorSemana[0].porcentaje],
        [this.translateService.instant("parada"), this.maquina.tiempoPorSemana[1].porcentaje],
        [this.translateService.instant("microparada"), this.maquina.tiempoPorSemana[2].porcentaje],
        [this.translateService.instant("preparacion"), this.maquina.tiempoPorSemana[3].porcentaje],
        [this.translateService.instant("mantenimiento"), this.maquina.tiempoPorSemana[4].porcentaje],
        [this.translateService.instant("alarma"), this.maquina.tiempoPorSemana[5].porcentaje],
        [this.translateService.instant("apagada"), this.maquina.tiempoPorSemana[6].porcentaje]];

        this.resumenSemanaGrafico.load({ columns: tiempoPorSemanaData });

        this.cargarProgramas();

        if (this.maquina.tiempoReal.length > 0) this.cargarPotenciometros();

      });

      this.recargarGraficos();

    } else {
      clearInterval(this.interval);
    }

  }

  refreshDataPestanas() {

    if (this.router.url == '/procesos/' + this.idmaquina) {

      this.cargarDatosProcesos();
      this.cargarDatosMantenimientos();
      this.cargarDatosNotas();
      this.cargarHerramientas();

    } else {
      clearInterval(this.interval2);
    }

  }

  cargarDatosProcesos() {
    this.hmiService.Get_ProcesosHistorial(this.idmaquina).subscribe((result) => {
      var datuak: any = result;
      this.historicoProcesos = [];
      datuak.forEach(function (valor, indice, array) {
        var tipoProceso = "";
        if (valor.idProcesos_Tipo == 1) tipoProceso = this.translateService.instant("ejecucion");
        if (valor.idProcesos_Tipo == 2) tipoProceso = this.translateService.instant("parada");
        if (valor.idProcesos_Tipo == 3) tipoProceso = this.translateService.instant("preparacion");
        if (valor.idProcesos_Tipo == 4) tipoProceso = this.translateService.instant("mantenimiento");
        if (valor.idProcesos_Tipo == 6) tipoProceso = this.translateService.instant("alarma");
        if (valor.idProcesos_Tipo == 7) tipoProceso = this.translateService.instant("alarma");
        if (valor.idProcesos_Tipo == 8) tipoProceso = this.translateService.instant("apagado");
        this.historicoProcesos.push(
          {
            idProcesos_Tipo: valor.idProcesos_Tipo,
            diaini: valor.diaini,
            horaini: valor.horaini,
            diafin: valor.diafin,
            horafin: valor.horafin,
            tipo: tipoProceso,
            tipoAlarNombre: valor.descripcion,
            nombre: valor.nombrePrograma,
            OF: valor.of,
            tiemporeal: this.secondsTo_HH_MM_SS(valor.tiemporeal),
            tiempoteorico: this.secondsTo_HH_MM_SS(valor.tiempoPredictivo),
            cliente: valor.cliente,
            parte: valor.parte,
            pieza: valor.pieza,
            divcss: "css_linea_lateral_" + valor.idProcesos_Tipo,
          }
        );
      }, this);
    });

    this.hmiService.Get_ProcesosAlarmas(this.idmaquina).subscribe((result) => {
      var datuak: any = result;
      this.alarmas = [];
      datuak.forEach(function (valor, indice, array) {
        var divnombre;
        if (valor.idAlarma_tipo == 1) divnombre = "css_linea_lateral_2";
        else divnombre = "css_linea_lateral_6";
        this.alarmas.push(
          {
            diaini: valor.diaini,
            horaini: valor.horaini,
            diafin: valor.diafin,
            horafin: valor.horafin,
            numeroAlarma: valor.numeroAlarma,
            descripcion: valor.descripcion,
            divcss: divnombre,
          }
        );
      }, this);
    });

    this.hmiService.Get_Planificados(this.idmaquina).subscribe((result) => {
      var datuak: any = result;
      this.planificados = [];
      datuak.forEach(function (valor, indice, array) {
        this.planificados.push(
          {
            idProcesos_Tipo: valor.idProcesos_Tipo,
            diaini: valor.diaini,
            horaini: valor.horaini,
            diafin: valor.diafin,
            horafin: valor.horafin,
            operacion: valor.operacion,
            OF: valor.of,
            tiempoteorico: this.secondsTo_HH_MM_SS(valor.tiempoEstimado),
            programa: 123456,
            cliente: valor.cliente,
            parte: valor.parte,
            pieza: valor.pieza,
            divcss: "css_linea_lateral_8",
          }
        );
      }, this);
    });

  }

  abrirPdf(mantenimiento) {

    this.hmiService.GetPdf_MantenimientosRecientes(mantenimiento.enlacePdf).subscribe((result) => {

      const win = window.open("", "_blank");
      let html = '';

      html += '<html>';
      html += '<body style="margin:0!important">';
      html += '<embed width="100%" height="100%" src="' + result[0].pdfBase64 + '" type="application/pdf" />';
      html += '</body>';
      html += '</html>';

      setTimeout(() => {
        win.document.write(html);
        win.document.title = mantenimiento.enlacePdf;
      }, 0);
    });

  }

  cargarDatosMantenimientos() {
    this.cargarDatosMantenimientos_porFecha();
    this.cargarDatosMantenimientos_porTiempo();
  }

  cargarDatosMantenimientos_porFecha() {
    this.hmiService.Get_MantenimientosRecientes_porFecha(this.idmaquina).subscribe((result) => {
      var datuak: any = result;

      this.mantenimientoPorFecha = [];

      var ahora = this.myFunctions.getDateNow();
      var finHoy = this.myFunctions.getDateNow();
      finHoy.setHours(23, 59, 59);
      var dif = finHoy.getTime() - ahora.getTime();
      var difHoy;
      var siguiente;

      var myhoraText = "";
      var myFaltaText = "";
      var myTextText = "";

      var PDFText = "";
      var PDFURL = "";

      var PDF;
      var rojo;
      var gris;
      var CheckBoxChecked;

      var mantenimientosTemp = [];

      var inf = new Promise((resolve, reject) => {
        datuak.forEach(async function (row, indice, array) {
          rojo = false;
          gris = false;
          CheckBoxChecked = false;
          PDF = false;
          var tipo = "";
          if (row.fechaUltimo != "") {
            if (row.idTipo == 1) {//diario
              siguiente = this.sqlToJsDate(row.fechaUltimo);
              siguiente.setDate(siguiente.getDate() + (1 * row.frecuenciaMantenimiento));
            } else if (row.idTipo == 2) {//semanal
              siguiente = this.sqlToJsDate(row.fechaUltimo);
              siguiente.setDate(siguiente.getDate() + (7 * row.frecuenciaMantenimiento));
            } else if (row.idTipo == 3) {//mensual
              siguiente = this.sqlToJsDate(row.fechaUltimo);
              siguiente.setMonth(siguiente.getMonth() + (1 * row.frecuenciaMantenimiento));
              var dia2 = row.dia;
              var mes = siguiente.getMonth();
              siguiente = new Date(siguiente.getFullYear(), siguiente.getMonth(), dia2, siguiente.getHours(), siguiente.getMinutes(), siguiente.getSeconds());
              siguiente.setMonth(mes);
            } else if (row.idTipo == 5) { // por turnos
              var that = this;
              await new Promise(function (myResolve, myReject) {
                that.maquinasService.getProximoTurno(that.idmaquina, row.turnos, row.fechaUltimo, row.frecuenciaMantenimiento).subscribe((result) => {
                  if (result.length != 0)
                    siguiente = new Date(result[0].fechaIni);
                  else
                    siguiente = ahora
                  myResolve(result[0].fechaIni);
                });
              });
            } else {//anual
              siguiente = this.sqlToJsDate(row.fechaUltimo);
              siguiente.setMonth(siguiente.getMonth() + (12 * (1 * row.frecuenciaMantenimiento)));
            }

            if (siguiente > ahora) {
              gris = true;
              CheckBoxChecked = true;
              //ViewState("checkMantenimientos") = ViewState("checkMantenimientos") & ":" & row("id") & ":"
            }

            //ANTES DE USAR EL CALCULO DEL PROXIMO MANTENIMIENTO, HAY QUE TRADUCIRLO A LA HORA DEL USUARIO!
            // hacer esto desde la DB es dificil xq se pasa dia y hora... no una fecha como tal.
            //ARATZ SIGUE AQUI
            if (row.idTipo == 1) {
              myhoraText = siguiente;
              ahora.setHours(0, 0, 0);
              difHoy = siguiente.getTime() - ahora.getTime();
              myFaltaText = this.milisecondsTo_HH_MM(difHoy % (1 * 24 * 60 * 60 * 1000));//myFaltaText = difHoy.ToString("hh\:mm")
              if ((difHoy / 1000) < 0) {
                rojo = true;
              }
              var dias = Math.round(difHoy / 1000 / 60 / 60 / 24);
              if (dias < 0) {
                dias = dias * (-1);
                if (dias == 1) {
                  myFaltaText = dias + " " + "día" + " " + myFaltaText;
                } else {
                  myFaltaText = dias + " " + "días" + " " + myFaltaText;
                }
              }
            } else {
              myhoraText = siguiente;
              ahora.setHours(0, 0, 0);
              difHoy = siguiente.getTime() - ahora.getTime();
              myFaltaText = this.milisecondsTo_HH_MM(difHoy % (1 * 24 * 60 * 60 * 1000));//myFaltaText = difHoy.ToString("hh\:mm")
              var dias = Math.round(difHoy / 1000 / 60 / 60 / 24);
              if (dias < 0) {
                dias = dias * (-1);
                if (dias == 1) {
                  myFaltaText = dias + " " + "día" + " " + myFaltaText;
                } else {
                  myFaltaText = dias + " " + "días" + " " + myFaltaText;
                }
              }
              if ((difHoy / 1000) < 0) {
                rojo = true;
                //myFaltaText = dif.Add(TimeSpan.FromDays(1)).ToString("d\.hh\:mm\:ss")
                myFaltaText = this.milisecondsTo_HH_MM_SS(dif + (1 * 24 * 60 * 60 * 1000));//sumar un dia en milisegundos
                myFaltaText = Math.floor((dif + (1 * 24 * 60 * 60 * 1000)) / (1 * 24 * 60 * 60 * 1000)) + " días, " + this.milisecondsTo_HH_MM_SS((dif + (1 * 24 * 60 * 60 * 1000)) % (1 * 24 * 60 * 60 * 1000));
              }
            }

            myTextText = row.nombre;

            if (!(row.pdf === "")) {
              PDFText = row.pdf;
              //PDFURL = "~/idcontent/" & current.conexID & "/mantenimientos/" & row("PDF")
              PDF = true;
            }

          }
          else {
            //no se ha hecho nunca
            CheckBoxChecked = false;

            row

            rojo = true;
            myTextText = row.nombre;
            myhoraText = "-";
            myFaltaText = this.milisecondsTo_HH_MM_SS(dif);

            if (!(row.pdf === "")) {
              PDFText = row.pdf;
              //PDFURL = "~/idcontent/" & current.conexID & "/mantenimientos/" & row("PDF")
              PDF = true;
            }
          }

          if (row.idTipo == 1) {//diario
            tipo = "diario";
          }
          else if (row.idTipo == 2) {//semanal
            tipo = "semanal";
          }
          else if (row.idTipo == 3) {//mensual
            tipo = "mensual";
          }
          else if (row.idTipo == 5) { // por turnos
            tipo = "porTurnos";
          } else {//anual
            tipo = "anual";
          }

          var mantenimiento = {
            id: row.id,
            texto: myTextText,
            fecha: this.dateToString(myhoraText),
            checked: CheckBoxChecked,
            enlacePdf: PDFText,
            tienePdf: PDF,
            esRojo: rojo,
            tipo: tipo
          };

          /* NO SE PUEDEN DESHACER MANTENIMIENTOS NI DESDE HMI NI DESDE LA APP, TENDRA QUE HACERSE DESDE HISTORICO */
          if (!mantenimiento.checked)
            mantenimientosTemp.push(mantenimiento);

          if (indice == array.length - 1) resolve(true);

        }, this);
      })

      inf.then(() => {
        var array = []
        var an: any = mantenimientosTemp;
        an.forEach(a => array[a['checked'] + this.myFunctions.dateToYYYYMMDDHHmmSS(new Date(a['fecha'])) + a['id']] = a); // Criterio de orden: false < true
        array.sort(function (a, b) { return b[1] - a[1] });
        var keys = [];
        Object.keys(array).sort().forEach(key => keys.push(key)); // Se ordena segun nuestro criterio de orden
        keys.forEach(key => {
          var row = array[key]; // Cogemos las rows desde el array que hemos creado. Y estos nos vendran en orden.
          this.mantenimientoPorFecha.push(row);
        });
      })
    });
  }

  cargarDatosMantenimientos_porTiempo() {
    this.hmiService.Get_MantenimientosRecientes_porTiempo(this.idmaquina).subscribe((result) => {
      var datuak: any = result;

      this.mantenimientoPorTiempo = [];

      var ahora = this.myFunctions.getDateNow()
      var finHoy = this.myFunctions.getDateNow()
      finHoy.setHours(23, 59, 59);
      var dif = finHoy.getTime() - ahora.getTime();

      var myhoraText = "";
      var myTextText = "";

      var PDFText = "";
      var PDFURL = "";

      var PDF;
      var rojo;
      var gris;
      var CheckBoxChecked;

      var mantenimientosTemp = [];

      datuak.forEach(function (row, indice, array) {
        rojo = false;
        gris = false;
        CheckBoxChecked = false;
        PDF = false;

        //no se ha hecho nunca
        if (row.tiempoRestante < 0) {
          CheckBoxChecked = false;
          rojo = true;
        }
        else {
          CheckBoxChecked = true;
          rojo = false;
        }

        myTextText = row.nombre;
        myhoraText = "-";

        if (!(row.pdf === "")) {
          PDFText = row.pdf;
          //PDFURL = "~/idcontent/" & current.conexID & "/mantenimientos/" & row("PDF")
          PDF = true;
        }

        myhoraText = this.myFunctions.secondsTo_HH_MM_SS(row.tiempoRestante)

        var mantenimiento = {
          id: row.id,
          texto: myTextText,
          fecha: myhoraText,
          checked: CheckBoxChecked,
          enlacePdf: PDFText,
          tienePdf: PDF,
          esRojo: rojo
        };
        /* NO SE PUEDEN DESHACER MANTENIMIENTOS NI DESDE HMI NI DESDE LA APP, TENDRA QUE HACERSE DESDE HISTORICO */
        if (!mantenimiento.checked)
          mantenimientosTemp.push(mantenimiento);

      }, this);

      var array = []
      var an: any = mantenimientosTemp;
      an.forEach(a => array[a['checked'] + this.myFunctions.dateToYYYYMMDDHHmmSS(new Date(a['fecha'])) + a['id']] = a); // Criterio de orden: false < true
      array.sort(function (a, b) { return b[1] - a[1] });
      var keys = [];
      Object.keys(array).sort().forEach(key => keys.push(key)); // Se ordena segun nuestro criterio de orden
      keys.forEach(key => {
        var row = array[key]; // Cogemos las rows desde el array que hemos creado. Y estos nos vendran en orden.
        this.mantenimientoPorTiempo.push(row);
      });
    });
  }

  clickSwitch(event: any, dataitem: any, tipo: number) {
    this.loadingPorFecha = true;

    var prevChecked = !dataitem.checked;

    if (prevChecked) { //El mantenimiento estaba hecho (switch=true)
      this.hmiService.UNupdate_mantenimiento_codigos(dataitem.id).subscribe((result) => {
        var datuak: any = result;
        this.loadingPorFecha = false;
        this.cargarDatosMantenimientos();
      });
    } else { //El mantenimiento no estaba hecho (switch=false)
      this.hmiService.update_mantenimiento_codigos(dataitem.id).subscribe((result) => {
        var datuak: any = result;
        this.loadingPorFecha = false;
        this.cargarDatosMantenimientos();
      });
    }

  }

  clickSwitchTiempo(event: any, dataitem: any, tipo: number) {
    this.loadingPorTiempo = true;

    var prevChecked = !dataitem.checked;

    if (prevChecked) { //El mantenimiento estaba hecho (switch=true)
      this.hmiService.UNupdate_mantenimiento_codigos_Tiempo(dataitem.id).subscribe((result) => {
        var datuak: any = result;
        this.loadingPorTiempo = false;
        this.cargarDatosMantenimientos();
      });
    } else { //El mantenimiento no estaba hecho (switch=false)
      this.hmiService.update_mantenimiento_codigos_Tiempo(dataitem.id).subscribe((result) => {
        var datuak: any = result;
        this.loadingPorTiempo = false;
        this.cargarDatosMantenimientos();
      });
    }

  }

  public rowCallback(context: RowClassArgs) {
    switch (context.dataItem.esRojo) {
      case false:
        return "";
      case true:
        return "gridFilaRoja";
      default:
        return "";
    }
  }

  cargarDatosNotas() {
    this.hmiService.Get_NotasActualesMaquina(this.idmaquina).subscribe((result) => {
      var datuak: any = result;
      this.notas = datuak;
    });
  }

  cargarHerramientas() {

    this.hmiService.Get_Herramientas(this.idmaquina).subscribe((result) => {
      var datuak: any = result;
      this.herramientas = datuak;
    });
    this.hmiService.Get_CambiosRoturas(this.idmaquina).subscribe((result) => {
      var datuak: any = result;
      this.roturasCambiosHerramientas = datuak;
    });

  }

  cargarGraficos() {

    var infoEjes;
    var bukatua = new Promise<void>((resolve, reject) => {
      this.hmiService.get_ejes_infoDet_id(this.idmaquina).subscribe((result) => {
        var datuak: any = result;
        infoEjes = datuak;
        resolve();
      });
    });

    bukatua.then(() => {
      var idejes = infoEjes.map(a => a.id).toString();
      var count = infoEjes.length
      this.hmiService.Get_DatosTiempoReal_ejes_det(this.idmaquina).subscribe((result) => {
        var datuak: any = result;
        var dtrEjes1 = datuak;
        var dtrEjes: any = [];
        var esta = false;
        infoEjes.forEach(function (rowInfo, indice, array) {
          esta = false;
          dtrEjes1.forEach(function (rowDato, indice, array) {
            if (rowInfo.id == rowDato.id) {
              esta = true;
              dtrEjes.push(
                {
                  rpm: rowDato.rpm,
                  temp: rowDato.temp,
                  consumo: rowDato.consumo,
                  posActual: rowDato.posactual,
                  posRestante: rowDato.posrestante,
                  posComando: rowDato.poscomando
                }
              );
            }
          });
          if (!esta) {
            dtrEjes.push(
              {
                rpm: 0,
                temp: 0,
                consumo: 0,
                posActual: 0,
                posRestante: 0,
                posComando: 0
              }
            );
          }
        });

        var feed1 = 0.0;           // FEED EJE
        var consumo1 = 0.0;        // CONSUMO EJE
        var temperatura1 = 0.0;    // TEMPERATURA EJE
        var fmin1 = 0.0;           // FEED MINIMO EJE
        var fmax1 = 0.0;           // FEED MAXIMO EJE
        var cmin1 = 0.0;           // CONSUMO MINIMO EJE
        var cc11 = 0.0;            // CONSUMO CAMBIO COLOR 1 EJE
        var cc21 = 0.0;            // CONSUMO CAMBIO COLOR 2 EJE
        var cc31 = 0.0;            // CONSUMO CAMBIO COLOR 3 EJE
        var cmax1 = 0.0;           // CONSUMO MAXIMO EJE
        var tmin1 = 0.0;           // TEMPERATURA MINIMA EJE
        var tc11 = 0.0;            // TEMPERATURA CAMBIO COLOR 1 EJE
        var tc21 = 0.0;            // TEMPERATURA CAMBIO COLOR 2 EJE
        var tc31 = 0.0;            // TEMPERATURA CAMBIO COLOR 3 EJE
        var tmax1 = 0.0;           // TEMPERATURA MAXIMA EJE

        var i;
        this.datosGraficoArray = [];

        for (i = 0; i < count; i++) {

          var datosGrafico = {};

          if (infoEjes[i].motor) {
            datosGrafico['unidad'] = "R.P.M.";
            datosGrafico['graficoClass'] = "cont-graf main";
            datosGrafico['valoresAbajoEnsennar'] = false;
          } else {
            datosGrafico['unidad'] = "mm/min";
            datosGrafico['graficoClass'] = "cont-graf";
            datosGrafico['valoresAbajoEnsennar'] = true;
          }

          datosGrafico['nombre'] = infoEjes[i].nombre;

          if (infoEjes[i].feed) {
            feed1 = dtrEjes[i].rpm;
            fmin1 = infoEjes[i].fmin;
            fmax1 = infoEjes[i].fmax;
            this.cargarGraficofeed(feed1, fmin1, fmax1, datosGrafico);
            datosGrafico['graficoFeedTexto'] = feed1;
            datosGrafico['ensennarFeed'] = true;
          } else {
            datosGrafico['ensennarFeed'] = false;
          }

          if (infoEjes[i].consumo) {
            consumo1 = dtrEjes[i].consumo;
            cmin1 = infoEjes[i].cmin;
            cc11 = infoEjes[i].cc1;
            cc21 = infoEjes[i].cc2;
            cc31 = infoEjes[i].cc3;
            cmax1 = infoEjes[i].cmax;
            this.cargarGraficoConsumo(consumo1, cmin1, cc11, cc21, cc31, cmax1, datosGrafico);
            datosGrafico['consumoTexto'] = consumo1.toFixed(1);
            datosGrafico['ensennarConsum'] = true;
          } else {
            datosGrafico['ensennarConsum'] = false;
          }

          if (infoEjes[i].temperatura) {
            temperatura1 = dtrEjes[i].temp;
            tmin1 = infoEjes[i].tmin;
            tc11 = infoEjes[i].tc1;
            tc21 = infoEjes[i].tc2;
            tc31 = infoEjes[i].tc3;
            tmax1 = infoEjes[i].tmax;
            this.cargarGraficotemperatura(temperatura1, tmin1, tc11, tc21, tc31, tmax1, datosGrafico);
            datosGrafico['temperaturaTexto'] = temperatura1.toFixed(1);
            datosGrafico['ensennarTemp'] = true;
          } else {
            datosGrafico['ensennarTemp'] = false;
          }

          if (infoEjes[i].posicion) {
            datosGrafico['comando'] = dtrEjes[i].posComando;
            datosGrafico['actual'] = dtrEjes[i].posActual;
            datosGrafico['restante'] = dtrEjes[i].posRestante;
            datosGrafico['valoresAbajoEnsennar'] = true;
          } else {
            datosGrafico['comando'] = "0";
            datosGrafico['actual'] = "0";
            datosGrafico['restante'] = "0";
            datosGrafico['valoresAbajoEnsennar'] = false;
          }

          this.datosGraficoArray.push(datosGrafico);

        }

      });

    });

  }

  recargarGraficos() {

    var infoEjes;
    var bukatua = new Promise<void>((resolve, reject) => {
      this.hmiService.get_ejes_infoDet_id(this.idmaquina).subscribe((result) => {
        var datuak: any = result;
        infoEjes = datuak;
        resolve();
      });
    });

    bukatua.then(() => {
      var idejes = infoEjes.map(a => a.id).toString();
      var count = infoEjes.length
      this.hmiService.Get_DatosTiempoReal_ejes_det(this.idmaquina).subscribe((result) => {
        var datuak: any = result;
        var dtrEjes1 = datuak;
        var dtrEjes: any = [];
        var esta = false;
        infoEjes.forEach(function (rowInfo, indice, array) {
          esta = false;
          dtrEjes1.forEach(function (rowDato, indice, array) {
            if (rowInfo.id == rowDato.id) {
              esta = true;
              dtrEjes.push(
                {
                  rpm: rowDato.rpm,
                  temp: rowDato.temp,
                  consumo: rowDato.consumo,
                  posActual: rowDato.posactual,
                  posRestante: rowDato.posrestante,
                  posComando: rowDato.poscomando
                }
              );
            }
          });
          if (!esta) {
            dtrEjes.push(
              {
                rpm: 0,
                temp: 0,
                consumo: 0,
                posActual: 0,
                posRestante: 0,
                posComando: 0
              }
            );
          }
        });

        var feed1 = 0.0;           // FEED EJE
        var consumo1 = 0.0;        // CONSUMO EJE
        var temperatura1 = 0.0;    // TEMPERATURA EJE
        var fmin1 = 0.0;           // FEED MINIMO EJE
        var fmax1 = 0.0;           // FEED MAXIMO EJE
        var cmin1 = 0.0;           // CONSUMO MINIMO EJE
        var cc11 = 0.0;            // CONSUMO CAMBIO COLOR 1 EJE
        var cc21 = 0.0;            // CONSUMO CAMBIO COLOR 2 EJE
        var cc31 = 0.0;            // CONSUMO CAMBIO COLOR 3 EJE
        var cmax1 = 0.0;           // CONSUMO MAXIMO EJE
        var tmin1 = 0.0;           // TEMPERATURA MINIMA EJE
        var tc11 = 0.0;            // TEMPERATURA CAMBIO COLOR 1 EJE
        var tc21 = 0.0;            // TEMPERATURA CAMBIO COLOR 2 EJE
        var tc31 = 0.0;            // TEMPERATURA CAMBIO COLOR 3 EJE
        var tmax1 = 0.0;           // TEMPERATURA MAXIMA EJE

        var i;

        for (i = 0; i < count; i++) {

          var datosGrafico = {};

          if (infoEjes[i].motor) {
            datosGrafico['unidad'] = "R.P.M.";
            datosGrafico['graficoClass'] = "cont-graf main";
            datosGrafico['valoresAbajoEnsennar'] = false;
          } else {
            datosGrafico['unidad'] = "mm/min";
            datosGrafico['graficoClass'] = "cont-graf";
            datosGrafico['valoresAbajoEnsennar'] = true;
          }

          this.datosGraficoArray[i].unidad = datosGrafico['unidad'];//
          this.datosGraficoArray[i].graficoClass = datosGrafico['graficoClass'];//
          this.datosGraficoArray[i].valoresAbajoEnsennar = datosGrafico['valoresAbajoEnsennar'];//

          datosGrafico['nombre'] = infoEjes[i].nombre;
          this.datosGraficoArray[i].nombre = datosGrafico['nombre'];//

          var feedViejo = this.datosGraficoArray[i].graficoFeedTexto;
          var consumViejo = this.datosGraficoArray[i].consumoTexto;
          var tempViejo = this.datosGraficoArray[i].temperaturaTexto;

          if (infoEjes[i].feed) {
            feed1 = dtrEjes[i].rpm;
            fmin1 = infoEjes[i].fmin;
            fmax1 = infoEjes[i].fmax;
            this.cargarGraficofeed(feed1, fmin1, fmax1, datosGrafico, i);
            datosGrafico['graficoFeedTexto'] = feed1;
            datosGrafico['ensennarFeed'] = true;
          } else {
            datosGrafico['ensennarFeed'] = false;
          }

          this.datosGraficoArray[i].ensennarFeed = datosGrafico['ensennarFeed'];//


          if (infoEjes[i].consumo) {
            consumo1 = dtrEjes[i].consumo;
            cmin1 = infoEjes[i].cmin;
            cc11 = infoEjes[i].cc1;
            cc21 = infoEjes[i].cc2;
            cc31 = infoEjes[i].cc3;
            cmax1 = infoEjes[i].cmax;
            this.cargarGraficoConsumo(consumo1, cmin1, cc11, cc21, cc31, cmax1, datosGrafico, i);
            datosGrafico['consumoTexto'] = consumo1.toFixed(1);
            datosGrafico['ensennarConsum'] = true;
          } else {
            datosGrafico['ensennarConsum'] = false;
          }

          this.datosGraficoArray[i].ensennarConsum = datosGrafico['ensennarConsum'];//

          if (infoEjes[i].temperatura) {
            temperatura1 = dtrEjes[i].temp;
            tmin1 = infoEjes[i].tmin;
            tc11 = infoEjes[i].tc1;
            tc21 = infoEjes[i].tc2;
            tc31 = infoEjes[i].tc3;
            tmax1 = infoEjes[i].tmax;
            this.cargarGraficotemperatura(temperatura1, tmin1, tc11, tc21, tc31, tmax1, datosGrafico, i);
            datosGrafico['temperaturaTexto'] = temperatura1.toFixed(1);
            datosGrafico['ensennarTemp'] = true;
          } else {
            datosGrafico['ensennarTemp'] = false;
          }

          this.datosGraficoArray[i].ensennarTemp = datosGrafico['ensennarTemp'];//

          if (infoEjes[i].posicion) {
            datosGrafico['comando'] = dtrEjes[i].posComando;
            datosGrafico['actual'] = dtrEjes[i].posActual;
            datosGrafico['restante'] = dtrEjes[i].posRestante;
            datosGrafico['valoresAbajoEnsennar'] = true;
          } else {
            datosGrafico['comando'] = "0";
            datosGrafico['actual'] = "0";
            datosGrafico['restante'] = "0";
            datosGrafico['valoresAbajoEnsennar'] = false;
          }

          this.datosGraficoArray[i].valoresAbajoEnsennar = datosGrafico['valoresAbajoEnsennar'];//

          var comandoViejo = this.datosGraficoArray[i].comando;
          var actualViejo = this.datosGraficoArray[i].actual;
          var restanteViejo = this.datosGraficoArray[i].restante;

          var comando = datosGrafico['comando'];
          var actual = datosGrafico['actual'];
          var restante = datosGrafico['restante'];

          var feed = datosGrafico['graficoFeedTexto'];
          var consum = datosGrafico['consumoTexto'];
          var temp = datosGrafico['temperaturaTexto'];

          this.cargarTextosGraficoAnimacion(feedViejo, feed, consumViejo, consum, tempViejo, temp, comandoViejo, comando, actualViejo, actual, restanteViejo, restante, i);

        }

      });

    });

  }

  cargarTextosGraficoAnimacion(feedViejo: number, feed: number, consumViejo: number, consum: number, tempViejo: number, temp: number, comandoViejo: number,
    comando: number, actualViejo: number, actual: number, restanteViejo: number, restante: number, i: number) {

    (async () => {
      for (var j = 0; j <= 10; j++) {
        var f = (Number(feedViejo) + j * ((Number(feed) - Number(feedViejo)) / 10));
        var c = (Number(consumViejo) + j * ((Number(consum) - Number(consumViejo)) / 10));
        var t = (Number(tempViejo) + j * ((Number(temp) - Number(tempViejo)) / 10));
        var com = (Number(comandoViejo) + j * ((Number(comando) - Number(comandoViejo)) / 10));
        var a = (Number(actualViejo) + j * ((Number(actual) - Number(actualViejo)) / 10));
        var r = (Number(restanteViejo) + j * ((Number(restante) - Number(restanteViejo)) / 10));

        if (this.datosGraficoArray[i].valoresAbajoEnsennar == true) this.datosGraficoArray[i].graficoFeedTexto = f.toFixed(2);
        else this.datosGraficoArray[i].graficoFeedTexto = f.toFixed();
        this.datosGraficoArray[i].consumoTexto = c.toFixed(1);
        this.datosGraficoArray[i].temperaturaTexto = t.toFixed(1);

        this.datosGraficoArray[i].comando = com;
        this.datosGraficoArray[i].actual = a;
        this.datosGraficoArray[i].restante = r;

        await this.delay(500 / 10 + 1);
      }

      if (this.datosGraficoArray[i].valoresAbajoEnsennar == true) this.datosGraficoArray[i].graficoFeedTexto = Number(feed).toFixed(2);
      else this.datosGraficoArray[i].graficoFeedTexto = Number(feed).toFixed();
      this.datosGraficoArray[i].consumoTexto = Number(consum).toFixed(1);//
      this.datosGraficoArray[i].temperaturaTexto = Number(temp).toFixed(1);//

      this.datosGraficoArray[i].comando = comando;//
      this.datosGraficoArray[i].actual = actual;//
      this.datosGraficoArray[i].restante = restante;//

    })();

  }

  cargarGraficofeed(feed: number, feedMin: number, feedMax: number, datosGrafico: object, i: number = -1) {
    var pViejo
    if (i != -1) pViejo = this.datosGraficoArray[i].pRpm;
    else pViejo = 0;
    var p = this.calcularPorcentaje(feed - feedMin, 50, feedMax, 0, true);
    datosGrafico['pRpm'] = p;

    var rango = this.rangoDeNumeros(pViejo, p);

    (async () => {
      var j;
      for (j = 0; j < rango.length; j++) {
        datosGrafico['graficoFeedSrc'] = "assets/rpm/rpm" + rango[j] + ".png";
        if (i != -1) {
          this.datosGraficoArray[i].pRpm = datosGrafico['pRpm'];
          this.datosGraficoArray[i].graficoFeedSrc = datosGrafico['graficoFeedSrc'];
        }
        await this.delay(500 / rango.length);
      }
    })();

  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  rangoDeNumeros(start, end, step = 1) {
    if (start <= end) {
      const len = Math.floor((end - start) / step) + 1
      return Array(len).fill(0).map((_, idx) => start + (idx * step))
    } else {
      const len = Math.floor((start - end) / step) + 1
      return Array(len).fill(0).map((_, idx) => end + (idx * step)).reverse()
    }
  }

  cargarGraficoConsumo(consumo: number, cmin: number, cc1: number, cc2: number, cc3: number, cmax: number, datosGrafico: object, i: number = -1) {
    var pViejo
    if (i != -1) pViejo = this.datosGraficoArray[i].pConsum;
    else pViejo = 0;

    var p = this.calcularPorcentaje(consumo - cmin, 25, cmax - cmin, 0, true);
    datosGrafico['pConsum'] = p;
    var pAzul = this.calcularPorcentaje(cc1 - cmin, 25, cmax - cmin, 0, true);
    var pVerde = this.calcularPorcentaje(cc2 - cmin, 25, cmax - cmin, 0, true);
    var pAmarillo = this.calcularPorcentaje(cc3 - cmin, 25, cmax - cmin, 0, true);

    var rango = this.rangoDeNumeros(pViejo, p);

    (async () => {
      var j;
      for (j = 0; j < rango.length; j++) {
        datosGrafico['graficoConsumIMG1src'] = this.analizarValorImagenConsumo(rango[j], pAzul, pVerde, pAmarillo, 1);
        datosGrafico['graficoConsumIMG2src'] = this.analizarValorImagenConsumo(rango[j], pAzul, pVerde, pAmarillo, 2);
        datosGrafico['graficoConsumIMG3src'] = this.analizarValorImagenConsumo(rango[j], pAzul, pVerde, pAmarillo, 3);
        datosGrafico['graficoConsumIMG4src'] = this.analizarValorImagenConsumo(rango[j], pAzul, pVerde, pAmarillo, 4);
        datosGrafico['graficoConsumIMG5src'] = this.analizarValorImagenConsumo(rango[j], pAzul, pVerde, pAmarillo, 5);
        datosGrafico['graficoConsumIMG6src'] = this.analizarValorImagenConsumo(rango[j], pAzul, pVerde, pAmarillo, 6);
        datosGrafico['graficoConsumIMG7src'] = this.analizarValorImagenConsumo(rango[j], pAzul, pVerde, pAmarillo, 7);
        datosGrafico['graficoConsumIMG8src'] = this.analizarValorImagenConsumo(rango[j], pAzul, pVerde, pAmarillo, 8);
        datosGrafico['graficoConsumIMG9src'] = this.analizarValorImagenConsumo(rango[j], pAzul, pVerde, pAmarillo, 9);
        datosGrafico['graficoConsumIMG10src'] = this.analizarValorImagenConsumo(rango[j], pAzul, pVerde, pAmarillo, 10);
        datosGrafico['graficoConsumIMG11src'] = this.analizarValorImagenConsumo(rango[j], pAzul, pVerde, pAmarillo, 11);
        datosGrafico['graficoConsumIMG12src'] = this.analizarValorImagenConsumo(rango[j], pAzul, pVerde, pAmarillo, 12);
        datosGrafico['graficoConsumIMG13src'] = this.analizarValorImagenConsumo(rango[j], pAzul, pVerde, pAmarillo, 13);
        datosGrafico['graficoConsumIMG14src'] = this.analizarValorImagenConsumo(rango[j], pAzul, pVerde, pAmarillo, 14);
        datosGrafico['graficoConsumIMG15src'] = this.analizarValorImagenConsumo(rango[j], pAzul, pVerde, pAmarillo, 15);
        datosGrafico['graficoConsumIMG16src'] = this.analizarValorImagenConsumo(rango[j], pAzul, pVerde, pAmarillo, 16);
        datosGrafico['graficoConsumIMG17src'] = this.analizarValorImagenConsumo(rango[j], pAzul, pVerde, pAmarillo, 17);
        datosGrafico['graficoConsumIMG18src'] = this.analizarValorImagenConsumo(rango[j], pAzul, pVerde, pAmarillo, 18);
        datosGrafico['graficoConsumIMG19src'] = this.analizarValorImagenConsumo(rango[j], pAzul, pVerde, pAmarillo, 19);
        datosGrafico['graficoConsumIMG20src'] = this.analizarValorImagenConsumo(rango[j], pAzul, pVerde, pAmarillo, 20);
        datosGrafico['graficoConsumIMG21src'] = this.analizarValorImagenConsumo(rango[j], pAzul, pVerde, pAmarillo, 21);
        datosGrafico['graficoConsumIMG22src'] = this.analizarValorImagenConsumo(rango[j], pAzul, pVerde, pAmarillo, 22);
        datosGrafico['graficoConsumIMG23src'] = this.analizarValorImagenConsumo(rango[j], pAzul, pVerde, pAmarillo, 23);
        datosGrafico['graficoConsumIMG24src'] = this.analizarValorImagenConsumo(rango[j], pAzul, pVerde, pAmarillo, 24);
        datosGrafico['graficoConsumIMG25src'] = this.analizarValorImagenConsumo(rango[j], pAzul, pVerde, pAmarillo, 25);

        if (i != -1) {
          this.datosGraficoArray[i].graficoConsumIMG1src = datosGrafico['graficoConsumIMG1src'];
          this.datosGraficoArray[i].graficoConsumIMG2src = datosGrafico['graficoConsumIMG2src'];
          this.datosGraficoArray[i].graficoConsumIMG3src = datosGrafico['graficoConsumIMG3src'];
          this.datosGraficoArray[i].graficoConsumIMG4src = datosGrafico['graficoConsumIMG4src'];
          this.datosGraficoArray[i].graficoConsumIMG5src = datosGrafico['graficoConsumIMG5src'];
          this.datosGraficoArray[i].graficoConsumIMG6src = datosGrafico['graficoConsumIMG6src'];
          this.datosGraficoArray[i].graficoConsumIMG7src = datosGrafico['graficoConsumIMG7src'];
          this.datosGraficoArray[i].graficoConsumIMG8src = datosGrafico['graficoConsumIMG8src'];
          this.datosGraficoArray[i].graficoConsumIMG9src = datosGrafico['graficoConsumIMG9src'];
          this.datosGraficoArray[i].graficoConsumIMG10src = datosGrafico['graficoConsumIMG10src'];
          this.datosGraficoArray[i].graficoConsumIMG11src = datosGrafico['graficoConsumIMG11src'];
          this.datosGraficoArray[i].graficoConsumIMG12src = datosGrafico['graficoConsumIMG12src'];
          this.datosGraficoArray[i].graficoConsumIMG13src = datosGrafico['graficoConsumIMG13src'];
          this.datosGraficoArray[i].graficoConsumIMG14src = datosGrafico['graficoConsumIMG14src'];
          this.datosGraficoArray[i].graficoConsumIMG15src = datosGrafico['graficoConsumIMG15src'];
          this.datosGraficoArray[i].graficoConsumIMG16src = datosGrafico['graficoConsumIMG16src'];
          this.datosGraficoArray[i].graficoConsumIMG17src = datosGrafico['graficoConsumIMG17src'];
          this.datosGraficoArray[i].graficoConsumIMG18src = datosGrafico['graficoConsumIMG18src'];
          this.datosGraficoArray[i].graficoConsumIMG19src = datosGrafico['graficoConsumIMG19src'];
          this.datosGraficoArray[i].graficoConsumIMG20src = datosGrafico['graficoConsumIMG20src'];
          this.datosGraficoArray[i].graficoConsumIMG21src = datosGrafico['graficoConsumIMG21src'];
          this.datosGraficoArray[i].graficoConsumIMG22src = datosGrafico['graficoConsumIMG22src'];
          this.datosGraficoArray[i].graficoConsumIMG23src = datosGrafico['graficoConsumIMG23src'];
          this.datosGraficoArray[i].graficoConsumIMG24src = datosGrafico['graficoConsumIMG24src'];
          this.datosGraficoArray[i].graficoConsumIMG25src = datosGrafico['graficoConsumIMG25src'];

          this.datosGraficoArray[i].pConsum = datosGrafico['pConsum'];

        }
        await this.delay(500 / rango.length);
      }
    })();

  }

  analizarValorImagenConsumo(valor: number, azul: number, verde: number, amaillo: number, indice: number) {
    if (indice <= valor) {
      if (indice <= azul) {
        return "assets/img/consum/azul/consum" + indice + ".png";
      } else if (indice <= verde) {
        return "assets/img/consum/verde/consum" + indice + ".png";
      } else if (indice <= amaillo) {
        return "assets/img/consum/amarillo/consum" + indice + ".png";
      } else {
        return "assets/img/consum/rojo/consum" + indice + ".png";
      }
    } else {
      return "";
    }
  }

  cargarGraficotemperatura(temp: number, cmin: number, cc1: number, cc2: number, cc3: number, cmax: number, datosGrafico: object, i: number = -1) {
    var pViejo
    if (i != -1) pViejo = this.datosGraficoArray[i].pTemp;
    else pViejo = 0;

    var p = this.calcularPorcentaje(temp - cmin, 25, cmax - cmin, 0, true);
    datosGrafico['pTemp'] = p;
    var pAzul = this.calcularPorcentaje(cc1 - cmin, 25, cmax - cmin, 0, true);
    var pVerde = this.calcularPorcentaje(cc2 - cmin, 25, cmax - cmin, 0, true);
    var pAmarillo = this.calcularPorcentaje(cc3 - cmin, 25, cmax - cmin, 0, true);

    var rango = this.rangoDeNumeros(pViejo, p);

    (async () => {
      var j;
      for (j = 0; j < rango.length; j++) {
        datosGrafico['graficoTempIMG1src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 1);
        datosGrafico['graficoTempIMG2src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 2);
        datosGrafico['graficoTempIMG3src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 3);
        datosGrafico['graficoTempIMG4src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 4);
        datosGrafico['graficoTempIMG5src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 5);
        datosGrafico['graficoTempIMG6src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 6);
        datosGrafico['graficoTempIMG7src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 7);
        datosGrafico['graficoTempIMG8src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 8);
        datosGrafico['graficoTempIMG9src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 9);
        datosGrafico['graficoTempIMG10src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 10);
        datosGrafico['graficoTempIMG11src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 11);
        datosGrafico['graficoTempIMG12src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 12);
        datosGrafico['graficoTempIMG13src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 13);
        datosGrafico['graficoTempIMG14src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 14);
        datosGrafico['graficoTempIMG15src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 15);
        datosGrafico['graficoTempIMG16src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 16);
        datosGrafico['graficoTempIMG17src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 17);
        datosGrafico['graficoTempIMG18src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 18);
        datosGrafico['graficoTempIMG19src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 19);
        datosGrafico['graficoTempIMG20src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 20);
        datosGrafico['graficoTempIMG21src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 21);
        datosGrafico['graficoTempIMG22src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 22);
        datosGrafico['graficoTempIMG23src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 23);
        datosGrafico['graficoTempIMG24src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 24);
        datosGrafico['graficoTempIMG25src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 25);
        datosGrafico['graficoTempIMG26src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 26);
        datosGrafico['graficoTempIMG27src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 27);
        datosGrafico['graficoTempIMG28src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 28);
        datosGrafico['graficoTempIMG29src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 29);
        datosGrafico['graficoTempIMG30src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 30);
        datosGrafico['graficoTempIMG31src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 31);
        datosGrafico['graficoTempIMG32src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 32);
        datosGrafico['graficoTempIMG33src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 33);
        datosGrafico['graficoTempIMG34src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 34);
        datosGrafico['graficoTempIMG35src'] = this.analizarValorImagenTemperatura(rango[j], pAzul, pVerde, pAmarillo, 35);

        if (i != -1) {
          this.datosGraficoArray[i].graficoTempIMG1src = datosGrafico['graficoTempIMG1src'];
          this.datosGraficoArray[i].graficoTempIMG2src = datosGrafico['graficoTempIMG2src'];
          this.datosGraficoArray[i].graficoTempIMG3src = datosGrafico['graficoTempIMG3src'];
          this.datosGraficoArray[i].graficoTempIMG4src = datosGrafico['graficoTempIMG4src'];
          this.datosGraficoArray[i].graficoTempIMG5src = datosGrafico['graficoTempIMG5src'];
          this.datosGraficoArray[i].graficoTempIMG6src = datosGrafico['graficoTempIMG6src'];
          this.datosGraficoArray[i].graficoTempIMG7src = datosGrafico['graficoTempIMG7src'];
          this.datosGraficoArray[i].graficoTempIMG8src = datosGrafico['graficoTempIMG8src'];
          this.datosGraficoArray[i].graficoTempIMG9src = datosGrafico['graficoTempIMG9src'];
          this.datosGraficoArray[i].graficoTempIMG10src = datosGrafico['graficoTempIMG10src'];
          this.datosGraficoArray[i].graficoTempIMG11src = datosGrafico['graficoTempIMG11src'];
          this.datosGraficoArray[i].graficoTempIMG12src = datosGrafico['graficoTempIMG12src'];
          this.datosGraficoArray[i].graficoTempIMG13src = datosGrafico['graficoTempIMG13src'];
          this.datosGraficoArray[i].graficoTempIMG14src = datosGrafico['graficoTempIMG14src'];
          this.datosGraficoArray[i].graficoTempIMG15src = datosGrafico['graficoTempIMG15src'];
          this.datosGraficoArray[i].graficoTempIMG16src = datosGrafico['graficoTempIMG16src'];
          this.datosGraficoArray[i].graficoTempIMG17src = datosGrafico['graficoTempIMG17src'];
          this.datosGraficoArray[i].graficoTempIMG18src = datosGrafico['graficoTempIMG18src'];
          this.datosGraficoArray[i].graficoTempIMG19src = datosGrafico['graficoTempIMG19src'];
          this.datosGraficoArray[i].graficoTempIMG20src = datosGrafico['graficoTempIMG20src'];
          this.datosGraficoArray[i].graficoTempIMG21src = datosGrafico['graficoTempIMG21src'];
          this.datosGraficoArray[i].graficoTempIMG22src = datosGrafico['graficoTempIMG22src'];
          this.datosGraficoArray[i].graficoTempIMG23src = datosGrafico['graficoTempIMG23src'];
          this.datosGraficoArray[i].graficoTempIMG24src = datosGrafico['graficoTempIMG24src'];
          this.datosGraficoArray[i].graficoTempIMG25src = datosGrafico['graficoTempIMG25src'];
          this.datosGraficoArray[i].graficoTempIMG26src = datosGrafico['graficoTempIMG26src'];
          this.datosGraficoArray[i].graficoTempIMG27src = datosGrafico['graficoTempIMG27src'];
          this.datosGraficoArray[i].graficoTempIMG28src = datosGrafico['graficoTempIMG28src'];
          this.datosGraficoArray[i].graficoTempIMG29src = datosGrafico['graficoTempIMG29src'];
          this.datosGraficoArray[i].graficoTempIMG30src = datosGrafico['graficoTempIMG30src'];
          this.datosGraficoArray[i].graficoTempIMG31src = datosGrafico['graficoTempIMG31src'];
          this.datosGraficoArray[i].graficoTempIMG32src = datosGrafico['graficoTempIMG32src'];
          this.datosGraficoArray[i].graficoTempIMG33src = datosGrafico['graficoTempIMG33src'];
          this.datosGraficoArray[i].graficoTempIMG34src = datosGrafico['graficoTempIMG34src'];
          this.datosGraficoArray[i].graficoTempIMG35src = datosGrafico['graficoTempIMG35src'];

          this.datosGraficoArray[i].pTemp = datosGrafico['pTemp'];//
        }
        await this.delay(500 / rango.length);
      }
    })();

  }

  analizarValorImagenTemperatura(valor: number, azul: number, verde: number, amaillo: number, indice: number) {
    if (indice <= valor) {
      if (indice <= azul) {
        return "assets/img/temp/azul/temp" + indice + ".png";
      } else if (indice <= verde) {
        return "assets/img/temp/verde/temp" + indice + ".png";
      } else if (indice <= amaillo) {
        return "assets/img/temp/amarillo/temp" + indice + ".png";
      } else {
        return "assets/img/temp/rojo/temp" + indice + ".png";
      }
    } else {
      return "";
    }
  }

  public secondsTo_HH_MM_SS(segundos) {
    if (segundos == null) return "00:00:00";
    var segs;
    if (segundos < 0) segs = -segundos;
    else segs = segundos;

    var sec_num = parseInt(segs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    var hs, ms, ss;

    if (hours < 10) { hs = "0" + hours; } else { hs = hours; }
    if (minutes < 10) { ms = "0" + minutes; } else { ms = minutes; }
    if (seconds < 10) { ss = "0" + seconds; } else { ss = seconds; }

    if (segundos < 0) return '-' + hs + ':' + ms + ':' + ss;
    else return hs + ':' + ms + ':' + ss;
  }

  public sqlToJsDate(dateString) {
    //var dateString = "2010-08-09 01:02:03";
    var reggie = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/;
    var dateArray = reggie.exec(dateString);
    var dateObject = new Date(
      (+dateArray[1]),
      (+dateArray[2]) - 1, // Careful, month starts at 0!
      (+dateArray[3]),
      (+dateArray[4]),
      (+dateArray[5]),
      (+dateArray[6])
    );
    return dateObject;
  }

  public dateToString(m) {
    //var m = this.myFunctions.getDateNow()
    var dateString =
      m.getFullYear() + "/" +
      ("0" + (m.getMonth() + 1)).slice(-2) + "/" +
      ("0" + m.getDate()).slice(-2) + " " +
      ("0" + m.getHours()).slice(-2) + ":" +
      ("0" + m.getMinutes()).slice(-2) + ":" +
      ("0" + m.getSeconds()).slice(-2);

    return dateString;
  }

  public milisecondsTo_HH_MM(milisegundos) {
    var segs;
    if (milisegundos < 0) segs = -(milisegundos / 1000);
    else segs = (milisegundos / 1000);

    var sec_num = parseInt(segs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    var hs, ms, ss;

    if (hours < 10) { hs = "0" + hours; } else { hs = hours; }
    if (minutes < 10) { ms = "0" + minutes; } else { ms = minutes; }
    if (seconds < 10) { ss = "0" + seconds; } else { ss = seconds; }

    if (milisegundos < 0) return '-' + hs + ':' + ms;
    else return hs + ':' + ms;
  }

  public milisecondsTo_HH_MM_SS(milisegundos) {
    var segs;
    if (milisegundos < 0) segs = -(milisegundos / 1000);
    else segs = (milisegundos / 1000);

    var sec_num = parseInt(segs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    var hs, ms, ss;

    if (hours < 10) { hs = "0" + hours; } else { hs = hours; }
    if (minutes < 10) { ms = "0" + minutes; } else { ms = minutes; }
    if (seconds < 10) { ss = "0" + seconds; } else { ss = seconds; }

    if (milisegundos < 0) return '-' + hs + ':' + ms + ':' + ss;
    else return hs + ':' + ms + ':' + ss;
  }

  redondearMaximo6Char(n) {
    if (n == null) return 0;
    if (Math.round(n).toString().length < 5) return this.roundTo(n, 2);
    if (Math.round(n).toString().length == 5) return this.roundTo(n, 1);
    if (Math.round(n).toString().length > 5) return this.roundTo(n, 0);
  }

  roundTo(num, scale) {
    return Math.round(num * Math.pow(10, scale) + Number.EPSILON) / Math.pow(10, scale);
  }

  //Si estamos encima de algun porcentaje entonces guardar el id de la maquina para no actualizar ese tooltip
  mouseOverPorcentajeId = undefined;
  mouseOverPorcentaje(id) {
    this.mouseOverPorcentajeId = id;
  }
  mouseOutPorcentaje(id) {
    this.mouseOverPorcentajeId = undefined;
  }

  // calcularDesviacionPieza(maquina: any) {
  //   var dias = 0;
  //   var horas = 0;
  //   try {
  //     if (maquina.ejecuciones[0].hechas != 0) {
  //       this.tiempoAcumuladoAux = maquina.tiempopasadoConAcumulado.toString();
  //       this.listaTiempo = this.tiempoAcumuladoAux.split(' ');

  //       if (this.listaTiempo.length > 2) {
  //         this.tiempoAcumuladoAux = "";
  //         this.tiempoAcumuladoAux = this.listaTiempo[2].toString();
  //         this.listaTiempo2 = this.tiempoAcumuladoAux.split(':');
  //         this.tiempoAcumulado = parseInt(this.listaTiempo[0].toString()) * 1440 + parseInt(this.listaTiempo2[0].toString()) * 60 + parseInt(this.listaTiempo2[1].toString());
  //       } else {
  //         this.listaTiempo = this.tiempoAcumuladoAux.split(':');
  //         this.tiempoAcumulado = parseInt(this.listaTiempo[0].toString()) * 60 + parseInt(this.listaTiempo[1].toString());
  //       }

  //       // Cycle time (Tiempo lote - tiempo pieza actual) / piezas hechas
  //       var lag = maquina.tiempopasadoConAcumulado
  //       if (maquina.tiempopasadoConAcumulado.includes('d')) {
  //         var tiempoAcumuladoMinutos = this.tiempoFormatoDiasAMinutos(maquina.tiempopasadoConAcumulado);
  //       } else if (maquina.tiempopasadoConAcumulado.includes(':')) {
  //         var tiempoAcumuladoMinutos = this.tiempoFormatoHorasAMinutos(maquina.tiempopasadoConAcumulado);
  //       }
  //       if (this.tiempoActualPieza.get(maquina.id) != undefined) {
  //         if (this.tiempoActualPieza.get(maquina.id).includes('d')) {
  //           var tiempoPiezaActualMinutos = this.tiempoFormatoDiasAMinutos(this.tiempoActualPieza.get(maquina.id));
  //         } else if (this.tiempoActualPieza.get(maquina.id).includes(':')) {
  //           var tiempoPiezaActualMinutos = this.tiempoFormatoHorasAMinutos(this.tiempoActualPieza.get(maquina.id));
  //         }
  //       }
  //       if (maquina.ejecuciones[0].hechas > 0) {
  //         this.tiempoMedioPieza = Math.floor((tiempoAcumuladoMinutos - tiempoPiezaActualMinutos) / maquina.ejecuciones[0].hechas);
  //       } else {
  //         this.tiempoMedioPieza = tiempoAcumuladoMinutos - tiempoPiezaActualMinutos;
  //       }
  //       // Dar formato a cycle time
  //       var cycleTimeFormatoHora = this.segundosAFormatoHora(Math.abs(this.tiempoMedioPieza) * 60);
  //       this.piezaTiempoMedioMap.set(maquina.id, cycleTimeFormatoHora);

  //       // Desviacion pieza
  //       if (this.piezaEst.get(maquina.id) != undefined) {
  //         if (this.piezaEst.get(maquina.id).includes('d')) {
  //           var tiempoEstimadoPiezaMinutos = this.tiempoFormatoDiasAMinutos(this.piezaEst.get(maquina.id));
  //         } else if (this.piezaEst.get(maquina.id).includes(':')) {
  //           var tiempoEstimadoPiezaMinutos = this.tiempoFormatoHorasAMinutos(this.piezaEst.get(maquina.id));
  //         }
  //       } else {
  //         var tiempoEstimadoPiezaMinutos = 0;
  //       }

  //       this.desviacionPieza = this.tiempoMedioPieza - tiempoEstimadoPiezaMinutos;
  //       // Dar formato a desviacion pieza
  //       if (this.desviacionPieza < 0) {
  //         if (Math.abs(this.desviacionPieza) > 60) {
  //           this.piezaDesviacionMap.set(maquina.id, '- ' + this.segundosAFormatoHora(this.desviacionPieza * 60));
  //         } else {
  //           this.piezaDesviacionMap.set(maquina.id, '- ' + Math.abs(this.desviacionPieza) + "min");
  //         }
  //       } else {
  //         if (Math.abs(this.desviacionPieza) > 60) {
  //           this.piezaDesviacionMap.set(maquina.id, "+ " + this.segundosAFormatoHora(this.desviacionPieza * 60));
  //         } else {
  //           this.piezaDesviacionMap.set(maquina.id, '+ ' + Math.abs(this.desviacionPieza) + "min");
  //         }
  //       }
  //     } else {
  //       this.tiempoMedioPieza = 0;
  //       this.piezaTiempoMedioMap.set(maquina.id, "00:00:00");
  //       this.desviacionPieza = 0;
  //       this.piezaDesviacionMap.set(maquina.id, '+ 00:00:00');
  //     }
  //   } catch (error) {
  //     this.tiempoMedioPieza = 0;
  //     this.piezaTiempoMedioMap.set(maquina.id, "00:00:00");
  //     this.desviacionPieza = 0;
  //     this.piezaDesviacionMap.set(maquina.id, '+ 00:00:00');
  //   }


  // }

  calcularDesviacionPieza(maquina: any) {
    try {
      if (this.ejecuciones[0].contador > 0) {
        this.tiempoAcumuladoAux = this.tiempoActualPieza.toString();
        this.listaTiempo = this.tiempoAcumuladoAux.split(' ');

        if (this.listaTiempo.length > 2) {
          this.tiempoAcumuladoAux = "";
          this.tiempoAcumuladoAux = this.listaTiempo[2].toString();
          this.listaTiempo2 = this.tiempoAcumuladoAux.split(':');
          this.tiempoAcumulado = parseInt(this.listaTiempo[0].toString()) * 1440 + parseInt(this.listaTiempo2[0].toString()) * 60 + parseInt(this.listaTiempo2[1].toString());
        } else {
          if (this.tiempoAcumuladoAux.includes("h")) {
            this.tiempoAcumuladoAux = this.tiempoAcumuladoAux.trim()
            this.listaTiempo = this.tiempoAcumuladoAux.split('d');
            this.tiempoAcumulado = parseInt(this.listaTiempo[0].toString()) * 3600 + parseInt(this.listaTiempo[1].replace("h", "".toString())) * 60;
          } else {
            this.listaTiempo = this.tiempoAcumuladoAux.split(':');
            this.tiempoAcumulado = parseInt(this.listaTiempo[0].toString()) * 3600 + parseInt(this.listaTiempo[1].toString()) * 60 + parseInt(this.listaTiempo[2].toString());
          }
        }

        // Cycle time (Tiempo lote - tiempo pieza actual) / piezas hechas
        // var lagAcumulado = maquina.tiempopasadoConAcumulado.toString();
        // var lagPieza = this.tiempoActualPieza.toString();
        //console.log("=>" + lagAcumulado + " " +this.translateService.instant('numdiashome').toLowerCase());
        // if (lagAcumulado.includes(this.translateService.instant('numdiashome').toLowerCase())) {
        //   var tiempoAcumulado = this.tiempoFormatoDiasAMinutos(lagAcumulado) * 60;
        // } else if (lagAcumulado.includes(':')) {
        //   var tiempoAcumulado = this.tiempoFormatoHorasASegundos(lagAcumulado);
        // }

        // if (this.tiempoLoteSegundos != -1){
        //   var tiempoAcumulado = this.tiempoLoteSegundos;
        // }else{
        //   var tiempoAcumulado = 0;
        // }

        // if (this.tiempoActualPieza != undefined) {
        //   if (lagPieza.includes('d')) {
        //     var tiempoPiezaActual = this.tiempoFormatoDiasAMinutos(lagPieza) * 60;
        //   } else if (lagPieza.includes(':')) {
        //     var tiempoPiezaActual = this.tiempoFormatoHorasASegundos(lagPieza);
        //   }
        // }

        if (maquina.ejecuciones[0].contador > 0) {
          this.tiempoMedioPieza = Math.floor(Math.abs(this.tiempoLoteSegundos - this.tiempoPiezaSegundos) / maquina.ejecuciones[0].contador);
        } else {
          this.tiempoMedioPieza = this.tiempoLoteSegundos - this.tiempoPiezaSegundos;
        }
        // Dar formato a cycle time
        var cycleTimeFormatoHora = this.segundosAFormatoHora(this.tiempoMedioPieza);
        this.piezaTiempoMedio = cycleTimeFormatoHora;

        // Desviacion pieza
        if (this.piezaEst != undefined) {
          if (this.piezaEst.includes('d')) {
            var tiempoEstimadoPieza = this.tiempoFormatoDiasAMinutos(this.piezaEst) * 60;
          } else if (this.piezaEst.includes(':')) {
            var tiempoEstimadoPieza = this.tiempoFormatoHorasASegundos(this.piezaEst);
          }
        } else {
          var tiempoEstimadoPieza = 0;
        }

        this.desviacionPieza = this.tiempoMedioPieza - tiempoEstimadoPieza;
        // Dar formato a desviacion pieza
        if (this.desviacionPieza < 0) {
          if (Math.abs(this.desviacionPieza) > 3600) {
            this.piezaDesviacion = '- ' + this.segundosAFormatoHora(Math.abs(this.desviacionPieza));
          } else {
            this.piezaDesviacion = '- ' + "00:" + this.pad(Math.floor(Math.abs(this.desviacionPieza) / 60)) + ":" + this.pad(Math.floor(Math.abs(this.desviacionPieza)) % 60);
          }
        } else {
          if (Math.abs(this.desviacionPieza) > 3600) {
            this.piezaDesviacion = "+ " + this.segundosAFormatoHora(Math.abs(this.desviacionPieza));
          } else {
            this.piezaDesviacion = '+ ' + "00:" + this.pad(Math.floor(Math.abs(this.desviacionPieza) / 60)) + ":" + this.pad(Math.floor(Math.abs(this.desviacionPieza)) % 60);
          }
        }
      } else {
        this.tiempoMedioPieza = 0;
        this.piezaTiempoMedio = "00:00:00";
        this.desviacionPieza = 0;
        this.piezaDesviacion = "+ 00:00:00";
      }
    } catch (error) {
      this.tiempoMedioPieza = 0;
      this.piezaTiempoMedio = "00:00:00";
      this.desviacionPieza = 0;
      this.piezaDesviacion = "+ 00:00:00";
    }


  }

  // calcularDesviacionLote(maquina: any) { //total time
  //   var dias = 0;
  //   var horas = 0;
  //   if (maquina.tiempopasadoConAcumulado != undefined) {
  //     this.tiempoAcumuladoAux = maquina.tiempopasadoConAcumulado.toString();
  //   } else {
  //     this.tiempoAcumuladoAux = "00:00:00";
  //   }

  //   this.listaTiempo = this.tiempoAcumuladoAux.split(' ');

  //   if (this.listaTiempo.length > 2) {
  //     this.tiempoAcumuladoAux = '';
  //     this.tiempoAcumuladoAux = this.listaTiempo[2].toString();
  //     this.listaTiempo2 = this.tiempoAcumuladoAux.split(':');
  //     this.tiempoAcumulado = parseInt(this.listaTiempo[0]) * 1440 + parseInt(this.listaTiempo2[0]) * 60 + parseInt(this.listaTiempo2[1].toString());
  //   } else {
  //     this.listaTiempo = this.tiempoAcumuladoAux.split(':');
  //     this.tiempoAcumulado = parseInt(this.listaTiempo[0].toString()) * 60 + parseInt(this.listaTiempo[1].toString());
  //   }
  //   if (maquina.tiempoEst != undefined) {
  //     this.tiempoEstimadoLoteAux = maquina.tiempoEst.toString();
  //   } else {
  //     this.tiempoEstimadoLoteAux = "00:00:00";
  //   }

  //   this.listaTiempo = this.tiempoEstimadoLoteAux.split(' ');

  //   if (this.listaTiempo.length > 2) {
  //     this.tiempoEstimadoLoteAux = '';
  //     this.tiempoEstimadoLoteAux = this.listaTiempo[2].toString();
  //     this.listaTiempo2 = this.tiempoEstimadoLoteAux.split(':');
  //     this.tiempoEstimadoLote = parseInt(this.listaTiempo[0].toString()) * 1440 + parseInt(this.listaTiempo2[0].toString()) * 60 + parseInt(this.listaTiempo2[1].toString());
  //   } else {
  //     this.listaTiempo = this.tiempoEstimadoLoteAux.split(':');
  //     this.tiempoEstimadoLote = parseInt(this.listaTiempo[0].toString()) * 60 + parseInt(this.listaTiempo[1]);
  //   }

  //   this.desviacionLote = this.tiempoAcumulado - this.tiempoEstimadoLote;

  //   if (this.desviacionLote < 0) {
  //     if (Math.abs(this.desviacionLote) > 60) {
  //       this.loteDesviacionMap.set(maquina.id, '- ' + this.segundosAFormatoHora(Math.abs(this.desviacionLote) * 60));
  //     } else {
  //       this.loteDesviacionMap.set(maquina.id, '- ' + Math.abs(this.desviacionLote) + "min")
  //     }
  //   } else {
  //     if (Math.abs(this.desviacionLote) > 60) {
  //       this.loteDesviacionMap.set(maquina.id, '+ ' + this.segundosAFormatoHora(Math.abs(this.desviacionLote) * 60));
  //     } else {
  //       this.loteDesviacionMap.set(maquina.id, '+ ' + Math.abs(this.desviacionLote) + "min")
  //     }
  //   }

  // }

  calcularDesviacionLote(maquina: any) { //total time
    var dias = 0;
    var horas = 0;
    if (maquina.tiempopasadoConAcumulado != undefined) {
      this.tiempoAcumuladoAux = maquina.tiempopasadoConAcumulado.toString();
    } else {
      this.tiempoAcumuladoAux = "00:00:00";
    }
    // Mirar si tiempo contiene dias o no
    this.listaTiempo = this.tiempoAcumuladoAux.split(' ');
    //Contiene dias
    if (this.listaTiempo.length > 2) {
      this.tiempoAcumuladoAux = '';
      this.tiempoAcumuladoAux = this.listaTiempo[2].toString();
      this.listaTiempo2 = this.tiempoAcumuladoAux.split(':');
      this.tiempoAcumulado = parseInt(this.listaTiempo[0]) * 86400 + parseInt(this.listaTiempo2[0]) * 3600 + parseInt(this.listaTiempo2[1].toString()) * 60 + parseInt(this.listaTiempo2[2].toString());
    } else { // No llega al dia
      this.listaTiempo = this.tiempoAcumuladoAux.split(':');
      this.tiempoAcumulado = parseInt(this.listaTiempo[0].toString()) * 3600 + parseInt(this.listaTiempo[1].toString()) * 60 + parseInt(this.listaTiempo[2].toString());
    }
    if (maquina.tiempoEst != undefined) {
      this.tiempoEstimadoLoteAux = maquina.tiempoEst.toString();
    } else {
      this.tiempoEstimadoLoteAux = "00:00:00";
    }

    this.listaTiempo = this.tiempoEstimadoLoteAux.split(' ');

    if (this.listaTiempo.length > 2) {
      this.tiempoEstimadoLoteAux = '';
      this.tiempoEstimadoLoteAux = this.listaTiempo[2].toString();
      this.listaTiempo2 = this.tiempoEstimadoLoteAux.split(':');
      this.tiempoEstimadoLote = parseInt(this.listaTiempo[0]) * 86400 + parseInt(this.listaTiempo2[0]) * 3600 + parseInt(this.listaTiempo2[1].toString()) * 60 + parseInt(this.listaTiempo2[2].toString());
    } else {
      this.listaTiempo = this.tiempoEstimadoLoteAux.split(':');
      this.tiempoEstimadoLote = parseInt(this.listaTiempo[0].toString()) * 3600 + parseInt(this.listaTiempo[1]) * 60 + parseInt(this.listaTiempo[2]);
    }

    this.desviacionLote = this.tiempoLoteSegundos - this.tiempoEstimadoLote;

    if (this.desviacionLote < 0) {
      if (Math.abs(this.desviacionLote) > 3600) {
        this.loteDesviacion = '- ' + this.segundosAFormatoHora(Math.abs(this.desviacionLote));
      } else {
        this.loteDesviacion = '- ' + "00:" + this.pad(Math.floor(Math.abs(this.desviacionLote) / 60)) + ":" + this.pad(Math.floor(Math.abs(this.desviacionLote) % 60));
      }
    } else {
      if (Math.abs(this.desviacionLote) > 3600) {
        this.loteDesviacion = '+ ' + this.segundosAFormatoHora(Math.abs(this.desviacionLote));
      } else {
        this.loteDesviacion = '+ ' + "00:" + this.pad(Math.floor(Math.abs(this.desviacionLote) / 60)) + ":" + this.pad(Math.floor(Math.abs(this.desviacionLote) % 60));
      }
    }

    if (this.tiempoLotePreparacionSegundos > maquina.tiempoPreparacionSegundos) {
      this.tiempoDesvioPreparacion = "+ " + this.segundosAFormatoHora(Math.abs(this.tiempoLotePreparacionSegundos - maquina.ejecuciones[0].tiempoPreparacionSegundos))
    } else {
      this.tiempoDesvioPreparacion = "- " + this.segundosAFormatoHora(Math.abs(maquina.ejecuciones[0].tiempoPreparacionSegundos - this.tiempoLotePreparacionSegundos))
    }

  }

  // calcularTiempoEstPredPieza(maquina: any) {

  //   if (maquina.ejecuciones[0].tiempoEstimadoSegundos == 0) {
  //     this.piezaEst.set(maquina.id, "00:00:00");
  //   } else {
  //     this.loteEstMap.set(maquina.id, this.segundosAFormatoHora(maquina.ejecuciones[0].tiempoEstimadoSegundos))
  //     this.tiempoEstAux = parseInt(maquina.ejecuciones[0].tiempoEstimadoSegundos.toString()) / parseInt(maquina.ejecuciones[0].cantidad.toString());

  //     var tiempoConFormato = this.segundosAFormatoHora(this.tiempoEstAux);
  //     this.piezaEst.set(maquina.id, tiempoConFormato);


  //   } if (maquina.ejecuciones[0].tiempoPredSegundos == 0) {
  //     this.piezaPred.set(maquina.id, "00:00:00");
  //   } else {
  //     this.lotePredMap.set(maquina.id, this.segundosAFormatoHora(maquina.ejecuciones[0].tiempoPredSegundos))
  //     this.tiempoPredAux = maquina.ejecuciones[0].tiempoPredSegundos / maquina.ejecuciones[0].cantidad;

  //     var tiempoConFormato = this.segundosAFormatoHora(this.tiempoPredAux);
  //     this.piezaPred.set(maquina.id, tiempoConFormato);
  //   }


  // }

  calcularTiempoEstPredPieza(maquina: any) {

    if (maquina.ejecuciones[0].tiempoEstimadoSegundos == 0) {
      this.piezaEst = "00:00:00";
      this.loteEst = "00:00:00";
    } else {
      this.loteEst = this.segundosAFormatoHora(maquina.ejecuciones[0].tiempoEstimadoSegundos);
      this.tiempoEstAux = parseInt(maquina.ejecuciones[0].tiempoEstimadoSegundos.toString()) / parseInt(maquina.ejecuciones[0].cantidad.toString());

      var tiempoConFormato = this.segundosAFormatoHora(this.tiempoEstAux);
      this.piezaEst = tiempoConFormato;


    } if (maquina.ejecuciones[0].tiempoPredSegundos == 0) {
      this.piezaPred = "00:00:00";
      this.lotePred = "00:00:00";
    } else {
      this.lotePred = this.segundosAFormatoHora(maquina.ejecuciones[0].tiempoPredSegundos);
      this.tiempoPredAux = maquina.ejecuciones[0].tiempoPredSegundos / maquina.ejecuciones[0].cantidad;

      var tiempoConFormato = this.segundosAFormatoHora(this.tiempoPredAux);
      this.piezaPred = tiempoConFormato;
    }


  }

  pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }

  validateOF(maquina: any) {
    if (maquina.ejecuciones[0].refOF != this.translateService.instant("noproceso"))
      this.isOF = true;
    else
      this.isOF = false;
  }

  datosMaquinaVaciar(maquina: any) {
    this.piezaPorcentaje = "-";
    if (maquina.ejecuciones[0].refOF == this.translateService.instant("noproceso")) {
      this.piezaimg = "assets/dcg/dcg0-" + maquina.ejecuciones[0].procesos_Tipo + ".png";
    } else {
      this.piezaimg = "assets/dcg/dcg0-apagada.png";
      this.lotePorcentaje = "-";
    }
    this.tiempoActualPieza = "--:--:--";
    this.piezaTiempoMedio = "--:--:--";
    this.piezaDesviacion = "--:--:--";
    this.piezaEst = "--:--:--";
    this.piezaPred = "--:--:--";
    this.loteEst = "--:--:--";
    this.lotePred = "--:--:--";
    this.tiempoActualLote = "--:--:--";
    this.tiempoLotePreparacion = "--:--:--";
    this.tiempoPiezaPreparacion = "--:--:--";
    this.loteDesviacion = "--:--:--";
    this.tiempoDesvioPreparacion = "--:--:--";
  }

  tiempoFormatoDiasAMinutos(tiempo: string) {
    var tiempoAux = tiempo.split(' ');
    // tratar dias
    var dias = tiempoAux[0];
    dias = dias.replace('d', '');
    var diasMinutos = parseInt(dias) * 24 * 60;
    // tratar horas
    if (tiempo.includes(':')) {
      var horasAux = tiempoAux[2].split(':');
      var horas = horasAux[0];
      var horasMinutos = parseInt(horas) * 60;
    } else {
      var horas = tiempoAux[1].replace('h', '');
      var horasMinutos = parseInt(horas) * 60;
    }

    return diasMinutos + horasMinutos;
  }

  tiempoFormatoHorasAMinutos(tiempo: string) {
    if (tiempo == undefined) {
      return 0;
    }
    var tiempoAux = tiempo.split(':');
    // Tratar horas
    var horasMinutos = parseInt(tiempoAux[0]) * 60;
    // Tratar minutos
    var minutos = parseInt(tiempoAux[1]);
    return horasMinutos + minutos;
  }

  segundosAFormatoHora(tiempo: number) {
    // if (tiempo < this.segundosDeUnDia) {
    var horas = this.pad(Math.floor(tiempo / 3600));
    var minutos = this.pad(Math.floor((tiempo % 3600) / 60));
    var segundos = this.pad(Math.floor(((tiempo % 3600) % 60) / 1));
    var formatoHora = horas + ":" + minutos + ":" + segundos;
    // } else {
    //   var dias = Math.floor((tiempo / 3600) / 24);
    //   var horas = this.pad(Math.floor((tiempo / 3600) % 24));
    //   var formatoHora = dias + "d " + horas + "h"
    // }
    return formatoHora;
  }

  tiempoFormatoHorasASegundos(tiempo: string) {
    if (tiempo == undefined) {
      return 0;
    }
    var tiempoAux = tiempo.split(':');
    // Tratar horas
    var horasMinutos = parseInt(tiempoAux[0]) * 3600;
    // Tratar minutos
    var minutos = parseInt(tiempoAux[1]) * 60;
    // Tratar segundos
    var segundos = parseInt(tiempoAux[2]);
    return horasMinutos + minutos + segundos;
  }
}
