import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MaquinasService, InformeOeeService, PlantaIsometricoService} from '@app/_services';


@Injectable()
export class ResetAllModelsService {

  constructor(
    private http: HttpClient,
    private maquinasService: MaquinasService,
    private informeOeeService: InformeOeeService,
    private plantaIsometricoService: PlantaIsometricoService
  ) {
  }

  public resetAllModels() {

    this.maquinasService.set_grupos_model(false);
    this.maquinasService.set_maquinas_model(false);
    this.maquinasService.set_maquinas_planificador_model(false);
    this.maquinasService.set_instalaciones_model(false);
    this.maquinasService.set_marcas_model(false);

    this.informeOeeService.set_maquinas_model(false);
    this.informeOeeService.set_maquinasCompletas_model([]);
    this.informeOeeService.set_maquinasSeleccionadas_model([]);
    this.informeOeeService.set_seccionesSeleccionadas_model([]);
    this.informeOeeService.set_gruposSeleccionados_model([]);
    this.informeOeeService.set_fechaIni_model(undefined);
    this.informeOeeService.set_fechaFin_model(undefined);
    
    this.plantaIsometricoService.Set_ListaPlantas_Model(false);

  }

}
