<style>
    .c3-line{
  stroke-dasharray: 5,5;
}
</style>
<!--   FILTRO-->
<div class="cont-filtro-desplegable card" [ngClass]="status ? 'filtro-oculto' : 'filtro-desplegado'">
    <div class="btn-filtro-desplegable" (click)="clickEvent()"><i class="fas fa-sliders-h"></i></div>
    <div class="filtro-desplegable card-body">
        <div class="filtro-seleccionados">
            <!--   LOS OBLIGATORIOS ESTÁN YA AQUÍ DE POR SÍ-->

            <!--LISTA OFS-->
            <div class="clearfix" id="ofFiltro">
                <div class="form-group">
                    <label>{{ 'of' | translate }}</label>
                    <div class="caja">
                        <kendo-multiselect kendoMultiSelectSummaryTag [data]="listaOfs" [(ngModel)]="ofsSeleccionados"
                            [textField]="'nombreOf'" [valueField]="'idOf'"
                            placeholder="{{ 'seleccioneOf' | translate }}" [autoClose]="false"
                            [kendoDropDownFilter]="{operator: 'contains'}" (valueChange)="ofsChange()">
                            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                <span class="k-icon k-i-arrow-s"></span>
                                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreOf}}
                                </ng-container>
                                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{
                                    'ofsSeleccionadas'
                                    |
                                    translate }}</ng-container>
                            </ng-template>
                            <kendo-multiselect-messages noDataText="{{'norecords' | translate}}">
                            </kendo-multiselect-messages>
                        </kendo-multiselect>
                    </div>
                </div>
            </div>

            <!--LISTA PIEZAS-->
            <div class="clearfix" id="piezaFiltro">
                <div class="form-group">
                    <label>{{ 'pieza' | translate }}</label>
                    <div class="caja">
                        <kendo-multiselect kendoMultiSelectSummaryTag [data]="listaPiezas"
                            [(ngModel)]="piezasSeleccionadas" [textField]="'nombrePieza'" [valueField]="'idPieza'"
                            placeholder="{{ 'seleccionePieza' | translate }}" [autoClose]="false"
                            [kendoDropDownFilter]="{operator: 'contains'}" (valueChange)="piezasChange()">
                            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                <span class="k-icon k-i-arrow-s"></span>
                                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombrePieza}}
                                </ng-container>
                                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{
                                    'piezasSeleccionadas' |
                                    translate }}</ng-container>
                            </ng-template>
                            <kendo-multiselect-messages noDataText="{{'norecords' | translate}}">
                            </kendo-multiselect-messages>
                        </kendo-multiselect>
                    </div>
                </div>
            </div>

            <!--LISTA PARTES (SI !ocultarParte no enseñar) -->
            <div class="clearfix" *ngIf="!user.ocultarParte" id="parteFiltro">
                <div class="form-group">
                    <label>{{ 'parte' | translate }}</label>
                    <div class="caja">
                        <kendo-multiselect kendoMultiSelectSummaryTag [data]="listaPartes"
                            [(ngModel)]="partesSeleccionadas" [textField]="'nombreParte'" [valueField]="'idParte'"
                            placeholder="{{ 'seleccioneParte' | translate }}" [autoClose]="false"
                            [kendoDropDownFilter]="{operator: 'contains'}" (valueChange)="partesChange()">
                            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                <span class="k-icon k-i-arrow-s"></span>
                                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreParte}}
                                </ng-container>
                                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{
                                    'partesSeleccionadas' |
                                    translate }}</ng-container>
                            </ng-template>
                            <kendo-multiselect-messages noDataText="{{'norecords' | translate}}">
                            </kendo-multiselect-messages>
                        </kendo-multiselect>
                    </div>
                </div>
            </div>

            <!--AREAS PRODUCTIVAS-->
            <div class="clearfix" id="seccionesFiltro">
                <div class="form-group">
                <label>{{ 'secciones' | translate }}</label>
                <div class="caja">
                    <kendo-multiselect kendoMultiSelectSummaryTag [(data)]="groupedSeccion"
                                    [textField]="'nombre'"
                                    [valueField]="'id'"
                                    [autoClose]="false"
                                    [(ngModel)]="seccionesSeleccionadas"
                                    placeholder="{{ 'secciones' | translate }}"
                                    [kendoDropDownFilter]="{operator: 'contains'}" 
                                    (close)="seccionChanged()"
                                    (valueChange)="seccionesChange()" >
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                        <span class="k-icon k-i-arrow-s"></span>
                        <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                        <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seccionesSeleccionadas' | translate }}</ng-container>
                    </ng-template>
                    <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                    </kendo-multiselect>
                </div>
                </div>
            </div>

            <!--TIPOS DE PROCESOS-->
            <div class="clearfix" id="procesosFiltro">
                <div class="form-group">
                    <label>{{ 'tipoProceso' | translate }}</label>
                    <div class="caja">
                        <kendo-multiselect kendoMultiSelectSummaryTag [data]="listaProcesos"
                            [(ngModel)]="procesosSeleccionados" [textField]="'nombreProceso'" [valueField]="'idProceso'"
                            placeholder="{{ 'seleccioneProceso' | translate }}" [autoClose]="false"
                            [kendoDropDownFilter]="{operator: 'contains'}" (valueChange)="procesosChange()">
                            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                <span class="k-icon k-i-arrow-s"></span>
                                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreProceso}}
                                </ng-container>
                                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{
                                    'procesosSeleccionados' | translate }}</ng-container>
                            </ng-template>
                            <kendo-multiselect-messages noDataText="{{'norecords' | translate}}">
                            </kendo-multiselect-messages>
                        </kendo-multiselect>
                    </div>
                </div>
            </div>

        </div>
        <div class="filtro-botones" *ngIf="getChildren()>0">
            <div class="clearfix">
                <button type="button" class="btn btn-danger mr-1 float-left" (click)="btnLimpiarFiltro()">{{
                    'limpiarFiltro' | translate }}</button>
                 <!--Se utiliza la opcion "opcionesSeleccionadas" para que cuando ya tenemos los obligatorios seleccionados te deje filtrar -->
                <button type="button" class="btn btn-primary float-left " [disabled]="!opcionesSeleccionadas" (click)="btnFiltrar()">{{ 'filtrar' |
                    translate }}</button>
            </div>
        </div>
        <div class="filtro-no-seleccionados">

            <!--FECHAS DE INICIO Y FIN-->
            <div class="clearfix" id="fechasFiltro">
                <label>{{ 'fechas' | translate }}</label>
                <div class="calendarFiltro">
                    <div class="calendarFiltro-input" (click)="showCalendar()">
                        <span id="calendarioInput"
                            *ngIf="periodoActualSeleccionado.length==1">{{periodoActualSeleccionado[0] |
                            kendoDate}}</span>
                        <span id="calendarioInput"
                            *ngIf="periodoActualSeleccionado.length>1">{{periodoActualSeleccionado[0] | kendoDate}}
                            -
                            {{periodoActualSeleccionado[1] | kendoDate}}</span>
                        <i class="fa fa-calendar" aria-hidden="true"></i>
                    </div>
                    <div class="calendarFiltro-calendario"
                        [ngClass]="mostrarCalendario ? 'mostrarCalendario' : 'ocultarCalendario'">
                        <div class="row">
                            <ng-container *ngFor="let month of monthsInYearFila;">
                                <div class="col-mes">
                                    <kendo-calendar id="calendario-{{month}}" [(value)]="DaysInMonths[month]"
                                        type="classic" (click)="valueClickCalendar(month, $event)">
                                        <ng-template kendoCalendarMonthCellTemplate let-date>
                                            <span [class.calendarFiltro-calendario-contenido-dia]="true"
                                                [class.calendarFiltro-calendarioSeleccionado]="isDateSelected(date)">
                                                {{ date.getDate() }}
                                            </span>
                                        </ng-template>
                                        <ng-template kendoCalendarHeaderTitleTemplate let-title>
                                            <div id="calendario-title-{{month}}">
                                                {{ title }}
                                            </div>
                                          </ng-template>
                                    </kendo-calendar>
                                </div>
                            </ng-container>
                            <div class="col-mes-botones">
                                <div class="calendarFiltro-botones">
                                    <div class="clearfix text-center">
                                        <button (click)="cambiarMeses(-1)"><i class="fa fa-chevron-left"></i></button>
                                        <button (click)="cambiarMeses(1)"><i class="fa fa-chevron-right"></i></button>
                                    </div>
                                    <div class="clearfix"><button (click)="ultimas24HButton()">{{'ultimas24H' |
                                            translate}}</button></div>
                                    <div class="clearfix"><button (click)="ultimos7DiasButton()">{{'ultimos7Dias' |
                                            translate}}</button></div>
                                    <div class="clearfix"><button (click)="ultimos30DiasButton()">{{'ultimos30Dias'
                                            |
                                            translate}}</button></div>
                                    <div class="clearfix"><button (click)="ultimos60DiasButton()">{{'ultimos60Dias'
                                            |
                                            translate}}</button></div>
                                    <div class="clearfix"><button (click)="ultimos90DiasButton()">{{'ultimos90Dias'
                                            |
                                            translate}}</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <!--LISTA RUTAS-->
            <div class="clearfix" id="rutasFiltro">
                <div class="form-group">
                    <label>{{ 'ruta' | translate }}</label>
                    <div class="caja">
                        <kendo-multiselect kendoMultiSelectSummaryTag [data]="listaRutas"
                            [(ngModel)]="rutasSeleccionadas" [textField]="'nombreRuta'"
                            [valueField]="'idRuta'" placeholder="{{ 'seleccioneRuta' | translate }}"
                            [autoClose]="false" [kendoDropDownFilter]="{operator: 'contains'}"
                            (valueChange)="rutasChange()">
                            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                <span class="k-icon k-i-arrow-s"></span>
                                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreRuta}}
                                </ng-container>
                                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{
                                    'rutasSeleccionadas' | translate }}</ng-container>
                            </ng-template>
                            <kendo-multiselect-messages noDataText="{{'norecords' | translate}}">
                            </kendo-multiselect-messages>
                        </kendo-multiselect>
                    </div>
                </div>
            </div>

            <!--LISTA OPERACIONES-->
            <div class="clearfix" id="operacionesFiltro">
                <div class="form-group">
                    <label>{{ 'operacion' | translate }}</label>
                    <div class="caja">
                        <kendo-multiselect kendoMultiSelectSummaryTag [data]="listaOperaciones"
                            [(ngModel)]="operacionesSeleccionadas" [textField]="'nombreOperacion'"
                            [valueField]="'idOperacion'" placeholder="{{ 'seleccioneOperacion' | translate }}"
                            [autoClose]="false" [kendoDropDownFilter]="{operator: 'contains'}"
                            (valueChange)="operacionesChange()">
                            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                <span class="k-icon k-i-arrow-s"></span>
                                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreOperacion}}
                                </ng-container>
                                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{
                                    'operacionesSeleccionadas' | translate }}</ng-container>
                            </ng-template>
                            <kendo-multiselect-messages noDataText="{{'norecords' | translate}}">
                            </kendo-multiselect-messages>
                        </kendo-multiselect>
                    </div>
                </div>
            </div>
            
            <!--GRUPOS-->
            <div class="clearfix" id="gruposFiltro">
                <div class="form-group">
                <label>{{ 'grupos' | translate }}</label>
                <div class="caja">
                    <kendo-multiselect kendoMultiSelectSummaryTag [data]="grupos"
                                    [textField]="'nombre'"
                                    [valueField]="'id'"
                                    [autoClose]="false"
                                    [(ngModel)]="gruposSeleccionados"
                                    placeholder="{{ 'grupoMaquinas' | translate }}"
                                    [kendoDropDownFilter]="{operator: 'contains'}" 
                                    (close)="seccionChanged()"
                                    (valueChange)="gruposChange()">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                        <span class="k-icon k-i-arrow-s"></span>
                        <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                        <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'gruposSeleccionados' | translate }}</ng-container>
                    </ng-template>
                    </kendo-multiselect>
                </div>
                </div>
            </div>


            <!--LISTA MAQUINAS-->
            <div class="clearfix" id="maquinasFiltro">
                <div class="form-group">
                <label>{{ 'maquina' | translate }}</label>
                <div class="caja">
                    <kendo-multiselect kendoMultiSelectSummaryTag [data]="listaMaquinas" [(ngModel)]="maquinasSeleccionadas" 
                    [textField]="'nombre'" [valueField]="'id'"
                                    placeholder="{{ 'seleccioneMaquina2' | translate }}" 
                                    [autoClose]="false" [kendoDropDownFilter]="{operator: 'contains'}"
                                    (valueChange)="maquinasChange()">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                        <span class="k-icon k-i-arrow-s"></span>
                        <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre}}</ng-container>
                        <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'maquinasSeleccionadas' | translate }}</ng-container>
                    </ng-template>
                    <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                    </kendo-multiselect>
                </div>
                </div>
            </div>


            <!--SOLO OPERACIONES CON DESVIO-->
            <div class="clearfix" id="desvioFiltro">
                <div class="form-group">
                    <label>{{ 'soloOperacionesDesvio' | translate }}</label>
                    <div class="caja" style="margin: 0 0 0 7px;">
                        <kendo-switch [(ngModel)]="soloOperacionesDesvio" [onLabel]="' '" (valueChange)="desvioChange()"
                            [offLabel]="' '"></kendo-switch>
                    </div>
                </div>
            </div>

            <!--GRAFICO REFERENCIA-->
            <div class="clearfix" id="graficoReferenciaFiltro">
                <div class="form-group">
                    <label>{{ 'graficoReferencia' | translate }}</label>
                    <div class="caja">
                        <kendo-dropdownlist [data]="listaGraficos" [textField]="'nombre'"
                            (valueChange)="graficoChange($event)" [valueField]="'id'" [(value)]="graficoSeleccionado"
                            class="form-control">
                        </kendo-dropdownlist>
                    </div>
                </div>
            </div>

        </div>

    </div>

</div>
<!--   CARD CON OPCIONES SELECCIONADAS FILTRO -->
<!--   Aquí deben aparecer los tags de las opciones si: -->
<!--                                                   -Si es un multiselect, se muestra un tag con el único seleccionado si solo se ha seleccionado uno y             -->
<!--                                                   "5 seleccionados" en el caso de que sean varios -como lo que aparece en el multiselect al seleccionarlos-       -->
<!--                                                   -En el caso de los dropdowns, solo se muestran cuando las opciones son relevantes -si son como en este          -->
<!--                                                   caso tipo a, b o ninguno, solo se mostrarán los tags si es a o b, pues ninguno no es relevante                  -->
<!--                                                   -En el caso de los kendo-switchs solo cuando sea positivo, pues es cuando indica que sí cojas esos elementos    -->
<div class="card">
    <div class="card-body">
        <div class="cont-tags-filtro" id="tagsFiltro">
            
        </div>
    </div>
</div>

<div class="card">
    <div class="card-body">
        <div id="grafica-histograma">
        </div>
        <div class="cont-mensaje-sindatos" *ngIf="datosFiltro.length==0">

          <div class="mensaje-sindatos" *ngIf="noPrimeraVez">
          {{'norecords' | translate}}
          </div>

          <div class="mensaje-sindatos" *ngIf="!noPrimeraVez">
            {{'filtrarDatos' | translate}}
            </div>
  


        </div>
    </div>
</div>

<div *ngIf="!loading" class="k-i-loading"></div>
