
<style>
  .k-text-error {
    display: none;
  }

  :host /deep/ .k-grid tbody td {
    white-space: nowrap;
    line-height: 20px;
    padding: 8px 12px;
  }

  :host /deep/ .k-grid .k-grid-content td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
<!-- PANEL DE CARGA -->
<div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;"></div>

<div class="card">
  <div class="card-body">
    <!-- POSIBLES HIJOS -->
    <div class="col-md-12 k-hidden" style="margin-top: -3px;">
      <div class="form-group">
        <label>{{ 'contenido' | translate }}</label>
        <div class="caja" style="margin: 0 0 0 7px;">
          <kendo-multiselect kendoMultiSelectSummaryTag
                             [(data)]="consumiblesTipos"
                             [textField]="'nombre'"
                             [valueField]="'id'"
                             [autoClose]="false"
                             [(ngModel)]="consumiblesTiposSelecteds"
                             placeholder="{{ 'contenido' | translate }}"
                             style="width: 200px;"
                             [kendoDropDownFilter]="{operator: 'contains'}">
            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
              <span class="k-icon k-i-arrow-s"></span>
              <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
              <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'consumibles' | translate }}</ng-container>
            </ng-template>
            <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
          </kendo-multiselect>
        </div>
      </div>
      <button kendoButton class="btn mr-1  btn-danger" (click)="filtrar()">{{ 'filtrar' | translate}}</button>
    </div>

    <!-- GRID: Tooltip -->
    <ng-template #template let-anchor>
      <span>{{ anchor.nativeElement.innerText }}</span>
    </ng-template>
    <!-- GRID -->
    <div kendoTooltip
         showOn="none"
         [tooltipTemplate]="template"
         filter=".k-grid td"
         (mouseover)="showGridTooltip($event)">
      <kendo-grid [kendoGridBinding]="consumibles"
                  [sortable]="true"
                  [navigable]="true"
                  filterable="menu"
                  [rowHeight]="36"
                  kendoGridSelectBy="id"
                  scrollable="virtual"
                  [selectedKeys]="seleccionados"
                  (cellClick)="cellClick($event, 1)">
        <!--nombre-->
        <kendo-grid-column [style]="{'text-align': 'left'}" field="nombre" title="{{ 'nombre' | translate}}"></kendo-grid-column>
        <!--tipo-->
        <kendo-grid-column [style]="{'text-align': 'left'}" field="tipo" title="{{ 'tipo' | translate}}"></kendo-grid-column>
        <!--fabricante-->
        <kendo-grid-column [style]="{'text-align': 'left'}" field="fabricante" title="{{ 'fabricante' | translate}}"></kendo-grid-column>
        <!--vida-->
        <kendo-grid-column [style]="{'text-align': 'right'}" field="vidaUtil" title="{{ 'vidaUtil' | translate}}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ngcontainer> {{ secondsToHms(dataItem.vidaUtil) }}</ngcontainer>
          </ng-template>
        </kendo-grid-column>
        <!--diametro-->
        <kendo-grid-column [style]="{'text-align': 'right'}" field="diametro" title="{{ 'diametro' | translate}}"></kendo-grid-column>
        <!--longitud-->
        <kendo-grid-column [style]="{'text-align': 'right'}" field="longitud" title="{{ 'longitud' | translate}}"></kendo-grid-column>
        <!--Angulo-->
        <kendo-grid-column [style]="{'text-align': 'right'}" field="angulo" title="{{ 'angulo' | translate}}"></kendo-grid-column>
        <!--nfiltros-->
        <kendo-grid-column [style]="{'text-align': 'right'}" field="numContenido" title="{{ 'capacidad' | translate}}"></kendo-grid-column>
        <!--salto-->
        <kendo-grid-column [style]="{'text-align': 'right'}" field="salto" title="{{ 'saltos' | translate}}"></kendo-grid-column>
        <!--cantidad-->
        <kendo-grid-column [style]="{'text-align': 'right'}" field="cantidad" title="{{ 'cantidad' | translate}}"></kendo-grid-column>
        <div *kendoGridDetailTemplate="let dataItem">
          <kendo-grid [kendoGridBinding]="dataItem.nSeries"
                      [sortable]="true"
                      [navigable]="true"
                      filterable="menu"
                      [rowHeight]="36"
                      kendoGridSelectBy="id"
                      scrollable="virtual"
                      [selectedKeys]="seleccionados_NSERIE"
                      (cellClick)="cellClick($event, 2)">
            <!--nSerie-->
            <kendo-grid-column field="nSerie" title="nSerie" width="10%"></kendo-grid-column>
            <!--baja-->
            <kendo-grid-column width="10%" [style]="{'text-align': 'left'}" field="baja" title="{{ 'baja' | translate}}">
              <ng-template kendoGridCellTemplate let-dataItem>
                <kendo-switch [checked]="dataItem.baja" [value]="dataItem.baja" [onLabel]="' '" [offLabel]="' '" disabled="false"></kendo-switch>
              </ng-template>
            </kendo-grid-column>
            <!--en-->
            <kendo-grid-column width="20%" [style]="{'text-align': 'left'}" field="en" title="{{ 'ubicacion' | translate}}"></kendo-grid-column>
            <!--fechaIni-->
            <kendo-grid-column width="15%" [style]="{'text-align': 'left'}" field="fechaIni" title="{{ 'fechainicio' | translate}}">
              <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechaIni | kendoDate }} {{dataItem.fechaIni | kendoDate:'HH:mm:ss' }}</ng-template>
            </kendo-grid-column>
            <!--fechaIni-->
            <kendo-grid-column width="15%" [style]="{'text-align': 'left'}" field="fechaFin" title="{{ 'fechafin' | translate}}">
              <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechaFin | kendoDate }} {{dataItem.fechaFin | kendoDate:'HH:mm:ss' }}</ng-template>
            </kendo-grid-column>
            <!--fiabilidad-->
            <kendo-grid-column width="10%" [style]="{'text-align': 'left'}" field="fiabilidad" title="{{ 'fiabilidad' | translate}} %"></kendo-grid-column>
            <!--fiabilidad actual-->
            <kendo-grid-column width="10%" [style]="{'text-align': 'left'}" field="fiabilidadActual" title="{{ 'actual' | translate}} %"></kendo-grid-column>
            <!--vida util-->
            <kendo-grid-column width="10%" [style]="{'text-align': 'left'}" field="vidaUtil" title="{{ 'vidaUtil' | translate}}">
              <ng-template kendoGridCellTemplate let-dataItem>
                <ngcontainer> {{ secondsToHms(dataItem.vidaUtil) }}</ngcontainer>
              </ng-template>
            </kendo-grid-column>
            <!--vida util restante-->
            <kendo-grid-column width="10%" [style]="{'text-align': 'left'}" field="vidaUtil" title="{{ 'restante' | translate}}">
              <ng-template kendoGridCellTemplate let-dataItem>
                <ngcontainer> {{ secondsToHms(dataItem.restante) }}</ngcontainer>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
        </div>
        <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
        filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
        filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
        filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
        filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
        filterContainsOperator="{{'filterContainsOperator' | translate}}"
        filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
        filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
        filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
        filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
        filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
        filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
        filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
        filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
        filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
        filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
        filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
        filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
        filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
        filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
        groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
        noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
      </kendo-grid>
    </div>
  </div>
</div>
