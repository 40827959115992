import { Component } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router"

import { OperacionesPredefinidasService } from '@app/_services/operacionesPredefinidas.service';

import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService, UsuariosService } from '../_services';
import { ViewEncapsulation, ViewChild } from '@angular/core';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { MyFunctions } from '@app/_helpers';

@Component({
  selector: 'app-operacionespredefinidas',
  templateUrl: 'operacionesPredefinidas.component.html'
})

export class OperacionesPredefinidasComponent {

  public seleccionados: number[] = [];
  private translate: TranslateService;
  public operacionesPredefinidas: any = [];
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  closeResult = '';
  navigationSubscription;
  user = this.userService.userValue;

  constructor(private operacionesPredefinidasService: OperacionesPredefinidasService,
    translate: TranslateService,
    private menuService: MenuService, public router: Router,
    private translateService: TranslateService,
    private userService: UsuariosService,
    private modalService: NgbModal,
    public myFunctions: MyFunctions) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('operacionespredefinidas').toUpperCase();

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/operacionespredefinidas') {
          this.menuService.titulo = this.translateService.instant('operacionespredefinidas');
          this.operacionesPredefinidasService.GetAll().subscribe((result) => {
            var dict: any = {};

            if (result.imagenesMaquinas.length > 0) {
              //Tenemos las imagenes, creamos el diccionario
              result.imagenesMaquinas.forEach(element => {
                dict[element.imagen] = element.imagenBASE64;
              });
            }

            result.operaciones.forEach(function (o) {
              //TIEMPOS
              o.tiempoEstimado = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoEstimadoS);
              o.tiempoEstimadoPreparacion = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoEstimadoPreparacionS);
              o.tiempoEstimadoTotal = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoEstimadoTotalS);
              o.tiempoPredictivo = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoPredictivoS);
              o.tiempoPredictivoPreparacion = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoPredictivoPreparacionS);
              o.tiempoPredictivoTotal = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoPredictivoTotalS);
              //FIN TIEMPOS

              if (o.idsMaquinas) {
                var idsMaquinas: any = o.idsMaquinas.split(',');

                var maquinas = [];
                var maquinasImagenes = [];
                var prioridadesSeparadas = o.prioridadesMaquinas.split(',');
                var prioridadesFinal = [];
                idsMaquinas.forEach((idm, index) => {
                  if (idm != "" && idm != "-1" && idm != "0") {
                    prioridadesFinal.push(prioridadesSeparadas[index]);
                    var m = result.nombresMaquinas.find(x => x.id == idm);
                    if (m != undefined) {
                      maquinas.push(m.nombre);
                      maquinasImagenes.push(m.urlImagen);
                    }
                  }
                });
                var auxDictPrioridades = {}
                prioridadesFinal.forEach((prioridad, index) => {
                  auxDictPrioridades[maquinas[index]] = prioridad;
                });
                o.prioridadMaquinasAux = auxDictPrioridades;
                o.maquina = maquinas.join(', ');
                //Ahora seguimos con las maquinas
                var maquinasAuxi = [];
                var maquinasAuxi2 = [];
                maquinas.sort((a, b) =>
                  (Number(auxDictPrioridades[a]) > Number(auxDictPrioridades[b])) ? -1 :
                    ((Number(auxDictPrioridades[b]) > Number(auxDictPrioridades[a])) ? 1 : 0)
                );
                maquinas.forEach(maquina => {
                  if (maquina == this.translate.instant("desconocido")) {
                    maquinasAuxi2.push("undefined");
                    maquinasAuxi.push("undefined");
                  } else {
                    var nombre = maquina.trim().substring(0, 1).toUpperCase();
                    var apellido = maquina.trim().substring(1, 2).toUpperCase();
                    maquinasAuxi2.push(maquina);
                    maquinasAuxi.push(nombre + apellido);
                  }
                });
                o.maquinasAuxi2 = maquinasAuxi2.join(";");
                o.maquinasAuxi = maquinasAuxi.join(",");
                //Ahora hay que corregir las imagenes de las maquinas
                var imagenes = maquinasImagenes;
                var auxiImagenes = "";
                imagenes.forEach(imagen => {
                  auxiImagenes += dict[imagen] + ";and;";
                });
                o.maquinasIm = auxiImagenes;
              }
              else {
                o.maquina = null;
              }
            }, this);
            this.operacionesPredefinidas = result.operaciones;
          }
          );
        } else {

        }
      }
    });
  }

  ngOnInit() {
    this.cargarOperaciones();
  }

  cargarOperaciones(){
    this.operacionesPredefinidasService.GetAll().subscribe((result) => {
      var dict: any = {};

      if (result.imagenesMaquinas.length > 0) {
        //Tenemos las imagenes, creamos el diccionario
        result.imagenesMaquinas.forEach(element => {
          dict[element.imagen] = element.imagenBASE64;
        });
      }

      result.operaciones.forEach(function (o) {
        //TIEMPOS
        o.tiempoEstimado = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoEstimadoS);
        o.tiempoEstimadoPreparacion = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoEstimadoPreparacionS);
        o.tiempoEstimadoTotal = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoEstimadoTotalS);
        o.tiempoPredictivo = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoPredictivoS);
        o.tiempoPredictivoPreparacion = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoPredictivoPreparacionS);
        o.tiempoPredictivoTotal = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoPredictivoTotalS);
        //FIN TIEMPOS

        if (o.idsMaquinas) {
          var idsMaquinas: any = o.idsMaquinas.split(',');
          
          var maquinas = [];
          var maquinasImagenes = [];
          var prioridadesSeparadas =  o.prioridadesMaquinas.split(',');
          var prioridadesFinal = [];
          idsMaquinas.forEach((idm, index) => {
            if (idm != "" && idm != "-1" && idm != "0") {
              prioridadesFinal.push(prioridadesSeparadas[index]);
              var m = result.nombresMaquinas.find(x => x.id == idm);
              if (m != undefined){
                maquinas.push(m.nombre);
                maquinasImagenes.push(m.urlImagen);
              } 
            }
          });
          var auxDictPrioridades = {}
          prioridadesFinal.forEach((prioridad, index) => {
            auxDictPrioridades[maquinas[index]] = prioridad;
          });
          o.prioridadMaquinasAux = auxDictPrioridades;
          o.maquina = maquinas.join(', ');
           //Ahora seguimos con las maquinas
          var maquinasAuxi = [];
          var maquinasAuxi2 = [];
          maquinas.sort((a, b) => 
            (Number(auxDictPrioridades[a]) >Number(auxDictPrioridades[b])) ? -1 : 
            ((Number(auxDictPrioridades[b]) > Number(auxDictPrioridades[a])) ? 1 : 0)
          );
          maquinas.forEach(maquina => {
            if (maquina == this.translate.instant("desconocido")) {
              maquinasAuxi2.push("undefined");
              maquinasAuxi.push("undefined");
            } else {
              var nombre = maquina.trim().substring(0, 1).toUpperCase();
              var apellido = maquina.trim().substring(1, 2).toUpperCase();
              maquinasAuxi2.push(maquina);
              maquinasAuxi.push(nombre + apellido);
            }
          });
          o.maquinasAuxi2 = maquinasAuxi2.join(";");
          o.maquinasAuxi = maquinasAuxi.join(",");
          //Ahora hay que corregir las imagenes de las maquinas
          var imagenes = maquinasImagenes;
          var auxiImagenes = "";
          imagenes.forEach(imagen => {
            auxiImagenes += dict[imagen] + ";and;";
          });
          o.maquinasIm = auxiImagenes;
        }
        else {
          o.maquina = null;
        }
      }, this);
      this.operacionesPredefinidas = result.operaciones;
    }
    );
  }

  onClickNuevo() {
    this.router.navigate(['operacionespredefinidas/crear/']);
  }

  onClickEditar() {
    if (this.seleccionados[0] > 0) {
      this.router.navigate(['operacionespredefinidas/editar/', this.seleccionados[0]]);
    }
  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['operacionespredefinidas/editar/', this.seleccionados[0]]);
    }
  }

  onClickEliminar(content) {
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  private eliminarRegistro(contentloading, contentNotErased) {

    this.operacionesPredefinidasService.delete({ ids: this.seleccionados }).subscribe(
      (data) => {
        if (data.error == false) {
          this.cargarOperaciones();
        }
        this.modalReferenceloading.close();
      }
    );
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.seleccionados = [];
  }
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;


  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN') &&
      (element.offsetWidth < element.scrollWidth)
      && !element.classList.contains('celda-tooltip-externo') && !element.classList.contains('tooltiptext')) {
      //Si tiene estas clases utiliza el otro tooltip, por lo que no debe mostrar este
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }
}
