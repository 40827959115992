import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CentroMecanizadoService, MenuService, UsuariosService, HmiService, MaquinasService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from "@angular/router";
import { MyFunctions } from '@app/_helpers';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';

import * as moment from 'moment';

import * as d3 from 'd3';
import * as c3 from 'c3';

@Component({ templateUrl: 'hmiHornosTemple.html' })

export class HMIHornosTempleComponent implements OnInit {

  start: boolean = true;

  interval: any;
  interval2: any;
  interval3: any;

  idmaquina: any;
  maquina: any;
  maquinasModel: any;
  marcasModel: any;

  //CSS pestaña
  pestannaColor: any = "";

  //PANEL MAQUINA
  operario: any = "";
  procesosTipo: any = "-";
  procesosTipoMinuscSinAcentos: any = "-";
  fechaServidor: any;
  fechaIni: any;
  porcentaje: any = 0;
  estadoImg: any = "";
  tiempo: any = 0;
  tiempoString: string = "";
  tiempoEst: any = 0;
  tiempoPred: any = 0;
  proceso: any = "";
  tipoAditivo: number = 0; //1: FDM, 2: LPFB, 3: LMD, 4: HP

  //TEMPERATURAS
  temperaturaActual: any = 0;
  temperaturaProgramada: any = 0;
  temperaturaInterior: any = 0;
  consumo: any = 0;
  nivelO2: any = 0;

  //PROCESOS MAQUINA
  procesosMaquina: any = [];
  public procesosSeleccionados: any = [];

  //LABELS OF
  of: any = "";
  cliente: any = "";
  pieza: any = "";
  parte: any = "";
  operacion: any = "";
  nPieza: any = "";
  material: any = "";
  cantidad: any = "";
  contador: any = "";
  tiempoAcumulado: number = 0;
  tiempoAcumuladoString: string = "";
  desviacionTotal: string = "";

  //IMAGENES
  imgCapaTermica: any = "";
  imgTeoricaReal: any = "";
  imgEscenaTeorica: any = "";

  //PROGRESO
  alturaActual: any = "";
  alturaImpresion: any = "";
  capaActual: any = "";
  tiempoTotal: any = 0;
  tiempoActual: any = 0;
  tiempoRestante: any = 0;
  fin: any = null;

  //MATERIAL
  materialActual: number = 0;
  materialNecesario: number = 0;
  materialConsumido: number = 0;
  contenedor: number = 0;

  //GRAFICOS
  graficoProgreso: any;
  graficoMaterial: any;
  graficoResumenSemana: any;

  //GRAFICO MULTIEJE
  public grafico1: any;
  public grafico2: any;
  public grafico3: any;
  public grafico4: any;
  public grafico5: any;
  public grafico6: any;
  public grafico7: any;
  public grafico8: any;
  public grafico9: any;
  public grafico10: any;
  public mostrarGraficosPorLeyenda: any = [true, true, true, true, false, false, false, false, false, false]; //10 grafiko sartu nahi ditugu
  public arrayFechas: any = ["-"];
  public fechaInicioZoom: any;
  public fechaFinZoom: any;
  public fechaIniRecetaSeleccionada: any;
  public fechaFinRecetaSeleccionada: any;
  public colores = [
    "#3366CC",
    "#CB3737",
    "#32A814",
    "#B4E50A",
    "#B962C5",
    "#FFC260",
    "#FF69A3",
    "#6DFACD",
    "#C09927",
    "#A8AABC",
  ];
  public datosGraficoMultieje: any = {};
  public listaTooltip: any = [];
  public listaTooltipSeleccionados: any = [];
  public primeraVez: boolean = true;//Cargamos solo una vez los graficos

  //FECHAS
  public fechaInicio: Date;
  public fechaFin: Date;

  //PESTANAS
  loadingPestannas: boolean = true;

  user = this.usuariosService.userValue;

  historicoProcesos: any;
  alarmas: any;
  planificados: any;

  mantenimientoPorFecha: any;
  mantenimientoPorTiempo: any;
  loadingPorFecha: any;
  loadingPorTiempo: any;

  notas: any;

  // CALCULO TIEMPOS NUEVO
  public r1: boolean = false;
  public unicaEjecucionTiempos: boolean = true;
  public tiempoLoteSegundos: number = -1;
  public loadingDatosTiempo: boolean = false;
  public timeOutTiempos: any;
  public ejecucion: any;

  public tiempoPreparacionEst: number;
  public estadoImgPrep: string;
  public porcentajePrep: string;
  public tiempoPreparacionSegundos: number;
  public tiempoPreparacionString: string;
  public procesoTipo: number;
  public tiempoPreparacionEstST: string;
  public desviacionPrep: string;


  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  constructor(private usuariosService: UsuariosService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private centroMecanizadoService: CentroMecanizadoService,
    private hmiService: HmiService,
    private maquinasService: MaquinasService,
    public translateService: TranslateService,
    public myFunctions: MyFunctions) {

    this.interval = setInterval(() => {
      this.refreshData();
    }, 30000); //60000 MINUTURO, 5000 zegoen.

    this.interval2 = setInterval(() => {
      this.refreshDataTiempos();
    }, 35000);

    this.interval3 = setInterval(() => {
      this.refreshDataPestanas();
    }, 30000);

  }

  ngOnInit(): void {
    this.menuService.titulo = this.translateService.instant('horno');
    this.idmaquina = parseInt(this.route.snapshot.paramMap.get("id"));
    this.maquina = {
      nombre: "-",
      imagenBase64: "",
      marca: { imagenBase64: "" },
      tiempoReal: [],
    };
    this.calcularTiempos();
    this.start = true;
    this.cargarMaquinasModel();
  }

  cargarMaquinasModel() {
    var r1, r2: boolean = false;

    //MAQUINAS
    var maquinas_model = this.maquinasService.get_maquinas_model();
    if (maquinas_model == false) {
      this.maquinasService.get().subscribe(json => {
        this.maquinasService.set_maquinas_model(json);
        this.maquinasModel = this.maquinasService.get_maquinas_model();
        r1 = true;
        if (r1 && r2) {
          this.cargarMaquina();
        }
      })
    } else {
      this.maquinasModel = maquinas_model;
      r1 = true;
      if (r1 && r2) {
        this.cargarMaquina();
      }
    }

    //MARCAS
    var marcas_model = this.maquinasService.get_marcas_model();
    if (marcas_model == false) {
      this.maquinasService.getMarcas().subscribe(json => {
        this.maquinasService.set_marcas_model(json);
        this.marcasModel = this.maquinasService.get_marcas_model();
        r2 = true;
        if (r1 && r2) {
          this.cargarMaquina();
        }
      })
    } else {
      this.marcasModel = marcas_model;
      r2 = true;
      if (r1 && r2) {
        this.cargarMaquina();
      }
    }
  }

  cargarMaquina() {

    //COGEMOS LA MAQUINA
    this.maquina = this.maquinasModel.find(x => x.id === this.idmaquina);
    this.maquina.marca = this.marcasModel.find(x => x.id === this.maquina.idmarca);

    this.r1 = true;
    //PINTAMOS LOS GRAFICOS
    this.pintarGraficos();

  }

  pintarGraficos() {
    this.pintarGraficoResumenSemana();
    setTimeout(function () {
      this.pintarGraficoMultiEje();
      //DESPUES DE PINTAR LOS GRAFICOS CARGAMOS TODOS LOS DATOS
      this.cargarTodosLosDatos();
    }.bind(this), 50);
  }



  redondear2decimales(zki: number): number {
    var m = Number((Math.abs(zki) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(zki);
  }

  pintarGraficoResumenSemana() {

    this.graficoResumenSemana = c3.generate({
      bindto: '#resumenSemanaGrafico_hmiAdi',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      data: {
        columns: [
          [this.translateService.instant("ejecucion"), 0],
          [this.translateService.instant("parada"), 0],
          [this.translateService.instant("microparada"), 0],
          [this.translateService.instant("preparacion"), 0],
          [this.translateService.instant("mantenimiento"), 0],
          [this.translateService.instant("alarma"), 0],
          [this.translateService.instant("apagada"), 0]
        ],
        type: 'bar',
        order: null
      },
      transition: {
        duration: 500
      },
      color: {
        pattern: ['#c0eada', '#e7cb68', '#cf8729', '#096844', '#99afc6', '#d33737', '#424242']
      },
      axis: {
        y: {
          show: false
        },
        x: {
          show: false
        }
      },
      bar: {
        width: {
          ratio: 1
        },
        space: 0.1
      },
      tooltip: {
        format: {
          title: function (d) { return 'Resumen semana' },
          value: function (value, ratio, id) {
            return value + '%';
          }
        }
      }
    });

  }

  pintarGraficoMultiEje() {

    var th = this;

    var i;
    for (i = 1; i <= 4; i++) {

      var chart = c3.generate({
        bindto: "#grafico" + i + "_hmiAdi",
        size: {
          height: 400
        },
        padding: {
          left: 124,
          right: 15,
          bottom: 15
        },
        data: {
          x: 'x',
          columns: [
            ['x'],
            ['valor']
          ],
          type: 'area',
          colors: {
            valor: th.colores[i - 1],
          },
          order: null
        },
        regions: [],
        transition: {
          duration: 0
        },
        axis: {
          x: {
            type: 'timeseries',
            tick: {
              values: function (x) {
                var dif = (x[1].getTime() - x[0].getTime()) / 1000;

                var fechaIni = x[0];
                fechaIni.setMinutes(fechaIni.getMinutes() - 10);
                var fechaFin = x[1];
                fechaFin.setMinutes(fechaFin.getMinutes() + 10);

                if (dif < 180)
                  return d3.scaleTime().domain([fechaIni, fechaFin]).ticks(120);
                else if (dif < 1800)
                  return d3.scaleTime().domain([fechaIni, fechaFin]).ticks(15);
                else
                  return d3.scaleTime().domain([fechaIni, fechaFin]).ticks(10);
              },
              //format: '%m/%d - %H:%M:%S'
              format: function (x) {
                if (x.getHours() == 0 && x.getMinutes() == 0 && x.getSeconds() == 0) {
                  if (x.getDay() == 0) return th.translateService.instant("domingo");
                  if (x.getDay() == 1) return th.translateService.instant("lunes");
                  if (x.getDay() == 2) return th.translateService.instant("martes");
                  if (x.getDay() == 3) return th.translateService.instant("miercoles");
                  if (x.getDay() == 4) return th.translateService.instant("jueves");
                  if (x.getDay() == 5) return th.translateService.instant("viernes");
                  if (x.getDay() == 6) return th.translateService.instant("sabado");
                } else {
                  return th.myFunctions.dateToHourString(x);
                }
              },
            },
            padding: 0
          }
        },
        legend: {
          show: false
        },
        point: {
          show: false
        },
        tooltip: {
          contents: function (d, defaultTitleFormat, defaultValueFormat, color) {

            var fecha = d[0].x;

            if (fecha >= th.fechaInicioZoom && fecha <= th.fechaFinZoom) {

              var $$ = this, config = $$.config,
                titleFormat = config.tooltip_format_title || defaultTitleFormat,
                nameFormat = config.tooltip_format_name || function (name) { return name; },
                valueFormat = config.tooltip_format_value || defaultValueFormat,
                text, i, title, value, name, bgcolor;
              for (i = 0; i < d.length; i++) {
                if (!(d[i] && (d[i].value || d[i].value === 0))) { continue; }

                if (!text) {
                  title = titleFormat ? titleFormat(d[i].x) : d[i].x;
                  text = "<table class='" + $$.CLASS.tooltip + "'>" + (title || title === 0 ? "<tr><th colspan='2'>" + title + "</th></tr>" : "");
                }

              }

              var indice = th.arrayFechas.findIndex(x => x == d[0].x);
              var camposSeleccionados = [];

              th.listaTooltip.forEach(function (campo) {
                if (campo.seleccionadoLeyenda) camposSeleccionados.push(campo);
              }, this);

              camposSeleccionados.forEach(function (campo) {

                if (th.mostrarGraficosPorLeyenda[campo.estaEnGrafico - 1] && campo.valoresMomentaneo[indice] != undefined) {
                  name = th.translateService.instant(campo.nombre);

                  if (typeof campo.valoresMomentaneo[indice] == 'boolean')
                    if (campo.valoresMomentaneo[indice]) value = "True " + campo.unidad;
                    else value = "False " + campo.unidad;
                  else
                    value = campo.valoresMomentaneo[indice] + " " + campo.unidad;

                  bgcolor = campo.color;
                  text += "<tr class='" + $$.CLASS.tooltipName + "-" + d[0].id + "'>";
                  text += "<td class='name'><span style='background-color:" + bgcolor + "'></span>" + name + "</td>";
                  text += "<td class='value'>" + value + "</td>";
                  text += "</tr>";
                }
              }, this);

              return text + "</table>";

            }

          }
        },
        zoom: {
          enabled: (i == 1),
          type: 'drag',
          disableDefaultBehavior: true,
          onzoomend: function (domain) {
            th.fechaInicioZoom = domain[0];
            th.fechaFinZoom = domain[1];
            th.aplicarZoomEnGrafico();
          }
        }
      });

      if (i == 1) this.grafico1 = chart;
      if (i == 2) this.grafico2 = chart;
      if (i == 3) this.grafico3 = chart;
      if (i == 4) this.grafico4 = chart;
      if (i == 5) this.grafico5 = chart;
      // if (i == 6) this.grafico6 = chart;
      // if (i == 7) this.grafico7 = chart;
      // if (i == 8) this.grafico8 = chart;
      // if (i == 9) this.grafico9 = chart;
      // if (i == 10) this.grafico10 = chart;

    }

    //SUSTITUIR LA CAPA DEL ZOOM Y LA DEL TOOLTIP Y AÑADIR QUE AL HACER CLICK CON EL BOTON IZQUIERDO SIMULE UN CLICK CON EL BOTON DERECHO PARA PODER HACER EL ZOOM
    let list_of_children = d3.select('#grafico1_hmiAdi svg').node().childNodes;
    list_of_children[2].parentNode.insertBefore(list_of_children[2], list_of_children[1]);

    d3.select('#grafico1_hmiAdi .c3-event-rect')._groups[0][0].onmousedown = function (e) {
      if (e.button != 0) {
        var evt = new MouseEvent("mousedown", {
          altKey: e.altKey,
          bubbles: e.bubbles,
          button: 0,
          buttons: e.buttons,
          cancelable: e.cancelable,
          clientX: e.clientX,
          clientY: e.clientY,
          composed: e.composed,
          ctrlKey: e.ctrlKey,
          detail: e.detail,
          metaKey: e.metaKey,
          movementX: e.movementX,
          movementY: e.movementY,
          relatedTarget: e.relatedTarget,
          screenX: e.screenX,
          screenY: e.screenY,
          shiftKey: e.shiftKey,
          view: e.view
        });
        d3.select("#grafico1_hmiAdi .c3-drag-zoom .overlay")._groups[0][0].dispatchEvent(evt);
      }
    }
    d3.select('#grafico2_hmiAdi .c3-event-rect')._groups[0][0].onmousedown = d3.select('#grafico1_hmiAdi .c3-event-rect')._groups[0][0].onmousedown;
    d3.select('#grafico3_hmiAdi .c3-event-rect')._groups[0][0].onmousedown = d3.select('#grafico1_hmiAdi .c3-event-rect')._groups[0][0].onmousedown;
    d3.select('#grafico4_hmiAdi .c3-event-rect')._groups[0][0].onmousedown = d3.select('#grafico1_hmiAdi .c3-event-rect')._groups[0][0].onmousedown;
    //d3.select('#grafico5_hmiAdi .c3-event-rect')._groups[0][0].onmousedown = d3.select('#grafico1_hmiAdi .c3-event-rect')._groups[0][0].onmousedown;

  }

  cargarTodosLosDatos() {

    this.centroMecanizadoService.GetHmiHornoTempleById(this.idmaquina).subscribe((result) => {

      var datos: any = result;

      var ejecucionActual = datos.ejecuciones;
      this.ejecucion = ejecucionActual[0];

      this.procesosMaquina = result.procesosMaquina

      var kont = 1;

      this.procesosMaquina.forEach(function (proceso) {
        proceso.id = kont;
        kont++
      });


      this.temperaturaProgramada = ejecucionActual[0].temperaturaProgramada;
      this.temperaturaActual = ejecucionActual[0].temperaturaActual;
      this.temperaturaInterior = ejecucionActual[0].temperaturaInterior;
      this.consumo = ejecucionActual[0].consumo;
      this.nivelO2 = ejecucionActual[0].nivelO2;

      if (ejecucionActual.length > 0) {

        //pestannaColor
        if (ejecucionActual[0].idProcesos_Tipo == 1) this.pestannaColor = "pestañaprocesos colorejecucion";
        else if (ejecucionActual[0].idProcesos_Tipo == 2) this.pestannaColor = "pestañaprocesos colorparada";
        else if (ejecucionActual[0].idProcesos_Tipo == 3) this.pestannaColor = "pestañaprocesos colorpreparacion";
        else if (ejecucionActual[0].idProcesos_Tipo == 4) this.pestannaColor = "pestañaprocesos colormantenimiento";
        else if (ejecucionActual[0].idProcesos_Tipo == 6) this.pestannaColor = "pestañaprocesos coloralarma";
        else if (ejecucionActual[0].idProcesos_Tipo == 8) this.pestannaColor = "pestañaprocesos colorapagada";
        else this.pestannaColor = "pestañaprocesos colorejecucion";
        this.procesoTipo = ejecucionActual[0].idProcesos_Tipo;

        //PANEL MAQUINA
        if (ejecucionActual[0].operario == " ") this.operario = this.translateService.instant('sinOperario');
        else this.operario = ejecucionActual[0].operario;

        this.fechaServidor = new Date(ejecucionActual[0].fechaServidor);
        this.fechaIni = new Date(ejecucionActual[0].fechainicio);
        //this.calcularTiempos();
        this.tiempoEst = ejecucionActual[0].tiempoEstimadoSegundos;
        this.tiempoPred = ejecucionActual[0].tiempoPredSegundos;
        this.proceso = ejecucionActual[0].operacion;
        this.tiempoPreparacionEst = ejecucionActual[0].tiempoPreparacionSegundos;
        this.tiempoPreparacionEstST = this.corregirFormato(ejecucionActual[0].tiempoPreparacionSegundos);
        //this.tipoAditivo = ejecucionActual[0].tipoAditivo; //1: FDM, 2: LPFB, 3: LMD, 4: HP

        if (ejecucionActual[0].idProcesos_Tipo == 1) this.procesosTipo = this.translateService.instant("ejecucion").toUpperCase();
        else if (ejecucionActual[0].idProcesos_Tipo == 2) this.procesosTipo = this.translateService.instant("parada").toUpperCase();
        else if (ejecucionActual[0].idProcesos_Tipo == 3) this.procesosTipo = this.translateService.instant("preparacion").toUpperCase();
        else if (ejecucionActual[0].idProcesos_Tipo == 4) this.procesosTipo = this.translateService.instant("mantenimiento").toUpperCase();
        else if (ejecucionActual[0].idProcesos_Tipo == 6) this.procesosTipo = this.translateService.instant("alarma").toUpperCase();
        else if (ejecucionActual[0].idProcesos_Tipo == 8) this.procesosTipo = this.translateService.instant("apagada").toUpperCase();
        else this.procesosTipo = this.translateService.instant("ejecucion").toUpperCase();

        this.procesosTipoMinuscSinAcentos = this.quitarAcentos(this.procesosTipo.toLowerCase());

        //GRAFICO EJECUCION
        this.CargarIconosEstadoMaquina()

        //DATOS PANEL
        this.of = ejecucionActual[0].refOF;
        this.cliente = ejecucionActual[0].cliente;
        this.pieza = ejecucionActual[0].pieza;
        this.parte = ejecucionActual[0].parte;
        this.operacion = ejecucionActual[0].operacion;
        this.nPieza = ejecucionActual[0].hechas + "/" + ejecucionActual[0].cantidad;
        this.material = ejecucionActual[0].material;
        this.cantidad = ejecucionActual[0].cantidad;
        this.contador = ejecucionActual[0].contador;
        this.tiempoAcumulado = ejecucionActual[0].tiempoAcumuladoOperacion;



        if (this.start) {
          this.start = false;
          this.calcularTiempos2();
        }



        //IMAGENES
        this.imgCapaTermica = "../../assets/Additivo_capa-termica.jpg";/////////////////////////////////////
        this.imgTeoricaReal = "../../assets/Additivo_Teorica-Real.jpg";////////////////////////////////
        this.imgEscenaTeorica = "../../assets/aditivo_Escena-teorica.jpg";//////////////////////////////////


      }

      //RESUMEN SEMANA
      var tiempoPorSemana = datos.tiempoPorSemana;

      if (tiempoPorSemana.length == 0) {
        tiempoPorSemana = [{ porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }];
      }

      var tiempoPorSemanaData = [[this.translateService.instant("ejecucion"), tiempoPorSemana[0].porcentaje],
      [this.translateService.instant("parada"), tiempoPorSemana[1].porcentaje],
      [this.translateService.instant("microparada"), tiempoPorSemana[2].porcentaje],
      [this.translateService.instant("preparacion"), tiempoPorSemana[3].porcentaje],
      [this.translateService.instant("mantenimiento"), tiempoPorSemana[4].porcentaje],
      [this.translateService.instant("alarma"), tiempoPorSemana[5].porcentaje],
      [this.translateService.instant("apagada"), tiempoPorSemana[6].porcentaje]];

      this.graficoResumenSemana.load({ columns: tiempoPorSemanaData });

      //GRAFICO MULTIEJE
      if (this.primeraVez) {

        this.datosGraficoMultieje = {
          fechas: [],
          temperaturaActual: [],
          temperaturaProgramada: [],
          consumo: [],
          nivelO2: [],
          temperaturaInterior: [],
        }

        var numDatos = datos.datosHistorico.length;
        datos.datosHistorico.reverse().forEach(function (row) {
          row.fecha_date = this.myFunctions.sqlToJsDate(row.fecha);
          row.fecha_time = this.myFunctions.sqlToJsDate(row.fecha).getTime();

          this.datosGraficoMultieje.fechas.push(row.fecha_date);

          this.datosGraficoMultieje.temperaturaActual.push(row.temperaturaActual);
          this.datosGraficoMultieje.temperaturaProgramada.push(row.temperaturaProgramada);
          this.datosGraficoMultieje.consumo.push(row.consumo);
          this.datosGraficoMultieje.nivelO2.push(row.nivelO2);
          this.datosGraficoMultieje.temperaturaInterior.push(row.temperaturaInterior);

        }, this);

        this.listaTooltip = [
          { estaEnGrafico: 1, nombre: "temperaturaProgramada", nombreTraducido: this.translateService.instant("temperaturaProgramada"), tipoDato: "num", seleccionadoLeyenda: true, color: this.colores[0], unidad: "", valoresMomentaneo: [], dim: "ºC", actual: this.temperaturaProgramada },
          { estaEnGrafico: 2, nombre: "temperaturaActual", nombreTraducido: this.translateService.instant("temperaturaActual"), tipoDato: "num", seleccionadoLeyenda: true, color: this.colores[1], unidad: "", valoresMomentaneo: [], dim: "ºC", actual: this.temperaturaActual },
          { estaEnGrafico: -1, nombre: "consumo", nombreTraducido: this.translateService.instant("consumo"), tipoDato: "num", seleccionadoLeyenda: true, color: this.colores[2], unidad: "", valoresMomentaneo: [], dim: "Hz", actual: this.consumo },
          { estaEnGrafico: -1, nombre: "nivelO2", nombreTraducido: this.translateService.instant("lpfb_nivelDeO2"), tipoDato: "num", seleccionadoLeyenda: true, color: this.colores[3], unidad: "", valoresMomentaneo: [], dim: "-", actual: this.nivelO2 },
          { estaEnGrafico: -1, nombre: "temperaturaInterior", nombreTraducido: this.translateService.instant("temperaturaInterior"), tipoDato: "num", seleccionadoLeyenda: true, color: this.colores[4], unidad: "", valoresMomentaneo: [], dim: "ºC", actual: this.temperaturaInterior },
        ];




        this.listaTooltipSeleccionados = this.listaTooltip.filter(x => x.estaEnGrafico != -1).map(x => x.nombre);

        this.mostrarGraficosPorLeyenda = [true, true, true, true, true];

        this.fechaInicio = this.datosGraficoMultieje.fechas[0];
        this.fechaFin = this.datosGraficoMultieje.fechas[this.datosGraficoMultieje.fechas.length - 1];
        this.fechaInicioZoom = this.fechaInicio;
        this.fechaFinZoom = this.fechaFin;

        this.cargarGraficosTodos();

        this.loadingPestannas = false;

        this.primeraVez = false;

      }

    });

  }

  quitarAcentos(katea) {
    return katea.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }


  CargarIconosEstadoMaquina() {

    var tipoProceso = this.quitarAcentos(this.procesosTipo.toLowerCase()); //"apagada", "parada", "ejecucion", "preparacion" o "mantenimiento" o "alarma"

    if (this.tiempoEst == 0) {
      this.estadoImg = "assets/dcg/dcg12-" + tipoProceso + ".png";
      this.estadoImgPrep = "assets/dcg/dcg12-preparacion.png";
      this.porcentaje = "";
      this.porcentajePrep = "";
    } else {
      var ahora = new Date(Date.parse(this.fechaServidor));//this.myFunctions.getDateNow();
      var fechaOrig = new Date(Date.parse(this.fechaIni));

      var dif = ahora.getTime() - fechaOrig.getTime();

      var Seconds_from_T1_to_T2 = dif / 1000;
      Seconds_from_T1_to_T2 = Seconds_from_T1_to_T2;
      var segundosfiff = Math.floor(Seconds_from_T1_to_T2);

      var docentaje = this.calcularPorcentaje(segundosfiff, 12, this.tiempoEst, 1, true);
      var porcentaje = this.calcularPorcentaje(segundosfiff, 100, this.tiempoEst, 1, true);
      this.porcentaje = porcentaje + "%";
      this.estadoImg = "assets/dcg/dcg" + docentaje + "-" + tipoProceso + ".png";

      var diffPreparacion = Math.floor(this.tiempoPreparacionEst - this.tiempoPreparacionSegundos);
      var docentajePrep = this.calcularPorcentaje(diffPreparacion, 12, this.tiempoPreparacionEst, 1, true);
      var porcentajePrep = this.calcularPorcentaje(diffPreparacion, 100, this.tiempoPreparacionEst, 1, true);
      this.porcentajePrep = porcentajePrep + "%";
      this.estadoImgPrep = "assets/dcg/dcg" + docentajePrep + "-preparacion.png";

    }

  }

  // calcularTiempos() {
  //   if (this.fechaIni != null && this.fechaIni != undefined) {
  //     var ahora: any = this.myFunctions.getDateNow();
  //     this.tiempo = (ahora - this.fechaIni) / 1000;
  //   }
  // }

  calcularTiempos2() {
    if (this.fechaIni != null && this.fechaIni != undefined) {
      var ahora: any = this.myFunctions.getDateNow();
      this.tiempo = (Date.parse(this.ejecucion.fechaServidor) - Date.parse(this.ejecucion.fechainicio)) / 1000;
      this.tiempoString = this.corregirFormato(this.tiempo);
    }
    if (this.tiempoLoteSegundos != -1) {
      this.tiempoLoteSegundos = this.tiempoLoteSegundos + 1;
      this.tiempoAcumuladoString = this.corregirFormato(this.tiempoLoteSegundos);
      this.tiempoPreparacionSegundos = this.tiempoPreparacionSegundos + 1;
      this.tiempoPreparacionString = this.corregirFormato(this.tiempoPreparacionSegundos);
    } else {
      this.tiempoAcumuladoString = '--:--:--'
    }
    this.calcularDesviacion();
    setTimeout((d) => this.calcularTiempos2(), 1000);
  }


  calcularTiempos() {

    if (this.r1) {
      if (this.unicaEjecucionTiempos) {
        this.unicaEjecucionTiempos = false;
        this.centroMecanizadoService.get_Tiempos_Lote_Pieza_byId(this.idmaquina).subscribe(result => {
          var datosTiempo: any = result;
          var tieneTiempo: boolean = false;

          if (datosTiempo[0].tiempoLote != undefined && datosTiempo[0].tiempoUltimaPieza != undefined) {
            this.tiempoLoteSegundos = datosTiempo[0].tiempoLote;
            this.tiempoPreparacionSegundos = datosTiempo[0].tiempoLotePrep;
          } else {
            this.tiempoLoteSegundos = -1;
            this.tiempoPreparacionSegundos = -1;
          }
          // this.loadingDatosTiempo = false;
          setTimeout((d) => this.loadingDatosTiempo = false, 1000);
          this.unicaEjecucionTiempos = true;

          this.timeOutTiempos = setTimeout((d) => this.calcularTiempos(), 60000);
        });
      }

    } else {
      setTimeout((d) => this.calcularTiempos(), 1000);
    }

  }


  refreshData() {
    if (this.router.url == '/procesoshornotemple/' + this.idmaquina) {
      this.cargarTodosLosDatos();
    } else {
      clearInterval(this.interval);
    }
  }

  refreshDataTiempos() {
    if (this.router.url == '/procesoshornotemple/' + this.idmaquina) {
      //this.calcularTiempos();
    } else {
      clearInterval(this.interval2);
    }
  }

  refreshDataPestanas() {

    if (this.router.url == '/procesoshornotemple/' + this.idmaquina) {

      this.cargarDatosProcesos();
      this.cargarDatosMantenimientos();
      this.cargarDatosNotas();

    } else {
      clearInterval(this.interval3);
    }

  }

  RedirigirRendimiento() {
    this.router.navigate(["informerendimiento/" + this.idmaquina]);
  }

  //GRAFICOS
  aplicarZoomEnGrafico() {
    this.cargarGraficosTodos();
  }

  onClickMostrarTodos() {
    this.fechaInicioZoom = this.fechaInicio;
    this.fechaFinZoom = this.fechaFin;
    this.cargarGraficosTodos();
  }

  clickHMI(event) {

    //TODOOOOOOOO
    var regionsSelected = [];
    var th = this;

    event.selectedRows.forEach(function (numSelec) {
      var newRegion = {
        start: new Date(numSelec.dataItem.fechaIni),
        end: new Date(numSelec.dataItem.fechaFin),
        class: 'regionTiempoPresionRemanente'
      }
      regionsSelected.push(newRegion);
    });
    var chart = d3.select("#grafico1_hmiAdi");

    // GRAFICO
    this.centroMecanizadoService.GetHmiHornoTempleByIdAndDate(this.idmaquina, event.selectedRows[0].dataItem.fechaIni, event.selectedRows[0].dataItem.fechaFin).subscribe((result) => {
      var datos: any = result;

      this.datosGraficoMultieje = {
        fechas: [],
        temperaturaActual: [],
        temperaturaProgramada: [],
        consumo: [],
        nivelO2: [],
        temperaturaInterior: [],
      }

      var numDatos = datos.length;
      datos.reverse().forEach(function (row) {
        row.fecha_date = this.myFunctions.sqlToJsDate(row.fecha);
        row.fecha_time = this.myFunctions.sqlToJsDate(row.fecha).getTime();

        this.datosGraficoMultieje.fechas.push(row.fecha_date);

        this.datosGraficoMultieje.temperaturaActual.push(row.temperaturaActual);
        this.datosGraficoMultieje.temperaturaProgramada.push(row.temperaturaProgramada);
        this.datosGraficoMultieje.consumo.push(row.consumo);
        this.datosGraficoMultieje.nivelO2.push(row.nivelO2);
        this.datosGraficoMultieje.temperaturaInterior.push(row.temperaturaInterior);

      }, this);

      this.listaTooltip = [
        { estaEnGrafico: 1, nombre: "temperaturaProgramada", nombreTraducido: this.translateService.instant("temperaturaProgramada"), tipoDato: "num", seleccionadoLeyenda: true, color: this.colores[0], unidad: "", valoresMomentaneo: [], dim: "ºC", actual: this.temperaturaProgramada },
        { estaEnGrafico: 2, nombre: "temperaturaActual", nombreTraducido: this.translateService.instant("temperaturaActual"), tipoDato: "num", seleccionadoLeyenda: true, color: this.colores[1], unidad: "", valoresMomentaneo: [], dim: "ºC", actual: this.temperaturaActual },
        { estaEnGrafico: -1, nombre: "consumo", nombreTraducido: this.translateService.instant("consumo"), tipoDato: "num", seleccionadoLeyenda: true, color: this.colores[2], unidad: "", valoresMomentaneo: [], dim: "Hz", actual: this.consumo },
        { estaEnGrafico: -1, nombre: "nivelO2", nombreTraducido: this.translateService.instant("lpfb_nivelDeO2"), tipoDato: "num", seleccionadoLeyenda: true, color: this.colores[3], unidad: "", valoresMomentaneo: [], dim: "-", actual: this.nivelO2 },
        { estaEnGrafico: -1, nombre: "temperaturaInterior", nombreTraducido: this.translateService.instant("temperaturaInterior"), tipoDato: "num", seleccionadoLeyenda: true, color: this.colores[4], unidad: "", valoresMomentaneo: [], dim: "ºC", actual: this.temperaturaInterior },
      ];




      this.listaTooltipSeleccionados = this.listaTooltip.filter(x => x.estaEnGrafico != -1).map(x => x.nombre);

      this.mostrarGraficosPorLeyenda = [true, true, true, true, true];

      this.fechaInicio = this.datosGraficoMultieje.fechas[0];
      this.fechaFin = this.datosGraficoMultieje.fechas[this.datosGraficoMultieje.fechas.length - 1];
      this.fechaInicioZoom = this.fechaInicio;
      this.fechaFinZoom = this.fechaFin;

      this.cargarGraficosTodos();

      this.grafico1.regions.remove();
      this.grafico1.internal.config.regions = [];
      this.grafico1.regions.add(regionsSelected);
      this.grafico1.regions.add(regionsSelected);

      this.grafico2.regions.remove();
      this.grafico2.internal.config.regions = [];
      this.grafico2.regions.add(regionsSelected);
      this.grafico2.regions.add(regionsSelected);

      this.grafico3.regions.remove();
      this.grafico3.internal.config.regions = [];
      this.grafico3.regions.add(regionsSelected);
      this.grafico3.regions.add(regionsSelected);

      this.grafico4.regions.remove();
      this.grafico4.internal.config.regions = [];
      this.grafico4.regions.add(regionsSelected);
      this.grafico4.regions.add(regionsSelected);
    });


    // END GRAFICO

    // setTimeout(function () {
    //     chart.regions=regionsSelected;
    // }, 3500);

    //   var newRegion={
    //     axis: 'x',
    //     start: '12:00:00',
    //     end: '13:00:00',
    //     opacity:0.5,
    //     class: 'fill: red'
    //   }
    //    regionsSelected.push(newRegion);

    //    newRegion={
    //     axis: 'x',
    //     start: '1950/01/01T12:10:00',
    //     end: '1950/01/01T13:10:00',
    //     opacity:0.5,
    //     class: 'fill: red'
    //   }
    //    regionsSelected.push(newRegion);

    //    newRegion={
    //     axis: 'x',
    //     start: '2022/07/06T12:10:00',
    //     end: '2022/07/06T13:10:00',
    //     opacity:0.5,
    //     class: 'fill: red'
    //   }
    //    regionsSelected.push(newRegion);

    // var th = this;
    // setTimeout(function() {
    //   th.grafico1.regions.remove();
    //   th.grafico1.regions.add(regionsSelected);
    //   th.grafico2.regions.remove();
    //   th.grafico2.regions.add(regionsSelected);
    //   th.grafico3.regions.remove();
    //   th.grafico3.regions.add(regionsSelected);
    //   th.grafico4.regions.remove();
    //   th.grafico4.regions.add(regionsSelected);
    // }, 2000);


    //var c3rgn = document.getElementById(".c3-region");  
    // setTimeout(function () {
    //   var chart= d3.select("#grafico1_hmiAdi");
    //   chart.regions.remove();
    //     chart.regions.add(
    //         [
    //           {start: 1, end: 2, opacity:0.2}
    //         ]
    //     );
    // }, 3500);
  }

  clickGridLeyenda(event) {
    var numeroDeCamposSeleccionados = this.listaTooltip.filter(x => x.estaEnGrafico != -1).length;
    if (event.deselectedRows.length > 0) {
      //SE HA DESELECCIONADO UN CAMPO
      var campo = event.deselectedRows[0].dataItem;
      campo.seleccionadoLeyenda = false;
      campo.estaEnGrafico = -1;
    }
    if (event.selectedRows.length > 0) {
      //SE HA SELECCIONADO UN CAMPO
      //SI YA HAY 4 ELEMENTOS SELECCIONADOS ECHAMOS PARA ATRAS LA SELECCION
      var campo = event.selectedRows[0].dataItem;
      if (numeroDeCamposSeleccionados == 4) {
        this.listaTooltipSeleccionados = this.listaTooltipSeleccionados.filter(x => x != campo.nombre);
      } else {
        campo.seleccionadoLeyenda = true;
        campo.estaEnGrafico = numeroDeCamposSeleccionados + 1;
      }
    }

    this.mostrarGraficosPorLeyenda = [false, false, false, false, false, false, false, false, false, false,];

    var indexGrafico = 1;
    this.listaTooltip.forEach(function (campo) {
      if (campo.estaEnGrafico != -1) {
        campo.estaEnGrafico = indexGrafico;
        this.mostrarGraficosPorLeyenda[indexGrafico - 1] = true;
        indexGrafico++;
      }
    }, this);

    this.cargarGraficosTodos();

  }

  cargarGraficosTodos() {

    var fechas: any = [];
    var valores1: any = [];
    var valores2: any = [];
    var valores3: any = [];
    var valores4: any = [];
    var valores5: any = [];


    var i;

    var estanEnGrafico: any = {};
    var kop = 1;
    this.listaTooltip.forEach(function (campo) {
      if (campo.estaEnGrafico > 0) {
        estanEnGrafico[campo.nombre] = kop;
        kop++
      }
      // if (campo.estaEnGrafico == 2)    estanEnGrafico[campo.nombre] = 2;
      // if (campo.estaEnGrafico == 3)   estanEnGrafico[campo.nombre] = 3;
      // if (campo.estaEnGrafico == 4)   estanEnGrafico[campo.nombre] = 4;
      // if (campo.estaEnGrafico == 5) estanEnGrafico[campo.nombre] = 5;


      if (campo.estaEnGrafico == -1) estanEnGrafico[campo.nombre] = -1;
    }, this);

    for (i = 0; i < this.datosGraficoMultieje.fechas.length; i++) {
      if ((this.datosGraficoMultieje.fechas[i + 1] >= this.fechaInicioZoom && this.datosGraficoMultieje.fechas[i + 1] <= this.fechaFinZoom) ||
        (this.datosGraficoMultieje.fechas[i - 1] >= this.fechaInicioZoom && this.datosGraficoMultieje.fechas[i - 1] <= this.fechaFinZoom) ||
        (this.datosGraficoMultieje.fechas[i] <= this.fechaInicioZoom && this.datosGraficoMultieje.fechas[i + 1] >= this.fechaFinZoom) ||
        (this.datosGraficoMultieje.fechas[i - 1] <= this.fechaInicioZoom && this.datosGraficoMultieje.fechas[i] >= this.fechaFinZoom)) {

        fechas.push(this.datosGraficoMultieje.fechas[i]);

        for (const key in this.datosGraficoMultieje) {
          if (key != "fechas") {
            if (estanEnGrafico[key] == 1) valores1.push(this.datosGraficoMultieje[key][i]);
            if (estanEnGrafico[key] == 2) valores2.push(this.datosGraficoMultieje[key][i]);
            if (estanEnGrafico[key] == 3) valores3.push(this.datosGraficoMultieje[key][i]);
            if (estanEnGrafico[key] == 4) valores4.push(this.datosGraficoMultieje[key][i]);

          }
        }

      }
    }

    if (fechas.length > 300) {
      fechas = this.aplanarArray(fechas, 300);
      valores1 = this.aplanarArray(valores1, 300);
      valores2 = this.aplanarArray(valores2, 300);
      valores3 = this.aplanarArray(valores3, 300);
      valores4 = this.aplanarArray(valores4, 300);

    }

    this.arrayFechas = fechas;

    //Tooltip
    this.listaTooltip.forEach(function (campo) {
      if (campo.estaEnGrafico == 1) campo.valoresMomentaneo = valores1;
      if (campo.estaEnGrafico == 2) campo.valoresMomentaneo = valores2;
      if (campo.estaEnGrafico == 3) campo.valoresMomentaneo = valores3;
      if (campo.estaEnGrafico == 4) campo.valoresMomentaneo = valores4;

    }, this);

    var c1: boolean = this.fechaInicioZoom < fechas[0];
    var c2: boolean = this.fechaFinZoom > fechas[fechas.length - 1];

    if (c1) fechas.unshift(this.fechaInicioZoom);
    fechas.unshift('x');
    if (c2) fechas.push(this.fechaFinZoom);

    if (c1) valores1.unshift(null);
    valores1.unshift('valor');
    if (c2) valores1.push(null);

    if (c1) valores2.unshift(null);
    valores2.unshift('valor');
    if (c2) valores2.push(null);

    if (c1) valores3.unshift(null);
    valores3.unshift('valor');
    if (c2) valores3.push(null);

    if (c1) valores4.unshift(null);
    valores4.unshift('valor');
    if (c2) valores4.push(null);

    // if (c1) valores5.unshift(null);
    // valores5.unshift('valor');
    // if (c2) valores5.push(null);



    //valores1
    var campoTooltip = this.listaTooltip.find(x => x.estaEnGrafico == 1)
    if (campoTooltip != undefined) {
      this.grafico1.load({ columns: [fechas, valores1] });
      this.grafico1.data.colors({ valor: d3.rgb(campoTooltip.color) });
      d3.selectAll('#grafico1_hmiAdi .c3-axis-y line').style("stroke", campoTooltip.color);
      d3.selectAll('#grafico1_hmiAdi .c3-axis-y tspan').style("fill", campoTooltip.color);
      d3.selectAll('#grafico1_hmiAdi .c3-axis-y path').style("stroke", campoTooltip.color);
    } else {
      this.grafico1.load({ columns: [['x'], ['valor']] });
    }
    //valores2
    var campoTooltip = this.listaTooltip.find(x => x.estaEnGrafico == 2)
    if (campoTooltip != undefined) {
      this.grafico2.load({ columns: [fechas, valores2] });
      this.grafico2.data.colors({ valor: d3.rgb(campoTooltip.color) });
      d3.selectAll('#grafico2_hmiAdi .c3-axis-y line').style("stroke", campoTooltip.color);
      d3.selectAll('#grafico2_hmiAdi .c3-axis-y tspan').style("fill", campoTooltip.color);
      d3.selectAll('#grafico2_hmiAdi .c3-axis-y path').style("stroke", campoTooltip.color);
    } else {
      this.grafico2.load({ columns: [['x'], ['valor']] });
    }
    //valores3
    var campoTooltip = this.listaTooltip.find(x => x.estaEnGrafico == 3)
    if (campoTooltip != undefined) {
      this.grafico3.load({ columns: [fechas, valores3] });
      this.grafico3.data.colors({ valor: d3.rgb(campoTooltip.color) });
      d3.selectAll('#grafico3_hmiAdi .c3-axis-y line').style("stroke", campoTooltip.color);
      d3.selectAll('#grafico3_hmiAdi .c3-axis-y tspan').style("fill", campoTooltip.color);
      d3.selectAll('#grafico3_hmiAdi .c3-axis-y path').style("stroke", campoTooltip.color);
    } else {
      this.grafico3.load({ columns: [['x'], ['valor']] });
    }
    //valores4
    var campoTooltip = this.listaTooltip.find(x => x.estaEnGrafico == 4)
    if (campoTooltip != undefined) {
      this.grafico4.load({ columns: [fechas, valores4] });
      this.grafico4.data.colors({ valor: d3.rgb(campoTooltip.color) });
      d3.selectAll('#grafico4_hmiAdi .c3-axis-y line').style("stroke", campoTooltip.color);
      d3.selectAll('#grafico4_hmiAdi .c3-axis-y tspan').style("fill", campoTooltip.color);
      d3.selectAll('#grafico4_hmiAdi .c3-axis-y path').style("stroke", campoTooltip.color);
    } else {
      this.grafico4.load({ columns: [['x'], ['valor']] });
    }



  }

  aplanarArray(data, aCuantosElementos) {
    //var aCuantosElementos = 1000;
    //var data = [15, 16, 9, 1, 84, 1, 6, 77];

    var resultado = [];
    if (data.length > 0) {
      resultado.push(data[0]);
      var i;
      for (i = 1; i < aCuantosElementos; i++) {
        resultado.push(data[Math.round((data.length / aCuantosElementos) * i)]);
      }
      resultado.push(data[data.length - 1]);
    }
    return resultado;
  }

  calcularPorcentaje(valor: number, sobre: number, de: number, decimales: number, limitado: boolean) {
    var porcentaje = 0.0
    if (de != 0) {
      var d = 10 ^ decimales;
      var porcen = 0;
      porcen = valor * sobre * d / de;
      porcen = Math.round(porcen);
      if (porcen > sobre * d && limitado)
        porcen = sobre * d;
      porcentaje = Math.round(porcen / d);
    }
    else {
      if (valor > 0 && limitado)
        porcentaje = sobre;
      else if (!limitado) {
        porcentaje = -1;
      }

    }
    if (porcentaje < 0)
      porcentaje = 0;

    return porcentaje;
  }
  //FIN GRAFICOS


  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }


  cargarDatosProcesos() {
    this.hmiService.Get_ProcesosHistorial(this.idmaquina).subscribe((result) => {
      var datuak: any = result;
      this.historicoProcesos = [];
      datuak.forEach(function (valor, indice, array) {
        var tipoProceso = "";
        if (valor.idProcesos_Tipo == 1) tipoProceso = this.translateService.instant("ejecucion");
        if (valor.idProcesos_Tipo == 2) tipoProceso = this.translateService.instant("parada");
        if (valor.idProcesos_Tipo == 3) tipoProceso = this.translateService.instant("preparacion");
        if (valor.idProcesos_Tipo == 4) tipoProceso = this.translateService.instant("mantenimiento");
        if (valor.idProcesos_Tipo == 6) tipoProceso = this.translateService.instant("alarma");
        if (valor.idProcesos_Tipo == 7) tipoProceso = this.translateService.instant("alarma");
        if (valor.idProcesos_Tipo == 8) tipoProceso = this.translateService.instant("apagado");
        this.historicoProcesos.push(
          {
            idProcesos_Tipo: valor.idProcesos_Tipo,
            diaini: valor.diaini,
            horaini: valor.horaini,
            diafin: valor.diafin,
            horafin: valor.horafin,
            tipo: tipoProceso,
            tipoAlarNombre: valor.descripcion,
            nombre: valor.nombrePrograma,
            OF: valor.of,
            tiemporeal: this.myFunctions.secondsTo_HH_MM_SS(valor.tiemporeal),
            tiempoteorico: this.myFunctions.secondsTo_HH_MM_SS(valor.tiempoPredictivo),
            cliente: valor.cliente,
            parte: valor.parte,
            pieza: valor.pieza,
            divcss: "css_linea_lateral_" + valor.idProcesos_Tipo,
          }
        );
      }, this);
    });

    this.hmiService.Get_ProcesosAlarmas(this.idmaquina).subscribe((result) => {
      var datuak: any = result;
      this.alarmas = [];
      datuak.forEach(function (valor, indice, array) {
        var divnombre;
        if (valor.idAlarma_tipo == 1) divnombre = "css_linea_lateral_2";
        else divnombre = "css_linea_lateral_6";
        this.alarmas.push(
          {
            diaini: valor.diaini,
            horaini: valor.horaini,
            diafin: valor.diafin,
            horafin: valor.horafin,
            numeroAlarma: valor.numeroAlarma,
            descripcion: valor.descripcion,
            divcss: divnombre,
          }
        );
      }, this);
    });

    this.hmiService.Get_Planificados(this.idmaquina).subscribe((result) => {
      var datuak: any = result;
      this.planificados = [];
      datuak.forEach(function (valor, indice, array) {
        this.planificados.push(
          {
            idProcesos_Tipo: valor.idProcesos_Tipo,
            diaini: valor.diaini,
            horaini: valor.horaini,
            diafin: valor.diafin,
            horafin: valor.horafin,
            operacion: valor.operacion,
            OF: valor.of,
            tiempoteorico: this.myFunctions.secondsTo_HH_MM_SS(valor.tiempoEstimado),
            programa: 123456,
            cliente: valor.cliente,
            parte: valor.parte,
            pieza: valor.pieza,
            divcss: "css_linea_lateral_8",
          }
        );
      }, this);
    });

  }


  cargarDatosMantenimientos() {
    this.cargarDatosMantenimientos_porFecha();
    this.cargarDatosMantenimientos_porTiempo();
  }

  cargarDatosMantenimientos_porFecha() {
    this.hmiService.Get_MantenimientosRecientes_porFecha(this.idmaquina).subscribe((result) => {
      var datuak: any = result;

      this.mantenimientoPorFecha = [];

      var ahora = this.myFunctions.getDateNow();
      var finHoy = this.myFunctions.getDateNow();
      finHoy.setHours(23, 59, 59);
      var dif = finHoy.getTime() - ahora.getTime();
      var difHoy;
      var siguiente;

      var myhoraText = "";
      var myFaltaText = "";
      var myTextText = "";

      var PDFText = "";
      var PDFURL = "";

      var PDF;
      var rojo;
      var gris;
      var CheckBoxChecked;

      var mantenimientosTemp = [];

      var inf = new Promise((resolve, reject) => {
        datuak.forEach(async function (row, indice, array) {
          rojo = false;
          gris = false;
          CheckBoxChecked = false;
          PDF = false;
          var tipo = "";
          if (row.fechaUltimo != "") {
            if (row.idTipo == 1) {//diario
              siguiente = this.myFunctions.sqlToJsDate(row.fechaUltimo);
              siguiente.setDate(siguiente.getDate() + (1 * row.frecuenciaMantenimiento));
            } else if (row.idTipo == 2) {//semanal
              siguiente = this.myFunctions.sqlToJsDate(row.fechaUltimo);
              siguiente.setDate(siguiente.getDate() + (7 * row.frecuenciaMantenimiento));
            } else if (row.idTipo == 3) {//mensual
              siguiente = this.myFunctions.sqlToJsDate(row.fechaUltimo);
              siguiente.setMonth(siguiente.getMonth() + (1 * row.frecuenciaMantenimiento));
              var dia2 = row.dia;
              var mes = siguiente.getMonth();
              siguiente = new Date(siguiente.getFullYear(), siguiente.getMonth(), dia2, siguiente.getHours(), siguiente.getMinutes(), siguiente.getSeconds());
              siguiente.setMonth(mes);

            } else if (row.idTipo == 5) { // por turnos
              var that = this;
              await new Promise(function (myResolve, myReject) {
                that.maquinasService.getProximoTurno(that.idmaquina, row.turnos, row.fechaUltimo, row.frecuenciaMantenimiento).subscribe((result) => {
                  if (result.length != 0)
                    siguiente = new Date(result[0].fechaIni);
                  else
                    siguiente = ahora
                  myResolve(result[0].fechaIni);
                });
              });
            } else {//anual
              siguiente = this.myFunctions.sqlToJsDate(row.fechaUltimo);
              siguiente.setMonth(siguiente.getMonth() + (12 * (1 * row.frecuenciaMantenimiento)));
            }
            if (siguiente > ahora) {
              gris = true;
              CheckBoxChecked = true;
              //ViewState("checkMantenimientos") = ViewState("checkMantenimientos") & ":" & row("id") & ":"
            }

            //ANTES DE USAR EL CALCULO DEL PROXIMO MANTENIMIENTO, HAY QUE TRADUCIRLO A LA HORA DEL USUARIO!
            // hacer esto desde la DB es dificil xq se pasa dia y hora... no una fecha como tal.
            //ARATZ SIGUE AQUI
            if (row.idTipo == 1) {
              myhoraText = siguiente;
              ahora.setHours(0, 0, 0);
              difHoy = siguiente.getTime() - ahora.getTime();
              myFaltaText = this.myFunctions.milisecondsTo_HH_MM(difHoy % (1 * 24 * 60 * 60 * 1000));//myFaltaText = difHoy.ToString("hh\:mm")
              if ((difHoy / 1000) < 0) {
                rojo = true;
              }
              var dias = Math.round(difHoy / 1000 / 60 / 60 / 24);
              if (dias < 0) {
                dias = dias * (-1);
                if (dias == 1) {
                  myFaltaText = dias + " " + "día" + " " + myFaltaText;
                } else {
                  myFaltaText = dias + " " + "días" + " " + myFaltaText;
                }
              }
            } else {
              myhoraText = siguiente;
              ahora.setHours(0, 0, 0);
              difHoy = siguiente.getTime() - ahora.getTime();
              myFaltaText = this.myFunctions.milisecondsTo_HH_MM(difHoy % (1 * 24 * 60 * 60 * 1000));//myFaltaText = difHoy.ToString("hh\:mm")
              var dias = Math.round(difHoy / 1000 / 60 / 60 / 24);
              if (dias < 0) {
                dias = dias * (-1);
                if (dias == 1) {
                  myFaltaText = dias + " " + "día" + " " + myFaltaText;
                } else {
                  myFaltaText = dias + " " + "días" + " " + myFaltaText;
                }
              }
              if ((difHoy / 1000) < 0) {
                rojo = true;
                //myFaltaText = dif.Add(TimeSpan.FromDays(1)).ToString("d\.hh\:mm\:ss")
                myFaltaText = this.myFunctions.milisecondsTo_HH_MM_SS(dif + (1 * 24 * 60 * 60 * 1000));//sumar un dia en milisegundos
                myFaltaText = Math.floor((dif + (1 * 24 * 60 * 60 * 1000)) / (1 * 24 * 60 * 60 * 1000)) + " días, " + this.myFunctions.milisecondsTo_HH_MM_SS((dif + (1 * 24 * 60 * 60 * 1000)) % (1 * 24 * 60 * 60 * 1000));
              }
            }

            myTextText = row.nombre;

            if (!(row.pdf === "")) {
              PDFText = row.pdf;
              //PDFURL = "~/idcontent/" & current.conexID & "/mantenimientos/" & row("PDF")
              PDF = true;
            }

          }
          else {
            //no se ha hecho nunca
            CheckBoxChecked = false;

            row

            rojo = true;
            myTextText = row.nombre;
            myhoraText = "-";
            myFaltaText = this.myFunctions.milisecondsTo_HH_MM_SS(dif);

            if (!(row.pdf === "")) {
              PDFText = row.pdf;
              //PDFURL = "~/idcontent/" & current.conexID & "/mantenimientos/" & row("PDF")
              PDF = true;
            }
          }

          if (row.idTipo == 1) {//diario
            tipo = "diario";
          }
          else if (row.idTipo == 2) {//semanal
            tipo = "semanal";
          }
          else if (row.idTipo == 3) {//mensual
            tipo = "mensual";
          }
          else if (row.idTipo == 5) { // por turnos
            tipo = "porTurnos";
          }
          else {//anual
            tipo = "anual";
          }

          var mantenimiento = {
            id: row.id,
            texto: myTextText,
            fecha: this.myFunctions.dateToString(myhoraText),
            checked: CheckBoxChecked,
            enlacePdf: PDFText,
            tienePdf: PDF,
            esRojo: rojo,
            tipo: tipo
          };

          /* NO SE PUEDEN DESHACER MANTENIMIENTOS NI DESDE HMI NI DESDE LA APP, TENDRA QUE HACERSE DESDE HISTORICO */
          if (!mantenimiento.checked)
            mantenimientosTemp.push(mantenimiento);

          if (indice == array.length - 1) resolve(true);

        }, this);
      })

      inf.then(() => {
        var array = []
        var an: any = mantenimientosTemp;
        an.forEach(a => array[a['checked'] + this.myFunctions.dateToYYYYMMDDHHmmSS(new Date(a['fecha'])) + a['id']] = a); // Criterio de orden: false < true
        array.sort(function (a, b) { return b[1] - a[1] });
        var keys = [];
        Object.keys(array).sort().forEach(key => keys.push(key)); // Se ordena segun nuestro criterio de orden
        keys.forEach(key => {
          var row = array[key]; // Cogemos las rows desde el array que hemos creado. Y estos nos vendran en orden.
          this.mantenimientoPorFecha.push(row);
        });
      })

    });
  }

  cargarDatosMantenimientos_porTiempo() {
    this.hmiService.Get_MantenimientosRecientes_porTiempo(this.idmaquina).subscribe((result) => {
      var datuak: any = result;

      this.mantenimientoPorTiempo = [];

      var ahora = this.myFunctions.getDateNow()
      var finHoy = this.myFunctions.getDateNow()
      finHoy.setHours(23, 59, 59);
      var dif = finHoy.getTime() - ahora.getTime();

      var myhoraText = "";
      var myTextText = "";

      var PDFText = "";
      var PDFURL = "";

      var PDF;
      var rojo;
      var gris;
      var CheckBoxChecked;

      var mantenimientosTemp = [];

      datuak.forEach(function (row, indice, array) {
        rojo = false;
        gris = false;
        CheckBoxChecked = false;
        PDF = false;

        //no se ha hecho nunca
        if (row.tiempoRestante < 0) {
          CheckBoxChecked = false;
          rojo = true;
        }
        else {
          CheckBoxChecked = true;
          rojo = false;
        }

        myTextText = row.nombre;
        myhoraText = "-";

        if (!(row.pdf === "")) {
          PDFText = row.pdf;
          //PDFURL = "~/idcontent/" & current.conexID & "/mantenimientos/" & row("PDF")
          PDF = true;
        }

        myhoraText = this.myFunctions.secondsTo_HH_MM_SS(row.tiempoRestante)

        var mantenimiento = {
          id: row.id,
          texto: myTextText,
          fecha: myhoraText,
          checked: CheckBoxChecked,
          enlacePdf: PDFText,
          tienePdf: PDF,
          esRojo: rojo
        };
        /* NO SE PUEDEN DESHACER MANTENIMIENTOS NI DESDE HMI NI DESDE LA APP, TENDRA QUE HACERSE DESDE HISTORICO */
        if (!mantenimiento.checked)
          mantenimientosTemp.push(mantenimiento);

      }, this);

      var array = []
      var an: any = mantenimientosTemp;
      an.forEach(a => array[a['checked'] + this.myFunctions.dateToYYYYMMDDHHmmSS(new Date(a['fecha'])) + a['id']] = a); // Criterio de orden: false < true
      array.sort(function (a, b) { return b[1] - a[1] });
      var keys = [];
      Object.keys(array).sort().forEach(key => keys.push(key)); // Se ordena segun nuestro criterio de orden
      keys.forEach(key => {
        var row = array[key]; // Cogemos las rows desde el array que hemos creado. Y estos nos vendran en orden.
        this.mantenimientoPorTiempo.push(row);
      });
    });
  }

  cargarDatosNotas() {
    this.hmiService.Get_NotasActualesMaquina(this.idmaquina).subscribe((result) => {
      var datuak: any = result;
      this.notas = datuak;
    });
  }

  calcularDesviacion() {
    if (this.tiempoLoteSegundos != -1) {
      var desviacion = this.tiempoLoteSegundos - this.tiempoEst;

      if (desviacion < 0) {
        if (Math.abs(desviacion) > 3600) {
          this.desviacionTotal = '- ' + this.corregirFormato(Math.abs(desviacion));
        } else {
          this.desviacionTotal = '- ' + "00:" + this.pad(Math.floor(Math.abs(desviacion) / 60)) + ":" + this.pad(Math.floor(Math.abs(desviacion) % 60));
        }
      } else {
        if (Math.abs(desviacion) > 3600) {
          this.desviacionTotal = '+ ' + this.corregirFormato(Math.abs(desviacion));
        } else {
          this.desviacionTotal = '+ ' + "00:" + this.pad(Math.floor(Math.abs(desviacion) / 60)) + ":" + this.pad(Math.floor(Math.abs(desviacion) % 60));
        }
      }
    } else {
      this.desviacionTotal = '--:--:--';
    }

    if (this.tiempoPreparacionSegundos != -1) {
      var desviavionPrep = this.tiempoPreparacionSegundos - this.tiempoPreparacionEst;
      if (Math.abs(desviavionPrep) > 0){
        this.desviacionPrep = "+ " + this.corregirFormato(Math.abs(desviavionPrep))
      } else{
        this.desviacionPrep = "- " + this.corregirFormato(Math.abs(desviavionPrep))
      }
    }

  }

  pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }

  segundosAFormatoHora(tiempo: number) {
    // if (tiempo < 86400) { // supera las 24h
    var horas = this.pad(Math.floor(tiempo / 3600));
    var minutos = this.pad(Math.floor((tiempo % 3600) / 60));
    var segundos = this.pad(Math.floor(((tiempo % 3600) % 60) / 1));
    var formatoHora = horas + ":" + minutos + ":" + segundos;
    // } else {
    //   var dias = Math.floor((tiempo / 3600) / 24);
    //   var horas = this.pad(Math.floor((tiempo / 3600) % 24));
    //   var formatoHora = dias + "d " + horas + "h"
    // }
    return formatoHora;
  }

  corregirFormato(tiempo: number) {
    // if (tiempo > 86400) {
    //   var dias = Math.floor(tiempo / 86400);
    //   var horas = this.pad(Math.floor(Math.floor(tiempo % 86400) / 3600));
    //   return dias + "d " + horas + "h"
    // } else {
    var hora = this.pad(Math.floor(tiempo / 3600));
    var minutos = this.pad(Math.floor((tiempo % 3600) / 60));
    var segundos = this.pad(Math.floor(((tiempo % 3600) % 60) / 1));
    return hora + ":" + minutos + ":" + segundos;
    // }
  }


}
