import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Usuario } from '@app/_models';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NumericFilterCellComponent } from '@progress/kendo-angular-grid';

const baseUrl = `${environment.apiUrl}/marcas`;

@Injectable()
export class MarcasService {

  constructor(private http: HttpClient) { }

  public GetById(id: number): Observable<any> {

    return this.http.get(baseUrl + "/" + id);
  }

  public GetAll(action: string = '', param?: string, data?: ''): Observable<any> {

    var headers, params;

    if (param == undefined) {
      param = ''
    }

    return this.http.get(baseUrl);
  }

  public GetAll_TiposPerdidas_DAT(action: string = '', param?: string, data?: ''): Observable<any> {

    var headers, params;

    if (param == undefined) {
      param = ''
    }

    return this.http.get(baseUrl + "/marcas");
  }

  insert(data, logo, archivo) {
    return this.http.post<any>(baseUrl + "/crear", {id: -1,  nombre: data.nombre, logo: logo, archivo: archivo}, { withCredentials: true });
  }

  update(data, logo, imagen) {
    return this.http.post<any>(baseUrl + "/editar", { id:data.id, nombre: data.nombre, logo: logo, archivo : imagen  }, { withCredentials: true });
  }

  delete(data?: any): Observable<any> {

    var formData: any = new FormData();
    formData.append("ids", data.ids);

    return this.http.post<JSON>(baseUrl + "/eliminar", formData, { withCredentials: true });
  }
  public download(idMarca): Observable<any> {
    return this.http.get(baseUrl + "/logo/download/" + idMarca);
  } 
}
