import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

const baseUrl = `${environment.apiUrl}/eskola_asignaturasretos`;

@Injectable()
export class Eskola_AsignaturasRetosService {

  public numeroCirculos: number = 4; // maxima cantidad de docentes

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<any[]>(baseUrl);
  }

  getById(idAsignatura: number) {
    return this.http.get<any>(`${baseUrl}/${idAsignatura}`);
  }

  create(id: number, nombre: string, docentes: string,  activo: Boolean) {
    return this.http.post<JSON>(`${baseUrl}/create`, { id, nombre,docentes, activo }, { withCredentials: true });
  }

  update(id: number, nombre: string, docentesDelete: string, docentesInsert: String, activo: Boolean) {
    return this.http.post<JSON>(`${baseUrl}/update`, { id, nombre, docentesDelete, docentesInsert, activo }, { withCredentials: true });
  }

  delete(id: number) {
    return this.http.post<JSON>(`${baseUrl}/delete`, { id }, { withCredentials: true });
  }

  GetAll_datosDocentes(): Observable<any> {
    return this.http.get(`${baseUrl}/GetAll_datosDocentes`, { withCredentials: true });
  }

  GetAll_Docentes(): Observable<any> {
    return this.http.get(`${baseUrl}/GetAll_Docentes`,{ withCredentials: true });
  }
  deleteDocente(Id:number){
    return this.http.post<JSON>(`${baseUrl}/deleteDocente`, { Id }, { withCredentials: true });
  }

}
