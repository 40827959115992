import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService, UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.less']
})
export class StockComponent implements OnInit {
  user = this.userService.userValue;

  public data: any;
  constructor(private menuService: MenuService, public router: Router, 
    private userService: UsuariosService,
     private translateService: TranslateService) { 
    this.menuService.titulo = this.translateService.instant('stock');
  }

  ngOnInit(): void {
  }

  btnRealizarPedido(){
    this.router.navigate(['/generadorPedido']);
  }

}
