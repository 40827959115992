import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/planesaccion`;
@Injectable()
export class PlanesAccionService extends BehaviorSubject<any> {

  public loading = false;

  constructor(private http: HttpClient) {
    super(null);
  }

  public GetAll(action: string = '',param?:string, data?: ''): Observable<any> {
    this.loading = true;
    return this.http.get(baseUrl);
  }

  public GetByID(id:Number): Observable<any> {
    this.loading = true;
    return this.http.get(baseUrl + "/" + id);
  }

  public delete(data?: any): Observable<any> {
    var formData: any = new FormData();
    formData.append("idplan", data.idplan);
    return this.http.post<JSON>(baseUrl + "/eliminar", formData, { withCredentials: true });
  }

  create(data) {
    var fIni:Date =data.fechaini;
    var fFin: Date = data.fechafin;
    return this.http.post<any>(baseUrl + "/crear", { Nombre: data.nombre, Descripcion: data.descripcion, Fechainicio: fIni, Fechafin: fFin, idresponsable: data.idresponsable }, { withCredentials: true });
  }

  update(data) {
    var fIni:Date =data.fechaini;
    var fFin:Date =data.fechafin;
    return this.http.post<any>(baseUrl + "/editar", { Id: data.idplan, Nombre: data.nombre, Descripcion: data.descripcion, Fechainicio: fIni, Fechafin: fFin, idresponsable: data.idresponsable }, { withCredentials: true });
  }

}
