import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ContenedoresService, MenuService } from '@app/_services';

@Component({
    selector: 'app-informeContenedorDetalle',
    templateUrl: 'informeContenedorDetalle.component.html'
  })

export class InformeContenedorDetalleComponent {

    public translate;

    public dataContenedor = [];
    public seleccionados = [];

    public ocultarParte = false;

    public id;

    constructor(
        translate: TranslateService,
        private menuService: MenuService,
        private route: ActivatedRoute,
        private contenedoresService: ContenedoresService,
        public router: Router) { 

        this.id = this.route.snapshot.params['id'];
            
        this.translate = translate;
        this.menuService.titulo = this.translate.instant('informeContenedor').toUpperCase();
    }

    ngOnInit() {
        if (this.id > 0)
            this.cargarDatos();
        else 
            this.cargarDatosEnProceso();
    }

    cargarDatos() {
        this.contenedoresService.GetContenedorById(this.id).subscribe((result: any) => {
            var cont = result[0];
            if (cont.maquinasOrigen != null)
                cont.maquinasOrigenArray = cont.maquinasOrigen?.split(',').map(function(x) { return parseInt(x) });
            else
                cont.maquinasOrigenArray = []
            if (cont.maquinasDestino != null)
                cont.maquinasDestinoArray = cont.maquinasDestino?.split(',').map(function(x) { return parseInt(x) });
            else
                cont.maquinasDestinoArray = []

            this.contenedoresService.GetInformeContenedoresAll().subscribe((json: any) => {
                var inf = json.table1;

                var idsHo = [];
                var arrayidsHo;
                inf.forEach(element => {
                    // tiene maquina origen y proxima
                    if (cont.maquinasOrigenArray.includes(element.idMaquina_anterior) && cont.maquinasDestinoArray.includes(element.idMaquina_proxima)) {
                        arrayidsHo = element.idHO?.split(';and;').map(function(x) { return parseInt(x) });
                        idsHo.push(...arrayidsHo);
                    }
                    // solo tiene maquina origen
                    else if (cont.maquinasOrigenArray.includes(element.idMaquina_anterior)) {
                        arrayidsHo = element.idHO?.split(';and;').map(function(x) { return parseInt(x) });
                        idsHo.push(...arrayidsHo);
                    }
                    // solo tiene maquina destino
                    else if (cont.maquinasDestinoArray.includes(element.idMaquina_proxima)) {
                        arrayidsHo = element.idHO?.split(';and;').map(function(x) { return parseInt(x) });
                        idsHo.push(...arrayidsHo);
                    }
                });

                this.cargarDatosHO(idsHo);

             });
        });
    }

    cargarDatosEnProceso() {

        this.contenedoresService.GetInformeContenedoresAll().subscribe((json: any) => {
            var inf = json.table2;

            var idsHo = [];
            inf.forEach(element => {
                idsHo.push(element.idHO);
            });

            this.cargarDatosHO(idsHo);
        });
    }

    cargarDatosHO(idsHO) {
        this.contenedoresService.GetInformeContenedorById(idsHO).subscribe((json: any) => {
            this.dataContenedor = json;
            this.ocultarParte = json[0].ocultarParte;
        });
    }

    cellClick(event) {
        var data = this.dataContenedor.filter(f => this.seleccionados[0] == f.idOf)[0]
        var idOF = data.idOf;
        var idPieza = data.idPieza;
        var idParte = data.idParte;
        var nSerie = data.nSeries.replace(/;/g, "__");
        if (!this.ocultarParte) {
            var enlace = 'historicoPiezas/0/0/0/0/0/0/' + idOF + '/0/' + idPieza + '/' + idParte + '/0/' + nSerie;
            window.open(enlace, '_blank');
        } else {
            var enlace = '#/historicoPiezas/0/0/0/0/0/0/' + idOF + '/0/' + idPieza + '/0/0/' + nSerie;
            window.open(enlace, '_blank');
            // this.router.navigate(['historicoPiezas/0/0/0/0/0/0/' + idOF + '/0/' + idPieza + '/0/0']);
        }
    }


}