
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="card w-50">
    <div class="card-body">
      <h3 *ngIf="isAddMode">{{ 'crearusuario' | translate}}</h3>
      <h3 *ngIf="!isAddMode">{{ 'editarusuario' | translate}}</h3>
      <div class="plegarpanel"></div>
    </div>
    <div class="card-body">

      <div class="form-group">
        <kendo-label text="{{ 'nombre' | translate}}">
          <kendo-textbox formControlName="nombre" maxlength="150" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }">
          </kendo-textbox>
          <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
            <div *ngIf="f.nombre.errors.required">{{ 'nombrerequerido' | translate}}</div>
          </div>
        </kendo-label>
      </div>

      <div class="form-group">
        <kendo-label text="{{ 'permisos' | translate}}">
          <kendo-treeview [nodes]="data"
                          textField="text"
                          kendoTreeViewExpandable                      
                          kendoTreeViewHierarchyBinding
                          childrenField="items"
                          (nodeClick)="onNodeClick($event)">
            <ng-template kendoTreeViewNodeTemplate let-dataItem>
              <span [ngClass]="iconClass(dataItem)"></span>
              {{dataItem.text}}
            </ng-template>
          </kendo-treeview>
        </kendo-label>
      </div>

      
      <div class="form-group">
        <button kendoButton [disabled]="loading" class="btn mr-1  btn-primary" [disabled]="this.user.gestionUsuarios < 2">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{ 'guardar' | translate}}
        </button>
        <a routerLink="/usuarios-tipos" class="btn mr-1  btn-danger">{{ 'cancelar' | translate}}</a>
      </div>
    </div>
  </div>
</form>
