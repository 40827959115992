<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <!--NOMBRE-->
          <div class="form-group">
            <label>{{ 'nombre' | translate }}</label>
            <kendo-textbox formControlName="nombre" maxlength="80"
                           [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }"></kendo-textbox>
          </div>
          <div class="row">
            <div class="col-md-4">
              <!--ASIGNAR PERDIDA A-->
              <div class="form-group">
                <label ID="lAsignarPerdidaA" class="control-label">{{ 'asignarPerdidaA' | translate }}</label>
                <div class="caja">
                  <kendo-dropdownlist formControlName="idTipoPerdida_DAT" [data]="tiposPerdidas_DAT"
                                      [textField]="'nombre'" [valueField]="'id'" (selectionChange)=" selectionPerdidaChange($event)"
                                      [value]="selectedItem" class="form-control">
                  </kendo-dropdownlist>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <!--TIPOS MANTENIMIENTOS-->
              <div class="form-group">
                <label class="control-label">{{ 'tipo' | translate }}</label>
                <div class="caja">
                  <kendo-dropdownlist formControlName="idTipoFechaTiempoMantenimiento_DAT"
                                      [data]="tiposFechaTiempoMantenimientos_DAT" [textField]="'nombre'" [valueField]="'id'"
                                      (selectionChange)="selectionFechaTiempoMantenimientoChange($event)"
                                      [value]="selectedFechaTiempoMantenimiento" class="form-control" style="width: 250px">
                  </kendo-dropdownlist>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <!--FRECUENCIAS MANTENIMIENTOS-->
              <div class="form-group"
                   *ngIf="idTipoFechaTiempoMantenimiento_DAT==1 || idTipoFechaTiempoMantenimiento_DAT==3">
                <label ID="lAsignarPerdidaA" class="control-label">{{ 'frecuencia' | translate }}</label>
                <div class="caja">
                  <kendo-dropdownlist formControlName="idTipoMantenimiento_DAT" [data]="tiposMantenimientos_DAT"
                                      [textField]="'nombre'" [valueField]="'id'" (selectionChange)="selectionMantenimientoChange($event)"
                                      [value]="selectedMantenimiento" class="form-control" style="width: 250px">
                  </kendo-dropdownlist>
                </div>
              </div>

              <!--DIAS (SEMANA)-->
              <div class="form-group"
                   *ngIf="(idTipoFechaTiempoMantenimiento_DAT==1 || idTipoFechaTiempoMantenimiento_DAT==3) && idTipoMantenimiento_DAT == 2">
                <label class="control-label">{{ 'dias' | translate }}</label>
                <div class="caja">
                  <!-- <kendo-numerictextbox formControlName="dias" [format]="'n'" [decimals]="decimals" [min]="1" [max]="maxDias" [autoCorrect]="autoCorrect">
                  </kendo-numerictextbox> -->

                  <kendo-dropdownlist [data]="listaDias" [textField]="'nombre'" [value]="dias"
                                      (selectionChange)="selectDiaChange($event)" class="form-control" style="width: 250px">
                  </kendo-dropdownlist>

                </div>
              </div>

              <!--DIAS (MES)-->
              <div class="form-group"
                   *ngIf="(idTipoFechaTiempoMantenimiento_DAT==1 || idTipoFechaTiempoMantenimiento_DAT==3) && idTipoMantenimiento_DAT == 3">
                <label class="control-label">{{ 'dia' | translate }}</label>
                <div class="caja">
                  <kendo-numerictextbox [value]="dias" [format]="'n'" [decimals]="decimals" [min]="1" [max]="maxDias"
                                        [autoCorrect]="autoCorrect" (valueChange)="selectDia($event)">
                  </kendo-numerictextbox>
                </div>
              </div>

              <!--MES DIAS (ANUAL)-->
              <div class="form-group"
                   *ngIf="(idTipoFechaTiempoMantenimiento_DAT==1 || idTipoFechaTiempoMantenimiento_DAT==3) && idTipoMantenimiento_DAT == 4">
                <label class="control-label">{{ 'mes' | translate }}</label>

                <div class="caja">
                  <kendo-dropdownlist [data]="listaMeses" [textField]="'nombre'" [value]="mes"
                                      (selectionChange)="selectMesChange($event)" class="form-control" style="width: 250px">
                  </kendo-dropdownlist>
                </div>

                <label class="control-label">{{ 'dia' | translate }}</label>

                <div class="caja">
                  <kendo-numerictextbox [value]="dias" [format]="'n'" [decimals]="decimals" [min]="1" [max]="31"
                                        [autoCorrect]="autoCorrect" (valueChange)="selectDia($event)">
                  </kendo-numerictextbox>
                </div>


              </div>

              <!--TURNOS -->
              <div class="form-group"
                   *ngIf="(idTipoFechaTiempoMantenimiento_DAT==1 || idTipoFechaTiempoMantenimiento_DAT==3) && idTipoMantenimiento_DAT == 5">
                <label class="control-label">{{ 'turno' | translate }}</label>
                <div class="caja">
                  <kendo-dropdownlist [data]="listaTurnos" [textField]="'nombre'" [valueField]="'id'" [(value)]="turnosSeleccionados" class="form-control" style="width: 250px">
                  </kendo-dropdownlist>

                  <!-- <kendo-multiselect kendoMultiSelectSummaryTag class="form-control" [(data)]="listaTurnos" [textField]="'nombre'"
                  [valueField]="'id'" [autoClose]="false"
                  [(value)]="turnosSeleccionados" placeholder="{{ 'turnos' | translate }}" style="width: 250px;"
                  [kendoDropDownFilter]="{operator: 'contains'}">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                      <ng-container *ngIf="dataItems.length > 1">
                        {{ dataItems.length }} {{ 'turnos' | translate }}
                      </ng-container>
                    </ng-template>
                    <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                  </kendo-multiselect> -->
                </div>
              </div>

              <!-- TIEMPO ENTRE MANTENIMIENTOS -->
              <div *ngIf="idTipoFechaTiempoMantenimiento_DAT == 2 || idTipoFechaTiempoMantenimiento_DAT ==3">
                <div class="form-group">
                  <label class="control-label">{{ 'tiempoEntreMantenimientos' | translate }}</label>
                  <div class="caja">
                    <!--<kendo-maskedtextbox [mask]="mask" [value]="tiempoestimado" (valueChange)="onMaskValueChange($event)" formControlName="tiempo"></kendo-maskedtextbox>-->
                    <kendo-numerictextbox formControlName="tiempo" [format]="'n'" [decimals]="0" [value]="value"
                                          [min]="0" [max]="99999" [autoCorrect]="true"></kendo-numerictextbox>
                  </div>
                </div>
              </div>

              <!--TIEMPO ESTIMADO-->
              <div class="form-group">
                <label ID="lTinePartes" class="control-label">{{ 'tiempoestimado' | translate }}</label>
                <div class="caja">
                  <kendo-textbox formControlName="tiempoEstimado"
                                 [ngClass]="{ 'is-invalid': submitted && f.tiempoEstimado.errors }"></kendo-textbox>
                  <div *ngIf="submitted && f.tiempoEstimado.errors" class="invalid-feedback">
                    <div *ngIf="f.tiempoEstimado.errors.pattern">
                      {{
 'formatoInadecuado' |
                      translate
                      }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- CONTAR DESDE ULTIMO -->
              <div *ngIf="idTipoFechaTiempoMantenimiento_DAT == 2 || idTipoFechaTiempoMantenimiento_DAT==3">
                <div class="form-group">
                  <label class="control-label">{{ 'contarDesdeUltimo' | translate}}</label>
                  <div class="checkbox">
                    <kendo-switch formControlName="contarDesdeUltimo" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="row">
                <!--FRECUENCIA-->
                <div class="form-group"
                     *ngIf="idTipoFechaTiempoMantenimiento_DAT==1 || idTipoFechaTiempoMantenimiento_DAT==3">
                  <label ID="lAsignarPerdidaA" class="control-label">{{ 'frecuencia' | translate }}</label>
                  <div class="caja">
                    <kendo-numerictextbox formControlName="frecuencia" [format]="'n'" [decimals]="decimals" [min]="1"
                                          [max]="1000" [autoCorrect]="autoCorrect" [ngClass]="{ 'is-invalid': submitted && f.frecuencia.errors }">
                    </kendo-numerictextbox>
                  </div>
                </div>
                <!--HORA-->
                <div class="form-group"
                     *ngIf="(idTipoFechaTiempoMantenimiento_DAT==1 || idTipoFechaTiempoMantenimiento_DAT==3) && idTipoMantenimiento_DAT != 5">
                  <label class="control-label">{{ 'hora' | translate }}</label>
                  <div class="caja">
                    <kendo-timepicker formControlName="hora">
                      <kendo-timepicker-messages accept="{{ 'accept' | translate }}"
                                                 acceptLabel="{{ 'acceptLabel' | translate }}" cancel="{{ 'cancel' | translate }}"
                                                 cancelLabel="{{ 'cancelLabel' | translate }}" now="{{ 'now' | translate }}"
                                                 nowLabel="{{ 'nowLabel' | translate }}" toggle="{{ 'toggle' | translate }}">
                      </kendo-timepicker-messages>
                    </kendo-timepicker>
                  </div>
                </div>
                <!--  TIPOS PROCESOS QUE CONTAR -->
                <div *ngIf="idTipoFechaTiempoMantenimiento_DAT == 2 || idTipoFechaTiempoMantenimiento_DAT==3">
                  <label>{{ 'contar' | translate}}</label>
                  <div class="row">
                    <!-- EJECUCION -->
                    <div class="col-md-4">
                      <div class="form-group form-check">
                        <label class="form-check-label">{{ 'ejecucion' | translate}}</label>
                        <div class="caja">
                          <kendo-switch formControlName="contarEjecucion" [onLabel]="' '" [offLabel]="' '">
                          </kendo-switch>
                        </div>
                      </div>
                    </div>
                    <!-- PARADA -->
                    <div class="col-md-4">
                      <div class="form-group form-check">
                        <label class="form-check-label">{{ 'parada' | translate}}</label>
                        <div class="caja">
                          <kendo-switch formControlName="contarParada" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <!-- PREPARACION -->
                    <div class="col-md-4">
                      <div class="form-group form-check">
                        <label class="form-check-label">{{ 'preparacion' | translate}}</label>
                        <div class="caja">
                          <kendo-switch formControlName="contarPreparacion" [onLabel]="' '" [offLabel]="' '">
                          </kendo-switch>
                        </div>
                      </div>
                    </div>
                    <!-- MANTENIMIENTO -->
                    <div class="col-md-4">
                      <div class="form-group form-check">
                        <label class="form-check-label">{{ 'mantenimiento' | translate}}</label>
                        <div class="caja">
                          <kendo-switch formControlName="contarMantenimiento" [onLabel]="' '" [offLabel]="' '">
                          </kendo-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <!-- ALARMA -->
                    <div class="col-md-4">
                      <div class="form-group form-check">
                        <label class="form-check-label">{{ 'alarma' | translate}}</label>
                        <div class="caja">
                          <kendo-switch formControlName="contarAlarma" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                        </div>
                      </div>
                    </div>
                    <!-- APAGADA -->
                    <div class="col-md-4">
                      <div class="form-group form-check">
                        <label class="form-check-label">{{ 'apagada' | translate}}</label>
                        <div class="caja">
                          <kendo-switch formControlName="contarApagada" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <!-- Tipo de acceso -->
          <div class="row">
            <div class="form-group col-md-6">
              <label class="control-label">{{ 'tipoAcceso' | translate }}</label>
              <kendo-buttongroup selection="single" width="100%">
                <button kendoButton type="button" [toggleable]="true" (click)="onClick(0)" iconClass="far fa-file"
                        title="modoArchivo" [selected]="idTipoAcceso === 0">
                  {{ 'archivo' | translate }}
                </button>
                <button kendoButton type="button" [toggleable]="true" (click)="onClick(1)" iconClass="fas fa-link"
                        title="modoLink" [selected]="idTipoAcceso === 1">
                  {{
                  'link' | translate
                  }}
                </button>
                <button kendoButton type="button" [toggleable]="true" (click)="onClick(2)" iconClass="fas fa-route"
                        title="modoRuta" [selected]="idTipoAcceso === 2">
                  {{
                  'ruta' | translate
                  }}
                </button>
              </kendo-buttongroup>
            </div>
          </div>
          <div class="row" *ngIf="idTipoAcceso == 2">
            <!--ES DIRECOTORIO-->
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label">{{ 'esDirectorio' | translate}}</label>
                <div class="checkbox">
                  <kendo-switch id="esDirectorio" formControlName="esDirectorio" [onLabel]="' '"
                                [offLabel]="' '"></kendo-switch>
                </div>
                <kendo-textbox class="mt-3" formControlName="nombreRuta" minlength="1" [ngClass]="{ 'is-invalid': submitted && rutaInvalida }"></kendo-textbox>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="idTipoAcceso == 1">
            <!--LINK-->
            <div class="col-md-4">
              <div class="form-group">
                <label ID="lAsignarPerdidaA" class="control-label">{{ 'link' | translate }}</label>
                <div class="caja">
                  <kendo-textbox formControlName="link" maxlength="100"
                                 [ngClass]="{ 'is-invalid': submitted && f.link.errors }"></kendo-textbox>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="idTipoAcceso == 0">
            <!--DOCUMENTO-->
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label">{{ 'documento' | translate }}</label>
                <kendo-textbox formControlName="pdf" [disabled]="true"
                               [style.display]="isUploaded ? 'none' : 'compact'"></kendo-textbox>
                <div class="caja">
                  <kendo-fileselect formControlName="archivo" [restrictions]="restrictions" [multiple]="false"
                                    (select)="selectEventHandler($event)" (remove)="removeEventHandler($event)">
                    <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                                           clearSelectedFiles="{{ 'clearSelectedFiles' | translate }}"
                                           dropFilesHere="{{ 'dropFilesHere' | translate }}"
                                           externalDropFilesHere="{{ 'externalDropFilesHere' | translate }}"
                                           fileStatusFailed="{{ 'fileStatusFailed' | translate }}"
                                           fileStatusUploaded="{{ 'fileStatusUploaded' | translate }}"
                                           filesBatchStatus="{{ 'filesBatchStatus' | translate }}"
                                           filesBatchStatusFailed="{{ 'filesBatchStatusFailed' | translate }}"
                                           filesBatchStatusUploaded="{{ 'filesBatchStatusUploaded' | translate }}"
                                           headerStatusPaused="{{ 'headerStatusPaused' | translate }}"
                                           headerStatusUploaded="{{ 'headerStatusUploaded' | translate }}"
                                           headerStatusUploading="{{ 'headerStatusUploading' | translate }}"
                                           invalidFileExtension="{{ 'invalidFileExtension' | translate }}"
                                           invalidMaxFileSize="{{ 'invalidMaxFileSize' | translate }}"
                                           invalidMinFileSize="{{ 'invalidMinFileSize' | translate }}" pause="{{ 'pause' | translate }}"
                                           remove="{{ 'remove' | translate }}" resume="{{ 'resume' | translate }}"
                                           retry="{{ 'retry' | translate }}" select="{{ 'select' | translate }}"
                                           uploadSelectedFiles="{{ 'uploadSelectedFiles' | translate }}">
                    </kendo-upload-messages>
                  </kendo-fileselect>
                </div>
              </div>
            </div>
          </div>
          <button type="button"
                  *ngIf="!isLink && !isUploaded && form.value.pdf!=null && form.value.pdf!=undefined && form.value.pdf!=''"
                  class="btn btn-primary" (click)=" btnBorrarDoc()" [disabled]="this.user.mantenimientosPredefinidos<2">
            {{
            'eliminardocmant' | translate
            }}
          </button>

          <div class="row">
            <!--TAREA MANTENIMIENTO-->
            <div class="col-md-6">
              <div class="form-group">
                <label ID="lAsignarPerdidaA" class="control-label">{{ 'tareamantenimiento' | translate }}</label>
                <div class="caja">
                  <textarea formControlName="tareamantenimiento" maxlength="280" kendoTextArea
                            [autoSize]="true"></textarea>
                </div>
              </div>
            </div>

          </div>
          <!--BOTONES-->
          <div class="form-group">
            <button class="btn btn-primary btn-sm mr-1" [disabled]="this.user.mantenimientosPredefinidos<2">
              {{ 'guardar' | translate}}
            </button>
            <button type="submit" class="btn btn-danger btn-sm mr-1" (click)="atras()">
              {{ 'cancelar' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
