<style>
  :host /deep/ .k-grid tbody td {
    white-space: nowrap;
    line-height: 20px;
    padding: 8px 12px;
  }

  :host /deep/ .k-grid .k-grid-content td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>

<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>
<div kendoTooltip
     showOn="none"
     [tooltipTemplate]="template"
     filter=".k-grid td"
     (mouseover)="showGridTooltip($event)">
  <!-- FILTRO -->
  <div>
    <div class="clearfix">

      <!-- Estimado / predictivo / Ineficiencias -->
      <div class="float-left mr-2">
        <div class="card">
          <div class="card-body">
            <div class="btn mr-1" [class.btn-success]="aplicarTiempoEstimado" [class.btn-outline-success]="!aplicarTiempoEstimado">
              <input type="radio" name="informesGroup" id="btnEstimado" class="k-radio" (click)="btnTiempoEstimado()" [checked]="aplicarTiempoEstimado" kendoRadioButton>
              <label class="k-radio-label" for="btnEstimado">{{ 'estimado' | translate }}</label>
            </div>
            <div class="btn mr-1" [class.btn-success]="!aplicarTiempoEstimado" [class.btn-outline-success]="aplicarTiempoEstimado">
              <input type="radio" name="informesGroup" id="btnPredictivo" class="k-radio" (click)="btnPredictivo()" [checked]="!aplicarTiempoEstimado" kendoRadioButton>
              <label class="k-radio-label" for="btnPredictivo">{{ 'predictivo' | translate }}</label>
            </div>
            <!-- Ineficiencias -->
            <button type="button" class="btn d-block mt-2 w-100" [class.btn-success]="aplicarIneficiencias" 
                    [class.btn-outline-success]="!aplicarIneficiencias" (click)="btnIneficiencia()" [disabled]="this.user.planificadorCorto<2">{{ 'ineficiencia' | translate }}</button>
          </div>
        </div>
      </div>
      <!-- <div class="float-left mr-2">
        <div class="card">
          <div class="card-body">
                    </div>
        </div>
      </div> -->
      <!-- ORIGINAL / VERSION DE SIMULADOR -->
      <div class="float-left mr-2">
        <div class="card">
          <div class="card-body">
            <label class="crontol-label float-left m-1">{{ 'version' | translate }}</label>
            <kendo-dropdownlist [data]="Jplanificadores"
                                [textField]="'text'"
                                [valueField]="'value'"
                                [(value)]="JplanificadoresSelected"
                                (valueChange)="versionChanged($event)"
                                style="width: 65px;">
            </kendo-dropdownlist>
            <button type="button" class="btn btn-success ml-1 mr-1" (click)="btnBorrarVersion()" [hidden]="!visibleUsarVersion" 
                    [disabled]="this.user.planificadorCorto<2"><i class="fas fa-trash"></i></button>
            <button type="button" class="btn btn-success mt-2 d-block w-100" (click)="btnCopiarAVersion()"
                    >{{ 'copiarAVersion' | translate }}</button>
          </div>
        </div>
      </div>

      <div class="float-left mr-2">
        <div class="card">
          <div class="card-body">
            <div class="clearfix">
              <!--LISTA OFS-->
              <div class="float-left mr-2">
                <!--<label>{{ 'of' | translate }}</label>-->
                <kendo-multiselect (valueChange)="CambioFiltro()"
                                   kendoMultiSelectSummaryTag
                                   [data]="listaOfs"
                                   [(ngModel)]="ofsSeleccionados"
                                   [textField]="'nombreOf'"
                                   [valueField]="'idOf'"
                                   placeholder="{{ 'seleccioneOf' | translate }}"
                                   [autoClose]="false"
                                   style="width: 200px;"
                                   [kendoDropDownFilter]="{operator: 'contains'}">
                  <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                    <span class="k-icon k-i-arrow-s"></span>
                    <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreOf}}</ng-container>
                    <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'ofsSeleccionadas' | translate }}</ng-container>
                  </ng-template>
                  <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                </kendo-multiselect>
              </div>
              <!--LISTA CLIENTES-->
              <div class="float-left mr-2">
                <!--<label>{{ 'cliente' | translate }}</label>-->
                <kendo-multiselect (valueChange)="CambioFiltro()"
                                   kendoMultiSelectSummaryTag
                                   [data]="listaClientes"
                                   [(ngModel)]="clientesSeleccionados"
                                   [textField]="'nombreCliente'"
                                   [valueField]="'idCliente'"
                                   placeholder="{{ 'seleccioneCliente' | translate }}"
                                   [autoClose]="false"
                                   style="width: 200px;"
                                   [kendoDropDownFilter]="{operator: 'contains'}">
                  <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                    <span class="k-icon k-i-arrow-s"></span>
                    <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreCliente }}</ng-container>
                    <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'clientesSeleccionados' | translate }}</ng-container>
                  </ng-template>
                  <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                </kendo-multiselect>
              </div>
              <!--LISTA PIEZAS-->
              <div class="float-left mr-2">

                <!--<label>{{ 'pieza' | translate }}</label>-->
                <div class="caja">
                  <kendo-multiselect (valueChange)="CambioFiltro()" kendoMultiSelectSummaryTag [data]="listaPiezas" [(ngModel)]="piezasSeleccionados" [textField]="'nombrePieza'" [valueField]="'idPieza'"
                                     placeholder="{{ 'seleccionePieza' | translate }}" [autoClose]="false" style="width: 270px;" [kendoDropDownFilter]="{operator: 'contains'}">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombrePieza }}</ng-container>
                      <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'piezasSeleccionados' | translate }}</ng-container>
                    </ng-template>
                    <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                  </kendo-multiselect>
                </div>
              </div>
            </div>

            <div class="clearfix mt-1">
              <!-- GRUPOS DE MAQUINAS -->
              <div class="float-left mr-2">
                <kendo-multiselect [data]="JgruposMaquinas"
                                   kendoMultiSelectSummaryTag
                                   [textField]="'nombre'"
                                   [valueField]="'id'"
                                   [(ngModel)]="JgruposMaquinasSelected"
                                   placeholder="{{ 'grupoMaquinas' | translate }}"
                                   [autoClose]="false"
                                   style="width: 200px;"
                                   [kendoDropDownFilter]="{operator: 'contains'}">
                  <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                    <span class="k-icon k-i-arrow-s"></span>
                    <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                    <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'maquinasSeleccionadas' | translate }}</ng-container>
                  </ng-template>
                </kendo-multiselect>
              </div>
              <!-- ÁREA PRODUCTIVA / SECCIÓN -->
              <div class="float-left mr-2">
                <kendo-multiselect kendoMultiSelectSummaryTag [(data)]="groupedSeccion"
                                   [textField]="'nombre'"
                                   [valueField]="'id'"
                                   [autoClose]="false"
                                   [(ngModel)]="seccionesSeleccionadas"
                                   (close)="seccionChanged()"
                                   style="width: 200px;"
                                   [kendoDropDownFilter]="{operator: 'contains'}">
                  <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                    <span class="k-icon k-i-arrow-s"></span>
                    <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                    <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seccionesSeleccionadas' | translate }}</ng-container>
                  </ng-template>
                  <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                </kendo-multiselect>
              </div>
              <button type="button" class="btn ml-1 mr-1 btn-primary float-right" (click)="btnFiltrar()">{{ 'filtrar' | translate }}</button>
            </div>
          </div>
        </div>
      </div>


      <div class="float-right mr-2">
        <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; gap:10px">

          <!-- BOTONES TOP -->
      
            <div class="" style="flex:1; text-align: center;">
              <div class="card  mb-1">
                <div class="card-body">

                  <div class="d-flex flex-row " style="gap:5px;">

                    <!-- MULTIROW -->
                    <button type="button" class="btn d-block w-100" [class.btn-success]="GANTT_multirrow" 
                    [class.btn-outline-success]="!GANTT_multirrow" (click)="GANTT_multirrow = !GANTT_multirrow; cargarGantt();">
                  
                    <svg class="icon-btn-planif"  width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.499756 0C0.223593 0 -0.000272508 0.223889 -0.000244138 0.500051L0.00016682 4.50005C0.000195188 4.77617 0.224045 5 0.500167 5H5.49981C4.67141 5 3.99985 4.32847 3.99981 3.50008L3.9997 1.50008C3.99966 0.67162 4.67125 0 5.4997 0H0.499756ZM5.49976 1C5.2236 1 4.99974 1.22387 4.99976 1.50003L4.99986 3.50003C4.99987 3.77616 5.22373 4 5.49986 4H15.4998C15.7759 4 15.9998 3.77614 15.9998 3.5L15.9998 1.5C15.9998 1.22386 15.7759 1 15.4998 1H5.49976ZM-0.000244138 11.7501C-0.000259397 11.3358 0.223603 11 0.499757 11L10.4998 11C10.7759 11 10.9998 11.3358 10.9998 11.75V13.25C10.9998 13.6642 10.7759 14 10.4998 14H0.499813C0.223681 14 -0.000173619 13.6642 -0.000188878 13.25L-0.000244138 11.7501ZM4.99976 15.7501C4.99974 15.3358 5.2236 15 5.49976 15L15.4998 15C15.7759 15 15.9998 15.3358 15.9998 15.75V17.25C15.9998 17.6642 15.7759 18 15.4998 18H5.49981C5.22368 18 4.99983 17.6642 4.99981 17.25L4.99976 15.7501ZM10.1717 7.35355C10.3669 7.15829 10.3669 6.84171 10.1717 6.64645C9.97641 6.45118 9.65982 6.45118 9.46456 6.64645L7.99993 8.11108L6.5353 6.64645C6.34004 6.45118 6.02346 6.45118 5.8282 6.64645C5.63294 6.84171 5.63294 7.15829 5.8282 7.35355L7.64638 9.17174C7.84164 9.367 8.15822 9.367 8.35349 9.17174L10.1717 7.35355Z" fill="#22c4c4"/>
                    </svg>
                  </button>       
                  <!-- MARCADORES -->
                  <button type="button" class="btn d-block w-100" [class.btn-success]="GANTT_marcadores" 
                    [class.btn-outline-success]="!GANTT_marcadores" (click)="GANTT_marcadores = !GANTT_marcadores; cargarGantt();">
                    <svg class="icon-btn-planif"  width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.70502e-10 7.50003C-7.13424e-06 7.22389 0.223852 7.00002 0.5 7.00002L15.8636 7C16.1398 7 16.3636 7.22386 16.3636 7.5L16.3636 12.8636C16.3636 13.1398 16.1398 13.3636 15.8636 13.3636L0.500139 13.3636C0.224001 13.3636 0.000145706 13.1398 0.000138572 12.8636L1.70502e-10 7.50003Z" fill="#22c4c4"/>
                      <path d="M0.181641 0.500015C0.181638 0.223872 0.405495 1.13563e-05 0.681639 1.04904e-05L3.97453 0C4.41998 -1.39677e-06 4.64307 0.538568 4.32809 0.853552L1.03523 4.14644C0.720249 4.46143 0.181678 4.23835 0.181674 3.7929L0.181641 0.500015Z" fill="#22c4c4"/>
                    </svg>
                    </button>       

                  </div>


                <!--   <button type="button" class="btn btn-success mr-1" (click)="btnLista()">{{ 'lista' | translate }}</button>
                 <button type="button" class="btn btn-success mr-3" (click)="btnVistaTemporal()">{{ 'vistaTemporal' | translate }}</button> -->
                </div>
              </div> 
            </div>

            <div  style="flex:1; text-align: center;">
              <div class="card  mb-1">
                <div class="card-body">
                  <button type="button" class="btn btn-secondary mr-1" (click)="btnReorganizar()">{{ 'reorganizar' | translate }}</button>
                </div>
              </div>
            </div>

            <div  style="flex:1; text-align: center;">
              <div class="card  mb-1">
                <div class="card-body">
                  <button type="button" class="btn btn-success" (click)="btnGuardar()"  [disabled]="this.user.planificadorCorto<2">{{ 'guardar' | translate }}</button>
                </div>
              </div>
            </div>


        </div>

        <div class="clearfix">
          <!--LEYENDA-->
          <div class="leyendas leyendas-top leyenda-corto position-relative">
            <div class="graf-barr-leyenda">
              <span class="colorleyenda ejecucion-en-marcha" style="background-color: #77DD77;"></span>
              <Label>{{ 'ejecucion' | translate }}</Label>
            </div>
            <!-- <div class="graf-barr-leyenda">
              <span class="colorleyenda" style="background-color: #77DD77;"></span>
              <Label>{{ 'ejecutado' | translate }}</Label>
            </div> -->
            <div class="graf-barr-leyenda">
              <span class="colorleyenda" style="background-color: #3289a8;"></span>
              <Label>{{ 'correcto' | translate }}</Label>
            </div>
            <div class="graf-barr-leyenda">
              <span class="colorleyenda" style="background-color: #ebdb34;"></span>
              <Label>{{ 'parcial' | translate }}</Label>
            </div>
            <div class="graf-barr-leyenda">
              <span class="colorleyenda" style="background-color: #eb8f34;"></span>
              <Label>{{ 'pendiente' | translate }}</Label>
            </div>
            <div class="graf-barr-leyenda">
              <span class="colorleyenda" style="background-color: #EA4335;"></span>
              <Label>{{ 'fueraPlazo' | translate }}</Label>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <!-- TODO -->
  <div>
    <!-- GANTT -->
    <div class="card">
      <div>
        <div id="gantt-chart" class="gantt-chart-planificadorcorto" style="height: 500px; margin-bottom: 7px; "></div>
      </div>
    </div>
    <!-- INFO: Operacion -->
    <div class="card" [hidden]="!visibleInfo">
      <div class="row">
        <!-- INFO-->
        <div class="col-sm-4 col-lg-3 col-xl-2" [hidden]="visibleInfoGrupo">
          <button type="button" class="btn btn-info mr-1" (click)="btnTraerDesdeLargo()">{{ 'traerDesdeLargo' | translate }}</button>
          <div class="bloques-info ">
            <label class="bloques-info-label">{{ 'of' | translate }}</label>
            <label class="bloques-info-valor">{{infoOF}}</label>
          </div>
          <div class="bloques-info ">
            <label class="bloques-info-label">{{ 'cliente' | translate }}</label>
            <label class="bloques-info-valor">{{infoCliente}}</label>
          </div>
          <div class="bloques-info ">
            <label class="bloques-info-label">{{ 'proyecto' | translate }}</label>
            <label class="bloques-info-valor">{{infoProyecto}}</label>
          </div>
          <div class="bloques-info ">
            <label class="bloques-info-label">{{ 'plano' | translate }}</label>
            <label class="bloques-info-valor">{{infoPlano}}</label>
          </div>
          <div class="bloques-info ">
            <label class="bloques-info-label">{{ 'refPieza' | translate }}</label>
            <label class="bloques-info-valor">{{infoRefPieza}}</label>
          </div>
          <div class="bloques-info ">
            <label class="bloques-info-label">{{ 'pieza' | translate }}</label>
            <label class="bloques-info-valor">{{infoPieza}}</label>
          </div>
          <div class="bloques-info ">
            <label class="bloques-info-label">{{ 'parte' | translate }}</label>
            <label class="bloques-info-valor">{{infoParte}}</label>
          </div>
        </div>
        <div class="col-sm-4 col-lg-3 col-xl-2" [hidden]="!visibleInfoGrupo">
          <button type="button" class="btn btn-info mr-1" (click)="btnTraerDesdeLargo()">{{ 'traerDesdeLargo' | translate }}</button>
          <div class="bloques-info ">
            <label class="bloques-info-label">{{ 'grupo' | translate }}</label>
            <label class="bloques-info-valor">{{nombreGrupo}}</label>
          </div>
          <div class="bloques-info ">
            <label class="bloques-info-label">{{ 'temperatura' | translate }}</label>
            <label class="bloques-info-valor">{{temperaturaGrupo}}</label>
          </div>
          <div class="bloques-info ">
            <label class="bloques-info-label">{{ 'tiempoEstimado' | translate }}</label>
            <label class="bloques-info-valor">{{tiempoEstimadoGrupo}}</label>
          </div>
          <div class="bloques-info ">
            <label class="bloques-info-label">{{ 'peso' | translate }}</label>
            <label class="bloques-info-valor">{{pesoGrupo}}</label>
          </div>
          <div class="bloques-info ">
            <label class="bloques-info-label">{{ 'unidades' | translate }}</label>
            <label class="bloques-info-valor">{{unidadesGrupo}}</label>
          </div>
        </div>
        <!-- ACCIONES -->
        <div class="col-sm-8 col-lg-9 col-xl-10">
          <div class="clearfix mt-1">
            <div class="float-left mr-1">
              <div class="card">
                <div class="clearfix">
                  <div class="m-1 float-left">
                    <label class="control-label m-1 float-left">{{ 'maquina' | translate }}</label>
                    <div class="caja float-left" style="width: 250px">
                      <kendo-combobox [data]="JmaquinasMostradas"
                                      [textField]="'nombre'"
                                      [valueField]="'id'"
                                      [(value)]="JmaquinaSelected"
                                      [class.requerido]="requiereMaquina"
                                      placeholder="{{ 'maquina' | translate }}"
                                      [kendoDropDownFilter]="{operator: 'contains'}">
                      </kendo-combobox>
                    </div>
                  </div>
                  <div class="m-1 float-left">
                    <button type="button" class="btn btn-success mr-1" (click)="btnMover()">{{ 'mover' | translate }}</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="float-left mr-1">
              <div class="card">
                <div class="clearfix">
                  <div class="m-1 float-left">
                    <label class="control-label m-1 float-left">{{ 'maquina' | translate }}</label>
                    <div class="caja float-left" style="width: 250px">
                      <kendo-combobox [data]="JmaquinasLargoMostradas"
                                      [textField]="'nombre'"
                                      [valueField]="'id'"
                                      [(value)]="JmaquinaSelectedLargo"
                                      [class.requerido]="requiereMaquinaLargo"
                                      placeholder="{{ 'maquina' | translate }}"
                                      [kendoDropDownFilter]="{operator: 'contains'}">
                      </kendo-combobox>
                    </div>
                  </div>
                  <div class="m-1 float-left">
                    <label class="control-label m-1 float-left">{{ 'semana' | translate }}</label>
                    <div class="caja float-left" style="width: 250px">
                      <kendo-combobox [data]="Jsemanas"
                                      [textField]="'text'"
                                      [valueField]="'value'"
                                      [(value)]="JsemanaSelected"
                                      [class.requerido]="requiereSemana"
                                      placeholder="{{ 'semana' | translate }}"
                                      [kendoDropDownFilter]="{operator: 'contains'}">
                      </kendo-combobox>
                    </div>
                  </div>
                  <div class="float-left">
                    <button type="button" class="btn btn-info m-1" (click)="btnMandarALargo()" [disabled]="this.user.planificadorCorto<2">{{ 'guardarYMandarALargo' | translate }}</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="float-right mr-1">
              <div class="card">
                <div class="clearfix">
                  <!-- <button type="button" class="btn btn-danger m-1" (click)="btnSacar()">{{ 'sacar' | translate }}</button> 
                    Hasta nuevo rediseño se ha dicho que este boton no tiene que existir.
                    La existencia del boton puede dar lugar a conflictos con el funcionamiento del algoritmo planificador.
                    Al sacar una operacion del planificador, a los 15 minutos, el algoritmo replanificara esta tarea y hay que buscar alguna forma 
                    de identificar que la operacion ha sido sacada a mano y este no deva tenerla en cuenta para replanificar.
                    En un futuro diseño, se definira correctamente y se tendra en cuenta esta opcion.
                  -->
                </div>
              </div>
            </div>
          </div>
          <!-- GRID -->
          <div class="clearfix">
            <kendo-grid [kendoGridBinding]="Joperaciones"
                        filterable="menu"
                        [sortable]="true"
                        kendoGridSelectBy="numFila"
                        filterable="menu"
                        [selectedKeys]="operacionesSelected"
                        scrollable="virtual"
                        [rowHeight]="37"
                        [navigable]="true"
                        [sortable]="true"
                        [height]="500"
                        [resizable]="true"
                        [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: true, mode: 'multiple'}">

              <!--checkbox-->
              <kendo-grid-checkbox-column  [hidden]="visibleInfoGrupo" width="3%" showSelectAll="true"></kendo-grid-checkbox-column>

              <!-- <kendo-grid-column field="ordenOperacion" title="{{ 'orden' | translate }}" width="10%" [style]="{'text-align': 'right'}"></kendo-grid-column> -->
              <kendo-grid-column field="secuencia" title="{{ 'secuencia' | translate }}" width="10%" [style]="{'text-align': 'right'}"></kendo-grid-column>
              <kendo-grid-column field="operacion" title="{{ 'operacion' | translate }}" width="60%"></kendo-grid-column>
              <!--<kendo-grid-column field="tiempoEstimadoHH" title="{{ 'tEstimado' | translate }}" width="10%"></kendo-grid-column>-->
              <!--{{dataItem.fechafin | kendoDate:'HH:mm:ss' }}-->
              <!-- En el largo en la misma ventana no se muestra <kendo-grid-column field="fechafin" title="{{ 'fechaentrega' | translate }}" width="10%">
                <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechafin | kendoDate }}</ng-template>
              </kendo-grid-column> -->

              <kendo-grid-column field="tiempoPreparacionHH" title="{{ 'tPreparacion' | translate }}" width="10%" [style]="{'text-align': 'right'}">
              </kendo-grid-column>
              <kendo-grid-column field="tiempoEstimadoHH" title="{{ 'tEstimado' | translate }}" width="10%" [style]="{'text-align': 'right'}">
              </kendo-grid-column>
              <kendo-grid-column field="dataItem" title="{{ 'tTotal' | translate }}" width="10%" [style]="{'text-align': 'right'}">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <ngcontainer [hidden]="dataItem.cantidadProv == 0">{{ secondsToHms(dataItem.tiempoPreparacion + dataItem.tiempoEstimado * dataItem.cantidadProv) }}</ngcontainer>
                  <ngcontainer [hidden]="dataItem.cantidadProv > 0"> {{ secondsToHms(dataItem.tiempoEstimado * dataItem.cantidadProv) }}</ngcontainer>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column field="total" title="{{ 'restantes' | translate }}" width="15%" class="p-0">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <kendo-numerictextbox  [(disabled)]="visibleInfoGrupo" [(value)]="dataItem.cantidadProv" [format]="'n'" [min]="0" [max]="dataItem.total" [autoCorrect]="true" [step]="dataItem.cantidadAgrupada" class="numerico-soloflechas">
                  </kendo-numerictextbox>
                </ng-template>
              </kendo-grid-column>
              
            <kendo-grid-column field="dataItem" width="7%" title="">
              <!-- <ng-template kendoGridHeaderTemplate let-column>
                <span class="tooltip-consumibles-contenido">
                  <label><i class="fas fa-info-circle mr-2"></i></label>
                  <span class="tooltipTextHeader">{{ 'irAOperacion' | translate }}</span>
                </span>
              </ng-template> -->
              <ng-template kendoGridCellTemplate let-dataItem>
                <a *ngIf="dataItem.idOperacionesGrupos <= 0" (click)="btnIrAOperacion(dataItem)"> <i class="icon-documento-alt"></i></a>               
              </ng-template>
            </kendo-grid-column>

              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>
        </div>
      </div>
    </div>
    <!-- LOADING PANEL -->
    <div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;">
      <div class="texto-loading-panel" *ngIf="secciones == undefined">
        <Label>{{ 'noTienesSeccionesAsignadas' | translate }}</Label>
      </div>
    </div>
  </div>
  <!-- INFO: General (grid inferior sin seleccion previa) -->
  <div class="card" [hidden]="visibleInfo">
    <kendo-tabstrip tabPosition="top" [keepTabContent]="true">
      <kendo-tabstrip-tab title="{{ 'largo' | translate}}" [selected]="true">
        <ng-template kendoTabContent>
          <div class="clearfix">
            <div class="float-left">
              <div class="clearfix">
                <div class="m-1 float-left">
                  <label class="control-label m-1 float-left">{{ 'maquina' | translate }}</label>
                  <div class="caja float-left" style="width: 300px">
                    <kendo-multiselect kendoMultiSelectSummaryTag
                                       [data]="JmaquinasLargoMostradas"
                                       [textField]="'nombre'"
                                       [valueField]="'id'"
                                       scrollable="virtual"
                                       [rowHeight]="37"
                                       [(ngModel)]="JmaquinasSelectedLargo"
                                       [class.requerido]="requiereMaquinaLargo"
                                       placeholder="{{ 'maquina' | translate }}"
                                       [autoClose]="false"
                                       [kendoDropDownFilter]="{operator: 'contains'}">
                      <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                        <span class="k-icon k-i-arrow-s"></span>
                        <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                        <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'maquinasSeleccionadas' | translate }}</ng-container>
                      </ng-template>
                    </kendo-multiselect>
                  </div>
                </div>
                <div class="m-1 float-left">
                  <label class="control-label m-1 float-left">{{ 'semana' | translate }}</label>
                  <div class="caja float-left" style="width: 300px">
                    <kendo-multiselect kendoMultiSelectSummaryTag
                                       [data]="Jsemanas"
                                       [textField]="'text'"
                                       [valueField]="'value'"
                                       [(ngModel)]="JsemanasSelected"
                                       [class.requerido]="requiereSemana"
                                       placeholder="{{ 'semana' | translate }}"
                                       [autoClose]="false"
                                       [kendoDropDownFilter]="{operator: 'contains'}">
                      <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                        <span class="k-icon k-i-arrow-s"></span>
                        <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].text }}</ng-container>
                        <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'semanasSeleccionadas' | translate }}</ng-container>
                      </ng-template>
                    </kendo-multiselect>
                  </div>
                </div>

                <div class="m-1 float-left">
                  <button type="button" class="btn btn-info" (click)="recargarGrid()">{{ 'filtrar' | translate }}</button>
                </div>
              </div>
            </div>
            <div class=" float-right">
              <div class="clearfix">
                <div class="m-1 float-left">
                  <button type="button" class="btn btn-success mr-1" (click)="btnLista()">{{ 'lista' | translate }}</button>
                </div>
                <div class="m-1 float-left">
                  <button kendoButton type="button" icon="file-excel" class="btn-excel " (click)="imprimirXls($event, gridLargo)">Excel</button> <!--(click)="imprimirXls($event, grid)"-->
                </div>
                <div class="m-1 float-left">
                  <button type="button" class="btn btn-info" (click)="btnMandarACorto()" [disabled]="this.user.planificadorCorto<2">{{ 'guardarYMandarACorto' | translate }}</button>
                </div>
              </div>
            </div>
          </div>
          <p>
            <kendo-grid [kendoGridBinding]="JOperacionesSinCorto"
                        filterable="menu"
                        [sortable]="true"
                        kendoGridSelectBy="idPlanificador"
                        [selectedKeys]="JOperacionesSinCortoSelecteds"
                        filterable="menu"
                        [navigable]="true"
                        [resizable]="true"
                        [pageable]="false"
                        scrollable="virtual"
                        [rowHeight]="37"
                        [height]="500"
                        [pageSize]="pageSize"
                        [skip]="skip"
                        (dataStateChange)="dataStateChange($event)"
                        (pageChange)="pageChange($event)"
                        [sortable]="true"
                        #gridLargo
                        [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: true, mode: 'multiple'}">
              <kendo-grid-excel fileName="Planificador Largo.xlsx" [fetchData]="allDataLargo"></kendo-grid-excel> 
              <kendo-grid-checkbox-column width="23">
                <ng-template kendoGridHeaderTemplate>
                  <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox
                         [state]="selectAllState"
                         (selectAllChange)="onSelectAllChangeGrid($event)">
                  <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
                </ng-template>
              </kendo-grid-checkbox-column>

              <kendo-grid-column field="refOF" title="{{ 'of' | translate }}" width="143"> </kendo-grid-column><!--15%-->
              <kendo-grid-column field="nombre" title="{{ 'referencia' | translate }}" width="361"> </kendo-grid-column><!--38%-->
              <kendo-grid-column field="operacion" title="{{ 'operacion' | translate }}" width="285"></kendo-grid-column><!--30%-->
              <kendo-grid-column field="secuencia" title="{{ 'secuencia' | translate }}" width="95" [style]="{'text-align': 'right'}"></kendo-grid-column><!--10%-->
          <kendo-grid-column field="maquina" title="{{ 'maquina' | translate }}" width="95"></kendo-grid-column><!--10%-->
          <!-- <kendo-grid-column field="orden" title="{{ 'orden' | translate }}" width="5%" [style]="{'text-align': 'right'}"></kendo-grid-column> ORDEN DE OPERACION-->
          <kendo-grid-column field="semana" title="{{ 'semana' | translate }}" width="143" [style]="{'text-align': 'right'}"><!--15%-->
            <ng-template kendoGridCellTemplate let-dataItem> ({{ dataItem.fechaIni | kendoDate }}) - {{dataItem.semana}} </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="fechaLimite" title="{{ 'fechaentrega' | translate }}" width="190"><!--20%-->
            <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechaLimite | kendoDate }}</ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="dataItem" title="{{ 'tTotal' | translate }}" width="67" [style]="{'text-align': 'right'}"><!--7%-->
            <ng-template kendoGridCellTemplate let-dataItem>
              <ngcontainer [hidden]="dataItem.cantidad == 0">{{ secondsToHms(dataItem.tiempoEstimadoPreparacions + dataItem.tiempoEstimados * dataItem.cantidad) }}</ngcontainer>
              <ngcontainer [hidden]="dataItem.cantidad > 0"> {{ secondsToHms(dataItem.tiempoEstimados * dataItem.cantidad) }}</ngcontainer>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="cantidad" title="{{ 'restantes' | translate }}" width="76"><!--8%-->
            <ng-template kendoGridCellTemplate let-dataItem>
              <kendo-numerictextbox [hidden]="dataItem.idOperacionesGrupos > 0" [(value)]="dataItem.cantidad" [format]="'n'" [min]="0" [max]="dataItem.total" [autoCorrect]="true" [step]="dataItem.cantidadAgrupada" class="numerico-soloflechas">
              </kendo-numerictextbox>
            </ng-template>
          </kendo-grid-column>
          
            <kendo-grid-column field="dataItem" width="76" title=""><!--7%-->
              <!-- <ng-template kendoGridHeaderTemplate let-column>
                <span class="tooltip-consumibles-contenido">
                  <label><i class="fas fa-info-circle mr-2"></i></label>
                  <span class="tooltipTextHeader">{{ 'irAOperacion' | translate }}</span>
                </span>
              </ng-template> -->
              <ng-template kendoGridCellTemplate let-dataItem>
                <a *ngIf="dataItem.idOperacionesGrupos <= 0" (click)="btnIrAOperacion(dataItem)"> <i class="icon-documento-alt"></i></a>               
              </ng-template>
            </kendo-grid-column>

              <!-- <kendo-grid-column field="refOF" title="{{ 'of' | translate }}" width="5%"></kendo-grid-column>
              <kendo-grid-column field="cliente" title="{{ 'cliente' | translate }}" width="10%"></kendo-grid-column>
              <kendo-grid-column field="pieza" title="{{ 'pieza' | translate }}" width="15%"></kendo-grid-column>
              <kendo-grid-column  [hidden]="ocultarParte == 1" field="parte" title="{{ 'parte' | translate }}" width="9%"></kendo-grid-column>
              <kendo-grid-column field="ordenOperacion" title="{{ 'orden' | translate }}" width="7%" [style]="{'text-align': 'right'}"></kendo-grid-column>
              <kendo-grid-column field="operacion" title="{{ 'operacion' | translate }}" width="17%"></kendo-grid-column>
              <kendo-grid-column field="maquina" title="{{ 'maquina' | translate }}" width="10%"></kendo-grid-column>
              <kendo-grid-column field="semana" title="{{ 'semana' | translate }}" width="10%"></kendo-grid-column>
              <kendo-grid-column field="fechafin" title="{{ 'fechaentrega' | translate }}" width="10%">
                <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechafin | kendoDate }} </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="tiempoPreparacionHH" title="{{ 'tPreparacion' | translate }}" width="7%" [style]="{'text-align': 'right'}">
              </kendo-grid-column>
              <kendo-grid-column field="tiempoEstimadoHH" title="{{ 'tEstimado' | translate }}" width="7%" [style]="{'text-align': 'right'}">
              </kendo-grid-column>
              <kendo-grid-column field="dataItem" title="{{ 'tTotal' | translate }}" width="7%" [style]="{'text-align': 'right'}">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <ngcontainer [hidden]="dataItem.cantidad == 0">{{ secondsToHms(dataItem.tiempoPreparacion + dataItem.tiempoEstimado * dataItem.cantidad) }}</ngcontainer>
                  <ngcontainer [hidden]="dataItem.cantidad > 0"> {{ secondsToHms(dataItem.tiempoEstimado * dataItem.cantidad) }}</ngcontainer>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="total" title="{{ 'restantes' | translate }}" width="8%" class="p-0">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <kendo-numerictextbox [(value)]="dataItem.cantidad" [format]="'n'" [min]="0" [max]="dataItem.total" [autoCorrect]="true" [step]="dataItem.cantidadAgrupada" class="numerico-soloflechas">
                  </kendo-numerictextbox>
                </ng-template>
              </kendo-grid-column> -->

              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </p>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab title="{{ 'corto' | translate}}">
        <ng-template kendoTabContent>
          <div class="clearfix">
            <div class="float-left">
              <div class="clearfix">
                <div class="m-1 float-left">
                  <label class="control-label m-1 float-left">{{ 'maquina' | translate }}</label>
                  <div class="caja float-left" style="width: 300px">
                    <kendo-multiselect kendoMultiSelectSummaryTag
                                       [data]="JmaquinasLargoMostradasCorto"
                                       [textField]="'nombre'"
                                       [valueField]="'id'"
                                       [(ngModel)]="JmaquinasSelectedCorto"
                                       [class.requerido]="requiereMaquinaLargo"
                                       placeholder="{{ 'maquina' | translate }}"
                                       [autoClose]="false"
                                       [kendoDropDownFilter]="{operator: 'contains'}">
                      <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                        <span class="k-icon k-i-arrow-s"></span>
                        <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                        <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'maquinasSeleccionadas' | translate }}</ng-container>
                      </ng-template>
                    </kendo-multiselect>
                  </div>
                </div>
                <div class="m-1 float-left">
                  <button type="button" class="btn btn-info" (click)="recargarGridCortoFiltrado()">{{ 'filtrar' | translate }}</button>
                </div>
              </div>
            </div>
            <div class="float-right">
              <div class="clearfix">
                <div class="m-1 float-left">
                  <button type="button" class="btn btn-success mr-1" (click)="btnLista()">{{ 'lista' | translate }}</button>
                </div>
                <div class="m-1 float-left">
                  <button kendoButton type="button" icon="file-excel" class="btn-excel " (click)="imprimirXls($event, gridCorto)">Excel</button> <!--(click)="imprimirXls($event, grid)"-->
                </div>
                <div class="m-1 float-left">
                  <button type="button" class="btn btn-info" (click)="btnMandarALargoDesdeTodos()" [disabled]="this.user.planificadorCorto<2">{{ 'guardarYMandarALargo' | translate }}</button>
                </div>
              </div>
            </div> 
          </div>
          <p>
            <kendo-grid [kendoGridBinding]="gridData.data"
                                    filterable="menu"
                                    [sortable]="true"
                                    kendoGridSelectBy="idPlanificador"
                                    [selectedKeys]="JOperacionesCortoSelecteds"
                                    [navigable]="true"
                                    [resizable]="true"
                                    [pageable]="false"
                                    scrollable="virtual"
                                    [rowHeight]="37"
                                    [height]="500"
                                    [pageSize]="pageSize"
                                    [skip]="skip"
                                    (dataStateChange)="clickGridCorto($event)"
                                    class="grid-plan-corto"
                                    #gridCorto
                                    [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: true, mode: 'multiple'}">
              <kendo-grid-excel fileName="Planificador Corto.xlsx" [fetchData]="allData"></kendo-grid-excel> 
              <kendo-grid-checkbox-column width="23"><!--3%-->
                <ng-template kendoGridHeaderTemplate>
                  <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox [state]="selectAllStateCorto" (selectAllChange)="onSelectAllChangeGridCorto($event)">
                  <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
                </ng-template>
              </kendo-grid-checkbox-column>

              <kendo-grid-column field="color" title="" width="27" [filterable]="false"><!--3%-->
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="cuadrado-color-grid" [style.background-color]="dataItem.color"></span>
                </ng-template>
              </kendo-grid-column>
<!-- 
              <kendo-grid-column field="refOF" title="{{ 'of' | translate }}" width="10%"></kendo-grid-column>
              <kendo-grid-column field="cliente" title="{{ 'cliente' | translate }}" width="10%"></kendo-grid-column>
              <kendo-grid-column field="pieza" title="{{ 'pieza' | translate }}" width="8%"></kendo-grid-column>
              <kendo-grid-column  [hidden]="ocultarParte == 1" field="parte" title="{{ 'parte' | translate }}" width="9%"></kendo-grid-column> -->

              <kendo-grid-column field="refOF" title="{{ 'of' | translate }}" width="143"> </kendo-grid-column>
              <kendo-grid-column field="nombre" title="{{ 'referencia' | translate }}" width="350"> </kendo-grid-column>
              <kendo-grid-column field="operacion" title="{{ 'operacion' | translate }}" width="275"></kendo-grid-column>
              <kendo-grid-column field="secuencia" title="{{ 'secuencia' | translate }}" width="90" [style]="{'text-align': 'right'}"></kendo-grid-column>

              <kendo-grid-column field="maquina" title="{{ 'maquina' | translate }}" width="65"></kendo-grid-column>
              <kendo-grid-column field="ordenPlanificado" title="{{ 'orden' | translate }}" width="40" [style]="{'text-align': 'right'}"></kendo-grid-column><!--5%-->
              
              <kendo-grid-column field="fechaPlanificadoInicio" title="{{ 'fechainicio' | translate }}" width="100">
                <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechaPlanificadoInicio | kendoDate }} {{dataItem.fechaPlanificadoInicio | kendoDate:'HH:mm:ss' }} </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="fechaPlanificadoFin" title="{{ 'fechafin' | translate }}" width="100"><!--11%-->
                <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechaPlanificadoFin | kendoDate }} {{dataItem.fechaPlanificadoFin | kendoDate:'HH:mm:ss' }} </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="fechafin" title="{{ 'fechaentrega' | translate }}" width="70">
                <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechafin | kendoDate }} </ng-template>
              </kendo-grid-column>

              <kendo-grid-column field="dataItem" title="{{ 'tTotal' | translate }}" width="60" [style]="{'text-align': 'right'}">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <ngcontainer [hidden]="dataItem.cantidadProv == 0">{{ secondsToHms(dataItem.tiempoPreparacion + dataItem.tiempoEstimado * dataItem.cantidadProv) }}</ngcontainer>
                  <ngcontainer [hidden]="dataItem.cantidadProv > 0"> {{ secondsToHms(dataItem.tiempoEstimado * dataItem.cantidadProv) }}</ngcontainer>
                </ng-template>
              </kendo-grid-column>
                          
              <kendo-grid-column field="tieneProgramaText" title="{{ 'programa' | translate }}" width="70" [style]="{'text-align': 'center'}" [headerStyle]="{'text-align': 'center'}">
                <ng-template kendoGridHeaderTemplate let-column>
                  <label> <span class="tooltip-consumibles-contenido"><i class="fas fa-info-circle mr-2"></i><span [attr.contenido]="contenidoTienePrograma" class="tooltipTextHeader"></span></span> {{ 'programa' | translate }} </label>
                </ng-template>                
                <ng-template kendoGridCellTemplate let-dataItem>
                  <input type="checkbox" [(ngModel)]="dataItem.tienePrograma" kendoCheckBox disabled />
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column field="total" title="{{ 'restantes' | translate }}" width="67" class="p-0">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <kendo-numerictextbox [hidden]="dataItem.idOperacionesGrupos > 0" [(value)]="dataItem.cantidadProv" [format]="'n'" [min]="0" [max]="dataItem.total" [autoCorrect]="true" [step]="dataItem.cantidadAgrupada" class="numerico-soloflechas">
                  </kendo-numerictextbox>
                </ng-template>
              </kendo-grid-column>  

              <kendo-grid-column field="dataItem" width="67" title="">
                <!-- <ng-template kendoGridHeaderTemplate let-column>
                  <span class="tooltip-consumibles-contenido">
                    <label><i class="fas fa-info-circle mr-2"></i></label>
                    <span class="tooltipTextHeader">{{ 'irAOperacion' | translate }}</span>
                  </span>
                </ng-template> -->
                <ng-template kendoGridCellTemplate let-dataItem>
                  <a *ngIf="dataItem.idOperacionesGrupos <= 0" (click)="btnIrAOperacion(dataItem)"> <i class="icon-documento-alt"></i></a>               
                </ng-template>
              </kendo-grid-column>
              
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </p>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>

  <!-- POPUP: Reorganizar -->
  <ng-template #popupReorganizar let-modal>
    <div class="modal-header">
      <h3 class="modal-title" id="modal-basic-title">
        <label>{{ 'prioridades' | translate }}</label>
      </h3>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <!-- ROW 1 -->
      <div class="clearfix">
        <div class="form-group">
          <label class="label-color1">{{ 'prioridadCliente' | translate }}</label>
          <div class="caja">
            <!--<kendo-numerictextbox [(value)]="prioridadCliente" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>-->
            <kendo-slider [vertical]="false" [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" [(ngModel)]="prioridadCliente" class="w-100">
              <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
            </kendo-slider>
          </div>
        </div>
      </div>
      <!-- ROW 2 -->
      <div class="clearfix">
        <div class="form-group">
          <label class="label-color1">{{ 'prioridadFacturacion' | translate }}</label>
          <div class="caja">
            <!--<kendo-numerictextbox [(value)]="prioridadFacturacion" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>-->
            <!--<p>{{ prioridadFacturacion }}</p>-->
            <kendo-slider [vertical]="false" [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" [(ngModel)]="prioridadFacturacion" class="w-100">
              <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
            </kendo-slider>
          </div>
        </div>
      </div>
      <!-- ROW 3 -->
      <div class="clearfix">
        <div class="form-group">
          <label class="label-color1">{{ 'prioridadOF' | translate }}</label>
          <div class="caja">
            <!--<kendo-numerictextbox [(value)]="prioridadOF" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>-->
            <kendo-slider [vertical]="false" [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" [(ngModel)]="prioridadOF" class="w-100">
              <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
            </kendo-slider>
          </div>
        </div>
      </div>
      <!-- ROW 4 -->
      <div class="clearfix">
        <div class="form-group">
          <label class="label-color1">{{ 'prioridadMaquina' | translate }}</label>
          <div class="caja">
            <!--<kendo-numerictextbox [(value)]="prioridadMaquina" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>-->
            <kendo-slider [vertical]="false" [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" [(ngModel)]="prioridadMaquina" class="w-100">
              <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
            </kendo-slider>
          </div>
        </div>
      </div>
      <!-- ROW 5 -->
      <div class="clearfix">
        <div class="form-group">
          <label class="label-color1">{{ 'prioridadFecha' | translate }}</label>
          <div class="caja">
            <!--<kendo-numerictextbox [(value)]="prioridadFecha" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>-->
            <kendo-slider [vertical]="false" [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" [(ngModel)]="prioridadFecha" class="w-100">
              <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
            </kendo-slider>
          </div>
        </div>
      </div>
      <!-- ROW 6 -->
    <div class="clearfix">
      <div class="form-group">
        <label class="label-color1">{{ 'prioridadTemperatura' | translate }}</label>
        <div class="caja">
          <!--<kendo-numerictextbox [(value)]="prioridadTemperatura" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>-->
          <kendo-slider [vertical]="false" [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" [(ngModel)]="prioridadTemperatura" class="w-100">
            <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
          </kendo-slider>
        </div>
      </div>
    </div>
    <!-- ROW 7 -->
      <div class="clearfix">
        <div class="form-group">
          <label class="label-color1">{{ 'percent_cap_maquina' | translate }}</label>
          <div class="caja">
            <!--<kendo-numerictextbox [(value)]="percent_cap_maquina" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>-->
            <kendo-slider [vertical]="false" [showButtons]="false" [min]="0" [max]="100" [smallStep]="10" [largeStep]="1" [(ngModel)]="percent_cap_maquina" class="w-100">
              <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
            </kendo-slider>
          </div>
        </div>
      </div>
      <!-- ROW 8 -->
      <div class="clearfix">
        <div class="form-group">
          <label class="label-color1">{{ 'dias_reserva' | translate }}</label>
          <div class="caja">
            <kendo-numerictextbox [(value)]="dias_reserva" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>
            <div class="btn mr-1 ml-1" [class.btn-success]="replanificarTiempoEstimado" [class.btn-outline-success]="!replanificarTiempoEstimado">
              <input type="radio" name="informesGroup2" class="k-radio" [checked]="replanificarTiempoEstimado" (click)="btnReorganizarTiempoEstimado()" kendoRadioButton>
              <label class="k-radio-label" for="btnReorganizarTiempoEstimado">{{ 'estimado' | translate }}</label>
            </div>
            <div class="btn mr-1" [class.btn-success]="!replanificarTiempoEstimado" [class.btn-outline-success]="replanificarTiempoEstimado">
              <input type="radio" name="informesGroup2" class="k-radio" [checked]="!replanificarTiempoEstimado" (click)="btnReorganizarTiempoPredictivo()" kendoRadioButton>
              <label class="k-radio-label" for="btnReorganizarTiempoPredictivo">{{ 'predictivo' | translate }}</label>
            </div>
            <button type="button" class="btn mr-1" [class.btn-success]="replanificarIneficiencias" 
               [class.btn-outline-success]="!replanificarIneficiencias" (click)="btnReorganizarIneficiencia()">{{ 'ineficiencia' | translate }}</button>
          </div>
        </div>
      </div>
      <!-- ROW 9 -->
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label class="label-color1">{{ 'optimizarCuelloBotella' | translate }}</label>
          <div class="caja">
            <kendo-switch [(ngModel)]="optimizarCuelloBotella" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
          </div>
        </div>
      </div>
        <div class="col">
          <div class="form-group">
          <label class="control-label">{{ 'metodologiasDePlanificacion' | translate }}</label>
          <div class="caja">
            <kendo-dropdownlist [data]="metodologiasDePlanificacion"
                                [textField]="'nombre'"
                                [valueField]="'id'"
                                [(value)]="metodologiasDePlanificacionSelected"
                                style="width: 100px">
            </kendo-dropdownlist>
          </div>
        </div>
      </div>
    </div>
    <!-- ROW 10 -->  
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label class="label-color1">{{ 'planificarSoloNoPlanificadas' | translate }}</label>
          <div class="caja">
            <kendo-switch [(ngModel)]="planificarSoloNoPlanificadas" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group" *ngIf="metodologiasDePlanificacionSelected.id == 1">
          <label class="label-color1">{{ 'asap_maximoMesesParaIniciarAntesDeFechaEntrega' | translate }}</label>
          <div class="caja">
            <kendo-numerictextbox [(value)]="asap_maximoMesesParaIniciarAntesDeFechaEntrega" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
      <button type="button" class="btn btn-primary" (click)="btnReorganizarAceptar()" [disabled]="this.user.planificadorCorto<2">{{ 'aceptar' | translate }}</button>
    </div>
  </ng-template>

  <!-- POPUP: Usar planning -->
  <ng-template #popupUsarVersion let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"></h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <label>{{'estasSeguroUsarVersion' | translate }}</label>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
      <button type="button" class="btn btn-primary" (click)="btnUsarVersionAceptar()" [disabled]="this.user.planificadorCorto<2">{{ 'aceptar' | translate }}</button>
    </div>
  </ng-template>

  <!-- POPUP: Borrar planning -->
  <ng-template #popupBorrarVersion let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"></h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <label>{{'borrarVersion' | translate }}</label>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
      <button type="button" class="btn btn-primary" (click)="btnBorrarVersionAceptar()" [disabled]="this.user.planificadorCorto<2">{{ 'aceptar' | translate }}</button>
    </div>
  </ng-template>

  <!-- POPUP: Copiar planning a version -->
  <ng-template #popupCopiarAVersion let-modal>
    <div class="modal-header">
      <h4>   <label>{{'aQueVersion' | translate }}</label> </h4>

      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      
            <div class="float-left">
              <label class="crontol-label float-left m-1">{{ 'planificacion' | translate }}</label>
              <!-- <div class="caja float-left" style="width: 150px"> -->
                <kendo-combobox [data]="JplanificadoresSinOriginal"
                                [textField]="'text'"
                                [valueField]="'value'"
                                [(value)]="JplanificadoresSelectedCopiar"
                                [kendoDropDownFilter]="{operator: 'contains'}">
                </kendo-combobox>

        <!-- </div> -->
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
      <button type="button" class="btn btn-primary" (click)="btnCopiarAVersionAceptar()" [disabled]="this.user.planificadorCorto<2"> {{ 'aceptar' | translate }}</button>
    </div>
  </ng-template>


  



  <!-- POPUP: Copiar planning a version -->
  <ng-template #popupVistaTemporal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"></h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body gantt-popup">
      <div class="card">


        <div class="card-body">









          <!--<label>{{'vistaTemporal' | translate }}</label>-->

          <div class="clearfix">

            <div id="gantt-chart-temporal" class="gantt-chart-planificadorcorto"></div>
            <!--LEYENDA-->
            <div class="leyendas leyendas-informe-potencia">
              <div class="graf-barr-leyenda">
                <span class="colorleyenda" style="background-color: #3289a8;"></span>
                <Label>{{ 'todoVaBien' | translate }}</Label>
              </div>
              <div class="graf-barr-leyenda">
                <span class="colorleyenda" style="background-color: #ebdb34;"></span>
                <Label>{{ 'sePuedeEmpezarPeroNoacabar' | translate }}</Label>
              </div>
              <div class="graf-barr-leyenda">
                <span class="colorleyenda" style="background-color: #eb8f34;"></span>
                <Label>{{ 'noSePodraEmpezar' | translate }}</Label>
              </div>
              <div class="graf-barr-leyenda">
                <span class="colorleyenda" style="background-color: #77DD77;"></span>
                <Label>{{ 'eProceso' | translate }}</Label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
    </div>
  </ng-template>

  <!-- POPUP: Reorganizando version -->
  <div class="popup-cont " *ngIf="popupReorganizandoVersion">
    <div class="popup " style="width: 215px;">
      <div class="popup-header">
        <h3>
          <label>{{'reorganizandoVersion' | translate }}</label>
        </h3>
      </div>
      <div class="popup-body">
        <div class="clearfix text-center">
          <div class="position-relative" style="height:100px">
            <div class="k-i-loading" style="z-index: 3;"></div>
          </div>
        </div>
        <div>
          <label class="crontol-label m-1">{{ 'version' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="Jplanificadores"
                            [textField]="'text'"
                            [valueField]="'value'"
                            [(value)]="JplanificadoresSelected"
                            (valueChange)="versionChanged($event)"
                            style="width: 100px"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
      <div class="popup-footer text-rigth">
        <button type="button" class="btn btn-primary" (click)="btnActualizarReorganizandoVersion()" 
                [disabled]="this.user.planificadorCorto<2">{{ 'actualizar' | translate }}</button>
      </div>
    </div>
  </div>

  <!-- POPUP: mandar a largo -->
  <ng-template #popupMandarALargo let-modal>
    <div class="modal-header">
      <h3 class="modal-title" id="modal-basic-title">
        <label>{{'mandarAlargo' | translate }}</label>
      </h3>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body gantt-popup">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <label class="control-label m-1 float-left">{{ 'semana' | translate }}</label>
            <div class="caja float-left" style="width: 250px">
              <kendo-dropdownlist [data]="Jsemanas"
                                  [textField]="'text'"
                                  [valueField]="'value'"
                                  [(value)]="JsemanaSelectedMandarALargo"
                                  [class.requerido]="requiereSemanaMandarALargo"
                                  placeholder="{{ 'semana' | translate }}">
              </kendo-dropdownlist>
            </div>
          </div>
          <div class="mt-2 row">
            <label class="crontol-label float-left m-1">{{ 'reorganizar' | translate }}</label>
            <div class="caja">
              <kendo-switch [onLabel]="' '" [offLabel]="' '" [(ngModel)]="replanificarMandarALargo"> </kendo-switch>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
      <button type="button" class="btn btn-primary" (click)="btnpopupMandarALargoAceptar()" [disabled]="this.user.planificadorCorto<2">{{ 'aceptar' | translate }}</button>
    </div>    
    <!-- LOADING PANEL -->
    <div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;">
    </div>
  </ng-template>

</div>
