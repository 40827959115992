<style>
  .reldiv1 {
    height: 0px;
    width: 0px;
    position: relative;
  }

  :host /deep/ .c3-drag-zoom {
    transform: translate(124.5px, 4.5px) !important;
  }

  /*GRAFICOS NUEVOS*/

  /* :host /deep/ #grafico2_hmiIny .c3-axis-x {
    display: none;
  }

  :host /deep/ #grafico3_hmiIny .c3-axis-x {
    display: none;
  }

  :host /deep/ #grafico4_hmiIny .c3-axis-x {
    display: none;
  } */

  /* :host /deep/ #grafico5_hmiIny .c3-axis-x {
    display: none;
  }

  :host /deep/ #grafico6_hmiIny .c3-axis-x {
    display: none;
  }

  :host /deep/ #grafico7_hmiIny .c3-axis-x {
    display: none;
  } */

  /* :host /deep/ #grafico1_hmiIny .c3-axis-y {
    transform: translate(0px, 0px);
  }

  :host /deep/ #grafico2_hmiIny .c3-axis-y {
    transform: translate(-32px, 0px);
  }

  :host /deep/ #grafico3_hmiIny .c3-axis-y {
    transform: translate(-64px, 0px);
  }

  :host /deep/ #grafico4_hmiIny .c3-axis-y {
    transform: translate(-96px, 0px);
  } */

  /* :host /deep/ #grafico5_hmiIny .c3-axis-y {
    transform: translate(-128px,0px);
  }

  :host /deep/ #grafico6_hmiIny .c3-axis-y {
    transform: translate(-160px,0px);
  }

  :host /deep/ #grafico7_hmiIny .c3-axis-y {
    transform: translate(-192px,0px);
  } */

  :host /deep/ #grafico1_hmiIny .c3-region.regionTiempoInyeccion {
    fill: #ac5cad;
  }

  :host /deep/ #grafico1_hmiIny .c3-region.regionTiempoPresionRemanente {
    fill: #57a9aa;
  }

  :host /deep/ #grafico1_hmiIny .c3-region.regionTiempoPlastificacion {
    fill: #adadad;
  }

  :host /deep/ #subgrafico_hmiIny .c3-axis-y {
    display: none;
  }

  :host /deep/ .c3-area-data1_color {
    opacity: 1 !important;
  }

  :host /deep/ .c3-area-data2_color {
    opacity: 1 !important;
  }

  :host /deep/ .c3-area-data3_color {
    opacity: 1 !important;
  }

  :host /deep/ .c3-area-data4_color {
    opacity: 1 !important;
  }

  :host /deep/ .c3-area-data5_color {
    opacity: 1 !important;
  }

  :host /deep/ .c3-line-data1_blanco {
    stroke-width: 0px !important;
  }

  :host /deep/ .c3-line-data2_color {
    stroke-width: 0px !important;
  }

  :host /deep/ .c3-line-data2_blanco {
    stroke-width: 0px !important;
  }

  :host /deep/ .c3-line-data3_color {
    stroke-width: 0px !important;
  }

  :host /deep/ .c3-line-data3_blanco {
    stroke-width: 0px !important;
  }

  :host /deep/ .c3-line-data4_color {
    stroke-width: 0px !important;
  }

  :host /deep/ .c3-line-data4_blanco {
    stroke-width: 0px !important;
  }

  :host /deep/ .c3-line-data5_color {
    stroke-width: 0px !important;
  }

  :host /deep/ .c3-line-data5_blanco {
    stroke-width: 0px !important;
  }

  /*FIN GRAFICOS NUEVOS*/
</style>


<!--PANEL PRINCIPAL-->

<div class="row hmi-inyectora-cont">
  <div class=" col-sm-12 col-md-12 cont-procesos">
    <div class="" id="panelTop">
      <div class="procesos-header-cont">
        <div ID="maquinaentera" Class="maquina-cont">
          <div class="procesos-header row">
            <!-- PANEL HOME -->
            <hmi-panel class="hmi-panel col-4" [idMaquina]="idmaquina"></hmi-panel>

            <!-- <div class="row hmi-inyectora-cont"> -->

            <!-- CENTRO -->
            <div class="col-md-4">
              <div class="row">

                <div class="col-lg-12" style="height: 380px;">
                  <div class="panel-procesos panel-con-resumen-semana">
                    <div class="" style=" height: 369px; padding: 0;">
                      <div class="">

                        <!--DATOS-->
                        <div class="datos-of">

                          <div class="pdatos-of-inner">
                            <label class="titulo-seccion-maquina">{{ 'datos' | translate }}</label>

                            <div class="datos-of-content">
                              <ul style="margin-bottom: 0;">
                                <li>
                                  <label class="" style="min-width: 20%;">{{ 'of' | translate }}</label>
                                  <label class="" data-toggle="tooltip" data-placement="bottom" title="{{of}}"> {{of}}
                                  </label>
                                </li>
                                <li *ngIf="cliente != ''">
                                  <label class="" style="min-width: 20%;">{{ 'cliente' | translate }}</label>
                                  <label class="" data-toggle="tooltip" data-placement="bottom" title="{{cliente}}">
                                    {{cliente}}
                                  </label>
                                </li>
                                <li *ngIf="nombrePieza != ''">
                                  <label class="" style="min-width: 20%;">{{ 'denomPieza' | translate }}</label>
                                  <label class="" data-toggle="tooltip" data-placement="bottom" title="{{nombrePieza}}">
                                    {{nombrePieza}} </label>
                                </li>
                                <li *ngIf="referenciaPieza != ''">
                                  <label class="" style="min-width: 20%;">{{ 'refpieza' | translate }}</label>
                                  <label class="" data-toggle="tooltip" data-placement="bottom"
                                    title="{{referenciaPieza}}">
                                    {{referenciaPieza}} </label>
                                </li>
                                <li *ngIf="material != ''">
                                  <label class="" style="min-width: 20%;">{{ 'material' | translate }}</label>
                                  <label class="" data-toggle="tooltip" data-placement="bottom" title="{{material}}">
                                    {{material}}
                                  </label>
                                </li>
                                <li *ngIf="refMaterial != ''">
                                  <label class="" style="min-width: 20%;">{{ 'refMaterial' | translate }}</label>
                                  <label class="" data-toggle="tooltip" data-placement="bottom" title="{{refMaterial}}">
                                    {{refMaterial}} </label>
                                </li>
                                <li *ngIf="nCavidades != ''">
                                  <label class="" style="min-width: 20%;">{{ 'nCavidades' | translate }}</label>
                                  <label class="" data-toggle="tooltip" data-placement="bottom" title="{{nCavidades}}">
                                    {{nCavidades}} </label>
                                </li>
                                <li *ngIf="refMolde != ''">
                                  <label class="" style="min-width: 20%;">{{ 'refMolde' | translate }}</label>
                                  <label class="" data-toggle="tooltip" data-placement="bottom" title="{{refMolde}}">
                                    {{refMolde}}
                                  </label>
                                </li>
                                <li *ngIf="realizadas != ''">
                                  <label class="" style="min-width: 20%;">{{ 'nPiezas' | translate }}</label>
                                  <label class="" data-toggle="tooltip" data-placement="bottom" title="{{realizadas}}">
                                    {{realizadas}} </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                      </div>
                      <!--RESUMEN SEMANA-->
                      <div id="resumenSemanaGrafico_hmiIny" style="height: 100px; cursor: pointer;"
                        [routerLink]="['/informerendimiento/'+idmaquina]">

                      </div>


                    </div>
                  </div>
                </div>

              </div>
            </div>
            <!-- DERECHA -->
            <div class="col-md-4">
              <div class="row">

                <!--CICLOS-->
                <div class="col-lg-12" style="height: 380px;">
                  <div class="panel-procesos">
                          <!--LINEA TITULO-->
                          <div class="datos-of-content titulos-datos-cont">
                            <ul style="margin-bottom: 0;">
                              <li>
                                <label class="label-datos-valor-home" style="width: 20%;">{{ 'OF' | translate }}/{{ 'pieza' | translate }}</label>
                                <label class="label-datos-valor-home" style="width: 15%;">{{ 'nCiclo' | translate
                                  }}</label>
                                <label class="label-datos-valor-home" style="width: 15%;">{{ 'nPiezas' | translate
                                  }}</label>
                                <label class="label-datos-valor-home" style="width: 20%;">{{ 'tiempoCiclo' |
                                  translate
                                  }}</label>
                                <label class="label-datos-valor-home" style="width: 20%;">{{ 'hora' | translate
                                  }}</label>
                                <label class="label-datos-valor-home" style="width: 10%;"></label>
                              </li>
                            </ul>
                          </div>
                    <div class="contenido-panel-procesos" style=" height: 369px; padding: 0;">
                      <div class="datos-of">

                        <div class="pdatos-of-inner">

                          <!--LISTA-->
                          <div class="datos-of-content">
                            <ul style="margin-bottom: 0;">
                              <li class="linea-ciclos-inyectora" *ngFor="let ciclo of listaCiclos"
                                [ngClass]="{'ciclo-seleccionado': ciclo.seleccionado ? true : false}"
                                (click)="clickCiclo(ciclo)">
                                <label class="label-datos-valor-home" style="width: 20%;">{{ ciclo.numeroOF }}/{{ ciclo.nombrePieza }}</label>
                                <label class="label-datos-valor-home" style="width: 15%;">{{ciclo.nciclo}}</label>
                                <label class="label-datos-valor-home" style="width: 15%;">{{ciclo.piezas}}</label>
                                <label *ngIf="ciclo.tiempoInyeccionCiclo>=0" class="label-datos-valor-home"
                                  style="width: 20%;">{{myFunctions.secondsTo_HH_MM_SS(ciclo.tiempoInyeccionCiclo)}}</label>
                                <label *ngIf="ciclo.tiempoInyeccionCiclo<0" class="label-datos-valor-home"
                                    style="width: 20%;"> </label>
                                <label class="label-datos-valor-home"
                                  style="width: 20%;">{{myFunctions.dateToHHMMSS(ciclo.fechaini)}}</label>
                                <label class="label-datos-valor-home"
                                  style="width: 10%;"><i class="icon-equis" *ngIf="ciclo.lineaRoja"></i></label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>





</div>





<!--PESTANAS-->
<div class="row">
  <div class="col">

    <kendo-tabstrip [keepTabContent]="true">

      <!--DATOS-->
      <kendo-tabstrip-tab title="{{ 'datos' | translate}}" [selected]="true">
        <ng-template kendoTabContent>
          <div class="row" style="margin-left: -10px; margin-right: -10px;">

            <!--VALORES TECNOLOGICOS-->
            <div class="col-md-3 contenedor-valores-tecnologicos-inyectora">
              <div class="datos-of">

                <div class="pdatos-of-inner">

                  <!--LINEA TITULO-->
                  <div class="datos-of-content  titulos-datos-cont" *ngIf="maquina.tipoMaquinaInyeccion == 2" >
                    <ul style="margin-bottom: 0;">
                      <li>
                        <label class="label-datos-valor-home" style="width: 40%;">{{ 'valoresTecnologicos' | translate}}</label>
                        <label class="label-datos-valor-home" style="width: 12%;">{{ 'dim' | translate}}</label>
                        <label class="label-datos-valor-home" style="width: 12%;">{{ 'toler' | translate}}</label>
                        <label class="label-datos-valor-home" style="width: 18%;">{{ 'programado' | translate}}</label>
                        <label class="label-datos-valor-home" style="width: 18%;">{{ 'actual' | translate}}</label>
                      </li>
                    </ul>
                  </div>

                  <!--LISTA-->
                  <div class="datos-of-content" *ngIf="maquina.tipoMaquinaInyeccion == 2" >
                    <ul style="margin-bottom: 0;">
                      <div *ngFor="let valor of listaValoresTecnologicos; let i = index"
                        [ngClass]="{'ciclo-rojo': valor.lineaRoja}">
                        <li class="linea-ciclos-inyectora" *ngIf="listaVariablesVisibles[i] == true">
                          <label class="label-datos-valor-home" style="width: 40%;">{{valor.nombre}}</label>
                          <label class="label-datos-valor-home" style="width: 12%;">{{valor.dim}}</label>
                          <label class="label-datos-valor-home" style="width: 12%;">±{{valor.toler}}</label>
                          <label class="label-datos-valor-home" style="width: 18%;">{{valor.programado}}</label>
                          <label class="label-datos-valor-home" style="width: 18%;">{{valor.actual}}</label>
                        </li>
                      </div>
                    </ul>
                  </div>

                  <!-- TABLA -->
                  <kendo-grid *ngIf="maquina.tipoMaquinaInyeccion == 1" [data]="listaValoresTecnologicos" class="grid-hornos-temple" [height]="400"
                    [selectedKeys]="listaTooltipSeleccionados" kendoGridSelectBy="index"
                    [selectable]="{ checkboxOnly: true, mode: 'multiple' }" [rowClass]="rowCallbackValoresTecnologicos"
                    (selectionChange)="clickGridLeyenda($event)">
                    <kendo-grid-checkbox-column width="9"></kendo-grid-checkbox-column>
                    <kendo-grid-column field="nombre" title="{{ 'valoresTecnologicos' | translate }}"
                      width="42%"></kendo-grid-column>
                    <kendo-grid-column field="toler" title="{{ 'toler' | translate }}" width="20">
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <span> ±{{ dataItem.toler}} </span>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="programado" title="{{ 'programado' | translate }}" width="20"></kendo-grid-column>
                    <kendo-grid-column field="actual" title="{{ 'actual' | translate }}" width="20" class="p-0">
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="pastilla-roja" *ngIf="dataItem.lineaRoja"><span title="{{ 'actual' | translate }}">{{dataItem.actual}}</span></div>
                        <div *ngIf="!dataItem.lineaRoja"><span title="{{ 'actual' | translate }}">{{dataItem.actual}}</span></div>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="dim" title="{{ 'dim' | translate }}" width="20"></kendo-grid-column>
                    <!-- <kendo-grid-column field="color" title="" width="9%">
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="colorleyenda" [ngStyle]="{'background-color': dataItem.color }"></span>
                      </ng-template>
                    </kendo-grid-column> -->
                  </kendo-grid>
                </div>
              </div>
            </div>

            <!--GRAFICO-->
            <div [ngClass]="(!tempCamaraCaliente_activo && !tempHusillo_activo && !cascada_activo) ? 'col-md-9' : 'col-md-6' "
                class="grafico-multiardatza" oncontextmenu="return false;">

              <div style="height: 450px;" class="grafico-hmi-inyectora">
                <button kendoButton look="flat" [icon]="'zoom-out'"
                  style="float: right; z-index: 2; margin-right: 10px;" (click)="onClickMostrarTodos()">{{
                  'mostrarTodos' | translate }}</button>
                <div class="reldiv1" id="grafico4_hmiIny" [class.d-none]="!mostrarGraficosPorLeyenda[3]"
                  style="z-index: 1;"></div>
                <div class="reldiv1" id="grafico3_hmiIny" [class.d-none]="!mostrarGraficosPorLeyenda[2]"
                  style="z-index: 1;"></div>
                <div class="reldiv1" id="grafico2_hmiIny" [class.d-none]="!mostrarGraficosPorLeyenda[1]"
                  style="z-index: 1;"></div>
                <div class="reldiv1" id="grafico1_hmiIny" [class.d-none]="!mostrarGraficosPorLeyenda[0]"
                  style="z-index: 1;"></div>
              </div>
              <div style="height: 100px; display: none !important;" id="subgrafico_hmiIny"></div>

              <!--BOTONES LEYENDA-->
              <div class="row" style="min-height: 50px; margin-top: -10px;" *ngIf="maquina.tipoMaquinaInyeccion == 2">
                <div class="col-md-11">
                  <div class="leyendas-chart row justify-content-md-center">
                    <ng-container *ngFor="let campo of listaTooltip">
                      <div class="leyenda-chart col-auto" (click)="clicklLeyenda(campo)"
                        style="cursor: pointer; z-index: 1;">
                        <div class="leyenda-chart-color" *ngIf="campo.seleccionadoLeyenda"
                          [style]="'background-color:' + campo.color"></div>
                        <div class="leyenda-chart-color" *ngIf="!campo.seleccionadoLeyenda"></div>
                        <button class="leyenda-chart-label">{{ campo.nombre | translate }}</button>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

            <!--PESTANAS Camara caliente, husillo, cascada-->
            <div class="col-md-3">
              <kendo-tabstrip>

                <!--T CAMARA CALIENTE-->

                <kendo-tabstrip-tab title="{{ 'tCamaraCaliente' | translate}}" [selected]="true"
                  [disabled]="!tempCamaraCaliente_activo">
                  <ng-template kendoTabContent>
                    <div class="datos-of" style="height: 300px;">

                      <div class="pdatos-of-inner">

                        <!--LINEA TITULO-->
                        <div class="datos-of-content  titulos-datos-cont">
                          <ul style="margin-bottom: 0;">
                            <li>
                              <label class="label-datos-valor-home" style="width: 25%;">{{ '' | translate }}</label>
                              <label class="label-datos-valor-home" style="width: 25%;">{{ 'toler' | translate }}</label>
                              <label class="label-datos-valor-home" style="width: 25%;">{{ 'programado' | translate }}</label>
                              <label class="label-datos-valor-home" style="width: 25%;">{{ 'actual' | translate }}</label>
                            </li>
                          </ul>
                        </div>

                        <!--LISTA-->
                        <div class="datos-of-content">
                          <ul style="margin-bottom: 0;">
                            <li class="linea-ciclos-inyectora" *ngFor="let valor of listaTCamaraCaliente"
                              [ngClass]="{'ciclo-rojo': valor.lineaRoja ? true : false}">
                              <label class="label-datos-valor-home" style="width: 25%;">{{valor.zona}}</label>
                              <label class="label-datos-valor-home" style="width: 25%;">±{{valor.toler}}</label>
                              <label class="label-datos-valor-home" style="width: 25%;">230</label>
                              <label class="label-datos-valor-home" style="width: 25%;">{{valor.actual}}</label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </kendo-tabstrip-tab>

                <!--T HUSILLO-->

                <kendo-tabstrip-tab title="{{ 'tHusillo' | translate }}" [disabled]="!tempHusillo_activo">
                  <ng-template kendoTabContent>
                    <div class="datos-of" style="height: 300px;">

                      <div class="pdatos-of-inner">

                        <!--LINEA TITULO-->
                        <div class="datos-of-content  titulos-datos-cont">
                          <ul style="margin-bottom: 0;">
                            <li>
                              <label class="label-datos-valor-home" style="width: 25%;">{{ '' | translate }}</label>
                              <label class="label-datos-valor-home" style="width: 25%;">{{ 'toler' | translate }}</label>
                              <label class="label-datos-valor-home" style="width: 25%;">{{ 'programado' | translate }}</label>
                              <label class="label-datos-valor-home" style="width: 25%;">{{ 'actual' | translate }}</label>
                            </li>
                          </ul>
                        </div>

                        <!--LISTA-->
                        <div class="datos-of-content">
                          <ul style="margin-bottom: 0;">
                            <li class="linea-ciclos-inyectora" *ngFor="let valor of listaTHusillo"
                              [ngClass]="{'ciclo-rojo': valor.lineaRoja ? true : false}">
                              <label class="label-datos-valor-home" style="width: 25%;">{{valor.zona}}</label>
                              <label class="label-datos-valor-home" style="width: 25%;">±{{valor.toler}}</label>
                              <label class="label-datos-valor-home" style="width: 25%;">230</label>
                              <label class="label-datos-valor-home" style="width: 25%;">{{valor.actual}}</label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </kendo-tabstrip-tab>

                <!--CASCADA-->

                <kendo-tabstrip-tab title="{{ 'cascada' | translate }}" [disabled]="!cascada_activo">
                  <ng-template kendoTabContent>
                    <div class="datos-of" style="height: 300px;">
                      <div class="pdatos-of-inner">
                        <!--LINEA TITULO-->
                        <div class="datos-of-content  titulos-datos-cont">
                          <ul style="margin-bottom: 0;">
                            <li>
                              <label class="label-datos-valor-home" style="width: 20%;">{{ 'zona' | translate }}</label>
                              <label class="label-datos-valor-home" style="width: 20%;">{{ 'abrir' | translate }}</label>
                              <label class="label-datos-valor-home" style="width: 20%;">{{ 'cerrar' | translate }}</label>
                              <label class="label-datos-valor-home" style="width: 20%;">{{ 'abrir2' | translate }}</label>
                              <label class="label-datos-valor-home" style="width: 20%;">{{ 'cerrar2' | translate }}</label>
                            </li>
                          </ul>
                        </div>
                        <!--LISTA-->
                        <div class="datos-of-content">
                          <ul style="margin-bottom: 0;">
                            <li class="linea-ciclos-inyectora" *ngFor="let valor of listaCascada"
                              [ngClass]="{'ciclo-rojo': valor.lineaRoja ? true : false}">
                              <label class="label-datos-valor-home" style="width: 20%;">{{valor.zona}}</label>
                              <label class="label-datos-valor-home" style="width: 20%;">{{valor.abrir}}</label>
                              <label class="label-datos-valor-home" style="width: 20%;">{{valor.cerrar}}</label>
                              <label class="label-datos-valor-home" style="width: 20%;">{{valor.abrir2}}</label>
                              <label class="label-datos-valor-home" style="width: 20%;">{{valor.cerrar2}}</label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </kendo-tabstrip-tab>


              </kendo-tabstrip>
              <!--ALERTAS-->
              <div class="row container-tiempos" style="margin-top: 10px;">
                <div class="col-4 celda-tiempos">
                  <i *ngIf="alertaTempCamaraCaliente" class="fas fa-exclamation-triangle"
                    style="font-size: 30px; color: #eb7272;"></i>
                  <i *ngIf="!alertaTempCamaraCaliente" class="fas fa-check"
                    style="font-size: 30px; color: #649dd4;"></i><br />
                  <span class="celda-tiempos-labelmini">{{ 'tCamaraCaliente' | translate | uppercase }}</span><br />
                  <span class="celda-tiempos-labelmini">{{ valorTempCamaraCaliente }}</span><br />
                  <!--<span class="celda-tiempos-labelmini">±{{ tolerTempCamaraCaliente }}</span>-->
                </div>
                <div class="col-4 celda-tiempos-medio">
                  <i *ngIf="alertaTempHusillo" class="fas fa-exclamation-triangle"
                    style="font-size: 30px; color: #eb7272;"></i>
                  <i *ngIf="!alertaTempHusillo" class="fas fa-check" style="font-size: 30px; color: #649dd4;"></i><br />
                  <span class="celda-tiempos-labelmini">{{ 'tHusillo' | translate | uppercase }}</span><br />
                  <span class="celda-tiempos-labelmini">{{ valorTempHusillo }}</span><br />
                  <!--<span class="celda-tiempos-labelmini">±{{ tolerTempHusillo }}</span>-->
                </div>
                <div class="col-4 celda-tiempos">
                  <i *ngIf="alertaCascada" class="fas fa-exclamation-triangle"
                    style="font-size: 30px; color: #eb7272;"></i>
                  <i *ngIf="!alertaCascada" class="fas fa-check" style="font-size: 30px; color: #649dd4;"></i><br />
                  <span class="celda-tiempos-labelmini">{{ 'cascada' | translate | uppercase }}</span><br />
                  <span class="celda-tiempos-labelmini">{{ valorCascada }}</span><br />
                  <!--<span class="celda-tiempos-labelmini">±{{ tolerCascada }}</span>-->
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

      <!-- PROCESOS -->
      <kendo-tabstrip-tab id="tabProcesos" title="{{ 'procesos' | translate}}">
        <ng-template kendoTabContent>
          <div class="row" style="margin-left: -10px; margin-right: -10px;">
            <div class="procesdos col-sm-12 col-md-4">
              <div class="proces-titulo">
                {{ 'historicoProcesos' | translate | uppercase}}
              </div>
              <div class="clearfix">
                <div class="processcroll">
                  <div class="historial" *ngFor="let historico of historicoProcesos">
                    <div class={{historico.divcss}}>
                      <div class="clearfix">
                        <div class="proces-fecha-cont">
                          <div id="horaIni" class="horaIni">
                            <label>{{historico.horaini}}</label>
                          </div>
                          <div id="diaIni" class="diaIni">
                            <label>{{historico.diaini}}</label>
                          </div>
                        </div>

                        <div class="proces-info-cont">
                          <div id="tipoProceso" *ngIf="historico.idProcesos_Tipo!=6">
                            <label><span class="proces-titulo-uno">{{historico.tipo}}:</span>
                              {{historico.nombre}}</label>
                          </div>
                          <div *ngIf="historico.idProcesos_Tipo==6">
                            <label><span class="proces-titulo-uno">{{historico.tipo}}:</span>
                              {{historico.tipoAlarNombre}}</label>
                          </div>
                          <div id="tiempos">
                            <label><span class="proces-titulo-uno">{{ 'tiempoReal' | translate}}:</span>
                              {{historico.tiemporeal}} <span class="proces-titulo-uno"
                                *ngIf="historico.idProcesos_Tipo!=2">{{ 'tiempoEstimado' | translate}}:</span><span
                                *ngIf="historico.idProcesos_Tipo!=2"> {{historico.tiempoteorico}}</span></label>
                          </div>
                        </div>
                      </div>
                      <div id="informacionOF" class="informacionOF">
                        <label><span class="proces-titulo-dos">OF:</span> {{historico.OF}} <span
                            class="proces-titulo-dos">{{ 'cliente' | translate}}:</span> {{historico.cliente}} <span
                            class="proces-titulo-dos">{{ 'parte' | translate}}:</span> {{historico.parte}} <span
                            class="proces-titulo-dos">{{ 'pieza' | translate}}:</span> {{historico.pieza}}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="procesdos col-sm-12 col-md-4">
              <div class="proces-titulo">
                {{ 'alarmas' | translate | uppercase}}
              </div>
              <div class="clearfix">
                <div class="processcroll">
                  <div class="historial" *ngFor="let alarma of alarmas">

                    <div class={{alarma.divcss}}>

                      <div class="clearfix">
                        <div class="proces-fecha-cont">

                          <div id="horaIni">
                            <label>{{alarma.horaini}}</label>
                          </div>
                          <div id="diaIni">
                            <label>{{alarma.diaini}}</label>
                          </div>

                        </div>

                        <div class="proces-info-cont">
                          <div id="numeroAlarma">
                            <label>{{alarma.numeroAlarma}}</label> - <label>{{alarma.descripcion.replace('ALARMA: ',
                              '')}}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="procesdos col-sm-12 col-md-4">
              <div class="proces-titulo">
                {{ 'planificado' | translate | uppercase}}
              </div>
              <div class="clearfix">
                <div class="processcroll">
                  <div class="historial" *ngFor="let planificado of planificados">

                    <div class={{planificado.divcss}}>

                      <div class="clearfix">

                        <div class="proces-fecha-cont">
                          <div id="tiempos" class="horaIni">
                            <label>{{planificado.horaini}}</label>
                          </div>

                          <div id="tiempos" class="diaIni">
                            <label>{{planificado.diaini}}</label>
                          </div>
                        </div>

                        <div class="proces-info-cont">
                          <div id="operacion">
                            <label id="operacion"><span class="proces-titulo-uno">{{ 'operacion' |
                                translate}}:</span>
                              {{planificado.operacion}}</label>
                          </div>
                          <div id="tiempoTeorico">
                            <label><span class="proces-titulo-uno">{{ 'tiempoEstimado' | translate}}:</span>
                              {{planificado.tiempoteorico}}</label>
                          </div>
                        </div>
                      </div>
                      <div id="informacionOF" class="informacionOF">
                        <label><span class="proces-titulo-dos">OF:</span> {{planificado.OF}} <span
                            class="proces-titulo-dos">{{ 'cliente' | translate}}:</span> {{planificado.cliente}}
                          <span class="proces-titulo-dos">{{ 'parte' | translate}}:</span> {{planificado.parte}}
                          <span class="proces-titulo-dos">{{ 'pieza' | translate}}:</span>
                          {{planificado.pieza}}</label>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

      <!-- MANTENIMIENTOS -->
      <kendo-tabstrip-tab id="tabMantenimiento" title="{{ 'mantenimientos' | translate}}">
        <ng-template kendoTabContent>
          <div class="row cont-mantenimientos">
            <div class="col-md-6">
              {{ 'porFechas' | translate | uppercase}}
              <div class="">
                <kendo-grid [data]="mantenimientoPorFecha" style="height: 400px" [hideHeader]="true"
                  [rowClass]="rowCallback">
                  <kendo-grid-column field="switch" width="17%">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <kendo-switch [(ngModel)]=dataItem.checked (click)="clickSwitch($event, dataItem, 1)"
                        [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="texto" width="41%">
                  </kendo-grid-column>
                  <kendo-grid-column width="10%" field="tipo" title="{{ 'tipo' | translate}}">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span>{{ dataItem.tipo | translate }}</span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="fecha" width="20%">
                  </kendo-grid-column>
                  <kendo-grid-column field="variacionicono" title="" width="12%">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <button (click)="abrirPdf(dataItem)" class="k-button pdf-button" *ngIf="dataItem.tienePdf==true">
                        <span class="k-icon k-i-file-pdf"></span>
                      </button>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                  filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                  filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                  filterContainsOperator="{{'filterContainsOperator' | translate}}"
                  filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                  filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                  filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                  filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                  filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                  filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                  groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
                </kendo-grid>
                <div *ngIf="loadingPorFecha" class="k-i-loading"></div>
              </div>
            </div>
            <div class="col-md-6">
              {{ 'porTiempos' | translate | uppercase}}
              <div class="">
                <kendo-grid [data]="mantenimientoPorTiempo" style="height: 400px" [hideHeader]="true"
                  [rowClass]="rowCallback">
                  <kendo-grid-column field="switch" width="17%">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <kendo-switch [(ngModel)]=dataItem.checked (click)="clickSwitchTiempo($event, dataItem, 2)"
                        [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="texto" width="51%">
                  </kendo-grid-column>
                  <kendo-grid-column field="fecha" width="20%">
                  </kendo-grid-column>
                  <kendo-grid-column field="variacionicono" title="" width="12%">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <button (click)="abrirPdf(dataItem)" class="k-button" *ngIf="dataItem.tienePdf==true">
                        <span class="k-icon k-i-file-pdf"></span>
                      </button>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                  filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                  filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                  filterContainsOperator="{{'filterContainsOperator' | translate}}"
                  filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                  filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                  filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                  filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                  filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                  filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                  groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
                </kendo-grid>
                <div *ngIf="loadingPorTiempo" class="k-i-loading"></div>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

      <!-- NOTAS -->
      <kendo-tabstrip-tab id="tabNotas" title="{{ 'notas' | translate}}">
        <ng-template kendoTabContent>
          <div class="row" style="margin-left: -10px; margin-right: -10px;">
            <kendo-grid [data]="notas" style="height: 400px">
              <kendo-grid-column field="creadoPor" title="{{ 'creadoPor' | translate}}" width="10%">
              </kendo-grid-column>
              <kendo-grid-column field="cerradoPor" title="{{ 'cerradoPor' | translate}}" width="10%">
              </kendo-grid-column>
              <kendo-grid-column field="fecha" title="{{ 'fecha' | translate}}" width="10%">
              </kendo-grid-column>
              <kendo-grid-column field="texto" title="{{ 'texto' | translate}}" width="70%">
              </kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

      <!--PIEZAS-->
      <kendo-tabstrip-tab title="{{ 'piezas' | translate }}">
        <ng-template kendoTabContent>
          <div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

      <!-- HERRAMIENTAS -->
      <!-- <kendo-tabstrip-tab id="tabHerramientas" title="{{ 'herramientas' | translate}}">
        <ng-template kendoTabContent>
          <div class="row" style="margin-left: -10px; margin-right: -10px;">
            <div class="col-md-6">
              {{ 'almacen' | translate | uppercase}}:
              <kendo-grid [data]="herramientas">
                <kendo-grid-column field="posicionTorreta" title="{{ 'posicion' | translate}}" width="7%">
                </kendo-grid-column>
                <kendo-grid-column field="posicionAlmacen" title="{{ 'posicionAlmacen' | translate}}" width="7%">
                </kendo-grid-column>
                <kendo-grid-column field="nombreHerramienta" title="{{ 'nombreHerramienta' | translate}}" width="20%">
                </kendo-grid-column>
                <kendo-grid-column field="radio" title="{{ 'radio' | translate}}" width="24%">
                </kendo-grid-column>
                <kendo-grid-column field="offX" title="offX" width="7%">
                </kendo-grid-column>
                <kendo-grid-column field="offZ" title="offZ" width="7%">
                </kendo-grid-column>
                <kendo-grid-column field="owX" title="owX" width="7%">
                </kendo-grid-column>
                <kendo-grid-column field="owZ" title="owZ" width="7%">
                </kendo-grid-column>
                <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                filterContainsOperator="{{'filterContainsOperator' | translate}}"
                filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
              </kendo-grid>
            </div>
            <div class="col-md-6">
              {{ 'roturasCambiosherramienta' | translate | uppercase}}
              <kendo-grid [data]="roturasCambiosHerramientas">
                <kendo-grid-column field="nombreHerramienta" title="{{ 'nombreHerramienta' | translate}}" width="35%">
                </kendo-grid-column>
                <kendo-grid-column field="nombreOperario" title="{{ 'nombreOperario' | translate}}" width="20%">
                </kendo-grid-column>
                <kendo-grid-column field="tipo" title="{{ 'tipo' | translate}}" width="15%">
                </kendo-grid-column>
                <kendo-grid-column field="cantidad" title="{{ 'cantidad' | translate}}" width="10%">
                </kendo-grid-column>
                <kendo-grid-column field="fecha" title="{{ 'fecha' | translate}}" width="20%">
                </kendo-grid-column>
                <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                filterContainsOperator="{{'filterContainsOperator' | translate}}"
                filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
              </kendo-grid>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab> -->

    </kendo-tabstrip>
    <div *ngIf="loadingPestannas" class="k-i-loading" style="z-index: 2;"></div>

  </div>
</div>
