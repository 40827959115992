<div class="card">
    <div class="card-header">
        <h3>{{ 'filtro' | translate}}</h3>
        <div class="plegarpanel"></div>
    </div>
    <div class="card-body">
        <div class="clearfix">
            <div class="caja">
                <button type="button" class="btn btn-danger mr-1 float-left nolabel" >{{ 'limpiarFiltro' | translate
                }}</button>
                <button type="button" class="btn btn-primary float-left nolabel" >{{ 'filtrar' | translate }}</button>
            </div>
        </div>
    </div>
</div>
<div class="card">
    <div class="card-header">
        <h3>{{ 'articulos' | translate}}</h3>
        <div class="plegarpanel"></div>
    </div>
    <div class="card-body">
        <kendo-grid [kendoGridBinding]="data" [sortable]="true"
                    kendoGridSelectBy="id" [navigable]="true" filterable="menu"
                    [selectedKeys]="mySelection">
                    <kendo-grid-checkbox-column width="5%" showSelectAll="true"></kendo-grid-checkbox-column>
                    <kendo-grid-column width="40%" field="nombre" title="{{ 'nombre' | translate}}">
                    </kendo-grid-column>
                    <kendo-grid-column width="40%" field="descripcion" title="{{ 'descripcion' | translate}}">
                    </kendo-grid-column>
                    <kendo-grid-column width="15%" field="fecha" title="{{ 'fecha' | translate}}" [style]="{'text-align': 'center'}">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            {{dataItem.fecha | kendoDate}}</ng-template>
                    </kendo-grid-column>
                    <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                    filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                    filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                    filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                    filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                    filterContainsOperator="{{'filterContainsOperator' | translate}}"
                    filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                    filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                    filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                    filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                    filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                    filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                    filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                    filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                    filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                    filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                    filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                    filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                    filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                    filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                    groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                    noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
                </kendo-grid>
    </div>
</div>


