import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
  translateAggregateResults,
  DataResult,
  DataSourceRequestState
} from '@progress/kendo-data-query';

import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';

import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { query } from '@angular/animations';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/rutas`;
@Injectable()
export class RutasService extends BehaviorSubject<any> {

  public loading = false;

  constructor(private http: HttpClient) {
    super(null);
  }

  public GetAll(action: string = '',param?:string, data?: ''): Observable<any> {
    this.loading = true;
    var headers, params;

    if(param == undefined) {
      param=''

    }
    
    return this.http
      .get(baseUrl);
  }

  public GetByID(idparte:Number,idruta:Number): Observable<any> {
    this.loading = true;
    var headers, params;

    
    
    return this.http
      .get(baseUrl+"/"+idparte+"/"+idruta);
  }

  public delete(data?: any): Observable<any> {
    //this.loading = true;

    var formData: any = new FormData();
    formData.append("idruta", data.idruta);
    

    return this.http
      .post<JSON>(baseUrl + "/eliminar" , formData,{ withCredentials: true });
     
  }


  create(data) {

    var fIni:Date =data.fechaini;
    var fFin: Date = data.fechafin;
   
    return this.http.post<any>(baseUrl + "/crear", { Nombre: data.nombre, idparte: data.idparte, principal: data.principal, IDERP: data.idERPRuta }, { withCredentials: true });
  }

  createPredefinida(data) {

    var fIni: Date = data.fechaini;
    var fFin: Date = data.fechafin;

    return this.http.post<any>(baseUrl + "/crearpredefinida", { Nombre: data.nombre, idparte: data.idparte, principal: data.principal, IDERP: data.idERPRuta }, { withCredentials: true });
  }

  update(data) {

    var formData: any = new FormData();
    var fIni:Date =data.fechaini;
    var fFin:Date =data.fechafin;

    return this.http.post<any>(baseUrl + "/editar", { id: data.idruta,Nombre: data.nombre, idparte: data.idparte, principal: data.principal, IDERP: data.idERPRuta }, { withCredentials: true });
  }

  updatePredefinida(data) {

    var formData: any = new FormData();
    var fIni: Date = data.fechaini;
    var fFin: Date = data.fechafin;

    return this.http.post<any>(baseUrl + "/editarpredefinida", { id: data.idruta, Nombre: data.nombre, idparte: data.idparte, principal: data.principal, IDERP: data.idERPRuta }, { withCredentials: true });
  }

  duplicar(data) {

 

    return this.http.post<any>(baseUrl + "/duplicar", data, { withCredentials: true });
  }


}
