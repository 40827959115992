







<div class="row">
  <div class="col-md-8 col-lg-6 col-xl-4">
    <div class="card">
      <div class="card-header">
        <h3>
          <label>{{ 'datos' | translate }}</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <!-- NOMBRE -->
        <div class="clearfix">
          <div class="form-group">
            <kendo-label text="{{ 'nombre' | translate }}">
              <kendo-textbox [(value)]="nombre" [class.requerido]="nombreRequerido"></kendo-textbox>
            </kendo-label>
          </div>
        </div>
        <div class="clearfix">
          <!-- SECCION -->
          <div class="float-left mr-2">
            <div class="form-group">
              <kendo-label text="{{ 'seccion' | translate }}">
                <kendo-dropdownlist [(data)]="groupedSeccion" [textField]="'nombre'" [valueField]="'id'" [(value)]="idSeccion" class="form-control" [class.requerido]="seccionRequerido"></kendo-dropdownlist>
              </kendo-label>
            </div>
          </div>
          <!-- CAPACIDAD SEMANAL -->
          <div class="float-left mr-2">
            <kendo-label text="{{ 'capacidadsemanal' | translate}}">
              <kendo-numerictextbox class="form-control" [(value)]="capacidadSemanal" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n2'" [class.requerido]="capacidadSemanalRequerido"></kendo-numerictextbox>
            </kendo-label>
          </div>
          <!-- ENVIO POR DEFECTO -->
          <div class="float-left">
            <kendo-label text="{{ 'envioPorDefecto' | translate}}">
              <kendo-numerictextbox class="form-control" [(value)]="envioPorDefecto" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n2'" [class.requerido]="envioPorDefectoRequerido"></kendo-numerictextbox>
            </kendo-label>
          </div>
          <!-- PRECIO HORA -->
          <div class="float-left">
            <kendo-label text="{{ 'precioHora' | translate}}">
              <kendo-numerictextbox class="form-control" [(value)]="costeHora" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n2'" [class.requerido]="costeHoraRequerido"></kendo-numerictextbox>
            </kendo-label>
          </div>
        </div>
        <!-- IMAGEN -->
        <div class="clearfix">
          <kendo-label text="{{ 'imagen' | translate}}">
            <div>
              <img [src]="imageToShow" style="max-height: 113px">
            </div>
            <div class="form-group w-50">
              <div class="caja">
                <kendo-fileselect [(ngModel)]="imagen" [restrictions]="restrictions" [multiple]="false" (select)="archivoSeleccionado($event)" (remove)="archivoEliminado($event)">
                  <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                                         clearSelectedFiles="{{ 'clearSelectedFiles' | translate }}"
                                         dropFilesHere="{{ 'dropFilesHere' | translate }}"
                                         externalDropFilesHere="{{ 'externalDropFilesHere' | translate }}"
                                         fileStatusFailed="{{ 'fileStatusFailed' | translate }}"
                                         fileStatusUploaded="{{ 'fileStatusUploaded' | translate }}"
                                         filesBatchStatus="{{ 'filesBatchStatus' | translate }}"
                                         filesBatchStatusFailed="{{ 'filesBatchStatusFailed' | translate }}"
                                         filesBatchStatusUploaded="{{ 'filesBatchStatusUploaded' | translate }}"
                                         headerStatusPaused="{{ 'headerStatusPaused' | translate }}"
                                         headerStatusUploaded="{{ 'headerStatusUploaded' | translate }}"
                                         headerStatusUploading="{{ 'headerStatusUploading' | translate }}"
                                         invalidFileExtension="{{ 'invalidFileExtension' | translate }}"
                                         invalidMaxFileSize="{{ 'invalidMaxFileSize' | translate }}"
                                         invalidMinFileSize="{{ 'invalidMinFileSize' | translate }}"
                                         pause="{{ 'pause' | translate }}"
                                         remove="{{ 'remove' | translate }}"
                                         resume="{{ 'resume' | translate }}"
                                         retry="{{ 'retry' | translate }}"
                                         select="{{ 'select' | translate }}"
                                         uploadSelectedFiles="{{ 'uploadSelectedFiles' | translate }}">
                  </kendo-upload-messages>
                </kendo-fileselect>
              </div>
            </div>
          </kendo-label>
        </div>
        <button kendoButton class="btn mr-1  btn-primary" (click)="onClickGuardar()" [disabled]="this.user.subcontratado < 2">{{ 'guardar' | translate}}</button>
        <button kendoButton class="btn mr-1  btn-danger" (click)="onClickCancelar()">{{ 'cancelar' | translate}}</button>
      </div>
    </div>
  </div>
</div>




