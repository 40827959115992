<kendo-treelist [kendoTreeListFlatBinding]="manuales" kendoTreeListExpandable idField="idManualCarpeta" [initiallyExpanded]="true"
                parentIdField="idPadre" [sortable]="true" [navigable]="true" filterable="menu"
                (cellClick)="manuales_cellClick($event)" kendoTreeListSelectable [selectable]="settings"
                (selectionChange)="onItemSelected($event)">
  <!--BOTONES ARRIBA-->
  <ng-template kendoTreeListToolbarTemplate position="top">
    <button (click)="manuales_onClickEditar()" class="btn btn-success btn-sm mr-1">{{ 'editar' | translate}}</button>
    <button (click)="manuales_onClickNuevo()" class="btn btn-primary btn-sm mr-1">{{ 'nuevo' | translate}}</button>
    <button (click)="manuales_onClickNuevaCarpeta()" class="btn btn-primary btn-sm mr-1">{{ 'nuevaCarpeta' | translate }}</button>
    <button (click)="manuales_onClickEliminar($event)" class="btn btn-danger btn-sm mr-1" [disabled]="isDeleting || this.user.diccionarioPerdidas < 2">{{ 'eliminar' | translate }}</button>
  </ng-template>
  <!--checkbox-->
  <kendo-treelist-checkbox-column width="3%" showSelectAll="true"></kendo-treelist-checkbox-column>
  <!--nombre-->
  <kendo-treelist-column [expandable]="true" width="32%" field="texto" title="{{ 'nombre' | translate}}"></kendo-treelist-column>
  <!--tipoManual-->
  <kendo-treelist-column width="32%" field="tipo" title="{{ 'tipo' | translate }}">
    <ng-template kendoTreeListCellTemplate let-dataItem><span>{{ dataItem.tipo | translate }}</span></ng-template>
  </kendo-treelist-column>
  <kendo-treelist-column width="40%" field="pdf" title="{{ 'pdf' | translate}}"></kendo-treelist-column>
  <kendo-treelist-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                           filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                           filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                           filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                           filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                           filterContainsOperator="{{'filterContainsOperator' | translate}}"
                           filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                           filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                           filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                           filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                           filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                           filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                           filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                           filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                           filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                           filterNotEqOperator="{{'filterNotEqOperator' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                           filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                           groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}"
                           noRecords="{{'noRecords' | translate}}" unlock="{{'unlock' | translate}}">
  </kendo-treelist-messages>
</kendo-treelist>

<!--POPUP MANUAL ELIMINAR-->
<ng-template #popupEliminarManual let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'preguntaeliminarpopup' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)=" manuales_eliminarRegistro()" [disabled]="this.user.maquinas<2">{{ 'si' | translate }}</button>
  </div>
</ng-template>

<!--POPUP MANUAL ELIMINAR-->
<ng-template #popupEliminarCarpeta let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'preguntaeliminarCarpetapopup' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)=" carpetas_eliminarRegistro()" [disabled]="this.user.maquinas<2">{{ 'si' | translate }}</button>
  </div>
</ng-template>
