<ng-container *ngIf="!arbolAsignar">
    <div class="card" *ngIf="!arbolCompleto">
        <div class="card-header">
            <h3>{{ 'datos' | translate}}</h3>
            <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-3">
                    <label>{{ 'nombre' | translate}}</label>
                    <p>{{dataActivo.nombre}}</p>
                </div>
                <div class="col-3">
                    <label>{{ 'referencia' | translate}}</label>
                    <p>{{dataActivo.referencia}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <kendo-treelist [kendoTreeListFlatBinding]="data" kendoTreeListExpandable idField="id" [initiallyExpanded]="true"
                parentIdField="idPadre">
                <kendo-treelist-column [expandable]="true" field="nombre" title="{{ 'activo' | translate}}">
                    <ng-template kendoTreeListCellTemplate let-dataItem>
                        <span ngClass="{{dataItem.id == idActivoMaestro ? 
                            'arbol-activo': ''}}">
                            {{dataItem.nombre}}
                        </span>
                    </ng-template>
                </kendo-treelist-column>
                <kendo-treelist-column field="tipoActivo" title="{{ 'tipoActivo' | translate }}">
                    <ng-template kendoTreeListCellTemplate let-dataItem>
                        <span ngClass="{{dataItem.id == idActivoMaestro ? 
                            'arbol-activo': ''}}">
                            {{dataItem.tipoActivo}}
                        </span>
                    </ng-template>
                </kendo-treelist-column>
                <kendo-treelist-messages loading="{{'loading' | translate}}"
                    groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}"
                    noRecords="{{'noRecords' | translate}}" unlock="{{'unlock' | translate}}">
                </kendo-treelist-messages>
            </kendo-treelist>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="arbolAsignar">
    <kendo-treelist [kendoTreeListFlatBinding]="data" kendoTreeListExpandable idField="id" [initiallyExpanded]="true"
                parentIdField="idPadre" kendoTreeListSelectable
                [selectable]="settings"
                [(selectedItems)]="mySelection">
                <kendo-treelist-column [expandable]="true" field="nombre" title="{{ 'activo' | translate}}">
                    <ng-template kendoTreeListCellTemplate let-dataItem>
                        <span ngClass="{{dataItem.id == idActivoSeleccionado ? 
                            'arbol-activo': ''}}">
                            {{dataItem.nombre}}
                        </span>
                    </ng-template>
                </kendo-treelist-column>
                <kendo-treelist-column field="tipoActivo" title="{{ 'tipoActivo' | translate }}">
                    <ng-template kendoTreeListCellTemplate let-dataItem>
                        <span ngClass="{{dataItem.id == idActivoSeleccionado ? 
                            'arbol-activo': ''}}">
                            {{dataItem.tipoActivo}}
                        </span>
                    </ng-template>
                </kendo-treelist-column>
                <kendo-treelist-messages loading="{{'loading' | translate}}"
                    groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}"
                    noRecords="{{'noRecords' | translate}}" unlock="{{'unlock' | translate}}">
                </kendo-treelist-messages>
            </kendo-treelist>
            <button kendoButton (click)="guardarRelacion()" class="btn mr-1  btn-success btn-sm mr-1" [disabled]="this.user.activosMaestro<2"> {{
                'asignar' | translate}} </button>
            <button kendoButton (click)="atras()" class="btn mr-1  btn-danger btn-sm mr-1"
                [disabled]="isDeleting"> {{ 'cancelar' | translate}} </button>
</ng-container>