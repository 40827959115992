<style>
  :host /deep/ .c3-chart-arcs-background {
    fill: #1A5F60;
    stroke: none;
  }

  :host /deep/ .c3-chart-arcs path {
    stroke-width: 0px;
  }
</style>

<div class="row home-maquinas-cont" style="padding: 8px 5px 0px 10px;">
  <ng-container *ngFor="let maquina of dataMaquinas">
    <div class="form-horizontal col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 panelosoa" id="panelosoa"
      *ngIf="appComponent.selectedGrupo.idMaquinas.includes(maquina.id) && !maquina.borrado && r1 == true && r2 == true">
      <div class="inner-panel">
        <!-- TODO -->
        <!-- Pestaña de color-->
        <div id="pestanaColor" class="{{maquina.css}}"></div>

        <div class="clearfix">
          <!-- ROW 1 -->
          <a ID="HLPrograma" (click)="RedirigirAProceso(maquina.id)">
            <div class="clearfix">


              <!-- Imagen de las maquinas-->
              <div ID="maquina" class="maquina">
                <div id="estado">
                  <div class="fotomaquina pqn">
                    <img ID="fotomaquina" src="{{maquina.logo}}" />
                  </div>
                </div>
              </div>


              <div class="header-home-maquina clearfix">
                <!-- Nombre de la maquina -->
                <div class="modelo-operario-cont">
                  <div class="nombre-modelo-maquina">
                    <div class="modelo-maquina">
                      <label ID="lmodelo">{{maquina.nombre}}</label>
                    </div>
                  </div>
                  <!-- Nombre del operario-->
                  <div class="nombre-operario-maquina">
                    <div ID="persona" class="usuario">
                      <!--<img id="fotopersona" src="assets/user-cm.png" />-->
                      <i class="icon-usuario"></i>
                      <label ID="nompersona">{{maquina.operario}}</label>
                    </div>
                  </div>
                </div>

                <div class="maquina-tiempo-estado-cont">
                  <!-- Tiempo ejecucion -->
                  <div *ngIf="maquina != undefined && maquina?.ejecuciones != undefined">
                    <div class="tiempo-ejecucion-maquina">
                      <kendo-label text="{{tiempoEjecucion.get(maquina.id)}}"></kendo-label>
                    </div>
                    <!-- <div class="tiempo-ejecucion-maquina"
                      *ngIf="(maquina.ejecuciones[0].procesos_Tipo == 'apagada' || maquina.ejecuciones[0].refOF == translateService.instant('noproceso')) && maquina.tipo_maquina!=5">
                      <kendo-label text="{{tiempoApagado.get(maquina.id)}}"></kendo-label>
                    </div> -->
                    <!-- <div class="tiempo-ejecucion-maquina"
                      *ngIf="(maquina.ejecuciones[0].procesos_Tipo == 'apagada' || maquina.ejecuciones[0].refOF == translateService.instant('noproceso')) && maquina.tipo_maquina==5">
                      <kendo-label text="{{tiempoEjecucion.get(maquina.id)}}"></kendo-label>
                    </div> -->
                    <!-- Estado de ejecucion -->
                    <div class="estado-maquina">
                      <div *ngIf="maquina?.ejecuciones[0]?.procesos_Tipo != undefined">
                        <div class="{{maquina.ejecuciones[0].procesos_Tipo}}">
                          <kendo-label text="{{maquina.procesos_Tipostr?.toUpperCase()}}"></kendo-label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Nombre programa -->
                <div *ngIf="maquina?.ejecuciones[0]?.procesos_Tipo != 'apagada'">
                  <div class="programa-ejecucion-maquina" *ngIf="maquina.tipo_maquina!=5">
                    <kendo-label text="{{maquina.programa}}"></kendo-label>
                  </div>
                  <div class="programa-ejecucion-maquina" *ngIf="maquina.tipo_maquina==5">
                    <kendo-label text="{{ 'tRestCalidad' | translate }} {{tRestCalidad_HHmmSS}}"></kendo-label>
                  </div>
                </div>
              </div>
            </div>
          </a>

          <!-- ROW 2 -->
          <div class="clearfix datos-cols-cont">

            <!-- COL 1 -->
            <div class="datos-of-col">

              <div ID="datosof" class="datos-of">

                <!--PANEL OF PARA MAQUINAS QUE NO SON INYECTORAS NI HORNOS-->
                <div class="pdatos-of-inner" *ngIf="maquina?.tipo_maquina != 4 && maquina?.tipo_maquina != 5">
                  <!--<label ID="Ldatos" class="titulo-seccion-maquina">{{ 'datos' | translate}}</label>-->

                  <!-- LISTA DATOS -->
                  <div
                    *ngIf="maquina?.ejecuciones[0]?.refOF!=translateService.instant('noproceso') && maquina?.ejecuciones[0]?.procesos_Tipo != 'apagada'">
                    <div id="divDatosof" class="datos-of-content">
                      <ul>
                        <li>
                          <label ID="ldatocodof" class="label-datos-home" data-toggle="tooltip" data-placement="bottom"
                            title="{{maquina.ejecuciones[0]?.refOF}}">
                            <!-- <span class="of-label">{{'of' |translate}}</span>{{maquina.ejecuciones[0].refOF}} -->
                            <strong>{{'of' |translate}} </strong> {{maquina.ejecuciones[0].refOF}}
                          </label>
                        </li>
                        <li *ngIf="maquina?.ejecuciones[0]?.cliente!=''">
                          <label ID="ldatocliente" class="label-datos-home" data-toggle="tooltip"
                            data-placement="bottom" title="{{maquina.ejecuciones[0].cliente}}">
                            {{maquina.ejecuciones[0].cliente}}
                          </label>
                        </li>
                        <li *ngIf="maquina?.ejecuciones[0]?.pieza!=''">
                          <label ID="lDatoPieza" class="label-datos-home" data-toggle="tooltip" data-placement="bottom"
                            title="{{maquina.ejecuciones[0].pieza}}">
                            {{maquina.ejecuciones[0].pieza}}
                          </label>
                        </li>
                        <!-- <li *ngIf="maquina?.tipo_maquina == 9 && maquina?.nombreMaterial!=''">
                          <label ID="lDatoMaterial" class="label-datos-home" data-toggle="tooltip"
                            data-placement="bottom" title="{{maquina.nombreMaterial}}">
                            {{maquina.nombreMaterial}}
                          </label>
                        </li> -->
                        <li *ngIf="maquina.ejecuciones[0]?.parte != '' && configVariables.ocultarParte == 0">
                          <label ID="ldatoparte" class="label-datos-home" data-toggle="tooltip" data-placement="bottom"
                            title="{{maquina.ejecuciones[0].parte}}">
                            {{maquina.ejecuciones[0].parte}}
                          </label>
                        </li>
                        <li *ngIf="maquina?.ejecuciones[0]?.operacion != ''">
                          <label ID="ldatoOperacion" class="label-datos-home" data-toggle="tooltip"
                            data-placement="bottom" title="{{maquina.ejecuciones[0].operacion}}">
                            {{maquina.ejecuciones[0].operacion}}
                          </label>
                        </li>
                        <li *ngIf="maquina?.tipo_maquina != 1 && maquina.ejecuciones[0]?.numeroSerie != ''">
                          <label ID="ldatoNumSerie" class="label-datos-home" data-toggle="tooltip"
                            data-placement="bottom" title="{{maquina.ejecuciones[0].numeroSerie}}">
                            {{maquina.ejecuciones[0].numeroSerie}}
                          </label>
                        </li>
                        <li *ngIf="maquina.contador">
                          <label ID="LdatoCantidad" class="label-datos-home" data-toggle="tooltip"
                            data-placement="bottom" title="{{maquina.ejecuciones[0].realizadas}}">
                            <strong>{{ 'contador' | translate }} </strong> {{maquina.ejecuciones[0].contador}} /
                            {{maquina.ejecuciones[0].cantidad}}
                          </label>
                        </li>
                        <li>
                          <label ID="LdatoCantidad" class="label-datos-home" data-toggle="tooltip"
                            data-placement="bottom" title="{{maquina.ejecuciones[0].realizadas}}">
                            <strong>{{ 'confirmadas' | translate }} </strong> {{maquina.ejecuciones[0].hechas}} /
                            {{maquina.ejecuciones[0].cantidad}}
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    *ngIf="maquina.ejecuciones[0]?.refOF==translateService.instant('noproceso') || maquina.ejecuciones[0]?.procesos_Tipo == 'apagada'"
                    class="label-datos-home-noidentificado">
                    <label ID="ldatocodof" class="label-datos-home" data-toggle="tooltip" data-placement="bottom">
                      {{ 'noproceso' | translate }}
                    </label>
                  </div>
                </div>

                <!--PANEL OF PARA INYECTORAS-->
                <div class="pdatos-of-inner" *ngIf="maquina.tipo_maquina == 4">

                  <!--MAQUINA-->
                  <label ID="Lmaquina" class="titulo-seccion-maquina">{{ 'maquina' |
                    translate}}</label>

                  <!-- <div id="divMaquinaInyec" class="datos-of-content">
                    <ul>
                      <li *ngIf="maquina.tonelaje!=''">
                        <label ID="ldatotonelaje" class="label-datos-home" style="width: 60%;" data-toggle="tooltip"
                          data-placement="bottom" title="{{maquina.tonelaje}}">
                          {{maquina.tonelaje}}
                        </label>
                      </li>
                      <li *ngIf="maquina.unidadInyeccion!=''">
                        <label ID="ldatounidadInyeccion" class="label-datos-home" style="width: 60%;"
                          data-toggle="tooltip" data-placement="bottom" title="{{maquina.unidadInyeccion}}">
                          {{maquina.unidadInyeccion}}
                        </label>
                      </li>
                      <li *ngIf="maquina.numMaquina!=''">
                        <label ID="ldatonumeroMaquina" class="label-datos-home" style="width: 60%;"
                          data-toggle="tooltip" data-placement="bottom" title="{{maquina.numMaquina}}">
                          {{maquina.numMaquina}}
                        </label>
                      </li>
                    </ul>
                  </div> -->

                  <!--DATOS-->
                  <div
                    *ngIf="maquina.ejecuciones[0].refOF!=translateService.instant('noproceso') && maquina.ejecuciones[0].procesos_Tipo != 'apagada'">
                    <div id="divDatosInyec" class="datos-of-content">
                      <ul>
                        <li *ngIf="maquina.ejecuciones[0].refOF!=translateService.instant('noproceso')">
                          <label ID="ldatocodof" class="label-datos-home" style="width: 60%;" data-toggle="tooltip"
                            data-placement="bottom" title="{{maquina.ejecuciones[0].refOF}}">
                            <span class="of-label">{{'of' |translate}}</span>{{maquina.ejecuciones[0].refOF}}
                          </label>
                        </li>
                        <li *ngIf="maquina?.ejecuciones[0]?.cliente!='' && maquina.ejecuciones[0].refOF!=translateService.instant('noproceso')">
                          <label ID="ldatocliente" class="label-datos-home" data-toggle="tooltip"
                            data-placement="bottom" title="{{maquina.ejecuciones[0]?.cliente}}">
                            {{maquina.ejecuciones[0]?.cliente}}
                          </label>
                        </li>
                        <!--denom pieza-->
                        <li *ngIf="maquina?.ejecuciones[0]?.pieza!='' && maquina.ejecuciones[0].refOF!=translateService.instant('noproceso')">
                          <label ID="lDatoPieza" class="label-datos-home" data-toggle="tooltip" data-placement="bottom"
                            title="{{maquina.ejecuciones[0].pieza}}">
                            {{maquina.ejecuciones[0].pieza}}
                          </label>
                        </li>
                        <!--ref pieza-->
                        <li *ngIf="maquina?.ejecuciones[0]?.refPieza!='' && maquina.ejecuciones[0].refOF!=translateService.instant('noproceso')">
                          <label ID="lRefPieza" class="label-datos-home" data-toggle="tooltip" data-placement="bottom"
                            title="{{maquina.ejecuciones[0].refPieza}}">
                            {{maquina.ejecuciones[0].refPieza}}
                          </label>
                        </li>
                        <li *ngIf="maquina.material!=''">
                          <label ID="lmaterial" class="label-datos-home" style="width: 60%;" data-toggle="tooltip"
                            data-placement="bottom" title="{{maquina.material}}">
                            {{maquina.material}}
                          </label>
                        </li>
                        <li *ngIf="maquina.nCavidades!=''">
                          <label ID="ldatonCavidades" class="label-datos-home" style="width: 60%;" data-toggle="tooltip"
                            data-placement="bottom" title="{{maquina.nCavidades}}">
                            {{maquina.nCavidades}}
                          </label>
                        </li>
                        <li>
                          <label ID="LdatoCantidad" class="label-datos-home" data-toggle="tooltip"
                            data-placement="bottom" title="{{maquina.ejecuciones[0].realizadas}}">
                            <strong>{{ 'confirmadas' | translate }} </strong> {{maquina.ejecuciones[0].hechas}} de
                            {{maquina.ejecuciones[0].cantidad}}
                          </label>
                        </li>
                        <!-- <li *ngIf="maquina.refMaterial!=''">
                          <label ID="ldatorefMaterial" class="label-datos-home" style="width: 60%;"
                            data-toggle="tooltip" data-placement="bottom" title="{{maquina.refMaterial}}">
                            {{maquina.refMaterial}}
                          </label>
                        </li> -->
                        <!-- <li *ngIf="maquina.refMolde!=''">
                          <label ID="ldatorefMolde" class="label-datos-home" style="width: 60%;" data-toggle="tooltip"
                            data-placement="bottom" title="{{maquina.refMolde}}">
                            {{maquina.refMolde}}
                          </label>
                        </li> -->
                      </ul>
                    </div>
                  </div>
                  <div
                    *ngIf="maquina.ejecuciones[0].refOF==translateService.instant('noproceso') || maquina.ejecuciones[0].procesos_Tipo == 'apagada'"
                    class="label-datos-home-noidentificado">
                    <label ID="ldatocodof" class="label-datos-home" data-toggle="tooltip" data-placement="bottom">
                      {{ 'noproceso' | translate }}
                    </label>
                  </div>
                </div>

                <!--PANEL OF PARA HORNOS-->
                <div class="pdatos-of-inner" *ngIf="maquina.tipo_maquina == 5">

                  <!--DATOS-->
                  <div
                    *ngIf="maquina.ejecuciones[0].refOF!=translateService.instant('noproceso') && maquina.ejecuciones[0].procesos_Tipo != 'apagada'">
                    <div id="divDatosInyec" class="datos-of-content">
                      <ul>
                        <li>
                          <label ID="ldatocodof" class="label-datos-valor-home" style="width: 60%;"
                            data-toggle="tooltip" data-placement="bottom" title="{{maquina.ejecuciones[0].refOF}}">
                            <strong>{{'of' |translate}}</strong> {{maquina.ejecuciones[0].refOF}}
                          </label>
                        </li>
                        <li>
                          <label ID="ldatoCliente" class="label-datos-valor-home" style="width: 60%;"
                            data-toggle="tooltip" data-placement="bottom" title="{{maquina.ejecuciones[0].cliente}}">
                            {{ maquina.ejecuciones[0].cliente }}
                          </label>
                        </li>
                        <li>
                          <label ID="ldatoPieza" class="label-datos-valor-home" style="width: 60%;"
                            data-toggle="tooltip" data-placement="bottom" title="{{maquina.ejecuciones[0].pieza}}">
                            {{ maquina.ejecuciones[0].pieza }}
                          </label>
                        </li>
                        <li>
                          <label ID="ldatoTarea" class="label-datos-valor-home" style="width: 60%;"
                            data-toggle="tooltip" data-placement="bottom" title="{{maquina.ejecuciones[0].operacion}}">
                            {{maquina.ejecuciones[0].operacion}}
                          </label>
                        </li>
                        <li>
                          <label ID="ldatoPiezas" class="label-datos-valor-home" style="width: 60%;"
                            data-toggle="tooltip" data-placement="bottom" title="{{ maquina.ejecuciones[0].hechas }}">
                            <strong>{{ 'pieza' | translate }}</strong> {{ maquina.ejecuciones[0].cuantasHechas }} /
                            {{maquina.ejecuciones[0].cantidad }}
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    *ngIf="maquina.ejecuciones[0].refOF==translateService.instant('noproceso') || maquina.ejecuciones[0].procesos_Tipo == 'apagada'"
                    class="label-datos-home-noidentificado">
                    <label ID="ldatocodof" class="label-datos-home" data-toggle="tooltip" data-placement="bottom">
                      {{ 'noproceso' | translate }}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <!--COL 2 Mas de una pieza y si no es horno-->
            <div
              *ngIf="maquina.ejecuciones[0]?.cantidad > 1 && maquina.tipo_maquina!=5 && maquina.ejecuciones[0].procesos_Tipo != 'preparacion' "
              [ngClass]="
              ( ((maquina.ejecuciones[0].lote != '' || maquina.ejecuciones[0].numeroSerie != '' || maquina.ejecuciones[0].colada != '') && maquina.tipo_maquina == 1) || 
              (maquina.totalCapas > 0 && maquina.tipo_maquina == 9) || 
              ((maquina.refMaterial != '' || maquina.refMolde != '') && maquina.tipo_maquina == 4) ) && 
              (maquina.ejecuciones[0].procesos_Tipo != 'apagada' && maquina.ejecuciones[0].refOF != translateService.instant('noproceso')) 
              ? 'datos-pieza-lote-col con-datosdestacados' : 'datos-pieza-lote-col' ">

              <!-- COL 2 mas de una pieza -->
              <!-- MAQUINA -->
              <div *ngIf="maquina.loading" class="k-i-loading"></div>
              <div class="datos-destacados-cont-home" div class="datos-destacados-cont-home"
                *ngIf="(maquina.ejecuciones[0].lote != '' || maquina.ejecuciones[0].numeroSerie != '' || maquina.ejecuciones[0].colada != '') && maquina.ejecuciones[0].refOF != translateService.instant('noproceso') && maquina.ejecuciones[0].procesos_Tipo != 'apagada' && maquina.tipo_maquina == 1">
                <div
                  *ngIf="maquina.ejecuciones[0].colada != '' && maquina.ejecuciones[0].colada != undefined && maquina.ejecuciones[0].refOF != translateService.instant('noproceso')"
                  class="dato-destacado-home">

                  <kendo-label class="label" text="{{ 'colada' | translate }}"></kendo-label>
                  <kendo-label class="dato" text="{{maquina.ejecuciones[0].colada}}"></kendo-label>

                </div>
                <div
                  *ngIf="maquina.ejecuciones[0].lote != '' && maquina.ejecuciones[0].lote != undefined && maquina.ejecuciones[0].refOF != translateService.instant('noproceso')"
                  class="dato-destacado-home">

                  <kendo-label class="label" text="{{ 'lote' | translate }}"></kendo-label>
                  <kendo-label class="dato" text="{{maquina.ejecuciones[0].lote}}"></kendo-label>

                </div>
                <div
                  *ngIf="maquina.ejecuciones[0].numeroSerie != '' && maquina.ejecuciones[0].numeroSerie != undefined && maquina.ejecuciones[0].refOF != translateService.instant('noproceso')"
                  class="dato-destacado-home">

                  <kendo-label class="label" text="{{ 'nserie' | translate }}"></kendo-label>
                  <kendo-label class="dato" text="{{maquina.ejecuciones[0].numeroSerie}}"></kendo-label>

                </div>

              </div>

              <!-- ADITIVO -->
              <div class="datos-destacados-cont-home" div class="datos-destacados-cont-home"
                *ngIf="maquina.totalCapas > 0 && maquina.ejecuciones[0].refOF != translateService.instant('noproceso') && maquina.ejecuciones[0].procesos_Tipo != 'apagada' && maquina.tipo_maquina == 9">
                <div
                  *ngIf="maquina.totalCapas > 0 && maquina.ejecuciones[0].refOF != translateService.instant('noproceso')"
                  class="dato-destacado-home">

                  <kendo-label class="label" text="{{ 'capa' | translate }}"></kendo-label>
                  <kendo-label class="dato" text="{{maquina.capaActual}} / {{maquina.totalCapas}}"></kendo-label>

                </div>
              </div>

              <!-- INYECCION -->
              <div class="datos-destacados-cont-home" div class="datos-destacados-cont-home"
                *ngIf="(maquina.refMaterial != '' || maquina.refMolde != '') && maquina.ejecuciones[0].refOF != translateService.instant('noproceso') && maquina.ejecuciones[0].procesos_Tipo != 'apagada' && maquina.tipo_maquina == 4">
                <div
                  *ngIf="maquina.refMaterial != '' && maquina.ejecuciones[0].refOF != translateService.instant('noproceso')"
                  class="dato-destacado-home">
                  <kendo-label class="label" text="{{ 'refMaterial' | translate }}"></kendo-label>
                  <kendo-label class="dato" text="{{maquina.refMaterial}}"></kendo-label>
                </div>

                <div
                  *ngIf="maquina.refMolde != '' && maquina.ejecuciones[0].refOF != translateService.instant('noproceso')"
                  class="dato-destacado-home">
                  <kendo-label class="label" text="{{ 'refMolde' | translate }}"></kendo-label>
                  <kendo-label class="dato" text="{{maquina.refMolde}}"></kendo-label>
                </div>
              </div>

              <!-- COL2-ROW1 -->
              <div class="clearfix datos-home-pieza-cont">
                <!-- Grafiko borobila pieza -->
                <div class="float-left">
                  <label ID="lhora" *ngIf="" class="estado-fecha d-none">{{maquina.ejecuciones[0].fechaini}}</label>
                  <label ID="lfecha" class="estado-hora d-none">{{maquina.ejecuciones[0].horaini}}</label>
                  <div ID="enjecucion" class="porcent-cont-outer">
                    <div class="porcent-cont">
                      <label ID="lPorcentajeEjecucion" class="donut-porcent">
                        {{piezaPorcentajeMap.get(maquina.id)}}
                      </label>
                      <img ID="imgPorcentajeEjecucion" src="{{piezaimgMap.get(maquina.id)}}" />
                    </div>
                  </div>
                </div>

                <div class="float-left">
                  <div class="pieza-actual-label">
                    <kendo-label text="{{ 'piezaActual' | translate }}"></kendo-label>
                  </div>
                  <!-- Piezaren denbora -->
                  <!-- si duplicamos el tiempo del lote a la pieza, se crea un retardo de un segundo
                    para solucionarlo lo tratamos aqui para qua sea el mismo tiempo .Alex-->
                  <div class="pieza-actual-tiempo" *ngIf="maxContadorTerminado.get(maquina.id) == 0">
                    <kendo-label text="{{tiempoActualLote.get(maquina.id)}}"></kendo-label>
                  </div>
                  <div class="pieza-actual-tiempo" *ngIf="maxContadorTerminado.get(maquina.id) > 0">
                    <!-- si duplicamos el tiempo del lote a la pieza, se crea un retardo de un segundo
                    para solucionarlo lo tratamos aqui para qua sea el mismo tiempo .Alex-->
                    <div *ngIf="tiempoActualPieza.get(maquina.id) != '00:00:00' ">
                      <kendo-label text="{{tiempoActualPieza.get(maquina.id)}}"></kendo-label>
                    </div>
                    <div *ngIf="tiempoActualPieza.get(maquina.id) == '00:00:00' ">
                      <kendo-label text="{{tiempoActualLote.get(maquina.id)}}"></kendo-label>
                    </div>
                  </div>
                </div>

                <div class="separador-home"></div>

                <div class="float-left">
                  <div class="cycle-time-label">
                    <kendo-label text="Cycle time"></kendo-label>
                    <!-- <kendo-label text="{{ 'tiempo_medio' | translate }}"></kendo-label> -->
                  </div>

                  <div
                    *ngIf="piezaTiempoMedioMap.get(maquina.id)!= 0 && maxContadorTerminado.get(maquina.id) > 1 && tiempoActualLote.get(maquina.id) != '--:--:--'">
                    <div class="cycle-time-tiempo">
                      <kendo-label text="{{piezaTiempoMedioMap.get(maquina.id)}}"></kendo-label>
                    </div>
                    <!-- Estimatutako denborarekiko desbiderapena pieza -->
                    <div class="cycle-time-desviacion" *ngIf="!maquina.loading">
                      <div *ngIf="piezaDesviacionMap.get(maquina.id)?.toString().includes('+')">
                        <label style="color:red">
                          <kendo-label text="{{piezaDesviacionMap.get(maquina.id)}}"></kendo-label>
                        </label>
                      </div>
                      <div *ngIf="piezaDesviacionMap.get(maquina.id)?.toString().includes('-')">
                        <kendo-label text="{{piezaDesviacionMap.get(maquina.id)}}"></kendo-label>
                      </div>
                    </div>
                  </div>
                  <div class="cycle-time-desviacion"
                    *ngIf="(piezaTiempoMedioMap.get(maquina.id) == 0 || maxContadorTerminado.get(maquina.id) == 0 || maxContadorTerminado.get(maquina.id) == 1) && tiempoActualLote.get(maquina.id) != '--:--:--'">
                    <div class="cycle-time-tiempo">
                      <kendo-label text="{{tiempoActualPieza.get(maquina.id)}}"></kendo-label>
                    </div>
                  </div>
                </div>




                <div class="float-left">
                  <div class="estimacion-label">
                    <kendo-label text="Est."></kendo-label>
                  </div>
                  <div class="estimacion-tiempo">
                    <kendo-label text="{{piezaEstMap.get(maquina.id)}}"></kendo-label>
                  </div>
                  <div class="prediccion-label">
                    <kendo-label text="Pred."></kendo-label>
                  </div>
                  <div class="prediccion-tiempo">
                    <kendo-label text="{{piezaPredMap.get(maquina.id)}}"></kendo-label>
                  </div>
                </div>


              </div>

              <!-- COL2-ROW2 -->
              <div class="clearfix datos-home-lote-cont">
                <div class="float-left">
                  <div class="float-left">
                    <!-- Grafiko borobila lote -->
                    <label ID="lhora" *ngIf="" class="estado-fecha d-none">{{maquina.ejecuciones[0].fechaini}}</label>
                    <label ID="lfecha" class="estado-hora d-none">{{maquina.ejecuciones[0].horaini}}</label>
                    <div ID="enjecucion" class="porcent-cont-outer">
                      <div class="porcent-cont">
                        <label ID="lPorcentajeEjecucion" class="donut-porcent">
                          {{lotePorcentajeMap.get(maquina.id)}}
                        </label>
                        <img ID="imgPorcentajeEjecucion" src="{{maquina.estadoimg}}" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="float-left">
                  <div class="lote-actual-label">
                    <kendo-label text="{{ 'loteActual' | translate }}"></kendo-label>
                  </div>
                  <div class="lote-actual-tiempo">
                    <kendo-label text="{{tiempoActualLote.get(maquina.id)}}"></kendo-label>
                  </div>
                </div>

                <div class="separador-home"></div>

                <div class="float-left">
                  <!-- Denbora totalaren desbiderapena-->
                  <div class="total-time-label">
                    <kendo-label text="{{ 'desvioTotal' | translate }}"></kendo-label>
                  </div>
                  <div class="total-time-desviacion">
                    <div *ngIf="loteDesviacionMap.get(maquina.id)?.toString().includes('+')">
                      <label style="color:red">
                        <kendo-label text="{{loteDesviacionMap.get(maquina.id)}}"></kendo-label>
                      </label>
                    </div>
                    <div *ngIf="loteDesviacionMap.get(maquina.id)?.toString().includes('-')">
                      <kendo-label text="{{loteDesviacionMap.get(maquina.id)}}"></kendo-label>
                    </div>
                  </div>
                </div>

                <div class="float-left">
                  <div class="estimacion-label">
                    <kendo-label text="Est."></kendo-label>
                  </div>
                  <div class="estimacion-tiempo">
                    <kendo-label text="{{loteEstMap.get(maquina.id)}}"></kendo-label>
                  </div>
                  <div class="prediccion-label">
                    <kendo-label text="Pred."></kendo-label>
                  </div>
                  <div class="prediccion-tiempo">
                    <kendo-label text="{{lotePredMap.get(maquina.id)}}"></kendo-label>
                  </div>
                </div>
              </div>


            </div>
            <!-- COL 2 unica pieza y si no es horno -->
            <div
              *ngIf="(maquina.ejecuciones[0]?.cantidad == 0 || maquina.ejecuciones[0]?.cantidad == 1) && maquina.tipo_maquina!=5  && maquina.ejecuciones[0].procesos_Tipo != 'preparacion'"
              [ngClass]="
              ( ((maquina.ejecuciones[0].lote != '' || maquina.ejecuciones[0].numeroSerie != '' || maquina.ejecuciones[0].colada != '') && maquina.tipo_maquina == 1) || 
              (maquina.totalCapas > 0 && maquina.tipo_maquina == 9) || 
              ((maquina.refMaterial != '' || maquina.refMolde != '') && maquina.tipo_maquina == 4) ) && 
              (maquina.ejecuciones[0].procesos_Tipo != 'apagada' && maquina.ejecuciones[0].refOF != translateService.instant('noproceso')) 
              ? 'datos-pieza-lote-col con-datosdestacados' : 'datos-pieza-lote-col' ">
              <!--con-datosdestacados klasea kendu datu horiek ez badaude-->

              <!-- MAQUINA -->
              <div *ngIf="maquina.loading" class="k-i-loading"></div>
              <div class="datos-destacados-cont-home" div class="datos-destacados-cont-home"
                *ngIf="(maquina.ejecuciones[0].lote != '' || maquina.ejecuciones[0].numeroSerie != '' || maquina.ejecuciones[0].colada != '') && maquina.ejecuciones[0].refOF != translateService.instant('noproceso') && maquina.ejecuciones[0].procesos_Tipo != 'apagada' && maquina.tipo_maquina == 1">
                <div
                  *ngIf="maquina.ejecuciones[0].colada != '' && maquina.ejecuciones[0].colada != undefined && maquina.ejecuciones[0].refOF != translateService.instant('noproceso')"
                  class="dato-destacado-home">

                  <kendo-label class="label" text="{{ 'colada' | translate }}"></kendo-label>
                  <kendo-label class="dato" text="{{maquina.ejecuciones[0].colada}}"></kendo-label>

                </div>
                <div
                  *ngIf="maquina.ejecuciones[0].lote != '' && maquina.ejecuciones[0].lote != undefined && maquina.ejecuciones[0].refOF != translateService.instant('noproceso')"
                  class="dato-destacado-home">

                  <kendo-label class="label" text="{{ 'lote' | translate }}"></kendo-label>
                  <kendo-label class="dato" text="{{maquina.ejecuciones[0].lote}}"></kendo-label>

                </div>
                <div
                  *ngIf="maquina.ejecuciones[0].numeroSerie != '' && maquina.ejecuciones[0].numeroSerie != undefined && maquina.ejecuciones[0].refOF != translateService.instant('noproceso')"
                  class="dato-destacado-home">

                  <kendo-label class="label" text="{{ 'nserie' | translate }}"></kendo-label>
                  <kendo-label class="dato" text="{{maquina.ejecuciones[0].numeroSerie}}"></kendo-label>

                </div>

              </div>

              <!-- ADITIVO -->
              <div class="datos-destacados-cont-home" div class="datos-destacados-cont-home"
                *ngIf="maquina.totalCapas > 0 && maquina.ejecuciones[0].refOF != translateService.instant('noproceso') && maquina.ejecuciones[0].procesos_Tipo != 'apagada' && maquina.tipo_maquina == 9">
                <div
                  *ngIf="maquina.totalCapas > 0 && maquina.ejecuciones[0].refOF != translateService.instant('noproceso')"
                  class="dato-destacado-home">

                  <kendo-label class="label" text="{{ 'capa' | translate }}"></kendo-label>
                  <kendo-label class="dato" text="{{maquina.capaActual}} / {{maquina.totalCapas}}"></kendo-label>

                </div>
              </div>

              <!-- INYECCION -->
              <div class="datos-destacados-cont-home" div class="datos-destacados-cont-home"
                *ngIf="(maquina.refMaterial != '' || maquina.refMolde != '') && maquina.ejecuciones[0].refOF != translateService.instant('noproceso') && maquina.ejecuciones[0].procesos_Tipo != 'apagada' && maquina.tipo_maquina == 4">
                <div
                  *ngIf="maquina.refMaterial != '' && maquina.ejecuciones[0].refOF != translateService.instant('noproceso')"
                  class="dato-destacado-home">
                  <kendo-label class="label" text="{{ 'refMaterial' | translate }}"></kendo-label>
                  <kendo-label class="dato" text="{{maquina.refMaterial}}"></kendo-label>
                </div>

                <div
                  *ngIf="maquina.refMolde != '' && maquina.ejecuciones[0].refOF != translateService.instant('noproceso')"
                  class="dato-destacado-home">
                  <kendo-label class="label" text="{{ 'refMolde' | translate }}"></kendo-label>
                  <kendo-label class="dato" text="{{maquina.refMolde}}"></kendo-label>
                </div>
              </div>

              <!-- COL2-ROW1 -->
              <div class="clearfix datos-pieza-unica">
                <div class="float-left">
                  <!-- Grafiko borobila lote -->
                  <label ID="lhora" *ngIf="" class="estado-fecha d-none">{{maquina.ejecuciones[0].fechaini}}</label>
                  <label ID="lfecha" class="estado-hora d-none">{{maquina.ejecuciones[0].horaini}}</label>
                  <div ID="enjecucion" class="porcent-cont-outer">
                    <div class="porcent-cont">
                      <label ID="lPorcentajeEjecucion" class="donut-porcent">
                        {{maquina.porcentaje}}
                      </label>
                      <img ID="imgPorcentajeEjecucion" src="{{maquina.estadoimg}}" />
                    </div>
                  </div>
                </div>
                <div class="float-left">
                  <div class="lote-actual-label">
                    <kendo-label text="{{ 'piezaActual' | translate }}"></kendo-label>
                  </div>
                  <div class="lote-actual-tiempo">
                    <kendo-label text="{{tiempoActualLote.get(maquina.id)}}"></kendo-label>
                  </div>
                </div>
              </div>
              <div class="margen-borde-inferior"></div>
              <!-- COL2-ROW2 -->
              <div class="clearfix datos-pieza-unica">
                <!-- Denbora totalaren desbiderapena-->

                <div class="dato-pieza-unica">
                  <div class="total-time-label">
                    <kendo-label text="Desvio total"></kendo-label>
                  </div>
                  <div class="total-time-desviacion">
                    <div class="rojoPlusDesviacion" *ngIf="loteDesviacionMap.get(maquina.id)?.toString().includes('+')">
                      <kendo-label text="{{loteDesviacionMap.get(maquina.id)}}"></kendo-label>
                    </div>
                    <div *ngIf="loteDesviacionMap.get(maquina.id)?.toString().includes('-')">
                      <kendo-label text="{{loteDesviacionMap.get(maquina.id)}}"></kendo-label>
                    </div>
                  </div>
                </div>
                <div class="dato-pieza-unica">
                  <div class="estimacion-label">
                    <kendo-label text="Est."></kendo-label>
                  </div>
                  <div class="estimacion-tiempo">
                    <kendo-label text="{{loteEstMap.get(maquina.id)}}"></kendo-label>
                  </div>
                </div>
                <div class="dato-pieza-unica">
                  <div class="prediccion-label">
                    <kendo-label text="Pred."></kendo-label>
                  </div>
                  <div class="prediccion-tiempo">
                    <kendo-label text="{{lotePredMap.get(maquina.id)}}"></kendo-label>
                  </div>
                </div>

              </div>
              <div class="margen-borde-inferior"></div>

            </div>

            <!-- COL 2 Maquina en preparacion -->
            <div *ngIf="maquina?.ejecuciones[0]?.procesos_Tipo == 'preparacion'" [ngClass]="
              ( ((maquina.ejecuciones[0].lote != '' || maquina.ejecuciones[0].numeroSerie != '' || maquina.ejecuciones[0].colada != '') && maquina.tipo_maquina == 1) || 
              (maquina.totalCapas > 0 && maquina.tipo_maquina == 9) || 
              ((maquina.refMaterial != '' || maquina.refMolde != '') && maquina.tipo_maquina == 4) ) && 
              (maquina.ejecuciones[0].procesos_Tipo != 'apagada' && maquina.ejecuciones[0].refOF != translateService.instant('noproceso')) 
              ? 'datos-pieza-lote-col con-datosdestacados' : 'datos-pieza-lote-col' ">

              <!-- MAQUINA -->
              <div *ngIf="maquina.loading" class="k-i-loading"></div>
              <div class="datos-destacados-cont-home" div class="datos-destacados-cont-home"
                *ngIf="(maquina.ejecuciones[0].lote != '' || maquina.ejecuciones[0].numeroSerie != '' || maquina.ejecuciones[0].colada != '') && maquina.ejecuciones[0].refOF != translateService.instant('noproceso') && maquina.ejecuciones[0].procesos_Tipo != 'apagada' && maquina.tipo_maquina == 1">
                <div
                  *ngIf="maquina.ejecuciones[0].colada != '' && maquina.ejecuciones[0].colada != undefined && maquina.ejecuciones[0].refOF != translateService.instant('noproceso')"
                  class="dato-destacado-home">

                  <kendo-label class="label" text="{{ 'colada' | translate }}"></kendo-label>
                  <kendo-label class="dato" text="{{maquina.ejecuciones[0].colada}}"></kendo-label>

                </div>
                <div
                  *ngIf="maquina.ejecuciones[0].lote != '' && maquina.ejecuciones[0].lote != undefined && maquina.ejecuciones[0].refOF != translateService.instant('noproceso')"
                  class="dato-destacado-home">

                  <kendo-label class="label" text="{{ 'lote' | translate }}"></kendo-label>
                  <kendo-label class="dato" text="{{maquina.ejecuciones[0].lote}}"></kendo-label>

                </div>
                <div
                  *ngIf="maquina.ejecuciones[0].numeroSerie != '' && maquina.ejecuciones[0].numeroSerie != undefined && maquina.ejecuciones[0].refOF != translateService.instant('noproceso')"
                  class="dato-destacado-home">

                  <kendo-label class="label" text="{{ 'nserie' | translate }}"></kendo-label>
                  <kendo-label class="dato" text="{{maquina.ejecuciones[0].numeroSerie}}"></kendo-label>

                </div>

              </div>

              <!-- ADITIVO -->
              <div class="datos-destacados-cont-home" div class="datos-destacados-cont-home"
                *ngIf="maquina.totalCapas > 0 && maquina.ejecuciones[0].refOF != translateService.instant('noproceso') && maquina.ejecuciones[0].procesos_Tipo != 'apagada' && maquina.tipo_maquina == 9">
                <div
                  *ngIf="maquina.totalCapas > 0 && maquina.ejecuciones[0].refOF != translateService.instant('noproceso')"
                  class="dato-destacado-home">

                  <kendo-label class="label" text="{{ 'capa' | translate }}"></kendo-label>
                  <kendo-label class="dato" text="{{maquina.capaActual}} / {{maquina.totalCapas}}"></kendo-label>

                </div>
              </div>

              <!-- INYECCION -->
              <div class="datos-destacados-cont-home" div class="datos-destacados-cont-home"
                *ngIf="(maquina.refMaterial != '' || maquina.refMolde != '') && maquina.ejecuciones[0].refOF != translateService.instant('noproceso') && maquina.ejecuciones[0].procesos_Tipo != 'apagada' && maquina.tipo_maquina == 4">
                <div
                  *ngIf="maquina.refMaterial != '' && maquina.ejecuciones[0].refOF != translateService.instant('noproceso')"
                  class="dato-destacado-home">
                  <kendo-label class="label" text="{{ 'refMaterial' | translate }}"></kendo-label>
                  <kendo-label class="dato" text="{{maquina.refMaterial}}"></kendo-label>
                </div>

                <div
                  *ngIf="maquina.refMolde != '' && maquina.ejecuciones[0].refOF != translateService.instant('noproceso')"
                  class="dato-destacado-home">
                  <kendo-label class="label" text="{{ 'refMolde' | translate }}"></kendo-label>
                  <kendo-label class="dato" text="{{maquina.refMolde}}"></kendo-label>
                </div>
              </div>

              <!-- COL2-ROW1 -->
              <div class="clearfix datos-pieza-unica">

                <div class="float-left">
                  <!-- Grafiko borobila lote -->
                  <label ID="lhora" *ngIf="" class="estado-fecha d-none">{{maquina.ejecuciones[0].fechaini}}</label>
                  <label ID="lfecha" class="estado-hora d-none">{{maquina.ejecuciones[0].horaini}}</label>
                  <div ID="enjecucion" class="porcent-cont-outer">
                    <div class="porcent-cont">
                      <label ID="lPorcentajeEjecucion" class="donut-porcent">
                        {{preparacionPorcentajeMap.get(maquina.id)}}
                      </label>
                      <img ID="imgPorcentajeEjecucion" src="{{preparacionimgMap.get(maquina.id)}}" />
                    </div>
                  </div>
                </div>
                <div class="float-left">
                  <div class="lote-actual-label d-flex justify-content-center">
                    <kendo-label text="{{ 'tiempoPreparacion' | translate }}"></kendo-label>
                  </div>
                  <div class="lote-actual-tiempo d-flex justify-content-center">
                    <kendo-label text="{{tiempoPreparacion.get(maquina.id)}}"></kendo-label>
                  </div>
                </div>

              </div>
              <div class="margen-borde-inferior"></div>
              <!-- COL2-ROW2 -->

              <!-- Estimatutako denborarekiko desbiderapena pieza -->
              <div class="row">
                <div class="col-6">
                  <div class="row d-flex justify-content-center">
                    <kendo-label text="{{ 'desvioTotal' | translate }}"></kendo-label>
                  </div>
                  <div *ngIf="!maquina.loading">
                    <div class="row d-flex justify-content-center"
                      *ngIf="desviacionLotePreparacionMap.get(maquina.id)?.toString().includes('+')">
                      <label style="color:red">
                        <kendo-label text="{{desviacionLotePreparacionMap.get(maquina.id)}}"></kendo-label>
                      </label>
                    </div>
                    <div class="row d-flex justify-content-center"
                      *ngIf="desviacionLotePreparacionMap.get(maquina.id)?.toString().includes('-')">
                      <kendo-label text="{{desviacionLotePreparacionMap.get(maquina.id)}}"></kendo-label>
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="row d-flex justify-content-center">
                    <kendo-label text="Est."></kendo-label>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <kendo-label text="{{preparacionEstMap.get(maquina.id)}}"></kendo-label>
                  </div>
                </div>
              </div>


              <div class="margen-borde-inferior"></div>

            </div>

            <!--COL 2 si es horno-->
            <div class="datos-pieza-lote-col " *ngIf="maquina.tipo_maquina == 5 && maquina?.ejecuciones[0]?.procesos_Tipo != 'preparacion'" >
              <div *ngIf="maquina.loading" class="k-i-loading"></div>
              <!-- COL2-ROW1 -->

              <div class="clearfix datos-home-pieza-cont">

                <div class="datos-home-pieza-cont-col">

                  <div class="pieza-dato-hora">
                    <div class="euros-hora-label">
                      <kendo-label text="€/h"></kendo-label>
                    </div>
                    <div class="euros-hora-cantidad">
                      <kendo-label text="{{maquina.eurosHora}}"></kendo-label> <!-- real -->
                    </div>
                    <div class="euros-hora-estimado">
                      <kendo-label text="{{maquina.eurosHoraEst}}"></kendo-label> <!-- estimatua -->
                    </div>
                  </div>
                  <!-- PORCENTAJE EUROS HORA -->
                  <div class="pieza-icono-porcentaje">
                    <div *ngIf="maquina.porcenEurosHora <= 100">
                      <i class="icon-flechaabajo-alt-2"></i>
                    </div>
                    <div *ngIf="maquina.porcenEurosHora > 100">
                      <i class="icon-flechaarriba-alt-2"></i>
                    </div>
                    <div *ngIf="maquina.porcenEurosHora == '-'">
                      <i class="icon-menos"></i>
                    </div>
                    <div class="euros-hora-porcentaje">
                      <kendo-label text="{{maquina.porcenEurosHora}}%"></kendo-label>
                    </div>
                  </div>
                </div>

                <div class="datos-home-pieza-cont-separador"></div>

                <div class="datos-home-pieza-cont-col">

                  <div class="pieza-dato-hora">
                    <div class="piezas-hora-label">
                      <kendo-label text="{{'piezash' | translate}}"></kendo-label>
                    </div>
                    <div class="piezas-hora-cantidad">
                      <kendo-label text="{{maquina.piezasHora}}"></kendo-label> <!-- real -->
                    </div>
                    <div class="piezas-hora-estimado">
                      <kendo-label text="{{maquina.piezasHoraEst}}"></kendo-label> <!-- estimado -->
                    </div>
                  </div>
                  <!-- PORCENTAJE PIEZAS HORA -->
                  <div class="pieza-icono-porcentaje">
                    <div *ngIf="maquina.porcenPiezasHora <= 100">
                      <i class="icon-flechaabajo-alt-2"></i>
                    </div>
                    <div *ngIf="maquina.porcenPiezasHora > 100">
                      <i class="icon-flechaarriba-alt-2"></i>
                    </div>
                    <div *ngIf="maquina.porcenPiezasHora == '-'">
                      <i class="icon-menos"></i>
                    </div>
                    <div class="pieza-hora-porcentaje">
                      <kendo-label text="{{maquina.porcenPiezasHora}}%"></kendo-label>
                    </div>
                  </div>

                </div>

              </div>

              <!-- COL2-ROW2 -->
              <div class="clearfix datos-home-lote-cont"
                *ngIf="maquina?.ejecuciones[0]?.procesos_Tipo != 'preparacion'">
                <div class="float-left">
                  <div class="float-left">
                    <!-- Grafiko borobila lote -->
                    <label ID="lhora" *ngIf="" class="estado-fecha d-none">{{maquina.ejecuciones[0].fechaini}}</label>
                    <label ID="lfecha" class="estado-hora d-none">{{maquina.ejecuciones[0].fechaini}}</label>
                    <div ID="enjecucion" class="porcent-cont-outer">
                      <div class="porcent-cont">
                        <label ID="lPorcentajeEjecucion" class="donut-porcent">
                          {{maquina.porcentaje}}
                        </label>
                        <img ID="imgPorcentajeEjecucion" src="{{maquina.estadoimg}}" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="float-left">
                  <div class="lote-actual-label">
                    <kendo-label text="Lote actual"></kendo-label>
                  </div>
                  <div class="lote-actual-tiempo">
                    <kendo-label text="{{tiempoActualLote.get(maquina.id)}}"></kendo-label>
                  </div>
                </div>


                <div class="float-left">
                  <!-- Denbora totalaren desbiderapena-->
                  <div class="total-time-label">
                    <kendo-label text="Total time"></kendo-label>
                  </div>
                  <div class="total-time-desviacion">
                    <div *ngIf="loteDesviacionMap.get(maquina.id)?.toString().includes('+')">
                      <label style="color:red">
                        <kendo-label text="{{loteDesviacionMap.get(maquina.id)}}"></kendo-label>
                      </label>
                    </div>
                    <div *ngIf="loteDesviacionMap.get(maquina.id)?.toString().includes('-')">
                      <kendo-label text="{{loteDesviacionMap.get(maquina.id)}}"></kendo-label>
                    </div>
                  </div>
                </div>

                <div class="float-left">
                  <div class="estimacion-label">
                    <kendo-label text="Est."></kendo-label>
                  </div>
                  <div class="estimacion-tiempo">
                    <kendo-label text="{{maquina.tiempoEst}}"></kendo-label>
                  </div>
                  <div class="prediccion-label">
                    <kendo-label text="Pred."></kendo-label>
                  </div>
                  <div class="prediccion-tiempo">
                    <kendo-label text="{{maquina.tiempoPred}}"></kendo-label>
                  </div>
                </div>
              </div>

              <!-- MANTENIMIENTO -->
              <div class="clearfix datos-home-lote-cont"
                *ngIf="maquina?.ejecuciones[0]?.procesos_Tipo == 'preparacion'">

                <div class="float-left">
                  <div class="lote-actual-label">
                    <kendo-label text="Tiempo Mantenimiento"></kendo-label>
                  </div>
                  <div class="lote-actual-tiempo">
                    <kendo-label text="{{tiempoMantenimiento.get(maquina.id)}}"></kendo-label>
                  </div>
                </div>
                <div class="float-left">
                  <!-- Denbora totalaren desbiderapena-->
                  <div class="total-time-label">
                    <kendo-label text="Tiempo Estimado Preparacion"></kendo-label>
                  </div>
                  <div class="total-time-desviacion">
                    <label>
                      <kendo-label text="{{this.mantenimientoEstMap.get(maquina.id)}}"></kendo-label>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- ROW 3 -->
          <div class="clearfix d-flex">
            <!-- COL 1 -->
            <div class="cont-ejes-home">
              <!-- CUTRO DONUTS-->
              <div class="ejes-grupo-home" *ngIf="maquina.tipo_maquina == 1 && !maquina.puestoOffline">

                <!-- Donut completo 1 -->
                <div id="DivEje1" class="donut-grupo-cm" *ngIf="maquina.ejesActivos>=3">
                  <div class="donut-porcent-cont">
                    <div class="alerticonos-linea">
                      <div class="alerticonos-cont">
                        <img ID="ImgAlertTemp1" *ngIf="maquina.ejes[0].tempvisible==true"
                          src="{{maquina.ejes[0].logoalarmatemp}}" />
                      </div>
                      <div class="alerticonos-cont">
                        <img ID="ImgWarning1" *ngIf="maquina.ejes[0].consumvisible==true"
                          src="{{maquina.ejes[0].logoalarmaconsum}}" />
                      </div>
                    </div>
                    <label ID="L1Speed" class="donut-porcent">{{maquina.ejes[0].rpm}}</label>
                    <label ID="LnombreEje1" class="donut-porcent-rpm">{{maquina.ejes[0].nombre}}</label>
                  </div>
                  <img ID="Imagen1Speed" class="donut-img"
                    src="assets/DC48Verde/dc{{maquina.ejes[0].valorGrafico}}.png" />
                </div>

                <!-- Donut completo 2 -->
                <div id="DivEje2" class="donut-grupo-cm" *ngIf="maquina.ejesActivos>=4">
                  <div class="donut-porcent-cont">
                    <div class="alerticonos-linea">
                      <div class="alerticonos-cont">
                        <img ID="ImgAlertTemp2" *ngIf="maquina.ejes[0].tempvisible==true"
                          src="{{maquina.ejes[1].logoalarmatemp}}" />
                      </div>
                      <div class="alerticonos-cont">
                        <img ID="ImgWarning2" *ngIf="maquina.ejes[1].consumvisible==true"
                          src="{{maquina.ejes[1].logoalarmaconsum}}" />
                      </div>
                    </div>
                    <label ID="L2Speed" class="donut-porcent">{{maquina.ejes[1].rpm}}</label>
                    <label ID="LnombreEje2" class="donut-porcent-rpm">{{maquina.ejes[1].nombre}}</label>
                  </div>
                  <img ID="Imagen2Speed" class="donut-img"
                    src="assets/DC48Verde/dc{{maquina.ejes[1].valorGrafico}}.png" />
                </div>

                <!-- Donut completo 3 -->
                <div id="DivEje3" class="donut-grupo-cm" *ngIf="maquina.potenciometro!=undefined">
                  <div class="donut-porcent-cont">
                    <div class="alerticonos-linea">
                    </div>
                    <label ID="L3Speed" class="donut-porcent">{{maquina.potenciometrostr}}</label>
                    <label ID="LnombreEje3" class="donut-porcent-rpm">{{'potencabezal' | translate
                      }}</label>
                  </div>
                  <img ID="Imagen3Speed" class="donut-img" src="assets/DC48Verde/dc{{maquina.potenciometro}}.png" />
                </div>

                <!-- Donut completo 4 -->
                <div id="DivEje4" class="donut-grupo-cm" *ngIf="maquina.potenciometro2!=undefined">
                  <div class="donut-porcent-cont">
                    <div class="alerticonos-linea">
                    </div>
                    <label ID="L4Speed" class="donut-porcent">{{maquina.potenciometrostr2}}</label>
                    <label ID="LnombreEje4" class="donut-porcent-rpm">{{'potenavances' | translate
                      }}</label>
                  </div>
                  <img ID="Imagen4Speed" class="donut-img" src="assets/DC48Verde/dc{{maquina.potenciometro2}}.png" />
                </div>
              </div>

              <!--CUATRO DONUTS SI ES HORNO-->
              <div class="ejes-grupo-home oee-home-hornos"
                *ngIf="maquina.tipo_maquina == 5 || maquina.tipo_maquina == 4 && !maquina.puestoOffline">

                <!-- Donut completo 1 OEE -->
                <div id="DivEje1" class="donut-grupo-cm">
                  <div class="donut-porcent-cont">
                    <label ID="L1Speed" class="donut-porcent">{{maquina.oee[0].valor}}</label>
                    <label ID="LnombreEje1" class="donut-porcent-rpm">{{'oee' | translate}}</label>
                  </div>
                  <img ID="Imagen1Speed" class="donut-img"
                    src="assets/DC48Verde/dc{{maquina.oee[0].valorGrafico}}.png" /> <!--HAU ALDATU-->
                </div>

                <!-- Donut completo 2 DISPONIBILIDAD -->
                <div id="DivEje2" class="donut-grupo-cm">
                  <div class="donut-porcent-cont">
                    <label ID="L2Speed" class="donut-porcent">{{maquina.oee[1].valor}}</label>
                    <label ID="LnombreEje2" class="donut-porcent-rpm">{{'disp' | translate}}</label>
                  </div>
                  <img ID="Imagen1Speed" class="donut-img"
                    src="assets/DC48Verde/dc{{maquina.oee[1].valorGrafico}}.png" /> <!--HAU ALDATU-->
                </div>

                <!-- Donut completo 3 RENDIMIENTO -->
                <div id="DivEje3" class="donut-grupo-cm">
                  <div class="donut-porcent-cont">
                    <label ID="L3Speed" class="donut-porcent">{{maquina.oee[2].valor}}</label>
                    <label ID="LnombreEje3" class="donut-porcent-rpm">{{'rend' | translate
                      }}</label>
                  </div>
                  <img ID="Imagen1Speed" class="donut-img"
                    src="assets/DC48Verde/dc{{maquina.oee[2].valorGrafico}}.png" /> <!--HAU ALDATU-->
                </div>

                <!-- Donut completo 4 CALIDAD -->
                <div id="DivEje4" class="donut-grupo-cm">
                  <div class="donut-porcent-cont">
                    <label ID="L4Speed" class="donut-porcent">{{maquina.oee[3].valor}}</label>
                    <label ID="LnombreEje4" class="donut-porcent-rpm">{{'cal' | translate
                      }}</label>
                  </div>
                  <img ID="Imagen1Speed" class="donut-img"
                    src="assets/DC48Verde/dc{{maquina.oee[3].valorGrafico}}.png" /> <!--HAU ALDATU-->
                </div>
              </div>

            </div>
            <!-- COL 2 -->
            <div class="cont-resumen-semana-home">
              <!-- Info Semanal -->
              <div ID="resumenSemana" class="planificador">
                <a ID="hlResumenMaquina" class="linkresumensemana" (click)="RedirigirRendimiento(maquina.id)">

                  <div class="resumensemana-panel-content">
                    <label ID="lResumenSemana" class="titulo-seccion-maquina">{{ 'resumensemana' |
                      translate }}</label>
                    <div class="bloques-semana-maquina">
                      <!--PREPARACION-->
                      <span ID="lPorcentajePreparacionSemana"
                        class="box-porcent box-verde">{{maquina.tiempoPorSemana[3].porcentaje}}</span>
                      <!--EJECUCIONES-->
                      <span ID="lPorcentajeEjecucionSemana"
                        class="box-porcent box-verdedos">{{maquina.tiempoPorSemana[0].porcentaje}}</span>
                      <!--MICROPARADAS-->
                      <span ID="lPorcentajeApagadaSemana"
                        class="box-porcent box-naranja">{{maquina.tiempoPorSemana[2].porcentaje}}</span>
                      <!--PARADAS-->
                      <span ID="lPorcentajeParadaSemana"
                        class="box-porcent box-amarillo">{{maquina.tiempoPorSemana[1].porcentaje}}</span>
                      <!--MANTENIMIENTO-->
                      <span ID="lPorcentajeMantenimientoSemana"
                        class="box-porcent box-azul">{{maquina.tiempoPorSemana[4].porcentaje}}</span>
                      <!--ALARMAS-->
                      <span ID="lPorcentajeAlarmaSemana"
                        class="box-porcent box-rojo">{{maquina.tiempoPorSemana[5].porcentaje}}</span>
                      <!--APAGADAS-->
                      <span ID="lPorcentajeApagadaSemana"
                        class="box-porcent box-negro">{{maquina.tiempoPorSemana[6].porcentaje}}</span>
                    </div>
                  </div>
                </a>
              </div>

              <!-- EXTRUSORAS-->
              <!-- RENDIMIENTO SOPLADORES Y INFO CONTROL DE ESPESORES-->
              <div class="ejes-grupo-home" *ngIf="maquina.tipo_maquina == 2" style="margin-top: -10px;">
                <div class="row">
                  <div class="col-6">
                    <div class="row" style="background-color: #f0f5ff; margin: 0 -5px 0 0; padding: 6px 0px 6px 0px;">
                      <div class="col-12">
                        <label class="panel-ext-label-title-home">{{ 'rendSopladores' |
                          translate
                          }}</label>
                      </div>
                      <div class="col-12">
                        <label class="panel-ext-label-text">{{ maquina.rendSopladores.toFixed(1)
                          }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row" style="background: #f0f5ff; margin: 0 10px 0 -12px; padding: 6px 0px 6px 0px;">
                      <div class="col-12">
                        <label class="panel-ext-label-title-home">{{ 'controlEsp' | translate
                          }}</label>
                      </div>
                      <div class="col-12">
                        <label class="panel-ext-label-text">{{ maquina.controlEsp.toFixed(1)
                          }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- IMPRESORAS PLASTICO-->
              <!-- SECADORES-->
              <div class="ejes-grupo-home" *ngIf="maquina.tipo_maquina == 3" style="margin-top: -10px;">
                <div class="row">
                  <div class="col-4">
                    <div class="row" style="background-color: #f0f5ff; margin: 0 -16px 0 0; padding: 6px 0px 6px 0px;">
                      <div class="col-12">
                        <label class="panel-ext-label-title-home">{{ 'velocidad' | translate
                          }}</label>
                      </div>
                      <div class="col-12">
                        <label class="panel-ext-label-text">{{ maquina.speed.toFixed(1)
                          }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="row"
                      style="background-color: #f0f5ff; margin: 0 -8px 0 -8px; padding: 6px 0px 6px 0px;">
                      <div class="col-12">
                        <label class="panel-ext-label-title-home">{{ 'secTempBCD' | translate
                          }}</label>
                      </div>
                      <div class="col-12">
                        <label class="panel-ext-label-text">{{ maquina.secTempBCD.toFixed(1)
                          }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="row" style="background: #f0f5ff; margin: 0 0 0 -16px; padding: 6px 0px 6px 0px;">
                      <div class="col-12">
                        <label class="panel-ext-label-title-home">{{ 'secTunelTempBCD' |
                          translate
                          }}</label>
                      </div>
                      <div class="col-12">
                        <label class="panel-ext-label-text">{{
                          maquina.secTunelTempBCD.toFixed(1)
                          }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>



        </div>
        <!-- Info Alarma -->
        <div id="infoAlarma" class="panel-alarma" *ngIf="maquina.tipo_maquina != 4 && maquina.tipo_maquina != 5">
          <label *ngIf="maquina.ejecuciones[0].procesos_Tipo.toString()=='alarma'"
            ID="LdescripcionAlarma">{{maquina.ejecuciones[0].descripcion}}</label>
        </div>
        <div class="contbarra" *ngIf="maquina.enEjecucion==true">
          <div class="barrainner"></div>
        </div>
      </div>

      <!--ALERTAS KW Y TEMPERATURA PARA HORNO-->
      <ng-container *ngIf="maquina.tipo_maquina == 5">
        <div *ngIf="maquina.kilowatio" class="alerta-kw d-none">Kw</div>
        <div *ngIf="maquina.temperatura" class="alerta-temperatura d-none"><i class="fas fa-thermometer-full"
            style="margin-top:3px; font-size: 20px;"></i></div>
      </ng-container>
    </div>
  </ng-container>
  <!--FIN MAQUINAS-->


  <!--INSTALACIONES-->
  <ng-container *ngFor="let instalacion of instalaciones; let i = index">

    <!-- Si tiene una columna -->
    <div class="form-horizontal col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-2 panelosoa instalacion-estructura-1"
      *ngIf="instalacion.estructura != 2 && instalacion.idsGrupos.includes(appComponent.selectedGrupo.id) && r1 == true && r2 == true">
      <div class="inner-panel">

        <div class="clearfix">
          <!-- ROW 1 -->
          <a (click)="RedirigirAProcesoInstalacion(instalacion.id)">
            <!-- Imagen de las instalaciones -->
            <div class="fotomaquina pqn">
              <img ID="fotomaquina" src="{{instalacion.imagenBase64}}" />
            </div>

            <!-- Nombre de la instalacion -->
            <label class="nombre-instalacion" title="{{instalacion.nombre}}">{{instalacion.nombre}}</label>

          </a>
        </div>

        <div class="clearfix datos-cols-cont">
          <!-- <div class="datos-of-col"> -->
          <div class="instalacion-nombre-cont" *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT != 9"><label
              class="instalacion-nombre"> {{ instalacion['m1Inf']?.nombre }} </label></div>
          <div class="instalacion-nombre-cont"
            *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 9 && !showLabelResumenSemana"><label
              class="instalacion-nombre"> {{ instalacion['m1Inf']?.nombre }} </label></div>

          <!-- GRAFICOS -->
          <div *ngIf="[1, 10, 11, 12].includes(instalacion['m1Inf']?.id_tipoGrafico_DAT)"
            id="{{'graficoBarras_1_' + i}}"></div>
          <div *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 2" id="{{'graficoDonut_1_' + i}}"></div>
          <div *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 4" id="{{'graficoLineas_1_' + i}}"></div>
          <div class="grafico-presion" *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 5"
            id="{{'graficoPresion_1_' + i}}"></div>

          <div class="cont-grafico-temperatura-label" *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 6">

            <grafico-temperatura *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 6" id="{{'graficoTemperatura_1'}}"
              [min]="instalacion['m1Inf']?.min" [max]="instalacion['m1Inf']?.max" [value]="instalacion['m1Inf']?.value">
            </grafico-temperatura>

            <div class="cont-grafico-temperatura-info">
              <div class="grafico-temperatura-icono">
                <i class="icon-temperatura"></i>
              </div>
              <div class="grafico-temperatura-labels" *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 6">

                <label> {{ instalacion['m1Inf']?.value }} </label>
                <label class="grafico-dato-unidad">{{ instalacion['m1Inf']?.unidad | translate }}</label>
              </div>
            </div>
          </div>

          <div class="cont-grafico-posicion-label" *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 7">

            <div class="cont-grafico-posicion-info">
              <div class="grafico-posicion-icono">
                <i *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 7" class="icon-objetivo"></i>
              </div>
              <div class="grafico-posicion-labels">
                <label *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 7"> {{ instalacion['m1Inf']?.value }} </label>
                <label class="grafico-dato-unidad">{{ instalacion['m1Inf']?.unidad | translate }}</label>
              </div>
            </div>

            <grafico-posicion *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 7" id="{{'graficoPosicion_1'}}"
              [min]="instalacion['m1Inf']?.min" [max]="instalacion['m1Inf']?.max" [value]="instalacion['m1Inf']?.value"
              [minBarra]="instalacion['m1Inf']?.min" [maxBarra]="instalacion['m1Inf']?.max"> </grafico-posicion>
          </div>

          <div class="cont-grafico-nivel-label" *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 3">
            <grafico-nivel *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 3" id="{{'graficoNivel_1'}}"
              [min]="instalacion['m1Inf']?.min" [max]="instalacion['m1Inf']?.max" [value]="instalacion['m1Inf']?.value">
            </grafico-nivel>

            <div class="cont-grafico-nivel-info">
              <div class="grafico-nivel-labels">
                <label class="grafico-nivel-label" *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 3"> {{
                  instalacion['m1Inf']?.value }} </label>
                <label class="grafico-dato-unidad">{{ instalacion['m1Inf']?.unidad | translate }}</label>
              </div>
            </div>
          </div>

          <div class="cont-grafico-dato-label" *ngIf="[8, 13, 14, 15].includes(instalacion['m1Inf']?.id_tipoGrafico_DAT)">
            <div class="grafico-dato-icono">
              <i class="{{ instalacion['m1Inf']?.icono }}"></i>
            </div>
            <div class="grafico-dato-labels">
              <label>{{instalacion['m1Inf']?.value}}</label>
              <label class="grafico-dato-unidad">{{ instalacion['m1Inf']?.unidad | translate }}</label>
            </div>
          </div>

          <div *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 9 && !showLabelResumenSemana"
            id="{{'graficoResumenSemana_1_' + i}}"></div>
          <div *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 9"><span *ngIf="showLabelResumenSemana">{{
              'noDatosParaMostrar' | translate }}</span></div>

          <!-- </div> -->
        </div>

      </div>
    </div>

    <!-- Si tiene dos columnas -->
    <div class="form-horizontal col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 panelosoa instalacion-estructura-2"
      *ngIf="instalacion.estructura == 2 && instalacion.idsGrupos.includes(appComponent.selectedGrupo.id) && r1 == true && r2 == true">
      <div class="inner-panel">

        <div class="clearfix">
          <!-- ROW 1 -->
          <a (click)="RedirigirAProcesoInstalacion(instalacion.id)">
            <div class="clearfix">
              <!-- Imagen de las maquinas-->
              <div ID="maquina" class="maquina">
                <div id="estado">
                  <div class="fotomaquina pqn">
                    <img ID="fotomaquina" src="{{instalacion.imagenBase64}}" />
                  </div>
                </div>
              </div>


              <div class="header-home-maquina clearfix">
                <!-- Nombre de la maquina -->
                <div class="modelo-operario-cont">
                  <div class="nombre-modelo-maquina">
                    <div class="modelo-maquina">
                      <label ID="lmodelo" title="{{instalacion.nombre}}">{{instalacion.nombre}}</label>
                    </div>
                  </div>

                </div>


              </div>
            </div>
          </a>
        </div>

        <!-- GRAFICOS -->
        <div class="clearfix datos-cols-cont">

          <!-- 1 -->
          <div class="datos-of-col">
            <div class="instalacion-nombre-cont" *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT != 9"><label
                class="instalacion-nombre"> {{ instalacion['m1Inf']?.nombre }} </label></div>
            <div class="instalacion-nombre-cont"
              *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 9 && !showLabelResumenSemana"><label
                class="instalacion-nombre"> {{ instalacion['m1Inf']?.nombre }} </label></div>

            <div *ngIf="[1, 10, 11, 12].includes(instalacion['m1Inf']?.id_tipoGrafico_DAT)"
              id="{{'graficoBarras_1_' + i}}"></div>
            <div *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 2" id="{{'graficoDonut_1_' + i}}"></div>
            <div *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 4" id="{{'graficoLineas_1_' + i}}"></div>
            <div class="grafico-presion" *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 5"
              id="{{'graficoPresion_1_' + i}}"></div>

            <div class="cont-grafico-temperatura-label" *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 6">

              <grafico-temperatura *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 6" id="{{'graficoTemperatura_1'}}"
                [min]="instalacion['m1Inf']?.min" [max]="instalacion['m1Inf']?.max"
                [value]="instalacion['m1Inf']?.value"> </grafico-temperatura>

              <div class="cont-grafico-temperatura-info">
                <div class="grafico-temperatura-icono">
                  <i class="icon-temperatura"></i>
                </div>
                <div class="grafico-temperatura-labels" *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 6">

                  <label> {{ instalacion['m1Inf']?.value }} </label>
                  <label class="grafico-dato-unidad">{{ instalacion['m1Inf']?.unidad | translate }}</label>
                </div>
              </div>
            </div>

            <div class="cont-grafico-posicion-label" *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 7">

              <div class="cont-grafico-posicion-info">
                <div class="grafico-posicion-icono">
                  <i *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 7" class="icon-objetivo"></i>
                </div>
                <div class="grafico-posicion-labels">
                  <label *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 7"> {{ instalacion['m1Inf']?.value }}
                  </label>
                  <label class="grafico-dato-unidad">{{ instalacion['m1Inf']?.unidad | translate }}</label>
                </div>
              </div>

              <grafico-posicion *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 7" id="{{'graficoPosicion_1'}}"
                [min]="instalacion['m1Inf']?.min" [max]="instalacion['m1Inf']?.max"
                [value]="instalacion['m1Inf']?.value" [minBarra]="instalacion['m1Inf']?.min"
                [maxBarra]="instalacion['m1Inf']?.max"> </grafico-posicion>
            </div>

            <div class="cont-grafico-nivel-label" *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 3">
              <grafico-nivel *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 3" id="{{'graficoNivel_1'}}"
                [min]="instalacion['m1Inf']?.min" [max]="instalacion['m1Inf']?.max"
                [value]="instalacion['m1Inf']?.value"> </grafico-nivel>

              <div class="cont-grafico-nivel-info">
                <div class="grafico-nivel-labels">
                  <label class="grafico-nivel-label" *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 3"> {{
                    instalacion['m1Inf']?.value }} </label>
                  <label class="grafico-dato-unidad">{{ instalacion['m1Inf']?.unidad | translate }}</label>
                </div>
              </div>
            </div>

            <div class="cont-grafico-dato-label" *ngIf="[8, 13, 14, 15].includes(instalacion['m1Inf']?.id_tipoGrafico_DAT)">
              <div class="grafico-dato-icono">
                <i class="{{ instalacion['m1Inf']?.icono }}"></i>
              </div>
              <div class="grafico-dato-labels">
                <label>{{instalacion['m1Inf']?.value}}</label>
                <label class="grafico-dato-unidad">{{ instalacion['m1Inf']?.unidad | translate }}</label>
              </div>
            </div>

            <div *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 9 && !showLabelResumenSemana"
              id="{{'graficoResumenSemana_1'}}"></div>
            <div *ngIf="instalacion['m1Inf']?.id_tipoGrafico_DAT == 9"><span *ngIf="showLabelResumenSemana">{{
                'noDatosParaMostrar' | translate }}</span></div>



          </div>
          <!-- 2 -->
          <div class="datos-of-col">
            <!-- <div class="clearfix datos-cols-cont"> -->
            <div class="instalacion-nombre-cont" *ngIf="instalacion['m2Inf']?.id_tipoGrafico_DAT != 9"><label
                class="instalacion-nombre"> {{ instalacion['m2Inf']?.nombre }} </label></div>
            <div class="instalacion-nombre-cont"
              *ngIf="instalacion['m2Inf']?.id_tipoGrafico_DAT == 9 && !showLabelResumenSemana"><label
                class="instalacion-nombre"> {{ instalacion['m2Inf']?.nombre }} </label></div>

            <div *ngIf="[1, 10, 11, 12].includes(instalacion['m2Inf']?.id_tipoGrafico_DAT)"
              id="{{'graficoBarras_2_' + i}}"></div>
            <div *ngIf="instalacion['m2Inf']?.id_tipoGrafico_DAT == 2" id="{{'graficoDonut_2_' + i}}"></div>
            <div *ngIf="instalacion['m2Inf']?.id_tipoGrafico_DAT == 4" id="{{'graficoLineas_2_' + i}}"></div>
            <div class="grafico-presion" *ngIf="instalacion['m2Inf']?.id_tipoGrafico_DAT == 5"
              id="{{'graficoPresion_2_' + i}}"></div>

            <div class="cont-grafico-temperatura-label" *ngIf="instalacion['m2Inf']?.id_tipoGrafico_DAT == 6">

              <grafico-temperatura *ngIf="instalacion['m2Inf']?.id_tipoGrafico_DAT == 6" id="{{'graficoTemperatura_1'}}"
                [min]="instalacion['m1Inf']?.min" [max]="instalacion['m1Inf']?.max"
                [value]="instalacion['m2Inf']?.value"> </grafico-temperatura>

              <div class="cont-grafico-temperatura-info">
                <div class="grafico-temperatura-icono">
                  <i class="icon-temperatura"></i>
                </div>
                <div class="grafico-temperatura-labels" *ngIf="instalacion['m2Inf']?.id_tipoGrafico_DAT == 6">

                  <label> {{ instalacion['m2Inf']?.value }} </label>
                  <label class="grafico-dato-unidad">{{ instalacion['m2Inf']?.unidad | translate }}</label>
                </div>
              </div>
            </div>

            <div class="cont-grafico-posicion-label" *ngIf="instalacion['m2Inf']?.id_tipoGrafico_DAT == 7">

              <div class="cont-grafico-posicion-info">
                <div class="grafico-posicion-icono">
                  <i *ngIf="instalacion['m2Inf']?.id_tipoGrafico_DAT == 7" class="icon-objetivo"></i>
                </div>
                <div class="grafico-posicion-labels">
                  <label *ngIf="instalacion['m2Inf']?.id_tipoGrafico_DAT == 7"> {{ instalacion['m2Inf']?.value }}
                  </label>
                  <label class="grafico-dato-unidad">{{ instalacion['m2Inf']?.unidad | translate }}</label>
                </div>
              </div>

              <grafico-posicion *ngIf="instalacion['m2Inf']?.id_tipoGrafico_DAT == 7" id="{{'graficoPosicion_1'}}"
                [min]="instalacion['m2Inf']?.min" [max]="instalacion['m2Inf']?.max"
                [value]="instalacion['m2Inf']?.value" [minBarra]="instalacion['m2Inf']?.min"
                [maxBarra]="instalacion['m2Inf']?.max"> </grafico-posicion>
            </div>

            <div class="cont-grafico-nivel-label" *ngIf="instalacion['m2Inf']?.id_tipoGrafico_DAT == 3">
              <grafico-nivel *ngIf="instalacion['m2Inf']?.id_tipoGrafico_DAT == 3" id="{{'graficoNivel_1'}}"
                [min]="instalacion['m1Inf']?.min" [max]="instalacion['m1Inf']?.max"
                [value]="instalacion['m2Inf']?.value"> </grafico-nivel>

              <div class="cont-grafico-nivel-info">
                <div class="grafico-nivel-labels">
                  <label class="grafico-nivel-label" *ngIf="instalacion['m2Inf']?.id_tipoGrafico_DAT == 3"> {{
                    instalacion['m2Inf']?.value }} </label>
                  <label class="grafico-dato-unidad">{{ instalacion['m2Inf']?.unidad | translate }}</label>
                </div>
              </div>
            </div>

            <div class="cont-grafico-dato-label" *ngIf="[8, 13, 14, 15].includes(instalacion['m2Inf']?.id_tipoGrafico_DAT)">
              <div class="grafico-dato-icono">
                <i class="{{ instalacion['m2Inf']?.icono }}"></i>
              </div>
              <div class="grafico-dato-labels">
                <label>{{instalacion['m2Inf']?.value}}</label>
                <label class="grafico-dato-unidad">{{ instalacion['m2Inf']?.unidad | translate }}</label>
              </div>
            </div>

            <div *ngIf="instalacion['m2Inf']?.id_tipoGrafico_DAT == 9 && !showLabelResumenSemana"
              id="{{'graficoResumenSemana_2'}}"></div>
            <div *ngIf="instalacion['m2Inf']?.id_tipoGrafico_DAT == 9"><span *ngIf="showLabelResumenSemana">{{
                'noDatosParaMostrar' | translate }}</span></div>


            <!-- </div> -->
          </div>
        </div>

      </div>
    </div>

  </ng-container>

</div>