import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivosService, AlertService, MenuService, UsuariosService } from '@app/_services';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tipos-activo-detalle',
  templateUrl: './tipos-activo-detalle.component.html',
  styleUrls: ['./tipos-activo-detalle.component.less']
})
export class TiposActivoDetalleComponent implements OnInit {
  @ViewChild('popupBorrar') popupBorrar: NgbModalRef;

  submitted = false;
  form: FormGroup;
  idTipoActivo: number = 0;

  user = this.userService.userValue;

  constructor(private formBuilder: FormBuilder,
    private menuService: MenuService, public router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private userService: UsuariosService,
    private activosService: ActivosService,
    private alertService: AlertService) {
    this.menuService.titulo = this.translateService.instant('tiposActivo');
    this.idTipoActivo = this.route.snapshot.params['id'];
    this.cargarDatos();
  }

  cargarDatos() {
    //Para evitar errores
    this.form = this.formBuilder.group({
      nombre: new FormControl('', [Validators.required]),
      descripcion: new FormControl('', [Validators.required]),
      activo: new FormControl(true)
    });
    if (this.idTipoActivo > 0) {
      this.activosService.GetTiposById(Number(this.idTipoActivo)).subscribe(
        (result) => {
          this.form = this.formBuilder.group({
            nombre: new FormControl(result[0].nombre, [Validators.required]),
            descripcion: new FormControl(result[0].descripcion, [Validators.required]),
            activo: new FormControl(result[0].activo)
          });
        });
    } else { //Si es uno nuevo se crea vacío
      this.form = this.formBuilder.group({
        nombre: new FormControl('', [Validators.required]),
        descripcion: new FormControl('', [Validators.required]),
        activo: new FormControl(true)
      });
    }

  }

  ngOnInit(): void {
  }

   //BOTONES PRINCIPALES
   guardarClick() {
    this.submitted= true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    if (this.idTipoActivo == 0) {
      this.activosService.Insert_tipo(this.form.value.nombre, this.form.value.descripcion, this.form.value.activo).subscribe(
        (result: any) => {
          if(result !== -1){
            this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
            this.router.navigate(['/tiposActivos']);
          }else{
            this.alertService.error(this.translateService.instant('errorcrear'));
          }
        });
    } else {
      this.activosService.Update_tipo(this.idTipoActivo, this.form.value.nombre, this.form.value.descripcion, this.form.value.activo).subscribe(
        (result: any) => {
          if(result !== -1){
            this.alertService.success(this.translateService.instant('actualizarcorrectamente'), { keepAfterRouteChange: true });
            this.router.navigate(['/tiposActivos']);
          }else{
            this.alertService.error(this.translateService.instant('erroractualizar'));
          }
        });
    }
  }

}
