import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from "@angular/router"
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UsuariosService, AlertService, MenuService, MaquinasService, OperariosService, OperacionesService, InformeProyectosService, TurnosService, PlanificadorService } from '../_services';
import { ActivatedRoute } from '@angular/router';
import { MyFunctions } from '@app/_helpers';
import { first } from 'rxjs/operators';
import { GroupResult, groupBy } from '@progress/kendo-data-query';

// Drag and drop saiakerak
// import { closest } from '@syncfusion/ej2-base';
// import { CardSettingsModel, SortSettingsModel, KanbanComponent, ColumnsModel, CardClickEventArgs, DialogEventArgs, DragEventArgs } from '@syncfusion/ej2-angular-kanban';
// import { extend } from '@syncfusion/ej2-base';
import {
  CdkDragDrop,
  CdkDragStart,
  moveItemInArray,
  transferArrayItem,
  CdkDrag
} from '@angular/cdk/drag-drop';


@Component({
  selector: 'app-gruposPlanificador',
  templateUrl: 'gruposPlanificador.component.html'
})

export class GruposPlanificadorComponent {

  user = this.userService.userValue;

  ////////////////////////////
  //     FILTROA DATUAK START

  public filtroActivado: boolean = false;
  //PANEL MAQUINAS
  public maquina: string = "";
  public maquinas: any = [];
  public instalaciones: any = [];
  public maquinasMostradas: any = [];

  //AREA PRODUCTIVA / SECCION
  public secciones: any[] = [];
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //GRUPOS DE MAQUINAS
  public grupos: any;
  public gruposMaquinas: any;
  public gruposSeleccionados: any;

  //CARGA DE SECCIONES Y GRUPOS
  public gruposCargados: boolean = false;
  public seccionesCargadas: boolean = false;

  private dataFiltro: any;
  // Combo cargable del filtro
  public filtro_listaMaquinas: any;
  public filtro_listaOperarios: any;
  public filtro_listaTurnos: any;
  public filtro_listaHerramientas: any;
  public filtro_ocultarPartes: boolean;

  // VARIABLES input del filtro
  public permitirFiltroVacio = false; // permite vaciar el filtro por completo
  public vaciarUltimaLinea = true; // cuando no se deja vaciar por completo un filtro, este resetea la ultima linea al intentar eliminarla
  public permitirMultiplesLineasVacias = false; // perminte añadir mas lineas que las que se han rellenado
  public annadirAutomatico = true; // añade lineas automaticamente si se selecciona la columna o la operacion Esta opcion contradice la de perminir multiples lineas vacias
  public dobleClickMismaFecha = false; // permite seleccionar la misma fecha en inicio y fin en el control doble de fechas

  // LOADING filtro
  public loadingFiltro: boolean = false;

  public actualizarVisible: boolean = false;

  public filtroPorDefecto = {
    logic: { id: 1, nombre: this.translateService.instant('o') },
    group: [
      {
        logic: { id: 0, nombre: this.translateService.instant('y') },
        group: [
          {
            columna: { id: 2, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },
            operator: { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          },
          {
            columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        ]
      }
    ]
  };
  public datosFiltro = {
    logic: { id: 1, nombre: this.translateService.instant('o') },
    group: []
  };
  // ESTOS SON LOS TIPOS QUE SE ACEPTAN EN EL FILTRO: date, dateTime, comboEstrincto, comboFlexible, check, numeric, decimal, string
  public columnasFiltro = [
    { id: 2, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },
    // { id: 3, nombre: this.translateService.instant('turno'), field: "idTurno", sqlfield: "hb.tipoTurno", tipo: 'comboEstrincto' },
    { id: 4, nombre: this.translateService.instant('seccion'), field: "idSeccion", sqlfield: "maq.idSeccion", tipo: 'comboEstrincto' },
    { id: 5, nombre: this.translateService.instant('grupoMaquinas'), field: "idGrupo", sqlfield: "mgm.idMaquinasGrupo", tipo: 'comboEstrincto' },
    { id: 6, nombre: this.translateService.instant('maquina'), field: "idMaquina", sqlfield: "hb.idMaquina", tipo: 'comboEstrincto' },
    // { id: 7, nombre: this.translateService.instant('operario'), field: "idOperario", sqlfield: "hb.idOperario", tipo: 'comboEstrincto' },
    { id: 8, nombre: this.translateService.instant('of'), field: "nombreOf", sqlfield: "po.numeroOF", tipo: 'comboFlexible' },
    //{ id: 9, nombre: this.translateService.instant('cliente'), field: "nombreCliente", sqlfield: "po.nombreCliente", tipo: 'comboFlexible' },
    { id: 10, nombre: this.translateService.instant('pieza'), field: "nombrePieza", sqlfield: "po.nombrePieza", tipo: 'comboFlexible' },
    //{ id: 11, nombre: this.translateService.instant('nserie'), field: "nSerie", sqlfield: "ho.nSerie", tipo: 'string' },
    //{ id: 12, nombre: this.translateService.instant('parte'), field: "nombreParte", sqlfield: "po.nombreParte", tipo: 'comboFlexible' },
    { id: 13, nombre: this.translateService.instant('operacion'), field: "nombreOperacion", sqlfield: "po.nombreOperacion", tipo: 'comboFlexible' }
    // { id: 14, nombre: this.translateService.instant('terminado'), field: "terminado", sqlfield: "po.operacionTerminada", tipo: 'check' }
  ];

  // NO SE PUEDEN CAMBIAR LOS PROXIMOS IDs START
  //  en las funciones de nuestro a kendo se usan estos IDs, tanto los de AND/OR como los de las opciones de cada tipo de dato.
  public andOr = [
    { id: 0, nombre: this.translateService.instant('y') },
    { id: 1, nombre: this.translateService.instant('o') }
  ];
  public opcionDate = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Menor que', dobleValor: false }, //solo comentado en esta ventana
    // { id: 2, nombre: 'Menor o igual', dobleValor: false }, //solo comentado en esta ventana
    // { id: 3, nombre: 'Mayor que', dobleValor: false }, //solo comentado en esta ventana
    // { id: 4, nombre: 'Mayor o igual', dobleValor: false }, //solo comentado en esta ventana
    // { id: 5, nombre: 'Es', dobleValor: false }, //solo comentado en esta ventana
    // { id: 6, nombre: 'No es', dobleValor: false }, //solo comentado en esta ventana
    { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
    { id: 8, nombre: this.translateService.instant('noEstaEntre'), dobleValor: true }
  ];
  public opcionDateTime = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translateService.instant('menorQue'), dobleValor: false },
    { id: 2, nombre: this.translateService.instant('menorOigual'), dobleValor: false },
    { id: 3, nombre: this.translateService.instant('mayorQue'), dobleValor: false },
    { id: 4, nombre: this.translateService.instant('mayorOigual'), dobleValor: false },
    { id: 5, nombre: this.translateService.instant('es'), dobleValor: false },
    { id: 6, nombre: this.translateService.instant('noEs'), dobleValor: false }
  ];
  public opcionNumericDecimal = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translateService.instant('menorQue'), dobleValor: false },
    { id: 2, nombre: this.translateService.instant('menorOigual'), dobleValor: false },
    { id: 3, nombre: this.translateService.instant('mayorQue'), dobleValor: false },
    { id: 4, nombre: this.translateService.instant('mayorOigual'), dobleValor: false },
    { id: 5, nombre: this.translateService.instant('es'), dobleValor: false },
    { id: 6, nombre: this.translateService.instant('noEs'), dobleValor: false },
    { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
    { id: 8, nombre: this.translateService.instant('noEstaEntre'), dobleValor: true }
  ];
  public opcionComboEstricto = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Uniselect', dobleValor: false},
    { id: 2, nombre: this.translateService.instant('es'), dobleValor: true }, // solo hase van a mostrar combos multiseleccionables IN / OUT
    // { id: 3, nombre: 'No select', dobleValor: false},
    { id: 4, nombre: this.translateService.instant('noEs'), dobleValor: true }// solo hase van a mostrar combos multiseleccionables IN / OUT
  ];
  public opcionComboFlexible = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Uniselect', dobleValor: false},
    { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
    // { id: 3, nombre: 'No select', dobleValor: false},
    { id: 4, nombre: this.translateService.instant('noEs'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
    { id: 5, nombre: this.translateService.instant('empiezaPor'), dobleValor: false },
    // { id: 6, nombre: 'No empieza por', dobleValor: false},
    { id: 7, nombre: this.translateService.instant('acabaPor'), dobleValor: false },
    // { id: 8, nombre: 'No acaba por', dobleValor: false},
    { id: 9, nombre: this.translateService.instant('contiene'), dobleValor: false },
    { id: 10, nombre: this.translateService.instant('noContiene'), dobleValor: false }//,
    // { id: 11, nombre: 'Igual que', dobleValor: false},
    // { id: 12, nombre: 'Diferente a', dobleValor: false}
  ];
  public opcionString = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translateService.instant('empiezaPor'), dobleValor: false },
    // { id: 2, nombre: 'No empieza por', dobleValor: false},
    { id: 3, nombre: this.translateService.instant('acabaPor'), dobleValor: false },
    // { id: 4, nombre: 'No acaba por', dobleValor: false},
    { id: 5, nombre: this.translateService.instant('contiene'), dobleValor: false },
    { id: 6, nombre: this.translateService.instant('noContiene'), dobleValor: false },
    { id: 7, nombre: this.translateService.instant('igualQue'), dobleValor: false },
    { id: 8, nombre: this.translateService.instant('diferenteA'), dobleValor: false }
  ];

  //FECHA DOBLE
  public DaysInMonths;


  //     FILTROA DATUAK END
  ////////////////////////////


  @ViewChild('popupCopiarAVersion') popupCopiarAVersion: NgbModalRef;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;

  public listaDatos = new Map();



  public tipoElementoDrag;
  public connectedList: string[];
  public datosRoot = { uId: '-1', name: 'root', type: 'root', children: [] };
  public datosRootOriginal = { uId: '-1', name: 'root', type: 'root', children: [] };
  public datosRootCopiar = { uId: '-1', name: 'root', type: 'root', children: [] };
  public listaConnectToG = [];
  public listaConnectToO = [];


  public recolocarAnnadir: boolean = false;



  public scrollSetting;

  public allowDragAndDrop: boolean = true;
  public zabalera: number = 0;
  public pantallaListaOp: boolean = false;
  public bloquearFiltro: boolean = false;
  public status: boolean = true;

  public listaOperaciones: any[] = [];
  public listaOperacionesFiltrado: any[] = [];
  public listaOperacionesGrupos: any[] = [];
  public listaOperacionesGrupos_Operaciones: any[] = [];
  public listaMaquinas: any[] = [];
  public operacionesSelected: any[] = [];
  public maquinaSelected: any;
  public onDrag: boolean = false;
  public nuevo: boolean = true;
  public idOperacionGrupoSelected: number = -1;
  public plegarAllDat: boolean = false;
  public eliminarElemento: boolean = false;
  public dragedElement: string = "";
  public dragMode: boolean = false;
  public maxGrupoId: number = 1;
  // public ensenarPlanificador: boolean = false;
  public listaSemanasPlanificador: any[] = [];
  public versionSelected: number = 1;
  public versionCopiarSelected: any = -1;

  public versionList: any[] = [{ value: 1, text: this.translateService.instant("real") }
    , { value: 2, text: this.translateService.instant("V1") }
    , { value: 3, text: this.translateService.instant("V2") }
    , { value: 4, text: this.translateService.instant("V3") }];



  constructor(
    translate: TranslateService,
    private menuService: MenuService, public router: Router,
    private translateService: TranslateService,
    private userService: UsuariosService,
    private modalService: NgbModal,
    private alertService: AlertService,
    public route: ActivatedRoute,
    public operacionesService: OperacionesService,
    public informeProyectosService: InformeProyectosService,
    public maquinasService: MaquinasService,
    public operariosService: OperariosService,
    public turnosService: TurnosService,
    public planificadorService: PlanificadorService,
    public myFunctions: MyFunctions
  ) {

    this.translateService = translate;
    this.menuService.titulo = this.translateService.instant('gruposPlanificador').toUpperCase();
    this.userService.user.subscribe(x => this.user = x);

  }

  ngOnInit() {
    this.maquinasService.getSecciones().subscribe(result => {
      var resultLag: any = result;
      this.groupedSeccion = resultLag;
      this.maquinasService.getGruposMaquinasGrupos().subscribe(data => {
        var dataLag: any = data;
        this.gruposMaquinas = dataLag;
        this.maquinasService.getGruposMaquinas().subscribe(dato => {
          var datoLag: any = dato;
          this.grupos = datoLag.data;
          this.versionCopiarSelected = -1;
          this.cargarTurnosPlanificador();
        });
      });
    });
  }


  cargarTurnosPlanificador() {
    this.listaSemanasPlanificador = [];
    this.turnosService.Get_TurnosFuturos().subscribe(result => {

      var listaAux: any = result;
      var lag = {
        id: -1,
        anno: '1950',
        numSemana: '0',
        fechaInicio: '1950-01-01',
        fechaFin: '1950-01-02',
        // nombre: this.translateService.instant("ninguno"),
        nombreCabecera1: this.translateService.instant("ninguno"),
        nombreCabecera2: ''
      }
      var lag2 = {
        id: 0,
        anno: '1950',
        numSemana: '0',
        fechaInicio: '1950-01-01',
        fechaFin: '1950-01-02',
        //nombre: this.translateService.instant("corto"),
        nombreCabecera1: this.translateService.instant("corto"),
        nombreCabecera2: ''
      }
      this.listaSemanasPlanificador.push(lag);
      this.listaSemanasPlanificador.push(lag2);

      listaAux.forEach(element => {
        var dateSplit = element.fechaInicio.toString().split('T')
        var fechaSplit = dateSplit[0].split('-')
        var lag = {
          id: element.id,
          anno: element.anno,
          numSemana: element.numSemana,
          fechaInicio: element.fechaInicio,
          fechaFin: element.fechaFin,
          //nombre: this.translateService.instant("semanaCorto") + element.numSemana + ' ' + fechaSplit[0] + "/" + fechaSplit[1] + "/" + fechaSplit[2],
          nombreCabecera1: this.translateService.instant("semanaCorto") + element.numSemana,
          nombreCabecera2: fechaSplit[0] + "/" + fechaSplit[1] + "/" + fechaSplit[2]
        }
        this.listaSemanasPlanificador.push(lag);
      });

      this.cargarDatosMaquinas();
      var a = this.listaSemanasPlanificador;
    });
  }


  cargarDatosMaquinas() {
    this.datosRoot = { uId: '-1', name: 'root', type: 'root', children: [] };
    this.maquinas = [];
    this.maquinasService.getMaquinasAgruparProcesos().subscribe(data => {
      var maquinas: any = data;
      this.maquinas = data;
      var newChildren = []
      maquinas.forEach(maquina => {
        var datosMaquina = {
          uId: 'maq' + maquina.id.toString(),
          name: maquina.nombre,
          type: 'maq',
          children: [],
          disabled: false,
          seccion: maquina.idSeccion,
          peso: maquina.capacidadPesoMaximo,
          temperaturaMax: maquina.temperaturaMax,
          temperaturaMin: maquina.temperaturaMin,
          capacidadUnidadesMaximo: maquina.capacidadUnidadesMaximo,
          capacidadUnidadesMinimo: maquina.capacidadUnidadesMinimo
        };
        newChildren.push(datosMaquina);
      });
      this.datosRoot.children = newChildren;
      this.cargarOperaciones();
    });
  }

  cargarOperaciones() {
    this.listaOperaciones = [];
    this.listaOperacionesGrupos = [];
    this.listaOperacionesGrupos_Operaciones = [];
    this.listaOperacionesFiltrado = [];

    this.operacionesService.getOperacionesGrupos(this.versionSelected).subscribe(data => {
      var aux1: any = data;
      aux1.forEach(auxi1 => {
        this.listaOperacionesGrupos.push(auxi1);
      });
      this.operacionesService.getOperacionesGrupos_Operaciones(this.versionSelected).subscribe(data2 => {
        var aux2: any = data2;
        aux2.forEach(element => {
          this.listaOperacionesGrupos_Operaciones.push(element);
        });
        this.operacionesService.getOperacionesAgrupar().subscribe(element => {
          var aux: any = element;
          aux.forEach(auxi => {

            var operacionesGrupo = this.listaOperacionesGrupos_Operaciones.filter(f => f.idOFs_operaciones == auxi.idOperacion);
            var sumaCantidadesoperativas = 0;
            if (operacionesGrupo.length > 0) {
              operacionesGrupo.forEach(operacion => {
                sumaCantidadesoperativas = sumaCantidadesoperativas + operacion.cantidad;
              });
            }

            var infoOp = {
              cantidad: auxi.cantidad,
              fechaFin: auxi.fechaFin,
              idOF: auxi.idOF,
              idOperacion: auxi.idOperacion,
              idPieza: auxi.idPieza,
              idsMaquinas: auxi.idsMaquinas,
              nombreOperacion: auxi.nombreOperacion,
              nombrePieza: auxi.nombrePieza,
              numeroOF: auxi.numeroOF,
              peso: auxi.peso,
              unidades: auxi.unidades,
              referenciaPieza: auxi.referenciaPieza,
              temperatura: auxi.temperatura,
              tiempoEstimado: auxi.tiempoEstimado,
              paraHacer: 0,
              sumaCantidadesoperativas: sumaCantidadesoperativas + "/" + auxi.cantidad,
              cantidadSobrante: auxi.cantidad - sumaCantidadesoperativas
            };

            this.listaOperaciones.push(infoOp);
          });
          this.listaOperacionesFiltrado = this.listaOperaciones;
          this.cargarOperacionesGrupos();
          this.cargar_Filtro();
        });
      });
    });
  }


  cargarOperacionesGrupos() {
    this.datosRoot.children.forEach(maquina => {
      var listaOpGrupos = [];
      this.listaOperacionesGrupos.forEach(opGrupo => {
        if ('maq' + opGrupo.idMaquina == maquina.uId) {
          var grupoAux = {
            uId: 'grupo' + opGrupo.id.toString(),
            name: this.translateService.instant('grupo') + opGrupo.id.toString(),
            type: 'grupo',
            tiempoEst: opGrupo.tiempoEstimado,
            tiempoEstFormato: this.myFunctions.secondsTo_HH_MM_SS(opGrupo.tiempoEstimado),
            temperatura: opGrupo.temperatura,
            children: [],
            disabled: false,
            idMaquina: maquina.uId,
            NumOperaciones: 0,
            sumaCantidad: 0,
            sumaPeso: 0,
            sumaUnidades: 0,
            pesoMaquina: maquina.peso,
            temperaturaMaxMaquina: maquina.temperaturaMax,
            temperaturaMinMaquina: maquina.temperaturaMin,
            capacidadUnidadesMaximo: maquina.capacidadUnidadesMaximo,
            capacidadUnidadesMinimo: maquina.capacidadUnidadesMinimo,
            editarTiempoEst: false,
            editarTemp: false,
            plegar: false,
            bloqueado: opGrupo.fijado,
            definidoPorOperario: opGrupo.definidoPorOperario,
            semanaPlanificador: {
              id: -1,
              anno: '1950',
              numSemana: '0',
              fechaInicio: '1950-01-01',
              fechaFin: '1950-01-02',
              // nombre: this.translateService.instant("ninguno"),
              nombreCabecera1: this.translateService.instant("ninguno"),
              nombreCabecera2: ''
            },
            ensenarPlanificador: false
          };
          listaOpGrupos.push(grupoAux);
        }
      });
      maquina.children = listaOpGrupos;
    });
    this.cargarBotonesAnnadir();
    this.cargarOperacionesGruposOperaciones();
  }

  cargarBotonesAnnadir() {
    this.datosRoot.children.forEach(maquina => {
      var btnAnnadir = {
        uId: '-99',
        name: 'annadir',
        type: 'annadir',
        idMaquina: maquina.uId,
        disabled: true,
        children: [],
        semanaPlanificador: {
          id: -1,
          anno: '1950',
          numSemana: '0',
          fechaInicio: '1950-01-01',
          fechaFin: '1950-01-02',
          // nombre: this.translateService.instant("ninguno"),
          nombreCabecera1: this.translateService.instant("ninguno"),
          nombreCabecera2: ''
        }
      };
      maquina.children.push(btnAnnadir);
    });
  }

  cargarOperacionesGruposOperaciones() {
    this.datosRoot.children.forEach(maquina => {
      maquina.children.forEach(grupo => {
        var listaOpGruposOp = [];
        var sumaCantidad = 0;
        var sumaPeso = 0;
        var sumaUnidades = 0;
        this.listaOperacionesGrupos_Operaciones.forEach(opGrupoOp => {
          if ('grupo' + opGrupoOp.idOperacionesGrupo == grupo.uId) {
            var operacion = this.listaOperaciones.filter(f => f.idOperacion == opGrupoOp.idOFs_operaciones)
            var opAux = {
              uId: 'ope' + opGrupoOp.idOFs_operaciones.toString(),
              type: 'ope',
              cantidad: operacion[0].cantidad,
              fechaFin: operacion[0].fechaFin,
              idOF: operacion[0].idOF,
              idOperacion: operacion[0].idOperacion,
              idPieza: operacion[0].idPieza,
              idsMaquinas: operacion[0].idsMaquinas,
              nombreOperacion: operacion[0].nombreOperacion,
              nombrePieza: operacion[0].nombrePieza,
              numeroOF: operacion[0].numeroOF,
              peso: operacion[0].peso,
              unidades: operacion[0].unidades,
              referenciaPieza: operacion[0].referenciaPieza,
              temperatura: operacion[0].temperatura,
              tiempoEstimado: operacion[0].tiempoEstimado,
              tiempoEstimadoFormat: this.myFunctions.secondsTo_HH_MM_SS(operacion[0].tiempoEstimado),
              disabled: false,
              paraHacer: opGrupoOp.cantidad,
              plegar: false,
              idGrupo: grupo.uId,
              bloqueado: opGrupoOp.fijado
            };
            listaOpGruposOp.push(opAux);
            sumaCantidad = sumaCantidad + opGrupoOp.cantidad;
            sumaPeso = sumaPeso + (opGrupoOp.cantidad * operacion[0].peso);
            sumaUnidades = sumaUnidades + (opGrupoOp.cantidad * operacion[0].unidades)
          }


        });
        grupo.children = listaOpGruposOp;
        grupo.NumOperaciones = listaOpGruposOp.length;
        grupo.sumaCantidad = sumaCantidad;
        grupo.sumaPeso = sumaPeso.toFixed(2);
        grupo.sumaUnidades = sumaUnidades;
        // if (grupo.children.length == 0) {
        //   this.operacionesService.deleteOperacionsGrupos(parseInt(grupo.uId.replace("grupo", ""))).subscribe(result => { });
        // }
      });
    });
    this.guardarCompatibilidades();
    this.recalcularCantidadesElegidas();
  }

  guardarCompatibilidades() {
    var listaMaquinas = [];
    this.listaConnectToG = [];
    this.listaConnectToO = [];
    this.datosRoot.children.forEach(maquina => {
      this.listaConnectToG.push(maquina.uId);
      maquina.children.forEach(grupo => {
        if (grupo.uId != '-99') {
          this.listaConnectToO.push(grupo.uId);
        }
        grupo.children.forEach(operacion => {
          this.listaConnectToO.push(operacion.uId);
        });
      });
    });
    this.cargarSemanasPlanificadorGrupos();
  }

  //var gruposRestantes = maquina.children.filter(f => f.uId != this.dragedElement);

  cargarSemanasPlanificadorGrupos() {
    this.planificadorService.get_PlanificadorLargo(this.versionSelected).subscribe(element => {
      var planLargo: any = element;
      this.planificadorService.get_PlanificadorCorto(this.versionSelected).subscribe(data => {
        var planCorto: any = data;

        this.datosRoot.children.forEach(maquina => {
          maquina.children.forEach(grupo => {
            var idGrupo = parseInt(grupo.uId.replace("grupo", ""));
            var esta = false;
            planLargo.forEach(largo => {
              if (largo.idOperacionesGrupos == idGrupo) {
                // esta en largo
                esta = true;
                var lagPlanifi = this.listaSemanasPlanificador.filter(f => f.fechaInicio == largo.fechaFin);
                grupo.semanaPlanificador = lagPlanifi[0];
              }
            });
            if (!esta) {
              planCorto.forEach(corto => {
                if (corto.idOperacionesGrupos == idGrupo) {
                  // esta en corto
                  esta = true;
                  var dataCorto = {
                    id: 0,
                    anno: '1950',
                    numSemana: '0',
                    fechaInicio: '1950-01-01',
                    fechaFin: '1950-01-02',
                    nombreCabecera1: this.translateService.instant("corto"),
                    nombreCabecera2: ''
                  }
                  grupo.semanaPlanificador = dataCorto;
                }
              });
            }
            if (!esta) {
              var semanaPlanificador = {
                id: -1,
                anno: '1950',
                numSemana: '0',
                fechaInicio: '1950-01-01',
                fechaFin: '1950-01-02',
                nombreCabecera1: this.translateService.instant("ninguno"),
                nombreCabecera2: ''
              }
              grupo.semanaPlanificador = semanaPlanificador;
            }
          });
        });
        var a = this.datosRoot;
        this.datosRootOriginal = this.myFunctions.copy(this.datosRoot);
      });
    });

  }

  bloquearDrag() {
    if (!this.onDrag) {
      this.allowDragAndDrop = false
    }
  }
  desbloquearDrag() {
    this.allowDragAndDrop = true
  }

  cambioTiempoMedio() {
    console.log("Cambio Tiempo Medio")
  }
  cambioTemperaturaMedia() {
    console.log("Cambio Temperatura Media")
  }

  onClick_Agregar() {
    this.operacionesSelected;
    this.listaOperaciones;
    var elementAnnadir;
    var operacionesAgrupadas: any[] = [];
    var listaOperaciones: string = "";
    var paraHacer = 0;
    var cantidadSeleccionadaLista: string = "";

    if (this.nuevo) {
      this.listaOperacionesGrupos.forEach(grupo => {
        if (grupo.id > this.maxGrupoId) {
          this.maxGrupoId = grupo.id;
        }
      });

      this.datosRoot.children.forEach(maquina => {
        maquina.children.forEach(grupo => {
          if (parseInt(grupo.uId.replace("grupo", "")) > this.maxGrupoId) {
            this.maxGrupoId = parseInt(grupo.uId.replace("grupo", ""));
          }
        });
      });

      this.idOperacionGrupoSelected = this.maxGrupoId + 1;
    }


    if (this.operacionesSelected.length > 0) {
      this.operacionesSelected.forEach(operacion => {
        this.listaOperaciones.forEach(lista => {
          if (lista.idOperacion == operacion) {
            var opAux = {
              uId: 'ope' + operacion.toString(),
              type: 'ope',
              cantidad: lista.cantidad,
              fechaFin: lista.fechaFin,
              idOF: lista.idOF,
              idOperacion: lista.idOperacion,
              idPieza: lista.idPieza,
              idsMaquinas: lista.idsMaquinas,
              nombreOperacion: lista.nombreOperacion,
              nombrePieza: lista.nombrePieza,
              numeroOF: lista.numeroOF,
              peso: lista.peso,
              unidades: lista.unidades,
              referenciaPieza: lista.referenciaPieza,
              temperatura: lista.temperatura,
              tiempoEstimado: lista.tiempoEstimado,
              tiempoEstimadoFormat: this.myFunctions.secondsTo_HH_MM_SS(lista.tiempoEstimado),
              disabled: false,
              paraHacer: lista.paraHacer,
              plegar: false,
              idGrupo: 'grupo' + this.idOperacionGrupoSelected,
              bloqueado: false
            }

            paraHacer = lista.paraHacer;
            if (listaOperaciones == "") {
              listaOperaciones = lista.idOperacion;
            } else {
              listaOperaciones = listaOperaciones + "," + lista.idOperacion;
            }
            if (cantidadSeleccionadaLista == "") {
              cantidadSeleccionadaLista = lista.paraHacer;
            } else {
              cantidadSeleccionadaLista = cantidadSeleccionadaLista + "," + lista.paraHacer;
            }

            operacionesAgrupadas.push(opAux);
          }
        });
      });

      if (!this.nuevo) {
        this.datosRoot.children.forEach(maquina => {
          if (parseInt(maquina.uId.replace("maq", "")) == this.maquinaSelected) {
            maquina.children.forEach(grupo => {
              if (parseInt(grupo.uId.replace("grupo", "")) == this.idOperacionGrupoSelected) {
                grupo.children = [];
                grupo.children = this.myFunctions.copy(operacionesAgrupadas);
              }
            });
          }
        });
      } else {

        var childrenMaquina = [];
        this.datosRoot.children.forEach(maquina => {
          if (maquina.uId == this.maquinaSelected) {
            var grupoTiempoEst = 0;
            var grupoTemperatura = 0;
            var sumaCantidad = 0;
            var sumaPeso = 0;
            var sumaUnidades = 0;
            operacionesAgrupadas.forEach(op => {
              grupoTiempoEst = grupoTiempoEst + op.tiempoEstimado;
              grupoTemperatura = grupoTemperatura + op.temperatura;
              sumaCantidad = sumaCantidad + op.paraHacer;
              sumaPeso = sumaPeso + op.peso;
              sumaUnidades = sumaUnidades + op.unidades;
            });

            var grupoAux = {
              uId: 'grupo' + this.idOperacionGrupoSelected,
              name: this.translateService.instant('grupo') + this.idOperacionGrupoSelected,
              type: 'grupo',
              tiempoEst: Math.floor(grupoTiempoEst / operacionesAgrupadas.length),
              tiempoEstFormato: this.myFunctions.secondsTo_HH_MM_SS(Math.floor(grupoTiempoEst / operacionesAgrupadas.length)),
              temperatura: Math.floor(grupoTemperatura / operacionesAgrupadas.length),
              children: this.myFunctions.copy(operacionesAgrupadas),
              disabled: false,
              idMaquina: maquina.uId,
              NumOperaciones: operacionesAgrupadas.length,
              sumaCantidad: sumaCantidad,
              sumaPeso: sumaPeso.toFixed(2),
              sumaUnidades: sumaUnidades,
              pesoMaquina: maquina.peso,
              temperaturaMaxMaquina: maquina.temperaturaMax,
              temperaturaMinMaquina: maquina.temperaturaMin,
              capacidadUnidadesMaximo: maquina.capacidadUnidadesMaximo,
              capacidadUnidadesMinimo: maquina.capacidadUnidadesMinimo,
              editarTiempoEst: false,
              editarTemp: false,
              plegar: false,
              bloqueado: false,
              definidoPorOperario: true,
              semanaPlanificador: {
                id: -1,
                anno: '1950',
                numSemana: '0',
                fechaInicio: '1950-01-01',
                fechaFin: '1950-01-02',
                // nombre: this.translateService.instant("ninguno"),
                nombreCabecera1: this.translateService.instant("ninguno"),
                nombreCabecera2: ''
              },
              ensenarPlanificador: false
            };
            maquina.children.forEach(grupo => {
              if (grupo.type != 'annadir') {
                childrenMaquina.push(grupo);
              }
            });
            childrenMaquina.push(grupoAux);
            var btnAnnadir = {
              uId: '-99',
              name: 'annadir',
              type: 'annadir',
              idMaquina: this.maquinaSelected,
              disabled: true,
              children: [],
              semanaPlanificador: {
                id: -1,
                anno: '1950',
                numSemana: '0',
                fechaInicio: '1950-01-01',
                fechaFin: '1950-01-02',
                // nombre: this.translateService.instant("ninguno"),
                nombreCabecera1: this.translateService.instant("ninguno"),
                nombreCabecera2: ''
              }
            };
            childrenMaquina.push(btnAnnadir);
            maquina.children = [];
            maquina.children = childrenMaquina;
          }
        });

      }


      var kontGrupos = 0
      this.datosRoot.children.forEach(maquina => {
        if (maquina.uId == this.maquinaSelected) {
          kontGrupos = maquina.children.length
        }
      });

      this.pantallaListaOp = false;

      // INSERT GRUPO
      // if (this.nuevo) {
      //   this.insert(operacionesAgrupadas, this.maquinaSelected, kontGrupos, listaOperaciones, cantidadSeleccionadaLista);
      // } else {
      //   this.update(operacionesAgrupadas, this.maquinaSelected, listaOperaciones, cantidadSeleccionadaLista);
      // }


    }
    this.guardarCompatibilidades();
  }


  update(operacionesAgrupadas, idMaquina, idOperaciones, cantidad) {
    var tempSum: number = 0;
    var tempMed: number = 0;
    var denbSum: number = 0;
    var denbMed: number = 0;

    operacionesAgrupadas.forEach(element => {
      tempSum = tempSum + element.temperatura;
      denbSum = denbSum + element.tiempoEstimado;
    });

    tempMed = tempSum / operacionesAgrupadas.length;
    denbMed = denbSum / operacionesAgrupadas.length;
  }

  // updateOrden() {
  //   var listaIdOrdenes = "";
  //   var listaIdOrdenes1 = "";
  //   var listaOrdenesMaq = new Map();
  //   this.datosRoot.children.forEach(maquina => {
  //     var kont = 1;
  //     listaIdOrdenes = "";
  //     maquina.children.forEach(grupo => {
  //       if (grupo.name != 'annadir') {
  //         if (listaIdOrdenes == "") {
  //           listaIdOrdenes = maquina.uId.replace('maq', '') + '/' + grupo.uId.replace('grupo', '') + '/' + kont;
  //         } else {
  //           listaIdOrdenes = listaIdOrdenes + ',' + maquina.uId.replace('maq', '') + '/' + grupo.uId.replace('grupo', '') + '/' + kont;
  //         }
  //         kont++;
  //       }
  //     });
  //     listaOrdenesMaq.set(maquina.uId, listaIdOrdenes);
  //     if (listaIdOrdenes != "") {
  //       if (listaIdOrdenes1 == "") {
  //         listaIdOrdenes1 = listaIdOrdenes;
  //       } else {
  //         listaIdOrdenes1 = listaIdOrdenes1 + "," + listaIdOrdenes;
  //       }
  //     }

  //     this.operacionesService.updateOperacionesGrupos_Orden(listaIdOrdenes1).subscribe(result => {
  //       //this.cargarOperacionesGrupos();
  //       this.datosRoot.children.forEach(maquina => {
  //         maquina.children.forEach(grupo => {
  //           var sumaCantidades = 0;
  //           var sumaPeso = 0;
  //           var sumaUnidades = 0;
  //           grupo.children.forEach(operacion => {
  //             sumaCantidades = sumaCantidades + operacion.paraHacer;
  //             sumaPeso = sumaPeso + (operacion.paraHacer * operacion.peso);
  //             sumaUnidades = sumaUnidades + (operacion.paraHacer * operacion.unidades);
  //           });
  //           grupo.sumaCantidad = sumaCantidades;
  //           grupo.NumOperaciones = grupo.children.length;
  //           grupo.sumaPeso = sumaPeso;
  //           grupo.sumaUnidades = sumaUnidades;
  //         });
  //       });

  //       this.datosRoot.children.forEach(maquina => {
  //         var primero = true;
  //         maquina.children.forEach(grupo => {
  //           var idOperaciones = "";
  //           var cantidades = "";
  //           grupo.children.forEach(operacion => {
  //             if (idOperaciones == "") {
  //               idOperaciones = operacion.uId.replace("ope", "");
  //             } else {
  //               idOperaciones = idOperaciones + "," + operacion.uId.replace("ope", "");
  //             }
  //             if (cantidades == "") {
  //               cantidades = operacion.paraHacer;
  //             } else {
  //               cantidades = cantidades + "," + operacion.paraHacer;
  //             }
  //           });
  //           if (grupo.uId != '-99' && primero) {
  //             primero = false;
  //             // this.operacionesService.updateOperacionesGrupos(parseInt(grupo.uId.replace("grupo", "")), parseInt(maquina.uId.replace("maq", "")), this.myFunctions.HH_MM_ToSeconds(grupo.tiempoEst), grupo.temperatura, idOperaciones, cantidades).subscribe(data => {
  //             //   var a = 0;
  //             // });
  //           }
  //         });
  //       });

  //       // this.datosRoot.children.forEach(maquina => {
  //       //   maquina.children.forEach(grupo => {
  //       //     if (grupo.children.length == 0 && grupo.uId != '-99') {
  //       //       this.operacionesService.deleteOperacionsGrupos(parseInt(grupo.uId.replace('grupo', ''))).subscribe(result => {
  //       //         this.cargarDatosMaquinas();
  //       //       });
  //       //     }
  //       //   });
  //       // });

  //     });
  //   });
  //   var a = listaOrdenesMaq;
  //   var sa = listaIdOrdenes1;
  // }

  updateDenbEstTemp(idGrupo, tiempoEst, temperatura) {
    this.operacionesService.updateOperacionesGrupos_TempDenb(parseInt(idGrupo.replace('grupo', '')), this.myFunctions.HH_MM_ToSeconds(tiempoEst), temperatura).subscribe(result => { });
  }

  public connectedTo() {
    var connectList = [];
    var connectList2 = [];
    this.datosRoot.children.forEach(root => {
      connectList = this.getIdsRecursive(root).reverse();
      connectList.forEach(element => {
        connectList2.push(element);
      });
    });

    this.connectedList = connectList2;
    //return connectList2;
  }

  onDragDrop = (event: CdkDragDrop<Array<any>>) => {

    if ((event.previousContainer.id.includes('maq') && event.container.id.includes('maq')) || (event.previousContainer.id.includes('grupo') && event.container.id.includes('grupo'))) {

      if (this.eliminarElemento) {
        this.datosRoot.children.forEach(maquina => {
          var aux = this.dragedElement.split("/");
          if (event.previousContainer.id.includes('maq')) {
            var gruposRestantes = maquina.children.filter(f => f.uId != this.dragedElement);
            maquina.children = [];
            maquina.children = this.myFunctions.copy(gruposRestantes);
            //this.operacionesService.deleteOperacionsGrupos(parseInt(aux[0].replace('grupo', ''))).subscribe(result => {
            // this.cargarDatosMaquinas();
            // this.reiniciarFiltroOF();
            //});

          } else if (event.previousContainer.id.includes('grupo')) {
            var aux = this.dragedElement.split("/");
            maquina.children.forEach(grupo => {
              if (grupo.uId == aux[0]) {
                var operacionesRestantes = grupo.children.filter(f => f.uId != aux[1]);
                grupo.children = [];
                grupo.children = this.myFunctions.copy(operacionesRestantes);
                //this.operacionesService.deleteOperacionsGrupos_operaciones(parseInt(aux[0].replace('grupo', '')), parseInt(aux[1].replace('ope', ''))).subscribe(result => {
                // this.cargarDatosMaquinas();
                // this.reiniciarFiltroOF();
                //});

              }
            });
          }

        });
        this.eliminarElemento = false;
      } else {
        if (event.previousContainer === event.container) {
          moveItemInArray(
            event.container.data,
            event.previousIndex,
            event.currentIndex
          );
          //this.updateOrden()
          // } else if () {
        } else {
          transferArrayItem(
            event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex
          );
          //this.updateOrden()
        }
      }

      var listaEliminar = [];
      this.datosRoot.children.forEach(maquina => {
        maquina.children.forEach(grupo => {
          if (grupo.children.length == 0 && grupo.type != 'annadir') {
            listaEliminar.push(grupo.uId);
          }
        });

        if (listaEliminar.length > 0) {
          var childrenNew = [];
          maquina.children.forEach(grupo2 => {

            listaEliminar.forEach(idGrupo => {
              if (idGrupo != grupo2.uId) {
                childrenNew.push(grupo2);
              }
            });
          });
          maquina.children = [];
          maquina.children = this.myFunctions.copy(childrenNew);
        }


      });

      this.calcularVariablesGrupo();

    }
    this.dragMode = false;

  };

  private getIdsRecursive(item): string[] {
    let ids;
    if (this.tipoElementoDrag == 'ope' && item.type == 'maq') {
      ids = [];
    } else {
      ids = [item.uId];
    }

    item.children.forEach(childItem => {
      if (childItem.type != 'ope' && this.tipoElementoDrag != 'grupo') {
        if (childItem.type != 'annadir') {
          ids = ids.concat(this.getIdsRecursive(childItem));
        }
      }
    });
    return ids;
  }


  sortPredicate(index: number, item: CdkDrag<number>) {
    if (index >= item.dropContainer.data.length - 1 && item.data == undefined) {
      return false
    }
    else {
      return true
    }
  }

  public dragStart(e) {
    console.log('Drag start Fired');
    this.dragMode = true;
    this.tipoElementoDrag = e.type;
    this.dragedElement = "";
    //this.connectedTo();
    if (e.uId.includes("ope")) {
      this.dragedElement = e.idGrupo + "/" + e.uId;
    } else if (e.uId.includes("grupo")) {
      this.dragedElement = e.uId;
    }

    var a = e;
  }

  annadirOperacion(nombre, idMaquina) {
    this.maquina = idMaquina;
    this.listaOperacionesFiltrado = [];
    this.listaOperaciones.forEach(operacion => {
      var listaMaq = operacion.idsMaquinas.split(",");
      listaMaq.forEach(maq => {
        if (maq == idMaquina.replace("maq", "")) {
          operacion.paraHacer = 0;
          this.listaOperacionesFiltrado.push(operacion);
        }
      });
    });

    this.maquinaSelected = [];
    this.operacionesSelected = [];
    if (!this.bloquearFiltro) {
      if (nombre == 'annadir') {
        this.pantallaListaOp = true;
        this.nuevo = true;
        this.maquinaSelected = idMaquina;
      } else if (nombre.includes(this.translateService.instant('grupo'))) {
        this.nuevo = false;
        this.pantallaListaOp = true;
        this.datosRoot.children.forEach(maquina => {
          maquina.children.forEach(grupo => {
            if (grupo.name == nombre) {
              this.maquinaSelected = parseInt(maquina.uId.replace('maq', ''));
              this.idOperacionGrupoSelected = parseInt(grupo.uId.replace('grupo', ''));
              grupo.children.forEach(operacion => {
                // Calcular suma de cantidades de todas las operaciones que estan en 
                this.listaOperacionesFiltrado.forEach(opFiltrado => {
                  if (opFiltrado.idOperacion == parseInt(operacion.uId.replace('ope', ''))) {
                    opFiltrado.paraHacer = operacion.paraHacer;
                  }
                });
                this.operacionesSelected.push(parseInt(operacion.uId.replace('ope', '')));
              });
            }
          });
        });
        console.log(this.operacionesSelected);
      }
    }
    this.recalcularCantidadesElegidas();
  }

  recalcularCantidadesElegidas() {
    this.listaOperacionesFiltrado.forEach(opFiltrado => {
      var sumaTotal = 0;
      this.datosRoot.children.forEach(maquina => {
        maquina.children.forEach(grupo => {
          grupo.children.forEach(operacion => {
            if (opFiltrado.idOperacion == parseInt(operacion.uId.replace('ope', ''))) {
              sumaTotal = sumaTotal + operacion.paraHacer;
            }
          });
        });
      });
      opFiltrado.sumaCantidadesoperativas = sumaTotal + "/" + opFiltrado.cantidad;
      opFiltrado.cantidadSobrante = opFiltrado.cantidad - sumaTotal;
    });
  }



  plegar(idGrupo) {
    this.bloquearFiltro = true;
    console.log("plegar fired!")
    this.datosRoot.children.forEach(maquina => {
      maquina.children.forEach(grupo => {
        if (grupo.uId == idGrupo) {
          grupo.plegar = !grupo.plegar;
          grupo.children.forEach(operacion => {
            operacion.plegar = !operacion.plegar;
          });
        }
      });
    });
    setTimeout((l) => this.bloquearFiltro = false, 500);
  }

  plegarAll() {
    this.datosRoot.children.forEach(maquina => {
      maquina.children.forEach(grupo => {
        grupo.plegar = !this.plegarAllDat;
        grupo.children.forEach(operacion => {
          operacion.plegar = !this.plegarAllDat;
        });
      });
    });
    this.plegarAllDat = !this.plegarAllDat;
  }

  ensenarPlanificadorSemanas(idGrupo) {
    this.datosRoot.children.forEach(maquina => {
      maquina.children.forEach(grupo => {
        if (grupo.uId == idGrupo) {
          if (!grupo.ensenarPlanificador) {
            this.bloquearFiltro = true;
            grupo.ensenarPlanificador = true;
            console.log("Planificador semana fired!")



          } else {
            grupo.ensenarPlanificador = false;
            setTimeout((l) => this.bloquearFiltro = false, 500);
          }
        }
      });
    });



  }

  clickEditarTiempoEst(idGrupo) {
    this.bloquearFiltro = true;

    this.datosRoot.children.forEach(maquina => {
      maquina.children.forEach(grupo => {
        if (grupo.uId == idGrupo) {
          if (grupo.editarTiempoEst) {
            grupo.editarTiempoEst = false;
            grupo.tiempoEst = this.myFunctions.HH_MM_ToSeconds(grupo.tiempoEstFormato);
            setTimeout((l) => this.bloquearFiltro = false, 500);
            // guardar dato
            //this.updateDenbEstTemp(idGrupo, grupo.tiempoEst, grupo.temperatura);
          } else {
            grupo.editarTiempoEst = true;
          }
        }
      });
    });

    //setTimeout((l) => this.bloquearFiltro = false, 500);
  }

  clickEditarTemp(idGrupo) {
    this.bloquearFiltro = true;
    this.datosRoot.children.forEach(maquina => {
      maquina.children.forEach(grupo => {
        if (grupo.uId == idGrupo) {
          if (grupo.editarTemp) {
            grupo.editarTemp = false;
            grupo.temperatura;
            setTimeout((l) => this.bloquearFiltro = false, 500);
            // guardar dato
            //this.updateDenbEstTemp(idGrupo, grupo.tiempoEst, grupo.temperatura);
          } else {
            grupo.editarTemp = true;
          }
        }
      });
    });
    //setTimeout((l) => this.bloquearFiltro = false, 500);
  }

  onChangeFiltroOF(e) {
    var a = e;

    var listaOperacionesFiltradoAux = [];
    this.listaOperaciones.forEach(operacion => {
      var listaMaq = operacion.idsMaquinas.split(",");
      listaMaq.forEach(maq => {
        if (maq == this.maquina.replace("maq", "")) {
          operacion.paraHacer = 0;
          listaOperacionesFiltradoAux.push(operacion);
        }
      });
    });
    this.listaOperacionesFiltrado = listaOperacionesFiltradoAux.filter(f => f.numeroOF.toString().toUpperCase().includes(e.toUpperCase()))
    if (this.listaOperacionesFiltrado.length == 0){
      this.listaOperacionesFiltrado = listaOperacionesFiltradoAux.filter(f => f.referenciaPieza.toString().toUpperCase().includes(e.toUpperCase()))
      if (this.listaOperacionesFiltrado.length == 0){
        this.listaOperacionesFiltrado = listaOperacionesFiltradoAux.filter(f => f.nombreOperacion.toString().toUpperCase().includes(e.toUpperCase()))
      }
    }
  }

  reiniciarFiltroOF() {
    this.pantallaListaOp = false;
    this.maquina = "";
    setTimeout((l) => this.listaOperacionesFiltrado = this.listaOperaciones, 500);
    // this.listaOperacionesFiltrado = this.listaOperaciones;
  }

  limpiarBuscador() {

  }

  // Ekhin funtzioa grupo optimoak automatikoki sortzeko
  sugerirGrupos() {
    this.planificadorService.Reorganizar_GruposTemporal_Alex().subscribe(result => {
      this.cargarTurnosPlanificador();
    });
  }

  borrarElemento(e) {
    if (e == 1) {
      this.eliminarElemento = true;
    } else {
      this.eliminarElemento = false;
    }
  }

  cerrarMenu() {
    this.datosRoot.children.forEach(maquina => {
      maquina.children.forEach(grupo => {
        grupo.editarTemp = false;
        grupo.editarTiempoEst = false;
      });
    });
  }

  bloquear(id) {
    this.datosRoot.children.forEach(maquina => {
      maquina.children.forEach(grupo => {
        var grupoAux = false;
        if (id == grupo.uId) {
          grupo.bloqueado = !grupo.bloqueado;
          grupo.disabled = !grupo.disabled;
          grupoAux = true;
        }
        grupo.children.forEach(operacion => {
          operacion.bloqueado = grupo.bloqueado;
          operacion.disabled = grupo.disabled;
        });
      });
    });

    var a = this.datosRoot.children;
  }

  guardar() {


    // ORDEN BERRIAK GORDE
    this.datosRoot.children.forEach(maquina => {
      var kont = 1;
      maquina.children.forEach(grupo => {
        grupo.idMaquina = parseInt(maquina.uId.replace("maq", ""));
        grupo.ordenGuardar = kont;
        kont++;
      });
    });


    this.datosRootOriginal
    var listaGruposNuevos = [];
    var listaGruposEditar = [];
    var listaGruposEliminar = [];



    this.datosRootOriginal.children.forEach(maquinaOriginal => {
      if (maquinaOriginal.children.length > 1) {
        maquinaOriginal.children.forEach(grupoOriginal => {
          if (grupoOriginal.type != 'annadir') {
            this.datosRoot.children.forEach(maquinaNueva => {
              var listaGruposEditarTemp = maquinaNueva.children.filter(f => f.uId == grupoOriginal.uId);
              listaGruposEditarTemp.forEach(temp => {
                listaGruposEditar.push(temp);
              });
            });
          }
        });
      } else {
        this.datosRoot.children.forEach(maquinaNueva => {
          if (maquinaOriginal.uId == maquinaNueva.uId) {
            maquinaNueva.children.forEach(element => {
              if (element.type != 'annadir') {
                listaGruposNuevos.push(element);
              }
            });
          }
        });
      }
    });

    this.datosRoot.children.forEach(maquinaNueva => {
      maquinaNueva.children.forEach(grupoNuevo => {
        var grupoAnnadir = false;
        if (grupoNuevo.type != 'annadir') {
          //listaGruposEditar.push(grupoNuevo);
          this.datosRootOriginal.children.forEach(maquinaOriginal => {
            if (maquinaOriginal.children.length > 1) {
              maquinaOriginal.children.forEach(grupoOriginal => {
                if (grupoNuevo.uId == grupoOriginal.uId) {
                  grupoAnnadir = true;
                }
              });
            } else {
              grupoAnnadir = true;
            }

            // listaGruposEliminarTemp.forEach(temp => {
            //   if (temp.type != 'annadir') {
            //     listaGruposEliminar.push(temp);
            //   }
            // });
          });
          if (!grupoAnnadir) {
            listaGruposNuevos.push(grupoNuevo);
          }
        }
      });
    });


    this.datosRootOriginal.children.forEach(maquinaOriginal => {
      if (maquinaOriginal.children.length > 1) {
        maquinaOriginal.children.forEach(grupoOriginal => {
          if (grupoOriginal.type != 'annadir') {
            var grupoEliminar = false;
            this.datosRoot.children.forEach(maquinaNueva => {
              maquinaNueva.children.forEach(grupoNuevo => {
                if (grupoOriginal.uId == grupoNuevo.uId) {
                  grupoEliminar = true;
                }
              });
            });
            if (!grupoEliminar) {
              listaGruposEliminar.push(grupoOriginal)
            }
          }
        });
      }
    });

    var a = this.eliminarElementosRepetidos(listaGruposEditar);
    var ab = this.eliminarElementosRepetidos(listaGruposEliminar);
    var ac = this.eliminarElementosRepetidos(listaGruposNuevos);

    listaGruposNuevos.forEach(grupo => {
      var idOperaciones = ""
      grupo.children.forEach(operacion => {
        if (idOperaciones == "") {
          idOperaciones = operacion.uId.replace("ope", "");
        } else {
          idOperaciones = idOperaciones + ',' + operacion.uId.replace("ope", "");
        }
      });
    });

    // this.updateGrupos(listaGruposEditar);
    if (listaGruposEliminar.length > 0) {
      var kont = 0;
      listaGruposEliminar.forEach(eliminar => {
        if (eliminar.semanaPlanificador.nombreCabecera1 == 'Corto') {
          var planificador = 0;
        } else if (eliminar.semanaPlanificador.nombreCabecera1.includes('S')) {
          var planificador = 1;
        } else {
          var planificador = -1;
        }
        this.operacionesService.deleteOperacionesGrupos(parseInt(eliminar.uId.replace("grupo", "")), parseInt(eliminar.idMaquina.replace("maq", "")), eliminar.semanaPlanificador.fechaFin, planificador, this.versionSelected).subscribe(result => {
          kont++;
          if (kont == listaGruposEliminar.length) {
            this.updateGrupos(listaGruposEditar, listaGruposNuevos);
          }
        });
      });
    } else {
      this.updateGrupos(listaGruposEditar, listaGruposNuevos);
    }
  }

  // eliminarGrupos(listaEliminar, listaEditar, listaGuardar) {
  //   listaEliminar.forEach(eliminar => {
  //     // this.operacionesService.deleteOperacionsGrupos(parseInt(eliminar.uId.replace("grupo", ""))).subscribe(result => {

  //     // });
  //   });
  //   //this.updateGrupos(listaEditar, listaGuardar);
  // }

  updateGrupos(listaEditar, listaGruposNuevos) {
    if (listaEditar.length > 0) {
      var kont = 0;
      listaEditar.forEach(editar => {
        var idOperaciones = ""
        var denb = 0;
        var temp = 0;
        var catidadHacer = 0;
        var opParaHacer = "";
        var opFijados = "";
        editar.children.forEach(operacion => {
          if (idOperaciones == "") {
            idOperaciones = operacion.uId.replace("ope", "");
          } else {
            idOperaciones = idOperaciones + ',' + operacion.uId.replace("ope", "");
          }

          if (opParaHacer == "") {
            opParaHacer = operacion.paraHacer.toString();
          } else {
            opParaHacer = opParaHacer + ',' + operacion.paraHacer;
          }

          if (opFijados == "") {
            if (operacion.bloqueado) {
              opFijados = '1';
            } else {
              opFijados = '0';
            }
          } else {
            if (operacion.bloqueado) {
              opFijados = opFijados + ',' + '1';
            } else {
              opFijados = opFijados + ',' + '0';
            }
          }
          denb = denb + (operacion.tiempoEstimado * operacion.paraHacer);
          temp = temp + operacion.temperatura;
        });

        if (editar.semanaPlanificador.nombreCabecera1 == 'Corto') {
          var planificador = 0;
        } else if (editar.semanaPlanificador.nombreCabecera1.includes('S')) {
          var planificador = 1;
        } else {
          var planificador = -1;
        }

        this.operacionesService.updateOperacionesGrupos(parseInt(editar.uId.replace("grupo", "")), editar.idMaquina, editar.ordenGuardar, editar.tiempoEst, editar.temperatura, idOperaciones, opParaHacer, parseInt(editar.sumaCantidad), editar.bloqueado, opFijados, planificador, editar.semanaPlanificador.fechaInicio, this.versionSelected).subscribe(result => {
          var data: any = result;
          kont++;
          if (data == -1) {
            console.log("ERROR");
            this.ngOnInit();
          }
          if (kont == listaEditar.length) {
            this.guardarGrupos(listaGruposNuevos);
          }
        });
      });
    } else {
      this.guardarGrupos(listaGruposNuevos);
    }
  }

  guardarGrupos(listaGuardar) {
    if (listaGuardar.length > 0) {
      listaGuardar.forEach(guardar => {
        var idOperaciones = ""
        var denb = 0;
        var temp = 0;
        var catidadHacer = 0;
        var opParaHacer = "";
        var opFijados = "";
        guardar.children.forEach(operacion => {
          if (idOperaciones == "") {
            idOperaciones = operacion.uId.replace("ope", "");
          } else {
            idOperaciones = idOperaciones + ',' + operacion.uId.replace("ope", "");
          }

          if (opParaHacer == "") {
            opParaHacer = operacion.paraHacer.toString();
          } else {
            opParaHacer = opParaHacer + ',' + operacion.paraHacer;
          }

          if (opFijados == "") {
            if (operacion.bloqueado) {
              opFijados = '1';
            } else {
              opFijados = '0';
            }
          } else {
            if (operacion.bloqueado) {
              opFijados = opFijados + ',' + '1';
            } else {
              opFijados = opFijados + ',' + '0';
            }
          }
          denb = denb + (operacion.tiempoEstimado * operacion.paraHacer);
          temp = temp + operacion.temperatura;
        });

        if (guardar.semanaPlanificador.nombreCabecera1 == 'Corto') {
          var planificador = 0;
        } else if (guardar.semanaPlanificador.nombreCabecera1.includes('S')) {
          var planificador = 1;
        } else {
          var planificador = -1;
        }

        this.operacionesService.insertOperacionesGrupos(guardar.idMaquina, guardar.ordenGuardar, Math.floor(denb / guardar.sumaCantidad), Math.floor(temp / guardar.sumaCantidad), idOperaciones, parseInt(opParaHacer), guardar.sumaCantidades, guardar.bloqueado, opFijados, planificador, guardar.semanaPlanificador.fechaInicio, this.versionSelected).subscribe(element => {
          var result: any = element;
          if (result == -1) {
            console.log("ERROR");
          }
          this.ngOnInit();
        });

      });
    } else {
      this.ngOnInit();
    }


  }

  eliminarElementosRepetidos(array: any[]) {
    array.splice(0, array.length, ...new Set(array));
    return array;
  }

  calcularVariablesGrupo() {
    this.datosRoot.children.forEach(maquina => {
      maquina.children.forEach(grupo => {
        if (grupo.type != 'annadir') {
          var grupoCantidad = 0;
          var grupoPeso = 0;
          var grupoUnidades = 0;
          var grupoTiempoEst = 0;
          var grupoTemp = 0;
          grupo.children.forEach(operacion => {
            grupoCantidad = grupoCantidad + operacion.paraHacer;
            grupoPeso = grupoPeso + (operacion.peso * operacion.paraHacer);
            grupoUnidades = grupoUnidades + operacion.unidades;
            grupoTiempoEst = grupoTiempoEst + (operacion.tiempoEstimado * operacion.paraHacer);
            grupoTemp = grupoTemp + operacion.temperatura;
          });

          grupo.sumaCantidad = grupoCantidad;
          grupo.sumaPeso = grupoPeso.toFixed(2);
          grupo.sumaUnidades = grupoUnidades.toFixed(2);
          grupo.tiempoEst = Math.floor(grupoTiempoEst);
          grupo.tiempoEstFormato = this.myFunctions.secondsTo_HH_MM_SS(Math.floor(grupoTiempoEst));
          grupo.temperatura = Math.floor(grupoTemp / grupoCantidad);
        }

      });
    });
  }

  bloaquearTarea(idOpe, idGrupo) {
    this.bloquearFiltro = true;

    this.datosRoot.children.forEach(maquina => {
      maquina.children.forEach(grupo => {
        if (grupo.uId == idGrupo) {
          grupo.children.forEach(operacion => {
            if (operacion.uId == idOpe) {
              operacion.bloqueado = !operacion.bloqueado;
              operacion.disabled = !operacion.disabled;
            }
          });
        }
      });
    });

    setTimeout((l) => this.bloquearFiltro = false, 500);

  }

  elegirPlanificador(idGrupo, planificador) {
    this.datosRoot.children.forEach(maquina => {
      maquina.children.forEach(grupo => {
        if (grupo.uId == idGrupo) {
          grupo.semanaPlanificador = planificador;
        }
      });
    });

    this.ensenarPlanificadorSemanas(idGrupo);
  }

  versionChange(e) {
    this.versionSelected = e.value;
    this.ngOnInit();
  }

  copiarVersion() {
    this.modalReference = this.modalService.open(this.popupCopiarAVersion, { backdrop: 'static', size: 'm', keyboard: false, centered: true });
  }

  btnCopiarAVersionAceptar() {
    //this.operacionesService.copiarOperacionesGruposVersiones(this.versionSelected, this.versionCopiarSelected.value).subscribe(value => {
    this.planificadorService.copiarAVersion(this.versionSelected, this.versionCopiarSelected.value, -1).subscribe(element => {
      this.versionSelected = this.versionCopiarSelected.value;
      this.modalReference.close();
      this.ngOnInit();
    });
    //});
  }


  ////////////////////////////
  //////////FILTROA///////////
  ////////////////////////////

  refiltrarFiltro(filtro) {
    // ESTA FUNCION SE EJECUTA CUANDO SE CAMBIA LA COLUMNA DE TIPO DE FILTRADO!
    // DE:
    //  - AND --> OR
    //  - OR  --> AND
  }

  filtroTipoChanged(filtro, row, newSelection) {
    row.columna = newSelection;
    if (this.annadirAutomatico)
      this.annadirLineaFiltro(filtro);


    // DATE -
    if (row.columna.tipo == 'date') { row.operator = this.opcionDate[0]; }
    // DATETIME -
    else if (row.columna.tipo == 'dateTime') { row.operator = this.opcionDateTime[0] }
    // COMBO ESTRICTO -
    else if (row.columna.tipo == 'comboEstrincto') { row.operator = this.opcionComboEstricto[0] }
    // COMBO FLEXIBLE -
    else if (row.columna.tipo == 'comboFlexible') { row.operator = this.opcionComboFlexible[0] }
    // CHECK - NO HAY
    // NUMERIC -
    else if (row.columna.tipo == 'numeric') { row.operator = this.opcionNumericDecimal[0] }
    // DECIMAL -
    else if (row.columna.tipo == 'decimal') { row.operator = this.opcionNumericDecimal[0] }
    // STRING -
    else if (row.columna.tipo == 'string') { row.operator = this.opcionString[0] }

    this.preFiltrado(filtro, row);
  }

  borrarFiltro() {
    this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);
    this.datosRoot = this.myFunctions.copy(this.datosRootOriginal);
    this.filtroActivado = false;
    this.status = true;
  }

  onFilter() {

    var a = this.datosFiltro;
    this.filtroActivado = true;
    // Bistaratzeko elementuak bakarrik filtratuko ditu, Taldeak edo makinak ezkutatuz

    // Orden batean aplikatuko da filtroa datuak jasota dauden ordena mantenduz
    // this.datosRoot.children = [];
    var primero = true;

    // 1- MAKINA SEKZIOAK
    this.datosFiltro.group[0].group.forEach(linea => {
      if (linea.columna.id == 4) {
        var seccionesFiltradas = [];
        linea.comboSelecteds.forEach(seccion => {
          seccionesFiltradas.push(seccion.id);
        });
        if (seccionesFiltradas.length > 0) {
          if (primero) {
            var arrayTemp = this.datosRootOriginal.children;
            this.datosRoot.children = [];
            primero = false;
          } else {
            var arrayTemp = this.datosRootOriginal.children;
            this.datosRoot.children = [];
          }
          arrayTemp.forEach(maquina => {
            seccionesFiltradas.forEach(seccionFiltro => {
              if (maquina.seccion == seccionFiltro) {
                this.datosRoot.children.push(maquina);
              }
            });
          });
        }

      }
    });
    // 2- GRUPO MAQUINAS
    this.datosFiltro.group[0].group.forEach(linea => {
      if (linea.columna.id == 5) {
        var gruposMaquinaFiltrados = [];
        linea.comboSelecteds.forEach(grupoMaquina => {
          gruposMaquinaFiltrados.push(grupoMaquina.id);
        });
        if (gruposMaquinaFiltrados.length > 0) {
          if (primero) {
            var arrayTemp = this.datosRootOriginal.children;
            this.datosRoot.children = [];
            primero = false;
          } else {
            var arrayTemp = this.datosRootOriginal.children;
            this.datosRoot.children = [];
          }

          arrayTemp.forEach(maquina => {
            this.gruposMaquinas.forEach(gruposMaquina => {
              gruposMaquinaFiltrados.forEach(grupoMaquinaFiltro => {
                if (grupoMaquinaFiltro == gruposMaquina.id) {
                  if (maquina.uId.replace('maq', '') == gruposMaquina.idMaquina) {
                    this.datosRoot.children.push(maquina);
                  }
                }
              });
            });
          });
        }
      }
    });
    // 3- MAKINAK
    this.datosFiltro.group[0].group.forEach(linea => {
      if (linea.columna.id == 6) {
        var maquinasFiltradas = [];
        linea.comboSelecteds.forEach(maquina => {
          maquinasFiltradas.push(maquina.id);
        });
        if (maquinasFiltradas.length > 0) {
          if (primero) {
            var arrayTemp = this.datosRootOriginal.children;
            this.datosRoot.children = [];
            primero = false;
          } else {
            var arrayTemp = this.datosRootOriginal.children;
            this.datosRoot.children = [];
          }

          // Maquina
          var arrayTemp = this.datosRoot.children;
          arrayTemp.forEach(maquina => {
            maquinasFiltradas.forEach(idMaquina => {
              if (idMaquina.toString() == maquina.uId.replace('maq', '')) {
                this.datosRoot.children.push(maquina);
              }
            });
          });
        }
      }
    });
    // 4- FECHA (PLANIFIKATZAILEKO ASTEAK)
    this.datosFiltro.group[0].group.forEach(linea => {
      if (linea.columna.id == 2) {

      }
    });
    // 5- CLIENTE
    this.datosFiltro.group[0].group.forEach(linea => {
      if (linea.columna.id == 9) {

      }
    });
    // 6- OF
    this.datosFiltro.group[0].group.forEach(linea => {
      if (linea.columna.id == 8) {

        var OFsFiltradas = [];
        linea.comboSelecteds.forEach(OFs => {
          OFsFiltradas.push(OFs.id);
        });
        if (OFsFiltradas.length > 0) {
          if (primero) {
            var arrayTemp = this.datosRootOriginal.children;
            this.datosRoot.children = [];
            primero = false;
          } else {
            var arrayTemp = this.datosRootOriginal.children;
            this.datosRoot.children = [];
          }

          arrayTemp.forEach(maquina => {
            var grupoAux = [];
            maquina.children.forEach(grupo => {
              var ofEncontrado = false;
              grupo.children.forEach(ope => {
                OFsFiltradas.forEach(ofsFiltro => {
                  if (ope.numeroOF.toString() == ofsFiltro) {
                    ofEncontrado = true;
                  }
                });
              });
              if (ofEncontrado) {
                grupoAux.push(grupo);
                ofEncontrado = false;
              }
            });
            if (grupoAux.length > 0) {
              maquina.children.push(grupoAux);
              this.datosRoot.children.push(maquina);
            }
          });


        }
      }
    });
    // 7- PIEZA
    this.datosFiltro.group[0].group.forEach(linea => {
      if (linea.columna.id == 10) {

      }
    });
    // 8- NSERIE
    this.datosFiltro.group[0].group.forEach(linea => {
      if (linea.columna.id == 11) {

      }
    });
    // 9- OPERAZIOA
    this.datosFiltro.group[0].group.forEach(linea => {
      if (linea.columna.id == 13) {

      }
    });


    this.datosFiltro.group[0].group.forEach(linea => {
      if (linea.columna.id != 0) {

        switch (linea.columna.id) {
          case 2:
            // Fecha (Planifikatzailean begiratu eta tarte horretan dauden taldeak filtratu)
            break;
          case 4:
            // Seccion (Makina filtratuko ditu sekzioaren arabera)

            break;
          case 5:
            // Grupo de maquina

            break;
          case 6:
            // Maquina
            var maquinasFiltradas = [];
            linea.comboSelecteds.forEach(maquina => {
              maquinasFiltradas.push(maquina.id);
            });
            if (maquinasFiltradas.length > 0) {
              //var datosAux = this.myFunctions.copy(this.datosRoot);
              var arrayTemp = this.datosRootOriginal.children;
              this.datosRoot.children = [];
              arrayTemp.forEach(maquina => {
                maquinasFiltradas.forEach(idMaquina => {
                  if (idMaquina.toString() == maquina.uId.replace('maq', '')) {
                    this.datosRoot.children.push(maquina);
                  }
                });
              });
            }

            var a = this.datosRoot
            break;
          case 8:
            // OF

            break;
          case 9:
            // Cliente

            break;
          case 10:
            // Pieza

            break;
          case 11:
            // Nserie

            break;
          case 13:
            // oOperacion

            break;
          default:
            break;
        }

      }
    });

    this.status = true;

  }

  annadirGrupoFiltro() {
    this.datosFiltro.group.push(
      {
        logic: { id: 0, nombre: this.translateService.instant('y') },
        group: [
          {
            columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        ]
      }
    )
  }
  annadirLineaFiltro(filtro) {
    // Ahora se selecciona la primera opcion, no hara falta mirar si hay algo seleccionado
    if (this.permitirMultiplesLineasVacias || filtro.group.filter(f => f.columna.id == 0 /*|| f.operator.id == 0*/).length == 0) {
      filtro.group.push({
        columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
        operator: { id: 0, nombre: '' },
        fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
        fechaFin: this.myFunctions.getDateNow(),
        mostrarCalendario: false,
        text: '',
        numberMin: 0,
        numberMax: 0,
        decimalformat: '0.000',
        decimalMin: 0.0,
        decimalMax: 0.0,
        check: false,
        combo: [{ id: 1, nombre: "" }],
        comboSelected: {},
        comboSelecteds: []
      })
    }
  }
  cargar_Filtro() {
    // Dentro de esta funcion se meteran todas las cargas de combos, fechas... que necesite el filtro para funcionar.

    // Como cal definir el filtro no se pueden poner las fechas de lunes a domingo, se hace al cargar el filtro, se actualiza el filtro por defecto
    var fechaIni;
    var fechaInicio;
    var fechaFin;

    //calcular fecha inicio
    if (fechaInicio == undefined) {
      fechaInicio = this.myFunctions.getDateNow()
    }
    fechaIni = fechaInicio
    if (fechaIni.getDay() == 3) {
      fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 2));
    } else if (fechaIni.getDay() == 4) {
      fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 3));
    } else if (fechaIni.getDay() == 5) {
      fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 4));
    } else if (fechaIni.getDay() == 6) {
      fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 5));
    } else if (fechaIni.getDay() == 0) {
      fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 6));
    } else if (fechaIni.getDay() == 2) {
      fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 1));
    }

    //calcular fecha fin
    fechaIni = new Date(fechaInicio);
    fechaFin = new Date(fechaIni.setDate(fechaIni.getDate() + 6));

    //calcular horas
    fechaInicio.setHours(0, 0, 0);
    fechaFin.setHours(0, 0, 0);
    this.filtroPorDefecto = {
      logic: { id: 1, nombre: this.translateService.instant('o') },
      group: [
        {
          logic: { id: 0, nombre: this.translateService.instant('y') },
          group: [
            {
              columna: { id: 1, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },
              operator: { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
              fechaIni: fechaInicio,
              fechaFin: fechaFin,
              mostrarCalendario: false,
              text: '',
              numberMin: 0,
              numberMax: 0,
              decimalformat: '0.000',
              decimalMin: 0.0,
              decimalMax: 0.0,
              check: false,
              combo: [{ id: 1, nombre: "" }],
              comboSelected: {},
              comboSelecteds: []
            },
            {
              columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
              operator: { id: 0, nombre: '' },
              fechaIni: fechaInicio,
              fechaFin: fechaFin,
              mostrarCalendario: false,
              text: '',
              numberMin: 0,
              numberMax: 0,
              decimalformat: '0.000',
              decimalMin: 0.0,
              decimalMax: 0.0,
              check: false,
              combo: [{ id: 1, nombre: "" }],
              comboSelected: {},
              comboSelecteds: []
            }
          ]
        }
      ]
    };


    this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);

    var r1, r2, r3, r4: boolean = false;
    //FECHAS
    this.cargarMeses();

    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
      this.seccionesSeleccionadas.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    }
    else {
      this.secciones.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    }
    //FLTRO POR GRUPOS
    var idsGruposSelecteds: any = [];
    if (this.gruposSeleccionados) {
      this.gruposSeleccionados.forEach(
        grupo => {
          idsGruposSelecteds.push(grupo.id.toString()); // se pasa a string para hacer la comparacion
        });
    }
    //MAQUINAS    
    this.filtro_listaMaquinas = this.maquinas;

    //CLIENTES, PIEZAS, OFS, OPERACIONES Y PARTES
    this.informeProyectosService.Get_ClientesPiezasOfsOperacionesPartes_simple().pipe(first()).subscribe(
      (data: any) => {
        this.dataFiltro = data;

        r4 = true;
        if (r1 && r2 && r3 && r4) this.cargarConTodasLasRespuestas();

      });

    //SI ocultarParte=1 NO ENSEÑAR EL MULTISELECT DE PARTES

    if (!this.user.ocultarParte) this.filtro_ocultarPartes = false;
    if (this.user.ocultarParte) this.filtro_ocultarPartes = true;

    // se quita parte de las opciones seleccionables directamente
    if (this.filtro_ocultarPartes)
      this.columnasFiltro = [
        { id: 2, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },
        { id: 3, nombre: this.translateService.instant('turno'), field: "idTurno", sqlfield: "hb.tipoTurno", tipo: 'comboEstrincto' },
        { id: 4, nombre: this.translateService.instant('seccion'), field: "idSeccion", sqlfield: "maq.idSeccion", tipo: 'comboEstrincto' },
        { id: 5, nombre: this.translateService.instant('grupoMaquinas'), field: "idGrupo", sqlfield: "mgm.idMaquinasGrupo", tipo: 'comboEstrincto' },
        { id: 6, nombre: this.translateService.instant('maquina'), field: "idMaquina", sqlfield: "hb.idMaquina", tipo: 'comboEstrincto' },
        { id: 7, nombre: this.translateService.instant('operario'), field: "idOperario", sqlfield: "hb.idOperario", tipo: 'comboEstrincto' },
        { id: 8, nombre: this.translateService.instant('of'), field: "nombreOf", sqlfield: "po.numeroOF", tipo: 'comboFlexible' },
        { id: 9, nombre: this.translateService.instant('cliente'), field: "nombreCliente", sqlfield: "po.nombreCliente", tipo: 'comboFlexible' },
        { id: 10, nombre: this.translateService.instant('pieza'), field: "nombrePieza", sqlfield: "po.nombrePieza", tipo: 'comboFlexible' },
        { id: 11, nombre: this.translateService.instant('nserie'), field: "nSerie", sqlfield: "ho.nSerie", tipo: 'string' },
        { id: 13, nombre: this.translateService.instant('operacion'), field: "nombreOperacion", sqlfield: "po.nombreOperacion", tipo: 'comboFlexible' },
        { id: 14, nombre: this.translateService.instant('terminado'), field: "terminado", sqlfield: "po.operacionTerminada", tipo: 'check' }
      ];

    r1 = true;
    if (r1 && r2 && r3 && r4) this.cargarConTodasLasRespuestas();

  }
  preFiltrado(filtro, row) {
    // si es un COMBO lo cargamos
    if (row.columna.tipo == "comboEstrincto" || row.columna.tipo == "comboFlexible") {
      // cargamos los datos filtrados que NO sean de esta linea.   
      var dataFiltroLag;
      dataFiltroLag = this.dataFiltro;

      // borrar la seleccion actual
      row.comboSelected = {};
      row.comboSelecteds = [];

      // cargar los combos
      // if (row.columna.id == 3) { // turnos
      //   row.combo = this.filtro_listaTurnos;
      // }
      if (row.columna.id == 4) { // seccion
        row.combo = this.groupedSeccion; // OK
      }
      else if (row.columna.id == 5) { // grupo de maquinas
        row.combo = this.grupos.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0)); // OK
      }
      else if (row.columna.id == 6) { // maquinas FILTRO!
        row.combo = this.filtro_listaMaquinas.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0)); // OK
      }
      // else if (row.columna.id == 7) { // operarios 
      //   row.combo = this.filtro_listaOperarios.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
      // }

      // cargar desde dataFiltroLag = filtrado(this.dataFiltro)
      else if (row.columna.id == 8) { // OF FILTRO!
        var combo = [];
        var lag = [];

        this.listaOperaciones.forEach(of => {
          lag.push(of.numeroOF);
          var js = { id: of.numeroOF, nombre: of.numeroOF };
          combo.push(js);
        });

        // dataFiltroLag.forEach(
        //   of => {
        //     if (!lag.includes(of.nombreOf)) {
        //       lag.push(of.nombreOf);
        //       var js = { id: of.nombreOf, nombre: of.nombreOf };
        //       combo.push(js);
        //     }
        //   });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
      }
      else if (row.columna.id == 9) { // clientes FILTRO!
        var combo = [];
        var lag = [];

        dataFiltroLag.forEach(
          cliente => {
            if (!lag.includes(cliente.nombreCliente)) {
              lag.push(cliente.nombreCliente);
              var js = { id: cliente.nombreCliente, nombre: cliente.nombreCliente };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
      }
      else if (row.columna.id == 10) { // piezas FILTRO!
        var combo = [];
        var lag = [];

        this.listaOperaciones.forEach(pieza => {
          lag.push(pieza.referenciaPieza);
          var js = { id: pieza.referenciaPieza, nombre: pieza.referenciaPieza };
          combo.push(js);
        });

        // dataFiltroLag.forEach(
        //   pieza => {
        //     if (!lag.includes(pieza.nombrePieza)) {
        //       lag.push(pieza.nombrePieza);
        //       var js = { id: pieza.nombrePieza, nombre: pieza.nombrePieza };
        //       combo.push(js);
        //     }

        //   });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
      }
      else if (row.columna.id == 11) { // N series FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          nSerie => {
            if (!lag.includes(nSerie.nSerie)) {
              lag.push(nSerie.nSerie);
              var js = { id: nSerie.nSerie, nombre: nSerie.nSerie };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 12) { // partes FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          parte => {
            if (!lag.includes(parte.nombreParte)) {
              lag.push(parte.nombreParte);
              var js = { id: parte.nombreParte, nombre: parte.nombreParte };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 13) { // operaciones FILTRO!
        var combo = [];
        var lag = [];

        this.listaOperaciones.forEach(operacion => {
          lag.push(operacion.nombreOperacion);
          var js = { id: operacion.nombreOperacion, nombre: operacion.nombreOperacion };
          combo.push(js);
        });

        // dataFiltroLag.forEach(
        //   operacion => {
        //     if (!lag.includes(operacion.nombreOperacion)) {
        //       lag.push(operacion.nombreOperacion);
        //       var js = { id: operacion.nombreOperacion, nombre: operacion.nombreOperacion };
        //       combo.push(js);
        //     }
        //   });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
      }
    }
  }

  cargarMeses() {
    this.DaysInMonths = [];
    //Necesitamos inicializar los meses para que se pongan en su día correcto, los pondremos en el día actual y el primer día del mes siguiente
    this.DaysInMonths.push(this.myFunctions.getDateNow());
  }

  cargarConTodasLasRespuestas() {
    // SI ES NECESARIO, SE CARGAN LAS VARIABLES DESDE LA URL.
    //this.cargarFiltroURL();
    this.loadingFiltro = false;
  }

  isDateSelected(row, date) {
    if (row.fechaIni == undefined && row.fechaFin == undefined) {
      return false;
    } else if (row.fechaIni != undefined && row.fechaFin == undefined) {
      return date.getFullYear() == row.fechaIni.getFullYear() && date.getMonth() == row.fechaIni.getMonth() && date.getDate() == row.fechaIni.getDate();
    } else if (row.fechaIni != undefined && row.fechaFin != undefined) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate()) >= new Date(row.fechaIni.getFullYear(), row.fechaIni.getMonth(), row.fechaIni.getDate()) &&
        new Date(date.getFullYear(), date.getMonth(), date.getDate()) <= new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth(), row.fechaFin.getDate());
    }
  };

  borrarLineaFiltro(row, filtro) {
    if (filtro.group.length > 1) {
      //ELIMINAR LINEA DE FILTRO
      let index = filtro.group.findIndex(d => d === row);
      filtro.group.splice(index, 1);
      this.actualizarVisible = true;
    } else if (filtro.group.length == 1 && ((filtro != this.datosFiltro.group[0] || this.datosFiltro.group.length > 1) || this.permitirFiltroVacio)) {
      //SIEMPRE Y CUANDO NO SEA EL PRIMER GRUPO, SE PUEDE ELIMINAR
      let index = this.datosFiltro.group.findIndex(d => d === filtro);
      this.datosFiltro.group.splice(index, 1);
      this.actualizarVisible = true;
    } else if (filtro.group.length == 1 && (((filtro != this.datosFiltro.group[0] || this.datosFiltro.group.length == 1) || !this.permitirFiltroVacio)) && this.vaciarUltimaLinea) {
      //ELIMINAR LINEA DE FILTRO
      let index = filtro.group.findIndex(d => d === row);
      filtro.group.splice(index, 1);
      // Se borra y se añade una linea nueva vacia
      filtro.group.push({
        columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
        operator: { id: 0, nombre: '' },
        fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
        fechaFin: this.myFunctions.getDateNow(),
        mostrarCalendario: false,
        text: '',
        numberMin: 0,
        numberMax: 0,
        decimalformat: '0.000',
        decimalMin: 0.0,
        decimalMax: 0.0,
        check: false,
        combo: [{ id: 1, nombre: "" }],
        comboSelected: {},
        comboSelecteds: []
      })
      this.actualizarVisible = true;
    }
  }

}