import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AlertService, MenuService, UsuariosService, CalendariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-calendarios',
    templateUrl: './eskola_calendarios.component.html'
  })
export class EskolaCalendariosComponent implements OnInit{

  form: FormGroup;
  dataCalendarios: any;
  mySelection: number[] = [];
  navigationSubscription;
  isDeleting = false;
  ultimoId:number;

  modalReference: NgbModalRef;
  user = this.userService.userValue;

  constructor(private calendariosService: CalendariosService,
    private alertService: AlertService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private userService: UsuariosService,
    private modalService: NgbModal) {

    this.menuService.titulo = this.translateService.instant('calendarios').toUpperCase();

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/calendarios') {
          this.cargarDatos();
        }
      }
    });
  
  }

// cargamos los datos de los cursos
  cargarDatos() {
    this.calendariosService.getAll().subscribe((result) => {
      this.dataCalendarios = result;
      this.ultimoId=result[result.length-1].id;
      console.log(this.ultimoId);
      console.log(result);
    });

  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['calendarios/editar/', this.mySelection[0]]);
    }
  }

  onClickEliminar(content) {
    if (this.mySelection[0] > 0) {
        this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
    }
    onClickEditar(){
      if(this.mySelection[0]>0){
        this.router.navigate(['calendarios/editar/', this.mySelection[0]]);
      }
    }
    onClickNuevo(){
      this.router.navigate(['calendarios/nuevo/']);
      
    }

    onClickDuplicar(){
      if(this.mySelection.length>0){
        console.log(this.mySelection);
        this.mySelection.forEach(element => {
          this.calendariosService.getById(element).subscribe(
            (calendarioSelected: any) => {
              if (calendarioSelected.length === 0) return;
              console.log(calendarioSelected);
              console.log(calendarioSelected[0].inicio);
              this.calendariosService.insertEskolaCalendario(true,
                calendarioSelected[0].nombre, calendarioSelected[0].inicio, calendarioSelected[0].fin, calendarioSelected[0].activo,
                calendarioSelected[0].dia1, calendarioSelected[0].dia2, calendarioSelected[0].dia3, calendarioSelected[0].dia4,
                calendarioSelected[0].dia5, calendarioSelected[0].dia6, calendarioSelected[0].dia7).subscribe((result=>{
                  if(result.error){
                    this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });
                    this.router.navigate(['calendarios']);  
                  }else{
                    this.alertService.success(this.translateService.instant('duplicadocorrecto'), { keepAfterRouteChange: true });
                    this.router.navigate(['calendarios']);  
                  }
                }));
          
            });
                      //guardamos los periodos
          this.calendariosService.getPeriodosDeCalendario(element).subscribe(
            (json: any) => {
              if (json.length > 0) {
                json.forEach(element => {
                  var aux1: string = element.fechainicio
                  var aux12: string = aux1.split('T')[0];
                  var aux2: string= element.fechafin;
                  var aux22: string = aux2.split('T')[0];
                  let auxFechaIni = new Date(Number(aux12.split('-')[0]), Number(aux12.split('-')[1]) - 1, Number(aux12.split('-')[2]));
                  let auxFechaFin = new Date(Number(aux22.split('-')[0]), Number(aux22.split('-')[1]) - 1, Number(aux22.split('-')[2]));
      
                  this.calendariosService.insertEskolaCalendarioPeriodos(this.ultimoId + 1, element.ano ,auxFechaIni,auxFechaFin, element.activo).subscribe((result=>{
                    if(result.error){
                      this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });
                      this.router.navigate(['calendarios']);  
                    }else{
                      this.alertService.success(this.translateService.instant('duplicadocorrecto'), { keepAfterRouteChange: true });
                      this.router.navigate(['calendarios']);  
                    }
                  }));
                });

              }
      
            });
            
        });
      
      }
      this.alertService.success(this.translateService.instant('duplicadocorrecto'), { keepAfterRouteChange: true });
      this.router.navigate(['../calendarios']);
    }

    eliminarRegistro() {

      this.mySelection.forEach(element => {
        if (element > 0) {
          this.isDeleting = true;
          this.form = this.formBuilder.group({
            id: element
          });
          this.calendariosService.delete(this.form.value.id).subscribe({
            next: () => {
              this.dataCalendarios = this.dataCalendarios.filter(x => x.id !== element);
              this.isDeleting = false;
              this.alertService.success(this.translateService.instant('eliminadocorrectamente'), { keepAfterRouteChange: true });
              this.router.navigate(['../calendarios'], { relativeTo: this.route });
            },
            error: error => {
              this.isDeleting = false;
              this.alertService.error(error);
            }
          });
        }
      });
  
      this.modalReference.close();
      this.mySelection = [];
  
    }

    ngOnInit(): void {

  }

}