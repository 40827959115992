import { Component } from '@angular/core';

import { FormBuilder, FormGroup } from '@angular/forms';

import { MaquinasService, MenuService, ComboService } from '@app/_services';

import { ActivatedRoute, Router } from "@angular/router";

import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UsuariosService } from '@app/_services';
import { AlarmaLeve, AlarmaGrave } from '@app/_models';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-maquina-detalle-alarmas-pasivas',
  templateUrl: './maquinaDetalleAlarmasPasivas.component.html'
})

export class MaquinaDetalleAlarmasPasivasComponent {
  //#region "CARGA"
  public id: number = -1;
  public idMaquina: number = -1;
  public tipoMaquina: any;
  public tiposLeves: AlarmaLeve[];
  public tipoLeve_selectedItem: AlarmaLeve;
  public tiposGraves: AlarmaGrave[];
  public tipoGrave_selectedItem: AlarmaGrave;
  public idTipoLeve;
  public idTipoGrave;
  closeResult = '';
  form: FormGroup;
  loading = false;
  submitted = false;
  isAddMode: boolean;
  user = this.userService.userValue;
  alertService: any;

  constructor(
    private formBuilder: FormBuilder,
    private maquinasService: MaquinasService,
    private comboService: ComboService,
    private userService: UsuariosService,
    private route: ActivatedRoute,
    public router: Router,
    private modalService: NgbModal,
    private menuService: MenuService,
    private translateService: TranslateService) {

    this.menuService.titulo = this.translateService.instant('alarmaLeve').toUpperCase();
  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.idMaquina = this.route.snapshot.params['idMaquina'];
    this.idTipoLeve = -1;
    this.idTipoGrave = -1;
    this.isAddMode = !this.id;

    this.maquinasService.GetTipoMaquinaById(this.idMaquina).pipe().subscribe((result) => {
      this.tipoMaquina = result[0].tipo_maquina;
    });

    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
    });

    this.comboService.Get_TipoAlarma().pipe(first()).subscribe(
      (result: any) => {
        var an: any = result.data;
        an.forEach(f => {
          f.nombre = this.translateService.instant(f.nombre);
        });
        this.tiposLeves = an;
        this.tiposGraves = an;
      }
    );

    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      tiempoProgramaHastaMin: [1, ],
      alarmaLeve: [1,],
      
      alarmaGrave: [1,],
      
    })

    if (this.id > 0) {
      this.maquinasService.Get_Alarma_Pasiva(this.id).pipe().subscribe((result) => {
        this.idTipoLeve = result.data[0].idTipoAviso;
        this.idTipoGrave = result.data[0].idTipoParada;
        this.tipoLeve_selectedItem = new AlarmaLeve(result.data[0].idTipoAviso, "");
        this.tipoGrave_selectedItem = new AlarmaGrave(result.data[0].idTipoParada, "");

        this.form = this.formBuilder.group({
          idDb: this.user.idDb,
          
          tiempoProgramaHastaMin: [result.data[0].tiempoPrograma,],
          alarmaLeve: [result.data[0].aviso,],
          idTipoLeve: [this.tipoLeve_selectedItem,],
          alarmaGrave: [result.data[0].parada,],
          idTipoGrave: [this.tipoGrave_selectedItem,],
        })
      });
    }
  }
  //#endregion

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    if (this.isAddMode) {
      this.insert();
    } else {
      this.update();
    }
  }

  private insert() {
    this.maquinasService.Insert_Alarma_Pasiva(this.form.value, this.idMaquina, this.idTipoLeve, this.idTipoGrave).subscribe((result) => {
      if (result.error == false) {
        this.atras();
      }
    });
  }

  private update() {
    this.form.value.id = this.id;
    this.maquinasService.Update_Alarma_Pasiva(this.form.value, this.id, this.idMaquina, this.idTipoLeve, this.idTipoGrave).subscribe((result) => {
      if (result.error == false) {
        this.atras();
      }
    });
  }

  public atras() {
    if (this.idMaquina > 0) {
      if (this.tipoMaquina == -1) this.router.navigate(['maquinas/editar/', this.idMaquina]); //NO TIENE TIPO DE MAQUINA, IR A MECANIZADO POR DEFECTO
      if (this.tipoMaquina == 1) this.router.navigate(['maquinas/editar/', this.idMaquina]); //MECANIZADO
      if (this.tipoMaquina == 2) this.router.navigate(['extrusoras/editar/', this.idMaquina]); //EXTRUSORA
      if (this.tipoMaquina == 3) this.router.navigate(['impresorasPlastico/editar/', this.idMaquina]); //IMPRESORA PLASTICO
      if (this.tipoMaquina == 4) this.router.navigate(['inyectoras/editar/', this.idMaquina]); //INYECTORAS
      if (this.tipoMaquina == 5) this.router.navigate(['hornos/editar/', this.idMaquina]); //HORNOS
      if (this.tipoMaquina == 9) this.router.navigate(['aditivo/editar/', this.idMaquina]); //ADITIVO
    }
  }


  //#region "COMBO"
  public tiposLeves_selectionChange(value: any): void {
   
    this.idTipoLeve = value.id;
    //this.idTipoGrave = -1;
  }

  public tiposGraves_selectionChange(value: any): void {
   // this.form.controls['idTipoGrave'].setValue(value.id);
    this.idTipoGrave = value.id;
  }
  //#endregion
}
