import { Component, Input } from '@angular/core';
import { MaquinasService, MenuService, AlertService } from '@app/_services';
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-maquina-secciones-capas',
  templateUrl: './maquinaDetalleCapas.component.html'
})

export class MaquinaDetalleSeccionesCapasComponent {

  @Input() id: string;

  public capas: any = [];
  public capas_seleccionados: number[] = [];
  public capas_modalReference: NgbModalRef;
  public capas_modalReferenceLoading: NgbModalRef;

  constructor(
    private maquinasService: MaquinasService,
    public router: Router,
    private menuService: MenuService,
    private translateService: TranslateService,
    private alertService: AlertService) {

    this.menuService.titulo = this.translateService.instant('maquina').toUpperCase();
  }

  ngOnInit() {

    this.maquinasService.GetAll_Capas(this.id).subscribe((result) => {
      this.capas = result.data;
    });

  }

  capas_onClickNuevo() {
    if (this.id != undefined) {
      this.router.navigate(['maquinas/capa/crear/' + this.id]);
    } else {
      this.alertService.warn(this.translateService.instant('creaPrimeroLaMaquina'), { keepAfterRouteChange: true });
    }
  }

  capas_onClickEditar() {
    if (this.id != undefined) {
      if (this.capas_seleccionados[0] > 0) {
        this.router.navigate(['maquinas/capa/editar/' + this.id + "/" + this.capas_seleccionados[0]]);
      }
    }
  }

  capas_cellClick(e) {
    if (this.id != undefined) {
      if (e.columnIndex > 0) {
        this.router.navigate(['maquinas/capa/editar/' + this.id + "/" + this.capas_seleccionados[0]]);
      }
    }
  }

}
