<!-- PANEL TOP -->
<div class="row proces">
  <div class=" col-sm-12 col-md-12 cont-procesos">
    <div class="" id="panelTop">
      <div class="procesos-header-cont">
        <div ID="maquinaentera" Class="maquina-cont">
          <div class="procesos-header row">
            <!--panel maquina (de home)-->
            <hmi-panel class="hmi-panel col-4" [idMaquina]="idmaquina"></hmi-panel>

            <!---------------------------------------------------------------------------------------------------------------->

            <div class="col-4">
              <div class="panel-procesos">

                <div class="row">

                  <div class="col">

                    <label ID="Lherramienta" class="titulo-seccion-maquina">{{'herramienta' | translate}}</label>
                    <div id="herramientaPanel" class="panel-herramientas">

                      <!--NO TIENE EJES, POR LO TANTO NO HERRAMIENTAS-->
                      <ng-container *ngIf="this.maquina.tiempoReal != undefined">
                        <ng-container *ngIf="this.maquina.tiempoReal.length == 0">

                          <ul id="DatosHerramienta">
                            <li>
                              <label ID="lNumHerramienta" Class="titulo-herramienta-inner">{{ 'noHayHerramienta' |
                                translate}}</label>
                            </li>
                          </ul>

                        </ng-container>

                        <!--TIENE EJES-->
                        <ng-container *ngIf="this.maquina.tiempoReal.length > 0">

                          <ul id="DatosHerramienta"
                            *ngIf="this.maquina.tiempoReal[0].numHerramienta != -1 && this.maquina.tiempoReal[0].numHerramienta != null">
                            <li>
                              <!-- <label ID="lNombreHerramienta" Class="titulo-herramienta-inner">{{ 'nombre' | translate }}</label> -->
                              <label ID="ldatoNombreHerramienta"> {{maquina.tiempoReal[0].nombreHerramienta}}
                              </label>
                            </li>
                          </ul>

                          <ul id="DatosHerramienta"
                            *ngIf="this.maquina.tiempoReal[0].numHerramienta == -1 || this.maquina.tiempoReal[0].numHerramienta == null">
                            <li>
                              <label ID="lNumHerramienta" Class="titulo-herramienta-inner">{{ 'noHayHerramienta' |
                                translate}}</label>
                            </li>
                          </ul>

                        </ng-container>
                      </ng-container>

                    </div>

                    <label id="MainContent_Lprogram" class="titulo-seccion-maquina">{{ 'programa' | translate}}
                    </label>
                    <div class="comandoprogramas-cabecera">
                      <div class="panel-of">
                        <div class="of-inner">
                          <div class="of-content">
                            <!--<span id="MainContent_LPrograma" class="label-num-num">{{maquina.programaTitulo}}</span>-->
                            <label ID="MainContent_LPrograma" class="label-proceso" data-toggle="tooltip"
                              data-placement="bottom"
                              title="{{maquina.programaTitulo}}">{{maquina.programaTitulo}}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="MainContent_IDprogramaDiv" class="comandoprogramas">
                      <div class="par-icono-cm">
                        <div id="MainContent_Divprograma" class="lineas-programa" [innerHTML]="maquina.programaTexto">
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>

            </div>

            <!--potenciometros-->
            <div class="col-4">
              <div class="proces-procedimiento-cont poti-graf panel-procesos panel-con-resumen-semana">

                <!--NO TIENE EJES, POR LO TANTO NO POTENCIOMETROS-->
                <ng-container *ngIf="this.maquina.tiempoReal != undefined">
                  <ng-container *ngIf="this.maquina.tiempoReal.length == 0">
 
                    <label ID="LNoPotens" class="titulo-seccion-maquina">{{ 'potenciometros' | translate }}</label>
                    <div class="panel-herramientas">
                      <ul id="DatosNoPotens">
                        <li>
                          <label ID="lDatosNoPotens" Class="titulo-herramienta-inner">{{ 'noHayPotenciometros' | translate }}</label>
                        </li>
                      </ul>
                    </div>
 
                  </ng-container>
 
 
                  <!--TIENE EJES-->
                  <ng-container *ngIf="this.maquina.tiempoReal.length > 0">
 
                    <div class="cont-poti clearfix" [class.dospoti]="dosPotenciometros">
 
                      <!-- POTENCIOMETRO 1 -->
                      <div class="outer-cont-donut" style="overflow: hidden;" *ngIf="valorPotenciometro1 != undefined">
                        <label class="titulopoten">{{ 'potenCabezal' | translate | uppercase }}</label>
                        <div id="divPoten1">
                          <kendo-radialgauge>
                            <kendo-radialgauge-pointers>
                              <kendo-radialgauge-pointer [value]="valorPotenciometro1" [color]="'#5b5d5f'">
                              </kendo-radialgauge-pointer>
                            </kendo-radialgauge-pointers>
                            <kendo-radialgauge-scale [minorUnit]="5" [majorUnit]="maxPotenciometro1/2" [max]="maxPotenciometro1">
                              <kendo-radialgauge-scale-ranges>
                                <kendo-radialgauge-scale-range [from]="0" [to]="maxPotenciometro1/2" color="#00eec5">
                                </kendo-radialgauge-scale-range>
                                <kendo-radialgauge-scale-range [from]="maxPotenciometro1/2" [to]="maxPotenciometro1" color="#00eec5">
                                </kendo-radialgauge-scale-range>
                              </kendo-radialgauge-scale-ranges>
                              <kendo-radialgauge-scale-labels [font]="'18px sans-serif'">
                              </kendo-radialgauge-scale-labels>
                            </kendo-radialgauge-scale>
                          </kendo-radialgauge>
 
 
                          <zitu-chart-gauge [min]="0" [max]="maxPotenciometro1" [value]="valorPotenciometro1" [idHTML]="'idChart_gauge'">
                          </zitu-chart-gauge>
 
 
 
                          <label class="valor-poten">{{valorPotenciometro1}}</label>
                        </div>
                      </div>
 
                      <!-- POTENCIOMETRO 2 -->
                      <div class="outer-cont-donut" style="overflow: hidden;"  *ngIf="valorPotenciometro2 != undefined">
                        <label class="titulopoten">{{ 'potenAvances' | translate | uppercase }}</label>
                        <div id="divPoten2">
                          <kendo-radialgauge>
                            <kendo-radialgauge-pointers>
                              <kendo-radialgauge-pointer [value]="valorPotenciometro2" [color]="'#5b5d5f'">
                              </kendo-radialgauge-pointer>
                            </kendo-radialgauge-pointers>
                            <kendo-radialgauge-scale [minorUnit]="5" [majorUnit]="maxPotenciometro2/2"
                              [max]="maxPotenciometro2">
                              <kendo-radialgauge-scale-ranges>
                                <kendo-radialgauge-scale-range [from]="0" [to]="maxPotenciometro2/2" color="#00eec5">
                                </kendo-radialgauge-scale-range>
                                <kendo-radialgauge-scale-range [from]="maxPotenciometro2/2" [to]="maxPotenciometro2"
                                  color="#00eec5">
                                </kendo-radialgauge-scale-range>
                                <kendo-radialgauge-scale-labels [font]="'18px sans-serif'">
                                </kendo-radialgauge-scale-labels>
                              </kendo-radialgauge-scale-ranges>
                            </kendo-radialgauge-scale>
                          </kendo-radialgauge>
                        </div>
 
                       
 
                        <zitu-chart-gauge [min]="0" [max]="maxPotenciometro2" [value]="valorPotenciometro2" [idHTML]="'idChart_gauge2'" >
                        </zitu-chart-gauge>
 
                        <label class="valor-poten">{{valorPotenciometro2}}</label>
                      </div>
 
                    </div>
 
                  </ng-container>
                </ng-container>

                <!-- Info Semanal -->
                <div ID="resumenSemana" class="planificador">
                  <label class="titulopoten">{{ 'resumenSemana' | translate | uppercase }}</label>
                  <a ID="hlResumenMaquina" class="linkresumensemana" [routerLink]="['/informerendimiento/'+idmaquina]">
                    <div id="resumenSemanaGrafico"></div>
                  </a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>

<div id="RAPINFERIOR">
  <kendo-tabstrip>
    <kendo-tabstrip-tab id="tabHMI" title="{{ 'hmi' | translate}}" [selected]="true"
      *ngIf="datosGraficoArray.length > 0">
      <ng-template kendoTabContent>
        <div visible="false" id="graficos">
          <!-- main -->
          <div class="donut-grupo-cm-cont">
            <div class="linea-col gallery-row-scroll">
              <div class={{datosGrafico.graficoClass}} *ngFor="let datosGrafico of datosGraficoArray">
                <div class="cont-superior">
                  <div class="barra-izq" *ngIf="datosGrafico.ensennarTemp">
                    <div class="barra-der-inner ">
                      <asp:Label ID="LT1" class="toplabel">Tº</asp:Label>
                      <div class="cont-multiples" id="DIVtemperatura1">
                        <!-- Esta clase se machaca desde codigo para añadir noActivo & la clase que tiene aqui -->
                        <img src={{datosGrafico.graficoTempIMG1src}} id="IMG1Temp1" />
                        <img src={{datosGrafico.graficoTempIMG2src}} id="IMG1Temp2" />
                        <img src={{datosGrafico.graficoTempIMG3src}} id="IMG1Temp3" />
                        <img src={{datosGrafico.graficoTempIMG4src}} id="IMG1Temp4" />
                        <img src={{datosGrafico.graficoTempIMG5src}} id="IMG1Temp5" />

                        <img src={{datosGrafico.graficoTempIMG6src}} id="IMG1Temp6" />
                        <img src={{datosGrafico.graficoTempIMG7src}} id="IMG1Temp7" />
                        <img src={{datosGrafico.graficoTempIMG8src}} id="IMG1Temp8" />
                        <img src={{datosGrafico.graficoTempIMG9src}} id="IMG1Temp9" />
                        <img src={{datosGrafico.graficoTempIMG10src}} id="IMG1Temp10" />

                        <img src={{datosGrafico.graficoTempIMG11src}} id="IMG1Temp11" />
                        <img src={{datosGrafico.graficoTempIMG12src}} id="IMG1Temp12" />
                        <img src={{datosGrafico.graficoTempIMG13src}} id="IMG1Temp13" />
                        <img src={{datosGrafico.graficoTempIMG14src}} id="IMG1Temp14" />
                        <img src={{datosGrafico.graficoTempIMG15src}} id="IMG1Temp15" />

                        <img src={{datosGrafico.graficoTempIMG16src}} id="IMG1Temp16" />
                        <img src={{datosGrafico.graficoTempIMG17src}} id="IMG1Temp17" />
                        <img src={{datosGrafico.graficoTempIMG18src}} id="IMG1Temp18" />
                        <img src={{datosGrafico.graficoTempIMG19src}} id="IMG1Temp19" />
                        <img src={{datosGrafico.graficoTempIMG20src}} id="IMG1Temp20" />

                        <img src={{datosGrafico.graficoTempIMG21src}} id="IMG1Temp21" />
                        <img src={{datosGrafico.graficoTempIMG22src}} id="IMG1Temp22" />
                        <img src={{datosGrafico.graficoTempIMG23src}} id="IMG1Temp23" />
                        <img src={{datosGrafico.graficoTempIMG24src}} id="IMG1Temp24" />
                        <img src={{datosGrafico.graficoTempIMG25src}} id="IMG1Temp25" />

                        <img src={{datosGrafico.graficoTempIMG26src}} id="IMG1Temp26" />
                        <img src={{datosGrafico.graficoTempIMG27src}} id="IMG1Temp27" />
                        <img src={{datosGrafico.graficoTempIMG28src}} id="IMG1Temp28" />
                        <img src={{datosGrafico.graficoTempIMG29src}} id="IMG1Temp29" />
                        <img src={{datosGrafico.graficoTempIMG30src}} id="IMG1Temp30" />

                        <img src={{datosGrafico.graficoTempIMG31src}} id="IMG1Temp31" />
                        <img src={{datosGrafico.graficoTempIMG32src}} id="IMG1Temp32" />
                        <img src={{datosGrafico.graficoTempIMG33src}} id="IMG1Temp33" />
                        <img src={{datosGrafico.graficoTempIMG34src}} id="IMG1Temp34" />
                        <img src={{datosGrafico.graficoTempIMG35src}} id="IMG1Temp35" />

                        <img class="consum-fondo" src="assets/img/temp/temp0.png" alt="Temperatura 1 spindle"
                          id="IMG1Temp" />
                      </div>
                      <asp:Label ID="L1TempVal" class="bottom-label">{{datosGrafico.temperaturaTexto}}º</asp:Label>
                    </div>
                  </div>
                  <div class="barra-der " *ngIf="datosGrafico.ensennarConsum">
                    <div class="barra-der-inner ">
                      <asp:Label ID="L1Consumo" class="toplabel">KW</asp:Label>
                      <div class="cont-multiples" id="DIVconsumo1">
                        <!-- Esta clase se machaca desde codigo para añadir noActivo & la clase que tiene aqui -->

                        <img src={{datosGrafico.graficoConsumIMG1src}} id="IMG1Consum1" />
                        <img src={{datosGrafico.graficoConsumIMG2src}} id="IMG1Consum2" />
                        <img src={{datosGrafico.graficoConsumIMG3src}} id="IMG1Consum3" />
                        <img src={{datosGrafico.graficoConsumIMG4src}} id="IMG1Consum4" />
                        <img src={{datosGrafico.graficoConsumIMG5src}} id="IMG1Consum5" />

                        <img src={{datosGrafico.graficoConsumIMG6src}} id="IMG1Consum6" />
                        <img src={{datosGrafico.graficoConsumIMG7src}} id="IMG1Consum7" />
                        <img src={{datosGrafico.graficoConsumIMG8src}} id="IMG1Consum8" />
                        <img src={{datosGrafico.graficoConsumIMG9src}} id="IMG1Consum9" />
                        <img src={{datosGrafico.graficoConsumIMG10src}} id="IMG1Consum10" />

                        <img src={{datosGrafico.graficoConsumIMG11src}} id="IMG1Consum11" />
                        <img src={{datosGrafico.graficoConsumIMG12src}} id="IMG1Consum12" />
                        <img src={{datosGrafico.graficoConsumIMG13src}} id="IMG1Consum13" />
                        <img src={{datosGrafico.graficoConsumIMG14src}} id="IMG1Consum14" />
                        <img src={{datosGrafico.graficoConsumIMG15src}} id="IMG1Consum15" />

                        <img src={{datosGrafico.graficoConsumIMG16src}} id="IMG1Consum16" />
                        <img src={{datosGrafico.graficoConsumIMG17src}} id="IMG1Consum17" />
                        <img src={{datosGrafico.graficoConsumIMG18src}} id="IMG1Consum18" />
                        <img src={{datosGrafico.graficoConsumIMG19src}} id="IMG1Consum19" />
                        <img src={{datosGrafico.graficoConsumIMG20src}} id="IMG1Consum20" />

                        <img src={{datosGrafico.graficoConsumIMG21src}} id="IMG1Consum21" />
                        <img src={{datosGrafico.graficoConsumIMG22src}} id="IMG1Consum22" />
                        <img src={{datosGrafico.graficoConsumIMG23src}} id="IMG1Consum23" />
                        <img src={{datosGrafico.graficoConsumIMG24src}} id="IMG1Consum24" />
                        <img src={{datosGrafico.graficoConsumIMG25src}} id="IMG1Consum25" />
                        <img class="consum-fondo" src="assets/img/consum/consum0.png" alt="DIVconsumo 1 spindle"
                          id="IMG1Consum" />
                      </div>
                      <asp:Label ID="L1ConsumoVal" class="bottom-label">{{datosGrafico.consumoTexto}}</asp:Label>
                    </div>
                  </div>
                </div>
                <div class="cont-donut" *ngIf="datosGrafico.ensennarFeed">
                  <!-- Esta clase se machaca desde codigo para añadir noActivo & la clase que tiene aqui -->
                  <asp:Label ID="L1">{{datosGrafico.nombre}}</asp:Label>
                  <div class="donut" id="DIVfeed1">
                    <!--<img src="assets/rpm/rpm0.png" alt="RPM 1 spindle" id="IMG1RPM" />-->
                    <img src={{datosGrafico.graficoFeedSrc}} alt="RPM 1 spindle" id="IMG1RPM" />
                    <div class="donut-valores">
                      <asp:Label ID="L1RPMVal">{{datosGrafico.graficoFeedTexto}}</asp:Label>
                      <asp:Label ID="L1RPM">{{datosGrafico.unidad}}</asp:Label>
                    </div>
                  </div>
                </div>
                <div class="valores-abajo" id="DIVposicion1" *ngIf="datosGrafico.valoresAbajoEnsennar">
                  <!-- Esta clase se machaca desde codigo para añadir noActivo & la clase que tiene aqui -->
                  <div class="eje">
                    <asp:Label ID="LComando1">{{redondearMaximo6Char(datosGrafico.comando)}}</asp:Label>
                    <p id="L1comando">{{ 'teorico' | translate | uppercase}}</p>
                  </div>
                  <asp:Label ID="LActual1">{{redondearMaximo6Char(datosGrafico.actual)}}</asp:Label>
                  <div class="eje">
                    <asp:Label ID="LRestante1">{{redondearMaximo6Char(datosGrafico.restante)}}</asp:Label>
                    <p id="L1resto">{{ 'restodos' | translate | uppercase}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab id="tabProcesos" title="{{ 'procesos' | translate}}" [selected]="datosGraficoArray.length == 0">
      <ng-template kendoTabContent>
        <div class="row" style="margin-left: -10px; margin-right: -10px;">
          <div class="procesdos col-sm-12 col-md-4">
            <div class="proces-titulo">
              {{ 'historicoProcesos' | translate | uppercase}}
            </div>
            <div class="clearfix">
              <div class="processcroll">
                <div class="historial" *ngFor="let historico of historicoProcesos">
                  <div class={{historico.divcss}}>
                    <div class="clearfix">
                      <div class="proces-fecha-cont">
                        <div id="horaIni" class="horaIni">
                          <label>{{historico.horaini}}</label>
                        </div>
                        <div id="diaIni" class="diaIni">
                          <label>{{historico.diaini}}</label>
                        </div>
                      </div>

                      <div class="proces-info-cont">
                        <div id="tipoProceso" *ngIf="historico.idProcesos_Tipo!=6">
                          <label><span class="proces-titulo-uno">{{historico.tipo}}:</span>
                            {{historico.nombre}}</label>
                        </div>
                        <div *ngIf="historico.idProcesos_Tipo==6">
                          <label><span class="proces-titulo-uno">{{historico.tipo}}:</span>
                            {{historico.tipoAlarNombre}}</label>
                        </div>
                        <div id="tiempos">
                          <label><span class="proces-titulo-uno">{{ 'tiempoReal' | translate}}:</span>
                            {{historico.tiemporeal}} <span class="proces-titulo-uno"
                              *ngIf="historico.idProcesos_Tipo!=2">{{ 'tiempoEstimado' | translate}}:</span><span
                              *ngIf="historico.idProcesos_Tipo!=2"> {{historico.tiempoteorico}}</span></label>
                        </div>
                      </div>
                    </div>
                    <div id="informacionOF" class="informacionOF">
                      <label><span class="proces-titulo-dos">OF:</span> {{historico.OF}} <span
                          class="proces-titulo-dos">{{ 'cliente' | translate}}:</span> {{historico.cliente}} <span
                          class="proces-titulo-dos">{{ 'parte' | translate}}:</span> {{historico.parte}} <span
                          class="proces-titulo-dos">{{ 'pieza' | translate}}:</span> {{historico.pieza}}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="procesdos col-sm-12 col-md-4">
            <div class="proces-titulo">
              {{ 'alarmas' | translate | uppercase}}
            </div>
            <div class="clearfix">
              <div class="processcroll">
                <div class="historial" *ngFor="let alarma of alarmas">

                  <div class={{alarma.divcss}}>

                    <div class="clearfix">
                      <div class="proces-fecha-cont">

                        <div id="horaIni">
                          <label>{{alarma.horaini}}</label>
                        </div>
                        <div id="diaIni">
                          <label>{{alarma.diaini}}</label>
                        </div>

                      </div>

                      <div class="proces-info-cont">
                        <div id="numeroAlarma">
                          <label>{{alarma.numeroAlarma}}</label> - <label>{{alarma.descripcion.replace('ALARMA: ',
                            '')}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="procesdos col-sm-12 col-md-4">
            <div class="proces-titulo">
              {{ 'planificado' | translate | uppercase}}
            </div>
            <div class="clearfix">
              <div class="processcroll">
                <div class="historial" *ngFor="let planificado of planificados">

                  <div class={{planificado.divcss}}>

                    <div class="clearfix">

                      <div class="proces-fecha-cont">
                        <div id="tiempos" class="horaIni">
                          <label>{{planificado.horaini}}</label>
                        </div>

                        <div id="tiempos" class="diaIni">
                          <label>{{planificado.diaini}}</label>
                        </div>
                      </div>

                      <div class="proces-info-cont">
                        <div id="operacion">
                          <label id="operacion"><span class="proces-titulo-uno">{{ 'operacion' | translate}}:</span>
                            {{planificado.operacion}}</label>
                        </div>
                        <div id="tiempoTeorico">
                          <label><span class="proces-titulo-uno">{{ 'tiempoEstimado' | translate}}:</span>
                            {{planificado.tiempoteorico}}</label>
                        </div>
                      </div>
                    </div>
                    <div id="informacionOF" class="informacionOF">
                      <label><span class="proces-titulo-dos">OF:</span> {{planificado.OF}} <span
                          class="proces-titulo-dos">{{ 'cliente' | translate}}:</span> {{planificado.cliente}} <span
                          class="proces-titulo-dos">{{ 'parte' | translate}}:</span> {{planificado.parte}} <span
                          class="proces-titulo-dos">{{ 'pieza' | translate}}:</span> {{planificado.pieza}}</label>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab id="tabMantenimiento" title="{{ 'mantenimientos' | translate}}">
      <ng-template kendoTabContent>
        <div class="row cont-mantenimientos">
          <div class="col-md-6">
            {{ 'porFechas' | translate | uppercase}}
            <div class="">
              <kendo-grid [data]="mantenimientoPorFecha" style="height: 400px" [hideHeader]="true"
                [rowClass]="rowCallback">
                <kendo-grid-column field="switch" width="17%">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-switch [(ngModel)]=dataItem.checked (click)="clickSwitch($event, dataItem, 1)"
                      [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="texto" width="41%">
                </kendo-grid-column>
                <kendo-grid-column width="10%" field="tipo" title="{{ 'tipo' | translate}}">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <span>{{ dataItem.tipo | translate }}</span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="fecha" width="20%">
                </kendo-grid-column>
                <kendo-grid-column field="variacionicono" title="" width="12%">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <button (click)="abrirPdf(dataItem)" class="k-button pdf-button" *ngIf="dataItem.tienePdf==true">
                      <span class="k-icon k-i-file-pdf"></span>
                    </button>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-messages filter="{{'filter' | translate}}"
                  filterAfterOperator="{{'filterAfterOperator' | translate}}"
                  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                  filterAndLogic="{{'filterAndLogic' | translate}}"
                  filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                  filterBooleanAll="{{'filterBooleanAll' | translate}}"
                  filterClearButton="{{'filterClearButton' | translate}}"
                  filterContainsOperator="{{'filterContainsOperator' | translate}}"
                  filterDateToday="{{'filterDateToday' | translate}}"
                  filterDateToggle="{{'filterDateToggle' | translate}}"
                  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                  filterEqOperator="{{'filterEqOperator' | translate}}"
                  filterFilterButton="{{'filterFilterButton' | translate}}"
                  filterGtOperator="{{'filterGtOperator' | translate}}"
                  filterGteOperator="{{'filterGteOperator' | translate}}"
                  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
                  filterIsFalse="{{'filterIsFalse' | translate}}"
                  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                  filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
                  filterIsTrue="{{'filterIsTrue' | translate}}" filterLtOperator="{{'filterLtOperator' | translate}}"
                  filterLteOperator="{{'filterLteOperator' | translate}}"
                  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                  filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                  filterOrLogic="{{'filterOrLogic' | translate}}"
                  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}"
                  loading="{{'loading' | translate}}" groupPanelEmpty="{{'groupPanelEmpty' | translate}}"
                  lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
              </kendo-grid>
              <div *ngIf="loadingPorFecha" class="k-i-loading"></div>
            </div>
          </div>
          <div class="col-md-6">
            {{ 'porTiempos' | translate | uppercase}}
            <div class="">
              <kendo-grid [data]="mantenimientoPorTiempo" style="height: 400px" [hideHeader]="true"
                [rowClass]="rowCallback">
                <kendo-grid-column field="switch" width="17%">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-switch [(ngModel)]=dataItem.checked (click)="clickSwitchTiempo($event, dataItem, 2)"
                      [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="texto" width="51%">
                </kendo-grid-column>
                <kendo-grid-column field="fecha" width="20%">
                </kendo-grid-column>
                <kendo-grid-column field="variacionicono" title="" width="12%">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <button (click)="abrirPdf(dataItem)" class="k-button" *ngIf="dataItem.tienePdf==true">
                      <span class="k-icon k-i-file-pdf"></span>
                    </button>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-messages filter="{{'filter' | translate}}"
                  filterAfterOperator="{{'filterAfterOperator' | translate}}"
                  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                  filterAndLogic="{{'filterAndLogic' | translate}}"
                  filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                  filterBooleanAll="{{'filterBooleanAll' | translate}}"
                  filterClearButton="{{'filterClearButton' | translate}}"
                  filterContainsOperator="{{'filterContainsOperator' | translate}}"
                  filterDateToday="{{'filterDateToday' | translate}}"
                  filterDateToggle="{{'filterDateToggle' | translate}}"
                  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                  filterEqOperator="{{'filterEqOperator' | translate}}"
                  filterFilterButton="{{'filterFilterButton' | translate}}"
                  filterGtOperator="{{'filterGtOperator' | translate}}"
                  filterGteOperator="{{'filterGteOperator' | translate}}"
                  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
                  filterIsFalse="{{'filterIsFalse' | translate}}"
                  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                  filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
                  filterIsTrue="{{'filterIsTrue' | translate}}" filterLtOperator="{{'filterLtOperator' | translate}}"
                  filterLteOperator="{{'filterLteOperator' | translate}}"
                  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                  filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                  filterOrLogic="{{'filterOrLogic' | translate}}"
                  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}"
                  loading="{{'loading' | translate}}" groupPanelEmpty="{{'groupPanelEmpty' | translate}}"
                  lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
              </kendo-grid>
              <div *ngIf="loadingPorTiempo" class="k-i-loading"></div>
            </div>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab id="tabNotas" title="{{ 'notas' | translate}}">
      <ng-template kendoTabContent>
        <div class="row" style="margin-left: -10px; margin-right: -10px;">
          <kendo-grid [data]="notas" style="height: 400px">
            <kendo-grid-column field="creadoPor" title="{{ 'creadoPor' | translate}}" width="10%">
            </kendo-grid-column>
            <kendo-grid-column field="cerradoPor" title="{{ 'cerradoPor' | translate}}" width="10%">
            </kendo-grid-column>
            <kendo-grid-column field="fecha" title="{{ 'fecha' | translate}}" width="10%">
            </kendo-grid-column>
            <kendo-grid-column field="texto" title="{{ 'texto' | translate}}" width="70%">
            </kendo-grid-column>

            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab id="tabHerramientas" title="{{ 'herramientas' | translate}}">
      <ng-template kendoTabContent>
        <div class="row" style="margin-left: -10px; margin-right: -10px;">
          <div class="col-md-6">
            {{ 'almacen' | translate | uppercase}}:
            <kendo-grid [data]="herramientas">
              <kendo-grid-column field="posicionTorreta" title="{{ 'posicion' | translate}}" width="7%">
              </kendo-grid-column>
              <kendo-grid-column field="posicionAlmacen" title="{{ 'posicionAlmacen' | translate}}" width="7%">
              </kendo-grid-column>
              <kendo-grid-column field="nombreHerramienta" title="{{ 'nombreHerramienta' | translate}}" width="20%">
              </kendo-grid-column>
              <kendo-grid-column field="radio" title="{{ 'radio' | translate}}" width="24%">
              </kendo-grid-column>
              <kendo-grid-column field="offX" title="offX" width="7%">
              </kendo-grid-column>
              <kendo-grid-column field="offZ" title="offZ" width="7%">
              </kendo-grid-column>
              <kendo-grid-column field="owX" title="owX" width="7%">
              </kendo-grid-column>
              <kendo-grid-column field="owZ" title="owZ" width="7%">
              </kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}"
                filterAfterOperator="{{'filterAfterOperator' | translate}}"
                filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                filterAndLogic="{{'filterAndLogic' | translate}}"
                filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                filterBooleanAll="{{'filterBooleanAll' | translate}}"
                filterClearButton="{{'filterClearButton' | translate}}"
                filterContainsOperator="{{'filterContainsOperator' | translate}}"
                filterDateToday="{{'filterDateToday' | translate}}"
                filterDateToggle="{{'filterDateToggle' | translate}}"
                filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                filterEqOperator="{{'filterEqOperator' | translate}}"
                filterFilterButton="{{'filterFilterButton' | translate}}"
                filterGtOperator="{{'filterGtOperator' | translate}}"
                filterGteOperator="{{'filterGteOperator' | translate}}"
                filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
                filterIsFalse="{{'filterIsFalse' | translate}}"
                filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
                filterIsTrue="{{'filterIsTrue' | translate}}" filterLtOperator="{{'filterLtOperator' | translate}}"
                filterLteOperator="{{'filterLteOperator' | translate}}"
                filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                filterOrLogic="{{'filterOrLogic' | translate}}"
                filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}"
                loading="{{'loading' | translate}}" groupPanelEmpty="{{'groupPanelEmpty' | translate}}"
                lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}" noRecords="{{'norecords' | translate}}"
                pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>
          <div class="col-md-6">
            {{ 'roturasCambiosherramienta' | translate | uppercase}}
            <kendo-grid [data]="roturasCambiosHerramientas">
              <kendo-grid-column field="nombreHerramienta" title="{{ 'nombreHerramienta' | translate}}" width="35%">
              </kendo-grid-column>
              <kendo-grid-column field="nombreOperario" title="{{ 'nombreOperario' | translate}}" width="20%">
              </kendo-grid-column>
              <kendo-grid-column field="tipo" title="{{ 'tipo' | translate}}" width="15%">
              </kendo-grid-column>
              <kendo-grid-column field="cantidad" title="{{ 'cantidad' | translate}}" width="10%">
              </kendo-grid-column>
              <kendo-grid-column field="fecha" title="{{ 'fecha' | translate}}" width="20%">
              </kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}"
                filterAfterOperator="{{'filterAfterOperator' | translate}}"
                filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                filterAndLogic="{{'filterAndLogic' | translate}}"
                filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                filterBooleanAll="{{'filterBooleanAll' | translate}}"
                filterClearButton="{{'filterClearButton' | translate}}"
                filterContainsOperator="{{'filterContainsOperator' | translate}}"
                filterDateToday="{{'filterDateToday' | translate}}"
                filterDateToggle="{{'filterDateToggle' | translate}}"
                filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                filterEqOperator="{{'filterEqOperator' | translate}}"
                filterFilterButton="{{'filterFilterButton' | translate}}"
                filterGtOperator="{{'filterGtOperator' | translate}}"
                filterGteOperator="{{'filterGteOperator' | translate}}"
                filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
                filterIsFalse="{{'filterIsFalse' | translate}}"
                filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
                filterIsTrue="{{'filterIsTrue' | translate}}" filterLtOperator="{{'filterLtOperator' | translate}}"
                filterLteOperator="{{'filterLteOperator' | translate}}"
                filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                filterOrLogic="{{'filterOrLogic' | translate}}"
                filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}"
                loading="{{'loading' | translate}}" groupPanelEmpty="{{'groupPanelEmpty' | translate}}"
                lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}" noRecords="{{'norecords' | translate}}"
                pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>

</div>