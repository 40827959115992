
<style>
  .k-text-error {
    display: none;
  }

  :host /deep/ .k-grid tbody td {
    white-space: nowrap;
    line-height: 20px;
    padding: 8px 12px;
  }

  :host /deep/ .k-grid .k-grid-content td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
<div *ngIf="loadingGraficoTresEjes" id="loadingDiario" class="k-i-loading" style="z-index: 3;"></div>
<!--TABS-->
<kendo-tabstrip>
  <kendo-tabstrip-tab [title]="herramientastr" [selected]="true">
    <ng-template kendoTabContent>
      <div kendoTooltip
           showOn="none"
           [tooltipTemplate]="template"
           filter=".k-grid td"
           (mouseover)="showGridTooltip($event)">
        <kendo-grid [kendoGridBinding]="herramientas"
                    [sortable]="true"
                    [navigable]="true"
                    filterable="menu"
                    [rowHeight]="36"
                    [height]="750"
                    [pageSize]="30"
                    kendoGridSelectBy="id"
                    scrollable="virtual"
                    [selectedKeys]="seleccionados"
                    (cellClick)="cellClick($event)"
                    *ngIf="router.url === '/herramientas'">
          <!--BOTONES ARRIBA-->
          <ng-template kendoGridToolbarTemplate position="top">
            <button (click)="onClickEditar()" class="btn btn-success btn-sm mr-1">{{ 'editar' | translate}}</button>
            <button (click)="onClickNuevo()" class="btn btn-primary btn-sm mr-1">{{ 'nuevo' | translate}}</button>
            <button (click)="onClickEliminar(content)" class="btn btn-danger btn-sm mr-1" [disabled]="isDeleting || this.user.herramientas <2">{{ 'eliminar' | translate}}</button>
          </ng-template>
          <!--checkbox-->
          <kendo-grid-checkbox-column width="5%">
            <ng-template kendoGridHeaderTemplate>
              <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox
                     [state]="selectAllState"
                     (selectAllChange)="onSelectAllChange($event)">
              <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
            </ng-template>
          </kendo-grid-checkbox-column>
          <!--id-->
          <kendo-grid-column width="10%" [style]="{'text-align': 'left'}" field="id" hidden="hidden" title="id"></kendo-grid-column>
          <!--nombre-->
          <kendo-grid-column width="50%" [style]="{'text-align': 'left'}" field="nombre" title="{{ 'nombre' | translate}}"></kendo-grid-column>
          <!--vida-->
          <kendo-grid-column width="6%" [style]="{'text-align': 'right'}" field="vida" title="{{ 'vida' | translate}}"></kendo-grid-column>
          <!--diametro-->
          <kendo-grid-column width="6%" [style]="{'text-align': 'right'}" field="diametro" title="{{ 'diametro' | translate}}"></kendo-grid-column>
          <!--longitud-->
          <kendo-grid-column width="6%" [style]="{'text-align': 'right'}" field="longitud" title="{{ 'longitud' | translate}}"></kendo-grid-column>
          <!--Angulo-->
          <kendo-grid-column width="6%" [style]="{'text-align': 'right'}" field="angulo" title="{{ 'angulo' | translate}}"></kendo-grid-column>
          <!--nfiltros-->
          <kendo-grid-column width="6%" [style]="{'text-align': 'right'}" field="numFilos" title="{{ 'nfiltros' | translate}}"></kendo-grid-column>
          <!--salto-->
          <kendo-grid-column width="6%" [style]="{'text-align': 'right'}" field="salto" title="{{ 'saltos' | translate}}"></kendo-grid-column>

          <!--imagen-->

          <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
          filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
          filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
          filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
          filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
          filterContainsOperator="{{'filterContainsOperator' | translate}}"
          filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
          filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
          filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
          filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
          filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
          filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
          filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
          filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
          filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
          filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
          filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
          filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
          filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
          filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
          groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
          noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
        </kendo-grid>
      </div>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab [title]="placastr">
    <ng-template kendoTabContent>
      <div kendoTooltip
           showOn="none"
           [tooltipTemplate]="template"
           filter=".k-grid td"
           (mouseover)="showGridTooltip($event)">
        <kendo-grid [kendoGridBinding]="placas"
                    [sortable]="true"
                    [navigable]="true"
                    filterable="menu"
                    [rowHeight]="36"
                    [height]="750"
                    [pageSize]="30"
                    kendoGridSelectBy="id"
                    scrollable="virtual"
                    [selectedKeys]="seleccionadosPlacas"
                    (cellClick)="cellClickPlacas($event)"
                    *ngIf="router.url === '/herramientas'">
          <!--BOTONES ARRIBA-->
          <ng-template kendoGridToolbarTemplate position="top">
            <button (click)="onClickEditarPlacas()" class="btn btn-success btn-sm mr-1">{{ 'editar' | translate}}</button>
            <button (click)="onClickNuevoPlacas()" class="btn btn-primary btn-sm mr-1">{{ 'nuevo' | translate}}</button>
            <button (click)="onClickEliminarPlacas(content)" class="btn btn-danger btn-sm mr-1" [disabled]="isDeleting || this.user.placas <2">{{ 'eliminar' | translate}}</button>
          </ng-template>
          <!--checkbox-->
          <kendo-grid-checkbox-column width="3%" showSelectAll="true"></kendo-grid-checkbox-column>
          <!--id-->
          <kendo-grid-column width="10%" field="id" hidden="hidden" title="id"></kendo-grid-column>
          <!--nombre-->
          <kendo-grid-column width="86%" [style]="{'text-align': 'left'}" field="nombre" title="{{ 'nombre' | translate}}"></kendo-grid-column>
          <!--vida-->
          <kendo-grid-column width="5%" [style]="{'text-align': 'right'}" field="vida" title="{{ 'vida' | translate}}"></kendo-grid-column>
          

          <!--imagen-->

          <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
          filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
          filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
          filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
          filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
          filterContainsOperator="{{'filterContainsOperator' | translate}}"
          filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
          filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
          filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
          filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
          filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
          filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
          filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
          filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
          filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
          filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
          filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
          filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
          filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
          filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
          groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
          noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
        </kendo-grid>
      </div>
    </ng-template>
  </kendo-tabstrip-tab>


</kendo-tabstrip>
<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>



<!--POPUP ELIMINAR-->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'preguntaeliminarpopup' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="eliminarRegistro(contentloading, contentNotErased)" [disabled]="this.user.herramientas <2">{{ 'si' | translate }}</button>
  </div>
</ng-template>

<!--POPUP NO SE PUDO ELIMINAR-->
<ng-template #contentNotErased let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'registrosnoeliminadas' | translate }}</p>
  </div>

</ng-template>

<ng-template #contentloading let-modal>
  <div class="modal-body">
    <p>{{ 'eliminando' | translate }}</p>
    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'eliminando' | translate }}...</span>
    </div>
  </div>
</ng-template>

<router-outlet></router-outlet>
