<style>
  :host /deep/ .k-grid tbody td {
    white-space: nowrap;
    line-height: 20px;
    padding: 8px 12px;
  }

  :host /deep/ .k-grid .k-grid-content td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>

<!-- DIV MAQUINAS -->
<div class="cont-filtro-maquinas-areas clearfix">
  <div class="filtro-areaproductiva-cont" style="width: 230px;">
    <div class="card" style="height: 90px;">
      <div class="card-body">

        <div class="row">

          <!--AREAS PRODUCTIVAS-->
          <div class="col-md-12" style="margin-top: -3px;">
            <kendo-multiselect kendoMultiSelectSummaryTag [(data)]="groupedSeccion"
                               [textField]="'nombre'"
                               [valueField]="'id'"
                               [autoClose]="false"
                               (close)="seccionChanged()"
                               [(ngModel)]="seccionesSeleccionadas"
                               placeholder="{{ 'secciones' | translate }}"
                               style="width: 200px;"
                               [kendoDropDownFilter]="{operator: 'contains'}">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seccionesSeleccionadas' | translate }}</ng-container>
              </ng-template>
              <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
            </kendo-multiselect>
          </div>

          <!--GRUPOS-->
          <div class="col-md-12" style="margin-top: 3px;">
            <kendo-multiselect kendoMultiSelectSummaryTag [data]="grupos"
                               [textField]="'nombre'"
                               [valueField]="'id'"
                               [autoClose]="false"
                               (close)="seccionChanged()"
                               [(ngModel)]="gruposSeleccionados"
                               placeholder="{{ 'grupoMaquinas' | translate }}"
                               style="width: 200px;"
                               [kendoDropDownFilter]="{operator: 'contains'}">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'gruposSeleccionados' | translate }}</ng-container>
              </ng-template>
            </kendo-multiselect>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MAQUINAS -->
  <div class="no-contraer-click-maquina cont-select-maquinas-out" style="width: calc(100% - 540px);" [ngClass]="{'aparecer': aparecer == true}">
    <div class="desplegar-selector-maquina" (click)="desplegarMaquinasClick()">
      <i class="fas fa-ellipsis-v"></i>
    </div>
    <div class="cont-select-maquinas">
      <div id="divMensajeSeleccionar" class="text-select-maquinas" *ngIf="selectedMaquinas.length==0">
        <div>
          {{ 'seleccioneMaquina' | translate }}
        </div>
      </div>
      <div *ngFor="let maquina of JmaquinasMostradas" (click)="clickMaquina(maquina)" class="clickable" [ngClass]="{'actual': selectedMaquinas.includes(maquina.id)}">
        <label>{{maquina.nombre}}</label>
        <img class="fas fa-3x" [src]="maquina.imagenBase64">
      </div>
    </div>
  </div>
  <!-- FILTRO -->
  <div class="cont-filtro-informes">
    <div class="card">
      <!-- ORIGINAL / VERSION DE SIMULADOR -->
      <div class="card-body">
        <label class="crontol-label float-left mr-1">{{ 'version' | translate }}</label>
        <kendo-dropdownlist [data]="Jplanificadores"
                            [textField]="'text'"
                            [valueField]="'value'"
                            [(value)]="JplanificadoresSelected"
                            (valueChange)="versionChanged($event)"
                            style="width: 100px;"
                            class="mr-2">
        </kendo-dropdownlist>
        <div class="clearfix" style="margin-top: 3px;">
          <!-- CORTO -->
          <button type="button" class="btn btn-success ml-1 mr-1 float-left" [class.btn-success]="mostrarCorto" [class.btn-outline-success]="!mostrarCorto" (click)="btnMostrarCorto()">{{ 'corto' | translate }}</button>
          <!-- LARGO -->
          <button type="button" class="btn btn-success float-left" [class.btn-success]="mostrarLargo" [class.btn-outline-success]="!mostrarLargo" (click)="btnMostrarLargo()">{{ 'largo' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;"></div>
</div>

<div style="position: relative;">
  <ng-template #template let-anchor>
    <span>{{ anchor.nativeElement.innerText }}</span>
  </ng-template>
  <div kendoTooltip
       showOn="none"
       [tooltipTemplate]="template"
       filter=".k-grid td"
       (mouseover)="showGridTooltip($event)">


    <!--JATORRIZKOA: <kendo-grid [data]="gridData"

                            [selectable]="{cell:false, checkboxOnly: false, drag: false, enabled: true, mode: 'multiple'}"
                            [selectedKeys]="JOperacionesSelecteds"
                            kendoGridSelectBy="numFila"
                           
                            #grid="kendoGrid"
                            class="grid-plan-corto"> -->

    <kendo-grid [kendoGridBinding]="JPlanificadorLista"
                [selectable]="{cell:false, checkboxOnly: false, drag: false, enabled: true, mode: 'multiple'}"
                [selectedKeys]="JOperacionesSelecteds"
                kendoGridSelectBy="numFila"
                filterable="menu"
                [navigable]="true"
                [resizable]="true"
                [pageable]="false"
                [sortable]="true"
                #grid="kendoGrid"
                scrollable="virtual"
                [rowHeight]="37"
                [height]="750"
                class="grid-plan-corto">

      <ng-template kendoGridToolbarTemplate>
        <button kendoButton type="button" (click)="imprimirXls($event, grid)" icon="file-excel">Excel</button>
        <button kendoButton type="button" (click)="cargarURLRuta()" class="btn btn-info m-1">{{ 'copiarDireccion' | translate }}</button>
      </ng-template>

      <kendo-grid-column field="maquina" title="{{ 'maquina' | translate }}" [width]="150" [hidden]="selectedMaquinas.length == 0 || selectedMaquinas.length == 1"></kendo-grid-column>
      <kendo-grid-column field="refOF" title="{{ 'of' | translate }}" [width]="100"></kendo-grid-column>
      <kendo-grid-column field="cliente" title="{{ 'cliente' | translate }}" [width]="100"></kendo-grid-column>
      <kendo-grid-column field="pieza" title="{{ 'pieza' | translate }}" [width]="150"></kendo-grid-column>
      <kendo-grid-column [hidden]="ocultarParte == 1" field="parte" title="{{ 'parte' | translate }}" [width]="150"></kendo-grid-column>
      <kendo-grid-column field="operacion" title="{{ 'operacion' | translate }}" [width]="150"></kendo-grid-column>
      <kendo-grid-column field="secuenciaOperacion" title="{{ 'secuencia_abreviado' | translate }}" [width]="50"></kendo-grid-column>
      <kendo-grid-column field="fechaLimite_excel" title="{{ 'fechaentrega' | translate }}" [width]="80">
        <ng-template kendoGridCellTemplate let-dataItem> {{dataItem.fechaLimite_excel}} </ng-template>
        <!-- <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechaLimite | kendoDate }}</ng-template> -->
      </kendo-grid-column>
      <kendo-grid-column field="fecha_previa_excel" title="{{ 'anterior' | translate }}" [width]="80" *ngIf="!esTTTbergara">
        <ng-template kendoGridCellTemplate let-dataItem> {{dataItem.fecha_previa_excel}}</ng-template>
        <!-- <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fecha_previa | kendoDate }}</ng-template> -->
      </kendo-grid-column>
      <kendo-grid-column field="ordenPlanificado" title="{{ 'orden' | translate }}" [width]="50" [style]="{'text-align': 'right'}"></kendo-grid-column>
      <kendo-grid-column field="semana" title="{{ 'semana' | translate }}" [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem> {{dataItem.semana | translate}} ({{ dataItem.fecha | kendoDate }})</ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="fechaIni" title="{{ 'inicio' | translate }}" [width]="140" *ngIf="esTTTbergara">
        <ng-template kendoGridCellTemplate let-dataItem> {{ myFunctions.dateToYYYYMMDDHHmmSSconSeparacion(dataItem.fechaIni) }} </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="fechaFin" title="{{ 'fin' | translate }}" [width]="140" *ngIf="esTTTbergara">
        <ng-template kendoGridCellTemplate let-dataItem> {{ myFunctions.dateToYYYYMMDDHHmmSSconSeparacion(dataItem.fechaFin) }} </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="fecha_proxima_excel" title="{{ 'proxima' | translate }}" [width]="80" *ngIf="!esTTTbergara">
        <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fecha_proxima_excel }}</ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="tiempoPreparacionHH" title="{{ 'tPreparacion' | translate }}" [hidden]="true" [width]="70" [style]="{'text-align': 'right'}"></kendo-grid-column>
      <kendo-grid-column field="tiempoEstimadoHH" title="{{ 'tEstimado' | translate }}" [hidden]="true" [width]="70" [style]="{'text-align': 'right'}"></kendo-grid-column>
      <kendo-grid-column field="cantidad" title="{{ 'cantidad' | translate }}" [width]="70" [style]="{'text-align': 'right'}" *ngIf="!esTTTbergara"></kendo-grid-column>
      <kendo-grid-column field="peso" title="{{ 'peso' | translate }}" [width]="70" [style]="{'text-align': 'right'}" *ngIf="esTTTbergara">
        <ng-template kendoGridCellTemplate let-dataItem> {{ myFunctions.calcularPorcentaje(dataItem.cantidad * dataItem.peso, dataItem.cantidad * dataItem.peso, dataItem.cantidad * dataItem.peso, 0, true) }} </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="temperatura" title="{{ 'temperatura' | translate }}" [width]="70" *ngIf="esTTTbergara" [style]="{'text-align': 'right'}"></kendo-grid-column>
      <kendo-grid-column field="numeroEnvases" title="{{ 'numeroEnvases' | translate }}" [width]="70" *ngIf="esTTTbergara"></kendo-grid-column>
      <!-- <kendo-grid-column field="tiempoTotal" title="{{ 'tTotal' | translate }}" [width]="70" [style]="{'text-align': 'right'}"></kendo-grid-column> -->
      <!--<kendo-grid-column field="tiempoTotalEnSegundos" title="{{ 'tTotal' | translate }}" width="70" [style]="{'text-align': 'right'}">
        <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM_SS(dataItem.tiempoTotalEnSegundos)}}</ng-template>
      </kendo-grid-column>-->
      <kendo-grid-column field="tiempoTotal" title="{{ 'tTotal' | translate }}" [width]="60" [style]="{'text-align': 'right'}"></kendo-grid-column>
      <kendo-grid-column field="tieneProgramaText" title="{{ 'programa_abreviado' | translate }}" [width]="40" [style]="{'text-align': 'center'}" [headerStyle]="{'text-align': 'center'}">
        <ng-template kendoGridHeaderTemplate let-column>
          <label>
            <span class="tooltip-consumibles-contenido"><i class="fas fa-info-circle mr-2"></i><span [attr.contenido]="contenidoTienePrograma" class="tooltipTextHeader"></span></span>
            {{ 'programa' | translate }}
          </label>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <input type="checkbox" [(ngModel)]="dataItem.tienePrograma" kendoCheckBox disabled />
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="tieneHerramientaText" title="{{ 'herramienta_abreviado' | translate }}" [width]="40" [style]="{'text-align': 'center'}" [headerStyle]="{'text-align': 'center'}">
        <ng-template kendoGridHeaderTemplate let-column>
          <label> {{ 'herramienta' | translate }} </label>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <input type="checkbox" [(ngModel)]="dataItem.tieneHerramienta" kendoCheckBox disabled />
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="dataItem" [width]="30" title="">
        <!-- <ng-template kendoGridHeaderTemplate let-column>
          <span class="tooltip-consumibles-contenido">
            <label><i class="fas fa-info-circle mr-2"></i></label>
            <span class="tooltipTextHeader">{{ 'irAOperacion' | translate }}</span>
          </span>
        </ng-template> -->
        <ng-template kendoGridCellTemplate let-dataItem>
          <a (click)="btnIrAOperacion(dataItem)"> <i class="icon-documento-alt"></i></a>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-excel fileName="{{'lista' | translate}}.xlsx" [fetchData]="allData"></kendo-grid-excel>
      <kendo-grid-pdf fileName="{{'listadoReservas' | translate}}.pdf" [repeatHeaders]="true">
        <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
      </kendo-grid-pdf>
      <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                           filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                           filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                           filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                           filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                           filterContainsOperator="{{'filterContainsOperator' | translate}}"
                           filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                           filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                           filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                           filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                           filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                           filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                           filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                           filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                           filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                           filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                           filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                           filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                           filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                           filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                           groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                           noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
    </kendo-grid>
    <div *ngIf="loadingGrid" class="k-i-loading" style="z-index: 3;"></div>
  </div>
  <!-- <div class="card" *ngIf="guardarActivo">
    <div class="card-body">
      <button type="button" class="btn btn-success" (click)="btnGuardar()" [disabled]="this.user.planificadorLista < 2">{{ 'guardar' | translate }}</button>
    </div>
  </div> -->
</div>