<div class="row">
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
        <div class="card">
            <div class="card-header">
                <h3>{{ 'datos' | translate}}</h3>
                <div class="plegarpanel"></div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group ">
                            <kendo-label text="{{ 'proveedor' | translate}}">
                                <div class="caja">
                                    <kendo-dropdownlist style="width:100%" [data]="dataProveedores" valuePrimitive="true" [disabled]="idProveedoresTabla != 0"
                                          [textField]="'nombre'" [valueField]="'id'" [(value)]="proveedorSeleccionado" 
                                          [ngClass]="{ 'is-invalid': submitted && proveedorSeleccionado==undefined }">
                                    </kendo-dropdownlist>
                                    <div *ngIf="submitted && proveedorSeleccionado==undefined" class="invalid-feedback">
                                        <div *ngIf="proveedorSeleccionado==undefined">{{ 'proveedorEsRequerido' |
                                            translate}}</div>
                                    </div>
                                </div>
                            </kendo-label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <div class="form-group ">
                            <kendo-label text="{{ 'precio' | translate}}">
                                <div class="caja">
                                    <kendo-numerictextbox class="form-control" [(value)]="proveedorPrecio" [min]="0.0"
                                        [max]="999.0" [autoCorrect]="true" [step]="0.1">
                                    </kendo-numerictextbox>
                                </div>
                            </kendo-label>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group ">
                            <kendo-label text="{{ 'plazoEntrega' | translate}}">
                                <div class="caja">
                                    <kendo-numerictextbox class="form-control" [(value)]="proveedorPlazoEntrega" [min]="0"
                                        [max]="999" [autoCorrect]="true" [step]="1"  [format]="'n'">
                                    </kendo-numerictextbox>
                                </div>
                            </kendo-label>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group ">
                            <kendo-label text="{{ 'garantia' | translate}}">
                                <div class="caja">
                                    <kendo-numerictextbox class="form-control" [(value)]="proveedorGarantia" [min]="0"
                                        [max]="999" [autoCorrect]="true" [step]="1"  [format]="'n'">
                                    </kendo-numerictextbox>
                                </div>
                            </kendo-label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <!--OBSERVACIONES-->
                        <div class="form-group">
                            <kendo-label text="{{ 'observacion' | translate }}">
                                <div class="caja">
                                    <textarea kendoTextArea class="form-control" [(value)]="proveedorObservaciones"
                                        style="height: 7em;"></textarea>
                                </div>
                            </kendo-label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>



<button type="button" class="btn mr-2 btn-primary" (click)="onClickGuardar()" [disabled]="this.user.activos<2">{{ 'guardar' | translate
    }}</button>
<button type="button" class="btn mr-2 btn-danger" (click)="atras()">{{ 'cancelar' | translate
    }}</button>