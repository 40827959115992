<form [formGroup]="form">
    <div class="row">
        <div class="col-md-6">
            <div class="card">
                <div class="card-header">
                    <h3 class="pt-3">{{ 'datos' | translate}}</h3>
                </div>
                <div class="card-body">
                    <div class="form-row">
                        <div class="form-group mr-2">
                            <kendo-label text="{{ 'nombre' | translate}}">
                                <div class="caja">
                                    <kendo-textbox formControlName="nombre" maxlength="150"
                                        [ngClass]="{ 'is-invalid': submitted && form.controls.nombre.errors }">
                                    </kendo-textbox>
                                    <div *ngIf="submitted && form.controls.nombre.errors" class="invalid-feedback">
                                        <div *ngIf="form.controls.nombre.errors.required">{{ 'nombrerequerido' |
                                            translate}}
                                        </div>
                                    </div>
                                </div>
                            </kendo-label>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group mr-2">
                            <kendo-label text="{{ 'descripcion' | translate}}">
                                <div class="caja">
                                    <kendo-textbox formControlName="descripcion" maxlength="150"
                                        [ngClass]="{ 'is-invalid': submitted && form.controls.descripcion.errors }">
                                    </kendo-textbox>
                                    <div *ngIf="submitted && form.controls.descripcion.errors" class="invalid-feedback">
                                        <div *ngIf="form.controls.descripcion.errors.required">{{ 'descripcionrequerido' |
                                            translate}}
                                        </div>
                                    </div>
                                </div>
                            </kendo-label>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group form-check p-0 mr-2" style="width: 5%">
                            <kendo-label for="activo" class="form-check-label" text="{{ 'activo' | translate}}">
                            </kendo-label>
                            <div class="caja">
                                <kendo-switch id="activo" formControlName="activo" [onLabel]="' '" [offLabel]="' '">
                                </kendo-switch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <button kendoButton class="btn mr-1  btn-primary" (click)="guardarClick()" [disabled]="this.user.activosTipos <2">
        {{ 'guardar' | translate}}
    </button>
    <a routerLink="/tiposActivos" class="btn mr-1  btn-danger">{{ 'cancelar' | translate}}</a>

</form>