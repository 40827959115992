import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UsuariosService, InformeOeeService, MenuService, MaquinasService, InformeProyectosService, OperariosService } from '@app/_services';
import { MyFunctions } from '@app/_helpers';

import * as d3 from 'd3';
import * as c3 from 'c3';
import { groupBy, GroupResult } from '@progress/kendo-data-query';

@Component({
  selector: 'app-informeoee-data',
  templateUrl: 'informeOEE.html'
})

export class InformeOEEComponent implements OnInit {

  form: FormGroup;
  id: string;
  isAddMode: boolean;
  submitted = false;
  user = this.userService.userValue;

  loading: boolean = true;

  private dataGraficoDonutOEE: any;
  private dataGraficoDonutDisponibilidad: any;
  private dataGraficoDonutRendimiento: any;
  private dataGraficoDonutCalidad: any;
  private dataGraficoEvolucion: any;
  private dataGraficoHoras: any;

  private graficoDonutOEE: any;
  private graficoDonutDisponibilidad: any;
  private graficoDonutRendimiento: any;
  private graficoDonutCalidad: any;
  private graficoEvolucion: any;
  private graficoHoras: any;

  public maquinas: any;
  public maquinasJ: any;
  public maquinasSeleccionadas: string[] = [];
  public datosJ: any;
  public data: any;

  // FILTRO CALENDARIO

  // public dpFechaIni: Date = new Date(this.myFunctions.getDateNow().getTime() - (30 * 24 * 60 * 60 * 1000));//30 egun atzera
  // public dpFechaFin: Date = this.myFunctions.getDateNow()
  public AnnoMesSemanaDia: number;
  public rangoFechasFiltro = {
    start: new Date(),
    end: new Date()
  };
  public mostrarCalendario1: boolean = false;
  public hoy = new Date();
  public dpFechaIni: Date = new Date(this.hoy.getFullYear(), this.hoy.getMonth(), this.hoy.getDate() - 7);
  public dpFechaFin: Date = this.hoy;
  public DaysInMonths = this.hoy;

  // // // //

  // FILTRO MAQUINAS
  public aparecer = false;
  public selectedMaquina: number = 0;
  public maquinasDesplegadas = false;
  public maquinasDesplegadasCount = 0; //para que solo se realice una vez
  public maquinasMostradas: any = [];

  public listaOperarios: any = [];
  public operariosSeleccionados: any = [];
  public seleccionarTodo: boolean = false;
  public listaOperariosSTR: string = "";
  public listaMaquinasAux: any = [];
  public listaOperariosAux: any = [];
  // // // //

  public objetivoOee: number = 70;

  private turno: number = -1;


  //AREA PRODUCTIVA / SECCION
  public secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //GRUPOS DE MAQUINAS
  public grupos: any;
  public gruposSeccion: any;
  public gruposSeleccionados: any;

  //CARGA DE SECCIONES Y GRUPOS
  public gruposCargados: boolean = false;
  public seccionesCargadas: boolean = false;

  constructor(
    private userService: UsuariosService,
    private maquinasService: MaquinasService,
    private informeOeeService: InformeOeeService,
    private informeProyectosService: InformeProyectosService,
    private translateService: TranslateService,
    private menuService: MenuService,
    private myFunctions: MyFunctions,
    private operariosService: OperariosService
  ) {

  }

  ngOnInit() {
    this.menuService.titulo = this.translateService.instant('tituloOee');
    this.dpFechaIni = new Date(this.myFunctions.getDateNow().getTime() - (30 * 24 * 60 * 60 * 1000));//30 egun atzera
    this.dpFechaFin = this.myFunctions.getDateNow()
    this.AnnoMesSemanaDia = 4;

    this.dpFechaIni = this.informeOeeService.get_fechaIni_model();
    this.dpFechaFin = this.informeOeeService.get_fechaFin_model();
    this.AnnoMesSemanaDia = this.informeOeeService.get_AnnoMesSemanaDia_model();
    if (this.dpFechaIni === undefined) {
      // this.dpFechaIni = new Date(this.myFunctions.getDateNow().getTime() - (6 * 24 * 60 * 60 * 1000));//7 egun atzera
      // this.dpFechaFin = this.myFunctions.getDateNow()
      // this.dpFechaIni.setHours(0, 0, 0);
      // this.dpFechaFin.setHours(0, 0, 0);
      this.cargarFechas();
      this.informeOeeService.set_fechaIni_model(this.dpFechaIni);
      this.informeOeeService.set_fechaFin_model(this.dpFechaFin);
      this.AnnoMesSemanaDia = 4;
      this.informeOeeService.set_AnnoMesSemanaDia_model(this.AnnoMesSemanaDia);
    }

    this.informeOeeService.get_objetivo_oee().subscribe(
      json => {
        this.objetivoOee = json[0].objetivoOee;
      }
    )

    this.cargarGrupos();
    this.cargarAreasProductivas();

    var bukatua = new Promise<void>((resolve, reject) => {

      var maquinas_model = this.informeOeeService.get_maquinas_model();
      var maquinasSeleccionadas_model = this.informeOeeService.get_maquinasSeleccionadas_model();
      var seccionesSeleccionadas_model = this.informeOeeService.get_seccionesSeleccionadas_model();
      var maquinasCompletas_model = this.informeOeeService.get_maquinasCompletas_model();
      var gruposSeleccionados_model = this.informeOeeService.get_gruposSeleccionados_model();

      if (maquinas_model == false) {
        this.informeOeeService.get_maquinas().subscribe(
          json => {
            this.maquinasJ = json;
            this.informeOeeService.set_maquinasCompletas_model(this.maquinasJ); //Necesitamos tenerlo al filtrar, pero lo guardamos en el servicio para no estar llamándolo siempre
            this.cargarMaquinas();
            resolve();
          }
        )
      } else {
        this.maquinas = maquinas_model;
        this.listaMaquinasAux = maquinas_model;
        this.maquinasSeleccionadas = maquinasSeleccionadas_model;
        this.seccionesSeleccionadas = seccionesSeleccionadas_model;
        this.gruposSeleccionados = gruposSeleccionados_model;
        this.maquinasJ = maquinasCompletas_model;
        this.cargarMaquinas2();
        resolve();
      }

      this.operariosService.getAllUsuariosConColor().subscribe(json => {
        var lag: any = json;
        lag.forEach(element => {
          this.listaOperarios.push(element);
          this.listaOperariosAux.push(element);
          //this.operariosSeleccionados.push(element.idUsuario);
        });
        this.listaOperariosAux.sort((a, b) => (a.nombre.toLowerCase() > b.nombre.toLowerCase()) ? 1 : ((b.nombre.toLowerCase() > a.nombre.toLowerCase()) ? -1 : 0))
      });

    });

    bukatua.then(() => {
      this.cargarDonuts();
      this.cargarEvolucion();
      this.cargarHoras();
    });

  }

  private cargarFechas() {
    var fechaIni;

    //calcular fecha inicio
    if (this.dpFechaIni == undefined) {
      this.dpFechaIni = this.myFunctions.getDateNow()
    }
    fechaIni = this.dpFechaIni
    if (fechaIni.getDay() == 3) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 2));
    } else if (fechaIni.getDay() == 4) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 3));
    } else if (fechaIni.getDay() == 5) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 4));
    } else if (fechaIni.getDay() == 6) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 5));
    } else if (fechaIni.getDay() == 0) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 6));
    } else if (fechaIni.getDay() == 2) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 1));
    }

    //calcular fecha fin
    fechaIni = new Date(this.dpFechaIni);
    this.dpFechaFin = new Date(fechaIni.setDate(fechaIni.getDate() + 6));

    //calcular horas
    this.dpFechaIni.setHours(0, 0, 0);
    this.dpFechaFin.setHours(0, 0, 0);
  }

  cargarGrupos() {

    this.maquinasService.GetGruposMaquinasConSeccion().subscribe(json => {
      this.grupos = json.data;
      this.gruposSeccion = this.grupos;
      this.gruposCargados = true;

      var aurk = false;
      this.gruposSeccion = [];
      this.grupos.forEach(grupo => {
        aurk = false;
        this.seccionesSeleccionadas.forEach(seccion => {
          if ((grupo.idSeccion == seccion.id) && !aurk) {
            aurk = true;
            this.gruposSeccion.push(grupo);
          }
        });
      });


    });

  }

  cargarAreasProductivas() {

    var an1: any = this.userService.secciones;
    this.secciones = undefined;

    if (an1 != undefined) this.secciones = an1.filter(f => f.activo === true);

    if (this.secciones == undefined) {

      this.userService.getSecciones().subscribe(json => {
        this.userService.secciones = json;
        //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
        var an1: any = this.userService.secciones;
        this.secciones = an1.filter(f => f.activo === true);

        var an: any = this.secciones;
        this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
        an.forEach(row => {
          if (row.activo) this.seccionesSeleccionadas.push(row);
        });

      });

    } else {

      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

      an.forEach(row => {
        if (row.activo) this.seccionesSeleccionadas.push(row);
      });

    }

  }

  cargarMaquinas() {
    this.maquinasService.get().subscribe(json => {
      this.maquinas = [];
      this.listaMaquinasAux = [];
      var primeraMaquina = true;
      this.maquinasService.set_maquinas_model(json);
      var maquinas1: any;
      maquinas1 = this.maquinasService.get_maquinas_model();

      for (let maquina of this.maquinasJ) {
        maquinas1.forEach(element => {
          if (element.id == maquina.id) {
            var m = { id: maquina.id, nombre: maquina.nombre, imgBase64: element.imagenBase64 };
            this.maquinas.push(m);
            this.listaMaquinasAux.push(m);

            if (primeraMaquina) {
              this.maquinasSeleccionadas.push(maquina.id);
              primeraMaquina = false;
            }
          }
        });
      }
      this.informeOeeService.set_maquinas_model(this.maquinas);
      this.informeOeeService.set_maquinasSeleccionadas_model(this.maquinasSeleccionadas);
      this.informeOeeService.set_seccionesSeleccionadas_model(this.seccionesSeleccionadas);
      this.informeOeeService.set_gruposSeleccionados_model(this.gruposSeleccionados);
      this.cargarGraficos();
    });
  }
  cargarMaquinas2() {
    var listaMaquinas: any;
    //MAQUINAS
    this.informeProyectosService.Get_Maquinas().subscribe(
      (data: any) => {
        //los grupos se pasan en una lista de strings separados por ",", de esta forma convertimos este string en una lista de JS manejable.
        var mostra: any = [];
        data.forEach(
          row => {
            row["idsGrupos"] = row.idGrupos.split(',');
            var an: any = this.userService.secciones;
            var listaIdsecciones = (an.filter(f => { return f.activo; }) === undefined) ? [] : an.filter(f => { return f.activo; }).map(a => a.id);
            if (listaIdsecciones.includes(row.idSeccion)) {
              mostra.push(row)
            }
          });
        listaMaquinas = mostra;
        var maquinasAux = [];

        var seccionesSeleccionadas = [];
        this.seccionesSeleccionadas?.forEach(elem => seccionesSeleccionadas.push(elem.id));
        var gruposSeleccionados = [];
        this.gruposSeleccionados?.forEach(elem => gruposSeleccionados.push(elem.id));
        if (this.maquinasJ) {
          for (let maquina of this.maquinasJ) {
            var aux = listaMaquinas.filter(f => f.idMaquina === maquina.id)[0];
            if ((this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) || (this.gruposSeleccionados && this.gruposSeleccionados.length > 0)) {
              if (seccionesSeleccionadas.includes(aux?.idSeccion) || aux?.idsGrupos.filter(r => gruposSeleccionados.includes(Number(r))).length > 0) {
                var m = { id: maquina.id, nombre: maquina.nombre };
                maquinasAux.push(m);
              }

            } else {
              var m = { id: maquina.id, nombre: maquina.nombre };
              maquinasAux.push(m);
            }
            this.maquinas = maquinasAux;
            this.listaMaquinasAux = maquinasAux;
          }
          this.maquinasSeleccionadas.forEach(elem => {
            var aux = this.maquinas.filter(e => e.id === elem);
            if (aux.length === 0)
              this.maquinasSeleccionadas.splice(this.maquinasSeleccionadas.indexOf(elem), 1);
          });
          if (this.maquinasSeleccionadas.length === 0) {
            this.maquinasSeleccionadas.push(this.maquinas[0].id);
          }
        }

        this.informeOeeService.set_maquinas_model(this.maquinas);
        this.informeOeeService.set_maquinasSeleccionadas_model(this.maquinasSeleccionadas);
        this.informeOeeService.set_seccionesSeleccionadas_model(this.seccionesSeleccionadas);
        this.informeOeeService.set_gruposSeleccionados_model(this.gruposSeleccionados);
        this.cargarGraficos();
      });
  }

  cargarDonuts() {
    this.dataGraficoDonutOEE = [['completo', 0], ['nocompleto', 100]];
    this.dataGraficoDonutDisponibilidad = [['completo', 0], ['nocompleto', 100]];
    this.dataGraficoDonutRendimiento = [['completo', 0], ['nocompleto', 100]];
    this.dataGraficoDonutCalidad = [['completo', 0], ['nocompleto', 100]];

    //GRAFICO DONUT OEE
    this.graficoDonutOEE = c3.generate({
      bindto: '#graficoDonutOEE_OEE',
      data: {
        columns: this.dataGraficoDonutOEE,
        type: 'donut',
        colors: {
          completo: '#18d6b0',
          nocompleto: '#3d6063'
        },
        order: 'null'
      },
      transition: {
        duration: 2000
      },
      donut: {
        title: "0",
        width: 15,
        label: { show: false }
      },
      legend: {
        show: false
      },
      tooltip: {
        show: false
      }
    });

    //GRAFICO DONUT DISPO
    this.graficoDonutDisponibilidad = c3.generate({
      bindto: '#graficoDonutDisponibilidad_OEE',
      data: {
        columns: this.dataGraficoDonutDisponibilidad,
        type: 'donut',
        colors: {
          completo: '#18d6b0',
          nocompleto: '#3d6063'
        },
        order: 'null'
      },
      transition: {
        duration: 2000
      },
      donut: {
        title: "0",
        width: 15,
        label: { show: false }
      },
      legend: {
        show: false
      },
      tooltip: {
        show: false
      }
    });

    //GRAFICO DONUT REND
    this.graficoDonutRendimiento = c3.generate({
      bindto: '#graficoDonutRendimiento_OEE',
      data: {
        columns: this.dataGraficoDonutRendimiento,
        type: 'donut',
        colors: {
          completo: '#18d6b0',
          nocompleto: '#3d6063'
        },
        order: 'null'
      },
      transition: {
        duration: 2000
      },
      donut: {
        title: "0",
        width: 15,
        label: { show: false }
      },
      legend: {
        show: false
      },
      tooltip: {
        show: false
      }
    });

    //GRAFICO DONUT CAL
    this.graficoDonutCalidad = c3.generate({
      bindto: '#graficoDonutCalidad_OEE',
      data: {
        columns: this.dataGraficoDonutCalidad,
        type: 'donut',
        colors: {
          completo: '#18d6b0',
          nocompleto: '#3d6063'
        },
        order: 'null'
      },
      transition: {
        duration: 2000
      },
      donut: {
        title: "0",
        width: 15,
        label: { show: false }
      },
      legend: {
        show: false
      },
      tooltip: {
        show: false
      }
    });

    d3.select('#graficoDonutOEE_OEE .c3-chart-arcs-title')
      .text("")
      .style("font-size", "30px")
      .attr("fill", "#18d6b0");
    d3.select('#graficoDonutDisponibilidad_OEE .c3-chart-arcs-title')
      .text("")
      .style("font-size", "30px")
      .attr("fill", "#18d6b0");
    d3.select('#graficoDonutRendimiento_OEE .c3-chart-arcs-title')
      .text("")
      .style("font-size", "30px")
      .attr("fill", "#18d6b0");
    d3.select('#graficoDonutCalidad_OEE .c3-chart-arcs-title')
      .text("")
      .style("font-size", "30px")
      .attr("fill", "#18d6b0");

    d3.selectAll("#graficoDonutOEE_OEE .c3-chart-arcs path").style("stroke-width", "0px");
    d3.selectAll("#graficoDonutDisponibilidad_OEE .c3-chart-arcs path").style("stroke-width", "0px");
    d3.selectAll("#graficoDonutRendimiento_OEE .c3-chart-arcs path").style("stroke-width", "0px");
    d3.selectAll("#graficoDonutCalidad_OEE .c3-chart-arcs path").style("stroke-width", "0px");
  }
  cargarEvolucion() {
    this.dataGraficoEvolucion = [['data'],
    [this.translateService.instant('disponibilidad')],
    [this.translateService.instant('rendimiento')],
    [this.translateService.instant('calidad')],
    [this.translateService.instant('total')],
    [this.translateService.instant('objetivo')]];

    var types = {};
    types[this.translateService.instant('disponibilidad')] = "line";
    types[this.translateService.instant('rendimiento')] = "line";
    types[this.translateService.instant('calidad')] = "line";
    types[this.translateService.instant('total')] = "area";
    types[this.translateService.instant('objetivo')] = "area";

    //GRAFICO EVOLUCION
    var graficoEvolucionDataFormat: any = "";
    if (this.AnnoMesSemanaDia == 1) graficoEvolucionDataFormat = '%Y';
    else if (this.AnnoMesSemanaDia == 2) graficoEvolucionDataFormat = '%Y-%m';
    else if (this.AnnoMesSemanaDia == 3) graficoEvolucionDataFormat = '%Y-%m-%d';
    else if (this.AnnoMesSemanaDia == 4) graficoEvolucionDataFormat = '%Y-%m-%d';

    this.graficoEvolucion = c3.generate({
      padding: {
        top: 0,
        right: 30,
        bottom: 0,
        left: 40,
      },
      data: {
        x: 'data',
        xFormat: '%d/%m/%Y',
        columns: this.dataGraficoEvolucion,
        types: types
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            format: graficoEvolucionDataFormat,
            outer: false
          }
        },
        y: {
          max: 100,
          min: 0,
          tick: {
            format: function (value) {
              return d3.format('.0%')(value / 100)
            },
            outer: false
          },
          padding: { top: 10, bottom: 0 }
        }
      },
      transition: {
        duration: 2000
      },
      bindto: '#graficoEvolucion_OEE'
    });
  }
  cargarHoras() {
    this.dataGraficoHoras = [[this.translateService.instant('total'), 0, 0, 0, 0],
    [this.translateService.instant('rendimiento'), 0, 0, 0, 0],
    [this.translateService.instant('paradas'), 0, 0, 0, 0],
    [this.translateService.instant('mantenimientos'), 0, 0, 0, 0],
    [this.translateService.instant('alarmas'), 0, 0, 0, 0],
    [this.translateService.instant('apagadas'), 0, 0, 0, 0],
    [this.translateService.instant('perdidasCalidad'), 0, 0, 0, 0]];

    //GRAFICO HORAS BARRAS
    this.graficoHoras = c3.generate({
      data: {
        columns: this.dataGraficoHoras,
        order: null,
        type: 'bar',
        groups: [[this.translateService.instant('total'), this.translateService.instant('paradas'), this.translateService.instant('mantenimientos'),
        this.translateService.instant('alarmas'), this.translateService.instant('apagadas'), this.translateService.instant('rendimiento'), this.translateService.instant('perdidasCalidad')]]
      },
      color: {
        pattern: [['#44e3c4'], ['#f99f4d'], ['#ebe078'], ['#99afcc'], ['#cc6464'], ['#4d4d4d'], ['#ffa8a8']]
      },
      axis: {
        x: {
          type: 'category',
          tick: {
            multiline: false
          },
          categories: [this.translateService.instant('tiempoTotal'), this.translateService.instant('disponibilidad'),
          this.translateService.instant('rendimiento'), this.translateService.instant('calidad')]
        },
        y: {
          show: false,
        },
        rotated: true
      },
      transition: {
        duration: 2000
      },
      bar: {
        width: {
          ratio: 0.85
        }
      },
      tooltip: {
        format: {
          value: function (value) {
            var hours = Math.floor(value / (60 * 60));
            var divisor_for_minutes = value % (60 * 60);
            var minutes = Math.floor(divisor_for_minutes / 60);
            if (0 <= minutes && minutes < 10)
              return hours + ":0" + minutes + "h";
            else
              return hours + ":" + minutes + "h";
          }
        }
      },
      bindto: '#graficoHoras_OEE'
    });
  }

  public cargarDatosInforme() {
    if (this.maquinasSeleccionadas.length === 0) {
      this.maquinasSeleccionadas.push(this.maquinasJ[0].id);
    }
    this.cargarGraficos();
    this.informeOeeService.set_maquinasSeleccionadas_model(this.maquinasSeleccionadas);
    this.informeOeeService.set_fechaIni_model(this.dpFechaIni);
    this.informeOeeService.set_fechaFin_model(this.dpFechaFin);
    this.informeOeeService.set_AnnoMesSemanaDia_model(this.AnnoMesSemanaDia);
  }

  public onChangeF(value: Date): void {
    this.dpFechaIni.setHours(0, 0, 0);
    this.dpFechaFin.setHours(0, 0, 0);

    if (this.dpFechaIni > this.dpFechaFin) {
      var f = this.dateCopy(this.dpFechaFin);
      this.dpFechaFin = this.dateCopy(this.dpFechaIni);
      this.dpFechaIni = f;
    }
    //this.cargarGraficos();
  }

  btnAnno_Click(event) {
    this.AnnoMesSemanaDia = 1;
    this.dpFechaIni = new Date(this.myFunctions.getDateNow().setFullYear(this.myFunctions.getDateNow().getFullYear() - 10));
    this.dpFechaFin = this.myFunctions.getDateNow()
    this.dpFechaIni.setHours(0, 0, 0);
    this.dpFechaFin.setHours(0, 0, 0);
  }
  btnMes_Click(event) {
    this.AnnoMesSemanaDia = 2;
    this.dpFechaIni = new Date(this.myFunctions.getDateNow().setMonth(this.myFunctions.getDateNow().getMonth() - 12));
    this.dpFechaFin = this.myFunctions.getDateNow()
    this.dpFechaIni.setHours(0, 0, 0);
    this.dpFechaFin.setHours(0, 0, 0);
  }
  btnSemana_Click(event) {
    this.AnnoMesSemanaDia = 3;
    this.dpFechaIni = new Date(this.myFunctions.getDateNow().setMonth(this.myFunctions.getDateNow().getMonth() - 3));
    this.dpFechaFin = this.myFunctions.getDateNow()
    this.dpFechaIni.setHours(0, 0, 0);
    this.dpFechaFin.setHours(0, 0, 0);
  }
  btnDias_Click(event) {
    this.AnnoMesSemanaDia = 4;
    this.dpFechaIni = new Date(this.myFunctions.getDateNow().getFullYear(), this.myFunctions.getDateNow().getMonth(), 1);
    this.dpFechaFin = this.myFunctions.getDateNow()
    this.dpFechaIni.setHours(0, 0, 0);
    this.dpFechaFin.setHours(0, 0, 0);
  }
  btnTodos_Click(event) {
    this.turno = -1;
  }
  btnManana_Click(event) {
    this.turno = 1;
  }
  btnTarde_Click(event) {
    this.turno = 2;
  }
  btnNoche_Click(event) {
    this.turno = 3;
  }

  cargarGraficos() {
    console.log("cargarGraficos")
    this.loading = true;

    var fini = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.dpFechaIni);
    this.dpFechaFin.setHours(23, 59, 59, 0);
    var ffin = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.dpFechaFin);


    this.listaOperariosSTR = "-";
    this.operariosSeleccionados.forEach((operario, i) => {
      if (i == 0 && this.operariosSeleccionados.length > 1) {
        this.listaOperariosSTR = operario
      } else if (this.operariosSeleccionados.length == 1) {
        this.listaOperariosSTR = operario
      } else {
        this.listaOperariosSTR = this.listaOperariosSTR + "," + operario
      }
    });

    //this.informeOeeService.Get_Oee_Fechas(fini, ffin, this.maquinasSeleccionadas.toString()).subscribe(json => {
    this.informeOeeService.Get_Oee_Fechas(fini, ffin, this.maquinasSeleccionadas.toString(), this.translateService.instant("sinAsignar"), this.turno, this.listaOperariosSTR).subscribe(json => {
      console.log(json)
      this.data = json;

      this.cargarGraficoBarrasYDonuts();
      this.cargarGraficoLineas();
      this.loading = false;

    });

  }

  cargarGraficoBarrasYDonuts() {

    var tEjecucion = 0;
    var tParadaDisponibilidad = 0;
    var tMantenimientoDisponibilidad = 0;
    var tAlarmaDisponibilidad = 0;
    var tApagadaDisponibilidad = 0;
    var tParadaRendimiento = 0;
    var tMantenimientoRendimiento = 0;
    var tAlarmaRendimiento = 0;
    var tApagadaRendimiento = 0;
    var tParadaCalidad = 0;
    var tMantenimientoCalidad = 0;
    var tAlarmaCalidad = 0;
    var tApagadaCalidad = 0;
    var tNegativoCalidad = 0;
    var tEstimado = 0;

    this.data.forEach(function (a) {
      tEjecucion += a.tEjecucion;
      tParadaDisponibilidad += a.tParadaDisponibilidad;
      tMantenimientoDisponibilidad += a.tMantenimientoDisponibilidad;
      tAlarmaDisponibilidad += a.tAlarmaDisponibilidad;
      tApagadaDisponibilidad += a.tApagadaDisponibilidad;
      tParadaRendimiento += a.tParadaRendimiento;
      tMantenimientoRendimiento += a.tMantenimientoRendimiento;
      tAlarmaRendimiento += a.tAlarmaRendimiento;
      tApagadaRendimiento += a.tApagadaRendimiento;
      tParadaCalidad += a.tParadaCalidad;
      tMantenimientoCalidad += a.tMantenimientoCalidad;
      tAlarmaCalidad += a.tAlarmaCalidad;
      tApagadaCalidad += a.tApagadaCalidad;
      tNegativoCalidad += a.tNegativoCalidad;
      tEstimado += a.tEstimado;
    });

    //CALCULAR DONUTS
    var oee: number = 0;
    var cal: number = 0;
    var ren: number = 0;
    var dis: number = 0;

    var perdidasDisponibilidad = tParadaDisponibilidad + tMantenimientoDisponibilidad + tAlarmaDisponibilidad + tApagadaDisponibilidad;
    var perdidasRendimiento = tParadaRendimiento + tMantenimientoRendimiento + tAlarmaRendimiento + tApagadaRendimiento;

    var perdidasCalidad = tParadaCalidad + tMantenimientoCalidad + tAlarmaCalidad + tApagadaCalidad;

    var disDividendo: number = tEjecucion + perdidasRendimiento;
    var disDivisor: number = tEjecucion + perdidasRendimiento + perdidasDisponibilidad;
    if (disDivisor == 0) dis = 0;
    else dis = (disDividendo / disDivisor) * 100;

    var renDividendo: number = tEstimado;
    var renDivisor: number = tEjecucion + perdidasRendimiento;
    if (renDivisor == 0) ren = 0;
    else ren = (renDividendo / renDivisor) * 100;

    var calDividendo: number = tEstimado - tNegativoCalidad - perdidasCalidad;
    var calDivisor: number = tEstimado;
    if (calDivisor == 0) cal = 0;
    else cal = (calDividendo / calDivisor) * 100;


    console.log(renDividendo)

    oee = cal * ren * dis / (10000);

    d3.select('#graficoDonutOEE_OEE .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "30px").style("opacity", "1")
      .text(Math.round(oee) + "%");
    d3.select('#graficoDonutDisponibilidad_OEE .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "30px").style("opacity", "1")
      .text(Math.round(dis) + "%");
    d3.select('#graficoDonutRendimiento_OEE .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "30px").style("opacity", "1")
      .text(Math.round(ren) + "%");
    d3.select('#graficoDonutCalidad_OEE .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "30px").style("opacity", "1")
      .text(Math.round(cal) + "%");

    if (oee > 100) oee = 100;
    if (dis > 100) dis = 100;
    if (ren > 100) ren = 100;
    if (cal > 100) cal = 100;

    this.dataGraficoDonutOEE = [['completo', oee], ['nocompleto', 100 - oee]];
    this.dataGraficoDonutDisponibilidad = [['completo', dis], ['nocompleto', 100 - dis]];
    this.dataGraficoDonutRendimiento = [['completo', ren], ['nocompleto', 100 - ren]];
    this.dataGraficoDonutCalidad = [['completo', cal], ['nocompleto', 100 - cal]];

    this.graficoDonutOEE.load({ columns: this.dataGraficoDonutOEE });
    this.graficoDonutDisponibilidad.load({ columns: this.dataGraficoDonutDisponibilidad });
    this.graficoDonutRendimiento.load({ columns: this.dataGraficoDonutRendimiento });
    this.graficoDonutCalidad.load({ columns: this.dataGraficoDonutCalidad });

    //CALCULAR BARRAS
    var tRendimiento: number = 0;
    if (tEstimado > tEjecucion) {
      tRendimiento = 0;
      tEstimado = tEjecucion;
    } else {
      tRendimiento = tEjecucion - tEstimado;
    }


    this.dataGraficoHoras = [[this.translateService.instant('total'), tRendimiento + tEstimado + perdidasRendimiento + perdidasDisponibilidad, tRendimiento + tEstimado + perdidasRendimiento, tEstimado, tEstimado - tNegativoCalidad - perdidasCalidad],
    [this.translateService.instant('rendimiento'), 0, 0, tRendimiento, 0],
    [this.translateService.instant('paradas'), 0, tParadaDisponibilidad, tParadaRendimiento, tParadaCalidad],
    [this.translateService.instant('mantenimientos'), 0, tMantenimientoDisponibilidad, tMantenimientoRendimiento, tMantenimientoCalidad],
    [this.translateService.instant('alarmas'), 0, tAlarmaDisponibilidad, tAlarmaRendimiento, tAlarmaCalidad],
    [this.translateService.instant('apagadas'), 0, tApagadaDisponibilidad, tApagadaRendimiento, tApagadaCalidad],
    [this.translateService.instant('perdidasCalidad'), 0, 0, 0, tNegativoCalidad]];

    this.graficoHoras.load({ columns: this.dataGraficoHoras });

  }
  cargarGraficoLineas() {

    var dataExpandida: any = [];

    //EXPANDIR LOS DATOS POR FECHATURNO (POR CADA FECHATURNO UNA LINEA)
    //this.data.forEach(function (a) {
    //  a.listaFechaTurnos.forEach(function (b) {
    //    var newItem = a;
    //    newItem.fechaTurno = new Date(b);
    //    dataExpandida.push(a);
    //  });
    //});

    //LOS AGRUPAMOS POR FECHA
    var groupByPorFecha = this.data; //[];

    //dataExpandida.forEach(function (a) {
    //  if (!this[a.fechaTurno]) {
    //    this[a.fechaTurno] = {
    //      fechaTurno: a.fechaTurno, tEjecucion: 0, tParadaDisponibilidad: 0, tMantenimientoDisponibilidad: 0, tAlarmaDisponibilidad: 0, tApagadaDisponibilidad: 0,
    //      tParadaRendimiento: 0, tMantenimientoRendimiento: 0, tAlarmaRendimiento: 0, tApagadaRendimiento: 0, tParadaCalidad: 0, tMantenimientoCalidad: 0,
    //      tAlarmaCalidad: 0, tApagadaCalidad: 0, tNegativoCalidad: 0, tEstimado: 0
    //    };
    //    groupByPorFecha.push(this[a.fechaTurno]);
    //  }
    //  this[a.fechaTurno].tEjecucion += a.tEjecucion;
    //  this[a.fechaTurno].tParadaDisponibilidad += a.tParadaDisponibilidad;
    //  this[a.fechaTurno].tMantenimientoDisponibilidad += a.tMantenimientoDisponibilidad;
    //  this[a.fechaTurno].tAlarmaDisponibilidad += a.tAlarmaDisponibilidad;
    //  this[a.fechaTurno].tApagadaDisponibilidad += a.tApagadaDisponibilidad;
    //  this[a.fechaTurno].tParadaRendimiento += a.tParadaRendimiento;
    //  this[a.fechaTurno].tMantenimientoRendimiento += a.tMantenimientoRendimiento;
    //  this[a.fechaTurno].tAlarmaRendimiento += a.tAlarmaRendimiento;
    //  this[a.fechaTurno].tApagadaRendimiento += a.tApagadaRendimiento;
    //  this[a.fechaTurno].tParadaCalidad += a.tParadaCalidad;
    //  this[a.fechaTurno].tMantenimientoCalidad += a.tMantenimientoCalidad;
    //  this[a.fechaTurno].tAlarmaCalidad += a.tAlarmaCalidad;
    //  this[a.fechaTurno].tApagadaCalidad += a.tApagadaCalidad;
    //  this[a.fechaTurno].tNegativoCalidad += a.tNegativoCalidad;
    //  this[a.fechaTurno].tEstimado += a.tEstimado;
    //}, Object.create(null));

    var dataGraficoLineas;

    if (this.AnnoMesSemanaDia == 1) dataGraficoLineas = this.groupByPorAnno(groupByPorFecha);
    else if (this.AnnoMesSemanaDia == 2) dataGraficoLineas = this.groupByPorMes(groupByPorFecha);
    else if (this.AnnoMesSemanaDia == 3) dataGraficoLineas = this.groupByPorSemana(groupByPorFecha);
    else if (this.AnnoMesSemanaDia == 4) dataGraficoLineas = this.groupByPorDia(groupByPorFecha);

    var oee: number = 0;
    var cal: number = 0;
    var ren: number = 0;
    var dis: number = 0;

    var fechas: any = ['data'];
    var dispo: any = [this.translateService.instant('disponibilidad')];
    var rend: any = [this.translateService.instant('rendimiento')];
    var cali: any = [this.translateService.instant('calidad')];
    var total: any = [this.translateService.instant('total')];
    var obje: any = [this.translateService.instant('objetivo')];

    var max = 100;

    dataGraficoLineas.forEach(function (obj) {

      var perdidasDisponibilidad = obj.tParadaDisponibilidad + obj.tMantenimientoDisponibilidad + obj.tAlarmaDisponibilidad + obj.tApagadaDisponibilidad;
      var perdidasRendimiento = obj.tParadaRendimiento + obj.tMantenimientoRendimiento + obj.tAlarmaRendimiento + obj.tApagadaRendimiento;
      var perdidasCalidad = obj.tParadaCalidad + obj.tMantenimientoCalidad + obj.tAlarmaCalidad + obj.tApagadaCalidad;

      var disDividendo: number = obj.tEjecucion + perdidasRendimiento;
      var disDivisor: number = obj.tEjecucion + perdidasRendimiento + perdidasDisponibilidad;
      if (disDivisor == 0) dis = 0;
      else dis = (disDividendo / disDivisor) * 100;

      var renDividendo: number = obj.tEstimado;
      var renDivisor: number = obj.tEjecucion + perdidasRendimiento;
      if (renDivisor == 0) ren = 0;
      else ren = (renDividendo / renDivisor) * 100;

      var calDividendo: number = obj.tEstimado - obj.tNegativoCalidad - perdidasCalidad;
      var calDivisor: number = obj.tEstimado;
      if (calDivisor == 0) cal = 0;
      else cal = (calDividendo / calDivisor) * 100;

      oee = cal * ren * dis / (10000);

      fechas.push(obj.fechaGrafico);
      dispo.push((Math.round(dis * 100) / 100).toFixed(2));
      rend.push((Math.round(ren * 100) / 100).toFixed(2));
      cali.push((Math.round(cal * 100) / 100).toFixed(2));
      total.push((Math.round(oee * 100) / 100).toFixed(2));
      obje.push(this.objetivoOee);

      if (dis > max) max = dis;
      if (ren > max) max = ren;
      if (cal > max) max = cal;
      if (oee > max) max = oee;

    }, this);

    if (this.AnnoMesSemanaDia == 1) this.graficoEvolucion.internal.config.axis_x_tick_format = '%Y';
    else if (this.AnnoMesSemanaDia == 2) this.graficoEvolucion.internal.config.axis_x_tick_format = '%Y-%m';
    else if (this.AnnoMesSemanaDia == 3) this.graficoEvolucion.internal.config.axis_x_tick_format = '%Y-%m-%d';
    else if (this.AnnoMesSemanaDia == 4) this.graficoEvolucion.internal.config.axis_x_tick_format = '%Y-%m-%d';

    this.dataGraficoEvolucion = [fechas, dispo, rend, cali, total, obje];
    this.graficoEvolucion.load({ columns: this.dataGraficoEvolucion });

    this.graficoEvolucion.axis.min(0);
    this.graficoEvolucion.axis.max(max);

  }

  groupByPorAnno(groupByPorFecha) {

    groupByPorFecha.forEach(function (a) {
      //SACAR EL PRIMER DIA DEL AÑO DE CADA FECHA
      var date = new Date(a.fechaTurno);
      var firstDay = new Date(date.getFullYear(), 0, 1);
      a.fechaGrafico = this.myFunctions.dateToDD_MM_YYYY_forwardslash(firstDay);
    }, this);

    var groupByPorAnno = [];

    //AGRUPAMOS POR SEMANA
    groupByPorFecha.forEach(function (a) {
      if (!this[a.fechaGrafico]) {
        this[a.fechaGrafico] = {
          fechaGrafico: a.fechaGrafico, tEjecucion: 0, tParadaDisponibilidad: 0, tMantenimientoDisponibilidad: 0, tAlarmaDisponibilidad: 0, tApagadaDisponibilidad: 0,
          tParadaRendimiento: 0, tMantenimientoRendimiento: 0, tAlarmaRendimiento: 0, tApagadaRendimiento: 0, tParadaCalidad: 0, tMantenimientoCalidad: 0,
          tAlarmaCalidad: 0, tApagadaCalidad: 0, tNegativoCalidad: 0, tEstimado: 0
        };
        groupByPorAnno.push(this[a.fechaGrafico]);
      }
      this[a.fechaGrafico].tEjecucion += a.tEjecucion;
      this[a.fechaGrafico].tParadaDisponibilidad += a.tParadaDisponibilidad;
      this[a.fechaGrafico].tMantenimientoDisponibilidad += a.tMantenimientoDisponibilidad;
      this[a.fechaGrafico].tAlarmaDisponibilidad += a.tAlarmaDisponibilidad;
      this[a.fechaGrafico].tApagadaDisponibilidad += a.tApagadaDisponibilidad;
      this[a.fechaGrafico].tParadaRendimiento += a.tParadaRendimiento;
      this[a.fechaGrafico].tMantenimientoRendimiento += a.tMantenimientoRendimiento;
      this[a.fechaGrafico].tAlarmaRendimiento += a.tAlarmaRendimiento;
      this[a.fechaGrafico].tApagadaRendimiento += a.tApagadaRendimiento;
      this[a.fechaGrafico].tParadaCalidad += a.tParadaCalidad;
      this[a.fechaGrafico].tMantenimientoCalidad += a.tMantenimientoCalidad;
      this[a.fechaGrafico].tAlarmaCalidad += a.tAlarmaCalidad;
      this[a.fechaGrafico].tApagadaCalidad += a.tApagadaCalidad;
      this[a.fechaGrafico].tNegativoCalidad += a.tNegativoCalidad;
      this[a.fechaGrafico].tEstimado += a.tEstimado;
    }, Object.create(null));

    return groupByPorAnno;

  }

  groupByPorMes(groupByPorFecha) {

    groupByPorFecha.forEach(function (a) {
      //SACAR EL PRIMER DIA DEL MES DE CADA FECHA
      var date = new Date(a.fechaTurno);
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      a.fechaGrafico = this.myFunctions.dateToDD_MM_YYYY_forwardslash(firstDay);
    }, this);

    var groupByPorMes = [];

    //AGRUPAMOS POR SEMANA
    groupByPorFecha.forEach(function (a) {
      if (!this[a.fechaGrafico]) {
        this[a.fechaGrafico] = {
          fechaGrafico: a.fechaGrafico, tEjecucion: 0, tParadaDisponibilidad: 0, tMantenimientoDisponibilidad: 0, tAlarmaDisponibilidad: 0, tApagadaDisponibilidad: 0,
          tParadaRendimiento: 0, tMantenimientoRendimiento: 0, tAlarmaRendimiento: 0, tApagadaRendimiento: 0, tParadaCalidad: 0, tMantenimientoCalidad: 0,
          tAlarmaCalidad: 0, tApagadaCalidad: 0, tNegativoCalidad: 0, tEstimado: 0
        };
        groupByPorMes.push(this[a.fechaGrafico]);
      }
      this[a.fechaGrafico].tEjecucion += a.tEjecucion;
      this[a.fechaGrafico].tParadaDisponibilidad += a.tParadaDisponibilidad;
      this[a.fechaGrafico].tMantenimientoDisponibilidad += a.tMantenimientoDisponibilidad;
      this[a.fechaGrafico].tAlarmaDisponibilidad += a.tAlarmaDisponibilidad;
      this[a.fechaGrafico].tApagadaDisponibilidad += a.tApagadaDisponibilidad;
      this[a.fechaGrafico].tParadaRendimiento += a.tParadaRendimiento;
      this[a.fechaGrafico].tMantenimientoRendimiento += a.tMantenimientoRendimiento;
      this[a.fechaGrafico].tAlarmaRendimiento += a.tAlarmaRendimiento;
      this[a.fechaGrafico].tApagadaRendimiento += a.tApagadaRendimiento;
      this[a.fechaGrafico].tParadaCalidad += a.tParadaCalidad;
      this[a.fechaGrafico].tMantenimientoCalidad += a.tMantenimientoCalidad;
      this[a.fechaGrafico].tAlarmaCalidad += a.tAlarmaCalidad;
      this[a.fechaGrafico].tApagadaCalidad += a.tApagadaCalidad;
      this[a.fechaGrafico].tNegativoCalidad += a.tNegativoCalidad;
      this[a.fechaGrafico].tEstimado += a.tEstimado;
    }, Object.create(null));

    return groupByPorMes;

  }

  groupByPorSemana(groupByPorFecha) {

    groupByPorFecha.forEach(function (a) {
      //SACAR EL PRIMER DIA DE LA SEMANA DE CADA FECHA
      var d = new Date(a.fechaTurno);
      var day = d.getDay();
      var diff = d.getDate() - day + (day == 0 ? -6 : 1);
      var firstDay = new Date(d.setDate(diff));
      a.fechaGrafico = this.myFunctions.dateToDD_MM_YYYY_forwardslash(firstDay);
    }, this);

    var groupByPorSemana = [];

    //AGRUPAMOS POR SEMANA
    groupByPorFecha.forEach(function (a) {
      if (!this[a.fechaGrafico]) {
        this[a.fechaGrafico] = {
          fechaGrafico: a.fechaGrafico, tEjecucion: 0, tParadaDisponibilidad: 0, tMantenimientoDisponibilidad: 0, tAlarmaDisponibilidad: 0, tApagadaDisponibilidad: 0,
          tParadaRendimiento: 0, tMantenimientoRendimiento: 0, tAlarmaRendimiento: 0, tApagadaRendimiento: 0, tParadaCalidad: 0, tMantenimientoCalidad: 0,
          tAlarmaCalidad: 0, tApagadaCalidad: 0, tNegativoCalidad: 0, tEstimado: 0
        };
        groupByPorSemana.push(this[a.fechaGrafico]);
      }
      this[a.fechaGrafico].tEjecucion += a.tEjecucion;
      this[a.fechaGrafico].tParadaDisponibilidad += a.tParadaDisponibilidad;
      this[a.fechaGrafico].tMantenimientoDisponibilidad += a.tMantenimientoDisponibilidad;
      this[a.fechaGrafico].tAlarmaDisponibilidad += a.tAlarmaDisponibilidad;
      this[a.fechaGrafico].tApagadaDisponibilidad += a.tApagadaDisponibilidad;
      this[a.fechaGrafico].tParadaRendimiento += a.tParadaRendimiento;
      this[a.fechaGrafico].tMantenimientoRendimiento += a.tMantenimientoRendimiento;
      this[a.fechaGrafico].tAlarmaRendimiento += a.tAlarmaRendimiento;
      this[a.fechaGrafico].tApagadaRendimiento += a.tApagadaRendimiento;
      this[a.fechaGrafico].tParadaCalidad += a.tParadaCalidad;
      this[a.fechaGrafico].tMantenimientoCalidad += a.tMantenimientoCalidad;
      this[a.fechaGrafico].tAlarmaCalidad += a.tAlarmaCalidad;
      this[a.fechaGrafico].tApagadaCalidad += a.tApagadaCalidad;
      this[a.fechaGrafico].tNegativoCalidad += a.tNegativoCalidad;
      this[a.fechaGrafico].tEstimado += a.tEstimado;
    }, Object.create(null));

    return groupByPorSemana;

  }

  groupByPorDia(groupByPorFecha) {
    groupByPorFecha.forEach(function (a) {
      a.fechaGrafico = this.myFunctions.dateToDD_MM_YYYY_forwardslash(a.fechaTurno);
    }, this);

    return groupByPorFecha;
  }


  dateCopy(miFecha: Date) {
    return new Date(miFecha.getFullYear(), miFecha.getMonth(), miFecha.getDate(), miFecha.getHours(), miFecha.getMinutes(), miFecha.getSeconds(), miFecha.getMilliseconds());
  }

  showCalendar() {
    if (!this.mostrarCalendario1) {
      this.mostrarCalendario1 = true;
      console.log("TRUE");
    }
    else {
      this.mostrarCalendario1 = false;
      console.log("FALSE");
      if (this.dpFechaIni != undefined && this.dpFechaFin == undefined) {
        this.dpFechaFin = this.dpFechaIni;
      }
      else if (this.dpFechaIni == undefined && this.dpFechaFin == undefined) {
        this.dpFechaIni = this.myFunctions.getDateNow();
        this.dpFechaFin = this.dpFechaIni;
      }

    }
    // this.mostrarCalendario1 = true; // activa el click de fondo!
  }


  // FECHA DOBLE
  //    Función para agregar los días seleccionados al periodo correspondiente (el periodo seleccionado)
  valueClickCalendar(month, event) {
    if (event.target.classList.contains("calendarFiltro-calendario-contenido-dia")) { //nos aseguramos de que se está clickando en un día y no en otra parte
      if ((event.target.classList.contains("calendarFiltro-calendarioSeleccionado") && this.dpFechaIni != undefined && this.dpFechaFin == undefined)) {//se ha vuelto a seleccionar en el periodo, deseleccionar
        this.dpFechaFin = this.DaysInMonths[month];
        this.mostrarCalendario1 = false; //Si ya tenemos las dos fechas, que lo cierre
      } else {
        if (this.dpFechaIni == undefined && this.dpFechaFin == undefined) {
          this.dpFechaIni = this.DaysInMonths[month];
        }
        else if (this.dpFechaIni != undefined && this.dpFechaFin == undefined) {
          this.dpFechaFin = this.DaysInMonths[month];
          if (this.dpFechaIni > this.dpFechaFin) { //mirar qué fecha debe ir primero, just in case
            //están al revés, corregirlas
            var aux = new Date(this.dpFechaIni.getTime());
            this.dpFechaIni = new Date(this.dpFechaFin.getTime());
            this.dpFechaFin = aux;
          }
          this.mostrarCalendario1 = false; //Si ya tenemos las dos fechas, que lo cierre
          //Y que lo añada com que ya se ha cambiado la fecha, como es obligatoria, nunca saldrá de ese div, solo se vuelve a incluir para destacar el cambio con la animación
          // this.CambioFiltro();
        }
        else {
          //en este caso había dos y se brran para empezar a seleccionar otra vez
          //por tanto, quitamos el tag hasta que se hayan seleccionado las dos
          this.dpFechaIni = this.DaysInMonths[month];
          this.dpFechaFin = undefined;
        }
      }
    }
  }

  //    Función para pintar del color adecuado el periodo seleccionado
  isDateSelected(date) {
    if (this.dpFechaIni == undefined && this.dpFechaFin == undefined) {
      return false;
    } else if (this.dpFechaIni != undefined && this.dpFechaFin == undefined) {
      return date.getFullYear() == this.dpFechaIni.getFullYear() && date.getMonth() == this.dpFechaIni.getMonth() && date.getDate() == this.dpFechaIni.getDate();
    } else if (this.dpFechaIni != undefined && this.dpFechaFin != undefined) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate()) >= new Date(this.dpFechaIni.getFullYear(), this.dpFechaIni.getMonth(), this.dpFechaIni.getDate()) &&
        new Date(date.getFullYear(), date.getMonth(), date.getDate()) <= new Date(this.dpFechaFin.getFullYear(), this.dpFechaFin.getMonth(), this.dpFechaFin.getDate());
    }
  }


  insertMaquinasSelected(id, e) {
    var aurk = false;
    this.maquinasSeleccionadas.forEach((maquina, index) => {
      if (maquina == id) {
        this.maquinasSeleccionadas.splice(index, 1);
        aurk = true;
      }
    });
    if (!aurk) {
      this.maquinasSeleccionadas.push(id);
    }
    console.log(this.maquinasSeleccionadas);
  }

  selectAllUsuarios() {
    if (this.operariosSeleccionados.length == this.listaOperarios.length) {
      this.seleccionarTodo = true;
      this.operariosSeleccionados = [];
    } else {
      this.seleccionarTodo = false;
      this.operariosSeleccionados = [];
      this.listaOperarios.forEach(operario => {
        this.operariosSeleccionados.push(operario.idUsuario);
      });
    }
    this.operariosSeleccionados;
  }

  filtroMaquinas(e: any) {
    this.listaMaquinasAux = [];
    if (e == '') {
      this.listaMaquinasAux = this.maquinas;
    } else {
      this.maquinas.forEach(maquina => {
        if (maquina.nombre.toLowerCase().includes(e.toLowerCase())) {
          this.listaMaquinasAux.push(maquina);
        }
      });
    }
  }

  filtroOperarios(e: any) {
    this.listaOperariosAux = [];
    if (e == '') {
      this.listaOperariosAux = this.listaOperarios;
    } else {
      this.listaOperarios.forEach(operario => {
        if (operario.nombreCompleto.toLowerCase().includes(e.toLowerCase())) {
          this.listaOperariosAux.push(operario);
        }
      });
    }
    this.listaOperariosAux.sort((a, b) => (a.nombre.toLowerCase() > b.nombre.toLowerCase()) ? 1 : ((b.nombre.toLowerCase() > a.nombre.toLowerCase()) ? -1 : 0))
  }

}
