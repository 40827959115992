<style>
  :host /deep/ .k-grid tbody td {
    white-space: nowrap;
    line-height: 20px;
    padding: 8px 12px;
  }

  :host /deep/ .k-grid .k-grid-content td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>

<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>

<!-- FILTRO -->
<div class="cont-filtro-desplegable card" [ngClass]="status ? 'filtro-oculto' : 'filtro-desplegado'">
  <div class="card-header">
    <h3>{{ 'filtro' | translate}}</h3>
    <button type="button" class="flt-cerrar " (click)="this.status = !this.status;"><i class="fas fa-times"></i></button>
  </div>
  <div class="filtro-desplegable card-body flt">
    <!-- Filtro: CON GRUPOS -->
    <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ filtros: [datosFiltro] }"></ng-container>
    <ng-template #recursiveListTmpl let-filtross="filtros">
      <div *ngFor="let filtro of filtross" class="mt-2">
        <!-- GRUPO -->
        <div *ngFor="let row of filtro.group" class="flt-grupo">
          <!-- LINEA FILTRO -->
          <div *ngIf="row.group == undefined" class="clearfix flt-linea">
            <!-- AND / OR -->
            <div class="flt-andor">
              <!-- = 1ª ROW NO TIENE NI OR, NI AND -->
              <label class="float-left" *ngIf="row == filtro.group[0]"> {{ 'where' | translate}} </label>
              <!-- = 2ª ROW -->
              <kendo-dropdownlist (selectionChange)="refiltrarFiltro(filtro)" *ngIf="row == filtro.group[1]" [data]="andOr" [textField]="'nombre'" [valueField]="'id'" [(value)]="filtro.logic">
              </kendo-dropdownlist>
              <!-- > 2ª ROW -->
              <label class="d-flex justify-content-center" *ngIf="row != filtro.group[0] && row != filtro.group[1] && filtro.logic.id == 0"> {{ 'y' | translate}} </label>
              <label class="d-flex justify-content-center" *ngIf="row != filtro.group[0] && row != filtro.group[1] && filtro.logic.id == 1"> {{ 'o' | translate}} </label>
            </div>
            <!-- TIPO -->
            <div class="flt-tipo">
              <kendo-dropdownlist (selectionChange)="filtroTipoChanged(filtro, row, $event)" [data]="columnasFiltro" [textField]="'nombre'"
                                  [valueField]="'id'" [class.flt-sin-seleccionar]="row.columna.id == 0" [(value)]="row.columna" [itemDisabled]="itemDisabled"></kendo-dropdownlist>
            </div>
            <!-- <div class="flt-tipo" *ngIf="row.columna.tipo == 'date'">
              <kendo-dropdownlist (selectionChange)="filtroTipoChanged(filtro, row, $event)" [data]="columnasFiltro" [textField]="'nombre'"
                                  [valueField]="'id'" [class.flt-sin-seleccionar]="row.columna.id == 0" [(value)]="row.columna" disabled></kendo-dropdownlist>
            </div> -->
            <!-- CONTENEDOR FILTRO DE LINEA -->
            <div class="flt-filtro-linea">
              <!-- Filtro: FECHA -->
              <div *ngIf="row.columna.tipo == 'date'" class="flt-cont-tipo">
                <!-- OPCION -->
                <div class="flt-opcion">
                  <kendo-dropdownlist [data]="opcionDate" [textField]="'nombre'" [valueField]="'id'" [(value)]="row.operator" disabled></kendo-dropdownlist>
                </div>
                <!-- DATO(s) -->
                <div *ngIf="row.operator.id > 0" class="flt-datos">
                  <!-- DATO 1 (tenemos un control especial para las fechas dobles) -->
                  <kendo-datepicker *ngIf="!row.operator.dobleValor" class="flt-dato1" [(value)]="row.fechaIni"></kendo-datepicker>
                  <!-- DATO 1 / 2 -->
                  <div *ngIf="row.operator.dobleValor" class="flt-dato2">
                    <div class="calendarFiltro">
                      <!-- CONTROL -->
                      <div class="calendarFiltro-input" (click)="showCalendar(row)">
                        <label *ngIf="row.fechaIni != undefined && row.fechaFin == undefined">{{row.fechaIni | kendoDate}}</label>
                        <label *ngIf="row.fechaFin != undefined && row.fechaFin != undefined">{{row.fechaIni | kendoDate}} - {{row.fechaFin | kendoDate}}</label>
                        <i class="k-icon k-i-calendar"></i>
                      </div>
                      <!-- CONTROL EXPANDIDO -->
                      <div class="calendarFiltro-calendario" style="position: absolute !important; width: 367px;" [ngClass]="row.mostrarCalendario ? 'mostrarCalendario' : 'ocultarCalendario'">
                        <div class="row">
                          <div class="col-mes">
                            <kendo-calendar id="calendario-0" [(value)]="DaysInMonths[0]" type="classic" (click)="valueClickCalendar(row, 0, $event)">
                              <ng-template kendoCalendarMonthCellTemplate let-date>
                                <span class="calendarFiltro-calendario-contenido-dia" [class.calendarFiltro-calendarioSeleccionado]="isDateSelected(row, date)"> {{ date.getDate() }} </span>
                              </ng-template>
                              <ng-template kendoCalendarHeaderTitleTemplate let-title>
                                <div> {{ title }} </div>
                              </ng-template>
                            </kendo-calendar>
                          </div>
                          <div class="col-mes-botones">
                            <div class="calendarFiltro-botones">
                              <div class="clearfix text-center">
                                <button (click)="cambiarMeses(-1)"><i class="fa fa-chevron-left"></i></button>
                                <button (click)="cambiarMeses(1)"><i class="fa fa-chevron-right"></i></button>
                                <button (click)="cargarMeses()">{{ 'hoy' | translate}}</button>
                              </div>
                              <div class="pl-2">
                                <div class="clearfix"><button (click)="ultimas24HButton(row)">{{'ultimas24H' | translate}}</button></div>
                                <div class="clearfix"><button (click)="ultimos7DiasButton(row)">{{'ultimos7Dias' | translate}}</button></div>
                                <div class="clearfix"><button (click)="ultimos30DiasButton(row)">{{'ultimos30Dias' | translate}}</button></div>
                                <div class="clearfix"><button (click)="ultimos60DiasButton(row)">{{'ultimos60Dias' | translate}}</button></div>
                                <div class="clearfix"><button (click)="ultimos90DiasButton(row)">{{'ultimos90Dias' | translate}}</button></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Filtro: FECHA y HORA (el caso ENTRE no esta diseñado aun) -->
              <div *ngIf="row.columna.tipo == 'dateTime'" class="flt-cont-tipo">
                <!-- OPCION -->
                <div class="flt-opcion">
                  <kendo-dropdownlist [data]="opcionDateTime" [textField]="'nombre'" [valueField]="'id'" [(value)]="row.operator"></kendo-dropdownlist>
                </div>
                <!-- DATO(s) -->
                <div *ngIf="row.operator.id > 0" class="flt-datos">
                  <!-- DATO -->
                  <div class="flt-dato1">
                    <kendo-datetimepicker [(value)]="row.fechaIni"> </kendo-datetimepicker>
                  </div>
                </div>
              </div>
              <!-- Filtro: NUMEROS ENTEROS -->
              <div *ngIf="row.columna.tipo == 'numeric'">
                <!-- OPCION -->
                <div class="flt-opcion">
                  <kendo-dropdownlist [data]="opcionNumericDecimal" [textField]="'nombre'" [valueField]="'id'" [(value)]="row.operator"></kendo-dropdownlist>
                </div>
                <!-- DATO(s) -->
                <div *ngIf="row.operator.id > 0" class="flt-datos">
                  <div class="flt-dato1">
                    <!-- DATO 1 -->
                    <kendo-numerictextbox format="0" [min]="-9999999" [max]="9999999" [(value)]="row.numberMin"></kendo-numerictextbox>
                  </div>
                  <div *ngIf="row.operator.dobleValor" class="flt-dato2">
                    <!-- DATO 2 -->
                    <kendo-numerictextbox format="0" [min]="-9999999" [max]="9999999" [(value)]="row.numberMax"></kendo-numerictextbox>
                  </div>
                </div>
              </div>
              <!-- Filtro: DECIMALES -->
              <div *ngIf="row.columna.tipo == 'decimal'">
                <div class="flt-opcion">
                  <!-- OPCION -->
                  <kendo-dropdownlist [data]="opcionNumericDecimal" [textField]="'nombre'" [valueField]="'id'" [(value)]="row.operator"></kendo-dropdownlist>
                </div>
                <!-- DATO(s) -->
                <div *ngIf="row.operator.id > 0" class="flt-datos">
                  <div class="flt-dato1">
                    <!-- DATO 1 -->
                    <kendo-numerictextbox [format]="row.decimalformat" [min]="-9999999" [max]="9999999" [(value)]="row.decimalMin"></kendo-numerictextbox>
                  </div>
                  <div class="flt-dato2">
                    <!-- DATO 2 -->
                    <kendo-numerictextbox *ngIf="row.operator.dobleValor" [format]="row.decimalformat" [min]="-9999999" [max]="9999999" [(value)]="row.decimalMax"></kendo-numerictextbox>
                  </div>
                </div>
              </div>
              <!-- Filtro: TEXTO -->
              <div *ngIf="row.columna.tipo == 'string'">
                <div class="flt-opcion">
                  <!-- OPCION -->
                  <kendo-dropdownlist [data]="opcionString" [textField]="'nombre'" [valueField]="'id'" [(value)]="row.operator"></kendo-dropdownlist>
                </div>
                <div class="flt-datos">
                  <div class="flt-dato1 float-left mr-2">
                    <!-- DATO -->
                    <kendo-textbox [(value)]="row.text" [maxlength]="50"></kendo-textbox>
                  </div>
                </div>
              </div>
              <!-- Filtro: COMBO ESTATICO -->
              <div *ngIf="row.columna.tipo == 'comboEstrincto'">
                <div class="flt-opcion">
                  <!-- OPCION -->
                  <kendo-dropdownlist [data]="opcionComboEstricto" [textField]="'nombre'" [valueField]="'id'" [(value)]="row.operator"></kendo-dropdownlist>
                </div>
                <!-- DATO(s) -->
                <div *ngIf="row.operator.id > 0" class="flt-datos">
                  <div class="flt-dato1">
                    <!-- DATO 1 -->
                    <kendo-dropdownlist *ngIf="!row.operator.dobleValor" [data]="row.combo" [textField]="'nombre'" [valueField]="'id'" [(value)]="row.comboSelected" style="width: 220px;">
                    </kendo-dropdownlist>
                  </div>
                  <div class="flt-dato2">
                    <!-- DATO 2 -->
                    <kendo-multiselect *ngIf="row.operator.dobleValor" kendoMultiSelectSummaryTag [data]="row.combo" [(ngModel)]="row.comboSelecteds" [textField]="'nombre'" [valueField]="'nombre'"
                                       [autoClose]="false" style="width: 220px;" [kendoDropDownFilter]="{operator: 'contains'}" class="flt-dato2">
                      <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                        <span class="k-icon k-i-arrow-s"></span>
                        <ng-container *ngIf="dataItems.length == 1">{{ this.myFunctions.stringMaxLength(dataItems[0].nombre, 16)}}</ng-container>
                        <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seleccionados' | translate }}</ng-container>
                      </ng-template>
                      <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                    </kendo-multiselect>
                  </div>
                </div>
              </div>
              <!-- Filtro: COMBO FLEXIBLE -->
              <div *ngIf="row.columna.tipo == 'comboFlexible'">
                <!-- OPCION -->
                <div class="flt-opcion">
                  <kendo-dropdownlist [data]="opcionComboFlexible" [textField]="'nombre'" [valueField]="'id'" [(value)]="row.operator"></kendo-dropdownlist>
                </div>
                <!-- DATO(s) -->
                <div *ngIf="row.operator.id > 0" class="flt-datos">
                  <!-- DATO 1 -->
                  <kendo-dropdownlist *ngIf="!row.operator.dobleValor && row.operator.id <= 4" class="mr-2 float-left" [data]="row.combo" [textField]="'nombre'" [valueField]="'id'"
                                      [(value)]="row.comboSelected"
                                      style="width: 220px;"></kendo-dropdownlist>
                  <!-- DATO 1 -->
                  <kendo-multiselect *ngIf="row.operator.dobleValor" kendoMultiSelectSummaryTag [data]="row.combo" [(ngModel)]="row.comboSelecteds" [textField]="'nombre'" [valueField]="'id'"
                                     [autoClose]="false" style="width: 220px;" [kendoDropDownFilter]="{operator: 'contains'}" class="flt-dato2">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{ this.myFunctions.stringMaxLength(dataItems[0].nombre, 16)}}</ng-container>
                      <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seleccionados' | translate }}</ng-container>
                    </ng-template>
                    <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                  </kendo-multiselect>
                  <!-- DATO 1 -->
                  <div *ngIf="!row.operator.dobleValor && row.operator.id > 4" class="flt-dato1 float-left mr-2">
                    <kendo-textbox [(value)]="row.text" [maxlength]="50"></kendo-textbox>
                  </div>
                </div>
              </div>
              <!-- Filtro: COMBO FLEXIBLE OPERARIO / MAQUINA -->
              <div *ngIf="row.columna.tipo == 'comboFlexibleOperarioMaquina'">
                <!-- OPCION -->
                <div class="flt-opcion">
                  <kendo-dropdownlist [data]="opcionComboFlexibleOperarioMaquinas" [textField]="'nombre'" [valueField]="'id'" [(value)]="row.operator"></kendo-dropdownlist>
                </div>
                <!-- DATO(s) -->
                <div *ngIf="row.operator.id > 0" class="flt-datos">
                  <!-- DATO 1 -->
                  <kendo-dropdownlist *ngIf="!row.operator.dobleValor && row.operator.id <= 4" class="mr-2 float-left" [data]="row.combo" [textField]="'nombre'" [valueField]="'id'"
                                      [(value)]="row.comboSelected"
                                      style="width: 220px;"></kendo-dropdownlist>
                  <!-- DATO 1 -->
                  <kendo-multiselect *ngIf="row.operator.dobleValor" kendoMultiSelectSummaryTag [data]="row.combo" [(ngModel)]="row.comboSelecteds" [textField]="'nombre'" [valueField]="'id'"
                                     [autoClose]="false" style="width: 220px;" [kendoDropDownFilter]="{operator: 'contains'}" class="flt-dato2">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{ this.myFunctions.stringMaxLength(dataItems[0].nombre, 16)}}</ng-container>
                      <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seleccionados' | translate }}</ng-container>
                    </ng-template>
                    <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                  </kendo-multiselect>
                  <!-- DATO 1 -->
                  <div *ngIf="!row.operator.dobleValor && row.operator.id > 4" class="flt-dato1 float-left mr-2">
                    <kendo-textbox [(value)]="row.text" [maxlength]="50"></kendo-textbox>
                  </div>
                </div>
              </div>
              <!-- Filtro: CHECK -->
              <div *ngIf="row.columna.tipo == 'check'" class="flt-dato1">
                <!-- SERIA MEJOR CON 2 BOTONES SI / NO ? TEXTO PARA VERDADERO / FALSO? -->
                <kendo-switch [(ngModel)]="row.check" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
              </div>
            </div>
            <!-- ELIMINAR -->
            <!-- <button *ngIf="row.columna.tipo != 'date' && !((row.columna.id == 0 && filtro == datosFiltro.group[0] && datosFiltro.group.length == 1 && !permitirFiltroVacio ) || (row.columna.id == 0 && row != filtro.group[0])) || !annadirAutomatico "
                    Class="flt-eliminar" (click)="borrarLineaFiltro(row, filtro, datPlanos)"> <i class="fas fa-times"></i> </button> -->
            <button *ngIf="!((row.columna.id == 0 && filtro == datosFiltro.group[0] && datosFiltro.group.length == 1 && !permitirFiltroVacio ) || (row.columna.id == 0 && row != filtro.group[0])) || !annadirAutomatico "
                    Class="flt-eliminar" (click)="borrarLineaFiltro(row, filtro, datPlanos)"> <i class="fas fa-times"></i> </button>
          </div>
          <!-- SUB GRUPO -->
          <div *ngIf="row.group != undefined">
            <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ filtros:  [row] }"></ng-container>
          </div>
        </div>
        <!-- BOTON: AÑADIR LINEA (SOLO EN LA ULTIMA LINEA, o primer subgrupo) -->
        <div class=" mt-1" *ngIf="filtro.group != undefined && filtro != datosFiltro && !annadirAutomatico">
          <button Class="btnEditatu btn btn-success btn-sm mr-1" (click)="annadirLineaFiltro(filtro)"> <i class="fas fa-plus"></i> {{ 'agregarCondicion' | translate }} </button>
        </div>
        <div class=" mt-1" *ngIf="filtro.group != undefined && filtro != datosFiltro">
          <hr>
        </div>
      </div>
    </ng-template>
    <div class="clearfix">
      <button class="flt-btnanadirgrupo" (click)="annadirGrupoFiltro()"> <i class="fas fa-plus"></i> {{ 'agregarGrupo' | translate }} </button>
    </div>
    <hr>
    <div class="clearfix">
      <button Class="btn btn-primary btn-sm mt-1 float-right" (click)="onFilter()" [disabled]="datosFiltro?.group.length == 1 && datosFiltro?.group[0]?.group?.length == 1"> {{ 'filtrar' | translate }}</button>
      <button Class="btn-danger btn btn-sm mt-1 float-right mr-1" (click)="borrarFiltro()"> {{ 'filterClearButton' | translate }}</button>
    </div>
  </div>
</div>
<!-- CARD CON OPCIONES SELECCIONADAS FILTRO -->
<div class="card">
  <div class="card-body">
    <div class="cont-tags-filtro" id="tagsFiltro">
      <ng-container *ngTemplateOutlet="recursiveListTmpl2; context:{ filtros: [datosFiltro] }"></ng-container>
      <ng-template #recursiveListTmpl2 let-filtross="filtros">
        <div *ngFor="let filtro of filtross" class="uno">
          <div class="flt-separador" *ngIf="filtro.group != undefined && filtro != datosFiltro && filtro!=datosFiltro.group[0] && filtroToCount(filtro) > 0"></div>
          <!-- GRUPO -->
          <div *ngFor="let row of filtro.group" class="dos">
            <!-- LINEA FILTRO -->
            <div *ngIf="row.group == undefined &&
            ((row.columna.tipo == 'date') || 
             (row.columna.tipo == 'dateTime') || 
            (row.columna.tipo == 'comboEstrincto' && row.comboSelecteds.length > 0 ) || 
            (row.columna.tipo == 'comboFlexible'  && ((row.operator.id <= 4 && row.comboSelecteds.length > 0 ) ||( row.operator.id > 4 && row.text != ''))) || 
            (row.columna.tipo == 'comboFlexibleOperarioMaquina'  && ((row.operator.id <= 4 && row.comboSelecteds.length > 0 ) ||( row.operator.id > 4 && row.text != ''))) || 
            (row.columna.tipo == 'check') || 
            (row.columna.tipo == 'numeric') || 
            (row.columna.tipo == 'decimal') || 
            (row.columna.tipo == 'string' && row.text != '' ))" class="tags-filtro">
              <label *ngIf="false"> {{ row.columna.nombre }} </label>
              <label *ngIf="false"> {{ row.operator.nombre }} </label>
              <!-- FILTRO -->
              <div class="tags-filtro-label">
                <!-- Filtro: FECHA -->
                <div *ngIf="row.columna.tipo == 'date'">
                  <div *ngIf="row.operator.id > 0">
                    <label class="tags-filtro-label" *ngIf="!row.operator.dobleValor">{{row.fechaIni | kendoDate}}</label>
                    <label class="tags-filtro-label" *ngIf="row.fechaFin != undefined && row.fechaFin == undefined && row.operator.dobleValor">{{row.fechaIni | kendoDate}}</label>
                    <label class="tags-filtro-label" *ngIf="row.fechaFin != undefined && row.fechaFin != undefined && row.operator.dobleValor">{{row.fechaIni | kendoDate}} - {{row.fechaFin | kendoDate}}</label>
                  </div>
                </div>
                <!-- Filtro: FECHA y HORA (el caso ENTRE no esta diseñado aun) -->
                <div *ngIf="row.columna.tipo == 'dateTime'">
                  <div *ngIf="row.operator.id > 0">
                    <label class="tags-filtro-label" *ngIf="!row.operator.dobleValor"> {{row.fechaIni | kendoDate}} </label>
                  </div>
                </div>
                <!-- Filtro: NUMEROS ENTEROS -->
                <div *ngIf="row.columna.tipo == 'numeric'">
                  <div *ngIf="row.operator.id > 0">
                    <label class="tags-filtro-label"> {{row.numberMin}} </label>
                    <label class="tags-filtro-label" *ngIf="row.operator.dobleValor"> {{row.numberMax}} </label>
                  </div>
                </div>
                <!-- Filtro: DECIMALES -->
                <div *ngIf="row.columna.tipo == 'decimal'">
                  <div *ngIf="row.operator.id > 0">
                    <label class="tags-filtro-label"> {{row.decimalMin}} </label>
                    <label class="tags-filtro-label" *ngIf="row.operator.dobleValor"> {{row.decimalMax}} </label>
                  </div>
                </div>
                <!-- Filtro: TEXTO -->
                <div *ngIf="row.columna.tipo == 'string'">
                  <label class="tags-filtro-label"> {{row.text}} </label>
                </div>
                <!-- Filtro: COMBO ESTATICO -->
                <div *ngIf="row.columna.tipo == 'comboEstrincto'">
                  <div *ngIf="row.operator.id > 0">
                    <label class="tags-filtro-label" *ngIf="row.comboSelecteds.length == 1">{{ row.comboSelecteds[0].nombre}}</label>
                    <label class="tags-filtro-label" *ngIf="row.comboSelecteds.length > 1">{{ row.columna.nombre }} ({{ row.comboSelecteds.length }})</label>
                  </div>
                </div>
                <!-- Filtro: COMBO FLEXIBLE -->
                <div *ngIf="row.columna.tipo == 'comboFlexible'">
                  <div *ngIf="row.operator.id > 0">
                    <div *ngIf="row.operator.id <= 4">
                      <label class="tags-filtro-label" *ngIf="row.comboSelecteds.length == 1">{{ row.comboSelecteds[0].nombre}}</label>
                      <label class="tags-filtro-label" *ngIf="row.comboSelecteds.length > 1">{{ row.columna.nombre }} ({{ row.comboSelecteds.length }})</label>
                    </div>
                    <label class="tags-filtro-label" *ngIf="row.operator.id > 4"> {{row.text}} </label>
                  </div>
                </div>
                <!-- Filtro: COMBO FLEXIBLE OPERARIO / MAQUINA -->
                <div *ngIf="row.columna.tipo == 'comboFlexibleOperarioMaquina'">
                  <div *ngIf="row.operator.id > 0">
                    <div *ngIf="row.operator.id <= 4">
                      <label class="tags-filtro-label" *ngIf="row.comboSelecteds.length == 1">{{ row.comboSelecteds[0].nombre}}</label>
                      <label class="tags-filtro-label" *ngIf="row.comboSelecteds.length > 1">{{ row.columna.nombre }} ({{ row.comboSelecteds.length }})</label>
                    </div>
                    <label class="tags-filtro-label" *ngIf="row.operator.id > 4"> {{row.text}} </label>
                  </div>
                </div>
                <!-- Filtro: CHECK -->
                <div *ngIf="row.columna.tipo == 'check'">
                  <label class="tags-filtro-label" *ngIf="row.check"> {{ row.columna.nombre }} : {{ 'si' | translate }} </label>
                  <label class="tags-filtro-label" *ngIf="!row.check"> {{ row.columna.nombre }} : {{ 'no' | translate }} </label>
                </div>
              </div>
              <!-- ELIMINAR -->
              <!-- <div class="" *ngIf="row.columna.tipo != 'date'">               -->
              <button (click)="borrarLineaFiltro(row, filtro, datPlanos)" *ngIf="row.columna.tipo != 'date'" class="fas fa-times" style="cursor:pointer"></button>
              <!-- </div> -->

            </div>
            <!-- SUB GRUPO -->
            <div *ngIf="row.group != undefined">
              <ng-container *ngTemplateOutlet="recursiveListTmpl2; context:{ filtros: [row] }"></ng-container>
            </div>
          </div>
        </div>
      </ng-template>

      <button type="button" class="btn btn-primary float-right mr-1" (click)="this.status = !this.status;"><i class="fas fa-filter mr-1"></i>{{ 'filtro' | translate }} ( {{ filtroToCount() }} )</button>
      <button Class="btn btn-danger float-right mr-1" (click)="borrarFiltro()"><i class="fas fa-backspace mr-1"></i>{{ 'filterClearButton' | translate }}</button>
      <button *ngIf="actualizarVisible" Class="btn btn-success float-right mr-1" (click)="onFilter()"><i class="fas fa-sync-alt mr-1"></i>{{ 'actualizar' | translate }}</button>
    </div>
  </div>
  <div *ngIf="loadingFiltro" class="k-i-loading"></div>
</div>

<!-- TOOLTIP -->
<div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td" (mouseover)="showGridTooltip($event)">

  <!-- GRID -->
  <div class="card">
    <div class="card-body">
      <!-- <button type="button" [disabled]="!botonesVisibles" class="btn btn-success m-1" (click)="btnmandarProduccion()">{{'mandarProduccion' | translate }}</button>
      <button type="button" class="btn btn-azul m-1" (click)="btnFinalizar()">{{ 'finalizar' | translate }}</button>
      <button type="button" class="btn btn-verde m-1" (click)="btnValidar()">{{ 'validar' | translate }}</button>
      <button type="button" [disabled]="!botonesVisibles" class="btn btn-naranja m-1" (click)="btnApartarPieza()">{{ 'apartarPieza' | translate }}</button>
      <button type="button" [disabled]="!botonesVisibles" class="btn btn-danger m-1" (click)="btnAchatarrar()">{{ 'achatarrar' | translate }}</button> -->
      <button type="button" class="btn btn-info m-1" (click)="cargarURLRuta()">{{ 'copiarDireccion' | translate }}</button>
      <button kendoButton type="button" (click)="imprimirXls($event, grid)" icon="file-excel">Excel</button>
      <kendo-grid [kendoGridBinding]="gridView"
                  kendoGridSelectBy="id"
                  [selectedKeys]="piezasSelecteds"
                  [navigable]="true"
                  [sortable]="true"
                  scrollable="virtual"
                  [rowHeight]="37"
                  [height]="heightGrid"
                  filterable="menu"
                  [resizable]="true"
                  (dataStateChange)="dataStateChange($event)"
                  (pageChange)="pageChange($event)"
                  (change)="onChange($event)"
                  [pageSize]="70"
                  [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: true, mode: 'multiple'}"
                  (cellClick)="cellClick($event)"
                  #grid="kendoGrid">
        <!-- <ng-template kendoGridToolbarTemplate>
          <button type="button" [disabled]="!botonesVisibles" class="btn btn-success m-1" (click)="btnmandarProduccion()">{{'mandarProduccion' | translate }}</button>
          <button type="button" class="btn btn-azul m-1" (click)="btnFinalizar()">{{ 'finalizar' | translate }}</button>
          <button type="button" class="btn btn-verde m-1" (click)="btnValidar()">{{ 'validar' | translate }}</button>
          <button type="button" [disabled]="!botonesVisibles" class="btn btn-naranja m-1" (click)="btnApartarPieza()">{{ 'apartarPieza' | translate }}</button>
          <button type="button" [disabled]="!botonesVisibles" class="btn btn-danger m-1" (click)="btnAchatarrar()">{{ 'achatarrar' | translate }}</button>
          <button type="button" class="btn btn-info m-1" (click)="cargarURLRuta()">{{ 'copiarDireccion' | translate }}</button>
          <button kendoButton type="button" (click)="imprimirXls($event, grid)" icon="file-excel">Excel</button>
        </ng-template> -->
        <!-- filterable="menu" -->
        <!-- CHECKBOX -->
        <kendo-grid-checkbox-column width="40">
          <ng-template kendoGridHeaderTemplate>
            <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox
                   (selectAllChange)="myFunctions.onSelectAllChangeGrid($event, piezasSelecteds, gridView, 'id')">
            <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
          </ng-template>
        </kendo-grid-checkbox-column>
        <!-- OF -->
        <kendo-grid-column field="refOF" title="{{ 'refOF' | translate }}" width="60"></kendo-grid-column>
        <!-- CLIENTE -->
        <kendo-grid-column field="cliente" title="{{ 'cliente' | translate }}" width="80" *ngIf="usuarioIdDb!=usuarioIdDbGamesa"></kendo-grid-column><!-- NO ENSEÑAR PARA GAMESA -->
        <!-- PIEZA -->
        <kendo-grid-column field="pieza" title="{{ 'pieza' | translate }}" width="100" *ngIf="usuarioIdDb!=usuarioIdDbGamesa"></kendo-grid-column><!-- NO GAMESA: TITULO=PIEZA -->
        <kendo-grid-column field="pieza" title="{{ 'fase' | translate }}" width="80" *ngIf="usuarioIdDb==usuarioIdDbGamesa"></kendo-grid-column><!-- GAMESA: TITULO=FASE -->
        <!-- PLANO -->
        <kendo-grid-column field="numeroPlano" title="{{ 'plano' | translate }}" width="80" *ngIf="!usuarioIdDbCAF"></kendo-grid-column>
        <!-- PARTE -->
        <kendo-grid-column field="parte" title="{{ 'parte' | translate }}" width="100" *ngIf="!ocultarParte"></kendo-grid-column>
        <!-- COLADA -->
        <kendo-grid-column field="coladaCAF" *ngIf="user.verColada" title="{{ 'pedircolada' | translate }}" width="80"></kendo-grid-column>
        <!-- LOTE -->
        <kendo-grid-column field="lote" *ngIf="user.verLote" title="{{ 'lote' | translate }}" width="60"></kendo-grid-column>
        <!-- NSERIE -->
        <kendo-grid-column field="numeroSerie" *ngIf="user.verNSerie" title="{{ 'nserie' | translate }}" width="80"></kendo-grid-column>
        <!-- CANTIDAD -->
        <kendo-grid-column field="cantidad" title="{{ 'cantidad' | translate }}" width="60"></kendo-grid-column>
        <!-- CANTIDAD NO OK-->
        <kendo-grid-column field="cantidadMalas" title="{{ 'malas' | translate }}" width="60" *ngIf="usaLotes"></kendo-grid-column>
        <!-- OPERARIOS -->
        <kendo-grid-column field="operarios" title="{{ 'operarios' | translate }}" width="80" class="celda-tooltip-externo">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="tooltip-consumibles-contenido" *ngIf="dataItem.operariosAuxi!=undefined">
              <ng-container *ngFor="let operario of dataItem.operariosAuxi.split(','); let i = index">
                <span *ngIf="(operario!='undefined' && operario!='Dundefined') && (i < numeroCirculos -1 || (i == numeroCirculos - 1 && dataItem.operariosAuxi.split(',').length == numeroCirculos ))" class="grid-circulo"
                      [style.background-color]="dataItem.operariosC.split(',')[i]+ '!important'">{{operario}}</span>
                <span *ngIf="(operario=='undefined' || operario=='Dundefined') && (i < numeroCirculos -1 || (i == numeroCirculos - 1 && dataItem.operariosAuxi.split(',').length == numeroCirculos ))" class="grid-circulo"><i class="fas fa-user-alt"></i></span>
                <span *ngIf="i == numeroCirculos - 1 && dataItem.operariosAuxi.split(',').length > numeroCirculos" class="grid-circulo grid-circulo-mas"><i class="fas fa-plus"></i></span>
              </ng-container>
              <span class="tooltiptext">
                <ng-container *ngFor="let operario of dataItem.operariosAuxi2.split(';'); let i = index">
                  <label *ngIf="operario !='Dundefined' && operario !='undefined'"> {{operario}} </label>
                  <label *ngIf="operario =='Dundefined' || operario =='undefined'"> {{'desconocido' | translate}} </label>
                </ng-container>
              </span>
            </span>
          </ng-template>
        </kendo-grid-column>
        <!-- MAQUINAS -->
        <kendo-grid-column field="maquinas" title="{{ 'maquinas' | translate }}" width="80"
                           class="celda-tooltip-externo">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="tooltip-consumibles-contenido" *ngIf="dataItem.maquinasAuxi!=undefined">
              <ng-container *ngFor="let maquina of dataItem.maquinasAuxi.split(','); let i = index">
                <span *ngIf="(dataItem.maquinasIm.split(';and;')[i]!='null' && maquina!='undefined') && (i < numeroCirculos -1 || (i == numeroCirculos - 1 && dataItem.maquinasAuxi.split(',').length == numeroCirculos ))" class="grid-circulo"
                      style="background-color:white!important;"
                      [ngStyle]="{'background-image': 'url(' + dataItem.maquinasIm.split(';and;')[i] + ')'}"></span>
                <span *ngIf="(dataItem.maquinasIm.split(';and;')[i]=='null' && maquina!='undefined') && (i < numeroCirculos -1 || (i == numeroCirculos - 1 && dataItem.maquinasAuxi.split(',').length == numeroCirculos ))" class="grid-circulo"
                      [style.background-color]="dataItem.maquinasC.split(',')[i]+ '!important'">{{maquina}}</span>
                <span *ngIf="i == numeroCirculos - 1 && dataItem.maquinasAuxi.split(',').length > numeroCirculos" class="grid-circulo grid-circulo-mas"><i class="fas fa-plus"></i></span>
              </ng-container>
              <span class="tooltiptext">
                <ng-container *ngFor="let maquina of dataItem.maquinasAuxi2.split(';'); let i = index">
                  <label *ngIf="maquina!='undefined'"> {{maquina}} </label>
                  <label *ngIf="maquina=='undefined'"> {{'desconocido' | translate}} </label>
                </ng-container>
              </span>
            </span>
          </ng-template>
        </kendo-grid-column>
        <!-- FECHA INICIO -->
        <kendo-grid-column field="fechaIniOrden" title="{{ 'fIni' | translate }}" width="90" class="celda-tooltip-externo">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="tooltip-consumibles-contenido">
              <label>{{this.myFunctions.dateWithoutYearShorted(this.myFunctions.sqlToJsDate(dataItem.fechaIni.replace('T', ' ')))}}
                {{this.myFunctions.dateToHHMM(this.myFunctions.sqlToJsDate(dataItem.fechaIni.replace('T', ' ')))}}</label>
              <span class="tooltiptext"> {{dataItem.fechaIni | kendoDate}} {{dataItem.fechaIni | kendoDate:'HH:mm:ss'}} </span>
            </span>
          </ng-template>
        </kendo-grid-column>
        <!-- FECHA FIN -->
        <kendo-grid-column field="fechaFinOrden" title="{{ 'fFin' | translate }}" width="90" class="celda-tooltip-externo">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="tooltip-consumibles-contenido">
              <label>{{this.myFunctions.dateWithoutYearShorted(this.myFunctions.sqlToJsDate(dataItem.fechaFin.replace('T', ' ')))}}
                {{this.myFunctions.dateToHHMM(this.myFunctions.sqlToJsDate(dataItem.fechaFin.replace('T', ' ')))}}</label>
              <span class="tooltiptext"> {{dataItem.fechaFin | kendoDate}} {{dataItem.fechaFin | kendoDate:'HH:mm:ss'}} </span>
            </span>
          </ng-template>
        </kendo-grid-column>
        <!-- TIEMPOS ESTIMADOS -->
        <kendo-grid-column field="tiempoEstimadoTotalFormateado" title="{{ 'tEstimado' | translate }}" width="60" [style]="{'text-align': 'right'}">
        </kendo-grid-column>
        <!-- TIEMPOS REALES -->
        <kendo-grid-column field="tiempoRealFormateado" title="{{ 'tReal' | translate }}" width="60" [style]="{'text-align': 'right'}">
        </kendo-grid-column>
        <!-- TIEMPO EJECUCION -->
        <kendo-grid-column field="tiempoRealEjecucionFormateado" title="{{ 'tEjecucion' | translate }}" width="90" [style]="{'text-align': 'right'}" *ngIf="!esDemo">
          <!-- HEADER -->
          <ng-template kendoGridHeaderTemplate let-column>
            <label>
              <span class="tooltip-consumibles-contenido">
                <i class="fas fa-info-circle mr-2"></i><span [attr.contenido]="tiempoAsigandoPiezaNoPreparacion" class="tooltipTextHeader"></span>
              </span>
              {{ 'tiempo' | translate }}
            </label>
          </ng-template>
        </kendo-grid-column>
        <!-- TIEMPO PREPARACION -->
        <kendo-grid-column field="tiempoRealPreparacionFormateado" title="{{ 'tPreparacion' | translate }}" width="60" [style]="{'text-align': 'right'}" *ngIf="(usuarioIdDb!=usuarioIdDbGamesa) && !esDemo">
        </kendo-grid-column>
        <!-- TIEMPOS DESVIOS -->
        <kendo-grid-column field="porcenDesvio" title="{{ 'desvioPorcentaje' | translate }}" width="60" [style]="{'text-align': 'right'}">
          <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.porcenDesvio}} %</ng-template>
        </kendo-grid-column>
        <!-- ESTADO -->
        <kendo-grid-column *ngIf="!exportandoExcel" field="claseEstado" title="{{ 'estado' | translate }}" width="100" [style]="{'text-align': 'center'}" class="celda-tooltip-externo">
          <!-- HEADER -->
          <ng-template kendoGridHeaderTemplate let-column>
            <label>
              <span class="tooltip-consumibles-contenido">
                <i class="fas fa-info-circle mr-2"></i><span [attr.contenido]="contenidoEstado" class="tooltipTextHeader"></span>
              </span>
              {{ 'estado' | translate }}
            </label>
          </ng-template>
          <!-- ROW -->
          <ng-template kendoGridCellTemplate let-dataItem>
            <!-- ESTO ESTABA ANTES DE CAMBIAR A ALGO ESPECIFICO POR ESTADO
              <span class="tooltip-consumibles-contenido">
              <div class="cont-barraprogreso-grid">
                <div *ngIf="dataItem.idEstado==1" class="barraprogreso-grid" [ngStyle]="{'width': dataItem.porcen+'%'}"></div>
                <div *ngIf="dataItem.idEstado!=1" class="barraprogreso-grid" [ngStyle]="{'width': '100%'}"></div>
                <label *ngIf="dataItem.idEstado!=1 || dataItem.porcen==100" class="label-barraprogreso-grid">100%</label>
              </div>
              <span class="tooltiptext text-left">
                <label class="tooltipDestacado" *ngIf="dataItem.idEstado==1 && dataItem.operacionActual!=null">{{dataItem.operacionActual}} {{dataItem.hechasTotal}}/{{dataItem.total}} {{dataItem.porcen}}%</label>
                <label class="tooltipDestacado" *ngIf="dataItem.idEstado!=1 && dataItem.hechasTotal!=0 && dataItem.total!=0">{{'terminado' | translate}} {{dataItem.hechasTotal}}/{{dataItem.total}} {{dataItem.porcen}}%</label>
                <ng-container *ngFor="let operacion of dataItem.opera; let i = index">
                  <label>{{dataItem.ordenOperaciones[i]}}. {{operacion}} {{dataItem.cant[i].split('/')[0]}}/{{dataItem.cant[i].split('/')[1]}}</label>
                </ng-container>
              </span>
            </span> -->
            <!-- ESTADO: EN PROCESO-->
            <span *ngIf="dataItem.idEstado==1 " class="tooltip-consumibles-contenido">
              <div class="cont-barraprogreso-grid">
                <div class="barraprogreso-grid barraprogreso-enProceso" [ngStyle]="{'width': dataItem.porcenGrafico +'%'}"></div>
                <label class="label-barraprogreso-grid"> {{dataItem.porcen}}% </label>
              </div>
              <span class="tooltiptext text-left">
                <label class="tooltipDestacado" *ngIf="dataItem.idEstado==1">{{dataItem.operacionActual}} {{dataItem.hechasTotal}}/{{dataItem.total}} {{dataItem.porcen}}%</label>
                <ng-container *ngFor="let operacion of dataItem.opera; let i = index">
                  <label>{{dataItem.ordenOperaciones[i]}}. {{operacion}} {{dataItem.cant[i].split('/')[0]}}/{{dataItem.cant[i].split('/')[1]}}</label>
                </ng-container>
              </span>
            </span>
            <!-- ESTADO: ACABADA-->
            <span *ngIf="dataItem.idEstado==2 " class="tooltip-consumibles-contenido">
              <div class="cont-barraprogreso-grid">
                <div class="barraprogreso-grid barraprogreso-acabada" [ngStyle]="{'width': dataItem.porcenGrafico +'%'}"></div>
                <!-- <label class="label-barraprogreso-grid"> {{dataItem.porcen}}% </label> -->
                <label class="label-barraprogreso-grid"> {{'terminada' | translate}} </label>
              </div>
              <span class="tooltiptext text-left">
                <label class="tooltipDestacado" *ngIf="dataItem.idEstado==2 ">{{'terminada' | translate}} {{dataItem.hechasTotal}}/{{dataItem.total}} {{dataItem.porcen}}%</label>
                <ng-container *ngFor="let operacion of dataItem.opera; let i = index">
                  <label>{{dataItem.ordenOperaciones[i]}}. {{operacion}} {{dataItem.cant[i].split('/')[0]}}/{{dataItem.cant[i].split('/')[1]}}</label>
                </ng-container>
              </span>
            </span>
            <!-- ESTADO: VALIDADA-->
            <span *ngIf="dataItem.idEstado==3 " class="tooltip-consumibles-contenido">
              <div class="cont-barraprogreso-grid">
                <div class="barraprogreso-grid barraprogreso-validada" [ngStyle]="{'width': dataItem.porcenGrafico +'%'}"></div>
                <label class="label-barraprogreso-grid"> {{'validada' | translate}} </label>
              </div>
              <span class="tooltiptext text-left">
                <label class="tooltipDestacado">{{'validada' | translate}} {{dataItem.hechasTotal}}/{{dataItem.total}} {{dataItem.porcen}}%</label>
                <ng-container *ngFor="let operacion of dataItem.opera; let i = index">
                  <label>{{dataItem.ordenOperaciones[i]}}. {{operacion}} {{dataItem.cant[i].split('/')[0]}}/{{dataItem.cant[i].split('/')[1]}}</label>
                </ng-container>
              </span>
            </span>
            <!-- ESTADO: APARTADA-->
            <span *ngIf="dataItem.idEstado==4 " class="tooltip-consumibles-contenido">
              <div class="cont-barraprogreso-grid">
                <div class="barraprogreso-grid barraprogreso-apartada" [ngStyle]="{'width': dataItem.porcenGrafico +'%'}"></div>
                <label class="label-barraprogreso-grid"> {{'apartada' | translate}} </label>
              </div>
              <span class="tooltiptext text-left">
                <label class="tooltipDestacado">{{'apartada' | translate}} {{dataItem.hechasTotal}}/{{dataItem.total}} {{dataItem.porcen}}%</label>
                <ng-container *ngFor="let operacion of dataItem.opera; let i = index">
                  <label>{{dataItem.ordenOperaciones[i]}}. {{operacion}} {{dataItem.cant[i].split('/')[0]}}/{{dataItem.cant[i].split('/')[1]}}</label>
                </ng-container>
              </span>
            </span>
            <!-- ESTADO: CHATARRA-->
            <span *ngIf="dataItem.idEstado==5 " class="tooltip-consumibles-contenido">
              <div class="cont-barraprogreso-grid">
                <div class="barraprogreso-grid barraprogreso-achatarrada" [ngStyle]="{'width': dataItem.porcenGrafico +'%'}"></div>
                <label class="label-barraprogreso-grid"> {{'chatarra' | translate}} </label>
              </div>
              <span class="tooltiptext text-left">
                <label class="tooltipDestacado">{{'chatarra' | translate}} {{dataItem.hechasTotal}}/{{dataItem.total}} {{dataItem.porcen}}%</label>
                <ng-container *ngFor="let operacion of dataItem.opera; let i = index">
                  <label>{{dataItem.ordenOperaciones[i]}}. {{operacion}} {{dataItem.cant[i].split('/')[0]}}/{{dataItem.cant[i].split('/')[1]}}</label>
                </ng-container>
              </span>
            </span>
          </ng-template>
        </kendo-grid-column>
        <!-- VALORES -->
        <kendo-grid-column *ngIf="!exportandoExcel" field="claseValorFueraLimites" title="{{ 'valores' | translate }}" width="30" filter="boolean" [style]="{'text-align': 'center'}" class="celda-tooltip-externo p-0">
          <ng-template kendoGridCellTemplate let-dataItem>
            <!-- contenido de la columna -->
            <span class="celda-completa"
                  [ngClass]="{'bg-amarillo': dataItem.claseValorFueraLimites == 'fas fa-ban icon-menos-alt',
                              'bg-naranja': dataItem.claseValorFueraLimites == 'fas fa-times icono-estado-valorFueraLimites-fuera',
                              'bg-rojo': dataItem.claseValorFueraLimites == 'fas fa-times icon-equis',
                              'bg-verde': dataItem.claseValorFueraLimites == 'fas fa-check icon-check'}">
              <span class="icono-valores" [ngClass]="dataItem.claseValorFueraLimites" title="{{ valores | translate }}">
              </span>
            </span>
            <!-- tooltip -->
            <span class="tooltip-consumibles-contenido">
              <span class="tooltiptext text-left">
                <label class="tooltipDestacado">{{ 'total' | translate }} {{dataItem.totalOK}}/{{dataItem.totalMal}}/{{dataItem.totalVacio}}</label>
                <ng-container *ngFor="let val of dataItem.valores.split(';'); let i = index">
                  <label>{{dataItem.ordenOperaciones[i]}}. {{dataItem.opera[i]}} {{val}}</label>
                </ng-container>
              </span>
            </span>
          </ng-template>
        </kendo-grid-column>
        <!-- ANALITICA -->
        <kendo-grid-column *ngIf="!exportandoExcel" field="id" title="{{ 'analitica' | translate }}" width="30"
                           [style]="{'text-align': 'center'}" class="celda-tooltip-externo-header p-0">
          <ng-template kendoGridHeaderTemplate let-column>
            <span class="tooltip-consumibles-contenido">
              <label><i class="fas fa-info-circle mr-2"></i><i class="fas icon-rendimiento"></i></label>
              <span [attr.contenido]="contenidoAnalitica" class="tooltipTextHeader"></span>
            </span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <button type="button" class="btn-success btn" (click)="clickAnalitica(dataItem.id)"><i
                 class="fas icon-rendimiento"></i></button>
          </ng-template>
        </kendo-grid-column>

        <!-- EXCEL -->
        <kendo-grid-excel fileName="{{'historicoPiezas' | translate}}.xlsx" [fetchData]="allData"></kendo-grid-excel>
        <!-- MENSAJES -->
                                    <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                                                         filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                                                         filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                                                         filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                                                         filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                                                         filterContainsOperator="{{'filterContainsOperator' | translate}}"
                                                         filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                                                         filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                                                         filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                                                         filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                                                         filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                                                         filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                                                         filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                                                         filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                                                         filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                                                         filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                                                         filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                                                         filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                                                         filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                                                         filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                                                         groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                                                         noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
      </kendo-grid>
    </div>
  </div>
  <!-- CARGA -->
  <div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;"></div>
</div>

<!-- POPUP -->
<!--<ng-template #popup let-modal>
   <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{tituloModal}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <label>{{ 'observacion' | translate }}</label>
        <textarea kendoTextArea [(value)]="Jobservacion"></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="btnPopupAceptar()">{{ 'aceptar' | translate }}</button>
  </div>
</ng-template> -->
  <!-- POPUP: ESTADO PIEZA -->
  <ng-template #popup let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{tituloModal}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group" *ngIf="tipoPopup != 1">
            <label>{{ 'perdida' | translate }}</label>
            <div class="caja">
              <kendo-combobox [data]="Jperdidas"
                              [textField]="'text'"
                              [valueField]="'value'"
                              [(value)]="JperdidasSelected"
                              placeholder="{{ 'perdida' | translate }}"
                              [kendoDropDownFilter]="{operator: 'contains'}">
              </kendo-combobox>
            </div>
          </div>
          <div class="form-group" *ngIf="tipoPopup != 1 && JperdidasSelected?.solicitarOperacion">
            <label>{{ 'operacion' | translate }}</label>
            <div class="caja">
              <kendo-combobox [data]="Joperaciones_perdida"
                              [textField]="'text'"
                              [valueField]="'value'"
                              [(value)]="Joperaciones_perdidaSelected"
                              placeholder="{{ 'operacion' | translate }}"
                              [kendoDropDownFilter]="{operator: 'contains'}">
              </kendo-combobox>
            </div>
          </div>
          <label>{{ 'observacion' | translate }}</label>
          <textarea kendoTextArea [(value)]="Jobservacion"></textarea>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')"> {{ 'cancelar' | translate }} </button>
      <button type="button" class="btn btn-primary" (click)="btnPopupAceptar()" [disabled]="this.user.historicoOperaciones < 2">{{ 'aceptar' | translate }}</button>
    </div>
  </ng-template>