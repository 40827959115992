<!-- filtro para insertar un grafico en una casilla -->
<div class="cont-filtro-desplegable card" [ngClass]="menuAbierto ? 'filtro-desplegado' : 'filtro-oculto'">
  <div class="card-header">
    <h3>{{ 'graficos' | translate }}</h3>
    <button type="button" class="flt-cerrar" (click)="cerrarMenu()">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="filtro-desplegable card-body flt">

    <div class="cont-tipo-grafico clearfix">
      <div class="out-selec-tipo-grafico" *ngFor="let tipoGrafico of tiposGrafico; let i = index">
        <div class="selec-tipo-grafico" (click)="agregarGrafico(tipoGrafico)">
          <i class="{{tipoGrafico.icon}}"></i>
          <p>{{tipoGrafico.nombre}}</p>
        </div>
      </div>
    </div>

  </div>
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()">

  <div class="row">
    <div class="col-md-6">

      <div class="card">
        <div class="card-header">
          <h3>
            <label ID="lCaracteristicas" CssClass="">{{ 'caracteristicas' | translate }}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">

          <div class="row">

            <!--NOMBRE-->
            <div class="col-md-4">
              <div class="form-group">
                <kendo-label text="{{ 'nombre' | translate }}">
                  <kendo-textbox formControlName="nombre"></kendo-textbox>
                </kendo-label>
              </div>
            </div>

            <!--ABREVIATURA-->
            <div class="col-md-4">
              <div class="form-group">
                <kendo-label text="{{ 'abreviatura' | translate }}">
                  <kendo-textbox formControlName="abreviatura" required [ngClass]="{ 'is-invalid': submitted && f.abreviatura.errors }"></kendo-textbox>
                </kendo-label>
              </div>
            </div>

            <!--Seccion-->
            <div class="col-md-4">
              <div class="form-group">
                <kendo-label text="{{ 'seccion' | translate }}">
                  <kendo-dropdownlist [(data)]="groupedSeccion" [textField]="'nombre'" class="form-control"
                    [valueField]="'id'" formControlName="idSeccion"
                    [ngClass]="{ 'is-invalid': submitted && f.idSeccion.errors }"></kendo-dropdownlist>
                </kendo-label>
              </div>
            </div>

            <!--Frec. captura de datos-->
            <!-- <div class="col-md-4">
                  <div class="form-group">
                    <label>{{ 'frecCapturaDatos' | translate }}</label>
                    <kendo-numerictextbox formControlName="freq_captura_datos" class="form-control" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                  </div>
                </div> -->

          </div>

          <div class="row">

            <!--IP-->
            <!-- <div class="col-md-4">
                  <div class="form-group">
                    <kendo-label text="{{ 'ip' | translate }}">
                      <kendo-textbox formControlName="ip"></kendo-textbox>
                    </kendo-label>
                  </div>
                </div> -->

            <!--PORT-->
            <!-- <div class="col-md-4">
                  <div class="form-group">
                    <kendo-label text="{{ 'port' | translate }}">
                      <kendo-textbox formControlName="port"></kendo-textbox>
                    </kendo-label>
                  </div>
                </div> -->

            <!--RACK-->
            <!-- <div class="col-md-4">
                  <div class="form-group">
                    <kendo-label text="{{ 'rack' | translate }}">
                      <kendo-textbox formControlName="rack"></kendo-textbox>
                    </kendo-label>
                  </div>
                </div> -->

          </div>

          <div class="row">

            <!--SLOT-->
            <!-- <div class="col-md-4">
                  <div class="form-group">
                    <kendo-label text="{{ 'slot' | translate }}">
                      <kendo-textbox formControlName="slot"></kendo-textbox>
                    </kendo-label>
                  </div>
                </div> -->

            <!--Orden-->
            <div class="col-md-4">
              <div class="form-group">
                <label>{{ 'orden' | translate }}</label>
                <kendo-numerictextbox formControlName="orden" class="form-control" [min]="0" [max]="9999"
                  [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
              </div>
            </div>

            <!--Tiene historico procesos-->
            <div class="col-md-2">
              <kendo-label text="{{ 'tieneHistoricoProcesos' | translate}}">
                <br />
                <kendo-switch formControlName="tieneHistorico_Procesos" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
              </kendo-label>
            </div>

            <!--Activo-->
            <div class="col-md-2">
              <kendo-label text="{{ 'activo' | translate}}">
                <br />
                <kendo-switch formControlName="activo" [onLabel]="' '" [offLabel]="' '" id="activoId"></kendo-switch>
              </kendo-label>
            </div>

          </div>

        </div>
      </div>

    </div>

    <div class="col-md-6">
      <div class="row">
        <!--IMAGEN-->
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h3>
                <label ID="lCaracteristicas" CssClass="">{{ 'imagen' | translate }}</label>
              </h3>
              <div class="plegarpanel"></div>
            </div>
            <div class="card-body">
              <div class="imagen-maquina">
                <!--IMAGEN VIEJA-->
                <div class="clearfix text-center">
                  <img [src]="imageToShow" style="max-height: 113px">
                </div>
                <div class="clearfix">

                  <kendo-fileselect formControlName="archivo" [restrictions]="restrictions" [multiple]="false"
                    (select)="archivoSeleccionado($event)" (remove)="archivoEliminado($event)">
                    <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                      clearSelectedFiles="{{ 'clearSelectedFiles' | translate }}"
                      dropFilesHere="{{ 'dropFilesHere' | translate }}"
                      externalDropFilesHere="{{ 'externalDropFilesHere' | translate }}"
                      fileStatusFailed="{{ 'fileStatusFailed' | translate }}"
                      fileStatusUploaded="{{ 'fileStatusUploaded' | translate }}"
                      filesBatchStatus="{{ 'filesBatchStatus' | translate }}"
                      filesBatchStatusFailed="{{ 'filesBatchStatusFailed' | translate }}"
                      filesBatchStatusUploaded="{{ 'filesBatchStatusUploaded' | translate }}"
                      headerStatusPaused="{{ 'headerStatusPaused' | translate }}"
                      headerStatusUploaded="{{ 'headerStatusUploaded' | translate }}"
                      headerStatusUploading="{{ 'headerStatusUploading' | translate }}"
                      invalidFileExtension="{{ 'invalidFileExtension' | translate }}"
                      invalidMaxFileSize="{{ 'invalidMaxFileSize' | translate }}"
                      invalidMinFileSize="{{ 'invalidMinFileSize' | translate }}" pause="{{ 'pause' | translate }}"
                      remove="{{ 'remove' | translate }}" resume="{{ 'resume' | translate }}"
                      retry="{{ 'retry' | translate }}" select="{{ 'select' | translate }}"
                      uploadSelectedFiles="{{ 'uploadSelectedFiles' | translate }}">
                    </kendo-upload-messages>
                  </kendo-fileselect>


                </div>
              </div>
            </div>
          </div>
        </div>
        <!--ISOMETRICO-->
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h3>
                <label ID="lCaracteristicas" CssClass="">{{ 'isometrico' | translate }}</label>
              </h3>
              <div class="plegarpanel"></div>
            </div>
            <div class="card-body">
              <div class="imagen-isometrico-maquina" (click)="onClickAbrirPopup()">
                <div class="clearfix text-center"><img style="max-width: 95px; max-height: 95px;"
                    [src]="selectedDibujoMaquina.imagen"></div>
                <div class="clearfix text-center"><label style="font-size: 12px;">{{ 'dimensiones' | translate }}:
                    {{selectedDibujoMaquina.dimensiones}}</label></div>
                <div class="clearfix text-center"><button type="button" class="btn btn-primary">{{
                    'cambiarImagenIsometrica' | translate }}</button></div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>

</form>

<div *ngIf="form.value.tieneHistorico_Procesos">
  <kendo-tabstrip (tabSelect)="onTabSelect($event)">

    <kendo-tabstrip-tab title="{{ 'campos' | translate}}" [selected]="true">
      <ng-template kendoTabContent>
        <div class="content">
          <div class="row">
            <!--GRID CAMPOS PLC-->

            <kendo-grid [kendoGridBinding]="gridCamposPLC" [rowClass]="rowCallback" [selectedKeys]="mySelectionPLC"
              kendoGridSelectBy="id" [sortable]="true" filterable="menu" (cellClick)="cellClick($event)">


              <ng-template kendoGridToolbarTemplate position="top">
                <button type="button" (click)="onClickEditarCampoPLC()" class="btn btn-copiar btn-sm mr-1">{{ 'editar' |
                  translate}}</button>
                <button type="button" (click)="onClickNuevoCampoPLC()" class="btn btn-primary btn-sm mr-1">{{ 'nuevo' |
                  translate }}</button>
                <button type="button" (click)="onClickEliminarCampoPLC()" class="btn btn-danger btn-sm mr-1"
                  [disabled]="this.user.instalaciones <2">{{ 'eliminar' | translate }}</button>
                <button type="button" (click)="onClickDuplicarCampoPLC()" class="btn btn-morado btn-sm mr-1"
                  [disabled]="this.user.instalaciones <2"> {{ 'duplicar' | translate}}</button>
              </ng-template>


              <kendo-grid-checkbox-column width="3%" showSelectAll="true"></kendo-grid-checkbox-column>
              <!--orden-->
              <kendo-grid-column field="orden" title="{{ 'orden' | translate}}" width="4%"
                [style]="{'text-align': 'right'}"></kendo-grid-column>
              <!--nombre-->
              <kendo-grid-column width="20%" field="nombreCampo" title="{{ 'nombre' | translate}}"></kendo-grid-column>
              <!--tipo campo-->
              <kendo-grid-column width="20%" field="nombreTipoCampoI"
                title="{{ 'tipoCampo' | translate}}"></kendo-grid-column>
              <!--TipoPLC-->
              <kendo-grid-column width="20%" field="nombreTipoPLC"
                title="{{ 'tipoPlc' | translate}}"></kendo-grid-column>
              <!--frecuencia -->
              <kendo-grid-column width="8%" field="freq_captura_datos" title="{{ 'frecCapturaDatos' | translate}}"
                [style]="{'text-align': 'right'}"></kendo-grid-column>
              <!--activo -->
              <kendo-grid-column width="4%" field="activo" title="{{ 'activo' | translate}}">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <input type="checkbox" [checked]="dataItem.activo" disabled />
                </ng-template>
              </kendo-grid-column>


              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab title="{{ 'estructuraHMI' | translate}}">
      <ng-template kendoTabContent>
        <div class="content">
          <div class="row cont-editor-hmi">

            <!-- COLUMNA 1 -->

            <div class="col-md-4">
              <div class="card-header">
                <h3>

                  {{ 'columna' | translate }} 1
                </h3>

                <!-- BOTON CREAR ESTRUCTURA -->
                <div class="btn-group btn-columnas-hmi">
                  <button type="button" class="btn dropdown-toggle " data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i data-toggle="dropdown" class="icon-columnas"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <!--<div class="">-->
                    <!-- ROW 1 -->
                    <div class="row d-flex justify-content-center bd-highlight">
                      <!-- uno -->
                      <button type="button" class="btn" (click)="crearEstructura(1,1)">
                        <span class="uncol-icono col-icono-cont">
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- dos col -->
                      <button type="button" class="btn" (click)="crearEstructura(2,1)">
                        <span class="doscol-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- dos row -->
                      <button type="button" class="btn" (click)="crearEstructura(3,1)">
                        <span class="doslin-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- cuatro -->
                      <button type="button" class="btn" (click)="crearEstructura(4,1)">
                        <span class="doslincol-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>

                      <!-- ROW 2 -->

                      <!-- 2 izquierda uno derecha -->
                      <button type="button" class="btn" (click)="crearEstructura(5,1)">
                        <span class="doslinizq-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- uno derecha 2 izquierda -->
                      <button type="button" class="btn" (click)="crearEstructura(6,1)">
                        <span class="doslinder-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- 1 horizontal dos abajo -->
                      <button type="button" class="btn" (click)="crearEstructura(7,1)">
                        <span class="doscolabajo-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- dos horizontal uno abajo -->
                      <button type="button" class="btn" (click)="crearEstructura(8,1)">
                        <span class="doscolarriba-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>

              </div>

              <div class="card-body">

                <!-- AGREGAR ELEMENTOS -->
                <div [ngClass]="{'row': col1Izquierda || col1Derecha || cuadradosDerecha || cuadradosIzquierda}">
                  <!-- un cuadrado -->
                  <div #columna class="agregar-elemento" *ngIf="col1Agregar" (click)="annadirModulo(columna, 1, 1)">
                    <i [ngClass]="iconCell(infColumnas[1]['m1'].id_tipoGrafico_DAT)"></i>
                    <div>
                      <span>{{infColumnas[1]['m1'].nombre}}</span>
                    </div>
                  </div>
                  <!-- col izquierda -->
                  <div #columna class="col-md-6" *ngIf="col1Izquierda" (click)="annadirModulo(columna, 1, 1)">
                    <div class="float-left agregar-elemento w-100">
                      <i [ngClass]="iconCell(infColumnas[1]['m1'].id_tipoGrafico_DAT)"></i>
                      <div>
                        <span>{{infColumnas[1]['m1'].nombre}}</span>
                      </div>
                    </div>
                  </div>
                  <!-- cuadrados izquierda -->
                  <div class="col-md-6" *ngIf="cuadradosIzquierda">
                    <div class="row">
                      <div #columna1 class="agregar-elemento float-left w-100" (click)="annadirModulo(columna1, 1, 1)">
                        <i [ngClass]="iconCell(infColumnas[1]['m1'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[1]['m1'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div #columna class="agregar-elemento float-left w-100" (click)="annadirModulo(columna, 1, 3)">
                        <i [ngClass]="iconCell(infColumnas[1]['m3'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[1]['m3'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- col derecha -->
                  <div class="col-md-6" *ngIf="col1Derecha">
                    <div #columna class="float-left agregar-elemento w-100" (click)="annadirModulo(columna, 1, 2)">
                      <i [ngClass]="iconCell(infColumnas[1]['m2'].id_tipoGrafico_DAT)"></i>
                      <div>
                        <span>{{infColumnas[1]['m2'].nombre}}</span>
                      </div>
                    </div>
                  </div>
                  <!-- cuadrados derecha -->
                  <div class="col-md-6" *ngIf="cuadradosDerecha">
                    <div class="row">
                      <div #columna1 class="agregar-elemento float-left w-100" (click)="annadirModulo(columna1, 1, 2)">
                        <i [ngClass]="iconCell(infColumnas[1]['m2'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[1]['m2'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div #columna class="agregar-elemento float-left w-100" (click)="annadirModulo(columna, 1, 4)">
                        <i [ngClass]="iconCell(infColumnas[1]['m4'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[1]['m4'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- row arriba -->
                  <div class="row" *ngIf="row1Arriba">
                    <div class="col-md-12">
                      <div #columna class="agregar-elemento" (click)="annadirModulo(columna, 1, 1)">
                        <i [ngClass]="iconCell(infColumnas[1]['m1'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[1]['m1'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- cuadrados arriba -->
                  <div class="row" *ngIf="cuadradosArriba">
                    <div class="col-md-6">
                      <div #columna1 class="agregar-elemento" (click)="annadirModulo(columna1, 1, 1)">
                        <i [ngClass]="iconCell(infColumnas[1]['m1'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[1]['m1'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div #columna class="agregar-elemento" (click)="annadirModulo(columna, 1, 2)">
                        <i [ngClass]="iconCell(infColumnas[1]['m2'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[1]['m2'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- row abajo -->
                  <div class="row" *ngIf="row1Abajo">
                    <div class="col-md-12">
                      <div #columna class="agregar-elemento" (click)="annadirModulo(columna, 1, 3)">
                        <i [ngClass]="iconCell(infColumnas[1]['m3'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[1]['m3'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- cuadrados abajo -->
                  <div class="row" *ngIf="cuadradosAbajo">
                    <div class="col-md-6">
                      <div #columna1 class="agregar-elemento" (click)="annadirModulo(columna1, 1, 3)">
                        <i [ngClass]="iconCell(infColumnas[1]['m3'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[1]['m3'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div #columna class="agregar-elemento" (click)="annadirModulo(columna, 1, 4)">
                        <i [ngClass]="iconCell(infColumnas[1]['m4'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[1]['m4'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>

            <!-- COLUMNA 2 -->
            <div class="col-md-4">
              <div class="card-header">

                <h3>
                  {{ 'columna' | translate }} 2

                </h3>

                <!-- BOTON CREAR ESTRUCTURA -->
                <div class="btn-group btn-columnas-hmi">
                  <button type="button" class="btn dropdown-toggle " data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i data-toggle="dropdown" class="icon-columnas"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <!--<div class="">-->
                    <!-- ROW 1 -->
                    <div class="row d-flex justify-content-center bd-highlight">
                      <!-- uno -->
                      <button type="button" class="btn" (click)="crearEstructura(1,2)">
                        <span class="uncol-icono col-icono-cont">
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- dos col -->
                      <button type="button" class="btn" (click)="crearEstructura(2,2)">
                        <span class="doscol-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- dos row -->
                      <button type="button" class="btn" (click)="crearEstructura(3,2)">
                        <span class="doslin-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- cuatro -->
                      <button type="button" class="btn" (click)="crearEstructura(4,2)">
                        <span class="doslincol-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>

                      <!-- ROW 2 -->


                      <!-- 2 izquierda uno derecha -->
                      <button type="button" class="btn" (click)="crearEstructura(5,2)">
                        <span class="doslinizq-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- uno derecha 2 izquierda -->
                      <button type="button" class="btn" (click)="crearEstructura(6,2)">
                        <span class="doslinder-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- 1 horizontal dos abajo -->
                      <button type="button" class="btn" (click)="crearEstructura(7,2)">
                        <span class="doscolabajo-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- dos horizontal uno abajo -->
                      <button type="button" class="btn" (click)="crearEstructura(8,2)">
                        <span class="doscolarriba-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>

              </div>
              <div class="card-body">

                <!-- AGREGAR ELEMENTOS -->
                <div [ngClass]="{'row': col2Izquierda || col2Derecha || cuadrados2Derecha || cuadrados2Izquierda}">
                  <!-- un cuadrado -->
                  <div #columna class="agregar-elemento" *ngIf="col2Agregar" (click)="annadirModulo(columna, 2, 1)">
                    <i [ngClass]="iconCell(infColumnas[2]['m1'].id_tipoGrafico_DAT)"></i>
                    <div>
                      <span>{{infColumnas[2]['m1'].nombre}}</span>
                    </div>
                  </div>
                  <!-- col izquierda -->
                  <div #columna class="col-md-6" *ngIf="col2Izquierda" (click)="annadirModulo(columna, 2, 1)">
                    <div class="float-left agregar-elemento w-100">
                      <i [ngClass]="iconCell(infColumnas[2]['m1'].id_tipoGrafico_DAT)"></i>
                      <div>
                        <span>{{infColumnas[2]['m1'].nombre}}</span>
                      </div>
                    </div>
                  </div>
                  <!-- cuadrados izquierda -->
                  <div class="col-md-6" *ngIf="cuadrados2Izquierda">
                    <div class="row">
                      <div #columna1 class="agregar-elemento float-left w-100" (click)="annadirModulo(columna1, 2, 1)">
                        <i [ngClass]="iconCell(infColumnas[2]['m1'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[2]['m1'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div #columna class="agregar-elemento float-left w-100" (click)="annadirModulo(columna, 2, 3)">
                        <i [ngClass]="iconCell(infColumnas[2]['m3'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[2]['m3'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- col derecha -->
                  <div class="col-md-6" *ngIf="col2Derecha">
                    <div #columna class="float-left agregar-elemento w-100" (click)="annadirModulo(columna, 2, 2)">
                      <i [ngClass]="iconCell(infColumnas[2]['m2'].id_tipoGrafico_DAT)"></i>
                      <div>
                        <span>{{infColumnas[2]['m2'].nombre}}</span>
                      </div>
                    </div>
                  </div>
                  <!-- cuadrados derecha -->
                  <div class="col-md-6" *ngIf="cuadrados2Derecha">
                    <div class="row">
                      <div #columna1 class="agregar-elemento float-left w-100" (click)="annadirModulo(columna1, 2, 2)">
                        <i [ngClass]="iconCell(infColumnas[2]['m2'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[2]['m2'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div #columna class="agregar-elemento float-left w-100" (click)="annadirModulo(columna, 2, 4)">
                        <i [ngClass]="iconCell(infColumnas[2]['m4'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[2]['m4'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- row arriba -->
                  <div class="row" *ngIf="row2Arriba">
                    <div class="col-md-12">
                      <div #columna class="agregar-elemento" (click)="annadirModulo(columna, 2, 1)">
                        <i [ngClass]="iconCell(infColumnas[2]['m1'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[2]['m1'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- cuadrados arriba -->
                  <div class="row" *ngIf="cuadrados2Arriba">
                    <div class="col-md-6">
                      <div #columna1 class="agregar-elemento" (click)="annadirModulo(columna1, 2, 1)">
                        <i [ngClass]="iconCell(infColumnas[2]['m1'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[2]['m1'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div #columna class="agregar-elemento" (click)="annadirModulo(columna, 2, 2)">
                        <i [ngClass]="iconCell(infColumnas[2]['m2'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[2]['m2'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- row abajo -->
                  <div class="row" *ngIf="row2Abajo">
                    <div class="col-md-12">
                      <div #columna class="agregar-elemento w-100" (click)="annadirModulo(columna, 2, 3)">
                        <i [ngClass]="iconCell(infColumnas[2]['m3'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[2]['m3'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- cuadrados abajo -->
                  <div class="row" *ngIf="cuadrados2Abajo">
                    <div class="col-md-6">
                      <div #columna1 class="agregar-elemento" (click)="annadirModulo(columna1, 2, 3)">
                        <i [ngClass]="iconCell(infColumnas[2]['m3'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[2]['m3'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div #columna class="agregar-elemento" (click)="annadirModulo(columna, 2, 4)">
                        <i [ngClass]="iconCell(infColumnas[2]['m4'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[2]['m4'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>

            <!-- COLUMNA 3 -->
            <div class="col-md-4">

              <div class="card-header">
                <h3>

                  {{ 'columna' | translate }} 3

                </h3> <!-- BOTON CREAR ESTRUCTURA -->
                <div class="btn-group btn-columnas-hmi">
                  <button type="button" class="btn dropdown-toggle " data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i data-toggle="dropdown" class="icon-columnas"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <!--<div class="">-->
                    <!-- ROW 1 -->
                    <div class="row d-flex justify-content-center bd-highlight">
                      <!-- uno -->
                      <button type="button" class="btn" (click)="crearEstructura(1,3)">
                        <span class="uncol-icono col-icono-cont">
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- dos col -->
                      <button type="button" class="btn" (click)="crearEstructura(2,3)">
                        <span class="doscol-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- dos row -->
                      <button type="button" class="btn" (click)="crearEstructura(3,3)">
                        <span class="doslin-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- cuatro -->
                      <button type="button" class="btn" (click)="crearEstructura(4,3)">
                        <span class="doslincol-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>

                      <!-- ROW 2 -->

                      <!-- 2 izquierda uno derecha -->
                      <button type="button" class="btn" (click)="crearEstructura(5,3)">
                        <span class="doslinizq-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- uno izquierda 2 derecha -->
                      <button type="button" class="btn" (click)="crearEstructura(6,3)">
                        <span class="doslinder-icono  col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- 1 horizontal dos abajo -->
                      <button type="button" class="btn" (click)="crearEstructura(7,3)">
                        <span class="doscolabajo-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- dos horizontal uno abajo -->
                      <button type="button" class="btn" (click)="crearEstructura(8,3)">
                        <span class="doscolarriba-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- AGREGAR ELEMENTOS -->
              <div class="card-body">
                <div [ngClass]="{'row': col3Izquierda || col3Derecha || cuadrados3Derecha || cuadrados3Izquierda}">
                  <!-- un cuadrado -->
                  <div #columna class="agregar-elemento" *ngIf="col3Agregar" (click)="annadirModulo(columna, 3, 1)">
                    <i [ngClass]="iconCell(infColumnas[3]['m1'].id_tipoGrafico_DAT)"></i>
                    <div>
                      <span>{{infColumnas[3]['m1'].nombre}}</span>
                    </div>
                  </div>
                  <!-- col izquierda -->
                  <div #columna class="col-md-6" *ngIf="col3Izquierda" (click)="annadirModulo(columna, 3, 1)">
                    <div class="float-left agregar-elemento w-100">
                      <i [ngClass]="iconCell(infColumnas[3]['m1'].id_tipoGrafico_DAT)"></i>
                      <div>
                        <span>{{infColumnas[3]['m1'].nombre}}</span>
                      </div>
                    </div>
                  </div>
                  <!-- cuadrados izquierda -->
                  <div class="col-md-6" *ngIf="cuadrados3Izquierda">
                    <div class="row">
                      <div #columna1 class="agregar-elemento float-left w-100" (click)="annadirModulo(columna1, 2, 1)">
                        <i [ngClass]="iconCell(infColumnas[3]['m1'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[3]['m1'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div #columna class="agregar-elemento float-left w-100" (click)="annadirModulo(columna, 3, 3)">
                        <i [ngClass]="iconCell(infColumnas[3]['m3'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[3]['m3'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- col derecha -->
                  <div class="col-md-6" *ngIf="col3Derecha">
                    <div #columna class="float-left agregar-elemento w-100" (click)="annadirModulo(columna, 3, 2)">
                      <i [ngClass]="iconCell(infColumnas[3]['m2'].id_tipoGrafico_DAT)"></i>
                      <div>
                        <span>{{infColumnas[3]['m2'].nombre}}</span>
                      </div>
                    </div>
                  </div>
                  <!-- cuadrados derecha -->
                  <div class="col-md-6" *ngIf="cuadrados3Derecha">
                    <div class="row">
                      <div #columna1 class="agregar-elemento float-left w-100" (click)="annadirModulo(columna1, 3, 2)">
                        <i [ngClass]="iconCell(infColumnas[3]['m2'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[3]['m2'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div #columna class="agregar-elemento float-left w-100" (click)="annadirModulo(columna, 3, 4)">
                        <i [ngClass]="iconCell(infColumnas[3]['m4'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[3]['m4'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- row arriba -->
                  <div class="row" *ngIf="row3Arriba">
                    <div class="col-md-12">
                      <div #columna class="agregar-elemento w-100" (click)="annadirModulo(columna, 3, 1)">
                        <i [ngClass]="iconCell(infColumnas[3]['m1'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[3]['m1'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- cuadrados arriba -->
                  <div class="row" *ngIf="cuadrados3Arriba">
                    <div class="col-md-6">
                      <div #columna1 class="agregar-elemento" (click)="annadirModulo(columna1, 3, 1)">
                        <i [ngClass]="iconCell(infColumnas[3]['m1'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[3]['m1'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div #columna class="agregar-elemento" (click)="annadirModulo(columna, 3, 2)">
                        <i [ngClass]="iconCell(infColumnas[3]['m2'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[3]['m2'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- row abajo -->
                  <div class="row" *ngIf="row3Abajo">
                    <div class="col-md-12">
                      <div #columna class="agregar-elemento w-100" (click)="annadirModulo(columna, 3, 3)">
                        <i [ngClass]="iconCell(infColumnas[3]['m3'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[3]['m3'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- cuadrados abajo -->
                  <div class="row" *ngIf="cuadrados3Abajo">
                    <div class="col-md-6">
                      <div #columna1 class="agregar-elemento" (click)="annadirModulo(columna1, 3, 3)">
                        <i [ngClass]="iconCell(infColumnas[3]['m3'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[3]['m3'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div #columna class="agregar-elemento" (click)="annadirModulo(columna, 3, 4)">
                        <i [ngClass]="iconCell(infColumnas[3]['m4'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[3]['m4'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>

          </div>

          <!-- BOTON GUARDAR -->
          <!-- <div class="d-flex justify-content-center bd-highlight">
            <button type="button" class="btn btn-success" (click)="onClickGuardar()">{{ 'guardar' | translate}}</button>
          </div> -->
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab title="{{ 'estructuraHome' | translate}}">
      <ng-template kendoTabContent>
        <div class="content">
          <div class="row cont-editor-hmi">

            <!-- ESTRUCTURA HOME -->

            <div class="col-md-4">
              <div class="card-header">
                <h3>

                  {{ 'home' | translate }}
                </h3>

                <!-- BOTON CREAR ESTRUCTURA -->
                <div class="btn-group btn-columnas-hmi">
                  <button type="button" class="btn dropdown-toggle " data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i data-toggle="dropdown" class="icon-columnas"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <!--<div class="">-->
                    <!-- ROW 1 -->
                    <div class="row d-flex justify-content-center bd-highlight">
                      <!-- un col -->
                      <button type="button" class="btn" (click)="crearEstructuraHome(1)">
                        <span class="doscol-icono col-icono-cont">
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- dos col -->
                      <button type="button" class="btn" (click)="crearEstructuraHome(2)">
                        <span class="doscol-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>

              </div>

              <div class="card-body">

                <!-- AGREGAR ELEMENTOS -->
                <div [ngClass]="{'row': colIzquierdaHome || colDerechaHome}">

                  <!-- col izquierda -->
                  <div #columna class="col-md-6" *ngIf="colIzquierdaHome" (click)="annadirModulo(columna, 1, 1)">
                    <div class="float-left agregar-elemento w-100">
                      <i [ngClass]="iconCell(infHome[1]['m1'].id_tipoGrafico_DAT)"></i>
                      <div>
                        <span>{{infHome[1]['m1'].nombre}}</span>
                      </div>
                    </div>
                  </div>

                  <!-- col derecha -->
                  <div class="col-md-6" *ngIf="colDerechaHome">
                    <div #columna class="float-left agregar-elemento w-100" (click)="annadirModulo(columna, 1, 2)">
                      <i [ngClass]="iconCell(infHome[1]['m2'].id_tipoGrafico_DAT)"></i>
                      <div>
                        <span>{{infHome[1]['m2'].nombre}}</span>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>


          </div>

          <!-- BOTON GUARDAR -->
          <!-- <div class="d-flex justify-content-center bd-highlight">
            <button type="button" class="btn btn-success" (click)="onClickGuardarHome()">{{ 'guardar' | translate}}</button>
          </div> -->
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <!-- SUBESTADOS-->
    <kendo-tabstrip-tab title="{{ 'subestados' | translate}}" [selected]="false">
      <ng-template kendoTabContent>
        <div class="row" style="margin-left: -10px; margin-right: -10px;">
          <app-maquina-secciones-subestados [id]="id"></app-maquina-secciones-subestados>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <!--MANTENIMIENTOS-->
    <kendo-tabstrip-tab title="{{ 'mantenimientos' | translate}}">
      <ng-template kendoTabContent>
        <div class="row" style="margin-left: -10px; margin-right: -10px;">
          <app-maquina-secciones-mantenimientos [id]="id"></app-maquina-secciones-mantenimientos>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <!--MANUALES-->
    <kendo-tabstrip-tab title="{{ 'manuales' | translate}}">
      <ng-template kendoTabContent>
        <div class="row" style="margin-left: -10px; margin-right: -10px;">
          <app-maquina-secciones-manuales [id]="id"></app-maquina-secciones-manuales>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab title="{{ '-' | translate}}" [selected]="false" disabled="true">
      <ng-template kendoTabContent>
        <div class="content">
          <div class="row">
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

  </kendo-tabstrip>
</div>

<div *ngIf="!form.value.tieneHistorico_Procesos">
  <kendo-tabstrip (tabSelect)="onTabSelect($event)">

    <kendo-tabstrip-tab title="{{ 'campos' | translate}}" [selected]="true">
      <ng-template kendoTabContent>
        <div class="content">
          <div class="row">
            <!--GRID CAMPOS PLC-->

            <kendo-grid [kendoGridBinding]="gridCamposPLC" [rowClass]="rowCallback" [selectedKeys]="mySelectionPLC"
              kendoGridSelectBy="id" [sortable]="true" filterable="menu" (cellClick)="cellClick($event)">


              <ng-template kendoGridToolbarTemplate position="top">
                <button type="button" (click)="onClickEditarCampoPLC()" class="btn btn-copiar btn-sm mr-1">{{ 'editar' |
                  translate}}</button>
                <button type="button" (click)="onClickNuevoCampoPLC()" class="btn btn-primary btn-sm mr-1">{{ 'nuevo' |
                  translate }}</button>
                <button type="button" (click)="onClickEliminarCampoPLC()" class="btn btn-danger btn-sm mr-1"
                  [disabled]="this.user.instalaciones <2">{{ 'eliminar' | translate }}</button>
                <button type="button" (click)="onClickDuplicarCampoPLC()" class="btn btn-morado btn-sm mr-1"
                  [disabled]="this.user.instalaciones <2"> {{ 'duplicar' | translate}}</button>
              </ng-template>


              <kendo-grid-checkbox-column width="3%" showSelectAll="true"></kendo-grid-checkbox-column>
              <!--orden-->
              <kendo-grid-column field="orden" title="{{ 'orden' | translate}}" width="4%"
                [style]="{'text-align': 'right'}"></kendo-grid-column>
              <!--nombre-->
              <kendo-grid-column width="20%" field="nombreCampo" title="{{ 'nombre' | translate}}"></kendo-grid-column>
              <!--tipo campo-->
              <kendo-grid-column width="20%" field="nombreTipoCampoI"
                title="{{ 'tipoCampo' | translate}}"></kendo-grid-column>
              <!--TipoPLC-->
              <kendo-grid-column width="20%" field="nombreTipoPLC"
                title="{{ 'tipoPlc' | translate}}"></kendo-grid-column>
              <!--frecuencia -->
              <kendo-grid-column width="8%" field="freq_captura_datos" title="{{ 'frecCapturaDatos' | translate}}"
                [style]="{'text-align': 'right'}"></kendo-grid-column>
              <!--activo -->
              <kendo-grid-column width="4%" field="activo" title="{{ 'activo' | translate}}">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <input type="checkbox" [checked]="dataItem.activo" disabled />
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab title="{{ 'estructuraHMI' | translate}}">
      <ng-template kendoTabContent>
        <div class="content">
          <div class="row cont-editor-hmi">

            <!-- COLUMNA 1 -->

            <div class="col-md-4">
              <div class="card-header">
                <h3>

                  {{ 'columna' | translate }} 1
                </h3>

                <!-- BOTON CREAR ESTRUCTURA -->
                <div class="btn-group btn-columnas-hmi">
                  <button type="button" class="btn dropdown-toggle " data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i data-toggle="dropdown" class="icon-columnas"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <!--<div class="">-->
                    <!-- ROW 1 -->
                    <div class="row d-flex justify-content-center bd-highlight">
                      <!-- uno -->
                      <button type="button" class="btn" (click)="crearEstructura(1,1)">
                        <span class="uncol-icono col-icono-cont">
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- dos col -->
                      <button type="button" class="btn" (click)="crearEstructura(2,1)">
                        <span class="doscol-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- dos row -->
                      <button type="button" class="btn" (click)="crearEstructura(3,1)">
                        <span class="doslin-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- cuatro -->
                      <button type="button" class="btn" (click)="crearEstructura(4,1)">
                        <span class="doslincol-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>

                      <!-- ROW 2 -->

                      <!-- 2 izquierda uno derecha -->
                      <button type="button" class="btn" (click)="crearEstructura(5,1)">
                        <span class="doslinizq-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- uno derecha 2 izquierda -->
                      <button type="button" class="btn" (click)="crearEstructura(6,1)">
                        <span class="doslinder-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- 1 horizontal dos abajo -->
                      <button type="button" class="btn" (click)="crearEstructura(7,1)">
                        <span class="doscolabajo-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- dos horizontal uno abajo -->
                      <button type="button" class="btn" (click)="crearEstructura(8,1)">
                        <span class="doscolarriba-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>

              </div>

              <div class="card-body">

                <!-- AGREGAR ELEMENTOS -->
                <div [ngClass]="{'row': col1Izquierda || col1Derecha || cuadradosDerecha || cuadradosIzquierda}">
                  <!-- un cuadrado -->
                  <div #columna class="agregar-elemento" *ngIf="col1Agregar" (click)="annadirModulo(columna, 1, 1)">
                    <i [ngClass]="iconCell(infColumnas[1]['m1'].id_tipoGrafico_DAT)"></i>
                    <div>
                      <span>{{infColumnas[1]['m1'].nombre}}</span>
                    </div>
                  </div>
                  <!-- col izquierda -->
                  <div #columna class="col-md-6" *ngIf="col1Izquierda" (click)="annadirModulo(columna, 1, 1)">
                    <div class="float-left agregar-elemento w-100">
                      <i [ngClass]="iconCell(infColumnas[1]['m1'].id_tipoGrafico_DAT)"></i>
                      <div>
                        <span>{{infColumnas[1]['m1'].nombre}}</span>
                      </div>
                    </div>
                  </div>
                  <!-- cuadrados izquierda -->
                  <div class="col-md-6" *ngIf="cuadradosIzquierda">
                    <div class="row">
                      <div #columna1 class="agregar-elemento float-left w-100" (click)="annadirModulo(columna1, 1, 1)">
                        <i [ngClass]="iconCell(infColumnas[1]['m1'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[1]['m1'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div #columna class="agregar-elemento float-left w-100" (click)="annadirModulo(columna, 1, 3)">
                        <i [ngClass]="iconCell(infColumnas[1]['m3'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[1]['m3'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- col derecha -->
                  <div class="col-md-6" *ngIf="col1Derecha">
                    <div #columna class="float-left agregar-elemento w-100" (click)="annadirModulo(columna, 1, 2)">
                      <i [ngClass]="iconCell(infColumnas[1]['m2'].id_tipoGrafico_DAT)"></i>
                      <div>
                        <span>{{infColumnas[1]['m2'].nombre}}</span>
                      </div>
                    </div>
                  </div>
                  <!-- cuadrados derecha -->
                  <div class="col-md-6" *ngIf="cuadradosDerecha">
                    <div class="row">
                      <div #columna1 class="agregar-elemento float-left w-100" (click)="annadirModulo(columna1, 1, 2)">
                        <i [ngClass]="iconCell(infColumnas[1]['m2'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[1]['m2'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div #columna class="agregar-elemento float-left w-100" (click)="annadirModulo(columna, 1, 4)">
                        <i [ngClass]="iconCell(infColumnas[1]['m4'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[1]['m4'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- row arriba -->
                  <div class="row" *ngIf="row1Arriba">
                    <div class="col-md-12">
                      <div #columna class="agregar-elemento" (click)="annadirModulo(columna, 1, 1)">
                        <i [ngClass]="iconCell(infColumnas[1]['m1'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[1]['m1'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- cuadrados arriba -->
                  <div class="row" *ngIf="cuadradosArriba">
                    <div class="col-md-6">
                      <div #columna1 class="agregar-elemento" (click)="annadirModulo(columna1, 1, 1)">
                        <i [ngClass]="iconCell(infColumnas[1]['m1'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[1]['m1'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div #columna class="agregar-elemento" (click)="annadirModulo(columna, 1, 2)">
                        <i [ngClass]="iconCell(infColumnas[1]['m2'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[1]['m2'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- row abajo -->
                  <div class="row" *ngIf="row1Abajo">
                    <div class="col-md-12">
                      <div #columna class="agregar-elemento" (click)="annadirModulo(columna, 1, 3)">
                        <i [ngClass]="iconCell(infColumnas[1]['m3'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[1]['m3'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- cuadrados abajo -->
                  <div class="row" *ngIf="cuadradosAbajo">
                    <div class="col-md-6">
                      <div #columna1 class="agregar-elemento" (click)="annadirModulo(columna1, 1, 3)">
                        <i [ngClass]="iconCell(infColumnas[1]['m3'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[1]['m3'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div #columna class="agregar-elemento" (click)="annadirModulo(columna, 1, 4)">
                        <i [ngClass]="iconCell(infColumnas[1]['m4'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[1]['m4'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>

            <!-- COLUMNA 2 -->
            <div class="col-md-4">
              <div class="card-header">

                <h3>
                  {{ 'columna' | translate }} 2

                </h3>

                <!-- BOTON CREAR ESTRUCTURA -->
                <div class="btn-group btn-columnas-hmi">
                  <button type="button" class="btn dropdown-toggle " data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i data-toggle="dropdown" class="icon-columnas"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <!--<div class="">-->
                    <!-- ROW 1 -->
                    <div class="row d-flex justify-content-center bd-highlight">
                      <!-- uno -->
                      <button type="button" class="btn" (click)="crearEstructura(1,2)">
                        <span class="uncol-icono col-icono-cont">
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- dos col -->
                      <button type="button" class="btn" (click)="crearEstructura(2,2)">
                        <span class="doscol-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- dos row -->
                      <button type="button" class="btn" (click)="crearEstructura(3,2)">
                        <span class="doslin-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- cuatro -->
                      <button type="button" class="btn" (click)="crearEstructura(4,2)">
                        <span class="doslincol-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>

                      <!-- ROW 2 -->


                      <!-- 2 izquierda uno derecha -->
                      <button type="button" class="btn" (click)="crearEstructura(5,2)">
                        <span class="doslinizq-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- uno derecha 2 izquierda -->
                      <button type="button" class="btn" (click)="crearEstructura(6,2)">
                        <span class="doslinder-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- 1 horizontal dos abajo -->
                      <button type="button" class="btn" (click)="crearEstructura(7,2)">
                        <span class="doscolabajo-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- dos horizontal uno abajo -->
                      <button type="button" class="btn" (click)="crearEstructura(8,2)">
                        <span class="doscolarriba-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>

              </div>
              <div class="card-body">

                <!-- AGREGAR ELEMENTOS -->
                <div [ngClass]="{'row': col2Izquierda || col2Derecha || cuadrados2Derecha || cuadrados2Izquierda}">
                  <!-- un cuadrado -->
                  <div #columna class="agregar-elemento" *ngIf="col2Agregar" (click)="annadirModulo(columna, 2, 1)">
                    <i [ngClass]="iconCell(infColumnas[2]['m1'].id_tipoGrafico_DAT)"></i>
                    <div>
                      <span>{{infColumnas[2]['m1'].nombre}}</span>
                    </div>
                  </div>
                  <!-- col izquierda -->
                  <div #columna class="col-md-6" *ngIf="col2Izquierda" (click)="annadirModulo(columna, 2, 1)">
                    <div class="float-left agregar-elemento w-100">
                      <i [ngClass]="iconCell(infColumnas[2]['m1'].id_tipoGrafico_DAT)"></i>
                      <div>
                        <span>{{infColumnas[2]['m1'].nombre}}</span>
                      </div>
                    </div>
                  </div>
                  <!-- cuadrados izquierda -->
                  <div class="col-md-6" *ngIf="cuadrados2Izquierda">
                    <div class="row">
                      <div #columna1 class="agregar-elemento float-left w-100" (click)="annadirModulo(columna1, 2, 1)">
                        <i [ngClass]="iconCell(infColumnas[2]['m1'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[2]['m1'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div #columna class="agregar-elemento float-left w-100" (click)="annadirModulo(columna, 2, 3)">
                        <i [ngClass]="iconCell(infColumnas[2]['m3'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[2]['m3'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- col derecha -->
                  <div class="col-md-6" *ngIf="col2Derecha">
                    <div #columna class="float-left agregar-elemento w-100" (click)="annadirModulo(columna, 2, 2)">
                      <i [ngClass]="iconCell(infColumnas[2]['m2'].id_tipoGrafico_DAT)"></i>
                      <div>
                        <span>{{infColumnas[2]['m2'].nombre}}</span>
                      </div>
                    </div>
                  </div>
                  <!-- cuadrados derecha -->
                  <div class="col-md-6" *ngIf="cuadrados2Derecha">
                    <div class="row">
                      <div #columna1 class="agregar-elemento float-left w-100" (click)="annadirModulo(columna1, 2, 2)">
                        <i [ngClass]="iconCell(infColumnas[2]['m2'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[2]['m2'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div #columna class="agregar-elemento float-left w-100" (click)="annadirModulo(columna, 2, 4)">
                        <i [ngClass]="iconCell(infColumnas[2]['m4'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[2]['m4'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- row arriba -->
                  <div class="row" *ngIf="row2Arriba">
                    <div class="col-md-12">
                      <div #columna class="agregar-elemento" (click)="annadirModulo(columna, 2, 1)">
                        <i [ngClass]="iconCell(infColumnas[2]['m1'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[2]['m1'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- cuadrados arriba -->
                  <div class="row" *ngIf="cuadrados2Arriba">
                    <div class="col-md-6">
                      <div #columna1 class="agregar-elemento" (click)="annadirModulo(columna1, 2, 1)">
                        <i [ngClass]="iconCell(infColumnas[2]['m1'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[2]['m1'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div #columna class="agregar-elemento" (click)="annadirModulo(columna, 2, 2)">
                        <i [ngClass]="iconCell(infColumnas[2]['m2'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[2]['m2'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- row abajo -->
                  <div class="row" *ngIf="row2Abajo">
                    <div class="col-md-12">
                      <div #columna class="agregar-elemento w-100" (click)="annadirModulo(columna, 2, 3)">
                        <i [ngClass]="iconCell(infColumnas[2]['m3'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[2]['m3'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- cuadrados abajo -->
                  <div class="row" *ngIf="cuadrados2Abajo">
                    <div class="col-md-6">
                      <div #columna1 class="agregar-elemento" (click)="annadirModulo(columna1, 2, 3)">
                        <i [ngClass]="iconCell(infColumnas[2]['m3'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[2]['m3'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div #columna class="agregar-elemento" (click)="annadirModulo(columna, 2, 4)">
                        <i [ngClass]="iconCell(infColumnas[2]['m4'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[2]['m4'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>

            <!-- COLUMNA 3 -->
            <div class="col-md-4">

              <div class="card-header">
                <h3>

                  {{ 'columna' | translate }} 3

                </h3> <!-- BOTON CREAR ESTRUCTURA -->
                <div class="btn-group btn-columnas-hmi">
                  <button type="button" class="btn dropdown-toggle " data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i data-toggle="dropdown" class="icon-columnas"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <!--<div class="">-->
                    <!-- ROW 1 -->
                    <div class="row d-flex justify-content-center bd-highlight">
                      <!-- uno -->
                      <button type="button" class="btn" (click)="crearEstructura(1,3)">
                        <span class="uncol-icono col-icono-cont">
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- dos col -->
                      <button type="button" class="btn" (click)="crearEstructura(2,3)">
                        <span class="doscol-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- dos row -->
                      <button type="button" class="btn" (click)="crearEstructura(3,3)">
                        <span class="doslin-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- cuatro -->
                      <button type="button" class="btn" (click)="crearEstructura(4,3)">
                        <span class="doslincol-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>

                      <!-- ROW 2 -->

                      <!-- 2 izquierda uno derecha -->
                      <button type="button" class="btn" (click)="crearEstructura(5,3)">
                        <span class="doslinizq-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- uno izquierda 2 derecha -->
                      <button type="button" class="btn" (click)="crearEstructura(6,3)">
                        <span class="doslinder-icono  col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- 1 horizontal dos abajo -->
                      <button type="button" class="btn" (click)="crearEstructura(7,3)">
                        <span class="doscolabajo-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- dos horizontal uno abajo -->
                      <button type="button" class="btn" (click)="crearEstructura(8,3)">
                        <span class="doscolarriba-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- AGREGAR ELEMENTOS -->
              <div class="card-body">
                <div [ngClass]="{'row': col3Izquierda || col3Derecha || cuadrados3Derecha || cuadrados3Izquierda}">
                  <!-- un cuadrado -->
                  <div #columna class="agregar-elemento" *ngIf="col3Agregar" (click)="annadirModulo(columna, 3, 1)">
                    <i [ngClass]="iconCell(infColumnas[3]['m1'].id_tipoGrafico_DAT)"></i>
                    <div>
                      <span>{{infColumnas[3]['m1'].nombre}}</span>
                    </div>
                  </div>
                  <!-- col izquierda -->
                  <div #columna class="col-md-6" *ngIf="col3Izquierda" (click)="annadirModulo(columna, 3, 1)">
                    <div class="float-left agregar-elemento w-100">
                      <i [ngClass]="iconCell(infColumnas[3]['m1'].id_tipoGrafico_DAT)"></i>
                      <div>
                        <span>{{infColumnas[3]['m1'].nombre}}</span>
                      </div>
                    </div>
                  </div>
                  <!-- cuadrados izquierda -->
                  <div class="col-md-6" *ngIf="cuadrados3Izquierda">
                    <div class="row">
                      <div #columna1 class="agregar-elemento float-left w-100" (click)="annadirModulo(columna1, 2, 1)">
                        <i [ngClass]="iconCell(infColumnas[3]['m1'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[3]['m1'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div #columna class="agregar-elemento float-left w-100" (click)="annadirModulo(columna, 3, 3)">
                        <i [ngClass]="iconCell(infColumnas[3]['m3'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[3]['m3'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- col derecha -->
                  <div class="col-md-6" *ngIf="col3Derecha">
                    <div #columna class="float-left agregar-elemento w-100" (click)="annadirModulo(columna, 3, 2)">
                      <i [ngClass]="iconCell(infColumnas[3]['m2'].id_tipoGrafico_DAT)"></i>
                      <div>
                        <span>{{infColumnas[3]['m2'].nombre}}</span>
                      </div>
                    </div>
                  </div>
                  <!-- cuadrados derecha -->
                  <div class="col-md-6" *ngIf="cuadrados3Derecha">
                    <div class="row">
                      <div #columna1 class="agregar-elemento float-left w-100" (click)="annadirModulo(columna1, 3, 2)">
                        <i [ngClass]="iconCell(infColumnas[3]['m2'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[3]['m2'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div #columna class="agregar-elemento float-left w-100" (click)="annadirModulo(columna, 3, 4)">
                        <i [ngClass]="iconCell(infColumnas[3]['m4'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[3]['m4'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- row arriba -->
                  <div class="row" *ngIf="row3Arriba">
                    <div class="col-md-12">
                      <div #columna class="agregar-elemento w-100" (click)="annadirModulo(columna, 3, 1)">
                        <i [ngClass]="iconCell(infColumnas[3]['m1'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[3]['m1'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- cuadrados arriba -->
                  <div class="row" *ngIf="cuadrados3Arriba">
                    <div class="col-md-6">
                      <div #columna1 class="agregar-elemento" (click)="annadirModulo(columna1, 3, 1)">
                        <i [ngClass]="iconCell(infColumnas[3]['m1'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[3]['m1'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div #columna class="agregar-elemento" (click)="annadirModulo(columna, 3, 2)">
                        <i [ngClass]="iconCell(infColumnas[3]['m2'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[3]['m2'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- row abajo -->
                  <div class="row" *ngIf="row3Abajo">
                    <div class="col-md-12">
                      <div #columna class="agregar-elemento w-100" (click)="annadirModulo(columna, 3, 3)">
                        <i [ngClass]="iconCell(infColumnas[3]['m3'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[3]['m3'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- cuadrados abajo -->
                  <div class="row" *ngIf="cuadrados3Abajo">
                    <div class="col-md-6">
                      <div #columna1 class="agregar-elemento" (click)="annadirModulo(columna1, 3, 3)">
                        <i [ngClass]="iconCell(infColumnas[3]['m3'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[3]['m3'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div #columna class="agregar-elemento" (click)="annadirModulo(columna, 3, 4)">
                        <i [ngClass]="iconCell(infColumnas[3]['m4'].id_tipoGrafico_DAT)"></i>
                        <div>
                          <span>{{infColumnas[3]['m4'].nombre}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>

          </div>

          <!-- BOTON GUARDAR -->
          <!-- <div class="d-flex justify-content-center bd-highlight">
            <button type="button" class="btn btn-success" (click)="onClickGuardar()">{{ 'guardar' | translate}}</button>
          </div> -->
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab title="{{ 'estructuraHome' | translate}}">
      <ng-template kendoTabContent>
        <div class="content">
          <div class="row cont-editor-hmi">

            <!-- ESTRUCTURA HOME -->

            <div class="col-md-4">
              <div class="card-header">
                <h3>

                  {{ 'home' | translate }}
                </h3>

                <!-- BOTON CREAR ESTRUCTURA -->
                <div class="btn-group btn-columnas-hmi">
                  <button type="button" class="btn dropdown-toggle " data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i data-toggle="dropdown" class="icon-columnas"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <!--<div class="">-->
                    <!-- ROW 1 -->
                    <div class="row d-flex justify-content-center bd-highlight">
                      <!-- un col -->
                      <button type="button" class="btn" (click)="crearEstructuraHome(1)">
                        <span class="doscol-icono col-icono-cont">
                          <span class="col-icono"></span>
                        </span>
                      </button>
                      <!-- dos col -->
                      <button type="button" class="btn" (click)="crearEstructuraHome(2)">
                        <span class="doscol-icono col-icono-cont">
                          <span class="col-icono"></span>
                          <span class="col-icono"></span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>

              </div>

              <div class="card-body">

                <!-- AGREGAR ELEMENTOS -->
                <div [ngClass]="{'row': colIzquierdaHome || colDerechaHome}">

                  <!-- col izquierda -->
                  <div #columna class="col-md-6" *ngIf="colIzquierdaHome" (click)="annadirModulo(columna, 1, 1)">
                    <div class="float-left agregar-elemento w-100">
                      <i [ngClass]="iconCell(infHome[1]['m1'].id_tipoGrafico_DAT)"></i>
                      <div>
                        <span>{{infHome[1]['m1'].nombre}}</span>
                      </div>
                    </div>
                  </div>

                  <!-- col derecha -->
                  <div class="col-md-6" *ngIf="colDerechaHome">
                    <div #columna class="float-left agregar-elemento w-100" (click)="annadirModulo(columna, 1, 2)">
                      <i [ngClass]="iconCell(infHome[1]['m2'].id_tipoGrafico_DAT)"></i>
                      <div>
                        <span>{{infHome[1]['m2'].nombre}}</span>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>


          </div>

          <!-- BOTON GUARDAR -->
          <!-- <div class="d-flex justify-content-center bd-highlight">
            <button type="button" class="btn btn-success" (click)="onClickGuardarHome()">{{ 'guardar' | translate}}</button>
          </div> -->
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <!--MANTENIMIENTOS-->
    <kendo-tabstrip-tab title="{{ 'mantenimientos' | translate}}">
      <ng-template kendoTabContent>
        <div class="row" style="margin-left: -10px; margin-right: -10px;">
          <app-maquina-secciones-mantenimientos [id]="id"></app-maquina-secciones-mantenimientos>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <!--MANUALES-->
    <kendo-tabstrip-tab title="{{ 'manuales' | translate}}">
      <ng-template kendoTabContent>
        <div class="row" style="margin-left: -10px; margin-right: -10px;">
          <app-maquina-secciones-manuales [id]="id"></app-maquina-secciones-manuales>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab title="{{ '-' | translate}}" [selected]="false" disabled="true">
      <ng-template kendoTabContent>
        <div class="content">
          <div class="row">
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

  </kendo-tabstrip>
</div>



<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="form-group" style=" margin-top: 20px;">

    <button type="submit" class="btn btn-primary btn-sm mr-1" [disabled]="this.user.instalaciones <2">
      {{ 'guardar' | translate}}
    </button>
    <a class="btn btn-danger btn-sm mr-1" (click)="atras()">
      {{ 'cancelar' | translate}}
    </a>

  </div>

    <!-- POPUP: Isometrico -->
    <ng-template #popupIsometrico let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{ 'isometrico' | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="cont-filtro-maquinas-areas clearfix">
  
          <!-- DIBUJOS MAQUINAS -->
          <div class="isometricos-popup">
  
            <div *ngFor="let maquina of dibujosMaquinas" (click)="selectedDibujoMaquina = maquina; onClickCerrarPopup()"
              class="clickable" [ngClass]="{'actual': maquina==selectedDibujoMaquina}"
              style="padding: 0px; height: 115px;">
              <img style="max-width: 95px; max-height: 95px;" [src]="maquina.imagen">
              <label style="font-size: 12px;">{{ 'dimensiones' | translate }}: {{maquina.dimensiones}}</label>
            </div>
  
          </div>
        </div>
      </div>
      <div class="modal-footer">
      </div>
    </ng-template>

</form>