<!-- Formulario con datos e info del grupo y botones para guardar cambios o volver atrás -->
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row">

    <div class="col-md-6 col-lg-6">
      <div class="card">
        <div class="card-body">

          <h3 class="pt-3">{{ 'datos' | translate}}</h3>

          <div class="form-group ">
            <kendo-label text="{{ 'nombre' | translate}}">
              <div class="caja">
                <kendo-textbox formControlName="nombre" maxlength="50"
                  [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }">
                </kendo-textbox>
                <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                  <div *ngIf="f.nombre.errors.required">{{ 'nombrerequerido' | translate}}</div>
                </div>
              </div>
            </kendo-label>
          </div>
          <div class="form-group form-check p-0">
            <kendo-label for="activo" class="form-check-label" text="{{ 'activo' | translate}}">
            </kendo-label>
            <div class="caja">
              <kendo-switch id="activo" formControlName="activo" [onLabel]="' '" [offLabel]="' '"
                [ngClass]="{ 'is-invalid': submitted && f.activo.errors }"> </kendo-switch>
            </div>
          </div>

        </div>

      </div>


      <div class="card">
        <div class="card-body">

          <button kendoButton [disabled]="loading" class="btn mr-1  btn-primary"
            [disabled]="this.user.diccionarioPerdidas<2">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            {{ 'guardar' | translate}}
          </button>
          <a routerLink="/perdidasGrupos" class="btn mr-1  btn-danger">{{ 'cancelar' | translate}}</a>

        </div>
      </div>
    </div>

    <!-- grid para seleccionar las pérdidas del diccionario a agregar al grupo -->
    <div class="col-md-6 col-lg-6">
      <div class="card">
        <div class="card-body">
          <h3 class="pt-3">{{ 'perdidas' | translate}}</h3>
          <div class="form-row">
            <ng-template #template let-anchor>
              <span>{{ anchor.nativeElement.innerText }}</span>
            </ng-template>


            <kendo-grid [data]="gridData" [selectable]="{enabled: false, drag: false}">

              <!--botoiak--><!--, checkboxOnly: false, drag: true-->
              <!-- <ng-template kendoGridToolbarTemplate position="top">
                  <div class="float-left">
                    <button (click)="checkAllPerdidas()" class="btn btn-success btn-sm mr-1">{{
                      buttonAllPerdidasSelectName }}</button>
                  </div>
                </ng-template> -->


              <kendo-grid-checkbox-column field="seleccionado" title="{{ 'seleccionado' | translate}}" hidden="hidden"
                width="15%">
                <ng-template kendoGridCellTemplate let-dataItem="rowIndex">
                  <input type="checkbox" [kendoGridSelectionCheckbox]="dataItem" enabled
                    (click)="clickCheckBox(dataItem)" />
                </ng-template>
              </kendo-grid-checkbox-column>
              <kendo-grid-column width="85%" field="nombre" title="{{ 'nombre' | translate}}"></kendo-grid-column>

              <kendo-grid-column field="tipoPerdida" title="{{ 'asignarPerdidaA' | translate}}" width="40%">
              </kendo-grid-column>

              <kendo-grid-column field="nombreDiccionarioPerdidasDat" title="{{ 'tipoPerdidas' | translate}}" width="45%">
              </kendo-grid-column>

              <kendo-grid-messages filter="{{'filter' | translate}}"
                filterAfterOperator="{{'filterAfterOperator' | translate}}"
                filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                filterAndLogic="{{'filterAndLogic' | translate}}"
                filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                filterBooleanAll="{{'filterBooleanAll' | translate}}"
                filterClearButton="{{'filterClearButton' | translate}}"
                filterContainsOperator="{{'filterContainsOperator' | translate}}"
                filterDateToday="{{'filterDateToday' | translate}}"
                filterDateToggle="{{'filterDateToggle' | translate}}"
                filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                filterEqOperator="{{'filterEqOperator' | translate}}"
                filterFilterButton="{{'filterFilterButton' | translate}}"
                filterGtOperator="{{'filterGtOperator' | translate}}"
                filterGteOperator="{{'filterGteOperator' | translate}}"
                filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
                filterIsFalse="{{'filterIsFalse' | translate}}"
                filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
                filterIsTrue="{{'filterIsTrue' | translate}}" filterLtOperator="{{'filterLtOperator' | translate}}"
                filterLteOperator="{{'filterLteOperator' | translate}}"
                filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                filterOrLogic="{{'filterOrLogic' | translate}}"
                filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}"
                loading="{{'loading' | translate}}" groupPanelEmpty="{{'groupPanelEmpty' | translate}}"
                lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}" noRecords="{{'norecords' | translate}}"
                pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>
        </div>
      </div>
    </div>
  </div>


</form>