export class Usuario {
  id: number;
  email: string;
  nombreUsuario: string;
  nombre: string;
  apellido1: string;
  apellido2: string;
  actualizado: Date;
  usuarios_TiposId: number;
  numOperario: number;
  idERP: string;
  idDb: number;
  idioma: string;
  tema: number;
  menuExpandido: boolean;
  activo: boolean;
  idMaquinas: string;
  permisoMaquinas: string;
  contraseñaHMI: string;
  coste: number;
  usuarioTipo: string;
  gestionUsuarios: number;
  home: number;
  validador: number;
  proyectos: number;
  proyectosH: number;
  operacionesPredefinidas: number;
  piezasPredefinidas: number;
  seguimiento: number;
  controlDeAsignaciones: number;
  historicoPiezas: number;
  planificador: number;
  vistoLargoMaquina: number;
  vistoLargoTodos: number;
  planificadorCorto: number;
  informes: number;
  analisisDeRendimiento: number;
  comparativaRendimiento: number;
  informeEjecuciones: number;
  informeTecnicoEjecucion: number;
  informeDeConsumo: number;
  oEE: number;
  informeOEE: number;
  informeDisponibilidad: number;
  informeRendimiento: number;
  informeCalidad: number;
  planesDeAccion: number;
  planesDeAccionH: number;
  maquinas: number;
  perdidas: number;
  disponibilidad: number;
  informesIncidencias: number;
  calendarioDisponibilidad: number;
  informeCalculoDisponibilidad: number;
  ficheros: number;
  administracion: number;
  configuracion: number;
  idiomaJavascript: string;
  timeZone: string;
  timeZoneSQL: string;
  idTimezone: number;
  desvioTimezone: number;
  ocultarParte: boolean;
  verColada: boolean;
  verLote: boolean;
  verNSerie: boolean;
  nombreEmpresa: string;
  jwtToken?: string;
  activos: number;
  activosMaestro: number;
  alarmasTipo: number;
  almacenes: number;
  analiticaAvanzadaEjecuciones: number;
  areaProductiva: number;
  asignacionTiempos: number;
  bonos: number;
  clientes: number;
  comparativaTiempos: number;
  consumibles: number;
  controles: number;
  diccionarioPerdidas: number;
  turnos: number;
  fabricantes: number;
  herramientas: number; 
  placas: number;
  historicoMantenimientos: number;
  historicoOperaciones: number;
  historicoProcesos: number;
  historicoParametros: number;
  instalaciones: number;
  mantenimientosPredefinidos: number;
  marcas: number;
  materiales: number;
  operaciones: number;
  operacionesTipo: number;
  piezas: number;
  piezasTipo: number;
  planificadorHerramientas: number;
  planificadorLargo: number;
  planificadorLista : number;
  plantasIsometrico : number;
  profiles : number;
  proveedores : number;
  OperacionesFinalizadas : number;
  rutas : number;
  rutasPredefinidas : number;
  secciones : number;
  stock : number;
  subcontratado : number;
  activosTipos : number;
  tolerancias : number;
  recetas : number;
  usuarios : number;
  calidad : number;
  tipo_baja_consumibles: number;
  permiso_eskola: number;


}
