import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HistoricoMaquinasDatosService, CentroMecanizadoService, MenuService, UsuariosService, MaquinasService, InstalacionesService, InformeOeeService, ConfiguracionService, MantenimientosPredefinidosService, TurnosService, HistoricoMaquinasDatosTemporal2Service } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { MyFunctions, MyFilter, MyCharts } from '@app/_helpers';
import * as d3 from 'd3';
import * as c3 from 'c3';
import { AppComponent } from '../app.component';

@Component({ templateUrl: 'home_tv_v2.component.html' })

export class Hometv_v2Component implements OnInit {

    //#region Parametros de maquinas
    public datosMaquinas: any = [];
    public historicoDatosTemporal: any = [];
    public configuracion: any = {
        tipoPantalla: 1,
        segundosCicloMaquinasTV: 10000,
        verLote: false,
        idDisenoSelected: 1
    };
    public listaMaquinasEnPantalla = [];
    public index = -1;

    public datosCargados: boolean = false;

    public fechaInicio;
    public fechaFin;
    public tipoTurno = new Map();

    public primeraVez = true;

    private timeoutzen;

    public historicoMaquinasDatosCargados = false;
    //#endregion

    public idGrupoActual = -1;
    public interval;

    public loading = false;

    //#region  HOME TV ZAHARRA
    public dataGraficoDonutOEE: any = new Map();
    public dataGraficoDonutDisponibilidad: any = new Map();
    public dataGraficoDonutRendimiento: any = new Map();
    public dataGraficoDonutCalidad: any = new Map();

    public graficoDonutOEE: any = new Map();
    public graficoDonutDisponibilidad: any = new Map();
    public graficoDonutRendimiento: any = new Map();
    public graficoDonutCalidad: any = new Map();

    public segundosFaltaMantenimiento = new Map();
    public faltaMantenimientoST = new Map();
    //#endregion

    constructor(private historicoMaquinasDatosService: HistoricoMaquinasDatosService,
        private historicoMaquinasDatosTemporalService: HistoricoMaquinasDatosTemporal2Service,
        private maquinasService: MaquinasService,
        public router: Router,
        public appComponent: AppComponent,
        private translateService: TranslateService,
        private turnosService: TurnosService,
        private centroMecanizadoService: CentroMecanizadoService,
        private usuariosService: UsuariosService,
        private menuService: MenuService,
        private configuracionService: ConfiguracionService,
        public myFunctions: MyFunctions,
        private mantenimientosPredefinidosService: MantenimientosPredefinidosService,
        private myFilter: MyFilter,
        private myCharts: MyCharts) {

    }

    ngOnInit() {
        this.loading = true;

        //#region Capturar el error de caché y almacenarlo en sessionStorage
        window.addEventListener('error', function (e) {
            if (e.message === 'QuotaExceededError' || e.message === 'Error: Error de caché excedido'
            || e.message === 'Out of Memory' || e.message === 'Código de error: Out of Memory' || e.message === 'Error: Out of Memory' || e.message.toLowerCase().includes('out of memory')) {
                window.location.reload();
            }
        }, true);
        this.refrescarPagina();
        //#endregion

        console.log("HOME: ngOnInit" + this.myFunctions.dateToYYYYMMDDtHHmmSSz(new Date()))

        this.menuService.titulo = this.translateService.instant('home').toUpperCase();

        this.configuracionService.get_configuracion().subscribe(result => {

            //#region CONFIGURACION
            var configuracion: any = result[0];
            this.configuracion.tipoPantalla = configuracion.visorTV;
            this.configuracion.segundosCicloMaquinasTV = configuracion.segundosCicloMaquinasTV * 1000;
            this.configuracion.verLote = configuracion.verLote;
            if (configuracion.idDisenoTv != undefined) {
                this.configuracion.disenoSelected = configuracion.idDisenoTv;
            } else {
                this.configuracion.disenoSelected = 1;
            }
            //#endregion

            this.cargarMaquinas();
            this.cargarTurnoActual();

            // esto es necesario para saber si se ha cambiado de grupo o no
            if (this.idGrupoActual != this.appComponent.selectedGrupo.id) {
                this.idGrupoActual = this.appComponent.selectedGrupo.id;
            }
            this.interval = setInterval(() => {
                if (this.idGrupoActual != this.appComponent.selectedGrupo.id) {
                    if (this.historicoDatosTemporal != undefined) this.prepararInformacion();
                    this.idGrupoActual = this.appComponent.selectedGrupo.id;
                }
                if (!(this.router.url == '/home_tv')) {
                    clearInterval(this.interval);
                }
            }, 1000);
        });
    }

    refrescarPagina() {
        if (this.router.url == '/home' || this.router.url == '/' || this.router.url == '/home_tv')
            setTimeout((d) => this.reload(), (120 * 60) * 1000);
    }

    reload() {
        window.location.reload();
    }

    // public llamadas = 0;
    timeDiff(tiempoEstado, now) {
        // console.log("llamadas: " + this.llamadas++)
        // return new Date();
        return this.myFunctions.secondsTo_HH_MM_SS((new Date().getTime() - new Date(now).getTime()) / 1000 + tiempoEstado);
    }

    //#region Maquina con proceso -------------------------------------------------------------------------------------------------------
    cargarTurnoActual() {
        this.turnosService.Get_Turnos_Maquinas().subscribe(json => {
            var an: any = json;
            an.forEach(element => {
                this.tipoTurno.set(element.idMaquina, element);
            });
        });
    }

    cargarMaquinas() {
        //MAQUINAS
        var maquinas_model = this.maquinasService.get_maquinas_model();
        if (maquinas_model == false) {
            this.maquinasService.get().subscribe((json: any) => {
                console.log("HOME: get maquinas DB luzera: " + json.length)
                this.maquinasService.set_maquinas_model(json);
                this.datosMaquinas = this.maquinasService.get_maquinas_model();
                this.datosMaquinas.forEach(element => {
                    element.indexOperacion = 0;
                });
                console.log("HOME: ha cargado las maquinas" + this.myFunctions.dateToYYYYMMDDtHHmmSSz(new Date()))
                this.cargarHistoricoMaquinasDatos();
                this.prepararDatosVisiblesMaquinas();
                this.cargarDatos();
            })
        } else {
            this.datosMaquinas = maquinas_model;
            console.log("HOME: get maquinas web luzera: " + this.datosMaquinas.length)
            this.datosMaquinas.forEach(element => {
                element.indexOperacion = 0;
            });
            console.log("HOME: ha cargado las maquinas" + this.myFunctions.dateToYYYYMMDDtHHmmSSz(new Date()))
            this.cargarHistoricoMaquinasDatos();
            this.prepararDatosVisiblesMaquinas();
            this.cargarDatos();
        }
    }

    cargarHistoricoMaquinasDatos() {

        if (this.router.url == '/home' || this.router.url == '/' || this.router.url == '/home_tv')
            setTimeout((d) => this.cargarHistoricoMaquinasDatos(), (5 * 60) * 1000);

        var fechas = this.cargarFechas()

        // this.fechaInicio = this.myFunctions.dateToYYYY_MM_DD_guion(fechas.fechaInicio) + "T00:00:00Z";
        // this.fechaFin = this.myFunctions.dateToYYYY_MM_DD_guion(fechas.fechaFin) + "T00:00:00Z";
        this.fechaInicio = fechas.fechaInicio;
        this.fechaFin = fechas.fechaFin;
        var OEE_donut_calculado: any;
        if (!this.historicoMaquinasDatosService.util(this.fechaInicio, this.fechaFin)) {
            this.historicoMaquinasDatosService.cargar_historico_completo(this.fechaInicio, this.fechaFin).subscribe(
                j => {
                    this.historicoMaquinasDatosService.set_historico_datos(j, this.fechaInicio, this.fechaFin);
                    this.historicoMaquinasDatosCargados = true;
                    // this.cargarDatos();
                    this.prepararInformacion();
                    this.cargarDonuts(this.datosMaquinas);
                });
        } else {
            this.historicoMaquinasDatosCargados = true;
            // this.cargarDatos();
            this.prepararInformacion();
            this.cargarDonuts(this.datosMaquinas);
        }
    }

    cargarDatosMantenimientos() {
        if (this.router.url == '/home_tv') {
            setTimeout((d) => this.cargarDatosMantenimientos(), 300000);
        }

        this.mantenimientosPredefinidosService.getTiempoRestanteSiguienteMantenimiento().subscribe((data) => {

            var datosMantenimiento: any = data;
            if (this.segundosFaltaMantenimiento.size == 0) {
                datosMantenimiento.data.forEach(manteni => {
                    if (this.segundosFaltaMantenimiento.has(manteni.idMaquina)) {
                        if (manteni.tiempoRestanteSiguienteMantenimiento == 0) {

                        }
                        else if (this.segundosFaltaMantenimiento.get(manteni.idMaquina) > manteni.tiempoRestanteSiguienteMantenimiento) {
                            this.segundosFaltaMantenimiento.set(manteni.idMaquina, manteni.tiempoRestanteSiguienteMantenimiento);
                        }
                    } else {
                        this.segundosFaltaMantenimiento.set(manteni.idMaquina, manteni.tiempoRestanteSiguienteMantenimiento);
                    }
                });
            }

            this.segundosFaltaMantenimiento.forEach((values, key) => {
                var ahora = new Date();
                // var fechaInicioLocal = new Date(this.horaInicioCalculosLocal.get(maquina.id));
                if (values == 0) {
                    this.faltaMantenimientoST.set(key, this.translateService.instant("annoSinMantener"))
                }
                else if (values > 0) {
                    this.faltaMantenimientoST.set(key, this.myFunctions.secondsTo_HH_MM_SS(Math.abs(values) - (ahora.getTime() /*- fechaInicioLocal.getTime()*/) / 1000).toString())
                } else {
                    this.faltaMantenimientoST.set(key, this.myFunctions.secondsTo_HH_MM_SS(Math.abs(values) + (ahora.getTime() /*- fechaInicioLocal.getTime()*/) / 1000).toString())
                }
            });
        });


    }

    // Esta funcion prepara un array con las maquinas que van a ser visibles en la pantalla
    // para que solo se se vayan rotando
    prepararDatosVisiblesMaquinas() {
        this.listaMaquinasEnPantalla = [];
        var index = 0; // Para controlar en que numero de array vamos
        var numMaquinas = 0; // Para controlar en esa posicion cuantas maquinas hay
        var maxMaquinas = this.configuracion.tipoPantalla == 2 ? 4 : 1;
        this.datosMaquinas.forEach(element => {
            if (this.appComponent.selectedGrupo.idMaquinas.includes(element.id)) {
                if (numMaquinas < maxMaquinas) {
                    numMaquinas++;
                } else {
                    index++;
                    numMaquinas = 1;
                }
                if (this.listaMaquinasEnPantalla[index] == undefined) this.listaMaquinasEnPantalla[index] = [];
                this.listaMaquinasEnPantalla[index].push(element.id);
            }
        });
        this.rotarMaquinas();
        console.log(this.listaMaquinasEnPantalla)
    }
    rotarMaquinas() {
        try {
            if (this.index < (this.listaMaquinasEnPantalla.length - 1)) {
                this.index++;
            } else {
                this.index = 0;
            }
            setTimeout(() => this.cargarDonuts(this.datosMaquinas), 100);
            if ((this.router.url == '/home_tv'))
                setTimeout(() => this.rotarMaquinas(), this.configuracion.segundosCicloMaquinasTV);
        } catch (error) {
            console.log("Error en rotarMaquinas: " + error)
        }

    }

    cargarDatos() {

        console.log("____________________________________________________________________")
        console.log(this.historicoMaquinasDatosTemporalService.timeoutzenHome);

        this.historicoMaquinasDatosTemporalService.actualizarTiempos();

        if (!this.historicoMaquinasDatosTemporalService.util()) {
            // if (!(this.historicoDatosTemporal == undefined)) {
            //     this.historicoDatosTemporal = this.historicoMaquinasDatosTemporalService.get_historico_temporal();
            //     console.log("HOME: preparando los datos" + this.myFunctions.dateToYYYYMMDDtHHmmSSz(new Date()))
            //     this.prepararInformacion();
            // }
            console.log("HOME: haciendo consulta..." + this.myFunctions.dateToYYYYMMDDtHHmmSSz(new Date()))
            this.historicoMaquinasDatosTemporalService.cargar_historico_temporal().subscribe(
                j => {
                    console.log("HOME: consulta realizada" + this.myFunctions.dateToYYYYMMDDtHHmmSSz(new Date()))
                    this.historicoMaquinasDatosTemporalService.set_historico_temporal(j);
                    console.log("HOME: set realizado" + this.myFunctions.dateToYYYYMMDDtHHmmSSz(new Date()))
                    // if (this.historicoDatosTemporal == undefined || this.primeraVez) {
                    this.historicoDatosTemporal = this.historicoMaquinasDatosTemporalService.get_historico_temporal();
                    console.log("HOME: preparando los datos" + this.myFunctions.dateToYYYYMMDDtHHmmSSz(new Date()))
                    this.prepararInformacion();
                    this.primeraVez = false;
                    // }
                });
        } else {
            this.historicoDatosTemporal = this.historicoMaquinasDatosTemporalService.get_historico_temporal();
            console.log("HOME: preparando los datos" + this.myFunctions.dateToYYYYMMDDtHHmmSSz(new Date()))
            this.prepararInformacion();
        }

        if ((this.router.url == '/home_tv') && this.historicoMaquinasDatosTemporalService.timeoutzenHome == undefined)
            this.historicoMaquinasDatosTemporalService.timeoutzenHome = setTimeout((d) => { this.historicoMaquinasDatosTemporalService.timeoutzenHome = undefined; this.cargarDatos(); }, 25000);
    }

    prepararInformacion() {
        this.datosMaquinas.forEach(element => {
            element.primeraCarga = true;
            element.home = this.historicoDatosTemporal.filter(f => f.idMaquina == element.id)[0];
            if (element.home == undefined) {
                console.log("HOME: ez dago daturik kontsultan makina honetan: " + element.id);
                // element.home = this.plantillaBase;
            } else {
                var tipoProceso = 'ejecucion';
                if (element.home.idEstado == 2) tipoProceso = 'parada';
                if (element.home.idEstado == 3) tipoProceso = 'preparacion';
                if (element.home.idEstado == 4) tipoProceso = 'mantenimiento';
                if (element.home.idEstado == 6) tipoProceso = 'alarma';
                if (element.home.idEstado == 8) tipoProceso = 'apagada';
                var docentaje = 0;
                var docentajePieza = 0;
                element.home.datosOperaciones.forEach(element => {
                    docentaje = Math.round(element.porcentajeActualLote * 12 / 100);
                    docentajePieza = Math.round(element.porcentajeActualPieza * 12 / 100);
                    if (element.cantidadReal == 1) docentajePieza = Math.round(Math.abs(element.porcentajePiezaCentral) * 12 / 100);
                    if (docentaje > 12) docentaje = 12;
                    if (docentajePieza > 12) docentajePieza = 12;
                    element.estadoimg = "assets/dcg/dcg" + docentaje + "-" + tipoProceso + ".png";
                    element.estadoimgPieza = "assets/dcg/dcg" + docentajePieza + "-" + tipoProceso + ".png";
                });
            }

            this.procesarInformacionColumnasConfigurables(element.home?.idHomeSeleccionIzq, 1, element.id);
            this.procesarInformacionColumnasConfigurables(element.home?.idHomeSeleccionDer, 2, element.id);

        });
        console.log(this.datosMaquinas)
        this.datosCargados = true;


        if (this.historicoDatosTemporal != undefined && this.historicoDatosTemporal.length != 0) {
            this.loading = false;
            console.log("HOME: datos preparados. FIN" + this.myFunctions.dateToYYYYMMDDtHHmmSSz(new Date()))
        }
    }

    /*
        * int idHomeSeleccion: el id del modulo seleccionado
        * int numColumna: 1=>izq ; 2=>der
        * int idMaquina
    */
    procesarInformacionColumnasConfigurables(idHomeSeleccion, numColumna, idMaquina) {
        var info, fechaini, fechafin, turnos;

        //#region Establecer fechas
        if ([1, 4, 7].includes(idHomeSeleccion)) { // fecha semana 
            var fechas = this.cargarFechas();
            fechaini = fechas.fechaInicio;
            fechafin = fechas.fechaFin;
            turnos = [];
        } else if ([2, 5].includes(idHomeSeleccion)) { // fecha dia
            fechaini = this.myFunctions.getDateNow();
            fechafin = this.myFunctions.getDateNow();
            fechafin.setDate(fechafin.getDate() + 1);

            fechaini.setHours(0, 0, 0);
            fechafin.setHours(0, 0, 0);

            turnos = [];
        } else if ([3, 6].includes(idHomeSeleccion)) { // fecha turno
            var maquinaInf = this.tipoTurno.get(idMaquina);
            if (maquinaInf != undefined) {
                // fechaini = maquinaInf.min;
                // fechafin = maquinaInf.max;

                fechaini = this.myFunctions.getDateNow();
                fechafin = this.myFunctions.getDateNow();
                fechafin.setDate(fechafin.getDate() + 1);

                fechaini.setHours(0, 0, 0);
                fechafin.setHours(0, 0, 0);

                turnos = [maquinaInf.tipoTurno];
            }
        }
        //#endregion

        //#region Establecer datos
        if ([1, 2, 3].includes(idHomeSeleccion)) { // oee
            if (this.historicoMaquinasDatosCargados) info = this.historicoMaquinasDatosService.get_OEE_data_Donut_SIN_filtro([idMaquina], fechaini, fechafin, turnos);
            this.cargarGraficos(idMaquina, info, numColumna, 0);
        } else if ([7].includes(idHomeSeleccion)) { // parametros
            if (this.historicoMaquinasDatosCargados) info = this.datosMaquinas.filter(f => f.id == idMaquina)[0]?.home.info_ejes;
            this.cargarGraficos(idMaquina, info, numColumna, 3)
        } else if ([4, 5, 6].includes(idHomeSeleccion)) { // resumen
            if (this.historicoMaquinasDatosCargados) info = this.historicoMaquinasDatosService.get_resumen_estados_porcentaje([idMaquina], fechaini, fechafin, turnos);
            this.cargarGraficos(idMaquina, info, numColumna, 1);
        }
        //#endregion


    }

    actualizarTiempos() {

        if (this.router.url == '/home' || this.router.url == '/' || this.router.url == '/home_tv')
            setTimeout((d) => this.actualizarTiempos(), 1000);

        if (this.datosCargados)
            this.datosMaquinas.forEach(element => {
                if (element.home != undefined) {
                    element.home.tiempoEstado += 1;
                    element.home.datosOperaciones.forEach(dato => {
                        dato.tiempoActualPieza += 1;
                        dato.tiempoActualLote += 1;
                        dato.tiempoActualTurno += 1;
                    });
                }
            });
    }

    /* TIPO_GRAFICO
        * OEE => 0
        * RESUMEN => 1
        * PARAMETROS => 3
    */
    cargarGraficos(idMaquina, info, col, tipo_grafico) {

        let elemento;
        //#region Cargar datos de OEE
        if (tipo_grafico == 0) {
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_value_" + col] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col2_value_" + col] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col3_value_" + col] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col4_value_" + col] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_datosCargados_" + col] = false;
            if (info != undefined) {

                //#region Esto se utiliza para asegurar que el html este cargado y exista el elemento
                elemento = document.getElementById("argia-col1_" + col + "_" + idMaquina);
                if (elemento != null) {
                    this.estiloGraficosOEE(info, col, idMaquina);
                    this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_datosCargados_" + col] = true;
                } else {
                    // setTimeout(() => {
                    //     this.estiloGraficosOEE(info, col, idMaquina);
                    //     this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_datosCargados_" + col] = true;
                    // }, 1);
                }
                //#endregion

                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_value_" + col] = Math.round(info.OEE_porcentaje);
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col2_value_" + col] = Math.round(info.disponibilidad_porcentaje);
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col3_value_" + col] = Math.round(info.rendimiento_porcentaje);
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col4_value_" + col] = Math.round(info.calidad_porcentaje);

            }

            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_label_" + col] = this.translateService.instant("oee");
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col2_label_" + col] = this.translateService.instant("disp");
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col3_label_" + col] = this.translateService.instant("rend");
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col4_label_" + col] = this.translateService.instant("cal");
        }
        //#endregion

        //#region Cargar resumen semana
        if (tipo_grafico == 1) {
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajePreparacion"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeEjecucion"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeParada"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeMantenimiento"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeAlarma"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeApagado"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeMicroParada"] = 0;

            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPreparacion"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TEjecucion"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TParada"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TMantenimiento"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TAlarma"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TApagado"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TMicroParada"] = 0;
            if (info != undefined) {
                if (info.columns.length > 0) {
                    if (info.columns.filter(f => f[0] == this.translateService.instant("preparacion")).length > 0) {
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajePreparacion"] = info.columns.filter(f => f[0] == this.translateService.instant("preparacion"))[0][1];
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPreparacion"] = info.columns.filter(f => f[0] == this.translateService.instant("preparacion"))[0][2];
                    }

                    if (info.columns.filter(f => f[0] == this.translateService.instant("ejecucion")).length > 0) {
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeEjecucion"] = info.columns.filter(f => f[0] == this.translateService.instant("ejecucion"))[0][1];
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TEjecucion"] = info.columns.filter(f => f[0] == this.translateService.instant("ejecucion"))[0][2];
                    }

                    if (info.columns.filter(f => f[0] == this.translateService.instant("parada")).length > 0) {
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeParada"] = info.columns.filter(f => f[0] == this.translateService.instant("parada"))[0][1];
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TParada"] = info.columns.filter(f => f[0] == this.translateService.instant("parada"))[0][2];
                    }

                    if (info.columns.filter(f => f[0] == this.translateService.instant("mantenimiento")).length > 0) {
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeMantenimiento"] = info.columns.filter(f => f[0] == this.translateService.instant("mantenimiento"))[0][1];
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TMantenimiento"] = info.columns.filter(f => f[0] == this.translateService.instant("mantenimiento"))[0][2];
                    }

                    if (info.columns.filter(f => f[0] == this.translateService.instant("alarma")).length > 0) {
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeAlarma"] = info.columns.filter(f => f[0] == this.translateService.instant("alarma"))[0][1];
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TAlarma"] = info.columns.filter(f => f[0] == this.translateService.instant("alarma"))[0][2];
                    }

                    if (info.columns.filter(f => f[0] == this.translateService.instant("apagada")).length > 0) {
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeApagado"] = info.columns.filter(f => f[0] == this.translateService.instant("apagada"))[0][1];
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TApagado"] = info.columns.filter(f => f[0] == this.translateService.instant("apagada"))[0][2];
                    }

                    if (info.columns.filter(f => f[0] == this.translateService.instant("microparada")).length > 0) {
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeMicroParada"] = info.columns.filter(f => f[0] == this.translateService.instant("microparada"))[0][1];
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TMicroParada"] = info.columns.filter(f => f[0] == this.translateService.instant("microparada"))[0][2];
                    }

                }

                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_datosCargados_" + col] = true;
            }
        }
        //#endregion

        //#region Cargar parametros
        if (tipo_grafico == 3) {
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_value_" + col] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col2_value_" + col] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col3_value_" + col] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col4_value_" + col] = 0;

            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_ensennarGrafico_" + col] = true;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col2_ensennarGrafico_" + col] = true;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col3_ensennarGrafico_" + col] = true;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col4_ensennarGrafico_" + col] = true;
            if (info != undefined) {
                //#region VALORES
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_ensennarGrafico_" + col] = info[0]?.ensennarGrafico;
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col2_ensennarGrafico_" + col] = info[1]?.ensennarGrafico;
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col3_ensennarGrafico_" + col] = info[2]?.ensennarGrafico;
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col4_ensennarGrafico_" + col] = info[3]?.ensennarGrafico;

                if (info[0]?.ensennarGrafico) info.porcentaje1 = (info[0]?.valor - info[0]?.valor_min) * 100 / (info[0]?.valor_max - info[0]?.valor_min);
                else info.porcentaje1 = 100;
                if (info[1]?.ensennarGrafico) info.porcentaje2 = (info[1]?.valor - info[1]?.valor_min) * 100 / (info[1]?.valor_max - info[1]?.valor_min);
                else info.porcentaje2 = 100;
                if (info[2]?.ensennarGrafico) info.porcentaje3 = (info[2]?.valor - info[2]?.valor_min) * 100 / (info[2]?.valor_max - info[2]?.valor_min);
                else info.porcentaje3 = 100;
                if (info[3]?.ensennarGrafico) info.porcentaje4 = (info[3]?.valor - info[3]?.valor_min) * 100 / (info[3]?.valor_max - info[3]?.valor_min);
                else info.porcentaje4 = 100;

                //#region Esto se utiliza para asegurar que el html este cargado y exista el elemento
                elemento = document.getElementById("argia-col1_" + col + "_" + idMaquina);
                if (elemento != null) {
                    this.estiloGraficosParametros(info, col, idMaquina);
                    this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_datosCargados_" + col] = true;
                } else {
                    // setTimeout(() => {
                    //     this.estiloGraficosParametros(info, col, idMaquina);
                    //     this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_datosCargados_" + col] = true;
                    // }, 1);
                }
                //#endregion

                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_value_" + col] = Math.round(info[0].valor);
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col2_value_" + col] = Math.round(info[1].valor);
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col3_value_" + col] = Math.round(info[2].valor);
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col4_value_" + col] = Math.round(info[3].valor);
                //#endregion
                //#region LABEL
                var label1 = info[0]?.nombreEje;
                var label2 = info[1]?.nombreEje;
                var label3 = info[2]?.nombreEje;
                var label4 = info[3]?.nombreEje;
                if (info[0]?.idEje == 0) label1 = this.translateService.instant(info[0]?.variableTipo_nombre);
                if (info[1]?.idEje == 0) label2 = this.translateService.instant(info[1]?.variableTipo_nombre);
                if (info[2]?.idEje == 0) label3 = this.translateService.instant(info[2]?.variableTipo_nombre);
                if (info[3]?.idEje == 0) label4 = this.translateService.instant(info[3]?.variableTipo_nombre);

                if (info[0]?.idEje == undefined) {
                    if (this.translateService.instant(info[0]?.nombreEje).split(" ") > 1)
                        label1 = this.translateService.instant(info[0]?.nombreEje).split(" ")[0][0] + this.translateService.instant(info[0]?.nombreEje).split(" ")[1][0];
                    else if (this.translateService.instant(info[0]?.nombreEje) != undefined)
                        label1 = this.translateService.instant(info[0]?.nombreEje).split(" ")[0][0];
                }
                if (info[1]?.idEje == undefined) {
                    if (this.translateService.instant(info[1]?.nombreEje).split(" ") > 1)
                        label2 = this.translateService.instant(info[1]?.nombreEje).split(" ")[0][0] + this.translateService.instant(info[0]?.nombreEje).split(" ")[1][0];
                    else if (this.translateService.instant(info[1]?.nombreEje) != undefined)
                        label2 = this.translateService.instant(info[1]?.nombreEje).split(" ")[0][0];
                }
                if (info[2]?.idEje == undefined) {
                    if (this.translateService.instant(info[2]?.nombreEje).split(" ") > 1)
                        label3 = this.translateService.instant(info[2]?.nombreEje).split(" ")[0][0] + this.translateService.instant(info[0]?.nombreEje).split(" ")[1][0];
                    else if (this.translateService.instant(info[2]?.nombreEje) != undefined)
                        label3 = this.translateService.instant(info[2]?.nombreEje).split(" ")[0][0];
                }
                if (info[3]?.idEje == undefined) {
                    if (this.translateService.instant(info[3]?.nombreEje).split(" ") > 1)
                        label4 = this.translateService.instant(info[3]?.nombreEje).split(" ")[0][0] + this.translateService.instant(info[0]?.nombreEje).split(" ")[1][0];
                    else if (this.translateService.instant(info[3]?.nombreEje) != undefined)
                        label4 = this.translateService.instant(info[3]?.nombreEje).split(" ")[0][0];
                }
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_label_" + col] = label1;
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col2_label_" + col] = label2;
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col3_label_" + col] = label3;
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col4_label_" + col] = label4;
                //#endregion

                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_datosCargados_" + col] = true;
            }
        }
        //#endregion


    }

    estiloGraficosOEE(info, col, idMaquina) {
        let elemento;
        //#region SEKZIO ARGIA
        elemento = document.getElementById("argia-col1_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, white 79%, transparent 80% 100%),  conic-gradient(transparent 95%, white 0), conic-gradient(#7C8593 "
            + this.myFunctions.calcularPorcentaje(info.OEE_porcentaje, 95, 100, 1, true) + "%, #DCE2EA 0)";
        elemento = document.getElementById("argia-col2_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, white 79%, transparent 80% 100%),  conic-gradient(transparent 95%, white 0), conic-gradient(#7C8593 "
            + this.myFunctions.calcularPorcentaje(info.disponibilidad_porcentaje, 95, 100, 1, true) + "%, #DCE2EA 0)";
        elemento = document.getElementById("argia-col3_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, white 79%, transparent 80% 100%),  conic-gradient(transparent 95%, white 0), conic-gradient(#7C8593 "
            + this.myFunctions.calcularPorcentaje(info.rendimiento_porcentaje, 95, 100, 1, true) + "%, #DCE2EA 0)";
        elemento = document.getElementById("argia-col4_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, white 79%, transparent 80% 100%),  conic-gradient(transparent 95%, white 0), conic-gradient(#7C8593 "
            + this.myFunctions.calcularPorcentaje(info.calidad_porcentaje, 95, 100, 1, true) + "%, #DCE2EA 0)";
        //#endregion

        //#region SEKZIO ILUNA
        elemento = document.getElementById("iluna-col1_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, #3c424e 79%, transparent 80% 100%),  conic-gradient(transparent 95%, #3c424e 0), conic-gradient(#CCCCCC "
            + this.myFunctions.calcularPorcentaje(info.OEE_porcentaje, 95, 100, 1, true) + "%, #888888 0)";

        elemento = document.getElementById("iluna-col2_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, #3c424e 79%, transparent 80% 100%),  conic-gradient(transparent 95%, #3c424e 0), conic-gradient(#CCCCCC "
            + this.myFunctions.calcularPorcentaje(info.disponibilidad_porcentaje, 95, 100, 1, true) + "%, #888888 0)";
        elemento = document.getElementById("iluna-col3_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, #3c424e 79%, transparent 80% 100%),  conic-gradient(transparent 95%, #3c424e 0), conic-gradient(#CCCCCC "
            + this.myFunctions.calcularPorcentaje(info.rendimiento_porcentaje, 95, 100, 1, true) + "%, #888888 0)";
        elemento = document.getElementById("iluna-col4_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, #3c424e 79%, transparent 80% 100%),  conic-gradient(transparent 95%, #3c424e 0), conic-gradient(#CCCCCC "
            + this.myFunctions.calcularPorcentaje(info.calidad_porcentaje, 95, 100, 1, true) + "%, #888888 0)";
        //#endregion

    }

    estiloGraficosParametros(info, col, idMaquina) {
        let elemento;
        //#region SEKZIO ARGIA
        elemento = document.getElementById("argia-col1_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, white 79%, transparent 80% 100%),  conic-gradient(transparent 95%, white 0), conic-gradient(#7C8593 "
            + this.myFunctions.calcularPorcentaje(info.porcentaje1, 95, 100, 1, true) + "%, #DCE2EA 0)";
        elemento = document.getElementById("argia-col2_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, white 79%, transparent 80% 100%),  conic-gradient(transparent 95%, white 0), conic-gradient(#7C8593 "
            + this.myFunctions.calcularPorcentaje(info.porcentaje2, 95, 100, 1, true) + "%, #DCE2EA 0)";
        elemento = document.getElementById("argia-col3_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, white 79%, transparent 80% 100%),  conic-gradient(transparent 95%, white 0), conic-gradient(#7C8593 "
            + this.myFunctions.calcularPorcentaje(info.porcentaje3, 95, 100, 1, true) + "%, #DCE2EA 0)";
        elemento = document.getElementById("argia-col4_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, white 79%, transparent 80% 100%),  conic-gradient(transparent 95%, white 0), conic-gradient(#7C8593 "
            + this.myFunctions.calcularPorcentaje(info.porcentaje4, 95, 100, 1, true) + "%, #DCE2EA 0)";
        //#endregion

        //#region SEKZIO ILUNA
        elemento = document.getElementById("iluna-col1_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, #3c424e 79%, transparent 80% 100%),  conic-gradient(transparent 95%, #3c424e 0), conic-gradient(#CCCCCC "
            + this.myFunctions.calcularPorcentaje(info.porcentaje1, 95, 100, 1, true) + "%, #888888 0)";

        elemento = document.getElementById("iluna-col2_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, #3c424e 79%, transparent 80% 100%),  conic-gradient(transparent 95%, #3c424e 0), conic-gradient(#CCCCCC "
            + this.myFunctions.calcularPorcentaje(info.porcentaje2, 95, 100, 1, true) + "%, #888888 0)";
        elemento = document.getElementById("iluna-col3_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, #3c424e 79%, transparent 80% 100%),  conic-gradient(transparent 95%, #3c424e 0), conic-gradient(#CCCCCC "
            + this.myFunctions.calcularPorcentaje(info.porcentaje3, 95, 100, 1, true) + "%, #888888 0)";
        elemento = document.getElementById("iluna-col4_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, #3c424e 79%, transparent 80% 100%),  conic-gradient(transparent 95%, #3c424e 0), conic-gradient(#CCCCCC "
            + this.myFunctions.calcularPorcentaje(info.porcentaje4, 95, 100, 1, true) + "%, #888888 0)";
        //#endregion

    }

    private cargarFechas() {
        var fechaIni, fechaInicio, fechaFin;

        //calcular fecha inicio
        fechaInicio = this.myFunctions.getDateNow();
        fechaIni = fechaInicio
        if (fechaIni.getDay() == 3) {
            fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 2));
        } else if (fechaIni.getDay() == 4) {
            fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 3));
        } else if (fechaIni.getDay() == 5) {
            fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 4));
        } else if (fechaIni.getDay() == 6) {
            fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 5));
        } else if (fechaIni.getDay() == 0) {
            fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 6));
        } else if (fechaIni.getDay() == 2) {
            fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 1));
        }

        //calcular fecha fin
        fechaIni = new Date(fechaInicio);
        fechaFin = new Date(fechaIni.setDate(fechaIni.getDate() + 7));

        //calcular horas
        fechaInicio.setHours(0, 0, 0);
        fechaFin.setHours(0, 0, 0);

        return { fechaInicio: fechaInicio, fechaFin: fechaFin }
    }
    //#endregion ------------------------------------------------------------------------------------------------------------------------


    //#region HOME TV ZAHARRA
    cargarDonuts(dataMaquinas: any) {
        //GRAFICO DONUT OEE
        var anchuraGraficos = 0;
        if (this.configuracion.tipoPantalla == 1) {
            anchuraGraficos = 25
        } else if (this.configuracion.tipoPantalla == 2) {
            anchuraGraficos = 15;
        }
        if (dataMaquinas != undefined) {
            // cargar vacios
            this.dataGraficoDonutOEE.clear();
            this.dataGraficoDonutDisponibilidad.clear();
            this.dataGraficoDonutRendimiento.clear();
            this.dataGraficoDonutCalidad.clear();
            dataMaquinas.forEach(maquina => {
                //console.log(maquina)
                if (maquina != undefined) {
                    this.dataGraficoDonutOEE.set(maquina.id, [['completo', 0], ['nocompleto', 100]]);
                    this.dataGraficoDonutDisponibilidad.set(maquina.id, [['completo', 0], ['nocompleto', 100]]);
                    this.dataGraficoDonutRendimiento.set(maquina.id, [['completo', 0], ['nocompleto', 100]]);
                    this.dataGraficoDonutCalidad.set(maquina.id, [['completo', 0], ['nocompleto', 100]]);

                    //GRAFICO DONUT OEE
                    this.graficoDonutOEE.set(maquina.id, c3.generate({
                        bindto: '#graficoDonutOEE_OEE_' + maquina.id,
                        data: {
                            columns: this.dataGraficoDonutOEE.get(maquina.id),
                            type: 'donut',
                            colors: {
                                completo: '#18d6b0',
                                nocompleto: '#3d6063'
                            },
                            order: 'null'
                        },
                        transition: {
                            duration: 2000
                        },
                        donut: {
                            title: "0",
                            width: anchuraGraficos,
                            label: { show: false }
                        },
                        legend: {
                            show: false
                        },
                        tooltip: {
                            show: false
                        }
                    }));

                    //GRAFICO DONUT DISPO
                    this.graficoDonutDisponibilidad.set(maquina.id, c3.generate({
                        bindto: '#graficoDonutDisponibilidad_OEE_' + maquina.id,
                        data: {
                            columns: this.dataGraficoDonutDisponibilidad.get(maquina.id),
                            type: 'donut',
                            colors: {
                                completo: '#18d6b0',
                                nocompleto: '#3d6063'
                            },
                            order: 'null'
                        },
                        transition: {
                            duration: 2000
                        },
                        donut: {
                            title: "0",
                            width: anchuraGraficos,
                            label: { show: false }
                        },
                        legend: {
                            show: false
                        },
                        tooltip: {
                            show: false
                        }
                    }));

                    //GRAFICO DONUT REND
                    this.graficoDonutRendimiento.set(maquina.id, c3.generate({
                        bindto: '#graficoDonutRendimiento_OEE_' + maquina.id,
                        data: {
                            columns: this.dataGraficoDonutRendimiento.get(maquina.id),
                            type: 'donut',
                            colors: {
                                completo: '#18d6b0',
                                nocompleto: '#3d6063'
                            },
                            order: 'null'
                        },
                        transition: {
                            duration: 2000
                        },
                        donut: {
                            title: "0",
                            width: anchuraGraficos,
                            label: { show: false }
                        },
                        legend: {
                            show: false
                        },
                        tooltip: {
                            show: false
                        }
                    }));

                    //GRAFICO DONUT CAL
                    this.graficoDonutCalidad.set(maquina.id, c3.generate({
                        bindto: '#graficoDonutCalidad_OEE_' + maquina.id,
                        data: {
                            columns: this.dataGraficoDonutCalidad.get(maquina.id),
                            type: 'donut',
                            colors: {
                                completo: '#18d6b0',
                                nocompleto: '#3d6063'
                            },
                            order: 'null'
                        },
                        transition: {
                            duration: 2000
                        },
                        donut: {
                            title: "0",
                            width: anchuraGraficos,
                            label: { show: false }
                        },
                        legend: {
                            show: false
                        },
                        tooltip: {
                            show: false
                        }
                    }));

                    d3.select('#graficoDonutOEE_OEE_' + maquina.id + ' .c3-chart-arcs-title')
                        .text("")
                        .style("font-size", "28px")
                        .attr("fill", "#18d6b0");
                    d3.select('#graficoDonutDisponibilidad_OEE_' + maquina.id + ' .c3-chart-arcs-title')
                        .text("")
                        .style("font-size", "28px")
                        .attr("fill", "#18d6b0");
                    d3.select('#graficoDonutRendimiento_OEE_' + maquina.id + ' .c3-chart-arcs-title')
                        .text("")
                        .style("font-size", "28px")
                        .attr("fill", "#18d6b0");
                    d3.select('#graficoDonutCalidad_OEE_' + maquina.id + ' .c3-chart-arcs-title')
                        .text("")
                        .style("font-size", "28px")
                        .attr("fill", "#18d6b0");

                    d3.selectAll("#graficoDonutOEE_OEE_" + maquina.id + " .c3-chart-arcs path").style("stroke-width", "0px");
                    d3.selectAll("#graficoDonutDisponibilidad_OEE_" + maquina.id + " .c3-chart-arcs path").style("stroke-width", "0px");
                    d3.selectAll("#graficoDonutRendimiento_OEE_" + maquina.id + " .c3-chart-arcs path").style("stroke-width", "0px");
                    d3.selectAll("#graficoDonutCalidad_OEE_" + maquina.id + " .c3-chart-arcs path").style("stroke-width", "0px");
                }

            });

            // cargar datos
            this.cargarGraficoBarrasYDonuts(dataMaquinas);
        }
    }
    cargarGraficoBarrasYDonuts(dataMaquinas: any) {

        //calcular fecha inicio
        var fechaInicio: Date, fechaIniLag: Date
        var fechaFin: Date
        if (fechaInicio == undefined) {
            fechaInicio = this.myFunctions.getDateNow()
        }
        fechaIniLag = fechaInicio
        if (fechaIniLag.getDay() == 3) {
            fechaInicio = new Date(fechaIniLag.setDate(fechaIniLag.getDate() - 2));
        } else if (fechaIniLag.getDay() == 4) {
            fechaInicio = new Date(fechaIniLag.setDate(fechaIniLag.getDate() - 3));
        } else if (fechaIniLag.getDay() == 5) {
            fechaInicio = new Date(fechaIniLag.setDate(fechaIniLag.getDate() - 4));
        } else if (fechaIniLag.getDay() == 6) {
            fechaInicio = new Date(fechaIniLag.setDate(fechaIniLag.getDate() - 5));
        } else if (fechaIniLag.getDay() == 0) {
            fechaInicio = new Date(fechaIniLag.setDate(fechaIniLag.getDate() - 6));
        } else if (fechaIniLag.getDay() == 2) {
            fechaInicio = new Date(fechaIniLag.setDate(fechaIniLag.getDate() - 1));
        }

        //calcular fecha fin
        fechaIniLag = new Date(fechaInicio);
        fechaFin = new Date(fechaIniLag.setDate(fechaIniLag.getDate() + 6));

        //calcular horas
        fechaInicio.setHours(0, 0, 0);
        fechaFin.setHours(0, 0, 0);


        if (dataMaquinas != undefined || dataMaquinas.length > 0) {
            dataMaquinas.forEach(maquina => {
                try {
                    // Como en esta pagina no hay un filtro nuevo, se apaña creando la misma estructura desde atras.
                    var filtro = this.myFilter.crearFiltro_rapido_AnalisisRendimiento([fechaInicio, fechaFin], [], maquina.id)
                    // Se combierte en filtro Kendo para usar sobre JSON
                    var filtro_kendo: any = this.myFilter.filtroToKendo(filtro)
                    var historico_completo_filtrado = this.historicoMaquinasDatosService.get_historicoMauqinas_filtrado(filtro_kendo);

                    // DONUTS
                    var OEE_donut_calculado: any = this.historicoMaquinasDatosService.get_OEE_data_Donut_prefiltrado(historico_completo_filtrado);

                    d3.select('#graficoDonutOEE_OEE_' + maquina.id + ' .c3-chart-arcs-title').style("font-size", "0px").style("opacity", "0").style("font-size", "28px").style("opacity", "1")
                        .text(Math.round(OEE_donut_calculado.OEE_porcentaje) + "%");
                    d3.select('#graficoDonutDisponibilidad_OEE_' + maquina.id + ' .c3-chart-arcs-title').style("font-size", "0px").style("opacity", "0").style("font-size", "28px").style("opacity", "1")
                        .text(Math.round(OEE_donut_calculado.disponibilidad_porcentaje) + "%");
                    d3.select('#graficoDonutRendimiento_OEE_' + maquina.id + ' .c3-chart-arcs-title').style("font-size", "0px").style("opacity", "0").style("font-size", "28px").style("opacity", "1")
                        .text(Math.round(OEE_donut_calculado.rendimiento_porcentaje) + "%");
                    d3.select('#graficoDonutCalidad_OEE_' + maquina.id + ' .c3-chart-arcs-title').style("font-size", "0px").style("opacity", "0").style("font-size", "28px").style("opacity", "1")
                        .text(Math.round(OEE_donut_calculado.calidad_porcentaje) + "%");

                    this.myCharts.load(this.graficoDonutOEE.get(maquina.id), OEE_donut_calculado.OEE);
                    this.myCharts.load(this.graficoDonutDisponibilidad.get(maquina.id), OEE_donut_calculado.disponibilidad);
                    this.myCharts.load(this.graficoDonutRendimiento.get(maquina.id), OEE_donut_calculado.rendimiento);
                    this.myCharts.load(this.graficoDonutCalidad.get(maquina.id), OEE_donut_calculado.calidad);

                } catch (error) {

                }
            });
        }

    }
    //#endregion
}
