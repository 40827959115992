<style>
  :host /deep/ .k-grid tbody td {
    white-space: nowrap;
    line-height: 20px;
    padding: 4px 12px;
  }

  :host /deep/ .k-grid .k-grid-content td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :host /deep/ .k-grid tr.rojo {
    background-color: #ff9d9d;
  }

    :host /deep/ .k-grid tr.rojo:hover {
      background-color: #ff8989;
    }

    :host /deep/ .k-grid tr.rojo.k-state-selected td {
      background-color: #ff7676;
    }
</style>

<!-- TODO -->
<div class="historico historicooperaciones">
  <!-- INFO-->  
  <div class="clearfix">

    <!-- OF Y CLIENTE-->
    <div class="float-left mr-2">
      <div class="card">
        <div class="card-body">
          <kendo-label text="{{infoOF}}"></kendo-label>
          <div class="cliente-historicoOperaciones"><kendo-label text="{{infoCliente}}"></kendo-label></div>
        </div>
      </div>
    </div>

    <!-- RECETA -->
    <div class="float-left mr-2" *ngIf="tipo_maquina==5">
      <div class="card">
        <div class="card-body">
          <kendo-label text="{{ 'receta' | translate}}"></kendo-label>
          <div class="plano-historicoOperaciones"><kendo-label text="{{infoPlano}}"></kendo-label></div>
        </div>
      </div>
    </div>

    <!-- REF PIEZA -->
    <div class="float-left mr-2">
      <div class="card">
        <div class="card-body">
          <div><kendo-label text="{{ 'refpieza' | translate}}"><span class="refPieza-historicoOperaciones"> {{infoRefPieza}}</span></kendo-label></div>
          <kendo-label class="refPieza-historicoOperaciones-label" text=""></kendo-label>
        </div>
      </div>
    </div>

    <!-- CANTIDAD -->
    <div class="float-left mr-2">
      <div class="card">
        <div class="card-body">
          <kendo-label text="{{ 'cantidad' | translate}}"></kendo-label>
          <div class="cantidad-historicoOperaciones"><kendo-label text="{{ cantidadAcumulada }} / {{cantidadLote }}"></kendo-label></div>
        </div>
      </div>
    </div>

    <!-- TIEMPO EST EJEC PREP -->
    <div class="float-left mr-2">
      <div class="card">
        <div class="card-body">

          <div class="float-left mr-1">
            <kendo-label text="{{ 'tEstimado' | translate}}"></kendo-label>
            <div class="tEstimado-historicoOperaciones"><kendo-label text="{{infoTiempoEstimado}}"></kendo-label></div>
          </div>

          <div class="float-left mr-1">
            <kendo-label text="{{ 'tEjecucion' | translate}}"></kendo-label>
            <div class="tEjecucion-historicoOperaciones"><kendo-label text="{{infoTiempoRealEjecucion}}"></kendo-label></div>
          </div>

          <div class="float-left mr-1">
            <kendo-label text="{{ 'tPreparacion' | translate}}"></kendo-label>
            <div class="tPreparacion-historicoOperaciones"><kendo-label text="{{infoTiempoRealPreparacion}}"></kendo-label></div>
          </div>
        </div>
      </div>
    </div>

    <!-- DESVIO -->
    <div class="float-left mr-2">
      <div class="card">
        <div class="card-body">
          <kendo-label text="{{ 'desvio' | translate}}"></kendo-label>
          <div class="desvio-historicoOperaciones">
            <div class="float-left mr-2">
              <div class="desvioTiempo-historicoOperaciones"><kendo-label text="{{infoDesvio}}"></kendo-label></div>
            </div>
            <div class="float-left mr-2">
              <div class="desvioPorcen-historicoOperaciones"><kendo-label text="{{infoPorcenDesvio}}"></kendo-label></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ESTADO -->
    <div class="float-left mr-2">
      <div class="card">
        <div class="card-body">
          <kendo-label text="{{ 'estado' | translate}}"></kendo-label>
          <div *ngIf="idEstado==1" class="estado-historicoOperaciones">
            <kendo-label text="{{ 'enProceso' | translate}}"></kendo-label>
          </div>
          <div *ngIf="idEstado==2" class="estado-historicoOperaciones">
            <kendo-label text="{{ 'acabada' | translate}}"></kendo-label>
          </div>
          <div *ngIf="idEstado==3" class="estado-historicoOperaciones">
            <kendo-label text="{{ 'validada' | translate}}"></kendo-label>
          </div>
          <div *ngIf="idEstado==4" class="estado-historicoOperaciones">
            <kendo-label text="{{ 'apartada' | translate}}"></kendo-label>
          </div>
          <div *ngIf="idEstado==5" class="estado-historicoOperaciones">
            <kendo-label text="{{ 'chatarra' | translate}}"></kendo-label>
          </div>
        </div>
      </div>
    </div>

    <!-- BOTONES -->
    <div class="float-right mr-2">
      <div class="card">
        <div class="card-body p-0">
          <div class="botones-pareja-historico clearfix">
            <button type="button" class="btn btn-success" (click)="btnValidar()">
              {{ 'validar' | translate }}
            </button>
            <button type="button" class="btn btn-primary" (click)="btnFinalizar()">
              {{ 'finalizar' | translate }}
            </button>
          </div>
          <div>
            <button type="button" class="btn btn-info m-1 float-left" (click)="btnAnaliticaAvanzada()">
              {{ 'analiticaAvanzadaEjecuciones' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>

  <ng-template #template let-anchor>
    <span>{{ anchor.nativeElement.innerText }}</span>
  </ng-template>
  <!-- GRID -->
  <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
       (mouseover)="showGridTooltip($event)">

    <!-- GRID -->
    <kendo-grid [kendoGridBinding]="JOperacionesView"
                kendoGridSelectBy="idHo"
                [selectedKeys]="operacionesSelecteds"
                (cellClick)="cellClick($event)"
                [navigable]="true"
                [sortable]="true"
                scrollable="virtual"
                [rowHeight]="28"
                [height]="500"
                [resizable]="true"
                [pageable]="true"
                [pageSize]="pageSize"
                [skip]="skip"
                class="grid-font-10"
                (pageChange)="pageChange($event)"
                [rowClass]="rowCallback">
      <kendo-grid-checkbox-column showSelectAll="true" width="4%"></kendo-grid-checkbox-column>
      <kendo-grid-column field="operacion" title="{{ 'tarea' | translate }}" width="6%"></kendo-grid-column>
      <kendo-grid-column field="cantidad" title="{{ 'nPiezas' | translate }}" width="6%"></kendo-grid-column>
      <kendo-grid-column field="fechaini" title="{{ 'fechainicio' | translate }}" width="10%" class="celda-tooltip-externo">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="tooltip-consumibles-contenido" *ngIf="dataItem.fechaini != null">
            <label>
              {{myFunctions.dateWithoutYearShorted(dataItem.fechaini)}}
              {{dataItem.fechaini | kendoDate:'HH:mm' }}
            </label>
            <span class="tooltiptext">
              {{dataItem.fechaini | kendoDate}} {{dataItem.fechaini | kendoDate:'HH:mm'}}
            </span>
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="fechafin" title="{{ 'fechafin' | translate }}" width="10%"
                         class="celda-tooltip-externo">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="tooltip-consumibles-contenido" *ngIf="dataItem.fechafin != null">
            <label>
              {{myFunctions.dateWithoutYearShorted(dataItem.fechafin)}}
              {{dataItem.fechafin | kendoDate:'HH:mm' }}
            </label>
            <span class="tooltiptext">
              {{dataItem.fechafin | kendoDate}} {{dataItem.fechafin | kendoDate:'HH:mm'}}
            </span>
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="tiempoEstimadoTota" title="{{ 'tEstimado' | translate }}" width="6%" [style]="{'text-align': 'right'}">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{myFunctions.secondsTo_HH_MM(dataItem.tiempoEstimadoTota)}}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="tiempoSoloEjecucion" title="{{ 'tEjecucion' | translate }}" width="6%"
                         [style]="{'text-align': 'right'}">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [hidden]="!dataItem.tieneHistorico">{{myFunctions.secondsTo_HH_MM(dataItem.tiempoSoloEjecucion)}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="tiempoRealPreparacion" title="{{ 'tPreparacion' | translate }}" width="6%"
                         [style]="{'text-align': 'right'}">
        <!-- NO ENSEÑAR PARA GAMESA -->
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [hidden]="!dataItem.tieneHistorico">{{myFunctions.secondsTo_HH_MM(dataItem.tiempoRealPreparacion)}}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="porcenDesvio" title="{{ 'desvioPorcentaje' | translate }}" width="6%"
                         [style]="{'text-align': 'right'}">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [hidden]="!dataItem.tieneHistorico">{{dataItem.porcenDesvio}} %</span>
        </ng-template>
      </kendo-grid-column>

      <!-- si es induccion -->
      <kendo-grid-column *ngIf="tipo_maquina==5" field="numeroPlano" title="{{ 'receta' | translate }}" width="6%"></kendo-grid-column>
      <!-- si es inyectora -->
      <kendo-grid-column *ngIf="tipo_maquina==4" field="tolerancia" title="{{ 'tolerancia' | translate }}" width="6%" class="celda-tooltip-externo-header p-0">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="celda-completa text-center" [ngClass]="{'bg-rojo': dataItem.tolerancia == false,
            'bg-verde': dataItem.tolerancia == true}">
            <span class="icon-equis" *ngIf="dataItem.tolerancia == false"></span>
            <span class="icon-check" *ngIf="dataItem.tolerancia == true"></span>
          </span>
        </ng-template> 
      </kendo-grid-column>
      <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
      filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
      filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
      filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
      filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
      filterContainsOperator="{{'filterContainsOperator' | translate}}"
      filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
      filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
      filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
      filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
      filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
      filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
      filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
      filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
      filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
      filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
      filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
      filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
      filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
      filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
      groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
      noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
    </kendo-grid>
  </div>

  <div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;"></div>
</div>

<!-- POPUP -->
<ng-template #popup let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{tituloModal}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <label>{{ 'observacion' | translate }}</label>
        <textarea kendoTextArea [(value)]="Jobservacion"></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')"> {{ 'cancelar' | translate }} </button>
    <button type="button" class="btn btn-primary" (click)="btnPopupAceptar()" [disabled]="this.user.historicoOperaciones < 2">{{ 'aceptar' | translate }}</button>
  </div>
</ng-template>
<!-- POPUP -->
<ng-template #popupRutas let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <kendo-grid [kendoGridBinding]="rutasOperaciones">
          <kendo-grid-column field="ruta" title="{{ 'ruta' | translate }}" width="100%"></kendo-grid-column>
          <div *kendoGridDetailTemplate="let dataItem">
            <kendo-grid [data]="dataItem.dtOperaciones" kendoGridSelectBy="id" [selectedKeys]="rutasOperacionesSelected"
                        [selectable]="{ mode:'single'}">
              <kendo-grid-checkbox-column showSelectAll="true" width="7%"></kendo-grid-checkbox-column>
              <kendo-grid-column field="orden" title="{{ 'orden' | translate }}" width="5%"></kendo-grid-column>
              <kendo-grid-column field="lado" title="{{ 'lado' | translate }}" width="5%"></kendo-grid-column>
              <kendo-grid-column field="suborden" title="{{ 'suborden' | translate }}" width="5%"></kendo-grid-column>
              <kendo-grid-column field="operacion" title="{{ 'operacion' | translate }}" width="78%">
              </kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>
          <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
          filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
          filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
          filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
          filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
          filterContainsOperator="{{'filterContainsOperator' | translate}}"
          filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
          filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
          filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
          filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
          filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
          filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
          filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
          filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
          filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
          filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
          filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
          filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
          filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
          filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
          groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
          noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
        </kendo-grid>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')"> {{ 'cancelar' | translate }} </button>
    <button type="button" class="btn btn-primary" (click)="btnPopupAceptarRuta()" [disabled]="this.user.historicoOperaciones < 2">{{ 'aceptar' | translate }}</button>
  </div>
</ng-template>
<!--POPUP MARCAR COMO ESTANDAR (GAMESA)-->
<ng-template #contentMarcarComoEstandar let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'preguntamarcarcomoestandarpopup' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')"> {{ 'no' | translate }} </button>
    <button type="button" class="btn btn-danger" (click)="cambiarTiemposEstimados()" [disabled]="this.user.historicoOperaciones < 2">{{ 'si' | translate }}</button>
  </div>
</ng-template>
<!--NO HAY OPERACIONES PARA MARCAR COMO ESTANDAR (GAMESA)-->
<ng-template #contentNoHayOperacionesMarcarComoEstandar let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'nohayoperacionesparamarcarcomoestandar' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="modal.dismiss('cancel click')"> {{ 'aceptar' | translate }} </button>
  </div>
</ng-template>
<ng-template #popupAnalitica>
  <div class="content">
    <div class="dropdown-menu dropdown-usuario show">
      <div class="cont-linea">
        <div class="inner-usuario-logout"><a class="btn btn-success w-100" (click)="clickAnalitica(-1)">{{ 'analiticaAvanzadaEjecuciones' | translate }}</a></div>
      </div>
      <div class="cont-linea">
        <div class="inner-usuario-logout"><a class="btn btn-success w-100" (click)="clickTemplado()">{{ 'informeTemplado' | translate }}</a></div>
      </div>
    </div>
  </div>
</ng-template>
