import { Component } from '@angular/core';
import { Router } from "@angular/router"
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService, ClasificacionesService, MenuService } from '../_services';
import { ActivatedRoute } from '@angular/router';
import { MyFunctions } from '@app/_helpers';
import { SelectableSettings } from '@progress/kendo-angular-treelist';

@Component({
  selector: 'app-clasificaciones',
  templateUrl: './clasificaciones.component.html',
})

export class ClasificacionesComponent {

  //VARIABLES BASICAS
  private translate: TranslateService;

  public settings: SelectableSettings = {
    mode: "row",
    multiple: true,
    drag: false,
    enabled: true,
    readonly: false
  };

  //GRID: clasificaciones
  public listaClasificaciones: any[] = [];
  public dataGrid: any;

  //Seleccion
  public seleccionados: any[] = [];

  //Constructor
  constructor(
    translate: TranslateService,
    private menuService: MenuService, public router: Router,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private alertService: AlertService,
    public route: ActivatedRoute,
    private clasificacionesService: ClasificacionesService,
    public myFunctions: MyFunctions) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('clasificaciones').toUpperCase();
  }

  /**
   * Funcion de ngOnInit, carga las clasificaciones de la DB
   */
  ngOnInit() {
    this.clasificacionesService.GetAll().subscribe(
      (result) => {
        this.dataGrid = result;
      });
  }

  /**
   * Al clicar en el boton Editar, gestiona errores y 
   * navega a clasificaciones/editar
   */
  onClick_Editar(event) {
    // if (this.seleccionados.length > 1) {
    //   this.alertService.error(this.translateService.instant('multiplesclasificaciones'), { keepAfterRouteChange: true });
    // } else if (this.seleccionados.length == 0) {
    //   this.alertService.warn(this.translateService.instant('clasificacionesnoseleccionada'), { keepAfterRouteChange: true });
    // } else {
      this.router.navigate(['clasificaciones/editar/', event.dataItem.id]);
    // }
  }

  onClick_Nuevo() {
    this.router.navigate(['clasificaciones/editar/0']);
  }

  /**
   * Al clicar el boton Eliminar, muestra el modal
   * de confirmacion de eliminar
   * @param eliminar modal de eliminar
   */
  onClick_Elimnar(eliminar) {
    if (this.seleccionados.length == 0) {
      this.alertService.warn(this.translateService.instant('clasificacionesnoseleccionada'), { keepAfterRouteChange: true });
    } else {
      this.modalService.open(eliminar, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  /**
   * Se llama al confirmar eliminacion de las clasificaciones seleccionadas 
   * en el modal de eliminar. Elimina las clasificaciones y obtiene las clasificaciones
   * actualizadas refrescando la fuente de datos del grid
   */
  eliminarClasificaciones() {
    var selected = []
    this.seleccionados.forEach(element => {
      selected.push(element.itemKey)
    })
    this.clasificacionesService.EliminarClasificaciones(selected).subscribe(
      (result) => {
        console.log("clasificaciones eliminadas");
        this.modalService.dismissAll();
        this.alertService.success(this.translateService.instant('clasificacioneliminada'), { keepAfterRouteChange: true });
        this.listaClasificaciones = [];
        this.clasificacionesService.GetAll().subscribe(
          (result) => {
            this.dataGrid = result;
          });
      });
  }
}
