import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/alarmastipo`;

@Injectable()
export class AlarmasTipoService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<any[]>(baseUrl);
  }

  getById(id: string) {
    return this.http.get<any>(`${baseUrl}/${id}`);
  }

  create(id: number, nombre: string) {
    return this.http.post<JSON>(`${baseUrl}/create`, { id, nombre }, { withCredentials: true });
  }

  update(id: number, nombre: string) {
    return this.http.post<JSON>(`${baseUrl}/update`, { id, nombre }, { withCredentials: true });
  }

  delete(id: number) {
    return this.http.post<JSON>(`${baseUrl}/delete`, { id }, { withCredentials: true });
  }

}
