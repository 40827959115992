import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AlertService, MenuService, Eskola_CursosLectivosService, UsuariosService, OperariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-cursoslectivos',
  templateUrl: './eskola_cursoslectivos.component.html'
  })
export class Eskola_CursosLectivosComponent implements OnInit{

  form: FormGroup;
  dataCursosLectivos: any;
  mySelection: number[] = [];
  navigationSubscription;
  isDeleting = false;
  public cursos: any = [];
  public dateAux: any = [];


  modalReference: NgbModalRef;
  user = this.userService.userValue;

  constructor(private cursosLectivosService: Eskola_CursosLectivosService,
    private alertService: AlertService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private userService: UsuariosService,
    private modalService: NgbModal) {

    this.menuService.titulo = this.translateService.instant('cursoslectivos').toUpperCase();

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/eskola_cursoslectivos') {
          this.cargarDatos();
        }
      }
    });
  
  }


  cargarDatos() {
    this.cursosLectivosService.getAll().subscribe((result) => {
      this.dataCursosLectivos = result;

      if(this.dataCursosLectivos != null && this.dataCursosLectivos.length > 0)
      /* 
        for(var curso of this.dataCursosLectivos)
        {
          //console.log(curso);
          //this.dateAux = curso.inicio.split("T");
          //curso.inicio = new Date(this.dateAux[0]);
          //curso.inicio = this.dateAux[0];
          //this.dateAux = curso.fin.split("T");
          //curso.fin = new Date(this.dateAux[0]);
          //curso.fin = this.dateAux[0];
          console.log(curso);
        }
        */
        console.log(this.dataCursosLectivos);
        this.dataCursosLectivos.forEach(element => {
            if(element != null){
              element.inicio
            }
        })
    });

  }

    ngOnInit(): void {

  }

  cellClick(e) {
      if (e.columnIndex > 0) {
        this.router.navigate(['eskola_cursoslectivos/editar/', this.mySelection[0]]);
      }
    }

  onClickEditar() {
      if (this.mySelection[0] > 0) {
        this.router.navigate(['eskola_cursoslectivos/editar/', this.mySelection[0]]);
      }
  }

  onClickNuevo() {
    this.router.navigate(['eskola_cursoslectivos/crear']);
    } 

  onClickEliminar(content) {
  if (this.mySelection[0] > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }
  }

  onClickDuplicar(){
    if(this.mySelection.length>0){
      this.mySelection.forEach(element => {
        this.cursosLectivosService.getById(element).subscribe(
          (cursoSelected: any) => {
            if (cursoSelected.length === 0) return;

            let nombreCopia: string = cursoSelected[0].nombre + " (copia)";
            let idNuevo: number;

            this.cursosLectivosService.create(-1, nombreCopia, cursoSelected[0].inicio, cursoSelected[0].fin, cursoSelected[0].observaciones).subscribe((result=>{
              if(result.error){
                this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });              
              }else{
                this.cursosLectivosService.getAll().subscribe(
                  (cursoNuevoSelected: any) => {
                    if (cursoNuevoSelected.length > 0){
                      cursoNuevoSelected.forEach( element => {
                        if(element.nombre === nombreCopia){
                          idNuevo = element.id;                 
                        }
                      })

                      this.cursosLectivosService.getHorariosDelCurso(cursoSelected[0].id).subscribe(
                        (horarioSelected: any) => {
                          if (horarioSelected.length > 0){
                            for(let i: number = 0; i < horarioSelected.length; i++){
                              this.cursosLectivosService.insertHorario(idNuevo, horarioSelected[i].dia,horarioSelected[i].horaInicio, horarioSelected[i].horaFin, false).subscribe(json => {
                                if (json.error && i == horarioSelected.length-1) {
                                  this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });
                                }else if(!json.error && i == horarioSelected.length-1){
                                  this.alertService.success(this.translateService.instant('duplicadocorrecto'), { keepAfterRouteChange: true });
                                  this.router.navigate(['eskola_cursoslectivos']);                                
                                }
                              });
                            }                         
                          }
                        }
                      )                      
                    }
                  }
                );             
              }
            }));                
          }
        )
      });     
    }
  }

  eliminarRegistro() {

      this.mySelection.forEach(element => {
        if (element > 0) {
          this.isDeleting = true;
          this.form = this.formBuilder.group({
            id: element
          });
          this.cursosLectivosService.delete(this.form.value.id).subscribe({
            next: () => {
              this.dataCursosLectivos = this.dataCursosLectivos.filter(x => x.id !== element);
              this.isDeleting = false;
              this.alertService.success(this.translateService.instant('eliminadocorrectamente'), { keepAfterRouteChange: true });
              this.router.navigate(['../eskola_cursoslectivos'], { relativeTo: this.route });
            },
            error: error => {
              this.isDeleting = false;
              this.alertService.error(error);
            }
          });
        }
      });
  
      this.modalReference.close();
      this.mySelection = [];
  
    }

}
