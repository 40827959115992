export class Maquina {
  id: number;
  email: string;
  nombreUsuario: string;
  nombre: string;
  apellido1: string;
  apellido2: string;
  usuarios_TiposId: number;
  numOperario: number;
  idERP: string;
  idDb: number;
  idioma: string;
  tema: number;
  menuExpandido: boolean;
  activo: boolean;
  idMaquinas: string;
  permisoMaquinas: string;
  contraseñaHMI: string;
  coste: number;
  usuarioTipo: string;
  gestionUsuarios: number;
  jwtToken?: string;
  rpm: string;
}
