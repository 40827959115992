import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/recetas`;

@Injectable()
export class RecetasService {

  constructor(private http: HttpClient) { }

  getById(id: string) {
    return this.http.get<any>(`${baseUrl}/${id}`);
  }

  create(id, form, tCamaraCaliente, tHusillo, cascadaAbrir1, cascadaCerrar1, cascadaAbrir2, cascadaCerrar2) {
    return this.http.post<JSON>(`${baseUrl}/create`, {
      id: id, idOperacion: form.idOperacion, nombre: form.nombre,
      tiempoCiclo: form.tiempoCiclo, tiempoInyeccion: form.tiempoInyeccion, tiempoPresionRemanente: form.tiempoPresionRemanente, tiempoEnfriamiento: form.tiempoEnfriamiento,
      tiempoDosificacionPlastificacion: form.tiempoDosificacionPlastificacion, presionInyeccionMax: form.presionInyeccionMax, presionRemanente: form.presionRemanente,
      contrapresion: form.contrapresion, ptoConmutacion: form.ptoConmutacion, paradaDosificado: form.paradaDosificado, retrocesoHusillo: form.retrocesoHusillo,
      paradaMolde: form.paradaMolde, cojin: form.cojin, revolucionesHusillo: form.revolucionesHusillo, fuerzaDeCierre: form.fuerzaDeCierre, temperaturaHusillo: form.temperaturaHusillo,
      temperaturaCamaraCaliente: form.temperaturaCamaraCaliente, tempPresionFija: form.tempPresionFija, tempPresionMovil: form.tempPresionMovil, temperaturaMolde: form.temperaturaMolde,
      pesoInyeccionEspumado: form.pesoInyeccionEspumado, porcenScf: form.porcenScf, dosificacionScf: form.dosificacionScf,
      tCamaraCaliente, tHusillo, cascadaAbrir1, cascadaCerrar1, cascadaAbrir2, cascadaCerrar2
    }, { withCredentials: true });
  }

  update(id, form, tCamaraCaliente, tHusillo, cascadaAbrir1, cascadaCerrar1, cascadaAbrir2, cascadaCerrar2) {
    return this.http.post<JSON>(`${baseUrl}/update`, {
      id: id, idOperacion: form.idOperacion, nombre: form.nombre,
      tiempoCiclo: form.tiempoCiclo, tiempoInyeccion: form.tiempoInyeccion, tiempoPresionRemanente: form.tiempoPresionRemanente, tiempoEnfriamiento: form.tiempoEnfriamiento,
      tiempoDosificacionPlastificacion: form.tiempoDosificacionPlastificacion, presionInyeccionMax: form.presionInyeccionMax, presionRemanente: form.presionRemanente,
      contrapresion: form.contrapresion, ptoConmutacion: form.ptoConmutacion, paradaDosificado: form.paradaDosificado, retrocesoHusillo: form.retrocesoHusillo,
      paradaMolde: form.paradaMolde, cojin: form.cojin, revolucionesHusillo: form.revolucionesHusillo, fuerzaDeCierre: form.fuerzaDeCierre, temperaturaHusillo: form.temperaturaHusillo,
      temperaturaCamaraCaliente: form.temperaturaCamaraCaliente, tempPresionFija: form.tempPresionFija, tempPresionMovil: form.tempPresionMovil, temperaturaMolde: form.temperaturaMolde,
      pesoInyeccionEspumado: form.pesoInyeccionEspumado, porcenScf: form.porcenScf, dosificacionScf: form.dosificacionScf,
      tCamaraCaliente, tHusillo, cascadaAbrir1, cascadaCerrar1, cascadaAbrir2, cascadaCerrar2
    }, { withCredentials: true });
  }

  delete(ids: string) {
    return this.http.post<JSON>(`${baseUrl}/delete`, { ids }, { withCredentials: true });
  }

}
