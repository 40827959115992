import { Component } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MaquinasService, MenuService } from '@app/_services';

import { ActivatedRoute, Router } from "@angular/router";

import { TranslateService } from '@ngx-translate/core';
import { UsuariosService } from '@app/_services';

@Component({
  selector: 'app-maquina-detalle-eje',
  templateUrl: './maquinaDetalleEje.component.html'
})

export class MaquinaDetalleEjeComponent {
  //#region "CARGA"
  public id: number = -1;
  public idMaquina: number = -1;
  public tipoMaquina: any;

  public ejeJSON: JSON;
  public feedId: number;
  public feedJSON: JSON;
  public consumoId: number;
  public consumoJSON: JSON;
  public temperaturaId: number;
  public temperaturaJSON: JSON;
  public posicionId: number;
  public posicionJSON: JSON;

  closeResult = '';
  form: FormGroup;
  loading = false;
  submitted = false;
  isAddMode: boolean;
  user = this.userService.userValue;

  constructor(
    private formBuilder: FormBuilder,
    private maquinasService: MaquinasService,
    private userService: UsuariosService,
    private route: ActivatedRoute,
    public router: Router,
    private menuService: MenuService,
    private translateService: TranslateService) {

    this.menuService.titulo = this.translateService.instant('eje').toUpperCase();
  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.idMaquina = this.route.snapshot.params['idMaquina'];

    this.isAddMode = !this.id;

    this.maquinasService.GetTipoMaquinaById(this.idMaquina).pipe().subscribe((result) => {
      this.tipoMaquina = result[0].tipo_maquina;
    });

    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      nombre: ['', Validators.required],
      orden: [1,],
      motor: [false,],
      principal: [false,],
      visible: [true,],
      activo: [true,],
      posicionDelEjeEnLaMaquina: [1,],
      canal: [-1,],
      //Feed
      feedId: [0,],
      feed: [true,],
      feedVisible: [true,],
      feedMinimo: [0,],
      feedRango1: [500,],
      feedRango2: [1500,],
      feedRango3: [2500,],
      feedMaximo: [5000,],
      //Consumo
      consumoId: [0,],
      consumo: [true,],
      consumoVisible: [true,],
      consumoMinimo: [0,],
      consumoRango1: [25,],
      consumoRango2: [50,],
      consumoRango3: [75,],
      consumoMaximo: [100,],
      //Temperatura
      temperaturaId: [0,],
      temperatura: [true,],
      temperaturaVisible: [true,],
      temperaturaMinimo: [0,],
      temperaturaRango1: [20,],
      temperaturaRango2: [40,],
      temperaturaRango3: [60,],
      temperaturaMaximo: [80,],
      //Posicion
      posicionId: [0,],
      posicion: [true,],
      posicionVisible: [true,],
      posConsumo1: [0,],
      posConsumo2: [0,],
      posTemperatura1: [0,],
      posTemperatura2: [0,],
      posCanal: [0,],
    })

    if (this.id > 0) {
      var eje, feed, consumo, temperatura, posicion = false;

      this.maquinasService.Get_Info_Eje(this.id).pipe().subscribe((result) => {
        eje = true;
        this.ejeJSON = result.data;

        if (eje && feed && consumo && temperatura && posicion) {
          this.cargarFromulario();
        }
      })

      this.maquinasService.Get_Variables_Eje(this.id, 1).pipe().subscribe((result) => {
        feed = true;

        if (result.data[0] != undefined) {
          this.feedJSON = result.data;
        } else {
          var jsonStr = '[{"id":"0","activo":"true","visible":"true","minimo":"0","cambioColor1":"500","cambioColor2":"1500","cambioColor3":"2500","maximo":"5000"}]';
          this.feedJSON = JSON.parse(jsonStr);
        }

        if (eje && feed && consumo && temperatura && posicion) {
          this.cargarFromulario();
        }
      })

      this.maquinasService.Get_Variables_Eje(this.id, 2).pipe().subscribe((result) => {
        consumo = true;

        if (result.data[0] != undefined) {
          this.consumoJSON = result.data;
        } else {
          var jsonStr = '[{"id":"0","activo":"true","visible":"true","minimo":"0","cambioColor1":"25","cambioColor2":"50","cambioColor3":"75","maximo":"100"}]';
          this.consumoJSON = JSON.parse(jsonStr);
        }

        if (eje && feed && consumo && temperatura && posicion) {
          this.cargarFromulario();
        }
      })

      this.maquinasService.Get_Variables_Eje(this.id, 3).pipe().subscribe((result) => {
        temperatura = true;

        if (result.data[0] != undefined) {
          this.temperaturaJSON = result.data;
        } else {
          var jsonStr = '[{"id":"0","activo":"true","visible":"true","minimo":"0","cambioColor1":"20","cambioColor2":"40","cambioColor3":"60","maximo":"80"}]';
          this.temperaturaJSON = JSON.parse(jsonStr);
        }

        if (eje && feed && consumo && temperatura && posicion) {
          this.cargarFromulario();
        }
      })

      this.maquinasService.Get_Variables_Eje(this.id, 4).pipe().subscribe((result) => {
        posicion = true;

        if (result.data[0] != undefined) {
          this.posicionJSON = result.data;
        } else {
          var jsonStr = '[{"id":"0","activo":"true","visible":"true"}]';
          this.posicionJSON = JSON.parse(jsonStr);
        }

        if (eje && feed && consumo && temperatura && posicion) {
          this.cargarFromulario();
        }
      })
    } else {
      this.maquinasService.Get_Count_Eje(this.idMaquina).pipe().subscribe((result) => {
        this.form = this.formBuilder.group({
          idDb: this.user.idDb,
          nombre: ['', Validators.required],
          orden: [result.data[0].num + 1,],
          motor: [false,],
          principal: [false,],
          visible: [true,],
          activo: [true,],
          posicionDelEjeEnLaMaquina: [1,],
          canal: [-1,],
          //Feed
          feed: [true,],
          feedVisible: [true,],
          feedMinimo: [0,],
          feedRango1: [500,],
          feedRango2: [1500,],
          feedRango3: [2500,],
          feedMaximo: [5000,],
          //Consumo
          consumo: [true,],
          consumoVisible: [true,],
          consumoMinimo: [0,],
          consumoRango1: [25,],
          consumoRango2: [50,],
          consumoRango3: [75,],
          consumoMaximo: [100,],
          //Temperatura
          temperatura: [true,],
          temperaturaVisible: [true,],
          temperaturaMinimo: [0,],
          temperaturaRango1: [20,],
          temperaturaRango2: [40,],
          temperaturaRango3: [60,],
          temperaturaMaximo: [80,],
          //Posicion
          posicion: [true,],
          posicionVisible: [true,],
          posConsumo1: [0,],
          posConsumo2: [0,],
          posTemperatura1: [0,],
          posTemperatura2: [0,],
          posCanal: [0,],
        })
      })

      this.feedId = 0;
      this.consumoId = 0;
      this.temperaturaId = 0;
      this.posicionId = 0;
    }
  }

  public cargarFromulario() {
    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      nombre: [this.ejeJSON[0].nombre, Validators.required],
      orden: [this.ejeJSON[0].orden,],
      motor: [this.ejeJSON[0].motor,],
      principal: [this.ejeJSON[0].principal,],
      visible: [this.ejeJSON[0].visible,],
      activo: [this.ejeJSON[0].activo,],
      posicionDelEjeEnLaMaquina: [this.ejeJSON[0].ordenMaquina,],
      canal: [this.ejeJSON[0].idCanales,],
      //Feed
      feed: [this.feedJSON[0].activo,],
      feedVisible: [this.feedJSON[0].visible,],
      feedMinimo: [this.feedJSON[0].minimo,],
      feedRango1: [this.feedJSON[0].cambioColor1,],
      feedRango2: [this.feedJSON[0].cambioColor2,],
      feedRango3: [this.feedJSON[0].cambioColor3,],
      feedMaximo: [this.feedJSON[0].maximo,],
      //Consumo
      consumo: [this.consumoJSON[0].activo,],
      consumoVisible: [this.consumoJSON[0].visible,],
      consumoMinimo: [this.consumoJSON[0].minimo,],
      consumoRango1: [this.consumoJSON[0].cambioColor1,],
      consumoRango2: [this.consumoJSON[0].cambioColor2,],
      consumoRango3: [this.consumoJSON[0].cambioColor3,],
      consumoMaximo: [this.consumoJSON[0].maximo,],
      //Temperatura
      temperatura: [this.temperaturaJSON[0].activo,],
      temperaturaVisible: [this.temperaturaJSON[0].visible,],
      temperaturaMinimo: [this.temperaturaJSON[0].minimo,],
      temperaturaRango1: [this.temperaturaJSON[0].cambioColor1,],
      temperaturaRango2: [this.temperaturaJSON[0].cambioColor2,],
      temperaturaRango3: [this.temperaturaJSON[0].cambioColor3,],
      temperaturaMaximo: [this.temperaturaJSON[0].maximo,],
      //Posicion
      posicion: [this.posicionJSON[0].activo,],
      posicionVisible: [this.posicionJSON[0].visible,],
      posConsumo1: [this.ejeJSON[0].posConsumo1,],
      posConsumo2: [this.ejeJSON[0].posConsumo2,],
      posTemperatura1: [this.ejeJSON[0].posTemperatura1,],
      posTemperatura2: [this.ejeJSON[0].posTemperatura2,],
      posCanal: [this.ejeJSON[0].posCanal,],
    });

    this.feedId = this.feedJSON[0].id;
    this.consumoId = this.consumoJSON[0].id;
    this.temperaturaId = this.temperaturaJSON[0].id;
    this.posicionId = this.posicionJSON[0].id;

  }
  //#endregion

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  public onSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    if (this.isAddMode) {
      this.insert();
    } else {
      this.update();
    }
  }

  private insert() {

    this.maquinasService.Insert_Eje(this.form.value, this.idMaquina).subscribe((result) => {
      if (result.error == false) {
        this.id = result.id;
        this.guardarVariable();
      }
      else {
      }
    });
  }

  private update() {
    this.form.value.id = this.id;
    this.maquinasService.Update_Eje(this.form.value, this.id, this.idMaquina).subscribe((result) => {
      if (result.error == false) {
        this.guardarVariable();
      }
      else {
      }
    });
  }

  private guardarVariable() {
    //Feed
    if (this.form.value.feed) {
      if (this.feedId == 0) { //INSERT
        this.maquinasService.Insert_Variables_Eje(this.id, 1, this.form.value.feedMinimo, this.form.value.feedRango1, this.form.value.feedRango2, this.form.value.feedRango3, this.form.value.feedMaximo, this.form.value.feedMaximo, this.form.value.feedVisible, this.form.value.feed).subscribe((result) => {
          if (result.error == false) {
          }
          else {
          }
        });
      } else { //UPDATE
        this.maquinasService.Update_Variables_Eje(this.feedId, this.form.value.feedMinimo, this.form.value.feedRango1, this.form.value.feedRango2, this.form.value.feedRango3, this.form.value.feedMaximo, this.form.value.feedMaximo, this.form.value.feedVisible).subscribe((result) => {
          if (result.error == false) {
          }
          else {
          }
        });
      }
    } else {
      if (this.feedId != 0) { //NO ACTIVO
        this.maquinasService.Update_Variables_Eje_No_Activo(this.feedId).subscribe((result) => {
          if (result.error == false) {
          }
          else {
          }
        });

      } else {
        this.maquinasService.Insert_Variables_Eje(this.id, 1, this.form.value.feedMinimo, this.form.value.feedRango1, this.form.value.feedRango2, this.form.value.feedRango3, this.form.value.feedMaximo, this.form.value.feedMaximo, this.form.value.feedVisible, this.form.value.feed).subscribe((result) => {
          if (result.error == false) {
          }
          else {
          }
        });
      }
    }

    //Consumo
    if (this.form.value.consumo) {
      if (this.consumoId == 0) { //INSERT
        this.maquinasService.Insert_Variables_Eje(this.id, 2, this.form.value.consumoMinimo, this.form.value.consumoRango1, this.form.value.consumoRango2, this.form.value.consumoRango3, this.form.value.consumoMaximo, this.form.value.consumoMaximo, this.form.value.consumoVisible, this.form.value.consumo).subscribe((result) => {
          if (result.error == false) {
          }
          else {
          }
        });
      } else { //UPDATE
        this.maquinasService.Update_Variables_Eje(this.consumoId, this.form.value.consumoMinimo, this.form.value.consumoRango1, this.form.value.consumoRango2, this.form.value.consumoRango3, this.form.value.consumoMaximo, this.form.value.consumoMaximo, this.form.value.consumoVisible).subscribe((result) => {
          if (result.error == false) {
          }
          else {
          }
        });
      }
    } else {
      if (this.consumoId != 0) { //NO ACTIVO
        this.maquinasService.Update_Variables_Eje_No_Activo(this.consumoId).subscribe((result) => {
          if (result.error == false) {
          }
          else {
          }
        });

      } else {
        this.maquinasService.Insert_Variables_Eje(this.id, 2, this.form.value.consumoMinimo, this.form.value.consumoRango1, this.form.value.consumoRango2, this.form.value.consumoRango3, this.form.value.consumoMaximo, this.form.value.consumoMaximo, this.form.value.consumoVisible, this.form.value.consumo).subscribe((result) => {
          if (result.error == false) {
          }
          else {
          }
        });
      }
    }

    //Temperatura
    if (this.form.value.temperatura) {
      if (this.temperaturaId == 0) { //INSERT
        this.maquinasService.Insert_Variables_Eje(this.id, 3, this.form.value.temperaturaMinimo, this.form.value.temperaturaRango1, this.form.value.temperaturaRango2, this.form.value.temperaturaRango3, this.form.value.temperaturaMaximo, this.form.value.temperaturaMaximo, this.form.value.temperaturaVisible, this.form.value.temperatura).subscribe((result) => {
          if (result.error == false) {
          }
          else {
          }
        });
      } else { //UPDATE
        this.maquinasService.Update_Variables_Eje(this.temperaturaId, this.form.value.temperaturaMinimo, this.form.value.temperaturaRango1, this.form.value.temperaturaRango2, this.form.value.temperaturaRango3, this.form.value.temperaturaMaximo, this.form.value.temperaturaMaximo, this.form.value.temperaturaVisible).subscribe((result) => {
          if (result.error == false) {
          }
          else {
          }
        });
      }
    } else {
      if (this.temperaturaId != 0) { //NO ACTIVO
        this.maquinasService.Update_Variables_Eje_No_Activo(this.temperaturaId).subscribe((result) => {
          if (result.error == false) {
          }
          else {
          }
        });

      } else {
        this.maquinasService.Insert_Variables_Eje(this.id, 3, this.form.value.temperaturaMinimo, this.form.value.temperaturaRango1, this.form.value.temperaturaRango2, this.form.value.temperaturaRango3, this.form.value.temperaturaMaximo, this.form.value.temperaturaMaximo, this.form.value.temperaturaVisible, this.form.value.temperatura).subscribe((result) => {
          if (result.error == false) {
          }
          else {
          }
        });
      }
    }

    //Posicion
    if (this.form.value.posicion) {
      if (this.posicionId.toString() == "0") { //INSERT
        this.maquinasService.Insert_Variables_Eje(this.id, 4, 0, 0, 0, 0, 0, 0, this.form.value.posicionVisible, this.form.value.posicion).subscribe((result) => {
          if (result.error == false) {
          }
          else {
          }
        });
      } else { //UPDATE
        this.maquinasService.Update_Variables_Eje(this.posicionId, 0, 0, 0, 0, 0, 0, this.form.value.posicionVisible).subscribe((result) => {
          if (result.error == false) {
          }
          else {
          }
        });
      }
    } else {
      if (this.posicionId.toString() != "0") { //NO ACTIVO
        this.maquinasService.Update_Variables_Eje_No_Activo(this.posicionId).subscribe((result) => {
          if (result.error == false) {
          }
          else {

          }
        });

      } else {
        this.maquinasService.Insert_Variables_Eje(this.id, 4, 0, 0, 0, 0, 0, 0, this.form.value.posicionVisible, this.form.value.posicion).subscribe((result) => {
          if (result.error == false) {
          }
          else {
          }
        });
      }
    }

    this.atras();
  }

  public atras() {
    if (this.idMaquina > 0) {
      if (this.tipoMaquina == -1) this.router.navigate(['maquinas/editar/', this.idMaquina]); //NO TIENE TIPO DE MAQUINA, IR A MECANIZADO POR DEFECTO
      if (this.tipoMaquina == 1) this.router.navigate(['maquinas/editar/', this.idMaquina]); //MECANIZADO
      if (this.tipoMaquina == 2) this.router.navigate(['extrusoras/editar/', this.idMaquina]); //EXTRUSORA
      if (this.tipoMaquina == 3) this.router.navigate(['impresorasPlastico/editar/', this.idMaquina]); //IMPRESORA PLASTICO
      if (this.tipoMaquina == 4) this.router.navigate(['inyectoras/editar/', this.idMaquina]); //INYECTORAS
      if (this.tipoMaquina == 5) this.router.navigate(['hornos/editar/', this.idMaquina]); //HORNOS
      if (this.tipoMaquina == 9) this.router.navigate(['aditivo/editar/', this.idMaquina]); //ADITIVO
    }
  }
}
